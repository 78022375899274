import { useCallback, useEffect, useState } from 'react';
import CustomDialog from '../CustomDialog';
import { useMutation } from '@apollo/client';
import { CREATE_ASSET_DOWNLOAD_URL } from '../../gql';
import { Color3, Vector3 } from 'babylonjs';
import { format, IAsset, PlanAssetColorType } from '@workflow-nx/common';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ZoomMeshViewer } from '../../views/cases/CaseView/CasePlanningTab/ZoomMeshViewer';
import { AssetMeshView } from '../BetterSpineViewer';

const useStyles = makeStyles(() => ({
  canvasRoot: {
    border: `1px solid rgba(0, 0, 0, 0.12)`,
    borderRadius: 5,
    '& canvas': {
      width: '100%',
      height: 600,
      touchAction: 'none',
      background: 'transparent',
    },
  },
}));

export function AssetMeshViewDialog(props: { open: boolean; asset?: IAsset; onClose: () => void }) {
  const styles = useStyles();
  const [createAssetDownloadUrl] = useMutation(CREATE_ASSET_DOWNLOAD_URL);
  const [zoomAsset, setZoomAsset] = useState<AssetMeshView>();
  const [focus, setFocus] = useState<string>();

  const handleLoad = useCallback(
    async (asset: IAsset) => {
      const { data } = await createAssetDownloadUrl({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        variables: {
          assetId: asset.assetId,
        },
      });

      const signedUrl = data.createAssetDownloadUrl.signedUrl;
      setZoomAsset({
        name: `${asset.assetType}`,
        signedDownloadUrl: signedUrl,
        tags: [asset.assetType],
        category: 'PLAN',
        type: 'IMPLANT',
        options: {
          color: Color3.FromHexString(
            asset.assetType.endsWith('_APP')
              ? PlanAssetColorType.PlanImplantCut
              : PlanAssetColorType.PlanVertebrae,
          ),
        },
      });
      setFocus(asset.assetType as string);
    },
    [createAssetDownloadUrl],
  );

  useEffect(() => {
    if (props.open && props.asset) {
      handleLoad(props.asset);
    }

    return function cleanup() {
      setZoomAsset(undefined);
    };
  }, [props.asset, props.open, handleLoad]);

  return (
    <CustomDialog
      maxWidth={'md'}
      open={props.open}
      title={`Viewing Asset ${format.formatAssetType(props?.asset?.assetType)}`}
      onClose={() => {
        props.onClose();
      }}
      positiveActionButtons={[]}
    >
      {props.open && zoomAsset ? (
        <Box
          className={styles.canvasRoot}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <ZoomMeshViewer
            assetMeshViews={[zoomAsset]}
            focus={focus ? [focus] : []}
            cameraPosition={new Vector3(0, 0, 100)}
          />
        </Box>
      ) : null}
    </CustomDialog>
  );
}
