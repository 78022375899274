import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { Box, LinearProgress, Link, Stack, Typography, useTheme } from '@mui/material';
import { IconFontButton } from '@workflow-nx/ui';
import { DateTime, Interval } from 'luxon';
import { useState } from 'react';
import { CalendarEventView } from './CalendarEventView';
import CalendarGridAllEventsView from './CalendarGridAllEventsView';
import CalendarGridEventView from './CalendarGridEventView';

function getMonthDateRange(selectedDate: DateTime) {
  const nowStart = selectedDate.startOf('month');
  const nowEnd = selectedDate.endOf('month');
  const startDate = nowStart.minus({ days: nowStart.weekday });
  const endDate = nowEnd.plus({ days: 6 - nowEnd.weekday });
  return Interval.fromDateTimes(startDate, endDate);
}

export function CalendarGridView(props: {
  events: any[];
  onMonthChange: (date: Date) => void;
  loading: boolean;
}) {
  const [selectedDate, setSelectedDate] = useState<DateTime>(DateTime.now());
  const [monthDatesInterval, setMonthDatesInterval] = useState<Interval>(
    getMonthDateRange(DateTime.now()),
  );

  const theme = useTheme();

  return (
    <Box>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{ mb: 1 }}
      >
        <Typography variant={'h3'}>
          {selectedDate.monthLong} {selectedDate.year}
        </Typography>
        <Stack spacing={1} direction={'row'}>
          <IconFontButton
            disabled={props.loading}
            icon={faChevronLeft}
            onClick={() => {
              const newSelectedDate = selectedDate.minus({ month: 1 });
              setSelectedDate(newSelectedDate);
              setMonthDatesInterval(getMonthDateRange(newSelectedDate));

              props.onMonthChange(newSelectedDate.toJSDate());
            }}
          />
          <IconFontButton
            disabled={props.loading}
            icon={faChevronRight}
            onClick={() => {
              const newSelectedDate = selectedDate.plus({ month: 1 });
              setSelectedDate(newSelectedDate);
              setMonthDatesInterval(getMonthDateRange(newSelectedDate));

              props.onMonthChange(newSelectedDate.toJSDate());
            }}
          />
        </Stack>
      </Stack>
      {props.loading ? (
        <Box sx={{ height: '2px' }}>
          <LinearProgress sx={{ height: '2px' }} />
        </Box>
      ) : null}

      <Box
        sx={{
          borderRight: `1px solid ${theme.palette.grey[200]}`,
          borderBottom: `1px solid ${theme.palette.grey[200]}`,
          display: 'grid',
          gridAutoFlow: 'row',
          gridTemplateRows: `30px repeat(${Math.round(
            monthDatesInterval.length('days') / 7,
          )}, minmax(150px, 1fr))`,
          gridTemplateColumns: 'repeat(7, minmax(120px, 1fr))',
        }}
      >
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => {
          return (
            <Box
              key={day}
              sx={{
                borderLeft: `1px solid ${theme.palette.grey[200]}`,
                borderTop: `1px solid ${theme.palette.grey[200]}`,
                background: '#eee',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography
                sx={{
                  textTransform: 'uppercase',
                  color: theme.palette.grey[600],
                }}
              >
                <strong>{day}</strong>
              </Typography>
            </Box>
          );
        })}

        {monthDatesInterval.splitBy({ days: 1 }).map((monthDate) => {
          const isDayOutsideCurrentMonth = selectedDate.month !== monthDate?.start?.month;
          const isCurrentDay =
            DateTime.now().month === monthDate?.start?.month &&
            DateTime.now().day === monthDate?.start?.day;

          let color = theme.palette.grey[500];
          if (isDayOutsideCurrentMonth) {
            color = theme.palette.grey[300];
          }

          const dayEvents = (props?.events ?? []).filter((c) => {
            const eventDate = DateTime.fromISO(c.date as string);
            return (
              eventDate.month === monthDate?.start?.month && eventDate.day === monthDate?.start?.day
            );
          });

          const topEvents = dayEvents.filter((_, index) => index < 3);
          const remainingEvents = dayEvents.filter((_, index) => index >= 3);

          return (
            <Box
              sx={{
                borderLeft: `1px solid ${theme.palette.grey[200]}`,
                borderTop: `1px solid ${theme.palette.grey[200]}`,
                height: '100%',
              }}
            >
              {props.loading ? (
                <Box
                  sx={{
                    position: 'relative',
                    height: '100%',
                    width: '100%',
                    zIndex: 1000,
                    backgroundColor: 'rgba(0,0,0, 0.01)',
                  }}
                />
              ) : null}
              <Box
                sx={{
                  display: 'flex',
                  color,
                  justifyContent: 'flex-end',
                }}
              >
                {!props.loading ? (
                  <Typography
                    sx={{
                      textTransform: 'uppercase',
                      padding: '5px',
                      color,
                    }}
                  >
                    <Box
                      sx={{
                        fontWeight: 'bold',
                        color: isCurrentDay ? theme.palette.primary.contrastText : undefined,
                        backgroundColor: isCurrentDay ? theme.palette.primary.dark : undefined,
                        borderRadius: isCurrentDay ? '5px' : undefined,
                        margin: '2px',
                        padding: '2px',
                        opacity: props.loading ? '0.1' : undefined,
                      }}
                    >
                      <>{monthDate?.start?.day}</>
                    </Box>
                  </Typography>
                ) : null}
              </Box>
              {!props.loading ? (
                <Stack sx={{ px: 1 }}>
                  {topEvents.map((event) => {
                    return (
                      <Box key={event.title}>
                        <CalendarGridEventView extendedProps={event.extendedProps}>
                          <Box>
                            <CalendarEventView event={event} />
                          </Box>
                        </CalendarGridEventView>
                      </Box>
                    );
                  })}
                  {remainingEvents.length > 0 ? (
                    <CalendarGridAllEventsView events={remainingEvents}>
                      <Link underline={'none'}>
                        <Typography textAlign={'center'}>+{remainingEvents.length} more</Typography>
                      </Link>
                    </CalendarGridAllEventsView>
                  ) : null}
                </Stack>
              ) : null}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
