import {
  AssetType,
  CaseSpineProfile,
  Form20ProductStatusType,
  ICase,
  ICaseImplantSpecification,
  IForm17Product,
  IForm20Product,
  ILevels,
  IPlan,
  ImplantDirection,
  ImplantOrientation,
  ImplantType,
  KitCartonRuleType,
  LEVEL_CONFIG_MAP,
  PartType,
  caseUtils,
  format,
} from '@workflow-nx/common';
import { date } from '@workflow-nx/utils';
import { sortBy } from 'lodash';
import config from '../extras/config';
import { isTlifCOrientationAvailable } from './featureFlags';
import {
  CountLevelsByImplant,
  ILevelsHeaderData,
  ImplantCombinationType,
  ProductDates,
  check11453ScrewAvailable,
  checkM4lExpandedAvailability,
  countLevelsByImplantType,
  findImplantsCombination,
  findInstrumentsByKit,
  getKitCartonRuleType,
} from './form19';

export const PRINT_UPTO_TWENTY_FOUR = 24;

function getForm17CaseItems(
  levels: ILevels,
  spineProfile: CaseSpineProfile,
  plusLevelSize: number,
  form20MasterList: IForm20Product[],
  isAlifXIncluded: boolean,
  finalCaseForm17InstrumentsPack: string[],
  caseImplantSpecifications: ICaseImplantSpecification[],
  is11453ScrewAvailable?: boolean,
): IForm17Product[] {
  const form17Parts: IForm17Product[] = [];
  let skuPartTypeCode = '';

  const profileLevelAssets = caseUtils
    .getCaseSpineProfile(spineProfile)
    .validLevels.map((level) => LEVEL_CONFIG_MAP[level].asset);

  Object.entries(levels).filter(([key]) => {
    if (profileLevelAssets.includes(key as AssetType)) {
      if (caseUtils.isValidLevelPartType(levels[key])) {
        skuPartTypeCode = caseUtils.getPartTypeCode(levels[key] as PartType);
        skuPartTypeCode += '.' + key.slice(0, 2) + key.slice(-1);
        const [implantType, direction, orientation] =
          caseUtils.getImplantTypeAndDirectionAndOrientation(levels[key] as PartType);

        const implantCageOrientation =
          implantType !== ImplantType.ALIFX ? ImplantOrientation.None : orientation;
        const implantThreadAngle = ![ImplantType.TLIFC, ImplantType.TLIFCA].includes(
          implantType as ImplantType,
        )
          ? ImplantOrientation.None
          : orientation;
        const cageOrientation = format.formatImplantOrientation(
          implantCageOrientation as ImplantOrientation,
        );
        const threadAngle = format.formatImplantOrientation(
          implantThreadAngle as ImplantOrientation,
        );

        for (let i = 1; i <= 3; i++) {
          const plusSize = i === 3 ? `+${plusLevelSize}` : 'N/A';
          const sku = skuPartTypeCode + '.0' + i;
          const element = form20MasterList.find((element) => element.sku === sku);
          if (element) {
            const form17Element = {
              ...element,
              direction: implantType !== ImplantType.ALIFX ? direction : ImplantDirection.None,
              implantType,
              level: 'N/A',
              cageOrientation: cageOrientation,
              plusSize: plusSize,
              threadAngle: threadAngle,
            } as IForm17Product;
            form17Parts.push(form17Element);
          }
        }
        skuPartTypeCode = '';
      }
    }
  });

  for (const instrumentSku of finalCaseForm17InstrumentsPack) {
    const element = form20MasterList.find(
      (element) =>
        element.sku === instrumentSku && element?.status === Form20ProductStatusType.InUse,
    );
    if (element && form17Parts.length >= 3) {
      const form17Element = {
        ...element,
        direction: ImplantDirection.None,
        implantType: ImplantType.None,
        level: 'N/A',
      };
      form17Parts.push(form17Element);
    }
  }

  if (isAlifXIncluded) {
    if (caseImplantSpecifications.length) {
      for (const caseImplantSpecification of caseImplantSpecifications) {
        const screwSku = caseImplantSpecification?.screwLength
          ? caseUtils.getScrewLengthSku(
              caseImplantSpecification?.screwLength,
              is11453ScrewAvailable,
            )
          : '';
        const element = form20MasterList.find((element) => element.sku === screwSku);
        if (element && form17Parts.length >= 3) {
          const form17Element = {
            ...element,
            direction: ImplantDirection.None,
            implantType: ImplantType.None,
            level: caseImplantSpecification?.level
              ? caseImplantSpecification?.level.slice(0, 2) +
                caseImplantSpecification?.level.slice(-1)
              : '',
          };
          form17Parts.push(form17Element);
        }
      }
    }
  }

  return form17Parts;
}

export function createCaseForm17InstrumentsPack(
  caseLevelsData: ILevelsHeaderData[],
  planApprovedAt: Date | null,
  productDates: ProductDates,
): string[] {
  let caseForm17InstrumentsPack: string[] = [];

  if (!planApprovedAt) return caseForm17InstrumentsPack;

  const levelsCount = countLevelsByImplantType(caseLevelsData);
  const kitCartonRuleType = getKitCartonRuleType(
    levelsCount.nonAlifXLevelCount,
    levelsCount.alifXLevelCount,
  );

  const isM4lExpandedAvailable = checkM4lExpandedAvailability(
    planApprovedAt,
    productDates?.tlifCM4lStartDate as Date,
  );

  switch (kitCartonRuleType) {
    case KitCartonRuleType.NonAlifXCase:
      caseForm17InstrumentsPack = generateNonAlifXInstrumentsPack(
        caseLevelsData,
        planApprovedAt,
        levelsCount,
        isM4lExpandedAvailable,
        productDates,
      );
      break;
    case KitCartonRuleType.AlifXCase:
      caseForm17InstrumentsPack = generateAlifXInstrumentsPack(
        levelsCount,
        isM4lExpandedAvailable,
        productDates,
      );
      break;
    case KitCartonRuleType.NonAlifXAndAlifXCase:
      caseForm17InstrumentsPack = generateNonAlifXAndAlifXInstrumentsPack(
        caseLevelsData,
        planApprovedAt,
        levelsCount,
        isM4lExpandedAvailable,
        productDates,
      );
      break;
  }
  return caseForm17InstrumentsPack;
}

export function generateNonAlifXInstrumentsPack(
  caseLevelsData: ILevelsHeaderData[],
  planApprovedAt: Date,
  levelsCount: CountLevelsByImplant,
  isM4lExpandedAvailable: boolean,
  productDates: ProductDates,
): string[] {
  const instrumentSkuNumbers: string[] = [];
  const implantsCombination = findImplantsCombination(caseLevelsData);
  const instrumentsByKit = findInstrumentsByKit(
    planApprovedAt,
    implantsCombination,
    KitCartonRuleType.NonAlifXCase,
    levelsCount,
    isM4lExpandedAvailable,
    productDates,
  );

  instrumentSkuNumbers.push(...instrumentsByKit.kit1);

  if (instrumentsByKit?.kit2?.length > 0) {
    instrumentSkuNumbers.push(...instrumentsByKit.kit2);
  }

  return instrumentSkuNumbers;
}

export function generateAlifXInstrumentsPack(
  levelsCount: CountLevelsByImplant,
  isM4lExpandedAvailable: boolean,
  productDates: ProductDates,
): string[] {
  const instrumentSkuNumbers: string[] = [];
  const planApprovedAt = new Date();
  const instrumentsByKit = findInstrumentsByKit(
    planApprovedAt,
    ImplantCombinationType.None,
    KitCartonRuleType.AlifXCase,
    levelsCount,
    isM4lExpandedAvailable,
    productDates,
  );

  instrumentSkuNumbers.push(...instrumentsByKit.kit1, ...instrumentsByKit.kit2);

  if (instrumentsByKit?.kit3?.length > 0) {
    instrumentSkuNumbers.push(...instrumentsByKit.kit3);
  }

  return instrumentSkuNumbers;
}

export function generateNonAlifXAndAlifXInstrumentsPack(
  caseLevelsData: ILevelsHeaderData[],
  planApprovedAt: Date,
  levelsCount: CountLevelsByImplant,
  isM4lExpandedAvailable: boolean,
  productDates: ProductDates,
): string[] {
  const instrumentSkuNumbers: string[] = [];

  const implantsCombination = findImplantsCombination(caseLevelsData);
  const instrumentsByKit = findInstrumentsByKit(
    planApprovedAt,
    implantsCombination,
    KitCartonRuleType.NonAlifXAndAlifXCase,
    levelsCount,
    isM4lExpandedAvailable,
    productDates,
  );

  instrumentSkuNumbers.push(
    ...instrumentsByKit.kit1,
    ...instrumentsByKit.kit2,
    ...instrumentsByKit.kit3,
  );
  return instrumentSkuNumbers;
}

export type Form17DataType = {
  [key: string]: {
    t?: string;
    z?: string;
    v: string | number | undefined;
  };
};

export type HeaderType = {
  value: string;
  width: number;
};

export const generateForm17CaseData = (
  activeCase: ICase,
  plan: IPlan,
  hospitalName: string,
  form20MasterList: IForm20Product[],
  caseImplantSpecifications: ICaseImplantSpecification[],
  productDates: ProductDates,
) => {
  const isAlifXIncluded = !!caseUtils.getValidCaseAlifXLevels(activeCase.levels)?.length;
  const planApprovedAt = date.parseCalendarDateFromString(plan?.approvedAt as string);
  const caseLevelsData = caseUtils.getValidCaseLevelsWithPartTypes(
    activeCase.levels,
  ) as unknown as ILevelsHeaderData[];
  const caseForm17InstrumentsPack = createCaseForm17InstrumentsPack(
    caseLevelsData,
    planApprovedAt,
    productDates,
  );
  const excludedInstruments = activeCase.excludedInstruments ?? [];

  const finalCaseForm17InstrumentsPack: string[] = caseForm17InstrumentsPack.filter(
    (element) => !excludedInstruments?.includes(element),
  );

  const sortedFinalCaseForm17InstrumentsPack = sortBy(finalCaseForm17InstrumentsPack);

  const tlifCOrientationStartDate = productDates?.tlifCOrientationStartDate as Date;
  const alifXRotationLockStartDate = productDates?.alifXRotationLockStartDate as Date;
  const is11453ScrewAvailable = check11453ScrewAvailable(
    planApprovedAt,
    alifXRotationLockStartDate,
  );

  const data: Form17DataType[] = [];
  const header = [
    { value: 'Contents #', width: 10 },
    { value: 'Patient Initials', width: 12 },
    { value: 'Index Number', width: 12 },
    { value: 'Imaging Date', width: 12 },
    { value: 'Expiration Date', width: 12 },
    { value: 'LOT #', width: 12 },
    { value: 'Patient ID', width: 10 },
    { value: 'MRN', width: 12 },
    { value: 'Hospital', width: 15 },
    { value: 'Surgeon Name', width: 15 },
    { value: 'REF', width: 10 },
    { value: 'GTIN', width: 15 },
    { value: 'Product Description', width: 35 },
    { value: 'Insertion Side', width: 15 },
    { value: 'Level', width: 10 },
  ];
  if (config.featureFlags.alifXOrientation) {
    header.push({ value: 'Cage Orientation', width: 15 }, { value: 'Plus Size', width: 10 });
  }

  if (isTlifCOrientationAvailable(tlifCOrientationStartDate)) {
    header.push({ value: 'Thread Angle', width: 12 });
  }

  let contentNo = 0;

  const form17CaseItems = getForm17CaseItems(
    activeCase.levels,
    activeCase.spineProfile,
    plan.plusLevelSize,
    form20MasterList,
    isAlifXIncluded,
    sortedFinalCaseForm17InstrumentsPack,
    caseImplantSpecifications,
    is11453ScrewAvailable,
  );

  for (const product of form17CaseItems) {
    contentNo += 1;
    const form17RowsData: Form17DataType = {
      'Contents #': {
        t: 'n',
        v: contentNo,
      },
      'Patient Initials': {
        t: 's',
        v: activeCase.patient ? format.formatInitials(activeCase.patient) : '',
      },
      'Index Number': {
        z: '@',
        t: 's',
        v: activeCase.number.split('.')[2],
      },
      'Imaging Date': {
        t: 's',
        v: activeCase?.studyDate?.studyDate
          ? format.formatISODate(activeCase?.studyDate?.studyDate as string)
          : undefined,
      },
      'Expiration Date': {
        t: 's',
        v: activeCase?.studyDate?.expiryDate
          ? format.formatISODate(activeCase?.studyDate?.expiryDate as string)
          : undefined,
      },
      'LOT #': {
        t: 's',
        v: activeCase.number,
      },
      'Patient ID': {
        z: '@',
        t: 's',
        v: activeCase.patient?.uniquePatientId ? activeCase.patient?.uniquePatientId : '',
      },

      MRN: {
        z: '@',
        t: 's',
        v: activeCase.patient?.mrn ? activeCase.patient?.mrn : '',
      },
      Hospital: {
        t: 's',
        v: hospitalName,
      },
      'Surgeon Name': {
        t: 's',
        v: activeCase.surgeonUser ? format.formatName(activeCase.surgeonUser) : '',
      },
      REF: {
        z: '@',
        t: 's',
        v: product.sku,
      },
      GTIN: {
        z: '@',
        t: 's',
        v: product.gs1DeviceId,
      },
      'Product Description': {
        t: 's',
        v: product.description,
      },
      'Insertion Side': {
        t: 's',
        v: product?.direction
          ? product.direction === 'NONE'
            ? 'N/A'
            : format.formatImplantDirection(product.direction)
          : 'N/A',
      },
      Level: {
        t: 's',
        v: product?.level ?? '',
      },
    };

    if (config.featureFlags.alifXOrientation) {
      form17RowsData['Cage Orientation'] = {
        t: 's',
        v: product?.cageOrientation || 'N/A',
      };
      form17RowsData['Plus Size'] = {
        t: 's',
        v: product?.plusSize ?? 'N/A',
      };
    }
    if (isTlifCOrientationAvailable(tlifCOrientationStartDate)) {
      form17RowsData['Thread Angle'] = {
        t: 's',
        v: product?.threadAngle || 'N/A',
      };
    }
    data.push(form17RowsData);
  }

  if (contentNo > 0 && contentNo < PRINT_UPTO_TWENTY_FOUR) {
    for (let i = contentNo + 1; i <= PRINT_UPTO_TWENTY_FOUR; i++) {
      data.push({
        'Contents #': {
          t: 'n',
          v: i,
        },
      });
    }
  }

  return { data, header };
};
