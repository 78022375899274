import { DatePicker as MatDatePicker } from '@mui/x-date-pickers';
import React, { ComponentProps } from 'react';
import { testing } from '@workflow-nx/utils';
import { TextField } from '@mui/material';
import { DateTime } from 'luxon';

type DatePickerProps = {
  variant?: 'outlined' | 'standard' | 'filled';
  label?: string;
  required?: boolean;
  disabled?: boolean;
  disablePast?: boolean;
  disableFuture?: boolean;
  shrink?: boolean;
  fullWidth?: boolean;
  inputProps?: ComponentProps<typeof TextField>['inputProps'];
  value: Date | null | undefined;
  onChange: (value: string) => void;
  minDate?: DateTime;
};

const DatePickerValue = ({
  label,
  required,
  disablePast,
  disableFuture,
  disabled,
  shrink,
  fullWidth,
  value,
  variant = 'outlined',
  onChange,
  minDate,
  inputProps,
}: DatePickerProps) => {
  return (
    <MatDatePicker
      closeOnSelect={true}
      label={label}
      disabled={disabled}
      minDate={minDate}
      disablePast={disablePast}
      disableFuture={disableFuture}
      value={value ? DateTime.fromJSDate(value) : null}
      format="MM/dd/yyyy"
      onChange={(date: DateTime | null) => onChange?.(date?.toISO() ?? '')}
      data-test-id={testing.toKebabCase(label ?? '') + '-datepicker-value'}
      slotProps={{
        textField: {
          size: 'small',
          required: required,
          fullWidth: fullWidth,
          variant: variant,
          inputProps: inputProps,
          InputLabelProps: { shrink },
          defaultValue: null,
        },
      }}
    />
  );
};

export default DatePickerValue;
