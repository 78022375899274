import {
  Axis,
  ISceneLoaderAsyncResult,
  Matrix,
  Mesh,
  MeshBuilder,
  Plane,
  Ray,
  Scene,
  SceneLoader,
  Tags,
  Vector3,
} from 'babylonjs';
import { evaluateCentroidFromIntercepts } from './landmarkingPointEvaluation';
import { EndPlate, Coordinate, ValidFileExtensions, VertebralBody } from '@workflow-nx/common';
import { AutoCorrectionMesh } from '../shared/enum';
import { AutoCorrectionConfigType } from '../shared/types';
import { STLExport } from 'babylonjs-serializers';

export const createSphere = (position: Vector3, scene: Scene): Mesh => {
  const sphere: Mesh = MeshBuilder.CreateSphere(AutoCorrectionMesh.Sphere, { diameter: 1 }, scene);

  Tags.AddTagsTo(sphere, AutoCorrectionMesh.Sphere);

  sphere.position = position;

  return sphere;
};

export const createRay = (
  directionVector: Vector3,
  position: Vector3,
  config: AutoCorrectionConfigType,
): Ray => {
  return new Ray(position, directionVector, config.BOUNDING_BOX_SIZE);
};

export const renderLSMPlane = (intercepts: Vector3[], normal: Vector3, scene: Scene) => {
  const centroid = evaluateCentroidFromIntercepts(intercepts);

  const plane = Plane.FromPositionAndNormal(centroid, normal);
  const planeMesh = MeshBuilder.CreatePlane(
    AutoCorrectionMesh.Plane,
    { size: 10, sourcePlane: plane },
    scene,
  );
  planeMesh.position = centroid;

  Tags.AddTagsTo(planeMesh, AutoCorrectionMesh.Plane);
};

export const renderTargetCentroid = (scene: Scene, centroid: Vector3): Mesh => {
  const centroidSphere: Mesh = MeshBuilder.CreateSphere(
    AutoCorrectionMesh.Centroid,
    { diameter: 5 },
    scene,
  );

  Tags.AddTagsTo(centroidSphere, AutoCorrectionMesh.Centroid);

  centroidSphere.setAbsolutePosition(centroid);

  return centroidSphere;
};

export const renderPosteriorPoint = (scene: Scene, endplate: EndPlate, position: Vector3): Mesh => {
  const posteriorSphere: Mesh = MeshBuilder.CreateSphere(
    `${AutoCorrectionMesh.EdgePosterior} ${AutoCorrectionMesh.EdgePosterior}-${endplate}`,
    { diameter: 5 },
    scene,
  );

  posteriorSphere.setAbsolutePosition(position);

  return posteriorSphere;
};

export const setPosteriorPointParent = (
  endplate: EndPlate,
  scene: Scene,
  coordinate: Coordinate,
  vertebralBody: VertebralBody,
) => {
  const targetPosition = new Vector3(coordinate.x, coordinate.y, coordinate.z);
  const posteriorSphere = renderPosteriorPoint(scene, endplate, targetPosition);

  Tags.AddTagsTo(
    posteriorSphere,
    `${AutoCorrectionMesh.EdgePosterior}-${vertebralBody}-${endplate}`,
  );

  const [parentVertebralBody]: Mesh[] = scene.getMeshesByTags(vertebralBody);
  posteriorSphere.setParent(parentVertebralBody);
  posteriorSphere.position = targetPosition;
};

export const addBoundingBox = (mesh: Mesh, scene: Scene, config: AutoCorrectionConfigType) => {
  const boundingBox = MeshBuilder.CreateBox(
    AutoCorrectionMesh.BoundingBox,
    {
      height: config.BOUNDING_BOX_SIZE,
      width: config.BOUNDING_BOX_SIZE,
      depth: config.BOUNDING_BOX_SIZE,
    },
    scene,
  );

  Tags.AddTagsTo(boundingBox, AutoCorrectionMesh.BoundingBox);

  boundingBox.position = mesh.getBoundingInfo().boundingBox.centerWorld;
  boundingBox.computeWorldMatrix();
};

export const removeBoundingBox = (scene: Scene) => {
  const [boundingBox]: Mesh[] = scene.getMeshesByTags(AutoCorrectionMesh.BoundingBox);
  boundingBox.dispose();
};

export const flipVertebralBodyCoronal = (mesh: Mesh) => {
  mesh.rotate(Axis.Z, Math.PI);
  mesh.computeWorldMatrix(true);
};

export const setLandMarkParent = (mesh: Mesh, sphere: Mesh) => {
  sphere.computeWorldMatrix();
  const childPosition: Vector3 = sphere.getAbsolutePosition();

  sphere.setParent(mesh);

  const meshWorldMatrix: Matrix = mesh.getWorldMatrix();
  const meshWorldMatrixInverse: Matrix = Matrix.Invert(meshWorldMatrix);
  const localPosition: Vector3 = Vector3.TransformCoordinates(
    childPosition,
    meshWorldMatrixInverse,
  );

  sphere.position = localPosition;
  sphere.computeWorldMatrix();
};

export const renderInterceptSpheres = (scene: Scene, endplatePoints: (Vector3 | null)[][]) => {
  const endplateSpheres: (Mesh | null)[][] = [];

  endplatePoints.forEach((row, i) => {
    if (i % 4 === 0) {
      const pointRow: (Mesh | null)[] = [];
      row.forEach((target, j) => {
        if (target && j % 4 === 0) {
          const sphere = createSphere(target, scene);
          pointRow.push(sphere);
        } else {
          pointRow.push(null);
        }
      });

      endplateSpheres.push(pointRow);
    }
  });

  return endplateSpheres;
};

export const createVertebralBodyPlane = (scene: Scene, mesh: Mesh) => {
  const plane: Mesh = MeshBuilder.CreateBox(
    AutoCorrectionMesh.Plane,
    {
      height: 0.01,
      width: 35,
      depth: 20,
    },
    scene,
  );

  Tags.AddTagsTo(plane, AutoCorrectionMesh.Plane);
  plane.setAbsolutePosition(mesh.getBoundingInfo().boundingBox.center);

  plane.computeWorldMatrix(true);

  return plane;
};

export const createSacrumPlane = (scene: Scene, position: Vector3) => {
  const plane: Mesh = MeshBuilder.CreateBox(
    AutoCorrectionMesh.Plane,
    {
      height: 0.01,
      width: 30,
      depth: 30,
    },
    scene,
  );

  Tags.AddTagsTo(plane, AutoCorrectionMesh.Plane);
  plane.setAbsolutePosition(position);

  plane.translate(Vector3.Down(), 5);
  plane.translate(Vector3.Backward(), 5);

  plane.computeWorldMatrix(true);

  return plane;
};

export const loadVertebralBody = async (
  vertebralBody: VertebralBody,
  scene: Scene,
  singedUrl: string,
): Promise<Mesh> => {
  const { meshes }: ISceneLoaderAsyncResult = await SceneLoader.ImportMeshAsync(
    vertebralBody,
    singedUrl,
    undefined,
    scene,
    undefined,
    ValidFileExtensions.STL,
  );
  const targetMesh: Mesh = meshes[0] as Mesh;

  Tags.AddTagsTo(targetMesh, `${vertebralBody} ${AutoCorrectionMesh.VertebralBody}`);
  targetMesh.name = vertebralBody;
  targetMesh.setPivotPoint(targetMesh.getBoundingInfo().boundingBox.center);

  return targetMesh;
};

export const getMeshBuffer = (asset: string, scene: Scene): Buffer | null => {
  let buffer: Buffer | null = null;

  const [targetMesh]: Mesh[] = scene.getMeshesByTags(asset);

  if (targetMesh) {
    buffer = STLExport.CreateSTL([targetMesh], false, undefined, true);
  }

  return buffer;
};

export const createEndplatePlane = (
  parent: Mesh,
  position: Vector3,
  rotation: Vector3,
  scene: Scene,
  vertebralBody: VertebralBody,
): void => {
  const plane: Mesh = MeshBuilder.CreateBox(
    `plane-${vertebralBody}`,
    {
      height: 0.01,
      width: 100,
      depth: 100,
    },
    scene,
  );

  Tags.AddTagsTo(plane, `plane-${vertebralBody} ${AutoCorrectionMesh.Plane}`);

  plane.setParent(parent);
  plane.setAbsolutePosition(position);
  plane.rotation = rotation;
};
