import { Box, Card, CardContent, Grid } from '@mui/material';
import React, { useReducer, useState } from 'react';
import { useQuery } from '@apollo/client';
import { ICase, Permission } from '@workflow-nx/common';
import { FIND_TASKS } from '../../../../gql';
import { EditTaskStatusDialog } from '../../../shared/EditTaskStatusDialog';
import { TaskListTable } from './TaskListTable';
import { Heading } from '../../../../components/Heading';
import { Alert } from '@mui/material';
import { TaskTabReducer, TaskTabStateType } from './TaskTab.reducer';
import { Button } from '@workflow-nx/ui';
import { CreateTaskDialog } from './CreateTaskDialog';
import useAuth from '../../../../hooks/useAuth';

type TaskTabViewProps = {
  activeCase: ICase;
};

export function TaskTabView({ activeCase }: TaskTabViewProps) {
  const auth = useAuth();
  const [openTaskStatusEditDialog, setOpenTaskStatusEditDialog] = useState(false);
  const [showCreateTaskDialog, setShowCreateTaskDialog] = useState(false);

  const [taskId, setTaskId] = useState<number | undefined>();
  const {
    loading: loadingTasks,
    data,
    refetch,
  } = useQuery(FIND_TASKS, {
    variables: {
      caseId: activeCase.caseId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const initialState: TaskTabStateType = {
    orderBy: { createdAt: 'desc' },
  };

  const [state, dispatch] = useReducer(TaskTabReducer(refetch), initialState);
  const canCreateTask = auth?.hasPermission?.([Permission.ManageTask]);
  const tasksFound =
    data?.tasks?.tasks?.length === 1 ? `1 task` : `${data?.tasks?.tasks?.length ?? 0} tasks`;

  return (
    <Box>
      <Grid container>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mb={1}>
                <Heading label={`Results (${tasksFound})`} />
                <Box
                  display={'flex'}
                  width={200}
                  justifyContent={'flex-end'}
                  alignItems={'center'}
                  mb={1}
                >
                  {canCreateTask ? (
                    <Button
                      label={'Create Task'}
                      variant={'contained'}
                      color={'secondary'}
                      onClick={() => setShowCreateTaskDialog(true)}
                    />
                  ) : null}
                </Box>
              </Box>
              <Box>
                <Card>
                  <CardContent style={{ height: 'calc(100vh - 228px)' }}>
                    {data?.tasks?.tasks?.length > 0 ? (
                      <TaskListTable
                        dispatch={dispatch}
                        orderBy={state.orderBy}
                        loading={loadingTasks}
                        tasks={data?.tasks?.tasks ?? []}
                        onView={(taskId: number) => {
                          if (auth?.hasPermission?.([Permission.ViewTask])) {
                            setTaskId(taskId);
                            setOpenTaskStatusEditDialog(true);
                          }
                        }}
                      />
                    ) : (
                      <Alert severity={'info'}>There are no tasks on this case </Alert>
                    )}
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <EditTaskStatusDialog
        taskId={taskId}
        open={openTaskStatusEditDialog}
        onClose={() => {
          setOpenTaskStatusEditDialog(false);
          setTaskId(undefined);
          refetch();
        }}
      />
      <CreateTaskDialog
        open={showCreateTaskDialog}
        activeCase={activeCase}
        onCreate={() => {
          setShowCreateTaskDialog(false);
          refetch();
        }}
        onClose={() => {
          setShowCreateTaskDialog(false);
          refetch();
        }}
      />
    </Box>
  );
}
