import { Box } from '@mui/material';
import {
  AssetPositions,
  CaseSpineProfile,
  CaseSpineType,
  IPatientRecord,
  LevelType,
  MeasurementsVersionType,
  TableType,
} from '@workflow-nx/common';
import { MeasurementsView } from '@workflow-nx/ui';
import config from '../../../../../extras/config';
import { getMeasurementsFromAssetPositions } from './utils/implantEditor';

export function ImplantEditorOverlayView(props: {
  assetPositions: AssetPositions;
  caseLevels: string[];
  caseSpineProfile: CaseSpineProfile;
  caseSpineType: CaseSpineType;
  measurementsVersion: MeasurementsVersionType;
  patientRecord: IPatientRecord;
}) {
  const preOpMeasurements = getMeasurementsFromAssetPositions(props.assetPositions.preop.points);
  const planMeasurements = getMeasurementsFromAssetPositions(props.assetPositions.plan.points);

  return (
    <Box position={'absolute'} top={10} right={10} zIndex={9998}>
      <Box p={1} borderRadius="5px" style={{ backgroundColor: 'rgba(255,255,255,0.5)' }}>
        <MeasurementsView
          spineType={props.caseSpineType}
          caseSpineProfile={props.caseSpineProfile}
          caseLevels={props.caseLevels as LevelType[]}
          disableToggle={false}
          pelvicIncidence={props.patientRecord.pelvicIncidence ?? 0}
          slopeOfLineOfSight={props.patientRecord.slopeOfLineOfSight ?? 0}
          plan={planMeasurements}
          preop={preOpMeasurements}
          measurementsVersion={props.measurementsVersion}
          tableType={TableType.Plan}
          size={'small'}
          showErrors={true}
          defaultExpanded={false}
          measurementConfig={config.measurementsConfig}
        />
      </Box>
    </Box>
  );
}
