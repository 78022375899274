import { useEffect } from 'react';

export function useKeyPress(callback: (event: KeyboardEvent) => void, keyCodes: string[]): void {
  const handler = (event: KeyboardEvent) => {
    if (keyCodes.includes(event.code)) {
      callback(event);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handler);
    return () => {
      window.removeEventListener('keydown', handler);
    };
  });
}
