import { format, IUser } from '@workflow-nx/common';
import { Box, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { FormattedFieldView } from '../../../components/FormattedFieldView';
import { Button } from '@workflow-nx/ui';

export function UserDetailsView({
  user,
  onEdit,
  canEdit,
}: {
  user: IUser;
  onEdit: () => void;
  canEdit: boolean;
}) {
  return (
    <>
      <Stack flexDirection={'row'} justifyContent={'space-between'}>
        <Typography variant={'h4'}>User Details</Typography>
        {canEdit ? (
          <Box display={'flex'} justifyContent={'flex-end'}>
            <Button onClick={() => onEdit()} variant={'contained'} label={'Edit'} />
          </Box>
        ) : null}
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <FormattedFieldView
            label={'Full Name'}
            value={
              <Box>
                <Typography variant={'body1'}>
                  {user ? format.formatName(user) : <span>&mdash;</span>}
                </Typography>
              </Box>
            }
          />
        </Grid>
        <Grid item xs={4}>
          <FormattedFieldView label={'Email'} value={user?.email} />
        </Grid>
        <Grid item xs={4}>
          <FormattedFieldView label={'Phone'} value={format.formatPhoneNumber(user?.phone)} />
        </Grid>
        <Grid item xs={4}>
          <FormattedFieldView label={'Status'} value={format.formatUserStatus(user?.status)} />
        </Grid>
        <Grid item xs={4}>
          <FormattedFieldView label={'Institution'} value={user?.organization?.name} />
        </Grid>
        <Grid item xs={4}>
          <FormattedFieldView label={'Location'} value={user?.location?.description} />
        </Grid>
        <Grid item xs={4}>
          <FormattedFieldView
            label={'Region'}
            value={user?.userRegionTerritories?.[0]?.region?.name}
          />
        </Grid>
        <Grid item xs={4}>
          <FormattedFieldView
            label={'Territory'}
            value={user?.userRegionTerritories?.[0]?.territory?.name}
          />
        </Grid>
      </Grid>
    </>
  );
}
