import { useLazyQuery, useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import {
  EventType,
  ICase,
  IEvent,
  IOrganization,
  OrganizationType,
  VendorTaskCategory,
  format,
} from '@workflow-nx/common';
import { InputConfigRHF, InputRHF, InputTypeRHF, ProgressButton } from '@workflow-nx/ui';
import { editVendorAssignmentDialogSchema } from 'apps/workflow-client/src/app/extras/schemas';
import { useSnackbar } from 'notistack';
import { ComponentProps, useEffect, useState } from 'react';
import { Control, FieldValues, Resolver, SubmitHandler, useForm } from 'react-hook-form';
import CustomDialog from '../../../../components/CustomDialog';
import { FIND_ORGANIZATIONS, UPDATE_CASE } from '../../../../gql';

const updateVendorAssignmentsFormValues = (activeCase: ICase): EditVendorAssignmentsFormType => {
  let additivePrintingVendor: number | null = null;
  let finalShipmentVendor: number | null = null;

  activeCase.vendorAssignments?.forEach((va) => {
    switch (va.taskCategory) {
      case VendorTaskCategory.AdditivePrinting:
        additivePrintingVendor = va.organizationId;
        break;
      case VendorTaskCategory.FinalShipment:
        finalShipmentVendor = va.organizationId;
        break;
    }
  });

  return {
    additivePrintingVendor,
    finalShipmentVendor,
  };
};

export interface EditVendorAssignmentsFormType extends FieldValues {
  additivePrintingVendor?: number | null;
  finalShipmentVendor?: number | null;
}

export function EditVendorAssignmentsDialog(props: {
  activeCase: ICase;
  events?: IEvent[];
  onClose: (shouldUpdate: boolean, eventType?: EventType) => void;
  open: boolean;
}) {
  const [updateCase, { loading: updatingCase }] = useMutation(UPDATE_CASE);

  const { enqueueSnackbar } = useSnackbar();

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: updateVendorAssignmentsFormValues(props.activeCase),
    resolver: yupResolver(
      editVendorAssignmentDialogSchema,
    ) as unknown as Resolver<EditVendorAssignmentsFormType>,
  });

  const [vendorMenuItems, setVendorMenuItems] = useState<{ key: string; value: string }[]>([]);

  const [findVendors] = useLazyQuery(FIND_ORGANIZATIONS, {
    fetchPolicy: 'network-only',
    variables: { organizationTypeFilter: [OrganizationType.Vendor] },
    onCompleted: (orgData) => {
      const organizations = orgData.organizations.organizations || [];

      const vendorOptions: Array<{ key: string; value: string }> = organizations.map(
        (org: IOrganization) => ({
          key: org.organizationId as unknown as string,
          value: org.name,
        }),
      );

      setVendorMenuItems(vendorOptions);
    },
  });

  useEffect(() => {
    findVendors({});
  }, []);

  const handleSubmitForm: SubmitHandler<EditVendorAssignmentsFormType> = async (data) => {
    try {
      await updateCase({
        variables: {
          caseId: props.activeCase.caseId,
          additivePrintingVendor: data.additivePrintingVendor ? data.additivePrintingVendor : null,
          finalShipmentVendor: data.finalShipmentVendor ? data.finalShipmentVendor : null,
        },
      });

      enqueueSnackbar('Vendor assignments updated', {
        variant: 'success',
      });

      props.onClose(true);
    } catch (err: any) {
      console.error(err);

      enqueueSnackbar('An error occurred updated vendor assignments', {
        variant: 'error',
      });
    } finally {
      reset();
    }
  };

  const loading = updatingCase;

  const formItems: InputConfigRHF[] = [
    {
      id: 'additivePrintingVendor',
      label: format.formatVendorTaskCategory(VendorTaskCategory.AdditivePrinting),
      input: InputTypeRHF.Select,
      menuItems: vendorMenuItems,
    },

    {
      id: 'finalShipmentVendor',
      label: format.formatVendorTaskCategory(VendorTaskCategory.FinalShipment),
      input: InputTypeRHF.Select,
      menuItems: vendorMenuItems,
    },
  ];

  const getInputProps = (config: InputConfigRHF): ComponentProps<typeof InputRHF> => ({
    config: config,
    control: control as Control<FieldValues>,
    disabled: isSubmitting || loading,
    selectFieldProps: {
      hideNone: false,
    },
  });

  return (
    <CustomDialog
      maxWidth={'md'}
      open={props.open}
      title={`Edit Vendor Assignments`}
      onClose={() => {
        reset();
        props.onClose(false);
      }}
      positiveActionButtons={[
        <ProgressButton
          variant={'contained'}
          disabled={isSubmitting || loading}
          onClick={(evt) => handleSubmit(handleSubmitForm)(evt)}
          label={'Save'}
          loading={isSubmitting || loading}
        />,
      ]}
    >
      <Grid container spacing={3}>
        {formItems.map((config) => {
          return <InputRHF key={config.id} {...getInputProps(config)} />;
        })}
      </Grid>
    </CustomDialog>
  );
}
