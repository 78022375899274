import {
  format,
  ILocation,
  IOrganization,
  IRegion,
  ITerritory,
  IUser,
  UserRoleType,
  UserStatusType,
} from '@workflow-nx/common';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  FIND_LOCATIONS,
  FIND_ORGANIZATIONS,
  FIND_REGIONS,
  FIND_USERS_BY_ROLE,
  UPDATE_USER,
} from '../../../gql';
import { Grid, SelectChangeEvent, Typography } from '@mui/material';
import CustomDialog from '../../../components/CustomDialog';
import {
  ErrorMessageRHF,
  InputTypeRHF,
  PhoneNumberField,
  ProgressButton,
  SelectFieldRHF,
  TextFieldRHF,
} from '@workflow-nx/ui';
import * as Yup from 'yup';
import { Control, FieldValues, Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { isPossiblePhoneNumber, parsePhoneNumber } from 'libphonenumber-js';

interface IUserDialogFormValues {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  status: string;
  reportsToId: string;
  regionId: string;
  territoryId: string;
  organizationId: string;
  locationId: string;
}

function getReportsToUserRole(role: UserRoleType): UserRoleType | undefined {
  switch (role) {
    case UserRoleType.Surgeon:
      return UserRoleType.SalesRep;
    case UserRoleType.CustomerRep:
      return UserRoleType.CustomerRepManager;
    case UserRoleType.FieldRep:
      return UserRoleType.FieldRepManager;
    default:
      return undefined;
  }
}

export function EditUserDetailsDialog({
  user,
  open,
  onClose,
}: {
  open: boolean;
  user: IUser;
  onClose: (shouldUpdate: boolean) => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [updateUser] = useMutation(UPDATE_USER);

  useQuery(FIND_ORGANIZATIONS, {
    variables: {
      orderBy: { name: 'asc' },
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const organizations = data.organizations.organizations || [];
      setOrganizations(
        organizations.map((org: IOrganization) => {
          return { key: org.organizationId, value: org.name };
        }),
      );
    },
  });

  useQuery(FIND_USERS_BY_ROLE, {
    fetchPolicy: 'network-only',
    variables: {
      roleFilter: [getReportsToUserRole(user?.role)],
    },
    onCompleted: (data) => {
      const reportsToUsers = data?.users?.users || [];
      setReportsToUsers(
        reportsToUsers.map((user: IUser) => {
          return { key: user.userId, value: format.formatName(user) };
        }),
      );
    },
  });

  const [findLocations] = useLazyQuery(FIND_LOCATIONS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const locations = data.locations || [];
      setLocations(
        locations.map((location: ILocation) => {
          return { key: location.locationId, value: location.description };
        }),
      );
    },
  });

  const [reportsToUsers, setReportsToUsers] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [locations, setLocations] = useState([]);
  const statuses = [
    { key: UserStatusType.Active, value: format.formatUserStatus(UserStatusType.Active) },
    { key: UserStatusType.Inactive, value: format.formatUserStatus(UserStatusType.Inactive) },
    { key: UserStatusType.Pending, value: format.formatUserStatus(UserStatusType.Pending) },
  ];
  const [regions, setRegions] = useState<
    {
      key: string;
      value: string;
      territories: any[];
    }[]
  >([]);
  const [territories, setTerritories] = useState<
    {
      key: string;
      value: string;
    }[]
  >([]);

  useQuery(FIND_REGIONS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const regions = data.regions || [];

      setRegions(
        regions.map((region: IRegion) => {
          const territories = region?.territories?.map((territory: ITerritory) => {
            return { key: territory.territoryId, value: territory.name };
          });

          return { key: region.regionId, value: region.name, territories: territories ?? [] };
        }),
      );
    },
  });

  const handleLocationChange = (event: SelectChangeEvent<any>): void => {
    const organizationId = event.target.value;
    findLocations({
      variables: { organizationId: organizationId },
    });
  };

  const handleRegionChange = (event: SelectChangeEvent<any>): void => {
    const regionId = event.target.value;
    const region = regions.find((region) => region.key === regionId);

    setTerritories(region?.territories || []);
  };

  const userDialogSchema = Yup.object().shape({
    organizationId: Yup.number().required('Institution is required'),
    locationId: Yup.number().required('Location is required'),
    email: Yup.string()
      .email('Must be a valid email')
      .max(255)
      .required('Email is required')
      .matches(
        /^[+\w-]+(\.[\w-]+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/,
        'Must be a valid email',
      ),
    status: Yup.string().required('Status is required'),
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    phone: Yup.string()
      .nullable()
      .test('phone', 'Invalid phone number', function (value) {
        if (!value || value.trim() === '' || /^\+\d*$/.test(value.trim())) {
          return true; // null values, empty strings, or just country codes
        } else {
          return isPossiblePhoneNumber(value);
        }
      }),
  });

  const formRowTextData = [
    {
      id: 'firstName',
      label: 'First Name',
      input: InputTypeRHF.Text,
    },
    {
      id: 'lastName',
      label: 'Last Name',
      input: InputTypeRHF.Text,
    },
    {
      id: 'email',
      label: 'Email',
      input: InputTypeRHF.Text,
    },
    {
      id: 'phone',
      label: 'Phone Number',
      input: InputTypeRHF.Phone,
    },
    {
      id: 'status',
      label: 'Status',
      input: InputTypeRHF.Select,
      menuItems: statuses,
    },
    {
      id: 'reportsToId',
      label: user?.role === UserRoleType.Surgeon ? 'Sales Agent' : 'Reports To',
      input: InputTypeRHF.Select,
      menuItems: reportsToUsers,
      limitation:
        user?.role === UserRoleType.FieldRep ||
        user?.role === UserRoleType.CustomerRep ||
        user?.role === UserRoleType.Surgeon,
      disabled: reportsToUsers.length === 0,
    },
    {
      id: 'regionId',
      label: 'Regions',
      input: InputTypeRHF.Select,
      menuItems: regions,
      limitation: user?.role === UserRoleType.FieldRep || user?.role === UserRoleType.CustomerRep,
      onChange: handleRegionChange,
    },
    {
      id: 'territoryId',
      label: 'Territory',
      input: InputTypeRHF.Select,
      menuItems: territories,
      limitation: user?.role === UserRoleType.FieldRep || user?.role === UserRoleType.CustomerRep,
    },
    {
      id: 'organizationId',
      label: 'Institution',
      input: InputTypeRHF.Select,
      menuItems: organizations,
      onChange: handleLocationChange,
    },
    {
      id: 'locationId',
      label: 'Location',
      input: InputTypeRHF.Select,
      menuItems: locations,
    },
  ];

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm<IUserDialogFormValues>({
    defaultValues: {
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
      email: user?.email ?? '',
      phone: user?.phone ?? '',
      status: user?.status ?? '',
      reportsToId: user?.reportsToUser?.userId?.toString() ?? '',
      organizationId: user?.organization?.organizationId?.toString() ?? '',
      locationId: user?.location?.locationId?.toString() ?? '',
      regionId: user?.userRegionTerritories?.[0]?.region?.regionId?.toString() ?? '',
      territoryId: user?.userRegionTerritories?.[0]?.territory?.territoryId?.toString() ?? '',
    },
    resolver: yupResolver(userDialogSchema) as unknown as Resolver<IUserDialogFormValues>,
  });

  const handleSubmitForm: SubmitHandler<IUserDialogFormValues> = async (values) => {
    try {
      let userRegionTerritories: { regionId: number; territoryId: any; userId: number }[] = [];
      if (values.regionId) {
        userRegionTerritories = [
          {
            userId: user?.userId,
            regionId: Number(values.regionId),
            territoryId: Number(values.territoryId) || undefined,
          },
        ];
      }

      const formattedPhone =
        !values?.phone || values.phone.trim() === '' || /^\+\d*$/.test(values.phone.trim())
          ? undefined
          : parsePhoneNumber(values.phone).number;

      await updateUser({
        variables: {
          userId: user?.userId,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phone: formattedPhone ?? null,
          status: values.status ?? user.status,
          reportsToId: values.reportsToId ? Number(values.reportsToId) : undefined,
          organizationId: values.organizationId ? Number(values.organizationId) : undefined,
          locationId: values.locationId ? Number(values.locationId) : undefined,
          userRegionTerritories,
        },
      });

      enqueueSnackbar('User Updated', {
        variant: 'success',
      });

      reset();
      onClose(true);
    } catch (err) {
      console.error(err);
      enqueueSnackbar('An error occurred updating the user', {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    if (user?.organization?.organizationId) {
      findLocations({
        variables: { organizationId: user?.organization?.organizationId },
      });
    }

    if (user) {
      const regionId = user?.userRegionTerritories?.[0]?.region?.regionId?.toString() ?? '';

      const region = regions.find((region) => region.key.toString() === regionId);
      setTerritories(region?.territories || []);
    }
  }, [user, findLocations, regions]);

  return (
    <CustomDialog
      maxWidth={'lg'}
      open={open}
      title={`Edit User - ${format.formatName(user)}`}
      onClose={() => {
        reset();
        onClose(false);
      }}
      positiveActionButtons={[
        <ProgressButton
          onClick={(evt) => handleSubmit(handleSubmitForm)(evt)}
          loading={isSubmitting}
          disabled={isSubmitting}
          label={'Save'}
        />,
      ]}
      negativeActionButtons={[]}
    >
      {open ? (
        <form>
          <Grid container spacing={3}>
            {formRowTextData.map((config, index) => {
              return (config?.limitation !== undefined && config?.limitation) ||
                config?.limitation === undefined ? (
                <Grid item container xs={12} spacing={1} alignItems={'center'} key={index}>
                  <Grid item xs={3} md={3} display={'flex'} alignItems={'center'}>
                    <Typography variant={'body1'}>{config.label}</Typography>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    {config.input === InputTypeRHF.Text ? (
                      <TextFieldRHF
                        name={config.id}
                        control={control as unknown as Control<FieldValues>}
                        disabled={isSubmitting || config?.disabled}
                      />
                    ) : config.input === InputTypeRHF.Select ? (
                      <SelectFieldRHF
                        name={config.id}
                        menuItems={config.menuItems ?? []}
                        control={control as unknown as Control<FieldValues>}
                        disabled={isSubmitting || config?.disabled}
                        onChange={config?.onChange}
                      />
                    ) : config.input === InputTypeRHF.Phone ? (
                      <PhoneNumberField
                        name={config.id}
                        control={control as unknown as Control<FieldValues>}
                        disabled={isSubmitting || config?.disabled}
                      />
                    ) : null}
                  </Grid>
                  <Grid item xs={3} md={3} display={'flex'} justifyContent={'center'}>
                    <ErrorMessageRHF
                      name={config.id}
                      control={control as unknown as Control<FieldValues>}
                    />
                  </Grid>
                </Grid>
              ) : null;
            })}
          </Grid>
        </form>
      ) : null}
    </CustomDialog>
  );
}
