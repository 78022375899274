import {
  TableCell as MatTableCell,
  TableSortLabel as MatTableSortLabel,
  Theme,
  Typography,
} from '@mui/material';
import React, { FunctionComponent, ReactNode } from 'react';
import { makeStyles } from '@mui/styles';

type TableCellProps = {
  id?: string;
  sortable?: boolean;
  orderBy?: { [key: string]: 'asc' | 'desc' };
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  width?: string | number;
  children?: ReactNode;
  onClick?: (orderBy: { [key: string]: 'asc' | 'desc' }) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    ...theme.typography.body1,
    fontWeight: 'normal',
    paddingBottom: 0,
    color: theme.palette.grey['600'],
  },
}));

export const TableHeadCell: FunctionComponent<TableCellProps> = (props) => {
  const styles = useStyles();

  const orderByKey = props.sortable && props.orderBy ? Object.keys(props?.orderBy)[0] : '';
  const orderByValue: 'asc' | 'desc' =
    props.sortable && props.orderBy ? props?.orderBy[orderByKey] : 'desc';

  return (
    <MatTableCell
      sortDirection={orderByKey === props?.id ? orderByValue : false}
      align={props.align}
      className={styles.root}
      width={props.width}
    >
      {props.sortable ? (
        <MatTableSortLabel
          active={orderByKey === props?.id}
          direction={orderByKey === props?.id ? orderByValue : 'asc'}
          onClick={() => {
            if (props.onClick) {
              props.onClick({ [props.id as string]: orderByValue === 'asc' ? 'desc' : 'asc' });
            }
          }}
        >
          <Typography component={'span'} noWrap={true}>
            {props.children}
          </Typography>
        </MatTableSortLabel>
      ) : (
        props.children
      )}
    </MatTableCell>
  );
};
