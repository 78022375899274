import { useMutation } from '@apollo/client';
import { Box, LinearProgress, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IAsset } from '@workflow-nx/common';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { CREATE_ASSET_DOWNLOAD_URL } from '../gql';
import CustomDialog from './CustomDialog';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 5,
    '& img': {
      width: '100%',
      height: 400,
      background: 'transparent',
      objectFit: 'contain',
    },
  },
}));

const getPDFDataUri = async (url?: string | null): Promise<string | null> => {
  const base64 = await getBase64Data(url);

  if (!base64) return null;

  return convertBase64ToDataUri(base64);
};

const convertBase64ToDataUri = (base64: string) => {
  return `data:application/pdf;base64,${base64}`;
};

const getBase64Data = async (url?: string | null) => {
  if (!url) return null;

  try {
    const response = await axios.get(url, {
      responseType: 'arraybuffer',
    });

    const base64 = btoa(String.fromCharCode(...new Uint8Array(response.data)));

    return base64;
  } catch (e) {
    console.error(`Error encoding data at ${url} in getBase64Data`, { e });
  }

  return null;
};

export function PDFViewDialog(props: { open: boolean; asset?: IAsset; onClose: () => void }) {
  const styles = useStyles();
  const [createAssetDownloadUrl] = useMutation(CREATE_ASSET_DOWNLOAD_URL);
  const [loading, setLoading] = useState<boolean>(false);
  const [dataUri, setDataUri] = useState<string>();

  const handleLoad = useCallback(
    async (asset: IAsset) => {
      try {
        setLoading(true);

        const { data } = await createAssetDownloadUrl({
          variables: {
            assetId: asset.assetId,
          },
        });

        const signedUrl = data.createAssetDownloadUrl.signedUrl;

        const dataUri = await getPDFDataUri(signedUrl);

        setDataUri(dataUri ?? signedUrl);
      } finally {
        setLoading(false);
      }
    },
    [createAssetDownloadUrl],
  );

  useEffect(() => {
    if (props.open && props.asset) {
      handleLoad(props.asset);
    }
  }, [props.asset, props.open, handleLoad]);

  return (
    <CustomDialog
      maxWidth={'sm'}
      open={props.open}
      title={`Viewing Asset ${props?.asset?.assetType}`}
      onClose={() => {
        props.onClose();
      }}
      positiveActionButtons={[]}
    >
      {props.open && dataUri ? (
        <>
          <Box
            className={styles.root}
            display={loading ? 'none' : 'flex'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            {<iframe src={dataUri} width={'100%'} height={800}></iframe>}
          </Box>
          {loading ? (
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={400}>
              <Box width={'100%'} textAlign={'center'}>
                <Typography variant={'body1'}>
                  Loading {1} of {1}
                </Typography>
                <LinearProgress variant={'indeterminate'} />
              </Box>
            </Box>
          ) : null}
        </>
      ) : null}
    </CustomDialog>
  );
}
