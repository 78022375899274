import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { FIND_REPORT_REVENUE_FORECAST } from '../../gql';
import { DashboardCardView } from './DashboardCardView';
import { Box, Typography, Divider } from '@mui/material';
import { Select } from '../../components/Select';
import { IProceduresRevenue } from '@workflow-nx/common';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from 'recharts';

const ForcastReport = () => {
  const [reportYear, setReportYear] = useState<string>(new Date().getFullYear().toString());
  const [yearsType, setYearsType] = useState<{ key: string; value: string }[]>([]);
  const [revenueByMonth, setRevenueByMonth] = useState<IProceduresRevenue[]>([]);
  const [proceduresByMonth, setProceduresByMonth] = useState<IProceduresRevenue[]>([]);
  const [backlogProceduresTotal, setBacklogProceduresTotal] = useState<number>(0);
  const [backlogLevelsTotal, setBacklogLevelsTotal] = useState<number>(0);
  const [backlogRevenueTotal, setBacklogRevenueTotal] = useState<number>(0);

  const { refetch, loading } = useQuery(FIND_REPORT_REVENUE_FORECAST, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      year: Number(reportYear),
    },
    onCompleted: (data) => {
      const revenueReportData = data?.reportRevenueForecast;
      const backlogProcedures: number = revenueReportData?.backlogProcedures || 0;
      const backlogLevels: number = revenueReportData?.backlogLevels || 0;
      const backlogRevenue: number = revenueReportData?.backlogRevenue || 0;
      const proceduresByMonthData: IProceduresRevenue[] =
        revenueReportData?.proceduresByMonth || [];
      const revenueByMonthData: IProceduresRevenue[] = revenueReportData?.revenueByMonth || [];

      setBacklogProceduresTotal(backlogProcedures);
      setBacklogLevelsTotal(backlogLevels);
      setBacklogRevenueTotal(backlogRevenue);

      setRevenueByMonth(
        revenueByMonthData.map((element) => {
          const updatedElement = { ...element };
          updatedElement.month = getMonthName(Number(element.month));

          return updatedElement;
        }),
      );

      setProceduresByMonth(
        proceduresByMonthData.map((element) => {
          const updatedElement = { ...element };
          updatedElement.month = getMonthName(Number(element.month));

          return updatedElement;
        }),
      );
    },
  });

  const rechartFont = {
    fontSize: '13px',
    fontFamily: 'Arial, Roboto, Helvetica, sans-serif',
    fontStyle: 'normal',
  };

  const currencyFormatter = (value: any) => {
    const dollarUS = Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    });

    return dollarUS.format(value);
  };

  const revenueAccessor = (entry: any) => {
    return entry ? currencyFormatter(entry.actualRevenue + entry.scheduledRevenue) : 0;
  };

  const proceduresAccessor = (entry: any) => {
    return entry ? entry.actualProcedures + entry.scheduledProcedures : 0;
  };

  const levelsAccessor = (entry: any) => {
    return entry ? entry.actualLevels + entry.scheduledLevels : 0;
  };

  function getMonthName(monthNumber: number): string {
    const date = new Date(Number(reportYear), monthNumber - 1, 1);

    return date
      .toLocaleString('en-US', {
        month: 'short',
      })
      .toUpperCase();
  }

  const getYears = () => {
    const years = [];
    const startYear = 2021;
    const endYear = new Date().getFullYear() + 2;

    for (let i = startYear; i <= endYear; i++) {
      years.push({ key: i.toString(), value: i.toString() });
    }

    return years;
  };

  useEffect(() => {
    setYearsType(getYears());
  }, []);

  return (
    <DashboardCardView label={'Forecast Report'} fitContentHeight={true}>
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'} mb={2}>
        <Typography variant={'h4'}>Backlog</Typography>
        <Box ml={4} mr={2}>
          <Typography variant={'body1'}>
            <strong>Revenue</strong>
          </Typography>
          <Typography variant={'body1'}>{currencyFormatter(backlogRevenueTotal)}</Typography>
        </Box>
        <Box mr={2}>
          <Typography variant={'body1'}>
            <strong>Procedures</strong>
          </Typography>
          <Typography variant={'body1'}>{backlogProceduresTotal}</Typography>
        </Box>
        <Box>
          <Typography variant={'body1'}>
            <strong>Levels</strong>
          </Typography>
          <Typography variant={'body1'}>{backlogLevelsTotal}</Typography>
        </Box>
      </Box>
      <Divider />
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} mt={2}>
        <Box width={150}>
          <Select
            name={'year'}
            label={'Year'}
            value={reportYear}
            onChange={(event) => {
              const year = event.target.value;
              setReportYear(year);
              refetch({
                year: Number(year),
              });
            }}
            menuItems={yearsType}
            disabled={loading}
            fullWidth={true}
            hideNone={true}
          />
        </Box>
      </Box>
      <ResponsiveContainer width="100%" aspect={5.0 / 1.0}>
        <BarChart
          data={revenueByMonth}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          style={rechartFont}
        >
          <CartesianGrid strokeDasharray="0" vertical={false} />
          <XAxis dataKey="month" />
          <YAxis tickFormatter={currencyFormatter} />
          <Tooltip formatter={currencyFormatter} />
          <Legend />
          <Bar
            name="Actual Revenue"
            dataKey="actualRevenue"
            stackId="a"
            fill="#82ca9d"
            barSize={20}
          />
          <Bar
            name="Scheduled Revenue"
            dataKey="scheduledRevenue"
            stackId="a"
            fill="#8884d8"
            barSize={20}
          >
            <LabelList position="top" valueAccessor={revenueAccessor} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      <ResponsiveContainer width="100%" aspect={5.0 / 1.0}>
        <BarChart
          data={proceduresByMonth}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          style={rechartFont}
        >
          <CartesianGrid strokeDasharray="0" vertical={false} />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar
            name="Actual Procedures"
            dataKey="actualProcedures"
            stackId="a"
            fill="#82ca9d"
            barSize={20}
          />
          <Bar
            name="Actual Levels"
            dataKey="actualLevels"
            stackId="b"
            fill="#1696C4"
            barSize={20}
          />
          <Bar
            name="Scheduled Procedures"
            dataKey="scheduledProcedures"
            stackId="a"
            fill="#8884d8"
            barSize={20}
          >
            <LabelList position="top" valueAccessor={proceduresAccessor} />
          </Bar>
          <Bar
            name="Scheduled Levels"
            dataKey="scheduledLevels"
            stackId="b"
            fill="#ff9e43"
            barSize={20}
          >
            <LabelList position="top" valueAccessor={levelsAccessor} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </DashboardCardView>
  );
};
export default ForcastReport;
