import { useApolloClient } from '@apollo/client';
import {
  IAsset,
  ICaseResult,
  IExportPostOpCase,
  IMeasurementPointValues,
  IPlan,
  LEVEL_CONFIG_MAP,
  LevelResult,
  LevelType,
  PartType,
  VertebralBody,
  caseUtils,
  dataExport,
  format,
  measurements,
} from '@workflow-nx/common';
import { date } from '@workflow-nx/utils';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import ActionButton from '../../../components/ActionButton';
import { FIND_POST_OP_EXPORT_CASES } from '../../../gql';

const MAX_SEGMENTAL_DEVIATION = 5;
const MAX_OVERALL_DEVIATION = 10;
const LONG_FUSION_MIN_LEVELS = 3;
const VALID_POST_OP_IMAGING_AGE_IN_WEEKS = 6;
const BLANK_CHARACTER = '-';

function convertNumber(numberToConvert: any, allowNull = false) {
  return !allowNull && (numberToConvert === null || numberToConvert === undefined)
    ? NaN
    : Number(numberToConvert);
}

const allLevels = [
  LevelType.L1L2,
  LevelType.L2L3,
  LevelType.L3L4,
  LevelType.L4L5,
  LevelType.L5S1,
  LevelType.L5L6,
  LevelType.L6S1,
  LevelType.L4S1,
];

const POST_OP_HEADER_LABEL = {
  ID: 'ID',
  Tags: 'Tags',
  CaseNumber: 'Case Number',
  HasImaging: 'Has Imaging?',
  HasValidImaging: 'Has Post-Op DICOM imaging?',
  ImagingDate: 'Post-Op Imaging Date',
  Surgeon: 'Surgeon',
  Institution: 'Institution',
  SurgeryDate: 'Surgery Date',
  PatientAge: 'Patient Age',
  PatientGender: 'Patient Gender',
  NumLevelsPlanned: '# of Levels Planned',
  NumLevelsCompleted: '# of Levels Completed',
  C2_C3: format.formatLevelType(LevelType.C2C3),
  C3_C4: format.formatLevelType(LevelType.C3C4),
  C4_C5: format.formatLevelType(LevelType.C4C5),
  C5_C6: format.formatLevelType(LevelType.C5C6),
  C6_C7: format.formatLevelType(LevelType.C6C7),
  C7_C8: format.formatLevelType(LevelType.C7C8),
  C7_T1: format.formatLevelType(LevelType.C7T1),
  C6_T1: format.formatLevelType(LevelType.C6T1),
  C8_T1: format.formatLevelType(LevelType.C8T1),
  L1_L2: format.formatLevelType(LevelType.L1L2),
  L2_L3: format.formatLevelType(LevelType.L2L3),
  L3_L4: format.formatLevelType(LevelType.L3L4),
  L4_L5: format.formatLevelType(LevelType.L4L5),
  L5_S1: format.formatLevelType(LevelType.L5S1),
  L4_S1: format.formatLevelType(LevelType.L4S1),
  L5_L6: format.formatLevelType(LevelType.L5L6),
  L6_S1: format.formatLevelType(LevelType.L6S1),
  PartTypes: 'Part Types',
  ImplantLevels: 'Implant Levels',
  FusionType: 'Fusion Type',
  LevelsFused: 'Levels Fused',
  NumLevelsFused: '# of Levels Fused',
  PelvicIncidence: 'Pelvic Incidence',
  Level: 'Level',
  Implant: 'Implant',
  ImplantResult: 'Implant Result',
  ImplantSize: 'Implant Size',
  ImplantReason: 'Implant Reason',
  PreOpLordoticAngle: 'Pre-Op: Lordosis Angle',
  PreOpCoronalAngle: 'Pre-Op: Coronal Angle',
  PreOpPosteriorHeight: 'Pre-Op: Posterior Height (mm)',
  PreOpAnteriorHeight: 'Pre-Op: Anterior Height (mm)',
  PlanLordoticAngle: 'Plan: Lordosis Angle',
  PlanCoronalAngle: 'Plan: Coronal Angle',
  PlanPosteriorHeight: 'Plan: Posterior Height (mm)',
  PlanAnteriorHeight: 'Plan: Anterior Height (mm)',
  PostOpLordoticAngle: 'Post-Op: Lordosis Angle',
  PostOpCoronalAngle: 'Post-Op: Coronal Angle',
  PostOpPosteriorHeight: 'Post-Op: Posterior Height (mm)',
  PostOpAnteriorHeight: 'Post-Op: Anterior Height (mm)',
  DeltaLordoticAngle: 'Delta: Lordosis Angle',
  DeltaCoronalAngle: 'Delta: Coronal Angle',
  DeltaPosteriorHeight: 'Delta: Posterior Height (mm)',
  DeltaAnteriorHeight: 'Delta: Anterior Height (mm)',
  PlanAchieved: 'Plan Achieved?',
};

const POST_OP_HEADER_CONFIG = [
  { value: POST_OP_HEADER_LABEL.ID, width: 10 },
  { value: POST_OP_HEADER_LABEL.Tags, width: 30 },
  { value: POST_OP_HEADER_LABEL.CaseNumber, width: 15 },
  { value: POST_OP_HEADER_LABEL.HasImaging, width: 20 },
  { value: POST_OP_HEADER_LABEL.HasValidImaging, width: 20 },
  { value: POST_OP_HEADER_LABEL.ImagingDate, width: 20 },
  { value: POST_OP_HEADER_LABEL.Surgeon, width: 15 },
  { value: POST_OP_HEADER_LABEL.Institution, width: 15 },
  { value: POST_OP_HEADER_LABEL.SurgeryDate, width: 15 },
  { value: POST_OP_HEADER_LABEL.PatientAge, width: 15 },
  { value: POST_OP_HEADER_LABEL.PatientGender, width: 15 },
  { value: POST_OP_HEADER_LABEL.NumLevelsPlanned, width: 20 },
  { value: POST_OP_HEADER_LABEL.NumLevelsCompleted, width: 20 },
  ...allLevels.map((levelType) => ({
    value: POST_OP_HEADER_LABEL[levelType],
    width: 10,
  })),
  { value: POST_OP_HEADER_LABEL.PartTypes, width: 15 },
  { value: POST_OP_HEADER_LABEL.ImplantLevels, width: 15 },
  { value: POST_OP_HEADER_LABEL.FusionType, width: 15 },
  { value: POST_OP_HEADER_LABEL.LevelsFused, width: 15 },
  { value: POST_OP_HEADER_LABEL.NumLevelsFused, width: 15 },
  { value: POST_OP_HEADER_LABEL.PelvicIncidence, width: 20 },
  { value: POST_OP_HEADER_LABEL.Level, width: 15 },
  { value: POST_OP_HEADER_LABEL.Implant, width: 15 },
  { value: POST_OP_HEADER_LABEL.ImplantResult, width: 15 },
  { value: POST_OP_HEADER_LABEL.ImplantSize, width: 15 },
  { value: POST_OP_HEADER_LABEL.ImplantReason, width: 15 },
  { value: POST_OP_HEADER_LABEL.PreOpLordoticAngle, width: 30 },
  { value: POST_OP_HEADER_LABEL.PreOpCoronalAngle, width: 30 },
  { value: POST_OP_HEADER_LABEL.PreOpPosteriorHeight, width: 30 },
  { value: POST_OP_HEADER_LABEL.PreOpAnteriorHeight, width: 30 },
  { value: POST_OP_HEADER_LABEL.PlanLordoticAngle, width: 30 },
  { value: POST_OP_HEADER_LABEL.PlanCoronalAngle, width: 30 },
  { value: POST_OP_HEADER_LABEL.PlanPosteriorHeight, width: 30 },
  { value: POST_OP_HEADER_LABEL.PlanAnteriorHeight, width: 30 },
  { value: POST_OP_HEADER_LABEL.PostOpLordoticAngle, width: 30 },
  { value: POST_OP_HEADER_LABEL.PostOpCoronalAngle, width: 30 },
  { value: POST_OP_HEADER_LABEL.PostOpPosteriorHeight, width: 30 },
  { value: POST_OP_HEADER_LABEL.PostOpAnteriorHeight, width: 30 },
  { value: POST_OP_HEADER_LABEL.DeltaLordoticAngle, width: 30 },
  { value: POST_OP_HEADER_LABEL.DeltaCoronalAngle, width: 30 },
  { value: POST_OP_HEADER_LABEL.DeltaPosteriorHeight, width: 30 },
  { value: POST_OP_HEADER_LABEL.DeltaAnteriorHeight, width: 30 },
  { value: POST_OP_HEADER_LABEL.PlanAchieved, width: 15 },
];

function getMeasurementKey(
  inferior: VertebralBody,
  superior: VertebralBody,
  measurement:
    | 'LORDOTIC_ANGLE'
    | 'CORONAL_ANGLE'
    | 'POSTERIOR_HEIGHT'
    | 'LUMBAR_LORDOSIS'
    | 'LUMBAR_CORONAL_COBB'
    | 'ANTERIOR_HEIGHT',
) {
  switch (measurement) {
    case 'LORDOTIC_ANGLE':
    case 'CORONAL_ANGLE':
    case 'POSTERIOR_HEIGHT':
    case 'ANTERIOR_HEIGHT':
      return `${measurement}.${inferior}.INFERIOR.${superior}.SUPERIOR`;
    case 'LUMBAR_LORDOSIS':
    case 'LUMBAR_CORONAL_COBB':
      return `${measurement}.${inferior}.SUPERIOR.${superior}.SUPERIOR`;
    default:
      return '';
  }
}

const convertNumberToString = (numStr?: number) =>
  !Number.isNaN(numStr) && typeof numStr === 'number' ? numStr.toFixed(1) : BLANK_CHARACTER;

export function ExportPostOpCasesButton() {
  const apolloClient = useApolloClient();

  const { enqueueSnackbar } = useSnackbar();

  const [loadingFindCases, setLoadingFindCases] = useState(false);

  const handleExport = async () => {
    try {
      setLoadingFindCases(true);

      const casesResult = await apolloClient.query({
        query: FIND_POST_OP_EXPORT_CASES,
        variables: {},
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
      });

      const xlsData: any[] = [];

      casesResult.data.reportPostOpCasesExport.forEach(
        (activeCase: IExportPostOpCase, caseIndex: number) => {
          const plannedCaseLevels: LevelType[] = caseUtils.getValidCaseLevels(activeCase.levels);
          const plannedLevelCount: number = plannedCaseLevels.length;
          const completedCaseLevels: LevelType[] = caseUtils.getCompletedCaseLevels(
            activeCase.caseResults,
          );
          const caseResultByLevelMap = _.keyBy(
            activeCase.caseResults,
            (cr: ICaseResult) => cr.level,
          );
          const completedCaseLevelCount: number = completedCaseLevels.length;
          const profileCaseLevels: LevelType[] = caseUtils.getLevelsSortedByHierarchy(
            activeCase.spineProfile,
          );
          const postOpAsset: IAsset = activeCase.postOpSixWeeksImaging;

          // Find the first approved plan or the most recent proposed plan
          const acceptedPlan: IPlan = activeCase.acceptedPlan;

          const planMeasurements: IMeasurementPointValues =
            measurements.getMeasurementPointValuesFromAssetPositions(
              acceptedPlan?.assetPositions?.plan?.points ?? [],
              activeCase.spineProfile,
              activeCase.measurementsVersion,
            );

          const preOpMeasurements: IMeasurementPointValues =
            measurements.getMeasurementPointValuesFromAssetPositions(
              acceptedPlan?.assetPositions?.preop?.points ?? [],
              activeCase.spineProfile,
              activeCase.measurementsVersion,
            );

          const sortedCaseSpineProfileLevels: LevelType[] = caseUtils.getLevelsSortedByHierarchy(
            activeCase.spineProfile,
            'desc',
          );

          const sortedCompletedCaseLevels: LevelType[] = sortedCaseSpineProfileLevels.filter(
            (level) => completedCaseLevels.includes(level),
          );

          const levelsFused: number =
            activeCase.uiv && activeCase.liv
              ? caseUtils.getLevelsBetweenVertebralBodies({
                  upper: activeCase.uiv,
                  lower: activeCase.liv,
                  spineProfile: activeCase.spineProfile,
                })
              : 0;

          const overallPatientMeasurementValues = {
            lumbarLordosis: {
              preOp: convertNumber(
                preOpMeasurements[
                  getMeasurementKey(VertebralBody.L1, VertebralBody.S1, 'LUMBAR_LORDOSIS')
                ],
              ),
              plan: convertNumber(
                planMeasurements[
                  getMeasurementKey(VertebralBody.L1, VertebralBody.S1, 'LUMBAR_LORDOSIS')
                ],
              ),
              postOp: convertNumber(activeCase.postOpAnalysisSixWeeks?.lumbarLordosis),
            },
            lumbarCoronalCobb: {
              preOp: convertNumber(
                preOpMeasurements[
                  getMeasurementKey(VertebralBody.L1, VertebralBody.S1, 'LUMBAR_CORONAL_COBB')
                ],
              ),
              plan: convertNumber(
                planMeasurements[
                  getMeasurementKey(VertebralBody.L1, VertebralBody.S1, 'LUMBAR_CORONAL_COBB')
                ],
              ),
              postOp: convertNumber(activeCase.postOpAnalysisSixWeeks?.lumbarCoronalAngulation),
            },
          };

          const overallPatientDeltaMeasurementValues = {
            lumbarLordosis:
              overallPatientMeasurementValues.lumbarLordosis.postOp -
              overallPatientMeasurementValues.lumbarLordosis.plan,
            lumbarCoronalCobb:
              overallPatientMeasurementValues.lumbarCoronalCobb.postOp -
              overallPatientMeasurementValues.lumbarCoronalCobb.plan,
          };

          const overallPlanAchievedFactors = [
            overallPatientDeltaMeasurementValues.lumbarLordosis,
          ].filter((deltaValue) => !Number.isNaN(deltaValue));

          const overallPlanAchieved =
            overallPlanAchievedFactors.length > 0
              ? overallPlanAchievedFactors.every((m) => Math.abs(m) < MAX_OVERALL_DEVIATION)
              : undefined;

          sortedCaseSpineProfileLevels.forEach((currentCaseLevel, levelIndex) => {
            const levelConfig = LEVEL_CONFIG_MAP[currentCaseLevel as LevelType];

            if (!levelConfig) return;

            const isFirstCaseRow = levelIndex === 0;

            const displayValue = (val: any) => (isFirstCaseRow ? val : '');

            const measurementValues = {
              lordoticAngle: {
                preOp: convertNumber(
                  preOpMeasurements[
                    getMeasurementKey(
                      levelConfig.inferiorVertebrae,
                      levelConfig.superiorVertebrae,
                      'LORDOTIC_ANGLE',
                    )
                  ],
                ),
                plan: convertNumber(
                  planMeasurements[
                    getMeasurementKey(
                      levelConfig.inferiorVertebrae,
                      levelConfig.superiorVertebrae,
                      'LORDOTIC_ANGLE',
                    )
                  ],
                ),
                postOp: convertNumber(
                  activeCase.postOpAnalysisSixWeeks?.segmentalLumbarLordosis?.[currentCaseLevel],
                ),
              },
              coronalAngle: {
                preOp: convertNumber(
                  preOpMeasurements[
                    getMeasurementKey(
                      levelConfig.inferiorVertebrae,
                      levelConfig.superiorVertebrae,
                      'CORONAL_ANGLE',
                    )
                  ],
                ),
                plan: convertNumber(
                  planMeasurements[
                    getMeasurementKey(
                      levelConfig.inferiorVertebrae,
                      levelConfig.superiorVertebrae,
                      'CORONAL_ANGLE',
                    )
                  ],
                ),
                postOp: convertNumber(
                  activeCase.postOpAnalysisSixWeeks?.segmentalCoronalAngle?.[currentCaseLevel],
                ),
              },
              posteriorHeight: {
                preOp: convertNumber(
                  preOpMeasurements[
                    getMeasurementKey(
                      levelConfig.inferiorVertebrae,
                      levelConfig.superiorVertebrae,
                      'POSTERIOR_HEIGHT',
                    )
                  ],
                ),
                plan: convertNumber(
                  planMeasurements[
                    getMeasurementKey(
                      levelConfig.inferiorVertebrae,
                      levelConfig.superiorVertebrae,
                      'POSTERIOR_HEIGHT',
                    )
                  ],
                ),
                postOp: convertNumber(
                  activeCase.postOpAnalysisSixWeeks?.segmentalPosteriorHeight?.[currentCaseLevel],
                ),
              },
              anteriorHeight: {
                preOp: convertNumber(
                  preOpMeasurements[
                    getMeasurementKey(
                      levelConfig.inferiorVertebrae,
                      levelConfig.superiorVertebrae,
                      'ANTERIOR_HEIGHT',
                    )
                  ],
                ),
                plan: convertNumber(
                  planMeasurements[
                    getMeasurementKey(
                      levelConfig.inferiorVertebrae,
                      levelConfig.superiorVertebrae,
                      'ANTERIOR_HEIGHT',
                    )
                  ],
                ),
                postOp: convertNumber(
                  activeCase.postOpAnalysisSixWeeks?.segmentalAnteriorHeight?.[currentCaseLevel],
                ),
              },
            };

            const deltaMeasurements = {
              lordoticAngle:
                measurementValues.lordoticAngle.postOp - measurementValues.lordoticAngle.plan,
              coronalAngle:
                measurementValues.coronalAngle.postOp - measurementValues.coronalAngle.plan,
              posteriorHeight:
                measurementValues.posteriorHeight.postOp - measurementValues.posteriorHeight.plan,
              anteriorHeight:
                measurementValues.anteriorHeight.postOp - measurementValues.anteriorHeight.plan,
            };

            // Ignore anterior height delta when computing "planAchieved"
            const planAchievedFactors = [
              deltaMeasurements.lordoticAngle,
              deltaMeasurements.coronalAngle,
              deltaMeasurements.posteriorHeight,
            ].filter((deltaValue) => !Number.isNaN(deltaValue));

            const planAchieved =
              planAchievedFactors.length > 0
                ? planAchievedFactors.every((m) => Math.abs(m) < MAX_SEGMENTAL_DEVIATION)
                : undefined;

            const caseResultForLevel = caseResultByLevelMap[currentCaseLevel];
            const caseResultForLevelUsed = caseResultForLevel?.result === LevelResult.Used;

            const postOpStudyDate = postOpAsset?.metadata?.studyDate
              ? new Date(postOpAsset.metadata.studyDate)
              : null;
            const caseSurgeryDate = activeCase.surgeryDate
              ? new Date(activeCase.surgeryDate)
              : null;
            const timeSinceSurgeryFromPostOpImaging =
              postOpStudyDate && caseSurgeryDate
                ? date.timeSinceInWeeks(
                    postOpStudyDate.toISOString(),
                    caseSurgeryDate.toISOString(),
                  )
                : null;

            xlsData.push({
              [POST_OP_HEADER_LABEL.ID]: {
                t: 'n',
                v: displayValue(caseIndex + 1),
              },
              [POST_OP_HEADER_LABEL.CaseNumber]: {
                t: 's',
                v: displayValue(activeCase.number),
              },
              [POST_OP_HEADER_LABEL.Tags]: {
                t: 's',
                v: displayValue(
                  activeCase.tags?.length
                    ? _.sortBy(activeCase.tags, 'tagCategoryId')
                        .map((caseTag) => caseTag.label)
                        .join(', ')
                    : BLANK_CHARACTER,
                ),
              },
              [POST_OP_HEADER_LABEL.HasImaging]: {
                t: isFirstCaseRow ? 'b' : 's',
                v: displayValue(activeCase.postOpSixWeeksImaging != null),
              },
              [POST_OP_HEADER_LABEL.HasValidImaging]: {
                t: isFirstCaseRow && timeSinceSurgeryFromPostOpImaging ? 'b' : 's',
                v: displayValue(
                  !_.isNull(timeSinceSurgeryFromPostOpImaging)
                    ? timeSinceSurgeryFromPostOpImaging >= VALID_POST_OP_IMAGING_AGE_IN_WEEKS
                    : BLANK_CHARACTER,
                ),
              },
              [POST_OP_HEADER_LABEL.ImagingDate]: {
                t: 's',
                v: displayValue(postOpAsset?.metadata?.studyDate || BLANK_CHARACTER),
              },
              [POST_OP_HEADER_LABEL.Surgeon]: {
                t: 's',
                v: displayValue(
                  activeCase.surgeonUser
                    ? format.formatName(activeCase.surgeonUser)
                    : BLANK_CHARACTER,
                ),
              },
              [POST_OP_HEADER_LABEL.Institution]: {
                t: 's',
                v: displayValue(activeCase?.organization?.name || BLANK_CHARACTER),
              },
              [POST_OP_HEADER_LABEL.SurgeryDate]: {
                t: 's',
                v: displayValue(
                  activeCase.surgeryDate && !activeCase.isSurgeryDateTentative
                    ? format.formatISODate(activeCase.surgeryDate as string)
                    : BLANK_CHARACTER,
                ),
              },
              [POST_OP_HEADER_LABEL.PatientAge]: {
                t: 's',
                v: displayValue(
                  activeCase.patient?.birthDate
                    ? date.distanceInYears(new Date(activeCase.patient.birthDate).toISOString())
                    : BLANK_CHARACTER,
                ),
              },
              [POST_OP_HEADER_LABEL.PatientGender]: {
                t: 's',
                v: displayValue(activeCase.patient?.gender || BLANK_CHARACTER),
              },
              [POST_OP_HEADER_LABEL.NumLevelsPlanned]: {
                t: 'n',
                v: displayValue(plannedLevelCount),
              },
              [POST_OP_HEADER_LABEL.NumLevelsCompleted]: {
                t: 'n',
                v: displayValue(completedCaseLevelCount),
              },
              ...allLevels.reduce(
                (prev, currLevel) => ({
                  ...prev,
                  [POST_OP_HEADER_LABEL[currLevel]]: {
                    t: 's',
                    v: displayValue(
                      profileCaseLevels.includes(currLevel)
                        ? format.formatPartType(activeCase.levels[currLevel] as PartType)
                        : BLANK_CHARACTER,
                    ),
                  },
                }),
                {},
              ),
              [POST_OP_HEADER_LABEL.PartTypes]: {
                t: 's',
                v: displayValue(
                  sortedCompletedCaseLevels
                    .map((currLevel) =>
                      format.formatPartType(activeCase.levels[currLevel] as PartType),
                    )
                    .join(', '),
                ),
              },
              [POST_OP_HEADER_LABEL.ImplantLevels]: {
                t: 's',
                v: displayValue(
                  _.uniq(
                    sortedCompletedCaseLevels.map((level) =>
                      format.formatLevelType(level as LevelType),
                    ),
                  ).join(', '),
                ),
              },
              [POST_OP_HEADER_LABEL.FusionType]: {
                t: 's',
                v: displayValue(
                  levelsFused >= LONG_FUSION_MIN_LEVELS
                    ? 'Long'
                    : levelsFused > 0
                    ? 'Short'
                    : BLANK_CHARACTER,
                ),
              },
              [POST_OP_HEADER_LABEL.LevelsFused]: {
                t: 's',
                v: displayValue(
                  activeCase.uiv && activeCase.liv
                    ? `${activeCase.uiv} - ${activeCase.liv}`
                    : BLANK_CHARACTER,
                ),
              },
              [POST_OP_HEADER_LABEL.NumLevelsFused]: {
                t: 's',
                v: displayValue(levelsFused > 0 ? levelsFused : BLANK_CHARACTER),
              },
              [POST_OP_HEADER_LABEL.PelvicIncidence]: {
                t: 's',
                v: displayValue(activeCase?.patientRecord?.pelvicIncidence || BLANK_CHARACTER),
              },
              [POST_OP_HEADER_LABEL.Level]: {
                t: 's',
                v: format.formatLevelType(currentCaseLevel as LevelType),
              },
              [POST_OP_HEADER_LABEL.Implant]: {
                t: 's',
                v: caseResultForLevel
                  ? format.formatPartType(activeCase.levels[currentCaseLevel] as PartType)
                  : BLANK_CHARACTER,
              },
              [POST_OP_HEADER_LABEL.ImplantResult]: {
                t: 's',
                v: caseResultForLevel
                  ? format.formatInterbodyLevelResult(caseResultForLevel.result)
                  : BLANK_CHARACTER,
              },
              [POST_OP_HEADER_LABEL.ImplantSize]: {
                t: 's',
                v: caseResultForLevel
                  ? format.formatInterbodyLevelSize(caseResultForLevel.size)
                  : BLANK_CHARACTER,
              },
              [POST_OP_HEADER_LABEL.ImplantReason]: {
                t: 's',
                v: caseResultForLevel?.exceptionReason || BLANK_CHARACTER,
              },
              [POST_OP_HEADER_LABEL.PreOpLordoticAngle]: {
                t: 's',
                v: convertNumberToString(measurementValues.lordoticAngle.preOp),
              },
              [POST_OP_HEADER_LABEL.PreOpCoronalAngle]: {
                t: 's',
                v: convertNumberToString(measurementValues.coronalAngle.preOp),
              },
              [POST_OP_HEADER_LABEL.PreOpPosteriorHeight]: {
                t: 's',
                v: convertNumberToString(measurementValues.posteriorHeight.preOp),
              },
              [POST_OP_HEADER_LABEL.PreOpAnteriorHeight]: {
                t: 's',
                v: convertNumberToString(measurementValues.anteriorHeight.preOp),
              },
              [POST_OP_HEADER_LABEL.PlanLordoticAngle]: {
                t: 's',
                v: convertNumberToString(measurementValues.lordoticAngle.plan),
              },
              [POST_OP_HEADER_LABEL.PlanCoronalAngle]: {
                t: 's',
                v: convertNumberToString(measurementValues.coronalAngle.plan),
              },
              [POST_OP_HEADER_LABEL.PlanPosteriorHeight]: {
                t: 's',
                v: convertNumberToString(measurementValues.posteriorHeight.plan),
              },
              [POST_OP_HEADER_LABEL.PlanAnteriorHeight]: {
                t: 's',
                v: convertNumberToString(measurementValues.anteriorHeight.plan),
              },
              [POST_OP_HEADER_LABEL.PostOpLordoticAngle]: {
                t: 's',
                v: caseResultForLevelUsed
                  ? convertNumberToString(measurementValues.lordoticAngle.postOp)
                  : BLANK_CHARACTER,
              },
              [POST_OP_HEADER_LABEL.PostOpCoronalAngle]: {
                t: 's',
                v: caseResultForLevelUsed
                  ? convertNumberToString(measurementValues.coronalAngle.postOp)
                  : BLANK_CHARACTER,
              },
              [POST_OP_HEADER_LABEL.PostOpPosteriorHeight]: {
                t: 's',
                v: caseResultForLevelUsed
                  ? convertNumberToString(measurementValues.posteriorHeight.postOp)
                  : BLANK_CHARACTER,
              },
              [POST_OP_HEADER_LABEL.PostOpAnteriorHeight]: {
                t: 's',
                v: caseResultForLevelUsed
                  ? convertNumberToString(measurementValues.anteriorHeight.postOp)
                  : BLANK_CHARACTER,
              },
              [POST_OP_HEADER_LABEL.DeltaLordoticAngle]: {
                t: 's',
                v: caseResultForLevelUsed
                  ? convertNumberToString(deltaMeasurements.lordoticAngle)
                  : BLANK_CHARACTER,
              },
              [POST_OP_HEADER_LABEL.DeltaCoronalAngle]: {
                t: 's',
                v: caseResultForLevelUsed
                  ? convertNumberToString(deltaMeasurements.coronalAngle)
                  : BLANK_CHARACTER,
              },
              [POST_OP_HEADER_LABEL.DeltaPosteriorHeight]: {
                t: 's',
                v: caseResultForLevelUsed
                  ? convertNumberToString(deltaMeasurements.posteriorHeight)
                  : BLANK_CHARACTER,
              },
              [POST_OP_HEADER_LABEL.DeltaAnteriorHeight]: {
                t: 's',
                v: caseResultForLevelUsed
                  ? convertNumberToString(deltaMeasurements.anteriorHeight)
                  : BLANK_CHARACTER,
              },
              [POST_OP_HEADER_LABEL.PlanAchieved]: {
                t: caseResultForLevelUsed && !_.isUndefined(planAchieved) ? 'b' : 's',
                v:
                  caseResultForLevelUsed && !_.isUndefined(planAchieved)
                    ? planAchieved
                    : BLANK_CHARACTER,
              },
            });
          });

          // Add row for Lumbar Lordosis (L1 / S1) - XR
          xlsData.push({
            [POST_OP_HEADER_LABEL.Level]: {
              t: 's',
              v: 'L1/S1 (XR)',
            },
            [POST_OP_HEADER_LABEL.Implant]: {
              t: 's',
              v: BLANK_CHARACTER,
            },
            [POST_OP_HEADER_LABEL.ImplantResult]: {
              t: 's',
              v: BLANK_CHARACTER,
            },
            [POST_OP_HEADER_LABEL.ImplantSize]: {
              t: 's',
              v: BLANK_CHARACTER,
            },
            [POST_OP_HEADER_LABEL.ImplantReason]: {
              t: 's',
              v: BLANK_CHARACTER,
            },
            [POST_OP_HEADER_LABEL.PreOpLordoticAngle]: {
              t: 's',
              v: convertNumberToString(activeCase?.patientRecord?.lumbarLordosis),
            },
            [POST_OP_HEADER_LABEL.PreOpCoronalAngle]: {
              t: 's',
              v: convertNumberToString(activeCase?.patientRecord?.lumbarCoronalCobb),
            },
            [POST_OP_HEADER_LABEL.PreOpPosteriorHeight]: {
              t: 's',
              v: BLANK_CHARACTER,
            },
            [POST_OP_HEADER_LABEL.PreOpAnteriorHeight]: {
              t: 's',
              v: BLANK_CHARACTER,
            },
            [POST_OP_HEADER_LABEL.PlanLordoticAngle]: {
              t: 's',
              v: BLANK_CHARACTER,
            },
            [POST_OP_HEADER_LABEL.PlanCoronalAngle]: {
              t: 's',
              v: BLANK_CHARACTER,
            },
            [POST_OP_HEADER_LABEL.PlanPosteriorHeight]: {
              t: 's',
              v: BLANK_CHARACTER,
            },
            [POST_OP_HEADER_LABEL.PlanAnteriorHeight]: {
              t: 's',
              v: BLANK_CHARACTER,
            },
            [POST_OP_HEADER_LABEL.PostOpLordoticAngle]: {
              t: 's',
              v: convertNumberToString(overallPatientMeasurementValues.lumbarLordosis.postOp),
            },
            [POST_OP_HEADER_LABEL.PostOpCoronalAngle]: {
              t: 's',
              v: convertNumberToString(overallPatientMeasurementValues.lumbarCoronalCobb.postOp),
            },
            [POST_OP_HEADER_LABEL.PostOpPosteriorHeight]: {
              t: 's',
              v: BLANK_CHARACTER,
            },
            [POST_OP_HEADER_LABEL.PostOpAnteriorHeight]: {
              t: 's',
              v: BLANK_CHARACTER,
            },
            [POST_OP_HEADER_LABEL.DeltaLordoticAngle]: {
              t: 's',
              v: BLANK_CHARACTER,
            },
            [POST_OP_HEADER_LABEL.DeltaCoronalAngle]: {
              t: 's',
              v: BLANK_CHARACTER,
            },
            [POST_OP_HEADER_LABEL.DeltaPosteriorHeight]: {
              t: 's',
              v: BLANK_CHARACTER,
            },
            [POST_OP_HEADER_LABEL.DeltaAnteriorHeight]: {
              t: 's',
              v: BLANK_CHARACTER,
            },
            [POST_OP_HEADER_LABEL.PlanAchieved]: {
              t: 's',
              v: BLANK_CHARACTER,
            },
          });

          // Add row for Lumbar Lordosis (L1 / S1) -
          xlsData.push({
            [POST_OP_HEADER_LABEL.Level]: {
              t: 's',
              v: 'L1/S1 (Cyborg)',
            },
            [POST_OP_HEADER_LABEL.Implant]: {
              t: 's',
              v: BLANK_CHARACTER,
            },
            [POST_OP_HEADER_LABEL.ImplantResult]: {
              t: 's',
              v: BLANK_CHARACTER,
            },
            [POST_OP_HEADER_LABEL.ImplantSize]: {
              t: 's',
              v: BLANK_CHARACTER,
            },
            [POST_OP_HEADER_LABEL.ImplantReason]: {
              t: 's',
              v: BLANK_CHARACTER,
            },
            [POST_OP_HEADER_LABEL.PreOpLordoticAngle]: {
              t: 's',
              v: convertNumberToString(overallPatientMeasurementValues.lumbarLordosis.preOp),
            },
            [POST_OP_HEADER_LABEL.PreOpCoronalAngle]: {
              t: 's',
              v: convertNumberToString(overallPatientMeasurementValues.lumbarCoronalCobb.preOp),
            },
            [POST_OP_HEADER_LABEL.PreOpPosteriorHeight]: {
              t: 's',
              v: BLANK_CHARACTER,
            },
            [POST_OP_HEADER_LABEL.PreOpAnteriorHeight]: {
              t: 's',
              v: BLANK_CHARACTER,
            },
            [POST_OP_HEADER_LABEL.PlanLordoticAngle]: {
              t: 's',
              v: convertNumberToString(overallPatientMeasurementValues.lumbarLordosis.plan),
            },
            [POST_OP_HEADER_LABEL.PlanCoronalAngle]: {
              t: 's',
              v: convertNumberToString(overallPatientMeasurementValues.lumbarCoronalCobb.plan),
            },
            [POST_OP_HEADER_LABEL.PlanPosteriorHeight]: {
              t: 's',
              v: BLANK_CHARACTER,
            },
            [POST_OP_HEADER_LABEL.PlanAnteriorHeight]: {
              t: 's',
              v: BLANK_CHARACTER,
            },
            [POST_OP_HEADER_LABEL.PostOpLordoticAngle]: {
              t: 's',
              v: BLANK_CHARACTER,
            },
            [POST_OP_HEADER_LABEL.PostOpCoronalAngle]: {
              t: 's',
              v: BLANK_CHARACTER,
            },
            [POST_OP_HEADER_LABEL.PostOpPosteriorHeight]: {
              t: 's',
              v: BLANK_CHARACTER,
            },
            [POST_OP_HEADER_LABEL.PostOpAnteriorHeight]: {
              t: 's',
              v: BLANK_CHARACTER,
            },
            [POST_OP_HEADER_LABEL.DeltaLordoticAngle]: {
              t: 's',
              v: convertNumberToString(overallPatientDeltaMeasurementValues.lumbarLordosis),
            },
            [POST_OP_HEADER_LABEL.DeltaCoronalAngle]: {
              t: 's',
              v: convertNumberToString(overallPatientDeltaMeasurementValues.lumbarCoronalCobb),
            },
            [POST_OP_HEADER_LABEL.DeltaPosteriorHeight]: {
              t: 's',
              v: BLANK_CHARACTER,
            },
            [POST_OP_HEADER_LABEL.DeltaAnteriorHeight]: {
              t: 's',
              v: BLANK_CHARACTER,
            },
            [POST_OP_HEADER_LABEL.PlanAchieved]: {
              t: _.isUndefined(overallPlanAchieved) ? 's' : 'b',
              v: _.isUndefined(overallPlanAchieved) ? BLANK_CHARACTER : overallPlanAchieved,
            },
          });
        },
      );

      dataExport.saveXlsxFile(
        `workflow-post-op-cases-${format.formatDate(new Date())}.xlsx`,
        'Workflow Post-Op Cases',
        POST_OP_HEADER_CONFIG,
        xlsData,
      );
    } catch (e) {
      console.error(e);
      enqueueSnackbar('Exporting post-op cases failed', { variant: 'error' });
    } finally {
      setLoadingFindCases(false);
    }
  };

  return (
    <ActionButton
      variant={'outlined'}
      fullWidth
      color={'secondary'}
      loading={loadingFindCases}
      onClick={handleExport}
    >
      Export Post-Op (XLSX)
    </ActionButton>
  );
}
