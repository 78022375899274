import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { EndPlate, Position, VertebraePositionColor } from '@workflow-nx/common';
import {
  AbstractMesh,
  ActionManager,
  ArcRotateCamera,
  Color3,
  Color4,
  ExecuteCodeAction,
  Matrix,
  MeshBuilder,
  Observer,
  PointerEventTypes,
  PointerInfo,
  Scene,
  SceneLoader,
  StandardMaterial,
  Tags,
  Vector3,
} from 'babylonjs';
import 'babylonjs-inspector';
import { useEffect, useState } from 'react';
import { AssetMeshView } from '../../../../components/BetterSpineViewer';
import {
  SceneComponent,
  configureCanvas,
  onControlClick,
  setOpacity,
  setTarget,
} from '../../../../components/SceneComponent';
import { rotateCamera } from '../../../../components/SceneComponent/renderer';

const useStyles = makeStyles(() => ({
  canvasRoot: {
    border: `1px solid rgba(0, 0, 0, 0.12)`,
    '& canvas': {
      width: '100%',
      height: 305,
      touchAction: 'none',
      zIndex: 9999,
    },
  },
}));

export function ZoomMeshViewer(props: {
  assetMeshViews: AssetMeshView[];
  focus?: string[];
  opacity?: number;
  cameraPosition?: Vector3;
}) {
  const styles = useStyles();
  const [scene, setScene] = useState<Scene>();
  const [isSceneReady, setIsSceneReady] = useState(false);
  const [loading, setLoading] = useState(false);

  function addPoint(point: Vector3, name: string, color: Color3, parentName: string, scene: Scene) {
    const parent = scene.getMeshByName(parentName);
    let mesh = scene.getMeshByName(name);

    if (!mesh) {
      const material = new StandardMaterial('redMat', scene);

      mesh = MeshBuilder.CreateSphere(name, { diameter: 3 }, scene);
      material.diffuseColor = color;

      // to remove light reflection, set the specular color to black
      material.specularColor = Color3.Black();

      mesh.material = material;

      mesh.parent = parent;
    }
    mesh.isPickable = false;
    mesh.setAbsolutePosition(point);
  }

  const loadMesh = async (
    assetMeshView: AssetMeshView,
    scene?: Scene,
  ): Promise<AbstractMesh | null> => {
    if (!scene || !assetMeshView.signedDownloadUrl) return null;

    const result = await SceneLoader.ImportMeshAsync(
      null,
      assetMeshView.signedDownloadUrl,
      '',
      scene,
    );
    const mesh = result.meshes[0];
    mesh.name = assetMeshView.name;

    // NOTE: The STL files come back from nTopology with no normal information
    // (used by the shader) so we need to create the normals manually
    mesh.createNormals(false);

    let tags = assetMeshView.tags;
    if (tags) {
      Tags.AddTagsTo(mesh, tags.join(' '));
    }

    const standardMaterial = new StandardMaterial('myMaterial', scene);
    standardMaterial.diffuseColor = assetMeshView?.options?.color ?? Color3.FromInts(245, 245, 245);

    // to remove light reflection, set the specular color to black
    standardMaterial.specularColor = Color3.Black();

    mesh.material = standardMaterial;

    if (assetMeshView?.options?.showEdges) {
      mesh.enableEdgesRendering();
      mesh.edgesWidth = 1.0;
      mesh.edgesColor = new Color4(1, 1, 1, 1);
    }

    if (assetMeshView?.options?.showBoundingBox) {
      mesh.showBoundingBox = true;
    }

    if (assetMeshView?.options?.position) {
      mesh.setAbsolutePosition(
        new Vector3(
          assetMeshView.options.position?.x,
          assetMeshView.options.position?.y,
          assetMeshView.options.position?.z,
        ),
      );
    }

    if (assetMeshView?.options?.rotation) {
      mesh.addRotation(
        assetMeshView.options.rotation?.x,
        assetMeshView.options.rotation?.y,
        assetMeshView.options.rotation?.z,
      );
    }

    if (assetMeshView.options?.showHighlight) {
      mesh.actionManager = new ActionManager(scene);
      mesh.actionManager.registerAction(
        new ExecuteCodeAction(ActionManager.OnPointerOverTrigger, function () {
          mesh.overlayColor = Color3.Yellow();
          mesh.renderOverlay = true;
        }),
      );
      mesh.actionManager.registerAction(
        new ExecuteCodeAction(ActionManager.OnPointerOutTrigger, function () {
          mesh.renderOverlay = false;
        }),
      );
    }

    if (assetMeshView?.landmarks?.length) {
      const landmarks = assetMeshView.landmarks;
      for (let landmark of landmarks) {
        const { position, endPlate, point } = landmark;
        const body = assetMeshView.name;
        const name = `${body}.${position}.${endPlate}.POINT`;

        let color =
          endPlate === EndPlate.Superior
            ? Color3.FromHexString(VertebraePositionColor.AnteriorTop) // light red
            : Color3.FromHexString(VertebraePositionColor.AnteriorBottom); // dark red

        if (position === Position.Posterior) {
          color =
            endPlate === EndPlate.Superior
              ? Color3.FromHexString(VertebraePositionColor.PosteriorTop) // light yellow
              : Color3.FromHexString(VertebraePositionColor.PosteriorBottom); // dark yellow
        }
        if (position === Position.PatientLeft) {
          color =
            endPlate === EndPlate.Superior
              ? Color3.FromHexString(VertebraePositionColor.PatientLeftTop) // light green
              : Color3.FromHexString(VertebraePositionColor.PatientLeftBottom); // dark green
        }
        if (position === Position.PatientRight) {
          color =
            endPlate === EndPlate.Superior
              ? Color3.FromHexString(VertebraePositionColor.PatientRightTop) // light blue
              : Color3.FromHexString(VertebraePositionColor.PatientRightBottom); // dark blue
        }

        const vector3 = new Vector3(point[0], point[1], point[2]);

        addPoint(vector3, name, color, body, scene);
      }
    }

    mesh.visibility = assetMeshView?.options?.visibility ?? 0;
    mesh.isPickable = mesh.visibility === 1;

    mesh.setPivotPoint(mesh.getBoundingInfo().boundingBox.center);
    return mesh as AbstractMesh;
  };

  function handlePointerEvent(evt: PointerInfo, zoomScene: Scene) {
    const camera = zoomScene?.cameras?.[0] as ArcRotateCamera;
    if (camera) {
      if (camera.inertialAlphaOffset || camera.inertialBetaOffset) {
        return;
      }

      const ray = zoomScene.createPickingRay(
        zoomScene.pointerX,
        zoomScene.pointerY,
        Matrix.Identity(),
        camera,
      );

      if (evt.type === PointerEventTypes.POINTERTAP && evt.event.ctrlKey) {
        const hit = zoomScene.pickWithRay(ray);
        if (hit?.pickedMesh) {
          onControlClick?.(hit.pickedPoint, zoomScene);
        }
        return;
      }
    }
  }

  const onSceneReady = (scene: Scene) => {
    if (isSceneReady) return;

    configureCanvas(
      {
        cameraPosition: props.cameraPosition ?? new Vector3(0, 0, -50),
        distance: 20,
        disableInputs: false,
      },
      scene,
    );

    setScene(scene);
    setIsSceneReady(true);
  };

  async function handleLoad(scene: Scene) {
    try {
      setLoading(true);

      for (let assetMeshView of props.assetMeshViews) {
        const assetView = {
          ...assetMeshView,
          options: { position: Vector3.Zero(), visibility: 1 },
        };
        await loadMesh(assetView, scene);

        if (assetView?.options?.visibility && props.opacity) {
          setOpacity(assetView.name, props.opacity, scene);
        }
      }

      if (props.focus) {
        setTarget(props.focus, scene);
      }
      rotateCamera(-90, 90, 100, scene);
    } catch {
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    let observer: any = null;

    if (scene) {
      observer = scene.onPointerObservable.add((evt) => {
        handlePointerEvent(evt, scene);
      });
    }
    return () => {
      scene?.onPointerObservable?.remove(observer as Observer<PointerInfo>);
    };
  }, [scene]);

  useEffect(() => {
    if (props.opacity && scene) {
      for (let assetMeshView of props.assetMeshViews) {
        if (assetMeshView?.options?.showOpacity) {
          setOpacity(assetMeshView.name, props.opacity, scene);
        }
      }
    }
  }, [props.opacity, scene]);

  useEffect(() => {
    if (isSceneReady && scene) {
      handleLoad(scene);
    }
  }, [isSceneReady, scene]);

  return (
    <>
      <Box className={styles.canvasRoot}>
        <SceneComponent
          antialias={true}
          adaptToDeviceRatio={true}
          canvasId={'zoomViewer'}
          onSceneReady={onSceneReady}
          loading={loading}
        />
      </Box>
    </>
  );
}
