import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Box, Toolbar, Typography, IconButton, Theme, Chip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Logo from '../../../components/Logow';
import Account from './Account';
import useNotifications from '../../../hooks/useNotifications';
import { Globals } from '../index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBellExclamation, faBellOn } from '@fortawesome/pro-light-svg-icons';
import config from '../../../extras/config';

const useStyles = makeStyles((theme: Theme) => ({
  root: (props: { isProduction: boolean }) => ({
    left: Globals.DrawerWidthClosed,
    width: `calc(100% - ${Globals.DrawerWidthClosed}px)`,
    zIndex: theme.zIndex.drawer + 100,
    boxShadow: 'none',
    backgroundColor: props.isProduction ? theme.palette.background.paper : undefined,
    background: !props.isProduction
      ? `repeating-linear-gradient(45deg, ${theme.palette.background.paper}, ${theme.palette.background.paper} 10px, #f2f2f2 10px, #f2f2f2 20px)`
      : undefined,
  }),
  toolbar: {
    minHeight: Globals.TopBarHeight,
    paddingLeft: 0,
  },
  title: {
    fontFamily: `'Questrial', sans-serif`,
    fontSize: '1em',
    textTransform: 'uppercase',
  },
}));

const TopBar = () => {
  const isProduction = config.environmentName === 'production';
  const styles = useStyles({ isProduction });

  const notificationsDeniedInBrowser =
    window.Notification && window.Notification.permission === 'denied';

  const {
    userIsSubscribed,
    isInitialized: notificationsIsInitialized,
    showSubscriptionModal,
  } = useNotifications();

  return (
    <AppBar className={styles.root} elevation={1}>
      <Toolbar className={styles.toolbar}>
        <RouterLink to="/">
          <Logo height={50} />
        </RouterLink>
        <Typography variant={'body1'} color={'textSecondary'} className={styles.title}>
          <strong>Digital Production System</strong>
        </Typography>
        {!isProduction ? (
          <Box ml={1}>
            <Chip color={'success'} label={config.environmentName.toUpperCase()} size={'small'} />
          </Box>
        ) : null}
        <Box ml={2} flexGrow={1} />
        {!userIsSubscribed && notificationsIsInitialized ? (
          <Box ml={2}>
            <IconButton onClick={showSubscriptionModal} size="large">
              {notificationsDeniedInBrowser ? (
                <FontAwesomeIcon icon={faBellExclamation} />
              ) : (
                <FontAwesomeIcon icon={faBellOn} />
              )}
            </IconButton>
          </Box>
        ) : null}
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
