import { useLazyQuery } from '@apollo/client';
import { Box, Button, Card, CardContent, Container, Typography, useTheme } from '@mui/material';
import {
  CaseCancellationListType,
  CaseSpineType,
  CaseStageType,
  CaseType,
  PartType,
  Permission,
  UserRoleType,
} from '@workflow-nx/common';
import { testing } from '@workflow-nx/utils';
import { useEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomAppBar } from '../../../components/CustomAppBar';
import { Heading } from '../../../components/Heading';
import Page from '../../../components/Page';
import SearchInput from '../../../components/SearchInput';
import { FIND_CASES } from '../../../gql';
import useAuth from '../../../hooks/useAuth';
import { Globals } from '../../../layouts/DashboardLayout';
import CaseListTable from './CaseListTable';
import { CaseTableFilter } from './CaseTableFilter';
import { CreateCaseDialog } from './CreateCase/CreateCaseDialog';
import { ExportCasesButton } from './ExportCasesButton';
import ImportCaseButton from './ImportCaseButton';
import { ListCasesReducer, ListCasesStateType } from './ListCases.reducer';

interface IListCaseQueryVariables {
  search: string;
  caseTypeFilter: CaseType[];
  caseSpineTypeFilter: CaseSpineType[];
  planVersion?: number;
  orderBy: { surgeryDate: string };
  caseCancellationTypeFilter?: CaseCancellationListType[];
  surgeonFilter: number[];
  stageFilter: CaseStageType[];
  fieldRepFilter: number[];
  assignedToFilter: number[];
  organizationFilter: number[];
  tagsFilter: number[];
  partTypeFilter: PartType[];
  showOnlyOnHoldCases: boolean;
  skip: number;
  take: number;
}

function getDefaultState(
  hasRole: ((roles: UserRoleType[]) => boolean) | undefined,
  currentUserId: number | undefined,
) {
  const cache = localStorage.getItem('ListCases.cache');

  let defaultVariables: IListCaseQueryVariables;
  let initialState: ListCasesStateType;

  if (!cache) {
    const defaultFieldRepId =
      hasRole && hasRole([UserRoleType.FieldRep]) ? currentUserId : undefined;
    const isQualityEngineer = hasRole && hasRole([UserRoleType.QualityEngineer]);
    const defaultStageFilter = !isQualityEngineer
      ? [
          CaseStageType.Open,
          CaseStageType.Segmentation,
          CaseStageType.Planning,
          CaseStageType.Proposed,
          CaseStageType.Design,
          CaseStageType.QaReview,
          CaseStageType.Production,
          CaseStageType.Ready,
        ]
      : [CaseStageType.Proposed, CaseStageType.Design, CaseStageType.QaReview];

    defaultVariables = {
      assignedToFilter: [] as number[],
      organizationFilter: [] as number[],
      surgeonFilter: [] as number[],
      fieldRepFilter: defaultFieldRepId ? [defaultFieldRepId] : [],
      caseTypeFilter: [CaseType.Live],
      caseSpineTypeFilter: [],
      planVersion: undefined,
      caseCancellationTypeFilter: [CaseCancellationListType.None],
      stageFilter: defaultStageFilter,
      tagsFilter: [],
      showOnlyOnHoldCases: false,
      orderBy: { surgeryDate: 'asc' },
      search: '',
      skip: 0,
      partTypeFilter: [],
      take: Globals.DefaultListPageSize,
    };

    initialState = {
      assignedTo: '',
      organization: '',
      surgeon: '',
      fieldRep: defaultFieldRepId ? defaultFieldRepId.toString() : '',
      caseType: [CaseType.Live],
      stage: defaultStageFilter,
      caseCancellationType: CaseCancellationListType.None,
      planVersion: undefined,
      showOnlyOnHoldCases: false,
      orderBy: { surgeryDate: 'asc' },
      search: '',
      pageNumber: 0,
      pageSize: Globals.DefaultListPageSize,
      caseTags: [],
      partTypes: [],
    };

    localStorage.setItem(
      'ListCases.cache',
      JSON.stringify({ variables: defaultVariables, state: initialState }),
    );
  } else {
    const hydratedCache: {
      variables: IListCaseQueryVariables;
      state: ListCasesStateType;
    } = JSON.parse(cache);

    // Fix old cache data from local storage to make caseType an array
    if (!Array.isArray(hydratedCache.state.caseType)) {
      hydratedCache.state.caseType = [hydratedCache.state.caseType as unknown as CaseType];
      hydratedCache.variables.caseTypeFilter = hydratedCache.state.caseType;
    }

    defaultVariables = hydratedCache.variables;
    initialState = hydratedCache.state;
  }
  return [defaultVariables, initialState];
}

const ListCasesView = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { hasRole, hasPermission, user: currentUser } = useAuth();
  const [showCaseCreateDialog, setShowCaseCreateDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [cases, setCases] = useState({ cases: [], count: 0 });
  const [defaultVariables, initialState] = getDefaultState(hasRole, currentUser?.userId);
  const [findCasesQuery, { loading: casesLoading }] = useLazyQuery(FIND_CASES, {
    variables: defaultVariables,
    fetchPolicy: 'network-only',
  });

  const [state, dispatch] = useReducer(ListCasesReducer, initialState as ListCasesStateType);

  const actionButtons = [];
  if (hasPermission?.([Permission.ManageCase])) {
    actionButtons.push(
      <Button
        data-test-id={testing.toKebabCase('Create Case') + '-button'}
        variant={'contained'}
        color={'secondary'}
        onClick={() => setShowCaseCreateDialog(true)}
      >
        Create Case
      </Button>,
    );
  }

  if (hasRole?.([UserRoleType.SiteAdministrator])) {
    actionButtons.push(
      <ImportCaseButton
        onImport={(caseId) => {
          navigate(`/app/cases/${caseId}`);
        }}
      />,
    );
  }

  if (hasPermission?.([Permission.ReportExportCaseData])) {
    actionButtons.push(<ExportCasesButton />);
  }

  const handleLoad = async () => {
    let orderBy: any = state.orderBy;

    const orderByKey = Object.keys(orderBy)[0];

    if (orderByKey === 'surgeonId') {
      orderBy = { surgeonUser: { lastName: orderBy['surgeonId'] } };
    }

    if (orderByKey === 'assignedId') {
      orderBy = { assignedUser: { lastName: orderBy['assignedId'] } };
    }

    if (orderByKey === 'fieldRepId') {
      orderBy = { fieldRepUser: { lastName: orderBy['fieldRepId'] } };
    }

    if (orderByKey === 'organizationId') {
      orderBy = { organization: { name: orderBy['organizationId'] } };
    }

    const variables = {
      assignedToFilter: state.assignedTo !== '' ? [Number(state.assignedTo)] : undefined,
      organizationFilter: state.organization ? [state.organization] : undefined,
      surgeonFilter: state.surgeon ? [Number(state.surgeon)] : undefined,
      fieldRepFilter: state.fieldRep ? [Number(state.fieldRep)] : undefined,
      caseCancellationTypeFilter: state.caseCancellationType ? [state.caseCancellationType] : '',
      caseTypeFilter: state.showOnlyDemoCases ? [CaseType.Demo] : state.caseType,
      caseSpineTypeFilter: state.caseSpineType ? state.caseSpineType : undefined,
      planVersion: state.planVersion ? Number(state.planVersion) : undefined,
      stageFilter: state.stage,
      partTypeFilter: state.partTypes,
      tagsFilter: (state.caseTags || []).map((tagId: string) => Number(tagId)),
      showOnlyOnHoldCases: state.showOnlyOnHoldCases,
      orderBy,
      search: (state.search ?? '').trim(),
      take: state.pageSize,
      skip: state.pageSize && state.pageNumber ? state.pageSize * state.pageNumber : undefined,
    };

    findCasesQuery({ variables })
      .then((result) => {
        setLoading(false);
        setCases(result.data.cases);

        localStorage.setItem(
          'ListCases.cache',
          JSON.stringify({
            variables: variables,
            state: state,
          }),
        );
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    handleLoad();
  }, [state]);

  return (
    <>
      <Page title={'Cases'}>
        <Container maxWidth={false}>
          <CustomAppBar title={`Cases`} actions={actionButtons} />

          <Box display={'flex'}>
            <Box width={250} mr={2}>
              <Box display={'flex'} alignItems={'center'} height={40} mb={1}>
                <Heading label={'Filters'} />
              </Box>
              <Card>
                <CardContent style={{ minWidth: 150 }}>
                  <CaseTableFilter
                    state={state}
                    dispatch={dispatch}
                    loading={casesLoading || loading}
                    onChange={() => {
                      setLoading(true);
                    }}
                  />
                </CardContent>
              </Card>
            </Box>

            <Box width={'calc(100% - 266px)'}>
              <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mb={1}>
                <Heading label={`Results (${cases?.count ?? 0} cases)`} />
                <Box width={250} bgcolor={theme.palette.background.paper}>
                  <SearchInput
                    value={state.search ?? ''}
                    onChange={(value: string) => dispatch({ type: 'SEARCH_CHANGED', data: value })}
                  />
                </Box>
              </Box>
              <Box>
                <Card>
                  <CardContent style={{ height: 'calc(100vh - 228px)', display: 'flex' }}>
                    {cases?.count || loading || casesLoading ? (
                      <CaseListTable
                        loading={casesLoading || loading}
                        orderBy={state.orderBy}
                        dispatch={dispatch}
                        cases={cases?.cases}
                        page={state.pageNumber}
                        rowsPerPage={state.pageSize}
                        totalCaseCount={cases?.count ?? 0}
                        onSort={(orderBy) => {
                          setLoading(true);
                          dispatch({ type: 'ORDER_BY_CHANGED', data: orderBy });
                        }}
                        onPagination={(pageSize, pageNumber) => {
                          setLoading(true);
                          dispatch?.({
                            type: 'UPDATE_PAGINATION',
                            data: {
                              pageSize,
                              pageNumber,
                            },
                          });
                        }}
                      />
                    ) : (
                      <Box
                        margin={'auto'}
                        alignItems="center"
                        display="flex"
                        flexDirection="column"
                      >
                        <Typography variant="h4">No results found</Typography>
                        <Box my={1} />
                        <Button
                          variant="outlined"
                          color={'secondary'}
                          onClick={() => {
                            dispatch({ type: 'RESET_FILTERS' });
                          }}
                        >
                          Reset Filters
                        </Button>
                      </Box>
                    )}
                  </CardContent>
                </Card>
              </Box>
            </Box>
          </Box>
        </Container>
      </Page>

      {showCaseCreateDialog ? (
        <CreateCaseDialog
          open={showCaseCreateDialog}
          onCreate={(caseId) => {
            setShowCaseCreateDialog(false);
            navigate(`/app/cases/${caseId}`);
          }}
          onClose={() => {
            setShowCaseCreateDialog(false);
            handleLoad();
          }}
        />
      ) : null}
    </>
  );
};

export default ListCasesView;
