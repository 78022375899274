import {
  caseUtils,
  format,
  ICase,
  InterbodyScrewLengthTypes,
  LevelResult,
  LevelResultNotUsedReason,
  LevelSize,
  LevelType,
  PartType,
  IPlan,
  planUtils,
} from '@workflow-nx/common';
import { Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { SelectFieldRHF, TextFieldRHF } from '@workflow-nx/ui';
import { Control, FieldValues, useFormContext } from 'react-hook-form';
import { useSurgeonExcludedImplantSizes } from '../../../hooks/useSurgeonExcludedImplantSizes';

export type CaseResultType = {
  size: LevelSize | '';
  part: PartType | '';
  result: LevelResult | '';
  notUsedReasons: LevelResultNotUsedReason[] | [];
  exceptionReason: string;
  screwLengthLeft?: InterbodyScrewLengthTypes | '';
  screwLengthMiddle?: InterbodyScrewLengthTypes | '';
  screwLengthRight?: InterbodyScrewLengthTypes | '';
};

export type CaseResultsType = Record<LevelType, CaseResultType>;

export const getCaseResultsSchema = (activeCase: ICase) => {
  function getLevelValidationSchema(level: string) {
    const levelType = activeCase.levels[level];
    if (caseUtils.isValidLevelPartType(levelType)) {
      return Yup.object().shape({
        size: Yup.string().when(['result'], {
          is: LevelResult.Used,
          then: (schema) => schema.required(),
          otherwise: (schema) => schema.notRequired(),
        }),
        part: Yup.string().required(),
        result: Yup.string().required(),
        notUsedReasons: Yup.array().when(['result'], {
          is: (result: string) =>
            result === LevelResult.NotUsed || result === LevelResult.NotUsedBillable,
          then: (schema) => schema.required().min(1),
          otherwise: (schema) => schema.notRequired(),
        }),
        exceptionReason: Yup.string().when(['result', 'notUsedReasons'], {
          is: (result: string, notUsedReasons: string[]) =>
            result === LevelResult.Exception ||
            notUsedReasons.includes(LevelResultNotUsedReason.Other),
          then: (schema) => schema.required(),
          otherwise: (schema) => schema.notRequired(),
        }),
        screwLengthLeft: Yup.string().when(['result', 'part'], {
          is: (result: string, part: string) => {
            return (
              result === LevelResult.Used &&
              (part === PartType.ALIF_X_TWO_UP || part === PartType.ALIF_X_TWO_DOWN)
            );
          },
          then: (schema) => schema.required(),
          otherwise: (schema) => schema.notRequired(),
        }),
        screwLengthMiddle: Yup.string().when(['result', 'part'], {
          is: (result: string, part: string) =>
            result === LevelResult.Used &&
            (part === PartType.ALIF_X_TWO_UP || part === PartType.ALIF_X_TWO_DOWN),
          then: (schema) => schema.required(),
          otherwise: (schema) => schema.notRequired(),
        }),
        screwLengthRight: Yup.string().when(['result', 'part'], {
          is: (result: string, part: string) =>
            result === LevelResult.Used &&
            (part === PartType.ALIF_X_TWO_UP || part === PartType.ALIF_X_TWO_DOWN),
          then: (schema) => schema.required(),
          otherwise: (schema) => schema.notRequired(),
        }),
      });
    }
    return;
  }

  const spineProfileConfig = caseUtils.getCaseSpineProfile(activeCase.spineProfile);

  return Yup.object().shape(
    spineProfileConfig.validLevels.reduce(
      (prev, level) => ({
        ...prev,
        [level]: getLevelValidationSchema(level),
      }),
      {},
    ),
  );
};

const notUsedReasonsMenuItems = Object.values(LevelResultNotUsedReason).map((reason: string) => {
  return {
    key: reason,
    value: format.formatLevelResultNotUsedReason(reason as LevelResultNotUsedReason),
  };
});

export type CaseResultsFormValuesType = {
  [key: string]: {
    size: string;
    part: string;
    result: string;
    notUsedReasons: string[];
    exceptionReason: string;
    screwLengthLeft?: string | undefined;
    screwLengthMiddle?: string | undefined;
    screwLengthRight?: string | undefined;
  };
};

export function CaseResultsFormFields(props: {
  activeCase: ICase;
  plan: IPlan;
  isEditingAllowed: boolean;
  control: Control<FieldValues, CaseResultType>;
}) {
  const [isAlifXIncluded, setIsAlifXIncluded] = useState(false);
  const allowedImplantExclusionTypes = useSurgeonExcludedImplantSizes(
    props?.activeCase?.levels,
    props?.activeCase?.surgeonUser?.userId,
  );
  const profileLevels = caseUtils.getLevelsSortedByHierarchy(props.activeCase.spineProfile, 'desc');

  const excludedLevelsImplants = planUtils.getExcludedLevelsImplants(
    props.activeCase.levels,
    props.plan as IPlan,
  );

  const {
    watch,
    setValue,
    formState: { isSubmitting },
  } = useFormContext();

  useEffect(() => {
    const hasValue =
      Object.values(props.activeCase.levels).includes(PartType.ALIF_X_TWO_DOWN) ||
      Object.values(props.activeCase.levels).includes(PartType.ALIF_X_TWO_UP);
    setIsAlifXIncluded(hasValue);
  }, [props.activeCase.levels]);

  return (
    <Stack spacing={5}>
      {profileLevels.map((key) => {
        const partType = props.activeCase.levels[key] as PartType;
        const levelResult = watch(`${key}.result`);
        const isLevelNotUsed =
          levelResult === LevelResult.NotUsed || levelResult === LevelResult.NotUsedBillable;
        const isSurgeryResultAnException =
          levelResult === LevelResult.Exception ||
          levelResult === LevelResult.NotUsed ||
          levelResult === LevelResult.NotUsedBillable;
        const isFieldDisabled = isSubmitting || !props.isEditingAllowed;

        const excludedImplantSize = planUtils.getExcludedImplantSize(key, excludedLevelsImplants);
        const isPlusSizeExcluded =
          excludedImplantSize === LevelSize.Plus && allowedImplantExclusionTypes?.length;
        const isMinusSizeExcluded =
          excludedImplantSize === LevelSize.Minus && allowedImplantExclusionTypes?.length;

        const levelSizeMenuItems = [];
        if (!isMinusSizeExcluded) {
          levelSizeMenuItems.push({
            key: LevelSize.Minus,
            value: 'Minus',
          });
        }
        levelSizeMenuItems.push({
          key: LevelSize.Normal,
          value: 'Plan',
        });
        if (!isPlusSizeExcluded) {
          levelSizeMenuItems.push({
            key: LevelSize.Plus,
            value: 'Plus',
          });
        }

        return !caseUtils.isValidLevelPartType(partType) ? null : (
          <Stack
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'flex-start'}
            key={key}
          >
            <Stack justifyContent={'center'} alignItems={'flex-start'} width={'225px'}>
              <Typography variant={'h5'} textAlign={'left'}>
                {format.formatInterbodyLevel(key as LevelType)}
                {`  -  `}
                {format.formatPartType(partType as PartType)}
              </Typography>
            </Stack>
            <Grid container rowSpacing={1} columnSpacing={2}>
              <Grid item xs={isAlifXIncluded ? 12 / 5 : 6}>
                <SelectFieldRHF
                  fullWidth={true}
                  name={`${key}.result`}
                  control={props.control}
                  label={'Result'}
                  menuItems={[
                    {
                      key: LevelResult.Used,
                      value: 'Used',
                    },
                    {
                      key: LevelResult.NotUsed,
                      value: 'Not Used',
                    },
                    {
                      key: LevelResult.NotUsedBillable,
                      value: 'Not Used (Billable)',
                    },
                    {
                      key: LevelResult.Exception,
                      value: 'Exception',
                    },
                  ]}
                  disabled={isFieldDisabled}
                />
              </Grid>
              <Grid item xs={isAlifXIncluded ? 12 / 5 : 6}>
                <SelectFieldRHF
                  name={`${key}.size`}
                  control={props.control}
                  label={'Size'}
                  menuItems={levelSizeMenuItems}
                  disabled={isFieldDisabled}
                />
              </Grid>
              {isAlifXIncluded ? (
                [PartType.ALIF_X_TWO_DOWN, PartType.ALIF_X_TWO_UP].includes(partType) ? (
                  <>
                    <Grid item xs={12 / 5}>
                      <SelectFieldRHF
                        name={`${key}.screwLengthLeft`}
                        control={props.control}
                        label={'Screw Left Length'}
                        menuItems={[
                          {
                            key: InterbodyScrewLengthTypes.None,
                            value: 'None',
                          },
                          {
                            key: InterbodyScrewLengthTypes.AlifXScrewLength20mm,
                            value: '20MM',
                          },
                          {
                            key: InterbodyScrewLengthTypes.AlifXScrewLength25mm,
                            value: '25MM',
                          },
                          {
                            key: InterbodyScrewLengthTypes.AlifXScrewLength30mm,
                            value: '30MM',
                          },
                        ]}
                        disabled={isFieldDisabled}
                        fullWidth
                        hideNone
                      />
                    </Grid>
                    <Grid item xs={12 / 5}>
                      <SelectFieldRHF
                        name={`${key}.screwLengthMiddle`}
                        control={props.control}
                        label={'Screw Middle Length'}
                        menuItems={[
                          {
                            key: InterbodyScrewLengthTypes.None,
                            value: 'None',
                          },
                          {
                            key: InterbodyScrewLengthTypes.AlifXScrewLength20mm,
                            value: '20MM',
                          },
                          {
                            key: InterbodyScrewLengthTypes.AlifXScrewLength25mm,
                            value: '25MM',
                          },
                          {
                            key: InterbodyScrewLengthTypes.AlifXScrewLength30mm,
                            value: '30MM',
                          },
                        ]}
                        disabled={isFieldDisabled}
                        fullWidth
                        hideNone
                      />
                    </Grid>
                    <Grid item xs={12 / 5}>
                      <SelectFieldRHF
                        name={`${key}.screwLengthRight`}
                        control={props.control}
                        label={'Screw Right Length'}
                        menuItems={[
                          {
                            key: InterbodyScrewLengthTypes.None,
                            value: 'None',
                          },
                          {
                            key: InterbodyScrewLengthTypes.AlifXScrewLength20mm,
                            value: '20MM',
                          },
                          {
                            key: InterbodyScrewLengthTypes.AlifXScrewLength25mm,
                            value: '25MM',
                          },
                          {
                            key: InterbodyScrewLengthTypes.AlifXScrewLength30mm,
                            value: '30MM',
                          },
                        ]}
                        disabled={isFieldDisabled}
                        fullWidth
                        hideNone
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={12 / 5}>
                      <Typography
                        color={'textSecondary'}
                        variant={'body1'}
                        align={'center'}
                        flexBasis={'50%'}
                      >
                        <span>&mdash;</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12 / 5}>
                      <Typography
                        color={'textSecondary'}
                        variant={'body1'}
                        align={'center'}
                        flexBasis={'50%'}
                      >
                        <span>&mdash;</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12 / 5}>
                      <Typography
                        color={'textSecondary'}
                        variant={'body1'}
                        align={'center'}
                        flexBasis={'50%'}
                      >
                        <span>&mdash;</span>
                      </Typography>
                    </Grid>
                  </>
                )
              ) : null}
              <Grid item xs={2 * (12 / 5)} alignSelf={'stretch'}>
                <SelectFieldRHF
                  name={`${key}.notUsedReasons`}
                  control={props.control}
                  label={'Reason? (select all that apply)'}
                  menuItems={notUsedReasonsMenuItems}
                  onChange={(event) => {
                    setValue(`${key}.notUsedReasons`, event.target.value);
                  }}
                  disabled={isFieldDisabled || !isLevelNotUsed}
                  multiple
                  hideNone
                />
              </Grid>
              <Grid item xs={12 - 2 * (12 / 5)} alignSelf={'stretch'}>
                <TextFieldRHF
                  name={`${key}.exceptionReason`}
                  control={props.control}
                  label={'Additional Note'}
                  disabled={isFieldDisabled || !isSurgeryResultAnException}
                  fullHeight
                  matchHeight
                />
              </Grid>
            </Grid>
          </Stack>
        );
      })}
    </Stack>
  );
}
