import React from 'react';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import { Box, Grid } from '@mui/material';
import CustomDialog from '../../../components/CustomDialog';
import * as Yup from 'yup';
import { ProgressButton, SelectField, TextField } from '@workflow-nx/ui';
import { useMutation } from '@apollo/client';
import { HOLD_CASE } from '../../../gql';
import { ICase, format, CaseHoldReasonType } from '@workflow-nx/common';
import { useSnackbar } from 'notistack';
import { useConfirm } from 'material-ui-confirm';

export function CaseHoldDialog(props: {
  activeCase: ICase;
  open: boolean;
  onClose: (shouldUpdate: boolean) => void;
}) {
  const [holdCase] = useMutation(HOLD_CASE);
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();

  async function handleHoldCase(holdType: CaseHoldReasonType, reason: string) {
    try {
      const holdReasonDescription = format.formatCaseHoldReasonType(holdType);

      await confirm({
        title: `Confirm Hold on Case ${props.activeCase.number}?`,
        description: (
          <>
            This will reset the surgery date to TBD and mark the case as ON-HOLD for the reason:{' '}
            <strong>{holdReasonDescription}</strong>.
          </>
        ),
      });

      await holdCase({
        variables: {
          caseId: props.activeCase.caseId,
          holdType,
          reason,
        },
      });

      enqueueSnackbar(
        `Case ${props.activeCase.number} surgery date was successfully placed on hold.`,
        {
          variant: 'success',
        },
      );

      props.onClose(true);
    } catch (errors) {
      console.error(errors);
      enqueueSnackbar('Error placing case on hold', {
        variant: 'error',
      });
    }
  }

  const handleSubmitForm = async (values: FormikValues, { setSubmitting }: FormikHelpers<any>) => {
    const { holdType, reason } = values;
    try {
      await handleHoldCase(holdType, reason);
    } finally {
      setSubmitting(false);
    }
  };

  const caseHoldReasonTypes = [
    {
      key: CaseHoldReasonType.Administrative,
      value: format.formatCaseHoldReasonType(CaseHoldReasonType.Administrative),
    },
    {
      key: CaseHoldReasonType.Medical,
      value: format.formatCaseHoldReasonType(CaseHoldReasonType.Medical),
    },
    {
      key: CaseHoldReasonType.PendingCaseApproval,
      value: format.formatCaseHoldReasonType(CaseHoldReasonType.PendingCaseApproval),
    },
    {
      key: CaseHoldReasonType.Unknown,
      value: format.formatCaseHoldReasonType(CaseHoldReasonType.Unknown),
    },
  ];

  return (
    <Formik
      initialValues={{
        holdType: '',
        reason: '',
      }}
      validationSchema={Yup.object().shape({
        holdType: Yup.string().required(),
        reason: Yup.string().required(),
      })}
      onSubmit={handleSubmitForm}
      enableReinitialize={true}
    >
      {({ validateForm, values, handleSubmit, isSubmitting, submitForm }) => (
        <CustomDialog
          maxWidth={'sm'}
          open={props.open}
          title={`Place Hold on Case ${props.activeCase.number}`}
          onClose={() => {
            props.onClose(false);
          }}
          positiveActionButtons={[
            <ProgressButton
              onClick={async () => {
                await validateForm(values);
                await submitForm();
              }}
              loading={isSubmitting}
              disabled={isSubmitting}
              label={'Place Surgery Date On-Hold'}
            />,
          ]}
        >
          {props.open ? (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SelectField
                    name={'holdType'}
                    label={'Hold Type'}
                    required={true}
                    hideNone={true}
                    menuItems={caseHoldReasonTypes}
                    disabled={isSubmitting}
                  />
                </Grid>
                <Grid item xs>
                  <TextField
                    disabled={isSubmitting}
                    name="reason"
                    required={true}
                    label={'Reason'}
                    multiline={true}
                  />
                </Grid>
              </Grid>
              <Box mt={1} />
            </form>
          ) : null}
        </CustomDialog>
      )}
    </Formik>
  );
}
