import { projectVectorOntoPlane } from '@workflow-nx/core-algorithms';
import * as BABYLON from 'babylonjs';
import { Vector3 } from './BabylonScene';

export const getMidpoint = (v1: Vector3, v2: Vector3): Vector3 => {
  return {
    x: (v1.x + v2.x) / 2,
    y: (v1.y + v2.y) / 2,
    z: (v1.z + v2.z) / 2,
  };
};

export const normalize = (v: Vector3): Vector3 => {
  const babylonVector = new BABYLON.Vector3(v.x, v.y, v.z);

  const normalized = BABYLON.Vector3.Normalize(babylonVector);

  return {
    x: normalized.x,
    y: normalized.y,
    z: normalized.z,
  };
};

export const cross = (v1: Vector3, v2: Vector3): Vector3 => {
  const babylonVector1 = new BABYLON.Vector3(v1.x, v1.y, v1.z);
  const babylonVector2 = new BABYLON.Vector3(v2.x, v2.y, v2.z);

  const cross = BABYLON.Vector3.Cross(babylonVector1, babylonVector2);

  return {
    x: cross.x,
    y: cross.y,
    z: cross.z,
  };
};

export const dot = (v1: Vector3, v2: Vector3): number => {
  const babylonVector1 = new BABYLON.Vector3(v1.x, v1.y, v1.z);
  const babylonVector2 = new BABYLON.Vector3(v2.x, v2.y, v2.z);

  const dot = BABYLON.Vector3.Dot(babylonVector1, babylonVector2);

  return dot;
};

export const subtract = (v1: Vector3, v2: Vector3): Vector3 => {
  return {
    x: v1.x - v2.x,
    y: v1.y - v2.y,
    z: v1.z - v2.z,
  };
};

export const multiply = (v: Vector3, value: number): Vector3 => {
  return {
    x: v.x * value,
    y: v.y * value,
    z: v.z * value,
  };
};

export const add = (v1: Vector3, v2: Vector3): Vector3 => {
  return {
    x: v1.x + v2.x,
    y: v1.y + v2.y,
    z: v1.z + v2.z,
  };
};

export const divide = (v: Vector3, value: number): Vector3 => {
  return {
    x: v.x / value,
    y: v.y / value,
    z: v.z / value,
  };
};

export const projectVectorToPlane = (v: Vector3, normal: Vector3): Vector3 => {
  const babylonV = new BABYLON.Vector3(v.x, v.y, v.z);

  const babylonNormal = new BABYLON.Vector3(normal.x, normal.y, normal.z);

  const plane = BABYLON.Plane.FromPositionAndNormal(BABYLON.Vector3.Zero(), babylonNormal);

  const proj = projectVectorOntoPlane(babylonV, plane);

  return {
    x: proj.x,
    y: proj.y,
    z: proj.z,
  };
};

export const findLateralPlaneAngle = (v1: Vector3, v2: Vector3): number => {
  const vector1 = new BABYLON.Vector3(v1.x, v1.y, v1.z);
  const vector2 = new BABYLON.Vector3(v2.x, v2.y, v2.z);

  let normalizedVector1 = vector1.normalize();
  let normalizedVector2 = vector2.normalize();

  // Calculate the dot product
  let dotProduct = BABYLON.Vector3.Dot(normalizedVector1, normalizedVector2);

  // Calculate the angle in radians
  let angleInRadians = Math.acos(dotProduct);

  // Convert the angle to degrees
  let angleInDegrees = BABYLON.Tools.ToDegrees(angleInRadians);

  return angleInDegrees;
};
