import { ApolloQueryResult } from '@apollo/client/core/types';
import { CaseStageType, CaseType, EventType } from '@workflow-nx/common';
import { CalendarCaseCategory, CASE_CATEGORY_CLASSIFICATION } from './config';
import { getMonthStartAndEndISODate } from './CaseCalendar.view';

export type CaseCalendarActionType = {
  type: string;
  data?: any;
};

export type CaseCalendarStateType = {
  fieldRep?: string;
  organization?: string;
  surgeon?: string;
  caseStatus?: string;
  orderBy?: { [key: string]: 'asc' | 'desc' };
  caseTags?: string[];
  search?: string;
  startDate?: string;
  endDate?: string;
  showTentativeSurgeryDates?: boolean;
  showDraftCases?: boolean;
};

export const CaseCalendarReducer =
  (refetch: (variables?: Partial<any>) => Promise<ApolloQueryResult<any>>) =>
  (state: CaseCalendarStateType, action: CaseCalendarActionType): CaseCalendarStateType => {
    let updatedState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
      case 'refetch':
        refetch();
        break;
      case 'RESET_FILTERS':
        const [startDate, endDate] = getMonthStartAndEndISODate(new Date());

        updatedState = {
          organization: '',
          surgeon: '',
          fieldRep: '',
          caseStatus: '',
          startDate: startDate,
          endDate: endDate,
          orderBy: { surgeryDate: 'asc' },
          caseTags: [],
          showTentativeSurgeryDates: true,
          showDraftCases: false,
        };
        localStorage.removeItem('CaseCalendar.cache');
        break;
      case 'SEARCH_CHANGED':
        updatedState.search = action.data;
        break;
      case 'ORDER_BY_CHANGED':
        updatedState.orderBy = action.data;
        break;
      case 'FIELD_REP_CHANGED':
        updatedState.fieldRep = Number(action.data);
        break;
      case 'SURGEON_CHANGED':
        updatedState.surgeon = Number(action.data);
        break;
      case 'ORGANIZATION_CHANGED':
        updatedState.organization = Number(action.data);
        break;
      case 'CASE_STATUS_CHANGED':
        updatedState.caseStatus = action.data;
        break;
      case 'CASE_DATE_RANGE_CHANGED':
        updatedState.startDate = action.data.startDate;

        updatedState.endDate = action.data.endDate;

        break;
      case 'CASE_TAGS_CHANGED':
        updatedState.caseTags = action.data;
        break;
      case 'SHOW_TENTATIVE_SURGERY_DATES_CHANGED':
        updatedState.showTentativeSurgeryDates = action.data;
        break;
      case 'SHOW_DRAFT_CASES_CHANGED':
        updatedState.showDraftCases = action.data;
        break;
    }

    let orderBy = updatedState.orderBy;

    if (Object.keys(orderBy)[0] === 'surgeonId') {
      orderBy = { surgeonUser: { lastName: orderBy['surgeonId'] } };
    }

    if (Object.keys(orderBy)[0] === 'fieldRepId') {
      orderBy = { fieldRepUser: { lastName: orderBy['fieldRepId'] } };
    }

    if (Object.keys(orderBy)[0] === 'organizationId') {
      orderBy = { organization: { name: orderBy['organizationId'] } };
    }

    const variables = {
      organizationFilter: updatedState.organization ? [updatedState.organization] : undefined,
      surgeonFilter: updatedState.surgeon ? [updatedState.surgeon] : undefined,
      fieldRepFilter: updatedState.fieldRep ? [updatedState.fieldRep] : undefined,
      tagsFilter: (updatedState.caseTags || []).map((tagId: string) => Number(tagId)),
      orderBy,
      search: updatedState.search,
      stageFilter: [] as CaseStageType[],
      eventTypeFilter: [] as EventType[],
      startDate: updatedState.startDate,
      endDate: updatedState.endDate,
      showTentativeSurgeryDates: updatedState.showTentativeSurgeryDates,
      caseTypeFilter: updatedState.showDraftCases
        ? [CaseType.Live, CaseType.Draft]
        : [CaseType.Live],
    };

    if (updatedState.caseStatus) {
      const categoryReq =
        CASE_CATEGORY_CLASSIFICATION[updatedState.caseStatus as CalendarCaseCategory];

      if (categoryReq.stages) variables.stageFilter = categoryReq.stages;

      if (categoryReq.hasHospitalDelivery)
        variables.eventTypeFilter = [EventType.HospitalDeliveryComplete];
    }

    refetch(variables);

    localStorage.setItem(
      'CaseCalendar.cache',
      JSON.stringify({
        variables: variables,
        state: updatedState,
      }),
    );

    return updatedState;
  };
