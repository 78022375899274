import { CaseSpineType, ICase, format } from '@workflow-nx/common';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useMutation } from '@apollo/client';
import { UPDATE_PATIENT_RECORD } from '../../../gql';
import { Formik, FormikHelpers } from 'formik';
import {
  IUpdatePatientRecordsFormValues,
  updatePatientRecordsFormValues,
} from '../../../extras/formValues';
import { updatePatientRecordsSchema } from '../../../extras/schemas';
import { Box, Grid } from '@mui/material';
import CustomDialog from '../../../components/CustomDialog';
import { NumberTextField } from '../../../components/NumberTextField';
import { ProgressButton, SelectField } from '@workflow-nx/ui';

export function EditPatientRecordsDialog({
  activeCase,
  open,
  onClose,
}: {
  open: boolean;
  activeCase: ICase;
  onClose: (shouldUpdate: boolean) => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [updatePatientRecord, { loading: loadingUpdatePatientRecord }] =
    useMutation(UPDATE_PATIENT_RECORD);

  const handleSubmitForm = async (
    values: IUpdatePatientRecordsFormValues,
    { setStatus, setSubmitting }: FormikHelpers<IUpdatePatientRecordsFormValues>,
  ) => {
    try {
      const patient = activeCase.patient;
      await updatePatientRecord({
        variables: {
          patientId: patient.patientId,
          bmi: values.bmi ? Number(values.bmi) : undefined,
          odi: values.odi ? Number(values.odi) : undefined,
          boneDensity: values.boneDensity,
          tScoreSpine: values.tScoreSpine ? Number(values.tScoreSpine) : undefined,
          tScoreHip: values.tScoreHip ? Number(values.tScoreHip) : undefined,
          vasBack: values.vasBack ? Number(values.vasBack) : undefined,
          vasLeg: values.vasLeg ? Number(values.vasLeg) : undefined,
          pelvicIncidence: values.pelvicIncidence ? Number(values.pelvicIncidence) : undefined,
          lumbarLordosis: values.lumbarLordosis ? Number(values.lumbarLordosis) : undefined,
          coronalBalance: values.coronalBalance ? Number(values.coronalBalance) : undefined,
          slopeOfLineOfSight: values.slopeOfLineOfSight
            ? Number(values.slopeOfLineOfSight)
            : undefined,
          sagittalVerticalAxis: values.sagittalVerticalAxis
            ? Number(values.sagittalVerticalAxis)
            : undefined,
          lumbarCoronalCobb: values.lumbarCoronalCobb
            ? Number(values.lumbarCoronalCobb)
            : undefined,
        },
      });

      setStatus({ success: true });
      enqueueSnackbar(`Patient ${format.formatName(patient)} records updated`, {
        variant: 'success',
      });

      onClose(true);
    } catch (err) {
      console.error(err);
      setStatus({ success: false });
      enqueueSnackbar('An error occurred updating the patient records', {
        variant: 'error',
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={updatePatientRecordsFormValues(activeCase)}
      validationSchema={updatePatientRecordsSchema}
      onSubmit={handleSubmitForm}
      enableReinitialize={true}
    >
      {({ handleSubmit, isSubmitting, submitForm }) => {
        const loading = isSubmitting || loadingUpdatePatientRecord;

        return (
          <CustomDialog
            maxWidth={'lg'}
            open={open}
            title={`Editing Patient ${format.formatName(activeCase.patient)}`}
            onClose={() => {
              onClose(false);
            }}
            positiveActionButtons={[
              <ProgressButton
                onClick={() => submitForm()}
                loading={loading}
                disabled={loading}
                label={'Save'}
              />,
            ]}
          >
            {open ? (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    {activeCase.spineType === CaseSpineType.Lumbar ? (
                      <NumberTextField
                        decimalPlaces={2}
                        name={'pelvicIncidence'}
                        label={'Pelvic Incidence(1\u00b0 to 180\u00b0)'}
                        disabled={loading}
                      />
                    ) : activeCase.spineType === CaseSpineType.Cervical ? (
                      <NumberTextField
                        name={'slopeOfLineOfSight'}
                        label={'Slope of Line of Sight'}
                        disabled={loading}
                      />
                    ) : null}
                  </Grid>
                  <Grid item xs={4}>
                    <NumberTextField
                      decimalPlaces={2}
                      name={'lumbarLordosis'}
                      label={'Lumbar Lordosis'}
                      disabled={loading}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <NumberTextField
                      decimalPlaces={2}
                      name={'lumbarCoronalCobb'}
                      label={'Lumbar Coronal Cobb'}
                      disabled={loading}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <NumberTextField
                      decimalPlaces={2}
                      name={'sagittalVerticalAxis'}
                      label={'SVA'}
                      disabled={loading}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <NumberTextField
                      decimalPlaces={2}
                      name={'coronalBalance'}
                      label={'Coronal Balance'}
                      disabled={loading}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <NumberTextField
                      name={'bmi'}
                      label={'BMI'}
                      disabled={loading}
                      decimalPlaces={2}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <NumberTextField
                      name={'odi'}
                      label={'ODI'}
                      disabled={loading}
                      decimalPlaces={2}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <SelectField
                      name={'boneDensity'}
                      label={'Bone Density'}
                      menuItems={[
                        { key: 'NORMAL', value: 'Normal' },
                        { key: 'OSTEOPENIA', value: 'Osteopenia' },
                        { key: 'OSTEOPOROSIS', value: 'Osteoporosis' },
                      ]}
                      disabled={loading}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <NumberTextField
                      name={'vasBack'}
                      label={'VAS (Back)'}
                      disabled={loading}
                      decimalPlaces={2}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <NumberTextField
                      name={'vasLeg'}
                      label={'VAS (Leg)'}
                      disabled={loading}
                      decimalPlaces={2}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <NumberTextField
                      name={'tScoreHip'}
                      label={'t-score (Hip)'}
                      disabled={loading}
                      decimalPlaces={2}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <NumberTextField
                      name={'tScoreSpine'}
                      label={'t-score (Spine)'}
                      disabled={loading}
                      decimalPlaces={2}
                    />
                  </Grid>
                </Grid>
                <Box mt={1} />
              </form>
            ) : null}
          </CustomDialog>
        );
      }}
    </Formik>
  );
}
