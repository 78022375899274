import { Box, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { ExportCasesButtonDwh } from '../cases/ListCasesView/ExportCasesButtonDwh';
import DatePickerValue from '../../components/DatePickerValue';

import { DashboardCardView } from './DashboardCardView';

export default function ExportCasesForm() {
  const [dailySnapshotDate, setDailySnapshotDate] = useState(new Date().toISOString());
  /**
   * Data warehouse daily snapshot start date is 2023-10-17'
   */
  const snapshotMinDate = DateTime.fromISO('2023-10-17');

  return (
    <DashboardCardView fitContentHeight label="">
      <Stack direction={'row'} alignItems={'center'} spacing={2}>
        <Box>
          <Typography variant={'h4'} noWrap={true}>
            Export Cases
          </Typography>
          <Typography variant={'body1'} color={'textSecondary'} noWrap={true}>
            The export contains all of the data up until 1am of the selected day.
          </Typography>
        </Box>
        <Box flexGrow={1} />
        <DatePickerValue
          variant={'standard'}
          minDate={snapshotMinDate}
          disableFuture={true}
          value={dailySnapshotDate ? DateTime.fromISO(dailySnapshotDate).toJSDate() : null}
          onChange={(date) => setDailySnapshotDate(date)}
        />
        <ExportCasesButtonDwh dailySnapshotDate={dailySnapshotDate} />
      </Stack>
    </DashboardCardView>
  );
}
