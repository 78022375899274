import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import React, { Dispatch, useState } from 'react';
import { format, ICase, Permission } from '@workflow-nx/common';
import { date } from '@workflow-nx/utils';
import { CaseViewActionType } from '../CaseView';
import useAuth from '../../../../hooks/useAuth';
import { EditPatientDetailsDialog } from '../EditPatientDetailsDialog';

type PatientTabViewProps = {
  activeCase: ICase;
  dispatch: Dispatch<CaseViewActionType>;
};

export function PatientDetailsView({ activeCase, dispatch }: PatientTabViewProps) {
  const [openEditPatientDialog, setOpenEditPatientDialog] = useState(false);
  const patient = activeCase?.patient;
  const auth = useAuth();
  const isPatientEditable = auth?.hasPermission?.([Permission.EditCase, Permission.ManageCase]);
  const emptyValue: JSX.Element = <>&mdash;</>;
  let hasPermissionToSchedule;
  if (patient?.hasPermissionToSchedule === null) {
    hasPermissionToSchedule = emptyValue;
  } else {
    patient?.hasPermissionToSchedule
      ? (hasPermissionToSchedule = 'Yes')
      : (hasPermissionToSchedule = 'No');
  }
  let hasPermissionToContact;
  if (patient?.hasPermissionToContact === null) {
    hasPermissionToContact = emptyValue;
  } else {
    patient?.hasPermissionToContact
      ? (hasPermissionToContact = 'Yes')
      : (hasPermissionToContact = 'No');
  }

  const handleUpdate = (shouldUpdate: boolean) => {
    if (shouldUpdate) {
      dispatch({ type: 'refetch' });
    }
    setOpenEditPatientDialog(false);
  };

  const patientDetailsData = [
    { title: 'First Name', data: patient?.firstName },
    { title: 'Middle Name', data: patient?.middleName },
    { title: 'Last Name', data: patient?.lastName },
    {
      title: 'Birth Date',
      data: patient?.birthDate
        ? `${format.formatDate(
            date.parseCalendarDateFromString(patient?.birthDate as string) as Date,
          )} / ${date.distanceInYears(patient?.birthDate as string)}yo`
        : emptyValue,
    },
    {
      title: 'Gender',
      data: patient?.gender ? format.formatGender(patient.gender as 'MALE' | 'FEMALE') : emptyValue,
    },
    { title: 'MRN', data: patient?.mrn },
    { title: 'Phone Number', data: format.formatPhoneNumber(patient?.phoneNumber as string) },
    { title: 'Email', data: patient?.email },
    {
      title: 'Contact Preference',
      data: patient?.contactPreference
        ? format.formatPatientContactPreferenceType(patient?.contactPreference)
        : emptyValue,
    },
    {
      title: 'Contact Cadence',
      data: patient.contactCadence
        ? format.formatPatientContactCadenceType(patient?.contactCadence)
        : emptyValue,
    },
    {
      title: 'Permission to contact',
      data: hasPermissionToContact,
    },
    {
      title: 'Permission to schedule',
      data: hasPermissionToSchedule,
    },
    { title: 'COMPASS Participant ID', data: patient?.participantId },
  ];

  return (
    <>
      <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>
        {/* <Grid container spacing={2}> */}

        {patientDetailsData.map((element, index) => {
          return (
            <Box key={index}>
              <Box
                display={'flex'}
                justifyContent="space-between"
                alignContent={'center'}
                bgcolor={''}
                p={0.5}
              >
                <Typography variant={'body1'} color={'textSecondary'}>
                  {element.title}
                </Typography>
                <Typography variant={'body1'} color={'textPrimary'}>
                  {element.data ? element.data : emptyValue}
                </Typography>
              </Box>
              {index === 4 || index === 7 ? (
                <Box pt={1} pb={1}>
                  <Divider />
                </Box>
              ) : null}
            </Box>
          );
        })}
        {isPatientEditable ? (
          <Grid container item xs={12} justifyContent={'center'}>
            <Button variant={'contained'} onClick={() => setOpenEditPatientDialog(true)}>
              Edit
            </Button>
          </Grid>
        ) : null}
      </Box>

      <EditPatientDetailsDialog
        open={openEditPatientDialog}
        activeCase={activeCase}
        onClose={handleUpdate}
      />
    </>
  );
}
