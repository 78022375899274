import { forwardRef, ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import config from '../extras/config';

type PagePropsType = {
  children: ReactNode;
  title?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
  },
}));

const environmentName =
  config.environmentName !== 'production'
    ? `aprevo® DPS  ${config.environmentName}`
    : 'aprevo® DPS ';

const Page = forwardRef<HTMLDivElement, PagePropsType>((props: PagePropsType, ref) => {
  const styles = useStyles();

  return (
    <div ref={ref} className={styles.root}>
      {/* @ts-ignore */}
      <Helmet>
        <title>
          {environmentName} {props.title ? ` - ${props.title}` : ''}
        </title>
      </Helmet>
      {props.children}
    </div>
  );
});

export default Page;
