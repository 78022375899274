import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import { Alert, Box, Button, Grid, LinearProgress, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  AssetType,
  CaseType,
  ILocation,
  IUser,
  ImportCasePayload,
  UserRoleType,
  ValidFileExtensions,
  format,
} from '@workflow-nx/common';
import { ProgressButton, SelectField, TextField } from '@workflow-nx/ui';
import { file } from '@workflow-nx/utils';
import clsx from 'clsx';
import { Formik, FormikHelpers, useField } from 'formik';
import JSZip from '@progress/jszip-esm';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone';
import * as Yup from 'yup';
import CustomDialog from '../../../components/CustomDialog';
import { DatePickerField } from '../../../components/DatePickerField';
import { FormikUserAutocomplete } from '../../../components/UserAutocomplete';
import { COMPLETE_CASE_IMPORT, FIND_LOCATIONS, FIND_USERS, IMPORT_CASE } from '../../../gql';
import { groupUsersByRole } from '../../../utils/user';
import {
  EXPORT_CASE_MAIN_DATA_FILENAME,
  generateExportedAssetFilename,
} from '../CaseView/ExportCaseButton';

const useStyles = makeStyles(() => ({
  dialogContainer: {
    flex: 1,
    width: '100%',
  },
  dialogContainerContent: {
    display: 'flex',
    maxWidth: '100%',
  },
}));

const importCaseDialogSchema = Yup.object().shape({
  receivedAt: Yup.date().required('Field is required'),
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  assignedId: Yup.number().nullable(),
  fieldRepId: Yup.number().nullable(),
  surgeonId: Yup.number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .required('Field is required'),
  locationId: Yup.number().required('Field is required'),
});

const ImportCaseModalContent: React.FC<{
  disabled: boolean;
  exportFile: File | null;
  onFileChange: (f: File | null) => void;
}> = ({ disabled, exportFile, onFileChange }) => {
  const [surgeon, setSurgeon] = useState<IUser>();
  const [, , locationIdHelpers] = useField('locationId');
  const [, , fieldRepIdHelpers] = useField('fieldRepId');
  const [surgeons, setSurgeons] = useState<IUser[]>([]);
  const [assignees, setAssignees] = useState<IUser[]>([]);
  const [fieldReps, setFieldReps] = useState<IUser[]>([]);
  const [locations, setLocations] = useState<{ key: string; value: string }[]>([]);
  const [surgeonId, setSurgeonId] = useState<number | undefined>();

  useQuery(FIND_USERS, {
    onCompleted: (data) => {
      const users = data.users;
      if (users?.count > 0) {
        const { surgeons, assignees, fieldReps } = groupUsersByRole(users?.users);
        const surgeon = (users?.users as IUser[]).find((user) => user.userId === surgeonId);

        setSurgeon(surgeon);
        setSurgeons(surgeons);
        setAssignees(assignees);
        setFieldReps(fieldReps);
      }
    },
  });
  const [findLocations] = useLazyQuery(FIND_LOCATIONS, {
    onCompleted: (data) => {
      const locationsBySurgeon = data?.locations || [];

      setLocations(
        locationsBySurgeon.map((location: ILocation) => {
          return { key: location.locationId as unknown as string, value: location.description };
        }),
      );

      const defaultLocationId = surgeon?.location?.locationId;
      const surgeonLocation = data.locations.find(
        (location: ILocation) => location.locationId === defaultLocationId,
      );
      const territoryId = surgeonLocation?.territory?.territoryId;

      if (territoryId) {
        const fieldRep = fieldReps.find(
          (fieldRep: IUser) =>
            fieldRep?.userRegionTerritories?.[0]?.territory?.territoryId === territoryId,
        );
        fieldRepIdHelpers.setValue(fieldRep?.userId);
      }

      if (
        locationsBySurgeon.some((location: ILocation) => location.locationId === defaultLocationId)
      ) {
        locationIdHelpers?.setValue(defaultLocationId);
      } else {
        locationIdHelpers?.setValue(locationsBySurgeon?.[0]?.locationId);
      }
    },
  });

  useEffect(() => {
    setLocations([]);
    locationIdHelpers?.setValue('');

    if (surgeon) {
      const organizationId = surgeon?.organization?.organizationId;

      findLocations({
        variables: { organizationId: organizationId },
      });
    }
  }, [surgeon]);

  const handleZipSelect = async (file: File) => {
    onFileChange(file);
  };

  const handleClearSelectedZip = () => {
    onFileChange(null);
  };

  return (
    <Box>
      <Box mb={3}>
        <Typography variant="h5" mb={3}>
          Case Information
        </Typography>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <SelectField
                name={'caseType'}
                label={'Case Type'}
                hideNone={true}
                menuItems={Object.values(CaseType).map((ct) => ({
                  key: ct,
                  value: format.formatCaseType(ct),
                }))}
              />
            </Grid>
            <Grid item xs={4}>
              <FormikUserAutocomplete
                name={'assignedId'}
                label={'Assigned To'}
                users={assignees}
                startWithLastName
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={4}>
              <FormikUserAutocomplete
                name={'surgeonId'}
                label={'Surgeon'}
                users={surgeons}
                startWithLastName
                disabled={disabled}
                required
                onChange={(value) => {
                  const surgeonId = Number(value) ? Number(value) : undefined;
                  const surgeon = surgeons.find((surgeon) => surgeon.userId === surgeonId);

                  setSurgeon(surgeon);
                  setSurgeonId(surgeonId);
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <SelectField
                name={'locationId'}
                label={'Location'}
                hideNone={true}
                menuItems={locations}
                disabled={disabled || !surgeonId}
                required
              />
            </Grid>
            <Grid item xs={4}>
              <FormikUserAutocomplete
                name={'fieldRepId'}
                label={`${format.formatUserRole(UserRoleType.FieldRep)}`}
                users={fieldReps}
                startWithLastName
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={4}>
              <DatePickerField
                disabled={disabled}
                name="receivedAt"
                label={'Received Date'}
                required={true}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box mb={3}>
        <Typography variant="h5" mb={3}>
          Patient Information
        </Typography>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                name={'firstName'}
                label={'First Name'}
                disabled={disabled}
                required={true}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name={'lastName'}
                label={'Last Name'}
                disabled={disabled}
                required={true}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box mb={3}>
        <Typography variant="h5">Export File</Typography>

        <Box my={2}>
          {exportFile ? (
            <Alert
              icon={<FolderZipIcon fontSize="inherit" />}
              severity="info"
              style={{ backgroundColor: '#f9f8f8' }}
              elevation={2}
              onClose={handleClearSelectedZip}
            >
              {exportFile.name}
            </Alert>
          ) : (
            <GenericFileDropzone
              validFileExtensions={[ValidFileExtensions.ZIP]}
              onFileSelect={handleZipSelect}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

type ImportCaseFormInput = {
  firstName: string;
  lastName: string;
  receivedAt: Date;
  assignedId: number | null;
  fieldRepId: number | null;
  surgeonId: File | null;
  locationId: number | null;
  caseType: CaseType;
};

const importCaseFormInitialValues: ImportCaseFormInput = {
  firstName: '',
  lastName: '',
  receivedAt: new Date(),
  assignedId: null,
  fieldRepId: null,
  surgeonId: null,
  locationId: null,
  caseType: CaseType.Live,
};

const ImportCaseButton: React.FC<{ onImport: (caseId: number) => void }> = ({ onImport }) => {
  const [importingCase, setImportingCase] = useState(false);
  const [exportFile, setExportFile] = useState<File | null>(null);
  const styles = useStyles();
  const handleImportCase = () => {
    setImportingCase(true);
  };
  const [uploadingAssets, setUploadingAssets] = useState(false);

  const [importCaseUploadProgress, setUploadProgress] = useState<{
    total: number;
    complete: number;
  } | null>(null);

  const [importCase] = useMutation(IMPORT_CASE, { fetchPolicy: 'network-only' });
  const [completeCaseImport] = useMutation(COMPLETE_CASE_IMPORT, { fetchPolicy: 'network-only' });

  const { enqueueSnackbar } = useSnackbar();

  const handleStartImport = async (
    form: ImportCaseFormInput,
    helpers: FormikHelpers<ImportCaseFormInput>,
  ) => {
    if (!exportFile) return;
    setUploadingAssets(true);
    helpers.setSubmitting(true);

    try {
      const zip = new JSZip();
      const zipContents = await zip.loadAsync(exportFile);
      const mainJsonContent = await zipContents.file(EXPORT_CASE_MAIN_DATA_FILENAME)?.async('text');

      if (mainJsonContent) {
        const mainJson = JSON.parse(mainJsonContent);

        const receivedAt = DateTime.fromJSDate(form.receivedAt).toISODate();

        const importCaseRes = await importCase({
          variables: {
            receivedAt,
            firstName: form.firstName,
            lastName: form.lastName,
            assignedUserId: form.assignedId,
            fieldRepId: form.fieldRepId,
            surgeonId: form.surgeonId,
            locationId: form.locationId,
            caseType: form.caseType,
            exportData: mainJson,
          },
        });

        const payload: ImportCasePayload = importCaseRes.data?.importCase;

        setUploadProgress({
          total: payload.assets.length,
          complete: 0,
        });

        const controller = new AbortController();

        const uploadPromises = payload.assets.map(async (asset) => {
          try {
            const filename = generateExportedAssetFilename({
              assetType: asset.assetType as AssetType,
              fileName: asset.fileName,
              exportedAssetId: asset.exportedAssetId,
            });

            const fileContent = zip.file(filename);

            if (!fileContent) throw `File ${filename} not found.`;

            const data = await fileContent.async('blob');

            await file.uploadFile(asset.signedUrl, data, () => {}, controller.signal);

            setUploadProgress((old) =>
              old
                ? {
                    ...old,
                    complete: old.complete + 1,
                  }
                : null,
            );

            return asset;
          } catch {
            console.error('Asset upload failed... cancelling remaining uploads.');

            controller.abort();

            return null;
          }
        });

        const uploadResults = await Promise.all(uploadPromises);

        if (uploadResults.length != payload.assets.length) {
          throw `Not all files uploaded. ${uploadResults.length} of ${payload.assets.length} files uploaded.`;
        }

        await completeCaseImport({
          variables: {
            caseId: payload.caseId,
          },
        });

        enqueueSnackbar(`Case ${payload.caseNumber} was successfully imported.`, {
          variant: 'success',
        });

        setImportingCase(false);

        setExportFile(null);

        helpers.resetForm();

        onImport(payload.caseId);
      } else {
        throw `${EXPORT_CASE_MAIN_DATA_FILENAME} not found in the zip file.`;
      }
    } catch (error) {
      console.error('Error importing case:', error);
      enqueueSnackbar('There was an error importing this case', { variant: 'error' });
    } finally {
      helpers.setSubmitting(false);
      setUploadingAssets(false);
      setUploadProgress(null);
    }
  };

  const reset = (resetForm: () => void) => {
    setExportFile(null);
    resetForm();
  };

  return (
    <>
      <Button
        disabled={importingCase}
        variant={'contained'}
        color={'secondary'}
        onClick={() => {
          handleImportCase();
        }}
      >
        <>Import Case</>
      </Button>

      <Formik
        initialValues={importCaseFormInitialValues}
        validationSchema={importCaseDialogSchema}
        onSubmit={(form, helpers) => {
          handleStartImport(form, helpers);
        }}
      >
        {({ submitForm, isSubmitting, errors, resetForm }) => {
          const loading = false;
          const fieldsDisabled = isSubmitting || loading || uploadingAssets;
          const submitDisabled = fieldsDisabled || Object.values(errors).length > 0 || !exportFile;
          return (
            <CustomDialog
              maxWidth={'lg'}
              open={importingCase}
              title={`Import Case`}
              onClose={() => {
                if (!uploadingAssets) {
                  setImportingCase(false);

                  reset(resetForm);
                }
              }}
              dialogContentClassName={styles.dialogContainerContent}
              containerClassName={styles.dialogContainer}
              positiveActionButtons={[
                <ProgressButton
                  variant={'contained'}
                  disabled={submitDisabled}
                  onClick={() => submitForm()}
                  label={'Import'}
                  loading={loading}
                />,
              ]}
            >
              <Box>
                {uploadingAssets ? (
                  <Box
                    position="absolute"
                    top={0}
                    bottom={0}
                    left={0}
                    right={0}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    p={6}
                    zIndex={10}
                    style={{
                      backdropFilter: 'blur(3px)',
                      backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    }}
                  >
                    <Box width={'100%'} textAlign={'center'}>
                      <Box marginBottom={6}>
                        <Typography color={'textSecondary'} align={'center'} variant={'h5'}>
                          {importCaseUploadProgress
                            ? `Uploading ${importCaseUploadProgress.complete} of ${importCaseUploadProgress.total} files`.toUpperCase()
                            : 'Uploading files'}
                        </Typography>
                      </Box>

                      {importCaseUploadProgress ? (
                        <LinearProgress
                          variant={'determinate'}
                          value={
                            (importCaseUploadProgress.complete / importCaseUploadProgress.total) *
                            100
                          }
                        />
                      ) : (
                        <LinearProgress variant={'indeterminate'} />
                      )}
                    </Box>
                  </Box>
                ) : null}

                <ImportCaseModalContent
                  disabled={fieldsDisabled}
                  exportFile={exportFile}
                  onFileChange={setExportFile}
                />
              </Box>
            </CustomDialog>
          );
        }}
      </Formik>
    </>
  );
};

const useDropzoneStyles = makeStyles((theme: Theme) => ({
  gridCell: {
    backgroundColor: 'white',
  },
  dragActive: {
    backgroundColor: theme.palette.divider,
    opacity: 0.1,
  },
}));

const GenericFileDropzone = (props: {
  validFileExtensions: ValidFileExtensions[];
  onFileSelect: (file: File) => void;
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const styles = useDropzoneStyles();

  const handleDrop: DropzoneOptions['onDrop'] = useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      try {
        if (fileRejections.length > 0) {
          const errorMessage = fileRejections[0].errors[0].message;
          enqueueSnackbar(`Error opening file. ${errorMessage}`, {
            variant: 'error',
          });
          return;
        }

        if (acceptedFiles.length === 0) {
          enqueueSnackbar(`Error opening file. No files were accepted.`, {
            variant: 'error',
          });
          return;
        }

        const allFilesHaveSize = acceptedFiles.every((f) => f.size !== 0);

        if (!allFilesHaveSize) {
          enqueueSnackbar(`Error opening file. File has a size of zero bytes.`, {
            variant: 'error',
          });
          return;
        }

        const acceptedFile = acceptedFiles[0];

        props.onFileSelect(acceptedFile);
      } catch (e) {
        console.error(e);
        enqueueSnackbar('Error opening file', {
          variant: 'error',
        });
      } finally {
      }
    },
    [enqueueSnackbar, props],
  );
  const acceptedFileTypes = props.validFileExtensions.join(',');

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxFiles: 1,
    multiple: false,
    onDrop: handleDrop,
    accept: acceptedFileTypes,
  });

  return (
    <Box
      className={clsx({
        [styles.gridCell]: true,
        [styles.dragActive]: isDragActive,
      })}
      {...getRootProps()}
      border="1px dashed gray"
      p={10}
    >
      <Box gridColumn={'span 4'} className={styles.gridCell}>
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={50}>
          <input {...getInputProps()} />
          <Typography color={'textSecondary'} align={'center'} variant={'h5'}>
            {`Drop a file (${acceptedFileTypes}) here or click to browse.`.toUpperCase()}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ImportCaseButton;
