import {
  Box,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { CaseSpineProfile, TableType, caseUtils } from '@workflow-nx/common';
import { CervicalMeasurements } from '@workflow-nx/core-algorithms';
import { MeasurementsConfig } from '@workflow-nx/ui';
import { testing } from '@workflow-nx/utils';
import { CervicalMeasurementTableRow } from './CervicalMeasurementTableRow';

export function CervicalMeasurementTable(props: {
  caseSpineProfile: CaseSpineProfile;
  caseLevels: string[];
  tableType: TableType;
  showErrors?: boolean;
  size: 'small' | 'medium';
  color: 'invert' | 'default';
  measurementsConfig: MeasurementsConfig;
  cervicalMeasurements: CervicalMeasurements;
}) {
  const validLevels = caseUtils.getLevelsSortedByHierarchy(props.caseSpineProfile, 'desc');

  const smallVerticalPadding = props.size === 'medium' ? 1 : 2;
  const smallHorizontalPadding = 1;

  const tableCellStyle = {
    paddingLeft: smallHorizontalPadding,
    paddingRight: smallHorizontalPadding,
    paddingTop: smallVerticalPadding,
    paddingBottom: smallVerticalPadding,
    align: 'center',
  };

  return (
    <Box data-testid={`${testing.toKebabCase(props.tableType)}-measurement-table`}>
      <Divider />
      <Table sx={{ my: 2 }}>
        <TableHead>
          <TableRow>
            <TableCell style={tableCellStyle}>
              <Typography variant={'body1'}>
                <strong>LEVEL</strong>
              </Typography>
            </TableCell>
            <TableCell style={tableCellStyle}>
              <Stack direction="row" justifyContent="center">
                <Typography variant={'body1'}>
                  <strong>LOR</strong>
                </Typography>
                <Typography color={'textSecondary'} variant={'body2'}>
                  &deg;
                </Typography>
              </Stack>
            </TableCell>
            <TableCell style={tableCellStyle}>
              <Stack direction={'row'} justifyContent="center">
                <Typography variant={'body1'}>
                  <strong>COR.</strong>
                </Typography>
                <Typography color={'textSecondary'} variant={'body2'}>
                  &deg;
                </Typography>
              </Stack>
            </TableCell>
            <TableCell style={tableCellStyle}>
              <Stack direction="row" justifyContent="center" alignItems={'baseline'}>
                <Typography variant={'body1'}>
                  <strong>ANT</strong>
                </Typography>
                <Typography color={'textSecondary'} variant={'body2'}>
                  mm
                </Typography>
              </Stack>
            </TableCell>
            <TableCell style={tableCellStyle}>
              <Stack direction={'row'} justifyContent="center" alignItems={'baseline'}>
                <Typography variant={'body1'}>
                  <strong>POST</strong>
                </Typography>
                <Typography color={'textSecondary'} variant={'body2'}>
                  mm
                </Typography>
              </Stack>
            </TableCell>
            <TableCell style={tableCellStyle}>
              <Stack direction={'row'} justifyContent="center" alignItems={'baseline'}>
                <Typography variant={'body1'}>
                  <strong>AP</strong>
                </Typography>
                <Typography color={'textSecondary'} variant={'body2'}>
                  mm
                </Typography>
              </Stack>
            </TableCell>
            <TableCell style={tableCellStyle}>
              <Stack direction={'row'} justifyContent="center" alignItems={'baseline'}>
                <Typography variant={'body1'}>
                  <strong>ML</strong>
                </Typography>
                <Typography color={'textSecondary'} variant={'body2'}>
                  mm
                </Typography>
              </Stack>
            </TableCell>
            <TableCell style={tableCellStyle}>
              <Stack direction={'row'} justifyContent="center">
                <Typography variant={'body1'}>
                  <strong>AR</strong>
                </Typography>
                <Typography color={'textSecondary'} variant={'body2'}>
                  &deg;
                </Typography>
              </Stack>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {validLevels.map((level) => (
            <>
              <div> </div>
              <CervicalMeasurementTableRow
                data={props.cervicalMeasurements.levels[level]}
                key={level}
                disabled={!props.caseLevels.includes(level)}
                levelType={level}
                tableType={props.tableType}
                showErrors={props.showErrors}
                measurementsConfig={props.measurementsConfig}
              />
            </>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}
