import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { Badge, Box, Button, ListItem, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { testing } from '@workflow-nx/utils';

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    '& .MuiButton-root:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.secondary.dark,
    },
  },
  badge: {
    '& .MuiBadge-badge': {
      right: -15,
      top: 10,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  },
  button: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
  },
  buttonLeaf: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      '& $title': {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    fontSize: '1.5em',
  },
  title: {
    marginRight: 'auto',
    marginLeft: theme.spacing(2),
  },
  active: {
    color: theme.palette.secondary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium,
    },
    '& $icon': {
      color: theme.palette.secondary.main,
    },
  },
}));

const NavItem = ({
  depth,
  href,
  icon,
  title,
  badge,
}: {
  children?: React.ReactNode;
  depth: number;
  href?: string;
  icon: any;
  title: string;
  badge?: number;
}) => {
  const classes = useStyles();

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = { paddingLeft };

  return (
    <ListItem
      className={classes.itemLeaf}
      disableGutters
      key={title}
      data-test-id={`${testing.toKebabCase(title)}-navItem`}
    >
      <Button
        className={clsx(classes.buttonLeaf, `depth-${depth}`)}
        component={RouterLink}
        style={style}
        to={href ?? ''}
        variant={'text'}
        disableRipple={true}
      >
        <Badge badgeContent={badge} color={'primary'} className={classes.badge}>
          <Box display={'flex'} alignItems={'center'} pr={1}>
            <FontAwesomeIcon icon={icon} className={classes.icon} fixedWidth={true} />
            <span className={classes.title}>{title}</span>
          </Box>
        </Badge>
      </Button>
    </ListItem>
  );
};

export default NavItem;
