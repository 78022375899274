import { useLazyQuery } from '@apollo/client';
import { Box, LinearProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import JSZip from '@progress/jszip-esm';
import { AssetType, ExportedCaseDataset, ICase } from '@workflow-nx/common';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import CustomDialog from '../../../components/CustomDialog';
import { EXPORT_CASE } from '../../../gql';
import * as FileSaver from 'file-saver';

const useStyles = makeStyles(() => ({
  dialogContainer: {
    flex: 1,
    width: '100%',
  },
  dialogContainerContent: {
    display: 'flex',
    maxWidth: '100%',
  },
}));

export const EXPORT_CASE_MAIN_DATA_FILENAME = 'main.json';

export const generateExportedAssetFilename = (assetData: {
  assetType: AssetType;
  exportedAssetId: number;
  fileName: string;
}) => {
  const parts = assetData.fileName.split('.');
  const ext = parts.length > 1 ? parts.pop()!.toLowerCase() : '';

  return `${assetData.assetType}_${assetData.exportedAssetId}.${ext}`;
};

const ExportCaseButton: React.FC<{ activeCase: ICase }> = ({ activeCase }) => {
  const [exportingCase, setExportingCase] = useState(false);
  const [exportCaseDownloadProgress, setExportCaseDownloadProgress] = useState<{
    total: number;
    complete: number;
  } | null>(null);

  const [exportCase] = useLazyQuery(EXPORT_CASE, { fetchPolicy: 'network-only' });
  const [exportAbortController, setExportAbortController] = useState<AbortController | null>(null);
  const confirm = useConfirm();
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleExportCase = async () => {
    try {
      await confirm({
        title: `Export case ${activeCase.number}?`,
        description: `Are you sure you want to export the case ${activeCase.number}. This process will take a few minutes.`,
      });

      setExportingCase(true);

      const controller = new AbortController();

      setExportAbortController(controller);

      const res = await exportCase({
        variables: {
          caseId: activeCase.caseId,
        },
      });

      const data: ExportedCaseDataset | null = res.data?.exportCase;

      if (!data) {
        throw 'No case data available';
      }

      const zip = new JSZip();

      const jsonContent = JSON.stringify(data, null, 2);

      zip.file(EXPORT_CASE_MAIN_DATA_FILENAME, jsonContent);

      const expectedCount = data.assets.length;

      let downloadedCount = 0;

      setExportCaseDownloadProgress({
        total: expectedCount,
        complete: downloadedCount,
      });

      const assetPromises = data.assets.map(async (a) => {
        try {
          const response = await fetch(a.signedUrl, { signal: controller.signal });

          if (response.status >= 300) {
            throw new Error(`File not found at ${a.signedUrl}`);
          }

          const blob = await response.blob();

          const filename = generateExportedAssetFilename({
            assetType: a.assetData.assetType as AssetType,
            fileName: a.assetData.fileName,
            exportedAssetId: a.exportedAssetId,
          });

          zip.file(filename, blob);

          downloadedCount += 1;

          setExportCaseDownloadProgress((old) =>
            old
              ? {
                  ...old,
                  complete: downloadedCount,
                }
              : null,
          );
        } catch (assetError) {
          console.error(
            `There was an error downloading asset ID ${a.exportedAssetId}. Cancelling other downloads...`,
          );

          controller.abort();

          throw assetError;
        }
      });

      await Promise.all(assetPromises);

      if (downloadedCount != expectedCount) {
        throw '1+ files failed to download.';
      }

      const zipBlob = await zip.generateAsync({ type: 'blob' });

      FileSaver.saveAs(zipBlob, `export_case_${data.caseData.number}.zip`);
    } catch (error) {
      enqueueSnackbar(`The case failed to export.`, {
        variant: 'error',
      });

      console.error('Error creating zip:', error);
    } finally {
      setExportingCase(false);
      setExportCaseDownloadProgress(null);
      setExportAbortController(null);
    }
  };

  const cancelExport = () => {
    exportAbortController?.abort();

    setExportAbortController(null);
  };

  return (
    <>
      <Box
        sx={{
          color: exportingCase ? 'InactiveCaptionText' : 'inherit',
          cursor: exportingCase ? 'wait' : 'pointer',
        }}
        width={'100%'}
        onClick={() => {
          if (!exportingCase) {
            handleExportCase();
          }
        }}
      >
        Export Case
      </Box>

      <CustomDialog
        maxWidth={'xs'}
        open={exportingCase}
        title={`Exporting Case ${activeCase.number}`}
        onClose={() => {
          cancelExport();
        }}
        positiveActionButtons={[]}
        dialogContentClassName={styles.dialogContainerContent}
        containerClassName={styles.dialogContainer}
      >
        <>
          {/* Loading UI */}
          {exportingCase ? (
            <Box
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              height={'100%'}
              p={6}
            >
              <Box width={'100%'} textAlign={'center'}>
                <Box marginBottom={6}>
                  <Typography color={'textSecondary'} align={'center'} variant={'h5'}>
                    {exportCaseDownloadProgress
                      ? `Downloading ${exportCaseDownloadProgress.complete} of ${exportCaseDownloadProgress.total} files`.toUpperCase()
                      : 'Downloading files'}
                  </Typography>
                </Box>

                {exportCaseDownloadProgress ? (
                  <LinearProgress
                    variant={'determinate'}
                    value={
                      (exportCaseDownloadProgress.complete / exportCaseDownloadProgress.total) * 100
                    }
                  />
                ) : (
                  <LinearProgress variant={'indeterminate'} />
                )}
              </Box>
            </Box>
          ) : null}
        </>
      </CustomDialog>
    </>
  );
};

export default ExportCaseButton;
