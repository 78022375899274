import { TableCell as MatTableCell, SxProps, Theme } from '@mui/material';
import React, { FunctionComponent, MouseEventHandler } from 'react';
import { makeStyles } from '@mui/styles';

type TableCellProps = {
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  width?: string | number;
  colSpan?: number;
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
  onClick?: MouseEventHandler<HTMLTableCellElement>;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    ...theme.typography.body1,
  },
}));

export const TableCell: FunctionComponent<TableCellProps> = (props) => {
  const styles = useStyles();

  return (
    <MatTableCell
      align={props.align}
      className={styles.root}
      width={props.width}
      colSpan={props.colSpan}
      sx={props.sx}
      onClick={props.onClick}
    >
      {props.children}
    </MatTableCell>
  );
};
