import { format, LevelType, PartType } from '@workflow-nx/common';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { PlanViewDialogImplantState } from './PlanViewDialog.context';

export function ImplantHeaderView(props: { implant: PlanViewDialogImplantState }) {
  return (
    <Box
      position={'absolute'}
      top={85}
      left={'50%'}
      style={{
        transform: 'translateX(-50%)',
      }}
    >
      <Box textAlign={'center'}>
        <Typography variant={'h3'} style={{ color: 'white' }}>
          {format.formatLevelType(props.implant.level as LevelType)}{' '}
          {format.formatPartType(props.implant.part as unknown as PartType)}
        </Typography>
        <Typography variant={'h6'} style={{ color: 'white' }}>
          AP:{props.implant.ap}mm ML:{props.implant.ml}mm{' '}
          {props?.implant?.screwLength
            ? `Screw Length:${format.formatImplantScrewLength(props.implant.screwLength)}`
            : ''}
        </Typography>
      </Box>
    </Box>
  );
}
