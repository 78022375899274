import { Box, Button, Divider, TextField, Theme, Typography } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { faChevronCircleDown, faChevronCircleUp } from '@fortawesome/pro-solid-svg-icons';
import React, { useState } from 'react';
import { IconFontButton } from '@workflow-nx/ui';
import { useConfirm } from 'material-ui-confirm';
import { useMutation, useQuery } from '@apollo/client';
import { IRegion, UserRoleType, format } from '@workflow-nx/common';
import {
  DELETE_REGION,
  DELETE_TERRITORY,
  FIND_REGIONS,
  UPSERT_REGION,
  UPSERT_TERRITORY,
} from '../../gql';
import { useSnackbar } from 'notistack';
import { faTrash } from '@fortawesome/pro-light-svg-icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      listStyle: 'none',
      padding: theme.spacing(0.5),
      margin: 0,
    },
    item: {
      display: 'flex',
      height: 48,
      alignItems: 'center',
    },
    chip: {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    errorButton: {
      color: '#f60258',
      border: '1px solid rgba(246, 2, 88, 0.60)',
      '&:hover': {
        border: '1px solid rgba(246, 2, 88, 0.60)',
      },
    },
    italicized: {
      fontStyle: 'italic',
    },
    selectedTagCategory: {
      backgroundColor: '#f5f5f5',
    },
  }),
);

export function RegionAndTerritoriesForm() {
  const styles = useStyles();
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  // const [errorMessage, setErrorMessage] = useState<ErrorMessageState>(initialErrorMessageState);
  const [regions, setRegions] = React.useState<IRegion[]>([]);

  const [upsertRegion, { loading: upsertRegionLoading }] = useMutation(UPSERT_REGION);
  const [upsertTerritory, { loading: upsertTerritoryLoading }] = useMutation(UPSERT_TERRITORY);
  const { refetch, loading } = useQuery(FIND_REGIONS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: () => {
      enqueueSnackbar('Failed to fetch regions', { variant: 'error' });
    },
    onCompleted: (data) => {
      setRegions(data.regions);
    },
  });

  const [deleteRegion] = useMutation(DELETE_REGION);
  const [deleteTerritory] = useMutation(DELETE_TERRITORY);
  const [createRegionForm, setCreateRegionForm] = useState<{ name: string }>({ name: '' });
  const [createTerritoryForm, setCreateTerritoryForm] = useState<{
    [name: string]: string;
  }>({});

  const handleDeleteRegion = async (regionId: number, name: string) => {
    try {
      await confirm({
        title: `Delete Region`,
        description: `Are you sure you want to delete the region "${name}"?. This will remove the region from all associated locations.`,
      });

      await deleteRegion({
        variables: {
          regionId,
        },
      });

      await refetch();
    } catch (e) {
      console.error(e);

      enqueueSnackbar(`Failed to delete the region "${name}"`, {
        variant: 'error',
      });
    }
  };

  const handleDeleteTerritory = async (territoryId: number, name: string) => {
    try {
      await confirm({
        title: `Delete Territory`,
        description: `Are you sure you want to delete the territory "${name}"?. This will remove the territory from all associated ${format.formatUserRole(
          UserRoleType.FieldRep,
        )} members.`,
      });

      await deleteTerritory({
        variables: {
          territoryId,
        },
      });

      await refetch();
    } catch (e) {
      console.error(e);

      enqueueSnackbar(`Failed to delete the territory "${name}"`, {
        variant: 'error',
      });
    }
  };

  const handleUpsertRegion = async (name: string, regionId?: number) => {
    try {
      if (name) {
        await upsertRegion({
          variables: {
            name,
            regionId,
          },
        });
      }

      setCreateRegionForm({ name: '' });

      await refetch();
    } catch (e) {
      console.error(e);

      enqueueSnackbar(`Failed to create region "${createRegionForm?.name}"`, {
        variant: 'error',
      });
    }
  };

  const handleUpsertTerritory = async (name: string, regionId?: number, territoryId?: number) => {
    try {
      if (name || regionId) {
        await upsertTerritory({
          variables: {
            name,
            regionId,
            territoryId,
          },
        });
      }

      setCreateTerritoryForm({ [`name${regionId}`]: '' });

      await refetch();
    } catch (e) {
      console.error(e);

      enqueueSnackbar(`Failed to create territory "${createTerritoryForm?.name}"`, {
        variant: 'error',
      });
    }
  };

  return (
    <>
      <Box>
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
          <Typography variant={'h4'}>Configure Regions And Territories</Typography>
          <Box>
            <IconFontButton
              icon={open ? faChevronCircleUp : faChevronCircleDown}
              onClick={() => setOpen(!open)}
            />
          </Box>
        </Box>
        {open && (
          <Box>
            <Box>
              <Box width={500}>
                <Box display={'flex'}>
                  <Box width={250}>
                    <Box className={styles.item}>
                      <Typography variant="h5">Regions</Typography>
                    </Box>
                  </Box>
                  <Box width={250}>
                    <Box className={styles.item}>
                      <Typography variant="h5">Territories</Typography>
                    </Box>
                  </Box>
                </Box>
                {regions.map((region) => {
                  return (
                    <Box>
                      <Box display={'flex'}>
                        <Box width={250}>
                          <Box className={styles.item}>
                            <Typography variant={'body1'}>{region.name}</Typography>
                            <Box flexGrow={1} />
                            <IconFontButton
                              onClick={() => handleDeleteRegion(region.regionId, region.name)}
                              icon={faTrash}
                            />
                          </Box>
                        </Box>
                        <Box width={250} flexDirection={'column'} display={'flex'}>
                          {region.territories.map((territory) => {
                            return (
                              <Box className={styles.item}>
                                <Typography variant={'body1'}>{territory.name}</Typography>
                                <Box flexGrow={1} />
                                <IconFontButton
                                  onClick={() =>
                                    handleDeleteTerritory(territory.territoryId, territory.name)
                                  }
                                  icon={faTrash}
                                />
                              </Box>
                            );
                          })}
                          {region.territories.length === 0 ? (
                            <Box className={styles.item}>
                              <Typography color={'textSecondary'} variant={'body1'}>
                                <em>No Territories</em>
                              </Typography>
                            </Box>
                          ) : null}
                          <Box>
                            <Divider variant={'middle'} />
                          </Box>
                          <Box display={'flex'} my={2} width={400}>
                            <TextField
                              label="Territory"
                              size={'small'}
                              InputLabelProps={{ shrink: true }}
                              value={(createTerritoryForm as any)[`name${region.regionId}`]}
                              onChange={(e) =>
                                setCreateTerritoryForm({
                                  [`name${region.regionId}`]: e.currentTarget.value,
                                })
                              }
                            />
                            <Box mx={0.5} />
                            <Button
                              variant="outlined"
                              color="primary"
                              disabled={upsertTerritoryLoading}
                              onClick={() => {
                                return handleUpsertTerritory(
                                  (createTerritoryForm as any)[`name${region.regionId}`],
                                  region.regionId,
                                );
                              }}
                            >
                              Create
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                      <Box width={'100%'}>
                        <Divider variant={'middle'} />
                      </Box>
                    </Box>
                  );
                })}
                <Box display={'flex'} my={2} width={250}>
                  <TextField
                    label="Region"
                    size={'small'}
                    InputLabelProps={{ shrink: true }}
                    value={createRegionForm.name}
                    onChange={(e) => setCreateRegionForm({ name: e.currentTarget.value })}
                  />
                  <Box mx={0.5} />
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={upsertRegionLoading}
                    onClick={() => handleUpsertRegion(createRegionForm.name)}
                  >
                    Create
                  </Button>
                </Box>
              </Box>
              {/*
                      <Box display={'flex'} my={2} width={400}>
                        <TextField
                          label="Name"
                          InputLabelProps={{ shrink: true }}
                          // value={}
                          // error={Boolean(errorMessage.tagCategoryForm)}
                          // helperText={errorMessage.tagCategoryForm}
                          onChange={(e) =>
                            setCreateTerritoryForm({
                              name: e.currentTarget.value,
                            })
                          }
                        />
                        <Box mx={1} />
                        <ActionButton
                          onClick={() =>
                            handleUpsertTerritory(createTerritoryForm.name, region.regionId)
                          }
                          variant={'outlined'}
                        >
                          Add&nbsp;Territory
                        </ActionButton>
                      </Box>
*/}
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
}
