import { Box, Card, CardContent, Grid, Tab, Tabs } from '@mui/material';
import React, { Dispatch, useState } from 'react';
import { ICase } from '@workflow-nx/common';
import { CaseViewActionType } from '../CaseView';
import { DiscussionTabView } from '../DiscussionTab/DiscussionTabView';
import { Heading } from 'apps/workflow-client/src/app/components/Heading';
import { PatientDetailsView } from './PatientDetailsView';
import { PatientCaseInfoView } from './PatientCaseInfoView';
import { PatientRecordsView } from './PatientRecordsView';

type PatientTabViewProps = {
  activeCase: ICase;
  dispatch: Dispatch<CaseViewActionType>;
};

export function PatientTabView({ activeCase, dispatch }: PatientTabViewProps) {
  const [tabValue, setTabValue] = useState('patientDetails');

  return (
    <Grid container alignContent={'stretch'} spacing={3}>
      <Grid item xs={12} md={6}>
        <Box height={'100%'} display={'flex'} flexDirection={'column'}>
          <Heading label={'Patient Info'} />
          <Box flexBasis={'100%'} component={Card}>
            <Card>
              <CardContent>
                <Box display={'flex'} justifyContent={'center'}>
                  <Tabs
                    value={tabValue}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="standard"
                    scrollButtons="auto"
                    onChange={(_, newValue) => {
                      setTabValue(newValue);
                    }}
                  >
                    <Tab label={'Patient Details'} value={'patientDetails'} />
                    <Tab label={'Patient Records'} value={'patientRecords'} />
                  </Tabs>
                </Box>
                <Box mb={1} />
                <div role={'tabpanel'} hidden={tabValue !== 'patientDetails'}>
                  {tabValue === 'patientDetails' ? (
                    <PatientDetailsView activeCase={activeCase} dispatch={dispatch} />
                  ) : null}
                </div>
                <div role={'tabpanel'} hidden={tabValue !== 'patientRecords'}>
                  {tabValue === 'patientRecords' ? (
                    <PatientRecordsView activeCase={activeCase} dispatch={dispatch} />
                  ) : null}
                </div>
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box height={'100%'} display={'flex'} flexDirection={'column'}>
          <Box>
            <Heading label={'Case Info'} />
          </Box>
          <Box component={Card} flexBasis={'100%'}>
            <CardContent style={{ width: '100%', height: '100%' }}>
              <PatientCaseInfoView activeCase={activeCase} />
            </CardContent>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box display={'flex'} flexDirection={'column'}>
          <Heading label={'Patient Notes'} />
          <DiscussionTabView caseId={activeCase.caseId} isPatientView={true} />;
        </Box>
      </Grid>
    </Grid>
  );
}
