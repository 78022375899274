import { useField } from 'formik';
import { TextField as MuiTextField } from '@mui/material';
import React from 'react';
import { testing } from '@workflow-nx/utils';

export function TextField({
  fullWidth = true,
  fullHeight = false,
  required,
  disabled,
  label,
  multiline,
  hidden,
  name,
  variant,
  type,
  shrink,
  multiLineRows,
  matchHeight,
}: {
  name: string;
  label?: string;
  fullWidth?: boolean;
  fullHeight?: boolean;
  required?: boolean;
  disabled?: boolean;
  multiline?: boolean;
  hidden?: boolean;
  type?: React.InputHTMLAttributes<unknown>['type'];
  variant?: 'standard' | 'filled' | 'outlined' | undefined;
  shrink?: boolean;
  multiLineRows?: number;
  matchHeight?: boolean;
}) {
  const [input, meta] = useField(name);

  return (
    <MuiTextField
      data-test-id={`${testing.toKebabCase(label ?? name)}-text-field`}
      required={required}
      error={Boolean(meta.touched && meta.error)}
      fullWidth={fullWidth}
      label={label}
      multiline={multiline || matchHeight}
      minRows={multiline ? multiLineRows || 3 : undefined}
      name={name}
      type={type ?? 'text'}
      onBlur={input.onBlur}
      onChange={input.onChange}
      value={meta.value}
      variant={variant ?? 'outlined'}
      disabled={Boolean(disabled)}
      size={'small'}
      InputLabelProps={{
        shrink,
      }}
      style={{
        height: fullHeight ? '100%' : undefined,
        display: hidden ? 'none' : undefined,
      }}
      sx={{ '& .MuiInputBase-root': { minHeight: matchHeight ? '100%' : undefined } }}
    />
  );
}
