import { useQuery } from '@apollo/client';
import { FIND_ACTIVITIES } from '../../../gql';
import { ActivityType, format, IActivity } from '@workflow-nx/common';
import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { Alert } from '@mui/material';

export function ActivityTable(props: {
  label: string;
  caseId: number;
  activityTypes: ActivityType[];
}) {
  const { data: activitiesData } = useQuery(FIND_ACTIVITIES, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      caseId: props.caseId,
      activityTypeFilter: props.activityTypes,
    },
  });

  const currentActivitiesList = activitiesData?.activities?.activities || [];

  return (
    <Box ml={1} mt={1}>
      {props.label ? (
        <Typography color={'textSecondary'} variant={'h5'} gutterBottom={true}>
          {props.label}
        </Typography>
      ) : null}
      {currentActivitiesList?.length ? (
        currentActivitiesList?.map((activity: IActivity) => (
          <Grid container key={activity.activityId} spacing={1}>
            <Grid item xs={3}>
              <Typography color={'textPrimary'} variant={'body1'} noWrap={true}>
                <strong>{format.formatDateTime(activity.createdAt)}</strong>
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography color={'textSecondary'} variant={'body1'}>
                {formatSpecificActivityType(activity)}
              </Typography>
            </Grid>
          </Grid>
        ))
      ) : (
        <Alert severity={'info'}>There is no activity to display</Alert>
      )}
    </Box>
  );
}

function formatSpecificActivityType(activity: IActivity) {
  switch (activity.activityType) {
    case ActivityType.CaseStagePlanningComplete:
      return `Plan was proposed by ${format.formatName(activity.createdByUser)}`;
    case ActivityType.CaseStagePlanningReopened:
      return `Plan was re-opened by ${format.formatName(activity.createdByUser)}`;
    case ActivityType.CaseStageProposedComplete:
      return `Plan was marked as approved by ${format.formatName(activity.createdByUser)}`;
    case ActivityType.PlanCreate:
      return `Plan was created by ${format.formatName(activity.createdByUser)}`;
    case ActivityType.PlanUpsert:
      return `Plan was upserted by ${format.formatName(activity.createdByUser)}`;
    case ActivityType.PlanDelete:
      return `Plan was deleted by ${format.formatName(activity.createdByUser)}`;
    case ActivityType.PlanUpdateStatus:
      return `Plan status was updated by ${format.formatName(activity.createdByUser)}`;
    case ActivityType.PlanProposalRejected:
      return `Proposed plan was rejected by ${format.formatName(activity.createdByUser)}`;
    case ActivityType.CaseStageDesignComplete:
      return `The case was sent for QA review by ${format.formatName(activity.createdByUser)}`;
    case ActivityType.CaseStageDesignReopened:
      return `The case was re-sent for QA review by ${format.formatName(activity.createdByUser)}`;
    case ActivityType.Form19UpdateStatusRejected:
      return `The "Form 19" was rejected by ${format.formatName(
        activity.createdByUser,
      )} because "${activity?.delta?.reason.join(', ')}"`;
    case ActivityType.Form19UpdateStatusApproved:
      return `The Form 19 was approved by ${format.formatName(activity.createdByUser)}`;
  }

  return activity.activityType;
}
