import { ApolloQueryResult } from '@apollo/client/core/types';
import { Globals } from '../../../layouts/DashboardLayout';

export type ListSurgeonsActionType = {
  type:
    | 'RESET_FILTERS'
    | 'SEARCH_CHANGED'
    | 'ORDER_BY_CHANGED'
    | 'ORGANIZATION_CHANGED'
    | 'DISTRIBUTOR_CHANGED'
    | 'UPDATE_PAGINATION'
    | 'USER_CHANGED';
  data?: any;
};

export type ListSurgeonsStateType = {
  organization?: string;
  fieldRepId?: string;
  territoryId?: string;
  orderBy: any;
  search?: string;
  pageSize?: number;
  pageNumber?: number;
};

export const ListSurgeonsReducer =
  (refetch: (variables?: Partial<any>) => Promise<ApolloQueryResult<any>>) =>
  (state: ListSurgeonsStateType, action: ListSurgeonsActionType): ListSurgeonsStateType => {
    let updatedState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
      case 'RESET_FILTERS':
        updatedState = {
          organization: '',
          fieldRepId: '',
          orderBy: { organization: { name: 'asc' } },
          pageSize: Globals.DefaultListPageSize,
        };
        break;
      case 'SEARCH_CHANGED':
        updatedState.search = action.data;
        break;
      case 'ORDER_BY_CHANGED':
        updatedState.orderBy = action.data;
        break;
      case 'DISTRIBUTOR_CHANGED':
        updatedState.distributor = action.data;
        break;
      case 'ORGANIZATION_CHANGED':
        updatedState.organization = action.data;
        break;
      case 'USER_CHANGED':
        updatedState.territoryId = action.data?.territoryId;
        updatedState.fieldRepId = action.data?.menuItem?.key;
        break;
      case 'UPDATE_PAGINATION':
        updatedState.pageSize = action.data.pageSize;
        updatedState.pageNumber = action.data.pageNumber;
        break;
    }

    let orderBy = updatedState.orderBy;

    if (Object.keys(orderBy)[0] === 'organizationId') {
      orderBy = { organization: { name: orderBy['organizationId'] } };
    }

    if (action.type !== 'UPDATE_PAGINATION') {
      updatedState.pageNumber = 0;
    }

    let territoryId: number | undefined | null;
    if (action.type === 'USER_CHANGED') {
      territoryId =
        updatedState.fieldRepId && updatedState.territoryId
          ? parseInt(updatedState.territoryId)
          : 0;
    } else {
      territoryId = undefined;
    }

    const variables = {
      organizationFilter: updatedState.organization ? [updatedState.organization] : undefined,
      linkedOrganizationFilter: updatedState.distributor ? [updatedState.distributor] : undefined,
      territoryId:
        updatedState.fieldRepId && updatedState.territoryId
          ? parseInt(updatedState.territoryId)
          : undefined,
      orderBy,
      search: updatedState.search,
      take: updatedState.pageSize,
      skip:
        updatedState.pageSize && updatedState.pageNumber
          ? updatedState.pageSize * updatedState.pageNumber
          : undefined,
    };
    refetch(variables);

    return updatedState;
  };
