import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { IAsset } from '@workflow-nx/common';
import { CircularProgress } from '@mui/material';
import { FIND_ASSET_HISTORY } from '../../gql';
import CustomDialog from '../CustomDialog';
import { Alert } from '@mui/material';

export function AssetHistoryDialog(props: { open: boolean; asset?: IAsset; onClose: () => void }) {
  const [deletedAssets, setDeletedAssets] = useState<IAsset[]>([]);
  const [findAssetHistory, { loading: loadingAssetHistory }] = useLazyQuery(FIND_ASSET_HISTORY, {
    variables: {
      caseId: props.asset?.caseId,
      planId: props.asset?.planId,
      assetType: props.asset?.assetType,
    },
    onCompleted: (data) => {
      setDeletedAssets(data.assetHistory ?? []);
    },
  });

  useEffect(() => {
    if (props.open) {
      findAssetHistory();
    }
  }, [props.open]);

  return (
    <CustomDialog
      maxWidth={'sm'}
      open={props.open}
      title={`Viewing Asset ${props?.asset?.assetType}`}
      onClose={() => {
        props.onClose();
      }}
      positiveActionButtons={[]}
    >
      {props.open && !loadingAssetHistory ? (
        <>
          {deletedAssets.length === 0 ? (
            <Alert severity={'warning'}>There are no previous versions of this asset.</Alert>
          ) : null}
          {deletedAssets.length > 0 ? <>{JSON.stringify(deletedAssets)}</> : null}
        </>
      ) : null}
      {props.open && loadingAssetHistory ? (
        <CircularProgress variant={'indeterminate'} size={'50'} />
      ) : null}
    </CustomDialog>
  );
}
