import React, { ReactNode } from 'react';
import useAuth from '../hooks/useAuth';
import { Navigate } from 'react-router-dom';

const GuestGuard = ({ children }: { children: ReactNode }) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to="/app/dashboard" />;
  }

  return children;
};

export default GuestGuard;
