import { Alert, Box } from '@mui/material';
import {
  AssetType,
  IAsset,
  ICase,
  IPlan,
  ValidFileExtensions,
  caseUtils,
} from '@workflow-nx/common';
import * as FileSaver from 'file-saver';
import { Dispatch } from 'react';
import { AssetGridTable } from '../../../../components/AssetGridTable/AssetGridTable';

const EditedVertebralAssetsGridTable = (props: {
  dispatch: Dispatch<any>;
  assets: IAsset[];
  activeCase: ICase;
  plan?: IPlan;
  readOnly: boolean;
}) => {
  const requiredPlanAssets = [
    ...caseUtils.getCaseVertebralBodyAssets(props.activeCase.spineProfile).standard,
  ];

  const editablePlanAssets = requiredPlanAssets
    .filter((requiredPlanAsset) => {
      return ![AssetType.PlanSummaryApp, AssetType.PlanSummaryFormApp].includes(requiredPlanAsset);
    })
    .map((requiredPlanAsset) => {
      return `${requiredPlanAsset}_EDITED` as AssetType;
    });

  return (
    <>
      <Box my={2}>
        <Alert severity={'warning'}>
          Vertebral body assets added here will ONLY be used during the Cloud Design process. Edited
          vertebrae are generally used for removing vertebral body features that will impact the
          generation of the implant.
        </Alert>
      </Box>
      <AssetGridTable
        dispatch={props.dispatch}
        assets={props.assets}
        caseId={props.activeCase.caseId}
        planId={props.plan?.planId}
        showDownloadAll={false}
        onZipComplete={(zipFile) =>
          FileSaver.saveAs(zipFile, `${props.activeCase.number}-plan-stl-assets.zip`)
        }
        validAssets={editablePlanAssets}
        validFileExtensions={[ValidFileExtensions.STL]}
        readOnly={props.readOnly}
      />
    </>
  );
};

export default EditedVertebralAssetsGridTable;
