import { useQuery } from '@apollo/client';
import { faThumbtack } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Badge, Box, Container, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  CaseStageType,
  IAsset,
  ICase,
  IComment,
  MeasurementsVersionType,
  Permission,
} from '@workflow-nx/common';
import { getStudyDateExpiryStatus } from 'libs/common/src/lib/utils/case';
import { useEffect, useReducer, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Page from '../../../components/Page';
import SplashScreen from '../../../components/SplashScreen';
import { TrackableUserEvent } from '../../../contexts/AnalyticsContext';
import { FIND_CASE } from '../../../gql';
import useAnalytics from '../../../hooks/useAnalytics';
import useAuth from '../../../hooks/useAuth';
import { canEditCase } from '../../../utils/case';
import { ActivityTabView } from './ActivityTab/ActivityTabView';
import { AssetsTabView } from './AssetsTab/AssetsTabView';
import { CaseCancellationView } from './CaseCancellationView';
import { CaseHeaderView } from './CaseHeaderView';
import { CaseHoldView } from './CaseHoldView';
import { CaseStudyDateExpirationView } from './CaseStudyDateExpirationView';
import { CaseTabView } from './CaseTabView';
import { CaseViewProvider } from './CaseView.context';
import { DiscussionTabView } from './DiscussionTab/DiscussionTabView';
import { PatientTabView } from './PatientTab/PatientTabView';
import { TaskTabView } from './TaskTab/TaskTabView';

export type CaseViewActionType = {
  type: 'refetch' | 'INIT';
  data?: any;
};

type CaseViewStateType = {
  canEdit: boolean;
  stage: CaseStageType;
  activeCase?: ICase;
  medicalRecordNumbers: { dicomCt: string; dicomXray: string; patient: string };
  mrnStatus: 'VALID' | 'MRN_MISSING' | 'MRN_MISMATCH';
  birthDates: { dicomCt: string; dicomXray: string; patient: string };
  birthDateStatus: 'VALID' | 'BIRTHDATE_MISMATCH' | 'BIRTHDATE_DICOM_MISMATCH';
  commentCount: number;
  pinnedComment?: IComment;
  taskCount: string | number;
  deletedAt?: Date;
};

function getViewTab(hash: string) {
  switch (hash) {
    case '#task': {
      return 'TASK';
    }
    case '#patient': {
      return 'PATIENT';
    }
    case '#discussion': {
      return 'DISCUSSION';
    }
    case '#activity': {
      return 'ACTIVITY';
    }
    case '#assets': {
      return 'ASSETS';
    }
    default:
      return 'CASE';
  }
}

const useStyles = makeStyles(() => ({
  tabsRoot: {
    '& [role=tab]': {
      padding: '0 40px',
    },
  },
}));

const CaseView = () => {
  const auth = useAuth();
  const { trackEvent } = useAnalytics();
  const theme = useTheme();
  const styles = useStyles();
  const { hash } = useLocation();
  const [viewTabValue, setViewTabValue] = useState(getViewTab(hash));
  const { caseId } = useParams<{ caseId: string }>();
  const navigate = useNavigate();
  const { refetch, loading } = useQuery(FIND_CASE, {
    variables: { caseId: Number(caseId) },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'ignore',
    onCompleted: (data) => {
      if (!data?.case) {
        navigate('/app/cases');

        return;
      }

      dispatch({ type: 'INIT', data });
    },
  });
  const initialState: CaseViewStateType = {
    stage: CaseStageType.Open,
    medicalRecordNumbers: { dicomCt: '', dicomXray: '', patient: '' },
    mrnStatus: 'VALID',
    birthDates: { dicomCt: '', dicomXray: '', patient: '' },
    birthDateStatus: 'VALID',
    canEdit: false,
    commentCount: 0,
    taskCount: 0,
  };
  const reducer = (state: CaseViewStateType, action: CaseViewActionType): CaseViewStateType => {
    let updatedState: CaseViewStateType = JSON.parse(JSON.stringify(state));
    switch (action.type) {
      case 'refetch':
        refetch();
        break;
      case 'INIT': {
        const activeCase = action.data.case;
        const isEditable = canEditCase(activeCase, auth?.user);
        const tasks = action?.data?.tasks;

        const openTasks =
          tasks?.tasks?.filter((task: { status: string }) => task.status === 'OPEN').length ?? 0;
        const taskCount = tasks?.count ? `${openTasks}/${action.data?.tasks?.count}` : 0;

        const dicomCtAsset = action?.data?.case?.assets?.find(
          (asset: IAsset) => asset.assetType === 'DICOM_CT',
        );
        const dicomXrayAsset = action?.data?.case.assets?.find(
          (asset: IAsset) => asset.assetType === 'DICOM_XRAY',
        );
        const mrn = action?.data?.case?.patient?.mrn;

        const medicalRecordNumbers = {
          dicomCt: dicomCtAsset?.metadata?.mrn,
          dicomXray: dicomXrayAsset?.metadata?.mrn,
          patient: mrn,
        };

        const birthDates = {
          dicomCt: dicomCtAsset?.metadata?.birthDate,
          dicomXray: dicomXrayAsset ? dicomXrayAsset?.metadata?.birthDate : 'NO_XRAY',
          patient: action?.data?.case?.patient?.birthDate,
        };

        let mrnStatus: 'VALID' | 'MRN_MISSING' | 'MRN_MISMATCH' = 'VALID';

        let birthDateStatus: 'VALID' | 'BIRTHDATE_MISMATCH' | 'BIRTHDATE_DICOM_MISMATCH' = 'VALID';

        if (birthDates.dicomCt !== birthDates.patient) {
          birthDateStatus = 'BIRTHDATE_MISMATCH';
        } else if (
          birthDates.dicomXray !== 'NO_XRAY' &&
          birthDates.dicomCt !== birthDates.dicomXray
        ) {
          birthDateStatus = 'BIRTHDATE_DICOM_MISMATCH';
        }

        if (!medicalRecordNumbers.patient) {
          mrnStatus = 'MRN_MISSING';
        } else {
          if (
            medicalRecordNumbers.dicomCt !== medicalRecordNumbers.patient &&
            medicalRecordNumbers.dicomXray !== medicalRecordNumbers.patient
          ) {
            mrnStatus = 'MRN_MISMATCH';
          }
        }

        updatedState = {
          commentCount: action.data.comments.count,
          taskCount: taskCount,
          pinnedComment: (action.data.comments.comments ?? []).find(
            (comment: IComment) => comment.isPinned,
          ),
          activeCase,
          medicalRecordNumbers,
          mrnStatus,
          birthDates,
          birthDateStatus,
          canEdit: isEditable,
          stage: activeCase.stage,
          deletedAt: activeCase.deletedAt,
        };
      }
    }
    return updatedState;
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const studyDateExpiryStatus = getStudyDateExpiryStatus(
    state?.activeCase?.surgeryDate ?? null,
    state?.activeCase?.studyDate?.expiryDate ?? null,
  );

  const showStudyDateExpiration =
    studyDateExpiryStatus !== 'DEFAULT' && state?.stage !== CaseStageType.Complete;

  useEffect(() => {
    const activeCase = state.activeCase;

    if (activeCase) {
      trackEvent(TrackableUserEvent.ViewedCase, {
        caseNumber: activeCase.number,
        caseId: activeCase.caseId,
      });
    }
  }, [state.activeCase?.caseId]);

  return loading ? (
    <SplashScreen />
  ) : (
    <Page title={state?.activeCase?.number}>
      {state.activeCase ? (
        <CaseViewProvider measurementsVersion={MeasurementsVersionType.Version2}>
          <CaseHeaderView activeCase={state?.activeCase} dispatch={dispatch} />

          <Container maxWidth="xl">
            <Box sx={{ mt: 10 }}>
              {state?.activeCase?.caseCancellation ? (
                <CaseCancellationView caseCancellation={state?.activeCase?.caseCancellation} />
              ) : null}
              {state?.activeCase?.caseHold ? (
                <CaseHoldView caseHold={state?.activeCase?.caseHold} />
              ) : null}
              {state?.pinnedComment ? (
                <Box
                  mt={1}
                  display={'flex'}
                  alignItems={'center'}
                  p={2}
                  bgcolor={theme.palette.background.paper}
                >
                  <FontAwesomeIcon
                    color={theme.palette.text.secondary}
                    icon={faThumbtack}
                    size={'lg'}
                  />
                  <Box mx={1} />
                  <Typography color={'textSecondary'} variant={'body1'}>
                    {state.pinnedComment.comment}
                  </Typography>
                </Box>
              ) : null}
              {state?.stage === CaseStageType.Production ? (
                <Box mt={1}>
                  <Alert severity={'info'}>Case is currently in production</Alert>
                </Box>
              ) : null}
              {state?.stage === CaseStageType.Ready ? (
                <Box mt={1}>
                  <Alert severity={'info'}>Case is currently ready for surgery</Alert>
                </Box>
              ) : null}
              {state?.stage === CaseStageType.Complete ? (
                <Box mt={1}>
                  <Alert severity={'success'}>Case has been completed!</Alert>
                </Box>
              ) : null}
              {state.mrnStatus === 'MRN_MISSING' ? (
                <Box mt={1}>
                  <Alert severity={'error'}>
                    The Patient MRN is missing. Check the imaging data (DICOM CT MRN:{' '}
                    {state.medicalRecordNumbers.dicomCt ?? <>&mdash;</>} or DICOM X-Ray MRN:{' '}
                    {state.medicalRecordNumbers.dicomXray ?? <>&mdash;</>})
                  </Alert>
                </Box>
              ) : null}
              {state.mrnStatus === 'MRN_MISMATCH' ? (
                <Box mt={1}>
                  <Alert severity={'error'}>
                    The Patient MRN {state.medicalRecordNumbers.patient}. does not match the imaging
                    data (DICOM CT MRN: {state.medicalRecordNumbers.dicomCt ?? <>&mdash;</>} or
                    DICOM X-Ray MRN: {state.medicalRecordNumbers.dicomXray ?? <>&mdash;</>})
                  </Alert>
                </Box>
              ) : null}
              {state.birthDateStatus === 'BIRTHDATE_MISMATCH' ? (
                <Box mt={1}>
                  <Alert severity={'error'}>
                    The Patient Birthday {state.birthDates.patient ?? <>&mdash;</>}. does not match
                    the imaging data (DICOM CT Birthday: {state.birthDates.dicomCt ?? <>&mdash;</>})
                  </Alert>
                </Box>
              ) : null}
              {state.birthDateStatus === 'BIRTHDATE_DICOM_MISMATCH' ? (
                <Box mt={1}>
                  <Alert severity={'error'}>
                    DICOM CT Birthday: {state.birthDates.dicomCt ?? <>&mdash;</>} does not match the
                    imaging data (DICOM XRAY Birthday: {state.birthDates.dicomXray ?? <>&mdash;</>}
                  </Alert>
                </Box>
              ) : null}
              {state?.deletedAt ? (
                <Box mt={1}>
                  <Alert severity={'warning'}>Case has been deleted!</Alert>
                </Box>
              ) : null}
              {showStudyDateExpiration ? (
                <Box mt={1}>
                  <CaseStudyDateExpirationView
                    surgeryDate={state?.activeCase?.surgeryDate}
                    studyDate={state?.activeCase?.studyDate}
                  />
                </Box>
              ) : null}

              <Box mt={1}>
                <Box display={'flex'} justifyContent={'center'}>
                  <Tabs
                    value={viewTabValue}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    onChange={(_, newValue) => {
                      setViewTabValue(newValue);
                    }}
                    style={{ marginBottom: 8 }}
                    className={styles.tabsRoot}
                  >
                    <Tab label={'Case'} value={'CASE'} />
                    <Tab label={'Patient'} value={'PATIENT'} />
                    <Tab
                      label={
                        <Badge badgeContent={state.commentCount} color="secondary">
                          Discussion
                        </Badge>
                      }
                      value={'DISCUSSION'}
                    />
                    {auth?.hasPermission?.([Permission.ListTasks]) && (
                      <Tab
                        label={
                          <Badge badgeContent={state.taskCount} color="secondary">
                            Task
                          </Badge>
                        }
                        value={'TASK'}
                      />
                    )}
                    <Tab label={'Activity'} value={'ACTIVITY'} />
                    <Tab label={'Assets'} value={'ASSETS'} />
                  </Tabs>
                </Box>
                <div role={'tabpanel'} hidden={viewTabValue !== 'CASE'}>
                  {viewTabValue === 'CASE' ? (
                    <CaseTabView
                      activeCase={state.activeCase}
                      canEdit={state.canEdit}
                      dispatch={dispatch}
                    />
                  ) : null}
                </div>
                <div role={'tabpanel'} hidden={viewTabValue !== 'DISCUSSION'}>
                  {viewTabValue === 'DISCUSSION' ? (
                    <DiscussionTabView caseId={state.activeCase.caseId} />
                  ) : null}
                </div>
                <div role={'tabpanel'} hidden={viewTabValue !== 'PATIENT'}>
                  {viewTabValue === 'PATIENT' ? (
                    <PatientTabView activeCase={state.activeCase} dispatch={dispatch} />
                  ) : null}
                </div>
                {auth?.hasPermission?.([Permission.ListTasks]) && (
                  <div role={'tabpanel'} hidden={viewTabValue !== 'TASK'}>
                    {viewTabValue === 'TASK' ? <TaskTabView activeCase={state.activeCase} /> : null}
                  </div>
                )}
                <div role={'tabpanel'} hidden={viewTabValue !== 'ACTIVITY'}>
                  {viewTabValue === 'ACTIVITY' ? (
                    <ActivityTabView activeCase={state.activeCase} />
                  ) : null}
                </div>
                <div role={'tabpanel'} hidden={viewTabValue !== 'ASSETS'}>
                  {viewTabValue === 'ASSETS' ? (
                    <AssetsTabView activeCase={state.activeCase} />
                  ) : null}
                </div>
              </Box>
            </Box>
          </Container>
        </CaseViewProvider>
      ) : null}
    </Page>
  );
};

export default CaseView;
