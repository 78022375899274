import {
  ILevels,
  LevelType,
  caseUtils,
  IPostOpAnalysis,
  CorrectionPlanRuleType,
  ICaseReportCorrectionPlanRule,
} from '@workflow-nx/common';
import { CaseReportCorrectionPlanRuleType } from '../../views/cases/CaseView/CasePlanningTab/CaseReportDialog/CaseReportDialog.reducer';

type MainSettings = {
  pelvicIncidenceMinusPlanLumbarLordosis: { max: number };
  planLumbarLordosis: { condition?: string; min: number; max: number };
  posteriorDiscHeight: { condition?: string; min: number; max: number };
};

type DiscLumbarLordosisSettings = {
  [key: string]: { min: number; max: number };
};

type CaseReportRulesMinMaxSettings = {
  main: MainSettings;
  discLumbarLordosis: DiscLumbarLordosisSettings;
};

export const CASE_REPORT_RULES_MIN_MAX_SETTINGS: CaseReportRulesMinMaxSettings = {
  main: {
    pelvicIncidenceMinusPlanLumbarLordosis: { max: 20 },
    planLumbarLordosis: { condition: '<PRE_OP', min: 40, max: 70 },
    posteriorDiscHeight: { condition: '<PRE_OP', min: 4, max: 10 },
  },
  discLumbarLordosis: {
    L1_L2: { min: 2, max: 10 },
    L2_L3: { min: 2, max: 10 },
    L3_L4: { min: 2, max: 10 },
    L4_L5: { min: 2, max: 15 },
    L5_S1: { min: 2, max: 20 },
    L5_L6: { min: 2, max: 20 },
    L6_S1: { min: 2, max: 20 },
    L4_S1: { min: 2, max: 20 },
  },
};

function checkPelvicIncidenceMinusPlanLumbarLordosisRule(
  pelvicIncidence?: number | null,
  planLumbarLordosis?: number,
  mainSettings?: MainSettings,
  note?: string,
): CaseReportCorrectionPlanRuleType {
  const max = mainSettings?.pelvicIncidenceMinusPlanLumbarLordosis?.max;
  const planValue =
    pelvicIncidence && planLumbarLordosis ? pelvicIncidence - planLumbarLordosis : 0;
  const flagged = !!(max && planValue > max);

  return {
    ruleType: CorrectionPlanRuleType.PelvicIncidenceMinusPlanLumbarLordosis,
    planValue,
    condition: undefined,
    preOp: undefined,
    min: undefined,
    max,
    flagged,
    note,
  };
}

function checkPlanLumbarLordosisRule(
  planLumbarLordosis: number,
  preOpLumbarLordosis: number,
  settings: { condition?: string; min: number; max: number },
  note?: string,
): CaseReportCorrectionPlanRuleType {
  const { min, max } = settings;
  const flagged =
    planLumbarLordosis < preOpLumbarLordosis ||
    planLumbarLordosis < min ||
    planLumbarLordosis > max;

  return {
    ruleType: CorrectionPlanRuleType.PlanLumbarLordosis,
    planValue: planLumbarLordosis,
    condition: settings.condition,
    preOp: preOpLumbarLordosis,
    min,
    max,
    flagged,
    note,
  };
}

function checkPosteriorDiscHeightRule(
  level: LevelType,
  mainSettings: MainSettings,
  preOp?: IPostOpAnalysis,
  plan?: IPostOpAnalysis,
  note?: string,
): CaseReportCorrectionPlanRuleType {
  const posteriorDiscHeightSettings = mainSettings.posteriorDiscHeight;
  const preOpSegmentalPosteriorHeight = preOp?.segmentalPosteriorHeight[level];
  const planSegmentalPosteriorHeight = plan?.segmentalPosteriorHeight[level];
  const flagged =
    planSegmentalPosteriorHeight < preOpSegmentalPosteriorHeight ||
    planSegmentalPosteriorHeight < posteriorDiscHeightSettings.min ||
    planSegmentalPosteriorHeight > posteriorDiscHeightSettings.max;

  return {
    ruleType: CorrectionPlanRuleType.PosteriorDiscHeight,
    level,
    planValue: planSegmentalPosteriorHeight,
    condition: posteriorDiscHeightSettings.condition,
    preOp: preOpSegmentalPosteriorHeight,
    min: posteriorDiscHeightSettings.min,
    max: posteriorDiscHeightSettings.max,
    flagged,
    note,
  };
}

function checkDiscLumbarLordosisRule(
  level: LevelType,
  discLumbarLordosis: DiscLumbarLordosisSettings,
  plan?: IPostOpAnalysis,
  note?: string,
): CaseReportCorrectionPlanRuleType {
  const levelLumbarLordosis = discLumbarLordosis[level];
  const planSegmentalLumbarLordosis = plan?.segmentalLumbarLordosis?.[level] ?? undefined;
  const flagged =
    planSegmentalLumbarLordosis < levelLumbarLordosis.min ||
    planSegmentalLumbarLordosis > levelLumbarLordosis.max;

  return {
    ruleType: CorrectionPlanRuleType.DiscLumbarLordosis,
    level,
    planValue: planSegmentalLumbarLordosis,
    condition: undefined,
    preOp: undefined,
    min: levelLumbarLordosis.min,
    max: levelLumbarLordosis.max,
    flagged,
    note,
  };
}

const getNoteForRuleType = (
  caseReportCorrectionPlanRules: ICaseReportCorrectionPlanRule[],
  ruleType: CorrectionPlanRuleType,
  level?: LevelType,
): string | undefined => {
  const rule = level
    ? caseReportCorrectionPlanRules.find(
        (rule) => rule.ruleType === ruleType && rule.level === level,
      )
    : caseReportCorrectionPlanRules.find((rule) => rule.ruleType === ruleType);
  return rule ? rule.note : '';
};

export function applyCorrectionPlanRules(
  levels: ILevels,
  pelvicIncidence?: number | null,
  preOp?: IPostOpAnalysis,
  plan?: IPostOpAnalysis,
  caseReportCorrectionPlanRules?: ICaseReportCorrectionPlanRule[],
): CaseReportCorrectionPlanRuleType[] {
  const correctionPlanResults: CaseReportCorrectionPlanRuleType[] = [];
  const mainSettings: MainSettings = CASE_REPORT_RULES_MIN_MAX_SETTINGS.main;
  const discLumbarLordosis: DiscLumbarLordosisSettings =
    CASE_REPORT_RULES_MIN_MAX_SETTINGS.discLumbarLordosis;

  const preOpLumbarLordosis = preOp?.lumbarLordosis ?? 0;
  const planLumbarLordosis = plan?.lumbarLordosis ?? 0;

  const pelvicIncidenceMinusPlanLumbarLordosisRuleNote = getNoteForRuleType(
    caseReportCorrectionPlanRules ?? [],
    CorrectionPlanRuleType.PelvicIncidenceMinusPlanLumbarLordosis,
  );
  correctionPlanResults.push(
    checkPelvicIncidenceMinusPlanLumbarLordosisRule(
      pelvicIncidence,
      planLumbarLordosis,
      mainSettings,
      pelvicIncidenceMinusPlanLumbarLordosisRuleNote,
    ),
  );

  const planLumbarLordosisRuleNote = getNoteForRuleType(
    caseReportCorrectionPlanRules ?? [],
    CorrectionPlanRuleType.PlanLumbarLordosis,
  );
  correctionPlanResults.push(
    checkPlanLumbarLordosisRule(
      planLumbarLordosis,
      preOpLumbarLordosis,
      mainSettings.planLumbarLordosis,
      planLumbarLordosisRuleNote,
    ),
  );

  const caseLevels = caseUtils.getValidCaseLevels(levels);

  caseLevels.forEach((level) => {
    const posteriorDiscHeightRuleNote = getNoteForRuleType(
      caseReportCorrectionPlanRules ?? [],
      CorrectionPlanRuleType.PosteriorDiscHeight,
      level,
    );

    correctionPlanResults.push(
      checkPosteriorDiscHeightRule(level, mainSettings, preOp, plan, posteriorDiscHeightRuleNote),
    );
  });

  caseLevels.forEach((level) => {
    const discLumbarLordosisRuleNote = getNoteForRuleType(
      caseReportCorrectionPlanRules ?? [],
      CorrectionPlanRuleType.DiscLumbarLordosis,
      level,
    );

    correctionPlanResults.push(
      checkDiscLumbarLordosisRule(level, discLumbarLordosis, plan, discLumbarLordosisRuleNote),
    );
  });

  return correctionPlanResults;
}

export const checkGuardrailsFlagged = (
  caseReportCorrectionPlanRules: CaseReportCorrectionPlanRuleType[],
): boolean => {
  return caseReportCorrectionPlanRules.some((rule) => rule.flagged === true);
};
