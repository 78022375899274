import {
  Box,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CaseSpineProfile, TableType, caseUtils } from '@workflow-nx/common';
import { LumbarMeasurements } from '@workflow-nx/core-algorithms';
import { MeasurementsConfig } from '@workflow-nx/ui';
import { testing } from '@workflow-nx/utils';
import { LumbarMeasurementTableRow } from './LumbarMeasurementTableRow';

const useStyles = makeStyles<Theme, { color: 'invert' | 'default'; size: 'medium' | 'small' }>(
  (theme: Theme) => ({
    tableRoot: {
      '& .MuiTableCell-root ': {
        padding: ({ size }: { size: number | string }) =>
          size === 'medium' ? theme.spacing(1) : theme.spacing(0.25),
        '& .MuiTypography-root ': {
          fontSize: ({ size }: { size: number | string }) =>
            size === 'medium' ? theme.typography.fontSize : '0.8rem',
        },
      },
    },
  }),
);

export function LumbarMeasurementTable(props: {
  caseSpineProfile: CaseSpineProfile;
  caseLevels: string[];
  tableType: TableType;
  showErrors?: boolean;
  size: 'small' | 'medium';
  color: 'invert' | 'default';
  measurementsConfig: MeasurementsConfig;
  lumbarMeasurements: LumbarMeasurements;
}) {
  const styles = useStyles(props);
  const validLevels = caseUtils.getLevelsSortedByHierarchy(props.caseSpineProfile, 'desc');

  return (
    <Box data-testid={`${testing.toKebabCase(props.tableType)}-measurement-table`}>
      <Divider />
      <Table size={props.size} padding={'none'} className={styles.tableRoot}>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography noWrap variant={'body1'}>
                <strong>LEVEL</strong>
              </Typography>
            </TableCell>
            <TableCell align={'center'}>
              <Typography noWrap variant={'body1'}>
                <strong>LORDOSIS</strong>
              </Typography>
            </TableCell>
            <TableCell align={'center'}>
              <Typography noWrap variant={'body1'}>
                <strong>ANGLE TO S1</strong>
              </Typography>
            </TableCell>
            <TableCell align={'center'}>
              <Typography noWrap variant={'body1'}>
                <strong>CORONAL</strong>
              </Typography>
            </TableCell>
            <TableCell align={'center'}>
              <Typography noWrap variant={'body1'}>
                <strong>H-ANT (mm)</strong>
              </Typography>
            </TableCell>
            <TableCell align={'center'}>
              <Typography noWrap variant={'body1'}>
                <strong>H-POST (mm)</strong>
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {validLevels.map((level) => (
            <LumbarMeasurementTableRow
              data={props.lumbarMeasurements!.levels[level]}
              key={level}
              disabled={!props.caseLevels.includes(level)}
              levelType={level}
              tableType={props.tableType}
              showErrors={props.showErrors}
              measurementsConfig={props.measurementsConfig}
            />
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}
