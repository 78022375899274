import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import { useMutation, useQuery } from '@apollo/client';
import {
  FIND_PRICE_LEVEL_BY_PRICE_LEVEL_ID,
  CREATE_PRICE_LEVEL,
  UPDATE_PRICE_LEVEL,
} from '../../../gql';
import CustomDialog from '../../../components/CustomDialog';
import { Box, Grid } from '@mui/material';
import { DatePickerField } from '../../../components/DatePickerField';
import * as Yup from 'yup';
import { PriceLevelType } from '@workflow-nx/common';
import { date } from '@workflow-nx/utils';
import { DateTime } from 'luxon';
import { NumberTextField } from '../../../components/NumberTextField';
import { ProgressButton, SelectField } from '@workflow-nx/ui';

export function CreatePriceLevelDialog(props: {
  open: boolean;
  priceLevelId: number;
  organizationId: number;
  onClose: (shouldUpdate?: boolean) => void;
}) {
  const [initialValues, setInitialValues] = useState({
    organizationId: props.organizationId,
    priceLevelType: PriceLevelType.BasePlusLevel,
    oneLevel: 26000,
    twoLevel: 6000,
    threeLevel: 6000,
    fourLevel: 6000,
    fiveLevel: 6000,
    startDate: new Date(new Date().getFullYear(), 0, 1),
    endDate: null,
  });

  const priceLevelTypes = [
    { key: PriceLevelType.PerImplant, value: 'Per-implant' },
    { key: PriceLevelType.BasePlusLevel, value: 'Base plus level' },
    { key: PriceLevelType.Fixed, value: 'Fixed' },
  ];

  const { loading: loadingInitialValues } = useQuery(FIND_PRICE_LEVEL_BY_PRICE_LEVEL_ID, {
    variables: { priceLevelId: props.priceLevelId },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const priceLevel = data?.priceLevel;

      if (priceLevel) {
        setInitialValues({
          ...priceLevel,
          organizationId: priceLevel?.organization?.organizationId,
          startDate: date.parseCalendarDateFromString(priceLevel?.startDate),
          endDate: date.parseCalendarDateFromString(priceLevel?.endDate),
        });
      }
    },
  });
  const { enqueueSnackbar } = useSnackbar();
  const [createPriceLevel, { loading: loadingCreatePriceLevel }] = useMutation(CREATE_PRICE_LEVEL);
  const [updatePriceLevel, { loading: loadingUpdatePriceLevel }] = useMutation(UPDATE_PRICE_LEVEL);

  const handleSubmitForm = async (
    values: FormikValues,
    { setStatus, setSubmitting, resetForm }: FormikHelpers<any>,
  ) => {
    try {
      const startDate = values.startDate
        ? DateTime.fromJSDate(values.startDate).toISODate()
        : undefined;

      const endDate = values.endDate ? DateTime.fromJSDate(values.endDate).toISODate() : null;

      if (!props.priceLevelId) {
        await createPriceLevel({
          variables: {
            ...values,
            organizationId: props.organizationId,
            oneLevel: Number(values.oneLevel),
            twoLevel: Number(values.twoLevel),
            threeLevel: Number(values.threeLevel),
            fourLevel: Number(values.fourLevel),
            fiveLevel: Number(values.fiveLevel),
            startDate,
            endDate,
          },
        });
      } else {
        await updatePriceLevel({
          variables: {
            ...values,
            oneLevel: Number(values.oneLevel),
            twoLevel: Number(values.twoLevel),
            threeLevel: Number(values.threeLevel),
            fourLevel: Number(values.fourLevel),
            fiveLevel: Number(values.fiveLevel),
            startDate,
            endDate,
          },
        });
      }

      setStatus({ success: true });
      enqueueSnackbar(props.priceLevelId ? 'Price level updated' : 'Price level created', {
        variant: 'success',
      });
      resetForm();

      props.onClose(true);
    } catch (err) {
      console.error(err);
      setStatus({ success: false });
      enqueueSnackbar(
        props.priceLevelId
          ? 'An error occurred updating the price level'
          : 'An error occurred creating the price level',
        {
          variant: 'error',
        },
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        priceLevelType: Yup.string().required(),
        oneLevel: Yup.number().required(),
        twoLevel: Yup.number().required(),
        threeLevel: Yup.number().required(),
        fourLevel: Yup.number().required(),
        fiveLevel: Yup.number().required(),
        startDate: Yup.date().required(),
        endDate: Yup.date().nullable(),
      })}
      onSubmit={handleSubmitForm}
    >
      {({ resetForm, submitForm, isSubmitting }) => {
        const loading = loadingCreatePriceLevel || loadingUpdatePriceLevel || loadingInitialValues;
        return (
          <CustomDialog
            maxWidth={'md'}
            open={props.open}
            title={props.priceLevelId ? 'Update Price Level' : 'Create Price Level'}
            onClose={() => {
              resetForm();
              props.onClose();
            }}
            positiveActionButtons={[
              <ProgressButton
                variant={'contained'}
                disabled={isSubmitting || loading}
                onClick={() => submitForm()}
                label={!props.priceLevelId ? 'Create' : 'Update'}
                loading={isSubmitting || loading}
              />,
            ]}
          >
            <Box p={2}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <SelectField
                    name={'priceLevelType'}
                    label={'Price Level Type'}
                    hideNone={true}
                    menuItems={priceLevelTypes}
                    disabled={isSubmitting}
                  />
                </Grid>
                <Grid item xs={4}>
                  <DatePickerField
                    name={'startDate'}
                    label={'Start Date'}
                    required={true}
                    loading={isSubmitting}
                  />
                </Grid>
                <Grid item xs={4}>
                  <DatePickerField name={'endDate'} label={'End Date'} loading={isSubmitting} />
                </Grid>

                <Grid item xs>
                  <NumberTextField label={'One Level'} name={'oneLevel'} disabled={isSubmitting} />
                </Grid>
                <Grid item xs>
                  <NumberTextField label={'Two Level'} name={'twoLevel'} disabled={isSubmitting} />
                </Grid>
                <Grid item xs>
                  <NumberTextField
                    label={'Three Level'}
                    name={'threeLevel'}
                    disabled={isSubmitting}
                  />
                </Grid>
                <Grid item xs>
                  <NumberTextField
                    label={'Four Level'}
                    name={'fourLevel'}
                    disabled={isSubmitting}
                  />
                </Grid>
                <Grid item xs>
                  <NumberTextField
                    label={'Five Level'}
                    name={'fiveLevel'}
                    disabled={isSubmitting}
                  />
                </Grid>
              </Grid>
            </Box>
          </CustomDialog>
        );
      }}
    </Formik>
  );
}
