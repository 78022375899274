import { IPlan, PlanRejectionReason, format } from '@workflow-nx/common';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { FIND_ACTIVITY, CREATE_PLAN_REJECTION, UPDATE_PLAN_REJECTION } from '../../../gql';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import CustomDialog from '../../../components/CustomDialog';
import * as Yup from 'yup';
import {
  CheckboxListFieldRHF,
  ErrorMessageRHF,
  InputTypeRHF,
  ProgressButton,
  TextFieldRHF,
} from '@workflow-nx/ui';
import { useForm, Control, FieldValues, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TimelineActivity } from './DiscussionTab/DiscussionTabView';
import _ from 'lodash';

interface IPlanRejectionValues {
  reasons: string[];
  note: string;
}

interface IActivityUserData {
  createdByUser: {
    firstName: string;
    lastName: string;
    userId: number;
  };
  createdAt: string | Date;
}

const formRowTextData = [
  {
    id: 'reasons',
    label: 'Reasons',
    extra: true,
    menuItems: [
      {
        key: PlanRejectionReason.LordosisChange,
        value: format.formatPlanRejectionReason(PlanRejectionReason.LordosisChange),
      },
      {
        key: PlanRejectionReason.CoronalChange,
        value: format.formatPlanRejectionReason(PlanRejectionReason.CoronalChange),
      },
      {
        key: PlanRejectionReason.HeightChange,
        value: format.formatPlanRejectionReason(PlanRejectionReason.HeightChange),
      },
      {
        key: PlanRejectionReason.FootprintChange,
        value: format.formatPlanRejectionReason(PlanRejectionReason.FootprintChange),
      },
      {
        key: PlanRejectionReason.CagePosition,
        value: format.formatPlanRejectionReason(PlanRejectionReason.CagePosition),
      },
      {
        key: PlanRejectionReason.LevelImplantChange,
        value: format.formatPlanRejectionReason(PlanRejectionReason.LevelImplantChange),
      },
      {
        key: PlanRejectionReason.Admin,
        value: format.formatPlanRejectionReason(PlanRejectionReason.Admin),
      },
      {
        key: PlanRejectionReason.Other,
        value: format.formatPlanRejectionReason(PlanRejectionReason.Other),
      },
    ],
  },
  {
    id: 'note',
    label: 'Note',
    input: InputTypeRHF.Text,
    extra: false,
  },
];

export function ProposedPlanRejectionDialog({
  plan,
  open,
  activity,
  onClose,
}: {
  open: boolean;
  plan?: IPlan;
  activity?: TimelineActivity;
  onClose: (shouldUpdate: boolean) => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [createPlanRejection] = useMutation(CREATE_PLAN_REJECTION);
  const [updatePlanRejection] = useMutation(UPDATE_PLAN_REJECTION);
  const [activityUserData, setActivityUserData] = useState<IActivityUserData | null>();
  const [findActivity] = useLazyQuery(FIND_ACTIVITY, {
    variables: {
      activityId: activity?.activityId,
    },
    onCompleted: (data) => {
      const activityData = data?.activity;
      if (activityData?.createdByUser && activityData?.createdAt) {
        setActivityUserData({
          createdByUser: activityData?.createdByUser,
          createdAt: activityData?.createdAt,
        });
      }
    },
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm<IPlanRejectionValues>({
    defaultValues: {
      reasons: activity?.entry?.rejectionReasons ? activity?.entry?.rejectionReasons : [],
      note: activity?.delta?.reason?.[0] ?? activity?.entry?.note ?? '',
    },
    resolver: yupResolver(
      Yup.object().shape({
        note: Yup.string().required('Field must be filled out'),
        reasons: Yup.array()
          .min(1, 'At least one reason must be selected')
          .required('Field is required'),
      }),
    ),
  });

  const handleSubmitForm: SubmitHandler<IPlanRejectionValues> = async (values) => {
    try {
      if (activity) {
        await updatePlanRejection({
          variables: {
            planId: plan?.planId,
            note: values.note,
            reasons: values.reasons,
          },
        });
      } else {
        await createPlanRejection({
          variables: {
            planId: plan?.planId,
            note: values.note,
            reasons: values.reasons,
          },
        });
      }

      enqueueSnackbar('Proposed plan was rejected', {
        variant: 'success',
      });
      reset();
      onClose(true);
    } catch (err) {
      console.error(err);
      enqueueSnackbar('An error occurred rejecting the proposed plan', {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    if (activity && open) {
      if (activity?.entry?.createdByUser && activity?.entry?.createdAt) {
        setActivityUserData({
          createdByUser: activity.entry.createdByUser,
          createdAt: activity.entry.createdAt,
        });
      } else {
        findActivity();
      }
    }
  }, [open, activity]);

  return (
    <CustomDialog
      maxWidth={'md'}
      open={open}
      title={activity ? `Editing Previous Rejection Plan` : `Rejecting Proposed Plan ${plan?.name}`}
      onClose={() => {
        reset();
        onClose(false);
      }}
      positiveActionButtons={[
        <ProgressButton
          onClick={(evt) => handleSubmit(handleSubmitForm)(evt)}
          loading={isSubmitting}
          disabled={isSubmitting}
          label={activity ? 'Save' : 'Reject'}
        />,
      ]}
    >
      {open ? (
        <Box>
          {activity && activityUserData ? (
            <>
              <Stack spacing={2} direction={'row'} alignItems={'flex-end'}>
                <Typography variant={'button'} color={'textSecondary'}>
                  {'Last Rejected by:'}
                </Typography>
                <Typography variant={'body1'} fontWeight={500}>
                  {format.formatName(activityUserData?.createdByUser)} {'at '}
                  {format.formatDateTime(activityUserData?.createdAt)}
                </Typography>
              </Stack>
              <Box py={1}>
                <Divider />
              </Box>
            </>
          ) : null}
          <form>
            <Grid container spacing={3}>
              {formRowTextData.map((config, index) => {
                let sortedCheckBoxes = _.sortBy(config.menuItems, ['value']);
                if (!activity) {
                  sortedCheckBoxes = sortedCheckBoxes.filter(
                    (ele) => ele.key !== PlanRejectionReason.Other,
                  );
                }
                return (
                  <Grid item container xs={12} spacing={1} alignItems={'center'} key={index}>
                    <Grid item xs={3} md={3} display={'flex'} alignSelf={'flex-start'}>
                      <Typography variant={'body1'}>{config.label}</Typography>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      {config.input === InputTypeRHF.Text ? (
                        <TextFieldRHF
                          name={config.id}
                          control={control as unknown as Control<FieldValues>}
                          disabled={isSubmitting}
                          multiline={config.id === 'note'}
                        />
                      ) : (
                        <CheckboxListFieldRHF
                          name={config.id}
                          label={''}
                          listItems={sortedCheckBoxes ?? []}
                          control={control as unknown as Control<FieldValues>}
                          disabled={isSubmitting}
                          displayError={false}
                          disableInline={true}
                        />
                      )}
                    </Grid>
                    <Grid item xs={3} md={3} display={'flex'} justifyContent={'flex-start'}>
                      <ErrorMessageRHF
                        name={config.id}
                        control={control as unknown as Control<FieldValues>}
                      />
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </form>
        </Box>
      ) : null}
    </CustomDialog>
  );
}
