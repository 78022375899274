import React, { useEffect } from 'react';
import mixpanel, { Dict as MixpanelDict } from 'mixpanel-browser';
import { config } from '../extras/config';
import useAuth from '../hooks/useAuth';
import { format } from '@workflow-nx/common';

type AnalyticsContextState = {
  trackEvent: (event: TrackableUserEvent, payload?: MixpanelDict) => void;
};

export enum TrackableUserEvent {
  SignIn = 'User Signed In',
  SignOut = 'User Signed Out',
  ViewedCase = 'User Viewed Case',
}

export const AnalyticsContext = React.createContext<AnalyticsContextState>({
  trackEvent: () => {},
});

export const AnalyticsProvider = (props: { children: React.ReactNode }) => {
  const { user } = useAuth();

  useEffect(() => {
    mixpanel.init(config.mixpanel.token);

    mixpanel.register({
      appVersion: config.version,
      environment: config.environmentName,
    });

    if (config.environmentName != 'production') {
      mixpanel.disable();
    }
  }, []);

  useEffect(() => {
    if (user) {
      let fullName = format.formatName(user);

      mixpanel.identify(user.userId.toString());

      mixpanel.people.set({
        email: user.email,
        role: user.role,
        firstName: user.firstName,
        lastName: user.lastName,
        fullName,
        $name: fullName,
        $email: user.email,
      });

      trackEvent(TrackableUserEvent.SignIn);
    } else {
      mixpanel.reset();
    }
  }, [user?.userId]);

  const trackEvent: AnalyticsContextState['trackEvent'] = (eventName, payload) => {
    if (config.environmentName != 'production') {
      return;
    }

    mixpanel.track(eventName, payload || {});
  };

  return (
    <AnalyticsContext.Provider
      value={{
        trackEvent,
      }}
    >
      {props.children}
    </AnalyticsContext.Provider>
  );
};
