import React, { useRef, useState, useEffect, RefObject } from 'react';
import { Stack, TextField, Typography } from '@mui/material';
import { format } from '@workflow-nx/common';

export const VerificationCode = ({
  onComplete,
  expiresAt,
}: {
  onComplete: (code: string) => Promise<void>;
  expiresAt: Date | null;
}) => {
  const [code, setCode] = useState<string>('');

  const inputRefs: RefObject<HTMLInputElement>[] = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];

  const resetCode = () => {
    inputRefs.forEach((ref) => {
      if (ref.current) ref.current.value = '';
    });
    inputRefs[0].current?.focus();
    setCode('');
  };

  useEffect(() => {
    if (code.length === 6) {
      onComplete(code).then(() => {
        resetCode();
      });
    }
  }, [code, onComplete]);

  function handleInput(
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    index: number,
  ) {
    const input = e.target;
    const previousInput = inputRefs[index - 1];
    const nextInput = inputRefs[index + 1];

    // Update code state with single digit
    const newCode = [...code];
    // Convert lowercase letters to uppercase
    if (/^[a-z]+$/.test(input.value)) {
      const uc = input.value.toUpperCase();
      newCode[index] = uc;
      inputRefs[index].current!.value = uc;
    } else {
      newCode[index] = input.value;
    }
    setCode(newCode.join(''));

    input.select();

    if (input.value === '') {
      if (previousInput) {
        previousInput.current?.focus();
      }
    } else if (nextInput) {
      nextInput.current?.select();
    }
  }

  // Select the contents on focus
  function handleFocus(e: React.FocusEvent<HTMLInputElement>) {
    e.target.select();
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLDivElement>, index: number) {
    const input = e.target as HTMLInputElement;
    const previousInput = inputRefs[index - 1];

    if ((e.key === 'Backspace' || e.key === 'Delete') && input.value === '') {
      e.preventDefault();
      setCode((prevCode) => prevCode.slice(0, index) + prevCode.slice(index + 1));
      if (previousInput) {
        previousInput.current?.focus();
      }
    }
  }

  // Capture pasted characters
  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedCode = e.clipboardData.getData('text');
    if (pastedCode.length === 6) {
      setCode(pastedCode);
      inputRefs.forEach((inputRef, index) => {
        inputRef.current!.value = pastedCode.charAt(index);
      });
    }
  };

  return (
    <Stack spacing={1} alignItems={'center'}>
      <Stack
        direction={'row'}
        spacing={1}
        alignItems={'center'}
        justifyContent={'center'}
        sx={{ m: 1 }}
      >
        {[0, 1, 2, 3, 4, 5].map((index) => (
          <TextField
            key={index}
            type="text"
            inputProps={{
              maxLength: 1,
              style: {
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: '1.5em',
              },
            }}
            onChange={(e) => handleInput(e, index)}
            inputRef={inputRefs[index]}
            autoFocus={index === 0}
            onFocus={handleFocus}
            onKeyDown={(e) => handleKeyDown(e, index)}
            onPaste={handlePaste}
          />
        ))}
      </Stack>
      {expiresAt ? (
        <Typography variant={'body2'} color={'textSecondary'}>
          This code will expire at {format.formatDateTime(expiresAt)}
        </Typography>
      ) : null}
    </Stack>
  );
};
