import React, { useEffect } from 'react';
import Page from '../../../components/Page';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import SplashScreen from '../../../components/SplashScreen';
import useAuth from '../../../hooks/useAuth';
import { UserStatusType } from '@workflow-nx/common';

const TOTPView = () => {
  const location = useLocation();
  const auth = useAuth();
  const navigate = useNavigate();

  const handleLoad = async () => {
    const authCode = localStorage.getItem('authCode');

    /**
     * NOTE - During development, redirecting to the /totp route
     * may cause the page to load twice; however the authorization code
     * received from Cognito only works once; therefore we want to make sure we skip any subsequent validations if there is an ongoing request
     */
    const searchParams: URLSearchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');

    if (!token) {
      throw new Error(`Unable to authenticate user`);
    }

    if (authCode === token) {
      console.debug('Ongoing code validation... Skipping.');

      return;
    }

    localStorage.setItem('authCode', token);

    const userStatus = await auth?.validateOneTime?.(token);

    localStorage.removeItem('authCode');

    if (userStatus === UserStatusType.Active) {
      navigate('/');
    } else {
      localStorage.removeItem('jwt_token');
      localStorage.removeItem('user');

      window.location.href = `/login?reason=${userStatus ?? 'ERROR'}`;
    }
  };

  useEffect(() => {
    handleLoad();
  }, [handleLoad]);

  return (
    <Page>
      <SplashScreen />
    </Page>
  );
};

export default TOTPView;
