import { Box, Button, Chip, colors, Stack, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { format, ICase, IPlan } from '@workflow-nx/common';
import { red } from '@mui/material/colors';
import { PlanStatusChip } from './CasePlanningTab/PlanStatusChip';

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    display: 'grid',
    rowGap: 1,
    gridTemplateColumns: 'auto max-content max-content max-content max-content max-content',
    gridTemplateRows: '25 50',
    alignItems: 'center',
    backgroundColor: theme.palette.divider,
  },
  gridHeaderCell: {
    backgroundColor: 'white',
    height: 25,
    color: colors.grey[600],
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  gridCell: {
    backgroundColor: 'white',
    height: 50,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    alignItems: 'center',
    display: 'inline-flex',
  },
  deleted: {
    backgroundColor: red[100],
  },
}));

export function PlanGridTable(props: {
  activeCase: ICase;
  plans: IPlan[];
  onSelect: (plan: IPlan) => void;
}) {
  const styles = useStyles();

  return (
    <Box className={styles.gridContainer}>
      <Box className={styles.gridHeaderCell}>
        <Typography variant={'body1'}>Plan</Typography>
      </Box>
      <Box className={styles.gridHeaderCell}>
        <Typography variant={'body1'}>Version</Typography>
      </Box>
      <Box className={styles.gridHeaderCell}>
        <Typography variant={'body1'}>Operations</Typography>
      </Box>
      <Box className={styles.gridHeaderCell}>
        <Typography variant={'body1'}>Updated By</Typography>
      </Box>
      <Box className={styles.gridHeaderCell}>
        <Typography variant={'body1'}>Updated At</Typography>
      </Box>
      <Box className={styles.gridHeaderCell}>
        <Typography variant={'body1'}>Actions</Typography>
      </Box>
      {props?.plans?.map((plan: IPlan) => {
        const classes = `${styles.gridCell} ${styles.gridCell} ${
          plan.deletedAt ? styles.deleted : undefined
        }`;

        return (
          <>
            <Box className={classes}>
              <Stack direction={'row'} spacing={1} alignItems={'center'}>
                <Typography>{plan.name}</Typography>
                <PlanStatusChip status={plan.status} />
                {plan.isActive ? <Chip color={'success'} label={'ACTIVE'}></Chip> : null}
              </Stack>
            </Box>
            <Box className={classes}>
              <Typography variant={'body1'} sx={{ textAlign: 'center', width: '100%' }}>
                V{plan.version}
              </Typography>
            </Box>
            <Box className={classes}>
              <Typography variant={'body1'} sx={{ textAlign: 'center', width: '100%' }}>
                {plan.operations.length}
              </Typography>
            </Box>
            <Box className={classes}>
              <Typography variant={'body1'}>{format.formatDateTime(plan.updatedAt)}</Typography>
            </Box>
            <Box className={classes}>
              <Typography variant={'body1'}>{format.formatName(plan.updatedByUser)}</Typography>
            </Box>
            <Box className={classes}>
              <Button onClick={() => props.onSelect(plan)} disabled={plan.isActive}>
                Set Active
              </Button>
            </Box>
          </>
        );
      })}
    </Box>
  );
}
