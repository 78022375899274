import React from 'react';
import { Box, Typography, SelectChangeEvent } from '@mui/material';
import { Control, FieldValues } from 'react-hook-form';
import { SelectFieldRHF, TextFieldRHF, ErrorMessageRHF } from '@workflow-nx/ui';
import { PlanFeedbackType } from '@workflow-nx/common';
import { planFeedbackTypeMenuItems } from './caseReportMenuOptions';

interface CaseReportDesignerFeedbackProps {
  control: Control<FieldValues>;
  planFeedback?: PlanFeedbackType[];
  disabled: boolean;
  handleFeedbackChange: (event: SelectChangeEvent<unknown>) => void;
}

export const CaseReportDesignerFeedback: React.FC<CaseReportDesignerFeedbackProps> = ({
  control,
  planFeedback,
  disabled,
  handleFeedbackChange,
}) => {
  return (
    <Box>
      <Box display={'flex'} alignItems={'center'} mt={4}>
        <Box display={'flex'} justifyContent={'left'}>
          <Typography variant={'h5'}>
            <strong>
              Designer Feedback Section: Were there any limitations encountered during the case
              planning?
            </strong>
          </Typography>
        </Box>
      </Box>

      <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
        <Box display={'flex'} width={240} justifyContent={'left'}>
          <Typography variant={'body1'}>Select All That Apply</Typography>
        </Box>
        <Box display={'flex'} width={650} justifyContent={'left'}>
          <SelectFieldRHF
            name={'planFeedback'}
            control={control as unknown as Control<FieldValues>}
            label={''}
            hideNone={true}
            multiple={true}
            menuItems={planFeedbackTypeMenuItems}
            disabled={disabled}
            onChange={handleFeedbackChange}
          />
        </Box>
        <Box display={'flex'} width={240} justifyContent={'left'}>
          <ErrorMessageRHF
            name={'planFeedback'}
            control={control as unknown as Control<FieldValues>}
          />
        </Box>
      </Box>
      {planFeedback?.includes(PlanFeedbackType.Other) && (
        <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
          <Box display={'flex'} width={240} justifyContent={'left'}>
            <Typography variant={'body1'}>Please Describe</Typography>
          </Box>
          <Box display={'flex'} width={650} justifyContent={'left'}>
            <TextFieldRHF
              name={'planFeedbackOther'}
              control={control as unknown as Control<FieldValues>}
              label={''}
              fullWidth={true}
              multiline={true}
              disabled={disabled}
              shrink={false}
            />
          </Box>
          <Box display={'flex'} width={240} justifyContent={'left'}>
            <ErrorMessageRHF
              name={'planFeedbackOther'}
              control={control as unknown as Control<FieldValues>}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};
