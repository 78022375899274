import React from 'react';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripHorizontal } from '@fortawesome/pro-solid-svg-icons';
import { IconFontButton } from './IconFontButton';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

type DraggableCardProps = {
  initialPosition: { top?: number; left?: number; right?: number; bottom?: number };
  title?: string;
  width: number;
  height?: number;
  onClose?: () => void;
  children?: React.ReactNode;
};

type DraggableCardState = {
  activeDrags: number;
  deltaPosition: { x: number; y: number };
};

export class DraggableCard extends React.Component<DraggableCardProps, DraggableCardState> {
  state = {
    activeDrags: 0,
    deltaPosition: {
      x: 0,
      y: 0,
    },
  };

  handleDrag = (e: DraggableEvent, ui: DraggableData) => {
    const { x, y } = this.state.deltaPosition;
    this.setState({
      deltaPosition: {
        x: x + ui.deltaX,
        y: y + ui.deltaY,
      },
    });
  };

  handleStart = () => {
    this.setState({ activeDrags: ++this.state.activeDrags });
  };

  handleStop = () => {
    this.setState({ activeDrags: --this.state.activeDrags });
  };

  render() {
    return (
      <>
        {/* @ts-ignore */}
        <Draggable
          handle=".handle"
          onStart={this.handleStart}
          onDrag={this.handleDrag}
          onStop={this.handleStop}
        >
          <Card
            style={{
              position: 'absolute',
              top: this.props.initialPosition.top,
              left: this.props.initialPosition.left,
              bottom: this.props.initialPosition.bottom,
              right: this.props.initialPosition.right,
            }}
            variant={'outlined'}
          >
            <CardContent style={{ padding: 8, width: this.props.width, height: this.props.height }}>
              <Box display={'flex'} alignItems={'center'} mb={1}>
                <Box style={{ cursor: 'grab' }}>
                  <FontAwesomeIcon size={'lg'} className="handle" icon={faGripHorizontal} />
                </Box>
                <Box ml={0.5} />
                <Typography variant={'body1'}>
                  <strong>{this.props.title?.toUpperCase()}</strong>
                </Typography>
                <Box flexGrow={1} />
                {this.props.onClose ? (
                  <IconFontButton icon={faTimes} size={'small'} onClick={this.props?.onClose} />
                ) : null}
              </Box>
              <Box>{this.props.children}</Box>
            </CardContent>
          </Card>
        </Draggable>
      </>
    );
  }
}
