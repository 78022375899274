export type TaskTableActionType = {
  type: 'ORDER_BY_CHANGED' | 'refetch';
  data?: any;
};

export type TaskTabStateType = {
  orderBy?: { [key: string]: 'asc' | 'desc' };
};

export const TaskTabReducer =
  (refetch: any) =>
  (state: TaskTabStateType, action: TaskTableActionType): TaskTabStateType => {
    const updatedState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
      case 'refetch':
        refetch();
        break;
      case 'ORDER_BY_CHANGED':
        updatedState.orderBy = action.data;
        break;
    }

    let orderBy = updatedState.orderBy;

    if (Object.keys(orderBy)[0] === 'assignedId') {
      orderBy = { assignedUser: { lastName: orderBy['assignedId'] } };
    }

    refetch({
      orderBy,
    });

    return updatedState;
  };
