import { format, ITableHeader, ITask } from '@workflow-nx/common';
import { TableBody, TableCell, TableRow, Typography } from '@mui/material';
import React from 'react';
import { CommonTable } from '../../../components/CommonTable';

export function TaskListPopoverTable(props: { loading?: boolean; tasks: ITask[] }) {
  const headers: ITableHeader[] = [
    {
      id: 'priority',
      label: 'Priority',
    },
    {
      id: 'description',
      label: 'Task',
    },
    {
      id: 'dueDate',
      label: 'Due Date',
    },
    {
      id: 'assignedId',
      label: 'Assigned To',
    },
    {
      id: 'status',
      label: 'Status',
    },
  ];

  return (
    <CommonTable
      headers={headers}
      rows={
        <TableBody>
          {props?.tasks?.map((task: ITask) => (
            <TableRow hover key={task.taskId}>
              <TableCell style={{ verticalAlign: 'top' }}>
                <Typography variant={'body1'} noWrap>
                  {format.formatTaskPriorityType(task?.priority)}
                </Typography>
              </TableCell>
              <TableCell style={{ verticalAlign: 'top', maxWidth: '600px' }}>
                <Typography variant={'body1'} noWrap>
                  {task?.description}
                </Typography>
              </TableCell>
              <TableCell style={{ verticalAlign: 'top' }}>
                <Typography variant={'body1'} noWrap>
                  {task?.dueDate ? (
                    format.formatISODate(task?.dueDate as unknown as string)
                  ) : (
                    <span>&mdash;</span>
                  )}
                </Typography>
              </TableCell>
              <TableCell style={{ verticalAlign: 'top' }}>
                <Typography variant={'body1'} noWrap>
                  {format.formatName(task.assignedUser)}
                </Typography>
              </TableCell>
              <TableCell style={{ verticalAlign: 'top' }}>
                <Typography variant={'body1'} noWrap>
                  {format.formatTaskStatusType(task?.status)}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      }
      loading={props.loading}
    />
  );
}
