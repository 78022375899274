import { format, IUser, UserRoleType, Permission } from '@workflow-nx/common';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { UPDATE_USER_PERMISSIONS } from '../../../gql';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import { Box, Grid } from '@mui/material';
import CustomDialog from '../../../components/CustomDialog';
import { Button, CheckboxListField, ProgressButton, SelectField } from '@workflow-nx/ui';
import * as Yup from 'yup';
import { permissions } from '@workflow-nx/utils';
import useAuth from '../../../hooks/useAuth';
import {
  adminPermissions,
  casePermissions,
  opsPermissions,
  reportPermissions,
  userAndOrganizationPermissions,
} from './UserView.view';
import { getValidUserRolesKVPSorted } from '../../../utils/user';

const userRoleTypes = getValidUserRolesKVPSorted();

export function EditUserPermissionsDialog({
  user,
  open,
  onClose,
}: {
  open: boolean;
  user: IUser;
  onClose: (shouldUpdate: boolean) => void;
}) {
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [updateUserPermissions, { loading: loadingUpdateUser }] =
    useMutation(UPDATE_USER_PERMISSIONS);
  const [defaultPermissionsForRole, setDefaultPermissionsForRole] = useState<Permission[]>([]);

  const [initialState, setInitialState] = useState<{
    role?: UserRoleType;
    permissions: Permission[];
  }>({
    role: undefined,
    permissions: [],
  });

  const handleSubmitForm = async (
    values: FormikValues,
    { setStatus, setSubmitting }: FormikHelpers<{ role?: UserRoleType; permissions: Permission[] }>,
  ) => {
    try {
      await updateUserPermissions({
        variables: {
          userId: user.userId,
          role: values.role,
          permissions: values.permissions,
        },
      });
      await auth?.refreshCurrentUser?.();

      setStatus({ success: true });
      enqueueSnackbar('User Permissions Updated', {
        variant: 'success',
      });

      onClose(true);
    } catch (err) {
      console.error(err);
      setStatus({ success: false });
      enqueueSnackbar('An error occurred updating the user permissions', {
        variant: 'error',
      });
    } finally {
      setSubmitting(false);
    }
  };

  const userDialogSchema = Yup.object().shape({
    role: Yup.string().required('Role is required'),
    permissions: Yup.array().required('Permissions are required'),
  });

  useEffect(() => {
    if (user) {
      setDefaultPermissionsForRole(permissions.getDefaultPermissionsForRole(user.role) ?? []);
      setInitialState({
        role: user.role,
        permissions: user?.permissions ?? [],
      });
    }
  }, [user]);

  return (
    <Formik
      initialValues={initialState}
      validationSchema={userDialogSchema}
      onSubmit={handleSubmitForm}
      enableReinitialize={true}
    >
      {({ handleSubmit, isSubmitting, submitForm }) => (
        <CustomDialog
          maxWidth={'lg'}
          open={open}
          title={`Edit User Permissions - ${format.formatName(user)}`}
          onClose={() => {
            onClose(false);
          }}
          positiveActionButtons={[
            <ProgressButton
              onClick={() => submitForm()}
              loading={isSubmitting}
              disabled={isSubmitting}
              label={'Save Permissions'}
            />,
          ]}
        >
          {open ? (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} alignItems={'center'}>
                <Grid item xs={12}>
                  <Box display={'flex'} alignItems={'center'}>
                    <SelectField
                      hideNone={true}
                      name={'role'}
                      label={'Role'}
                      onChange={(e) => {
                        const role = e.target.value;
                        setInitialState({
                          role: role,
                          permissions: permissions.getDefaultPermissionsForRole(role),
                        });
                        setDefaultPermissionsForRole(
                          permissions.getDefaultPermissionsForRole(role),
                        );
                      }}
                      menuItems={userRoleTypes}
                      disabled={loadingUpdateUser}
                    />
                    <Box mx={2} />
                    <Button
                      color={'primary'}
                      label={'Reset'}
                      variant={'outlined'}
                      onClick={() => {
                        setInitialState({
                          role: user.role,
                          permissions: permissions.getDefaultPermissionsForRole(user.role),
                        });
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <CheckboxListField
                    name={'permissions'}
                    label={'Case Permissions'}
                    listItems={casePermissions}
                    disabled={loadingUpdateUser}
                    onLabelRender={(key: string, value: string) =>
                      defaultPermissionsForRole.includes(key as Permission) ? `${value} ★` : value
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <CheckboxListField
                    name={'permissions'}
                    label={'User / Institution Permissions'}
                    listItems={userAndOrganizationPermissions}
                    disabled={loadingUpdateUser}
                    onLabelRender={(key: string, value: string) =>
                      defaultPermissionsForRole.includes(key as Permission) ? `${value} ★` : value
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <CheckboxListField
                    name={'permissions'}
                    label={'Reporting Permissions'}
                    listItems={reportPermissions}
                    disabled={loadingUpdateUser}
                    onLabelRender={(key: string, value: string) =>
                      defaultPermissionsForRole.includes(key as Permission) ? `${value} ★` : value
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <CheckboxListField
                    name={'permissions'}
                    label={'Operations Permissions'}
                    listItems={opsPermissions}
                    disabled={loadingUpdateUser}
                    onLabelRender={(key: string, value: string) =>
                      defaultPermissionsForRole.includes(key as Permission) ? `${value} ★` : value
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <CheckboxListField
                    name={'permissions'}
                    label={'Admin Permissions'}
                    listItems={adminPermissions}
                    disabled={loadingUpdateUser}
                    onLabelRender={(key: string, value: string) =>
                      defaultPermissionsForRole.includes(key as Permission) ? `${value} ★` : value
                    }
                  />
                </Grid>
              </Grid>
              <Box mt={1} />
            </form>
          ) : null}
        </CustomDialog>
      )}
    </Formik>
  );
}
