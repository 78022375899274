import React, { useReducer, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import Page from '../../../components/Page';
import { useQuery } from '@apollo/client';
import { FIND_CALENDAR_CASES } from '../../../gql';
import { CustomAppBar } from '../../../components/CustomAppBar';
import {
  CaseCancellationListType,
  CaseStageType,
  CaseType,
  format,
  ICase,
  ICaseCalendarFilteredCases,
  ICaseCalendarQueryVariables,
} from '@workflow-nx/common';
import { DateTime } from 'luxon';
import { CaseCalendarReducer, CaseCalendarStateType } from './CaseCalendar.reducer';
import { Heading } from '../../../components/Heading';
import { CaseCalendarFilter } from './CaseCalendarFilter';
import clsx from 'clsx';
import {
  CalendarCaseCategory,
  CASE_CATEGORY_COLOR_LEGEND,
  getCalendarCaseCategory,
  useCalendarStyles,
} from './config';
import { parseISO } from 'date-fns';
import { CalendarGridView } from './CalendarGridView';

export function getMonthStartAndEndISODate(date: Date): [string, string] {
  //The number added to the Altered day is to query and account for the extra days(outside of the current month)
  //that will appear on the full calendar
  //So for alteredFirstDayThisMonth at most extra days before that would be 6
  //And for alteredFirstDayNextMonth at most extra days after would be 14
  const alteredFirstDayThisMonth = new Date(date.getFullYear(), date.getMonth(), -6);
  const alteredFirstDayNextMonth = new Date(date.getFullYear(), date.getMonth() + 1, +14);

  return [
    DateTime.fromJSDate(alteredFirstDayThisMonth).toISODate() as string,
    DateTime.fromJSDate(alteredFirstDayNextMonth).toISODate() as string,
  ];
}

function getDefaultState() {
  const cache = localStorage.getItem('CaseCalendar.cache');
  let defaultVariables: ICaseCalendarQueryVariables;
  let initialState: CaseCalendarStateType;
  const [startDate, endDate] = getMonthStartAndEndISODate(new Date());

  if (cache) {
    const hydratedCache: {
      variables: ICaseCalendarQueryVariables;
      state: CaseCalendarStateType;
    } = JSON.parse(cache);
    defaultVariables = hydratedCache.variables;

    // these fields should always default to the following
    defaultVariables.startDate = startDate;
    defaultVariables.endDate = endDate;
    defaultVariables.caseCancellationTypeFilter = [CaseCancellationListType.None];

    initialState = hydratedCache.state;
  } else {
    defaultVariables = {
      caseCancellationTypeFilter: [CaseCancellationListType.None],
      caseTypeFilter: [CaseType.Live],
      tagsFilter: [],
      stageFilter: [
        CaseStageType.Open,
        CaseStageType.Segmentation,
        CaseStageType.Planning,
        CaseStageType.Proposed,
        CaseStageType.Design,
        CaseStageType.QaReview,
        CaseStageType.Production,
        CaseStageType.Ready,
        CaseStageType.Complete,
      ],
      orderBy: { surgeryDate: 'asc' },
      search: '',
      showTentativeSurgeryDates: true,
      startDate,
      endDate,
    };

    initialState = {
      organization: '',
      surgeon: '',
      fieldRep: '',
      orderBy: { surgeryDate: 'asc' },
      search: '',
      caseStatus: '',
      caseTags: [],
      showTentativeSurgeryDates: true,
      showDraftCases: false,
    };
  }

  return [defaultVariables, initialState];
}

const CaseCalendarView = () => {
  const styles = useCalendarStyles();

  const [calendarDate, setCalendarDate] = useState(new Date());
  const [defaultVariables, initialState] = getDefaultState();
  const { loading, data, refetch } = useQuery(FIND_CALENDAR_CASES, {
    variables: defaultVariables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const theme = useTheme();

  const [state, dispatch] = useReducer(
    CaseCalendarReducer(refetch),
    initialState as CaseCalendarStateType,
  );

  function getSurgeryDate(surgeryDate: Date) {
    return format.formatISODate(surgeryDate as unknown as string);
  }

  function handleCalendarDateChange(newDate: Date) {
    const [startDate, endDate] = getMonthStartAndEndISODate(newDate);
    setCalendarDate(newDate);

    dispatch({
      type: 'CASE_DATE_RANGE_CHANGED',
      data: {
        startDate,
        endDate,
      },
    });
  }

  function isSameCalendarMonth(inputDate: string): boolean {
    let inputDateCompare = parseISO(inputDate);
    const calendarDateCompare = new Date(calendarDate.getFullYear(), calendarDate.getMonth());
    inputDateCompare = new Date(inputDateCompare.getFullYear(), inputDateCompare.getMonth());
    return inputDateCompare.getTime() === calendarDateCompare.getTime();
  }

  const filteredCases: ICaseCalendarFilteredCases[] = data?.cases?.cases
    ?.filter((activeCase: ICase) => !!activeCase.surgeryDate)
    .map((activeCase: ICase) => {
      const caseCategory = getCalendarCaseCategory(activeCase) as CalendarCaseCategory;
      const { palette, stripedClass } = CASE_CATEGORY_COLOR_LEGEND(theme)[caseCategory];
      const className =
        (activeCase.surgeryDate && activeCase.isSurgeryDateTentative) ||
        activeCase.caseType === CaseType.Draft
          ? clsx([styles[stripedClass as keyof typeof styles], styles.stripeTextBackground])
          : [styles.stripeTextBackground];

      return {
        title: activeCase.number,
        date: getSurgeryDate(activeCase.surgeryDate as Date),
        extendedProps: activeCase,
        backgroundColor: isSameCalendarMonth(getSurgeryDate(activeCase.surgeryDate as Date))
          ? palette.main
          : palette.main + 90,
        borderColor: palette.main,
        textColor: palette.contrastText,
        className,
      };
    });

  return (
    <Page title={'Case Calendar'}>
      <Container maxWidth={false}>
        <CustomAppBar title={`Case Calendar`} />
        <Box>
          <Grid container>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Box mb={3}>
                    <Box display={'flex'} alignItems={'center'} height={40} mb={1}>
                      <Heading label={'Filters'} />
                    </Box>
                    <Card>
                      <CardContent>
                        <CaseCalendarFilter state={state} dispatch={dispatch} loading={loading} />
                      </CardContent>
                    </Card>
                  </Box>

                  <Box>
                    <Box display={'flex'} alignItems={'center'} height={40} mb={1}>
                      <Heading label={'Legend'} />
                    </Box>
                    <Card>
                      <CardContent>
                        <Box py={2}>
                          <Box mb={2}>
                            {Object.entries(CASE_CATEGORY_COLOR_LEGEND(theme)).map(
                              ([categoryKey, categoryColor]) => (
                                <Box
                                  display={'flex'}
                                  alignItems="center"
                                  mb={1.5}
                                  key={categoryKey}
                                >
                                  <Box
                                    sx={{
                                      bgcolor: categoryColor.palette.main,
                                    }}
                                    className={
                                      categoryKey === CalendarCaseCategory.Draft
                                        ? clsx([styles.legendBox, styles.stripedLegendBox])
                                        : styles.legendBox
                                    }
                                  />
                                  <Typography>{categoryKey}</Typography>
                                </Box>
                              ),
                            )}
                          </Box>
                          <Divider />
                          <Box mt={2}>
                            <Box display={'flex'} alignItems="center" mb={1.5}>
                              <Box className={clsx([styles.legendBox, styles.stripedLegendBox])} />
                              <Typography>Surgery Date Tentative</Typography>
                            </Box>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Box>
                </Grid>
                <Grid item xs={10}>
                  <Box>
                    <Card>
                      <CardContent>
                        <CalendarGridView
                          events={filteredCases}
                          loading={loading}
                          onMonthChange={handleCalendarDateChange}
                        />
                      </CardContent>
                    </Card>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
};

export default CaseCalendarView;
