import { faExternalLink } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Box, Button, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ICase } from '@workflow-nx/common';
import { ProgressButton, SelectField, TextField } from '@workflow-nx/ui';
import CustomDialog from 'apps/workflow-client/src/app/components/CustomDialog';
import config from 'apps/workflow-client/src/app/extras/config';
import { Formik } from 'formik';
import * as Yup from 'yup';

enum DHRReviewStatus {
  Approved = 'Approved',
  Rejected = 'Rejected',
}

const useStyles = makeStyles(() => ({
  alertMessage: {
    flexGrow: 1,
  },
  alertIcon: {
    alignItems: 'center',
  },
}));

const ReviewDHRDialog: React.FC<{
  caseData: ICase;
  loading: boolean;
  visible: boolean;
  onClose: () => void;
  onSave: (isApproved: boolean, note: string) => Promise<void>;
}> = ({ caseData, loading, visible, onClose, onSave }) => {
  const classes = useStyles();
  return (
    <Formik
      initialValues={{ note: '', status: DHRReviewStatus.Approved }}
      validationSchema={Yup.object().shape({
        status: Yup.string().oneOf(Object.values(DHRReviewStatus)).required(),
        note: Yup.string().when('status', {
          is: DHRReviewStatus.Rejected,
          then: (schema) => schema.required(),
        }),
      })}
      onSubmit={(form) => {
        onSave(form.status === DHRReviewStatus.Approved, form.note);
      }}
      enableReinitialize={true}
    >
      {({ isSubmitting, submitForm, values, resetForm, isValid }) => {
        const submitDisabled = loading || isSubmitting || !isValid;

        return (
          <CustomDialog
            maxWidth={'md'}
            fullHeight={false}
            open={visible}
            title={`Review DHR - ${caseData.number}`}
            onClose={() => {
              onClose();
              resetForm();
            }}
            positiveActionButtons={[
              <ProgressButton
                onClick={async () => {
                  await submitForm();
                }}
                loading={isSubmitting || loading}
                disabled={submitDisabled}
                label={'Submit Review'}
              />,
            ]}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Alert
                  severity={'info'}
                  classes={{ message: classes.alertMessage, icon: classes.alertIcon }}
                >
                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    flexDirection="row"
                    alignItems={'center'}
                    width={'100%'}
                  >
                    <span>Review the DHR</span>
                    <Button
                      variant={'text'}
                      href={config.operations.mainstreamDocumentsUrl}
                      target="_blank"
                      endIcon={<FontAwesomeIcon icon={faExternalLink} />}
                    >
                      Open Box.com
                    </Button>
                  </Box>
                </Alert>
              </Grid>
              <Grid item xs={12}>
                <SelectField
                  name={'status'}
                  label={'Status'}
                  required={true}
                  hideNone={true}
                  menuItems={Object.values(DHRReviewStatus).map((status) => ({
                    key: status,
                    value: status,
                  }))}
                  disabled={loading}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={loading}
                  name="note"
                  label={values.status === DHRReviewStatus.Approved ? 'Note (optional)' : 'Note'}
                  multiline={true}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Box mt={1} />
          </CustomDialog>
        );
      }}
    </Formik>
  );
};

export default ReviewDHRDialog;
