import { useField } from 'formik';
import {
  Checkbox as MuiCheckbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
} from '@mui/material';
import React from 'react';
import { testing } from '@workflow-nx/utils';

export function CheckboxListField(props: {
  label?: string;
  name: string;
  disabled?: boolean;
  required?: boolean;
  listItems: { key: string; value: string }[];
  onLabelRender?: (key: string, value: string) => string;
}) {
  const [input, meta] = useField(props.name);

  return (
    <FormControl
      required={props.required}
      error={Boolean(meta.touched && meta.error)}
      component="fieldset"
    >
      {props.label ? <FormLabel component="legend">{props.label}</FormLabel> : null}
      <FormGroup style={{ display: 'inline' }}>
        {props.listItems.map((listItem) => {
          return (
            <FormControlLabel
              key={listItem.key}
              control={
                <MuiCheckbox
                  data-test-id={`${testing.toKebabCase(props.name)}-${testing.toKebabCase(
                    listItem.key,
                  )}-checkbox`}
                  checked={input?.value?.includes?.(listItem.key)}
                  onChange={input.onChange}
                  name={props.name}
                  value={listItem.key}
                  disabled={props.disabled}
                />
              }
              label={props?.onLabelRender?.(listItem.key, listItem.value) ?? listItem.value}
            />
          );
        })}
      </FormGroup>
      <FormHelperText>{meta.error}</FormHelperText>
    </FormControl>
  );
}
