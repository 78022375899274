import {
  AssetType,
  CaseStageType,
  format,
  ICase,
  IPlan,
  Permission,
  PlanStatusType,
  ValidFileExtensions,
} from '@workflow-nx/common';
import { useSnackbar } from 'notistack';
import { gql, useMutation, useQuery } from '@apollo/client';
import { APPROVE_PROPOSED_PLAN } from '../../../../gql';
import useCreateAndUploadAsset from '../../../../hooks/useCreateAndUploadAsset';
import { Control, Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DateTime } from 'luxon';
import { InputConfigRHF, InputRHF, InputTypeRHF, ProgressButton } from '@workflow-nx/ui';
import React, { ComponentProps, useState } from 'react';
import { Alert, Box, Button, Grid, Stack, Typography } from '@mui/material';
import * as Yup from 'yup';
import { ProposedPlanRejectionDialog } from '../ProposedPlanRejectionDialog';
import useAuth from '../../../../hooks/useAuth';

export function ReviewPlanView(props: { activeCase: ICase; plan: IPlan; onUpdate: () => void }) {
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [approveProposedPlan, { loading }] = useMutation(APPROVE_PROPOSED_PLAN);
  const { createAndUploadAsset, uploadProgress } = useCreateAndUploadAsset();
  const [openProposedPlanRejectionDialog, setOpenProposedPlanRejectionDialog] = useState(false);

  const stageComplete =
    props.activeCase.stage !== CaseStageType.Open &&
    props.activeCase.stage !== CaseStageType.Segmentation &&
    props.activeCase.stage !== CaseStageType.Planning &&
    props.activeCase.stage !== CaseStageType.Proposed;
  const editingAllowed = !!auth.hasPermission?.([Permission.ManageCase, Permission.EditCase]);

  const { loading: loadingAssets, data } = useQuery(
    gql`
      query FindAsset($caseId: Int!, $planId: Int!, $assetTypeFilter: [AssetType]) {
        assets(caseId: $caseId, planId: $planId, assetTypeFilter: $assetTypeFilter) {
          assetId
          fileName
          assetType
          signedDownloadUrl
        }
      }
    `,
    {
      variables: {
        caseId: props.activeCase.caseId,
        planId: props.plan.planId,
        assetTypeFilter: [AssetType.Form19ApprovalSignature],
      },
    },
  );

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: { approvedAt: undefined },
    resolver: yupResolver(
      Yup.object().shape({
        approvedAt: Yup.date().typeError('Invalid Date').required('Field is required'),
        signatureAsset: Yup.mixed().test('fileSize', 'The file is too large', (value) => {
          const file = value as File | undefined | null;

          if (!file) return false;

          return file.size >= Math.pow(2, 7);
        }),
      }),
    ) as unknown as Resolver<{
      approvedAt?: Date;
      signatureAsset?: File;
    }>,
  });

  const handleSubmitForm: SubmitHandler<{
    approvedAt?: Date;
    signatureAsset?: File;
  }> = async (data) => {
    try {
      await createAndUploadAsset(
        data.signatureAsset as File,
        AssetType.Form19ApprovalSignature,
        props.activeCase.caseId,
        props.plan.planId,
      );

      const approvedAt = data.approvedAt
        ? DateTime.fromJSDate(data.approvedAt).toISODate()
        : undefined;

      await approveProposedPlan({ variables: { caseId: props.activeCase.caseId, approvedAt } });

      enqueueSnackbar('Plan approved', {
        variant: 'success',
      });

      props.onUpdate();
    } catch (err: any) {
      console.error(err);

      enqueueSnackbar('An error occurred approving the plan', {
        variant: 'error',
      });
    } finally {
      reset();
    }
  };

  const formItems: InputConfigRHF[] = [
    {
      id: 'approvedAt',
      label: 'Approved At',
      input: InputTypeRHF.Date,
    },
    {
      id: 'signatureAsset',
      label: 'Surgeon Signature',
      input: InputTypeRHF.Asset,
    },
  ];

  const getInputProps = (config: InputConfigRHF): ComponentProps<typeof InputRHF> => ({
    config: config,
    control: control as Control,
    disabled: isSubmitting || loading,
    datePickerProps: {
      disableFuture: true,
    },
    dropzoneProps: {
      validFileExtensions: [ValidFileExtensions.JPG, ValidFileExtensions.PNG],
    },
  });

  return (
    <>
      {props.plan.status === PlanStatusType.Approved ? (
        <Stack spacing={1}>
          <Alert severity={'info'}>
            The plan was approved on {format.formatDate(props.plan.approvedAt ?? new Date())} by{' '}
            {format.formatName(props.plan.updatedByUser)}
          </Alert>
          <Typography variant={'h4'}>Approval Signature</Typography>
          <img
            style={{
              height: 200,
              objectFit: 'contain',
              objectPosition: 'left',
              border: '1px solid #c0c0c0',
              borderRadius: 5,
            }}
            src={data?.assets?.[0]?.signedDownloadUrl}
            alt={'approval signature'}
          />
        </Stack>
      ) : null}
      {props.plan.status === PlanStatusType.Proposed ? (
        <Box>
          <Grid container spacing={3}>
            {formItems.map((config) => {
              if (config.id === 'signatureAsset') {
                config.label = format.formatAssetType(AssetType.Form19ApprovalSignature);
              }

              return <InputRHF key={config.id} {...getInputProps(config)} />;
            })}
          </Grid>
          <Grid container spacing={3} sx={{ pt: 1 }}>
            <Grid item xs={3}></Grid>
            <Grid item xs={6}>
              <Stack direction={'row'} justifyContent={'space-between'} sx={{ mt: 1 }}>
                <Button
                  variant={'outlined'}
                  disabled={
                    !editingAllowed || stageComplete || !!props?.activeCase?.caseCancellation
                  }
                  onClick={() => setOpenProposedPlanRejectionDialog(true)}
                >
                  Reject Proposed Plan
                </Button>
                <ProgressButton
                  variant={'contained'}
                  disabled={isSubmitting || loading}
                  onClick={(evt) => handleSubmit(handleSubmitForm)(evt)}
                  label={'Approve Proposed Plan'}
                  loading={isSubmitting || loading}
                />
              </Stack>
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
        </Box>
      ) : null}

      <ProposedPlanRejectionDialog
        open={openProposedPlanRejectionDialog}
        plan={props?.plan}
        onClose={(shouldUpdate) => {
          if (shouldUpdate) {
            props.onUpdate();
          }
          setOpenProposedPlanRejectionDialog(false);
        }}
      />
    </>
  );
}
