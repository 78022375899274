import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, SelectChangeEvent } from '@mui/material';
import {
  CarrierType,
  CaseShippingStatusType,
  CaseStageType,
  EventType,
  IOrganizationMetadata,
  format,
  getEventAssetType,
  getSubsequentManualEvents,
  isTrackableEvent,
} from '@workflow-nx/common';
import { InputConfigRHF, InputRHF, InputTypeRHF, ProgressButton } from '@workflow-nx/ui';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import { ComponentProps, useCallback, useState } from 'react';
import { Control, FieldValues, Resolver, SubmitHandler, useForm } from 'react-hook-form';
import CustomDialog from '../../../../components/CustomDialog';
import {
  ICreateEventDialogFormValues,
  createEventDialogFormValues,
} from '../../../../extras/formValues';
import { createEventsDialogSchema } from '../../../../extras/schemas';
import { CREATE_EVENTS } from '../../../../gql';

type CaseEventType = {
  caseId: number;
  eventType?: EventType;
  occurredAt?: Date;
  stage: CaseStageType;
  status?: CaseShippingStatusType;
  surgeryDate?: Date;
  additivePrintingVendorMetadata?: IOrganizationMetadata;
  additivePrintingVendorId?: number;
  finalShipmentVendorId?: number;
};

export function CreateEventsDialog(props: {
  activeCases: CaseEventType[];
  onClose: (resetTableChecks?: boolean) => void;
  open: boolean;
}) {
  const [createEvents, { loading }] = useMutation(CREATE_EVENTS);
  const { enqueueSnackbar } = useSnackbar();
  const [hasExtraForms, setHasExtraForms] = useState(false);
  const caseNumber = props.activeCases.length;

  function getEventMenuItems(): { key: string; value: string }[] {
    const manualEvents = getSubsequentManualEvents(props.activeCases?.[0]?.eventType).filter(
      (e) => !getEventAssetType(e),
    );

    const menuItems: { key: string; value: string }[] = manualEvents.map((eventTypeValue) => {
      return {
        key: eventTypeValue,
        value: format.formatEventType(eventTypeValue as EventType),
      };
    });

    return menuItems;
  }

  const formRowTextData: (InputConfigRHF & { extra: boolean })[] = [
    {
      id: 'eventType',
      label: 'Event Type',
      input: InputTypeRHF.Select,
      extra: false,
      menuItems: getEventMenuItems(),
    },
    {
      id: 'occurredAt',
      label: 'Occurred At',
      input: InputTypeRHF.Date,
      extra: false,
    },
    {
      id: 'description',
      label: 'Description',
      input: InputTypeRHF.Text,
      extra: false,
    },
    {
      id: 'note',
      label: 'Note',
      input: InputTypeRHF.Text,
      extra: false,
    },
    {
      id: 'carrierType',
      label: 'Carrier',
      input: InputTypeRHF.Select,
      extra: true,
      menuItems: [
        { key: CarrierType.FedEx, value: 'FedEx' },
        {
          key: CarrierType.Mnx,
          value: 'MNX',
        },
        { key: CarrierType.Other, value: 'Other' },
      ],
    },
    {
      id: 'trackingNumber',
      label: 'Tracking Number',
      input: InputTypeRHF.Text,
      extra: true,
    },
  ];

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
    setError,
  } = useForm({
    defaultValues: createEventDialogFormValues(),
    resolver: yupResolver(
      createEventsDialogSchema,
    ) as unknown as Resolver<ICreateEventDialogFormValues>,
  });

  const handleSubmitForm: SubmitHandler<FieldValues> = async (data) => {
    let hasError = false;

    try {
      const caseIds = props.activeCases.map((currentCase) => currentCase.caseId);
      const occurredAt = data.occurredAt
        ? DateTime.fromJSDate(data.occurredAt).toISODate()
        : undefined;

      let allUseHeatTreat = false;

      let allUseHip = true;

      let allRequireShipmentToPackager = true;

      props.activeCases.forEach((c) => {
        /**
         * TODO
         * According to VEN-196, a heat-treat capable vendor may not use heat treat for every case
         * Usage of HIP v.s VSR will depend on other factors, to be determined at a later date
         */

        allRequireShipmentToPackager =
          allRequireShipmentToPackager && c.additivePrintingVendorId !== c.finalShipmentVendorId;
      });

      if (
        [EventType.HeatTreatComplete, EventType.PostHeatTreatInspectionComplete].includes(
          data.eventType,
        ) &&
        !allUseHeatTreat
      ) {
        hasError = true;

        setError('eventType', {
          type: 'validate',
          message: 'Not all selected cases have vendors with heat treat capabilities.',
        });

        return;
      } else if (
        [EventType.HipComplete, EventType.PostHipInspectionComplete].includes(data.eventType) &&
        !allUseHip
      ) {
        hasError = true;

        setError('eventType', {
          type: 'validate',
          message: 'Some selected cases have vendors that use heat treat.',
        });

        return;
      }

      if (
        data.eventType === EventType.PostHipInspectionDeliveryComplete &&
        !allRequireShipmentToPackager
      ) {
        hasError = true;

        setError('eventType', {
          type: 'validate',
          message: 'Some selected cases were not shipped to a different vendor.',
        });

        return;
      }

      await createEvents({
        variables: {
          caseIds,
          eventType: data.eventType,
          carrierType: isTrackableEvent(data.eventType) ? data.carrierType : undefined,
          trackingNumber: isTrackableEvent(data.eventType) ? data.trackingNumber : undefined,
          description: data.description,
          occurredAt,
          note: data.note,
        },
      });

      enqueueSnackbar(`${caseNumber} events created`, {
        variant: 'success',
      });

      props.onClose(true);
    } catch (err: unknown) {
      console.error(err);
      enqueueSnackbar('An error occurred creating the event', {
        variant: 'error',
      });
    } finally {
      if (!hasError) reset();
    }
  };

  const handleLocationChange = useCallback((e: SelectChangeEvent<HTMLSelectElement>): void => {
    if (isTrackableEvent(e.target.value as EventType)) {
      setHasExtraForms(true);
    } else setHasExtraForms(false);
  }, []);

  const getInputProps = (config: InputConfigRHF): ComponentProps<typeof InputRHF> => ({
    config: config,
    control: control as Control<FieldValues>,
    disabled: isSubmitting || loading,
    onChange: config.id === 'eventType' ? handleLocationChange : undefined,
    textFieldProps: {
      multiline: config.id === 'note' ? true : false,
    },
    selectFieldProps: {
      hideNone: true,
    },
  });

  return (
    <form>
      <CustomDialog
        maxWidth={'sm'}
        open={props.open}
        title={`Bulk Change Event (${caseNumber} cases)`}
        onClose={() => {
          reset();
          props.onClose();
        }}
        positiveActionButtons={[
          <ProgressButton
            variant={'contained'}
            disabled={isSubmitting || loading}
            onClick={(evt) => handleSubmit(handleSubmitForm)(evt)}
            label={`Update ${caseNumber} Cases`}
            loading={isSubmitting || loading}
          />,
        ]}
      >
        <Grid container spacing={3}>
          {formRowTextData
            .filter((filtered) => !filtered.extra)
            .map((config) => {
              return <InputRHF key={config.id} {...getInputProps(config)} />;
            })}
          {hasExtraForms
            ? formRowTextData
                .filter((filtered) => filtered.extra)
                .map((config) => {
                  return <InputRHF key={config.id} {...getInputProps(config)} />;
                })
            : null}
        </Grid>
      </CustomDialog>
    </form>
  );
}
