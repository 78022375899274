import React, { useEffect, useState } from 'react';
import {
  AssetType,
  CaseSpineProfile,
  caseUtils,
  format,
  IAsset,
  ICase,
  ILevels,
  LevelType,
  PostOpAnalysisType,
} from '@workflow-nx/common';
import { Box, Grid, Typography } from '@mui/material';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import { ProgressButton } from '@workflow-nx/ui';
import {
  FIND_POST_OP_ANALYSIS_FORM_DATA,
  UPDATE_ASSET_METADATA,
  UPSERT_POST_OP_ANALYSIS,
} from '../../../gql';
import * as Yup from 'yup';
import CustomDialog from '../../../components/CustomDialog';
import { NumberTextField } from '../../../components/NumberTextField';
import _ from 'lodash';
import { DatePickerField } from '../../../components/DatePickerField';
import { DateTime } from 'luxon';
import { date } from '@workflow-nx/utils';

function parseNullableNumberString(value: any) {
  return _.isNumber(value) || (!_.isEmpty(value) && !_.isNaN(value)) ? Number(value) : null;
}

function SegmentalValueFields(props: {
  label: string;
  disabled: boolean;
  name: string;
  suffix: string;
  validLevels?: string[];
  caseSpineProfile?: CaseSpineProfile;
}) {
  const profileLevelTypes = caseUtils.getLevelsSortedByHierarchy(props.caseSpineProfile, 'desc');

  return (
    <Grid container spacing={3} direction={'column'}>
      <Grid item>
        <Typography variant={'button'}>{props.label}</Typography>
      </Grid>

      {profileLevelTypes.map((level) => {
        const isInvalidLevel = !props.validLevels?.includes(level);

        return (
          <>
            <Grid item>
              <NumberTextField
                decimalPlaces={2}
                name={`${props.name}.${level}`}
                label={format.formatLevelType(level)}
                disabled={props.disabled}
                shrink={true}
                suffix={props.suffix}
                variant={isInvalidLevel ? 'filled' : undefined}
              />
            </Grid>
          </>
        );
      })}
    </Grid>
  );
}

const allLevelTypes = Object.values(LevelType);

function findAsset(assets: IAsset[], assetType: AssetType) {
  return assets.find((a) => a.assetType === assetType);
}

export function EditPostOpAnalysisDialog(props: {
  caseId: number;
  activeCase?: ICase;
  analysisType?: PostOpAnalysisType;
  onClose: (shouldUpdate: boolean) => void;
  open: boolean;
}) {
  const [upsertPostOpAnalysis, { loading: postOpAnalysisUpserting }] =
    useMutation(UPSERT_POST_OP_ANALYSIS);

  const [updateAssetMetadata, { loading: assetMetadataUpdating }] =
    useMutation(UPDATE_ASSET_METADATA);

  const { enqueueSnackbar } = useSnackbar();

  const [findPostOpAnalysis] = useLazyQuery(FIND_POST_OP_ANALYSIS_FORM_DATA, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const postOpAnalysis = data.postOpAnalysis;
      const postOpAssetList = data.assets as IAsset[];

      const postOpOneYear = findAsset(postOpAssetList, AssetType.PostOpOneYear);
      const postOpSixWeeksXRay = findAsset(postOpAssetList, AssetType.PostOpSixWeeksXray);

      setPostOpAssets({
        postOpOneYear,
        postOpSixWeeksXRay,
      });

      setInitialValues({
        analysisType: postOpAnalysis?.analysisType,
        lumbarCoronalAngulation: postOpAnalysis?.lumbarCoronalAngulation,
        lumbarLordosis: postOpAnalysis?.lumbarLordosis,
        segmentalAnteriorHeight: postOpAnalysis?.segmentalAnteriorHeight,
        segmentalPosteriorHeight: postOpAnalysis?.segmentalPosteriorHeight,
        segmentalLumbarLordosis: postOpAnalysis?.segmentalLumbarLordosis,
        angleToS1: postOpAnalysis?.angleToS1,
        segmentalCoronalAngle: postOpAnalysis?.segmentalCoronalAngle,
        pelvicTilt: postOpAnalysis?.pelvicTilt,
        thoracicKyphosis: postOpAnalysis?.thoracicKyphosis,
        sagittalVerticalAxis: postOpAnalysis?.sagittalVerticalAxis,
        coronalBalance: postOpAnalysis?.coronalBalance,
        postOpOneYearStudyDate: postOpOneYear?.metadata?.studyDate
          ? date.parseCalendarDateFromString(postOpOneYear.metadata.studyDate)
          : null,
        postOpSixWeeksXRayStudyDate: postOpSixWeeksXRay?.metadata?.studyDate
          ? date.parseCalendarDateFromString(postOpSixWeeksXRay.metadata.studyDate)
          : null,
      });
    },
  });

  const defaultSegmentationLevelValues = allLevelTypes.reduce(
    (prev, level) => ({
      ...prev,
      [level]: null,
    }),
    {},
  );

  const [postOpAssets, setPostOpAssets] = useState<{
    postOpOneYear?: IAsset;
    postOpSixWeeksXRay?: IAsset;
  }>({
    postOpOneYear: undefined,
    postOpSixWeeksXRay: undefined,
  });

  const [initialValues, setInitialValues] = useState({
    analysisType: PostOpAnalysisType.PostOpSixWeeks,
    lumbarCoronalAngulation: null,
    lumbarLordosis: null,
    segmentalAnteriorHeight: defaultSegmentationLevelValues,
    segmentalPosteriorHeight: defaultSegmentationLevelValues,
    segmentalLumbarLordosis: defaultSegmentationLevelValues,
    angleToS1: defaultSegmentationLevelValues,
    segmentalCoronalAngle: defaultSegmentationLevelValues,
    pelvicTilt: null,
    thoracicKyphosis: null,
    sagittalVerticalAxis: null,
    coronalBalance: null,
    postOpSixWeeksXRayStudyDate: null as Date | null,
    postOpOneYearStudyDate: null as Date | null,
  });

  const handleSubmitForm = async (
    values: FormikValues,
    { setErrors, setStatus, setSubmitting, resetForm }: FormikHelpers<any>,
  ) => {
    try {
      const segmentalLumbarLordosis =
        values.segmentalLumbarLordosis ?? defaultSegmentationLevelValues;
      const angleToS1 = values.angleToS1 ?? defaultSegmentationLevelValues;
      const segmentalCoronalAngle = values.segmentalCoronalAngle ?? defaultSegmentationLevelValues;
      const segmentalAnteriorHeight =
        values.segmentalAnteriorHeight ?? defaultSegmentationLevelValues;
      const segmentalPosteriorHeight =
        values.segmentalPosteriorHeight ?? defaultSegmentationLevelValues;

      const saveAssetMetadataPromises: Promise<any>[] = [];

      Object.entries(postOpAssets).forEach(([keyName, asset]) => {
        const assetName = keyName as keyof typeof postOpAssets;

        if (!asset) return;

        const assetId = asset.assetId;

        switch (assetName) {
          case 'postOpOneYear':
            saveAssetMetadataPromises.push(
              updateAssetMetadata({
                variables: {
                  assetId,
                  metadata: {
                    studyDate: DateTime.fromJSDate(
                      new Date(values.postOpOneYearStudyDate),
                    ).toISODate(),
                  },
                },
              }),
            );
            break;
          case 'postOpSixWeeksXRay':
            saveAssetMetadataPromises.push(
              updateAssetMetadata({
                variables: {
                  assetId,
                  metadata: {
                    studyDate: DateTime.fromJSDate(
                      new Date(values.postOpSixWeeksXRayStudyDate),
                    ).toISODate(),
                  },
                },
              }),
            );
            break;

          default:
            return;
        }
      });

      const saveAnalysisPromise = upsertPostOpAnalysis({
        variables: {
          caseId: props.caseId,
          analysisType: props.analysisType,
          lumbarCoronalAngulation: parseNullableNumberString(values.lumbarCoronalAngulation),
          lumbarLordosis: parseNullableNumberString(values.lumbarLordosis),
          segmentalAnteriorHeight: allLevelTypes.reduce(
            (prev, level) => ({
              ...prev,
              [level]: parseNullableNumberString(segmentalAnteriorHeight[level]),
            }),
            {},
          ),

          segmentalPosteriorHeight: allLevelTypes.reduce(
            (prev, level) => ({
              ...prev,
              [level]: parseNullableNumberString(segmentalPosteriorHeight[level]),
            }),
            {},
          ),
          segmentalLumbarLordosis: allLevelTypes.reduce(
            (prev, level) => ({
              ...prev,
              [level]: parseNullableNumberString(segmentalLumbarLordosis[level]),
            }),
            {},
          ),
          angleToS1: allLevelTypes.reduce(
            (prev, level) => ({
              ...prev,
              [level]: parseNullableNumberString(angleToS1[level]),
            }),
            {},
          ),
          segmentalCoronalAngle: allLevelTypes.reduce(
            (prev, level) => ({
              ...prev,
              [level]: parseNullableNumberString(segmentalCoronalAngle[level]),
            }),
            {},
          ),
          pelvicTilt: parseNullableNumberString(values.pelvicTilt),
          thoracicKyphosis: parseNullableNumberString(values.thoracicKyphosis),
          sagittalVerticalAxis: parseNullableNumberString(values.sagittalVerticalAxis),
          coronalBalance: parseNullableNumberString(values.coronalBalance),
        },
      });

      await Promise.all([saveAnalysisPromise, saveAssetMetadataPromises]);

      setStatus({ success: true });

      enqueueSnackbar('Post-Op Analysis Saved', {
        variant: 'success',
      });

      props.onClose(true);
    } catch (err: any) {
      console.error(err);
      setStatus({ success: false });
      setErrors({ submit: err.message });
      enqueueSnackbar('An error occurred saving the post-op analysis', {
        variant: 'error',
      });
    } finally {
      setSubmitting(false);
      resetForm();
    }
  };

  useEffect(() => {
    findPostOpAnalysis({
      variables: {
        caseId: props.caseId,
        analysisType: props.analysisType,
      },
    });
  }, [props.caseId, props.analysisType]);

  const validLevels = caseUtils.getValidCaseLevels(props?.activeCase?.levels as ILevels);

  const loading = postOpAnalysisUpserting || assetMetadataUpdating;

  const getValidationSchema = () => {
    const levelNumberNullableSchema = allLevelTypes.reduce(
      (prev, level) => ({
        ...prev,
        [level]: Yup.number().nullable(),
      }),
      {},
    );

    return Yup.object().shape({
      lumbarCoronalAngulation: Yup.number().nullable(),
      lumbarLordosis: Yup.number().nullable(),
      segmentalAnteriorHeight: Yup.object().shape(levelNumberNullableSchema),
      segmentalPosteriorHeight: Yup.object().shape(levelNumberNullableSchema),
      segmentalLumbarLordosis: Yup.object().shape(levelNumberNullableSchema),
      segmentalCoronalAngle: Yup.object().shape(levelNumberNullableSchema),
      pelvicTilt: Yup.number().nullable(),
      thoracicKyphosis: Yup.number().nullable(),
      sagittalVerticalAxis: Yup.number().nullable(),
      coronalBalance: Yup.number().nullable(),
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={getValidationSchema()}
      enableReinitialize={true}
      onSubmit={handleSubmitForm}
    >
      {({ resetForm, submitForm, isSubmitting }) => {
        return (
          <CustomDialog
            maxWidth={'lg'}
            open={props.open}
            title={`Edit Post-Op Analysis`}
            onClose={() => {
              resetForm();
              props.onClose(false);
            }}
            positiveActionButtons={[
              <ProgressButton
                variant={'contained'}
                disabled={isSubmitting || loading}
                onClick={() => submitForm()}
                label={'Save'}
                loading={isSubmitting || loading}
              />,
            ]}
          >
            <Box mb={1} ml={1}>
              <Typography variant={'button'}>Post-Op Imaging</Typography>
            </Box>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <DatePickerField
                  name={'postOpSixWeeksXRayStudyDate'}
                  label={'Six Weeks X-Ray Study Date'}
                  disabled={loading || !postOpAssets.postOpSixWeeksXRay}
                  shrink={true}
                />
              </Grid>
              <Grid item xs={3}>
                <DatePickerField
                  name={'postOpOneYearStudyDate'}
                  label={'One Year Study Date'}
                  disabled={loading || !postOpAssets.postOpOneYear}
                  shrink={true}
                />
              </Grid>
            </Grid>

            <Box mt={2} mb={1} ml={1}>
              <Typography variant={'button'}>Lumbar And Coronal Angles</Typography>
            </Box>
            <Grid container spacing={3}>
              <Grid item xs>
                <NumberTextField
                  decimalPlaces={2}
                  name={'lumbarLordosis'}
                  label={'Lumbar Lordosis'}
                  disabled={loading}
                  shrink={true}
                  suffix={'°'}
                />
              </Grid>
              <Grid item xs>
                <NumberTextField
                  decimalPlaces={2}
                  name={'lumbarCoronalAngulation'}
                  label={'Lumbar Coronal Angle'}
                  disabled={loading}
                  shrink={true}
                  suffix={'°'}
                />
              </Grid>
              <Grid item xs>
                <NumberTextField
                  decimalPlaces={2}
                  name={'coronalBalance'}
                  label={'Coronal Balance'}
                  disabled={loading}
                  shrink={true}
                />
              </Grid>
              <Grid item xs>
                <NumberTextField
                  decimalPlaces={2}
                  name={'pelvicTilt'}
                  label={'Pelvic Tilt'}
                  disabled={loading}
                  shrink={true}
                />
              </Grid>
              <Grid item xs>
                <NumberTextField
                  decimalPlaces={2}
                  name={'thoracicKyphosis'}
                  label={'Thoracic Kyphosis'}
                  disabled={loading}
                  shrink={true}
                />
              </Grid>
              <Grid item xs>
                <NumberTextField
                  decimalPlaces={2}
                  name={'sagittalVerticalAxis'}
                  label={'SVA'}
                  disabled={loading}
                  shrink={true}
                />
              </Grid>
            </Grid>
            <Box mt={2} mb={1} ml={1}>
              <Typography variant={'button'}>Segmental Data</Typography>
            </Box>
            <Box display={'flex'} mt={2}>
              <SegmentalValueFields
                caseSpineProfile={props.activeCase?.spineProfile}
                label={'Lumbar Lordosis'}
                disabled={loading}
                name={'segmentalLumbarLordosis'}
                suffix={'°'}
                validLevels={validLevels}
              />
              <Box mx={2} />
              <SegmentalValueFields
                caseSpineProfile={props.activeCase?.spineProfile}
                label={'Angle To S1'}
                disabled={loading}
                name={'angleToS1'}
                suffix={'°'}
                validLevels={validLevels}
              />
              <Box mx={2} />
              <SegmentalValueFields
                caseSpineProfile={props.activeCase?.spineProfile}
                label={'Coronal Angle'}
                disabled={loading}
                name={'segmentalCoronalAngle'}
                suffix={'°'}
                validLevels={validLevels}
              />
              <Box mx={2} />
              <SegmentalValueFields
                caseSpineProfile={props.activeCase?.spineProfile}
                label={'Anterior Height'}
                disabled={loading}
                name={'segmentalAnteriorHeight'}
                suffix={'mm'}
                validLevels={validLevels}
              />
              <Box mx={2} />
              <SegmentalValueFields
                caseSpineProfile={props.activeCase?.spineProfile}
                label={'Posterior Height'}
                disabled={loading}
                name={'segmentalPosteriorHeight'}
                suffix={'mm'}
                validLevels={validLevels}
              />
            </Box>
          </CustomDialog>
        );
      }}
    </Formik>
  );
}
