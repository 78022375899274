import React from 'react';
import { Box, Popover, Typography } from '@mui/material';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { bindPopover, bindTrigger } from 'material-ui-popup-state';

const DowntimeEventView = (props: { title: string }) => {
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'caseDetailsPopover',
  });

  return (
    <Box display={'flex'} alignItems={'center'}>
      <Box
        {...bindTrigger(popupState)}
        style={{ width: '100%' }}
        alignItems={'center'}
        display={'flex'}
        p={0.5}
      >
        <Typography variant={'body1'} noWrap={true}>
          {props?.title}
        </Typography>
      </Box>
      <Popover
        style={{ zIndex: 9999 }}
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box p={2}>
          <Typography> {props?.title}</Typography>
        </Box>
      </Popover>
    </Box>
  );
};

export default DowntimeEventView;
