import {
  AssetPositions,
  AssetPositionsMetaDataFormValidationType,
  AssetPositionsVertebralBody,
  AssetSuffix,
  AxialCorrectionGoalType,
  CaseApproachType,
  CaseSpineProfile,
  Coordinate,
  CoronalCorrectionGoalType,
  EndPlate,
  Form19JsonAttributesType,
  HeightRestorationGoalType,
  IMeasure,
  IMeasurementPointValues,
  IOperation,
  LevelForm19Type,
  LevelType,
  MeasurementDefinition,
  measurements,
  MeasurementsVersionType,
  MeasurementType,
  Operation,
  PatientGender,
  Position,
  SPINE_PROFILE_CONFIG_MAP,
  VertebralBody,
  measurements as measurementsUtils,
  ImplantType,
} from '@workflow-nx/common';
import {
  calculateCorrectionData,
  calculateSingleMeasurementSet,
  evaluateInterVertebralHeight,
  inRangeCheck,
} from '@workflow-nx/math';
import { evaluateCoronalAngle, evaluateLordoticAngle } from '@workflow-nx/core-algorithms';
import {
  AbstractMesh,
  Angle,
  Axis,
  Matrix,
  Mesh,
  MeshBuilder,
  NullEngine,
  Quaternion,
  Scene,
  SceneLoader,
  Tags,
  Vector3,
} from 'babylonjs';
import { STLFileLoader } from 'babylonjs-loaders';
import { cloneDeep } from 'lodash';
import {
  defaultAddedLordosis,
  defaultAngleToS1TargetSpineMap,
  defaultConfig,
  defaultIdealSpineMap,
  defaultPosteriorTargets,
  defaultPreopLordosis,
  defaultRecommendationMap,
  defaultStrictRecommendations,
} from '../data/defaultConfigs';
import {
  AutoCorrectionMesh,
  GapScoreConfig,
  IncrementUnits,
  MeasurementSetCount,
} from '../shared/enum';
import {
  AutoCorrectionConfigType,
  AutoCorrectionEvaluatedCorrectionType,
  AutoCorrectionInputType,
  AutoCorrectionLevelGoalsType,
  AutoCorrectionLordosisStore,
  AutoCorrectionRawLandmarkMeasurementsType,
  AutoCorrectionRawLandmarkType,
  AutoCorrectionStrictRecommendations,
  InterVertebralBodyMeasurementsType,
  LevelMeasurementsType,
  RangeType,
  VertebraePreopType,
  VertebraeSignUrlsType,
  VertebralBodyMeasurementsType,
} from '../shared/types';
import { createEndplatePlane, loadVertebralBody } from '../utils/cad';
import { calculateTargetLordosis } from '../utils/sagittalGoalEvaluation';

SceneLoader.RegisterPlugin(new STLFileLoader());

export class AutoCorrection {
  private _engine: NullEngine = new NullEngine();
  private _scene: Scene = new Scene(this._engine);
  private _config: AutoCorrectionConfigType = defaultConfig;
  private _spineProfile: CaseSpineProfile = CaseSpineProfile.LumbarStandard;
  private _targetLordosis = NaN;
  private _pelvicIncidence = NaN;
  private _activeLevelGoals: AutoCorrectionLevelGoalsType[];
  private _patientBirthDate: string;
  private _sagittalGoal: CaseApproachType;
  private _coronalGoal: CoronalCorrectionGoalType;
  private _axialGoal: AxialCorrectionGoalType;
  private _heightRestorationGoal: HeightRestorationGoalType;
  private _measurementPoints: IMeasure[] = [];
  private _preopMeasurementPoints: IMeasure[] = [];
  private _assetPositions: AssetPositions = {
    preop: { points: [], vertebrae: [] },
    plan: { vertebrae: [], points: [] },
    version: 1,
  };
  private _vertebraeSignedUrls: VertebraeSignUrlsType = {
    C1: null,
    C2: null,
    C3: null,
    C4: null,
    C5: null,
    C6: null,
    C7: null,
    C8: null,
    T1: null,
    L1: null,
    L2: null,
    L3: null,
    L4: null,
    L5: null,
    L6: null,
    S1: null,
  };

  private _vertebralbodyMeasurements: VertebralBodyMeasurementsType = {
    C1: [],
    C2: [],
    C3: [],
    C4: [],
    C5: [],
    C6: [],
    C7: [],
    C8: [],
    T1: [],
    L1: [],
    L2: [],
    L3: [],
    L4: [],
    L5: [],
    L6: [],
    S1: [],
  };
  private _interVertebralbodyMeasurements: InterVertebralBodyMeasurementsType = {
    C2_C3: [],
    C3_C4: [],
    C4_C5: [],
    C5_C6: [],
    C6_C7: [],
    C8_T1: [],
    C7_T1: [],
    C6_T1: [],
    C7_C8: [],
    L1_L2: [],
    L2_L3: [],
    L3_L4: [],
    L4_L5: [],
    L5_S1: [],
    L4_S1: [],
    L5_L6: [],
    L6_S1: [],
  };
  private _preop: VertebraePreopType = {
    C2_C3: {
      discCoronal: 0,
      vbLordosis: 0,
      discLordosis: 0,
      anteriorHeight: 0,
      posteriorHeight: 0,
    },
    C3_C4: {
      discCoronal: 0,
      vbLordosis: 0,
      discLordosis: 0,
      anteriorHeight: 0,
      posteriorHeight: 0,
    },
    C4_C5: {
      discCoronal: 0,
      vbLordosis: 0,
      discLordosis: 0,
      anteriorHeight: 0,
      posteriorHeight: 0,
    },
    C5_C6: {
      discCoronal: 0,
      vbLordosis: 0,
      discLordosis: 0,
      anteriorHeight: 0,
      posteriorHeight: 0,
    },
    C6_C7: {
      discCoronal: 0,
      vbLordosis: 0,
      discLordosis: 0,
      anteriorHeight: 0,
      posteriorHeight: 0,
    },
    C7_C8: {
      discCoronal: 0,
      vbLordosis: 0,
      discLordosis: 0,
      anteriorHeight: 0,
      posteriorHeight: 0,
    },
    C7_T1: {
      discCoronal: 0,
      vbLordosis: 0,
      discLordosis: 0,
      anteriorHeight: 0,
      posteriorHeight: 0,
    },
    C6_T1: {
      discCoronal: 0,
      vbLordosis: 0,
      discLordosis: 0,
      anteriorHeight: 0,
      posteriorHeight: 0,
    },
    C8_T1: {
      discCoronal: 0,
      vbLordosis: 0,
      discLordosis: 0,
      anteriorHeight: 0,
      posteriorHeight: 0,
    },

    L1_L2: {
      discCoronal: 0,
      vbLordosis: 0,
      discLordosis: 0,
      anteriorHeight: 0,
      posteriorHeight: 0,
    },
    L2_L3: {
      discCoronal: 0,
      vbLordosis: 0,
      discLordosis: 0,
      anteriorHeight: 0,
      posteriorHeight: 0,
    },
    L3_L4: {
      discCoronal: 0,
      vbLordosis: 0,
      discLordosis: 0,
      anteriorHeight: 0,
      posteriorHeight: 0,
    },
    L4_L5: {
      discCoronal: 0,
      vbLordosis: 0,
      discLordosis: 0,
      anteriorHeight: 0,
      posteriorHeight: 0,
    },
    L5_S1: {
      discCoronal: 0,
      vbLordosis: 0,
      discLordosis: 0,
      anteriorHeight: 0,
      posteriorHeight: 0,
    },
    L4_S1: {
      discCoronal: 0,
      vbLordosis: 0,
      discLordosis: 0,
      anteriorHeight: 0,
      posteriorHeight: 0,
    },
    L5_L6: {
      discCoronal: 0,
      vbLordosis: 0,
      discLordosis: 0,
      anteriorHeight: 0,
      posteriorHeight: 0,
    },
    L6_S1: {
      discCoronal: 0,
      vbLordosis: 0,
      discLordosis: 0,
      anteriorHeight: 0,
      posteriorHeight: 0,
    },
  };
  private _operations: IOperation[] = [];
  private _levelForm19: LevelForm19Type = {};
  private _gender: PatientGender = PatientGender.Male;
  private _landmarkMeasurements: AutoCorrectionRawLandmarkMeasurementsType = {
    C1: null,
    C2: null,
    C3: null,
    C4: null,
    C5: null,
    C6: null,
    C7: null,
    C8: null,
    T1: null,
    L1: null,
    L2: null,
    L3: null,
    L4: null,
    L5: null,
    L6: null,
    S1: null,
  };

  constructor(
    autoCorrectionGoals: AutoCorrectionInputType,
    config: AutoCorrectionConfigType = defaultConfig,
  ) {
    const sortedActiveLevelGoals: AutoCorrectionLevelGoalsType[] =
      autoCorrectionGoals.activeLevelGoals.sort((a, b) => {
        return a.level > b.level ? -1 : 1;
      });

    const compiledMeasurements = Object.values(autoCorrectionGoals.landmarkMeasurements)
      .filter((landmarkMeasurement) => landmarkMeasurement?.measurementPoints)
      .flatMap(
        (landmarkMeasurement) =>
          (landmarkMeasurement as AutoCorrectionRawLandmarkType).measurementPoints,
      );

    this._activeLevelGoals = sortedActiveLevelGoals;
    this._config = config;
    this._pelvicIncidence = autoCorrectionGoals.pelvicIncidence;
    this._spineProfile = autoCorrectionGoals.spineProfile || CaseSpineProfile.LumbarStandard;
    this._vertebraeSignedUrls = autoCorrectionGoals.signedUrls;
    this._sagittalGoal = autoCorrectionGoals.sagittalGoal;
    this._coronalGoal = autoCorrectionGoals.coronalGoal;
    this._axialGoal = autoCorrectionGoals.axialGoal;
    this._heightRestorationGoal = autoCorrectionGoals.heightRestorationGoal;
    this._patientBirthDate = autoCorrectionGoals.patientBirthDate;
    this._levelForm19 = autoCorrectionGoals.levelForm19;
    this._measurementPoints = compiledMeasurements;
    this._preopMeasurementPoints = cloneDeep(compiledMeasurements);
    this._landmarkMeasurements = autoCorrectionGoals.landmarkMeasurements;
    this._gender = autoCorrectionGoals.gender;
    this._targetLordosis = calculateTargetLordosis(
      this._sagittalGoal,
      this._pelvicIncidence,
      this._patientBirthDate as string,
    );
  }

  get assetPositions(): AssetPositions {
    return this._assetPositions;
  }
  get scene(): Scene {
    return this._scene;
  }
  get measurementPoints(): IMeasure[] {
    return this._measurementPoints;
  }
  get preopMeasurementPoints(): IMeasure[] {
    return this._preopMeasurementPoints;
  }
  get vertebraeSignedUrls(): VertebraeSignUrlsType {
    return this._vertebraeSignedUrls;
  }
  get vertebralbodyMeasurements(): VertebralBodyMeasurementsType {
    return this._vertebralbodyMeasurements;
  }
  get interVertebralbodyMeasurements(): InterVertebralBodyMeasurementsType {
    return this._interVertebralbodyMeasurements;
  }
  get operations(): IOperation[] {
    return this._operations;
  }
  get targetLordosis(): number {
    return this._targetLordosis;
  }
  get activeLevelGoals(): AutoCorrectionLevelGoalsType[] {
    return this._activeLevelGoals;
  }

  set setMeasurementPoints(measurements: IMeasure[]) {
    this._measurementPoints = measurements;
  }
  set setLandmarkMeasurements(landmarkMeasurements: AutoCorrectionRawLandmarkMeasurementsType) {
    this._landmarkMeasurements = landmarkMeasurements;
  }

  public async loadVertebrae(): Promise<void> {
    const promises: Promise<Mesh>[] = [];
    for (const key in this._vertebraeSignedUrls) {
      const vbKey = key as VertebralBody;
      const targetSignedUrl: string | null = this._vertebraeSignedUrls[vbKey];
      if (targetSignedUrl) {
        const promise: Promise<Mesh> = loadVertebralBody(vbKey, this._scene, targetSignedUrl);
        promises.push(promise);
      }
    }

    await Promise.all(promises);
  }

  public loadPreopEndPlatePlane(): void {
    const vertebraeMap: VertebralBody[] =
      SPINE_PROFILE_CONFIG_MAP[this._spineProfile].validVertebralBodies;

    vertebraeMap.forEach((vertebralBody: VertebralBody) => {
      let targetMeasurements;

      if (vertebralBody === VertebralBody.S1) {
        targetMeasurements = this._vertebralbodyMeasurements[vertebralBody].filter(
          (measurement: IMeasure) => measurement.endPlate === EndPlate.Superior,
        );
      } else {
        targetMeasurements = this._vertebralbodyMeasurements[vertebralBody].filter(
          (measurement: IMeasure) => measurement.endPlate === EndPlate.Superior,
        );
      }

      if (targetMeasurements.length === MeasurementSetCount.Single) {
        const { centroid, eulerAngles } = calculateCorrectionData(targetMeasurements);
        const [targetMesh] = this.getMeshesByTag(vertebralBody);

        createEndplatePlane(targetMesh, centroid, eulerAngles, this._scene, vertebralBody);
      }
    });
  }

  public getMeshByTag(...tagNames: string[]): Mesh {
    const [targetMesh] = this._scene.getMeshesByTags(tagNames.join(' && '));
    return targetMesh;
  }

  public getMeshesByTag(...tagNames: string[]): Mesh[] {
    return this._scene.getMeshesByTags(tagNames.join(' && '));
  }

  public setPivotPoint(vertebralBody: VertebralBody, pivotPoint: Vector3): void {
    const targetMesh: Mesh = this.getMeshByTag(vertebralBody);
    targetMesh.setPivotPoint(pivotPoint);
  }

  public translateMesh(vertebralBody: VertebralBody, axis: Vector3, distance: number): void {
    const targetMesh: Mesh = this.getMeshByTag(vertebralBody);
    targetMesh.translate(axis, distance);
    targetMesh.computeWorldMatrix();
  }

  public rotateMesh(vertebralBody: VertebralBody, axis: Vector3, degrees: number): void {
    const radians = Angle.FromDegrees(degrees).radians();
    const targetMesh = this.getMeshByTag(vertebralBody);
    targetMesh.rotate(axis, radians);
    targetMesh.computeWorldMatrix(true);
  }

  public setVertebralHierarchy(): void {
    const vertebraeMap: VertebralBody[] =
      SPINE_PROFILE_CONFIG_MAP[this._spineProfile].validVertebralBodies;

    for (let i = 0; i < vertebraeMap.length; i++) {
      if (vertebraeMap[i + 1]) {
        const child = this.getMeshByTag(vertebraeMap[i]);
        const parent = this.getMeshByTag(vertebraeMap[i + 1]);
        child.setParent(parent);
        child.computeWorldMatrix(true);
      }
    }
  }

  public parseMeasurements() {
    const validLevels = SPINE_PROFILE_CONFIG_MAP[this._spineProfile].validLevels;

    validLevels.forEach((level) => {
      const [child, parent] = level.split('_');

      const vertebralbodyPoints = this._measurementPoints.filter(
        (measurement: IMeasure) => measurement.body === (child as VertebralBody),
      );

      if (parent === VertebralBody.S1) {
        const parentVertebralbodyPoints = this._measurementPoints.filter(
          (measurement: IMeasure) => measurement.body === (parent as VertebralBody),
        );
        const parentVbKey = parent as VertebralBody;
        this._vertebralbodyMeasurements[parentVbKey] = parentVertebralbodyPoints;
      }

      const vbKey = child as VertebralBody;
      this._vertebralbodyMeasurements[vbKey] = vertebralbodyPoints;

      this._interVertebralbodyMeasurements[level] = this._measurementPoints.filter(
        (measurement: IMeasure) =>
          (measurement.body === (child as VertebralBody) &&
            measurement.endPlate === EndPlate.Inferior) ||
          (measurement.body === (parent as VertebralBody) &&
            measurement.endPlate === EndPlate.Superior),
      );
    });
  }

  public loadPreopMeasurementPoints(): void {
    this._measurementPoints.forEach(({ body, position, endPlate, point }: IMeasure) => {
      const sphere: Mesh = MeshBuilder.CreateSphere(
        `${body}.${position}.${endPlate}${AssetSuffix.PointSuffix}`,
        { diameter: 3 },
        this._scene,
      );

      Tags.AddTagsTo(
        sphere,
        `${body}.${position}.${endPlate}${AssetSuffix.PointSuffix} ${AutoCorrectionMesh.Landmark}`,
      );

      const parentMesh: Mesh = this.getMeshByTag(body);

      sphere.setParent(parentMesh);
      sphere.position = new Vector3(...point);
    });
  }

  public updateAllWorldMatrices(mesh: Mesh) {
    const allChildMeshes = mesh.getChildMeshes();
    for (const childMesh of allChildMeshes) {
      if (
        childMesh.name.endsWith(AssetSuffix.PointSuffix) ||
        childMesh.name.endsWith(AssetSuffix.ImplantSuffix)
      ) {
        childMesh.computeWorldMatrix(true);
      }
    }
  }

  public reEvaluateMeasurements(): void {
    const pointMeshes: Mesh[] = this.getMeshesByTag(AutoCorrectionMesh.Landmark);

    pointMeshes.forEach((pointMesh: Mesh) => {
      const absPosition: Vector3 = pointMesh.getAbsolutePosition();

      const targetPointMesh: number[] = [absPosition.x, absPosition.y, absPosition.z];

      const targetMeasurementPoint: IMeasure | undefined = this._measurementPoints.find(
        ({ body, position, endPlate }: IMeasure) =>
          pointMesh.name === `${body}.${position}.${endPlate}${AssetSuffix.PointSuffix}`,
      );

      if (targetMeasurementPoint) {
        targetMeasurementPoint.point = targetPointMesh;
      }
    });
  }

  public endplateCoronalParallelization(): void {
    switch (this._coronalGoal) {
      case CoronalCorrectionGoalType.DiscSpaceParallelization:
        this.coronalDiscSpaceParallelization();
        break;
      case CoronalCorrectionGoalType.HightestSuperiorEndplateParallelization:
        this.coronalHighestSuperiorEndplateParallelization();
        break;
      case CoronalCorrectionGoalType.FloorParallelization:
        this.coronalFloorParallelization();
        break;
      case CoronalCorrectionGoalType.ZeroCoronal:
        this.coronalZeroCoronalParallelization();
        break;
      case CoronalCorrectionGoalType.None:
      default:
        break;
    }

    // TODO: consider implant coronal max and min
    // TODO: consider disc coronal angle conditions
  }

  public coronalHighestSuperiorEndplateParallelization(): void {
    const [highestChild] = this._activeLevelGoals[this._activeLevelGoals.length - 1].level.split(
      '_',
    ) as VertebralBody[];

    const highestVBMeasurements: IMeasure[] | undefined =
      this._vertebralbodyMeasurements[highestChild];

    if (highestVBMeasurements) {
      const { ap, ml } = calculateSingleMeasurementSet(highestVBMeasurements);

      const highestNormal: Vector3 = ml.cross(ap).normalize();

      this._activeLevelGoals.forEach(({ level }: AutoCorrectionLevelGoalsType) => {
        const [child] = level.split('_') as VertebralBody[];

        const targetInterVertebralbodyMeasurements: IMeasure[] =
          this._interVertebralbodyMeasurements[level];

        const targetChildVBMeasurements: IMeasure[] | undefined = this._vertebralbodyMeasurements[
          child
        ].filter((measurement: IMeasure) => measurement.endPlate === EndPlate.Superior);

        if (targetInterVertebralbodyMeasurements && targetChildVBMeasurements) {
          const { zPrime }: AutoCorrectionEvaluatedCorrectionType = calculateCorrectionData(
            targetInterVertebralbodyMeasurements,
          );

          const { yPrime }: AutoCorrectionEvaluatedCorrectionType =
            calculateCorrectionData(targetChildVBMeasurements);

          const childMesh: Mesh = this.getMeshByTag(child);

          const matrixBefore: Matrix = cloneDeep(childMesh.getWorldMatrix());

          const deltaX: number = highestNormal.x - yPrime.x;

          childMesh.rotate(zPrime.negate(), Math.asin(deltaX));

          this.updateAllWorldMatrices(childMesh);

          this.reEvaluateMeasurements();
          this.parseMeasurements();

          const matrixAfter: Matrix = childMesh.getWorldMatrix();
          const inverseMatrix: Matrix = Matrix.Invert(matrixBefore);
          const differenceMatrix: Matrix = matrixAfter.multiply(inverseMatrix);
          const differenceQuaternion: Quaternion = Quaternion.FromRotationMatrix(differenceMatrix);

          this.evaluateRotationalOperations(child, differenceQuaternion);
        }
      });
    }
  }

  public coronalZeroCoronalParallelization(): void {
    const sacrumVBMeasurements: IMeasure[] | undefined =
      this._vertebralbodyMeasurements[VertebralBody.S1];

    if (sacrumVBMeasurements) {
      const { ap, ml } = calculateSingleMeasurementSet(sacrumVBMeasurements);

      const sacrumNormal: Vector3 = ml.cross(ap).normalize();

      this._activeLevelGoals.forEach(({ level }: AutoCorrectionLevelGoalsType) => {
        const [child] = level.split('_') as VertebralBody[];

        const targetInterVertebralbodyMeasurements: IMeasure[] =
          this._interVertebralbodyMeasurements[level];

        const targetChildVBMeasurements: IMeasure[] | undefined = this._vertebralbodyMeasurements[
          child
        ].filter((measurement: IMeasure) => measurement.endPlate === EndPlate.Superior);

        if (targetInterVertebralbodyMeasurements && targetChildVBMeasurements) {
          const { zPrime }: AutoCorrectionEvaluatedCorrectionType = calculateCorrectionData(
            targetInterVertebralbodyMeasurements,
          );

          const { yPrime }: AutoCorrectionEvaluatedCorrectionType =
            calculateCorrectionData(targetChildVBMeasurements);

          const childMesh: Mesh = this.getMeshByTag(child);

          const matrixBefore: Matrix = cloneDeep(childMesh.getWorldMatrix());

          const deltaX: number = sacrumNormal.x - yPrime.x;

          childMesh.rotate(zPrime.negate(), Math.asin(deltaX));

          this.updateAllWorldMatrices(childMesh);

          this.reEvaluateMeasurements();
          this.parseMeasurements();

          const matrixAfter: Matrix = childMesh.getWorldMatrix();
          const inverseMatrix: Matrix = Matrix.Invert(matrixBefore);
          const differenceMatrix: Matrix = matrixAfter.multiply(inverseMatrix);
          const differenceQuaternion: Quaternion = Quaternion.FromRotationMatrix(differenceMatrix);

          this.evaluateRotationalOperations(child, differenceQuaternion);
        }
      });
    }
  }

  public coronalFloorParallelization(): void {
    this._activeLevelGoals.forEach(({ level }: AutoCorrectionLevelGoalsType) => {
      const [child] = level.split('_') as VertebralBody[];

      const targetInterVertebralbodyMeasurements: IMeasure[] =
        this._interVertebralbodyMeasurements[level];

      const targetChildVBMeasurements: IMeasure[] | undefined = this._vertebralbodyMeasurements[
        child
      ].filter((measurement: IMeasure) => measurement.endPlate === EndPlate.Superior);

      if (targetInterVertebralbodyMeasurements && targetChildVBMeasurements) {
        const { zPrime }: AutoCorrectionEvaluatedCorrectionType = calculateCorrectionData(
          targetInterVertebralbodyMeasurements,
        );

        const { yPrime }: AutoCorrectionEvaluatedCorrectionType =
          calculateCorrectionData(targetChildVBMeasurements);

        const childMesh: Mesh = this.getMeshByTag(child);

        const matrixBefore: Matrix = cloneDeep(childMesh.getWorldMatrix());

        const deltaX: number = Axis.Y.x - yPrime.x;

        childMesh.rotate(zPrime.negate(), Math.asin(deltaX));

        this.updateAllWorldMatrices(childMesh);

        this.reEvaluateMeasurements();
        this.parseMeasurements();

        const matrixAfter: Matrix = childMesh.getWorldMatrix();
        const inverseMatrix: Matrix = Matrix.Invert(matrixBefore);
        const differenceMatrix: Matrix = matrixAfter.multiply(inverseMatrix);
        const differenceQuaternion: Quaternion = Quaternion.FromRotationMatrix(differenceMatrix);

        this.evaluateRotationalOperations(child, differenceQuaternion);
      }
    });
  }

  public coronalDiscSpaceParallelization(): void {
    this._activeLevelGoals.forEach(({ level, implantType }: AutoCorrectionLevelGoalsType) => {
      const [child, parent] = level.split('_') as VertebralBody[];

      const targetChildVBMeasurements: IMeasure[] | undefined = this._vertebralbodyMeasurements[
        child
      ].filter((measurement: IMeasure) => measurement.endPlate === EndPlate.Inferior);

      const targetParentVBMeasurements: IMeasure[] | undefined = this._vertebralbodyMeasurements[
        parent
      ].filter((measurement: IMeasure) => measurement.endPlate === EndPlate.Superior);

      if (targetParentVBMeasurements && targetChildVBMeasurements) {
        const { yPrime: childNormal }: AutoCorrectionEvaluatedCorrectionType =
          calculateCorrectionData(targetChildVBMeasurements);

        const { yPrime: parentNormal }: AutoCorrectionEvaluatedCorrectionType =
          calculateCorrectionData(targetParentVBMeasurements);

        const { zPrime }: AutoCorrectionEvaluatedCorrectionType = calculateCorrectionData([
          ...targetChildVBMeasurements,
          ...targetParentVBMeasurements,
        ]);

        const childMesh: Mesh = this.getMeshByTag(child);

        const matrixBefore: Matrix = cloneDeep(childMesh.getWorldMatrix());

        const deltaX: number = parentNormal.x - childNormal.x;
        childMesh.rotate(zPrime.negate(), Math.asin(deltaX));

        this.updateAllWorldMatrices(childMesh);

        this.reEvaluateMeasurements();
        this.parseMeasurements();

        // Added below Coronal Correction to match Measurement Version 2
        let currentCoronal: number = this.evaluateIntervertebralCoronal(parent, child);

        const { coronalAngle: coronalLimits }: Form19JsonAttributesType = this._levelForm19[level];

        const recommendedCoronal =
          defaultRecommendationMap.implantRecommendations[implantType as ImplantType]?.coronal;
        const targetRecommendation: number = recommendedCoronal ?? 0;
        const maxTargetRecommendation: number = (targetRecommendation / 180) * Math.PI;
        const implantMax: number = (coronalLimits.max / 180) * Math.PI;

        while (
          currentCoronal !== 0 &&
          currentCoronal < maxTargetRecommendation &&
          currentCoronal < implantMax
        ) {
          const targetLimit = IncrementUnits.AngleIncrement * 2;

          if (inRangeCheck(currentCoronal, -targetLimit, targetLimit)) {
            break;
          }

          const direction = currentCoronal > 0 ? 1 : -1;

          childMesh.rotate(zPrime.negate(), direction * IncrementUnits.AngleIncrement);

          this.updateAllWorldMatrices(childMesh);

          this.reEvaluateMeasurements();
          this.parseMeasurements();

          currentCoronal = this.evaluateIntervertebralCoronal(parent, child);
        }

        const matrixAfter: Matrix = childMesh.getWorldMatrix();
        const inverseMatrix: Matrix = Matrix.Invert(matrixBefore);
        const differenceMatrix: Matrix = matrixAfter.multiply(inverseMatrix);
        const differenceQuaternion: Quaternion = Quaternion.FromRotationMatrix(differenceMatrix);

        this.evaluateRotationalOperations(child, differenceQuaternion);
      }
    });
  }

  public coronalAlignment(): void {
    switch (this._coronalGoal) {
      case CoronalCorrectionGoalType.DiscSpaceParallelization:
      case CoronalCorrectionGoalType.HightestSuperiorEndplateParallelization:
      case CoronalCorrectionGoalType.ZeroCoronal:
        this.coronalDiscSpaceAlignment();
        break;
      case CoronalCorrectionGoalType.FloorParallelization:
        this.coronalSacrumAlignment();
        break;
      case CoronalCorrectionGoalType.None:
      default:
        break;
    }
  }

  public coronalSacrumAlignment(): void {
    const sacrumMeasurements: IMeasure[] | undefined =
      this._vertebralbodyMeasurements[VertebralBody.S1];

    if (sacrumMeasurements) {
      const { centroid: sacrumCentroid }: AutoCorrectionEvaluatedCorrectionType =
        calculateCorrectionData(sacrumMeasurements);

      this._activeLevelGoals.forEach(({ level }: AutoCorrectionLevelGoalsType) => {
        const [child] = level.split('_') as VertebralBody[];

        const targetChildVBMeasurements: IMeasure[] | undefined =
          this._vertebralbodyMeasurements[child];

        if (targetChildVBMeasurements) {
          const { centroid: childCentroid }: AutoCorrectionEvaluatedCorrectionType =
            calculateCorrectionData(targetChildVBMeasurements);

          const coronalAlignment: number = sacrumCentroid.x - childCentroid.x;

          const childMesh: Mesh = this.getMeshByTag(child);

          childMesh.translate(Axis.X, coronalAlignment);

          this.updateAllWorldMatrices(childMesh);

          this.reEvaluateMeasurements();
          this.parseMeasurements();

          const translationOperation: IOperation = {
            body: child,
            operation: Operation.TranslateX,
            value: Math.round(coronalAlignment * 10) / 10,
          };
          this._operations.push(translationOperation);
        }
      });
    }
  }

  public coronalDiscSpaceAlignment(): void {
    this._activeLevelGoals.forEach((activeLevelGoal: AutoCorrectionLevelGoalsType) => {
      const [child, parent] = activeLevelGoal.level.split('_') as VertebralBody[];

      const targetChildVBMeasurements: IMeasure[] | undefined =
        this._vertebralbodyMeasurements[child];
      const targetParentVBMeasurements: IMeasure[] | undefined =
        this._vertebralbodyMeasurements[parent];
      const targetInterVBMeasurements: IMeasure[] | undefined =
        this._interVertebralbodyMeasurements[activeLevelGoal.level];

      if (targetParentVBMeasurements && targetChildVBMeasurements) {
        const { centroid: childCentroid }: AutoCorrectionEvaluatedCorrectionType =
          calculateCorrectionData(targetChildVBMeasurements);
        const { centroid: parentCentroid }: AutoCorrectionEvaluatedCorrectionType =
          calculateCorrectionData(targetParentVBMeasurements);
        const { xPrime }: AutoCorrectionEvaluatedCorrectionType =
          calculateCorrectionData(targetInterVBMeasurements);

        const childProjection = Vector3.Dot(childCentroid, xPrime);
        const parentProjection = Vector3.Dot(parentCentroid, xPrime);

        const coronalAlignment: number = parentProjection - childProjection;

        const childMesh: Mesh = this.getMeshByTag(child);
        const matrixBefore: Matrix = cloneDeep(childMesh.getWorldMatrix());

        childMesh.translate(xPrime, coronalAlignment);

        this.updateAllWorldMatrices(childMesh);

        this.reEvaluateMeasurements();
        this.parseMeasurements();

        const matrixAfter: Matrix = childMesh.getWorldMatrix();
        const inverseMatrix: Matrix = Matrix.Invert(matrixBefore);

        const differenceMatrix: Matrix = matrixAfter.multiply(inverseMatrix);
        const differenceQuaternion: Quaternion = Quaternion.FromRotationMatrix(differenceMatrix);
        const differenceTranslation: Vector3 = differenceMatrix.getTranslation();

        this.evaluateRotationalOperations(child, differenceQuaternion);
        this.evaluateTranslationalOperations(child, differenceTranslation);
      }
    });
  }

  public evaluateLevelLordosis(parentVB: VertebralBody, childVB: VertebralBody) {
    const measurementDefinition: MeasurementDefinition = {
      type: MeasurementType.SegmentalLordoticAngle,
      start: { body: childVB, endPlate: EndPlate.Superior },
      end: { body: parentVB, endPlate: EndPlate.Superior },
    };

    const measurementPoints = measurementsUtils.getMeasurementPointsVertebralBodyMeasurements(
      this._vertebralbodyMeasurements,
    );

    return (evaluateLordoticAngle(measurementDefinition, measurementPoints) * Math.PI) / 180;
  }

  public evaluateIntervertebralLordosis(parentVB: VertebralBody, childVB: VertebralBody) {
    const measurementDefinition: MeasurementDefinition = {
      type: MeasurementType.LordoticAngle,
      start: { body: childVB, endPlate: EndPlate.Inferior },
      end: { body: parentVB, endPlate: EndPlate.Superior },
    };

    const measurementPoints = measurementsUtils.getMeasurementPointsVertebralBodyMeasurements(
      this._vertebralbodyMeasurements,
    );

    return (evaluateLordoticAngle(measurementDefinition, measurementPoints) * Math.PI) / 180;
  }

  public evaluateLevelCoronal(parentVB: VertebralBody, childVB: VertebralBody) {
    const measurementDefinition: MeasurementDefinition = {
      type: MeasurementType.SegmentalCoronalAngle,
      start: { body: childVB, endPlate: EndPlate.Superior },
      end: { body: parentVB, endPlate: EndPlate.Superior },
    };

    const measurementPoints = measurementsUtils.getMeasurementPointsVertebralBodyMeasurements(
      this._vertebralbodyMeasurements,
    );

    return (evaluateCoronalAngle(measurementDefinition, measurementPoints) * Math.PI) / 180;
  }

  public evaluateIntervertebralCoronal(parentVB: VertebralBody, childVB: VertebralBody) {
    const measurementDefinition: MeasurementDefinition = {
      type: MeasurementType.CoronalAngle,
      start: { body: childVB, endPlate: EndPlate.Inferior },
      end: { body: parentVB, endPlate: EndPlate.Superior },
    };

    const measurementPoints = measurementsUtils.getMeasurementPointsVertebralBodyMeasurements(
      this._vertebralbodyMeasurements,
    );

    return (evaluateCoronalAngle(measurementDefinition, measurementPoints) * Math.PI) / 180;
  }

  public evaluateLordosisAndHeight(level: LevelType): LevelMeasurementsType {
    const levelMeasurements: LevelMeasurementsType = {
      discCoronal: 0,
      vbLordosis: 0,
      discLordosis: 0,
      anteriorHeight: 0,
      posteriorHeight: 0,
    };

    const [child, parent] = level.split('_') as VertebralBody[];

    const parentMeasurements: IMeasure[] = this._vertebralbodyMeasurements[parent].filter(
      (measurement: IMeasure) => measurement.endPlate === EndPlate.Superior,
    );
    const childMeasurementsInferior: IMeasure[] = this._vertebralbodyMeasurements[child].filter(
      (measurement: IMeasure) => measurement.endPlate === EndPlate.Inferior,
    );

    const supAMeasurement: IMeasure | undefined = parentMeasurements.find(
      (measurement: IMeasure) => measurement.position === Position.Anterior,
    );
    const supPMeasurement: IMeasure | undefined = parentMeasurements.find(
      (measurement: IMeasure) => measurement.position === Position.Posterior,
    );

    const infAMeasurement: IMeasure | undefined = childMeasurementsInferior.find(
      (measurement: IMeasure) => measurement.position === Position.Anterior,
    );
    const infPMeasurement: IMeasure | undefined = childMeasurementsInferior.find(
      (measurement: IMeasure) => measurement.position === Position.Posterior,
    );

    if (supAMeasurement && supPMeasurement && infAMeasurement && infPMeasurement) {
      const supA: Vector3 = new Vector3(...supAMeasurement.point);
      const supP: Vector3 = new Vector3(...supPMeasurement.point);
      const infA: Vector3 = new Vector3(...infAMeasurement.point);
      const infP: Vector3 = new Vector3(...infPMeasurement.point);

      const discAPMeasurementPoints = parentMeasurements.concat(childMeasurementsInferior);
      const { yPrime, centroid } = calculateCorrectionData(discAPMeasurementPoints);

      const lordosisMeasurementDefinition: MeasurementDefinition = {
        type: MeasurementType.LumbarLordosis,
        start: { body: child, endPlate: EndPlate.Inferior },
        end: { body: child, endPlate: EndPlate.Superior },
      };
      const coronalMeasurementDefinition: MeasurementDefinition = {
        type: MeasurementType.CoronalAngle,
        start: { body: parent, endPlate: EndPlate.Inferior },
        end: { body: child, endPlate: EndPlate.Superior },
      };
      const discLordosisMeasurementDefinition: MeasurementDefinition = {
        type: MeasurementType.LordoticAngle,
        start: { body: parent, endPlate: EndPlate.Inferior },
        end: { body: child, endPlate: EndPlate.Superior },
      };

      const measurementPoints = measurementsUtils.getMeasurementPointsVertebralBodyMeasurements(
        this._vertebralbodyMeasurements,
      );

      levelMeasurements.vbLordosis =
        (evaluateLordoticAngle(lordosisMeasurementDefinition, measurementPoints) * Math.PI) / 180;
      levelMeasurements.discCoronal =
        (evaluateCoronalAngle(coronalMeasurementDefinition, measurementPoints) * Math.PI) / 180;
      levelMeasurements.discLordosis =
        (evaluateLordoticAngle(discLordosisMeasurementDefinition, measurementPoints) * Math.PI) /
        180;
      levelMeasurements.anteriorHeight = evaluateInterVertebralHeight(supA, infA, yPrime, centroid);
      levelMeasurements.posteriorHeight = evaluateInterVertebralHeight(
        supP,
        infP,
        yPrime,
        centroid,
      );
    }

    return levelMeasurements;
  }

  public sagittalCorrection(): void {
    const lordosisAdded: AutoCorrectionLordosisStore = defaultAddedLordosis;
    const preopLordosis: AutoCorrectionLordosisStore = defaultPreopLordosis;

    this._activeLevelGoals.forEach(({ level }: AutoCorrectionLevelGoalsType) => {
      const [child, parent] = level.split('_') as VertebralBody[];
      preopLordosis[level] = this.evaluateIntervertebralLordosis(parent, child);
    });

    switch (this._sagittalGoal) {
      case CaseApproachType.AgeAdjusted:
      case CaseApproachType.GapScore:
      case CaseApproachType.FocalAlignment:
      case CaseApproachType.ZeroMismatch:
        this.lumbarLordosisBaseline(lordosisAdded, preopLordosis);
        this.lumbarLordosisCorrectionAngleToS1(lordosisAdded, preopLordosis);
        this.lumbarLordosisCorrectionPyramid(lordosisAdded, preopLordosis);
        this.lumbarLordosisCorrectionIdeal(lordosisAdded, preopLordosis);
        this.lumbarLordosisCorrectionTopOff(lordosisAdded, preopLordosis);
        break;
      case CaseApproachType.Other:
        this.lumbarLordosisBaseline(lordosisAdded, preopLordosis);
        this.lumbarLordosisLevelSpecificCorrection();
        break;
      case CaseApproachType.None:
      default:
        break;
    }
  }

  public lumbarLordosisBaseline(
    lordosisAdded: AutoCorrectionLordosisStore,
    preopLordosis: AutoCorrectionLordosisStore,
  ): void {
    const actualTotalLordosis: number = this.evaluateLevelLordosis(
      VertebralBody.S1,
      VertebralBody.L1,
    );
    const totalLordosisRemaining: number = this._targetLordosis - actualTotalLordosis;

    this._activeLevelGoals.forEach((activeLevelGoal: AutoCorrectionLevelGoalsType) => {
      const { level }: AutoCorrectionLevelGoalsType = activeLevelGoal;

      const [child, parent] = activeLevelGoal.level.split('_') as VertebralBody[];

      const childMesh: Mesh = this.getMeshByTag(child);

      const matrixBefore: Matrix = cloneDeep(childMesh.getWorldMatrix());

      // define rotation direction
      const targetInterVBMeasurements: IMeasure[] | undefined =
        this._interVertebralbodyMeasurements[level];
      const { xPrime }: AutoCorrectionEvaluatedCorrectionType =
        calculateCorrectionData(targetInterVBMeasurements);

      let discLordosis: number = this.evaluateIntervertebralLordosis(parent, child);
      let currentLordosis: number = this.evaluateLevelLordosis(VertebralBody.S1, VertebralBody.L1);

      let isLordosisLeft = this._targetLordosis - currentLordosis <= totalLordosisRemaining;
      let notMinSpec = discLordosis < (4 / 180) * Math.PI;

      while (isLordosisLeft && notMinSpec) {
        childMesh.rotate(xPrime, IncrementUnits.AngleIncrement);

        this.updateAllWorldMatrices(childMesh);

        this.reEvaluateMeasurements();
        this.parseMeasurements();

        discLordosis = this.evaluateIntervertebralLordosis(parent, child);
        currentLordosis = this.evaluateLevelLordosis(VertebralBody.S1, VertebralBody.L1);
        lordosisAdded[level] = discLordosis - preopLordosis[level];
        isLordosisLeft = this._targetLordosis - currentLordosis <= totalLordosisRemaining;
        notMinSpec = discLordosis < (4 / 180) * Math.PI;
      }

      const matrixAfter: Matrix = childMesh.getWorldMatrix();
      const inverseMatrix: Matrix = Matrix.Invert(matrixBefore);
      const differenceMatrix: Matrix = matrixAfter.multiply(inverseMatrix);
      const differenceQuaternion: Quaternion = Quaternion.FromRotationMatrix(differenceMatrix);

      this.evaluateRotationalOperations(child, differenceQuaternion);
    });
  }

  public lumbarLordosisCorrectionAngleToS1(
    lordosisAdded: AutoCorrectionLordosisStore,
    preopLordosis: AutoCorrectionLordosisStore,
  ): void {
    const actualTotalLordosis: number = this.evaluateLevelLordosis(
      VertebralBody.S1,
      VertebralBody.L1,
    );
    const totalLordosisRemaining: number = this._targetLordosis - actualTotalLordosis;

    this._activeLevelGoals.forEach((activeLevelGoal: AutoCorrectionLevelGoalsType) => {
      const { level }: AutoCorrectionLevelGoalsType = activeLevelGoal;

      const [child, parent] = activeLevelGoal.level.split('_') as VertebralBody[];
      const childMesh: Mesh = this.getMeshByTag(child);

      // define rotation direction
      const targetInterVBMeasurements: IMeasure[] | undefined =
        this._interVertebralbodyMeasurements[level];
      const { xPrime }: AutoCorrectionEvaluatedCorrectionType =
        calculateCorrectionData(targetInterVBMeasurements);

      // store matrix before apply rotation for operation evaluation
      const positionBefore: Vector3 = cloneDeep(
        childMesh.getBoundingInfo().boundingBox.centerWorld,
      );
      const matrixBefore: Matrix = cloneDeep(childMesh.getWorldMatrix());

      const recommendedLordosis =
        defaultRecommendationMap.implantRecommendations[activeLevelGoal.implantType as ImplantType]
          ?.lordosis;
      const recommendedMaxSupplement: number = ((recommendedLordosis ?? 0) / 180) * Math.PI;

      const { lordoticAngle: strictLordosis }: Form19JsonAttributesType = this._levelForm19[level];
      const strictMaxLimit: number = (strictLordosis.max / 180) * Math.PI;
      const strictLordosisRecommendation: number =
        (defaultStrictRecommendations.lordosis * Math.PI) / 180;

      const targetAngleToS1: number | null =
        defaultAngleToS1TargetSpineMap[this._spineProfile][level];

      let currentLordosis: number = this.evaluateLevelLordosis(VertebralBody.S1, VertebralBody.L1);
      let discLordosis: number = this.evaluateIntervertebralLordosis(parent, child);
      let angleToS1: number = this.evaluateLevelLordosis(VertebralBody.S1, child);

      let targetAngleToS1Condition = targetAngleToS1 ? angleToS1 <= targetAngleToS1 : false;
      let isLordosisLeft = this._targetLordosis - currentLordosis <= totalLordosisRemaining;
      let isWithinRecommendedRange = lordosisAdded[level] < recommendedMaxSupplement;
      let isWithinStrictRange = discLordosis < strictMaxLimit;
      let isUnderTargetLordosis = currentLordosis < this._targetLordosis;
      let isUnderStrictRecommendations = discLordosis < strictLordosisRecommendation;

      // satisfy angle to s1 40 25 rule
      while (
        isLordosisLeft &&
        isWithinRecommendedRange &&
        isWithinStrictRange &&
        isUnderTargetLordosis &&
        targetAngleToS1Condition &&
        isUnderStrictRecommendations
      ) {
        childMesh.rotate(xPrime, IncrementUnits.AngleIncrement);

        this.updateAllWorldMatrices(childMesh);

        this.reEvaluateMeasurements();
        this.parseMeasurements();

        currentLordosis = this.evaluateLevelLordosis(VertebralBody.S1, VertebralBody.L1);
        discLordosis = this.evaluateIntervertebralLordosis(parent, child);
        lordosisAdded[level] = discLordosis - preopLordosis[level];
        angleToS1 = this.evaluateLevelLordosis(VertebralBody.S1, child);
        targetAngleToS1Condition = targetAngleToS1 ? angleToS1 <= targetAngleToS1 : false;
        isLordosisLeft = this._targetLordosis - currentLordosis <= totalLordosisRemaining;
        isWithinRecommendedRange = lordosisAdded[level] < recommendedMaxSupplement;
        isWithinStrictRange = discLordosis < strictMaxLimit;
        isUnderTargetLordosis = currentLordosis < this._targetLordosis;
        isUnderStrictRecommendations = discLordosis < strictLordosisRecommendation;
      }

      const matrixAfter: Matrix = childMesh.getWorldMatrix();
      const inverseMatrix: Matrix = Matrix.Invert(matrixBefore);
      const differenceMatrix: Matrix = matrixAfter.multiply(inverseMatrix);
      const differenceQuaternion: Quaternion = Quaternion.FromRotationMatrix(differenceMatrix);
      const positionAfter: Vector3 = childMesh.getBoundingInfo().boundingBox.centerWorld;
      const differenceTranslation: Vector3 = positionAfter.subtract(positionBefore);

      this.evaluateRotationalOperations(child, differenceQuaternion);
      this.evaluateTranslationalOperations(child, differenceTranslation);
    });
  }

  public evaluateRequiredLordosisPyramid(index: number): number {
    const lordoses: number[] = [];
    this._activeLevelGoals.forEach((activeLevelGoal: AutoCorrectionLevelGoalsType) => {
      const [child, parent] = activeLevelGoal.level.split('_') as VertebralBody[];

      const discLordosis: number = this.evaluateIntervertebralLordosis(parent, child);
      lordoses.push(discLordosis);
    });

    const topLevelLordosis = lordoses.slice(index);

    return Math.max(...topLevelLordosis);
  }

  public lumbarLordosisCorrectionPyramid(
    lordosisAdded: AutoCorrectionLordosisStore,
    preopLordosis: AutoCorrectionLordosisStore,
  ): void {
    const actualTotalLordosis: number = this.evaluateLevelLordosis(
      VertebralBody.S1,
      VertebralBody.L1,
    );

    const totalLordosisRemaining: number = this._targetLordosis - actualTotalLordosis;

    this._activeLevelGoals.forEach(
      (activeLevelGoal: AutoCorrectionLevelGoalsType, index: number) => {
        const { level }: AutoCorrectionLevelGoalsType = activeLevelGoal;

        const [child, parent] = activeLevelGoal.level.split('_') as VertebralBody[];
        const childMesh: Mesh = this.getMeshByTag(child);

        // define rotation direction
        const targetInterVBMeasurements: IMeasure[] | undefined =
          this._interVertebralbodyMeasurements[level];
        const { xPrime }: AutoCorrectionEvaluatedCorrectionType =
          calculateCorrectionData(targetInterVBMeasurements);

        // store matrix before apply rotation for operation evaluation
        const positionBefore: Vector3 = cloneDeep(
          childMesh.getBoundingInfo().boundingBox.centerWorld,
        );
        const matrixBefore: Matrix = cloneDeep(childMesh.getWorldMatrix());

        const recommendedMaxSupplement: number =
          ((defaultRecommendationMap.implantRecommendations[
            activeLevelGoal.implantType as ImplantType
          ]?.lordosis ?? 0) /
            180) *
          Math.PI;

        const { lordoticAngle: strictLordosis }: Form19JsonAttributesType =
          this._levelForm19[level];
        const strictMaxLimit: number = (strictLordosis.max / 180) * Math.PI;
        const { lordosis: strictLordosisRecommendation }: AutoCorrectionStrictRecommendations =
          defaultStrictRecommendations;

        let currentLordosis: number = this.evaluateLevelLordosis(
          VertebralBody.S1,
          VertebralBody.L1,
        );
        let discLordosis: number = this.evaluateIntervertebralLordosis(parent, child);
        const requiredDiscLordosis = this.evaluateRequiredLordosisPyramid(index);

        let isLordosisLeft = this._targetLordosis - currentLordosis <= totalLordosisRemaining;
        let isUnderRequiredRange = discLordosis < requiredDiscLordosis;
        let isWithinRecommendedRange = lordosisAdded[level] < recommendedMaxSupplement;
        let isWithinStrictRange = discLordosis < strictMaxLimit;
        let isUnderTargetLordosis = currentLordosis < this._targetLordosis;
        let isUnderStrictRecommendations = discLordosis < strictLordosisRecommendation;

        while (
          isLordosisLeft &&
          isUnderRequiredRange &&
          isWithinRecommendedRange &&
          isWithinStrictRange &&
          isUnderTargetLordosis &&
          isUnderStrictRecommendations
        ) {
          childMesh.rotate(xPrime, IncrementUnits.AngleIncrement);

          this.updateAllWorldMatrices(childMesh);

          this.reEvaluateMeasurements();
          this.parseMeasurements();

          currentLordosis = this.evaluateLevelLordosis(VertebralBody.S1, VertebralBody.L1);
          discLordosis = this.evaluateIntervertebralLordosis(parent, child);
          lordosisAdded[level] = discLordosis - preopLordosis[level];
          isLordosisLeft = this._targetLordosis - currentLordosis <= totalLordosisRemaining;
          isUnderRequiredRange = discLordosis < requiredDiscLordosis;
          isWithinRecommendedRange = lordosisAdded[level] < recommendedMaxSupplement;
          isWithinStrictRange = discLordosis < strictMaxLimit;
          isUnderTargetLordosis = currentLordosis < this._targetLordosis;
          isUnderStrictRecommendations = discLordosis < strictLordosisRecommendation;
        }

        const matrixAfter: Matrix = childMesh.getWorldMatrix();
        const inverseMatrix: Matrix = Matrix.Invert(matrixBefore);
        const differenceMatrix: Matrix = matrixAfter.multiply(inverseMatrix);
        const differenceQuaternion: Quaternion = Quaternion.FromRotationMatrix(differenceMatrix);
        const positionAfter: Vector3 = childMesh.getBoundingInfo().boundingBox.centerWorld;
        const differenceTranslation: Vector3 = positionAfter.subtract(positionBefore);

        this.evaluateRotationalOperations(child, differenceQuaternion);
        this.evaluateTranslationalOperations(child, differenceTranslation);
      },
    );
  }

  public lumbarLordosisCorrectionTopOff(
    lordosisAdded: AutoCorrectionLordosisStore,
    preopLordosis: AutoCorrectionLordosisStore,
  ): void {
    this._activeLevelGoals.forEach((activeLevelGoal: AutoCorrectionLevelGoalsType) => {
      const { level }: AutoCorrectionLevelGoalsType = activeLevelGoal;

      const [child, parent] = activeLevelGoal.level.split('_') as VertebralBody[];
      const childMesh: Mesh = this.getMeshByTag(child);

      // define rotation direction
      const targetInterVBMeasurements: IMeasure[] | undefined =
        this._interVertebralbodyMeasurements[level];
      const { xPrime }: AutoCorrectionEvaluatedCorrectionType =
        calculateCorrectionData(targetInterVBMeasurements);

      // store matrix before apply rotation for operation evaluation
      const positionBefore: Vector3 = cloneDeep(
        childMesh.getBoundingInfo().boundingBox.centerWorld,
      );
      const matrixBefore: Matrix = cloneDeep(childMesh.getWorldMatrix());

      const recommendedLordosis =
        defaultRecommendationMap?.implantRecommendations?.[
          activeLevelGoal.implantType as ImplantType
        ]?.lordosis ?? 0;
      const recommendedMaxSupplement: number = (recommendedLordosis / 180) * Math.PI;

      const { lordoticAngle: strictLordosis }: Form19JsonAttributesType = this._levelForm19[level];
      const strictMaxLimit: number = (strictLordosis.max / 180) * Math.PI;
      const strictLordosisRecommendation: number =
        (defaultStrictRecommendations.lordosis * Math.PI) / 180;

      let currentLordosis: number = this.evaluateLevelLordosis(VertebralBody.S1, VertebralBody.L1);
      let discLordosis: number = this.evaluateIntervertebralLordosis(parent, child);

      let isWithinRecommendedRange = lordosisAdded[level] < recommendedMaxSupplement;
      let isWithinStrictRange = discLordosis < strictMaxLimit;
      let isUnderTargetLordosis = currentLordosis < this._targetLordosis;
      let isUnderStrictRecommendations = discLordosis < strictLordosisRecommendation;

      while (
        isWithinRecommendedRange &&
        isWithinStrictRange &&
        isUnderTargetLordosis &&
        isUnderStrictRecommendations
      ) {
        childMesh.rotate(xPrime, IncrementUnits.AngleIncrement);

        this.updateAllWorldMatrices(childMesh);

        this.reEvaluateMeasurements();
        this.parseMeasurements();

        currentLordosis = this.evaluateLevelLordosis(VertebralBody.S1, VertebralBody.L1);
        discLordosis = this.evaluateIntervertebralLordosis(parent, child);
        lordosisAdded[level] = discLordosis - preopLordosis[level];
        isWithinRecommendedRange = lordosisAdded[level] < recommendedMaxSupplement;
        isWithinStrictRange = discLordosis < strictMaxLimit;
        isUnderTargetLordosis = currentLordosis < this._targetLordosis;
        isUnderStrictRecommendations = discLordosis < strictLordosisRecommendation;
      }

      const matrixAfter: Matrix = childMesh.getWorldMatrix();
      const inverseMatrix: Matrix = Matrix.Invert(matrixBefore);
      const differenceMatrix: Matrix = matrixAfter.multiply(inverseMatrix);
      const differenceQuaternion: Quaternion = Quaternion.FromRotationMatrix(differenceMatrix);
      const positionAfter: Vector3 = childMesh.getBoundingInfo().boundingBox.centerWorld;
      const differenceTranslation: Vector3 = positionAfter.subtract(positionBefore);

      this.evaluateRotationalOperations(child, differenceQuaternion);
      this.evaluateTranslationalOperations(child, differenceTranslation);
    });
  }

  public lumbarLordosisCorrectionIdeal(
    lordosisAdded: AutoCorrectionLordosisStore,
    preopLordosis: AutoCorrectionLordosisStore,
  ): void {
    const LDI65: number = GapScoreConfig.LDI65 * this._targetLordosis;
    const LDI35: number = GapScoreConfig.LDI35 * this._targetLordosis;

    this._activeLevelGoals.forEach((activeLevelGoal: AutoCorrectionLevelGoalsType) => {
      const { level }: AutoCorrectionLevelGoalsType = activeLevelGoal;

      const [child, parent] = activeLevelGoal.level.split('_') as VertebralBody[];
      const childMesh: Mesh = this.getMeshByTag(child);

      let actualLDI: number;
      let theoreticalLDI: number;
      if (
        level === LevelType.L4L5 ||
        level === LevelType.L5S1 ||
        level === LevelType.L4S1 ||
        level === LevelType.L6S1
      ) {
        actualLDI = this.evaluateLevelLordosis(VertebralBody.S1, VertebralBody.L4);
        theoreticalLDI = LDI65;
      } else {
        actualLDI = this.evaluateLevelLordosis(VertebralBody.L4, VertebralBody.L1);
        theoreticalLDI = LDI35;
      }

      // prevent adding of lordosis if condition
      const actualTotalLordosis: number = this.evaluateLevelLordosis(parent, child);
      const idealLordosis: number = defaultIdealSpineMap[level] * this._targetLordosis;

      const levelSpecificCondition: boolean = actualTotalLordosis > idealLordosis;
      const LDICondition: boolean = actualLDI > theoreticalLDI;

      if (levelSpecificCondition || LDICondition) {
        return;
      }

      // define rotation direction
      const targetInterVBMeasurements: IMeasure[] | undefined =
        this._interVertebralbodyMeasurements[level];
      const { xPrime }: AutoCorrectionEvaluatedCorrectionType =
        calculateCorrectionData(targetInterVBMeasurements);

      // store matrix before apply rotation for operation evaluation
      const positionBefore: Vector3 = cloneDeep(
        childMesh.getBoundingInfo().boundingBox.centerWorld,
      );
      const matrixBefore: Matrix = cloneDeep(childMesh.getWorldMatrix());

      // increase lordosis, apply Rotation, and reset data
      const totalLordosisRemaining: number = idealLordosis - actualTotalLordosis;

      const { lordoticAngle: strictLordosis }: Form19JsonAttributesType = this._levelForm19[level];

      const recommendedLordosis =
        defaultRecommendationMap.implantRecommendations[activeLevelGoal.implantType as ImplantType]
          ?.lordosis ?? 0;
      const recommendedMaxSupplement: number = (recommendedLordosis / 180) * Math.PI;
      const strictMaxLimit: number = (strictLordosis.max / 180) * Math.PI;
      const strictLordosisRecommendation: number =
        (defaultStrictRecommendations.lordosis * Math.PI) / 180;

      let currentLordosis: number = this.evaluateLevelLordosis(VertebralBody.S1, VertebralBody.L1);
      let discLordosis: number = this.evaluateIntervertebralLordosis(parent, child);

      let isLordosisLeft = this._targetLordosis - currentLordosis <= totalLordosisRemaining;
      let isWithinRecommendedRange = lordosisAdded[level] < recommendedMaxSupplement;
      let isWithinStrictRange = discLordosis < strictMaxLimit;
      let isUnderTargetLordosis = currentLordosis < this._targetLordosis;
      let isUnderStrictRecommendations = discLordosis < strictLordosisRecommendation;

      while (
        isLordosisLeft &&
        isWithinRecommendedRange &&
        isWithinStrictRange &&
        isUnderTargetLordosis &&
        isUnderStrictRecommendations
      ) {
        childMesh.rotate(xPrime, IncrementUnits.AngleIncrement);

        this.updateAllWorldMatrices(childMesh);

        this.reEvaluateMeasurements();
        this.parseMeasurements();

        currentLordosis = this.evaluateLevelLordosis(VertebralBody.S1, VertebralBody.L1);
        discLordosis = this.evaluateIntervertebralLordosis(parent, child);
        lordosisAdded[level] = discLordosis - preopLordosis[level];
        isLordosisLeft = this._targetLordosis - currentLordosis <= totalLordosisRemaining;
        isWithinRecommendedRange = lordosisAdded[level] < recommendedMaxSupplement;
        isWithinStrictRange = discLordosis < strictMaxLimit;
        isUnderTargetLordosis = currentLordosis < this._targetLordosis;
        isUnderStrictRecommendations = discLordosis < strictLordosisRecommendation;
      }

      const matrixAfter: Matrix = childMesh.getWorldMatrix();
      const inverseMatrix: Matrix = Matrix.Invert(matrixBefore);
      const differenceMatrix: Matrix = matrixAfter.multiply(inverseMatrix);
      const differenceQuaternion: Quaternion = Quaternion.FromRotationMatrix(differenceMatrix);
      const positionAfter: Vector3 = childMesh.getBoundingInfo().boundingBox.centerWorld;
      const differenceTranslation: Vector3 = positionAfter.subtract(positionBefore);

      this.evaluateRotationalOperations(child, differenceQuaternion);
      this.evaluateTranslationalOperations(child, differenceTranslation);
    });
  }

  public lumbarLordosisLevelSpecificCorrection(): void {
    this._activeLevelGoals.forEach((activeLevelGoal: AutoCorrectionLevelGoalsType) => {
      const { level }: AutoCorrectionLevelGoalsType = activeLevelGoal;

      const [child, parent] = activeLevelGoal.level.split('_') as VertebralBody[];
      const childMesh: Mesh = this.getMeshByTag(child);

      // define rotation direction
      const targetInterVBMeasurements: IMeasure[] | undefined =
        this._interVertebralbodyMeasurements[level];
      const { xPrime }: AutoCorrectionEvaluatedCorrectionType =
        calculateCorrectionData(targetInterVBMeasurements);

      // store matrix before apply rotation for operation evaluation
      const positionBefore: Vector3 = cloneDeep(
        childMesh.getBoundingInfo().boundingBox.centerWorld,
      );
      const matrixBefore: Matrix = cloneDeep(childMesh.getWorldMatrix());

      // increase lordosis, apply Rotation, and reset data
      const { lordoticAngle: strictLordosis }: Form19JsonAttributesType = this._levelForm19[level];
      const strictMaxLimit: number = (strictLordosis.max / 180) * Math.PI;

      let discLordosis: number = this.evaluateIntervertebralLordosis(parent, child);
      let isWithinStrictRange = discLordosis < strictMaxLimit;
      let isUnderSpecificTarget =
        discLordosis + IncrementUnits.AngleIncrement < (activeLevelGoal.lordosis * Math.PI) / 180;

      while (isUnderSpecificTarget && isWithinStrictRange) {
        childMesh.rotate(xPrime, IncrementUnits.AngleIncrement);

        this.updateAllWorldMatrices(childMesh);

        this.reEvaluateMeasurements();
        this.parseMeasurements();

        discLordosis = this.evaluateIntervertebralLordosis(parent, child);
        isWithinStrictRange = discLordosis < strictMaxLimit;
        isUnderSpecificTarget =
          discLordosis + IncrementUnits.AngleIncrement < (activeLevelGoal.lordosis * Math.PI) / 180;
      }

      const matrixAfter: Matrix = childMesh.getWorldMatrix();
      const inverseMatrix: Matrix = Matrix.Invert(matrixBefore);
      const differenceMatrix: Matrix = matrixAfter.multiply(inverseMatrix);
      const differenceQuaternion: Quaternion = Quaternion.FromRotationMatrix(differenceMatrix);
      const positionAfter: Vector3 = childMesh.getBoundingInfo().boundingBox.centerWorld;
      const differenceTranslation: Vector3 = positionAfter.subtract(positionBefore);

      this.evaluateRotationalOperations(child, differenceQuaternion);
      this.evaluateTranslationalOperations(child, differenceTranslation);
    });
  }

  public evaluateRotationalOperations(vertebralBody: VertebralBody, quaternion: Quaternion): void {
    const { x, y, z }: Vector3 = quaternion.toEulerAngles();

    const zDegree: number = Math.round((y * 180) / Math.PI);
    const xDegree: number = Math.round((x * 180) / Math.PI);
    const yDegree: number = Math.round((z * 180) / Math.PI);

    const rotationOperationX: IOperation = {
      body: vertebralBody,
      operation: Operation.RotateX,
      value: xDegree,
    };

    const rotationOperationY: IOperation = {
      body: vertebralBody,
      operation: Operation.RotateY,
      value: yDegree,
    };

    const rotationOperationZ: IOperation = {
      body: vertebralBody,
      operation: Operation.RotateZ,
      value: zDegree,
    };

    // order of components operations matter
    const rotationOperations: IOperation[] = [
      rotationOperationZ,
      rotationOperationX,
      rotationOperationY,
    ];

    const filteredOperations = rotationOperations.filter(
      (operation: IOperation) => operation.value !== 0,
    );

    this._operations = this._operations.concat(filteredOperations);
  }

  public evaluateTranslationalOperations(
    vertebralBody: VertebralBody,
    vectorDifference: Vector3,
  ): void {
    const { x, y, z }: Vector3 = vectorDifference;

    const zTrans: number = Math.round(y * 10) / 10;
    const xTrans: number = Math.round(x * 10) / 10;
    const yTrans: number = Math.round(z * 10) / 10;

    const translationOperationX: IOperation = {
      body: vertebralBody,
      operation: Operation.TranslateX,
      value: xTrans,
    };

    const translationOperationY: IOperation = {
      body: vertebralBody,
      operation: Operation.TranslateY,
      value: yTrans,
    };

    const translationOperationZ: IOperation = {
      body: vertebralBody,
      operation: Operation.TranslateZ,
      value: zTrans,
    };

    const translationOperations: IOperation[] = [
      translationOperationX,
      translationOperationY,
      translationOperationZ,
    ];

    const filteredOperations = translationOperations.filter(
      (operation: IOperation) => operation.value !== 0,
    );

    this._operations = this._operations.concat(filteredOperations);
  }

  public heightCorrection(): Promise<void> {
    let result;

    switch (this._heightRestorationGoal) {
      case HeightRestorationGoalType.Specified:
        result = this.heightRestorationSpecific();
        break;
      case HeightRestorationGoalType.TEM013:
        result = this.heightRestorationRecommended();
        break;
      case HeightRestorationGoalType.None:
      default:
        result = new Promise<void>((resolve) => resolve());
        break;
    }

    return result;
  }

  public promiseRace(promise: Promise<void>): Promise<unknown> {
    const timeout = new Promise((_, reject) => {
      const timeOutId: NodeJS.Timeout = setTimeout(() => {
        clearTimeout(timeOutId);
        reject(new Error('Height Restoration timed out after 10 seconds'));
      }, 10000);
    });

    return Promise.race([promise, timeout]);
  }

  public heightRestorationRecommended(): Promise<void> {
    return new Promise((resolve) => {
      this._activeLevelGoals.forEach(({ level, implantType }: AutoCorrectionLevelGoalsType) => {
        const [child] = level.split('_') as VertebralBody[];

        const { patientContactLowerHeight: strictPosteriorHeight }: Form19JsonAttributesType =
          this._levelForm19[level];

        const childMesh: Mesh = this.getMeshByTag(child);

        const targetInterVBMeasurements: IMeasure[] | undefined =
          this._interVertebralbodyMeasurements[level];

        const { yPrime }: AutoCorrectionEvaluatedCorrectionType =
          calculateCorrectionData(targetInterVBMeasurements);

        const matrixBefore: Matrix = cloneDeep(childMesh.getWorldMatrix());

        const targetPosterior: number =
          defaultPosteriorTargets[implantType as ImplantType]?.posterior ?? 0;

        let isInRange = false;

        while (!isInRange) {
          const { posteriorHeight }: LevelMeasurementsType = this.evaluateLordosisAndHeight(level);

          isInRange = posteriorHeight > targetPosterior;

          if (!isInRange) {
            if (posteriorHeight > strictPosteriorHeight.max) {
              break;
            } else if (
              posteriorHeight < targetPosterior ||
              posteriorHeight < strictPosteriorHeight.min
            ) {
              childMesh.translate(yPrime, IncrementUnits.HeightIncrement);
            }

            this.updateAllWorldMatrices(childMesh);

            this.reEvaluateMeasurements();
            this.parseMeasurements();
          }
        }

        const { anteriorHeight: recommendedAnteriorHeight } =
          defaultRecommendationMap.levelRecommendations[this._spineProfile][level];

        isInRange = false;

        if (recommendedAnteriorHeight.min && recommendedAnteriorHeight.max) {
          while (!isInRange) {
            const { anteriorHeight }: LevelMeasurementsType = this.evaluateLordosisAndHeight(level);

            isInRange = inRangeCheck(
              anteriorHeight,
              recommendedAnteriorHeight.min,
              recommendedAnteriorHeight.max,
            );

            if (!isInRange) {
              if (
                anteriorHeight > recommendedAnteriorHeight.max ||
                anteriorHeight > strictPosteriorHeight.max
              ) {
                break;
              } else if (
                anteriorHeight < recommendedAnteriorHeight.min ||
                anteriorHeight < strictPosteriorHeight.min
              ) {
                childMesh.translate(yPrime, IncrementUnits.HeightIncrement);
              }

              this.updateAllWorldMatrices(childMesh);

              this.reEvaluateMeasurements();
              this.parseMeasurements();
            }
          }
        }

        const matrixAfter: Matrix = childMesh.getWorldMatrix();
        const inverseMatrix: Matrix = Matrix.Invert(matrixBefore);

        const differenceMatrix: Matrix = matrixAfter.multiply(inverseMatrix);
        const differenceQuaternion: Quaternion = Quaternion.FromRotationMatrix(differenceMatrix);
        const differenceTranslation: Vector3 = differenceMatrix.getTranslation();

        this.evaluateRotationalOperations(child, differenceQuaternion);
        this.evaluateTranslationalOperations(child, differenceTranslation);

        resolve();
      });
    });
  }

  public heightRestorationSpecific(): Promise<void> {
    return new Promise((resolve) => {
      this._activeLevelGoals.forEach(
        ({ level, posteriorHeightRange, anteriorHeightRange }: AutoCorrectionLevelGoalsType) => {
          const [child] = level.split('_') as VertebralBody[];

          const { patientContactLowerHeight: strictPosteriorHeight }: Form19JsonAttributesType =
            this._levelForm19[level];

          const childMesh: Mesh = this.getMeshByTag(child);

          const targetInterVBMeasurements: IMeasure[] | undefined =
            this._interVertebralbodyMeasurements[level];

          const { yPrime }: AutoCorrectionEvaluatedCorrectionType =
            calculateCorrectionData(targetInterVBMeasurements);

          const matrixBefore: Matrix = cloneDeep(childMesh.getWorldMatrix());

          const posteriorExists = !!(posteriorHeightRange.min && posteriorHeightRange.max);

          const targetRange: RangeType = posteriorExists
            ? posteriorHeightRange
            : anteriorHeightRange;

          let isInRange = false;

          if (targetRange.min && targetRange.max) {
            while (!isInRange) {
              const { posteriorHeight, anteriorHeight }: LevelMeasurementsType =
                this.evaluateLordosisAndHeight(level);

              const currentHeight: number = posteriorExists ? posteriorHeight : anteriorHeight;

              isInRange = currentHeight > targetRange.min && currentHeight < targetRange.max;

              if (!isInRange) {
                if (currentHeight > targetRange.max || currentHeight > strictPosteriorHeight.max) {
                  childMesh.translate(yPrime, -IncrementUnits.HeightIncrement);
                } else if (
                  currentHeight < targetRange.min ||
                  currentHeight < strictPosteriorHeight.min
                ) {
                  childMesh.translate(yPrime, IncrementUnits.HeightIncrement);
                }

                this.updateAllWorldMatrices(childMesh);

                this.reEvaluateMeasurements();
                this.parseMeasurements();
              }
            }
          }

          const matrixAfter: Matrix = childMesh.getWorldMatrix();
          const inverseMatrix: Matrix = Matrix.Invert(matrixBefore);

          const differenceMatrix: Matrix = matrixAfter.multiply(inverseMatrix);
          const differenceQuaternion: Quaternion = Quaternion.FromRotationMatrix(differenceMatrix);
          const differenceTranslation: Vector3 = differenceMatrix.getTranslation();

          this.evaluateRotationalOperations(child, differenceQuaternion);
          this.evaluateTranslationalOperations(child, differenceTranslation);

          resolve();
        },
      );
    });
  }

  public axialCorrection(): void {
    switch (this._axialGoal) {
      case AxialCorrectionGoalType.Include:
        this.axialRotation();
        break;
      case AxialCorrectionGoalType.Other:
      case AxialCorrectionGoalType.None:
        break;
      default:
        break;
    }
  }

  public axialRotation(): void {
    this._activeLevelGoals.forEach(({ level }: AutoCorrectionLevelGoalsType) => {
      const [child, parent] = level.split('_') as VertebralBody[];

      const childMesh: Mesh = this.getMeshByTag(child);

      const targetChildVBMeasurements: IMeasure[] | undefined =
        this._vertebralbodyMeasurements[child];
      const targetParentVBMeasurements: IMeasure[] | undefined =
        this._vertebralbodyMeasurements[parent];

      const { zPrime: childZPrime }: AutoCorrectionEvaluatedCorrectionType =
        calculateCorrectionData(targetChildVBMeasurements);
      const { zPrime: parentZPrime }: AutoCorrectionEvaluatedCorrectionType =
        calculateCorrectionData(targetParentVBMeasurements);

      const targetInterVBMeasurements: IMeasure[] | undefined =
        this._interVertebralbodyMeasurements[level];

      const { yPrime }: AutoCorrectionEvaluatedCorrectionType =
        calculateCorrectionData(targetInterVBMeasurements);

      const projectedChildZPrime: Vector3 = childZPrime
        .subtract(yPrime.scale(Vector3.Dot(childZPrime, yPrime)))
        .normalize();
      const projectedParentZPrime: Vector3 = parentZPrime
        .subtract(yPrime.scale(Vector3.Dot(parentZPrime, yPrime)))
        .normalize();

      const axialCorrectionAngle: number = Vector3.GetAngleBetweenVectors(
        projectedChildZPrime,
        projectedParentZPrime,
        yPrime,
      );

      const matrixBeforeRotation: Matrix = cloneDeep(childMesh.getWorldMatrix());

      childMesh.rotate(yPrime, axialCorrectionAngle);
      this.updateAllWorldMatrices(childMesh);

      this.reEvaluateMeasurements();
      this.parseMeasurements();

      const matrixAfterRotation: Matrix = childMesh.getWorldMatrix();
      const inverseMatrixRotation: Matrix = Matrix.Invert(matrixBeforeRotation);
      const differenceMatrixRotation: Matrix = matrixAfterRotation.multiply(inverseMatrixRotation);
      const differenceQuaternionRotation: Quaternion =
        Quaternion.FromRotationMatrix(differenceMatrixRotation);

      this.evaluateRotationalOperations(child, differenceQuaternionRotation);
    });
  }

  public sagittalAlignment(): void {
    this._activeLevelGoals.forEach(({ level }: AutoCorrectionLevelGoalsType) => {
      const [child, parent] = level.split('_') as VertebralBody[];

      const childMesh: Mesh = this.getMeshByTag(child);

      const targetInterVBMeasurements: IMeasure[] | undefined =
        this._interVertebralbodyMeasurements[level];

      const parentTruePosteriorCoordinate: Mesh | undefined =
        this.getMeshByTag(
          `${parent}.${Position.PosteriorEdge}.${EndPlate.Superior}${AssetSuffix.PointSuffix}`,
        ) ||
        this.getMeshByTag(
          `${parent}.${Position.Posterior}.${EndPlate.Superior}${AssetSuffix.PointSuffix}`,
        );

      const childTruePosteriorCoordinate: Mesh | undefined =
        this.getMeshByTag(
          `${child}.${Position.PosteriorEdge}.${EndPlate.Inferior}${AssetSuffix.PointSuffix}`,
        ) ||
        this.getMeshByTag(
          `${child}.${Position.Posterior}.${EndPlate.Inferior}${AssetSuffix.PointSuffix}`,
        );

      if (parentTruePosteriorCoordinate && childTruePosteriorCoordinate) {
        const parentTruePosterior = new Vector3(
          parentTruePosteriorCoordinate.position.x,
          parentTruePosteriorCoordinate.position.y,
          parentTruePosteriorCoordinate.position.z,
        );

        const childTruePosterior = new Vector3(
          childTruePosteriorCoordinate.position.x,
          childTruePosteriorCoordinate.position.y,
          childTruePosteriorCoordinate.position.z,
        );

        const { yPrime, centroid, zPrime }: AutoCorrectionEvaluatedCorrectionType =
          calculateCorrectionData(targetInterVBMeasurements);

        const planeDistance: number = Vector3.Dot(yPrime, centroid);

        const parentProj: Vector3 = parentTruePosterior.subtract(
          yPrime.scale(Vector3.Dot(parentTruePosterior, yPrime) - planeDistance),
        );
        const childProj: Vector3 = childTruePosterior.subtract(
          yPrime.scale(Vector3.Dot(childTruePosterior, yPrime) - planeDistance),
        );

        const distance: number = Vector3.Dot(parentProj.subtract(childProj), zPrime);

        const matrixBeforeTranslation: Matrix = cloneDeep(childMesh.getWorldMatrix());

        childMesh.translate(zPrime, distance);
        this.updateAllWorldMatrices(childMesh);

        this.reEvaluateMeasurements();
        this.parseMeasurements();

        const matrixAfterTranslation: Matrix = childMesh.getWorldMatrix();
        const inverseMatrixTranslation: Matrix = Matrix.Invert(matrixBeforeTranslation);
        const differenceMatrixTranslation: Matrix =
          matrixAfterTranslation.multiply(inverseMatrixTranslation);
        const differenceTranslation: Vector3 = differenceMatrixTranslation.getTranslation();

        this.evaluateTranslationalOperations(child, differenceTranslation);
      }
    });
  }

  public parsePreopHeightsAndLordosis() {
    this._activeLevelGoals.forEach(({ level }: AutoCorrectionLevelGoalsType) => {
      this._preop[level] = this.evaluateLordosisAndHeight(level);
    });
  }

  public removeMeasurementPoints = (): void => {
    const measurementPoints: Mesh[] = this.getMeshesByTag(AutoCorrectionMesh.Landmark);

    measurementPoints.forEach((measurementPoint: Mesh) => {
      measurementPoint.dispose();
    });
  };

  public getAssetPositionsVertebralBody = (vertebralBody: AbstractMesh) => {
    const meshQuaternion = vertebralBody.absoluteRotationQuaternion;
    const meshEulerAngles = meshQuaternion?.toEulerAngles();
    const targetVertebralBody: AssetPositionsVertebralBody = {
      name: vertebralBody.name.replace(
        AssetSuffix.PreopSuffix,
        AssetSuffix.PlanSuffix,
      ) as VertebralBody,
      position: {
        x: vertebralBody.position.x,
        y: vertebralBody.position.y,
        z: vertebralBody.position.z,
      },
      rotation: {
        x: meshEulerAngles.x,
        y: meshEulerAngles.y,
        z: meshEulerAngles.z,
      },
    };

    return targetVertebralBody;
  };

  public getAssetPositionPoints = (
    measurements: IMeasure[],
  ): { position: Position; name: VertebralBody; endPlate: EndPlate; point: Coordinate }[] => {
    const points: {
      position: Position;
      name: VertebralBody;
      endPlate: EndPlate;
      point: Coordinate;
    }[] = [];

    measurements.forEach(({ body, endPlate, point, position }) => {
      points.push({
        position: position,
        name: body as VertebralBody,
        endPlate: endPlate,
        point: {
          x: point[0],
          y: point[1],
          z: point[2],
        },
      });
    });
    return points;
  };

  public evaluateAssetPositionMetaData = () => {
    const measurementPointValues: IMeasurementPointValues =
      measurements.getMeasurementPointValuesFromAssetPositions(
        this._assetPositions.preop.points ?? [],
        this._spineProfile,
        MeasurementsVersionType.Version2,
      );

    const levelValidations: AssetPositionsMetaDataFormValidationType = {
      C2_C3: null,
      C3_C4: null,
      C4_C5: null,
      C5_C6: null,
      C6_C7: null,
      C7_C8: null,
      C6_T1: null,
      C7_T1: null,
      C8_T1: null,
      L1_L2: null,
      L2_L3: null,
      L3_L4: null,
      L4_L5: null,
      L5_S1: null,
      L4_S1: null,
      L5_L6: null,
      L6_S1: null,
    };

    this._activeLevelGoals.forEach(({ level, implantType }) => {
      const { discCoronal, discLordosis, anteriorHeight, posteriorHeight } =
        this.evaluateLordosisAndHeight(level);

      const {
        lordoticAngle: from19Lordosis,
        coronalAngle: from19Coronal,
        patientContactLowerHeight: form19PosteriorHeight,
        patientContactUpperHeight: form19AnteriorHeight,
      }: Form19JsonAttributesType = this._levelForm19[level];

      const { lordosis: recommendedLordosis, coronal: recommendedCoronal } =
        defaultRecommendationMap?.implantRecommendations?.[implantType as ImplantType] ?? {
          lordosis: 0,
          coronal: 0,
        };
      const {
        anteriorHeight: recommendedAnteriorHeight,
        posteriorHeight: recommendedPosteriorHeight,
      } = defaultRecommendationMap.levelRecommendations[this._spineProfile][level];

      levelValidations[level] = {
        strict: {
          lordosisInSpec: inRangeCheck(discLordosis, from19Lordosis.min, from19Lordosis.max),
          coronalInSpec: inRangeCheck(discCoronal, from19Coronal.min, from19Coronal.max),
          anteriorHeightInSpec: inRangeCheck(
            anteriorHeight,
            form19AnteriorHeight.min,
            form19AnteriorHeight.max,
          ),
          posteriorHeightInSpec: inRangeCheck(
            posteriorHeight,
            form19PosteriorHeight.min,
            form19PosteriorHeight.max,
          ),
        },
        recommended: {
          lordosisInSpec: discLordosis < recommendedLordosis,
          coronalInSpec: discCoronal < recommendedCoronal,
          anteriorHeightInSpec: inRangeCheck(
            anteriorHeight,
            recommendedAnteriorHeight.min as number,
            recommendedAnteriorHeight.max as number,
          ),
          posteriorHeightInSpec: posteriorHeight > recommendedPosteriorHeight,
        },
      };
    });

    this._assetPositions.metadata = {
      autoCorrectionInputs: {
        activeLevelGoals: this._activeLevelGoals,
        pelvicIncidence: this._pelvicIncidence,
        coronalGoal: this._coronalGoal,
        sagittalGoal: this._sagittalGoal,
        axialGoal: this._axialGoal,
        signedUrls: this._vertebraeSignedUrls,
        spineProfile: this._spineProfile,
        patientBirthDate: this._patientBirthDate,
        landmarkMeasurements: this._landmarkMeasurements,
        levelForm19: this._levelForm19,
        gender: this._gender,
      },
      measurements: measurementPointValues,
      formValidation: levelValidations,
    };
  };

  public evaluatePreopAssetPositions = () => {
    this._assetPositions.preop.points = this.getAssetPositionPoints(this._preopMeasurementPoints);

    const preopMeshes = this.getMeshesByTag(AutoCorrectionMesh.VertebralBody);
    const preopVertebralBodies: AssetPositionsVertebralBody[] = [];

    for (const mesh of preopMeshes) {
      preopVertebralBodies.push(this.getAssetPositionsVertebralBody(mesh));
    }

    this._assetPositions.preop.vertebrae = preopVertebralBodies.sort((a, b) =>
      a.name < b.name ? -1 : 1,
    );
  };

  public evaluatePlanAssetPositions = () => {
    this._assetPositions.plan.points = this.getAssetPositionPoints(this._measurementPoints);

    const planVertebralBodies: AssetPositionsVertebralBody[] = [];
    const planMeshes = this.getMeshesByTag(AutoCorrectionMesh.VertebralBody);

    for (const mesh of planMeshes) {
      planVertebralBodies.push(this.getAssetPositionsVertebralBody(mesh));
    }

    this._assetPositions.plan.vertebrae = planVertebralBodies.sort((a, b) =>
      a.name < b.name ? -1 : 1,
    );
  };

  public async main(): Promise<void> {
    console.log('Loading Vertebrae');
    await this.loadVertebrae();

    console.log('Setting Preop Asset Positions');
    this.evaluatePreopAssetPositions();

    console.log('Removing Measurement Points');
    this.removeMeasurementPoints();

    console.log('Parsing Measurements');
    this.parseMeasurements();

    console.log('Setting Vertebral Hierarchy');
    this.setVertebralHierarchy();

    console.log('Loading Preop Endplate Plane');
    this.loadPreopEndPlatePlane();

    console.log('Loading Preop Measurement Points');
    this.loadPreopMeasurementPoints();

    console.log('Parsing Preop Heights And Lordosis');
    this.parsePreopHeightsAndLordosis();

    console.log('Endplate Coronal Parallelization');
    this.endplateCoronalParallelization();

    console.log('Coronal Correction');
    this.coronalAlignment();

    console.log('Sagittal Correction');
    this.sagittalCorrection();

    console.log('Sagittal Alignment');
    this.sagittalAlignment();

    console.log('Axial Correction');
    this.axialCorrection();

    try {
      console.log('Height Restoration');
      await this.promiseRace(this.heightCorrection());
    } catch (error) {
      console.error(error);
    }

    console.log('Setting Plan Asset Positions');
    this.evaluatePlanAssetPositions();

    console.log('Setting Asset Position Metadata');
    this.evaluateAssetPositionMetaData();
  }
}
