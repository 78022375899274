import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  OutlinedInput,
  SelectChangeEvent,
} from '@mui/material';
import { useEffect, useState } from 'react';

export function Select({
  disabled,
  fullWidth = true,
  multiple,
  hideNone,
  label,
  menuItems,
  name,
  onChange,
  value,
  variant,
  customEmptyText = 'None',
  ...args
}: {
  name: string;
  label?: string;
  value?: string | string[];
  disabled?: boolean;
  hideNone?: boolean;
  fullWidth?: boolean;
  multiple?: boolean;
  menuItems: { key: string; value: string }[];
  onChange?: (e: SelectChangeEvent<any>) => void;
  variant?: 'outlined' | 'standard';
  customEmptyText?: string;
}) {
  const [selectValue, setSelectValue] = useState(value);

  const handleChange = (e: SelectChangeEvent<string | string[]>) => {
    setSelectValue(e.target.value);
    onChange?.(e);
  };

  useEffect(() => {
    setSelectValue(value);
  }, [value]);

  return (
    <FormControl fullWidth={fullWidth} size={'small'} variant={variant}>
      {label ? (
        <InputLabel shrink id={`$variantName}-${name}-always-notched-label`}>
          {label}
        </InputLabel>
      ) : null}
      <MuiSelect
        {...args}
        input={<OutlinedInput label={label} notched />}
        fullWidth={fullWidth}
        id={name}
        label={label}
        type="text"
        displayEmpty={true}
        onChange={handleChange}
        disabled={disabled}
        multiple={multiple}
        value={multiple ? selectValue || [''] : selectValue || ''}
        renderValue={(selected: any) => {
          const value = menuItems.find(
            (menuItem) => menuItem.key.toString() === selected?.toString(),
          )?.value;

          return multiple && selected && selected.length ? (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((key: string) => {
                const menuItem = menuItems.find(
                  (menuItem) => menuItem.key.toString() === key.toString(),
                );
                if (!menuItem) {
                  return;
                }

                return (
                  <Chip
                    key={menuItem.key}
                    label={menuItem.value}
                    size={'small'}
                    onMouseDown={(event) => {
                      event.stopPropagation();
                    }}
                    onDelete={() => {
                      const removed = selected.filter((s: string) => s !== menuItem.key);
                      handleChange({ target: { value: removed } } as SelectChangeEvent);
                    }}
                  />
                );
              })}
            </Box>
          ) : (
            value ?? customEmptyText
          );
        }}
      >
        {!hideNone ? <MenuItem value={''}>None</MenuItem> : null}

        {menuItems.map(({ key, value }) => (
          <MenuItem key={key} value={key}>
            {value}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
}
