import {
  faCube,
  faMagnifyingGlassPlus,
  faRuler,
  faToggleOn,
  faVideoCamera,
} from '@fortawesome/pro-light-svg-icons';
import {
  faEyeSlash as faEyeSlashSolid,
  faEye as faEyeSolid,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Menu,
  MenuItem,
  Popover,
  Slider,
  Stack,
  Switch,
} from '@mui/material';
import { format } from '@workflow-nx/common';
import { SpineAssetTags } from '@workflow-nx/scene';
import { Button, IconFontButton } from '@workflow-nx/ui';
import { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';
import React, { useContext, useState } from 'react';
import { PlanViewDialogContext } from './PlanViewDialog.context';

export function PlanNavigationView(props: { showDaisy?: boolean }) {
  const planViewDialogContext = useContext(PlanViewDialogContext);

  const [cameraMenuEl, setCameraMenuEl] = useState<null | HTMLElement>(null);
  const [implantMenuEl, setImplantMenuEl] = useState<null | HTMLElement>(null);
  const opacityPopupState = usePopupState({
    variant: 'popover',
    popupId: 'opacityPopover',
  });
  const visibilityPopupState = usePopupState({
    variant: 'popover',
    popupId: 'visibilityPopover',
  });

  const handleCameraMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setCameraMenuEl(event.currentTarget);
  };

  const handleImplantMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setImplantMenuEl(event.currentTarget);
  };

  const levelState = planViewDialogContext.levelState;

  return (
    <Stack
      alignItems={'center'}
      justifyContent={'center'}
      divider={<Divider orientation={'horizontal'} flexItem={true} />}
    >
      <Box height={50} display={'flex'} alignItems={'center'}>
        <IconFontButton icon={faVideoCamera} onClick={handleCameraMenuClick} />
        <Menu
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          anchorEl={cameraMenuEl}
          keepMounted
          open={Boolean(cameraMenuEl)}
          onClose={() => setCameraMenuEl(null)}
        >
          <MenuItem
            key={'CORONAL'}
            onClick={() => {
              planViewDialogContext.switchCamera('CORONAL');
              setCameraMenuEl(null);
            }}
          >
            Coronal
          </MenuItem>
          <MenuItem
            key={'SAGITTAL_LEFT'}
            onClick={() => {
              planViewDialogContext.switchCamera('SAGITTAL_LEFT');
              setCameraMenuEl(null);
            }}
          >
            Sagittal Left
          </MenuItem>
          <MenuItem
            key={'SAGITTAL_RIGHT'}
            onClick={() => {
              planViewDialogContext.switchCamera('SAGITTAL_RIGHT');
              setCameraMenuEl(null);
            }}
          >
            Sagittal Right
          </MenuItem>
          <MenuItem
            key={'AXIAL'}
            onClick={() => {
              planViewDialogContext.switchCamera('AXIAL');
              setCameraMenuEl(null);
            }}
          >
            Axial
          </MenuItem>
        </Menu>
      </Box>
      <Box height={50} display={'flex'} alignItems={'center'}>
        <IconFontButton icon={faToggleOn} {...bindTrigger(visibilityPopupState)} />
        <Popover
          style={{ zIndex: 9999 }}
          {...bindPopover(visibilityPopupState)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Box display={'flex'} width={400} alignItems={'flexStart'} p={2}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Pre-op</FormLabel>
              <FormGroup>
                {levelState.preop.map((levelState) => {
                  return (
                    <FormControlLabel
                      key={`${levelState}_preop`}
                      control={
                        <Switch
                          checked={levelState.isChecked}
                          onChange={() => {
                            planViewDialogContext.toggleVisibility([
                              `PREOP`,
                              `VERTEBRAE`,
                              levelState.level,
                            ]);
                          }}
                        />
                      }
                      label={levelState.level}
                    />
                  );
                })}
              </FormGroup>
            </FormControl>
            {planViewDialogContext.planDiff ? (
              <FormControl component="fieldset">
                <FormLabel component="legend">Plan</FormLabel>
                <FormGroup>
                  {levelState.plan.map((levelState) => {
                    return (
                      <FormControlLabel
                        key={`${levelState}_plan`}
                        control={
                          <Switch
                            checked={levelState.isChecked}
                            onChange={() => {
                              planViewDialogContext.toggleVisibility([
                                `PLAN`,
                                `VERTEBRAE`,
                                levelState.level,
                              ]);
                            }}
                          />
                        }
                        label={levelState.level}
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
            ) : null}
            {planViewDialogContext.planDiff ? (
              <FormControl component="fieldset">
                <FormLabel component="legend">Plan Diff</FormLabel>
                <FormGroup>
                  {levelState.planDiff.map((levelState) => {
                    return (
                      <FormControlLabel
                        key={`${levelState}_planDiff`}
                        control={
                          <Switch
                            checked={levelState.isChecked}
                            onChange={() => {
                              planViewDialogContext.toggleVisibility([
                                `PLAN_DIFF`,
                                `VERTEBRAE`,
                                levelState.level,
                              ]);
                            }}
                          />
                        }
                        label={levelState.level}
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
            ) : null}
            {props.showDaisy && levelState.daisy.length > 0 ? (
              <FormControl component="fieldset">
                <FormLabel component="legend">Daisy</FormLabel>
                <FormGroup>
                  {levelState.daisy.map((levelState) => {
                    return (
                      <FormControlLabel
                        key={`${levelState}_daisy`}
                        control={
                          <Switch
                            checked={levelState.isChecked}
                            onChange={() => {
                              planViewDialogContext.toggleVisibility([
                                `DAISY`,
                                `VERTEBRAE`,
                                levelState.level,
                              ]);
                            }}
                          />
                        }
                        label={levelState.level.replace('_DAISY', '')}
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
            ) : null}
            {planViewDialogContext.plan ? (
              <FormControl component="fieldset">
                <FormLabel component="legend">Implants</FormLabel>
                <FormGroup>
                  {levelState.implants.map((levelState) => {
                    return (
                      <FormControlLabel
                        key={`${levelState}_implant`}
                        control={
                          <Switch
                            checked={levelState.isChecked}
                            onChange={() => {
                              planViewDialogContext.toggleVisibility([
                                `PLAN`,
                                `IMPLANT`,
                                levelState.level,
                              ]);
                            }}
                          />
                        }
                        label={format.formatLevelType(levelState.level)}
                      />
                    );
                  })}
                  <FormControlLabel
                    key={`hardware_implants`}
                    control={
                      <Switch
                        checked={levelState.hardwareVisible}
                        onChange={() => {
                          planViewDialogContext.toggleVisibility([SpineAssetTags.Hardware]);
                        }}
                      />
                    }
                    label={'Hardware'}
                  />
                </FormGroup>
              </FormControl>
            ) : null}
          </Box>
        </Popover>
      </Box>
      {props.showDaisy && levelState.daisy.length > 0 ? (
        <Box
          height={50}
          display={'flex'}
          alignItems={'center'}
          style={{
            backgroundColor: levelState.daisyVisible ? 'lightblue' : '',
          }}
        >
          <Button
            label={'DAISY'}
            onClick={() => planViewDialogContext.toggleVisibility(['DAISY'])}
            style={{ height: '100%' }}
          />
        </Box>
      ) : null}
      <Box
        height={50}
        display={'flex'}
        alignItems={'center'}
        style={{
          backgroundColor: levelState.preopVisible ? 'lightblue' : '',
        }}
      >
        <Button
          label={'PRE'}
          onClick={() => planViewDialogContext.toggleVisibility(['PREOP'])}
          style={{ height: '100%' }}
        />
      </Box>
      {planViewDialogContext.plan ? (
        <Box
          height={50}
          display={'flex'}
          alignItems={'center'}
          style={{
            backgroundColor: levelState.planVisible ? 'lightblue' : '',
          }}
        >
          <Button
            label={'PLAN'}
            onClick={() => planViewDialogContext.toggleVisibility(['PLAN'])}
            style={{ height: '100%' }}
          />
        </Box>
      ) : null}
      <Box
        height={50}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        width={60}
        textAlign={'center'}
        style={{
          backgroundColor: planViewDialogContext.showMeasurements ? 'lightblue' : '',
        }}
      >
        <IconFontButton
          disabled={!levelState.preopVisible && !levelState.planVisible}
          icon={faRuler}
          onClick={() => planViewDialogContext.toggleMeasurements()}
        />
      </Box>
      <Box height={50} display={'flex'} alignItems={'center'}>
        <IconFontButton icon={faCube} {...bindTrigger(opacityPopupState)} />
        <Popover
          style={{ zIndex: 9999 }}
          {...bindPopover(opacityPopupState)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Box display={'flex'} width={200} alignItems={'center'}>
            <Box mx={1}>
              <FontAwesomeIcon color={'#696969'} icon={faEyeSlashSolid} />
            </Box>
            <Box mx={1} flexGrow={1}>
              <Slider
                min={0.1}
                max={1.1}
                step={0.1}
                value={planViewDialogContext.opacity}
                onChange={(event: any, newValue: number | number[]) => {
                  planViewDialogContext.toggleOpacity(newValue as number);
                }}
              />
            </Box>
            <Box mx={1}>
              <FontAwesomeIcon color={'#696969'} icon={faEyeSolid} />
            </Box>
          </Box>
        </Popover>
      </Box>
      {planViewDialogContext.plan ? (
        <Box height={50} display={'flex'} alignItems={'center'}>
          <IconFontButton icon={faMagnifyingGlassPlus} onClick={handleImplantMenuClick} />
          <Menu
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            anchorEl={implantMenuEl}
            keepMounted
            open={Boolean(implantMenuEl)}
            onClose={() => setImplantMenuEl(null)}
          >
            {levelState.implants.map((implant) => (
              <MenuItem
                key={implant.level}
                onClick={() => {
                  planViewDialogContext.toggleImplant(implant.level);
                  setImplantMenuEl(null);
                }}
              >
                {format.formatLevelType(implant.level)}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      ) : null}
    </Stack>
  );
}
