import React from 'react';
import { Box, Typography, Grid, Divider } from '@mui/material';
import { TextFieldRHF, ErrorMessageRHF, Button } from '@workflow-nx/ui';
import { FieldValues, Control } from 'react-hook-form';
import SwitchRHF from '../../../components/SwitchRHF';
import { ConfigurationValueType } from '@workflow-nx/common';

export function ServicesSectionForm(props: {
  title: string;
  fields: { id: string; label: string; type: ConfigurationValueType }[];
  control: Control<FieldValues>;
  submitHandler: (evt: React.MouseEvent<Element, MouseEvent>) => Promise<void>;
  disabled?: boolean;
}) {
  return (
    <Box m={1} mt={2}>
      <Box>
        <Typography variant={'body1'}>
          <strong>{props.title}</strong>
        </Typography>
        <Box mb={2} />

        <Grid container spacing={2} mx={1} mb={1}>
          {props.fields.map((data, index) => {
            return (
              <Grid item container xs={12} spacing={1} alignItems={'center'} key={index}>
                <Grid item xs={3} md={3} display={'flex'} alignItems={'center'}>
                  <Typography variant={'body1'}>{data.label}</Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  {data.type !== ConfigurationValueType.Boolean ? (
                    <TextFieldRHF
                      name={data.id}
                      control={props.control}
                      type={data.type === ConfigurationValueType.Number ? 'number' : 'text'}
                    />
                  ) : (
                    <SwitchRHF name={data.id} control={props.control} />
                  )}
                </Grid>
                <Grid item xs={3} md={3} display={'flex'} justifyContent={'center'}>
                  <ErrorMessageRHF name={data.id} control={props.control} />
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <Box my={2} display={'flex'} justifyContent={'flex-end'}>
        <Button
          variant={'outlined'}
          color={'primary'}
          label={'Save Settings'}
          onClick={props.submitHandler}
          disabled={props.disabled}
        />
      </Box>
      <Divider />
    </Box>
  );
}
