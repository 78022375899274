import { date } from '@workflow-nx/utils';
import * as dateFns from 'date-fns';
import DOMPurify from 'dompurify';
import { parsePhoneNumber } from 'libphonenumber-js';
import { DateTime } from 'luxon';
import HTMLParser from 'node-html-parser';
import {
  ActivityOriginType,
  ActivityType,
  AgeRange,
  AssetType,
  CarrierType,
  CaseApproachType,
  CaseCancellationType,
  CaseCancelledReasonType,
  CaseHoldReasonType,
  CaseInvalidReasonType,
  CaseMissedReasonType,
  CaseRiskAssessmentType,
  CaseShippingStatusType,
  CaseSpineProfile,
  CaseSpineType,
  CaseStageType,
  CaseStageTypeColor,
  CaseType,
  CommentType,
  CoronalCorrectionGoalType,
  CoronalGoalType,
  EventType,
  Form20ProductType,
  HeightRestorationGoalType,
  ImplantCharacteristicType,
  ImplantDirection,
  ImplantOrientation,
  ImplantPositionType,
  ImplantType,
  InterbodyScrewLengthTypes,
  InterbodySystemType,
  LevelResult,
  LevelResultNotUsedReason,
  LevelSize,
  LevelType,
  NavigationSystemType,
  OrganizationType,
  PartType,
  PatientContactCadenceType,
  PatientContactPreferenceType,
  PlanRejectionReason,
  Position,
  PositionDistanceType,
  PostOpAnalysisType,
  PriceLevelType,
  SagittalGoalType,
  SurgeonApproachType,
  SurgeonPreferenceMeasurementType,
  TargetHeightMetricType,
  TaskActionType,
  TaskPriorityType,
  TaskStatusType,
  UserRoleType,
  UserStatusType,
  VendorTaskCategory,
} from '../enums';
import { ICaseCancellation, ImplantMeasurementRange } from '../interfaces';

export const formatDegrees = (value: number, decimalPlaces = 0) => {
  if (Number.isNaN(Number(value))) {
    return '';
  }
  return `${Number(Math.round(value)).toFixed(decimalPlaces)}°`;
};

export const formatHeight = (value: number, decimalPlaces = 0) => {
  if (Number.isNaN(Number(value))) {
    return '';
  }
  return `${Number(value).toFixed(decimalPlaces)}mm`;
};

export const formatDate = (value: Date | string, format = 'yyyy-MM-dd') => {
  try {
    let dateToFormat: Date;

    if (!value) {
      return '';
    }

    if (value instanceof Date) {
      dateToFormat = value;
    } else {
      dateToFormat = date.parseISO(value.toString());
    }

    return dateFns.format(dateToFormat, format);
  } catch (e) {
    return '';
  }
};

export const formatTime = (value: Date | string, format = 'h:mma') => {
  try {
    let dateToFormat: Date;

    if (!value) {
      return '';
    }

    if (value instanceof Date) {
      dateToFormat = value;
    } else {
      dateToFormat = date.parseISO(value.toString());
    }

    return dateFns.format(dateToFormat, format);
  } catch (e) {
    return '';
  }
};

export const formatGender = (value: 'MALE' | 'FEMALE') => {
  switch (value) {
    case 'MALE':
      return 'Male';
    case 'FEMALE':
      return 'Female';
    default:
      return value;
  }
};

export const formatDateTime = (value: Date | string, format = 'yyyy-MM-dd h:mma') => {
  let dateToFormat: Date;
  if (value instanceof Date) {
    dateToFormat = value;
  } else {
    dateToFormat = date.parseISO(value.toString());
  }
  return DateTime.fromJSDate(dateToFormat).toFormat(format);
};

export const formatName = (
  {
    firstName,
    lastName,
    role,
  }: {
    firstName?: string;
    lastName?: string;
    role?: string;
  } = {},
  startWithLast = false,
) => {
  return startWithLast
    ? `${lastName || 'X'}, ${role === UserRoleType.Surgeon ? 'Dr.' : ''} ${firstName || 'X'}`.trim()
    : `${role === UserRoleType.Surgeon ? 'Dr.' : ''} ${firstName || 'X'} ${lastName || 'X'}`.trim();
};

export const formatInitials = ({
  firstName,
  lastName,
}: {
  firstName?: string;
  lastName?: string;
} = {}) => {
  return `${(firstName || 'X')[0]}${(lastName || 'X')[0]}`.trim().toUpperCase();
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const formatActivityType = (activityType: ActivityType) => {
  switch (activityType) {
    case ActivityType.CaseAutoSegmentationApproved:
      return 'Auto-segmentation Approved';
    case ActivityType.CaseAutoSegmentationRejected:
      return 'Auto-segmentation Rejected';
    case ActivityType.CaseAutoSegmentationApprovedWithChanges:
      return 'Auto-segmentation Approved with Changes';
    case ActivityType.CaseCancelled:
      return 'Case Cancelled';
    case ActivityType.CaseUncancelled:
      return 'Case Uncancelled';
    case ActivityType.CaseHold:
      return 'Case Surgery Date On-Hold';
    case ActivityType.CaseRemoveHold:
      return 'Case Surgery Date Hold Removed';
    case ActivityType.CaseStageSegmentationReopened:
      return 'Case Stage Segmentation Reopened';
    case ActivityType.CaseResultsUpdated:
      return 'Case Results Updated';
    case ActivityType.CaseStudyDateUpdated:
      return 'Case Study Date Updated';
    case ActivityType.CaseSurgeryDateUpdated:
      return 'Case Surgery Date Updated';
    case ActivityType.CaseImplantSpecificationCreate:
      return 'Case Implant Specification Created';
    case ActivityType.CaseImplantSpecificationDelete:
      return 'Case Implant Specification Deleted';
    case ActivityType.CaseImplantSpecificationUpdate:
      return 'Case Implant Specification Updated';
    case ActivityType.CaseImplantSpecificationUpsert:
      return 'Case Implant Specification Upserted';
    case ActivityType.PriceLevelUpdate:
      return 'Price Level Updated';
    case ActivityType.PriceLevelCreate:
      return 'Price Level Created';
    case ActivityType.PriceLevelDelete:
      return 'Price Level Deleted';
    case ActivityType.AssetCreate:
      return 'Asset Create';
    case ActivityType.AssetDelete:
      return 'Asset Delete';
    case ActivityType.CaseCreate:
      return 'Case Create';
    case ActivityType.CaseUpdate:
      return 'Case Update';
    case ActivityType.CaseUpdateNumber:
      return 'Case Update Number';
    case ActivityType.CaseUpdateStudyDate:
      return 'Case Update Study Date';
    case ActivityType.CaseDelete:
      return 'Case Delete';
    case ActivityType.CaseUndelete:
      return 'Case Undelete';
    case ActivityType.CaseImport:
      return 'Case Import';
    case ActivityType.CaseImported:
      return 'Case Imported';
    case ActivityType.CaseExported:
      return 'Case Exported';
    case ActivityType.CaseForceSync:
      return 'Case Force Sync';
    case ActivityType.CaseStageOpenComplete:
      return 'Case Stage Open Complete';
    case ActivityType.CaseStageSegmentationComplete:
      return 'Case Stage Segmentation Complete';
    case ActivityType.CaseStagePlanningComplete:
      return 'Case Stage Planning Complete';
    case ActivityType.CaseStagePlanningReopened:
      return 'Case Stage Planning Reopened';
    case ActivityType.CaseStageProposedComplete:
      return 'Case Stage Proposed Complete';
    case ActivityType.CaseStageProposedReopened:
      return 'Case Stage Proposed Reopened';
    case ActivityType.CaseStageDesignComplete:
      return 'Case Stage Design Complete';
    case ActivityType.CaseStageDesignReopened:
      return 'Case Stage Design Reopened';
    case ActivityType.CaseStageQaReviewComplete:
      return 'Case Stage QA Review Complete';
    case ActivityType.CaseStageQaReviewReopened:
      return 'Case Stage QA Review Reopened';
    case ActivityType.CaseStageProductionComplete:
      return 'Case Stage Production Complete';
    case ActivityType.CaseStageProductionReopened:
      return 'Case Stage Production Reopened';
    case ActivityType.CaseStageReadyComplete:
      return 'Case Stage Ready Complete';
    case ActivityType.CaseStageReadyReopened:
      return 'Case Stage Ready Reopened';
    case ActivityType.CaseStageCompleteReopened:
      return 'Case Reopened';
    case ActivityType.CaseReportApproved:
      return 'Case Report Approved';
    case ActivityType.CaseReportCreate:
      return 'Case Report Create';
    case ActivityType.CaseReportDelete:
      return 'Case Report Delete';
    case ActivityType.CaseReportInProgress:
      return 'Case Report In Progress';
    case ActivityType.CaseReportInReview:
      return 'Case Report In Review';
    case ActivityType.CaseReportRejected:
      return 'Case Report Rejected';
    case ActivityType.CaseReportUpdate:
      return 'Case Report Update';
    case ActivityType.CommentCreate:
      return 'Comment Create';
    case ActivityType.CommentDelete:
      return 'Comment Delete';
    case ActivityType.CommentUpdate:
      return 'Comment Update';
    case ActivityType.ConfigurationUpdate:
      return 'Configuration Update';
    case ActivityType.EmailSendSuccess:
      return 'Email Send Success';
    case ActivityType.EmailSendFailure:
      return 'Email Send Failure';
    case ActivityType.EventCreate:
      return 'Event Create';
    case ActivityType.EventDelete:
      return 'Event Delete';
    case ActivityType.Form19Update:
      return 'Form 19 Update';
    case ActivityType.Form19Create:
      return 'Form 19 Created';
    case ActivityType.Form19Regenerated:
      return 'Form 19 Regenerated';
    case ActivityType.Form19Delete:
      return 'Form 19 Deleted';
    case ActivityType.Form19UpdateStatusDraft:
      return 'Form 19 Update Status Draft';
    case ActivityType.Form19UpdateStatusReadyForReview:
      return 'Form 19 Update Status Ready For Review';
    case ActivityType.Form19UpdateStatusVerified:
      return 'Form 19 Update Status Verified';
    case ActivityType.Form19UpdateStatusRejected:
      return 'Form 19 Update Status Rejected';
    case ActivityType.Form19UpdateStatusApproved:
      return 'Form 19 Update Status Approved';
    case ActivityType.LocationCreate:
      return 'Location Create';
    case ActivityType.LocationUpdate:
      return 'Location Update';
    case ActivityType.LocationDelete:
      return 'Location Delete';
    case ActivityType.LocationUpsert:
      return 'Location Upsert';
    case ActivityType.MeasurementUpsert:
      return 'Measurement Upsert';
    case ActivityType.PatientUpdate:
      return 'Patient Update';
    case ActivityType.PatientRecordUpdate:
      return 'Patient Record Update';
    case ActivityType.OrganizationCreate:
      return 'Organization Create';
    case ActivityType.OrganizationUpdate:
      return 'Organization Update';
    case ActivityType.OrganizationDelete:
      return 'Organization Delete';
    case ActivityType.PlanCopy:
      return 'Plan Copy';
    case ActivityType.PlanCreate:
      return 'Plan Create';
    case ActivityType.PlanUpdate:
      return 'Plan Update';
    case ActivityType.PlanUpsert:
      return 'Plan Upsert';
    case ActivityType.PlanDelete:
      return 'Plan Delete';
    case ActivityType.PlanUnapprove:
      return 'Plan Unapprove';
    case ActivityType.PlanUpdateStatus:
      return 'Plan Update Status';
    case ActivityType.PlanProposalRejected:
      return 'Plan Proposal Rejected';
    case ActivityType.PlanUpdateImplants:
      return 'Plan Update Implants';
    case ActivityType.PlanImplantDelete:
      return 'Plan Implant Delete';
    case ActivityType.PlanImplantMove:
      return 'Plan Implant Move';
    case ActivityType.PlanImplantUpsert:
      return 'Plan Implant Upsert';
    case ActivityType.PostOpAnalysisCreate:
      return 'Post-Op Analysis Created';
    case ActivityType.PostOpAnalysisDelete:
      return 'Post-Op Analysis Deleted';
    case ActivityType.PostOpAnalysisUpdate:
      return 'Post-Op Analysis Updated';
    case ActivityType.SettingUpdate:
      return 'Setting Created';
    case ActivityType.TaskCancel:
      return 'Task Cancelled';
    case ActivityType.TaskCreate:
      return 'Task Created';
    case ActivityType.TaskDelete:
      return 'Task Deleted';
    case ActivityType.TaskUpdate:
      return 'Task Updated';
    case ActivityType.UserCreate:
      return 'User Create';
    case ActivityType.UserUpdate:
      return 'User Update';
    case ActivityType.UserUpdatePreferences:
      return 'User Update Preferences';
    case ActivityType.UserUpdatePermissions:
      return 'User Update Permissions';
    case ActivityType.UserDelete:
      return 'User Delete';
    case ActivityType.CaseDHRReviewAccepted:
      return 'Case DHR Review Accepted';
    case ActivityType.CaseDHRReviewRejected:
      return 'Case DHR Review Rejected';
    case ActivityType.CaseDHRReviewRequested:
      return 'Case DHR Review Requested';
    default:
      return activityType;
  }
};

export const formatActivityOriginType = (activityOriginType: ActivityOriginType) => {
  switch (activityOriginType) {
    case ActivityOriginType.Asset:
      return 'Asset';
    case ActivityOriginType.Case:
      return 'Case';
    case ActivityOriginType.CaseImplantSpecification:
      return 'Case Implant Specification';
    case ActivityOriginType.Comment:
      return 'Comment';
    case ActivityOriginType.Configuration:
      return 'Configuration';
    case ActivityOriginType.Event:
      return 'Event';
    case ActivityOriginType.Email:
      return 'Email';
    case ActivityOriginType.Form:
      return 'Form';
    case ActivityOriginType.Form19:
      return 'Form-19';
    case ActivityOriginType.Location:
      return 'Location';
    case ActivityOriginType.Measurement:
      return 'Measurement';
    case ActivityOriginType.Organization:
      return 'Organization';
    case ActivityOriginType.Patient:
      return 'Patient';
    case ActivityOriginType.PatientRecord:
      return 'Patient Record';
    case ActivityOriginType.Plan:
      return 'Plan';
    case ActivityOriginType.PlanRejection:
      return 'Plan Rejection';
    case ActivityOriginType.PostOpAnalysis:
      return 'Post-Op Analysis';
    case ActivityOriginType.PriceLevel:
      return 'Price Level';
    case ActivityOriginType.Setting:
      return 'Setting';
    case ActivityOriginType.Task:
      return 'Task';
    case ActivityOriginType.User:
      return 'User';
    default:
      return activityOriginType;
  }
};

export const formatCaseStage = (stage: CaseStageType) => {
  switch (stage) {
    case CaseStageType.Open:
      return 'Unassigned';
    case CaseStageType.Segmentation:
      return 'Segmentation';
    case CaseStageType.Planning:
      return 'Planning';
    case CaseStageType.Proposed:
      return 'Proposed';
    case CaseStageType.Design:
      return 'Design';
    case CaseStageType.QaReview:
      return 'QA Review';
    case CaseStageType.Production:
      return 'Production';
    case CaseStageType.Packaging:
      return 'Packaging';
    case CaseStageType.Shipping:
      return 'Shipping';
    case CaseStageType.Distributor:
      return 'Distributor';
    case CaseStageType.Ready:
      return 'Ready';
    case CaseStageType.Complete:
      return 'Complete';
  }
};

export const formatNavigationSystem = (navigationSystem: NavigationSystemType) => {
  switch (navigationSystem) {
    case NavigationSystemType.PosteriorFixation:
      return 'Posterior Fixation';
    case NavigationSystemType.Interbody:
      return 'Interbody';
    case NavigationSystemType.DiscPrep:
      return 'Disc Prep';
  }
};

export const formatInterbodySystem = (interbodySystem: InterbodySystemType) => {
  switch (interbodySystem) {
    case InterbodySystemType.Allosource:
      return 'Allosource';
    case InterbodySystemType.Alphatec:
      return 'Alphatec';
    case InterbodySystemType.Medtronic:
      return 'Medtronic';
    case InterbodySystemType.DePuySynthes:
      return 'DePuySynthesis';
    case InterbodySystemType.NuVasive:
      return 'NuVasive';
    case InterbodySystemType.GlobusMedical:
      return 'Globus Medical';
    case InterbodySystemType.Orthofix:
      return 'Orthofix';
    case InterbodySystemType.K2Stryker:
      return 'K2 Stryker';
    case InterbodySystemType.SpineArt:
      return 'Spine Art';
    case InterbodySystemType.Lifenet:
      return 'Lifenet';
    case InterbodySystemType.ZimmerBiomet:
      return 'Zimmer Biomet';
    case InterbodySystemType.Other:
      return 'Other';
  }
};

export const formatCaseType = (caseType: CaseType) => {
  switch (caseType) {
    case CaseType.Live:
      return 'Live';
    case CaseType.Lab:
      return 'Lab';
    case CaseType.Test:
      return 'Test';
    case CaseType.Draft:
      return 'Draft';
    case CaseType.Demo:
      return 'Demo';
  }
};

export const formatSpineType = (spineType: CaseSpineType) => {
  switch (spineType) {
    case CaseSpineType.Cervical:
      return 'Cervical';
    case CaseSpineType.Lumbar:
      return 'Lumbar';
  }
};

export const formatPartType = (part: PartType): string => {
  let formattedPart = 'None';

  switch (part) {
    case PartType.FUSED:
      formattedPart = 'Fused';
      break;
    case PartType.ACDF:
      formattedPart = 'ACDF';
      break;
    case PartType.ACDF_X_TWO_UP:
      formattedPart = 'ACDF/X (2 Up)';
      break;
    case PartType.ACDF_X_TWO_DOWN:
      formattedPart = 'ACDF/X (2 Down)';
      break;
    case PartType.ACDF_X_LEFT_UP:
      formattedPart = 'ACDF/X (Left Up)';
      break;
    case PartType.ACDF_X_LEFT_DOWN:
      formattedPart = 'ACDF/X (Left Down)';
      break;
    case PartType.ACDF_X_NO_CAM_TWO_UP:
      formattedPart = 'ACDF/X (No Cam 2 Up)';
      break;
    case PartType.ACDF_X_NO_CAM_TWO_DOWN:
      formattedPart = 'ACDF/X (No Cam 2 Down)';
      break;
    case PartType.ACDF_X_NO_CAM_LEFT_UP:
      formattedPart = 'ACDF/X (No Cam Left Up)';
      break;
    case PartType.ACDF_X_NO_CAM_LEFT_DOWN:
      formattedPart = 'ACDF/X (No Cam Left Down)';
      break;
    case PartType.ALIF:
      formattedPart = 'ALIF';
      break;
    case PartType.ALIF_X_TWO_UP:
      formattedPart = 'ALIF/X (2 UP/1 DOWN)';
      break;
    case PartType.ALIF_X_TWO_DOWN:
      formattedPart = 'ALIF/X (1 UP/2 DOWN)';
      break;
    case PartType.LLIF_RIGHT:
      formattedPart = 'LLIF (Right)';
      break;
    case PartType.LLIF_LEFT:
      formattedPart = 'LLIF (Left)';
      break;
    case PartType.TLIFO_RIGHT:
      formattedPart = 'TLIF/O (Right)';
      break;
    case PartType.TLIFO_LEFT:
      formattedPart = 'TLIF/O (Left)';
      break;
    case PartType.TLIFC_INLINE_RIGHT:
      formattedPart = 'TLIF/C Inline (Right)';
      break;
    case PartType.TLIFC_INLINE_LEFT:
      formattedPart = 'TLIF/C Inline (Left)';
      break;
    case PartType.TLIFC_OFFSET_RIGHT:
      formattedPart = 'TLIF/C Offset (Right)';
      break;
    case PartType.TLIFC_OFFSET_LEFT:
      formattedPart = 'TLIF/C Offset (Left)';
      break;
    case PartType.TLIFCA_INLINE_RIGHT:
      formattedPart = 'TLIF/CA Inline (Right)';
      break;
    case PartType.TLIFCA_INLINE_LEFT:
      formattedPart = 'TLIF/CA Inline (Left)';
      break;
    case PartType.TLIFCA_OFFSET_RIGHT:
      formattedPart = 'TLIF/CA Offset (Right)';
      break;
    case PartType.TLIFCA_OFFSET_LEFT:
      formattedPart = 'TLIF/CA Offset (Left)';
      break;
  }

  return formattedPart;
};

export const formatInterbodyLevelSize = (size: LevelSize): string => {
  let formattedSize = 'None';

  switch (size) {
    case LevelSize.Normal:
      formattedSize = 'Plan';
      break;
    case LevelSize.Plus:
      formattedSize = 'Plus';
      break;
    case LevelSize.Minus:
      formattedSize = 'Minus';
      break;
  }

  return formattedSize;
};

export const formatInterbodyLevelResult = (result: LevelResult): string => {
  let formattedResult = 'None';

  switch (result) {
    case LevelResult.Used:
      formattedResult = 'Used';
      break;
    case LevelResult.NotUsed:
      formattedResult = 'Not Used';
      break;
    case LevelResult.NotUsedBillable:
      formattedResult = 'Not Used (Billable)';
      break;
    case LevelResult.Exception:
      formattedResult = 'Exception';
      break;
  }

  return formattedResult;
};

export const formatInterbodyLevel = (level: LevelType): string => {
  let formattedLevel = 'None';

  switch (level) {
    case LevelType.C2C3:
      formattedLevel = 'C2/C3';
      break;
    case LevelType.C3C4:
      formattedLevel = 'C3/C4';
      break;
    case LevelType.C4C5:
      formattedLevel = 'C4/C5';
      break;
    case LevelType.C5C6:
      formattedLevel = 'C5/C6';
      break;
    case LevelType.C6C7:
      formattedLevel = 'C6/C7';
      break;
    case LevelType.C7T1:
      formattedLevel = 'C7/T1';
      break;
    case LevelType.C6T1:
      formattedLevel = 'C6/T1';
      break;
    case LevelType.C7C8:
      formattedLevel = 'C7/C8';
      break;
    case LevelType.C8T1:
      formattedLevel = 'C8/T1';
      break;
    case LevelType.L5L6:
      formattedLevel = 'L5/L6';
      break;
    case LevelType.L6S1:
      formattedLevel = 'L6/S1';
      break;
    case LevelType.L4S1:
      formattedLevel = 'L4/S1';
      break;
    case LevelType.L5S1:
      formattedLevel = 'L5/S1';
      break;
    case LevelType.L4L5:
      formattedLevel = 'L4/L5';
      break;
    case LevelType.L3L4:
      formattedLevel = 'L3/L4';
      break;
    case LevelType.L2L3:
      formattedLevel = 'L2/L3';
      break;
    case LevelType.L1L2:
      formattedLevel = 'L1/L2';
      break;
  }

  return formattedLevel;
};

export const formatUserRole = (role?: UserRoleType) => {
  switch (role) {
    case UserRoleType.SiteAdministrator:
      return 'Site Administrator';
    case UserRoleType.Surgeon:
      return 'Surgeon';
    case UserRoleType.CaseEngineer:
      return 'Designer';
    case UserRoleType.CaseAdmin:
      return 'Case Administrator';
    case UserRoleType.Operations:
      return 'Operations';
    case UserRoleType.QualityEngineer:
      return 'Quality Engineer';
    case UserRoleType.System:
      return 'System';
    case UserRoleType.FieldRep:
      return 'Sales Leader';
    case UserRoleType.FieldRepManager:
      return 'Sales Director';
    case UserRoleType.CustomerRep:
      return 'CSS';
    case UserRoleType.CustomerRepManager:
      return 'CSS Manager';
    case UserRoleType.SalesRep:
      return 'Sales Agent';
    case UserRoleType.SegmentationEngineer:
      return 'Segmentation Engineer';
    case UserRoleType.Viewer:
      return 'Viewer';
    case UserRoleType.Finance:
      return 'Finance';
    case UserRoleType.SupplyChain:
      return 'Supply Chain';
    case UserRoleType.Vendor:
      return 'Vendor';
    case UserRoleType.Demo:
      return 'Demo';
    case UserRoleType.Clinical:
      return 'Clinical';
    default:
      return 'Unknown';
  }
};

export const formatUserStatus = (status?: UserStatusType) => {
  switch (status) {
    case UserStatusType.Active:
      return 'Active';
    case UserStatusType.Pending:
      return 'Pending';
    case UserStatusType.Inactive:
      return 'Inactive';
    default:
      return 'Unknown';
  }
};

export const formatPhoneNumber = (phoneNumber?: string) => {
  if (!phoneNumber) return null;

  try {
    const parsedPhoneNumber = parsePhoneNumber(phoneNumber, 'US');
    return parsedPhoneNumber.formatNational();
  } catch (e) {
    console.error(`Error formatting the phone number ${phoneNumber}`);
    return '';
  }
};

export const formatISODate = (value: string, format = 'yyyy-MM-dd', zone = 'utc'): string => {
  try {
    if (!value) {
      return '';
    }

    const dateToFormat = DateTime.fromISO(value, { zone });

    return dateToFormat.toFormat(format);
  } catch (e) {
    return '';
  }
};

export const formatEventType = (eventType: EventType): string => {
  switch (eventType) {
    case EventType.PurchaseOrderSent:
      return 'Manufacturing Purchase Order Sent';
    case EventType.MasterBuildRecordSent:
      return 'Master Build Record Sent';
    case EventType.PurchaseOrderPackagingSent:
      return 'Packaging Purchase Order Sent';
    case EventType.BuildPlateConfirmationComplete:
      return 'Build Plate Confirmation Complete';
    case EventType.AdditivePrintingComplete:
      return '3-D Printing Complete';
    case EventType.HipComplete:
      return 'HIP Complete';
    case EventType.PostHipInspectionComplete:
      return 'Post-HIP Inspection and Processing Complete';
    case EventType.HeatTreatComplete:
      return 'Heat Treat Complete';
    case EventType.PostHeatTreatInspectionComplete:
      return 'Post Heat Treat Inspection and Processing Complete';
    case EventType.PostHipInspectionDeliveryComplete:
      return 'Post-Processing Inspection Delivery Complete';
    case EventType.NitricPassivationComplete:
      return 'Nitric Passivation Complete';
    case EventType.CleaningAndPackagingComplete:
      return 'Cleaning and Packaging Complete';
    case EventType.SterilizationComplete:
      return 'Sterilization Complete';
    case EventType.FinalReleaseComplete:
      return 'Final Release Complete';
    case EventType.HospitalDeliveryComplete:
      return 'Hospital Delivery Complete';
    case EventType.Exception:
      return 'Exception';
    case EventType.DHRReviewRequestApproved:
      return 'DHR Review Request Approved';
    case EventType.DHRReviewRequestRejected:
      return 'DHR Review Request Rejected';
    case EventType.DHRReviewRequested:
      return 'DHR Review Requested';
    default:
      return eventType;
  }
};

export const formatCarrierType = (carrierType: CarrierType): string => {
  switch (carrierType) {
    case CarrierType.FedEx:
      return 'FedEx';
    case CarrierType.FedExSameDay:
      return 'FedEx Same-Day';
    case CarrierType.Mnx:
      return 'MNX';
    case CarrierType.Other:
      return 'Other';
    default:
      return carrierType;
  }
};

export const formatCarrierTrackingUrl = (
  carrierType: CarrierType,
  trackingNumber: string,
): string => {
  switch (carrierType) {
    case CarrierType.FedEx:
      return `https://www.fedex.com/fedextrack/index.html?tracknumbers=${trackingNumber}&cntry_code=us`;
    case CarrierType.FedExSameDay:
      return `https://www.fedexsameday.com/fdx_dotracking_ua.aspx?tracknum=${trackingNumber}`;
    case CarrierType.Mnx:
      return `https://mxweb.mnx.com/TrackOrder.aspx?TrackingNumber=${trackingNumber}`;
    default:
      return '';
  }
};

export const formatPriceLevelType = (priceLevelType: PriceLevelType): string => {
  switch (priceLevelType) {
    case PriceLevelType.PerImplant:
      return 'Per-Implant';
    case PriceLevelType.Fixed:
      return 'Fixed';
    case PriceLevelType.BasePlusLevel:
      return 'Base plus level';
  }
};

export const formatAssetType = (assetType?: AssetType): string => {
  switch (assetType) {
    case AssetType.T1:
      return 'T1';
    case AssetType.C8:
      return 'C8';
    case AssetType.C7:
      return 'C7';
    case AssetType.C6:
      return 'C6';
    case AssetType.C5:
      return 'C5';
    case AssetType.C4:
      return 'C4';
    case AssetType.C3:
      return 'C3';
    case AssetType.C2:
      return 'C2';
    case AssetType.T1Edited:
      return 'T1 (Edited)';
    case AssetType.C8Edited:
      return 'C8 (Edited)';
    case AssetType.C7Edited:
      return 'C7 (Edited)';
    case AssetType.C6Edited:
      return 'C6 (Edited)';
    case AssetType.C5Edited:
      return 'C5 (Edited)';
    case AssetType.C4Edited:
      return 'C4 (Edited)';
    case AssetType.C3Edited:
      return 'C3 (Edited)';
    case AssetType.C2Edited:
      return 'C2 (Edited)';
    case AssetType.Pelvis:
      return 'Pelvis';
    case AssetType.S1:
      return 'S1';
    case AssetType.L6:
      return 'L6';
    case AssetType.L5:
      return 'L5';
    case AssetType.L4:
      return 'L4';
    case AssetType.L3:
      return 'L3';
    case AssetType.L2:
      return 'L2';
    case AssetType.L1:
      return 'L1';
    case AssetType.S1Edited:
      return 'S1 (Edited)';
    case AssetType.L6Edited:
      return 'L6 (Edited)';
    case AssetType.L5Edited:
      return 'L5 (Edited)';
    case AssetType.L4Edited:
      return 'L4 (Edited)';
    case AssetType.L3Edited:
      return 'L3 (Edited)';
    case AssetType.L2Edited:
      return 'L2 (Edited)';
    case AssetType.L1Edited:
      return 'L1 (Edited)';
    case AssetType.HardwarePreop:
      return 'Hardware (Pre-op)';
    case AssetType.HardwareDaisy:
      return 'Hardware (Daisy)';
    case AssetType.S1Daisy:
      return 'S1 (Daisy)';
    case AssetType.L6Daisy:
      return 'L6 (Daisy)';
    case AssetType.L5Daisy:
      return 'L5 (Daisy)';
    case AssetType.L4Daisy:
      return 'L4 (Daisy)';
    case AssetType.L3Daisy:
      return 'L3 (Daisy)';
    case AssetType.L2Daisy:
      return 'L2 (Daisy)';
    case AssetType.L1Daisy:
      return 'L1 (Daisy)';
    case AssetType.L6S1:
      return 'L6/S1';
    case AssetType.L4S1:
      return 'L4/S1';
    case AssetType.L5L6:
      return 'L5/L6';
    case AssetType.L5S1:
      return 'L5/S1';
    case AssetType.L4L5:
      return 'L4/L5';
    case AssetType.L3L4:
      return 'L3/L4';
    case AssetType.L2L3:
      return 'L2/L3';
    case AssetType.L1L2:
      return 'L1/L2';
    case AssetType.L6S1CyborgImplant:
      return 'L6/S1 Cyborg Implant';
    case AssetType.L4S1CyborgImplant:
      return 'L4/S1 Cyborg Implant';
    case AssetType.L5L6CyborgImplant:
      return 'L5/L6 Cyborg Implant';
    case AssetType.L5S1CyborgImplant:
      return 'L5/S1 Cyborg Implant';
    case AssetType.L4L5CyborgImplant:
      return 'L4/L5 Cyborg Implant';
    case AssetType.L3L4CyborgImplant:
      return 'L3/L4 Cyborg Implant';
    case AssetType.L2L3CyborgImplant:
      return 'L2/L3 Cyborg Implant';
    case AssetType.L1L2CyborgImplant:
      return 'L1/L2 Cyborg Implant';
    case AssetType.L6S1ImplantScrewGuide:
      return 'L6/S1 Implant Screw Guide';
    case AssetType.L4S1ImplantScrewGuide:
      return 'L4/S1 Implant Screw Guide';
    case AssetType.L5L6ImplantScrewGuide:
      return 'L5/L6 Implant Screw Guide';
    case AssetType.L5S1ImplantScrewGuide:
      return 'L5/S1 Implant Screw Guide';
    case AssetType.L4L5ImplantScrewGuide:
      return 'L4/L5 Implant Screw Guide';
    case AssetType.L3L4ImplantScrewGuide:
      return 'L3/L4 Implant Screw Guide';
    case AssetType.L2L3ImplantScrewGuide:
      return 'L2/L3 Implant Screw Guide';
    case AssetType.L1L2ImplantScrewGuide:
      return 'L1/L2 Implant Screw Guide';
    case AssetType.L6S1ImplantInstrument:
      return 'L6/S1 Implant Instrument';
    case AssetType.L4S1ImplantInstrument:
      return 'L4/S1 Implant Instrument';
    case AssetType.L5L6ImplantInstrument:
      return 'L5/L6 Implant Instrument';
    case AssetType.L5S1ImplantInstrument:
      return 'L5/S1 Implant Instrument';
    case AssetType.L4L5ImplantInstrument:
      return 'L4/L5 Implant Instrument';
    case AssetType.L3L4ImplantInstrument:
      return 'L3/L4 Implant Instrument';
    case AssetType.L2L3ImplantInstrument:
      return 'L2/L3 Implant Instrument';
    case AssetType.L1L2ImplantInstrument:
      return 'L1/L2 Implant Instrument';
    case AssetType.L6S1ImplantScrew:
      return 'L6/S1 Implant Screw';
    case AssetType.L4S1ImplantScrew:
      return 'L4/S1 Implant Screw';
    case AssetType.L5L6ImplantScrew:
      return 'L5/L6 Implant Screw';
    case AssetType.L5S1ImplantScrew:
      return 'L5/S1 Implant Screw';
    case AssetType.L4L5ImplantScrew:
      return 'L4/L5 Implant Screw';
    case AssetType.L3L4ImplantScrew:
      return 'L3/L4 Implant Screw';
    case AssetType.L2L3ImplantScrew:
      return 'L2/L3 Implant Screw';
    case AssetType.L1L2ImplantScrew:
      return 'L1/L2 Implant Screw';
    case AssetType.L5L6NTopCli:
      return 'L5/L6 (nTop CLI)';
    case AssetType.L6S1NTopCli:
      return 'L6/S1 (nTop CLI)';
    case AssetType.L4S1NTopCli:
      return 'L4/S1 (nTop CLI)';
    case AssetType.L5S1NTopCli:
      return 'L5/S1 (nTop CLI)';
    case AssetType.L4L5NTopCli:
      return 'L4/L5 (nTop CLI)';
    case AssetType.L3L4NTopCli:
      return 'L3/L4 (nTop CLI)';
    case AssetType.L2L3NTopCli:
      return 'L2/L3 (nTop CLI)';
    case AssetType.L1L2NTopCli:
      return 'L1/L2 (nTop CLI)';
    case AssetType.L5L6PlusNTopCli:
      return 'L5/L6 Plus (nTop CLI)';
    case AssetType.L6S1PlusNTopCli:
      return 'L6/S1 Plus (nTop CLI)';
    case AssetType.L4S1PlusNTopCli:
      return 'L4/S1 Plus (nTop CLI)';
    case AssetType.L5S1PlusNTopCli:
      return 'L5/S1 Plus (nTop CLI)';
    case AssetType.L4L5PlusNTopCli:
      return 'L4/L5 Plus (nTop CLI)';
    case AssetType.L3L4PlusNTopCli:
      return 'L3/L4 Plus (nTop CLI)';
    case AssetType.L2L3PlusNTopCli:
      return 'L2/L3 Plus (nTop CLI)';
    case AssetType.L1L2PlusNTopCli:
      return 'L1/L2 Plus (nTop CLI)';
    case AssetType.L6S1Data:
      return 'L6/S1 Data';
    case AssetType.L4S1Data:
      return 'L4/S1 Data';
    case AssetType.L5L6Data:
      return 'L5/L6 Data';
    case AssetType.L5S1Data:
      return 'L5/S1 Data';
    case AssetType.L4L5Data:
      return 'L4/L5 Data';
    case AssetType.L3L4Data:
      return 'L3/L4 Data';
    case AssetType.L2L3Data:
      return 'L2/L3 Data';
    case AssetType.L1L2Data:
      return 'L1/L2 Data';
    case AssetType.L6S1Plus:
      return 'L6/S1 Plus';
    case AssetType.L4S1Plus:
      return 'L4/S1 Plus';
    case AssetType.L5L6Plus:
      return 'L5/L6 Plus';
    case AssetType.L5S1Plus:
      return 'L5/S1 Plus';
    case AssetType.L4L5Plus:
      return 'L4/L5 Plus';
    case AssetType.L3L4Plus:
      return 'L3/L4 Plus';
    case AssetType.L2L3Plus:
      return 'L2/L3 Plus';
    case AssetType.L1L2Plus:
      return 'L1/L2 Plus';
    case AssetType.L6S1Minus:
      return 'L6/S1 Minus';
    case AssetType.L4S1Minus:
      return 'L4/S1 Minus';
    case AssetType.L5L6Minus:
      return 'L5/L6 Minus';
    case AssetType.L5S1Minus:
      return 'L5/S1 Minus';
    case AssetType.L4L5Minus:
      return 'L4/L5 Minus';
    case AssetType.L3L4Minus:
      return 'L3/L4 Minus';
    case AssetType.L2L3Minus:
      return 'L2/L3 Minus';
    case AssetType.L1L2Minus:
      return 'L1/L2 Minus';
    case AssetType.L6S1Log:
      return 'L6/S1 Log';
    case AssetType.L4S1Log:
      return 'L4/S1 Log';
    case AssetType.L5L6Log:
      return 'L5/L6 Log';
    case AssetType.L5S1Log:
      return 'L5/S1 Log';
    case AssetType.L4L5Log:
      return 'L4/L5 Log';
    case AssetType.L3L4Log:
      return 'L3/L4 Log';
    case AssetType.L2L3Log:
      return 'L2/L3 Log';
    case AssetType.L1L2Log:
      return 'L1/L2 Log';
    case AssetType.L6S1PlusLog:
      return 'L6/S1 Plus Log';
    case AssetType.L4S1PlusLog:
      return 'L4/S1 Plus Log';
    case AssetType.L5L6PlusLog:
      return 'L5/L6 Plus Log';
    case AssetType.L5S1PlusLog:
      return 'L5/S1 Plus Log';
    case AssetType.L4L5PlusLog:
      return 'L4/L5 Plus Log';
    case AssetType.L3L4PlusLog:
      return 'L3/L4 Plus Log';
    case AssetType.L2L3PlusLog:
      return 'L2/L3 Plus Log';
    case AssetType.L1L2PlusLog:
      return 'L1/L2 Plus Log';
    case AssetType.L6S1MinusLog:
      return 'L6/S1 Minus Log';
    case AssetType.L4S1MinusLog:
      return 'L4/S1 Minus Log';
    case AssetType.L5L6MinusLog:
      return 'L5/L6 Minus Log';
    case AssetType.L5S1MinusLog:
      return 'L5/S1 Minus Log';
    case AssetType.L4L5MinusLog:
      return 'L4/L5 Minus Log';
    case AssetType.L3L4MinusLog:
      return 'L3/L4 Minus Log';
    case AssetType.L2L3MinusLog:
      return 'L2/L3 Minus Log';
    case AssetType.L1L2MinusLog:
      return 'L1/L2 Minus Log';
    case AssetType.L5S1MinusNTopCli:
      return 'L5/S1 Minus (nTop CLI)';
    case AssetType.L4L5MinusNTopCli:
      return 'L4/L5 Minus (nTop CLI)';
    case AssetType.L3L4MinusNTopCli:
      return 'L3/L4 Minus (nTop CLI)';
    case AssetType.L2L3MinusNTopCli:
      return 'L2/L3 Minus (nTop CLI)';
    case AssetType.L1L2MinusNTopCli:
      return 'L1/L2 Minus (nTop CLI)';
    case AssetType.RodTemplateLeft:
      return 'Rod Template (Left)';
    case AssetType.RodTemplateRight:
      return 'Rod Template (Right)';
    case AssetType.DicomCt:
      return 'DICOM CT';
    case AssetType.DicomXray:
      return 'DICOM X-ray';
    case AssetType.DicomMri:
      return 'DICOM MRI';
    case AssetType.Form19ApprovalSignature:
      return 'Surgeon Approval Signature';
    case AssetType.Form19:
      return 'Form 19';
    case AssetType.Form18:
      return 'Form 18';
    case AssetType.Tem013:
      return 'TEM-013';
    case AssetType.S1PreopApp:
      return 'S1 (Preop) App';
    case AssetType.L6PreopApp:
      return 'L6 (Preop) App';
    case AssetType.L5PreopApp:
      return 'L5 (Preop) App';
    case AssetType.L4PreopApp:
      return 'L4 (Preop) App';
    case AssetType.L3PreopApp:
      return 'L3 (Preop) App';
    case AssetType.L2PreopApp:
      return 'L2 (Preop) App';
    case AssetType.L1PreopApp:
      return 'L1 (Preop) App';
    case AssetType.S1PlanApp:
      return 'S1 (Plan) App';
    case AssetType.L6PlanApp:
      return 'L6 (Plan) App';
    case AssetType.L5PlanApp:
      return 'L5 (Plan) App';
    case AssetType.L4PlanApp:
      return 'L4 (Plan) App';
    case AssetType.L3PlanApp:
      return 'L3 (Plan) App';
    case AssetType.L2PlanApp:
      return 'L2 (Plan) App';
    case AssetType.L1PlanApp:
      return 'L1 (Plan) App';
    case AssetType.L6S1App:
      return 'L6/S1 App';
    case AssetType.L4S1App:
      return 'L4/S1 App';
    case AssetType.L5L6App:
      return 'L5/L6 App';
    case AssetType.L5S1App:
      return 'L5/S1 App';
    case AssetType.L4L5App:
      return 'L4/L5 App';
    case AssetType.L3L4App:
      return 'L3/L4 App';
    case AssetType.L2L3App:
      return 'L2/L3 App';
    case AssetType.L1L2App:
      return 'L1/L2 App';
    case AssetType.L6S1AppMinus:
      return 'L6/S1 App (Minus)';
    case AssetType.L4S1AppMinus:
      return 'L4/S1 App (Minus)';
    case AssetType.L5L6AppMinus:
      return 'L5/L6 App (Minus)';
    case AssetType.L5S1AppMinus:
      return 'L5/S1 App (Minus)';
    case AssetType.L4L5AppMinus:
      return 'L4/L5 App (Minus)';
    case AssetType.L3L4AppMinus:
      return 'L3/L4 App (Minus)';
    case AssetType.L2L3AppMinus:
      return 'L2/L3 App (Minus)';
    case AssetType.L1L2AppMinus:
      return 'L1/L2 App (Minus)';
    case AssetType.PlanSummaryApp:
      return 'Plan Summary App';
    case AssetType.PlanSummaryFormApp:
      return 'Plan Summary Form App';
    case AssetType.ParasolidExport:
      return 'Parasolid Export';
    case AssetType.PostOpOneYear:
      return 'Post-Op One Year';
    case AssetType.PostOpTwoYear:
      return 'Post-Op Two Year';
    case AssetType.PostOpSixWeeks:
      return 'Post-Op Six Weeks';
    case AssetType.PostOpSixMonths:
      return 'Post-Op Six Months';
    case AssetType.PostOpIntraOpXray:
      return 'Post-Op Intra-op X-Ray';
    case AssetType.PostOpSixWeeksXray:
      return 'Post-Op Six Weeks DICOM X-Ray';
    case AssetType.PostOpSixMonthsXray:
      return 'Post-Op Six Months DICOM X-Ray';
    case AssetType.PostOpOneYearXray:
      return 'Post-Op One Year DICOM X-Ray';
    case AssetType.PostOpTwoYearXray:
      return 'Post-Op Two Year DICOM X-Ray';
    case AssetType.PostOpSixWeeksCt:
      return 'Post-Op Six Weeks DICOM Ct';
    case AssetType.PostOpSixMonthsCt:
      return 'Post-Op Six Months DICOM Ct';
    case AssetType.PostOpOneYearCt:
      return 'Post-Op One Year DICOM Ct';
    case AssetType.PostOpTwoYearCt:
      return 'Post-Op Two Year DICOM Ct';
    case AssetType.PostOpSixWeeksMri:
      return 'Post-Op Six Weeks DICOM Mri';
    case AssetType.PostOpSixMonthsMri:
      return 'Post-Op Six Months DICOM Mri';
    case AssetType.PostOpOneYearMri:
      return 'Post-Op One Year DICOM Mri';
    case AssetType.PostOpTwoYearMri:
      return 'Post-Op Two Year DICOM Mri';
    case AssetType.PostOpIntraOp:
      return 'Post-Op Intra-Op X-Ray';
    case AssetType.StandingXrayAP:
      return 'Standing X-Ray (AP View)';
    case AssetType.StandingXrayLateral:
      return 'Standing X-Ray (Lateral View)';
    case AssetType.PostOpIntraOpStandingXrayAp:
      return 'Post-Op Intra-Op Standing X-Ray (AP View)';
    case AssetType.PostOpIntraOpStandingXrayLateral:
      return 'Post-Op Intra-Op Standing X-Ray (Lateral View)';
    case AssetType.PostOpSixWeeksStandingXrayAp:
      return 'Post-Op Six Weeks Standing X-Ray (AP View)';
    case AssetType.PostOpSixWeeksStandingXrayLateral:
      return 'Post-Op Six Weeks Standing X-Ray (Lateral View)';
    case AssetType.PostOpSixMonthsStandingXrayAp:
      return 'Post-Op Six Months Standing X-Ray (AP View)';
    case AssetType.PostOpSixMonthsStandingXrayLateral:
      return 'Post-Op Six Months Standing X-Ray (Lateral View)';
    case AssetType.PostOpOneYearStandingXrayAp:
      return 'Post-Op One Year Standing X-Ray (AP View)';
    case AssetType.PostOpOneYearStandingXrayLateral:
      return 'Post-Op One Year Standing X-Ray (Lateral View)';
    case AssetType.PostOpTwoYearStandingXrayAp:
      return 'Post-Op Two Year Standing X-Ray (AP View)';
    case AssetType.PostOpTwoYearStandingXrayLateral:
      return 'Post-Op Two Year Standing X-Ray (Lateral View)';
    case AssetType.L6S1ImplantMeasurementImage:
      return 'L6/S1 Implant Measurement Image';
    case AssetType.L4S1ImplantMeasurementImage:
      return 'L4/S1 Implant Measurement Image';
    case AssetType.L5L6ImplantMeasurementImage:
      return 'L5/L6 Implant Measurement Image';
    case AssetType.L5S1ImplantMeasurementImage:
      return 'L5/S1 Implant Measurement Image';
    case AssetType.L4L5ImplantMeasurementImage:
      return 'L4/L5 Implant Measurement Image';
    case AssetType.L3L4ImplantMeasurementImage:
      return 'L3/L4 Implant Measurement Image';
    case AssetType.L2L3ImplantMeasurementImage:
      return 'L2/L3 Implant Measurement Image';
    case AssetType.L1L2ImplantMeasurementImage:
      return 'L1/L2 Implant Measurement Image';
    case AssetType.PurchaseOrder:
      return 'Manufacturing Purchase Order';
    case AssetType.PurchaseOrderPackaging:
      return 'Packaging Purchase Order';
    case AssetType.DHR:
      return 'DHR';
    case AssetType.SignedDHR:
      return 'Signed DHR';
    case AssetType.BookingSheet:
      return 'Booking Sheet';
    case AssetType.MasterBuildRecord:
      return 'Master Build Record';
    case AssetType.DicomCtDaisy:
      return 'DICOM CT Daisy';
    case AssetType.L5L6Dimensions:
      return 'L5/L6 Dimensions';
    case AssetType.L6S1Dimensions:
      return 'L6/S1 Dimensions';
    case AssetType.L4S1Dimensions:
      return 'L4/S1 Dimensions';
    case AssetType.L5S1Dimensions:
      return 'L5/S1 Dimensions';
    case AssetType.L4L5Dimensions:
      return 'L4/L5 Dimensions';
    case AssetType.L3L4Dimensions:
      return 'L3/L4 Dimensions';
    case AssetType.L2L3Dimensions:
      return 'L2/L3 Dimensions';
    case AssetType.L1L2Dimensions:
      return 'L1/L2 Dimensions';
    case AssetType.L5L6AppDimensions:
      return 'L5/L6 Dimensions App';
    case AssetType.L6S1AppDimensions:
      return 'L6/S1 Dimensions App';
    case AssetType.L4S1AppDimensions:
      return 'L4/S1 Dimensions App';
    case AssetType.L5S1AppDimensions:
      return 'L5/S1 Dimensions App';
    case AssetType.L4L5AppDimensions:
      return 'L4/L5 Dimensions App';
    case AssetType.L3L4AppDimensions:
      return 'L3/L4 Dimensions App';
    case AssetType.L2L3AppDimensions:
      return 'L2/L3 Dimensions App';
    case AssetType.L1L2AppDimensions:
      return 'L1/L2 Dimensions App';
    case AssetType.L5L6PlusDimensions:
      return 'L5/L6 Dimensions (Plus)';
    case AssetType.L6S1PlusDimensions:
      return 'L6/S1 Dimensions (Plus)';
    case AssetType.L4S1PlusDimensions:
      return 'L4/S1 Dimensions (Plus)';
    case AssetType.L5S1PlusDimensions:
      return 'L5/S1 Dimensions (Plus)';
    case AssetType.L4L5PlusDimensions:
      return 'L4/L5 Dimensions (Plus)';
    case AssetType.L3L4PlusDimensions:
      return 'L3/L4 Dimensions (Plus)';
    case AssetType.L2L3PlusDimensions:
      return 'L2/L3 Dimensions (Plus)';
    case AssetType.L1L2PlusDimensions:
      return 'L1/L2 Dimensions (Plus)';
    case AssetType.L5L6MinusDimensions:
      return 'L5/L6 Dimensions (Minus)';
    case AssetType.L6S1MinusDimensions:
      return 'L6/S1 Dimensions (Minus)';
    case AssetType.L4S1MinusDimensions:
      return 'L4/S1 Dimensions (Minus)';
    case AssetType.L5S1MinusDimensions:
      return 'L5/S1 Dimensions (Minus)';
    case AssetType.L4L5MinusDimensions:
      return 'L4/L5 Dimensions (Minus)';
    case AssetType.L3L4MinusDimensions:
      return 'L3/L4 Dimensions (Minus)';
    case AssetType.L2L3MinusDimensions:
      return 'L2/L3 Dimensions (Minus)';
    case AssetType.L1L2MinusDimensions:
      return 'L1/L2 Dimensions (Minus)';
    case AssetType.L5L6Metadata:
      return 'L5/L6 Metadata';
    case AssetType.L6S1Metadata:
      return 'L6/S1 Metadata';
    case AssetType.L4S1Metadata:
      return 'L4/S1 Metadata';
    case AssetType.L5S1Metadata:
      return 'L5/S1 Metadata';
    case AssetType.L4L5Metadata:
      return 'L4/L5 Metadata';
    case AssetType.L3L4Metadata:
      return 'L3/L4 Metadata';
    case AssetType.L2L3Metadata:
      return 'L2/L3 Metadata';
    case AssetType.L1L2Metadata:
      return 'L1/L2 Metadata';
    case AssetType.L5L6AppMetadata:
      return 'L5/L6 Metadata App';
    case AssetType.L6S1AppMetadata:
      return 'L6/S1 Metadata App';
    case AssetType.L4S1AppMetadata:
      return 'L4/S1 Metadata App';
    case AssetType.L5S1AppMetadata:
      return 'L5/S1 Metadata App';
    case AssetType.L4L5AppMetadata:
      return 'L4/L5 Metadata App';
    case AssetType.L3L4AppMetadata:
      return 'L3/L4 Metadata App';
    case AssetType.L2L3AppMetadata:
      return 'L2/L3 Metadata App';
    case AssetType.L1L2AppMetadata:
      return 'L1/L2 Metadata App';
    case AssetType.L5L6PlusMetadata:
      return 'L5/L6 Metadata (Plus)';
    case AssetType.L6S1PlusMetadata:
      return 'L6/S1 Metadata (Plus)';
    case AssetType.L4S1PlusMetadata:
      return 'L4/S1 Metadata (Plus)';
    case AssetType.L5S1PlusMetadata:
      return 'L5/S1 Metadata (Plus)';
    case AssetType.L4L5PlusMetadata:
      return 'L4/L5 Metadata (Plus)';
    case AssetType.L3L4PlusMetadata:
      return 'L3/L4 Metadata (Plus)';
    case AssetType.L2L3PlusMetadata:
      return 'L2/L3 Metadata (Plus)';
    case AssetType.L1L2PlusMetadata:
      return 'L1/L2 Metadata (Plus)';
    case AssetType.L5L6MinusMetadata:
      return 'L5/L6 Metadata (Minus)';
    case AssetType.L6S1MinusMetadata:
      return 'L6/S1 Metadata (Minus)';
    case AssetType.L4S1MinusMetadata:
      return 'L4/S1 Metadata (Minus)';
    case AssetType.L5S1MinusMetadata:
      return 'L5/S1 Metadata (Minus)';
    case AssetType.L4L5MinusMetadata:
      return 'L4/L5 Metadata (Minus)';
    case AssetType.L3L4MinusMetadata:
      return 'L3/L4 Metadata (Minus)';
    case AssetType.L2L3MinusMetadata:
      return 'L2/L3 Metadata (Minus)';
    case AssetType.L1L2MinusMetadata:
      return 'L1/L2 Metadata (Minus)';
    case AssetType.L5L6AppLog:
      return 'L5/L6 App Log';
    case AssetType.L6S1AppLog:
      return 'L6/S1 App Log';
    case AssetType.L4S1AppLog:
      return 'L4/S1 App Log';
    case AssetType.L5S1AppLog:
      return 'L5/S1 App Log';
    case AssetType.L4L5AppLog:
      return 'L4/L5 App Log';
    case AssetType.L3L4AppLog:
      return 'L3/L4 App Log';
    case AssetType.L2L3AppLog:
      return 'L2/L3 App Log';
    case AssetType.L1L2AppLog:
      return 'L1/L2 App Log';
    case AssetType.L5L6MinusNTopCli:
      return 'L5/L6 Minus (nTop CLI)';
    case AssetType.L6S1MinusNTopCli:
      return 'L6/S1 Minus (nTop CLI)';
    case AssetType.L4S1MinusNTopCli:
      return 'L4/S1 Minus (nTop CLI)';
    case AssetType.Form19L5L6AdditionalImage:
      return 'Form 19 L5/L6 Additional Image';
    case AssetType.Form19L6S1NAdditionalImage:
      return 'Form 19 L6/S1 Additional Image';
    case AssetType.Form19L4S1AdditionalImage:
      return 'Form 19 L4/S1 Additional Image';
    case AssetType.Form19L5S1AdditionalImage:
      return 'Form 19 L5/S1 Additional Image';
    case AssetType.Form19L4L5AdditionalImage:
      return 'Form 19 L4/L5 Additional Image';
    case AssetType.Form19L3L4AdditionalImage:
      return 'Form 19 L3/L4 Additional Image';
    case AssetType.Form19L2L3AdditionalImage:
      return 'Form 19 L2/L3 Additional Image';
    case AssetType.Form19L1L2AdditionalImage:
      return 'Form 19 L1/L2 Additional Image';
    case AssetType.Form19MeasurementsReference:
      return 'Form 19 Measurements Reference';
    case AssetType.CloudDesignLevelExport:
      return 'Cloud Design Level Export';
    case AssetType.PlanCoronalImage:
      return 'Plan Coronal Image';
    case AssetType.PlanLateralImage:
      return 'Plan Lateral Image';
    case AssetType.PreopCoronalImage:
      return 'Preop Coronal Image';
    case AssetType.PreopLateralImage:
      return 'Preop Lateral Image';
    case AssetType.CaseReportVertebraePreEditImage:
      return 'Vertebrae Pre Edit Image';
    case AssetType.CaseReportVertebraePostEditImage:
      return 'Vertebrae Post Edit Image';
    case AssetType.CaseReportImplantPreEditImage:
      return 'Implant Pre Edit Image';
    case AssetType.CaseReportImplantPostEditImage:
      return 'Implant Post Edit Image';
    case AssetType.CaseReportStandingXrayLateralMeasured:
      return 'Standing X-Ray (Lateral View) Measured';
    case AssetType.CaseReportL5L6ImplantAppImage:
      return 'App Image';
    case AssetType.CaseReportL5L6ImplantPositionImage:
      return 'Position Image';
    case AssetType.CaseReportL5L6ImplantMiniCheckTopImage:
      return 'Mini Check Top Image';
    case AssetType.CaseReportL5L6ImplantMiniCheckBottomImage:
      return 'Mini Check Bottom Image';
    case AssetType.CaseReportL5L6ImplantScrewVerificationImage:
      return 'Screw Verification Image';
    case AssetType.CaseReportL5L6ImplantScrewAnteriorPositionImage:
      return 'Screw Anterior Position Image';
    case AssetType.CaseReportL5L6ImplantScrewLateralPositionImage:
      return 'Screw Lateral Position Image';
    case AssetType.CaseReportL6S1ImplantAppImage:
      return 'App Image';
    case AssetType.CaseReportL6S1ImplantPositionImage:
      return 'Position Image';
    case AssetType.CaseReportL6S1ImplantMiniCheckTopImage:
      return 'Mini Check Top Image';
    case AssetType.CaseReportL6S1ImplantMiniCheckBottomImage:
      return 'Mini Check Bottom Image';
    case AssetType.CaseReportL6S1ImplantScrewVerificationImage:
      return 'Screw Verification Image';
    case AssetType.CaseReportL6S1ImplantScrewAnteriorPositionImage:
      return 'Screw Anterior Position Image';
    case AssetType.CaseReportL6S1ImplantScrewLateralPositionImage:
      return 'Screw Lateral Position Image';
    case AssetType.CaseReportL4S1ImplantAppImage:
      return 'App Image';
    case AssetType.CaseReportL4S1ImplantPositionImage:
      return 'Position Image';
    case AssetType.CaseReportL4S1ImplantMiniCheckTopImage:
      return 'Mini Check Top Image';
    case AssetType.CaseReportL4S1ImplantMiniCheckBottomImage:
      return 'Mini Check Bottom Image';
    case AssetType.CaseReportL4S1ImplantScrewVerificationImage:
      return 'Screw Verification Image';
    case AssetType.CaseReportL4S1ImplantScrewAnteriorPositionImage:
      return 'Screw Anterior Position Image';
    case AssetType.CaseReportL4S1ImplantScrewLateralPositionImage:
      return 'Screw Lateral Position Image';
    case AssetType.CaseReportL5S1ImplantAppImage:
      return 'App Image';
    case AssetType.CaseReportL5S1ImplantPositionImage:
      return 'Position Image';
    case AssetType.CaseReportL5S1ImplantMiniCheckTopImage:
      return 'Mini Check Top Image';
    case AssetType.CaseReportL5S1ImplantMiniCheckBottomImage:
      return 'Mini Check Bottom Image';
    case AssetType.CaseReportL5S1ImplantScrewVerificationImage:
      return 'Screw Verification Image';
    case AssetType.CaseReportL5S1ImplantScrewAnteriorPositionImage:
      return 'Screw Anterior Position Image';
    case AssetType.CaseReportL5S1ImplantScrewLateralPositionImage:
      return 'Screw Lateral Position Image';
    case AssetType.CaseReportL4L5ImplantAppImage:
      return 'App Image';
    case AssetType.CaseReportL4L5ImplantPositionImage:
      return 'Position Image';
    case AssetType.CaseReportL4L5ImplantMiniCheckTopImage:
      return 'Mini Check Top Image';
    case AssetType.CaseReportL4L5ImplantMiniCheckBottomImage:
      return 'Mini Check Bottom Image';
    case AssetType.CaseReportL4L5ImplantScrewVerificationImage:
      return 'Screw Verification Image';
    case AssetType.CaseReportL4L5ImplantScrewAnteriorPositionImage:
      return 'Screw Anterior Position Image';
    case AssetType.CaseReportL4L5ImplantScrewLateralPositionImage:
      return 'Screw Lateral Position Image';
    case AssetType.CaseReportL3L4ImplantAppImage:
      return 'App Image';
    case AssetType.CaseReportL3L4ImplantPositionImage:
      return 'Position Image';
    case AssetType.CaseReportL3L4ImplantMiniCheckTopImage:
      return 'Mini Check Top Image';
    case AssetType.CaseReportL3L4ImplantMiniCheckBottomImage:
      return 'Mini Check Bottom Image';
    case AssetType.CaseReportL3L4ImplantScrewVerificationImage:
      return 'Screw Verification Image';
    case AssetType.CaseReportL3L4ImplantScrewAnteriorPositionImage:
      return 'Screw Anterior Position Image';
    case AssetType.CaseReportL3L4ImplantScrewLateralPositionImage:
      return 'Screw Lateral Position Image';
    case AssetType.CaseReportL2L3ImplantAppImage:
      return 'App Image';
    case AssetType.CaseReportL2L3ImplantPositionImage:
      return 'Position Image';
    case AssetType.CaseReportL2L3ImplantMiniCheckTopImage:
      return 'Mini Check Top Image';
    case AssetType.CaseReportL2L3ImplantMiniCheckBottomImage:
      return 'Mini Check Bottom Image';
    case AssetType.CaseReportL2L3ImplantScrewVerificationImage:
      return 'Screw Verification Image';
    case AssetType.CaseReportL2L3ImplantScrewAnteriorPositionImage:
      return 'Screw Anterior Position Image';
    case AssetType.CaseReportL2L3ImplantScrewLateralPositionImage:
      return 'Screw Lateral Position Image';
    case AssetType.CaseReportL1L2ImplantAppImage:
      return 'App Image';
    case AssetType.CaseReportL1L2ImplantPositionImage:
      return 'Position Image';
    case AssetType.CaseReportL1L2ImplantMiniCheckTopImage:
      return 'Mini Check Top Image';
    case AssetType.CaseReportL1L2ImplantMiniCheckBottomImage:
      return 'Mini Check Bottom Image';
    case AssetType.CaseReportL1L2ImplantScrewVerificationImage:
      return 'Screw Verification Image';
    case AssetType.CaseReportL1L2ImplantScrewAnteriorPositionImage:
      return 'Screw Anterior Position Image';
    case AssetType.CaseReportL1L2ImplantScrewLateralPositionImage:
      return 'Screw Lateral Position Image';
    default:
      return `Unknown (${assetType})`;
  }
};

export const formatCaseCancellationType = (caseCancellationType: CaseCancellationType): string => {
  switch (caseCancellationType) {
    case CaseCancellationType.Cancelled:
      return 'Cancelled';
    case CaseCancellationType.Missed:
      return 'Missed';
    case CaseCancellationType.Invalid:
      return 'Invalid';
    default:
      return caseCancellationType;
  }
};

export const formatCaseCancelledReasonType = (
  caseCancelledReasonType: CaseCancelledReasonType,
): string => {
  switch (caseCancelledReasonType) {
    case CaseCancelledReasonType.PatientOptOut:
      return 'Patient opt-out';
    case CaseCancelledReasonType.PatientHealth:
      return 'Patient health';
    case CaseCancelledReasonType.SurgeonPreference:
      return 'Surgeon preference';
    case CaseCancelledReasonType.InsuranceCoPay:
      return 'Insurance co-pay';
    case CaseCancelledReasonType.InsuranceDenial:
      return 'Insurance denial';
    default:
      return caseCancelledReasonType;
  }
};
export const formatCaseMissedReasonType = (caseMissedReasonType: CaseMissedReasonType): string => {
  switch (caseMissedReasonType) {
    case CaseMissedReasonType.InsufficientLeadTime:
      return 'Insufficient lead time';
    case CaseMissedReasonType.SurgeryMovedUp:
      return 'Surgery moved up';
    case CaseMissedReasonType.ProductionIssue:
      return 'Production issue';
    case CaseMissedReasonType.Imaging:
      return 'Imaging';
    case CaseMissedReasonType.SurgeonPreferencePreOp:
      return 'Surgeon preference pre-op';
    case CaseMissedReasonType.SurgeonPreferenceIntraOp:
      return 'Surgeon preference intra-op';
    case CaseMissedReasonType.LostDelayedShipment:
      return 'Lost / Delayed shipment';
    case CaseMissedReasonType.HospitalApproval:
      return 'Hospital approval';
    default:
      return caseMissedReasonType;
  }
};

export const formatCaseInvalidReasonType = (
  caseMissedReasonType: CaseInvalidReasonType,
): string => {
  switch (caseMissedReasonType) {
    case CaseInvalidReasonType.Duplicate:
      return 'Duplicate';
    case CaseInvalidReasonType.DataEntryError:
      return 'Data Entry Error';
    case CaseInvalidReasonType.ExpiredCT:
      return 'Expired CT';
    case CaseInvalidReasonType.ExpiredXR:
      return 'Expired XR';
    case CaseInvalidReasonType.CTExpiresBeforeSurgery:
      return 'CT Expires Before Scheduled Surgery';
    case CaseInvalidReasonType.XRExpiresBeforeSurgery:
      return 'XR Expires Before Scheduled Surgery';
    case CaseInvalidReasonType.NonDicomCT:
      return 'CT is a format other than DICOM';
    case CaseInvalidReasonType.IncompleteOrCorruptedCT:
      return 'CT is incomplete or corrupted';
    case CaseInvalidReasonType.InvalidOrMissingPatientInfo:
      return 'Invalid or missing patient information';
    case CaseInvalidReasonType.SurgeryDateTooEarlyForProd:
      return 'Surgery date too soon for production';
    case CaseInvalidReasonType.SurgeryDateTooLateForImaging:
      return 'Surgery date after study expiration';
    case CaseInvalidReasonType.LevelsUnknown:
      return 'Levels Unknown';
    case CaseInvalidReasonType.ImplantTypeUnknown:
      return 'Implant Type Unknown';
    case CaseInvalidReasonType.ApproachSideUnknown:
      return 'Approach Side Unknown';
    case CaseInvalidReasonType.NotAprevoCandidate:
      return 'Not an aprevo device candidate';
    case CaseInvalidReasonType.InsuranceApprovalNotGranted:
      return 'Insurance approval not granted';
    case CaseInvalidReasonType.RequestToDropCase:
      return 'Sales/Surgeon/Hospital asked for case to be dropped';
    default:
      return caseMissedReasonType;
  }
};

export const formatCaseCancellationReasonType = (
  caseCancellation: Pick<ICaseCancellation, 'reason' | 'cancellationType'>,
): string => {
  switch (caseCancellation?.cancellationType) {
    case CaseCancellationType.Cancelled:
      return formatCaseCancelledReasonType(caseCancellation?.reason as CaseCancelledReasonType);
    case CaseCancellationType.Missed:
      return formatCaseMissedReasonType(caseCancellation?.reason as CaseMissedReasonType);
    case CaseCancellationType.Invalid:
      return formatCaseInvalidReasonType(caseCancellation?.reason as CaseInvalidReasonType);
    default:
      return caseCancellation?.reason;
  }
};

export const formatCaseResult = (result: LevelResult): string => {
  switch (result) {
    case LevelResult.Used:
      return 'Used';
    case LevelResult.NotUsed:
      return 'Not Used';
    case LevelResult.NotUsedBillable:
      return 'Not Used (Billable)';
    case LevelResult.Exception:
      return 'Exception';
    default:
      return 'Unknown';
  }
};

export const formatOrganizationType = (organizationType: OrganizationType): string => {
  switch (organizationType) {
    case OrganizationType.Hospital:
      return 'Health System';
    case OrganizationType.Main:
      return 'Main';
    case OrganizationType.Manufacturer:
      return 'Manufacturer';
    case OrganizationType.Distributor:
      return 'Distributor';
    case OrganizationType.Vendor:
      return 'Vendor';
    default:
      return organizationType;
  }
};

export const formatLevelType = (levelType: LevelType): string => {
  switch (levelType) {
    case LevelType.C2C3:
      return 'C2/C3';
    case LevelType.C3C4:
      return 'C3/C4';
    case LevelType.C4C5:
      return 'C4/C5';
    case LevelType.C5C6:
      return 'C5/C6';
    case LevelType.C6C7:
      return 'C6/C7';
    case LevelType.C7T1:
      return 'C7/T1';
    case LevelType.C6T1:
      return 'C6/T1';
    case LevelType.C7C8:
      return 'C7/C8';
    case LevelType.C8T1:
      return 'C8/T1';
    case LevelType.L5S1:
      return 'L5/S1';
    case LevelType.L4L5:
      return 'L4/L5';
    case LevelType.L3L4:
      return 'L3/L4';
    case LevelType.L2L3:
      return 'L2/L3';
    case LevelType.L1L2:
      return 'L1/L2';
    case LevelType.L5L6:
      return 'L5/L6';
    case LevelType.L6S1:
      return 'L6/S1';
    case LevelType.L4S1:
      return 'L4/S1';
    default:
      return levelType;
  }
};

export const formatLevelTypeShorten = (levelType: LevelType): string => {
  switch (levelType) {
    case LevelType.C2C3:
      return 'C23';
    case LevelType.C3C4:
      return 'C34';
    case LevelType.C4C5:
      return 'C45';
    case LevelType.C5C6:
      return 'C56';
    case LevelType.C6C7:
      return 'C67';
    case LevelType.C7T1:
      return 'C71';
    case LevelType.C6T1:
      return 'C61';
    case LevelType.C7C8:
      return 'C78';
    case LevelType.C8T1:
      return 'C81';
    case LevelType.L5S1:
      return 'L51';
    case LevelType.L4L5:
      return 'L45';
    case LevelType.L3L4:
      return 'L34';
    case LevelType.L2L3:
      return 'L23';
    case LevelType.L1L2:
      return 'L12';
    case LevelType.L5L6:
      return 'L56';
    case LevelType.L6S1:
      return 'L61';
    case LevelType.L4S1:
      return 'L41';
    default:
      return levelType;
  }
};

export const formatLevelSize = (levelSize: LevelSize): string => {
  switch (levelSize) {
    case LevelSize.Normal:
      return 'Plan';
    case LevelSize.Plus:
      return 'Plus';
    case LevelSize.Minus:
      return 'Minus';
    default:
      return levelSize;
  }
};

export const formatCaseApproach = (caseApproach?: CaseApproachType) => {
  switch (caseApproach) {
    case CaseApproachType.AgeAdjusted:
      return 'Age Adjusted';
    case CaseApproachType.GapScore:
      return 'Gap Score';
    case CaseApproachType.FocalAlignment:
      return 'Focal Segmental Alignment';
    case CaseApproachType.ZeroMismatch:
      return 'Minimal Mismatch';
    case CaseApproachType.Other:
      return 'Other';
    case CaseApproachType.None:
      return 'None';
    default:
      return 'Unknown';
  }
};

export const formatHeightRestorationGoalType = (
  heightRestorationGoal: HeightRestorationGoalType,
) => {
  switch (heightRestorationGoal) {
    case HeightRestorationGoalType.Specified:
      return 'Level specific';
    case HeightRestorationGoalType.TEM013:
      return 'TEM-013';
    case HeightRestorationGoalType.None:
      return 'None';
    default:
      return 'Unknown';
  }
};

export const formatImplantPositionType = (implantPositionType?: ImplantPositionType) => {
  switch (implantPositionType) {
    case ImplantPositionType.Anterior:
      return 'Anterior';
    case ImplantPositionType.AnteriorMiddle:
      return 'Anterior-Middle';
    case ImplantPositionType.Middle:
      return 'Middle';
    case ImplantPositionType.Midline:
      return 'Midline';
    case ImplantPositionType.OffMidline:
      return 'Off Midline';
    case ImplantPositionType.Other:
      return 'Other';
    case ImplantPositionType.Posterior:
      return 'Posterior';
    case ImplantPositionType.PosteriorMiddle:
      return 'Posterior-Middle';
    case ImplantPositionType.PatientLeft:
      return 'Patient Left';
    case ImplantPositionType.PatientRight:
      return 'Patient Right';
    default:
      return implantPositionType;
  }
};

export const formatTaskPriorityType = (taskPriorityType?: string) => {
  switch (taskPriorityType) {
    case TaskPriorityType.High:
      return 'High';
    case TaskPriorityType.Medium:
      return 'Medium';
    case TaskPriorityType.Low:
      return 'Low';
    default:
      return 'Unknown';
  }
};

export const formatTaskStatusType = (taskStatusType?: string) => {
  switch (taskStatusType) {
    case TaskStatusType.Open:
      return 'Open';
    case TaskStatusType.Complete:
      return 'Completed';
    case TaskStatusType.Cancelled:
      return 'Cancelled';
    default:
      return 'Unknown';
  }
};

export const formatTaskCompleteReasons = (taskActionType?: string) => {
  switch (taskActionType) {
    case TaskActionType.DicomCtScanNeeded:
      return 'DICOM CT Scan has been uploaded.';
    case TaskActionType.NewDicomCtScanNeeded:
      return 'New DICOM CT Scan has been uploaded.';
    case TaskActionType.SurgeonCasePlanApprovalNeeded:
      return 'Surgeon case plan approval has been received.';
    case TaskActionType.SurgeonPreferencesNeeded:
      return 'The surgeon preferences have been uploaded.';
    case TaskActionType.UploadSixWeekPostOpFeedback:
      return 'Six-Week Post-Op films (aQUA™) have been uploaded.';
    default:
      return 'Unknown';
  }
};

export const formatForm20ProductType = (form20ProductType?: string) => {
  switch (form20ProductType) {
    case Form20ProductType.Implant:
      return 'Implant';
    case Form20ProductType.Instrument:
      return 'Instrument';
    case Form20ProductType.AlifXScrew:
      return 'ALIF/X Screw';
    default:
      return 'Unknown';
  }
};

export const formatCaseSpineProfile = (spineProfile: CaseSpineProfile) => {
  switch (spineProfile) {
    case CaseSpineProfile.CervicalStandard:
      return 'Cervical Standard';
    case CaseSpineProfile.CervicalStandardMinusC7:
      return 'Cervical Standard Minus C7';
    case CaseSpineProfile.CervicalStandardPlusC8:
      return 'Cervical Standard Plus C8';
    case CaseSpineProfile.LumbarStandard:
      return 'Lumbar Standard';
    case CaseSpineProfile.LumbarStandardMinusL5:
      return 'Lumbar Standard Minus L5';
    case CaseSpineProfile.LumbarStandardPlusL6:
      return 'Lumbar Standard Plus L6';
    default:
      return `Unknown (${spineProfile})`;
  }
};

export const formatImplantCharacteristicType = (
  implantCharacteristicType?: string,
  partType?: PartType,
) => {
  const cosmeticCheck = `Cosmetic Check`;
  switch (implantCharacteristicType) {
    case ImplantCharacteristicType.ImplantName:
      return 'Implant Name';
    case ImplantCharacteristicType.ApDepthB:
      return 'AP Depth  "B" (mm)';
    case ImplantCharacteristicType.MlWidthA:
      return 'ML Width  "A" (mm)';
    case ImplantCharacteristicType.MaxHeightC:
      return 'Max Cage Height "C" (mm)';
    case ImplantCharacteristicType.BulletAngle:
      return 'Insertion Bullet Angle (°)';
    case ImplantCharacteristicType.LeftBulletAngle:
      return 'Left Bullet Angle (°)';
    case ImplantCharacteristicType.RightBulletAngle:
      return 'Right Bullet Angle (°)';
    case ImplantCharacteristicType.BulletHeight:
      return 'Bullet Height (mm)';
    case ImplantCharacteristicType.LeftBulletHeight:
      return 'Left Bullet Height (mm)';
    case ImplantCharacteristicType.RightBulletHeight:
      return 'Right Bullet Height (mm)';
    case ImplantCharacteristicType.CoronalAngle:
      return 'Coronal angle (°)';
    case ImplantCharacteristicType.LordoticAngle:
      return 'Lordotic Angle (°)';
    case ImplantCharacteristicType.BulletMinDistance:
      return 'Bullet Min Distance \r\n (visual check)';
    case ImplantCharacteristicType.BulletMinHeight:
      return 'Bullet Min Height \r\n (visual check)';
    case ImplantCharacteristicType.CosmeticCheck:
      switch (partType) {
        case PartType.ALIF:
          return `${cosmeticCheck} (2 graft windows, 2 threads, lattice)`;
        case PartType.ALIF_X_TWO_UP:
        case PartType.ALIF_X_TWO_DOWN:
          return `${cosmeticCheck} (4 graft windows, 1 thread, lattice)`;
        case PartType.LLIF_LEFT:
        case PartType.LLIF_RIGHT:
          return `${cosmeticCheck} (2 graft windows, 1 thread. lattice)`;
        case PartType.TLIFC_INLINE_LEFT:
        case PartType.TLIFC_INLINE_RIGHT:
        case PartType.TLIFC_OFFSET_LEFT:
        case PartType.TLIFC_OFFSET_RIGHT:
          return `${cosmeticCheck} (2 graft windows, 1 thread, lattice)`;
        case PartType.TLIFO_LEFT:
        case PartType.TLIFO_RIGHT:
          return `${cosmeticCheck} (1 graft window, 1 thread, lattice)`;
        default:
          return cosmeticCheck;
      }
    case ImplantCharacteristicType.PartMarkings:
      return 'Part Markings \r\n (-, +, Lot code, "R")';
    default:
      return 'Unknown';
  }
};

export const formatSurgeonApproachType = (surgeonApproachType: SurgeonApproachType): string => {
  let formattedSurgeonApproachType = '';
  switch (surgeonApproachType) {
    case SurgeonApproachType.ALIF:
      formattedSurgeonApproachType = 'ALIF';
      break;
    case SurgeonApproachType.ALIFX:
      formattedSurgeonApproachType = 'ALIF/X';
      break;
    case SurgeonApproachType.LLIF:
      formattedSurgeonApproachType = 'LLIF';
      break;
    case SurgeonApproachType.TLIFC:
      formattedSurgeonApproachType = 'TLIF/C';
      break;
    case SurgeonApproachType.TLIFCA:
      formattedSurgeonApproachType = 'TLIF/CA';
      break;
    case SurgeonApproachType.TLIFO:
      formattedSurgeonApproachType = 'TLIF/O';
      break;
  }

  return formattedSurgeonApproachType;
};

export const formatImplantDirection = (implantDirection?: ImplantDirection) => {
  switch (implantDirection) {
    case ImplantDirection.Left:
      return 'Patient Left';
    case ImplantDirection.Right:
      return 'Patient Right';
    default:
      return implantDirection;
  }
};

export const formatInterbodyScrewLengthTypes = (
  screwLengthTypes?: InterbodyScrewLengthTypes,
  is11453ScrewAvailable?: boolean,
): string => {
  if (is11453ScrewAvailable) {
    switch (screwLengthTypes) {
      case InterbodyScrewLengthTypes.AlifXScrewLength20mm:
        return 'Screw Pack: 1145.20.3 - aprevo® ALIF-X Locking Screw, Ø5.5x20mm / 3PK';
      case InterbodyScrewLengthTypes.AlifXScrewLength25mm:
        return 'Screw Pack: 1145.25.3 - aprevo® ALIF-X Locking Screw, Ø5.5x25mm / 3PK';
      case InterbodyScrewLengthTypes.AlifXScrewLength30mm:
        return 'Screw Pack: 1145.30.3 - aprevo® ALIF-X Locking Screw, Ø5.5x30mm / 3PK';
      default:
        return 'None';
    }
  } else {
    switch (screwLengthTypes) {
      case InterbodyScrewLengthTypes.AlifXScrewLength20mm:
        return 'Screw Pack: 1053.20 - aprevo® ALIF/X Screw, Ø5.5x20mm / 4PK';
      case InterbodyScrewLengthTypes.AlifXScrewLength25mm:
        return 'Screw Pack: 1053.25 - aprevo® ALIF/X Screw, Ø5.5x25mm / 4PK';
      case InterbodyScrewLengthTypes.AlifXScrewLength30mm:
        return 'Screw Pack: 1053.30 - aprevo® ALIF/X Screw, Ø5.5x30mm / 4PK';
      default:
        return 'None';
    }
  }
};

export const formatImplantScrewLength = (screwLengthTypes?: number): string => {
  switch (screwLengthTypes) {
    case 20:
      return '20mm';
    case 25:
      return '25mm';
    case 30:
      return '30mm';
    default:
      return 'None';
  }
};

export const formatInterbodyScrewLength = (
  screwLengthTypes?: InterbodyScrewLengthTypes,
): string => {
  switch (screwLengthTypes) {
    case InterbodyScrewLengthTypes.AlifXScrewLength20mm:
      return '20mm';
    case InterbodyScrewLengthTypes.AlifXScrewLength25mm:
      return '25mm';
    case InterbodyScrewLengthTypes.AlifXScrewLength30mm:
      return '30mm';
    default:
      return 'None';
  }
};

export const formatCaseRiskAssessmentType = (
  caseRiskAssessmentType?: CaseRiskAssessmentType,
): string => {
  switch (caseRiskAssessmentType) {
    case CaseRiskAssessmentType.CaseReviewQaReview:
      return 'Case Review and QA Review Required';
    case CaseRiskAssessmentType.QaReviewOnly:
      return 'QA Review Only (N/A Operations Case Review)';
    default:
      return 'N/A';
  }
};
export const formatTaskDescription = (taskActionType?: string) => {
  switch (taskActionType) {
    case TaskActionType.DicomCtScanNeeded:
      return 'Carlsmed® Imaging Protocol Compliant CT Scan Needed';
    case TaskActionType.NewDicomCtScanNeeded:
      return 'Case study (CT Scan) has expired or will expire by the current surgery date. Upload new imaging.';
    case TaskActionType.SurgeonCasePlanApprovalNeeded:
      return 'Surgeon Case Plan Approval Needed';
    case TaskActionType.SurgeonPreferencesNeeded:
      return 'Surgeon Preferences Needed';
    case TaskActionType.UploadSixWeekPostOpFeedback:
      return 'Upload 6-week post-op films (aQUA™)';
    case TaskActionType.UploadSixMonthPostOpFeedback:
      return 'Upload 6-month post-op films (aQUA™)';
    case TaskActionType.UpdateSurgeryDate:
      return 'Update the surgery date or place the case on-hold.';
    default:
      return 'Unknown';
  }
};

export const formatImplantRange = (
  type: 'AP' | 'ML',
  implantMeasurementRange: ImplantMeasurementRange | null,
) => {
  if (implantMeasurementRange) {
    if (type === 'AP') {
      return ` (${implantMeasurementRange?.minAP}-${implantMeasurementRange?.maxAP}mm)`;
    }
    if (type === 'ML') {
      return ` (${implantMeasurementRange?.minML}-${implantMeasurementRange?.maxML}mm)`;
    }
  }

  return '';
};

export const formatPatientContactCadenceType = (contactCadence: PatientContactCadenceType) => {
  switch (contactCadence) {
    case PatientContactCadenceType.OptOut:
      return 'Opt-Out';
    case PatientContactCadenceType.BiWeekly:
      return 'Bi-Weekly';
    case PatientContactCadenceType.Weekly:
      return 'Weekly';
    default:
      return 'Unknown';
  }
};

export const formatPatientContactPreferenceType = (
  contactPreference: PatientContactPreferenceType,
) => {
  switch (contactPreference) {
    case PatientContactPreferenceType.Email:
      return 'Email';
    case PatientContactPreferenceType.Phone:
      return 'Phone';
    case PatientContactPreferenceType.Text:
      return 'Text';
    default:
      return 'Unknown';
  }
};

export const formatCaseHoldReasonType = (reason: CaseHoldReasonType): string => {
  switch (reason) {
    case CaseHoldReasonType.Administrative:
      return 'Administrative';
    case CaseHoldReasonType.Medical:
      return 'Medical';
    case CaseHoldReasonType.PendingCaseApproval:
      return 'Pending Case Approval';
    case CaseHoldReasonType.Unknown:
      return 'Unknown';
  }
};

export const formatAgeRange = (range: AgeRange): string => {
  switch (range) {
    case AgeRange.Under40:
      return '< 40';
    case AgeRange.In40:
      return '40 - 49';
    case AgeRange.In50:
      return '50 - 59';
    case AgeRange.In60:
      return '60 - 69';
    case AgeRange.In70:
      return '70 - 79';
    case AgeRange.Above80:
      return '≥ 80';
  }
};

export const formatImplantLevel = (
  spineType: CaseSpineType,
  levelType: LevelType,
  levelSize: LevelSize,
) => {
  // reformat the level from L4_L5 to L45 by removing both L characters, the S and _ and prefixing with an L
  const levelNumbers = levelType
    .replace('_', '')
    .replace('C', '')
    .replace('C', '')
    .replace('L', '')
    .replace('L', '')
    .replace('S', '')
    .replace('T', '');
  const plusSizeSuffix = levelSize === LevelSize.Plus ? '+' : '';
  const minusSizeSuffix = levelSize === LevelSize.Minus ? '-' : '';
  return `${
    spineType === CaseSpineType.Lumbar ? 'L' : 'C'
  }${levelNumbers}${plusSizeSuffix}${minusSizeSuffix}`;
};

export const formatImplantType = (partType: PartType): ImplantType => {
  return partType
    ?.replace('_LEFT', '')
    ?.replace('_RIGHT', '')
    ?.replace('_INLINE', '')
    ?.replace('_TWO_DOWN', '') as ImplantType;
};

export const formatCaseShippingStatusType = (
  caseShippingStatusType: CaseShippingStatusType,
): string => {
  switch (caseShippingStatusType) {
    case CaseShippingStatusType.AvailableForPickup:
      return 'Available for Pickup';
    case CaseShippingStatusType.Cancelled:
      return 'Cancelled';
    case CaseShippingStatusType.Delivered:
      return 'Delivered';
    case CaseShippingStatusType.Error:
      return 'Error';
    case CaseShippingStatusType.Failure:
      return 'Failure';
    case CaseShippingStatusType.InTransit:
      return 'In Transit';
    case CaseShippingStatusType.OutForDelivery:
      return 'Out For Delivery';
    case CaseShippingStatusType.PreTransit:
      return 'Pre-Transit';
    case CaseShippingStatusType.ReturnToSender:
      return 'Return To Sender';
    case CaseShippingStatusType.Unknown:
      return 'Unknown';
    default:
      return caseShippingStatusType;
  }
};

export const formatCaseStageColors = (caseStage: CaseStageType) => {
  switch (caseStage) {
    case CaseStageType.Open:
      return CaseStageTypeColor.Open;
    case CaseStageType.Complete:
      return CaseStageTypeColor.Complete;
    case CaseStageType.Design:
      return CaseStageTypeColor.Design;
    case CaseStageType.Planning:
      return CaseStageTypeColor.Planning;
    case CaseStageType.Production:
      return CaseStageTypeColor.Production;
    case CaseStageType.Proposed:
      return CaseStageTypeColor.Proposed;
    case CaseStageType.QaReview:
      return CaseStageTypeColor.QaReview;
    case CaseStageType.Ready:
      return CaseStageTypeColor.Ready;
    case CaseStageType.Segmentation:
      return CaseStageTypeColor.Segmentation;
    default:
      return '#f5f5f5';
  }
};

export const formatExcludedInstrumentType = (instrumentSkuType: string): string => {
  let formattedExcludedInstrumentType = '';
  switch (instrumentSkuType) {
    case '1034':
      formattedExcludedInstrumentType = 'aprevo® Driver';
      break;
    case '1045':
      formattedExcludedInstrumentType = 'aprevo® M4 Inserter';
      break;
    case '1050':
      formattedExcludedInstrumentType = 'aprevo® M5 Inserter';
      break;
    case '1060':
      formattedExcludedInstrumentType = 'aprevo® U-joint Driver';
      break;
    case '1080':
      formattedExcludedInstrumentType = 'aprevo® Awl';
      break;
    case '1081':
      formattedExcludedInstrumentType = 'aprevo® NAV Inserter';
      break;
    case '1090':
      formattedExcludedInstrumentType = 'aprevo® M4L Inserter';
      break;
    case '1162':
      formattedExcludedInstrumentType = 'aprevo® Quarter Square Handle';
      break;
    case '1180':
      formattedExcludedInstrumentType = 'aprevo® Quarter Square Ratcheting Handle\t';
      break;
    case '1210':
      formattedExcludedInstrumentType = 'aprevo® Straight Awl';
      break;
    case '1220':
      formattedExcludedInstrumentType = 'aprevo® Straight Driver';
      break;
    case '1230':
      formattedExcludedInstrumentType = 'aprevo® Angled Awl';
      break;
    case '1240':
      formattedExcludedInstrumentType = 'aprevo® Angled Driver';
      break;
    case '1270':
      formattedExcludedInstrumentType = 'aprevo® Reusable Packaging';
      break;
    case '1300':
      formattedExcludedInstrumentType = 'aprevo® TLIF-C Articulating Inserter';
      break;
  }
  return formattedExcludedInstrumentType;
};

export const formatImplantTypes = (implantType: ImplantType): string => {
  let formattedImplantTypes: string;
  switch (implantType) {
    case ImplantType.ALIF:
      formattedImplantTypes = 'ALIF';
      break;
    case ImplantType.ALIFX:
      formattedImplantTypes = 'ALIF/X';
      break;
    case ImplantType.LLIF:
      formattedImplantTypes = 'LLIF';
      break;
    case ImplantType.TLIFC:
      formattedImplantTypes = 'TLIF/C';
      break;
    case ImplantType.TLIFCA:
      formattedImplantTypes = 'TLIF/CA';
      break;
    case ImplantType.TLIFO:
      formattedImplantTypes = 'TLIF/O';
      break;
    default:
      formattedImplantTypes = implantType;
  }
  return formattedImplantTypes;
};

export const formatSurgeonPreferenceMeasurementType = (
  surgeonPreferenceMeasurementType: SurgeonPreferenceMeasurementType,
): string => {
  let formattedSurgeonPreferenceMeasurementType = '';
  switch (surgeonPreferenceMeasurementType) {
    case SurgeonPreferenceMeasurementType.FootprintApRange:
      formattedSurgeonPreferenceMeasurementType = 'Footprint AP Range (mm)';
      break;
    case SurgeonPreferenceMeasurementType.FootprintMlRange:
      formattedSurgeonPreferenceMeasurementType = 'Footprint ML Range (mm)';
      break;
    case SurgeonPreferenceMeasurementType.AnteriorHeightRange:
      formattedSurgeonPreferenceMeasurementType = 'Anterior Height Range (mm)';
      break;
    case SurgeonPreferenceMeasurementType.PosteriorHeightRange:
      formattedSurgeonPreferenceMeasurementType = 'Posterior Height Range (mm)';
      break;
    case SurgeonPreferenceMeasurementType.LordosisAngleRange:
      formattedSurgeonPreferenceMeasurementType = 'Lordosis Angle Range (°)';
      break;
    case SurgeonPreferenceMeasurementType.ImplantPositionDistanceRange:
      formattedSurgeonPreferenceMeasurementType = 'Position Distance Range (mm)';
      break;
  }
  return formattedSurgeonPreferenceMeasurementType;
};

export const formatPositionDistanceType = (positionDistanceType: PositionDistanceType): string => {
  let formattedPositionDistanceType = '';
  switch (positionDistanceType) {
    case PositionDistanceType.DistanceFromAnteriorEdge:
      formattedPositionDistanceType = 'Distance from anterior edge';
      break;
    case PositionDistanceType.DistanceFromApCenter:
      formattedPositionDistanceType = 'Distance from AP Center';
      break;
    case PositionDistanceType.AnteriorThird:
      formattedPositionDistanceType = 'Anterior Third';
      break;
    case PositionDistanceType.Midline:
      formattedPositionDistanceType = 'Midline';
      break;
    case PositionDistanceType.PosteriorThird:
      formattedPositionDistanceType = 'Posterior Third';
      break;
  }
  return formattedPositionDistanceType;
};

export const formatImplantOrientation = (implantOrientation?: ImplantOrientation): string => {
  switch (implantOrientation) {
    case ImplantOrientation.TwoUp:
      return '2 UP/1 DOWN';
    case ImplantOrientation.TwoDown:
      return '1 UP/2 DOWN';
    case ImplantOrientation.Inline:
      return 'Inline';
    case ImplantOrientation.Offset:
      return 'Offset';
    case ImplantOrientation.CaseSpecific:
      return 'Case Specific';
    case ImplantOrientation.LevelSpecific:
      return 'Level Specific';
    default:
      return '';
  }
};

export const formatSagittalGoalType = (sagittalGoalType?: SagittalGoalType) => {
  switch (sagittalGoalType) {
    case SagittalGoalType.AgeAdjusted:
      return 'Age Adjusted';
    case SagittalGoalType.MinimalMismatch:
      return 'Minimal Mismatch';
    case SagittalGoalType.GapScore:
      return 'Gap Score';
    case SagittalGoalType.AlternativeSupportivePlanningReference:
      return 'Alternative Supportive Planning Reference (E.g. UNiD, Surgimap)';
    case SagittalGoalType.Other:
      return 'Surgeon Request';
    default:
      return 'Unknown';
  }
};

export const formatCoronalGoalType = (coronalGoalType?: CoronalGoalType) => {
  switch (coronalGoalType) {
    case CoronalGoalType.ParallelDiscSpaceAtTreatmentLevels:
      return 'Parallel Disc Space at Treatment Level(s)';
    case CoronalGoalType.SuperiorEndplateOfHighestTreatedLevelParallelToSacrum:
      return 'Superior Endplate of Highest Treated Level Parallel to Sacrum​';
    case CoronalGoalType.SuperiorL1EndplateParallelToSacrum:
      return 'Superior L1 Endplate Parallel to Sacrum';
    case CoronalGoalType.SuperiorL1EndplateParallelToFloor:
      return 'Superior L1 Endplate Parallel to Floor';
    case CoronalGoalType.Other:
      return 'Surgeon Request';
    default:
      return 'Unknown';
  }
};

export const formatCoronalCorrectionGoalType = (
  coronalCorrectionGoalType?: CoronalCorrectionGoalType,
) => {
  switch (coronalCorrectionGoalType) {
    case CoronalCorrectionGoalType.DiscSpaceParallelization:
      return 'Disc Space Parallelization';
    case CoronalCorrectionGoalType.HightestSuperiorEndplateParallelization:
      return 'Highest Superior Endplate Parallelization';
    case CoronalCorrectionGoalType.ZeroCoronal:
      return 'Zero Coronal';
    case CoronalCorrectionGoalType.FloorParallelization:
      return 'Floor Parallelization';
    case CoronalCorrectionGoalType.None:
      return 'None';
    default:
      return 'Unknown';
  }
};

export const formatInstrumentSkuType = (instrumentSkuType: string): string => {
  let formattedInstrumentSkuType = '';
  switch (instrumentSkuType) {
    case '1034':
      formattedInstrumentSkuType = '1034 - aprevo® Driver';
      break;
    case '1045':
      formattedInstrumentSkuType = '1045 - aprevo® M4 Inserter';
      break;
    case '1050':
      formattedInstrumentSkuType = '1050 - aprevo® M5 Inserter';
      break;
    case '1060':
      formattedInstrumentSkuType = '1060 - aprevo® U-joint Driver';
      break;
    case '1080':
      formattedInstrumentSkuType = '1080 - aprevo® Awl';
      break;
    case '1081':
      formattedInstrumentSkuType = '1081 - aprevo® NAV Inserter';
      break;
    case '1090':
      formattedInstrumentSkuType = '1090 - aprevo® M4L Inserter';
      break;
    case '1162':
      formattedInstrumentSkuType = '1162 - aprevo® Quarter Square Handle';
      break;
    case '1180':
      formattedInstrumentSkuType = '1180 - aprevo® Quarter Square Ratcheting Handle\t';
      break;
    case '1210':
      formattedInstrumentSkuType = '1210 - aprevo® Straight Awl';
      break;
    case '1220':
      formattedInstrumentSkuType = '1220 - aprevo® Straight Driver';
      break;
    case '1230':
      formattedInstrumentSkuType = '1230 - aprevo® Angled Awl';
      break;
    case '1240':
      formattedInstrumentSkuType = '1240 - aprevo® Angled Driver';
      break;
    case '1270':
      formattedInstrumentSkuType = '1270 - aprevo® Reusable Packaging';
      break;
    case '1300':
      formattedInstrumentSkuType = '1300 - aprevo® TLIF-C Articulating Inserter';
      break;
  }
  return formattedInstrumentSkuType;
};

export const formatTargetHeightMetricType = (
  targetHeightMetricType: TargetHeightMetricType,
): string => {
  let formattedTargetHeightMetricType = '';
  switch (targetHeightMetricType) {
    case TargetHeightMetricType.ImplantHeight:
      formattedTargetHeightMetricType = 'Implant Height';
      break;
    case TargetHeightMetricType.DiscSpaceHeight:
      formattedTargetHeightMetricType = 'Disc Space Height';
      break;
    case TargetHeightMetricType.CaseSpecific:
      formattedTargetHeightMetricType = 'Case Specific';
      break;
  }
  return formattedTargetHeightMetricType;
};

export const formatVendorTaskCategory = (taskCategory: VendorTaskCategory): string => {
  switch (taskCategory) {
    case VendorTaskCategory.AdditivePrinting:
      return '3D Printing';
    case VendorTaskCategory.FinalShipment:
      return 'Packaging';
  }
};

export const formatPlanRejectionReason = (rejectionReasons: PlanRejectionReason) => {
  switch (rejectionReasons) {
    case PlanRejectionReason.Admin:
      return 'Admin (for designer use only)';
    case PlanRejectionReason.CagePosition:
      return 'Cage Position Change';
    case PlanRejectionReason.CoronalChange:
      return 'Disc / Lumbar Coronal Change';
    case PlanRejectionReason.FootprintChange:
      return 'Footprint Change';
    case PlanRejectionReason.HeightChange:
      return 'Height Change';
    case PlanRejectionReason.LevelImplantChange:
      return 'Level / Implant Changes';
    case PlanRejectionReason.LordosisChange:
      return 'Disc / Lumbar Lordosis Change';
    case PlanRejectionReason.Other:
      return 'Other';
    default:
      return '';
  }
};

export const formatCommentType = (commentType: CommentType): string => {
  switch (commentType) {
    case CommentType.Patient:
      return 'Patient';
    case CommentType.Production:
      return 'Production';
    case CommentType.Case:
      return 'Case';
  }
};

export const formatLevelResultNotUsedReason = (reason: LevelResultNotUsedReason): string => {
  switch (reason) {
    case LevelResultNotUsedReason.CorrectionAchievedWithoutLevel:
      return 'Desired correction achieved without this level ';
    case LevelResultNotUsedReason.SurgeonSurgicalPlanChange:
      return "Change in the surgeon's surgical plan";
    case LevelResultNotUsedReason.LimitedAccess:
      return 'Limited access prevented implant placement';
    case LevelResultNotUsedReason.LevelAutofused:
      return 'Level was auto-fused';
    case LevelResultNotUsedReason.AnatomicalConstraints:
      return 'Anatomical constraints (E.g. high crest, high PI, etc.)';
    case LevelResultNotUsedReason.StockDeviceUsed:
      return 'Stock device used instead';
    case LevelResultNotUsedReason.DiscSpaceFitIssue:
      return 'aprevo® implant did not fit the disc space';
    case LevelResultNotUsedReason.Other:
      return 'Other: ___';
    default:
      return '';
  }
};

export const formatImplantDirectionOrientation = (
  implantDirectionOrientation?: ImplantDirection,
): string => {
  switch (implantDirectionOrientation) {
    case ImplantDirection.Left:
      return 'Patient Left';
    case ImplantDirection.Right:
      return 'Patient Right';
    case ImplantDirection.ApproachFromSideWithShorterDiscHeight:
      return 'Approach from side with shorter disc height';
    case ImplantDirection.CaseSpecific:
      return 'Case Specific';
    case ImplantDirection.LevelSpecific:
      return 'Level Specific';
    default:
      return '';
  }
};

export const formatSanitizeAndParseHtml = (dirtyHtmlString: string) => {
  const clean = DOMPurify.sanitize(dirtyHtmlString);
  return HTMLParser.parse(clean).innerText;
};

export const formatPostOpAnalysisType = (postOpType: PostOpAnalysisType) => {
  switch (postOpType) {
    case PostOpAnalysisType.Plan:
      return 'Plan';
    case PostOpAnalysisType.PreOp:
      return 'Pre-Op';
    case PostOpAnalysisType.PostOpIntraOp:
      return 'Intra-Op';
    case PostOpAnalysisType.PostOpSixWeeks:
      return 'Post-Op (Six Weeks)';
    case PostOpAnalysisType.PostOpSixMonths:
      return 'Post-Op (Six Months)';
    case PostOpAnalysisType.PostOpOneYear:
      return 'Post-Op (One Year)';
    case PostOpAnalysisType.PostOpTwoYears:
      return 'Post-Op (Two Years)';
    case PostOpAnalysisType.PostOpCt:
      return 'post-Op Ct';
    case PostOpAnalysisType.PostOpMri:
      return 'Post-Op Mri';
  }
};

export const formatPositionLabel = (position: Position): string => {
  switch (position) {
    case Position.Posterior:
      return 'P';
    case Position.Anterior:
      return 'A';
    case Position.PatientRight:
      return 'PR';
    case Position.PatientLeft:
      return 'PL';
    default:
      return '';
  }
};
