import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/client';
import { UPSERT_SETTINGS } from '../../../gql';
import { Box } from '@mui/material';
import { Button } from '@workflow-nx/ui';
import { VanillaJSONEditor } from './VanillaJSONEditor';
import {
  createAjvValidator,
  Content,
  isTextContent,
  OnChangeStatus,
  isJSONContent,
  MenuItem,
} from 'vanilla-jsoneditor';
import { IForm19JsonLevel, IForm20Product } from '@workflow-nx/common';
import { schemas } from '@workflow-nx/utils';

enum FormTypes {
  form19 = 'FORM-19',
  form20 = 'FORM-20',
}

export function JsonEditorForm(props: {
  form: IForm19JsonLevel[] | IForm20Product[];
  formType: FormTypes;
  handleClose: () => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [upsertSettings] = useMutation(UPSERT_SETTINGS);
  const [content, setContent] = useState<string>(JSON.stringify(props.form));
  const [jsonErrors, setJsonErrors] = useState<boolean>(false);
  const validate = createAjvValidator({
    schema: props.formType === FormTypes.form19 ? schemas.form19Schema : schemas.form20Schema,
  });
  const formTypeKey = Object.keys(FormTypes)[Object.values(FormTypes).indexOf(props.formType)];

  useEffect(() => {
    setContent(JSON.stringify(props.form));
  }, [props.form]);

  const handleOnChange = (
    updatedContent: Content,
    previousContent: Content,
    { contentErrors }: OnChangeStatus,
  ) => {
    if (isJSONContent(updatedContent)) {
      const newContent: string = JSON.stringify(updatedContent.json);
      setContent(newContent);
    } else if (isTextContent(updatedContent)) {
      const newContent: string = updatedContent.text;
      setContent(newContent);
    }

    if ('validationErrors' in contentErrors && contentErrors.validationErrors.length) {
      setJsonErrors(true);
    } else if ('parseError' in contentErrors) {
      setJsonErrors(true);
    } else setJsonErrors(false);
  };

  const handleRenderMenu = (items: MenuItem[]): MenuItem[] => {
    //Removing the table and transform button, as I just think it will confuse users
    const newItem = items.filter((m) => {
      const item = m as Record<string, any>;
      return !(item.text === 'table' || item.className === 'jse-transform');
    });
    return [...newItem];
  };

  const handleSubmit = async () => {
    try {
      await upsertSettings({
        variables: {
          [formTypeKey]: JSON.parse(content),
        },
      });

      enqueueSnackbar(`${props.formType} updated`, {
        variant: 'success',
      });
      props.handleClose();
    } catch (err) {
      console.error(err);
      enqueueSnackbar(`An error occurred updating the ${props.formType}`, {
        variant: 'error',
      });
    }
  };

  return (
    <Box m={1}>
      <Box p={1}>
        <VanillaJSONEditor
          content={{ text: content }}
          validator={validate}
          onChange={handleOnChange}
          onRenderMenu={handleRenderMenu}
        />
      </Box>
      <Box p={1} display={'flex'} justifyContent={'flex-end'}>
        <Button
          variant={'outlined'}
          color={'primary'}
          label={`Save ${props.formType}`}
          disabled={jsonErrors}
          onClick={handleSubmit}
        />
      </Box>
    </Box>
  );
}
