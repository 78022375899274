import React, { useReducer, useState } from 'react';
import { Box, Card, CardContent, Container, Stack, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import Page from '../../../components/Page';
import { useLazyQuery, useQuery } from '@apollo/client';
import { FIND_ACTIVITIES, FIND_USER } from '../../../gql';
import SplashScreen from '../../../components/SplashScreen';
import { CustomAppBar } from '../../../components/CustomAppBar';
import {
  ActivityOriginType,
  format,
  ISurgeonPreferences,
  IUser,
  UserRoleType,
} from '@workflow-nx/common';
import useAuth from '../../../hooks/useAuth';
import { ViewSurgeonDetails } from './ViewSurgeonDetails';
import { ViewSurgeonPreferencesDetails } from './ViewSurgeonPreferencesDetails';
import { EditSurgeonPreferencesDialog } from './SurgeonPreferencesView/EditSurgeonPreferencesDialog';
import { canEditSurgeon, canEditSurgeonPreferences } from '../../../utils/userAccess';
import { CreateSurgeonDialog } from '../ListSurgeonsView/CreateSurgeonDialog';
import { Button } from '@workflow-nx/ui';
import { EditAssociatedUsersDialog } from '../UserView/EditAssociatedUsersDialog';
import { ActivityListTable } from '../../cases/CaseView/ActivityTab/ActivityListTable';

export type SurgeonViewActionType = {
  type: 'refetch' | 'INIT';
  data?: any;
};

type SurgeonViewStateType = {
  canEditSurgeon: boolean;
  canEditSurgeonPreferences: boolean;
  user?: IUser;
  preferences?: ISurgeonPreferences;
};

const SurgeonView = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const [openEditSurgeonDialog, setOpenEditSurgeonDialog] = useState(false);
  const [openEditSurgeonPreferencesDialog, setOpenEditSurgeonPreferencesDialog] = useState(false);
  const [userActivities, setUserActivities] = useState([]);
  const { userId } = useParams<{ userId?: string }>();
  const { data, loading, refetch } = useQuery(FIND_USER, {
    variables: { userId: Number(userId) },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const user = data?.user;
      if (!user) {
        navigate('/app/surgeons');

        return;
      }

      findUserActivities({
        variables: {
          originId: user?.userId,
          originTypeFilter: [ActivityOriginType.User],
          orderBy: { createdAt: 'desc' },
        },
      }).then(({ data }) => {
        const activities = data?.activities?.activities ?? [];
        setUserActivities(activities);
      });

      dispatch({ type: 'INIT', data });
    },
  });

  const [findUserActivities, { loading: loadingActivityData }] = useLazyQuery(FIND_ACTIVITIES, {
    variables: {
      originId: null,
      originTypeFilter: [ActivityOriginType.User],
      orderBy: { createdAt: 'desc' },
    },
  });

  const initialState: SurgeonViewStateType = {
    canEditSurgeon: false,
    canEditSurgeonPreferences: false,
  };

  const reducer = (
    state: SurgeonViewStateType,
    action: SurgeonViewActionType,
  ): SurgeonViewStateType => {
    let updatedState: SurgeonViewStateType = JSON.parse(JSON.stringify(state));
    switch (action.type) {
      case 'refetch':
        refetch();
        break;
      case 'INIT': {
        const user = action.data.user;
        const isEditableSurgeon = canEditSurgeon(user, auth?.user);
        const isEditableSurgeonPreferences = canEditSurgeonPreferences(user, auth?.user);

        updatedState = {
          user,
          preferences: user?.preferences,
          canEditSurgeon: isEditableSurgeon,
          canEditSurgeonPreferences: isEditableSurgeonPreferences,
        };
      }
    }
    return updatedState;
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const handleUpdate = (shouldUpdate: boolean) => {
    if (shouldUpdate) {
      dispatch({ type: 'refetch' });
    }
    setOpenEditSurgeonDialog(false);
    setOpenEditSurgeonPreferencesDialog(false);
  };

  return loading || !data?.user ? (
    <SplashScreen />
  ) : (
    <Page title={'Surgeon'}>
      <Container>
        <CustomAppBar
          title={`${format.formatName(data.user)} - ${data.user?.organization?.name}`}
          actions={[]}
        />
        <Card>
          <CardContent>
            <Box m={1}>
              <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography variant={'h4'}>Surgeon Details</Typography>
                {state.canEditSurgeon ? (
                  <Button
                    onClick={() => setOpenEditSurgeonDialog(true)}
                    variant={'contained'}
                    label={'Edit'}
                  />
                ) : null}
              </Stack>
            </Box>
            <Box m={1}>
              <ViewSurgeonDetails user={data?.user} />
            </Box>
          </CardContent>
        </Card>
        <Box mt={2} />
        <EditAssociatedUsersDialog
          user={data?.user}
          role={UserRoleType.SalesRep}
          onClose={handleUpdate}
        />
        <Box mt={2} />
        <Card>
          <CardContent>
            <Box m={1}>
              <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography variant={'h4'}>Surgeon Preferences</Typography>
                {state.canEditSurgeonPreferences ? (
                  <Button
                    onClick={() => setOpenEditSurgeonPreferencesDialog(true)}
                    variant={'contained'}
                    label={'Edit'}
                  />
                ) : null}
              </Stack>
            </Box>
            <Box m={1}>
              <ViewSurgeonPreferencesDetails preferences={data?.user?.preferences} />
            </Box>
            <Box mt={1} />
          </CardContent>
        </Card>
        <Box mt={2} />
        <Box>
          <Card>
            <CardContent>
              <Box m={1}>
                <Typography variant={'h4'}>Activity</Typography>
              </Box>
              <ActivityListTable
                dispatch={dispatch}
                orderBy={{ createdAt: 'desc' }}
                loading={loadingActivityData}
                activities={userActivities}
              />
            </CardContent>
          </Card>
        </Box>
      </Container>
      <CreateSurgeonDialog open={openEditSurgeonDialog} user={data?.user} onClose={handleUpdate} />
      <EditSurgeonPreferencesDialog
        open={openEditSurgeonPreferencesDialog}
        surgeonUser={data?.user}
        onClose={handleUpdate}
      />
    </Page>
  );
};

export default SurgeonView;
