import { Box, Typography } from '@mui/material';
import {
  ISurgeonPreferenceImplant,
  ImplantDirection,
  ImplantOrientation,
  ImplantType,
  LevelSize,
  format,
} from '@workflow-nx/common';
import useAuth from '../../../../hooks/useAuth';
import { FeatureFlag, isTlifCOrientationAvailable } from '../../../../utils/featureFlags';
import { date } from '@workflow-nx/utils';

interface ViewSurgeonPreferencesImplantProps {
  selectedImplantType: ISurgeonPreferenceImplant | undefined;
}

export function ViewSurgeonPreferencesImplant({
  selectedImplantType,
}: ViewSurgeonPreferencesImplantProps) {
  const { hasFeatureFlag, product } = useAuth();
  const isTlifCArticulatingStarted =
    !!product?.tlifCArticulatingStartDate &&
    date.isBeforeToday(product.tlifCArticulatingStartDate as string);

  return (
    <>
      <Box display={'flex'} my={4}>
        <Typography variant={'h4'}>
          <strong>
            {selectedImplantType ? format.formatImplantTypes(selectedImplantType?.implantType) : ''}
          </strong>
        </Typography>
      </Box>
      <Box display={'flex'} alignItems={'center'} my={2}>
        <Box display={'flex'} width={250} justifyContent={'left'}>
          <Typography variant={'body1'}>
            <strong>Position Distance:</strong>
          </Typography>
        </Box>
        <Box display={'flex'} width={500} justifyContent={'left'}>
          <Typography variant={'body1'}>
            {selectedImplantType
              ? format.formatPositionDistanceType(selectedImplantType?.positioning)
              : ''}
          </Typography>
        </Box>
      </Box>
      {![ImplantType.ALIF, ImplantType.ALIFX].includes(
        selectedImplantType?.implantType as ImplantType,
      ) ? (
        <Box display={'flex'} alignItems={'center'} my={2}>
          <Box display={'flex'} width={250} justifyContent={'left'}>
            <Typography variant={'body1'}>
              <strong>Direction:</strong>
            </Typography>
          </Box>
          <Box display={'flex'} width={500} justifyContent={'left'}>
            <Typography variant={'body1'}>
              {selectedImplantType
                ? format.formatImplantDirectionOrientation(
                    selectedImplantType?.direction as ImplantDirection,
                  )
                : ''}
            </Typography>
          </Box>
        </Box>
      ) : null}
      {isTlifCOrientationAvailable() &&
      [ImplantType.TLIFC].includes(selectedImplantType?.implantType as ImplantType) ? (
        <Box display={'flex'} alignItems={'center'} my={2}>
          <Box display={'flex'} width={250} justifyContent={'left'}>
            <Typography variant={'body1'}>
              <strong>Thread Angle:</strong>
            </Typography>
          </Box>
          <Box display={'flex'} width={500} justifyContent={'left'}>
            <Typography variant={'body1'}>
              {selectedImplantType
                ? format.formatImplantOrientation(
                    selectedImplantType?.orientation as ImplantOrientation,
                  )
                : ''}
            </Typography>
          </Box>
        </Box>
      ) : null}
      {isTlifCArticulatingStarted &&
      [ImplantType.TLIFCA].includes(selectedImplantType?.implantType as ImplantType) ? (
        <Box display={'flex'} alignItems={'center'} my={2}>
          <Box display={'flex'} width={250} justifyContent={'left'}>
            <Typography variant={'body1'}>
              <strong>Thread Angle:</strong>
            </Typography>
          </Box>
          <Box display={'flex'} width={500} justifyContent={'left'}>
            <Typography variant={'body1'}>
              {selectedImplantType
                ? format.formatImplantOrientation(
                    selectedImplantType?.orientation as ImplantOrientation,
                  )
                : ''}
            </Typography>
          </Box>
        </Box>
      ) : null}
      {selectedImplantType?.implantType === ImplantType.ALIFX ? (
        <>
          <Box display={'flex'} alignItems={'center'} my={2}>
            <Box display={'flex'} width={250} justifyContent={'left'}>
              <Typography variant={'body1'}>
                <strong>Screw Orientation:</strong>
              </Typography>
            </Box>
            <Box display={'flex'} width={500} justifyContent={'left'}>
              <Typography variant={'body1'}>
                {selectedImplantType
                  ? format.formatImplantOrientation(
                      selectedImplantType?.orientation as ImplantOrientation,
                    )
                  : ''}
              </Typography>
            </Box>
          </Box>
          {selectedImplantType?.orientation === ImplantOrientation.LevelSpecific ? (
            <>
              <Box display={'flex'} alignItems={'center'} my={2}>
                <Box display={'flex'} width={250} justifyContent={'left'} pl={2}>
                  <Typography variant={'body1'}>
                    <strong>L3/L4:</strong>
                  </Typography>
                </Box>
                <Box display={'flex'} width={500} justifyContent={'left'}>
                  <Typography variant={'body1'}>
                    {selectedImplantType?.orientationByLevels?.L3_L4 ? (
                      format.formatImplantOrientation(
                        selectedImplantType?.orientationByLevels?.L3_L4 as ImplantOrientation,
                      )
                    ) : (
                      <span>&mdash;</span>
                    )}
                  </Typography>
                </Box>
              </Box>

              <Box display={'flex'} alignItems={'center'} my={2}>
                <Box display={'flex'} width={250} justifyContent={'left'} pl={2}>
                  <Typography variant={'body1'}>
                    <strong>L4/L5:</strong>
                  </Typography>
                </Box>
                <Box display={'flex'} width={500} justifyContent={'left'}>
                  <Typography variant={'body1'}>
                    {selectedImplantType?.orientationByLevels?.L4_L5 ? (
                      format.formatImplantOrientation(
                        selectedImplantType?.orientationByLevels?.L4_L5 as ImplantOrientation,
                      )
                    ) : (
                      <span>&mdash;</span>
                    )}
                  </Typography>
                </Box>
              </Box>
              <Box display={'flex'} alignItems={'center'} my={2}>
                <Box display={'flex'} width={250} justifyContent={'left'} pl={2}>
                  <Typography variant={'body1'}>
                    <strong>L5/S1:</strong>
                  </Typography>
                </Box>
                <Box display={'flex'} width={500} justifyContent={'left'}>
                  <Typography variant={'body1'}>
                    {selectedImplantType?.orientationByLevels?.L5_S1 ? (
                      format.formatImplantOrientation(
                        selectedImplantType?.orientationByLevels?.L5_S1 as ImplantOrientation,
                      )
                    ) : (
                      <span>&mdash;</span>
                    )}
                  </Typography>
                </Box>
              </Box>
            </>
          ) : null}
          <Box display={'flex'} alignItems={'center'} my={2}>
            <Box display={'flex'} width={250} justifyContent={'left'}>
              <Typography variant={'body1'}>
                <strong>Screw Length:</strong>
              </Typography>
            </Box>
            <Box display={'flex'} width={500} justifyContent={'left'}>
              <Typography variant={'body1'}>
                {selectedImplantType?.screwLength ? (
                  format.formatInterbodyScrewLength(selectedImplantType?.screwLength)
                ) : (
                  <span>&mdash;</span>
                )}
              </Typography>
            </Box>
          </Box>
        </>
      ) : null}
      {[ImplantType.ALIF, ImplantType.ALIFX, ImplantType.LLIF].includes(
        selectedImplantType?.implantType as ImplantType,
      ) ? (
        <Box display={'flex'} alignItems={'center'} my={2}>
          <Box display={'flex'} width={250} justifyContent={'left'}>
            <Typography variant={'body1'}>
              <strong>Maximize Footprint:</strong>
            </Typography>
          </Box>
          <Box display={'flex'} width={500} justifyContent={'left'}>
            <Typography variant={'body1'}>
              {selectedImplantType?.maximizeFootprint ? 'Yes' : 'No'}
            </Typography>
          </Box>
        </Box>
      ) : null}
      <Box display={'flex'} alignItems={'center'} my={2}>
        <Box display={'flex'} width={250} justifyContent={'left'}>
          <Typography variant={'body1'}>
            <strong>Plus Size Implant:</strong>
          </Typography>
        </Box>
        <Box display={'flex'} width={500} justifyContent={'left'}>
          <Typography variant={'body1'}>
            {selectedImplantType?.plusSizeImplant ? (
              `+${selectedImplantType?.plusSizeImplant}mm`
            ) : (
              <span>&mdash;</span>
            )}
          </Typography>
        </Box>
      </Box>
      {hasFeatureFlag?.(FeatureFlag.implantSizeExclusionEnabled) ? (
        <Box display={'flex'} alignItems={'center'} my={2}>
          <Box display={'flex'} width={250} justifyContent={'left'}>
            <Typography variant={'body1'}>
              <strong>Excluded Implant Sizes:</strong>
            </Typography>
          </Box>
          <Box display={'flex'} width={500} justifyContent={'left'}>
            <Typography variant={'body1'}>
              {' '}
              {
                <>
                  {(selectedImplantType?.excludedImplantSizes as LevelSize[])
                    .map(format.formatLevelSize)
                    .join(', ')}
                  {selectedImplantType?.excludedImplantSizes?.length === 0 ? (
                    <span>&mdash;</span>
                  ) : null}
                </>
              }{' '}
            </Typography>
          </Box>
        </Box>
      ) : null}
      <Box display={'flex'} alignItems={'center'} my={2}>
        <Box display={'flex'} width={250} justifyContent={'left'}>
          <Typography variant={'body1'}>
            <strong>Minimal Height Specification:</strong>
          </Typography>
        </Box>
        <Box display={'flex'} width={500} justifyContent={'left'}>
          <Typography variant={'body1'}>
            {selectedImplantType?.minimalHeightSpecification ? 'Yes' : 'No'}
          </Typography>
        </Box>
      </Box>
      <Box display={'flex'} alignItems={'flex-start'} my={2}>
        <Box display={'flex'} width={250} justifyContent={'left'}>
          <Typography variant={'body1'}>
            <strong>Procedure Note:</strong>
          </Typography>
        </Box>
        <Box display={'flex'} width={500} justifyContent={'left'}>
          <Typography variant={'body1'} whiteSpace={'pre-wrap'}>
            {selectedImplantType?.note ? selectedImplantType?.note : <span>&mdash;</span>}
          </Typography>
        </Box>
      </Box>
    </>
  );
}
