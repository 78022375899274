import React, { Dispatch, useState } from 'react';
import { format, IRegion, OrganizationType } from '@workflow-nx/common';
import { Box, Button } from '@mui/material';
import { Select } from '../../../components/Select';
import { useQuery } from '@apollo/client';
import { FIND_REGIONS } from '../../../gql';
import { ListOrganizationsActionType, ListOrganizationsStateType } from './OrganizationListTable';

const organizationTypes = [
  {
    key: OrganizationType.Distributor,
    value: format.formatOrganizationType(OrganizationType.Distributor),
  },
  {
    key: OrganizationType.Main,
    value: format.formatOrganizationType(OrganizationType.Main),
  },
  {
    key: OrganizationType.Manufacturer,
    value: format.formatOrganizationType(OrganizationType.Manufacturer),
  },
  {
    key: OrganizationType.Vendor,
    value: format.formatOrganizationType(OrganizationType.Vendor),
  },
];

export function OrganizationTableFilter(props: {
  state: ListOrganizationsStateType;
  loading: boolean;
  dispatch: Dispatch<ListOrganizationsActionType>;
}) {
  const [regions, setRegions] = useState<
    {
      key: string;
      value: string;
    }[]
  >([]);

  useQuery(FIND_REGIONS, {
    fetchPolicy: 'network-only',
    onCompleted: (regionData) => {
      const regions: {
        key: string;
        value: string;
      }[] = (regionData.regions || []).map((region: IRegion) => {
        return { key: region.regionId, value: region.name };
      });
      setRegions(regions);
    },
  });

  return (
    <Box display={'flex'} flexDirection={'column'} gap={2}>
      <Box>
        <Select
          name={'organizationType'}
          label={'Org. Type'}
          value={props.state.organizationType.toString()}
          onChange={(event) => {
            props.dispatch({
              type: 'ORGANIZATION_TYPE_CHANGED',
              data: event.target.value,
            });
          }}
          menuItems={organizationTypes}
          disabled={props.loading}
          fullWidth={true}
        />
      </Box>
      <Box>
        <Select
          name={'regionId'}
          label={'Region'}
          value={props.state.regionId?.toString() ?? ''}
          onChange={(event) => {
            props.dispatch({
              type: 'REGION_CHANGED',
              data: event.target.value,
            });
          }}
          menuItems={regions}
          disabled={props.loading}
          fullWidth={true}
        />
      </Box>
      <Box>
        <Box textAlign={'center'}>
          <Button onClick={() => props.dispatch({ type: 'RESET_FILTERS' })}>Reset Filters</Button>
        </Box>
      </Box>
    </Box>
  );
}
