import React from 'react';
import { ImplantType } from '@workflow-nx/common';
import { Table, TableBody, TableHead, TableRow } from '@mui/material';

import { SurgeonPreferenceImplantMeasurementType } from './EditSurgeonPreferences.reducer';
import { TableHeadCell } from '../../../../components/TableHeadCell';
import { SurgeonPreferencesMeasurementTableRow } from './SurgeonPreferencesMeasurementTableRow';

interface SurgeonPreferencesMeasurementTableProps {
  surgeonPreferenceImplantMeasurements: SurgeonPreferenceImplantMeasurementType[];
  selectedImplantType: ImplantType;
  implantTypeIndex: number;
  disabled: boolean;
}

export function SurgeonPreferencesMeasurementEditTable({
  surgeonPreferenceImplantMeasurements,
  selectedImplantType,
  implantTypeIndex,
  disabled,
}: SurgeonPreferencesMeasurementTableProps) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeadCell>Measurement Type</TableHeadCell>
          <TableHeadCell>Min</TableHeadCell>
          <TableHeadCell>Max</TableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {surgeonPreferenceImplantMeasurements?.map(
          (
            implantMeasurement: SurgeonPreferenceImplantMeasurementType,
            measurementTypeIndex: number,
          ) => {
            return implantMeasurement?.implantType === selectedImplantType ? (
              <SurgeonPreferencesMeasurementTableRow
                key={`${implantTypeIndex}.${measurementTypeIndex}`}
                implantType={selectedImplantType}
                measurementType={implantMeasurement?.measurementType}
                measurementTypeIndex={measurementTypeIndex}
                implantTypeIndex={implantTypeIndex}
                disabled={disabled}
              />
            ) : null;
          },
        )}
      </TableBody>
    </Table>
  );
}
