import { DatePicker as MatDatePicker } from '@mui/x-date-pickers';
import { memo } from 'react';
import { Control, FieldValues, useController } from 'react-hook-form';
import { DateTime } from 'luxon';

type DatePickerProps = {
  name: string;
  control: Control<FieldValues>;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  disablePast?: boolean;
  disableFuture?: boolean;
  shrink?: boolean;
  helperText?: boolean;
};

const DatePickerRHF = memo(function ({
  name,
  control,
  label,
  required,
  disablePast,
  disabled,
  shrink,
  helperText,
  disableFuture,
}: DatePickerProps) {
  const {
    field: { onChange: hookFormOnChange, onBlur: hookFormOnBlur, ref, value },
    fieldState: { invalid, error },
  } = useController({ name, control });

  const currentError = error?.message;

  return (
    <MatDatePicker
      closeOnSelect={true}
      label={label}
      disabled={disabled}
      disablePast={disablePast}
      disableFuture={disableFuture}
      inputRef={ref}
      slotProps={{
        textField: {
          variant: 'outlined',
          name: name,
          size: 'small',
          required: required,
          fullWidth: true,
          helperText: helperText ? currentError : undefined,
          InputLabelProps: { shrink },
          onBlur: hookFormOnBlur,
          error: invalid,
        },
      }}
      value={value ? DateTime.fromJSDate(value) : null}
      format="MM/dd/yyyy"
      onError={(error) => {
        if (error !== currentError) {
          control.setError(name, { message: 'Invalid Date' });
        }
      }}
      onChange={(date) => {
        hookFormOnChange(date?.toJSDate() ?? null);
      }}
    />
  );
});

export { DatePickerRHF };
