import { Box, Button, Card, CardActionArea, TextField, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { faChevronCircleDown, faChevronCircleUp } from '@fortawesome/pro-solid-svg-icons';
import React, { useState } from 'react';
import { IconFontButton, ColorSelectField } from '@workflow-nx/ui';
import { useConfirm } from 'material-ui-confirm';
import { useMutation, useQuery } from '@apollo/client';
import { DEFAULT_TAG_CATEGORY_COLOR, ITag, ITagCategory } from '@workflow-nx/common';
import _ from 'lodash';
import {
  CREATE_TAG,
  CREATE_TAG_CATEGORY,
  DELETE_TAG,
  DELETE_TAG_CATEGORY,
  FIND_TAGS,
  FIND_TAG_CATEGORIES,
  UPDATE_TAG,
  UPDATE_TAG_CATEGORY,
} from '../../gql';
import { Select } from '../../components/Select';
import { TagChip } from '../../components/TagChip';
import { useSnackbar } from 'notistack';

type TagForm = {
  label: string;
  tagCategoryId: number | null;
};

type TagCategoryForm = {
  label: string;
  color: string | null;
};

type TagCategoryOption = {
  key: string;
  value: string;
  color: string;
};

type ErrorMessageState = {
  tagForm: string;
  tagCategoryForm: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      listStyle: 'none',
      padding: theme.spacing(0.5),
      margin: 0,
    },
    chip: {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    errorButton: {
      color: '#f60258',
      border: '1px solid rgba(246, 2, 88, 0.60)',
      '&:hover': {
        border: '1px solid rgba(246, 2, 88, 0.60)',
      },
    },
    italicized: {
      fontStyle: 'italic',
    },
    selectedTagCategory: {
      backgroundColor: '#f5f5f5',
    },
  }),
);

const initialTagFormState: TagForm = {
  label: '',
  tagCategoryId: null,
};

const initialTagCategoryFormState: TagCategoryForm = {
  label: '',
  color: DEFAULT_TAG_CATEGORY_COLOR,
};

const initialErrorMessageState: ErrorMessageState = {
  tagCategoryForm: '',
  tagForm: '',
};

const TagsForm: React.FC = () => {
  const classes = useStyles();

  const confirm = useConfirm();

  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);

  const [errorMessage, setErrorMessage] = useState<ErrorMessageState>(initialErrorMessageState);

  const [tagForm, setTagForm] = useState<TagForm>(initialTagFormState);

  const [tagCategoryForm, setTagCategoryForm] = useState<TagCategoryForm>(
    initialTagCategoryFormState,
  );

  const [tagChipData, setTagChipData] = React.useState<ITag[]>([]);

  const [tagCategoryOptions, setTagCategoryOptions] = React.useState<TagCategoryOption[]>([]);

  const [selectedTag, setSelectedTag] = React.useState<ITag>();

  const [selectedTagCategory, setSelectedTagCategory] = React.useState<TagCategoryOption>();

  const { refetch: refetchTags, loading: tagsLoading } = useQuery(FIND_TAGS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: () => {
      enqueueSnackbar('Failed to fetch tags', { variant: 'error' });
    },
    onCompleted: (tagsData) => {
      if (!tagsData || !tagsData.tags) return;

      setTagChipData(tagsData.tags);

      // Upon category deletion, a tag with that category may no longer be returned
      // Ensure the selected tag is deselected
      const selectedTagExists = tagsData.tags.find((tag: ITag) => tag.tagId === selectedTag?.tagId);

      if (!selectedTagExists) {
        setSelectedTag(undefined);

        setTagForm(initialTagFormState);
      }
    },
  });

  const { refetch: refetchTagCategories, loading: tagCategoriesLoading } = useQuery(
    FIND_TAG_CATEGORIES,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onError: () => {
        enqueueSnackbar('Failed to fetch tag categories', { variant: 'error' });
      },
      onCompleted: (tagCategoriesData) => {
        if (!tagCategoriesData || !tagCategoriesData.tagCategories) return;

        setTagCategoryOptions(
          tagCategoriesData.tagCategories.map((tagCategory: ITagCategory) => ({
            key: tagCategory.tagCategoryId,
            value: tagCategory.label,
            color: tagCategory.color || DEFAULT_TAG_CATEGORY_COLOR,
          })),
        );
      },
    },
  );

  const [createTag, { loading: createTagPending }] = useMutation(CREATE_TAG);

  const [updateTag, { loading: updateTagPending }] = useMutation(UPDATE_TAG);

  const [deleteTag] = useMutation(DELETE_TAG);

  const [createTagCategory, { loading: createTagCategoryPending }] =
    useMutation(CREATE_TAG_CATEGORY);

  const [updateTagCategory, { loading: updateTagCategoryPending }] =
    useMutation(UPDATE_TAG_CATEGORY);

  const [deleteTagCategory] = useMutation(DELETE_TAG_CATEGORY);

  const handleDisableTag = (tagToDisable: ITag) => async () => {
    try {
      await confirm({
        title: `Disable Tag`,
        description: `Are you sure you want to disable the tag "${tagToDisable.label}"?`,
      });

      await deleteTag({
        variables: {
          tagId: tagToDisable.tagId,
        },
      });

      setSelectedTag(undefined);

      setTagForm(initialTagFormState);

      setErrorMessage((prevErrors) => ({
        ...prevErrors,
        tagForm: '',
      }));

      await refetchTags();
    } catch (e) {
      console.error(e);

      enqueueSnackbar(`Failed to delete tag "${selectedTag?.label}"`, {
        variant: 'error',
      });
    }
  };

  const handleCreateTag = async () => {
    try {
      if (!tagForm.label || selectedTag) return;

      const duplicateTag = tagChipData.find(
        (tag) => tag.label.toLowerCase() === tagForm.label.toLowerCase(),
      );

      if (duplicateTag) {
        setErrorMessage((prevError) => ({
          ...prevError,
          tagForm: `A tag with the label "${tagForm.label}" already exists`,
        }));
      } else {
        await createTag({
          variables: {
            label: tagForm.label,
            tagCategoryId: tagForm.tagCategoryId,
          },
        });

        await refetchTags();

        setErrorMessage((prevError) => ({
          ...prevError,
          tagForm: '',
        }));

        setTagForm(initialTagFormState);
      }
    } catch (e) {
      console.error(e);
      enqueueSnackbar(`Failed to create tag "${tagForm?.label}"`, {
        variant: 'error',
      });
    }
  };

  const handleUpdateTag = async () => {
    try {
      if (!tagForm.label || !selectedTag) return;

      const duplicateTag = tagChipData.find(
        (tag) =>
          tag.label.toLowerCase() === tagForm.label.toLowerCase() &&
          tag.tagId !== selectedTag.tagId,
      );

      if (duplicateTag) {
        setErrorMessage((prevError) => ({
          ...prevError,
          tagForm: `A tag with the label "${tagForm.label}" already exists`,
        }));
      } else {
        const newTagData = await updateTag({
          variables: {
            tagId: selectedTag.tagId,
            label: tagForm.label,
            tagCategoryId: tagForm.tagCategoryId,
          },
        });

        await refetchTags();

        setErrorMessage((prevError) => ({
          ...prevError,
          tagForm: '',
        }));

        const newTag = newTagData.data.updateTag.tag;

        setSelectedTag(newTag);
      }
    } catch (e) {
      console.error(e);

      enqueueSnackbar(`Failed to update tag "${selectedTag?.label}"`, {
        variant: 'error',
      });
    }
  };

  const handleUpdateTagForm = (field: keyof typeof tagForm, val: string) => {
    setTagForm((prevForm) => ({ ...prevForm, [field]: val }));
  };

  const handleUpdateTagCategoryForm = (field: keyof typeof tagCategoryForm, val: string | null) => {
    setTagCategoryForm((prevForm) => ({ ...prevForm, [field]: val }));
  };

  const handleUpdateTagFormCategory = (tagCategoryId: string) => {
    const newTagCategoryId = tagCategoryOptions.find((o) => o.key === tagCategoryId)
      ? Number(tagCategoryId)
      : null;

    setTagForm((prevForm) => ({
      ...prevForm,
      tagCategoryId: newTagCategoryId,
    }));
  };

  const handleSelectTag = (tagData: ITag) => {
    setSelectedTag(tagData);

    setTagForm({
      label: tagData.label,
      tagCategoryId: tagData.tagCategory?.tagCategoryId || null,
    });

    setErrorMessage((prevErrors) => ({
      ...prevErrors,
      tagForm: '',
    }));
  };

  const handleUnselectTag = () => {
    setSelectedTag(undefined);

    setTagForm(initialTagFormState);

    setErrorMessage((prevErrors) => ({
      ...prevErrors,
      tagForm: '',
    }));
  };

  const handleSelectTagCategory = (tagCategoryData: TagCategoryOption) => {
    setSelectedTagCategory(tagCategoryData);

    const newSelectedTagCategory = tagCategoryOptions.find((c) => tagCategoryData.key === c.key);

    if (!newSelectedTagCategory) return;

    setTagCategoryForm({
      label: newSelectedTagCategory.value,
      color: newSelectedTagCategory.color || DEFAULT_TAG_CATEGORY_COLOR,
    });

    setErrorMessage((prevErrors) => ({
      ...prevErrors,
      tagCategoryForm: '',
    }));
  };

  const handleUnselectTagCategory = () => {
    setSelectedTagCategory(undefined);

    setTagCategoryForm(initialTagCategoryFormState);

    setErrorMessage((prevErrors) => ({
      ...prevErrors,
      tagCategoryForm: '',
    }));
  };

  const handleCreateTagCategory = async () => {
    try {
      if (!tagCategoryForm.label || selectedTagCategory) return;

      const duplicateTagCategory = tagCategoryOptions.find(
        (tagCategory) => tagCategory.value.toLowerCase() === tagCategoryForm.label.toLowerCase(),
      );

      if (duplicateTagCategory) {
        setErrorMessage((prevError) => ({
          ...prevError,
          tagCategoryForm: `A tag category with the label "${tagCategoryForm.label}" already exists`,
        }));
      } else {
        await createTagCategory({
          variables: {
            label: tagCategoryForm.label,
            color: tagCategoryForm.color,
          },
        });

        await refetchTagCategories();

        setErrorMessage((prevError) => ({
          ...prevError,
          tagCategoryForm: '',
        }));

        setTagCategoryForm(initialTagCategoryFormState);
      }
    } catch (e) {
      console.error(e);

      enqueueSnackbar(`Failed to create tag category "${selectedTagCategory?.value}"`, {
        variant: 'error',
      });
    }
  };

  const handleUpdateTagCategory = async () => {
    try {
      if (!tagCategoryForm.label || !selectedTagCategory) return;

      const duplicateTagCategory = tagCategoryOptions.find(
        (tagCategory) =>
          tagCategory.value.toLowerCase() === tagCategoryForm.label.toLowerCase() &&
          tagCategory.key !== selectedTagCategory.key,
      );

      if (duplicateTagCategory) {
        setErrorMessage((prevError) => ({
          ...prevError,
          tagCategoryForm: `A tag category with the label "${tagCategoryForm.label}" already exists`,
        }));
      } else {
        const newTagCategory = {
          tagCategoryId: selectedTagCategory.key,
          label: tagCategoryForm.label,
          color: tagCategoryForm.color,
        };

        await updateTagCategory({
          variables: newTagCategory,
        });

        await Promise.all([refetchTagCategories(), refetchTags()]);

        setErrorMessage((prevError) => ({
          ...prevError,
          tagCategoryForm: '',
        }));

        setSelectedTagCategory({
          key: selectedTagCategory.key,
          value: tagCategoryForm.label,
          color: tagCategoryForm.color || DEFAULT_TAG_CATEGORY_COLOR,
        });
      }
    } catch (e) {
      console.error(e);

      enqueueSnackbar(`Failed to update tag category "${selectedTagCategory?.value}"`, {
        variant: 'error',
      });
    }
  };

  const handleDisableTagCategory = (tagCategoryToDisable: TagCategoryOption) => async () => {
    try {
      await confirm({
        title: `Disable Tag Category`,
        description: `Are you sure you want to disable the tag category "${tagCategoryToDisable.value}"?`,
      });

      await deleteTagCategory({
        variables: {
          tagCategoryId: tagCategoryToDisable.key,
        },
      });

      setSelectedTagCategory(undefined);

      setTagCategoryForm(initialTagCategoryFormState);

      setErrorMessage((prevErrors) => ({
        ...prevErrors,
        tagCategoryForm: '',
      }));

      await Promise.all([refetchTagCategories(), refetchTags()]);
    } catch (e) {
      console.error(e);

      enqueueSnackbar(`Failed to delete tag category "${tagCategoryToDisable.value}"`, {
        variant: 'error',
      });
    }
  };

  const tagSubmitDisabled = !tagForm.label || tagsLoading || createTagPending || updateTagPending;

  const selectedTagUpdated = !(
    selectedTag?.label === tagForm.label && selectedTag?.tagCategoryId === tagForm.tagCategoryId
  );

  const tagUpdateEnabled = !tagSubmitDisabled && selectedTagUpdated;

  const tagCategorySubmitDisabled =
    !tagCategoryForm.label ||
    tagCategoriesLoading ||
    createTagCategoryPending ||
    updateTagCategoryPending;

  const selectedTagCategoryUpdated = !(
    selectedTagCategory?.value === tagCategoryForm.label &&
    selectedTagCategory?.color === tagCategoryForm.color
  );

  const tagCategoryUpdateEnabled = !tagCategorySubmitDisabled && selectedTagCategoryUpdated;

  return (
    <>
      <Box>
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
          <Typography variant={'h4'}>Configure Tags</Typography>
          <Box>
            <IconFontButton
              icon={open ? faChevronCircleUp : faChevronCircleDown}
              onClick={() => setOpen(!open)}
            />
          </Box>
        </Box>
        {open && (
          <Box my={3}>
            {/* Tag Category */}
            <Box my={4}>
              <Typography variant="h5">Tag Categories</Typography>
              <Box display="flex" my={2} alignItems="center">
                <Box mr={2} width={400}>
                  <TextField
                    label="Label"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    value={tagCategoryForm.label}
                    error={Boolean(errorMessage.tagCategoryForm)}
                    helperText={errorMessage.tagCategoryForm}
                    onChange={(e) => handleUpdateTagCategoryForm('label', e.currentTarget.value)}
                  />
                </Box>
                <Box mr={2} width={150}>
                  <ColorSelectField
                    handleChange={(color) => handleUpdateTagCategoryForm('color', color.hex)}
                    color={tagCategoryForm.color || DEFAULT_TAG_CATEGORY_COLOR}
                  />
                </Box>

                <Box display="flex">
                  {selectedTagCategory ? (
                    <>
                      <Box mr={2}>
                        <Button
                          variant="outlined"
                          color="primary"
                          disabled={!tagCategoryUpdateEnabled}
                          onClick={handleUpdateTagCategory}
                        >
                          Update
                        </Button>
                      </Box>
                      <Box mr={2}>
                        <Button
                          variant="outlined"
                          color="secondary"
                          className={classes.errorButton}
                          onClick={handleDisableTagCategory(selectedTagCategory)}
                        >
                          Delete
                        </Button>
                      </Box>
                      <Box>
                        <Button variant="text" color="primary" onClick={handleUnselectTagCategory}>
                          Cancel
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <Button
                      variant="outlined"
                      color="primary"
                      disabled={tagCategorySubmitDisabled}
                      onClick={handleCreateTagCategory}
                    >
                      Create
                    </Button>
                  )}
                </Box>
              </Box>
              <Box my={2}>
                <Box my={2} display="flex" flexWrap="wrap">
                  {tagCategoryOptions.length ? (
                    _.sortBy(tagCategoryOptions, 'key').map((data) => {
                      return (
                        <Box width={'fit-content'} mr={2} mb={2} key={data.key}>
                          <Card
                            className={
                              selectedTagCategory?.key === data.key
                                ? classes.selectedTagCategory
                                : undefined
                            }
                          >
                            <CardActionArea
                              disabled={selectedTagCategory?.key === data.key}
                              onClick={() => handleSelectTagCategory(data)}
                            >
                              <Box display="flex" alignItems={'center'} p={2}>
                                <Box
                                  sx={{
                                    width: 20,
                                    height: 20,
                                    borderRadius: 5,
                                    mr: 1,
                                    border: '1px solid #b5b5b5',
                                    bgcolor: data.color || DEFAULT_TAG_CATEGORY_COLOR,
                                  }}
                                />
                                <Typography>{data.value}</Typography>
                              </Box>
                            </CardActionArea>
                          </Card>
                        </Box>
                      );
                    })
                  ) : (
                    <Typography variant="body1" className={classes.italicized}>
                      No tag categories found.
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
            {/* Tag */}
            <Box my={4}>
              <Typography variant="h5">Tags</Typography>

              <Box display="flex" my={2} alignItems="center">
                <Box mr={2} width={400}>
                  <TextField
                    label="Label"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    value={tagForm.label}
                    error={Boolean(errorMessage.tagForm)}
                    helperText={errorMessage.tagForm}
                    onChange={(e) => handleUpdateTagForm('label', e.currentTarget.value)}
                  />
                </Box>
                <Box mr={2} width={150}>
                  <Select
                    disabled={tagCategoriesLoading}
                    variant="outlined"
                    name="tagCategory"
                    label="Category"
                    value={tagForm.tagCategoryId?.toString() || ''}
                    onChange={(e) => handleUpdateTagFormCategory(e.target.value)}
                    fullWidth
                    menuItems={tagCategoryOptions}
                  />
                </Box>
                <Box display="flex">
                  {selectedTag ? (
                    <>
                      <Box mr={2}>
                        <Button
                          variant="outlined"
                          color="primary"
                          disabled={!tagUpdateEnabled}
                          onClick={handleUpdateTag}
                        >
                          Update
                        </Button>
                      </Box>
                      <Box mr={2}>
                        <Button
                          variant="outlined"
                          color="secondary"
                          className={classes.errorButton}
                          onClick={handleDisableTag(selectedTag)}
                        >
                          Delete
                        </Button>
                      </Box>
                      <Box>
                        <Button variant="text" color="primary" onClick={handleUnselectTag}>
                          Cancel
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <Button
                      variant="outlined"
                      color="primary"
                      disabled={tagSubmitDisabled}
                      onClick={handleCreateTag}
                    >
                      Create
                    </Button>
                  )}
                </Box>
              </Box>

              <Box my={2}>
                <Box my={2} flexWrap="wrap">
                  {tagChipData.length ? (
                    _.sortBy(tagChipData, 'tagCategoryId').map((data) => {
                      return (
                        <TagChip
                          tag={data}
                          onClick={() => handleSelectTag(data)}
                          key={data.tagId}
                          className={classes.chip}
                          disabled={selectedTag?.tagId === data.tagId}
                        />
                      );
                    })
                  ) : (
                    <Typography variant="body1" className={classes.italicized}>
                      No tags found.
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default TagsForm;
