import { AssetType, CaseStageType, IAsset, ICase } from '@workflow-nx/common';

export type AssetsTableActionType = {
  type: 'ASSETS_TYPE_CHANGED' | 'SHOW_DELETED_TOGGLE_CHANGED' | 'RESET_FILTERS' | 'refetch';
  data?: any;
};

export type AssetsTabStateType = {
  assetTypeFilter?: AssetType[];
  showDeleted?: boolean;
};

export const defaultAssetTypeFilter: AssetsTabStateType['assetTypeFilter'] = [
  AssetType.DicomCt,
  AssetType.DicomXray,
  AssetType.DicomMri,
  AssetType.PostOpSixWeeksXray,
];

export const getAssetPageUrl = (activeCase: ICase, asset: IAsset) => {
  let stage: CaseStageType | undefined = undefined;

  switch (asset.assetType) {
    case AssetType.DicomCt:
    case AssetType.DicomXray:
    case AssetType.DicomMri:
    case AssetType.L1:
    case AssetType.L2:
    case AssetType.L3:
    case AssetType.L4:
    case AssetType.L5:
    case AssetType.L6:
    case AssetType.S1:
    case AssetType.L1Daisy:
    case AssetType.L2Daisy:
    case AssetType.L3Daisy:
    case AssetType.L4Daisy:
    case AssetType.L5Daisy:
    case AssetType.L6Daisy:
    case AssetType.S1Daisy:
      if (asset.planId) {
        stage = CaseStageType.Planning;
      } else {
        stage = CaseStageType.Segmentation;
      }
      break;
    case AssetType.L1L2App:
    case AssetType.L2L3App:
    case AssetType.L3L4App:
    case AssetType.L4L5App:
    case AssetType.L5S1App:
    case AssetType.L5L6App:
    case AssetType.L6S1App:
    case AssetType.L4S1App:
    case AssetType.L1L2Data:
    case AssetType.L2L3Data:
    case AssetType.L3L4Data:
    case AssetType.L4L5Data:
    case AssetType.L5S1Data:
    case AssetType.L5L6Data:
    case AssetType.L6S1Data:
    case AssetType.L4S1Data:
    case AssetType.L1L2ImplantMeasurementImage:
    case AssetType.L2L3ImplantMeasurementImage:
    case AssetType.L3L4ImplantMeasurementImage:
    case AssetType.L4L5ImplantMeasurementImage:
    case AssetType.L5S1ImplantMeasurementImage:
    case AssetType.L5L6ImplantMeasurementImage:
    case AssetType.L6S1ImplantMeasurementImage:
    case AssetType.L4S1ImplantMeasurementImage:
    case AssetType.PlanSummaryApp:
    case AssetType.PlanSummaryFormApp:
      stage = CaseStageType.Planning;
      break;
    case AssetType.Form19ApprovalSignature:
      stage = CaseStageType.Proposed;
      break;
    case AssetType.L1L2:
    case AssetType.L1L2Dimensions:
    case AssetType.L1L2PlusDimensions:
    case AssetType.L1L2Plus:
    case AssetType.L1L2Minus:
    case AssetType.L1L2MinusDimensions:
    case AssetType.L2L3:
    case AssetType.L2L3Dimensions:
    case AssetType.L2L3PlusDimensions:
    case AssetType.L2L3Plus:
    case AssetType.L2L3Minus:
    case AssetType.L2L3MinusDimensions:
    case AssetType.L3L4:
    case AssetType.L3L4Dimensions:
    case AssetType.L3L4PlusDimensions:
    case AssetType.L3L4Plus:
    case AssetType.L3L4Minus:
    case AssetType.L3L4MinusDimensions:
    case AssetType.L4L5:
    case AssetType.L4L5Dimensions:
    case AssetType.L4L5PlusDimensions:
    case AssetType.L4L5Plus:
    case AssetType.L4L5Minus:
    case AssetType.L4L5MinusDimensions:
    case AssetType.L5S1:
    case AssetType.L5S1Dimensions:
    case AssetType.L5S1PlusDimensions:
    case AssetType.L5S1Plus:
    case AssetType.L5S1Minus:
    case AssetType.L5S1MinusDimensions:
    case AssetType.L4S1:
    case AssetType.L4S1Dimensions:
    case AssetType.L4S1PlusDimensions:
    case AssetType.L4S1Plus:
    case AssetType.L4S1Minus:
    case AssetType.L4S1MinusDimensions:
    case AssetType.L5L6:
    case AssetType.L5L6Dimensions:
    case AssetType.L5L6PlusDimensions:
    case AssetType.L5L6Plus:
    case AssetType.L5L6Minus:
    case AssetType.L5L6MinusDimensions:
    case AssetType.L6S1:
    case AssetType.L6S1Dimensions:
    case AssetType.L6S1PlusDimensions:
    case AssetType.L6S1Plus:
    case AssetType.L6S1Minus:
    case AssetType.L6S1MinusDimensions:
    case AssetType.Form18:
    case AssetType.Form19:
    case AssetType.RodTemplateLeft:
    case AssetType.RodTemplateRight:
      stage = CaseStageType.Design;
      break;
  }

  if (stage) {
    return `/app/cases/${activeCase.caseId}#case-${stage.toLowerCase()}`;
  }

  if (
    [
      AssetType.PostOpSixWeeksXray,
      AssetType.PostOpOneYear,
      AssetType.PostOpIntraOp,
      AssetType.PostOpSixMonthsXray,
    ].includes(asset.assetType)
  ) {
    return `/app/post-op-analysis/${activeCase.caseId}`;
  }

  return null;
};

export const AssetsTabReducer =
  (refetch: any) =>
  (state: AssetsTabStateType, action: AssetsTableActionType): AssetsTabStateType => {
    let updatedState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
      case 'refetch':
        refetch();
        break;
      case 'RESET_FILTERS':
        updatedState = {
          assetTypeFilter: defaultAssetTypeFilter,
        };
        break;
      case 'ASSETS_TYPE_CHANGED':
        updatedState.assetTypeFilter = action.data ? action.data : undefined;
        break;
      case 'SHOW_DELETED_TOGGLE_CHANGED':
        updatedState.showDeleted = action.data || false;
        break;
    }

    refetch({
      assetTypeFilter: updatedState.assetTypeFilter,
      showDeleted: updatedState.showDeleted,
    });

    return updatedState;
  };
