import React, { Dispatch } from 'react';
import { Box, Checkbox, TableBody, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { format, ICase, ITableHeader, CaseEventsType } from '@workflow-nx/common';
import { TableCell } from '../../../../components/TableCell';
import { blue, red } from '@mui/material/colors';
import { CommonTable } from '../../../../components/CommonTable';
import { ListCasesActionType } from '../ListCases.reducer';
import { useNavigate } from 'react-router-dom';
import { Globals } from '../../../../layouts/DashboardLayout';

type CaseEventsListProps = {
  caseEvents: CaseEventsType[];
  page?: number;
  rowsPerPage?: number;
  totalCaseEventCount?: number;
  orderBy?: { [key: string]: 'asc' | 'desc' };
  dispatch?: Dispatch<ListCasesActionType>;
  shouldHighlight?: (activeCase: ICase) => boolean;
  loading?: boolean;
  selectedCases: string[];
  onSelected: (selectedCases: string[]) => void;
};

const useStyles = makeStyles(() => ({
  highlightedRow: {
    backgroundColor: blue[50],
  },
  cancelledRow: {
    backgroundColor: red[50],
  },
  row: {
    height: 50,
    '& :hover': {
      cursor: 'pointer',
    },
  },
}));

const CaseEventsListTable = ({
  caseEvents,
  totalCaseEventCount,
  page,
  rowsPerPage,
  orderBy,
  dispatch,
  loading,
  selectedCases,
  onSelected,
}: CaseEventsListProps) => {
  const navigate = useNavigate();
  const styles = useStyles();

  const isSelected = (caseId: string) => selectedCases.indexOf(caseId) !== -1;

  const handleCheckClick = (event: React.MouseEvent<unknown>, caseId: string) => {
    const newSelected: string[] = [];

    if (selectedCases.indexOf(caseId) === -1) {
      newSelected.push(...selectedCases, caseId);
    } else if (selectedCases.indexOf(caseId) === 0) {
      newSelected.push(...selectedCases.slice(1));
    } else if (selectedCases.indexOf(caseId) === selectedCases.length - 1) {
      newSelected.push(...selectedCases.slice(0, -1));
    } else if (selectedCases.indexOf(caseId) > 0) {
      newSelected.push(
        ...selectedCases.slice(0, selectedCases.indexOf(caseId)),
        ...selectedCases.slice(selectedCases.indexOf(caseId) + 1),
      );
    }

    onSelected(newSelected);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = caseEvents?.map((n) => n.caseId.toString());
      onSelected(newSelected);
      return;
    }
    onSelected([]);
  };

  const headers: ITableHeader[] = [
    {
      id: 'bulkChange',
      sortable: false,
      label: 'Bulk Change',
      customLabel: (
        <Checkbox
          disabled={false}
          id={'eventBulkChange'}
          name={'Event Bulk Change'}
          onChange={handleSelectAllClick}
          checked={selectedCases.length > 0 && selectedCases.length === caseEvents?.length}
          indeterminate={selectedCases.length > 0 && selectedCases.length < caseEvents?.length}
          size={'small'}
        />
      ),
    },
    {
      id: 'number',
      sortable: true,
      label: 'Case #',
    },
    {
      id: 'events',
      sortable: true,
      label: 'Production Events',
      align: 'center',
    },
    {
      id: 'occurredAt',
      sortable: true,
      label: 'Occurred At',
      align: 'center',
    },
    {
      id: 'status',
      sortable: true,
      label: 'Shipping Status',
      align: 'center',
    },
    {
      id: 'surgeryDate',
      sortable: true,
      label: 'Surgery Date',
      align: 'center',
    },
  ];

  const showPagination = Boolean(dispatch && rowsPerPage && page !== undefined);

  return (
    <CommonTable
      orderBy={orderBy}
      headers={headers}
      onHeaderClick={(orderBy) => {
        if (dispatch) {
          dispatch({ type: 'ORDER_BY_CHANGED', data: orderBy });
        }
      }}
      rows={
        <TableBody>
          {caseEvents?.map((caseEvent: CaseEventsType) => {
            const isCaseSelected = isSelected(caseEvent.caseId.toString());
            return (
              <TableRow
                hover
                key={caseEvent.caseId}
                className={`${styles.row} styles.highlightedRow`}
              >
                <TableCell width={2}>
                  <Checkbox
                    disabled={false}
                    id={'eventBulkChange'}
                    name={'Event Bulk Change'}
                    onClick={(event) => handleCheckClick(event, caseEvent.caseId.toString())}
                    checked={isCaseSelected}
                    size={'small'}
                  />
                </TableCell>
                <TableCell onClick={() => navigate(`/app/cases/${caseEvent.caseId}`)}>
                  <Box display={'flex'} alignItems={'center'}>
                    {caseEvent.number}
                  </Box>
                </TableCell>
                <TableCell onClick={() => navigate(`/app/cases/${caseEvent.caseId}`)}>
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    overflow={'hidden'}
                    textOverflow={'ellipsis'}
                  >
                    {caseEvent.eventType ? (
                      format.formatEventType(caseEvent.eventType)
                    ) : (
                      <>&mdash;</>
                    )}
                  </Box>
                </TableCell>
                <TableCell
                  align={'center'}
                  onClick={() => navigate(`/app/cases/${caseEvent.caseId}`)}
                >
                  <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                    {caseEvent.occurredAt ? (
                      format.formatISODate(caseEvent.occurredAt as unknown as string)
                    ) : (
                      <>&mdash;</>
                    )}
                  </Box>
                </TableCell>
                <TableCell onClick={() => navigate(`/app/cases/${caseEvent.caseId}`)}>
                  <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                    {caseEvent.status ? (
                      format.formatCaseShippingStatusType(caseEvent.status)
                    ) : (
                      <>&mdash;</>
                    )}
                  </Box>
                </TableCell>
                <TableCell
                  align={'center'}
                  onClick={() => navigate(`/app/cases/${caseEvent.caseId}`)}
                >
                  <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                    {caseEvent.surgeryDate
                      ? format.formatISODate(caseEvent.surgeryDate as unknown as string)
                      : 'TBD'}
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      }
      loading={loading}
      paginationConfig={
        showPagination
          ? {
              totalCount: totalCaseEventCount || 0,
              onPaginationChange: (pagination) => {
                dispatch?.({
                  type: 'UPDATE_PAGINATION',
                  data: {
                    pageSize: pagination.rowsPerPage,
                    pageNumber: pagination.page,
                  },
                });
              },
              rowsPerPage: rowsPerPage || Globals.DefaultListPageSize,
              page: page || 0,
              rowsPerPageOptions: Globals.DefaultListPageSizeOptions,
            }
          : undefined
      }
    />
  );
};

export default CaseEventsListTable;
