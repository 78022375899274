import { format, ICaseCancellation } from '@workflow-nx/common';
import { Box } from '@mui/material';
import { Alert, AlertTitle } from '@mui/material';
import React from 'react';

export function CaseCancellationView(props: { caseCancellation: ICaseCancellation }) {
  return (
    <Box mt={1}>
      <Alert severity={'error'}>
        <AlertTitle>Lost Opportunity</AlertTitle>
        Case was{' '}
        <strong>
          {format.formatCaseCancellationType(props?.caseCancellation.cancellationType)}
        </strong>{' '}
        on {format.formatISODate(props?.caseCancellation.cancelledAt?.toString())} for the reason{' '}
        <strong>{format.formatCaseCancellationReasonType(props.caseCancellation)}</strong>.
      </Alert>
    </Box>
  );
}
