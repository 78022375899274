import * as dateFns from 'date-fns';

export async function getFreeAgentAccessToken(
  clientId: string,
  clientSecret: string,
  tokenEndpoint: string,
) {
  const response = await fetch(tokenEndpoint as unknown as URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },

    body: new URLSearchParams({
      grant_type: 'client_credentials',
      client_id: clientId ?? '',
      client_secret: clientSecret ?? '',
    }),
  });

  const data = await response.json();
  return data.access_token;
}

const getHospitalsQuery = `
 query listEntityValues($entity: String!, $fields: [String], $order: [[String]], $limit: Int, $offset: Int, $pattern: String, $filters: [Filter]) {
   listEntityValues(entity: $entity, fields: $fields, order: $order, limit: $limit, offset: $offset, pattern: $pattern, filters: $filters) {
     count
     entity_values {
       id
       seq_id
       field_values
     }
   }
 }
`;

const getHospitalsVariables = {
  entity: 'logo',
  filters: [
    {
      field_name: 'logo_field5',
      operator: 'contains',
      values: ['Healthcare Institution'],
    },
    {
      field_name: 'health_systems_id_ns',
      operator: 'exists',
      values: [],
    },
  ],
  fields: ['name', 'account_short_name', 'health_systems_id_ns', 'updated_at', 'logo_field96'],
};

const dayBeforeDate = dateFns.sub(new Date(), { days: 2 });
//TODO - Replace this as main hospital variable when database has been primed
const getHospitalsVariablesOnlyCheckNewUpdates = {
  entity: 'logo',
  filters: [
    {
      field_name: 'logo_field5',
      operator: 'contains',
      values: ['Healthcare Institution'],
    },
    {
      field_name: 'health_systems_id_ns',
      operator: 'exists',
      values: [],
    },
    {
      field_name: 'updated_at',
      operator: 'between',
      values: [dayBeforeDate, new Date()],
    },
  ],
  fields: ['name', 'health_systems_id_ns', 'updated_at', 'logo_field96'],
};

export async function freeAgentSendRequest(token: string, url: URL) {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      query: getHospitalsQuery,
      variables: getHospitalsVariables,
    }),
  });

  if (response.ok) {
    const data = await response.json();
    if (data?.data?.listEntityValues?.entity_values) {
      return {
        count: data?.data?.listEntityValues?.count,
        entityValues: data?.data?.listEntityValues?.entity_values,
      };
    } else {
      throw new Error(`Cannot find entity_values on response: ${data}`);
    }
  } else {
    throw new Error(`Failed to send request. Status: ${response.status}`);
  }
}
