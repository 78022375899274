import { CaseStageType, caseUtils, IAsset, ICase, IPlan, Permission } from '@workflow-nx/common';
import config from '../../../../extras/config';

type CaseDesignTabActionType = {
  type: 'ASSET_DELETED' | 'ASSET_UPLOADED' | 'INIT' | 'FORM19_UPDATED';
  data: any;
};

type CaseDesignTabStateType = {
  plan?: IPlan;
  planAssets?: IAsset[];
  isEditingAllowed: boolean;
  isStageComplete: boolean;
  canMoveToQaReview: boolean;
  isForm19Approved: boolean;
  form19Data: any;
};

export const designReducerInitialState: CaseDesignTabStateType = {
  canMoveToQaReview: false,
  isEditingAllowed: false,
  isStageComplete: false,
  plan: undefined,
  planAssets: undefined,
  isForm19Approved: false,
  form19Data: undefined,
};

export const CaseDesignTabReducer =
  (activeCase: ICase, permissions?: Permission[]) =>
  (state: CaseDesignTabStateType, action: CaseDesignTabActionType): CaseDesignTabStateType => {
    let updatedState = state;

    switch (action.type) {
      case 'ASSET_DELETED': {
        const deletedAsset = action.data;
        let updatedPlanAssets = JSON.parse(JSON.stringify(state.planAssets));

        updatedPlanAssets = updatedPlanAssets.filter(
          (ca: IAsset) => ca.assetId !== deletedAsset.assetId,
        );

        updatedState = {
          canMoveToQaReview: caseUtils.isCaseStageDesignComplete(
            activeCase.includeRodTemplates,
            activeCase.levels,
            updatedPlanAssets,
            state.isForm19Approved,
            config.featureFlags.designRequireTem013,
          ),
          isEditingAllowed: state.isEditingAllowed,
          isStageComplete: state.isStageComplete,
          plan: state.plan,
          planAssets: updatedPlanAssets,
          isForm19Approved: state.isForm19Approved,
          form19Data: state.form19Data,
        };
        break;
      }
      case 'ASSET_UPLOADED': {
        const updatedPlanAssets = JSON.parse(JSON.stringify(state.planAssets));
        const createdAsset = action.data;

        updatedPlanAssets.push(createdAsset);
        updatedState = {
          canMoveToQaReview: caseUtils.isCaseStageDesignComplete(
            activeCase.includeRodTemplates,
            activeCase.levels,
            updatedPlanAssets,
            state.isForm19Approved,
            config.featureFlags.designRequireTem013,
          ),
          isEditingAllowed: state.isEditingAllowed,
          isStageComplete: state.isStageComplete,
          plan: state.plan,
          planAssets: updatedPlanAssets,
          isForm19Approved: state.isForm19Approved,
          form19Data: state.form19Data,
        };

        break;
      }
      case 'FORM19_UPDATED': {
        const form19Data = action.data;
        const isForm19Approved = !!form19Data?.engineeringApprovedAt;

        updatedState = {
          canMoveToQaReview: caseUtils.isCaseStageDesignComplete(
            activeCase.includeRodTemplates,
            activeCase.levels,
            state.planAssets,
            isForm19Approved,
            config.featureFlags.designRequireTem013,
          ),
          isEditingAllowed: state.isEditingAllowed,
          isStageComplete: state.isStageComplete,
          plan: state.plan,
          planAssets: state.planAssets,
          isForm19Approved,
          form19Data,
        };

        break;
      }
      case 'INIT': {
        const plan = action?.data;
        const planAssets = action?.data?.assets ?? [];
        const stageComplete =
          activeCase.stage !== CaseStageType.Open &&
          activeCase.stage !== CaseStageType.Segmentation &&
          activeCase.stage !== CaseStageType.Planning &&
          activeCase.stage !== CaseStageType.Proposed &&
          activeCase.stage !== CaseStageType.Design;

        const editingAllowed =
          !!permissions?.includes(Permission.ManageCase) ||
          !!permissions?.includes(Permission.EditCase);

        updatedState = {
          canMoveToQaReview: caseUtils.isCaseStageDesignComplete(
            activeCase.includeRodTemplates,
            activeCase.levels,
            planAssets,
            state.isForm19Approved,
            config.featureFlags.designRequireTem013,
          ),
          isEditingAllowed: editingAllowed,
          isStageComplete: stageComplete || !editingAllowed,
          plan,
          planAssets,
          isForm19Approved: state.isForm19Approved,
          form19Data: state.form19Data,
        };
        break;
      }
    }
    return updatedState;
  };
