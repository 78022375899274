import { Accordion, AccordionDetails, Box, Drawer, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ICase, IPlanImplant, LevelType } from '@workflow-nx/common';
import { Scene } from 'babylonjs';
import { useCallback, useContext, useEffect, useState } from 'react';
import CutImplantMetadataOverlay from './CutImplantMetadataOverlay';
import { ImplantEditorDialogContext } from './ImplantEditorDialog.context';
import { BasicControlsView } from './MoveImplantView/BasicControlsView';
import { CutControlsView } from './MoveImplantView/CutControlsView';
import IDEAccordionSummary from './MoveImplantView/IDEAccordionSummary';
import { ImplantVisibilityView } from './MoveImplantView/ImplantControlsView';
import { MiniVisibilityView } from './MoveImplantView/MiniControlsView';
import { SelectedItemType } from './MoveImplantView/MoveImplantView';
import { getImplantHasScrews } from './utils/implantEditor';

const useStyles = makeStyles((theme: Theme) => ({
  improvedScroller: {
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.grey[200],
      borderRadius: 5,
    },
  },
}));

const SecondaryImplantOverlay = (props: {
  activeCase: ICase;
  levelType: LevelType;
  scene: Scene;
  open: boolean;
}) => {
  const context = useContext(ImplantEditorDialogContext);

  const [implant, setImplant] = useState<IPlanImplant | undefined>();

  const styles = useStyles();

  const hasScrews = getImplantHasScrews(implant?.partType);

  const hasAngledInstruments =
    context?.patientRecord?.hasPelvisHighCrest ||
    context?.patientRecord?.hasPelvisHighPelvicIncidence ||
    context?.patientRecord?.hasPelvisOblique;

  const basicControlItems = ['SUPERIOR', 'INFERIOR'];

  const extraControlItems = hasScrews ? ['SCREWS', 'SCREW_GUIDE'] : [];

  const angledInstrumentsItems = hasAngledInstruments ? ['INSTRUMENT', 'PELVIS'] : [];

  const handleLoad = useCallback(async (newLevelType: LevelType) => {
    const foundImplant = await context.findPlanImplant(newLevelType);

    if (!foundImplant) {
      setImplant(undefined);

      return;
    }

    setImplant(foundImplant);
  }, []);

  useEffect(() => {
    handleLoad(props.levelType);
  }, [props.levelType]);

  return (
    <>
      <Drawer
        hideBackdrop
        anchor={'right'}
        open={props.open}
        variant="persistent"
        sx={{
          zIndex: 100000,

          '& .MuiDrawer-root': {
            position: 'relative',
          },
          '& .MuiDrawer-paper': {
            position: 'absolute',
            background: 'transparent',
          },
        }}
      >
        <Box
          data-testid="implant-editor-menu"
          p={1.5}
          className={styles.improvedScroller}
          overflow={'auto'}
          height="100%"
          width={375}
          display={'flex'}
          justifyContent={'flex-end'}
        >
          <Box
            height="100%"
            width={'100%'}
            sx={{
              backgroundColor: 'rgba(255,255,255,0.5)',
              borderRadius: 1,
            }}
            position={'relative'}
          >
            <Box
              data-testid="implant-editor-menu"
              p={1.5}
              className={styles.improvedScroller}
              overflow={'auto'}
              height="100%"
              width={'100%'}
            >
              {implant ? (
                <Box overflow={'hidden'} borderRadius={1}>
                  <Accordion defaultExpanded={true}>
                    <IDEAccordionSummary>
                      <Box px={1}>
                        <Typography variant="h6">Visibility</Typography>
                      </Box>
                    </IDEAccordionSummary>
                    <AccordionDetails>
                      {basicControlItems.map((item, index) => (
                        <BasicControlsView
                          key={index}
                          scene={props.scene}
                          levelType={props.levelType}
                          selectedItem={item as SelectedItemType}
                        />
                      ))}
                      <ImplantVisibilityView
                        scene={props.scene}
                        levelType={props.levelType}
                        planImplant={implant}
                      />
                      {extraControlItems.map((item) => (
                        <BasicControlsView
                          scene={props.scene}
                          levelType={props.levelType}
                          selectedItem={item as SelectedItemType}
                        />
                      ))}
                      {angledInstrumentsItems.map((item) => (
                        <BasicControlsView
                          scene={props.scene}
                          levelType={props.levelType}
                          selectedItem={item as SelectedItemType}
                        />
                      ))}
                      <CutControlsView scene={props.scene} levelType={props.levelType} />
                      <MiniVisibilityView
                        scene={props.scene}
                        levelType={props.levelType}
                        planImplant={implant}
                      />
                    </AccordionDetails>
                  </Accordion>
                  <Accordion defaultExpanded={true}>
                    <IDEAccordionSummary>
                      <Box px={1}>
                        <Typography variant="h6">Cut Implant Metadata</Typography>
                      </Box>
                    </IDEAccordionSummary>
                    <AccordionDetails>
                      <CutImplantMetadataOverlay levelType={props.levelType} scene={props.scene} />
                    </AccordionDetails>
                  </Accordion>
                </Box>
              ) : null}
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default SecondaryImplantOverlay;
