import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { CaseStageType, format } from '@workflow-nx/common';
import { useQuery } from '@apollo/client';
import { FIND_REPORT_CASE_STAGES } from '../../gql';
import { DashboardCardView } from './DashboardCardView';
import { ResponsivePie } from '@nivo/pie';
import { Box, useTheme } from '@mui/material';
import { barChartTheme } from './CasesByQuarter';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';

const SIZE_CUTOFF = 600;

const CaseStageStats = () => {
  const theme = useTheme();
  const { loading } = useQuery(FIND_REPORT_CASE_STAGES, {
    onCompleted: (reportData) => {
      if (reportData && reportData.reportCaseStages.length > 0) {
        const totalCases = reportData.reportCaseStages.reduce(
          (acc: number, curr: { _count: { stage: number } }) => {
            return acc + Number(curr._count.stage);
          },
          0,
        );
        const data: Record<string | number, string | number>[] = [];

        const sortedReportCaseStageData: {
          stage: string;
          _count: { stage: number };
        }[] = [];

        [
          'OPEN',
          'SEGMENTATION',
          'PLANNING',
          'PROPOSED',
          'DESIGN',
          'QA_REVIEW',
          'PRODUCTION',
          'READY',
        ].forEach((stage) => {
          const foundStage = reportData.reportCaseStages.find(
            (reportCaseStage: { stage: string; _count: { stage: number } }) =>
              reportCaseStage.stage === stage,
          );
          if (foundStage) {
            sortedReportCaseStageData.push(foundStage);
          } else {
            sortedReportCaseStageData.push({ stage, _count: { stage: 0 } });
          }
        });

        sortedReportCaseStageData.forEach(
          ({ stage, _count }: { stage: string; _count: { stage: number } }) => {
            data.push({
              id: stage,
              label: stage,
              value: _count.stage,
              color: format.formatCaseStageColors(stage as unknown as CaseStageType),
            });
          },
        );

        setTotalCount(totalCases);
        setData(data);
      }
    },
  });
  const [data, setData] = useState<Record<string | number, string | number>[]>([]);
  const [totalCount, setTotalCount] = useState(0);

  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      <DashboardCardView label={'Cases Stage Stats'}>
        {loading ? (
          <Box height={'inherit'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <CircularProgress />
          </Box>
        ) : (
          <AutoSizer>
            {(size: Size) => {
              return (
                <Box position={'relative'} height={size.height} width={size.width}>
                  <ResponsivePie
                    data={data}
                    theme={barChartTheme(theme)}
                    colors={(t) => t.data.color as string}
                    margin={
                      size.width < SIZE_CUTOFF
                        ? { top: 70, right: 70, bottom: 70, left: 70 }
                        : { top: 30, right: 0, bottom: 50, left: 0 }
                    }
                    innerRadius={0.5}
                    padAngle={0.7}
                    cornerRadius={3}
                    borderWidth={1}
                    arcLabelsTextColor="#ffffff"
                    enableArcLinkLabels={true}
                    startAngle={-90}
                    legends={
                      size.width < SIZE_CUTOFF
                        ? [
                            {
                              data: (data as unknown as any).map(
                                (item: Record<string, string | number>, index: number) => {
                                  return {
                                    id: item.id,
                                    label: format
                                      .formatCaseStage(item.label as CaseStageType)
                                      .substring(0, 3),
                                    color: item.color,
                                  };
                                },
                              ),
                              anchor: 'top',
                              direction: 'row',
                              justify: false,
                              translateX: 0,
                              translateY: -50,
                              itemsSpacing: 5,
                              itemWidth: 48,
                              itemHeight: 18,
                              itemTextColor: '#999',
                              itemDirection: 'left-to-right',
                              itemOpacity: 1,
                              symbolSize: 18,
                              symbolShape: 'circle',
                              effects: [
                                {
                                  on: 'hover',
                                  style: {
                                    itemTextColor: '#000',
                                  },
                                },
                              ],
                            },
                          ]
                        : [
                            {
                              data: (data as unknown as any).map(
                                (item: Record<string, string | number>, index: number) => {
                                  return {
                                    id: item.id,
                                    label: format.formatCaseStage(item.label as CaseStageType),
                                    color: item.color,
                                  };
                                },
                              ),
                              anchor: 'right',
                              direction: 'column',
                              justify: false,
                              translateX: 10,
                              translateY: 0,
                              itemsSpacing: 10,
                              itemWidth: 130,
                              itemHeight: 18,
                              itemTextColor: '#999',
                              itemDirection: 'left-to-right',
                              itemOpacity: 1,
                              symbolSize: 18,
                              symbolShape: 'circle',
                              effects: [
                                {
                                  on: 'hover',
                                  style: {
                                    itemTextColor: '#000',
                                  },
                                },
                              ],
                            },
                          ]
                    }
                    arcLinkLabel={(t) => {
                      const percentage = (t.value / totalCount) * 100;

                      return Number.isNaN(percentage) ? '' : `${percentage.toFixed(0)}%`;
                    }}
                    arcLinkLabelsStraightLength={10}
                    arcLabel={(t) => (t.value !== 0 ? t.formattedValue : '')}
                    arcLinkLabelsOffset={0}
                    isInteractive={true}
                    activeOuterRadiusOffset={8}
                    tooltip={({ datum: { id, value, color } }) => (
                      <div
                        style={{
                          padding: 5,
                          color,
                          background: '#fff',
                          fontSize: theme.typography.h5.fontSize,
                          fontFamily: theme.typography.button.fontFamily,
                          fontWeight: 500,
                          boxShadow: '1px 1px 1px',
                          borderRadius: '3px',
                        }}
                      >
                        <strong>
                          {format.formatCaseStage(id as CaseStageType)}: {value}
                        </strong>
                      </div>
                    )}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      top: size.width >= SIZE_CUTOFF ? '48%' : '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      fontSize: '1.2rem',
                      fontWeight: 'bold',
                      fontFamily: theme.typography.button.fontFamily,
                    }}
                  >
                    {totalCount}
                  </div>
                </Box>
              );
            }}
          </AutoSizer>
        )}
      </DashboardCardView>
    </div>
  );
};

export default CaseStageStats;
