import {
  CaseApproachType,
  format,
  ILevels,
  IPatient,
  ISurgeonPreferences,
  IUser,
} from '@workflow-nx/common';
import { Box, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { gql } from '@apollo/client/core';
import { SurgeonPreferencesViewDialog } from './SurgeonPreferencesView/SurgeonPreferencesViewDialog';
import ActionButton from '../../../../components/ActionButton';
import { date } from '@workflow-nx/utils';

export function PlanDescriptionView(props: {
  caseLevels: ILevels;
  caseNumber: string;
  caseApproach: CaseApproachType;
  length: number;
  patientId: number;
  surgeonUser: IUser;
  surgeryDate: Date | string;
}) {
  const [findSurgeon] = useLazyQuery(
    gql`
      query FindSurgeon($userId: Int!, $patientId: Int!) {
        user(userId: $userId) {
          userId
          firstName
          lastName
          email
          role
          preferences {
            surgeonPreferenceId
            userId
            preferredProcedures
            sagittalGoalPrimary
            sagittalGoalSecondary
            sagittalGoalOther
            coronalGoalPrimary
            coronalGoalSecondary
            navigationSystems
            navigationSystemOther
            targetHeightMetric
            excludedInstruments
            reusableInstruments
            note
            createdAt
            updatedAt
            createdByUser {
              userId
              firstName
              lastName
            }
            updatedByUser {
              userId
              firstName
              lastName
            }
            surgeonPreferenceImplants {
              surgeonPreferenceImplantId
              surgeonPreferenceId
              implantType
              positioning
              direction
              orientation
              orientationByLevels {
                L3_L4
                L4_L5
                L5_S1
              }
              screwLength
              maximizeFootprint
              plusSizeImplant
              minimalHeightSpecification
              excludedImplantSizes
              note
            }
            surgeonPreferenceImplantMeasurements {
              surgeonPreferenceImplantMeasurementId
              surgeonPreferenceId
              implantType
              measurementType
              min
              max
            }
          }
        }
        patient(patientId: $patientId) {
          patientId
          birthDate
          patientRecord {
            pelvicIncidence
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      variables: {
        userId: Number(props?.surgeonUser?.userId),
        patientId: Number(props?.patientId),
      },
      onCompleted: (data) => {
        setSurgeonPreferences(data.user.preferences);
        setPatient(data.patient);
      },
    },
  );

  function getTargetLumbarLordosis() {
    if (!surgeonPreferences || !patient || !patient.patientRecord) {
      return 0;
    }

    const patientAge = date.distanceInYears(patient?.birthDate as string);
    const pelvicIncidence = patient?.patientRecord?.pelvicIncidence ?? 0;

    let targetLumbarLordosis;

    if (props.caseApproach === CaseApproachType.AgeAdjusted) {
      targetLumbarLordosis = pelvicIncidence - 3 - (patientAge - 55) / 2;
    }
    if (props.caseApproach === CaseApproachType.ZeroMismatch) {
      targetLumbarLordosis = pelvicIncidence;
    }

    return targetLumbarLordosis;
  }

  const [patient, setPatient] = useState<IPatient>();

  const [showSurgeonPreferencesViewDialog, setShowSurgeonPreferencesViewDialog] = useState(false);
  const [surgeonPreferences, setSurgeonPreferences] = useState<ISurgeonPreferences>();

  useEffect(() => {
    if (props?.surgeonUser?.userId) {
      findSurgeon();
    }
  }, []);

  return (
    <>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{ width: '100%' }}
      >
        <Box>
          <Typography variant={'body1'} gutterBottom={false}>
            This <strong>{props.length}</strong> level plan is for{' '}
            <strong>{props.surgeonUser ? format.formatName(props.surgeonUser) : 'TBD'}</strong> with
            a surgery date of{' '}
            <strong>
              {props.surgeryDate ? format.formatISODate(props.surgeryDate as string) : 'TBD'}
            </strong>
          </Typography>
          {props.caseApproach === CaseApproachType.AgeAdjusted ? (
            <Box my={1}>
              <Typography variant={'body1'}>
                The approach for this case is{' '}
                <strong>{format.formatCaseApproach(props.caseApproach)}</strong>. Based on the
                approach, with a PI of <strong>{patient?.patientRecord?.pelvicIncidence}</strong>{' '}
                and a patient age of{' '}
                <strong>{date.distanceInYears(patient?.birthDate as string)}</strong> the target
                lumbar lordosis should be <strong>{getTargetLumbarLordosis()}</strong>.
              </Typography>
              <Typography variant={'caption'}>
                Age-adjusted formula <em>LL = (PI - 3) - ((age - 55) /2)</em>
              </Typography>
            </Box>
          ) : null}
          {props.caseApproach === CaseApproachType.ZeroMismatch ? (
            <Box my={1}>
              <Typography variant={'body1'}>
                The approach for this case is{' '}
                <strong>{format.formatCaseApproach(props.caseApproach)}</strong>. Based on the
                approach, with a the target lumbar lordosis should be{' '}
                <strong>{getTargetLumbarLordosis()}</strong>.
              </Typography>
            </Box>
          ) : null}
          {props.caseApproach === CaseApproachType.Other ? (
            <Box my={1}>
              <Typography variant={'body1'}>
                The approach for this case is{' '}
                <strong>{format.formatCaseApproach(props.caseApproach)}</strong>. Based on the
                approach, follow Surgeon Preferences for posterior height, lordosis, and coronal
                angle.
              </Typography>
            </Box>
          ) : null}
        </Box>
        <ActionButton
          variant={'outlined'}
          onClick={() => setShowSurgeonPreferencesViewDialog(true)}
        >
          <Typography color={'secondary'} variant={'button'} noWrap={true}>
            View Surgeon Preferences
          </Typography>
        </ActionButton>
      </Stack>
      {showSurgeonPreferencesViewDialog ? (
        <Box>
          <SurgeonPreferencesViewDialog
            caseLevels={props.caseLevels}
            caseNumber={props.caseNumber}
            surgeonUser={props.surgeonUser}
            preferences={surgeonPreferences}
            open={showSurgeonPreferencesViewDialog}
            onClose={() => setShowSurgeonPreferencesViewDialog(false)}
          />
        </Box>
      ) : null}
    </>
  );
}
