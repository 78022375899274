import {
  AssetPositions,
  AssetType,
  IAsset,
  ICase,
  ICaseImplantSpecification,
  IPostOpAnalysis,
} from '@workflow-nx/common';

export type PostOpCaseActionType = {
  type: string;
  data?: any;
};

export type PostOpCaseStateType = {
  activeCase: ICase | null;
  assetPositions?: AssetPositions;
  commentCount?: number;
  intraOpAnalysisXRAsset?: IAsset;
  sixWeekAnalysisXRAsset?: IAsset;
  sixMonthAnalysisXRAsset?: IAsset;
  oneYearAnalysisXRAsset?: IAsset;
  twoYearAnalysisXRAsset?: IAsset;

  sixWeekAnalysisCtAsset?: IAsset;
  sixMonthAnalysisCtAsset?: IAsset;
  oneYearAnalysisCtAsset?: IAsset;
  twoYearAnalysisCtAsset?: IAsset;

  sixWeekAnalysisMriAsset?: IAsset;
  sixMonthAnalysisMriAsset?: IAsset;
  oneYearAnalysisMriAsset?: IAsset;
  twoYearAnalysisMriAsset?: IAsset;

  postOpIntraOpStandingXRApAsset?: IAsset;
  sixWeekAnalysisStandingXRApAsset?: IAsset;
  sixMonthAnalysisStandingXRApAsset?: IAsset;
  oneYearAnalysisStandingXRApAsset?: IAsset;
  twoYearAnalysisStandingXRApAsset?: IAsset;

  postOpIntraOpStandingXRLateralAsset?: IAsset;
  sixWeekAnalysisStandingXRLateralAsset?: IAsset;
  sixMonthAnalysisStandingXRLateralAsset?: IAsset;
  oneYearAnalysisStandingXRLateralAsset?: IAsset;
  twoYearAnalysisStandingXRLateralAsset?: IAsset;

  preOp?: IPostOpAnalysis;
  plan?: IPostOpAnalysis;
  postOpIntraOp?: IPostOpAnalysis;
  postOpSixWeeks?: IPostOpAnalysis;
  postOpSixMonths?: IPostOpAnalysis;
  postOpOneYear?: IPostOpAnalysis;
  postOpTwoYears?: IPostOpAnalysis;
  caseImplantSpecifications?: ICaseImplantSpecification[];
};

export const PostOpCaseReducer = (
  state: PostOpCaseStateType,
  action: PostOpCaseActionType,
): PostOpCaseStateType => {
  let updatedState = JSON.parse(JSON.stringify(state));

  switch (action.type) {
    case 'ASSET_REPLACED': {
      const replacedAsset: IAsset = action.data;

      updatedState = {
        activeCase: updatedState.activeCase,
        assetPositions: updatedState.assetPositions,
        commentCount: updatedState.commentCount,
        intraOpAnalysisXRAsset:
          replacedAsset.assetType === AssetType.PostOpIntraOpXray
            ? replacedAsset
            : updatedState.intraOpAnalysisXRAsset,
        sixWeekAnalysisXRAsset:
          replacedAsset.assetType === AssetType.PostOpSixWeeksXray
            ? replacedAsset
            : updatedState.sixWeekAnalysisXRAsset,
        sixMonthAnalysisXRAsset:
          replacedAsset.assetType === AssetType.PostOpSixMonthsXray
            ? replacedAsset
            : updatedState.sixMonthAnalysisXRAsset,
        oneYearAnalysisXRAsset:
          replacedAsset.assetType === AssetType.PostOpOneYearXray
            ? replacedAsset
            : updatedState.oneYearAnalysisXRAsset,
        twoYearAnalysisXRAsset:
          replacedAsset.assetType === AssetType.PostOpTwoYearXray
            ? replacedAsset
            : updatedState.twoYearAnalysisXRAsset,

        sixWeekAnalysisCtAsset:
          replacedAsset.assetType === AssetType.PostOpSixWeeksCt
            ? replacedAsset
            : updatedState.sixWeekAnalysisCtAsset,
        sixMonthAnalysisCtAsset:
          replacedAsset.assetType === AssetType.PostOpSixMonthsCt
            ? replacedAsset
            : updatedState.sixMonthAnalysisCtAsset,
        oneYearAnalysisCtAsset:
          replacedAsset.assetType === AssetType.PostOpOneYearCt
            ? replacedAsset
            : updatedState.oneYearAnalysisCtAsset,
        twoYearAnalysisCtAsset:
          replacedAsset.assetType === AssetType.PostOpTwoYearCt
            ? replacedAsset
            : updatedState.twoYearAnalysisCtAsset,

        sixWeekAnalysisMriAsset:
          replacedAsset.assetType === AssetType.PostOpSixWeeksMri
            ? replacedAsset
            : updatedState.sixWeekAnalysisMriAsset,
        sixMonthAnalysisMriAsset:
          replacedAsset.assetType === AssetType.PostOpSixMonthsMri
            ? replacedAsset
            : updatedState.sixMonthAnalysisMriAsset,
        oneYearAnalysisMriAsset:
          replacedAsset.assetType === AssetType.PostOpOneYearMri
            ? replacedAsset
            : updatedState.oneYearAnalysisMriAsset,
        twoYearAnalysisMriAsset:
          replacedAsset.assetType === AssetType.PostOpTwoYearMri
            ? replacedAsset
            : updatedState.twoYearAnalysisMriAsset,

        postOpIntraOpStandingXRApAsset:
          replacedAsset.assetType === AssetType.PostOpIntraOpStandingXrayAp
            ? replacedAsset
            : updatedState.postOpIntraOpStandingXRApAsset,
        sixWeekAnalysisStandingXRApAsset:
          replacedAsset.assetType === AssetType.PostOpSixWeeksStandingXrayAp
            ? replacedAsset
            : updatedState.sixWeekAnalysisXRAsset,
        sixMonthAnalysisStandingXRApAsset:
          replacedAsset.assetType === AssetType.PostOpSixMonthsStandingXrayAp
            ? replacedAsset
            : updatedState.sixMonthAnalysisXRAsset,
        oneYearAnalysisStandingXRApAsset:
          replacedAsset.assetType === AssetType.PostOpOneYearStandingXrayAp
            ? replacedAsset
            : updatedState.oneYearAnalysisXRAsset,
        twoYearAnalysisStandingXRApAsset:
          replacedAsset.assetType === AssetType.PostOpTwoYearStandingXrayAp
            ? replacedAsset
            : updatedState.twoYearAnalysisXRAsset,

        postOpIntraOpStandingXRLateralAsset:
          replacedAsset.assetType === AssetType.PostOpIntraOpStandingXrayLateral
            ? replacedAsset
            : updatedState.postOpIntraOpStandingXRLateralAsset,
        sixWeekAnalysisStandingXRLateralAsset:
          replacedAsset.assetType === AssetType.PostOpSixWeeksStandingXrayLateral
            ? replacedAsset
            : updatedState.sixWeekAnalysisXRAsset,
        sixMonthAnalysisStandingXRLateralAsset:
          replacedAsset.assetType === AssetType.PostOpSixMonthsStandingXrayLateral
            ? replacedAsset
            : updatedState.sixMonthAnalysisXRAsset,
        oneYearAnalysisStandingXRLateralAsset:
          replacedAsset.assetType === AssetType.PostOpOneYearStandingXrayLateral
            ? replacedAsset
            : updatedState.oneYearAnalysisXRAsset,
        twoYearAnalysisStandingXRLateralAsset:
          replacedAsset.assetType === AssetType.PostOpTwoYearStandingXrayLateral
            ? replacedAsset
            : updatedState.twoYearAnalysisXRAsset,

        sixWeekPostOpAnalysis: updatedState.sixWeekPostOpAnalysis,
        caseImplantSpecifications: updatedState.caseImplantSpecifications,
      };
      break;
    }
    case 'ASSET_DELETED': {
      const deletedAsset = action.data;

      updatedState = {
        activeCase: updatedState.activeCase,
        assetPositions: updatedState.assetPositions,
        commentCount: updatedState.commentCount,
        intraOpAnalysisXRAsset:
          deletedAsset.assetType === AssetType.PostOpIntraOpXray
            ? undefined
            : updatedState.intraOpAnalysisXRAsset,
        sixWeekAnalysisXRAsset:
          deletedAsset.assetType === AssetType.PostOpSixWeeksXray
            ? undefined
            : updatedState.sixWeekAnalysisXRAsset,
        sixMonthAnalysisXRAsset:
          deletedAsset.assetType === AssetType.PostOpSixMonthsXray
            ? undefined
            : updatedState.sixMonthAnalysisXRAsset,
        oneYearAnalysisXRAsset:
          deletedAsset.assetType === AssetType.PostOpOneYearXray
            ? undefined
            : updatedState.oneYearAnalysisXRAsset,
        twoYearAnalysisXRAsset:
          deletedAsset.assetType === AssetType.PostOpTwoYearXray
            ? undefined
            : updatedState.twoYearAnalysisXRAsset,

        sixWeekAnalysisCtAsset:
          deletedAsset.assetType === AssetType.PostOpSixWeeksCt
            ? undefined
            : updatedState.sixWeekAnalysisCtAsset,
        sixMonthAnalysisCtAsset:
          deletedAsset.assetType === AssetType.PostOpSixMonthsCt
            ? undefined
            : updatedState.sixMonthAnalysisCtAsset,
        oneYearAnalysisCtAsset:
          deletedAsset.assetType === AssetType.PostOpOneYearCt
            ? undefined
            : updatedState.oneYearAnalysisCtAsset,
        twoYearAnalysisCtAsset:
          deletedAsset.assetType === AssetType.PostOpTwoYearCt
            ? undefined
            : updatedState.twoYearAnalysisCtAsset,

        sixWeekAnalysisMriAsset:
          deletedAsset.assetType === AssetType.PostOpSixWeeksMri
            ? undefined
            : updatedState.sixWeekAnalysisMriAsset,
        sixMonthAnalysisMriAsset:
          deletedAsset.assetType === AssetType.PostOpSixMonthsMri
            ? undefined
            : updatedState.sixMonthAnalysisMriAsset,
        oneYearAnalysisMriAsset:
          deletedAsset.assetType === AssetType.PostOpOneYearMri
            ? undefined
            : updatedState.oneYearAnalysisMriAsset,
        twoYearAnalysisMriAsset:
          deletedAsset.assetType === AssetType.PostOpTwoYearMri
            ? undefined
            : updatedState.twoYearAnalysisMriAsset,

        postOpIntraOpStandingXRApAsset:
          deletedAsset.assetType === AssetType.PostOpIntraOpStandingXrayAp
            ? undefined
            : updatedState.postOpIntraOpStandingXRApAsset,
        sixWeekAnalysisStandingXRApAsset:
          deletedAsset.assetType === AssetType.PostOpSixWeeksStandingXrayAp
            ? undefined
            : updatedState.sixWeekAnalysisStandingXRApAsset,
        sixMonthAnalysisStandingXRApAsset:
          deletedAsset.assetType === AssetType.PostOpSixMonthsStandingXrayAp
            ? undefined
            : updatedState.sixMonthAnalysisStandingXRApAsset,
        oneYearAnalysisStandingXRApAsset:
          deletedAsset.assetType === AssetType.PostOpOneYearStandingXrayAp
            ? undefined
            : updatedState.oneYearAnalysisStandingXRApAsset,
        twoYearAnalysisStandingXRApAsset:
          deletedAsset.assetType === AssetType.PostOpTwoYearStandingXrayAp
            ? undefined
            : updatedState.twoYearAnalysisStandingXRApAsset,

        postOpIntraOpStandingXRLateralAsset:
          deletedAsset.assetType === AssetType.PostOpIntraOpStandingXrayLateral
            ? undefined
            : updatedState.postOpIntraOpStandingXRLateralAsset,
        sixWeekAnalysisStandingXRLateralAsset:
          deletedAsset.assetType === AssetType.PostOpSixWeeksStandingXrayLateral
            ? undefined
            : updatedState.sixWeekAnalysisStandingXRLateralAsset,
        sixMonthAnalysisStandingXRLateralAsset:
          deletedAsset.assetType === AssetType.PostOpSixMonthsStandingXrayLateral
            ? undefined
            : updatedState.sixMonthAnalysisStandingXRLateralAsset,
        oneYearAnalysisStandingXRLateralAsset:
          deletedAsset.assetType === AssetType.PostOpOneYearStandingXrayLateral
            ? undefined
            : updatedState.oneYearAnalysisXRAsset,
        twoYearAnalysisStandingXRLateralAsset:
          deletedAsset.assetType === AssetType.PostOpTwoYearStandingXrayLateral
            ? undefined
            : updatedState.twoYearAnalysisXRAsset,

        sixWeekPostOpAnalysis: updatedState.sixWeekPostOpAnalysis,
        caseImplantSpecifications: updatedState.caseImplantSpecifications,
      };

      break;
    }
    case 'ASSET_UPLOADED': {
      const createdAsset = action.data;

      updatedState = {
        activeCase: updatedState.activeCase,
        assetPositions: updatedState.assetPositions,
        commentCount: updatedState.commentCount,
        intraOpAnalysisXRAsset:
          createdAsset.assetType === AssetType.PostOpIntraOpXray
            ? createdAsset
            : updatedState.intraOpAnalysisXRAsset,
        sixWeekAnalysisXRAsset:
          createdAsset.assetType === AssetType.PostOpSixWeeksXray
            ? createdAsset
            : updatedState.sixWeekAnalysisXRAsset,
        sixMonthAnalysisXRAsset:
          createdAsset.assetType === AssetType.PostOpSixMonthsXray
            ? createdAsset
            : updatedState.sixMonthAnalysisXRAsset,
        oneYearAnalysisXRAsset:
          createdAsset.assetType === AssetType.PostOpOneYearXray
            ? createdAsset
            : updatedState.oneYearAnalysisXRAsset,
        twoYearAnalysisXRAsset:
          createdAsset.assetType === AssetType.PostOpTwoYearXray
            ? createdAsset
            : updatedState.twoYearAnalysisXRAsset,

        sixWeekAnalysisCtAsset:
          createdAsset.assetType === AssetType.PostOpSixWeeksCt
            ? createdAsset
            : updatedState.sixWeekAnalysisCtAsset,
        sixMonthAnalysisCtAsset:
          createdAsset.assetType === AssetType.PostOpSixMonthsCt
            ? createdAsset
            : updatedState.sixMonthAnalysisCtAsset,
        oneYearAnalysisCtAsset:
          createdAsset.assetType === AssetType.PostOpOneYearCt
            ? createdAsset
            : updatedState.oneYearAnalysisCtAsset,
        twoYearAnalysisCtAsset:
          createdAsset.assetType === AssetType.PostOpTwoYearCt
            ? createdAsset
            : updatedState.twoYearAnalysisCtAsset,

        sixWeekAnalysisMriAsset:
          createdAsset.assetType === AssetType.PostOpSixWeeksMri
            ? createdAsset
            : updatedState.sixWeekAnalysisMriAsset,
        sixMonthAnalysisMriAsset:
          createdAsset.assetType === AssetType.PostOpSixMonthsMri
            ? createdAsset
            : updatedState.sixMonthAnalysisMriAsset,
        oneYearAnalysisMriAsset:
          createdAsset.assetType === AssetType.PostOpOneYearMri
            ? createdAsset
            : updatedState.oneYearAnalysisMriAsset,
        twoYearAnalysisMriAsset:
          createdAsset.assetType === AssetType.PostOpTwoYearMri
            ? createdAsset
            : updatedState.twoYearAnalysisMriAsset,

        postOpIntraOpStandingXRApAsset:
          createdAsset.assetType === AssetType.PostOpIntraOpStandingXrayAp
            ? createdAsset
            : updatedState.postOpIntraOpStandingXRApAsset,
        sixWeekAnalysisStandingXRApAsset:
          createdAsset.assetType === AssetType.PostOpSixWeeksStandingXrayAp
            ? createdAsset
            : updatedState.sixWeekAnalysisStandingXRApAsset,
        sixMonthAnalysisStandingXRApAsset:
          createdAsset.assetType === AssetType.PostOpSixMonthsStandingXrayAp
            ? createdAsset
            : updatedState.sixMonthAnalysisStandingXRApAsset,
        oneYearAnalysisStandingXRApAsset:
          createdAsset.assetType === AssetType.PostOpOneYearStandingXrayAp
            ? createdAsset
            : updatedState.oneYearAnalysisStandingXRApAsset,
        twoYearAnalysisStandingXRApAsset:
          createdAsset.assetType === AssetType.PostOpTwoYearStandingXrayAp
            ? createdAsset
            : updatedState.twoYearAnalysisStandingXRApAsset,

        postOpIntraOpStandingXRLateralAsset:
          createdAsset.assetType === AssetType.PostOpIntraOpStandingXrayLateral
            ? createdAsset
            : updatedState.postOpIntraOpStandingXRLateralAsset,
        sixWeekAnalysisStandingXRLateralAsset:
          createdAsset.assetType === AssetType.PostOpSixWeeksStandingXrayLateral
            ? createdAsset
            : updatedState.sixWeekAnalysisStandingXRLateralAsset,
        sixMonthAnalysisStandingXRLateralAsset:
          createdAsset.assetType === AssetType.PostOpSixMonthsStandingXrayLateral
            ? createdAsset
            : updatedState.sixMonthAnalysisStandingXRLateralAsset,
        oneYearAnalysisStandingXRLateralAsset:
          createdAsset.assetType === AssetType.PostOpOneYearStandingXrayLateral
            ? createdAsset
            : updatedState.oneYearAnalysisStandingXRLateralAsset,
        twoYearAnalysisStandingXRLateralAsset:
          createdAsset.assetType === AssetType.PostOpTwoYearStandingXrayLateral
            ? createdAsset
            : updatedState.twoYearAnalysisStandingXRLateralAsset,

        sixWeekPostOpAnalysis: updatedState.sixWeekPostOpAnalysis,
        caseImplantSpecifications: updatedState.caseImplantSpecifications,
      };
      break;
    }
    case 'INIT':
      const postOpAssets: IAsset[] = action?.data?.assets ?? [];

      updatedState = {
        activeCase: action.data.case,
        assetPositions: action?.data?.planByStatus?.assetPositions,
        commentCount: action?.data?.comments?.count ?? 0,
        preOp: action?.data?.preOp,
        plan: action?.data?.plan,
        postOpIntraOp: action?.data?.postOpIntraOp,
        postOpSixWeeks: action?.data?.postOpSixWeeks,
        postOpSixMonths: action?.data?.postOpSixMonths,
        postOpOneYear: action?.data?.postOpOneYear,
        postOpTwoYears: action?.data?.postOpTwoYears,

        intraOpAnalysisXRAsset: postOpAssets.find(
          (a) => a.assetType === AssetType.PostOpIntraOpXray,
        ),
        sixWeekAnalysisXRAsset: postOpAssets.find(
          (a) => a.assetType === AssetType.PostOpSixWeeksXray,
        ),
        sixMonthAnalysisXRAsset: postOpAssets.find(
          (a) => a.assetType === AssetType.PostOpSixMonthsXray,
        ),
        oneYearAnalysisXRAsset: postOpAssets.find(
          (a) => a.assetType === AssetType.PostOpOneYearXray,
        ),
        twoYearAnalysisXRAsset: postOpAssets.find(
          (a) => a.assetType === AssetType.PostOpTwoYearXray,
        ),

        sixWeekAnalysisCtAsset: postOpAssets.find(
          (a) => a.assetType === AssetType.PostOpSixWeeksCt,
        ),
        sixMonthAnalysisCtAsset: postOpAssets.find(
          (a) => a.assetType === AssetType.PostOpSixMonthsCt,
        ),
        oneYearAnalysisCtAsset: postOpAssets.find((a) => a.assetType === AssetType.PostOpOneYearCt),
        twoYearAnalysisCtAsset: postOpAssets.find((a) => a.assetType === AssetType.PostOpTwoYearCt),

        sixWeekAnalysisMriAsset: postOpAssets.find(
          (a) => a.assetType === AssetType.PostOpSixWeeksMri,
        ),
        sixMonthAnalysisMriAsset: postOpAssets.find(
          (a) => a.assetType === AssetType.PostOpSixMonthsMri,
        ),
        oneYearAnalysisMriAsset: postOpAssets.find(
          (a) => a.assetType === AssetType.PostOpOneYearMri,
        ),
        twoYearAnalysisMriAsset: postOpAssets.find(
          (a) => a.assetType === AssetType.PostOpTwoYearMri,
        ),

        postOpIntraOpStandingXRApAsset: postOpAssets.find(
          (a) => a.assetType === AssetType.PostOpIntraOpStandingXrayAp,
        ),
        sixWeekAnalysisStandingXRApAsset: postOpAssets.find(
          (a) => a.assetType === AssetType.PostOpSixWeeksStandingXrayAp,
        ),
        sixMonthAnalysisStandingXRApAsset: postOpAssets.find(
          (a) => a.assetType === AssetType.PostOpSixMonthsStandingXrayAp,
        ),
        oneYearAnalysisStandingXRApAsset: postOpAssets.find(
          (a) => a.assetType === AssetType.PostOpOneYearStandingXrayAp,
        ),
        twoYearAnalysisStandingXRApAsset: postOpAssets.find(
          (a) => a.assetType === AssetType.PostOpTwoYearStandingXrayAp,
        ),

        postOpIntraOpStandingXRLateralAsset: postOpAssets.find(
          (a) => a.assetType === AssetType.PostOpIntraOpStandingXrayLateral,
        ),
        sixWeekAnalysisStandingXRLateralAsset: postOpAssets.find(
          (a) => a.assetType === AssetType.PostOpSixWeeksStandingXrayLateral,
        ),
        sixMonthAnalysisStandingXRLateralAsset: postOpAssets.find(
          (a) => a.assetType === AssetType.PostOpSixMonthsStandingXrayLateral,
        ),
        oneYearAnalysisStandingXRLateralAsset: postOpAssets.find(
          (a) => a.assetType === AssetType.PostOpOneYearStandingXrayLateral,
        ),
        twoYearAnalysisStandingXRLateralAsset: postOpAssets.find(
          (a) => a.assetType === AssetType.PostOpTwoYearStandingXrayLateral,
        ),

        caseImplantSpecifications:
          action?.data?.caseImplantSpecifications?.caseImplantSpecifications ?? [],
      };
      break;
  }

  return updatedState;
};
