import { format, IAsset, IComment, Permission } from '@workflow-nx/common';
import { Box, CircularProgress, IconButton, Stack, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import useAuth from '../../../../hooks/useAuth';
import { faDownload, faEdit, faMapPin, faTrash } from '@fortawesome/pro-light-svg-icons';
import { faMapPin as faMapPinSolid } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/pro-thin-svg-icons';
import { IconFontButton } from '@workflow-nx/ui';
import { useMutation } from '@apollo/client';
import { CREATE_ASSET_DOWNLOAD_URL } from '../../../../gql';
import * as FileSaver from 'file-saver';
import { useSnackbar } from 'notistack';
import { file } from '@workflow-nx/utils';

const useStyles = makeStyles((theme: Theme) => ({
  comment: {
    border: `1px solid rgba(0, 0, 0, 0.12)`,
    backgroundColor: '#f8f8f8',
    borderRadius: 5,
    color: theme.palette.text.primary,
    '&.selected': {
      backgroundColor: theme.palette.info.light,
      color: theme.palette.info.contrastText,
    },
    '&.pinned': {
      border: `2px solid rgba(0, 0, 0, 0.62)`,
    },
  },
}));

export function CommentView(props: {
  selected: boolean;
  disabled?: boolean;
  comment: IComment;
  isPatientView?: boolean;
  onDeleteClicked?: () => void;
  onEditClicked?: () => void;
  onPinClicked?: () => void;
}) {
  const styles = useStyles();
  const { hasPermission, user } = useAuth();
  const [createAssetDownloadUrl] = useMutation(CREATE_ASSET_DOWNLOAD_URL);
  const [downloading, setDownloading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const canPinOrDeleteComments =
    !!hasPermission?.([Permission.ManageCase]) && props.onPinClicked && !props.isPatientView;
  const canShowEditButton =
    props?.comment?.createdByUser?.userId === user?.userId && props.onEditClicked;
  const canShowDeleteButton =
    (canPinOrDeleteComments || props?.comment?.createdByUser?.userId === user?.userId) &&
    props.onDeleteClicked;

  const handleDownloadAsset = async (asset: IAsset) => {
    try {
      setDownloading(true);
      const { data } = await createAssetDownloadUrl({
        variables: {
          assetId: asset.assetId,
        },
      });

      const response = await file.downloadFile(data.createAssetDownloadUrl.signedUrl);

      FileSaver.saveAs(response.data, asset?.fileName);
    } catch (e) {
      enqueueSnackbar('Error Downloading File', {
        variant: 'error',
      });
    } finally {
      setDownloading(false);
    }
  };

  return (
    <Box display={'flex'} flexDirection={'column'} minWidth={'75%'}>
      <Stack>
        <Box
          className={`${styles.comment} ${props.selected ? 'selected' : ''} ${
            props.comment.isPinned ? 'pinned' : ''
          }`}
          p={1}
        >
          <Typography variant={'body1'} style={{ whiteSpace: 'pre-wrap' }}>
            {props.comment.comment}
          </Typography>
        </Box>
        {props.comment.asset ? (
          <Stack direction={'row'} gap={1} alignItems={'center'} mt={0.5}>
            <FontAwesomeIcon icon={faPaperclip} />
            <Typography color={'textSecondary'}>{props.comment.asset.fileName}</Typography>
            {downloading ? (
              <CircularProgress variant={'indeterminate'} size={15} />
            ) : (
              <IconFontButton
                icon={faDownload}
                onClick={() => {
                  handleDownloadAsset(props.comment.asset);
                }}
                size={'small'}
              />
            )}
          </Stack>
        ) : null}
      </Stack>
      <Box mt={0.5} display={'flex'} alignItems={'center'}>
        <Typography variant={'body1'} color={'textSecondary'}>
          {format.formatName(props.comment.createdByUser)}
        </Typography>
        {props.comment.updatedAt !== props.comment.createdAt ? (
          <Box mx={0.5}>
            <Typography variant={'caption'} color={'textSecondary'}>
              - Edited
            </Typography>
          </Box>
        ) : null}
        <Box mx={1} />
        {canShowEditButton ? (
          <>
            <IconButton
              onClick={props?.onEditClicked}
              size={'small'}
              disabled={props.disabled}
              data-testid="comment-edit-button"
            >
              <FontAwesomeIcon icon={faEdit} />
            </IconButton>
            <Box mx={0.5} />
          </>
        ) : null}
        {canShowDeleteButton ? (
          <>
            <IconButton
              onClick={props?.onDeleteClicked}
              size={'small'}
              disabled={props.disabled}
              data-testid="comment-delete-button"
            >
              <FontAwesomeIcon icon={faTrash} />
            </IconButton>
            <Box mx={0.5} />
          </>
        ) : null}
        {canPinOrDeleteComments ? (
          <IconButton onClick={props?.onPinClicked} size={'small'} disabled={props.disabled}>
            {props.comment.isPinned ? (
              <FontAwesomeIcon icon={faMapPinSolid} />
            ) : (
              <FontAwesomeIcon icon={faMapPin} />
            )}
          </IconButton>
        ) : null}
      </Box>
    </Box>
  );
}
