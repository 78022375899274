import { format, IAsset } from '@workflow-nx/common';
import { Box, Typography } from '@mui/material';
import React from 'react';

export function FileInfoView(props: { asset?: IAsset }) {
  return (
    <Box>
      <Typography>
        {props.asset?.fileName} ({format.formatBytes(props.asset?.size ?? 0)})
      </Typography>
      <Typography color={'textSecondary'} variant={'body2'}>
        Updated {format.formatDateTime(props.asset?.updatedAt ?? new Date())} by{' '}
        {format.formatName(props.asset?.updatedByUser)}
      </Typography>
    </Box>
  );
}
