import React, { Dispatch } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link, TableBody, TableRow, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { format, ITableHeader, IUser, UserRoleType, UserStatusType } from '@workflow-nx/common';
import { TableCell } from '../../../components/TableCell';
import { CommonTable } from '../../../components/CommonTable';
import { ListUsersActionType } from './ListUsers.reducer';
import { UserLocationsView } from './UserLocationsView';
import { Globals } from '../../../layouts/DashboardLayout';

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    '& :hover': {
      cursor: 'pointer',
    },
    height: 65,
  },
  pending: {
    '& .MuiTableCell-root ': {
      color: theme.palette.warning.main,
    },
  },
  inactive: {
    '& .MuiTableCell-root ': {
      color: theme.palette.grey[500],
    },
  },
}));

type UserListTableProps = {
  users: IUser[];
  orderBy: any;
  dispatch: Dispatch<ListUsersActionType>;
  loading?: boolean;
  page?: number;
  rowsPerPage?: number;
  totalUserCount?: number;
  onPagination: (pageSize: number, pageNumber: number) => void;
};

const UserListTable = ({
  users: allUsers,
  orderBy,
  dispatch,
  loading,
  page,
  rowsPerPage,
  totalUserCount,
  onPagination,
}: UserListTableProps) => {
  const navigate = useNavigate();
  const styles = useStyles();
  const users = allUsers?.filter((user) => user.role !== UserRoleType.Surgeon);
  const showPagination = Boolean(rowsPerPage && page !== undefined);

  const headers: ITableHeader[] = [
    {
      id: 'lastName',
      sortable: true,
      label: 'Name',
    },
    {
      id: 'email',
      sortable: true,
      label: 'Email',
    },
    {
      id: 'organizationId',
      sortable: false,
      label: 'Institution',
    },
    {
      id: 'role',
      sortable: true,
      label: 'Role',
    },
    {
      id: 'status',
      sortable: true,
      label: 'Status',
    },
    {
      id: 'locations',
      sortable: false,
      label: 'Locations',
      align: 'center',
    },
    {
      id: 'region',
      sortable: false,
      label: 'Region',
    },
    {
      id: 'territory',
      sortable: false,
      label: 'Territory',
    },
  ];

  return (
    <CommonTable
      orderBy={orderBy}
      headers={headers}
      onHeaderClick={(orderBy) => {
        if (dispatch) {
          dispatch({ type: 'ORDER_BY_CHANGED', data: orderBy });
        }
      }}
      rows={
        <TableBody>
          {users?.map((user: IUser) => {
            return (
              <TableRow
                hover
                key={user.userId}
                onClick={() => navigate(`/app/user/${user.userId}`)}
                className={`${styles.row} ${
                  user.status === UserStatusType.Pending ? styles.pending : ''
                } ${user.status === UserStatusType.Inactive ? styles.inactive : ''}`}
              >
                <TableCell>
                  {user ? (
                    <Link
                      color={'inherit'}
                      underline={'none'}
                      href={`/app/user/${user.userId}`}
                      noWrap={true}
                      onClick={(e) => e.preventDefault()}
                    >
                      {format.formatName(user)}
                    </Link>
                  ) : (
                    <span>&mdash;</span>
                  )}
                </TableCell>
                <TableCell>{user?.email}</TableCell>
                <TableCell>{user?.organization?.name}</TableCell>
                <TableCell>{format.formatUserRole(user.role)}</TableCell>
                <TableCell>{format.formatUserStatus(user.status)}</TableCell>
                <TableCell align={'center'}>
                  {user?.locationUser?.length ? (
                    <UserLocationsView
                      userId={user.userId}
                      userLocations={user.locationUser ?? []}
                    />
                  ) : (
                    <>&mdash;</>
                  )}
                </TableCell>
                <TableCell>
                  {user?.userRegionTerritories?.length
                    ? user?.userRegionTerritories[0]?.region?.name
                    : ''}
                </TableCell>
                <TableCell>
                  {user?.userRegionTerritories?.length
                    ? user?.userRegionTerritories[0]?.territory?.name
                    : ''}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      }
      loading={loading}
      paginationConfig={
        showPagination
          ? {
              totalCount: totalUserCount || 0,
              onPaginationChange: (pagination) => {
                onPagination(pagination.rowsPerPage, pagination.page);
              },
              rowsPerPage: rowsPerPage || Globals.DefaultListPageSize,
              page: page || 0,
              rowsPerPageOptions: Globals.DefaultListPageSizeOptions,
            }
          : undefined
      }
    />
  );
};

export default UserListTable;
