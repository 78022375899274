import { faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, ListItem, Slider, Typography } from '@mui/material';
import { LevelSize, LevelType } from '@workflow-nx/common';
import { Mesh, Scene } from 'babylonjs';
import { useEffect, useState } from 'react';

export function CutControlsView(props: { levelType: LevelType; scene: Scene }) {
  const [planVisibility, setPlanVisibility] = useState(1);
  const [minusVisibility, setMinusVisibility] = useState(0);

  const planVisible = planVisibility !== 0;
  const minusVisible = minusVisibility !== 0;

  const [size, setSize] = useState<LevelSize.Minus | LevelSize.Normal | null>(LevelSize.Normal);

  const handleChange = (value: number, newSize: LevelSize.Minus | LevelSize.Normal) => {
    const showPlan = newSize === LevelSize.Normal;

    const planMesh = props.scene.getMeshByName(`${props.levelType}_APP`) as Mesh | null;

    const minusMesh = props.scene.getMeshByName(`${props.levelType}_APP_MINUS`) as Mesh | null;

    if (showPlan) {
      if (planMesh) planMesh.visibility = value;

      setPlanVisibility(value);

      setMinusVisibility(0);

      if (minusMesh) minusMesh.visibility = 0;
    } else {
      if (minusMesh) minusMesh.visibility = value;

      setMinusVisibility(value);
      setPlanVisibility(0);
      if (planMesh) planMesh.visibility = 0;
    }

    setSize(newSize);
  };

  const handleIconChange = (
    sizeIconOnState: boolean,
    newSize: LevelSize.Minus | LevelSize.Normal,
  ) => {
    const planMesh = props.scene.getMeshByName(`${props.levelType}_APP`) as Mesh | null;
    const minusMesh = props.scene.getMeshByName(`${props.levelType}_APP_MINUS`) as Mesh | null;

    if (sizeIconOnState) {
      const planValue = newSize === LevelSize.Normal ? 1 : 0;
      const minusValue = newSize === LevelSize.Normal ? 0 : 1;

      setPlanVisibility(planValue);
      setMinusVisibility(minusValue);

      if (planMesh) planMesh.visibility = planValue;
      if (minusMesh) minusMesh.visibility = minusValue;

      setSize(newSize);
    } else {
      setPlanVisibility(0);
      setMinusVisibility(0);

      if (planMesh) planMesh.visibility = 0;
      if (minusMesh) minusMesh.visibility = 0;

      setSize(null);
    }
  };

  const handleSetVisibility = (planMesh: Mesh | null, minusMesh: Mesh | null) => {
    setPlanVisibility(planMesh?.visibility ?? 0);
    setMinusVisibility(minusMesh?.visibility ?? 0);

    setSize(
      minusMesh?.visibility === 1
        ? LevelSize.Minus
        : planMesh?.visibility === 1
        ? LevelSize.Normal
        : null,
    );
  };

  const planMesh = props.scene.getMeshByName(`${props.levelType}_APP`) as Mesh | null;
  const minusMesh = props.scene.getMeshByName(`${props.levelType}_APP_MINUS`) as Mesh | null;

  if (
    planMesh &&
    minusMesh &&
    (planMesh?.visibility !== planVisibility || minusMesh?.visibility !== minusVisibility)
  ) {
    handleSetVisibility(planMesh, minusMesh);
  }

  useEffect(() => {
    handleSetVisibility(planMesh, minusMesh);
  }, [props.levelType]);

  return (
    <>
      <ListItem>
        <Box flexGrow={1}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography color="text.secondary" variant="h6" flex={2}>
              Cut Plan
            </Typography>

            <Box display="flex" flex={3} ml={3}>
              <Slider
                size="small"
                disabled={size != LevelSize.Normal}
                min={0.05}
                max={1}
                step={0.1}
                value={planVisibility}
                onChange={(_: any, newValue: number | number[]) => {
                  handleChange(newValue as number, LevelSize.Normal);
                }}
              />

              <Box mx={1} />

              <IconButton
                size="small"
                onClick={() => {
                  handleIconChange(Boolean(!planVisible), LevelSize.Normal);
                }}
              >
                <FontAwesomeIcon icon={planVisible ? faEye : faEyeSlash} size="sm" />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </ListItem>

      <ListItem>
        <Box flexGrow={1}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography color="text.secondary" variant="h6" flex={2}>
              Cut Minus
            </Typography>

            <Box display="flex" flex={3} ml={3}>
              <Slider
                size="small"
                disabled={size != LevelSize.Minus}
                min={0.05}
                max={1}
                step={0.1}
                value={minusVisibility}
                onChange={(_: any, newValue: number | number[]) => {
                  handleChange(newValue as number, LevelSize.Minus);
                }}
              />

              <Box mx={1} />

              <IconButton
                size="small"
                onClick={() => {
                  handleIconChange(Boolean(!minusVisible), LevelSize.Minus);
                }}
              >
                <FontAwesomeIcon icon={minusVisible ? faEye : faEyeSlash} size="sm" />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </ListItem>
    </>
  );
}
