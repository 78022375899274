import { ActivityOriginType, ActivityType } from '@workflow-nx/common';

export type ActivityTableActionType = {
  type:
    | 'ACTIVITY_ORIGIN_TYPE_CHANGED'
    | 'ACTIVITY_TYPE_CHANGED'
    | 'ORDER_BY_CHANGED'
    | 'RESET_FILTERS'
    | 'refetch';
  data?: any;
};

export type ActivityTabStateType = {
  activityTypeFilter?: ActivityType[];
  activityOriginTypeFilter?: ActivityOriginType[];
  orderBy?: { [key: string]: 'asc' | 'desc' };
};

export const ActivityTabReducer =
  (refetch: any) =>
  (state: ActivityTabStateType, action: ActivityTableActionType): ActivityTabStateType => {
    let updatedState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
      case 'refetch':
        refetch();
        break;
      case 'RESET_FILTERS':
        updatedState = {
          activityTypeFilter: undefined,
          activityOriginTypeFilter: undefined,
          orderBy: { createdAt: 'desc' },
        };
        break;
      case 'ORDER_BY_CHANGED':
        updatedState.orderBy = action.data;
        break;
      case 'ACTIVITY_TYPE_CHANGED':
        updatedState.activityTypeFilter = action.data ? [action.data] : undefined;
        break;
      case 'ACTIVITY_ORIGIN_TYPE_CHANGED':
        updatedState.activityOriginTypeFilter = action.data ? [action.data] : undefined;
        break;
    }

    refetch({
      activityTypeFilter: updatedState.activityTypeFilter,
      originTypeFilter: updatedState.activityOriginTypeFilter,
      orderBy: updatedState.orderBy,
    });

    return updatedState;
  };
