import { Grid, Typography } from '@mui/material';
import { ErrorMessageRHF } from '@workflow-nx/ui';
import React from 'react';
import { Control, FieldValues } from 'react-hook-form';

export function FieldRow(props: {
  id: string;
  label: string;
  control: Control<FieldValues, any>;
  children: React.ReactNode;
}) {
  return (
    <Grid item container xs={12} spacing={1} alignItems={'center'}>
      <Grid item xs={3} md={3} display={'flex'} alignItems={'center'}>
        <Typography variant={'h5'}>{props.label}</Typography>
      </Grid>
      <Grid item xs={6} md={6}>
        {props.children}
      </Grid>
      <Grid item xs={3} md={3} display={'flex'} justifyContent={'center'}>
        <ErrorMessageRHF
          name={props.id}
          control={props.control as unknown as Control<FieldValues>}
        />
      </Grid>
    </Grid>
  );
}
