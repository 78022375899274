import React, { ChangeEvent, useCallback } from 'react';
import { Field } from 'formik';
import { FormControlLabel, Radio, FormControl } from '@mui/material';
import MuiRadioGroup from '@mui/material/RadioGroup';

const RadioGroup = (props: {
  disabled?: boolean;
  label?: string;
  name: string;
  hideLabel?: boolean;
  row?: boolean;
  options: { key: string; value: string }[];
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  const change = useCallback(
    (
        setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void,
        onChange: (event: ChangeEvent<HTMLInputElement>) => void,
      ) =>
      (event: ChangeEvent<HTMLInputElement>) => {
        event.persist();
        setFieldTouched(props.name, true, false);
        onChange(event);
        if (props.onChange) {
          props.onChange(event);
        }
      },
    [props.name],
  );

  return (
    <FormControl fullWidth>
      <Field name={props.name}>
        {({ field, form }: { field: any; form: any }) => (
          <MuiRadioGroup
            name={props.name}
            value={field.value || ''}
            row={props.row}
            onChange={change(form.setFieldTouched, field.onChange)}
          >
            {(props.options || []).map((option, index) => (
              <FormControlLabel
                control={<Radio disabled={props.disabled} />}
                disabled={props.disabled || form.isSubmitting}
                key={index}
                label={!props.hideLabel ? option.value : null}
                value={option.key}
              />
            ))}
          </MuiRadioGroup>
        )}
      </Field>
    </FormControl>
  );
};

export default RadioGroup;
