import React, { memo } from 'react';
import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { testing } from '@workflow-nx/utils';
import { Control, FieldValues, useController } from 'react-hook-form';

const SelectFieldRHF = memo(function ({
  disabled,
  fullWidth = true,
  required,
  multiple,
  hideNone,
  label,
  menuItems,
  name,
  control,
  onChange,
}: {
  name: string;
  control: Control<FieldValues>;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  multiple?: boolean;
  fullWidth?: boolean;
  hideNone?: boolean;
  menuItems: { key: string; value: string }[];
  onChange?: (e: SelectChangeEvent<HTMLSelectElement>) => void;
}) {
  const inputLabel = React.useRef(null);
  const {
    field: {
      onChange: hookFormOnChange,
      onBlur: hookFormOnBlur,
      ref,
      value,
      ...hookFormRemainingProps
    },
    fieldState: { invalid },
  } = useController({ name, control });

  return (
    <FormControl
      fullWidth={fullWidth}
      variant={'outlined'}
      error={invalid}
      required={required}
      size={'small'}
    >
      <InputLabel id={`outlined-${name}-always-notched`} ref={inputLabel}>
        {label}
      </InputLabel>
      <Select
        id={name}
        inputRef={ref}
        data-test-id={`${testing.toKebabCase(label ?? name)}-select-field`}
        type="text"
        displayEmpty={true}
        onBlur={hookFormOnBlur}
        onChange={(e) => {
          if (onChange) {
            onChange(e);
          }
          hookFormOnChange(e);
        }}
        disabled={Boolean(disabled)}
        multiple={multiple}
        value={value ?? ''}
        input={<OutlinedInput name={name} id={`outlined-${name}-always-notched`} label={label} />}
        renderValue={(selected: any) => {
          const value = menuItems.find(
            (menuItem) => menuItem.key.toString() === selected?.toString(),
          )?.value;

          return multiple ? (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {(selected ?? []).map((key: string) => {
                const menuItem = menuItems.find(
                  (menuItem) => menuItem.key.toString() === key.toString(),
                );
                if (!menuItem) {
                  return;
                }

                return (
                  <Chip
                    key={menuItem.key}
                    label={menuItem.value}
                    size={'small'}
                    onMouseDown={(event) => {
                      event.stopPropagation();
                    }}
                    onDelete={() => {
                      const removed = selected.filter((s: string) => s !== menuItem.key);
                      onChange?.({
                        target: { value: removed },
                      } as SelectChangeEvent<HTMLSelectElement>);
                    }}
                  />
                );
              })}
            </Box>
          ) : (
            value
          );
        }}
        {...hookFormRemainingProps}
      >
        {!hideNone ? <MenuItem value={''}>None</MenuItem> : null}

        {menuItems?.map(({ key, value }) => (
          <MenuItem key={key} value={key}>
            {value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
});

export { SelectFieldRHF };
