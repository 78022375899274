import { IPlanShippingLabel } from '../interfaces';

export type Form17DataType = {
  [key: string]: {
    t?: string;
    z?: string;
    v: string | number | undefined;
  };
};

export type HeaderType = {
  value: string;
  width: number;
};

export const PRINT_UPTO_TWENTY_FOUR = 24;

export const generateCaseForm17 = (planShippingLabels: IPlanShippingLabel[]) => {
  const data: Form17DataType[] = [];
  const form17CaseItems = planShippingLabels?.length ? planShippingLabels : [];

  const header = [
    { value: 'Contents #', width: 10 },
    { value: 'Patient Initials', width: 12 },
    { value: 'Index Number', width: 12 },
    { value: 'Imaging Date', width: 12 },
    { value: 'Expiration Date', width: 12 },
    { value: 'LOT #', width: 12 },
    { value: 'Patient ID', width: 10 },
    { value: 'MRN', width: 12 },
    { value: 'Hospital', width: 15 },
    { value: 'Surgeon Name', width: 15 },
    { value: 'REF', width: 10 },
    { value: 'GTIN', width: 15 },
    { value: 'Product Description', width: 35 },
    { value: 'Insertion Side', width: 15 },
    { value: 'Level', width: 10 },
    { value: 'Cage Orientation', width: 15 },
    { value: 'Plus Size', width: 10 },
    { value: 'Thread Angle', width: 12 },
  ];

  let contentNo = 0;

  for (const product of form17CaseItems) {
    contentNo += 1;
    const form17RowsData: Form17DataType = {
      'Contents #': {
        t: 'n',
        v: product?.contentNumber,
      },
      'Patient Initials': {
        t: 's',
        v: product?.patientInitials,
      },
      'Index Number': {
        z: '@',
        t: 's',
        v: product?.indexNumber,
      },
      'Imaging Date': {
        t: 's',
        v: product?.imagingDate,
      },
      'Expiration Date': {
        t: 's',
        v: product?.expirationDate,
      },
      'LOT #': {
        t: 's',
        v: product?.lotNumber,
      },
      'Patient ID': {
        z: '@',
        t: 's',
        v: product?.patientId,
      },

      MRN: {
        z: '@',
        t: 's',
        v: product?.mrn,
      },
      Hospital: {
        t: 's',
        v: product?.hospital,
      },
      'Surgeon Name': {
        t: 's',
        v: product?.surgeonName,
      },
      REF: {
        z: '@',
        t: 's',
        v: product?.ref,
      },
      GTIN: {
        z: '@',
        t: 's',
        v: product?.gtin,
      },
      'Product Description': {
        t: 's',
        v: product?.productDescription,
      },
      'Insertion Side': {
        t: 's',
        v: product?.insertionSide,
      },
      Level: {
        t: 's',
        v: product?.level,
      },
      'Cage Orientation': {
        t: 's',
        v: product?.cageOrientation || 'N/A',
      },
      'Plus Size': {
        t: 's',
        v: product?.plusSize ?? 'N/A',
      },
      'Thread Angle': {
        t: 's',
        v: product?.threadAngle || 'N/A',
      },
    };

    data.push(form17RowsData);
  }

  if (contentNo > 0 && contentNo < PRINT_UPTO_TWENTY_FOUR) {
    for (let i = contentNo + 1; i <= PRINT_UPTO_TWENTY_FOUR; i++) {
      data.push({
        'Contents #': {
          t: 'n',
          v: i,
        },
      });
    }
  }

  return { data, header };
};
