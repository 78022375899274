import React from 'react';
import { format, ImplantOrientation, InterbodyScrewLengthTypes } from '@workflow-nx/common';
import { Box, Typography } from '@mui/material';

import { SurgeonPreferenceImplantType } from './EditSurgeonPreferences.reducer';
import RadioGroup from '../../../../components/RadioGroup';

interface AlifxScrewOrientationEditViewProps {
  implantTypeIndex: number;
  orientation?: ImplantOrientation;
  disabled: boolean;
}

export function AlifxScrewOrientationEditView({
  implantTypeIndex,
  orientation,
  disabled,
}: AlifxScrewOrientationEditViewProps) {
  const screwOrientationOptions = [
    {
      key: ImplantOrientation.LevelSpecific,
      value: format.formatImplantOrientation(ImplantOrientation.LevelSpecific),
    },
    {
      key: ImplantOrientation.TwoUp,
      value: format.formatImplantOrientation(ImplantOrientation.TwoUp),
    },
    {
      key: ImplantOrientation.TwoDown,
      value: format.formatImplantOrientation(ImplantOrientation.TwoDown),
    },
  ];

  const screwOrientationByLevelOptions = [
    {
      key: ImplantOrientation.TwoUp,
      value: format.formatImplantOrientation(ImplantOrientation.TwoUp),
    },
    {
      key: ImplantOrientation.TwoDown,
      value: format.formatImplantOrientation(ImplantOrientation.TwoDown),
    },
  ];

  const screwLengthOptions = [
    {
      key: InterbodyScrewLengthTypes.AlifXScrewLength20mm,
      value: '20MM',
    },
    {
      key: InterbodyScrewLengthTypes.AlifXScrewLength25mm,
      value: '25MM',
    },
    {
      key: InterbodyScrewLengthTypes.AlifXScrewLength30mm,
      value: '30MM',
    },
  ];

  return (
    <>
      <Box display={'flex'} alignItems={'center'} my={2}>
        <Box display={'flex'} width={240} justifyContent={'left'}>
          <Typography variant={'body1'}>Screw Orientation</Typography>
        </Box>
        <Box display={'flex'} width={500} justifyContent={'left'}>
          <RadioGroup
            name={`surgeonPreferenceImplants[${implantTypeIndex}].orientation`}
            label={''}
            hideLabel={false}
            disabled={disabled}
            row={true}
            options={screwOrientationOptions}
            onChange={(e) => {}}
          />
        </Box>
      </Box>
      {orientation === ImplantOrientation.LevelSpecific ? (
        <>
          <Box display={'flex'} alignItems={'center'} my={2}>
            <Box display={'flex'} width={300} justifyContent={'left'}>
              <Typography variant={'body1'}>L3/L4</Typography>
            </Box>
            <Box display={'flex'} width={500} justifyContent={'left'}>
              <RadioGroup
                name={`surgeonPreferenceImplants[${implantTypeIndex}].orientationLevelL3L4`}
                label={''}
                hideLabel={false}
                disabled={false}
                row={true}
                options={screwOrientationByLevelOptions}
                onChange={(e) => {}}
              />
            </Box>
          </Box>
          <Box display={'flex'} alignItems={'center'} my={2}>
            <Box display={'flex'} width={300} justifyContent={'left'}>
              <Typography variant={'body1'}>L4/L5</Typography>
            </Box>
            <Box display={'flex'} width={500} justifyContent={'left'}>
              <RadioGroup
                name={`surgeonPreferenceImplants[${implantTypeIndex}].orientationLevelL4L5`}
                label={''}
                hideLabel={false}
                disabled={false}
                row={true}
                options={screwOrientationByLevelOptions}
                onChange={(e) => {}}
              />
            </Box>
          </Box>
          <Box display={'flex'} alignItems={'center'} my={2}>
            <Box display={'flex'} width={300} justifyContent={'left'}>
              <Typography variant={'body1'}>L5/S1</Typography>
            </Box>
            <Box display={'flex'} width={500} justifyContent={'left'}>
              <RadioGroup
                name={`surgeonPreferenceImplants[${implantTypeIndex}].orientationLevelL5S1`}
                label={''}
                hideLabel={false}
                disabled={false}
                row={true}
                options={screwOrientationByLevelOptions}
                onChange={(e) => {}}
              />
            </Box>
          </Box>
        </>
      ) : null}
      <Box display={'flex'} alignItems={'center'} my={2}>
        <Box display={'flex'} width={240} justifyContent={'left'}>
          <Typography variant={'body1'}>Screw Length</Typography>
        </Box>
        <Box display={'flex'} width={500} justifyContent={'left'}>
          <RadioGroup
            name={`surgeonPreferenceImplants[${implantTypeIndex}].screwLength`}
            label={''}
            hideLabel={false}
            disabled={disabled}
            row={true}
            options={screwLengthOptions}
            onChange={(e) => {}}
          />
        </Box>
      </Box>
    </>
  );
}
