// BabylonScene.tsx
import React, { useEffect, useRef } from 'react';
import { BabylonSceneManager } from './BabylonSceneManager';

const BabylonScene: React.FC<{ debug?: boolean; onReady: (scene: BabylonSceneManager) => void }> = (
  props,
) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const sceneManagerRef = useRef<BabylonSceneManager | null>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const sceneManager = new BabylonSceneManager(canvas, {
      debug: props.debug,
    });

    sceneManager.start();

    sceneManagerRef.current = sceneManager;

    props.onReady(sceneManager);

    return () => {
      if (sceneManagerRef.current) {
        sceneManagerRef.current.dispose();
      }
    };
  }, []);

  return (
    <canvas
      id={'renderCanvas'}
      ref={canvasRef}
      style={{ width: 'calc(100% - 1px)', height: 'calc(100% - 1px)' }}
    />
  );
};

export default BabylonScene;

export * from './BabylonSceneManager';
