import {
  CloudDesignQueueType,
  CloudDesignStatus,
  format,
  IAsset,
  ICloudDesignQueue,
  IPlan,
  IPlanImplant,
  LevelSize,
  LevelType,
  PartType,
} from '@workflow-nx/common';
import { useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  CREATE_ASSET_DOWNLOAD_URL,
  EXPORT_CLOUD_DESIGN_CUT_AND_MESH,
  FIND_ASSETS,
  FIND_CLOUD_DESIGN_QUEUE_ITEMS,
} from '../../../../gql';
import { useSnackbar } from 'notistack';
import * as FileSaver from 'file-saver';
import { green, red, yellow } from '@mui/material/colors';
import { Box, Skeleton, Typography } from '@mui/material';
import { file } from '@workflow-nx/utils';
import { ManageDesignAssetsDialog } from './ManageDesignAssetsDialog';
import { nTopCuts } from '@workflow-nx/common';
import { OptionsMenuButton } from '../../../../components/OptionsMenuButton';
import { useConfirm } from 'material-ui-confirm';
import { faCloudUpload, faDownload, faEye } from '@fortawesome/pro-light-svg-icons';
import { gql } from '@apollo/client/core';

export function DesignAssetsLevelView(props: {
  caseNumber: string;
  levelType: LevelType;
  partType: PartType;
  plan: IPlan;
}) {
  const [loadingDownloadAll, setLoadingDownloadAllDownloadAll] = useState(false);
  const [loadingSendToCloudDesign, setLoadingSendToCloudDesign] = useState(false);
  const [cloudDesignInitialized, setCloudDesignInitialized] = useState(false);
  const [isCloudDesignInProgress, setIsCloudDesignInProgress] = useState(false);
  const [showDesignAssetsDialog, setShowDesignAssetsDialog] = useState(false);
  const [levelAssets, setLevelAssets] = useState<IAsset[]>([]);
  const [createAssetDownloadUrl] = useMutation(CREATE_ASSET_DOWNLOAD_URL);
  const [exportCloudDesignCutAndMesh] = useMutation(EXPORT_CLOUD_DESIGN_CUT_AND_MESH);
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();

  const [findPlanImplants] = useLazyQuery(
    gql`
      query FindPlanImplants($planId: Int!) {
        planImplants(planId: $planId) {
          planImplantId
          level
          excludedImplantSizes
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    },
  );

  function getCalloutColor() {
    let hasAllAssets = hasDesignAssetsCompleted();
    let color = yellow['50'] as string;

    if (hasAllAssets && !isCloudDesignInProgress && !loadingSendToCloudDesign) {
      color = hasAllAssets ? green['50'] : red['50'];
    }
    return color;
  }

  const { startPolling, stopPolling, refetch } = useQuery(FIND_CLOUD_DESIGN_QUEUE_ITEMS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      caseId: props.plan.caseId,
      planId: props.plan.planId,
      level: props.levelType,
      type: CloudDesignQueueType.CutAndMesh,
    },
    onCompleted: (data: any) => {
      const cloudDesignQueueItems = data.cloudDesignQueues as ICloudDesignQueue[];

      let isCloudDesignInProgress = false;

      for (const cloudDesignQueueItem of cloudDesignQueueItems) {
        if (cloudDesignQueueItem.status === CloudDesignStatus.Processing) {
          isCloudDesignInProgress = true;
        }
      }

      setCloudDesignInitialized(true);
      setIsCloudDesignInProgress(isCloudDesignInProgress);
    },
  });

  const assetTypeFilter = [
    `${props.levelType}`,
    `${props.levelType}_MINUS`,
    `${props.levelType}_PLUS`,
    `${props.levelType}_DIMENSIONS`,
    `${props.levelType}_METADATA`,
  ];

  const { refetch: refetchLevelAssets } = useQuery(FIND_ASSETS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      caseId: props.plan.caseId,
      planId: props.plan.planId,
      assetTypeFilter,
    },
    onCompleted: (data: any) => {
      setLevelAssets(data.assets);
    },
  });

  async function getDownloadAsBlob(
    caseNumber: string,
    asset: IAsset,
    levelSize: LevelSize,
    partType: PartType,
  ) {
    const { data } = await createAssetDownloadUrl({
      variables: {
        assetId: asset?.assetId,
      },
    });
    const response = await file.downloadFile(data.createAssetDownloadUrl.signedUrl);
    const extension = asset.fileName.slice(asset.fileName.lastIndexOf('.'));

    const partTypeCode = nTopCuts.getImplantPartTypeCode(partType);
    const sizeCode = nTopCuts.getImplantSizeNumber(levelSize);
    let levelCode = asset.assetType
      .replace('_PLUS', '')
      .replace('_MINUS', '')
      .replace('_METADATA', '')
      .replace('_DIMENSIONS', '')
      .replace('_L', '')
      .replace('_S', '');

    if (asset.assetType.endsWith('_METADATA')) {
      levelCode = `${levelCode}.METADATA`;
    }

    if (asset.assetType.endsWith('_DIMENSIONS')) {
      levelCode = `${levelCode}.DIMENSIONS`;
    }

    return {
      blob: new Blob([response.data], { type: 'octet/stream' }),
      name: `${caseNumber}_${partTypeCode}.${levelCode}.${sizeCode}.${extension}`,
    };
  }

  function hasDesignAssetsCompleted() {
    let hasDesignAssetsComplete = true;

    const requiredAssets = [`${props.levelType}_MINUS`, props.levelType, `${props.levelType}_PLUS`];

    for (let requiredAsset of requiredAssets) {
      const foundPlanAsset = levelAssets.find(
        (levelAsset) => levelAsset.assetType === requiredAsset,
      );
      if (!foundPlanAsset) {
        hasDesignAssetsComplete = false;
      }
    }

    return hasDesignAssetsComplete;
  }

  const handleSendAllToCloudDesign = async (): Promise<void> => {
    try {
      if (hasDesignAssetsCompleted()) {
        await confirm({
          title: `Send All To Cloud Design ${format.formatLevelType(props.levelType)}?`,
          description: (
            <>
              This will remove the implants that have been completed and re-send the implants though
              Cloud Design. Proceed?
            </>
          ),
        });
      }

      setLoadingSendToCloudDesign(true);

      const promises = [];

      for (let levelSize of [LevelSize.Normal, LevelSize.Plus, LevelSize.Minus]) {
        promises.push(
          exportCloudDesignCutAndMesh({
            variables: {
              caseId: props.plan.caseId,
              input: {
                level: props.levelType,
                levelSize,
                planId: props.plan.planId,
              },
            },
          }),
        );
      }

      await Promise.all(promises);

      setIsCloudDesignInProgress(true);

      enqueueSnackbar(
        `Cutting and meshing of ${format.formatLevelType(props.levelType)} ${format.formatPartType(
          props.partType,
        )} initiated`,
        {
          variant: 'success',
        },
      );

      refetch();
      startPolling(10000);
    } catch (err) {
      console.error(err);
      enqueueSnackbar('An error occurred while initiating cloud design', {
        variant: 'error',
      });
    } finally {
      setLoadingSendToCloudDesign(false);
    }
  };

  const handleDownloadAll = async (
    caseNumber: string,
    levelType: LevelType,
    partType: PartType,
  ) => {
    try {
      setLoadingDownloadAllDownloadAll(true);

      const { data } = await findPlanImplants({ variables: { planId: props.plan.planId } });

      const foundPlanImplant = data.planImplants.find((pi: IPlanImplant) => pi.level === levelType);

      const downloadAsBlobPromises = [];

      for (let i = 0; i < levelAssets.length; i++) {
        const asset: IAsset = levelAssets[i];
        let levelSize = LevelSize.Normal;

        levelSize = asset.assetType.endsWith('_PLUS') ? LevelSize.Plus : levelSize;
        levelSize = asset.assetType.endsWith('_MINUS') ? LevelSize.Minus : levelSize;

        // if the planImplant is excluding this level size, then don't include
        // it in the zip that's downloaded
        if (foundPlanImplant?.excludedImplantSizes?.includes(levelSize)) {
          continue;
        }

        if (asset) {
          downloadAsBlobPromises.push(getDownloadAsBlob(caseNumber, asset, levelSize, partType));
        }
      }

      const assetsToZip = await Promise.all(downloadAsBlobPromises);
      const zipFile = await file.createZipFile(assetsToZip);
      FileSaver.saveAs(zipFile, `${props.caseNumber}-${levelType}-design-assets.zip`);
    } catch (e) {
      enqueueSnackbar('Error Downloading File', {
        variant: 'error',
      });
    } finally {
      setLoadingDownloadAllDownloadAll(false);
    }
  };

  useEffect(() => {
    if (isCloudDesignInProgress) {
      startPolling(60000);
    }
    stopPolling();
  }, [isCloudDesignInProgress]);

  const levelOptions = [
    {
      key: 'VIEW',
      icon: faEye,
      label: 'View Level',
      disabled: false,
    },
    {
      key: 'CLOUD_DESIGN_ALL',
      icon: faCloudUpload,
      label: 'Send To Cloud Design',
      disabled: loadingDownloadAll || hasDesignAssetsCompleted(),
    },
    {
      key: 'DOWNLOAD',
      icon: faDownload,
      label: 'Download Level Assets',
      disabled: loadingSendToCloudDesign || isCloudDesignInProgress,
    },
  ];

  return (
    <>
      {!cloudDesignInitialized ? (
        <Box display={'flex'} alignItems={'center'} mx={10}>
          <Skeleton component={'div'} width={'100%'} height={120} />
        </Box>
      ) : null}
      {cloudDesignInitialized ? (
        <Box
          display={'flex'}
          bgcolor={getCalloutColor()}
          borderRadius="5px"
          alignItems={'center'}
          mb={2}
          p={2}
          mx={10}
        >
          <Box sx={{ width: 150 }}>
            <Typography variant={'body1'}>
              <strong>{format.formatLevelType(props.levelType)}</strong>
            </Typography>
            <Typography variant={'body1'}>{format.formatPartType(props.partType)}</Typography>
          </Box>
          <Box mx={1} />
          <Box>
            {!isCloudDesignInProgress ? (
              <Typography variant={'body1'}>
                {hasDesignAssetsCompleted()
                  ? 'All design assets are complete for this level'
                  : 'There are design assets missing for this level'}
              </Typography>
            ) : (
              <Typography variant={'button'} color={'textSecondary'}>
                Cloud Design is currently in progress for this level
              </Typography>
            )}
          </Box>
          <Box flexGrow={1} />
          <Box>
            <OptionsMenuButton
              disabled={false}
              options={levelOptions}
              onSelect={async (option) => {
                switch (option) {
                  case 'CLOUD_DESIGN_ALL':
                    await handleSendAllToCloudDesign();
                    break;
                  case 'VIEW':
                    setShowDesignAssetsDialog(true);
                    break;
                  case 'DOWNLOAD':
                    await handleDownloadAll(props.caseNumber, props.levelType, props.partType);
                    break;
                }
              }}
            />
          </Box>
        </Box>
      ) : null}
      <ManageDesignAssetsDialog
        plan={props.plan}
        partType={props.partType}
        open={showDesignAssetsDialog}
        level={props.levelType}
        caseNumber={props.caseNumber}
        onClose={() => {
          setShowDesignAssetsDialog(false);
        }}
        onStatusChange={(level, levelSize, partType, cloudDesignStatus) => {
          // need to reload plan assets for the level here
          // need to delete old asset when recut is chosen + confirm
          refetch();
          refetchLevelAssets();
        }}
      />
    </>
  );
}
