import { faCircleNotch, faFolderDownload, faVideoCamera } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { useState } from 'react';
import { ImplantListRootCameraView } from './ImplantList';

export function CameraViewMenu(props: {
  disabled?: boolean;
  onCameraSelection: (cameraView: ImplantListRootCameraView) => void;
}) {
  const [cameraMenuEl, setCameraMenuEl] = useState<null | HTMLElement>(null);

  return (
    <>
      <Tooltip title={'Change Camera View'}>
        <IconButton
          disabled={props.disabled}
          onClick={(event) => setCameraMenuEl(event.currentTarget)}
        >
          <FontAwesomeIcon icon={faVideoCamera} />
        </IconButton>
      </Tooltip>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        anchorEl={cameraMenuEl}
        keepMounted
        open={Boolean(cameraMenuEl)}
        onClose={() => setCameraMenuEl(null)}
      >
        <MenuItem
          key={'CORONAL'}
          onClick={() => {
            props.onCameraSelection('CORONAL');
            setCameraMenuEl(null);
          }}
        >
          Coronal
        </MenuItem>
        <MenuItem
          key={'SAGITTAL_LEFT'}
          onClick={() => {
            props.onCameraSelection('SAGITTAL_LEFT');
            setCameraMenuEl(null);
          }}
        >
          Sagittal Left
        </MenuItem>
        <MenuItem
          key={'SAGITTAL_RIGHT'}
          onClick={() => {
            props.onCameraSelection('SAGITTAL_RIGHT');
            setCameraMenuEl(null);
          }}
        >
          Sagittal Right
        </MenuItem>
        <MenuItem
          key={'AXIAL'}
          onClick={() => {
            props.onCameraSelection('AXIAL');
            setCameraMenuEl(null);
          }}
        >
          Axial
        </MenuItem>
      </Menu>
    </>
  );
}

export function ImplantListRootOptionsMenu(props: {
  disabled?: boolean;
  loading?: boolean;
  downloading?: boolean;
  onDownloadAll: () => Promise<void>;
  onCameraSelection: (cameraView: ImplantListRootCameraView) => void;
}) {
  return (
    <>
      <CameraViewMenu disabled={props.disabled} onCameraSelection={props.onCameraSelection} />
      <Tooltip title={'Download All Implant Assets'}>
        <IconButton disabled={props.disabled} onClick={props.onDownloadAll}>
          {props?.downloading ? (
            <FontAwesomeIcon icon={faCircleNotch} spin={true} />
          ) : (
            <FontAwesomeIcon icon={faFolderDownload} />
          )}
        </IconButton>
      </Tooltip>
    </>
  );
}
