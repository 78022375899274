import React, { useReducer, useState } from 'react';

import { useQuery } from '@apollo/client';
import { FIND_CASES } from '../../gql';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import {
  CaseCancellationListType,
  CaseStageType,
  CaseType,
  format,
  ICase,
  ICaseCalendarFilteredCases,
  ICaseCalendarQueryVariables,
} from '@workflow-nx/common';
import { DashboardCardView } from './DashboardCardView';
import { IconFontButton } from '@workflow-nx/ui';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { CaseCalendarStateType } from '../cases/CaseCalendarView/CaseCalendar.reducer';
import { DateTime } from 'luxon';
import { CaseCalendarWeekReducer } from './CaseCalendarWeek.reducer';
import CalendarGridEventView from '../cases/CaseCalendarView/CalendarGridEventView';
import { CalendarEventView } from '../cases/CaseCalendarView/CalendarEventView';
import clsx from 'clsx';
import {
  CASE_CATEGORY_COLOR_LEGEND,
  CalendarCaseCategory,
  getCalendarCaseCategory,
  useCalendarStyles,
} from '../cases/CaseCalendarView/config';
import { date } from '@workflow-nx/utils';

const DAY_COLUMNS = 4;

const getDefaultState = () => {
  const today = new Date();
  const startDate = DateTime.fromJSDate(today).toISODate() as string;
  const endDate = DateTime.fromJSDate(
    new Date(today.setDate(today.getDate() + 3)),
  ).toISODate() as string;

  const defaultVariables: ICaseCalendarQueryVariables = {
    caseCancellationTypeFilter: [CaseCancellationListType.None],
    caseTypeFilter: [CaseType.Live],
    tagsFilter: [],
    stageFilter: [
      CaseStageType.Open,
      CaseStageType.Segmentation,
      CaseStageType.Planning,
      CaseStageType.Proposed,
      CaseStageType.Design,
      CaseStageType.QaReview,
      CaseStageType.Production,
      CaseStageType.Ready,
      CaseStageType.Complete,
    ],
    orderBy: { surgeryDate: 'asc' },
    search: '',
    showTentativeSurgeryDates: true,
    startDate,
    endDate,
  };

  const initialState: CaseCalendarStateType = {
    organization: '',
    surgeon: '',
    fieldRep: '',
    orderBy: { surgeryDate: 'asc' },
    search: '',
    caseStatus: '',
    caseTags: [],
    showTentativeSurgeryDates: true,
    showDraftCases: false,
  };

  return [defaultVariables, initialState];
};

const CaseCalendarWeek = () => {
  const theme = useTheme();
  const calendarStyles = useCalendarStyles();
  const [defaultVariables, initialState] = getDefaultState();
  const [selectedDate, setSelectedDate] = useState<DateTime>(DateTime.now());
  const { loading, data, refetch } = useQuery(FIND_CASES, {
    variables: defaultVariables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const [, dispatch] = useReducer(
    CaseCalendarWeekReducer(refetch),
    initialState as CaseCalendarStateType,
  );

  const getCellDate = (dayAdjustment: number) => {
    return selectedDate.plus({ day: dayAdjustment });
  };

  const getSurgeryDate = (surgeryDate: Date) => {
    return format.formatISODate(surgeryDate as unknown as string);
  };

  const getMonthAndYearDisplay = () => {
    const firstDate = selectedDate;
    const lastDate = selectedDate.plus({ day: DAY_COLUMNS });

    if (firstDate.monthLong !== lastDate.monthLong) {
      if (firstDate.year !== lastDate.year) {
        return `${firstDate.monthLong} ${firstDate.year} - ${lastDate.monthLong} ${lastDate.year}`;
      }
      return `${firstDate.monthLong} - ${lastDate.monthLong} ${lastDate.year}`;
    }
    return `${firstDate.monthLong} ${firstDate.year}`;
  };

  const filteredCases: ICaseCalendarFilteredCases[] = data?.cases?.cases
    ?.filter((activeCase: ICase) => !!activeCase.surgeryDate)
    .map((activeCase: ICase) => {
      const caseCategory = getCalendarCaseCategory(activeCase) as CalendarCaseCategory;
      const { palette, stripedClass } = CASE_CATEGORY_COLOR_LEGEND(theme)[caseCategory];
      const className =
        (activeCase.surgeryDate && activeCase.isSurgeryDateTentative) ||
        activeCase.caseType === CaseType.Draft
          ? clsx([
              calendarStyles[stripedClass as keyof typeof calendarStyles],
              calendarStyles.stripeTextBackground,
            ])
          : [calendarStyles.stripeTextBackground];

      return {
        title: activeCase.number,
        date: getSurgeryDate(activeCase.surgeryDate as Date),
        extendedProps: activeCase,
        backgroundColor: palette.main,
        borderColor: palette.main,
        textColor: palette.contrastText,
        className,
      };
    });

  return (
    <DashboardCardView label={'Case Calendar'}>
      <Box height={'100%'} display={'flex'} flexDirection={'column'} sx={{ overflowX: 'auto' }}>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          sx={{ mb: 1 }}
          padding={'5px'}
        >
          <Typography variant={'h3'}>{getMonthAndYearDisplay()}</Typography>
          <Stack spacing={1} direction={'row'}>
            <IconFontButton
              disabled={loading || date.isBeforeToday(selectedDate.toISODate() as string)}
              icon={faChevronLeft}
              onClick={() => {
                const newSelectedDate = selectedDate.minus({ days: DAY_COLUMNS });
                setSelectedDate(newSelectedDate);

                dispatch({
                  type: 'CASE_DATE_RANGE_CHANGED',
                  data: {
                    startDate: newSelectedDate.toISODate(),
                    endDate: newSelectedDate.plus({ days: DAY_COLUMNS }).toISODate(),
                  },
                });
              }}
            />
            <IconFontButton
              disabled={loading}
              icon={faChevronRight}
              onClick={() => {
                const newSelectedDate = selectedDate.plus({ days: DAY_COLUMNS });
                setSelectedDate(newSelectedDate);

                dispatch({
                  type: 'CASE_DATE_RANGE_CHANGED',
                  data: {
                    startDate: newSelectedDate.toISODate(),
                    endDate: newSelectedDate.plus({ days: DAY_COLUMNS }).toISODate(),
                  },
                });
              }}
            />
          </Stack>
        </Stack>
        <Box
          sx={{
            borderRight: `1px solid ${theme.palette.grey[200]}`,
            borderBottom: `1px solid ${theme.palette.grey[200]}`,
            display: 'grid',
            gridAutoFlow: 'row',
            gridTemplateRows: `30px minmax(150px, 1fr)`,
            gridTemplateColumns: `repeat(${DAY_COLUMNS}, minmax(120px, 1fr))`,
          }}
          flexBasis={'100%'}
        >
          {[...Array(DAY_COLUMNS)].map((x, index: number) => {
            const cellDate = getCellDate(index);

            return (
              <Box
                key={index}
                sx={{
                  borderLeft: `1px solid ${theme.palette.grey[200]}`,
                  borderTop: `1px solid ${theme.palette.grey[200]}`,
                  background: '#eee',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  sx={{
                    textTransform: 'uppercase',
                    color: theme.palette.grey[600],
                  }}
                >
                  <strong>{cellDate.weekdayShort}</strong>
                </Typography>
              </Box>
            );
          })}

          {[...Array(DAY_COLUMNS)].map((y, index) => {
            const color = theme.palette.grey[500];
            const cellDate = getCellDate(index);

            return (
              <Box
                key={index}
                sx={{
                  borderLeft: `1px solid ${theme.palette.grey[200]}`,
                  borderTop: `1px solid ${theme.palette.grey[200]}`,
                }}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'space-between'}
                flexBasis={'auto'}
              >
                {loading ? (
                  <Box
                    sx={{
                      position: 'relative',
                      height: '100%',
                      width: '100%',
                      zIndex: 1000,
                      backgroundColor: 'rgba(0,0,0, 0.01)',
                    }}
                  />
                ) : null}

                <Box
                  sx={{
                    display: 'flex',
                    color,
                    justifyContent: 'flex-end',
                  }}
                >
                  {!loading ? (
                    <Box
                      sx={{
                        fontWeight: 'bold',
                        margin: '2px',
                        padding: '2px',
                        opacity: loading ? '0.1' : undefined,
                      }}
                    >
                      {cellDate.day}
                    </Box>
                  ) : null}
                </Box>
                <Stack
                  direction={'column'}
                  justifyContent={'flex-start'}
                  alignItems={'center'}
                  height={'100%'}
                >
                  {filteredCases
                    ?.filter((c: ICaseCalendarFilteredCases) => c.date === cellDate.toISODate())
                    .map((reportedCase: ICaseCalendarFilteredCases, index: number) => (
                      <CalendarGridEventView key={index} extendedProps={reportedCase.extendedProps}>
                        <Box maxWidth={'min(150px, 85%)'} textOverflow={'clip'} margin={'auto'}>
                          <CalendarEventView event={reportedCase} />
                        </Box>
                      </CalendarGridEventView>
                    ))}
                </Stack>
              </Box>
            );
          })}
        </Box>
      </Box>
    </DashboardCardView>
  );
};

export default CaseCalendarWeek;
