import { PlanStatusType } from '@workflow-nx/common';
import { Chip } from '@mui/material';
import React from 'react';

export function PlanStatusChip(props: { status: PlanStatusType }) {
  function getPlanStatusColor(
    status: PlanStatusType,
  ): 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' {
    switch (status) {
      case PlanStatusType.Approved:
        return 'success';
      case PlanStatusType.Proposed:
        return 'warning';
      default:
        return 'default';
    }
  }

  return <Chip color={getPlanStatusColor(props.status)} label={props.status}></Chip>;
}
