import { ICasePathology, PathologyType } from '@workflow-nx/common';
import { CasePathologyType } from '../../views/cases/CaseView/CasePlanningTab/CaseReportDialog/CaseReportDialog.reducer';
import { sortBy } from 'lodash';

export const PATHOLOGY_TYPE_CUSTOM_SORT = [
  PathologyType.Osteomyelitis,
  PathologyType.DiscDegeneration,
  PathologyType.AdultDegenerativeScoliosis,
  PathologyType.AdjacentSegmentDisease,
  PathologyType.Spondylolisthesis,
  PathologyType.TransitionalAnatomy,
  PathologyType.ExtraVertebrae,
  PathologyType.EndplateDeformityAtTreatmentLevels,
  PathologyType.UniqueAnatomy,
  PathologyType.RevisionOfInterbodyImplant,
  PathologyType.RevisionOfOtherPriorHardware,
  PathologyType.Other,
];

export const getCasePathologies = (casePathologies: ICasePathology[]): CasePathologyType[] => {
  const pathologies: CasePathologyType[] = [];
  const pathologyTypes = Object.values(PathologyType);

  pathologyTypes.forEach((pathologyType) => {
    const casePathology = casePathologies.find(
      (casePathology) => casePathology.pathologyType === pathologyType,
    );

    if (casePathology) {
      pathologies.push({
        pathologyType: casePathology.pathologyType,
        pathologySelected: true,
        pathologyNote: casePathology.pathologyNote || '',
      });
    } else {
      pathologies.push({
        pathologyType: pathologyType,
        pathologySelected: false,
        pathologyNote: '',
      });
    }
  });

  return sortBy(pathologies, (item) => PATHOLOGY_TYPE_CUSTOM_SORT.indexOf(item.pathologyType));
};
