import { Box, Divider, Menu, MenuItem, Popover, Slider, Stack } from '@mui/material';
import { IconFontButton } from '@workflow-nx/ui';
import { faClose, faCube, faScrewdriver, faVideoCamera } from '@fortawesome/pro-light-svg-icons';
import {
  faEye as faEyeSolid,
  faEyeSlash as faEyeSlashSolid,
} from '@fortawesome/pro-solid-svg-icons';
import React, { useContext, useState } from 'react';
import { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PlanViewDialogContext } from './PlanViewDialog.context';
import { PartType } from '@workflow-nx/common';

export function ImplantNavigationView() {
  const planViewDialogContext = useContext(PlanViewDialogContext);
  const [cameraMenuEl, setCameraMenuEl] = useState<null | HTMLElement>(null);
  const opacityPopupState = usePopupState({
    variant: 'popover',
    popupId: 'opacityPopover',
  });

  const handleCameraMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setCameraMenuEl(event.currentTarget);
  };

  return (
    <Stack
      alignItems={'center'}
      justifyContent={'center'}
      divider={<Divider orientation={'horizontal'} flexItem={true} />}
    >
      <Box height={50} display={'flex'} alignItems={'center'}>
        <IconFontButton icon={faVideoCamera} onClick={handleCameraMenuClick} />
        <Menu
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          anchorEl={cameraMenuEl}
          keepMounted
          open={Boolean(cameraMenuEl)}
          onClose={() => setCameraMenuEl(null)}
        >
          <MenuItem
            onClick={() => {
              planViewDialogContext.switchCamera('CORONAL');
              setCameraMenuEl(null);
            }}
          >
            Coronal
          </MenuItem>
          <MenuItem
            onClick={() => {
              planViewDialogContext.switchCamera('SAGITTAL_LEFT');
              setCameraMenuEl(null);
            }}
          >
            Sagittal Left
          </MenuItem>
          <MenuItem
            onClick={() => {
              planViewDialogContext.switchCamera('SAGITTAL_RIGHT');
              setCameraMenuEl(null);
            }}
          >
            Sagittal Right
          </MenuItem>
          <MenuItem
            onClick={() => {
              planViewDialogContext.switchCamera('AXIAL');
              setCameraMenuEl(null);
            }}
          >
            Axial
          </MenuItem>
        </Menu>
      </Box>
      <Box height={50} display={'flex'} alignItems={'center'}>
        <IconFontButton icon={faCube} {...bindTrigger(opacityPopupState)} />
        <Popover
          style={{ zIndex: 9999 }}
          {...bindPopover(opacityPopupState)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Box display={'flex'} width={200} alignItems={'center'}>
            <Box mx={1}>
              <FontAwesomeIcon color={'#696969'} icon={faEyeSlashSolid} />
            </Box>
            <Box mx={1} flexGrow={1}>
              <Slider
                min={0.1}
                max={1.1}
                step={0.1}
                value={planViewDialogContext.opacity}
                onChange={(event: any, newValue: number | number[]) => {
                  planViewDialogContext.toggleOpacity(newValue as number);
                }}
              />
            </Box>
            <Box mx={1}>
              <FontAwesomeIcon color={'#696969'} icon={faEyeSolid} />
            </Box>
          </Box>
        </Popover>
      </Box>
      {[PartType.ALIF_X_TWO_DOWN, PartType.ALIF_X_TWO_UP].includes(
        planViewDialogContext?.implantState?.part as PartType,
      ) ? (
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          height={50}
          width={60}
          style={{
            backgroundColor: planViewDialogContext?.implantState.screwsVisible ? 'lightblue' : '',
          }}
        >
          <IconFontButton
            icon={faScrewdriver}
            onClick={() => {
              planViewDialogContext.toggleScrews();
            }}
          />
        </Box>
      ) : null}
      <Box height={50} display={'flex'} alignItems={'center'}>
        <IconFontButton
          icon={faClose}
          onClick={() => {
            planViewDialogContext.toggleImplant(null);
          }}
        />
      </Box>
    </Stack>
  );
}
