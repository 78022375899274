import {
  format,
  ImplantDirection,
  ImplantOrientation,
  ImplantType,
  InterbodyScrewLengthTypes,
  ISurgeonPreferenceImplant,
  ISurgeonPreferenceImplantMeasurement,
  LevelType,
  PositionDistanceType,
  SagittalGoalType,
  SurgeonPreferenceMeasurementType,
  SurgeonPreferencesError,
  SurgeonPreferencesErrorCodeType,
} from '@workflow-nx/common';
import * as math from '@workflow-nx/math';
import {
  SurgeonPreferenceImplantMeasurementType,
  SurgeonPreferenceImplantType,
  SurgeonPreferencesStateType,
} from '../views/users/SurgeonView/SurgeonPreferencesView/EditSurgeonPreferences.reducer';
import { sortBy } from 'lodash';
import { isTlifCOrientationAvailable } from './featureFlags';

type MeasurementMinMaxType = {
  footprintApRange: { min: number; max: number };
  footprintMlRange: { min: number; max: number };
  anteriorHeightRange: { min: number; max: number };
  posteriorHeightRange: { min: number; max: number };
  lordosisAngleRange: { min: number; max: number };
  positionDistanceRange: { min: number; max: number };
};

type MeasurementMinMaxTypes = {
  alif: MeasurementMinMaxType;
  alifx: MeasurementMinMaxType;
  llif: MeasurementMinMaxType;
  tlifo: MeasurementMinMaxType;
  tlifc: MeasurementMinMaxType;
  tlifca: MeasurementMinMaxType;
};

type MeasurementMinMaxSettingsType = {
  measurementTypes: MeasurementMinMaxTypes;
};

export const IMPLANT_TYPE_CUSTOM_SORT = [
  ImplantType.ALIF,
  ImplantType.ALIFX,
  ImplantType.LLIF,
  ImplantType.TLIFC,
  ImplantType.TLIFCA,
  ImplantType.TLIFO,
];

export const MEASUREMENT_TYPE_CUSTOM_SORT = [
  SurgeonPreferenceMeasurementType.FootprintApRange,
  SurgeonPreferenceMeasurementType.FootprintMlRange,
  SurgeonPreferenceMeasurementType.AnteriorHeightRange,
  SurgeonPreferenceMeasurementType.PosteriorHeightRange,
  SurgeonPreferenceMeasurementType.LordosisAngleRange,
  SurgeonPreferenceMeasurementType.ImplantPositionDistanceRange,
];

export const SURGEON_PREFERENCES_MIN_MAX_SETTINGS: MeasurementMinMaxSettingsType = {
  measurementTypes: {
    alif: {
      footprintApRange: { min: 18, max: 29 },
      footprintMlRange: { min: 29, max: 60 },
      anteriorHeightRange: { min: 5.5, max: 21 },
      posteriorHeightRange: { min: 3.8, max: 21 },
      lordosisAngleRange: { min: 0, max: 30 },
      positionDistanceRange: { min: 0, max: 8 },
    },
    alifx: {
      footprintApRange: { min: 18, max: 34 },
      footprintMlRange: { min: 30, max: 60 },
      anteriorHeightRange: { min: 8.5, max: 21 },
      posteriorHeightRange: { min: 3.8, max: 19.1 },
      lordosisAngleRange: { min: 0, max: 30 },
      positionDistanceRange: { min: 0, max: 8 },
    },
    llif: {
      footprintApRange: { min: 18, max: 29 },
      footprintMlRange: { min: 29, max: 60 },
      anteriorHeightRange: { min: 5.5, max: 21 },
      posteriorHeightRange: { min: 3.8, max: 21 },
      lordosisAngleRange: { min: 0, max: 30 },
      positionDistanceRange: { min: 0, max: 0 },
    },
    tlifo: {
      footprintApRange: { min: 10, max: 14 },
      footprintMlRange: { min: 25, max: 40 },
      anteriorHeightRange: { min: 5, max: 18 },
      posteriorHeightRange: { min: 5, max: 18 },
      lordosisAngleRange: { min: 0, max: 15 },
      positionDistanceRange: { min: 0, max: 8 },
    },
    tlifc: {
      footprintApRange: { min: 12, max: 15 },
      footprintMlRange: { min: 27, max: 40 },
      anteriorHeightRange: { min: 6, max: 16 },
      posteriorHeightRange: { min: 6, max: 16 },
      lordosisAngleRange: { min: 0, max: 15 },
      positionDistanceRange: { min: 0, max: 8 },
    },
    tlifca: {
      footprintApRange: { min: 12, max: 15 },
      footprintMlRange: { min: 27, max: 40 },
      anteriorHeightRange: { min: 6, max: 16 },
      posteriorHeightRange: { min: 6, max: 16 },
      lordosisAngleRange: { min: 0, max: 15 },
      positionDistanceRange: { min: 0, max: 8 },
    },
  },
};

const SURGEON_PREFERENCES_DEFAULT_SETTINGS = {
  SurgeonPreferences: {},
  SurgeonPreferencesImplants: {},
  measurementTypes: {
    alif: {
      footprintApRange: { min: 18, max: 29 },
      footprintMlRange: { min: 29, max: 60 },
      anteriorHeightRange: { min: 5.5, max: 21 },
      posteriorHeightRange: { min: 3.8, max: 21 },
      lordosisAngleRange: { min: 0, max: 30 },
      positionDistanceRange: { min: 0, max: 8 },
    },
    alifx: {
      footprintApRange: { min: 18, max: 34 },
      footprintMlRange: { min: 30, max: 60 },
      anteriorHeightRange: { min: 8.5, max: 21 },
      posteriorHeightRange: { min: 3.8, max: 19.1 },
      lordosisAngleRange: { min: 0, max: 30 },
      positionDistanceRange: { min: 0, max: 8 },
    },
    llif: {
      footprintApRange: { min: 18, max: 29 },
      footprintMlRange: { min: 29, max: 60 },
      anteriorHeightRange: { min: 5.5, max: 21 },
      posteriorHeightRange: { min: 3.8, max: 21 },
      lordosisAngleRange: { min: 0, max: 30 },
      positionDistanceRange: { min: 0, max: 0 },
    },
    tlifo: {
      footprintApRange: { min: 10, max: 14 },
      footprintMlRange: { min: 25, max: 40 },
      anteriorHeightRange: { min: 5, max: 18 },
      posteriorHeightRange: { min: 5, max: 18 },
      lordosisAngleRange: { min: 0, max: 15 },
      positionDistanceRange: { min: 0, max: 8 },
    },
    tlifc: {
      footprintApRange: { min: 12, max: 15 },
      footprintMlRange: { min: 27, max: 40 },
      anteriorHeightRange: { min: 6, max: 16 },
      posteriorHeightRange: { min: 6, max: 16 },
      lordosisAngleRange: { min: 0, max: 15 },
      positionDistanceRange: { min: 0, max: 8 },
    },
    tlifca: {
      footprintApRange: { min: 12, max: 15 },
      footprintMlRange: { min: 27, max: 40 },
      anteriorHeightRange: { min: 6, max: 16 },
      posteriorHeightRange: { min: 6, max: 16 },
      lordosisAngleRange: { min: 0, max: 15 },
      positionDistanceRange: { min: 0, max: 8 },
    },
  },
};

/**
 * The below default values kept temporarily and will be deleted later
 */

/*
const SURGEON_PREFERENCES_DEFAULT_SETTINGS = {
  SurgeonPreferences: {},
  SurgeonPreferencesImplants: {},
  measurementTypes: {
    alif: {
      footprintApRange: { min: 24, max: 26 },
      footprintMlRange: { min: 32, max: 42 },
      anteriorHeightRange: { min: 8, max: 16 },
      posteriorHeightRange: { min: 7, max: 10 },
      lordosisAngleRange: { min: 0, max: 20 },
      positionDistanceRange: { min: 2, max: 5 },
    },
    alifx: {
      footprintApRange: { min: 24, max: 26 },
      footprintMlRange: { min: 32, max: 42 },
      anteriorHeightRange: { min: 8.5, max: 16 },
      posteriorHeightRange: { min: 7, max: 10 },
      lordosisAngleRange: { min: 6, max: 20 },
      positionDistanceRange: { min: 1, max: 2 },
    },
    llif: {
      footprintApRange: { min: 18, max: 24 },
      footprintMlRange: { min: 45, max: 50 },
      anteriorHeightRange: { min: 8, max: 16 },
      posteriorHeightRange: { min: 7, max: 12 },
      lordosisAngleRange: { min: 0, max: 20 },
      positionDistanceRange: { min: 0, max: 0 },
    },
    tlifo: {
      footprintApRange: { min: 10, max: 12 },
      footprintMlRange: { min: 25, max: 30 },
      anteriorHeightRange: { min: 8, max: 12 },
      posteriorHeightRange: { min: 7, max: 12 },
      lordosisAngleRange: { min: 0, max: 10 },
      positionDistanceRange: { min: 2, max: 5 },
    },
    tlifc: {
      footprintApRange: { min: 12, max: 14 },
      footprintMlRange: { min: 27, max: 35 },
      anteriorHeightRange: { min: 8, max: 12 },
      posteriorHeightRange: { min: 7, max: 9.9 },
      lordosisAngleRange: { min: 0, max: 10 },
      positionDistanceRange: { min: 2, max: 5 },
    },
  },
};
*/

export const getSurgeonPreferenceImplants = (
  surgeonPreferenceImplants: ISurgeonPreferenceImplant[],
): SurgeonPreferenceImplantType[] => {
  const preferencesImplants: SurgeonPreferenceImplantType[] = [];
  const implantTypes = Object.values(ImplantType).filter((element) => element !== ImplantType.None);

  implantTypes.forEach((implantType) => {
    const surgeonPreferenceImplant = surgeonPreferenceImplants.find(
      (surgeonPreferenceImplant) => surgeonPreferenceImplant.implantType === implantType,
    );

    if (surgeonPreferenceImplant) {
      const orientationByLevels = surgeonPreferenceImplant?.orientationByLevels ?? {};

      preferencesImplants.push({
        implantType: surgeonPreferenceImplant?.implantType,
        positioning: surgeonPreferenceImplant?.positioning,
        direction: surgeonPreferenceImplant?.direction || undefined,
        orientation: surgeonPreferenceImplant?.orientation || undefined,
        orientationLevelL3L4: orientationByLevels[LevelType.L3L4] || 'NONE',
        orientationLevelL4L5: orientationByLevels[LevelType.L4L5] || 'NONE',
        orientationLevelL5S1: orientationByLevels[LevelType.L5S1] || 'NONE',
        screwLength: surgeonPreferenceImplant?.screwLength || undefined,
        maximizeFootprint: surgeonPreferenceImplant?.maximizeFootprint,
        plusSizeImplant: surgeonPreferenceImplant?.plusSizeImplant,
        minimalHeightSpecification: surgeonPreferenceImplant?.minimalHeightSpecification,
        excludedImplantSizes: surgeonPreferenceImplant?.excludedImplantSizes || [],
        note: surgeonPreferenceImplant?.note || undefined,
      });
    } else {
      let direction = undefined;
      let orientation = undefined;
      let maximizeFootprint = null;

      if (
        [ImplantType.LLIF, ImplantType.TLIFO, ImplantType.TLIFC, ImplantType.TLIFCA].includes(
          implantType,
        )
      ) {
        direction = ImplantDirection.CaseSpecific;
      }
      if ([ImplantType.ALIF, ImplantType.ALIFX, ImplantType.LLIF].includes(implantType)) {
        maximizeFootprint = false;
      }

      if (isTlifCOrientationAvailable() && [ImplantType.TLIFC].includes(implantType)) {
        orientation = ImplantOrientation.Offset;
      }

      if ([ImplantType.TLIFCA].includes(implantType)) {
        orientation = ImplantOrientation.Offset;
      }

      preferencesImplants.push({
        implantType: implantType,
        positioning:
          implantType !== ImplantType.LLIF
            ? PositionDistanceType.DistanceFromAnteriorEdge
            : PositionDistanceType.Midline,
        direction: direction,
        orientation: orientation,
        orientationLevelL3L4: ImplantOrientation.None,
        orientationLevelL4L5: ImplantOrientation.None,
        orientationLevelL5S1: ImplantOrientation.None,
        screwLength:
          implantType === ImplantType.ALIFX
            ? InterbodyScrewLengthTypes.AlifXScrewLength25mm
            : undefined,
        maximizeFootprint: maximizeFootprint,
        plusSizeImplant: 2,
        minimalHeightSpecification: false,
        excludedImplantSizes: [],
        note: undefined,
      });
    }
  });

  return sortBy(preferencesImplants, (item) => IMPLANT_TYPE_CUSTOM_SORT.indexOf(item.implantType));
};

export const getSurgeonPreferenceImplantMeasurements = (
  surgeonPreferenceImplantMeasurements: ISurgeonPreferenceImplantMeasurement[],
): SurgeonPreferenceImplantMeasurementType[] => {
  const preferenceImplantMeasurements: SurgeonPreferenceImplantMeasurementType[] = [];
  const implantTypes = Object.values(ImplantType).filter((element) => element !== ImplantType.None);
  const surgeonPreferenceMeasurementTypes = Object.values(SurgeonPreferenceMeasurementType);

  let min = 0;
  let max = 0;

  implantTypes.forEach((implantType) => {
    const implantMeasurements = surgeonPreferenceImplantMeasurements.filter(
      (surgeonPreferenceImplantMeasurement) =>
        surgeonPreferenceImplantMeasurement?.implantType === implantType,
    );

    surgeonPreferenceMeasurementTypes.forEach((measurementType) => {
      const implantMeasurement = implantMeasurements.find(
        (implantMeasurement) => implantMeasurement?.measurementType === measurementType,
      );

      if (implantMeasurement) {
        if (
          implantMeasurement?.implantType !== ImplantType.LLIF ||
          implantMeasurement?.measurementType !==
            SurgeonPreferenceMeasurementType.ImplantPositionDistanceRange
        ) {
          preferenceImplantMeasurements.push({
            implantType: implantMeasurement?.implantType,
            levelType: implantMeasurement?.levelType,
            measurementType: implantMeasurement?.measurementType,
            min: implantMeasurement?.min,
            max: implantMeasurement?.max,
          });
        }
      } else {
        switch (implantType) {
          case ImplantType.ALIF: {
            switch (measurementType) {
              case SurgeonPreferenceMeasurementType.FootprintApRange:
                min =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.alif.footprintApRange.min;
                max =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.alif.footprintApRange.max;
                break;
              case SurgeonPreferenceMeasurementType.FootprintMlRange:
                min =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.alif.footprintMlRange.min;
                max =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.alif.footprintMlRange.max;
                break;
              case SurgeonPreferenceMeasurementType.AnteriorHeightRange:
                min =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.alif.anteriorHeightRange
                    .min;
                max =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.alif.anteriorHeightRange
                    .max;
                break;
              case SurgeonPreferenceMeasurementType.PosteriorHeightRange:
                min =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.alif.posteriorHeightRange
                    .min;
                max =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.alif.posteriorHeightRange
                    .max;
                break;
              case SurgeonPreferenceMeasurementType.LordosisAngleRange:
                min =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.alif.lordosisAngleRange.min;
                max =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.alif.lordosisAngleRange.max;
                break;
              case SurgeonPreferenceMeasurementType.ImplantPositionDistanceRange:
                min =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.alif.positionDistanceRange
                    .min;
                max =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.alif.positionDistanceRange
                    .max;
                break;
            }
            break;
          }
          case ImplantType.ALIFX: {
            switch (measurementType) {
              case SurgeonPreferenceMeasurementType.FootprintApRange:
                min =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.alifx.footprintApRange.min;
                max =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.alifx.footprintApRange.max;
                break;
              case SurgeonPreferenceMeasurementType.FootprintMlRange:
                min =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.alifx.footprintMlRange.min;
                max =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.alifx.footprintMlRange.max;
                break;
              case SurgeonPreferenceMeasurementType.AnteriorHeightRange:
                min =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.alifx.anteriorHeightRange
                    .min;
                max =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.alifx.anteriorHeightRange
                    .max;
                break;
              case SurgeonPreferenceMeasurementType.PosteriorHeightRange:
                min =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.alifx.posteriorHeightRange
                    .min;
                max =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.alifx.posteriorHeightRange
                    .max;
                break;
              case SurgeonPreferenceMeasurementType.LordosisAngleRange:
                min =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.alifx.lordosisAngleRange
                    .min;
                max =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.alifx.lordosisAngleRange
                    .max;
                break;
              case SurgeonPreferenceMeasurementType.ImplantPositionDistanceRange:
                min =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.alifx.positionDistanceRange
                    .min;
                max =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.alifx.positionDistanceRange
                    .max;
                break;
            }
            break;
          }
          case ImplantType.LLIF: {
            switch (measurementType) {
              case SurgeonPreferenceMeasurementType.FootprintApRange:
                min =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.llif.footprintApRange.min;
                max =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.llif.footprintApRange.max;
                break;
              case SurgeonPreferenceMeasurementType.FootprintMlRange:
                min =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.llif.footprintMlRange.min;
                max =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.llif.footprintMlRange.max;
                break;
              case SurgeonPreferenceMeasurementType.AnteriorHeightRange:
                min =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.llif.anteriorHeightRange
                    .min;
                max =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.llif.anteriorHeightRange
                    .max;
                break;
              case SurgeonPreferenceMeasurementType.PosteriorHeightRange:
                min =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.llif.posteriorHeightRange
                    .min;
                max =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.llif.posteriorHeightRange
                    .max;
                break;
              case SurgeonPreferenceMeasurementType.LordosisAngleRange:
                min =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.llif.lordosisAngleRange.min;
                max =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.llif.lordosisAngleRange.max;
                break;
            }
            break;
          }
          case ImplantType.TLIFO: {
            switch (measurementType) {
              case SurgeonPreferenceMeasurementType.FootprintApRange:
                min =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.tlifo.footprintApRange.min;
                max =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.tlifo.footprintApRange.max;
                break;
              case SurgeonPreferenceMeasurementType.FootprintMlRange:
                min =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.tlifo.footprintMlRange.min;
                max =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.tlifo.footprintMlRange.max;
                break;
              case SurgeonPreferenceMeasurementType.AnteriorHeightRange:
                min =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.tlifo.anteriorHeightRange
                    .min;
                max =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.tlifo.anteriorHeightRange
                    .max;
                break;
              case SurgeonPreferenceMeasurementType.PosteriorHeightRange:
                min =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.tlifo.posteriorHeightRange
                    .min;
                max =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.tlifo.posteriorHeightRange
                    .max;
                break;
              case SurgeonPreferenceMeasurementType.LordosisAngleRange:
                min =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.tlifo.lordosisAngleRange
                    .min;
                max =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.tlifo.lordosisAngleRange
                    .max;
                break;
              case SurgeonPreferenceMeasurementType.ImplantPositionDistanceRange:
                min =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.tlifo.positionDistanceRange
                    .min;
                max =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.tlifo.positionDistanceRange
                    .max;
                break;
            }
            break;
          }
          case ImplantType.TLIFC: {
            switch (measurementType) {
              case SurgeonPreferenceMeasurementType.FootprintApRange:
                min =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.tlifc.footprintApRange.min;
                max =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.tlifc.footprintApRange.max;
                break;
              case SurgeonPreferenceMeasurementType.FootprintMlRange:
                min =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.tlifc.footprintMlRange.min;
                max =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.tlifc.footprintMlRange.max;
                break;
              case SurgeonPreferenceMeasurementType.AnteriorHeightRange:
                min =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.tlifc.anteriorHeightRange
                    .min;
                max =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.tlifc.anteriorHeightRange
                    .max;
                break;
              case SurgeonPreferenceMeasurementType.PosteriorHeightRange:
                min =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.tlifc.posteriorHeightRange
                    .min;
                max =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.tlifc.posteriorHeightRange
                    .max;
                break;
              case SurgeonPreferenceMeasurementType.LordosisAngleRange:
                min =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.tlifc.lordosisAngleRange
                    .min;
                max =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.tlifc.lordosisAngleRange
                    .max;
                break;
              case SurgeonPreferenceMeasurementType.ImplantPositionDistanceRange:
                min =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.tlifc.positionDistanceRange
                    .min;
                max =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.tlifc.positionDistanceRange
                    .max;
                break;
            }
            break;
          }
          case ImplantType.TLIFCA: {
            switch (measurementType) {
              case SurgeonPreferenceMeasurementType.FootprintApRange:
                min =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.tlifca.footprintApRange.min;
                max =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.tlifca.footprintApRange.max;
                break;
              case SurgeonPreferenceMeasurementType.FootprintMlRange:
                min =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.tlifca.footprintMlRange.min;
                max =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.tlifca.footprintMlRange.max;
                break;
              case SurgeonPreferenceMeasurementType.AnteriorHeightRange:
                min =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.tlifca.anteriorHeightRange
                    .min;
                max =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.tlifca.anteriorHeightRange
                    .max;
                break;
              case SurgeonPreferenceMeasurementType.PosteriorHeightRange:
                min =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.tlifca.posteriorHeightRange
                    .min;
                max =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.tlifca.posteriorHeightRange
                    .max;
                break;
              case SurgeonPreferenceMeasurementType.LordosisAngleRange:
                min =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.tlifca.lordosisAngleRange
                    .min;
                max =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.tlifca.lordosisAngleRange
                    .max;
                break;
              case SurgeonPreferenceMeasurementType.ImplantPositionDistanceRange:
                min =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.tlifca.positionDistanceRange
                    .min;
                max =
                  SURGEON_PREFERENCES_DEFAULT_SETTINGS.measurementTypes.tlifca.positionDistanceRange
                    .max;
                break;
            }
            break;
          }
        }
        if (
          implantType !== ImplantType.LLIF ||
          measurementType !== SurgeonPreferenceMeasurementType.ImplantPositionDistanceRange
        ) {
          preferenceImplantMeasurements.push({
            implantType: implantType,
            levelType: undefined,
            measurementType: measurementType,
            min: min,
            max: max,
          });
        }
      }
    });
  });

  return sortBy(preferenceImplantMeasurements, (item) => [
    IMPLANT_TYPE_CUSTOM_SORT.indexOf(item.implantType),
    MEASUREMENT_TYPE_CUSTOM_SORT.indexOf(item.measurementType),
  ]);
};

export const getSurgeonPreferencesErrors = (
  surgeonPreferencesData: SurgeonPreferencesStateType,
  SURGEON_PREFERENCES_MIN_MAX_SETTINGS: MeasurementMinMaxSettingsType,
  isTlifCArticulatingStarted: boolean,
) => {
  return checkSurgeonPreferencesValidity(
    surgeonPreferencesData,
    SURGEON_PREFERENCES_MIN_MAX_SETTINGS,
    isTlifCArticulatingStarted,
  );
};

export function checkSurgeonPreferencesValidity(
  surgeonPreferencesData: SurgeonPreferencesStateType,
  SURGEON_PREFERENCES_MIN_MAX_SETTINGS: MeasurementMinMaxSettingsType,
  isTlifCArticulatingStarted: boolean,
): SurgeonPreferencesError[] {
  const surgeonPreferencesDataErrors: SurgeonPreferencesError[] = [];

  const surgeonPreferenceImplants = surgeonPreferencesData.surgeonPreferenceImplants;

  const surgeonPreferenceImplantMeasurements =
    surgeonPreferencesData.surgeonPreferenceImplantMeasurements;

  const measurementTypes = SURGEON_PREFERENCES_MIN_MAX_SETTINGS.measurementTypes;
  const surgeonPreferenceMeasurementTypes = Object.values(SurgeonPreferenceMeasurementType);

  let minLimit = 0;
  let maxLimit = 0;

  if (!surgeonPreferencesData.sagittalGoalPrimary) {
    surgeonPreferencesDataErrors.push({
      type: 'SURGEON_PREFERENCES',
      code: SurgeonPreferencesErrorCodeType.SagittalGoalPrimaryMissing,
    });
  }

  if (
    surgeonPreferencesData.sagittalGoalPrimary ===
      SagittalGoalType.AlternativeSupportivePlanningReference &&
    !surgeonPreferencesData?.sagittalGoalSecondary
  ) {
    surgeonPreferencesDataErrors.push({
      type: 'SURGEON_PREFERENCES',
      code: SurgeonPreferencesErrorCodeType.SagittalGoalSecondaryMissing,
    });
  }

  if (
    surgeonPreferencesData.sagittalGoalPrimary === SagittalGoalType.Other &&
    !surgeonPreferencesData?.sagittalGoalOther?.trim()
  ) {
    surgeonPreferencesDataErrors.push({
      type: 'SURGEON_PREFERENCES',
      code: SurgeonPreferencesErrorCodeType.SagittalGoalOtherMissing,
    });
  }

  if (isTlifCOrientationAvailable()) {
    const foundTlifcImplant = surgeonPreferenceImplants.find(
      (implant) =>
        implant.implantType === ImplantType.TLIFC &&
        surgeonPreferencesData?.preferredProcedures.includes(ImplantType.TLIFC),
    );

    if (foundTlifcImplant) {
      if (
        !foundTlifcImplant?.orientation ||
        foundTlifcImplant?.orientation === ImplantOrientation.None
      ) {
        surgeonPreferencesDataErrors.push({
          type: 'SURGEON_PREFERENCE_IMPLANTS',
          code: SurgeonPreferencesErrorCodeType.ThreadAngleMissing,
          procedure: foundTlifcImplant.implantType,
        });
      }
    }
  }

  if (isTlifCArticulatingStarted) {
    const foundTlifcaImplant = surgeonPreferenceImplants.find(
      (implant) =>
        implant.implantType === ImplantType.TLIFCA &&
        surgeonPreferencesData?.preferredProcedures.includes(ImplantType.TLIFCA),
    );

    if (foundTlifcaImplant) {
      if (
        !foundTlifcaImplant?.orientation ||
        foundTlifcaImplant?.orientation === ImplantOrientation.None
      ) {
        surgeonPreferencesDataErrors.push({
          type: 'SURGEON_PREFERENCE_IMPLANTS',
          code: SurgeonPreferencesErrorCodeType.ThreadAngleMissing,
          procedure: foundTlifcaImplant.implantType,
        });
      }
    }
  }

  const foundAlifxImplant = surgeonPreferenceImplants.find(
    (implant) =>
      implant.implantType === ImplantType.ALIFX &&
      surgeonPreferencesData?.preferredProcedures.includes(ImplantType.ALIFX),
  );

  if (foundAlifxImplant) {
    if (
      !foundAlifxImplant?.orientation ||
      foundAlifxImplant?.orientation === ImplantOrientation.None
    ) {
      surgeonPreferencesDataErrors.push({
        type: 'SURGEON_PREFERENCE_IMPLANTS',
        code: SurgeonPreferencesErrorCodeType.ScrewOrientationMissing,
        procedure: foundAlifxImplant.implantType,
      });
    }

    if (foundAlifxImplant?.orientation === ImplantOrientation.LevelSpecific) {
      const orientationByLevels = [ImplantOrientation.TwoUp, ImplantOrientation.TwoDown];

      if (
        !orientationByLevels.includes(foundAlifxImplant?.orientationLevelL3L4 as ImplantOrientation)
      )
        surgeonPreferencesDataErrors.push({
          type: 'SURGEON_PREFERENCE_IMPLANTS',
          code: SurgeonPreferencesErrorCodeType.ScrewOrientationByLevelMissing,
          procedure: foundAlifxImplant.implantType,
          levelType: LevelType.L3L4,
        });

      if (
        !orientationByLevels.includes(foundAlifxImplant?.orientationLevelL4L5 as ImplantOrientation)
      )
        surgeonPreferencesDataErrors.push({
          type: 'SURGEON_PREFERENCE_IMPLANTS',
          code: SurgeonPreferencesErrorCodeType.ScrewOrientationByLevelMissing,
          procedure: foundAlifxImplant.implantType,
          levelType: LevelType.L4L5,
        });

      if (
        !orientationByLevels.includes(foundAlifxImplant?.orientationLevelL5S1 as ImplantOrientation)
      )
        surgeonPreferencesDataErrors.push({
          type: 'SURGEON_PREFERENCE_IMPLANTS',
          code: SurgeonPreferencesErrorCodeType.ScrewOrientationByLevelMissing,
          procedure: foundAlifxImplant.implantType,
          levelType: LevelType.L5S1,
        });
    }
  }

  let isMeasurementDataValid = true;
  surgeonPreferencesData?.preferredProcedures.forEach((implantType) => {
    const implantMeasurements = surgeonPreferenceImplantMeasurements.filter(
      (surgeonPreferenceImplantMeasurement) =>
        surgeonPreferenceImplantMeasurement.implantType === implantType,
    );

    surgeonPreferenceMeasurementTypes.forEach((measurementType) => {
      const foundImplantMeasurement = implantMeasurements.find(
        (implantMeasurement) => implantMeasurement.measurementType === measurementType,
      );

      switch (implantType) {
        case ImplantType.ALIF: {
          switch (measurementType) {
            case SurgeonPreferenceMeasurementType.FootprintApRange:
              minLimit = measurementTypes.alif.footprintApRange.min;
              maxLimit = measurementTypes.alif.footprintApRange.max;
              break;
            case SurgeonPreferenceMeasurementType.FootprintMlRange:
              minLimit = measurementTypes.alif.footprintMlRange.min;
              maxLimit = measurementTypes.alif.footprintMlRange.max;
              break;
            case SurgeonPreferenceMeasurementType.AnteriorHeightRange:
              minLimit = measurementTypes.alif.anteriorHeightRange.min;
              maxLimit = measurementTypes.alif.anteriorHeightRange.max;
              break;

            case SurgeonPreferenceMeasurementType.PosteriorHeightRange:
              minLimit = measurementTypes.alif.posteriorHeightRange.min;
              maxLimit = measurementTypes.alif.posteriorHeightRange.max;
              break;

            case SurgeonPreferenceMeasurementType.LordosisAngleRange:
              minLimit = measurementTypes.alif.lordosisAngleRange.min;
              maxLimit = measurementTypes.alif.lordosisAngleRange.max;
              break;

            case SurgeonPreferenceMeasurementType.ImplantPositionDistanceRange:
              minLimit = measurementTypes.alif.positionDistanceRange.min;
              maxLimit = measurementTypes.alif.positionDistanceRange.max;
              break;
          }
          break;
        }
        case ImplantType.ALIFX: {
          switch (measurementType) {
            case SurgeonPreferenceMeasurementType.FootprintApRange:
              minLimit = measurementTypes.alifx.footprintApRange.min;
              maxLimit = measurementTypes.alifx.footprintApRange.max;
              break;
            case SurgeonPreferenceMeasurementType.FootprintMlRange:
              minLimit = measurementTypes.alifx.footprintMlRange.min;
              maxLimit = measurementTypes.alifx.footprintMlRange.max;
              break;
            case SurgeonPreferenceMeasurementType.AnteriorHeightRange:
              minLimit = measurementTypes.alifx.anteriorHeightRange.min;
              maxLimit = measurementTypes.alifx.anteriorHeightRange.max;
              break;

            case SurgeonPreferenceMeasurementType.PosteriorHeightRange:
              minLimit = measurementTypes.alifx.posteriorHeightRange.min;
              maxLimit = measurementTypes.alifx.posteriorHeightRange.max;
              break;

            case SurgeonPreferenceMeasurementType.LordosisAngleRange:
              minLimit = measurementTypes.alifx.lordosisAngleRange.min;
              maxLimit = measurementTypes.alifx.lordosisAngleRange.max;
              break;

            case SurgeonPreferenceMeasurementType.ImplantPositionDistanceRange:
              minLimit = measurementTypes.alifx.positionDistanceRange.min;
              maxLimit = measurementTypes.alifx.positionDistanceRange.max;
              break;
          }
          break;
        }
        case ImplantType.LLIF: {
          switch (measurementType) {
            case SurgeonPreferenceMeasurementType.FootprintApRange:
              minLimit = measurementTypes.llif.footprintApRange.min;
              maxLimit = measurementTypes.llif.footprintApRange.max;
              break;
            case SurgeonPreferenceMeasurementType.FootprintMlRange:
              minLimit = measurementTypes.llif.footprintMlRange.min;
              maxLimit = measurementTypes.llif.footprintMlRange.max;
              break;
            case SurgeonPreferenceMeasurementType.AnteriorHeightRange:
              minLimit = measurementTypes.llif.anteriorHeightRange.min;
              maxLimit = measurementTypes.llif.anteriorHeightRange.max;
              break;

            case SurgeonPreferenceMeasurementType.PosteriorHeightRange:
              minLimit = measurementTypes.llif.posteriorHeightRange.min;
              maxLimit = measurementTypes.llif.posteriorHeightRange.max;
              break;

            case SurgeonPreferenceMeasurementType.LordosisAngleRange:
              minLimit = measurementTypes.llif.lordosisAngleRange.min;
              maxLimit = measurementTypes.llif.lordosisAngleRange.max;
              break;

            case SurgeonPreferenceMeasurementType.ImplantPositionDistanceRange:
              minLimit = measurementTypes.llif.positionDistanceRange.min;
              maxLimit = measurementTypes.llif.positionDistanceRange.max;
              break;
          }
          break;
        }
        case ImplantType.TLIFO: {
          switch (measurementType) {
            case SurgeonPreferenceMeasurementType.FootprintApRange:
              minLimit = measurementTypes.tlifo.footprintApRange.min;
              maxLimit = measurementTypes.tlifo.footprintApRange.max;
              break;
            case SurgeonPreferenceMeasurementType.FootprintMlRange:
              minLimit = measurementTypes.tlifo.footprintMlRange.min;
              maxLimit = measurementTypes.tlifo.footprintMlRange.max;
              break;
            case SurgeonPreferenceMeasurementType.AnteriorHeightRange:
              minLimit = measurementTypes.tlifo.anteriorHeightRange.min;
              maxLimit = measurementTypes.tlifo.anteriorHeightRange.max;
              break;

            case SurgeonPreferenceMeasurementType.PosteriorHeightRange:
              minLimit = measurementTypes.tlifo.posteriorHeightRange.min;
              maxLimit = measurementTypes.tlifo.posteriorHeightRange.max;
              break;

            case SurgeonPreferenceMeasurementType.LordosisAngleRange:
              minLimit = measurementTypes.tlifo.lordosisAngleRange.min;
              maxLimit = measurementTypes.tlifo.lordosisAngleRange.max;
              break;

            case SurgeonPreferenceMeasurementType.ImplantPositionDistanceRange:
              minLimit = measurementTypes.tlifo.positionDistanceRange.min;
              maxLimit = measurementTypes.tlifo.positionDistanceRange.max;
              break;
          }
          break;
        }
        case ImplantType.TLIFC: {
          switch (measurementType) {
            case SurgeonPreferenceMeasurementType.FootprintApRange:
              minLimit = measurementTypes.tlifc.footprintApRange.min;
              maxLimit = measurementTypes.tlifc.footprintApRange.max;
              break;
            case SurgeonPreferenceMeasurementType.FootprintMlRange:
              minLimit = measurementTypes.tlifc.footprintMlRange.min;
              maxLimit = measurementTypes.tlifc.footprintMlRange.max;
              break;
            case SurgeonPreferenceMeasurementType.AnteriorHeightRange:
              minLimit = measurementTypes.tlifc.anteriorHeightRange.min;
              maxLimit = measurementTypes.tlifc.anteriorHeightRange.max;
              break;

            case SurgeonPreferenceMeasurementType.PosteriorHeightRange:
              minLimit = measurementTypes.tlifc.posteriorHeightRange.min;
              maxLimit = measurementTypes.tlifc.posteriorHeightRange.max;
              break;

            case SurgeonPreferenceMeasurementType.LordosisAngleRange:
              minLimit = measurementTypes.tlifc.lordosisAngleRange.min;
              maxLimit = measurementTypes.tlifc.lordosisAngleRange.max;
              break;

            case SurgeonPreferenceMeasurementType.ImplantPositionDistanceRange:
              minLimit = measurementTypes.tlifc.positionDistanceRange.min;
              maxLimit = measurementTypes.tlifc.positionDistanceRange.max;
              break;
          }
          break;
        }
        case ImplantType.TLIFCA: {
          switch (measurementType) {
            case SurgeonPreferenceMeasurementType.FootprintApRange:
              minLimit = measurementTypes.tlifca.footprintApRange.min;
              maxLimit = measurementTypes.tlifca.footprintApRange.max;
              break;
            case SurgeonPreferenceMeasurementType.FootprintMlRange:
              minLimit = measurementTypes.tlifca.footprintMlRange.min;
              maxLimit = measurementTypes.tlifca.footprintMlRange.max;
              break;
            case SurgeonPreferenceMeasurementType.AnteriorHeightRange:
              minLimit = measurementTypes.tlifca.anteriorHeightRange.min;
              maxLimit = measurementTypes.tlifca.anteriorHeightRange.max;
              break;

            case SurgeonPreferenceMeasurementType.PosteriorHeightRange:
              minLimit = measurementTypes.tlifca.posteriorHeightRange.min;
              maxLimit = measurementTypes.tlifca.posteriorHeightRange.max;
              break;

            case SurgeonPreferenceMeasurementType.LordosisAngleRange:
              minLimit = measurementTypes.tlifca.lordosisAngleRange.min;
              maxLimit = measurementTypes.tlifca.lordosisAngleRange.max;
              break;

            case SurgeonPreferenceMeasurementType.ImplantPositionDistanceRange:
              minLimit = measurementTypes.tlifca.positionDistanceRange.min;
              maxLimit = measurementTypes.tlifca.positionDistanceRange.max;
              break;
          }
          break;
        }
      }

      if (foundImplantMeasurement) {
        isMeasurementDataValid = math.inRangeCheck(
          Number(foundImplantMeasurement.min),
          Number(minLimit),
          Number(maxLimit),
        );
        if (!isMeasurementDataValid) {
          surgeonPreferencesDataErrors.push({
            type: 'SURGEON_PREFERENCE_IMPLANT_MEASUREMENTS',
            code: SurgeonPreferencesErrorCodeType.MinOutOfRange,
            measurementType: foundImplantMeasurement.measurementType,
            procedure: foundImplantMeasurement.implantType,
            minValue: foundImplantMeasurement.min,
            range: [minLimit, maxLimit],
          });
        }

        isMeasurementDataValid = math.inRangeCheck(
          Number(foundImplantMeasurement.max),
          Number(minLimit),
          Number(maxLimit),
        );
        if (!isMeasurementDataValid) {
          surgeonPreferencesDataErrors.push({
            type: 'SURGEON_PREFERENCE_IMPLANT_MEASUREMENTS',
            code: SurgeonPreferencesErrorCodeType.MaxOutOfRange,
            measurementType: foundImplantMeasurement.measurementType,
            procedure: foundImplantMeasurement.implantType,
            maxValue: foundImplantMeasurement.max,
            range: [minLimit, maxLimit],
          });
        }

        if (Number(foundImplantMeasurement.min) > Number(foundImplantMeasurement.max)) {
          surgeonPreferencesDataErrors.push({
            type: 'SURGEON_PREFERENCE_IMPLANT_MEASUREMENTS',
            code: SurgeonPreferencesErrorCodeType.MinCannotBeGreaterThanMax,
            measurementType: foundImplantMeasurement.measurementType,
            procedure: foundImplantMeasurement.implantType,
          });
        }
      }
    });
  });

  return surgeonPreferencesDataErrors;
}

export const getSurgeonPreferencesErrorDescription = (
  surgeonPreferencesError: SurgeonPreferencesError,
): string => {
  const messagePrefix = `${format.formatImplantTypes(
    surgeonPreferencesError.procedure as ImplantType,
  )}`;

  const messagePrefixMeasurements = `${messagePrefix} - ${format.formatSurgeonPreferenceMeasurementType(
    surgeonPreferencesError.measurementType as SurgeonPreferenceMeasurementType,
  )}`;

  const messagePrefixOrientationByLevel = `${messagePrefix} - ${format.formatLevelType(
    surgeonPreferencesError?.levelType as LevelType,
  )}`;

  let error: string;
  switch (surgeonPreferencesError.code) {
    case SurgeonPreferencesErrorCodeType.SagittalGoalPrimaryMissing:
      error = `Sagittal goal primary is missing`;
      break;
    case SurgeonPreferencesErrorCodeType.SagittalGoalSecondaryMissing:
      error = `Sagittal goal secondary is missing`;
      break;
    case SurgeonPreferencesErrorCodeType.SagittalGoalOtherMissing:
      error = `Sagittal goal other is missing`;
      break;
    case SurgeonPreferencesErrorCodeType.ScrewOrientationMissing:
      error = `${messagePrefix} - Screw orientation is missing`;
      break;
    case SurgeonPreferencesErrorCodeType.ThreadAngleMissing:
      error = `${messagePrefix} - Thread angle is missing`;
      break;
    case SurgeonPreferencesErrorCodeType.ScrewOrientationByLevelMissing:
      error = `${messagePrefixOrientationByLevel} - Screw orientation is missing`;
      break;
    case SurgeonPreferencesErrorCodeType.OutOfRange:
      error = `${messagePrefixMeasurements} - the value ${surgeonPreferencesError.value} is out of range (min: ${surgeonPreferencesError?.range?.[0]}, max: ${surgeonPreferencesError?.range?.[1]})`;
      break;
    case SurgeonPreferencesErrorCodeType.MinOutOfRange:
      error = `${messagePrefixMeasurements} - the min value ${surgeonPreferencesError.minValue} is out of range (min: ${surgeonPreferencesError?.range?.[0]}, max: ${surgeonPreferencesError?.range?.[1]})`;
      break;
    case SurgeonPreferencesErrorCodeType.MaxOutOfRange:
      error = `${messagePrefixMeasurements} - the max value ${surgeonPreferencesError.maxValue} is out of range (min: ${surgeonPreferencesError?.range?.[0]}, max: ${surgeonPreferencesError?.range?.[1]})`;
      break;
    case SurgeonPreferencesErrorCodeType.MinCannotBeGreaterThanMax:
      error = `${messagePrefixMeasurements} - the min value cannot be greater than max value`;
      break;
    default:
      error = surgeonPreferencesError.code;
  }

  return error;
};
