import _ from 'lodash';
import {
  AgeRange,
  AssetType,
  CaseSpineProfile,
  CaseStageType,
  DistributionProductionSubstageType,
  EventType,
  HIPProductionSubstageType,
  LevelType,
  ManufacturingProductionSubstageType,
  PackagingProductionSubstageType,
  PartType,
  ProductionStageType,
  SterilizationProductionSubstageType,
  SurgeonApproachType,
  ValidFileExtensions,
  VendorTaskCategory,
  VendorTaskType,
  VertebralBody,
} from './enums';
import { IVendorAssignment, ProductionSubstageType } from './interfaces';

export const PRODUCTION_STAGE_ORDER: Array<{
  order: number;
  stage: ProductionStageType;
  subStage: ProductionSubstageType | null;
}> = [
  {
    order: 0,
    stage: ProductionStageType.PreProduction,
    subStage: null,
  },
  {
    order: 1,
    stage: ProductionStageType.SurgeonApproval,
    subStage: null,
  },
  {
    order: 2,
    stage: ProductionStageType.Manufacturing,
    subStage: ManufacturingProductionSubstageType.BuildFileCreation,
  },
  {
    order: 3,
    stage: ProductionStageType.Manufacturing,
    subStage: ManufacturingProductionSubstageType.AdditiveManufacturing,
  },
  {
    order: 4,
    stage: ProductionStageType.Manufacturing,
    subStage: ManufacturingProductionSubstageType.BuildPlateCheckAndRemoval,
  },
  {
    order: 5,
    stage: ProductionStageType.HIP,
    subStage: HIPProductionSubstageType.Transit,
  },
  {
    order: 6,
    stage: ProductionStageType.HIP,
    subStage: HIPProductionSubstageType.Hipping,
  },
  {
    order: 7,
    stage: ProductionStageType.Manufacturing,
    subStage: ManufacturingProductionSubstageType.ReceiveHIP,
  },
  {
    order: 8,
    stage: ProductionStageType.Manufacturing,
    subStage: ManufacturingProductionSubstageType.PackageAndRelease,
  },
  {
    order: 9,
    stage: ProductionStageType.Packaging,
    subStage: PackagingProductionSubstageType.Receive,
  },
  {
    order: 10,
    stage: ProductionStageType.Packaging,
    subStage: PackagingProductionSubstageType.PackagingAndLabeling,
  },
  {
    order: 11,
    stage: ProductionStageType.Sterilization,
    subStage: SterilizationProductionSubstageType.Receive,
  },
  {
    order: 12,
    stage: ProductionStageType.Sterilization,
    subStage: SterilizationProductionSubstageType.Sterilization,
  },
  {
    order: 13,
    stage: ProductionStageType.Packaging,
    subStage: PackagingProductionSubstageType.ReceiveShipSterilized,
  },
  {
    order: 14,
    stage: ProductionStageType.Distribution,
    subStage: DistributionProductionSubstageType.ReceivePackage,
  },
  {
    order: 15,
    stage: ProductionStageType.Distribution,
    subStage: DistributionProductionSubstageType.ReadyForSurgery,
  },
];

export const DEFAULT_SPINE_PROFILE = CaseSpineProfile.LumbarStandard;

export type VertebralBodyAssetMap = {
  asset: AssetType;
  daisyAsset: AssetType;
  vertebralBody: VertebralBody;
  appAssets: {
    preOp: AssetType;
    plan: AssetType;
  };
};

export type LevelTypeConfigMap = {
  asset: AssetType;
  cyborgImplantAssets: {
    uncut: AssetType;
  };
  implantAssets: {
    stl: {
      plus: AssetType;
      plan: AssetType;
      minus: AssetType;
    };
    dimensions: {
      plus: AssetType;
      plan: AssetType;
      minus: AssetType;
    };
  };
  nTopAssets: {
    plus: AssetType;
    plan: AssetType;
    minus: AssetType;
  };
  appAsset: AssetType;
  dataAsset: AssetType;
  form19AdditionalImageAsset: AssetType;
  inferiorVertebrae: VertebralBody;
  superiorVertebrae: VertebralBody;
  levelType: LevelType;
};

export const VERTEBRAL_BODY_ASSET_MAP: Record<VertebralBody, VertebralBodyAssetMap> = {
  // Cervical
  [VertebralBody.C1]: {
    asset: AssetType.C1,
    daisyAsset: AssetType.C1Daisy,
    vertebralBody: VertebralBody.C1,
    appAssets: {
      preOp: AssetType.C2PreopApp,
      plan: AssetType.C1PlanApp,
    },
  },
  [VertebralBody.C2]: {
    asset: AssetType.C2,
    daisyAsset: AssetType.C2Daisy,
    vertebralBody: VertebralBody.C2,
    appAssets: {
      preOp: AssetType.C2PreopApp,
      plan: AssetType.C2PlanApp,
    },
  },
  [VertebralBody.C3]: {
    asset: AssetType.C3,
    daisyAsset: AssetType.C3Daisy,
    vertebralBody: VertebralBody.C3,
    appAssets: {
      preOp: AssetType.C3PreopApp,
      plan: AssetType.C3PlanApp,
    },
  },
  [VertebralBody.C4]: {
    asset: AssetType.C4,
    daisyAsset: AssetType.C4Daisy,
    vertebralBody: VertebralBody.C4,
    appAssets: {
      preOp: AssetType.C4PreopApp,
      plan: AssetType.C4PlanApp,
    },
  },
  [VertebralBody.C5]: {
    asset: AssetType.C5,
    daisyAsset: AssetType.C5Daisy,
    vertebralBody: VertebralBody.C5,
    appAssets: {
      preOp: AssetType.C5PreopApp,
      plan: AssetType.C5PlanApp,
    },
  },
  [VertebralBody.C6]: {
    asset: AssetType.C6,
    daisyAsset: AssetType.C6Daisy,
    vertebralBody: VertebralBody.C6,
    appAssets: {
      preOp: AssetType.C6PreopApp,
      plan: AssetType.C6PlanApp,
    },
  },
  [VertebralBody.C7]: {
    asset: AssetType.C7,
    daisyAsset: AssetType.C7Daisy,
    vertebralBody: VertebralBody.C7,
    appAssets: {
      preOp: AssetType.C7PreopApp,
      plan: AssetType.C7PlanApp,
    },
  },
  [VertebralBody.C8]: {
    asset: AssetType.C8,
    daisyAsset: AssetType.C8Daisy,
    vertebralBody: VertebralBody.C8,
    appAssets: {
      preOp: AssetType.C8PreopApp,
      plan: AssetType.C8PlanApp,
    },
  },
  [VertebralBody.T1]: {
    asset: AssetType.T1,
    daisyAsset: AssetType.T1Daisy,
    vertebralBody: VertebralBody.T1,
    appAssets: {
      preOp: AssetType.T1PreopApp,
      plan: AssetType.T1PlanApp,
    },
  },
  // Lumbar
  [VertebralBody.L1]: {
    asset: AssetType.L1,
    daisyAsset: AssetType.L1Daisy,
    vertebralBody: VertebralBody.L1,
    appAssets: {
      preOp: AssetType.L1PreopApp,
      plan: AssetType.L1PlanApp,
    },
  },
  [VertebralBody.L2]: {
    asset: AssetType.L2,
    daisyAsset: AssetType.L2Daisy,
    vertebralBody: VertebralBody.L2,
    appAssets: {
      preOp: AssetType.L2PreopApp,
      plan: AssetType.L2PlanApp,
    },
  },
  [VertebralBody.L3]: {
    asset: AssetType.L3,
    daisyAsset: AssetType.L3Daisy,
    vertebralBody: VertebralBody.L3,
    appAssets: {
      preOp: AssetType.L3PreopApp,
      plan: AssetType.L3PlanApp,
    },
  },
  [VertebralBody.L4]: {
    asset: AssetType.L4,
    daisyAsset: AssetType.L4Daisy,
    vertebralBody: VertebralBody.L4,
    appAssets: {
      preOp: AssetType.L4PreopApp,
      plan: AssetType.L4PlanApp,
    },
  },
  [VertebralBody.L5]: {
    asset: AssetType.L5,
    daisyAsset: AssetType.L5Daisy,
    vertebralBody: VertebralBody.L5,
    appAssets: {
      preOp: AssetType.L5PreopApp,
      plan: AssetType.L5PlanApp,
    },
  },
  [VertebralBody.L6]: {
    asset: AssetType.L6,
    daisyAsset: AssetType.L6Daisy,
    vertebralBody: VertebralBody.L6,
    appAssets: {
      preOp: AssetType.L6PreopApp,
      plan: AssetType.L6PlanApp,
    },
  },
  [VertebralBody.S1]: {
    asset: AssetType.S1,
    daisyAsset: AssetType.S1Daisy,
    vertebralBody: VertebralBody.S1,
    appAssets: {
      preOp: AssetType.S1PreopApp,
      plan: AssetType.S1PlanApp,
    },
  },
};

export const LEVEL_CONFIG_MAP: Record<LevelType, LevelTypeConfigMap> = {
  // Cervical
  [LevelType.C2C3]: {
    levelType: LevelType.C2C3,
    asset: AssetType.C2C3,
    cyborgImplantAssets: {
      uncut: AssetType.C2C3CyborgImplant,
    },
    implantAssets: {
      stl: {
        plus: AssetType.C2C3Plus,
        plan: AssetType.C2C3,
        minus: AssetType.C2C3Minus,
      },
      dimensions: {
        plus: AssetType.C2C3PlusDimensions,
        plan: AssetType.C2C3Dimensions,
        minus: AssetType.C2C3MinusDimensions,
      },
    },
    nTopAssets: {
      plus: AssetType.C2C3PlusNTopCli,
      plan: AssetType.C2C3NTopCli,
      minus: AssetType.C2C3MinusNTopCli,
    },
    appAsset: AssetType.C2C3App,
    dataAsset: AssetType.C2C3Data,
    form19AdditionalImageAsset: AssetType.Form19C2C3AdditionalImage,
    inferiorVertebrae: VertebralBody.C2,
    superiorVertebrae: VertebralBody.C3,
  },
  [LevelType.C3C4]: {
    levelType: LevelType.C3C4,
    asset: AssetType.C3C4,
    cyborgImplantAssets: {
      uncut: AssetType.C3C4CyborgImplant,
    },
    implantAssets: {
      stl: {
        plus: AssetType.C3C4Plus,
        plan: AssetType.C3C4,
        minus: AssetType.C3C4Minus,
      },
      dimensions: {
        plus: AssetType.C3C4PlusDimensions,
        plan: AssetType.C3C4Dimensions,
        minus: AssetType.C3C4MinusDimensions,
      },
    },
    nTopAssets: {
      plus: AssetType.C3C4PlusNTopCli,
      plan: AssetType.C3C4NTopCli,
      minus: AssetType.C3C4MinusNTopCli,
    },
    appAsset: AssetType.C3C4App,
    dataAsset: AssetType.C3C4Data,
    form19AdditionalImageAsset: AssetType.Form19C3C4AdditionalImage,
    inferiorVertebrae: VertebralBody.C3,
    superiorVertebrae: VertebralBody.C4,
  },
  [LevelType.C4C5]: {
    levelType: LevelType.C4C5,
    asset: AssetType.C4C5,
    cyborgImplantAssets: {
      uncut: AssetType.C4C5CyborgImplant,
    },
    implantAssets: {
      stl: {
        plus: AssetType.C4C5Plus,
        plan: AssetType.C4C5,
        minus: AssetType.C4C5Minus,
      },
      dimensions: {
        plus: AssetType.C4C5PlusDimensions,
        plan: AssetType.C4C5Dimensions,
        minus: AssetType.C4C5MinusDimensions,
      },
    },
    nTopAssets: {
      plus: AssetType.C4C5PlusNTopCli,
      plan: AssetType.C4C5NTopCli,
      minus: AssetType.C4C5MinusNTopCli,
    },
    appAsset: AssetType.C4C5App,
    dataAsset: AssetType.C4C5Data,
    form19AdditionalImageAsset: AssetType.Form19C4C5AdditionalImage,
    inferiorVertebrae: VertebralBody.C4,
    superiorVertebrae: VertebralBody.C5,
  },
  [LevelType.C5C6]: {
    levelType: LevelType.C5C6,
    asset: AssetType.C5C6,
    cyborgImplantAssets: {
      uncut: AssetType.C5C6CyborgImplant,
    },
    implantAssets: {
      stl: {
        plus: AssetType.C5C6Plus,
        plan: AssetType.C5C6,
        minus: AssetType.C5C6Minus,
      },
      dimensions: {
        plus: AssetType.C5C6PlusDimensions,
        plan: AssetType.C5C6Dimensions,
        minus: AssetType.C5C6MinusDimensions,
      },
    },
    nTopAssets: {
      plus: AssetType.C5C6PlusNTopCli,
      plan: AssetType.C5C6NTopCli,
      minus: AssetType.C5C6MinusNTopCli,
    },
    appAsset: AssetType.C5C6App,
    dataAsset: AssetType.C5C6Data,
    form19AdditionalImageAsset: AssetType.Form19C5C6AdditionalImage,
    inferiorVertebrae: VertebralBody.C5,
    superiorVertebrae: VertebralBody.C6,
  },
  [LevelType.C6C7]: {
    levelType: LevelType.C6C7,
    asset: AssetType.C6C7,
    cyborgImplantAssets: {
      uncut: AssetType.C6C7CyborgImplant,
    },
    implantAssets: {
      stl: {
        plus: AssetType.C6C7Plus,
        plan: AssetType.C6C7,
        minus: AssetType.C6C7Minus,
      },
      dimensions: {
        plus: AssetType.C6C7PlusDimensions,
        plan: AssetType.C6C7Dimensions,
        minus: AssetType.C6C7MinusDimensions,
      },
    },
    nTopAssets: {
      plus: AssetType.C6C7PlusNTopCli,
      plan: AssetType.C6C7NTopCli,
      minus: AssetType.C6C7MinusNTopCli,
    },
    appAsset: AssetType.C6C7App,
    dataAsset: AssetType.C6C7Data,
    form19AdditionalImageAsset: AssetType.Form19C6C7AdditionalImage,
    inferiorVertebrae: VertebralBody.C6,
    superiorVertebrae: VertebralBody.C7,
  },
  [LevelType.C7T1]: {
    levelType: LevelType.C7T1,
    asset: AssetType.C7T1,
    cyborgImplantAssets: {
      uncut: AssetType.C7T1CyborgImplant,
    },
    implantAssets: {
      stl: {
        plus: AssetType.C7T1Plus,
        plan: AssetType.C7T1,
        minus: AssetType.C7T1Minus,
      },
      dimensions: {
        plus: AssetType.C7T1PlusDimensions,
        plan: AssetType.C7T1Dimensions,
        minus: AssetType.C7T1MinusDimensions,
      },
    },
    nTopAssets: {
      plus: AssetType.C7T1PlusNTopCli,
      plan: AssetType.C7T1NTopCli,
      minus: AssetType.C7T1MinusNTopCli,
    },
    appAsset: AssetType.C7T1App,
    dataAsset: AssetType.C7T1Data,
    form19AdditionalImageAsset: AssetType.Form19C7T1AdditionalImage,
    inferiorVertebrae: VertebralBody.C7,
    superiorVertebrae: VertebralBody.T1,
  },
  [LevelType.C6T1]: {
    levelType: LevelType.C6T1,
    asset: AssetType.C6T1,
    cyborgImplantAssets: {
      uncut: AssetType.C6T1CyborgImplant,
    },
    implantAssets: {
      stl: {
        plus: AssetType.C6T1Plus,
        plan: AssetType.C6T1,
        minus: AssetType.C6T1Minus,
      },
      dimensions: {
        plus: AssetType.C6T1PlusDimensions,
        plan: AssetType.C6T1Dimensions,
        minus: AssetType.C6T1MinusDimensions,
      },
    },
    nTopAssets: {
      plus: AssetType.C6T1PlusNTopCli,
      plan: AssetType.C6T1NTopCli,
      minus: AssetType.C6T1MinusNTopCli,
    },
    appAsset: AssetType.C6T1App,
    dataAsset: AssetType.C6T1Data,
    form19AdditionalImageAsset: AssetType.Form19C6T1AdditionalImage,
    inferiorVertebrae: VertebralBody.C6,
    superiorVertebrae: VertebralBody.T1,
  },
  [LevelType.C7C8]: {
    levelType: LevelType.C7C8,
    asset: AssetType.C7C8,
    cyborgImplantAssets: {
      uncut: AssetType.C7C8CyborgImplant,
    },
    implantAssets: {
      stl: {
        plus: AssetType.C7C8Plus,
        plan: AssetType.C7C8,
        minus: AssetType.C7C8Minus,
      },
      dimensions: {
        plus: AssetType.C7C8PlusDimensions,
        plan: AssetType.C7C8Dimensions,
        minus: AssetType.C7C8MinusDimensions,
      },
    },
    nTopAssets: {
      plus: AssetType.C7C8PlusNTopCli,
      plan: AssetType.C7C8NTopCli,
      minus: AssetType.C7C8MinusNTopCli,
    },
    appAsset: AssetType.C7C8App,
    dataAsset: AssetType.C7C8Data,
    form19AdditionalImageAsset: AssetType.Form19C7C8AdditionalImage,
    inferiorVertebrae: VertebralBody.C7,
    superiorVertebrae: VertebralBody.C8,
  },
  [LevelType.C8T1]: {
    levelType: LevelType.C8T1,
    asset: AssetType.C8T1,
    cyborgImplantAssets: {
      uncut: AssetType.C8T1CyborgImplant,
    },
    implantAssets: {
      stl: {
        plus: AssetType.C8T1Plus,
        plan: AssetType.C8T1,
        minus: AssetType.C8T1Minus,
      },
      dimensions: {
        plus: AssetType.C8T1PlusDimensions,
        plan: AssetType.C8T1Dimensions,
        minus: AssetType.C8T1MinusDimensions,
      },
    },
    nTopAssets: {
      plus: AssetType.C8T1PlusNTopCli,
      plan: AssetType.C8T1NTopCli,
      minus: AssetType.C8T1MinusNTopCli,
    },
    appAsset: AssetType.C8T1App,
    dataAsset: AssetType.C8T1Data,
    form19AdditionalImageAsset: AssetType.Form19C8T1AdditionalImage,
    inferiorVertebrae: VertebralBody.C8,
    superiorVertebrae: VertebralBody.T1,
  },
  // Lumbar
  [LevelType.L1L2]: {
    levelType: LevelType.L1L2,
    asset: AssetType.L1L2,
    cyborgImplantAssets: {
      uncut: AssetType.L1L2CyborgImplant,
    },
    implantAssets: {
      stl: {
        plus: AssetType.L1L2Plus,
        plan: AssetType.L1L2,
        minus: AssetType.L1L2Minus,
      },
      dimensions: {
        plus: AssetType.L1L2PlusDimensions,
        plan: AssetType.L1L2Dimensions,
        minus: AssetType.L1L2MinusDimensions,
      },
    },
    nTopAssets: {
      plus: AssetType.L1L2PlusNTopCli,
      plan: AssetType.L1L2NTopCli,
      minus: AssetType.L1L2MinusNTopCli,
    },
    appAsset: AssetType.L1L2App,
    dataAsset: AssetType.L1L2Data,
    form19AdditionalImageAsset: AssetType.Form19L1L2AdditionalImage,
    inferiorVertebrae: VertebralBody.L1,
    superiorVertebrae: VertebralBody.L2,
  },
  [LevelType.L2L3]: {
    levelType: LevelType.L2L3,
    asset: AssetType.L2L3,
    cyborgImplantAssets: {
      uncut: AssetType.L2L3CyborgImplant,
    },
    implantAssets: {
      stl: {
        plus: AssetType.L2L3Plus,
        plan: AssetType.L2L3,
        minus: AssetType.L2L3Minus,
      },
      dimensions: {
        plus: AssetType.L2L3PlusDimensions,
        plan: AssetType.L2L3Dimensions,
        minus: AssetType.L2L3MinusDimensions,
      },
    },
    nTopAssets: {
      plus: AssetType.L2L3PlusNTopCli,
      plan: AssetType.L2L3NTopCli,
      minus: AssetType.L2L3MinusNTopCli,
    },
    appAsset: AssetType.L2L3App,
    dataAsset: AssetType.L2L3Data,
    form19AdditionalImageAsset: AssetType.Form19L2L3AdditionalImage,
    inferiorVertebrae: VertebralBody.L2,
    superiorVertebrae: VertebralBody.L3,
  },
  [LevelType.L3L4]: {
    levelType: LevelType.L3L4,
    asset: AssetType.L3L4,
    cyborgImplantAssets: {
      uncut: AssetType.L3L4CyborgImplant,
    },
    implantAssets: {
      stl: {
        plus: AssetType.L3L4Plus,
        plan: AssetType.L3L4,
        minus: AssetType.L3L4Minus,
      },
      dimensions: {
        plus: AssetType.L3L4PlusDimensions,
        plan: AssetType.L3L4Dimensions,
        minus: AssetType.L3L4MinusDimensions,
      },
    },
    nTopAssets: {
      plus: AssetType.L3L4PlusNTopCli,
      plan: AssetType.L3L4NTopCli,
      minus: AssetType.L3L4MinusNTopCli,
    },
    appAsset: AssetType.L3L4App,
    dataAsset: AssetType.L3L4Data,
    form19AdditionalImageAsset: AssetType.Form19L3L4AdditionalImage,
    inferiorVertebrae: VertebralBody.L3,
    superiorVertebrae: VertebralBody.L4,
  },
  [LevelType.L4L5]: {
    levelType: LevelType.L4L5,
    asset: AssetType.L4L5,
    cyborgImplantAssets: {
      uncut: AssetType.L4L5CyborgImplant,
    },
    implantAssets: {
      stl: {
        plus: AssetType.L4L5Plus,
        plan: AssetType.L4L5,
        minus: AssetType.L4L5Minus,
      },
      dimensions: {
        plus: AssetType.L4L5PlusDimensions,
        plan: AssetType.L4L5Dimensions,
        minus: AssetType.L4L5MinusDimensions,
      },
    },
    nTopAssets: {
      plus: AssetType.L4L5PlusNTopCli,
      plan: AssetType.L4L5NTopCli,
      minus: AssetType.L4L5MinusNTopCli,
    },
    appAsset: AssetType.L4L5App,
    dataAsset: AssetType.L4L5Data,
    form19AdditionalImageAsset: AssetType.Form19L4L5AdditionalImage,
    inferiorVertebrae: VertebralBody.L4,
    superiorVertebrae: VertebralBody.L5,
  },
  [LevelType.L5S1]: {
    levelType: LevelType.L5S1,
    asset: AssetType.L5S1,
    cyborgImplantAssets: {
      uncut: AssetType.L5S1CyborgImplant,
    },
    implantAssets: {
      stl: {
        plus: AssetType.L5S1Plus,
        plan: AssetType.L5S1,
        minus: AssetType.L5S1Minus,
      },
      dimensions: {
        plus: AssetType.L5S1PlusDimensions,
        plan: AssetType.L5S1Dimensions,
        minus: AssetType.L5S1MinusDimensions,
      },
    },
    nTopAssets: {
      plus: AssetType.L5S1PlusNTopCli,
      plan: AssetType.L5S1NTopCli,
      minus: AssetType.L5S1MinusNTopCli,
    },
    appAsset: AssetType.L5S1App,
    dataAsset: AssetType.L5S1Data,
    form19AdditionalImageAsset: AssetType.Form19L5S1AdditionalImage,
    inferiorVertebrae: VertebralBody.L5,
    superiorVertebrae: VertebralBody.S1,
  },
  [LevelType.L4S1]: {
    levelType: LevelType.L4S1,
    asset: AssetType.L4S1,
    cyborgImplantAssets: {
      uncut: AssetType.L4S1CyborgImplant,
    },
    implantAssets: {
      stl: {
        plus: AssetType.L4S1Plus,
        plan: AssetType.L4S1,
        minus: AssetType.L4S1Minus,
      },
      dimensions: {
        plus: AssetType.L4S1PlusDimensions,
        plan: AssetType.L4S1Dimensions,
        minus: AssetType.L4S1MinusDimensions,
      },
    },
    nTopAssets: {
      plus: AssetType.L4S1PlusNTopCli,
      plan: AssetType.L4S1NTopCli,
      minus: AssetType.L4S1MinusNTopCli,
    },
    appAsset: AssetType.L4S1App,
    dataAsset: AssetType.L4S1Data,
    form19AdditionalImageAsset: AssetType.Form19L4S1AdditionalImage,
    inferiorVertebrae: VertebralBody.L4,
    superiorVertebrae: VertebralBody.S1,
  },
  [LevelType.L5L6]: {
    levelType: LevelType.L5L6,
    asset: AssetType.L5L6,
    cyborgImplantAssets: {
      uncut: AssetType.L5L6CyborgImplant,
    },
    implantAssets: {
      stl: {
        plus: AssetType.L5L6Plus,
        plan: AssetType.L5L6,
        minus: AssetType.L5L6Minus,
      },
      dimensions: {
        plus: AssetType.L5L6PlusDimensions,
        plan: AssetType.L5L6Dimensions,
        minus: AssetType.L5L6MinusDimensions,
      },
    },
    nTopAssets: {
      plus: AssetType.L5L6PlusNTopCli,
      plan: AssetType.L5L6NTopCli,
      minus: AssetType.L5L6MinusNTopCli,
    },
    appAsset: AssetType.L5L6App,
    dataAsset: AssetType.L5L6Data,
    form19AdditionalImageAsset: AssetType.Form19L5L6AdditionalImage,
    inferiorVertebrae: VertebralBody.L5,
    superiorVertebrae: VertebralBody.L6,
  },
  [LevelType.L6S1]: {
    levelType: LevelType.L6S1,
    asset: AssetType.L6S1,
    cyborgImplantAssets: {
      uncut: AssetType.L6S1CyborgImplant,
    },
    implantAssets: {
      stl: {
        plus: AssetType.L6S1Plus,
        plan: AssetType.L6S1,
        minus: AssetType.L6S1Minus,
      },
      dimensions: {
        plus: AssetType.L6S1PlusDimensions,
        plan: AssetType.L6S1Dimensions,
        minus: AssetType.L6S1MinusDimensions,
      },
    },
    nTopAssets: {
      plus: AssetType.L6S1PlusNTopCli,
      plan: AssetType.L6S1NTopCli,
      minus: AssetType.L6S1MinusNTopCli,
    },
    appAsset: AssetType.L6S1App,
    dataAsset: AssetType.L6S1Data,
    form19AdditionalImageAsset: AssetType.Form19L6S1NAdditionalImage,
    inferiorVertebrae: VertebralBody.L6,
    superiorVertebrae: VertebralBody.S1,
  },
};

export type CaseSpineProfileConfig = {
  landmarkCount: 36 | 44 | 48 | 52 | 56 | 60 | 64;
  validLevels: LevelType[];
  validVertebralBodies: VertebralBody[];
  vertebralInfoMap: Record<
    VertebralBody,
    | (VertebralBodyAssetMap & {
        parent: VertebralBody | null;
      })
    | null
  >;
};

export const SPINE_PROFILE_CONFIG_MAP: Record<CaseSpineProfile, CaseSpineProfileConfig> = {
  [CaseSpineProfile.CervicalStandard]: {
    landmarkCount: 56,
    validLevels: [
      LevelType.C2C3,
      LevelType.C3C4,
      LevelType.C4C5,
      LevelType.C5C6,
      LevelType.C6C7,
      LevelType.C7T1,
    ],
    validVertebralBodies: [
      VertebralBody.C1,
      VertebralBody.C2,
      VertebralBody.C3,
      VertebralBody.C4,
      VertebralBody.C5,
      VertebralBody.C6,
      VertebralBody.C7,
      VertebralBody.T1,
    ],
    vertebralInfoMap: {
      [VertebralBody.C1]: {
        ...VERTEBRAL_BODY_ASSET_MAP[VertebralBody.C1],
        parent: VertebralBody.C2,
      },
      [VertebralBody.C2]: {
        ...VERTEBRAL_BODY_ASSET_MAP[VertebralBody.C2],
        parent: VertebralBody.C3,
      },
      [VertebralBody.C3]: {
        ...VERTEBRAL_BODY_ASSET_MAP[VertebralBody.C3],
        parent: VertebralBody.C4,
      },
      [VertebralBody.C4]: {
        ...VERTEBRAL_BODY_ASSET_MAP[VertebralBody.C4],
        parent: VertebralBody.C5,
      },
      [VertebralBody.C5]: {
        ...VERTEBRAL_BODY_ASSET_MAP[VertebralBody.C5],
        parent: VertebralBody.C6,
      },
      [VertebralBody.C6]: {
        ...VERTEBRAL_BODY_ASSET_MAP[VertebralBody.C6],
        parent: VertebralBody.C7,
      },
      [VertebralBody.C7]: {
        ...VERTEBRAL_BODY_ASSET_MAP[VertebralBody.C7],
        parent: VertebralBody.T1,
      },
      [VertebralBody.C8]: null,
      [VertebralBody.T1]: {
        ...VERTEBRAL_BODY_ASSET_MAP[VertebralBody.T1],
        parent: null,
      },
      [VertebralBody.L1]: null,
      [VertebralBody.L2]: null,
      [VertebralBody.L3]: null,
      [VertebralBody.L4]: null,
      [VertebralBody.L5]: null,
      [VertebralBody.L6]: null,
      [VertebralBody.S1]: null,
    },
  },
  [CaseSpineProfile.CervicalStandardMinusC7]: {
    landmarkCount: 48,
    validLevels: [LevelType.C2C3, LevelType.C3C4, LevelType.C4C5, LevelType.C5C6, LevelType.C6T1],
    validVertebralBodies: [
      VertebralBody.C1,
      VertebralBody.C2,
      VertebralBody.C3,
      VertebralBody.C4,
      VertebralBody.C5,
      VertebralBody.C6,
      VertebralBody.T1,
    ],
    vertebralInfoMap: {
      [VertebralBody.C1]: {
        ...VERTEBRAL_BODY_ASSET_MAP[VertebralBody.C1],
        parent: VertebralBody.C2,
      },
      [VertebralBody.C2]: {
        ...VERTEBRAL_BODY_ASSET_MAP[VertebralBody.C2],
        parent: VertebralBody.C3,
      },
      [VertebralBody.C3]: {
        ...VERTEBRAL_BODY_ASSET_MAP[VertebralBody.C3],
        parent: VertebralBody.C4,
      },
      [VertebralBody.C4]: {
        ...VERTEBRAL_BODY_ASSET_MAP[VertebralBody.C4],
        parent: VertebralBody.C5,
      },
      [VertebralBody.C5]: {
        ...VERTEBRAL_BODY_ASSET_MAP[VertebralBody.C5],
        parent: VertebralBody.C6,
      },
      [VertebralBody.C6]: {
        ...VERTEBRAL_BODY_ASSET_MAP[VertebralBody.C6],
        parent: VertebralBody.C7,
      },
      [VertebralBody.C7]: null,
      [VertebralBody.C8]: null,
      [VertebralBody.T1]: {
        ...VERTEBRAL_BODY_ASSET_MAP[VertebralBody.T1],
        parent: null,
      },
      [VertebralBody.L1]: null,
      [VertebralBody.L2]: null,
      [VertebralBody.L3]: null,
      [VertebralBody.L4]: null,
      [VertebralBody.L5]: null,
      [VertebralBody.L6]: null,
      [VertebralBody.S1]: null,
    },
  },
  [CaseSpineProfile.CervicalStandardPlusC8]: {
    landmarkCount: 64,
    validLevels: [
      LevelType.C2C3,
      LevelType.C3C4,
      LevelType.C4C5,
      LevelType.C5C6,
      LevelType.C6C7,
      LevelType.C7C8,
      LevelType.C8T1,
    ],
    validVertebralBodies: [
      VertebralBody.C1,
      VertebralBody.C2,
      VertebralBody.C3,
      VertebralBody.C4,
      VertebralBody.C5,
      VertebralBody.C6,
      VertebralBody.C7,
      VertebralBody.C8,
      VertebralBody.T1,
    ],
    vertebralInfoMap: {
      [VertebralBody.C1]: {
        ...VERTEBRAL_BODY_ASSET_MAP[VertebralBody.C1],
        parent: VertebralBody.C2,
      },
      [VertebralBody.C2]: {
        ...VERTEBRAL_BODY_ASSET_MAP[VertebralBody.C2],
        parent: VertebralBody.C3,
      },
      [VertebralBody.C3]: {
        ...VERTEBRAL_BODY_ASSET_MAP[VertebralBody.C3],
        parent: VertebralBody.C4,
      },
      [VertebralBody.C4]: {
        ...VERTEBRAL_BODY_ASSET_MAP[VertebralBody.C4],
        parent: VertebralBody.C5,
      },
      [VertebralBody.C5]: {
        ...VERTEBRAL_BODY_ASSET_MAP[VertebralBody.C5],
        parent: VertebralBody.C6,
      },
      [VertebralBody.C6]: {
        ...VERTEBRAL_BODY_ASSET_MAP[VertebralBody.C6],
        parent: VertebralBody.C7,
      },
      [VertebralBody.C7]: {
        ...VERTEBRAL_BODY_ASSET_MAP[VertebralBody.C7],
        parent: VertebralBody.C8,
      },
      [VertebralBody.C8]: {
        ...VERTEBRAL_BODY_ASSET_MAP[VertebralBody.C8],
        parent: VertebralBody.T1,
      },
      [VertebralBody.T1]: {
        ...VERTEBRAL_BODY_ASSET_MAP[VertebralBody.T1],
        parent: null,
      },
      [VertebralBody.L1]: null,
      [VertebralBody.L2]: null,
      [VertebralBody.L3]: null,
      [VertebralBody.L4]: null,
      [VertebralBody.L5]: null,
      [VertebralBody.L6]: null,
      [VertebralBody.S1]: null,
    },
  },
  [CaseSpineProfile.LumbarStandard]: {
    landmarkCount: 44,
    validLevels: [LevelType.L1L2, LevelType.L2L3, LevelType.L3L4, LevelType.L4L5, LevelType.L5S1],
    validVertebralBodies: [
      VertebralBody.L1,
      VertebralBody.L2,
      VertebralBody.L3,
      VertebralBody.L4,
      VertebralBody.L5,
      VertebralBody.S1,
    ],
    vertebralInfoMap: {
      [VertebralBody.C1]: null,
      [VertebralBody.C2]: null,
      [VertebralBody.C3]: null,
      [VertebralBody.C4]: null,
      [VertebralBody.C5]: null,
      [VertebralBody.C6]: null,
      [VertebralBody.C7]: null,
      [VertebralBody.C8]: null,
      [VertebralBody.T1]: null,
      [VertebralBody.L1]: {
        ...VERTEBRAL_BODY_ASSET_MAP[VertebralBody.L1],
        parent: VertebralBody.L2,
      },
      [VertebralBody.L2]: {
        ...VERTEBRAL_BODY_ASSET_MAP[VertebralBody.L2],
        parent: VertebralBody.L3,
      },
      [VertebralBody.L3]: {
        ...VERTEBRAL_BODY_ASSET_MAP[VertebralBody.L3],
        parent: VertebralBody.L4,
      },
      [VertebralBody.L4]: {
        ...VERTEBRAL_BODY_ASSET_MAP[VertebralBody.L4],
        parent: VertebralBody.L5,
      },
      [VertebralBody.L5]: {
        ...VERTEBRAL_BODY_ASSET_MAP[VertebralBody.L5],
        parent: VertebralBody.S1,
      },
      [VertebralBody.L6]: null,
      [VertebralBody.S1]: {
        ...VERTEBRAL_BODY_ASSET_MAP[VertebralBody.S1],
        parent: null,
      },
    },
  },
  [CaseSpineProfile.LumbarStandardMinusL5]: {
    landmarkCount: 36,
    validLevels: [LevelType.L1L2, LevelType.L2L3, LevelType.L3L4, LevelType.L4S1],
    validVertebralBodies: [
      VertebralBody.L1,
      VertebralBody.L2,
      VertebralBody.L3,
      VertebralBody.L4,
      VertebralBody.S1,
    ],
    vertebralInfoMap: {
      [VertebralBody.C1]: null,
      [VertebralBody.C2]: null,
      [VertebralBody.C3]: null,
      [VertebralBody.C4]: null,
      [VertebralBody.C5]: null,
      [VertebralBody.C6]: null,
      [VertebralBody.C7]: null,
      [VertebralBody.C8]: null,
      [VertebralBody.T1]: null,
      [VertebralBody.L1]: {
        ...VERTEBRAL_BODY_ASSET_MAP[VertebralBody.L1],
        parent: VertebralBody.L2,
      },
      [VertebralBody.L2]: {
        ...VERTEBRAL_BODY_ASSET_MAP[VertebralBody.L2],
        parent: VertebralBody.L3,
      },
      [VertebralBody.L3]: {
        ...VERTEBRAL_BODY_ASSET_MAP[VertebralBody.L3],
        parent: VertebralBody.L4,
      },
      [VertebralBody.L4]: {
        ...VERTEBRAL_BODY_ASSET_MAP[VertebralBody.L4],
        parent: VertebralBody.S1,
      },
      [VertebralBody.L5]: null,
      [VertebralBody.L6]: null,
      [VertebralBody.S1]: {
        ...VERTEBRAL_BODY_ASSET_MAP[VertebralBody.S1],
        parent: null,
      },
    },
  },
  [CaseSpineProfile.LumbarStandardPlusL6]: {
    landmarkCount: 52,
    validLevels: [
      LevelType.L1L2,
      LevelType.L2L3,
      LevelType.L3L4,
      LevelType.L4L5,
      LevelType.L5L6,
      LevelType.L6S1,
    ],
    validVertebralBodies: [
      VertebralBody.L1,
      VertebralBody.L2,
      VertebralBody.L3,
      VertebralBody.L4,
      VertebralBody.L5,
      VertebralBody.L6,
      VertebralBody.S1,
    ],
    vertebralInfoMap: {
      [VertebralBody.C1]: null,
      [VertebralBody.C2]: null,
      [VertebralBody.C3]: null,
      [VertebralBody.C4]: null,
      [VertebralBody.C5]: null,
      [VertebralBody.C6]: null,
      [VertebralBody.C7]: null,
      [VertebralBody.C8]: null,
      [VertebralBody.T1]: null,
      [VertebralBody.L1]: {
        ...VERTEBRAL_BODY_ASSET_MAP[VertebralBody.L1],
        parent: VertebralBody.L2,
      },
      [VertebralBody.L2]: {
        ...VERTEBRAL_BODY_ASSET_MAP[VertebralBody.L2],
        parent: VertebralBody.L3,
      },
      [VertebralBody.L3]: {
        ...VERTEBRAL_BODY_ASSET_MAP[VertebralBody.L3],
        parent: VertebralBody.L4,
      },
      [VertebralBody.L4]: {
        ...VERTEBRAL_BODY_ASSET_MAP[VertebralBody.L4],
        parent: VertebralBody.L5,
      },
      [VertebralBody.L5]: {
        ...VERTEBRAL_BODY_ASSET_MAP[VertebralBody.L5],
        parent: VertebralBody.L6,
      },
      [VertebralBody.L6]: {
        ...VERTEBRAL_BODY_ASSET_MAP[VertebralBody.L6],
        parent: VertebralBody.S1,
      },
      [VertebralBody.S1]: {
        ...VERTEBRAL_BODY_ASSET_MAP[VertebralBody.S1],
        parent: null,
      },
    },
  },
};

export const VERTEBRAL_BODY_HIERARCHY_INDEX = [
  'C1',
  'C2',
  'C3',
  'C4',
  'C5',
  'C6',
  'C7',
  'T1',
  'T2',
  'T3',
  'T4',
  'T5',
  'T6',
  'T7',
  'T8',
  'T9',
  'T10',
  'T11',
  'T12',
  VertebralBody.L1,
  VertebralBody.L2,
  VertebralBody.L3,
  VertebralBody.L4,
  VertebralBody.L5,
  VertebralBody.L6,
  VertebralBody.S1,
];

export const AGE_RANGE_MAP: Record<
  AgeRange,
  { from: number | undefined; to: number | undefined; inclusive: boolean }
> = {
  [AgeRange.Under40]: {
    from: undefined,
    to: 40,
    inclusive: false,
  },
  [AgeRange.In40]: {
    from: 40,
    to: 49,
    inclusive: true,
  },
  [AgeRange.In50]: {
    from: 50,
    to: 59,
    inclusive: true,
  },
  [AgeRange.In60]: {
    from: 60,
    to: 69,
    inclusive: true,
  },
  [AgeRange.In70]: {
    from: 70,
    to: 79,
    inclusive: true,
  },
  [AgeRange.Above80]: {
    from: 80,
    to: undefined,
    inclusive: true,
  },
};

export const DEFAULT_TAG_CATEGORY_COLOR = '#d9e3f0';

export const MONTHS_BEFORE_EXPIRATION_TO_SHOW_WARNING = 1;

export const DEFAULT_ASSET_EXPIRY_DATE_MONTHS = 6;

export const IGNORED_EVENTS: EventType[] = [
  EventType.NitricPassivationComplete,
  EventType.SterilizationComplete,
];

export const NON_SEQUENTIAL_EVENTS: EventType[] = [
  EventType.PurchaseOrderPackagingSent,
  EventType.PurchaseOrderSent,
  EventType.MasterBuildRecordSent,
  EventType.Exception,
  ...IGNORED_EVENTS,
];

export const ORDERED_MANUAL_EVENT_TYPES: EventType[] = [
  EventType.BuildPlateConfirmationComplete,
  EventType.AdditivePrintingComplete,
  EventType.HipComplete,
  EventType.HeatTreatComplete,
  EventType.PostHipInspectionComplete,
  EventType.PostHeatTreatInspectionComplete,
  EventType.PostHipInspectionDeliveryComplete,
  EventType.CleaningAndPackagingComplete,
  EventType.DHRReviewRequested,
  EventType.FinalReleaseComplete,
  EventType.HospitalDeliveryComplete,
];

export const ORDERED_MANUAL_VENDOR_CATEGORIES: VendorTaskCategory[] = [
  VendorTaskCategory.AdditivePrinting,
  VendorTaskCategory.FinalShipment,
];

export const getAssetVertebralBody = (assetType: AssetType): VertebralBody | null => {
  switch (assetType) {
    case AssetType.L1:
    case AssetType.L1Edited:
    case AssetType.L1Daisy:
      return VertebralBody.L1;
    case AssetType.L2:
    case AssetType.L2Edited:
    case AssetType.L2Daisy:
      return VertebralBody.L2;
    case AssetType.L3:
    case AssetType.L3Edited:
    case AssetType.L3Daisy:
      return VertebralBody.L3;
    case AssetType.L4:
    case AssetType.L4Edited:
    case AssetType.L4Daisy:
      return VertebralBody.L4;
    case AssetType.L5:
    case AssetType.L5Edited:
    case AssetType.L5Daisy:
      return VertebralBody.L5;
    case AssetType.L6:
    case AssetType.L6Edited:
    case AssetType.L6Daisy:
      return VertebralBody.L6;
    case AssetType.S1:
    case AssetType.S1Edited:
    case AssetType.S1Daisy:
      return VertebralBody.S1;
    case AssetType.C1:
    case AssetType.C1Edited:
    case AssetType.C1Daisy:
      return VertebralBody.C1;
    case AssetType.C2:
    case AssetType.C2Edited:
    case AssetType.C2Daisy:
      return VertebralBody.C2;
    case AssetType.C3:
    case AssetType.C3Edited:
    case AssetType.C3Daisy:
      return VertebralBody.C3;
    case AssetType.C4:
    case AssetType.C4Edited:
    case AssetType.C4Daisy:
      return VertebralBody.C4;
    case AssetType.C5:
    case AssetType.C5Edited:
    case AssetType.C5Daisy:
      return VertebralBody.C5;
    case AssetType.C6:
    case AssetType.C6Edited:
    case AssetType.C6Daisy:
      return VertebralBody.C6;
    case AssetType.C7:
    case AssetType.C7Edited:
    case AssetType.C7Daisy:
      return VertebralBody.C7;
    case AssetType.C8:
    case AssetType.C8Edited:
    case AssetType.C8Daisy:
      return VertebralBody.C8;
    case AssetType.T1:
    case AssetType.T1Edited:
    case AssetType.T1Daisy:
      return VertebralBody.T1;
    default:
      return null;
  }
};

export const getSubsequentManualEvents = (
  lastEventType?: EventType,
  vendorAssignments?: IVendorAssignment[],
): EventType[] => {
  let eventTypeValues = ORDERED_MANUAL_EVENT_TYPES;

  if (!_.isNil(vendorAssignments)) {
    const printingVendor = vendorAssignments.find(
      (va) => va.taskCategory === VendorTaskCategory.AdditivePrinting,
    );

    const finalShipmentVendor = vendorAssignments.find(
      (va) => va.taskCategory === VendorTaskCategory.FinalShipment,
    );

    /**
     * TODO
     * According to VEN-196, a heat-treat capable vendor may not use heat treat for every case
     * Usage of HIP v.s VSR will depend on other factors, to be determined at a later date
     */
    const usesHeatTreat = false;

    const sameVendor = printingVendor?.organizationId === finalShipmentVendor?.organizationId;

    if (usesHeatTreat) {
      eventTypeValues = ORDERED_MANUAL_EVENT_TYPES.filter(
        (e) => ![EventType.HipComplete, EventType.PostHipInspectionComplete].includes(e),
      );
    } else {
      eventTypeValues = ORDERED_MANUAL_EVENT_TYPES.filter(
        (e) =>
          ![EventType.HeatTreatComplete, EventType.PostHeatTreatInspectionComplete].includes(e),
      );
    }

    eventTypeValues = eventTypeValues.filter((e) =>
      sameVendor ? e !== EventType.PostHipInspectionDeliveryComplete : true,
    );
  }

  const eventTypes: EventType[] = [];

  let lastEventTypeIndex = -1;

  if (lastEventType) {
    lastEventTypeIndex = eventTypeValues.findIndex(
      (eventTypeKey) => eventTypeKey === lastEventType,
    );
  }

  for (let i = 0; i < eventTypeValues.length; i++) {
    const eventTypeValue = eventTypeValues[i];

    if (i <= lastEventTypeIndex) continue;

    eventTypes.push(eventTypeValue);
  }

  return eventTypes;
};

export const isTrackableEvent = (eventType: EventType): { required: boolean } | null => {
  switch (eventType) {
    case EventType.FinalReleaseComplete:
      return { required: true };
    case EventType.PostHipInspectionComplete:
    case EventType.PostHeatTreatInspectionComplete:
      return { required: false };
    default:
      return null;
  }
};

export const isPurchaseOrderEvent = (eventType: EventType): { required: boolean } | null => {
  switch (eventType) {
    case EventType.PurchaseOrderPackagingSent:
    case EventType.PurchaseOrderSent:
      return { required: true };
    case EventType.CleaningAndPackagingComplete:
      return { required: false };
    default:
      return null;
  }
};

export const isTimeSensitiveEvent = (eventType: EventType): { required: boolean } | null => {
  switch (eventType) {
    case EventType.PurchaseOrderPackagingSent:
    case EventType.PurchaseOrderSent:
    case EventType.BuildPlateConfirmationComplete:
      return { required: true };
    case EventType.CleaningAndPackagingComplete:
      return { required: false };
    default:
      return null;
  }
};

export const isUpdatableCaseEvent = (eventType: EventType): boolean => {
  switch (eventType) {
    case EventType.DHRReviewRequestApproved:
    case EventType.DHRReviewRequested:
    case EventType.DHRReviewRequestRejected:
      return false;
    default:
      return true;
  }
};

export const getEventAssetType = (
  eventType: EventType,
): { assetType: AssetType; fileExtension: ValidFileExtensions; optional: boolean } | null => {
  switch (eventType) {
    case EventType.PurchaseOrderSent:
      return {
        assetType: AssetType.PurchaseOrder,
        fileExtension: ValidFileExtensions.PDF,
        optional: false,
      };
    case EventType.DHRReviewRequestApproved:
      return {
        assetType: AssetType.SignedDHR,
        fileExtension: ValidFileExtensions.PDF,
        optional: true,
      };
    case EventType.DHRReviewRequested:
      return { assetType: AssetType.DHR, fileExtension: ValidFileExtensions.PDF, optional: false };

    case EventType.PurchaseOrderPackagingSent:
      return {
        assetType: AssetType.PurchaseOrderPackaging,
        fileExtension: ValidFileExtensions.PDF,
        optional: true,
      };
    case EventType.MasterBuildRecordSent:
      return {
        assetType: AssetType.MasterBuildRecord,
        fileExtension: ValidFileExtensions.PDF,
        optional: false,
      };
    default:
      return null;
  }
};

export const getAssetEventType = (assetType: AssetType): EventType | null => {
  switch (assetType) {
    case AssetType.PurchaseOrder:
      return EventType.PurchaseOrderSent;
    case AssetType.PurchaseOrderPackaging:
      return EventType.PurchaseOrderPackagingSent;
    case AssetType.MasterBuildRecord:
      return EventType.MasterBuildRecordSent;
    default:
      return null;
  }
};
export const getApproachPartTypes = (approaches: SurgeonApproachType[]): PartType[] => {
  const allPartTypes: PartType[] = [];

  (approaches ?? []).forEach((approach) => {
    switch (approach) {
      case SurgeonApproachType.ALIF:
        allPartTypes.push(PartType.ALIF);
        break;
      case SurgeonApproachType.ALIFX:
        allPartTypes.push(PartType.ALIF_X_TWO_DOWN, PartType.ALIF_X_TWO_UP);
        break;
      case SurgeonApproachType.LLIF:
        allPartTypes.push(PartType.LLIF_LEFT, PartType.LLIF_RIGHT);
        break;
      case SurgeonApproachType.TLIFC:
        allPartTypes.push(
          PartType.TLIFC_OFFSET_LEFT,
          PartType.TLIFC_OFFSET_RIGHT,
          PartType.TLIFC_INLINE_LEFT,
          PartType.TLIFC_INLINE_RIGHT,
        );
        break;
      case SurgeonApproachType.TLIFCA:
        allPartTypes.push(
          PartType.TLIFCA_OFFSET_LEFT,
          PartType.TLIFCA_OFFSET_RIGHT,
          PartType.TLIFCA_INLINE_LEFT,
          PartType.TLIFCA_INLINE_RIGHT,
        );
        break;
      case SurgeonApproachType.TLIFO:
        allPartTypes.push(PartType.TLIFO_LEFT, PartType.TLIFO_RIGHT);
        break;
      default:
        return;
    }
  });

  return allPartTypes;
};

export const getPartTypeApproach = (partType: PartType): SurgeonApproachType => {
  switch (partType) {
    case PartType.NONE:
    case PartType.FUSED:
    case PartType.ACDF:
    case PartType.ACDF_X_TWO_UP:
    case PartType.ACDF_X_TWO_DOWN:
    case PartType.ACDF_X_LEFT_UP:
    case PartType.ACDF_X_LEFT_DOWN:
    case PartType.ACDF_X_NO_CAM_TWO_UP:
    case PartType.ACDF_X_NO_CAM_TWO_DOWN:
    case PartType.ACDF_X_NO_CAM_LEFT_UP:
    case PartType.ACDF_X_NO_CAM_LEFT_DOWN:
      return SurgeonApproachType.None;
    case PartType.ALIF:
      return SurgeonApproachType.ALIF;
    case PartType.ALIF_X_TWO_UP:
    case PartType.ALIF_X_TWO_DOWN:
      return SurgeonApproachType.ALIFX;

    case PartType.LLIF_RIGHT:
    case PartType.LLIF_LEFT:
      return SurgeonApproachType.LLIF;

    case PartType.TLIFO_RIGHT:
    case PartType.TLIFO_LEFT:
      return SurgeonApproachType.TLIFO;

    case PartType.TLIFC_INLINE_RIGHT:
    case PartType.TLIFC_INLINE_LEFT:
    case PartType.TLIFC_OFFSET_RIGHT:
    case PartType.TLIFC_OFFSET_LEFT:
      return SurgeonApproachType.TLIFC;

    case PartType.TLIFCA_INLINE_RIGHT:
    case PartType.TLIFCA_INLINE_LEFT:
    case PartType.TLIFCA_OFFSET_RIGHT:
    case PartType.TLIFCA_OFFSET_LEFT:
      return SurgeonApproachType.TLIFCA;
    default:
      return SurgeonApproachType.None;
  }
};

export const getTaskCategoryFromVendorTaskType = (taskType: VendorTaskType): VendorTaskCategory => {
  switch (taskType) {
    case VendorTaskType.UploadDhr:
    case VendorTaskType.CorrectDhr:
    case VendorTaskType.ShipCase:
      return VendorTaskCategory.FinalShipment;
  }
};

export const getVendorCreatableEvents = (vendorTaskCategory: VendorTaskCategory): EventType[] => {
  switch (vendorTaskCategory) {
    case VendorTaskCategory.AdditivePrinting:
      return [
        EventType.BuildPlateConfirmationComplete,
        EventType.AdditivePrintingComplete,
        EventType.HipComplete,
        EventType.HeatTreatComplete,
        EventType.PostHipInspectionComplete,
        EventType.PostHeatTreatInspectionComplete,
        EventType.PostHipInspectionComplete,
        EventType.MasterBuildRecordSent,
      ];
    case VendorTaskCategory.FinalShipment:
      return [
        EventType.CleaningAndPackagingComplete,
        EventType.DHRReviewRequested,
        EventType.FinalReleaseComplete,
      ];
  }
};

export const getVendorDeletableEvents = (vendorTaskCategory: VendorTaskCategory): EventType[] => {
  const nonDeletableEvents = [EventType.DHRReviewRequested];

  return getVendorCreatableEvents(vendorTaskCategory).filter(
    (e) => !nonDeletableEvents.includes(e),
  );
};

export const isPurchaseOrderComplete = (
  purchaseOrder: EventType,
  stage: CaseStageType,
  latestEvent?: EventType,
): boolean => {
  if ([CaseStageType.Ready, CaseStageType.Complete].includes(stage)) {
    return true;
  }

  const getEventIndex = (eventType?: EventType) =>
    ORDERED_MANUAL_EVENT_TYPES.findIndex((e) => e === eventType);

  const latestEventIndex = getEventIndex(latestEvent);

  switch (purchaseOrder) {
    case EventType.PurchaseOrderSent:
      return latestEventIndex >= getEventIndex(EventType.PostHipInspectionDeliveryComplete);
    case EventType.PurchaseOrderPackagingSent:
      return latestEventIndex >= getEventIndex(EventType.FinalReleaseComplete);
    case EventType.CleaningAndPackagingComplete:
      return latestEventIndex > getEventIndex(EventType.CleaningAndPackagingComplete);
    default:
      return false;
  }
};
