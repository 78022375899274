import { Alert, AlertTitle } from '@mui/material';
import { AlertColor } from '@mui/material/Alert/Alert';
import { caseUtils, format, ICase, ICaseStudyDate } from '@workflow-nx/common';

export function CaseStudyDateExpirationView(props: {
  surgeryDate: ICase['surgeryDate'] | null;
  studyDate?: ICaseStudyDate;
}) {
  if (!props.studyDate) return;

  const expiryStatus = caseUtils.getStudyDateExpiryStatus(
    props.surgeryDate,
    props.studyDate.expiryDate,
  );

  let severity: AlertColor = 'info';
  let studyDateExpiryTitle = 'Case Study Date';
  const formattedAssetType = props.studyDate.assetType
    ? format.formatAssetType(props.studyDate.assetType)
    : 'manual';
  let studyDateExpiryMessage = `The study date expiry is ${props.studyDate.expiryDate}, based on the ${formattedAssetType} study date of ${props.studyDate.studyDate} (${props.studyDate.daysOld} days old).`;

  switch (expiryStatus) {
    case 'EXPIRED':
      studyDateExpiryMessage = `WARNING: The ${
        props.studyDate.isExpiryDateSetManually ? 'manually edited study date' : formattedAssetType
      }, with a study date of ${props.studyDate.studyDate}, expired on ${
        props.studyDate.expiryDate
      }.`;
      severity = 'error';
      studyDateExpiryTitle = 'Case Study Has Expired!';

      break;
    case 'NEAR_EXPIRY':
      studyDateExpiryMessage = `WARNING: The study date expiry is ${props.studyDate.expiryDate}, based on the ${formattedAssetType} study date of ${props.studyDate.studyDate} (${props.studyDate.daysOld} days old).`;
      severity = 'warning';
      studyDateExpiryTitle = 'Case Study Is About To Expire!';
      break;
  }

  return (
    <Alert severity={severity}>
      <AlertTitle>{studyDateExpiryTitle}</AlertTitle>
      {studyDateExpiryMessage}
    </Alert>
  );
}
