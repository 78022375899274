import { faCircleNotch, faDownload, faPencil, faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Tooltip } from '@mui/material';
import { ImplantListLevelOptionsType } from './ImplantList';

export function ImplantListLevelOptionsMenu(props: {
  disabled?: boolean;
  loading?: boolean;
  downloading?: boolean;
  onSelect: (value: ImplantListLevelOptionsType) => Promise<void>;
}) {
  const options = [
    {
      key: 'EDIT',
      icon: faPencil,
      label: 'Edit Implant Size',
      disabled: props.disabled,
    },
    {
      key: 'DOWNLOAD',
      icon: faDownload,
      label: 'Download Implant Assets',
      disabled: props.disabled,
    },
    {
      key: 'REMOVE',
      icon: faTrash,
      label: 'Remove Implant',
      disabled: props.disabled,
    },
  ];
  return (
    <>
      {options.map((o, index) => (
        <Tooltip title={o.label} key={index}>
          <IconButton
            disabled={o.disabled}
            onClick={() => props.onSelect(o.key as ImplantListLevelOptionsType)}
          >
            {o.key === 'DOWNLOAD' && props?.downloading ? (
              <FontAwesomeIcon icon={faCircleNotch} spin={true} />
            ) : (
              <FontAwesomeIcon icon={o.icon} />
            )}
          </IconButton>
        </Tooltip>
      ))}
    </>
  );
}
