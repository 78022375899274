import { useLazyQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { gql } from '@apollo/client/core';
import * as FileSaver from 'file-saver';
import { file } from '@workflow-nx/utils';

export const useDownloadCloudDesignDebugFiles = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [findCloudDesignQueueQuery, { loading }] = useLazyQuery(
    gql`
      query FindCloudDesignQueue($cloudDesignQueueId: Int!) {
        cloudDesignQueue(cloudDesignQueueId: $cloudDesignQueueId) {
          cloudDesignQueue {
            cloudDesignQueueId
            caseId
            planId
            assetId
            level
            levelSize
            name
            status
            minutesProcessing
            startedAt
            finishedAt
            log
          }
          signedInputDownloadUrl
          signedOutputDownloadUrl
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    },
  );

  async function download(cloudDesignQueueId?: number) {
    if (!cloudDesignQueueId) return;

    try {
      const { data } = await findCloudDesignQueueQuery({
        variables: {
          cloudDesignQueueId,
        },
      });

      const cloudDesignQueue = data.cloudDesignQueue;
      if (cloudDesignQueue) {
        const signedInputDownloadUrl = cloudDesignQueue.signedInputDownloadUrl;
        const signedOutputDownloadUrl = cloudDesignQueue.signedOutputDownloadUrl;
        const name = cloudDesignQueue.cloudDesignQueue.name;

        if (signedInputDownloadUrl) {
          enqueueSnackbar('Downloading Cloud Design input zip file', { variant: 'info' });
          const response = await file.downloadFile(signedInputDownloadUrl);
          FileSaver.saveAs(response.data, `${name}-input.zip`);
        }

        if (signedOutputDownloadUrl) {
          enqueueSnackbar('Downloading Cloud Design output zip file', { variant: 'info' });
          const response = await file.downloadFile(signedOutputDownloadUrl);
          FileSaver.saveAs(response.data, `${name}-output.zip`);
        }
      }
    } catch (e) {
      enqueueSnackbar('An error occurred downloading the debug assets', { variant: 'error' });
    }
  }

  return { download, loading };
};
