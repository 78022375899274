import {
  HeightRestorationGoalType,
  AxialCorrectionGoalType,
  CaseApproachType,
  CaseSpineProfile,
  CoronalCorrectionGoalType,
  IMeasure,
  ImplantType,
  LevelType,
  PatientGender,
  Position,
  SurgeonApproachType,
  VertebralBody,
  LevelForm19Type,
} from '@workflow-nx/common';
import { Vector3 } from 'babylonjs';

export type VertebralBodyMeasurementsType = {
  [VertebralBody.C1]: IMeasure[];
  [VertebralBody.C2]: IMeasure[];
  [VertebralBody.C3]: IMeasure[];
  [VertebralBody.C4]: IMeasure[];
  [VertebralBody.C5]: IMeasure[];
  [VertebralBody.C6]: IMeasure[];
  [VertebralBody.C7]: IMeasure[];
  [VertebralBody.C8]: IMeasure[];
  [VertebralBody.T1]: IMeasure[];
  [VertebralBody.L1]: IMeasure[];
  [VertebralBody.L2]: IMeasure[];
  [VertebralBody.L3]: IMeasure[];
  [VertebralBody.L4]: IMeasure[];
  [VertebralBody.L5]: IMeasure[];
  [VertebralBody.L6]: IMeasure[];
  [VertebralBody.S1]: IMeasure[];
};

export type InterVertebralBodyMeasurementsType = {
  [LevelType.C2C3]: IMeasure[];
  [LevelType.C3C4]: IMeasure[];
  [LevelType.C4C5]: IMeasure[];
  [LevelType.C5C6]: IMeasure[];
  [LevelType.C6C7]: IMeasure[];
  [LevelType.C8T1]: IMeasure[];
  [LevelType.C7T1]: IMeasure[];
  [LevelType.C6T1]: IMeasure[];
  [LevelType.C7C8]: IMeasure[];
  [LevelType.L1L2]: IMeasure[];
  [LevelType.L2L3]: IMeasure[];
  [LevelType.L3L4]: IMeasure[];
  [LevelType.L4L5]: IMeasure[];
  [LevelType.L5L6]: IMeasure[];
  [LevelType.L5S1]: IMeasure[];
  [LevelType.L4S1]: IMeasure[];
  [LevelType.L6S1]: IMeasure[];
};

export type VertebraeSignUrlsType = {
  [VertebralBody.C1]: string | null;
  [VertebralBody.C2]: string | null;
  [VertebralBody.C3]: string | null;
  [VertebralBody.C4]: string | null;
  [VertebralBody.C5]: string | null;
  [VertebralBody.C6]: string | null;
  [VertebralBody.C7]: string | null;
  [VertebralBody.C8]: string | null;
  [VertebralBody.T1]: string | null;
  [VertebralBody.L1]: string | null;
  [VertebralBody.L2]: string | null;
  [VertebralBody.L3]: string | null;
  [VertebralBody.L4]: string | null;
  [VertebralBody.L5]: string | null;
  [VertebralBody.L6]: string | null;
  [VertebralBody.S1]: string | null;
};

export type DefaultGoalsType = {
  mismatchCorrectionGoalType: CaseApproachType;
  footprintSize: FootPrintType;
  anteriorHeightRange: RangeType;
  posteriorHeightRange: RangeType;
  lordosis: number;
  positioning: Position;
  surgeonApproach: SurgeonApproachType[];
};

export type RangeType = {
  min: number | null;
  max: number | null;
  fixedValue?: number;
};

export type FootPrintType = {
  ml: number;
  ap: number;
};

export type LevelMeasurementsType = {
  discCoronal: number;
  vbLordosis: number;
  discLordosis: number;
  anteriorHeight: number;
  posteriorHeight: number;
};

export type VertebraePreopType = {
  [LevelType.C2C3]: LevelMeasurementsType;
  [LevelType.C3C4]: LevelMeasurementsType;
  [LevelType.C4C5]: LevelMeasurementsType;
  [LevelType.C5C6]: LevelMeasurementsType;
  [LevelType.C6C7]: LevelMeasurementsType;
  [LevelType.C8T1]: LevelMeasurementsType;
  [LevelType.C7T1]: LevelMeasurementsType;
  [LevelType.C6T1]: LevelMeasurementsType;
  [LevelType.C7C8]: LevelMeasurementsType;
  [LevelType.L1L2]: LevelMeasurementsType;
  [LevelType.L2L3]: LevelMeasurementsType;
  [LevelType.L3L4]: LevelMeasurementsType;
  [LevelType.L4L5]: LevelMeasurementsType;
  [LevelType.L5L6]: LevelMeasurementsType;
  [LevelType.L5S1]: LevelMeasurementsType;
  [LevelType.L4S1]: LevelMeasurementsType;
  [LevelType.L6S1]: LevelMeasurementsType;
};

export type AutoCorrectionLevelGoalsType = {
  lordosis: number;
  level: LevelType;
  posteriorHeightRange: RangeType;
  anteriorHeightRange: RangeType;
  implantType: ImplantType;
};

export type AutoCorrectionRawLandmarkMeasurementsType = {
  [VertebralBody.C1]: AutoCorrectionRawLandmarkType | null;
  [VertebralBody.C2]: AutoCorrectionRawLandmarkType | null;
  [VertebralBody.C3]: AutoCorrectionRawLandmarkType | null;
  [VertebralBody.C4]: AutoCorrectionRawLandmarkType | null;
  [VertebralBody.C5]: AutoCorrectionRawLandmarkType | null;
  [VertebralBody.C6]: AutoCorrectionRawLandmarkType | null;
  [VertebralBody.C7]: AutoCorrectionRawLandmarkType | null;
  [VertebralBody.C8]: AutoCorrectionRawLandmarkType | null;
  [VertebralBody.T1]: AutoCorrectionRawLandmarkType | null;
  [VertebralBody.L1]: AutoCorrectionRawLandmarkType | null;
  [VertebralBody.L2]: AutoCorrectionRawLandmarkType | null;
  [VertebralBody.L3]: AutoCorrectionRawLandmarkType | null;
  [VertebralBody.L4]: AutoCorrectionRawLandmarkType | null;
  [VertebralBody.L5]: AutoCorrectionRawLandmarkType | null;
  [VertebralBody.L6]: AutoCorrectionRawLandmarkType | null;
  [VertebralBody.S1]: AutoCorrectionRawLandmarkType | null;
};

export type AutoCorrectionInputType = {
  activeLevelGoals: AutoCorrectionLevelGoalsType[];
  pelvicIncidence: number;
  coronalGoal: CoronalCorrectionGoalType;
  sagittalGoal: CaseApproachType;
  axialGoal: AxialCorrectionGoalType;
  heightRestorationGoal: HeightRestorationGoalType;
  signedUrls: VertebraeSignUrlsType;
  spineProfile: null | CaseSpineProfile;
  patientBirthDate: string;
  landmarkMeasurements: AutoCorrectionRawLandmarkMeasurementsType;
  levelForm19: LevelForm19Type;
  gender: PatientGender;
};

export type BoundingBoxDimensionsType = {
  width: number;
  height: number;
  depth: number;
  vectorsWorld: Vector3[];
};

export type Coordinates2DType = {
  x: number;
  y: number;
};

export type AutoCorrectionRawType = {
  raw: (Vector3 | null)[][];
  foramen: Vector3[];
  endplate: (Vector3 | null)[][];
};

export type AutoCorrectionForm19ImplantAttributeType = {
  cageHeight: RangeType;
  lordosis: RangeType;
};

export type AutoCorrectionForm19Type = {
  [key in ImplantType]: AutoCorrectionForm19ImplantAttributeType;
};

export type AutoCorrectionIdealSpineMapType = {
  [LevelType.C2C3]: number;
  [LevelType.C3C4]: number;
  [LevelType.C4C5]: number;
  [LevelType.C5C6]: number;
  [LevelType.C6C7]: number;
  [LevelType.C8T1]: number;
  [LevelType.C7T1]: number;
  [LevelType.C6T1]: number;
  [LevelType.C7C8]: number;

  [LevelType.L1L2]: number;
  [LevelType.L2L3]: number;
  [LevelType.L3L4]: number;
  [LevelType.L4L5]: number;
  [LevelType.L5L6]: number;
  [LevelType.L5S1]: number;
  [LevelType.L4S1]: number;
  [LevelType.L6S1]: number;
};

// NOTE: Rename to AutoCorrectionAngleToBaseSpineMapType (where base can be S1 or T1)
export type AutoCorrectionAngleToS1SpineMapType = {
  [CaseSpineProfile.CervicalStandard]: AutoCorrectionAngleToS1SpineMapAttributesType;
  [CaseSpineProfile.CervicalStandardMinusC7]: AutoCorrectionAngleToS1SpineMapAttributesType;
  [CaseSpineProfile.CervicalStandardPlusC8]: AutoCorrectionAngleToS1SpineMapAttributesType;
  [CaseSpineProfile.LumbarStandard]: AutoCorrectionAngleToS1SpineMapAttributesType;
  [CaseSpineProfile.LumbarStandardMinusL5]: AutoCorrectionAngleToS1SpineMapAttributesType;
  [CaseSpineProfile.LumbarStandardPlusL6]: AutoCorrectionAngleToS1SpineMapAttributesType;
};

export type AutoCorrectionAngleToS1SpineMapAttributesType = {
  [LevelType.C2C3]: number | null;
  [LevelType.C3C4]: number | null;
  [LevelType.C4C5]: number | null;
  [LevelType.C5C6]: number | null;
  [LevelType.C6C7]: number | null;
  [LevelType.C8T1]: number | null;
  [LevelType.C7T1]: number | null;
  [LevelType.C6T1]: number | null;
  [LevelType.C7C8]: number | null;

  [LevelType.L1L2]: number | null;
  [LevelType.L2L3]: number | null;
  [LevelType.L3L4]: number | null;
  [LevelType.L4L5]: number | null;
  [LevelType.L5L6]: number | null;
  [LevelType.L5S1]: number | null;
  [LevelType.L4S1]: number | null;
  [LevelType.L6S1]: number | null;
};

export type AutoCorrectionLordosisStore = {
  [key in LevelType]: number;
};

export type AutoCorrectionActiveLevelType = {
  level: LevelType;
  lordosis: number;
};

export type AutoCorrectionConfigType = {
  SACRUM_MAX_ML_CUT_OFF_MALE: number;
  SACRUM_MAX_ML_CUT_OFF_FEMALE: number;
  SACRUM_MIN_AP_CUT_OFF_AVERAGE: number;
  VB_MAX_ML_CUT_OFF_MALE: number;
  VB_MAX_ML_CUT_OFF_FEMALE: number;
  VB_MIN_ML_CUT_OFF: number;
  DBSCAN_CLUSTER_COUNT: number;
  DBSCAN_SCAN_XY_RADIUS: number;
  DBSCAN_SCAN_VB_RADIUS: number;
  DBSCAN_SCAN_CROSS_SECTION_RADIUS: number;
  DBSCAN_SCAN_SACRUM_RADIUS: number;
  DBSCAN_SCAN_DERIVATIVE_RADIUS: number;
  DBSCAN_SCAN_LOW_FIDELITY_ARTIFACT_RADIUS: number;
  DBSCAN_SCAN_HIGH_FIDELITY_ARTIFACT_RADIUS: number;
  DBSCAN_SCAN_DERIVATIVE_TRANSPOSED_RADIUS: number;
  DBSCAN_AXIAL_VALIDATION: number;
  RAY_CAST_LOW_FIDELITY: number;
  RAY_CAST_HIGH_FIDELITY: number;
  NORMAL_FILTER_AP_CUT_OFF: number;
  BOUNDING_BOX_SIZE: number;
};

export type AutoCorrectionEvaluatedCorrectionType = {
  centroid: Vector3;
  yPrime: Vector3;
  zPrime: Vector3;
  xPrime: Vector3;
  eulerAngles: Vector3;
};

export type AutoCorrectionQuartileMaxType = {
  q1Index: number;
  q4Index: number;
};

export type AutoCorrectionRawLandmarkType = {
  measurementPoints: IMeasure[];
};

export type TEM013RecommendationsType = {
  implantRecommendations: TEM013ImplantRecommendationsType;
  levelRecommendations: TEM013LevelRecommendationsType;
};

export type PosteriorTargetType = {
  [key in ImplantType]: {
    posterior: number;
  };
};

export type AutoCorrectionStrictRecommendations = {
  lordosis: number;
};

export type TEM013ImplantRecommendationsType = {
  [key in ImplantType]: TEM013ImplantMaxAttributeRecommendationsType;
};

export type TEM013ImplantMaxAttributeRecommendationsType = {
  lordosis: number;
  coronal: number;
};

export type TEM013LevelRecommendationsType = {
  [CaseSpineProfile.CervicalStandard]: TEM013SpineSpecificLevelRecommendationsType;
  [CaseSpineProfile.CervicalStandardMinusC7]: TEM013SpineSpecificLevelRecommendationsType;
  [CaseSpineProfile.CervicalStandardPlusC8]: TEM013SpineSpecificLevelRecommendationsType;
  [CaseSpineProfile.LumbarStandard]: TEM013SpineSpecificLevelRecommendationsType;
  [CaseSpineProfile.LumbarStandardMinusL5]: TEM013SpineSpecificLevelRecommendationsType;
  [CaseSpineProfile.LumbarStandardPlusL6]: TEM013SpineSpecificLevelRecommendationsType;
};

export type TEM013SpineSpecificLevelRecommendationsType = {
  [LevelType.C2C3]: TEM013LevelMaxAttributeRecommendationsType;
  [LevelType.C3C4]: TEM013LevelMaxAttributeRecommendationsType;
  [LevelType.C4C5]: TEM013LevelMaxAttributeRecommendationsType;
  [LevelType.C5C6]: TEM013LevelMaxAttributeRecommendationsType;
  [LevelType.C6C7]: TEM013LevelMaxAttributeRecommendationsType;
  [LevelType.C8T1]: TEM013LevelMaxAttributeRecommendationsType;
  [LevelType.C7T1]: TEM013LevelMaxAttributeRecommendationsType;
  [LevelType.C6T1]: TEM013LevelMaxAttributeRecommendationsType;
  [LevelType.C7C8]: TEM013LevelMaxAttributeRecommendationsType;

  [LevelType.L1L2]: TEM013LevelMaxAttributeRecommendationsType;
  [LevelType.L2L3]: TEM013LevelMaxAttributeRecommendationsType;
  [LevelType.L3L4]: TEM013LevelMaxAttributeRecommendationsType;
  [LevelType.L4L5]: TEM013LevelMaxAttributeRecommendationsType;
  [LevelType.L5L6]: TEM013LevelMaxAttributeRecommendationsType;
  [LevelType.L5S1]: TEM013LevelMaxAttributeRecommendationsType;
  [LevelType.L4S1]: TEM013LevelMaxAttributeRecommendationsType;
  [LevelType.L6S1]: TEM013LevelMaxAttributeRecommendationsType;
};

export type TEM013LevelMaxAttributeRecommendationsType = {
  anteriorHeight: RangeType;
  posteriorHeight: number;
};

export type AutoCorrectionVectorLimitsType = {
  start: Vector3;
  end: Vector3;
};

export type AutoCorrectionIndexLimitsType = {
  startIndex: number;
  endIndex: number;
};
