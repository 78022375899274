import MuiTextField from '@mui/material/TextField';
import React, { useEffect, useRef, useState } from 'react';
import { FormControl, IconButton, InputAdornment, InputLabel } from '@mui/material';
import { faSearch, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SearchInput = (props: {
  id?: string;
  label?: string;
  value: string;
  disabled?: boolean;
  placeholder?: string;
  onChange: (value: string) => void;
}) => {
  const [value, setValue] = useState(props.value);

  const handleReset = () => {
    const emptyValue = '';
    setValue(emptyValue);
    props.onChange(emptyValue);
  };

  const timer = useRef();

  const onChange = (event: React.ChangeEvent<any>) => {
    const { value } = event.target;
    event.preventDefault();
    setValue(value);
    clearTimeout(timer.current);
    // @ts-ignore
    timer.current = setTimeout(() => {
      clearTimeout(timer.current);
      props.onChange(value);
    }, 1000);
  };

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <FormControl fullWidth={true}>
      <InputLabel htmlFor={`search-input-${props.id}`}>{props.label}</InputLabel>
      <MuiTextField
        placeholder={props.placeholder || ''}
        value={value}
        size={'small'}
        variant={'outlined'}
        onChange={onChange}
        id={`search-input-${props.id}`}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FontAwesomeIcon icon={faSearch} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton disabled={!value} onClick={handleReset} size="large">
                <FontAwesomeIcon icon={faTimes} size="xs" />
              </IconButton>
            </InputAdornment>
          ),
        }}
        disabled={props.disabled}
      />
    </FormControl>
  );
};

export default SearchInput;
