import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useQuery } from '@apollo/client';
import { FIND_REPORT_CASES_BY_QUARTER } from '../../gql';
import { DashboardCardView } from './DashboardCardView';
import { BarDatum, ResponsiveBar } from '@nivo/bar';
import { Box, Theme, useTheme } from '@mui/material';

export const barChartTheme = (theme: Theme) => {
  return {
    axis: {
      ticks: {
        text: {
          fill: theme.palette.grey[800],
          fontWeight: 500,
          fontFamily: theme.typography.body2.fontFamily,
        },
      },
    },
    legends: {
      text: {
        fill: theme.palette.text.secondary,
        outlineWidth: 0,
        outlineColor: 'transparent',
        fontSize: theme.typography.button.fontSize,
        fontWeight: 600,
        fontFamily: theme.typography.button.fontFamily,
      },
    },
    tooltip: {
      container: {
        background: '#ffffff',
        color: theme.palette.text.secondary,
        fontSize: theme.typography.h5.fontSize,
        fontFamily: theme.typography.button.fontFamily,
        fontWeight: 500,
      },
    },
  };
};

const CasesByQuarter = () => {
  const { data: reportData, loading } = useQuery(FIND_REPORT_CASES_BY_QUARTER);
  const [caseLoad, setCaseLoad] = useState<Record<number, unknown>[]>([]);
  const theme = useTheme();

  const formatLabel = (id: string) => {
    switch (id) {
      case 'inProgress':
        return 'In Progress';
      case 'complete':
        return 'Complete';
      case 'missed':
        return 'Missed';
      case 'cancelled':
        return 'Cancelled';
      case 'draft':
        return 'Draft';
      default:
        return id;
    }
  };

  useEffect(() => {
    if (reportData && reportData.reportCasesByQuarter.length > 0) {
      const caseLoad: Record<number | string, unknown>[] = [];
      reportData.reportCasesByQuarter.forEach(
        ({
          quarter,
          count,
          complete,
          cancelled,
          missed,
          inProgress,
          draft,
        }: {
          quarter: number;
          count: number;
          complete: number;
          cancelled: number;
          missed: number;
          inProgress: number;
          draft: number;
        }) => {
          caseLoad.push({
            quarter: `Q${quarter} (${count})`,
            cancelled,
            missed,
            complete,
            inProgress,
            draft,
          });
        },
      );

      setCaseLoad(caseLoad);
    }
  }, [reportData]);

  return (
    <DashboardCardView label={'Cases By Quarter'}>
      {loading ? (
        <Box height={'inherit'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <CircularProgress />
        </Box>
      ) : (
        <ResponsiveBar
          data={caseLoad as unknown as BarDatum[]}
          keys={['inProgress', 'complete', 'missed', 'cancelled', 'draft']}
          indexBy="quarter"
          margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
          padding={0.3}
          layout="vertical"
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          colors={[
            '#ffec27', // IN PROGRESS
            '#1DB83E', // COMPLETE
            '#fa8600', // MISSED
            '#c43333', // CANCELLED
            '#d3d3d3', // DRAFT
          ]}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendPosition: 'middle',
            legendOffset: 32,
          }}
          innerPadding={2}
          borderColor={{
            from: 'color',
            modifiers: [['darker', 1.5]],
          }}
          borderWidth={1}
          labelSkipWidth={12}
          labelSkipHeight={12}
          enableLabel={false}
          legendLabel={(d) => formatLabel(d.id as string)}
          tooltipLabel={(d) => `${d.indexValue} - ${formatLabel(d.id as string)}`}
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'top',
              direction: 'row',
              justify: false,
              translateX: 0,
              translateY: -40,
              itemsSpacing: 2,
              itemWidth: 110,
              itemHeight: 0,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          role="application"
          ariaLabel="Cases By Quarter"
          theme={barChartTheme(theme)}
        />
      )}
    </DashboardCardView>
  );
};
export default CasesByQuarter;
