import { useMutation, useQuery } from '@apollo/client';
import { Alert, Box, Button, Card, CardContent, Divider, Typography } from '@mui/material';
import {
  ActivityType,
  AssetType,
  CaseStageType,
  ICase,
  LevelType,
  Permission,
  PlanStatusType,
  PlanVersionType,
  ValidFileExtensions,
  caseUtils,
  format,
} from '@workflow-nx/common';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import { Dispatch, useCallback, useReducer, useState } from 'react';
import ActionButton from '../../../../components/ActionButton';
import { AssetGridTable } from '../../../../components/AssetGridTable/AssetGridTable';
import config from '../../../../extras/config';
import {
  COMPLETE_CASE_STAGE,
  FIND_FORM19_BY_CASE_ID,
  FIND_PLAN_BY_STATUS,
  IMPORT_CASE_IMPLANT_SPECIFICATION,
  UNAPPROVE_PLAN,
} from '../../../../gql';
import useAuth from '../../../../hooks/useAuth';
import { ActivityTable } from '../ActivityTable';
import { CaseViewActionType } from '../CaseView';
import { TabLoadingView } from '../TabLoadingView';
import { CaseDesignTabReducer, designReducerInitialState } from './CaseDesignTab.reducer';
import { DesignDetailsView } from './DesignDetailsView';
import { ExportNTopAssetsDialog } from './ExportNTopAssetsDialog';
import { Form19FillDialog } from './Form19FillDialog';
import { LevelAssetGridView } from './LevelAssetGridView';
import EditedVertebralAssetsBanner from '../EditedVertebralAssetsBanner';
import { ImplantSizeExclusionAlert } from '../../../../components/ImplantSizeExclusionAlert';

export function CaseDesignTabView(props: {
  activeCase: ICase;
  dispatch: Dispatch<CaseViewActionType>;
}) {
  const auth = useAuth();
  const confirm = useConfirm();
  const [completeCaseStage, { loading: loadingCompleteCase }] = useMutation(COMPLETE_CASE_STAGE);
  const [unapprovePlan, { loading: loadingUnapprovePlan }] = useMutation(UNAPPROVE_PLAN);
  const [importCaseImplantSpecification, { loading: loadingImportCaseImplantSpecification }] =
    useMutation(IMPORT_CASE_IMPLANT_SPECIFICATION);
  const { enqueueSnackbar } = useSnackbar();
  const [openForm19FillDialog, setOpenForm19FillDialog] = useState(false);

  const { refetch: refetchPlanByStatus, loading: loadingPlanByStatus } = useQuery(
    FIND_PLAN_BY_STATUS,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      variables: {
        caseId: props.activeCase.caseId,
        status: [PlanStatusType.Proposed, PlanStatusType.Approved],
      },
      onCompleted: (data) => {
        const plan = data.planByStatus;
        designTabDispatch({ type: 'INIT', data: plan });
      },
    },
  );

  const { refetch: refetchForm19, loading: loadingForm19 } = useQuery(FIND_FORM19_BY_CASE_ID, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      caseId: props.activeCase.caseId,
      deletedAt: null,
    },
    onCompleted: (data) => {
      const form19 = data.form19;
      designTabDispatch({ type: 'FORM19_UPDATED', data: form19 });
    },
  });

  const [openExportDialog, setOpenExportDialog] = useState(false);

  const [state, designTabDispatch] = useReducer(
    CaseDesignTabReducer(props.activeCase, auth?.user?.permissions),
    designReducerInitialState,
  );

  const form19Permissions = auth?.user?.permissions;
  const canEditForm19 =
    !!form19Permissions?.includes(Permission.ManageCase) ||
    !!form19Permissions?.includes(Permission.EditCase);

  const handleImportCaseImplantSpecification = async () => {
    try {
      await confirm({
        title: 'Import case implant dimension files data',
        description:
          'Are you sure you want to import dimension files data? This will delete the existing Form-019 data for this case.',
      });

      await importCaseImplantSpecification({
        variables: {
          caseId: props.activeCase.caseId,
        },
      });

      enqueueSnackbar('Case implants dimensions data imported successfully', {
        variant: 'success',
      });
    } catch (err) {
      console.error(err);
      enqueueSnackbar('An error occurred importing implants dimensions data', {
        variant: 'error',
      });
    }
  };
  const handleCompleteCaseStage = useCallback(async () => {
    await confirm({
      title: 'Move To QA Review',
      description:
        'Are you sure you want to move this case to QA Review? Please confirm that the Form 19 and design assets are all up to date.',
    });

    try {
      try {
        await completeCaseStage({
          variables: {
            caseId: props.activeCase.caseId,
          },
        });
      } catch {
        return;
      }

      props.dispatch({ type: 'refetch' });

      enqueueSnackbar('Case updated successfully', {
        variant: 'success',
      });
    } catch (e) {
      console.error(e);
      enqueueSnackbar('Error updating case', {
        variant: 'error',
      });
    }
  }, [confirm, completeCaseStage, props, enqueueSnackbar]);

  const handleUnapprovePlan = useCallback(async () => {
    try {
      await confirm({
        title: 'Un-approve Plan',
        description: (
          <span>
            Are you sure you want to un-approve the plan? This will move the case back to the{' '}
            <strong>{CaseStageType.Planning}</strong> stage. This will also delete any cloud design
            assets and reset the Form 19 back to its original state.
          </span>
        ),
      });
    } catch {
      return;
    }

    try {
      await unapprovePlan({
        variables: {
          planId: state.plan?.planId,
        },
      });

      props.dispatch({ type: 'refetch' });

      enqueueSnackbar('Plan un-approved successfully', {
        variant: 'success',
      });
    } catch (e) {
      console.error(e);
      enqueueSnackbar('Error un-approving plan', {
        variant: 'error',
      });
    }
  }, [confirm, unapprovePlan, props, enqueueSnackbar, state.plan]);

  const validCaseLevels = caseUtils.getValidCaseLevels(props.activeCase.levels);
  const validAssets = config.featureFlags.designRequireTem013
    ? [AssetType.Tem013, AssetType.Form19]
    : [AssetType.Form18, AssetType.Form19];

  return loadingPlanByStatus || loadingForm19 ? (
    <TabLoadingView />
  ) : (
    <Card>
      <CardContent>
        <Box mb={4}>
          <Typography variant={'h4'}>
            <strong>Step 1: Upload Design Assets</strong>
          </Typography>
        </Box>

        {state?.plan?.plusLevelSize !== 2 ? (
          <Box mb={2} mx={10}>
            <Alert severity={'warning'}>
              NOTE: This plan is using the smaller sized PLUS level implant.
            </Alert>
          </Box>
        ) : null}
        {state?.plan ? (
          <ImplantSizeExclusionAlert
            activeCase={props.activeCase}
            currentStage={CaseStageType.Design}
            planId={state?.plan?.planId}
          />
        ) : null}
        <Box my={2} mx={7}>
          <Divider variant={'middle'} />
        </Box>
        <Box mx={10}>
          <EditedVertebralAssetsBanner
            dispatch={designTabDispatch}
            activeCase={props.activeCase}
            assets={state.planAssets ?? []}
            readOnly={state.isStageComplete || !state.isEditingAllowed}
            plan={state.plan}
          />
        </Box>
        {state?.plan?.version === PlanVersionType.Version2 ? (
          <DesignDetailsView
            plan={state.plan}
            levels={props.activeCase.levels}
            planAssets={state.planAssets ?? []}
            caseNumber={props.activeCase.number}
          />
        ) : null}

        {state?.plan?.version === PlanVersionType.Version1 ? (
          <>
            <Box display={'flex'} justifyContent={'end'} mb={2}>
              <ActionButton
                color={'secondary'}
                onClick={() => {
                  setOpenExportDialog(true);
                }}
                variant={'outlined'}
              >
                Download nTop GUI Assets
              </ActionButton>
            </Box>

            {state.plan && state.planAssets ? (
              <>
                {validCaseLevels.map((levelType) => {
                  return (
                    <Box mb={4} key={levelType}>
                      <LevelAssetGridView
                        key={levelType}
                        activeCase={props.activeCase}
                        dispatch={designTabDispatch}
                        levelType={levelType as LevelType}
                        // @ts-ignore
                        plan={state.plan}
                        planAssets={state.planAssets ?? []}
                        readonly={state.isStageComplete || !state.isEditingAllowed}
                      />
                    </Box>
                  );
                })}
              </>
            ) : null}

            {props.activeCase.includeRodTemplates ? (
              <Box mb={2}>
                <Typography variant={'h5'}>Rod Template Assets</Typography>
                <AssetGridTable
                  dispatch={designTabDispatch}
                  caseId={props.activeCase.caseId}
                  planId={state.plan?.planId}
                  assets={state.planAssets || []}
                  validFileExtensions={[ValidFileExtensions.STL]}
                  validAssets={[AssetType.RodTemplateLeft, AssetType.RodTemplateRight]}
                  readOnly={state.isStageComplete || !state.isEditingAllowed}
                />
              </Box>
            ) : null}
          </>
        ) : null}
        <Box my={4}>
          <Typography variant={'h4'}>
            <strong>Step 2: Complete TEM-013 and Form 19</strong>
          </Typography>
        </Box>
        <Box mx={10}>
          {state.plan && state.planAssets && canEditForm19 ? (
            <Box mb={2}>
              {state.form19Data?.engineeringApprovedAt ? (
                <Box mb={2}>
                  <Alert severity={'success'}>
                    {format.formatName(state.form19Data?.engineeringApprovedByUser)} has given
                    engineering approval for the FORM-019 on{' '}
                    {format.formatDate(state.form19Data?.engineeringApprovedAt)}
                  </Alert>
                </Box>
              ) : !state.canMoveToQaReview ? (
                <Box mb={2}>
                  <Alert severity={'warning'}>
                    This FORM-019 has not been approved by engineering yet
                  </Alert>
                </Box>
              ) : null}
              {state.form19Data?.qualityApprovedAt ? (
                <Box mb={2}>
                  <Alert severity={'success'}>
                    {format.formatName(state.form19Data?.qualityApprovedByUser)} has given quality
                    approval for the FORM-019 on{' '}
                    {format.formatDate(state.form19Data?.qualityApprovedAt)}
                  </Alert>
                </Box>
              ) : null}
              {!state.isStageComplete ? (
                <Box display={'flex'} my={2} justifyContent={'left'}>
                  <Button
                    variant={'outlined'}
                    color={'secondary'}
                    onClick={() => setOpenForm19FillDialog(true)}
                    disabled={
                      !canEditForm19 ||
                      loadingForm19 ||
                      loadingImportCaseImplantSpecification ||
                      !!props?.activeCase?.caseCancellation
                    }
                  >
                    Fill FORM-019
                  </Button>
                  <Box mx={1} />
                  <Button
                    variant={'outlined'}
                    color={'secondary'}
                    onClick={handleImportCaseImplantSpecification}
                    disabled={
                      !canEditForm19 ||
                      loadingForm19 ||
                      loadingImportCaseImplantSpecification ||
                      state.form19Data?.engineeringApprovedAt ||
                      !!props?.activeCase?.caseCancellation
                    }
                  >
                    Import Dimension Files Data
                  </Button>
                </Box>
              ) : null}
            </Box>
          ) : null}
          <Box mb={2}>
            <AssetGridTable
              dispatch={designTabDispatch}
              assets={state.planAssets || []}
              caseId={props.activeCase.caseId}
              planId={state.plan?.planId}
              validFileExtensions={[
                ValidFileExtensions.PDF,
                ValidFileExtensions.XLSX,
                ValidFileExtensions.XLSM,
                ValidFileExtensions.DOCX,
              ]}
              validAssets={validAssets}
              readOnly={state.isStageComplete || !state.isEditingAllowed}
            />
          </Box>
          <ActivityTable
            label={'Form 19 Activity'}
            caseId={props.activeCase.caseId}
            activityTypes={[ActivityType.Form19UpdateStatusRejected]}
          />
        </Box>
        {props.activeCase.stage === CaseStageType.Design ? (
          <Box my={4} mx={10}>
            {!state.canMoveToQaReview ? (
              <Box mb={2}>
                <Alert severity={'info'}>
                  All interbody assets and rod-templates (if required) must be uploaded before
                  moving to the <strong>QA REVIEW</strong> stage
                </Alert>
              </Box>
            ) : null}

            <Box display={'flex'} my={2} justifyContent={'center'}>
              <ActionButton
                loading={loadingUnapprovePlan || loadingCompleteCase}
                variant={'outlined'}
                onClick={handleUnapprovePlan}
                disabled={!state.isEditingAllowed || !!props?.activeCase?.caseCancellation}
              >
                Un-approve Plan
              </ActionButton>
              <Box mx={1} />
              <ActionButton
                loading={loadingUnapprovePlan || loadingCompleteCase}
                variant={'outlined'}
                disabled={
                  !state.canMoveToQaReview ||
                  !state.isEditingAllowed ||
                  !!props?.activeCase?.caseCancellation
                }
                onClick={handleCompleteCaseStage}
              >
                Move To QA Review
              </ActionButton>
            </Box>
          </Box>
        ) : null}
      </CardContent>
      {openExportDialog ? (
        <ExportNTopAssetsDialog
          open={openExportDialog}
          activeCase={props.activeCase}
          plan={state.plan}
          onClose={(shouldUpdate) => {
            if (shouldUpdate) {
              refetchPlanByStatus();
            }
            setOpenExportDialog(false);
          }}
        />
      ) : null}
      {state.plan ? (
        <Form19FillDialog
          activeCase={props.activeCase}
          plan={state.plan}
          open={openForm19FillDialog}
          onClose={(shouldUpdate) => {
            if (shouldUpdate) {
              refetchForm19();
            }
            setOpenForm19FillDialog(false);
          }}
        />
      ) : null}
    </Card>
  );
}
