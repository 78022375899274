import React from 'react';
import {
  Checkbox as MuiCheckbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
} from '@mui/material';
import { useController, UseControllerProps } from 'react-hook-form';
//import { testing } from '@workflow-nx/utils';

interface CheckboxListFieldRHFProps {
  label: string;
  name: string;
  disabled?: boolean;
  required?: boolean;
  listItems: { key: string; value: string }[];
  onLabelRender?: (key: string, value: string) => string;
  control: UseControllerProps['control'];
  displayError?: boolean;
  disableInline?: boolean;
  onChange?: (values: string[], event: React.ChangeEvent<HTMLInputElement>) => void;
}

export function CheckboxListFieldRHF({
  label,
  name,
  disabled,
  required,
  listItems,
  onLabelRender,
  control,
  displayError = true,
  disableInline = false,
  onChange,
}: CheckboxListFieldRHFProps) {
  const {
    field: { value = [] as string[], onChange: hookFormOnChange, onBlur, ref },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
  } as UseControllerProps);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    itemKey: string,
  ) => {
    const updatedValues = checked
      ? [...value, itemKey]
      : value.filter((v: string) => v !== itemKey);
    hookFormOnChange(updatedValues);
    onChange && onChange(updatedValues, event);
  };

  return (
    <FormControl required={required} error={Boolean(invalid)} component="fieldset">
      <FormLabel component="legend">{label}</FormLabel>
      <FormGroup style={disableInline ? {} : { display: 'inline' }}>
        {listItems.map((listItem) => (
          <FormControlLabel
            key={listItem.key}
            control={
              <MuiCheckbox
                /*       data-test-id={`${testing.toKebabCase(name)}-${testing.toKebabCase(
                        listItem.key,
                      )}-checkbox`} */
                checked={Array.isArray(value) ? value?.includes(listItem.key) : false}
                onChange={(event, checked) => handleChange(event, checked, listItem.key)}
                name={name}
                value={listItem.key}
                disabled={disabled}
              />
            }
            label={onLabelRender?.(listItem.key, listItem.value) ?? listItem.value}
          />
        ))}
      </FormGroup>
      {displayError ? <FormHelperText>{error?.message}</FormHelperText> : null}
    </FormControl>
  );
}
