import { Field } from 'formik';
import React from 'react';
import DatePicker from './DatePicker';

export function DatePickerField(props: {
  loading?: boolean;
  disablePast?: boolean;
  required?: boolean;
  disabled?: boolean;
  name: string;
  label: string;
  shrink?: boolean;
}) {
  return (
    <Field
      disabled={Boolean(props.disabled || props.loading)}
      name={props.name}
      label={props.label}
      required={props.required}
      disablePast={Boolean(props.disablePast)}
      component={DatePicker}
    />
  );
}
