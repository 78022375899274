import { Grid, SelectChangeEvent, Typography } from '@mui/material';
import { ValidFileExtensions } from '@workflow-nx/common';
import { ComponentProps } from 'react';
import { Control, FieldValues } from 'react-hook-form';
import { DatePickerRHF } from './DatePickerRHF';
import { DropzoneRHF } from './DropzoneRHF';
import { ErrorMessageRHF } from './ErrorMessageRHF';
import { PhoneNumberField } from './PhoneNumberField';
import { SelectFieldRHF } from './SelectFieldRHF';
import { TextFieldRHF } from './TextFieldRHF';
import { NumberTextFieldRHF } from './NumberTextFieldRHF';
import { SwitchRHF } from './SwitchRHF';

export const enum InputTypeRHF {
  Text = 'TEXT',
  Select = 'SELECT',
  Date = 'DATE',
  Asset = 'ASSET',
  Phone = 'PHONE',
  Switch = 'SWITCH',
  Number = 'NUMBER',
}

export type MenuItemRHF = {
  key: string;
  value: string;
};

export type InputConfigRHF = {
  id: string;
  label: string;
  input: InputTypeRHF;
  menuItems?: MenuItemRHF[];
  multiple?: boolean;
  validFileExtensions?: ValidFileExtensions[];
};

export const InputRHF = (props: {
  config: InputConfigRHF;
  disabled?: boolean;
  required?: boolean;
  control: Control<FieldValues>;
  onChange?: (evt: SelectChangeEvent<HTMLSelectElement>) => void;
  textFieldProps?: Partial<ComponentProps<typeof TextFieldRHF>>;
  numberTextFieldProps?: Partial<ComponentProps<typeof NumberTextFieldRHF>>;
  selectFieldProps?: Partial<ComponentProps<typeof SelectFieldRHF>>;
  datePickerProps?: Partial<ComponentProps<typeof DatePickerRHF>>;
  dropzoneProps?: Partial<ComponentProps<typeof DropzoneRHF>>;
}) => {
  return (
    <>
      {props.config.input === InputTypeRHF.Text ? (
        <>
          <Grid item container xs={12} spacing={1} alignItems={'center'}>
            <Grid item xs={3} md={3} display={'flex'} alignItems={'center'}>
              <Typography variant={'h5'}>
                {props.config.label}
                {props.required ? (
                  <span
                    style={{
                      color: 'red',
                      marginLeft: 4,
                    }}
                  >
                    *
                  </span>
                ) : undefined}
              </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
              <TextFieldRHF
                name={props.config.id}
                control={props.control}
                disabled={props.disabled}
                required={props.required}
                {...props.textFieldProps}
              />
            </Grid>
            <Grid item xs={3} md={3} display={'flex'} justifyContent={'center'}>
              <ErrorMessageRHF name={props.config.id} control={props.control} />
            </Grid>
          </Grid>
        </>
      ) : props.config.input === InputTypeRHF.Number ? (
        <>
          <Grid item container xs={12} spacing={1} alignItems={'center'}>
            <Grid item xs={3} md={3} display={'flex'} alignItems={'center'}>
              <Typography variant={'h5'}>
                {props.config.label}
                {props.required ? (
                  <span
                    style={{
                      color: 'red',
                      marginLeft: 4,
                    }}
                  >
                    *
                  </span>
                ) : undefined}
              </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
              <NumberTextFieldRHF
                name={props.config.id}
                control={props.control}
                disabled={props.disabled}
                required={props.required}
                {...props.textFieldProps}
              />
            </Grid>
            <Grid item xs={3} md={3} display={'flex'} justifyContent={'center'}>
              <ErrorMessageRHF name={props.config.id} control={props.control} />
            </Grid>
          </Grid>
        </>
      ) : props.config.input === InputTypeRHF.Select ? (
        <Grid item container xs={12} spacing={1} alignItems={'center'}>
          <Grid item xs={3} md={3} display={'flex'} alignItems={'center'}>
            <Typography variant={'h5'}>
              {props.config.label}
              {props.required ? (
                <span
                  style={{
                    color: 'red',
                    marginLeft: 4,
                  }}
                >
                  *
                </span>
              ) : undefined}
            </Typography>
          </Grid>
          <Grid item xs={6} md={6}>
            <SelectFieldRHF
              onChange={props.onChange}
              control={props.control}
              name={props.config.id}
              menuItems={props.config.menuItems ? props.config.menuItems : []}
              disabled={props.disabled}
              required={props.required}
              {...props.selectFieldProps}
            />
          </Grid>
          <Grid item xs={3} md={3} display={'flex'} justifyContent={'center'}>
            <ErrorMessageRHF name={props.config.id} control={props.control} />
          </Grid>
        </Grid>
      ) : props.config.input === InputTypeRHF.Date ? (
        <Grid item container xs={12} spacing={1} alignItems={'center'}>
          <Grid item xs={3} md={3} display={'flex'} alignItems={'center'}>
            <Typography variant={'h5'}>
              {props.config.label}
              {props.required ? (
                <span
                  style={{
                    color: 'red',
                    marginLeft: 4,
                  }}
                >
                  *
                </span>
              ) : undefined}
            </Typography>
          </Grid>
          <Grid item xs={6} md={6}>
            <DatePickerRHF
              name={props.config.id}
              control={props.control}
              disabled={props.disabled}
              required={props.required}
              {...props.datePickerProps}
            />
          </Grid>
          <Grid item xs={3} md={3} display={'flex'} justifyContent={'center'}>
            <ErrorMessageRHF name={props.config.id} control={props.control} />
          </Grid>
        </Grid>
      ) : props.config.input === InputTypeRHF.Asset ? (
        <Grid item container xs={12} spacing={1} alignItems={'center'}>
          <Grid item xs={3} md={3} display={'flex'} alignItems={'center'}>
            <Typography variant={'h5'}>
              {props.config.label}
              {props.required ? (
                <span
                  style={{
                    color: 'red',
                    marginLeft: 4,
                  }}
                >
                  *
                </span>
              ) : undefined}
            </Typography>
          </Grid>
          <Grid item xs={6} md={6}>
            <DropzoneRHF
              name={props.config.id}
              control={props.control}
              validFileExtensions={[]}
              {...props.dropzoneProps}
            />
          </Grid>
          <Grid item xs={3} md={3} display={'flex'} justifyContent={'center'}>
            <ErrorMessageRHF name={props.config.id} control={props.control} />
          </Grid>
        </Grid>
      ) : props.config.input === InputTypeRHF.Switch ? (
        <Grid item container xs={12} spacing={1} alignItems={'center'}>
          <Grid item xs={3} md={3} display={'flex'} alignItems={'center'}>
            <Typography variant={'h5'}>
              {props.config.label}
              {props.required ? (
                <span
                  style={{
                    color: 'red',
                    marginLeft: 4,
                  }}
                >
                  *
                </span>
              ) : undefined}
            </Typography>
          </Grid>
          <Grid item xs={6} md={6}>
            <SwitchRHF
              name={props.config.id}
              control={props.control}
              disabled={props.disabled}
              required={props.required}
            />
          </Grid>
          <Grid item xs={3} md={3} display={'flex'} justifyContent={'center'}>
            <ErrorMessageRHF name={props.config.id} control={props.control} />
          </Grid>
        </Grid>
      ) : props.config.input === InputTypeRHF.Phone ? (
        <Grid item container xs={12} spacing={1} alignItems={'center'}>
          <Grid item xs={3} md={3} display={'flex'} alignItems={'center'}>
            <Typography variant={'h5'}>
              {props.config.label}
              {props.required ? (
                <span
                  style={{
                    color: 'red',
                    marginLeft: 4,
                  }}
                >
                  *
                </span>
              ) : undefined}
            </Typography>
          </Grid>
          <Grid item xs={6} md={6}>
            <PhoneNumberField
              name={props.config.id}
              control={props.control}
              disabled={props.disabled}
              required={props.required}
            />
          </Grid>
          <Grid item xs={3} md={3} display={'flex'} justifyContent={'center'}>
            <ErrorMessageRHF name={props.config.id} control={props.control} />
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};
