import React, { ChangeEvent } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IconFontButton } from '@workflow-nx/ui';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { format } from '@workflow-nx/common';
import { DateTime } from 'luxon';

export type CalendarViewMode = 'listWeek' | 'dayGridMonth' | 'dayGridWeek' | 'dayGridMonthSpan';

type CalendarToolbarProps = {
  date: Date;
  onDateNext: () => void;
  onDatePrev: () => void;
  onDateToday: () => void;
  onViewChange: (view: CalendarViewMode) => void;
  loading?: boolean;
  hideControls?: boolean;
  totalWeeksShown?: number;
  view: CalendarViewMode;
};

const useStyles = makeStyles(() => ({
  progressIcon: {
    margin: '0 12px',
  },
}));

export const CalendarToolbar = (props: CalendarToolbarProps) => {
  const { date, onDateNext, onDatePrev, onViewChange, loading } = props;
  const styles = useStyles();
  const datetime = DateTime.fromJSDate(date);

  const startOfWeekAtCalendarStart =
    props.totalWeeksShown && datetime.weekday !== 7
      ? datetime.startOf('week').minus({ days: 1 }).toJSDate()
      : date;

  const startOfWeekAtCalendarEnd = props.totalWeeksShown
    ? datetime.weekday !== 7
      ? datetime
          .plus({ weeks: props.totalWeeksShown - 1 })
          .startOf('week')
          .minus({ days: 1 })
          .toJSDate()
      : datetime.plus({ weeks: props.totalWeeksShown - 1 }).toJSDate()
    : undefined;

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        p: 1,
        flexDirection: {
          xs: 'column',
          md: 'row',
        },
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            mr: 2,
          }}
        >
          <Typography variant="h3">
            {format.formatDate(startOfWeekAtCalendarStart, 'MMMM')}
          </Typography>
          <Typography variant="h3">
            <Box
              sx={{
                fontWeight: 400,
                ml: 1,
              }}
            >
              {format.formatDate(startOfWeekAtCalendarStart, 'y')}
            </Box>
          </Typography>
        </Box>
        {startOfWeekAtCalendarEnd && startOfWeekAtCalendarEnd.getMonth() !== date.getMonth() ? (
          <>
            <Box mr={2}>&mdash;</Box>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                mr: 2,
              }}
            >
              <Typography variant="h3">
                {format.formatDate(startOfWeekAtCalendarEnd, 'MMMM')}
              </Typography>
              <Typography variant="h3">
                <Box
                  sx={{
                    fontWeight: 400,
                    ml: 1,
                  }}
                >
                  {format.formatDate(startOfWeekAtCalendarEnd, 'y')}
                </Box>
              </Typography>
            </Box>
          </>
        ) : null}
      </Box>
      {!props.hideControls ? (
        <Box
          sx={{
            alignItems: 'center',
            flexWrap: 'wrap',
            display: 'flex',
            m: -1,
          }}
        >
          <Box sx={{ alignItems: 'center', display: 'flex' }}>
            {loading && <CircularProgress size="20px" className={styles.progressIcon} />}
            <IconFontButton onClick={onDatePrev} disabled={loading} icon={faChevronLeft} />
            <IconFontButton onClick={onDateNext} disabled={loading} icon={faChevronRight} />
          </Box>
          {/*
        <Box
          sx={{
            ml: {
              xs: 'auto',
              md: 1,
            },
            m: 1,
            minWidth: 120,
          }}
        >
          <TextField
            label="View"
            name="view"
            onChange={handleViewChange}
            select
            size="small"
            value={view}
            SelectProps={{ native: true }}
          >
            {viewOptions.map((viewOption) => {
              return (
                <option key={viewOption.value} value={viewOption.value}>
                  {viewOption.label}
                </option>
              );
            })}
          </TextField>
        </Box>
*/}
        </Box>
      ) : null}
    </Box>
  );
};
