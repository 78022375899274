import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import OneSignal from 'react-onesignal';
import config from '../extras/config';
import useAuth from '../hooks/useAuth';

export default async function setupOneSignal() {
  await OneSignal.init({
    appId: config.oneSignal.appId,
    allowLocalhostAsSecureOrigin: true,
    serviceWorkerPath: './assets/service-workers/OneSignalSDKWorker.js',
    serviceWorkerParam: { scope: '/assets/service-workers/' },
  });
}

type NotificationsContextState = {
  isInitialized: boolean;
  userIsSubscribed: boolean;
  getUserSubscriptionStatus: () => void;
  showSubscriptionModal: () => void;
  registerCallback: (event: string, callback?: (val: any) => void) => () => void;
};

export const NotificationsContext = React.createContext<NotificationsContextState>({
  isInitialized: false,
  userIsSubscribed: false,
  showSubscriptionModal: () => {},
  getUserSubscriptionStatus: () => {},
  registerCallback: () => () => {},
});

export const NotificationsProvider = (props: { children: React.ReactNode }) => {
  const auth = useAuth();
  const [initialized, setInitialized] = useState(false);
  const [userIsSubscribed, setUserIsSubscribed] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  async function showSubscriptionModal() {
    if (window.Notification && window.Notification.permission === 'denied') {
      enqueueSnackbar(
        "Notifications have been disabled; please reset your browser's notification settings to re-enable",
        { variant: 'error' },
      );
      return;
    }

    OneSignal.showSlidedownPrompt({ force: true, forceSlidedownOverNative: true });
  }

  async function getUserSubscriptionStatus() {
    return await OneSignal.isPushNotificationsEnabled();
  }

  function registerCallback(event: string, callback?: (val: any) => void): () => void {
    if (!callback) return () => {};

    OneSignal.on(event, callback);

    return () => OneSignal.off(event, callback);
  }

  useEffect(() => {
    async function init() {
      if (config.oneSignal.appId) {
        await setupOneSignal();

        const isPushEnabled = await getUserSubscriptionStatus();

        setUserIsSubscribed(isPushEnabled);

        setInitialized(true);
      }
    }

    init();
  }, [auth.user?.userId]);

  useEffect(() => {
    async function handleSubscriptionChange(isSubscribed: boolean) {
      try {
        if (!auth?.user) return;

        if (isSubscribed) {
          await OneSignal.setExternalUserId(auth.user.userId.toString());
        } else {
          await OneSignal.removeExternalUserId();
        }

        setUserIsSubscribed(isSubscribed);
      } catch (e) {
        console.error({ e });
      }
    }

    if (!initialized) return;

    if (auth.user) {
      OneSignal.on('subscriptionChange', handleSubscriptionChange);

      return () => {
        OneSignal.off('subscriptionChange', handleSubscriptionChange);
      };
    } else {
      OneSignal.removeExternalUserId();

      setUserIsSubscribed(false);

      return () => {};
    }
  }, [auth.user?.userId, initialized]);

  return (
    <NotificationsContext.Provider
      value={{
        isInitialized: initialized,
        userIsSubscribed,
        showSubscriptionModal,
        getUserSubscriptionStatus,
        registerCallback,
      }}
    >
      {props.children}
    </NotificationsContext.Provider>
  );
};
