import { LevelType } from '@workflow-nx/common';
import { Scene } from 'babylonjs';
import { SelectedItemType } from './MoveImplantView';
import VisibilityView from './VisibilityView';

function getMeshName(selectedItem: SelectedItemType, levelType: LevelType) {
  const [superior, inferior] = levelType.split('_');

  switch (selectedItem) {
    case 'SUPERIOR':
      return superior;
    case 'INFERIOR':
      return inferior;
    case 'SCREWS':
      return `${levelType}_IMPLANT_SCREW`;
    case 'SCREW_GUIDE':
      return `${levelType}_IMPLANT_SCREW_GUIDE`;
    case 'PELVIS':
      return `PELVIS`;
    case 'INSTRUMENT':
      return `${levelType}_IMPLANT_INSTRUMENT`;
  }

  return null;
}

function getLabel(selectedItem: SelectedItemType, levelType: LevelType) {
  const [superior, inferior] = levelType.split('_');

  switch (selectedItem) {
    case 'SUPERIOR':
      return `Superior (${superior})`;
    case 'INFERIOR':
      return `Inferior (${inferior})`;
    case 'SCREWS':
      return 'Screws';
    case 'SCREW_GUIDE':
      return 'Screw Guide';
    case 'PELVIS':
      return 'Pelvis';
    case 'INSTRUMENT':
      return 'Instrument';
  }
  return '';
}

export function BasicControlsView(props: {
  selectedItem: SelectedItemType;
  levelType: LevelType;
  scene: Scene;
}) {
  return (
    <VisibilityView
      scene={props.scene}
      levelType={props.levelType}
      label={getLabel(props.selectedItem, props.levelType)}
      meshName={getMeshName(props.selectedItem, props.levelType) ?? ''}
    />
  );
}
