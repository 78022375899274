import config from '../extras/config';

export enum FeatureFlag {
  angledInstrumentsEnabled = 'angledInstrumentsEnabled',
  cervicalSupportEnabled = 'cervicalSupportEnabled',
  cyborgV2Enabled = 'cyborgV2Enabled',
  fastImplantCuttingEnabled = 'fastImplantCuttingEnabled',
  implantSizeExclusionEnabled = 'implantSizeExclusionEnabled',
  reusableInstrumentsEnabled = 'reusableInstrumentsEnabled',
  caseReportAutomationEnabled = 'caseReportAutomationEnabled',
}

export function isTlifCOrientationAvailable(tlifCOrientationStartDate?: Date) {
  if (!tlifCOrientationStartDate) return config.featureFlags.tlifCOrientation;

  return config.featureFlags.tlifCOrientation && tlifCOrientationStartDate < new Date();
}
