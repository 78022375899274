import { useQuery } from '@apollo/client';
import { Box, Button, Divider, FormControlLabel, FormGroup, Switch } from '@mui/material';
import {
  CaseCancellationListType,
  CaseSpineType,
  CaseStageType,
  CaseType,
  IUser,
  PartType,
  UserRoleType,
  UserStatusType,
  format,
} from '@workflow-nx/common';
import { Dispatch, useEffect, useState } from 'react';
import { OrganizationSelect } from '../../../components/OrganizationSelect';
import { Select } from '../../../components/Select';
import TagSelect from '../../../components/TagSelect';
import { UserAutocomplete } from '../../../components/UserAutocomplete';
import { FIND_USERS_BY_ROLE } from '../../../gql';
import useAuth from '../../../hooks/useAuth';
import { FeatureFlag } from '../../../utils/featureFlags';
import { groupUsersByRole } from '../../../utils/user';
import { ListCasesActionType } from './ListCases.reducer';

export function CaseTableFilter(props: {
  state: {
    assignedTo?: string;
    organization?: string;
    surgeon?: string;
    fieldRep?: string;
    stage?: CaseStageType[];
    caseTags?: string[];
    caseType?: CaseType[];
    caseSpineType?: CaseSpineType[];
    planVersion?: number;
    caseCancellationType?: CaseCancellationListType;
    partTypes?: PartType[];
    showOnlyOnHoldCases?: boolean;
    showOnlyDemoCases?: boolean;
  };
  loading: boolean;
  dispatch: Dispatch<ListCasesActionType>;
  onChange: () => void;
}) {
  const { hasFeatureFlag } = useAuth();
  const [surgeons, setSurgeons] = useState<IUser[]>([]);
  const [assignees, setAssignees] = useState<IUser[]>([]);
  const [fieldReps, setFieldReps] = useState<IUser[]>([]);
  const { data } = useQuery(FIND_USERS_BY_ROLE);

  useEffect(() => {
    const users = data?.users;
    if (users?.users?.length > 0) {
      const { surgeons, assignees, fieldReps } = groupUsersByRole(users?.users);

      const activeAssignees = assignees.filter((u) => u.status === UserStatusType.Active);

      setAssignees(activeAssignees);
      setSurgeons(surgeons);
      setFieldReps(fieldReps);
    }
  }, [data?.users]);

  const handleCaseStageSelectAll = () => {
    props.dispatch({
      type: 'CASE_STAGE_CHANGED',
      data: [
        CaseStageType.Open,
        CaseStageType.Segmentation,
        CaseStageType.Planning,
        CaseStageType.Proposed,
        CaseStageType.Design,
        CaseStageType.QaReview,
        CaseStageType.Production,
        CaseStageType.Ready,
        CaseStageType.Complete,
      ],
    });
    props.onChange();
  };

  const handleCaseStageClearAll = () => {
    props.dispatch({
      type: 'CASE_STAGE_CHANGED',
      data: [],
    });
    props.onChange();
  };

  const handlePartTypesClearAll = () => {
    props.dispatch({
      type: 'CASE_PART_TYPES_CHANGED',
      data: [],
    });
    props.onChange();
  };

  return (
    <Box display={'flex'} flexDirection={'column'} gap={2}>
      <Box>
        <UserAutocomplete
          name={'assignedId'}
          label={'Assigned To'}
          value={props.state.assignedTo ? props.state.assignedTo : undefined}
          onChange={(newVal) => {
            props.dispatch({
              type: 'ASSIGNED_TO_CHANGED',
              data: newVal || '',
            });
            props.onChange();
          }}
          fullWidth
          users={assignees}
          startWithLastName
          disabled={props.loading}
        />
      </Box>
      <Box>
        <OrganizationSelect
          value={props.state.organization ?? ''}
          onChange={(event) => {
            props.dispatch({
              type: 'ORGANIZATION_CHANGED',
              data: event.target.value,
            });
          }}
          disabled={props.loading}
        />
      </Box>
      <Box>
        <UserAutocomplete
          name={'surgeon'}
          label={'Surgeon'}
          value={props.state.surgeon ? props.state.surgeon?.toString() : undefined}
          onChange={(newVal) => {
            props.dispatch({
              type: 'SURGEON_CHANGED',
              data: newVal || '',
            });
            props.onChange();
          }}
          fullWidth
          users={surgeons}
          startWithLastName
          disabled={props.loading}
        />
      </Box>
      <Box>
        <UserAutocomplete
          name={'fieldRep'}
          label={`${format.formatUserRole(UserRoleType.FieldRep)}`}
          value={props.state.fieldRep ? props.state.fieldRep?.toString() : undefined}
          onChange={(newVal) => {
            props.dispatch({
              type: 'FIELD_REP_CHANGED',
              data: newVal || '',
            });
            props.onChange();
          }}
          fullWidth
          users={fieldReps}
          startWithLastName
          disabled={props.loading}
        />
      </Box>
      <Box>
        <Select
          name={'stage'}
          label={'Case Stage'}
          multiple={true}
          hideNone={true}
          value={props.state?.stage ?? []}
          onChange={(event) => {
            props.dispatch({
              type: 'CASE_STAGE_CHANGED',
              data: event.target.value,
            });
            props.onChange();
          }}
          menuItems={[
            { key: CaseStageType.Open, value: 'Open' },
            { key: CaseStageType.Segmentation, value: 'Segmentation' },
            { key: CaseStageType.Planning, value: 'Planning' },
            { key: CaseStageType.Proposed, value: 'Proposed' },
            { key: CaseStageType.Design, value: 'Design' },
            { key: CaseStageType.QaReview, value: 'QaReview' },
            { key: CaseStageType.Production, value: 'Production' },
            { key: CaseStageType.Ready, value: 'Ready' },
            { key: CaseStageType.Complete, value: 'Complete' },
          ]}
          disabled={props.loading}
          fullWidth={true}
        />
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <Button fullWidth={true} size={'small'} onClick={handleCaseStageSelectAll}>
            Reset
          </Button>
          <Box height={15} mx={0.5}>
            <Divider orientation={'vertical'} />
          </Box>
          <Button fullWidth={true} size={'small'} onClick={handleCaseStageClearAll}>
            Clear
          </Button>
        </Box>
      </Box>
      <Box>
        <Select
          name={'caseType'}
          label={'Case Types'}
          value={
            props.state?.caseType
              ? (props.state?.caseType as unknown as string)
              : ([] as unknown as string)
          }
          onChange={(event) => {
            props.dispatch({
              type: 'CASE_TYPE_CHANGED',
              data: event.target.value,
            });
            props.onChange();
          }}
          multiple={true}
          hideNone={true}
          menuItems={[CaseType.Live, CaseType.Draft].map((ct) => ({
            key: ct,
            value: format.formatCaseType(ct),
          }))}
          disabled={props.loading}
          fullWidth={true}
        />
      </Box>
      <Box>
        <Select
          name={'caseCancellationType'}
          label={'Lost Opportunity'}
          value={props.state?.caseCancellationType?.toString()}
          onChange={(event) => {
            props.dispatch({
              type: 'CASE_CANCELLATION_TYPE_CHANGED',
              data: event.target.value,
            });
            props.onChange();
          }}
          hideNone={true}
          menuItems={[
            { key: CaseCancellationListType.None, value: 'None' },
            { key: CaseCancellationListType.All, value: 'All' },
            { key: CaseCancellationListType.Cancelled, value: 'Cancelled' },
            { key: CaseCancellationListType.Missed, value: 'Missed' },
            { key: CaseCancellationListType.Invalid, value: 'Invalid' },
          ]}
          disabled={props.loading}
          fullWidth={true}
        />
      </Box>
      <Box>
        <TagSelect
          label={'Case Tags'}
          value={props.state?.caseTags ? props.state?.caseTags : []}
          onChange={(newValue) => {
            props.dispatch({
              type: 'CASE_TAGS_CHANGED',
              data: newValue,
            });
            props.onChange();
          }}
          disabled={props.loading}
          fullWidth={true}
        />
      </Box>
      <Box>
        <Select
          name={'partTypes'}
          label={'Part Types'}
          multiple={true}
          hideNone={true}
          value={
            props.state?.partTypes
              ? (props.state?.partTypes as unknown as string)
              : ([] as unknown as string)
          }
          onChange={(event) => {
            props.dispatch({
              type: 'CASE_PART_TYPES_CHANGED',
              data: event.target.value,
            });
            props.onChange();
          }}
          menuItems={Object.values(PartType).map((partType) => ({
            key: partType,
            value: format.formatPartType(partType),
          }))}
          disabled={props.loading}
          fullWidth={true}
        />
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <Button fullWidth={true} size={'small'} onClick={handlePartTypesClearAll}>
            Clear
          </Button>
        </Box>
      </Box>
      <Box>
        <Select
          name={'planVersion'}
          label={'Plan Version'}
          hideNone={false}
          value={props.state?.planVersion?.toString()}
          onChange={(event) => {
            props.dispatch({
              type: 'PLAN_VERSION_CHANGED',
              data: event.target.value,
            });
            props.onChange();
          }}
          menuItems={[
            {
              key: '1',
              value: '1 (nTop)',
            },
            {
              key: '2',
              value: '2 (IDE)',
            },
          ]}
          disabled={props.loading}
          fullWidth={true}
        />
      </Box>

      {hasFeatureFlag?.(FeatureFlag.cervicalSupportEnabled) ? (
        <Box>
          <Select
            name={'caseSpineType'}
            label={'Spine Type'}
            hideNone={false}
            value={props.state?.caseSpineType}
            onChange={(event) => {
              props.dispatch({
                type: 'CASE_SPINE_TYPE_CHANGED',
                data: event.target.value,
              });
              props.onChange();
            }}
            menuItems={Object.values(CaseSpineType).map((spineType) => ({
              key: spineType,
              value: format.formatSpineType(spineType),
            }))}
            disabled={props.loading}
            fullWidth={true}
          />
        </Box>
      ) : null}
      <Box>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={props.state.showOnlyOnHoldCases}
                onChange={(event) => {
                  props.dispatch({
                    type: 'CASE_HOLD_CHANGED',
                    data: event.target.checked,
                  });
                  props.onChange();
                }}
              />
            }
            label="On-hold only"
          />
        </FormGroup>
      </Box>
      <Box>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={props.state.showOnlyDemoCases}
                onChange={(event) => {
                  props.dispatch({
                    type: 'DEMO_CASES_ONLY_CHANGED',
                    data: event.target.checked,
                  });
                  props.onChange();
                }}
              />
            }
            label="Demo cases only"
          />
        </FormGroup>
      </Box>
      <Box>
        <Divider variant={'middle'} />
      </Box>
      <Box>
        <Box textAlign={'center'}>
          <Button
            onClick={() => {
              props.dispatch({ type: 'RESET_FILTERS' });
              props.onChange();
            }}
          >
            Reset All Filters
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
