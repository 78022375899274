import React, { useReducer, useState } from 'react';
import { Box, Card, CardContent, Container, Grid, useTheme } from '@mui/material';
import Page from '../../../components/Page';
import OrganizationListTable from './OrganizationListTable';
import { useQuery } from '@apollo/client';
import { CustomAppBar } from '../../../components/CustomAppBar';
import { CreateOrganizationDialog } from '../components/CreateOrganizationDialog';
import { FIND_ORGANIZATIONS_AND_PRICE_LEVELS } from '../../../gql';
import useAuth from '../../../hooks/useAuth';
import { Permission } from '@workflow-nx/common';
import { Button } from '@workflow-nx/ui';
import { Heading } from '../../../components/Heading';
import SearchInput from '../../../components/SearchInput';
import { Alert } from '@mui/material';
import { ListOrganizationsReducer, defaultOrganizationTypes } from './ListOrganizations.reducer';
import { OrganizationTableFilter } from './OrganizationTableFilter';
import { Globals } from '../../../layouts/DashboardLayout';

const initialState: any = {
  orderBy: { name: 'asc' },
  organizationType: '',
  search: '',
  regionId: null,
  pageNumber: 0,
  pageSize: Globals.DefaultListPageSize,
};

const ListOrganizationsView = () => {
  const auth = useAuth();
  const theme = useTheme();
  const { data, refetch, loading } = useQuery(FIND_ORGANIZATIONS_AND_PRICE_LEVELS, {
    fetchPolicy: 'network-only',
    variables: {
      orderBy: initialState.orderBy,
      search: '',
      organizationTypeFilter: initialState.organizationType
        ? [initialState.organizationType]
        : defaultOrganizationTypes,
      regionId: initialState.regionId,
      skip: initialState.pageNumber,
      take: initialState.pageSize,
    },
  });

  const [state, dispatch] = useReducer(ListOrganizationsReducer(refetch), initialState);

  const [showCreateOrganizationDialog, setShowCreateOrganizationDialog] = useState(false);

  return (
    <Page title={'Organizations'}>
      <Container maxWidth={false}>
        <CustomAppBar
          title={`Organizations`}
          actions={
            auth?.hasPermission?.([Permission.ManageOrganization])
              ? [
                  <Button
                    variant={'contained'}
                    onClick={() => setShowCreateOrganizationDialog(true)}
                    label={'Create Organization'}
                  />,
                ]
              : []
          }
        />

        <Box>
          <Grid container>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Box display={'flex'} alignItems={'center'} height={40} mb={1}>
                    <Heading label={'Filters'} />
                  </Box>
                  <Card>
                    <CardContent>
                      <OrganizationTableFilter
                        state={state}
                        dispatch={dispatch}
                        loading={loading}
                      />
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={10}>
                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    mb={1}
                  >
                    <Heading label={`Results (${data?.organizations?.count ?? 0} institutions)`} />
                    <Box width={250} bgcolor={theme.palette.background.paper}>
                      <SearchInput
                        value={state.search ?? ''}
                        onChange={(value: string) =>
                          dispatch({ type: 'SEARCH_CHANGED', data: value })
                        }
                      />
                    </Box>
                  </Box>
                  <Box>
                    <Card>
                      <CardContent style={{ height: 'calc(100vh - 228px)' }}>
                        {!loading && data?.organizations?.count > 0 ? (
                          <OrganizationListTable
                            page={state.pageNumber}
                            rowsPerPage={state.pageSize}
                            totalCaseCount={data?.organizations?.count ?? 0}
                            organizations={data?.organizations?.organizations}
                            orderBy={state.orderBy}
                            dispatch={dispatch}
                            loading={loading}
                          />
                        ) : !loading ? (
                          <Alert severity={'warning'}>
                            No organizations match the filter criteria.{' '}
                          </Alert>
                        ) : null}
                      </CardContent>
                    </Card>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <CreateOrganizationDialog
        open={showCreateOrganizationDialog}
        onCreate={() => {
          setShowCreateOrganizationDialog(false);
          refetch();
        }}
        onClose={() => {
          setShowCreateOrganizationDialog(false);
          refetch();
        }}
      />
    </Page>
  );
};

export default ListOrganizationsView;
