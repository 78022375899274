import { ApolloQueryResult } from '@apollo/client/core/types';
import {
  CaseCancellationListType,
  CaseStageType,
  CaseType,
  PartType,
  PatientGender,
} from '@workflow-nx/common';

export type ListCompletedCasesActionType = {
  type: string;
  data?: any;
};

export type ListPostOpCasesStateType = {
  orderBy?: { [key: string]: 'asc' | 'desc' };
  search?: string;
  surgeon?: string;
  showSixWeekAnalysisCompleted?: boolean;
  showSixWeekImagingCompleted?: boolean;
  showHasPostOpData?: boolean;
  levelPartFilter?: PartType[];
  patientGenderFilter?: PatientGender[];
  patientAgeFilter?: number[];
  caseTags?: string[];
  pageSize?: number;
  pageNumber?: number;
};

export const PATIENT_AGE_RANGE_MIN = 0;

export const PATIENT_AGE_RANGE_MAX = 100;

export const ListPostOpCasesReducer =
  (refetch: (variables?: Partial<any>) => Promise<ApolloQueryResult<any>>) =>
  (
    state: ListPostOpCasesStateType,
    action: ListCompletedCasesActionType,
  ): ListPostOpCasesStateType => {
    let updatedState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
      case 'refetch':
        refetch();
        break;
      case 'RESET_FILTERS':
        updatedState = {
          showSixWeekAnalysisCompleted: false,
          showSixWeekImagingCompleted: false,
          showHasPostOpData: false,
          search: '',
          surgeon: '',
          orderBy: { surgeryDate: 'asc' },
          patientGenderFilter: [],
          patientAgeFilter: [PATIENT_AGE_RANGE_MIN, PATIENT_AGE_RANGE_MAX],
          levelPartFilter: [],
        };
        break;
      case 'SHOW_SIX_WEEK_ANALYSIS_COMPLETED_CHANGED':
        updatedState.showSixWeekAnalysisCompleted = action.data;
        break;
      case 'SHOW_SIX_WEEK_IMAGING_COMPLETED_CHANGED':
        updatedState.showSixWeekImagingCompleted = action.data;
        break;
      case 'SHOW_HAS_POST_OP_DATA_CHANGED':
        updatedState.showHasPostOpData = action.data;
        break;
      case 'PATIENT_GENDER_CHANGED':
        updatedState.patientGenderFilter = action.data;
        break;
      case 'PART_TYPES_CHANGED':
        updatedState.levelPartFilter = action.data;
        break;
      case 'PATIENT_AGE_CHANGED':
        updatedState.patientAgeFilter = action.data;
        break;
      case 'SEARCH_CHANGED':
        updatedState.search = action.data;
        break;
      case 'SURGEON_CHANGED':
        updatedState.surgeon = action.data;
        break;
      case 'ORDER_BY_CHANGED':
        updatedState.orderBy = action.data;
        break;
      case 'CASE_TAGS_CHANGED':
        updatedState.caseTags = action.data;
        break;
      case 'UPDATE_PAGINATION':
        updatedState.pageSize = action.data.pageSize;
        updatedState.pageNumber = action.data.pageNumber;
        break;
    }

    let orderBy = updatedState.orderBy;

    if (Object.keys(orderBy)[0] === 'surgeonId') {
      orderBy = { surgeonUser: { lastName: orderBy['surgeonId'] } };
    }

    if (Object.keys(orderBy)[0] === 'assignedId') {
      orderBy = { assignedUser: { lastName: orderBy['assignedId'] } };
    }

    if (Object.keys(orderBy)[0] === 'fieldRepId') {
      orderBy = { fieldRepUser: { lastName: orderBy['fieldRepId'] } };
    }

    if (Object.keys(orderBy)[0] === 'organizationId') {
      orderBy = { organization: { name: orderBy['organizationId'] } };
    }

    const variables = {
      orderBy,
      search: updatedState.search,
    };

    const [patientAgeMin, patientAgeMax] = updatedState.patientAgeFilter;

    let patientAgeFilter: { min?: number; max?: number } = {
      min: patientAgeMin,
      max: patientAgeMax,
    };

    // Account for ages that do not fall within the range
    if (patientAgeMin === PATIENT_AGE_RANGE_MIN && patientAgeMax === PATIENT_AGE_RANGE_MAX) {
      patientAgeFilter = {};
    }

    refetch({
      ...variables,
      caseCancellationTypeFilter: [CaseCancellationListType.None],
      caseTypeFilter: [CaseType.Live],
      stageFilter: [CaseStageType.Complete],
      showSixWeekAnalysisCompleted: updatedState.showSixWeekAnalysisCompleted,
      showSixWeekImagingCompleted: updatedState.showSixWeekImagingCompleted,
      showHasPostOpData: updatedState.showHasPostOpData,
      patientGenderFilter: updatedState.patientGenderFilter,
      levelPartFilter: updatedState.levelPartFilter,
      surgeonFilter: updatedState.surgeon ? [Number(updatedState.surgeon)] : undefined,
      patientAgeFilter,
      showHasCompletedLevels: true,
      tagsFilter: (updatedState.caseTags || []).map((tagId: string) => Number(tagId)),
      take: updatedState.pageSize,
      skip:
        updatedState.pageSize && updatedState.pageNumber
          ? updatedState.pageSize * updatedState.pageNumber
          : undefined,
    });

    return updatedState;
  };
