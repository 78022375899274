import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import React, { Dispatch, useState } from 'react';
import { CaseSpineType, ICase, Permission } from '@workflow-nx/common';
import { CaseViewActionType } from '../CaseView';
import useAuth from '../../../../hooks/useAuth';
import { EditPatientRecordsDialog } from '../EditPatientRecordsDialog';

type PatientTabViewProps = {
  activeCase: ICase;
  dispatch: Dispatch<CaseViewActionType>;
};

export function PatientRecordsView({ activeCase, dispatch }: PatientTabViewProps) {
  const [openEditPatientDialog, setOpenEditPatientDialog] = useState(false);
  const patientRecord = activeCase?.patient.patientRecord;
  const auth = useAuth();
  const isPatientEditable = auth?.hasPermission?.([Permission.EditCase, Permission.ManageCase]);
  const emptyValue: JSX.Element = <>&mdash;</>;

  const handleUpdate = (shouldUpdate: boolean) => {
    if (shouldUpdate) {
      dispatch({ type: 'refetch' });
    }
    setOpenEditPatientDialog(false);
  };

  const patientRecordsData = [
    activeCase?.spineType === CaseSpineType.Lumbar
      ? { title: 'Pelvic Incidence', data: patientRecord?.pelvicIncidence }
      : { title: 'Slope of Line of Sight', data: patientRecord?.slopeOfLineOfSight },
    { title: 'Lumbar Lordosis', data: patientRecord?.lumbarLordosis },
    { title: 'Lumbar Coronal Cobb', data: patientRecord?.lumbarCoronalCobb },
    { title: 'SVA', data: patientRecord?.sagittalVerticalAxis },
    {
      title: 'Coronal Balance',
      data: patientRecord?.coronalBalance,
    },
    { title: 'BMI', data: patientRecord?.bmi },
    { title: 'ODI', data: patientRecord?.odi },
    {
      title: 'Bone Density',
      data: patientRecord?.boneDensity,
    },
    { title: 'VAS (back)', data: patientRecord?.vasBack },
    { title: 'VAS (leg)', data: patientRecord?.vasLeg },

    {
      title: 't-score (hip)',
      data: patientRecord?.tScoreHip,
    },
    { title: 't-score (spine)', data: patientRecord?.tScoreSpine },
  ];

  return (
    <>
      <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>
        {patientRecordsData.map((element, index) => {
          return (
            <Box key={index}>
              <Box
                display={'flex'}
                justifyContent="space-between"
                alignContent={'center'}
                bgcolor={''}
                p={0.5}
              >
                <Typography variant={'body1'} color={'textSecondary'}>
                  {element.title}
                </Typography>
                <Typography variant={'body1'} color={'textPrimary'}>
                  {element.data ? element.data : emptyValue}
                </Typography>
              </Box>
              {(index + 1) % 4 === 0 && index + 1 !== patientRecordsData.length ? (
                <Box pt={1} pb={1}>
                  <Divider />
                </Box>
              ) : null}
            </Box>
          );
        })}
        {isPatientEditable ? (
          <Grid container item xs={12} justifyContent={'center'}>
            <Button variant={'contained'} onClick={() => setOpenEditPatientDialog(true)}>
              Edit
            </Button>
          </Grid>
        ) : null}
      </Box>
      <EditPatientRecordsDialog
        open={openEditPatientDialog}
        activeCase={activeCase}
        onClose={handleUpdate}
      />
    </>
  );
}
