import React, { Dispatch, useEffect, useState } from 'react';
import { Box, Card, CardContent, Skeleton, Stack, Typography } from '@mui/material';
import {
  CaseStageType,
  caseUtils,
  ICase,
  ICaseResult,
  IPlan,
  LevelResultNotUsedReason,
  LevelType,
  PartType,
  Permission,
  PlanStatusType,
} from '@workflow-nx/common';
import { useMutation, useQuery } from '@apollo/client';
import {
  FIND_CASE_RESULTS_BY_CASE_ID,
  FIND_PLAN_BY_STATUS,
  UPDATE_CASE_RESULTS,
} from '../../../../gql';
import { useSnackbar } from 'notistack';
import { useConfirm } from 'material-ui-confirm';
import useAuth from '../../../../hooks/useAuth';
import ActionButton from '../../../../components/ActionButton';
import { Alert } from '@mui/material';
import { CaseViewActionType } from '../CaseView';
import {
  CaseResultsFormFields,
  CaseResultsFormValuesType,
  CaseResultsType,
  getCaseResultsSchema,
} from '../CaseResultsFormFields';
import { UncompleteCaseDialog } from './UncompleteCaseDialog';
import { Control, FormProvider, Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ImplantSizeExclusionAlert } from '../../../../components/ImplantSizeExclusionAlert';

const allLevelTypes = Object.values(LevelType);

export function CaseCompleteTabView(props: {
  activeCase: ICase;
  dispatch: Dispatch<CaseViewActionType>;
}) {
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [updateCaseResults, { loading: loadingUpdateCaseResults }] =
    useMutation(UPDATE_CASE_RESULTS);
  const confirm = useConfirm();
  const [openUncompleteCaseDialog, setOpenUncompleteCaseDialog] = useState(false);
  const [approvedPlan, setApprovedPlan] = useState<IPlan>();

  useQuery(FIND_PLAN_BY_STATUS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      caseId: props.activeCase.caseId,
      status: [PlanStatusType.Approved],
    },
    onCompleted: (data) => {
      setApprovedPlan(data.planByStatus);
    },
  });

  const { loading: loadingCaseResultsByCaseId } = useQuery(FIND_CASE_RESULTS_BY_CASE_ID, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      caseId: props.activeCase.caseId,
    },
    onCompleted: (data) => {
      if (data.caseResultsByCaseId) {
        const levels = props.activeCase.levels;
        const initialValues: CaseResultsType = allLevelTypes.reduce(
          (prev, currLevel) => ({
            ...prev,
            [currLevel]: {
              size: '',
              part: (levels[currLevel] as PartType) || 'NONE',
              result: '',
              notUsedReasons: [],
              exceptionReason: '',
              screwLengthLeft: '',
              screwLengthMiddle: '',
              screwLengthRight: '',
            },
          }),
          {} as CaseResultsType,
        );
        data.caseResultsByCaseId.forEach((caseResult: ICaseResult) => {
          initialValues[caseResult.level] = {
            size: caseResult.size ?? '',
            part: caseResult.part,
            result: caseResult.result,
            notUsedReasons: caseResult.notUsedReasons ?? [],
            exceptionReason: caseResult.exceptionReason,
            screwLengthLeft: caseResult?.screwLengthLeft ?? '',
            screwLengthMiddle: caseResult?.screwLengthMiddle ?? '',
            screwLengthRight: caseResult?.screwLengthRight ?? '',
          };
        });

        setInitialValues(initialValues);
      }
    },
  });

  const [initialValues, setInitialValues] = useState<CaseResultsType>(
    allLevelTypes.reduce(
      (prev, currLevel) => ({
        ...prev,
        [currLevel]: {
          size: '',
          part: '',
          result: '',
          notUsedReasons: [],
          exceptionReason: '',
          screwsUsed: '',
          screwLengthUsed: '',
        },
      }),
      {} as CaseResultsType,
    ),
  );

  const formMethods = useForm<CaseResultsFormValuesType>({
    defaultValues: initialValues ?? undefined,
    resolver: yupResolver(
      getCaseResultsSchema(props.activeCase),
    ) as unknown as Resolver<CaseResultsFormValuesType>,
  });

  const { control, handleSubmit, reset } = formMethods;

  const handleSubmitForm: SubmitHandler<CaseResultsFormValuesType> = async (values) => {
    try {
      await confirm({
        title: 'Update case results?',
        description: 'Are you sure you want to update the results of this case?',
      });

      const results = Object.entries(values)
        .map(([key, value]) => {
          const notUsedReasons = value.notUsedReasons?.filter((reason: string) =>
            Boolean(
              Object.values(LevelResultNotUsedReason).includes(reason as LevelResultNotUsedReason),
            ),
          );
          return {
            level: key,
            size: value.size === '' ? undefined : value.size,
            part: value.part,
            result: value.result,
            notUsedReasons: notUsedReasons.length ? notUsedReasons : undefined,
            exceptionReason: value.exceptionReason,
            screwLengthLeft: value?.screwLengthLeft === '' ? undefined : value.screwLengthLeft,
            screwLengthMiddle:
              value?.screwLengthMiddle === '' ? undefined : value.screwLengthMiddle,
            screwLengthRight: value?.screwLengthRight === '' ? undefined : value.screwLengthRight,
          };
        })
        .filter((r) => caseUtils.isValidLevelPartType(r.part));

      await updateCaseResults({
        variables: {
          caseId: props.activeCase.caseId,
          results: { results },
        },
      });

      enqueueSnackbar('Case results have been updated', {
        variant: 'success',
      });

      props.dispatch({ type: 'refetch' });
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Error updating the case results', {
        variant: 'error',
      });
    }
  };

  const isEditingAllowed = !!auth.hasPermission?.([Permission.ManageCase]);

  useEffect(() => {
    reset(initialValues);
  }, [initialValues]);

  return (
    <>
      <Card>
        <Stack direction={'row'} spacing={1} sx={{ mt: 2, mx: 2 }} alignItems={'center'}>
          <Typography variant={'h2'}>Case Results</Typography>
        </Stack>
        <CardContent>
          <>
            <Box my={2}>
              <Alert severity={'success'}>This case has been completed.</Alert>
              <Box mb={2} />
            </Box>
            <ImplantSizeExclusionAlert
              activeCase={props.activeCase}
              currentStage={CaseStageType.Complete}
              planId={approvedPlan?.planId}
            />
            <Stack spacing={2}>
              <Box px={5}>
                {loadingCaseResultsByCaseId ? (
                  <Stack spacing={1}>
                    {caseUtils.getValidCaseLevels(props.activeCase?.levels)?.map((level) => (
                      <Stack
                        direction={'row'}
                        spacing={3}
                        justifyContent={'center'}
                        alignItems={'center'}
                        key={`${level}-Skeleton`}
                      >
                        <Skeleton height={50} width={190} />
                        <Skeleton height={110} width={'100%'} />
                      </Stack>
                    ))}
                    <Stack
                      direction={'row'}
                      justifyContent={'center'}
                      alignItems={'center'}
                      spacing={1}
                    >
                      <Skeleton height={60} width={150} />
                      <Skeleton height={60} width={150} />
                    </Stack>
                  </Stack>
                ) : (
                  <FormProvider {...formMethods}>
                    <form>
                      {approvedPlan ? (
                        <CaseResultsFormFields
                          activeCase={props.activeCase}
                          isEditingAllowed={isEditingAllowed}
                          plan={approvedPlan}
                          control={control as unknown as Control}
                        />
                      ) : null}
                      <Box mt={4}>
                        <Box my={1} />
                        <Box display={'flex'} my={2} justifyContent={'center'} gap={2}>
                          <ActionButton
                            loading={loadingUpdateCaseResults || loadingCaseResultsByCaseId}
                            disabled={!isEditingAllowed || !!props?.activeCase?.caseCancellation}
                            variant={'outlined'}
                            onClick={handleSubmit(handleSubmitForm)}
                          >
                            Update Case Results
                          </ActionButton>
                          <ActionButton
                            loading={loadingUpdateCaseResults || loadingCaseResultsByCaseId}
                            disabled={!isEditingAllowed}
                            variant={'outlined'}
                            onClick={() => {
                              setOpenUncompleteCaseDialog(true);
                            }}
                          >
                            Un-Complete Case
                          </ActionButton>
                        </Box>
                      </Box>
                    </form>
                  </FormProvider>
                )}
              </Box>
            </Stack>
          </>
        </CardContent>
      </Card>
      <UncompleteCaseDialog
        open={openUncompleteCaseDialog}
        activeCase={props.activeCase}
        onClose={(shouldUpdate) => {
          if (shouldUpdate) {
            props.dispatch({ type: 'refetch' });
          }
          setOpenUncompleteCaseDialog(false);
        }}
      />
    </>
  );
}
