import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { format, IShipmentTrackingDetails } from '@workflow-nx/common';

const useStyles = makeStyles((theme: Theme) => ({
  gridCell: {
    backgroundColor: 'white',
    height: 50,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    whiteSpace: 'nowrap',
    '&.note': {
      gridColumn: 'span 4',
    },
  },
}));

export function ShipmentTrackingGridTableRow(props: {
  trackingEvent: IShipmentTrackingDetails;
  estDeliveryDate?: string;
  readOnly?: boolean;
}) {
  const styles = useStyles();
  const trackingEventInfo = {
    message: props?.trackingEvent?.message ?? 'Unknown Status',
    eventDate: props?.trackingEvent?.datetime
      ? format.formatDateTime(props?.trackingEvent?.datetime)
      : 'TBD',
    location: formatLocation(),
  };
  function formatLocation() {
    const city = props.trackingEvent?.tracking_location?.city ?? null;
    const state = props.trackingEvent?.tracking_location?.state ?? null;
    const zip = props.trackingEvent?.tracking_location?.zip ?? null;
    if (city || state || zip) {
      return (
        <>
          {city ?? <>&mdash;</>}
          {`, `}
          {state ?? <>&mdash;</>}
          {` `}
          {zip ?? <>&mdash;</>}
        </>
      );
    } else {
      return <>&mdash;</>;
    }
  }

  return (
    <>
      <Box className={styles.gridCell}>
        <Box display={'flex'} alignItems={'center'} height={50}>
          <Typography variant={'button'} fontSize={15}>
            <strong>{trackingEventInfo.message}</strong>
          </Typography>
        </Box>
      </Box>
      <Box className={styles.gridCell}>
        <Box display={'flex'} alignItems={'center'} height={50}>
          <Typography variant={'body1'}>{trackingEventInfo.eventDate}</Typography>
        </Box>
      </Box>
      <Box className={styles.gridCell}>
        <Box display={'flex'} alignItems={'center'} height={50}>
          <Typography variant={'body1'}>{trackingEventInfo.location}</Typography>
        </Box>
      </Box>
    </>
  );
}
