import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/client';
import { UPSERT_SETTINGS } from '../../gql';
import { IOnShapeSettings } from '@workflow-nx/common';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import { Box, Typography } from '@mui/material';
import { Button, IconFontButton, TextField } from '@workflow-nx/ui';
import { faChevronCircleDown, faChevronCircleUp } from '@fortawesome/pro-solid-svg-icons';
import * as Yup from 'yup';

function UrlEntryView(props: { name: string; label: string }) {
  return (
    <Box display={'flex'} alignItems={'center'} mb={2}>
      <Box mr={2} width={150}>
        <Typography variant={'body1'} noWrap={false} align={'right'}>
          {props.label}
        </Typography>
      </Box>
      <TextField name={props.name} />
    </Box>
  );
}

export function OnShapeForm(props: {
  onShape: IOnShapeSettings;
  onClose: (shouldUpdate: boolean) => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [upsertSettings] = useMutation(UPSERT_SETTINGS);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    if (open) {
      props.onClose(false);
    }
    setOpen(!open);
  };

  const handleSubmitForm = async (
    values: FormikValues,
    { setStatus, setSubmitting }: FormikHelpers<any>,
  ) => {
    try {
      await upsertSettings({
        variables: {
          onShape: values.onShape,
        },
      });

      setOpen(false);
      setStatus({ success: true });
      enqueueSnackbar('OnShape settings updated', {
        variant: 'success',
      });
      props.onClose(true);
    } catch (err) {
      console.error(err);
      setStatus({ success: false });
      enqueueSnackbar('An error occurred updating the OnShape settings', {
        variant: 'error',
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        <Typography variant={'h4'}>Configure OnShape</Typography>
        <Box>
          <IconFontButton
            icon={open ? faChevronCircleUp : faChevronCircleDown}
            onClick={handleClose}
          />
        </Box>
      </Box>
      {open ? (
        <Box m={1}>
          <Formik
            initialValues={{ onShape: props.onShape }}
            validationSchema={Yup.object().shape({
              onShape: Yup.object().shape({
                urls: Yup.object().shape({
                  production: Yup.object().shape({
                    ACDF: Yup.string().required(),
                    ACDF_X: Yup.string().required(),
                    ACDF_X_NO_CAM: Yup.string().required(),
                    ALIF: Yup.string().required(),
                    ALIF_X: Yup.string().required(),
                    LLIF_LEFT: Yup.string().required(),
                    LLIF_RIGHT: Yup.string().required(),
                    TLIF_C_LEFT: Yup.string().required(),
                    TLIF_C_RIGHT: Yup.string().required(),
                    TLIF_CA_LEFT: Yup.string().required(),
                    TLIF_CA_RIGHT: Yup.string().required(),
                    TLIF_O_LEFT: Yup.string().required(),
                    TLIF_O_RIGHT: Yup.string().required(),
                  }),
                }),
              }),
            })}
            onSubmit={handleSubmitForm}
            enableReinitialize={true}
          >
            {({ submitForm }) => {
              return (
                <Box>
                  <Typography variant={'body1'}>
                    <strong>OnShape V1 Implant Part Urls</strong>
                  </Typography>
                  <Box mb={2} />

                  <Typography variant={'body1'}>
                    <strong>Lumbar Implants</strong>
                  </Typography>

                  <UrlEntryView label={'ALIF'} name={'onShape.urls.production.ALIF'} />
                  <UrlEntryView label={'ALIF/X'} name={'onShape.urls.production.ALIF_X'} />
                  <UrlEntryView label={'LLIF (Left)'} name={'onShape.urls.production.LLIF_LEFT'} />
                  <UrlEntryView
                    label={'LLIF (Right)'}
                    name={'onShape.urls.production.LLIF_RIGHT'}
                  />
                  <UrlEntryView
                    label={'TLIF/C (Left)'}
                    name={'onShape.urls.production.TLIF_C_LEFT'}
                  />
                  <UrlEntryView
                    label={'TLIF/C (Right)'}
                    name={'onShape.urls.production.TLIF_C_RIGHT'}
                  />
                  <UrlEntryView
                    label={'TLIF/C Articulating (Left)'}
                    name={'onShape.urls.production.TLIF_CA_LEFT'}
                  />
                  <UrlEntryView
                    label={'TLIF/C Articulating (Right)'}
                    name={'onShape.urls.production.TLIF_CA_RIGHT'}
                  />
                  <UrlEntryView
                    label={'TLIF/O (Left)'}
                    name={'onShape.urls.production.TLIF_O_LEFT'}
                  />
                  <UrlEntryView
                    label={'TLIF/O (Right)'}
                    name={'onShape.urls.production.TLIF_O_RIGHT'}
                  />

                  <Typography variant={'body1'}>
                    <strong>Cervical Implants</strong>
                  </Typography>

                  <UrlEntryView label={'ACDF'} name={'onShape.urls.production.ACDF'} />
                  <UrlEntryView label={'ACDF/X (Cam)'} name={'onShape.urls.production.ACDF_X'} />
                  <UrlEntryView
                    label={'ACDF/X (No cam)'}
                    name={'onShape.urls.production.ACDF_X_NO_CAM'}
                  />
                  <Box mt={4} display={'flex'} justifyContent={'flex-end'}>
                    <Button
                      variant={'outlined'}
                      color={'primary'}
                      label={'Save OnShape V1 Url Settings'}
                      onClick={submitForm}
                    />
                  </Box>
                </Box>
              );
            }}
          </Formik>
        </Box>
      ) : null}
      {open ? (
        <Box m={1}>
          <Formik
            initialValues={{ onShape: props.onShape }}
            validationSchema={Yup.object().shape({
              onShape: Yup.object().shape({
                m4: Yup.object().shape({
                  urls: Yup.object().shape({
                    PELVIS: Yup.string().required(),
                    ACDF: Yup.string().required(),
                    ACDF_X: Yup.string().required(),
                    ACDF_X_NO_CAM: Yup.string().required(),
                    ALIF: Yup.string().required(),
                    ALIF_X: Yup.string().required(),
                    LLIF: Yup.string().required(),
                    TLIF_C: Yup.string().required(),
                    TLIF_CA: Yup.string().required(),
                    TLIF_O: Yup.string().required(),
                  }),
                }),
              }),
            })}
            onSubmit={handleSubmitForm}
            enableReinitialize={true}
          >
            {({ submitForm }) => {
              return (
                <Box>
                  <Typography variant={'h5'}>
                    <strong>OnShape V2 Implant Part Urls</strong>
                  </Typography>
                  <Box mb={2} />

                  <Typography variant={'body1'}>
                    <strong>Lumbar Implants</strong>
                  </Typography>

                  <UrlEntryView label={'ALIF'} name={'onShape.m4.urls.ALIF'} />
                  <UrlEntryView label={'ALIF/X'} name={'onShape.m4.urls.ALIF_X'} />
                  <UrlEntryView label={'LLIF'} name={'onShape.m4.urls.LLIF'} />
                  <UrlEntryView label={'TLIF/C'} name={'onShape.m4.urls.TLIF_C'} />
                  <UrlEntryView label={'TLIF/C (Articulating)'} name={'onShape.m4.urls.TLIF_CA'} />
                  <UrlEntryView label={'TLIF/O'} name={'onShape.m4.urls.TLIF_O'} />

                  <Typography variant={'body1'}>
                    <strong>Cervical Implants</strong>
                  </Typography>

                  <UrlEntryView label={'ACDF'} name={'onShape.m4.urls.ACDF'} />
                  <UrlEntryView label={'ACDF/X (Cam)'} name={'onShape.m4.urls.ACDF_X'} />
                  <UrlEntryView label={'ACDF/X (No Cam)'} name={'onShape.m4.urls.ACDF_X_NO_CAM'} />

                  <Typography variant={'body1'}>
                    <strong>Other</strong>
                  </Typography>

                  <UrlEntryView label={'Pelvis'} name={'onShape.m4.urls.PELVIS'} />

                  <Box mt={4} display={'flex'} justifyContent={'flex-end'}>
                    <Button
                      variant={'outlined'}
                      color={'primary'}
                      label={'Save OnShape V2 Url Settings'}
                      onClick={submitForm}
                    />
                  </Box>
                </Box>
              );
            }}
          </Formik>
        </Box>
      ) : null}
    </>
  );
}
