import React, { Dispatch } from 'react';
import { ActivityOriginType, ActivityType, format } from '@workflow-nx/common';
import { Box, Button } from '@mui/material';
import { Select } from '../../../../components/Select';
import { ActivityTableActionType, ActivityTabStateType } from './ActivityTab.reducer';
import _ from 'lodash';

export function ActivityTableFilter(props: {
  state: ActivityTabStateType;
  loading: boolean;
  dispatch: Dispatch<ActivityTableActionType>;
}) {
  const activityTypeMenuItems = Object.values(ActivityType).map((activityType: string) => {
    return {
      key: activityType,
      value: format.formatActivityType(activityType as ActivityType),
    };
  });

  const activityOriginTypeMenuItems = Object.values(ActivityOriginType).map(
    (activityOriginType: string) => {
      return {
        key: activityOriginType,
        value: format.formatActivityOriginType(activityOriginType as ActivityOriginType),
      };
    },
  );
  const sortedActivityOriginTypes = _.sortBy(activityTypeMenuItems, 'value');

  const currentActivityOriginTypeFilter = props.state?.activityOriginTypeFilter?.[0];

  const currentActivityTypeFilter = props.state?.activityTypeFilter?.[0];

  return (
    <Box display={'flex'} flexDirection={'column'} gap={2}>
      <Box>
        <Select
          name={'activityOriginType'}
          label={'Origin Type'}
          value={currentActivityOriginTypeFilter || ''}
          onChange={(event) => {
            props.dispatch({
              type: 'ACTIVITY_ORIGIN_TYPE_CHANGED',
              data: event.target.value,
            });
          }}
          menuItems={activityOriginTypeMenuItems}
          disabled={props.loading}
          fullWidth={true}
        />
      </Box>
      <Box>
        <Select
          name={'activityType'}
          label={'Activity Type'}
          value={currentActivityTypeFilter || ''}
          onChange={(event) => {
            props.dispatch({
              type: 'ACTIVITY_TYPE_CHANGED',
              data: event.target.value,
            });
          }}
          menuItems={sortedActivityOriginTypes}
          disabled={props.loading}
          fullWidth={true}
        />
      </Box>
      <Box>
        <Box textAlign={'center'}>
          <Button onClick={() => props.dispatch({ type: 'RESET_FILTERS' })}>Reset Filters</Button>
        </Box>
      </Box>
    </Box>
  );
}
