import { Box, Typography } from '@mui/material';
import { bytesToSize } from '../utils/bytesToSize';
import React from 'react';
import { IconFontButton } from '@workflow-nx/ui';
import { faEye, faTimes } from '@fortawesome/pro-light-svg-icons';

export function FileSelectedView(props: {
  label: string;
  name: string;
  size: number;
  hideRemove: boolean;
  hideDetails?: boolean;
  disabled?: boolean;
  onRemove: () => void;
  onDetails?: () => void;
}) {
  return (
    <>
      <Typography variant={'caption'} color={'textSecondary'} noWrap={true}>
        {props.label}
      </Typography>
      <Box display={'flex'} alignItems={'center'}>
        <Typography variant={'body1'} noWrap={true}>
          {props.name}
        </Typography>
        <Box flexGrow={1} />
        <Box mx={1}>
          <Typography variant={'body1'} noWrap={true}>
            {bytesToSize(props.size)}
          </Typography>
        </Box>
        <Box textAlign={'right'}>
          {!props.hideRemove && !props.disabled ? (
            <>
              <IconFontButton size={'small'} onClick={props.onRemove} icon={faTimes} />
              <Box component={'span'} pl={1} />
            </>
          ) : null}
          {!props.hideDetails ? (
            <IconFontButton size={'small'} onClick={props.onDetails} icon={faEye} />
          ) : null}
        </Box>
      </Box>
    </>
  );
}
