import { format, ImplantType, SurgeonPreferenceMeasurementType } from '@workflow-nx/common';
import { TableRow } from '@mui/material';
import { TableCell } from '../../../../components/TableCell';
import React from 'react';

export function SurgeonPreferencesMeasurementViewTableRow(props: {
  implantType: ImplantType;
  measurementType: SurgeonPreferenceMeasurementType;
  min: number;
  max: number;
}) {
  return (
    (props.implantType !== ImplantType.LLIF ||
      props.measurementType !== SurgeonPreferenceMeasurementType.ImplantPositionDistanceRange) && (
      <TableRow hover>
        <TableCell>
          {format.formatSurgeonPreferenceMeasurementType(props.measurementType)}
        </TableCell>
        <TableCell>{props.min}</TableCell>
        <TableCell>{props.max}</TableCell>
      </TableRow>
    )
  );
}
