import { useSnackbar } from 'notistack';
import React, { ReactNode } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { Box, Grid, Typography } from '@mui/material';
import * as Yup from 'yup';
import { ProgressButton, TextField } from '@workflow-nx/ui';
import CustomDialog from '../../../../components/CustomDialog';
import { DatePickerField } from '../../../../components/DatePickerField';
import { caseUtils, format, ICase, ICaseStudyDate } from '@workflow-nx/common';
import { date } from '@workflow-nx/utils';
import { Alert, AlertTitle } from '@mui/material';
import { DateTime } from 'luxon';
import { useMutation } from '@apollo/client';
import { UPDATE_CASE_STUDY_DATE_EXPIRY } from '../../../../gql';

type UpdateCaseStudyDateFormType = {
  reason: string;
  expiryDate: Date | null;
  monthsUntilExpiry: number;
};

export const getStudyDateDescription = (studyDate: ICaseStudyDate): ReactNode => {
  if (!studyDate) {
    return 'No Study Date';
  }
  const assetTypeDescription =
    studyDate?.assetType === null ? 'Manual' : format.formatAssetType(studyDate.assetType);
  const formattedExpiryDate = format.formatISODate(studyDate.expiryDate);
  const formattedStudyDate = format.formatISODate(studyDate.studyDate);

  return (
    <>
      {assetTypeDescription} study date of{' '}
      <Typography component={'span'} noWrap={true}>
        <strong>{formattedStudyDate}</strong>
      </Typography>{' '}
      will expire on{' '}
      <Typography component={'span'} noWrap={true}>
        <strong>{formattedExpiryDate}</strong>
      </Typography>
    </>
  );
};

function getStudyDate(expiryDate: Date | null, monthsUntilExpiry: number): DateTime | null {
  const _expiryDate = expiryDate ? DateTime.fromJSDate(expiryDate, { zone: 'utc' }) : null;
  const _monthsUntilExpiry = Number(monthsUntilExpiry);
  if (_expiryDate) {
    return _expiryDate.plus({ months: _monthsUntilExpiry * -1 });
  }
  return null;
}

export function UpdateCaseStudyDateExpiryDialog({
  activeCase,
  open,
  onClose,
}: {
  open: boolean;
  activeCase: ICase;
  onClose: (shouldUpdate: boolean) => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [updateCaseStudyDate] = useMutation(UPDATE_CASE_STUDY_DATE_EXPIRY);

  const handleSubmit = async (
    values: UpdateCaseStudyDateFormType,
    { setStatus, setSubmitting }: FormikHelpers<any>,
  ) => {
    try {
      const studyDate = getStudyDate(values.expiryDate, values.monthsUntilExpiry);

      if (studyDate && values.expiryDate) {
        await updateCaseStudyDate({
          variables: {
            caseId: activeCase.caseId,
            studyDate: studyDate.toISODate(),
            expiryDate: format.formatDate(values.expiryDate),
            reason: values.reason,
          },
        });

        setStatus({ success: true });
        enqueueSnackbar('Case study date updated', {
          variant: 'success',
        });

        onClose(true);
      } else {
        setStatus({ success: false });
        enqueueSnackbar(
          `An error occurred converting desired expiry date of ${values.expiryDate}`,
          {
            variant: 'error',
          },
        );
      }
    } catch (err) {
      console.error(err);
      setStatus({ success: false });
      enqueueSnackbar('An error occurred updating the case study date', {
        variant: 'error',
      });
    } finally {
      setSubmitting(false);
    }
  };

  function getMonthsUntilExpiry() {
    const caseStudyDate = activeCase?.studyDate;

    if (caseStudyDate?.monthsUntilExpiry) {
      return caseStudyDate?.monthsUntilExpiry;
    }

    return caseUtils.getMonthsUntilAssetTypeExpiry(
      caseStudyDate?.assetType,
      date.parseISO(activeCase.receivedAt as string),
    );
  }

  return (
    <Formik
      initialValues={{
        reason: activeCase?.studyDate?.manualExpiryReason ?? '',
        expiryDate: date.parseCalendarDateFromString(activeCase?.studyDate?.expiryDate) ?? null,
        monthsUntilExpiry: getMonthsUntilExpiry(),
      }}
      validationSchema={Yup.object().shape({
        expiryDate: Yup.date().required(),
        monthsUntilExpiry: Yup.number().required(),
        reason: Yup.string().required(),
      })}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({ handleSubmit, isSubmitting, values, submitForm }) => (
        <CustomDialog
          maxWidth={'sm'}
          open={open}
          title={`Update Case Study Date Expiry`}
          onClose={() => {
            onClose(false);
          }}
          positiveActionButtons={[
            <ProgressButton
              onClick={() => submitForm()}
              loading={isSubmitting}
              disabled={isSubmitting}
              label={'Update'}
            />,
          ]}
        >
          {open ? (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <Box flexGrow={1}>
                      <DatePickerField
                        disabled={isSubmitting}
                        name="expiryDate"
                        required={false}
                        label={'Desired Expiry Date'}
                      />
                    </Box>
                  </Box>
                  <Box p={1}>
                    <Typography color={'textSecondary'} variant={'body1'}>
                      The selected expiry date will result in a study date of{' '}
                      <strong>
                        {`${
                          getStudyDate(values.expiryDate, values.monthsUntilExpiry)?.toISODate() ??
                          'unknown'
                        } [${values.monthsUntilExpiry} Month(s)]`}
                      </strong>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    multiline={true}
                    disabled={isSubmitting}
                    name="reason"
                    label={'Reason'}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Alert severity={'info'}>
                    <AlertTitle>Current Study Date</AlertTitle>
                    <Typography variant={'body1'}>
                      {getStudyDateDescription(activeCase.studyDate)}
                    </Typography>
                  </Alert>
                </Grid>
              </Grid>
            </form>
          ) : null}
        </CustomDialog>
      )}
    </Formik>
  );
}
