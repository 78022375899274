import React, { useReducer, useState } from 'react';
import { Box, Card, CardContent, Container, Grid, useTheme } from '@mui/material';
import Page from '../../../components/Page';
import { useQuery } from '@apollo/client';
import { CustomAppBar } from '../../../components/CustomAppBar';
import { Permission } from '@workflow-nx/common';
import { Alert } from '@mui/material';
import { Heading } from '../../../components/Heading';
import SearchInput from '../../../components/SearchInput';
import { FIND_SURGEONS } from '../../../gql';
import { ListSurgeonsReducer, ListSurgeonsStateType } from './ListSurgeons.reducer';
import { SurgeonTableFilter } from './SurgeonTableFilter';
import { CreateSurgeonDialog } from './CreateSurgeonDialog';
import SurgeonListTable from './SurgeonListTable';
import useAuth from '../../../hooks/useAuth';
import { Button } from '@workflow-nx/ui';
import { Globals } from '../../../layouts/DashboardLayout';

interface IListSurgeonQueryVariables {
  search: string;
  orderBy: any;
  organizationFilter: number[];
  territoryId: number | undefined;
  skip: number;
  take: number;
}

function getDefaultState() {
  const defaultVariables: IListSurgeonQueryVariables = {
    organizationFilter: [] as number[],
    territoryId: undefined,
    orderBy: { organization: { name: 'asc' } },
    search: '',
    skip: 0,
    take: Globals.DefaultListPageSize,
  };

  const initialState: ListSurgeonsStateType = {
    organization: '',
    fieldRepId: '',
    orderBy: { organization: { name: 'asc' } },
    search: '',
    pageNumber: 0,
    pageSize: Globals.DefaultListPageSize,
  };

  return [defaultVariables, initialState];
}

const ListSurgeonsView = () => {
  const auth = useAuth();
  const theme = useTheme();
  const [defaultVariables, initialState] = getDefaultState();
  const [surgeonsData, setSurgonsData] = useState({ surgeons: [], count: 0 });
  const [loading, setLoading] = useState(true);
  const { loading: surgeonLoading, refetch } = useQuery(FIND_SURGEONS, {
    variables: defaultVariables,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onCompleted: (result) => {
      setLoading(false);
      setSurgonsData(result.surgeons);
    },
  });

  const [state, dispatch] = useReducer(
    ListSurgeonsReducer(refetch),
    initialState as ListSurgeonsStateType,
  );

  const [showCreateUserDialog, setShowCreateUserDialog] = useState(false);

  return (
    <>
      <Page title={'Surgeons'}>
        <Container maxWidth={false}>
          <CustomAppBar
            title={`Surgeons`}
            actions={
              auth?.hasPermission?.([Permission.ManageSurgeon])
                ? [
                    <Button
                      label={'Create Surgeon'}
                      variant={'contained'}
                      color={'secondary'}
                      onClick={() => setShowCreateUserDialog(true)}
                    />,
                  ]
                : []
            }
          />
          <Box>
            <Grid container>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <Box display={'flex'} alignItems={'center'} height={40} mb={1}>
                      <Heading label={'Filters'} />
                    </Box>
                    <Card>
                      <CardContent>
                        <SurgeonTableFilter
                          state={state}
                          dispatch={dispatch}
                          loading={surgeonLoading}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={10}>
                    <Box
                      display={'flex'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      mb={1}
                    >
                      <Heading label={`Results (${surgeonsData?.count ?? 0} surgeons)`} />
                      <Box width={250} bgcolor={theme.palette.background.paper}>
                        <SearchInput
                          value={state.search ?? ''}
                          onChange={(value: string) =>
                            dispatch({ type: 'SEARCH_CHANGED', data: value })
                          }
                        />
                      </Box>
                    </Box>
                    <Box>
                      <Card>
                        <CardContent style={{ height: 'calc(100vh - 228px)' }}>
                          {loading || surgeonLoading || surgeonsData?.count ? (
                            <SurgeonListTable
                              loading={surgeonLoading}
                              orderBy={state.orderBy}
                              dispatch={dispatch}
                              users={surgeonsData?.surgeons}
                              page={state.pageNumber}
                              rowsPerPage={state.pageSize}
                              totalSurgeonCount={surgeonsData?.count ?? 0}
                              onPagination={(pageSize, pageNumber) => {
                                setLoading(true);
                                dispatch?.({
                                  type: 'UPDATE_PAGINATION',
                                  data: {
                                    pageSize,
                                    pageNumber,
                                  },
                                });
                              }}
                            />
                          ) : (
                            <Alert severity={'warning'}>
                              No surgeons match the filter criteria.{' '}
                            </Alert>
                          )}
                        </CardContent>
                      </Card>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Page>
      <CreateSurgeonDialog
        open={showCreateUserDialog}
        onCreate={() => {
          setShowCreateUserDialog(false);
          refetch();
        }}
        onClose={() => {
          setShowCreateUserDialog(false);
          refetch();
        }}
      />
    </>
  );
};

export default ListSurgeonsView;
