import React from 'react';
import { Badge, Box } from '@mui/material';
import { ITask } from '@workflow-nx/common';
import { faTasks } from '@fortawesome/pro-light-svg-icons';
import PopupState, { bindHover, bindPopover } from 'material-ui-popup-state';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TaskListPopoverTable } from './TaskListPopoverTable';

export function TasksPopoverView(props: { caseId: number; tasks: ITask[]; loading?: boolean }) {
  return props.tasks.length > 0 ? (
    <PopupState variant="popover" popupId={`${props.caseId}Popover`}>
      {(popupState) => (
        <div>
          <div {...bindHover(popupState)}>
            <Badge badgeContent={props?.tasks?.length} color={'primary'} {...bindHover(popupState)}>
              <FontAwesomeIcon size={'lg'} icon={faTasks} />
            </Badge>
          </div>
          <HoverPopover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Box p={1}>
              <TaskListPopoverTable tasks={props?.tasks ?? []} loading={props.loading} />
            </Box>
          </HoverPopover>
        </div>
      )}
    </PopupState>
  ) : (
    <FontAwesomeIcon size={'lg'} icon={faTasks} />
  );
}
