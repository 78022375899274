import { Box, Button } from '@mui/material';
import { caseUtils, format, ICase, Permission, UserRoleType } from '@workflow-nx/common';
import { IconFontButton, LargeValueView } from '@workflow-nx/ui';
import { faChevronCircleDown, faChevronCircleUp } from '@fortawesome/pro-solid-svg-icons';
import useAuth from '../../../../hooks/useAuth';
import React from 'react';

export function ViewCaseDetailsHeaderView(props: {
  activeCase: ICase;
  open: boolean;
  onEdit?: () => void;
  onClick: () => void;
}) {
  const { hasPermission } = useAuth();

  const activeCase = props.activeCase;
  return (
    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
      <LargeValueView
        value={format.formatISODate(activeCase?.surgeryDate as unknown as string) || 'TBD'}
        label={'Surgery Date'}
      />
      <LargeValueView
        value={
          activeCase?.surgeonUser ? (
            format.formatName(activeCase?.surgeonUser)
          ) : (
            <span>&mdash;</span>
          )
        }
        label={'Surgeon'}
      />
      <LargeValueView
        value={
          activeCase?.assignedUser ? (
            format.formatName(activeCase?.assignedUser)
          ) : (
            <span>&mdash;</span>
          )
        }
        label={'Assigned To'}
      />
      <LargeValueView
        value={
          activeCase?.fieldRepUser ? (
            format.formatName(activeCase?.fieldRepUser)
          ) : (
            <span>&mdash;</span>
          )
        }
        label={`${format.formatUserRole(UserRoleType.FieldRep)}`}
      />
      <LargeValueView
        value={caseUtils.getValidCaseLevels(activeCase?.levels).length}
        label={'Levels'}
      />
      <LargeValueView
        value={
          activeCase?.studyDate?.expiryDate ? (
            format.formatISODate(activeCase?.studyDate?.expiryDate)
          ) : (
            <span>&mdash;</span>
          )
        }
        label={'Image Expiry'}
      />
      <Box flexGrow={1} />

      {hasPermission?.([Permission.ManageCase, Permission.EditCase]) && props.onEdit ? (
        <Box mr={2}>
          <Button onClick={props.onEdit} variant={'contained'}>
            Edit
          </Button>
        </Box>
      ) : null}

      <Box>
        <IconFontButton
          icon={props.open ? faChevronCircleUp : faChevronCircleDown}
          onClick={props.onClick}
        />
      </Box>
    </Box>
  );
}
