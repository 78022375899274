import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getLoginProvider } from './loginHelpers';

const LoginButton = () => {
  const loginProvider = getLoginProvider();

  return (
    <Box>
      <Button
        color="primary"
        fullWidth
        size="large"
        onClick={() => (window.location.href = loginProvider.authUrl)}
        variant="text"
      >
        <Box display={'flex'} alignItems={'center'}>
          <FontAwesomeIcon icon={loginProvider.icon} size={'xl'} />
          <Box mx={1} />
          <Typography variant={'body1'}>{loginProvider.label}</Typography>
        </Box>
      </Button>
    </Box>
  );
};

export default LoginButton;
