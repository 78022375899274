import { Box, Divider, Typography } from '@mui/material';
import React from 'react';
import { AssetType, EventType, format, ICase, IEvent, IPlan } from '@workflow-nx/common';
import { FIND_EVENTS } from 'apps/workflow-client/src/app/gql';
import { useQuery } from '@apollo/client';

type PatientTabViewProps = {
  activeCase: ICase;
};

export function PatientCaseInfoView({ activeCase }: PatientTabViewProps) {
  const ctDate = activeCase.assets.find(
    (asset) => asset.assetType === AssetType.DicomCt,
  )?.updatedAt;
  const xrayDate = activeCase.assets.find(
    (asset) => asset.assetType === AssetType.DicomXray,
  )?.updatedAt;
  const emptyValue: JSX.Element = <>&mdash;</>;

  const { data: eventsData } = useQuery(FIND_EVENTS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      caseId: activeCase.caseId,
    },
  });

  const eventList = eventsData?.events?.events || [];
  const printedEvent = eventList.find(
    (event: IEvent) => event.eventType === EventType.AdditivePrintingComplete,
  );
  const deliveredEvent = eventList.find(
    (event: IEvent) => event.eventType === EventType.FinalReleaseComplete,
  );
  const approvedPlan = activeCase.plans.find((plan: IPlan) => plan.approvedAt !== null);

  const patientCaseInfoData = [
    {
      title: 'Surgeon',
      data: activeCase.surgeonUser ? format.formatName(activeCase.surgeonUser) : emptyValue,
    },
    { title: 'Date of Surgery', data: activeCase.surgeryDate?.toString() },
    { title: 'CT Received Date', data: format.formatDate(ctDate as Date) },
    { title: 'Xray Received Date', data: format.formatDate(xrayDate as Date) },
    { title: 'Plan Approved Date', data: format.formatISODate(approvedPlan?.approvedAt as string) },
    {
      title: 'Date 3D Printed Completed',
      data: format.formatISODate(printedEvent?.occurredAt as string),
    },
    { title: 'Date Delivered to the Hospital', data: activeCase.surgeryDate?.toString() },
    { title: 'Date of Surgery', data: format.formatISODate(deliveredEvent?.occurredAt as string) },
  ];

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      height={'100%'}
      justifyContent={'center'}
      gap={1}
    >
      {patientCaseInfoData.map((element, index) => {
        return (
          <Box key={index}>
            <Box
              display={'flex'}
              justifyContent="space-between"
              alignContent={'center'}
              bgcolor={''}
              p={1}
            >
              <Typography variant={'body1'} color={'textSecondary'}>
                {element.title}
              </Typography>
              <Typography variant={'body1'} color={'textPrimary'}>
                {element.data ? element.data : emptyValue}
              </Typography>
            </Box>
            {(index + 1) % 4 === 0 && index + 1 != patientCaseInfoData.length ? (
              <Box pt={1} pb={1}>
                <Divider />
              </Box>
            ) : null}
          </Box>
        );
      })}
    </Box>
  );
}
