import { ILocationUser } from '@workflow-nx/common';
import React from 'react';
import { Box, Chip, Typography, Popover } from '@mui/material';

export function UserLocationsView(props: { userId: number; userLocations: ILocationUser[] }) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (props.userLocations.length) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    if (props.userLocations.length) {
      setAnchorEl(null);
    }
  };

  const popoverOpen = Boolean(anchorEl);
  const id = popoverOpen ? `user-locations-popover${props?.userId}` : undefined;

  return (
    <>
      <Chip
        onMouseEnter={handleClick}
        onMouseLeave={handleClose}
        label={props.userLocations.length}
      ></Chip>
      <Popover
        style={{ pointerEvents: 'none' }}
        id={id}
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box p={2}>
          <Typography variant={'h4'} sx={{ mb: 1 }}>
            User Locations
          </Typography>
          {props.userLocations.map((locationUser, index) => (
            <Typography key={index}>
              {`${locationUser?.location?.organization?.name}`} &mdash;{' '}
              {`${locationUser?.location?.description}`}
            </Typography>
          ))}
        </Box>
      </Popover>
    </>
  );
}
