import { useLazyQuery, useQuery } from '@apollo/client';
import { Box, Grid, Typography } from '@mui/material';
import {
  CaseSpineProfile,
  CaseType,
  ILocation,
  IUser,
  UserRoleType,
  format,
  CaseSpineType,
} from '@workflow-nx/common';
import { Checkbox, SelectField } from '@workflow-nx/ui';
import { FormikUserAutocomplete } from 'apps/workflow-client/src/app/components/UserAutocomplete';
import { FormikValues, useField, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { DatePickerField } from '../../../../components/DatePickerField';
import { groupUsersByRole } from '../../../../utils/user';
import { gql } from '@apollo/client/core';
import { sortBy } from 'lodash';
import useAuth from '../../../../hooks/useAuth';
import { FeatureFlag } from '../../../../utils/featureFlags';

export function BasicCaseDetailsForm(props: {
  caseId?: number;
  surgeonId?: number;
  locationId?: number;
  loading: boolean;
  disabled: boolean;
  disabledFields?: Partial<{
    spineType: boolean;
    spineProfile: boolean;
    caseType: boolean;
  }>;
  onSurgeonChange?: (surgeon: IUser | undefined) => void;
  onSpineProfileChange?: (spineProfileChange: CaseSpineProfile) => void;
}) {
  const formikContext = useFormikContext<FormikValues>();
  const [surgeon, setSurgeon] = useState<IUser>();
  const [, , locationIdHelpers] = useField('locationId');
  const [, , fieldRepIdHelpers] = useField('fieldRepId');
  const [surgeons, setSurgeons] = useState<IUser[]>([]);
  const [assignees, setAssignees] = useState<IUser[]>([]);
  const [fieldReps, setFieldReps] = useState<IUser[]>([]);
  const [locations, setLocations] = useState<
    {
      key: string;
      value: string;
    }[]
  >([]);
  const [surgeonId, setSurgeonId] = useState<number | undefined>(props?.surgeonId);
  const { values } = useFormikContext<FormikValues>();

  const { hasFeatureFlag } = useAuth();

  const { data: findUsersData, loading: loadingUsers } = useQuery(gql`
    query FindUsers(
      $statusFilter: [UserStatus]
      $roleFilter: [UserRole]
      $regionId: Int
      $territoryId: Int
      $associatedUserIds: [Int]
      $orderBy: UserOrderByInput
      $search: String
      $take: Int
      $skip: Int
    ) {
      users(
        roleFilter: $roleFilter
        statusFilter: $statusFilter
        regionId: $regionId
        territoryId: $territoryId
        associatedUserIds: $associatedUserIds
        orderBy: $orderBy
        search: $search
        take: $take
        skip: $skip
      ) {
        users {
          userId
          role
          firstName
          lastName
          locationId
          organizationId
          location {
            description
            territoryId
          }
          userRegionTerritories {
            territory {
              name
              territoryId
            }
          }
        }
        count
      }
    }
  `);
  const [findAllLocations, { loading: loadingLocations }] = useLazyQuery(
    gql`
      query FindAllLocations($organizationId: Int, $territoryId: Int) {
        surgeonLocations: locations(organizationId: $organizationId) {
          locationId
          description
          territory {
            territoryId
            name
          }
          organization {
            organizationId
            name
          }
        }
        territoryLocations: locations(territoryId: $territoryId) {
          locationId
          description
          territory {
            territoryId
            name
          }
          organization {
            organizationId
            name
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        const locationsBySurgeon = (data?.surgeonLocations || []).map((location: ILocation) => {
          return {
            key: location.locationId as unknown as string,
            value: location.description,
            parent: 'DEFAULT - ' + location?.organization?.name,
          };
        });

        const locationsByTerritory = sortBy(
          (data?.territoryLocations || [])
            .filter((location: ILocation) => {
              return location?.organization?.organizationId !== surgeon?.organizationId;
            })
            .map((location: ILocation) => {
              return {
                key: location.locationId as unknown as string,
                value: location.description,
                parent:
                  location?.territory?.name?.toUpperCase() +
                  ' TERRITORY - ' +
                  location?.organization?.name,
              };
            }),
          ['parent', 'value'],
        );

        const allLocations = locationsBySurgeon.concat(locationsByTerritory);
        setLocations(allLocations);

        const defaultLocationId = surgeon?.locationId;
        const surgeonLocation = data.surgeonLocations.find(
          (location: ILocation) => location.locationId === defaultLocationId,
        );
        const territoryId = surgeonLocation?.territory?.territoryId;

        if (territoryId) {
          const fieldRep = fieldReps.find(
            (fieldRep: IUser) =>
              fieldRep?.userRegionTerritories?.[0]?.territory?.territoryId === territoryId,
          );
          fieldRepIdHelpers.setValue(fieldRep?.userId);
        }

        if (surgeonId && surgeonId === props?.surgeonId) {
          locationIdHelpers?.setValue(props?.locationId);
        } else if (
          locationsBySurgeon.some((location: { key: number }) => location.key === defaultLocationId)
        ) {
          locationIdHelpers?.setValue(defaultLocationId);
        } else {
          locationIdHelpers?.setValue(locationsBySurgeon?.[0]?.locationId);
        }
      },
    },
  );

  useEffect(() => {
    const users = findUsersData?.users;

    if (users?.count > 0) {
      const { surgeons, assignees, fieldReps } = groupUsersByRole(users?.users);
      const surgeon = (users?.users as IUser[]).find((user) => user.userId === surgeonId);

      setSurgeon(surgeon);
      setSurgeons(surgeons);
      setAssignees(assignees);
      setFieldReps(fieldReps);
    }
  }, [findUsersData, surgeonId]);

  useEffect(() => {
    setLocations([]);
    locationIdHelpers?.setValue('');

    if (surgeon) {
      const organizationId = surgeon?.organizationId;

      findAllLocations({
        variables: {
          organizationId: organizationId,
          territoryId: surgeon?.location?.territoryId,
        },
      });
    }
  }, [surgeon]);

  const disabled = props.loading || props.disabled || loadingLocations || loadingUsers;

  const caseTypeFieldDisabled = Boolean(disabled || props.disabledFields?.caseType);

  const spineTypeFieldDisabled = Boolean(disabled || props.disabledFields?.spineType);

  const spineProfileMenuItems = Object.values(CaseSpineProfile)
    .filter((profile) => {
      const spineType = values?.spineType ?? CaseSpineType.Lumbar;
      switch (profile) {
        case 'CERVICAL_STANDARD':
        case 'CERVICAL_STANDARD_MINUS_C7':
        case 'CERVICAL_STANDARD_PLUS_C8':
          return spineType === CaseSpineType.Cervical;
        case 'LUMBAR_STANDARD':
        case 'LUMBAR_STANDARD_MINUS_L5':
        case 'LUMBAR_STANDARD_PLUS_L6':
          return spineType === CaseSpineType.Lumbar;
      }
      return true;
    })
    .map((profile) => ({
      key: profile,
      value: format.formatCaseSpineProfile(profile),
    }));

  return (
    <Grid container spacing={3} justifyContent={'space-between'}>
      <Grid item xs={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SelectField
              name={'caseType'}
              label={'Case Type'}
              hideNone={true}
              menuItems={[CaseType.Live, CaseType.Draft].map((ct) => ({
                key: ct,
                value: format.formatCaseType(ct),
              }))}
              disabled={caseTypeFieldDisabled}
            />
          </Grid>
          {hasFeatureFlag?.(FeatureFlag.cervicalSupportEnabled) ? (
            <Grid item xs={12}>
              <SelectField
                name={'spineType'}
                label={'Spine Type'}
                hideNone={true}
                menuItems={[CaseSpineType.Lumbar, CaseSpineType.Cervical].map((ct) => ({
                  key: ct,
                  value: format.formatSpineType(ct),
                }))}
                disabled={spineTypeFieldDisabled}
                onChange={(value) => {
                  const spineType = value.target.value as CaseSpineType;
                  formikContext.setFieldValue(
                    'spineProfile',
                    spineType === CaseSpineType.Cervical
                      ? CaseSpineProfile.CervicalStandard
                      : CaseSpineProfile.LumbarStandard,
                  );
                }}
              />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <SelectField
              name={'spineProfile'}
              label={'Spine Profile'}
              required
              hideNone={true}
              menuItems={spineProfileMenuItems}
              disabled={disabled || Boolean(props.disabledFields?.spineProfile)}
              onChange={(value) => {
                const spineProfile = value.target.value as keyof typeof CaseSpineProfile;
                props.onSpineProfileChange
                  ? props.onSpineProfileChange(spineProfile as CaseSpineProfile)
                  : null;
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <DatePickerField
              name={'receivedAt'}
              label={'Received Date'}
              required={true}
              loading={disabled || !!props.caseId}
            />
          </Grid>
          <Grid item xs={12}>
            <FormikUserAutocomplete
              name={'surgeonId'}
              label={'Surgeon'}
              users={surgeons}
              startWithLastName
              disabled={disabled}
              required
              onChange={(value) => {
                const surgeonId = Number(value) ? Number(value) : undefined;
                const surgeon = surgeons.find((surgeon) => surgeon.userId === surgeonId);

                setSurgeon(surgeon);
                setSurgeonId(surgeonId);

                props.onSurgeonChange?.(surgeon);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormikUserAutocomplete
              name={'assignedId'}
              label={'Assigned To'}
              users={assignees}
              startWithLastName
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectField
              name={'locationId'}
              label={'Location'}
              hideNone={true}
              grouped={true}
              menuItems={locations}
              disabled={disabled || !surgeonId}
              required
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box display={'flex'} alignItems={'center'}>
              <DatePickerField
                disabled={disabled}
                name="surgeryDate"
                required={false}
                label={'Surgery Date'}
              />
              <Box mx={0.5} />
              <Checkbox
                name={'isSurgeryDateTentative'}
                label={
                  <Typography variant={'body1'} noWrap={true}>
                    Tentative?
                  </Typography>
                }
                disabled={disabled}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <FormikUserAutocomplete
              name={'fieldRepId'}
              label={`${format.formatUserRole(UserRoleType.FieldRep)}`}
              users={fieldReps}
              startWithLastName
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
