import {
  CaseStageType,
  format,
  ICase,
  IPlan,
  PlanStatusType,
  UserRoleType,
} from '@workflow-nx/common';
import { useSnackbar } from 'notistack';
import { useConfirm } from 'material-ui-confirm';
import { useMutation } from '@apollo/client';
import { DELETE_PLAN } from '../../../../gql';
import { Alert, Box, IconButton, Stack, Typography } from '@mui/material';
import { PlanStatusChip } from './PlanStatusChip';
import { PlansList } from './PlansList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faTrash, faFileContract } from '@fortawesome/pro-light-svg-icons';
import React, { useState } from 'react';
import { PlanViewDialog } from './PlanView/PlanViewDialog';
import { PlanViewDialogProvider } from './PlanView/PlanViewDialog.context';
import useAuth from '../../../../hooks/useAuth';

export function PlanHeaderView(props: {
  activeCase: ICase;
  selectedPlan: IPlan | null;
  onSelectPlan?: (plan: IPlan | null) => void;
  onDeletePlan?: () => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { hasRole } = useAuth();
  const confirm = useConfirm();
  const [deletePlan, { loading: loadingDeletePlan }] = useMutation(DELETE_PLAN);
  const [showPlanPreviewView, setShowPlanPreviewView] = useState(false);
  const handleDeletePlan = async (plan: IPlan) => {
    try {
      await confirm({
        confirmationButtonProps: { variant: 'contained' },
        title: 'Delete Plan',
        description: (
          <>
            Are you sure you want to delete the plan <strong>{plan.name}</strong>? This will remove
            the plan and the associated plan assets from this case!`
          </>
        ),
        confirmationText: 'Delete Plan',
      });

      await deletePlan({
        variables: {
          planId: plan.planId,
        },
      });

      enqueueSnackbar(`Plan ${plan.name} was deleted`, {
        variant: 'success',
      });

      props?.onDeletePlan?.();
    } catch (e) {
      enqueueSnackbar('Error deleting plan', {
        variant: 'error',
      });
    }
  };

  return (
    <>
      <Stack direction={'row'} spacing={2} alignItems={'center'}>
        <Stack>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <Typography variant={'h3'}>{props.selectedPlan?.name ?? 'No Plan Selected'}</Typography>
            {props.selectedPlan ? <PlanStatusChip status={props.selectedPlan.status} /> : null}
          </Stack>
          {props.selectedPlan ? (
            <Typography>
              Updated at {format.formatDateTime(props.selectedPlan.updatedAt)} by{' '}
              {format.formatName(props.selectedPlan?.updatedByUser)}
            </Typography>
          ) : null}
        </Stack>
        <Box flexGrow={1}></Box>
        <IconButton
          size={'large'}
          onClick={() => setShowPlanPreviewView(true)}
          disabled={!props.selectedPlan}
        >
          <FontAwesomeIcon icon={faEye} />
        </IconButton>
        {hasRole?.([UserRoleType.CaseAdmin, UserRoleType.SiteAdministrator]) &&
        props.onSelectPlan ? (
          <PlansList
            activeCase={props.activeCase}
            onSelectPlan={props.onSelectPlan}
            selectedPlan={props.selectedPlan}
            caseNumber={props.activeCase.number}
          />
        ) : null}

        {!props.selectedPlan?.deletedAt && props.activeCase.stage === CaseStageType.Planning ? (
          <IconButton
            disabled={loadingDeletePlan || props.selectedPlan?.status !== PlanStatusType.Draft}
            onClick={() => (props.selectedPlan ? handleDeletePlan(props.selectedPlan) : undefined)}
            size="large"
          >
            <FontAwesomeIcon icon={faTrash} />
          </IconButton>
        ) : null}
      </Stack>
      {props.selectedPlan?.deletedAt ? (
        <Alert severity={'error'} sx={{ mt: 1 }}>
          This plan was deleted on {format.formatDateTime(props.selectedPlan.deletedAt)}. This plan
          is in read-only mode and cannot be changed.
        </Alert>
      ) : null}
      {showPlanPreviewView && props.selectedPlan ? (
        <PlanViewDialogProvider
          activeCase={props.activeCase}
          showDaisy={false}
          plan={props.selectedPlan}
        >
          <PlanViewDialog
            open={showPlanPreviewView}
            caseMeasurementsVersion={props.activeCase.settings.measurementsVersion}
            plan={props.selectedPlan}
            activeCase={props.activeCase}
            onClose={() => setShowPlanPreviewView(false)}
          />
        </PlanViewDialogProvider>
      ) : null}
    </>
  );
}
