import { format, IUser } from '@workflow-nx/common';
import { Avatar, Box, Popover, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    height: 40,
    width: 40,
    marginRight: (props: any) => (props.hideMarginRight ? 0 : theme.spacing(1)),
  },
}));

export function UserAvatar(props: {
  user?: IUser;
  hideDetails?: boolean;
  hideMarginRight?: boolean;
}) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [hideMarginRight, sethideMarginRight] = useState(props.hideMarginRight);
  const styles = useStyles({ hideMarginRight });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!props.hideDetails) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    if (!props.hideDetails) {
      setAnchorEl(null);
    }
  };

  const popoverOpen = Boolean(anchorEl);
  const id = popoverOpen ? `user-popover${props?.user?.userId}` : undefined;

  return (
    <Box display={'flex'} justifyContent={'center'}>
      <Avatar
        alt="User"
        className={styles.avatar}
        onMouseEnter={handleClick}
        onMouseLeave={handleClose}
      >
        {format.formatInitials(props.user)}
      </Avatar>
      <Popover
        style={{ pointerEvents: 'none' }}
        id={id}
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box p={2}>
          <Typography variant={'h4'}>{format.formatName(props.user)}</Typography>
          {props.user?.organization ? (
            <Typography noWrap={true} color={'textSecondary'} variant={'button'}>
              {props.user?.organization.name}
            </Typography>
          ) : null}
        </Box>
      </Popover>
    </Box>
  );
}
