import { faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, ListItem, Slider, Typography } from '@mui/material';
import { LevelType, Nullable } from '@workflow-nx/common';
import { Mesh, Scene } from 'babylonjs';
import { useEffect, useState } from 'react';

export default function VisibilityView(props: {
  levelType: LevelType;
  meshName: string;
  label: string;
  scene: Scene;
}) {
  const mesh = props.scene?.getMeshByName(props.meshName) as Nullable<Mesh>;

  const [visibility, setVisibility] = useState(0);
  const [meshVisible, setMeshVisible] = useState(false);

  if (mesh && visibility !== mesh?.visibility) {
    setVisibility(mesh?.visibility ?? 1);

    setMeshVisible((mesh?.visibility ?? 1) !== 0);
  }

  useEffect(() => {
    const thisMesh = props.scene?.getMeshByName(props.meshName) as Nullable<Mesh>;

    setVisibility(thisMesh?.visibility ?? 1);

    setMeshVisible((thisMesh?.visibility ?? 1) !== 0);
  }, [props.meshName]);

  return (
    <ListItem>
      <Box flexGrow={1}>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography color="text.secondary" variant="h6" flex={2}>
            {props.label}
          </Typography>

          <Box display="flex" flex={3} ml={3}>
            <Slider
              size="small"
              disabled={!meshVisible}
              min={0.05}
              max={1}
              step={0.1}
              value={visibility}
              onChange={(_: any, newValue: number | number[]) => {
                if (mesh) {
                  mesh.visibility = newValue as number;
                }
                setVisibility(newValue as number);
              }}
            />

            <Box mx={1} />

            <IconButton
              size="small"
              onClick={() => {
                const isVisible = !meshVisible;

                const newVisibility = isVisible ? 1 : 0;

                if (mesh) {
                  mesh.visibility = newVisibility;
                }

                setMeshVisible(isVisible);
                setVisibility(newVisibility);
              }}
            >
              <FontAwesomeIcon icon={meshVisible ? faEye : faEyeSlash} size="sm" />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </ListItem>
  );
}
