import React, { Dispatch, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { FIND_USERS_BY_ROLE } from '../../../../gql';
import {
  CaseType,
  CaseCancellationListType,
  format,
  IUser,
  PartType,
  UserRoleType,
  EventType,
  CaseShippingStatusType,
} from '@workflow-nx/common';
import { Box, Button } from '@mui/material';
import { Select } from '../../../../components/Select';
import { ListCasesActionType } from '../ListCases.reducer';
import { groupUsersByRole } from '../../../../utils/user';
import { UserAutocomplete } from '../../../../components/UserAutocomplete';
import { OrganizationSelect } from '../../../../components/OrganizationSelect';

export function CaseEventTableFilter(props: {
  state: {
    assignedTo?: string;
    organization?: string;
    surgeon?: string;
    fieldRep?: string;
    caseTags?: string[];
    caseType?: CaseType;
    caseCancellationType?: CaseCancellationListType;
    partTypes?: PartType[];
    shippingStatuses?: CaseShippingStatusType[];
    events?: EventType[];
  };
  loading: boolean;
  dispatch: Dispatch<ListCasesActionType>;
}) {
  const [surgeons, setSurgeons] = useState<IUser[]>([]);
  const [assignees, setAssignees] = useState<IUser[]>([]);
  const [fieldReps, setFieldReps] = useState<IUser[]>([]);
  const { data } = useQuery(FIND_USERS_BY_ROLE);

  const eventTypeMenuItems = Object.values(EventType).map((eventType) => {
    return {
      key: eventType,
      value: format.formatEventType(eventType as EventType),
    };
  });
  eventTypeMenuItems.unshift({ key: 'NONE' as unknown as EventType, value: 'None' });

  const ShipmentStatusTypeMenuItems = Object.values(CaseShippingStatusType).map(
    (shipmentStatusType) => {
      return {
        key: shipmentStatusType,
        value: format.formatCaseShippingStatusType(shipmentStatusType as CaseShippingStatusType),
      };
    },
  );

  useEffect(() => {
    const users = data?.users;
    if (users?.users?.length > 0) {
      const { surgeons, assignees, fieldReps } = groupUsersByRole(users?.users);

      setAssignees(assignees);
      setSurgeons(surgeons);
      setFieldReps(fieldReps);
    }
  }, [data?.users]);

  const handleShippingStatusClearAll = () => {
    props.dispatch({
      type: 'SHIPPING_STATUS_CHANGED',
      data: [],
    });
  };

  const handleProductionEventsClearAll = () => {
    props.dispatch({
      type: 'EVENTS_CHANGED',
      data: [],
    });
  };

  return (
    <Box display={'flex'} flexDirection={'column'} gap={2}>
      <Box>
        <UserAutocomplete
          name={'assignedId'}
          label={'Assigned To'}
          value={props.state.assignedTo ? props.state.assignedTo : undefined}
          onChange={(newVal) => {
            props.dispatch({
              type: 'ASSIGNED_TO_CHANGED',
              data: newVal || '',
            });
          }}
          fullWidth
          users={assignees}
          startWithLastName
          disabled={props.loading}
        />
      </Box>
      <Box>
        <OrganizationSelect
          value={props.state.organization ?? ''}
          onChange={(event) => {
            props.dispatch({
              type: 'ORGANIZATION_CHANGED',
              data: event.target.value,
            });
          }}
          disabled={props.loading}
        />
      </Box>
      <Box>
        <UserAutocomplete
          name={'surgeon'}
          label={'Surgeon'}
          value={props.state.surgeon ? props.state.surgeon?.toString() : undefined}
          onChange={(newVal) => {
            props.dispatch({
              type: 'SURGEON_CHANGED',
              data: newVal || '',
            });
          }}
          fullWidth
          users={surgeons}
          startWithLastName
          disabled={props.loading}
        />
      </Box>
      <Box>
        <UserAutocomplete
          name={'fieldRep'}
          label={`${format.formatUserRole(UserRoleType.FieldRep)}`}
          value={props.state.fieldRep ? props.state.fieldRep?.toString() : undefined}
          onChange={(newVal) => {
            props.dispatch({
              type: 'FIELD_REP_CHANGED',
              data: newVal || '',
            });
          }}
          fullWidth
          users={fieldReps}
          startWithLastName
          disabled={props.loading}
        />
      </Box>
      <Box>
        <Select
          name={'shippingStatus'}
          label={'Shipping Status'}
          multiple={true}
          hideNone={true}
          value={
            props.state?.shippingStatuses
              ? (props.state?.shippingStatuses as unknown as string)
              : ([] as unknown as string)
          }
          onChange={(event) => {
            props.dispatch({
              type: 'SHIPPING_STATUS_CHANGED',
              data: event.target.value,
            });
          }}
          menuItems={ShipmentStatusTypeMenuItems}
          disabled={props.loading}
          fullWidth={true}
        />
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <Button fullWidth={true} size={'small'} onClick={handleShippingStatusClearAll}>
            Clear
          </Button>
        </Box>
      </Box>
      <Box>
        <Select
          name={'events'}
          label={'Production Events'}
          multiple={true}
          hideNone={true}
          value={
            props.state?.events
              ? (props.state?.events as unknown as string)
              : ([] as unknown as string)
          }
          onChange={(event) => {
            props.dispatch({
              type: 'EVENTS_CHANGED',
              data: event.target.value,
            });
          }}
          menuItems={eventTypeMenuItems}
          disabled={props.loading}
          fullWidth={true}
          customEmptyText={'[Empty Selection]'}
        />
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <Button fullWidth={true} size={'small'} onClick={handleProductionEventsClearAll}>
            Clear
          </Button>
        </Box>
      </Box>
      <Box>
        <Box textAlign={'center'}>
          <Button onClick={() => props.dispatch({ type: 'RESET_FILTERS' })}>Reset Filters</Button>
        </Box>
      </Box>
    </Box>
  );
}
