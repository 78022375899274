import { format, PartType } from '@workflow-nx/common';
import { TableRow } from '@mui/material';
import { TableCell } from '../../../../components/TableCell';
import { Checkbox } from '@workflow-nx/ui';
import React from 'react';

export function Form19ManualAttributeTableRow(props: {
  value: any;
  valueIndex: number;
  levelIndex: number;
  disabled: boolean;
  partType: PartType;
  isMinusSizeImplantExcluded: boolean;
  isPlusSizeImplantExcluded: boolean;
}) {
  return (
    <TableRow hover>
      <TableCell>
        {format.formatImplantCharacteristicType(props.value.attribute, props.partType)}
      </TableCell>
      <TableCell>
        <Checkbox
          name={`levels[${props.levelIndex}].values[${props.valueIndex}].minus01`}
          label={'Present'}
          disabled={props.disabled || props.isMinusSizeImplantExcluded}
        />
      </TableCell>
      <TableCell>
        <Checkbox
          name={`levels[${props.levelIndex}].values[${props.valueIndex}].plan02`}
          label={'Present'}
          disabled={props.disabled}
        />
      </TableCell>
      <TableCell>
        <Checkbox
          name={`levels[${props.levelIndex}].values[${props.valueIndex}].plus03`}
          label={'Present'}
          disabled={props.disabled || props.isPlusSizeImplantExcluded}
        />
      </TableCell>
    </TableRow>
  );
}
