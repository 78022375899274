import { ApolloError } from '@apollo/client';

export const getGraphQLErrorMessages = (error: Error, defaultMessage: string = '') => {
  let message = defaultMessage;
  if (error instanceof ApolloError) {
    message = error.graphQLErrors
      .map(({ message }) => {
        return message;
      })
      .join('\n');
  }
  return message;
};
