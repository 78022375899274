import {
  IUser,
  LevelType,
  ILevels,
  ImplantType,
  SagittalGoalType,
  CoronalGoalType,
  NavigationSystemType,
  ImplantDirection,
  ImplantOrientation,
  SurgeonPreferenceMeasurementType,
  PositionDistanceType,
  InterbodyScrewLengthTypes,
  TargetHeightMetricType,
} from '@workflow-nx/common';
import {
  getSurgeonPreferenceImplants,
  getSurgeonPreferenceImplantMeasurements,
} from '../../../../utils/surgeonPreferences';

export type SurgeonPreferencesStateType = {
  surgeonPreferenceId: number | null;
  userId: number;
  preferredProcedures: ImplantType[];
  sagittalGoalPrimary?: SagittalGoalType;
  sagittalGoalSecondary?: SagittalGoalType;
  sagittalGoalOther?: string;
  coronalGoalPrimary?: CoronalGoalType;
  coronalGoalSecondary?: CoronalGoalType;
  navigationSystems: NavigationSystemType[];
  navigationSystemOther?: string;
  targetHeightMetric?: TargetHeightMetricType | null;
  excludedInstruments?: string[];
  reusableInstruments?: boolean | null;
  note?: string;
  surgeonPreferenceImplants: SurgeonPreferenceImplantType[];
  surgeonPreferenceImplantMeasurements: SurgeonPreferenceImplantMeasurementType[];
};

export type SurgeonPreferenceImplantType = {
  implantType: ImplantType;
  positioning: PositionDistanceType;
  direction?: ImplantDirection | null;
  orientation?: ImplantOrientation | null;
  orientationByLevels?: ILevels;
  orientationLevelL3L4?: ImplantOrientation | string;
  orientationLevelL4L5?: ImplantOrientation | string;
  orientationLevelL5S1?: ImplantOrientation | string;
  screwLength?: InterbodyScrewLengthTypes | null;
  maximizeFootprint?: boolean | null;
  plusSizeImplant: number;
  minimalHeightSpecification: boolean;
  excludedImplantSizes: string[];
  note?: string;
};

export type SurgeonPreferenceImplantMeasurementType = {
  implantType: ImplantType;
  levelType?: LevelType;
  measurementType: SurgeonPreferenceMeasurementType;
  min: number;
  max: number;
};

export type SurgeonPreferencesActionType = {
  type: 'INIT';
  data?: any;
};

export const SurgeonPreferencesReducer =
  (surgeonUser: IUser) =>
  (
    state: SurgeonPreferencesStateType,
    action: SurgeonPreferencesActionType,
  ): SurgeonPreferencesStateType => {
    let updatedState: SurgeonPreferencesStateType = JSON.parse(JSON.stringify(state));
    const preferences = surgeonUser.preferences;
    switch (action.type) {
      case 'INIT': {
        const surgeonPreferenceImplants = getSurgeonPreferenceImplants(
          preferences?.surgeonPreferenceImplants ?? [],
        );

        const surgeonPreferenceImplantMeasurements = getSurgeonPreferenceImplantMeasurements(
          preferences?.surgeonPreferenceImplantMeasurements ?? [],
        );

        updatedState = {
          surgeonPreferenceId: preferences?.surgeonPreferenceId || null,
          userId: surgeonUser.userId,
          preferredProcedures: preferences?.preferredProcedures || updatedState.preferredProcedures,
          sagittalGoalPrimary: preferences?.sagittalGoalPrimary || updatedState.sagittalGoalPrimary,
          sagittalGoalSecondary:
            preferences?.sagittalGoalSecondary || updatedState.sagittalGoalSecondary,
          sagittalGoalOther: preferences?.sagittalGoalOther || updatedState.sagittalGoalOther,
          coronalGoalPrimary: preferences?.coronalGoalPrimary || updatedState.coronalGoalPrimary,
          coronalGoalSecondary:
            preferences?.coronalGoalSecondary || updatedState.coronalGoalSecondary,
          navigationSystems: preferences?.navigationSystems || updatedState.navigationSystems,
          navigationSystemOther:
            preferences?.navigationSystemOther || updatedState.navigationSystemOther,
          targetHeightMetric:
            preferences?.targetHeightMetric ||
            updatedState.targetHeightMetric ||
            TargetHeightMetricType.ImplantHeight,
          excludedInstruments: preferences?.excludedInstruments || updatedState.excludedInstruments,
          reusableInstruments: preferences?.reusableInstruments || updatedState.reusableInstruments,
          note: preferences?.note || updatedState.note,
          surgeonPreferenceImplants: surgeonPreferenceImplants,
          surgeonPreferenceImplantMeasurements: surgeonPreferenceImplantMeasurements,
        };
      }
    }
    return updatedState;
  };
