import React from 'react';
import { Typography } from '@mui/material';
import { Control, FieldValues, useController } from 'react-hook-form';

type ErrorMessageProps = {
  name: string;
  control: Control<FieldValues>;
};

export const ErrorMessageRHF = ({ name, control }: ErrorMessageProps) => {
  const {
    fieldState: { error },
  } = useController({ name, control });

  return (
    <Typography variant={'caption'} color={'error'}>
      {error ? error.message : null}
    </Typography>
  );
};
