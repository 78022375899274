import { ICase } from '@workflow-nx/common';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useMutation } from '@apollo/client';
import { REJECT_READY } from '../../../../gql';
import { Formik, FormikHelpers } from 'formik';
import { Box, Grid } from '@mui/material';
import CustomDialog from '../../../../components/CustomDialog';
import * as Yup from 'yup';
import { ProgressButton, TextField } from '@workflow-nx/ui';

export function ReadyRejectionDialog({
  activeCase,
  open,
  onClose,
}: {
  open: boolean;
  activeCase?: ICase;
  onClose: (shouldUpdate: boolean) => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [rejectReady] = useMutation(REJECT_READY);

  const handleSubmitForm = async (
    values: { reason: string },
    { setStatus, setSubmitting }: FormikHelpers<{ reason: string }>,
  ) => {
    try {
      await rejectReady({
        variables: { caseId: activeCase?.caseId, reason: values.reason },
      });

      setStatus({ success: true });
      enqueueSnackbar('Case was move back to production stage', {
        variant: 'success',
      });

      onClose(true);
    } catch (err) {
      console.error(err);
      setStatus({ success: false });
      enqueueSnackbar('An error occurred moving the case to production', {
        variant: 'error',
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{ reason: '' }}
      validationSchema={Yup.object().shape({
        reason: Yup.string().required(),
      })}
      onSubmit={handleSubmitForm}
      enableReinitialize={true}
    >
      {({ handleSubmit, isSubmitting, submitForm }) => (
        <CustomDialog
          maxWidth={'md'}
          open={open}
          title={`Rejecting Ready Case ${activeCase?.number}`}
          onClose={() => {
            onClose(false);
          }}
          positiveActionButtons={[
            <ProgressButton
              onClick={() => submitForm()}
              loading={isSubmitting}
              disabled={isSubmitting}
              label={'Reject'}
            />,
          ]}
        >
          {open ? (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs>
                  <TextField disabled={isSubmitting} name="reason" label={'Reason'} />
                </Grid>
              </Grid>
              <Box mt={1} />
            </form>
          ) : null}
        </CustomDialog>
      )}
    </Formik>
  );
}
