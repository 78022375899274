import {
  CaseApproachType,
  CaseSpineProfile,
  CaseSpineType,
  CaseType,
  DEFAULT_SPINE_PROFILE,
  PartType,
} from '@workflow-nx/common';
import * as Yup from 'yup';
import config from '../../../../extras/config';
import { bytesToSize } from '../../../../utils/bytesToSize';

export interface ICreateCaseDialogFormValues {
  approach?: CaseApproachType | '';
  dicomCt?: File;
  dicomCtMetadata?: any;
  dicomXray?: File;
  dicomXrayMetadata?: any;
  dicomMri?: File;
  dicomMriMetadata?: any;
  surgeryDate?: Date | null;
  isSurgeryDateTentative?: boolean;
  spineType: CaseSpineType;
  spineProfile: CaseSpineProfile;
  // includeRodTemplates?: boolean;
  receivedAt: Date;
  levelL1L2?: 'NONE' | PartType;
  levelL2L3?: 'NONE' | PartType;
  levelL3L4?: 'NONE' | PartType;
  levelL4L5?: 'NONE' | PartType;
  levelL5S1?: 'NONE' | PartType;
  levelL5L6?: 'NONE' | PartType;
  levelL6S1?: 'NONE' | PartType;
  levelL4S1?: 'NONE' | PartType;
  levelC2C3?: 'NONE' | PartType;
  levelC3C4?: 'NONE' | PartType;
  levelC4C5?: 'NONE' | PartType;
  levelC5C6?: 'NONE' | PartType;
  levelC6C7?: 'NONE' | PartType;
  levelC7T1?: 'NONE' | PartType;
  levelC7C8?: 'NONE' | PartType;
  levelC8T1?: 'NONE' | PartType;
  levelC6T1?: 'NONE' | PartType;
  levelL1L2Metadata?: { anteriorInstrumentation: boolean; otherVendor: boolean };
  levelL2L3Metadata?: { anteriorInstrumentation: boolean; otherVendor: boolean };
  levelL3L4Metadata?: { anteriorInstrumentation: boolean; otherVendor: boolean };
  levelL4L5Metadata?: { anteriorInstrumentation: boolean; otherVendor: boolean };
  levelL5S1Metadata?: { anteriorInstrumentation: boolean; otherVendor: boolean };
  levelL5L6Metadata?: { anteriorInstrumentation: boolean; otherVendor: boolean };
  levelL6S1Metadata?: { anteriorInstrumentation: boolean; otherVendor: boolean };
  levelL4S1Metadata?: { anteriorInstrumentation: boolean; otherVendor: boolean };
  levelC2C3Metadata?: { anteriorInstrumentation: boolean; otherVendor: boolean };
  levelC3C4Metadata?: { anteriorInstrumentation: boolean; otherVendor: boolean };
  levelC4C5Metadata?: { anteriorInstrumentation: boolean; otherVendor: boolean };
  levelC5C6Metadata?: { anteriorInstrumentation: boolean; otherVendor: boolean };
  levelC6C7Metadata?: { anteriorInstrumentation: boolean; otherVendor: boolean };
  levelC7T1Metadata?: { anteriorInstrumentation: boolean; otherVendor: boolean };
  levelC7C8Metadata?: { anteriorInstrumentation: boolean; otherVendor: boolean };
  levelC8T1Metadata?: { anteriorInstrumentation: boolean; otherVendor: boolean };
  levelC6T1Metadata?: { anteriorInstrumentation: boolean; otherVendor: boolean };
  surgeonId?: string;
  locationId?: string;
  assignedId?: string;
  fieldRepId?: string;
  // approach?: string;
  pelvicIncidence?: number;
  slopeOfLineOfSight?: number;
  lumbarLordosis?: number;
  lumbarCoronalCobb?: number;
  sagittalVerticalAxis?: number;
  coronalBalance?: number;
  uiv?: string;
  liv?: string;
  pco?: string[];
  pso?: string[];
  caseType: CaseType;
  comment?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  mrn?: string;
  gender?: string;
  birthDate?: Date | null;
  tags?: { key: number; value: string }[];
  excludedInstruments?: string[];
}

export const createCaseDialogFormValues = (): ICreateCaseDialogFormValues => {
  return {
    approach: '',
    assignedId: '',
    fieldRepId: '',
    surgeonId: '',
    locationId: '',
    surgeryDate: null,
    spineProfile: DEFAULT_SPINE_PROFILE,
    spineType: CaseSpineType.Lumbar,
    isSurgeryDateTentative: false,
    receivedAt: new Date(),
    caseType: CaseType.Live,
    levelL1L2: 'NONE',
    levelL2L3: 'NONE',
    levelL3L4: 'NONE',
    levelL4L5: 'NONE',
    levelL5S1: 'NONE',
    levelL5L6: 'NONE',
    levelL6S1: 'NONE',
    levelL4S1: 'NONE',
    pso: [],
    pco: [],
    uiv: '',
    liv: '',
    comment: '',
    tags: [],
    firstName: '',
    middleName: '',
    lastName: '',
    mrn: '',
    birthDate: null,
    gender: '',
    excludedInstruments: [],
  };
};

const dicomMaxFileSizeBytes = Number(config.dicomMaxFileSizeBytes);
const dicomTypes = ['dicomCt', 'dicomXray', 'dicomMri'];

const caseDialogSchema: any = {
  assignedId: Yup.number().nullable(),
  surgeonId: Yup.number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .required('Field is required'),
  locationId: Yup.number().required('Field is required'),
  surgeryDate: Yup.date().nullable(),
  isSurgeryDateTentative: Yup.bool().nullable(),
  levels: Yup.array().min(0, 'There must be at least level'),
  // includeRodTemplates: Yup.bool().nullable(),
  spineProfile: Yup.string().required('Field is required'),
  pelvicIncidence: Yup.number()
    .nullable()
    .min(1, 'Must be 1\u00b0 or more')
    .max(180, 'Must be 180\u00b0 or less'),
  slopeOfLineOfSight: Yup.number().nullable(),
  lumbarLordosis: Yup.number().nullable(),
  lumbarCoronalCobb: Yup.number().nullable(),
  tags: Yup.array()
    .of(
      Yup.object({
        key: Yup.number(),
        value: Yup.string(),
      }),
    )
    .nullable(),
};

dicomTypes.forEach((entry) => {
  caseDialogSchema[entry] = Yup.mixed()
    .test('fileFormat', 'The selected file must be a ZIP file', (value) => {
      if (!value) {
        return true;
      }
      return value && (value as any).name.toLowerCase().endsWith('.zip');
    })
    .test(
      'fileSize',
      `The selected file must be less than ${bytesToSize(dicomMaxFileSizeBytes, 2)}`,
      (value) => {
        if (!value) {
          return true;
        }
        return value && (value as any).size < Number(dicomMaxFileSizeBytes);
      },
    );
});

export const createCaseDialogSchema = Yup.object().shape(caseDialogSchema);
