import React from 'react';
import { Box, Typography } from '@mui/material';

export function LargeValueView(props: { label: string; value: React.ReactNode }) {
  return (
    <Box mr={4} textOverflow={'ellipsis'} overflow={'hidden'}>
      <Typography variant={'h5'} noWrap>
        {props.value}
      </Typography>
      <Typography variant={'body2'} noWrap color={'textSecondary'}>
        {props.label}
      </Typography>
    </Box>
  );
}
