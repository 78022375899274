import { PlanVersionType } from '@workflow-nx/common';
import { FormControlLabel, FormGroup, Stack, Switch } from '@mui/material';
import React from 'react';

export function PlanVersionToggle(props: {
  disabled: boolean;
  planVersion: PlanVersionType | undefined;
  onChange: () => void;
}) {
  return (
    <Stack direction={'row'} alignItems={'center'} spacing={1}>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              disabled={props.disabled}
              checked={props.planVersion === PlanVersionType.Version2}
              onChange={props.onChange}
            />
          }
          labelPlacement={'start'}
          label={
            <Stack direction="row" alignItems={'center'} spacing={1}>
              {props.planVersion === PlanVersionType.Version1 ? 'v1' : 'v2'}
            </Stack>
          }
        />
      </FormGroup>
    </Stack>
  );
}
