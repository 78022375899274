import React, { Dispatch, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import { IUser, PartType, PatientGender, UserRoleType } from '@workflow-nx/common';
import { IOSSlider } from '@workflow-nx/ui';
import {
  PATIENT_AGE_RANGE_MAX,
  PATIENT_AGE_RANGE_MIN,
  ListCompletedCasesActionType,
} from './ListPostOpCases.reducer';
import { Select } from '../../../components/Select';
import { useQuery } from '@apollo/client';
import { FIND_USERS_BY_ROLE } from '../../../gql';
import { groupUsersByRole } from '../../../utils/user';
import TagSelect from '../../../components/TagSelect';
import { UserAutocomplete } from '../../../components/UserAutocomplete';

export function PostOpCaseTableFilter(props: {
  state: {
    showSixWeekAnalysisCompleted?: boolean;
    showSixWeekImagingCompleted?: boolean;
    showHasPostOpData?: boolean;
    surgeon?: string;
    patientGenderFilter?: PatientGender[];
    levelPartFilter?: PartType[];
    patientAgeFilter?: number[];
    caseTags?: string[];
  };
  loading: boolean;
  dispatch: Dispatch<ListCompletedCasesActionType>;
}) {
  const [ageRange, setAgeRange] = React.useState<number[]>([
    PATIENT_AGE_RANGE_MIN,
    PATIENT_AGE_RANGE_MAX,
  ]);

  const [surgeons, setSurgeons] = useState<IUser[]>([]);
  const { data } = useQuery(FIND_USERS_BY_ROLE, {
    variables: [UserRoleType.Surgeon],
  });

  const handlePatientGenderSelectAll = () => {
    props.dispatch({
      type: 'PATIENT_GENDER_CHANGED',
      data: Object.values(PatientGender),
    });
  };

  const handlePatientGenderClearAll = () => {
    props.dispatch({
      type: 'PATIENT_GENDER_CHANGED',
      data: [],
    });
  };

  const handlePartTypeSelectAll = () => {
    props.dispatch({
      type: 'PART_TYPES_CHANGED',
      data: Object.values(PartType),
    });
  };

  const handlePartTypeClearAll = () => {
    props.dispatch({
      type: 'PART_TYPES_CHANGED',
      data: [],
    });
  };

  const handleDispatchAgeRangeChange = React.useCallback(
    _.debounce((ageRange: number[]) => {
      props.dispatch({
        type: 'PATIENT_AGE_CHANGED',
        data: ageRange,
      });
    }, 1000),
    [],
  );

  const handleAgeRangeChange = (_: any, newValue: number | number[]) => {
    setAgeRange(newValue as number[]);

    handleDispatchAgeRangeChange(newValue as number[]);
  };

  useEffect(() => {
    if (props.state.patientAgeFilter) {
      setAgeRange(props.state.patientAgeFilter);
    }
  }, [props.state.patientAgeFilter]);

  useEffect(() => {
    if (data?.users?.users?.length > 0) {
      const { surgeons } = groupUsersByRole(data?.users?.users);

      setSurgeons(surgeons);
    }
  }, [data?.users]);

  return (
    <Box display={'flex'} flexDirection={'column'} gap={2}>
      <Box>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={props.state.showSixWeekAnalysisCompleted}
                onChange={(event) => {
                  props.dispatch({
                    type: 'SHOW_SIX_WEEK_ANALYSIS_COMPLETED_CHANGED',
                    data: event.target.checked,
                  });
                }}
              />
            }
            label="Has Analysis"
          />
        </FormGroup>
      </Box>
      <Box>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={props.state.showSixWeekImagingCompleted}
                onChange={(event) => {
                  props.dispatch({
                    type: 'SHOW_SIX_WEEK_IMAGING_COMPLETED_CHANGED',
                    data: event.target.checked,
                  });
                }}
              />
            }
            label="Has Imaging"
          />
        </FormGroup>
      </Box>
      <Box>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={props.state.showHasPostOpData}
                onChange={(event) => {
                  props.dispatch({
                    type: 'SHOW_HAS_POST_OP_DATA_CHANGED',
                    data: event.target.checked,
                  });
                }}
              />
            }
            label="Has Data"
          />
        </FormGroup>
      </Box>
      <Box>
        <UserAutocomplete
          name={'surgeon'}
          label={'Surgeon'}
          value={props.state.surgeon?.toString()}
          onChange={(newVal) => {
            props.dispatch({
              type: 'SURGEON_CHANGED',
              data: newVal || '',
            });
          }}
          users={surgeons}
          startWithLastName
          disabled={props.loading}
          fullWidth={true}
        />
      </Box>
      <Box>
        <Select
          name={'gender'}
          label={'Patient Gender'}
          hideNone={true}
          value={
            props.state?.patientGenderFilter
              ? (props.state?.patientGenderFilter as unknown as string)
              : ([] as unknown as string)
          }
          onChange={(event) => {
            props.dispatch({
              type: 'PATIENT_GENDER_CHANGED',
              data: event.target.value,
            });
          }}
          menuItems={Object.entries(PatientGender).map(([label, key]) => ({ key, value: label }))}
          disabled={props.loading}
          fullWidth={true}
        />
      </Box>
      <Box>
        <Select
          name={'part-types'}
          label={'Part Types'}
          multiple={true}
          hideNone={true}
          value={
            props.state?.levelPartFilter
              ? (props.state?.levelPartFilter as unknown as string)
              : ([] as unknown as string)
          }
          onChange={(event) => {
            props.dispatch({
              type: 'PART_TYPES_CHANGED',
              data: event.target.value,
            });
          }}
          menuItems={Object.values(PartType).map((part) => ({ key: part, value: part }))}
          disabled={props.loading}
          fullWidth={true}
        />
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <Button fullWidth={true} size={'small'} onClick={handlePartTypeSelectAll}>
            Reset
          </Button>
          <Box height={15} mx={0.5}>
            <Divider orientation={'vertical'} />
          </Box>
          <Button fullWidth={true} size={'small'} onClick={handlePartTypeClearAll}>
            Clear
          </Button>
        </Box>
      </Box>
      <Box>
        <TagSelect
          label={'Case Tags'}
          value={props.state?.caseTags ? props.state?.caseTags : []}
          onChange={(newValue) => {
            props.dispatch({
              type: 'CASE_TAGS_CHANGED',
              data: newValue,
            });
          }}
          disabled={props.loading}
          fullWidth={true}
        />
      </Box>
      <Box>
        <Box mb={4}>
          <Typography color="textSecondary" gutterBottom>
            Patient Age range
          </Typography>
        </Box>
        <Box px={2}>
          <IOSSlider
            disabled={props.loading}
            value={ageRange}
            step={5}
            marks
            min={PATIENT_AGE_RANGE_MIN}
            max={PATIENT_AGE_RANGE_MAX}
            onChange={handleAgeRangeChange}
            valueLabelDisplay="on"
          />
        </Box>
      </Box>
      <Box>
        <Box textAlign={'center'}>
          <Button onClick={() => props.dispatch({ type: 'RESET_FILTERS' })}>Reset Filters</Button>
        </Box>
      </Box>
    </Box>
  );
}
