import { Divider, Stack } from '@mui/material';
import {
  CaseSpineProfile,
  ICase,
  IPlan,
  LevelType,
  MeasurementsVersionType,
  TableType,
  caseUtils,
  measurements,
} from '@workflow-nx/common';
import { IMeasure } from '@workflow-nx/core-algorithms';
import { DraggableCard, MeasurementsView } from '@workflow-nx/ui';
import config from '../../../../../extras/config';

export function MeasurementCardsView(props: {
  activeCase: ICase;
  caseMeasurementsVersion: MeasurementsVersionType;
  autoMeasurements?: IMeasure[];
  planVisible: boolean;
  preopDaisyVisible: boolean;
  preopVisible: boolean;
  plan?: IPlan;
  planDiff: IPlan | null;
}) {
  const caseSpineProfile: CaseSpineProfile = props.activeCase.spineProfile;
  const validCaseLevels: LevelType[] = caseUtils.getValidCaseLevels(
    props.activeCase.levels,
  ) as LevelType[];
  const pelvicIncidence: number | undefined =
    props.activeCase.patient.patientRecord.pelvicIncidence ?? 0;
  const slopeOfLineOfSight: number | undefined =
    props.activeCase.patient.patientRecord.slopeOfLineOfSight ?? 0;

  const preOpMeasurements: IMeasure[] = measurements.getMeasurementsFromAssetPositions(
    props.plan?.assetPositions.preop.points ?? [],
  );

  const planMeasurements: IMeasure[] = measurements.getMeasurementsFromAssetPositions(
    props.plan?.assetPositions.plan.points ?? [],
  );

  const planDiffMeasurements: IMeasure[] = measurements.getMeasurementsFromAssetPositions(
    props.planDiff?.assetPositions.plan.points ?? [],
  );
  return (
    <>
      {props.planVisible || props.preopVisible || props.preopDaisyVisible ? (
        <DraggableCard title={''} initialPosition={{ top: 85, left: 35 }} width={500}>
          <Stack
            spacing={2}
            divider={<Divider orientation="horizontal" flexItem />}
            sx={{ backgroundColor: '#ffffff' }}
          >
            {props.planVisible ? (
              <MeasurementsView
                measurementsVersion={props.caseMeasurementsVersion}
                spineType={props.activeCase.spineType}
                caseSpineProfile={caseSpineProfile}
                caseLevels={validCaseLevels}
                disableToggle={true}
                pelvicIncidence={pelvicIncidence}
                slopeOfLineOfSight={slopeOfLineOfSight}
                plan={planMeasurements}
                preop={preOpMeasurements}
                tableType={TableType.Plan}
                showErrors={true}
                size={'small'}
                measurementConfig={config.measurementsConfig}
              />
            ) : null}
            {props.planDiff ? (
              <MeasurementsView
                measurementsVersion={props.caseMeasurementsVersion}
                spineType={props.activeCase.spineType}
                caseSpineProfile={caseSpineProfile}
                caseLevels={validCaseLevels}
                disableToggle={true}
                pelvicIncidence={pelvicIncidence}
                slopeOfLineOfSight={slopeOfLineOfSight}
                plan={planDiffMeasurements}
                preop={preOpMeasurements}
                tableType={TableType.PlanDiff}
                showErrors={true}
                size={'small'}
                measurementConfig={config.measurementsConfig}
              />
            ) : null}
            {props.preopVisible && preOpMeasurements ? (
              <MeasurementsView
                measurementsVersion={props.caseMeasurementsVersion}
                spineType={props.activeCase.spineType}
                caseSpineProfile={caseSpineProfile}
                caseLevels={validCaseLevels}
                disableToggle={true}
                pelvicIncidence={pelvicIncidence}
                slopeOfLineOfSight={slopeOfLineOfSight}
                preop={preOpMeasurements}
                plan={[]}
                tableType={TableType.Preop}
                showErrors={true}
                size={'small'}
                measurementConfig={config.measurementsConfig}
              />
            ) : null}
            {props.preopDaisyVisible ? (
              <MeasurementsView
                measurementsVersion={props.caseMeasurementsVersion}
                spineType={props.activeCase.spineType}
                caseSpineProfile={caseSpineProfile}
                caseLevels={validCaseLevels}
                disableToggle={true}
                pelvicIncidence={pelvicIncidence}
                slopeOfLineOfSight={slopeOfLineOfSight}
                preop={props.autoMeasurements}
                tableType={TableType.Daisy}
                showErrors={true}
                size={'small'}
                measurementConfig={config.measurementsConfig}
              />
            ) : null}
          </Stack>
        </DraggableCard>
      ) : null}
    </>
  );
}
