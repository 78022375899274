import {
  CaseApproachType,
  CaseSpineProfile,
  CaseSpineType,
  CaseType,
  caseUtils,
  DEFAULT_SPINE_PROFILE,
  ICase,
  LevelType,
} from '@workflow-nx/common';
import { date } from '@workflow-nx/utils';
import * as Yup from 'yup';
import { TagOption, transformTagToTagOption } from '../../../../components/TagDetailsForm';

export const updateCaseDetailsSchema = Yup.object().shape({
  assignedId: Yup.number().nullable(),
  surgeonId: Yup.number().nullable(),
  locationId: Yup.number().nullable(),
  surgeryDate: Yup.date().nullable(),
  pelvicIncidence: Yup.number()
    .nullable()
    .min(1, 'Must be 1\u00b0 or more')
    .max(180, 'Must be 180\u00b0 or less'),
  levels: Yup.array().min(0, 'There must be at least level'),
});

export interface IUpdateCaseDetailsFormValues {
  approach?: CaseApproachType | '' | null;
  receivedAt?: Date | null;
  surgeryDate?: Date | null;
  caseType?: CaseType;
  isSurgeryDateTentative?: boolean;
  // includeRodTemplates?: boolean;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  gender?: string;
  birthDate?: Date | null;
  mrn?: string;
  // approach?: string;
  spineType?: CaseSpineType;
  spineProfile?: CaseSpineProfile;
  levelC2C3?: string;
  levelC3C4?: string;
  levelC4C5: string;
  levelC5C6: string;
  levelC6C7: string;
  levelC7C8: string;
  levelC8T1: string;
  levelC7T1: string;
  levelC6T1: string;
  levelL1L2?: string;
  levelL2L3?: string;
  levelL3L4?: string;
  levelL4L5?: string;
  levelL5S1?: string;
  levelL5L6?: string;
  levelL6S1?: string;
  levelL4S1?: string;
  levelC2C3Metadata?: { anteriorInstrumentation: boolean; otherVendor: boolean };
  levelC3C4Metadata?: { anteriorInstrumentation: boolean; otherVendor: boolean };
  levelC4C5Metadata?: { anteriorInstrumentation: boolean; otherVendor: boolean };
  levelC5C6Metadata?: { anteriorInstrumentation: boolean; otherVendor: boolean };
  levelC6C7Metadata?: { anteriorInstrumentation: boolean; otherVendor: boolean };
  levelC7C8Metadata?: { anteriorInstrumentation: boolean; otherVendor: boolean };
  levelC8T1Metadata?: { anteriorInstrumentation: boolean; otherVendor: boolean };
  levelC7T1Metadata?: { anteriorInstrumentation: boolean; otherVendor: boolean };
  levelC6T1Metadata?: { anteriorInstrumentation: boolean; otherVendor: boolean };
  levelL1L2Metadata?: { anteriorInstrumentation: boolean; otherVendor: boolean };
  levelL2L3Metadata?: { anteriorInstrumentation: boolean; otherVendor: boolean };
  levelL3L4Metadata?: { anteriorInstrumentation: boolean; otherVendor: boolean };
  levelL4L5Metadata?: { anteriorInstrumentation: boolean; otherVendor: boolean };
  levelL5S1Metadata?: { anteriorInstrumentation: boolean; otherVendor: boolean };
  levelL5L6Metadata?: { anteriorInstrumentation: boolean; otherVendor: boolean };
  levelL6S1Metadata?: { anteriorInstrumentation: boolean; otherVendor: boolean };
  levelL4S1Metadata?: { anteriorInstrumentation: boolean; otherVendor: boolean };
  pelvicIncidence?: number;
  slopeOfLineOfSight?: number;
  lumbarLordosis?: number;
  lumbarCoronalCobb?: number;
  sagittalVerticalAxis?: number;
  coronalBalance?: number;
  uiv?: string;
  liv?: string;
  pco?: string[];
  pso?: string[];
  surgeonId?: number;
  assignedId?: number;
  fieldRepId?: number;
  locationId?: number;
  tags: TagOption[];
  excludedInstruments?: string[];
}

export const updateCaseDetailsFormValues = (activeCase: ICase): IUpdateCaseDetailsFormValues => {
  const levels = activeCase?.levels ?? {};
  const levelsMetadata = activeCase?.levelsMetadata ?? {};
  const surgeryDate = date.parseCalendarDateFromString(activeCase.surgeryDate as unknown as string);
  const receivedAt = date.parseCalendarDateFromString(activeCase.receivedAt as unknown as string);
  const patient = activeCase?.patient;
  const patientRecord = patient?.patientRecord;
  const birthDate = date.parseCalendarDateFromString(patient?.birthDate as unknown as string);

  function getLevelMetadata(level: string) {
    if (levelsMetadata[level]) {
      const { anteriorInstrumentation, otherVendor } = levelsMetadata[level];
      if (anteriorInstrumentation || otherVendor) {
        const otherVendor = caseUtils.isValidLevelPartType(levels[level])
          ? false
          : !!levelsMetadata[level].otherVendor;
        return { anteriorInstrumentation, otherVendor };
      }
    }
    return undefined;
  }

  return {
    receivedAt,
    surgeryDate,
    approach: activeCase?.approach,
    isSurgeryDateTentative: activeCase?.isSurgeryDateTentative,
    caseType: activeCase?.caseType,
    spineType: activeCase?.spineType || CaseSpineType.Lumbar,
    spineProfile: activeCase?.spineProfile || DEFAULT_SPINE_PROFILE,
    firstName: patient?.firstName || '',
    middleName: patient?.middleName || '',
    lastName: patient?.lastName || '',
    gender: patient?.gender || '',
    birthDate,
    mrn: patient?.mrn || '',
    levelC2C3: levels[LevelType.C2C3] ?? 'NONE',
    levelC3C4: levels[LevelType.C3C4] ?? 'NONE',
    levelC4C5: levels[LevelType.C4C5] ?? 'NONE',
    levelC5C6: levels[LevelType.C5C6] ?? 'NONE',
    levelC6C7: levels[LevelType.C6C7] ?? 'NONE',
    levelC7C8: levels[LevelType.C7C8] ?? 'NONE',
    levelC8T1: levels[LevelType.C8T1] ?? 'NONE',
    levelC7T1: levels[LevelType.C7T1] ?? 'NONE',
    levelC6T1: levels[LevelType.C6T1] ?? 'NONE',
    levelL1L2: levels[LevelType.L1L2] ?? 'NONE',
    levelL2L3: levels[LevelType.L2L3] ?? 'NONE',
    levelL3L4: levels[LevelType.L3L4] ?? 'NONE',
    levelL4L5: levels[LevelType.L4L5] ?? 'NONE',
    levelL5S1: levels[LevelType.L5S1] ?? 'NONE',
    levelL6S1: levels[LevelType.L6S1] ?? 'NONE',
    levelL5L6: levels[LevelType.L5L6] ?? 'NONE',
    levelL4S1: levels[LevelType.L4S1] ?? 'NONE',
    levelC2C3Metadata: getLevelMetadata(LevelType.C2C3),
    levelC3C4Metadata: getLevelMetadata(LevelType.C3C4),
    levelC4C5Metadata: getLevelMetadata(LevelType.C4C5),
    levelC5C6Metadata: getLevelMetadata(LevelType.C5C6),
    levelC6C7Metadata: getLevelMetadata(LevelType.C6C7),
    levelC7C8Metadata: getLevelMetadata(LevelType.C7C8),
    levelC8T1Metadata: getLevelMetadata(LevelType.C8T1),
    levelC7T1Metadata: getLevelMetadata(LevelType.C7T1),
    levelC6T1Metadata: getLevelMetadata(LevelType.C6T1),
    levelL1L2Metadata: getLevelMetadata(LevelType.L1L2),
    levelL2L3Metadata: getLevelMetadata(LevelType.L2L3),
    levelL3L4Metadata: getLevelMetadata(LevelType.L3L4),
    levelL4L5Metadata: getLevelMetadata(LevelType.L4L5),
    levelL5S1Metadata: getLevelMetadata(LevelType.L5S1),
    levelL5L6Metadata: getLevelMetadata(LevelType.L5L6),
    levelL6S1Metadata: getLevelMetadata(LevelType.L6S1),
    levelL4S1Metadata: getLevelMetadata(LevelType.L4S1),
    pelvicIncidence: patientRecord?.pelvicIncidence || undefined,
    slopeOfLineOfSight: patientRecord?.slopeOfLineOfSight || undefined,
    lumbarLordosis: patientRecord?.lumbarLordosis || undefined,
    lumbarCoronalCobb: patientRecord?.lumbarCoronalCobb || undefined,
    sagittalVerticalAxis: patientRecord?.sagittalVerticalAxis || undefined,
    coronalBalance: patient.patientRecord?.coronalBalance || undefined,
    pco: activeCase.pco || undefined,
    pso: activeCase.pso || undefined,
    uiv: activeCase.uiv || undefined,
    liv: activeCase.liv || undefined,
    surgeonId: activeCase?.surgeonUser?.userId || undefined,
    assignedId: activeCase?.assignedUser?.userId || undefined,
    fieldRepId: activeCase?.fieldRepUser?.userId || undefined,
    locationId: activeCase?.location?.locationId || undefined,
    tags: activeCase?.caseTags?.map((caseTag) => transformTagToTagOption(caseTag.tag)) || undefined,
    excludedInstruments: activeCase?.excludedInstruments || [],
  };
};
