import React, { Dispatch, useContext, useReducer, useState } from 'react';
import Page from '../../../components/Page';
import { Badge, Box, Button, Card, CardContent, Container, Tab, Tabs } from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import { PostOpCaseReducer, PostOpCaseStateType } from './PostOpCase.reducer';
import { useNavigate, useParams } from 'react-router-dom';
import { IAsset, MeasurementsVersionType } from '@workflow-nx/common';
import { FIND_POST_OP_ANALYSIS_DATA, IMPORT_CASE_IMPLANT_SPECIFICATION } from '../../../gql';
import { CustomAppBar } from '../../../components/CustomAppBar';
import { ViewCaseDetails } from '../../cases/CaseView/CaseDetails/ViewCaseDetails';
import { EditCaseDetailsDialog } from '../../cases/CaseView/CaseDetails/EditCaseDetailsDialog';
import { DiscussionTabView } from '../../cases/CaseView/DiscussionTab/DiscussionTabView';
import { CaseViewContext, CaseViewProvider } from '../../cases/CaseView/CaseView.context';
import { MeasurementsVersionToggle } from '../../cases/CaseView/MeasurementsVersionToggle';
import { PostOpCaseDetailsView } from './PostOpCaseDetailsView';

function PostOpDetailsView({
  caseId,
  dispatch,
  loading,
  onMeasurementsVersionChange,
  onReload,
  state,
}: {
  caseId: string | undefined;
  loading: boolean;
  state: PostOpCaseStateType;
  dispatch: Dispatch<any>;
  onMeasurementsVersionChange: (measurementsVersion: MeasurementsVersionType) => void;
  onReload: () => void;
}) {
  const [viewTabValue, setViewTabValue] = useState('CASE');
  const [importCaseImplantSpecification, { loading: loadingImport }] = useMutation(
    IMPORT_CASE_IMPLANT_SPECIFICATION,
    { variables: { caseId: Number(caseId) } },
  );

  const caseViewContext = useContext(CaseViewContext);
  const [openEditCaseDialog, setOpenEditCaseDialog] = useState(false);

  const validAssets = [
    state.sixWeekAnalysisXRAsset,
    state.sixMonthAnalysisXRAsset,
    state.oneYearAnalysisXRAsset,
    state.twoYearAnalysisXRAsset,
    state.intraOpAnalysisXRAsset,
    state.postOpIntraOpStandingXRApAsset,
    state.sixWeekAnalysisStandingXRApAsset,
    state.sixMonthAnalysisStandingXRApAsset,
    state.oneYearAnalysisStandingXRApAsset,
    state.twoYearAnalysisStandingXRApAsset,
    state.postOpIntraOpStandingXRLateralAsset,
    state.sixWeekAnalysisStandingXRLateralAsset,
    state.sixMonthAnalysisStandingXRLateralAsset,
    state.oneYearAnalysisStandingXRLateralAsset,
    state.twoYearAnalysisStandingXRLateralAsset,
    state.sixWeekAnalysisCtAsset,
    state.sixMonthAnalysisCtAsset,
    state.oneYearAnalysisCtAsset,
    state.twoYearAnalysisCtAsset,
    state.sixWeekAnalysisMriAsset,
    state.sixMonthAnalysisMriAsset,
    state.oneYearAnalysisMriAsset,
    state.twoYearAnalysisMriAsset,
  ].filter((a) => Boolean(a)) as IAsset[];

  const handleUpdateCase = (shouldUpdate: boolean) => {
    if (shouldUpdate) {
      onReload();
    }
    setOpenEditCaseDialog(false);
  };

  const handleCaseImplantSpecificationsImport = async () => {
    try {
      await importCaseImplantSpecification();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <CustomAppBar
        title={`${state?.activeCase?.number} Post-Op Analysis`}
        navigateBackUrl={'/app/post-op-analysis'}
        actions={[
          <MeasurementsVersionToggle
            originalCaseMeasurementsVersion={state?.activeCase?.settings.measurementsVersion}
            selectedCaseMeasurementsVersion={caseViewContext.caseMeasurementsVersion}
            onChange={() => {
              onMeasurementsVersionChange(caseViewContext.toggleCaseMeasurementsVersion());
            }}
          />,
          <Button href={`/app/cases/${caseId}`}>View Case</Button>,
        ]}
      />
      {state?.activeCase ? (
        <Box my={2}>
          <Card>
            <CardContent>
              <Box m={1}>
                <ViewCaseDetails
                  activeCase={state?.activeCase}
                  onEdit={() => setOpenEditCaseDialog(true)}
                />
              </Box>
            </CardContent>
          </Card>
        </Box>
      ) : null}
      <Tabs
        value={viewTabValue}
        centered={true}
        indicatorColor="primary"
        textColor="primary"
        variant="standard"
        onChange={(_, newValue) => {
          setViewTabValue(newValue);
        }}
        style={{ marginBottom: 8 }}
      >
        <Tab label={'Post-op Details'} value={'CASE'} />
        <Tab
          label={
            <Badge badgeContent={state.commentCount} color="secondary">
              Discussion
            </Badge>
          }
          value={'DISCUSSION'}
        />
      </Tabs>
      <div role={'tabpanel'} hidden={viewTabValue !== 'CASE'}>
        {viewTabValue === 'CASE' ? (
          <Box mt={3}>
            <PostOpCaseDetailsView
              activeCase={state.activeCase}
              caseImplantSpecifications={state.caseImplantSpecifications}
              plan={state.plan}
              postOpIntraOp={state.postOpIntraOp}
              postOpSixWeeks={state.postOpSixWeeks}
              postOpSixMonths={state.postOpSixMonths}
              postOpOneYear={state.postOpOneYear}
              postOpTwoYears={state.postOpTwoYears}
              preOp={state.preOp}
              dispatch={dispatch}
              assets={validAssets}
              measurementsVersion={caseViewContext.caseMeasurementsVersion}
              loading={loading || loadingImport}
              onImport={handleCaseImplantSpecificationsImport}
              onReload={() => onReload()}
            />
          </Box>
        ) : null}
      </div>
      <div role={'tabpanel'} hidden={viewTabValue !== 'DISCUSSION'}>
        {viewTabValue === 'DISCUSSION' && state.activeCase ? (
          <DiscussionTabView caseId={state.activeCase.caseId} readOnly={true} />
        ) : null}
      </div>
      {state.activeCase ? (
        <EditCaseDetailsDialog
          open={openEditCaseDialog}
          activeCase={state.activeCase}
          onClose={handleUpdateCase}
        />
      ) : null}
    </>
  );
}

const PostOpCaseView = () => {
  const navigate = useNavigate();
  const { caseId } = useParams<{ caseId: string }>();

  const { refetch, loading } = useQuery(FIND_POST_OP_ANALYSIS_DATA, {
    variables: { caseId: Number(caseId) },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (!data?.case) {
        navigate('/app/post-op-analysis');

        return;
      }
      dispatch({ type: 'INIT', data });
    },
    onError: () => {
      navigate('/app/post-op-analysis');
    },
  });

  const initialState: PostOpCaseStateType = {
    activeCase: null,
  };

  const [state, dispatch] = useReducer(PostOpCaseReducer, initialState);
  return (
    <Page title={'Post-Op Analysis'}>
      <CaseViewProvider measurementsVersion={MeasurementsVersionType.Version2}>
        <Container maxWidth={'lg'}>
          <PostOpDetailsView
            caseId={caseId}
            state={state}
            loading={loading}
            dispatch={dispatch}
            onMeasurementsVersionChange={(measurementsVersion: MeasurementsVersionType) => {
              refetch({
                caseId: Number(caseId),
                // @ts-ignore
                measurementsVersion,
              });
            }}
            onReload={() => refetch()}
          />
        </Container>
      </CaseViewProvider>
    </Page>
  );
};

export default PostOpCaseView;
