import { useLazyQuery, useMutation } from '@apollo/client';
import { faChevronCircleDown, faChevronCircleUp } from '@fortawesome/pro-solid-svg-icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Typography } from '@mui/material';
import {
  IApplicationSettings,
  IOrganization,
  IUser,
  OrganizationType,
  UserRoleType,
  format,
} from '@workflow-nx/common';
import {
  Button,
  ErrorMessageRHF,
  IconFontButton,
  InputConfigRHF,
  InputRHF,
  InputTypeRHF,
  SwitchRHF,
} from '@workflow-nx/ui';
import { date } from '@workflow-nx/utils';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import { ComponentProps, useEffect, useState } from 'react';
import { Control, FieldValues, Resolver, SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { FIND_ORGANIZATIONS, FIND_USERS_BY_ROLE, UPSERT_SETTINGS } from '../../../gql';
import useAuth from '../../../hooks/useAuth';
import { FeatureFlag } from '../../../utils/featureFlags';
import { InputApplicationSettingsRHF } from './InputApplicationSettingsRHF';

export function ApplicationForm(props: {
  application: IApplicationSettings;
  onClose: (shouldUpdate: boolean) => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [upsertSettings] = useMutation(UPSERT_SETTINGS);
  const [vendorMenuItems, setVendorMenuItems] = useState<
    {
      key: string;
      value: string;
    }[]
  >([]);
  const [userMenuItems, setUserMenuItems] = useState<
    {
      key: string;
      value: string;
    }[]
  >([]);

  const [findVendors] = useLazyQuery(FIND_ORGANIZATIONS, {
    fetchPolicy: 'network-only',
    variables: { organizationTypeFilter: [OrganizationType.Vendor] },
    onCompleted: (orgData) => {
      const organizations = orgData.organizations.organizations || [];

      const vendorOptions: Array<{
        key: string;
        value: string;
      }> = organizations.map((org: IOrganization) => ({
        key: org.organizationId as unknown as string,
        value: org.name,
      }));

      setVendorMenuItems(vendorOptions);
    },
  });

  const [findUsers] = useLazyQuery(FIND_USERS_BY_ROLE, {
    fetchPolicy: 'network-only',
    variables: {
      roleFilter: [
        UserRoleType.CaseEngineer,
        UserRoleType.CaseAdmin,
        UserRoleType.Clinical,
        UserRoleType.CustomerRep,
        UserRoleType.FieldRep,
        UserRoleType.Finance,
        UserRoleType.Operations,
        UserRoleType.QualityEngineer,
        UserRoleType.SiteAdministrator,
        UserRoleType.SupplyChain,
        UserRoleType.Viewer,
      ],
    },
    onCompleted: (data) => {
      const users = data.users.users || [];

      const userOptions: Array<{
        key: string;
        value: string;
      }> = users.map((user: IUser) => ({
        key: user.userId as unknown as string,
        value: format.formatName(user),
      }));

      setUserMenuItems(userOptions);
    },
  });

  useEffect(() => {
    findVendors({});
    findUsers({});
  }, []);

  const [open, setOpen] = useState(false);
  const { hasFeatureFlag, hasRole } = useAuth();

  const applicationData: IApplicationSettings = {
    ...props?.application,
    product: {
      kitCartonStartDate:
        date.parseCalendarDateFromString(
          props?.application?.product?.kitCartonStartDate as string,
        ) ?? undefined,
      m4lStartDate:
        date.parseCalendarDateFromString(props?.application?.product?.m4lStartDate as string) ??
        undefined,
      tlifCM4lStartDate:
        date.parseCalendarDateFromString(
          props?.application?.product?.tlifCM4lStartDate as string,
        ) ?? undefined,
      tlifCOrientationStartDate:
        date.parseCalendarDateFromString(
          props?.application?.product?.tlifCOrientationStartDate as string,
        ) ?? undefined,
      alifXRotationLockStartDate:
        date.parseCalendarDateFromString(
          props?.application?.product?.alifXRotationLockStartDate as string,
        ) ?? undefined,
      tlifCArticulatingStartDate:
        date.parseCalendarDateFromString(
          props?.application?.product?.tlifCArticulatingStartDate as string,
        ) ?? undefined,
      reusableInstrumentsStartDate:
        date.parseCalendarDateFromString(
          props?.application?.product?.reusableInstrumentsStartDate as string,
        ) ?? undefined,
    },
    caseReports: {
      peerReviewUserIds: props?.application?.caseReports?.peerReviewUserIds ?? [],
      medicalReviewUserId: props?.application?.caseReports?.medicalReviewUserId ?? '',
    },
  };

  const applicationFormSchema = Yup.object().shape({
    product: Yup.object().shape({
      kitCartonStartDate: Yup.date().typeError('Invalid Date').required('Field is required'),
      m4lStartDate: Yup.date().typeError('Invalid Date').required('Field is required'),
      tlifCM4lStartDate: Yup.date().typeError('Invalid Date').required('Field is required'),
      tlifCOrientationStartDate: Yup.date().typeError('Invalid Date').required('Field is required'),
      alifXRotationLockStartDate: Yup.date()
        .typeError('Invalid Date')
        .required('Field is required'),
      tlifCArticulatingStartDate: Yup.date()
        .typeError('Invalid Date')
        .required('Field is required'),
      reusableInstrumentsStartDate: Yup.date()
        .typeError('Invalid Date')
        .required('Field is required'),
    }),
    operations: Yup.object().shape({
      defaultVendors: Yup.object().shape({
        additivePrinting: Yup.number().optional(),
        finalShipment: Yup.number().optional(),
      }),
    }),
    externalApps: Yup.object().shape({
      cyborgUrl: hasFeatureFlag?.(FeatureFlag.cyborgV2Enabled)
        ? Yup.string().required()
        : Yup.string(),
      ventiUrl: Yup.string().required(),
    }),
    caseReports: Yup.object().shape({
      peerReviewUserIds: Yup.array().of(Yup.number()),
      medicalReviewUserId: Yup.mixed(),
    }),
    featureFlags: Yup.object().shape({
      angledInstrumentsEnabled: Yup.boolean().required(),
      cervicalSupportEnabled: Yup.boolean().required(),
      cyborgV2Enabled: Yup.boolean().required(),
      fastImplantCuttingEnabled: Yup.boolean().required(),
      implantSizeExclusionEnabled: Yup.boolean().required(),
      reusableInstrumentsEnabled: Yup.boolean().required(),
      caseReportAutomationEnabled: Yup.boolean().required(),
    }),
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: applicationData,
    values: applicationData,
    resolver: yupResolver(applicationFormSchema) as unknown as Resolver<IApplicationSettings>,
  });

  const handleClose = () => {
    if (open) {
      props.onClose(false);
    }
    setOpen(!open);
  };

  const handleSubmitForm: SubmitHandler<IApplicationSettings> = async (data) => {
    try {
      const application: IApplicationSettings = {
        product: {
          kitCartonStartDate: data?.product?.kitCartonStartDate
            ? DateTime.fromJSDate(data?.product?.kitCartonStartDate as Date).toISODate()
            : undefined,
          m4lStartDate: data?.product?.m4lStartDate
            ? DateTime.fromJSDate(data?.product?.m4lStartDate as Date).toISODate()
            : undefined,
          tlifCM4lStartDate: data?.product?.tlifCM4lStartDate
            ? DateTime.fromJSDate(data?.product?.tlifCM4lStartDate as Date).toISODate()
            : undefined,
          tlifCOrientationStartDate: data?.product?.tlifCOrientationStartDate
            ? DateTime.fromJSDate(data?.product?.tlifCOrientationStartDate as Date).toISODate()
            : undefined,
          alifXRotationLockStartDate: data?.product?.alifXRotationLockStartDate
            ? DateTime.fromJSDate(data?.product?.alifXRotationLockStartDate as Date).toISODate()
            : undefined,
          tlifCArticulatingStartDate: data?.product?.tlifCArticulatingStartDate
            ? DateTime.fromJSDate(data?.product?.tlifCArticulatingStartDate as Date).toISODate()
            : undefined,
          reusableInstrumentsStartDate: data?.product?.reusableInstrumentsStartDate
            ? DateTime.fromJSDate(data?.product?.reusableInstrumentsStartDate as Date).toISODate()
            : undefined,
        },
        operations: {
          defaultVendors: {
            additivePrinting: data.operations?.defaultVendors?.additivePrinting,
            finalShipment: data.operations?.defaultVendors?.finalShipment,
          },
        },
        externalApps: {
          cyborgUrl: data.externalApps.cyborgUrl,
          ventiUrl: data.externalApps.ventiUrl,
        },
        caseReports: {
          peerReviewUserIds: data.caseReports.peerReviewUserIds ?? [],
          medicalReviewUserId: data.caseReports.medicalReviewUserId ?? '',
        },
        featureFlags: {
          angledInstrumentsEnabled: data.featureFlags.angledInstrumentsEnabled,
          cervicalSupportEnabled: data.featureFlags.cervicalSupportEnabled,
          cyborgV2Enabled: data.featureFlags.cyborgV2Enabled,
          fastImplantCuttingEnabled: data.featureFlags.fastImplantCuttingEnabled,
          implantSizeExclusionEnabled: data.featureFlags.implantSizeExclusionEnabled,
          reusableInstrumentsEnabled: data.featureFlags.reusableInstrumentsEnabled,
          caseReportAutomationEnabled: data.featureFlags.caseReportAutomationEnabled,
        },
      };

      await upsertSettings({
        variables: {
          application: application,
        },
      });

      setOpen(false);
      enqueueSnackbar('Application settings updated', {
        variant: 'success',
      });
      props.onClose(true);
    } catch (err) {
      console.error(err);
      enqueueSnackbar('An error occurred updating the Application settings', {
        variant: 'error',
      });
    }
  };

  const productDatesFormItems: InputConfigRHF[] = [
    {
      id: 'product.kitCartonStartDate',
      label: 'Kit Carton Start Date',
      input: InputTypeRHF.Date,
    },

    {
      id: 'product.m4lStartDate',
      label: 'M4L Start Date',
      input: InputTypeRHF.Date,
    },

    {
      id: 'product.tlifCM4lStartDate',
      label: 'TLIF/C M4L Start Date',
      input: InputTypeRHF.Date,
    },

    {
      id: 'product.tlifCOrientationStartDate',
      label: 'TLIF/C Orientation Start Date',
      input: InputTypeRHF.Date,
    },

    {
      id: 'product.alifXRotationLockStartDate',
      label: 'ALIF/X Rotation Lock Start Date',
      input: InputTypeRHF.Date,
    },

    {
      id: 'product.tlifCArticulatingStartDate',
      label: 'TLIF/CA (Articulating) Start Date',
      input: InputTypeRHF.Date,
    },
    {
      id: 'product.reusableInstrumentsStartDate',
      label: 'Reusable Instruments Start Date',
      input: InputTypeRHF.Date,
    },
  ];

  const externalAppsFormItems: InputConfigRHF[] = [
    {
      id: 'externalApps.cyborgUrl',
      label: 'Cyborg Url',
      input: InputTypeRHF.Text,
    },
    {
      id: 'externalApps.ventiUrl',
      label: 'VENTI Url',
      input: InputTypeRHF.Text,
    },
  ];

  const caseReportsFormItems: InputConfigRHF[] = [
    {
      id: 'caseReports.peerReviewUserIds',
      label: 'Peer Reviewers',
      input: InputTypeRHF.Select,
      menuItems: userMenuItems,
      multiple: true,
    },
    {
      id: 'caseReports.medicalReviewUserId',
      label: 'Medical Reviewer',
      input: InputTypeRHF.Select,
      menuItems: userMenuItems,
    },
  ];

  const operationsFormItems: InputConfigRHF[] = [
    {
      id: 'operations.defaultVendors.additivePrinting',
      label: 'Additive Printing Vendor',
      input: InputTypeRHF.Select,
      menuItems: vendorMenuItems,
    },
    {
      id: 'operations.defaultVendors.finalShipment',
      label: 'Final Shipment Vendor',
      input: InputTypeRHF.Select,
      menuItems: vendorMenuItems,
    },
  ];

  const featureFlagsFormItems: {
    id: string;
    label: string;
    description: string;
  }[] = [
    {
      id: 'featureFlags.angledInstrumentsEnabled',
      label: 'Angled Instruments',
      description: 'Show pelvis generation details and allow angle selection on implants.',
    },
    {
      id: 'featureFlags.cervicalSupportEnabled',
      label: 'Cervical Support',
      description: 'Cervical support allows the creation and planning of cervical cases.',
    },
    {
      id: 'featureFlags.cyborgV2Enabled',
      label: 'Cyborg V2',
      description: 'Cyborg V2 is tightly integrated with DPS.',
    },
    {
      id: 'featureFlags.fastImplantCuttingEnabled',
      label: 'Fast Implant Cutting',
      description: 'Speed up cutting of implants in IDE.',
    },
    {
      id: 'featureFlags.implantSizeExclusionEnabled',
      label: 'Implant Size Exclusion',
      description: 'The option to exclude plus-size implants from being generated for the case.',
    },
    {
      id: 'featureFlags.reusableInstrumentsEnabled',
      label: 'Reusable Instruments',
      description: 'Enables the feature of reusable instruments.',
    },
    {
      id: 'featureFlags.caseReportAutomationEnabled',
      label: 'Case Report Automation',
      description: 'Enables the feature of Case Report Automation.',
    },
  ];

  const getDatePickerInputProps = (
    config: InputConfigRHF,
  ): ComponentProps<typeof InputApplicationSettingsRHF> => ({
    config: config,
    control: control as unknown as Control<FieldValues>,
    disabled: isSubmitting,
    datePickerProps: {
      disableFuture: false,
    },
  });

  return (
    <>
      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        <Typography variant={'h4'}>Configure Application</Typography>
        <Box>
          <IconFontButton
            icon={open ? faChevronCircleUp : faChevronCircleDown}
            onClick={handleClose}
          />
        </Box>
      </Box>
      {open ? (
        <Box m={1}>
          <Box>
            <Typography variant={'h4'}>Product Dates</Typography>
            <Box mb={2} />
            <Grid container spacing={3}>
              {productDatesFormItems.map((config) => {
                return (
                  <InputApplicationSettingsRHF
                    key={config.id}
                    {...getDatePickerInputProps(config)}
                  />
                );
              })}
            </Grid>
          </Box>
          <Box>
            <Typography variant={'h4'}>Operations</Typography>
            <Box mb={2} />
            <Grid container spacing={3}>
              {operationsFormItems.map((config) => {
                return (
                  <InputRHF config={config} control={control as unknown as Control<FieldValues>} />
                );
              })}
            </Grid>
          </Box>
          <Box my={2}>
            <Typography variant={'h4'}>External Apps</Typography>
            <Box mb={2} />
            <Grid container spacing={3}>
              {externalAppsFormItems.map((config) => {
                return (
                  <InputRHF config={config} control={control as unknown as Control<FieldValues>} />
                );
              })}
            </Grid>
          </Box>
          <Box my={2}>
            <Typography variant={'h4'}>Case Reports</Typography>
            <Box mb={2} />
            <Grid container spacing={3}>
              {caseReportsFormItems.map((config) => {
                return (
                  <InputRHF
                    config={config}
                    control={control as unknown as Control<FieldValues>}
                    selectFieldProps={{ multiple: config.multiple }}
                  />
                );
              })}
            </Grid>
          </Box>
          {hasRole?.([UserRoleType.SiteAdministrator]) ? (
            <Box my={2}>
              <Box mb={2} />
              <Typography variant={'h4'}>Feature Flags</Typography>
              <Box mb={2} />
              <Grid container spacing={3}>
                {featureFlagsFormItems.map((config) => {
                  return (
                    <Grid item container xs={12} spacing={1} alignItems={'center'}>
                      <Grid item xs={3} md={3} display={'flex'} alignItems={'center'}>
                        <Typography>{config.label}</Typography>
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <SwitchRHF
                          name={config.id}
                          control={control as unknown as Control<FieldValues>}
                        />
                        <Typography color={'textSecondary'}>{config.description}</Typography>
                      </Grid>
                      <Grid item xs={3} md={3} display={'flex'} justifyContent={'center'}>
                        <ErrorMessageRHF
                          name={config.id}
                          control={control as unknown as Control<FieldValues>}
                        />
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          ) : null}
          <Box mt={4} display={'flex'} justifyContent={'flex-end'}>
            <Button
              variant={'outlined'}
              color={'primary'}
              label={'Save Application Settings'}
              onClick={(evt) => handleSubmit(handleSubmitForm)(evt)}
            />
          </Box>
        </Box>
      ) : null}
    </>
  );
}
