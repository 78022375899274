import { useQuery } from '@apollo/client';
import { FormControl, Autocomplete, TextField } from '@mui/material';
import { ITag } from '@workflow-nx/common';
import React, { useState } from 'react';
import { FIND_TAGS } from '../gql';
import _ from 'lodash';

type TagOption = {
  key: string;
  value: string;
  categoryName: string;
};

function TagSelect({
  disabled,
  fullWidth = true,
  label,
  value,
  onChange,
  variant,
}: {
  disabled?: boolean;
  fullWidth?: boolean;
  label?: string;
  value?: string[];
  onChange?: (newValue: string[]) => void;
  variant?: 'outlined' | 'standard';
}) {
  const [tagOptions, setTagOptions] = useState<TagOption[]>([]);
  const [currentTagValues, setCurrentTagValues] = useState<TagOption[]>([]);

  const { loading: tagsLoading } = useQuery(FIND_TAGS, {
    onCompleted: (tagsData) => {
      if (!tagsData || !tagsData?.tags?.length) return;
      const sortedTags = _.sortBy(tagsData.tags, ['tagCategory.label', 'label']);

      setTagOptions(
        sortedTags.map((tag: ITag) => ({
          key: tag.tagId.toString(),
          value: tag.label,
          categoryName: tag.tagCategory?.label ?? 'Uncategorized',
        })),
      );
      if (value) {
        setCurrentTagValues(
          value.flatMap((currentValue) => {
            const foundTag = sortedTags.find((tag: ITag) => tag.tagId.toString() === currentValue);
            if (foundTag) {
              return {
                key: currentValue,
                value: foundTag.label,
                categoryName: foundTag.tagCategory?.label ?? 'Uncategorized',
              };
            } else {
              return [];
            }
          }),
        );
      }
    },
  });

  return (
    <FormControl fullWidth={fullWidth} size={'small'} variant={variant}>
      <Autocomplete
        clearOnBlur={true}
        clearOnEscape={true}
        disabled={tagsLoading}
        disableCloseOnSelect={true}
        multiple
        value={currentTagValues}
        options={tagOptions}
        size={'small'}
        onChange={(e, value) => {
          setCurrentTagValues(value);
          if (onChange && !disabled) {
            onChange(value?.map((val) => val.key) ?? []);
          }
        }}
        groupBy={(option) => {
          return option.categoryName;
        }}
        isOptionEqualToValue={(option, value) => option.key === value.key}
        getOptionLabel={(option) => option.value}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            disabled={disabled}
            tabIndex={1}
            required={false}
            inputProps={{
              ...params.inputProps,
            }}
            name={'tag list'}
            onKeyDown={(e) => {
              // stop enter from submitting a form
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
          />
        )}
      />
    </FormControl>
  );
}

export default TagSelect;
