import { TableRow } from '@mui/material';
import { TableCell } from '../../../../components/TableCell';
import { ImageField } from '../../../../components/ImageField';
import { LevelType } from '@workflow-nx/common';
import React from 'react';

export function Form19AdditionalImageTableRow(props: {
  value: any;
  levelIndex: number;
  valueIndex: number;
  disabled: boolean;
  loading: boolean;
  onRemove: (levelType: LevelType, assetId: number | undefined) => void;
}) {
  return (
    <TableRow hover>
      <TableCell colSpan={4}>
        <ImageField
          asset={props.value.asset}
          levelType={props.value.level}
          name={`levels[${props.levelIndex}].values[${props.valueIndex}].additionalImage`}
          label={'Select Implants Additional Image'}
          loading={props.loading}
          onRemove={props.onRemove}
          disabled={props.disabled}
        />
      </TableCell>
    </TableRow>
  );
}
