import { format, IUser, UserRoleType } from '@workflow-nx/common';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Box, Card, CardContent, Link, Skeleton, Stack, Typography } from '@mui/material';
import CustomDialog from '../../../components/CustomDialog';
import { Button, ProgressButton } from '@workflow-nx/ui';
import { MultiUserAutocomplete } from '../../../components/UserAutocomplete';
import { groupUsersByRole } from '../../../utils/user';
import { gql } from '@apollo/client/core';

export function EditAssociatedUsersDialog({
  user,
  role,
  onClose,
}: {
  user: IUser;
  role: UserRoleType;
  onClose: (shouldUpdate: boolean) => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [updateUser, { loading: loadingUpdateUser }] = useMutation(gql`
    mutation UpdateUser($userId: Int!, $associatedUserIds: [Int]!) {
      updateUser(userId: $userId, input: { associatedUserIds: $associatedUserIds }) {
        user {
          userId
        }
      }
    }
  `);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentAssociatedUsers, setCurrentAssociatedUsers] = useState<IUser[]>([]);
  const [currentAssociatedUserIds, setCurrentAssociatedUserIds] = useState<number[]>([]);
  const [associatedUsers, setAssociatedUsers] = useState<IUser[]>([]);

  const { loading } = useQuery(
    gql`
      query FindUsers($roleFilter: [UserRole]) {
        users(roleFilter: $roleFilter) {
          users {
            userId
            firstName
            lastName
            role
            status
            associatedUserIds
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      variables: {
        roleFilter: [UserRoleType.Surgeon, UserRoleType.SalesRep],
      },
      onCompleted: (data) => {
        const users = data?.users?.users ?? [];
        let currentAssociatedUsers = [] as IUser[];
        let currentAssociatedUserIds = [] as number[];
        let updatedAssociatedUsers = [] as IUser[];

        if (users.length > 0) {
          const groupedUsers = groupUsersByRole(users);

          if (role === UserRoleType.Surgeon) {
            currentAssociatedUsers = groupedUsers.surgeons.filter((surgeon) =>
              user?.associatedUserIds?.includes(surgeon.userId),
            );
            updatedAssociatedUsers = groupedUsers.surgeons;
            currentAssociatedUserIds = currentAssociatedUsers.map((user) => user.userId);
          }

          if (role === UserRoleType.SalesRep) {
            currentAssociatedUsers = groupedUsers.salesReps.filter((salesRep) =>
              salesRep?.associatedUserIds?.includes(user.userId),
            );
            updatedAssociatedUsers = groupedUsers.salesReps;
            currentAssociatedUserIds = currentAssociatedUsers.map((user) => user.userId);
          }
        }

        setCurrentAssociatedUsers(currentAssociatedUsers);
        setCurrentAssociatedUserIds(currentAssociatedUserIds);
        setAssociatedUsers(updatedAssociatedUsers);
      },
    },
  );

  const handleSubmitForm = async () => {
    try {
      await updateUser({
        variables: {
          userId: user.userId,
          associatedUserIds: currentAssociatedUserIds,
        },
      });

      enqueueSnackbar('User Updated', {
        variant: 'success',
      });

      setOpenDialog(false);
      onClose(true);
    } catch (err) {
      console.error(err);
      enqueueSnackbar('An error occurred updating the user', {
        variant: 'error',
      });
    }
  };

  return (
    <>
      <CustomDialog
        maxWidth={'sm'}
        open={openDialog}
        title={`Edit ${format.formatUserRole(role)}s - ${format.formatName(user)}`}
        onClose={() => {
          setOpenDialog(false);
          onClose(false);
        }}
        positiveActionButtons={[
          <ProgressButton
            onClick={() => handleSubmitForm()}
            loading={loadingUpdateUser}
            disabled={loadingUpdateUser}
            label={'Save'}
          />,
        ]}
        negativeActionButtons={[]}
      >
        {openDialog ? (
          <MultiUserAutocomplete
            name={'associatedUserId'}
            label={format.formatUserRole(role)}
            value={currentAssociatedUsers}
            onChange={(userIds) => {
              const updatedCurrentAssociatedUsers: IUser[] = [];
              const updatedCurrentAssociatedUserIds: number[] = [];

              for (let userId of userIds) {
                const foundUser = associatedUsers.find((user) => user?.userId === Number(userId));
                if (foundUser) {
                  updatedCurrentAssociatedUsers.push(foundUser);
                  updatedCurrentAssociatedUserIds.push(Number(userId));
                }
              }

              setCurrentAssociatedUsers(updatedCurrentAssociatedUsers);
              setCurrentAssociatedUserIds(updatedCurrentAssociatedUserIds);
            }}
            users={associatedUsers}
            disabled={loadingUpdateUser}
            fullWidth={true}
            startWithLastName
          />
        ) : null}
      </CustomDialog>
      <Card>
        <CardContent sx={{ px: 3 }}>
          <Stack flexDirection={'row'} justifyContent={'space-between'} sx={{ mb: 2 }}>
            <Typography variant={'h4'}>Associated {format.formatUserRole(role)}s</Typography>
            <Box display={'flex'} justifyContent={'flex-end'}>
              <Button
                onClick={() => setOpenDialog(true)}
                variant={'contained'}
                label={`Edit ${format.formatUserRole(role)}s`}
              />
            </Box>
          </Stack>
          <Stack direction={'row'} alignItems={'center'} spacing={2} flexWrap={'wrap'}>
            {!loading &&
              currentAssociatedUsers.map((associatedUser) => {
                return (
                  <Link
                    href={`/app/${role === UserRoleType.Surgeon ? 'surgeon' : 'user'}/${
                      associatedUser.userId
                    }`}
                    noWrap={true}
                  >
                    <Typography variant={'body1'}>{format.formatName(associatedUser)}</Typography>
                  </Link>
                );
              })}
            {!loading && !associatedUsers?.length ? (
              <Typography variant={'body1'}>No one is associated with this user</Typography>
            ) : null}
            {loading && <Skeleton height={24} style={{ width: '75%' }} />}
          </Stack>
        </CardContent>
      </Card>
    </>
  );
}
