import { ITableHeader } from '@workflow-nx/common';
import React, { ReactNode } from 'react';
import {
  Box,
  IconButton,
  Table,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { TableHeadCell } from './TableHeadCell';
import { LoadingTableBody } from './LoadingTableBody';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBackwardStep,
  faChevronLeft,
  faChevronRight,
  faForwardStep,
} from '@fortawesome/pro-light-svg-icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }),
);

const useTableStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
    },
    tableContainer: {
      flex: 1,
      overflow: 'auto',
    },
    footer: {
      width: '100%',
      display: 'flex',
    },
    footerRow: {
      width: '100%',
      display: 'flex',
      flex: 1,
    },
    footerPagination: {
      flexDirection: 'row',
      flex: 1,
    },
  }),
);

function TablePaginationActions(props: TablePaginationActionsProps) {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
        size="large"
      >
        {theme.direction === 'rtl' ? (
          <FontAwesomeIcon icon={faForwardStep} />
        ) : (
          <FontAwesomeIcon icon={faBackwardStep} />
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        size="large"
      >
        {theme.direction === 'rtl' ? (
          <FontAwesomeIcon icon={faChevronRight} />
        ) : (
          <FontAwesomeIcon icon={faChevronLeft} />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        size="large"
      >
        {theme.direction === 'rtl' ? (
          <FontAwesomeIcon icon={faChevronLeft} />
        ) : (
          <FontAwesomeIcon icon={faChevronRight} />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
        size="large"
      >
        {theme.direction === 'rtl' ? (
          <FontAwesomeIcon icon={faBackwardStep} />
        ) : (
          <FontAwesomeIcon icon={faForwardStep} />
        )}
      </IconButton>
    </div>
  );
}

export function CommonTable(props: {
  headers: ITableHeader[];
  loading?: boolean;
  rows: ReactNode;
  onHeaderClick?: (orderBy: { [key: string]: 'asc' | 'desc' }) => void;
  orderBy?: any;
  paginationConfig?: {
    totalCount: number;
    rowsPerPageOptions?: number[];
    rowsPerPage: number;
    page: number;
    onPaginationChange: (config: { page: number; rowsPerPage: number }) => void;
  };
}) {
  const styles = useTableStyles();

  const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    props.paginationConfig?.onPaginationChange({
      page: newPage,
      rowsPerPage: props.paginationConfig.rowsPerPage,
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);

    props.paginationConfig?.onPaginationChange({ page: 0, rowsPerPage: newRowsPerPage });
  };

  return (
    <Box className={styles.root}>
      <TableContainer className={styles.tableContainer}>
        <Table stickyHeader={true} size={'small'}>
          <TableHead>
            <TableRow>
              {props.headers.map((header) => {
                return (
                  <TableHeadCell
                    key={header.id}
                    id={header.id}
                    orderBy={props?.orderBy}
                    align={header.align}
                    sortable={header.sortable}
                    onClick={props?.onHeaderClick}
                  >
                    {header?.customLabel ? (
                      header.customLabel
                    ) : (
                      <Typography variant={'body1'} noWrap={true} color={'textSecondary'}>
                        {header.label}
                      </Typography>
                    )}
                  </TableHeadCell>
                );
              })}
            </TableRow>
          </TableHead>
          {!props.loading ? props.rows : null}
          {props.loading ? <LoadingTableBody rows={10} cols={props.headers.length} /> : null}
        </Table>
      </TableContainer>
      {props?.paginationConfig ? (
        <Table>
          <TableFooter className={styles.footer}>
            <TableRow className={styles.footerRow}>
              <TablePagination
                className={styles.footerPagination}
                rowsPerPageOptions={props.paginationConfig?.rowsPerPageOptions || [5, 10, 25]}
                colSpan={3}
                count={props.paginationConfig.totalCount}
                rowsPerPage={props.paginationConfig.rowsPerPage}
                page={props.paginationConfig.page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      ) : null}
    </Box>
  );
}
