import { IUser, Permission } from '@workflow-nx/common';

export const canEditSurgeon = (user: IUser, currentUser?: IUser) => {
  if (!currentUser) return false;

  let isEditable = currentUser.permissions.includes(Permission.ManageSurgeon);

  if (user.deletedAt) {
    isEditable = false;
  }

  return isEditable;
};

export const canEditSurgeonPreferences = (user: IUser, currentUser?: IUser) => {
  if (!currentUser) return false;

  let isEditable = currentUser.permissions.includes(Permission.ManageSurgeonPreferences);

  if (user.deletedAt) {
    isEditable = false;
  }

  return isEditable;
};
