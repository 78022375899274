import { Box, Card, CardContent, LinearProgress, Typography } from '@mui/material';
import React from 'react';

export function TabLoadingView(props: { label?: string }) {
  return (
    <Card>
      <CardContent>
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          height={'20vh'}
          justifyContent={'center'}
        >
          <LinearProgress color="secondary" style={{ width: '95%' }} />
          <Box mt={1} />
          <Typography color={'textSecondary'} variant={'body1'}>
            {props.label ?? 'Loading'}...
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}
