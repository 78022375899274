import React, { useReducer } from 'react';
import { Box, Card, CardContent, Container, Grid, Typography, useTheme } from '@mui/material';
import Page from '../../../components/Page';
import CaseTriageListTable from './CaseTriageListTable';
import { useQuery } from '@apollo/client';
import { FIND_CASES } from '../../../gql';
import { CustomAppBar } from '../../../components/CustomAppBar';
import {
  CaseCancellationListType,
  CaseStageType,
  CaseType,
  UserRoleType,
} from '@workflow-nx/common';
import { Alert } from '@mui/material';
import { Heading } from '../../../components/Heading';
import SearchInput from '../../../components/SearchInput';
import { ListCaseTriageReducer, ListCaseTriageStateType } from './ListCaseTriage.reducer';
import useAuth from '../../../hooks/useAuth';

interface IListCaseTriageQueryVariables {
  caseCancellationTypeFilter?: CaseCancellationListType[];
  caseTypeFilter: CaseType[];
  orderBy: { surgeryDate: string };
  search: string;
  stageFilter: CaseStageType[];
}

function getDefaultState(stageFilter: CaseStageType[]) {
  let defaultVariables: IListCaseTriageQueryVariables;
  let initialState: ListCaseTriageStateType;

  defaultVariables = {
    caseCancellationTypeFilter: [CaseCancellationListType.None],
    caseTypeFilter: [CaseType.Live],
    stageFilter: stageFilter,
    orderBy: { surgeryDate: 'asc' },
    search: '',
  };

  initialState = {
    orderBy: { surgeryDate: 'asc' },
    search: '',
  };

  return [defaultVariables, initialState];
}

const ListCaseTriageView = () => {
  const theme = useTheme();
  const auth = useAuth();

  const defaultStageFilter: CaseStageType[] = auth?.hasRole?.([UserRoleType.SegmentationEngineer])
    ? [CaseStageType.Open, CaseStageType.Segmentation]
    : [
        CaseStageType.Open,
        CaseStageType.Segmentation,
        CaseStageType.Planning,
        CaseStageType.Proposed,
        CaseStageType.Design,
        CaseStageType.QaReview,
      ];

  const [defaultVariables, initialState] = getDefaultState(defaultStageFilter);
  const { loading, data, refetch } = useQuery(FIND_CASES, {
    variables: defaultVariables,
    fetchPolicy: 'cache-and-network',
  });

  const [state, dispatch] = useReducer(
    ListCaseTriageReducer(refetch),
    initialState as ListCaseTriageStateType,
  );

  return (
    <>
      <Page title={'Case Triage'}>
        <Container maxWidth={false}>
          <CustomAppBar title={`Case Triage`} />
          <Box>
            <Grid container>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display={'flex'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      mb={1}
                    >
                      <Heading label={`Results (${data?.cases?.count ?? 0} cases)`} />
                      <Box width={250} bgcolor={theme.palette.background.paper}>
                        <SearchInput
                          value={state.search ?? ''}
                          onChange={(value: string) =>
                            dispatch({ type: 'SEARCH_CHANGED', data: value })
                          }
                        />
                      </Box>
                    </Box>
                    <Box>
                      <Card>
                        <CardContent style={{ height: 'calc(100vh - 228px)' }}>
                          <Box
                            mb={1}
                            border={1}
                            borderRadius="5px"
                            p={1}
                            borderColor={theme.palette.divider}
                          >
                            <Typography variant={'body1'} color={'textSecondary'}>
                              Days to Mfg CW (Manufacturing Close Window) is 28 days from the
                              surgery date
                            </Typography>
                          </Box>

                          {data?.cases?.count ? (
                            <CaseTriageListTable
                              loading={loading}
                              orderBy={state.orderBy}
                              dispatch={dispatch}
                              cases={data?.cases?.cases}
                            />
                          ) : (
                            <Alert severity={'warning'}>No cases match the filter criteria. </Alert>
                          )}
                        </CardContent>
                      </Card>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Page>
    </>
  );
};

export default ListCaseTriageView;
