import { Box } from '@mui/material';
import { IAxes, IMeasure, IPlanImplant, LevelType } from '@workflow-nx/common';
import { Mesh } from 'babylonjs';
import {
  CoordinateInputContainer,
  CoordinateTypes,
  CoordinateValueTypes,
  DirectionalKeyboardMappingTypes,
} from './CoordinateInputContainer';
import InlineNummericTextField from './InlineNummericTextField';
import { repositionMesh } from './MoveImplantView.helpers';

export type MeshCoordinateState = {
  [CoordinateValueTypes.XPosition]: number;
  [CoordinateValueTypes.YPosition]: number;
  [CoordinateValueTypes.ZPosition]: number;
  [CoordinateValueTypes.XRotation]: number;
  [CoordinateValueTypes.YRotation]: number;
  [CoordinateValueTypes.ZRotation]: number;
};

const ImplantTransformationControls = (props: {
  active: boolean;
  controlsType: CoordinateTypes;
  coordinates: MeshCoordinateState;
  secondaryCoordinates: MeshCoordinateState;
  mesh: Mesh;
  secondaryMesh: Mesh;
  onlyMoveSecondary: boolean;
  originalTransform: IPlanImplant | null;
  axes: IAxes | undefined;
  measurements: IMeasure[];
  handleInputComplete: (axes: IAxes | undefined, levelType: LevelType) => void;
  handleInputChange: (value: number, coordinateValueType: CoordinateValueTypes) => void;
  levelType: LevelType;
}) => {
  const coordinates = props.onlyMoveSecondary ? props.secondaryCoordinates : props.coordinates;

  const handleTranslationInput = (value: number, coordinateValueType: CoordinateValueTypes) => {
    if (!props.onlyMoveSecondary)
      handleMeshTranslationInput(props.mesh, value, coordinateValueType);

    handleMeshTranslationInput(props.secondaryMesh, value, coordinateValueType);
  };

  const handleMeshTranslationInput = (
    thisMesh: Mesh,
    value: number,
    coordinateValueType: CoordinateValueTypes,
  ) => {
    if (!props.originalTransform) return;

    props.handleInputChange(value, coordinateValueType);

    if (Number.isNaN(value) || Number.isNaN(coordinates[coordinateValueType])) return;

    const delta = value - coordinates[coordinateValueType];

    const result = repositionMesh(
      thisMesh,
      props.axes,
      props.levelType,
      props.measurements,
      delta,
      coordinateValueType,
    );

    props.handleInputComplete(result.axes, props.levelType);
  };

  const handleIncrementTranslation = (value: number, coordinateValueType: CoordinateValueTypes) => {
    if (!props.onlyMoveSecondary)
      handleMeshIncrementTranslation(props.mesh, value, coordinateValueType);

    handleMeshIncrementTranslation(props.secondaryMesh, value, coordinateValueType);
  };

  const handleMeshIncrementTranslation = (
    thisMesh: Mesh,
    value: number,
    coordinateValueType: CoordinateValueTypes,
  ) => {
    const roundedValue = value + coordinates[coordinateValueType];
    handleMeshTranslationInput(thisMesh, roundedValue, coordinateValueType);
  };

  const xType =
    props.controlsType === CoordinateTypes.Rotation
      ? CoordinateValueTypes.XRotation
      : CoordinateValueTypes.XPosition;

  const yType =
    props.controlsType === CoordinateTypes.Rotation
      ? CoordinateValueTypes.YRotation
      : CoordinateValueTypes.YPosition;

  const zType =
    props.controlsType === CoordinateTypes.Rotation
      ? CoordinateValueTypes.ZRotation
      : CoordinateValueTypes.ZPosition;

  return (
    <Box p={1}>
      <Box display="flex" my={2}>
        <Box flex={1}>
          <InlineNummericTextField
            onChange={(v) => handleTranslationInput(v, xType)}
            value={coordinates[xType]}
            label={'X'}
          />
        </Box>
        <Box flex={1} ml={1}>
          <InlineNummericTextField
            onChange={(v) => handleTranslationInput(v, yType)}
            value={coordinates[yType]}
            label={'Y'}
          />
        </Box>
        <Box flex={1} ml={1}>
          <InlineNummericTextField
            onChange={(v) => handleTranslationInput(v, zType)}
            value={coordinates[zType]}
            label={'Z'}
          />
        </Box>
      </Box>
      <CoordinateInputContainer
        active={props.active}
        onChange={handleIncrementTranslation}
        coordinateType={props.controlsType}
        keyConfiguration={DirectionalKeyboardMappingTypes.KeyboardLeft}
      />
    </Box>
  );
};

export default ImplantTransformationControls;
