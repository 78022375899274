import React, { useState, useMemo } from 'react';
import { AssetType, IAsset, ICase, ValidFileExtensions } from '@workflow-nx/common';
import { Box, Chip, Paper, Stack, Typography } from '@mui/material';
import { IconFontButton } from '@workflow-nx/ui';
import { faChevronCircleDown, faChevronCircleUp } from '@fortawesome/pro-solid-svg-icons';
import { AssetGridTable } from '../../../components/AssetGridTable/AssetGridTable';

export function PostOpAssetsView(props: {
  label: string;
  dispatch: React.Dispatch<any>;
  activeCase: ICase;
  assets: IAsset[];
  assetType: AssetType;
}) {
  const [expanded, setExpanded] = useState(false);

  function hasAsset(assetType: AssetType): boolean {
    return !!props.assets.find((asset) => asset.assetType === assetType);
  }

  const dicomXrayAssetType = `${props.assetType}_XRAY` as AssetType;
  const dicomCtAssetType = `${props.assetType}_CT` as AssetType;
  const dicomMriAssetType = `${props.assetType}_MRI` as AssetType;
  const apXrayAssetType = `${props.assetType}_STANDING_XRAY_AP` as AssetType;
  const lateralXrayAssetType = `${props.assetType}_STANDING_XRAY_LATERAL` as AssetType;

  const validDicomAssets = useMemo(() => {
    if (props.assetType === AssetType.PostOpIntraOp) {
      return [dicomXrayAssetType];
    }
    return [dicomXrayAssetType, dicomCtAssetType, dicomMriAssetType];
  }, [props.assetType]);

  return (
    <Box>
      <Stack direction={'row'} gap={2} alignItems={'center'}>
        <Typography variant={'h4'} py={2}>
          {props.label} Imaging
        </Typography>
        {!expanded ? (
          <Stack direction={'row'} gap={1} alignItems={'center'}>
            <Chip
              size={'small'}
              color={hasAsset(dicomXrayAssetType) ? 'primary' : 'default'}
              label={'DICOM'}
            ></Chip>
            <Chip
              size={'small'}
              color={hasAsset(apXrayAssetType) ? 'primary' : 'default'}
              label={'AP'}
            ></Chip>
            <Chip
              size={'small'}
              color={hasAsset(lateralXrayAssetType) ? 'primary' : 'default'}
              label={'Lateral'}
            ></Chip>
          </Stack>
        ) : null}
        <Box flex={1} />
        <IconFontButton
          icon={expanded ? faChevronCircleUp : faChevronCircleDown}
          onClick={() => setExpanded(!expanded)}
        />
      </Stack>
      {expanded ? (
        <Box>
          <Paper style={{ marginBottom: 16 }}>
            <Box p={3}>
              <Typography variant={'button'} py={1} fontSize={16} color={'textSecondary'}>
                Dicom
              </Typography>
              <AssetGridTable
                dispatch={props.dispatch}
                caseId={Number(props.activeCase.caseId)}
                assets={props.assets}
                allowReplace={true}
                validFileExtensions={[ValidFileExtensions.ZIP]}
                validAssets={validDicomAssets}
              />
            </Box>
          </Paper>
          <Paper>
            <Box p={2}>
              <Typography variant={'button'} py={1} fontSize={16} color={'textSecondary'}>
                Standing X-Rays
              </Typography>
              <AssetGridTable
                dispatch={props.dispatch}
                caseId={Number(props.activeCase.caseId)}
                assets={props.assets}
                allowReplace={true}
                validFileExtensions={[
                  ValidFileExtensions.JPG,
                  ValidFileExtensions.JPEG,
                  ValidFileExtensions.PNG,
                ]}
                validAssets={[apXrayAssetType, lateralXrayAssetType]}
              />
            </Box>
          </Paper>
        </Box>
      ) : null}
    </Box>
  );
}
