import { format, IActivity, ITableHeader } from '@workflow-nx/common';
import { Box, IconButton, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/pro-light-svg-icons';
import React, { useState } from 'react';
import { CommonTable } from '../../../../components/CommonTable';
import { ActivityViewDialog } from './ActivityViewDialog';

export function ActivityListTable(props: {
  orderBy: any;
  loading: boolean;
  activities: IActivity[];
  dispatch?: any;
}) {
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [openActivityViewDialog, setOpenActivityViewDialog] = useState(false);
  const [activityId, setActivityId] = useState<number | undefined>();

  const headers: ITableHeader[] = [
    {
      id: 'activityType',
      label: 'Activity',
    },
    {
      id: 'originType',
      label: 'Origin',
    },
    {
      id: 'createdBy',
      label: 'Created By',
    },
    {
      id: 'createdAt',
      label: 'Created At',
    },
    {
      id: 'blank',
      label: '',
    },
  ];

  return (
    <>
      <CommonTable
        orderBy={props.orderBy}
        headers={headers}
        onHeaderClick={(orderBy) => {
          if (props.dispatch) {
            props.dispatch({
              type: 'ORDER_BY_CHANGED',
              data: orderBy,
            });
          }
        }}
        rows={
          <TableBody>
            {props?.activities?.map((activity: IActivity) => (
              <TableRow key={activity.activityId} selected={selectedId === activity.activityId}>
                <TableCell>
                  <Typography variant={'body1'} noWrap>
                    {format.formatActivityType(activity.activityType)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant={'body1'} noWrap>
                    {format.formatActivityOriginType(activity.originType)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant={'body1'} noWrap>
                    {format.formatName(activity.createdByUser)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant={'body1'} noWrap>
                    {format.formatDateTime(activity.createdAt)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Box display={'flex'} alignItems={'center'} height={50}>
                    <IconButton
                      onClick={() => {
                        setSelectedId(activity.activityId);
                        setActivityId(activity.activityId);
                        setOpenActivityViewDialog(true);
                      }}
                      size="large"
                    >
                      <FontAwesomeIcon icon={faEye} />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        }
        loading={props.loading}
      />
      <ActivityViewDialog
        activityId={activityId}
        open={openActivityViewDialog}
        onClose={() => {
          setOpenActivityViewDialog(false);
          setActivityId(undefined);
        }}
      />
    </>
  );
}
