import { CloudDesignStatus, LevelSize, LevelType, PartType } from '@workflow-nx/common';
import { Box, colors, Divider, Typography } from '@mui/material';
import { CloudDesignTableRow } from './CloudDesignTableRow';
import React from 'react';

export type CloudDesignTableRowItem = {
  label: React.ReactNode;
  levelSize: LevelSize;
  level: LevelType;
  partType: PartType;
};

export function CloudDesignTable(props: {
  caseId: number;
  planId: number;
  items: CloudDesignTableRowItem[];
  onStatusChange: (
    level: LevelType,
    levelSize: LevelSize,
    partType: PartType,
    status: CloudDesignStatus,
  ) => void;
}) {
  return (
    <Box display={'grid'} gridTemplateColumns={'auto max-content'} gridTemplateRows={'auto'}>
      <Box pl={1}>
        <Typography style={{ color: colors.grey[600] }}>Level Size</Typography>
      </Box>
      <Box pl={1}>
        <Typography style={{ color: colors.grey[600] }}>Actions</Typography>
      </Box>
      {props.items.map((item) => {
        return (
          <>
            <Box gridColumn={'span 2'} mb={1} key={`${item.level}_${item.levelSize}_divider`}>
              <Divider />
            </Box>
            <CloudDesignTableRow
              key={`${item.level}_${item.levelSize}_cd`}
              label={item.label}
              caseId={props.caseId}
              planId={props.planId}
              levelType={item.level}
              levelSize={item.levelSize}
              partType={item.partType}
              onStatusChange={props.onStatusChange}
            />
          </>
        );
      })}
    </Box>
  );
}
