import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, TableCell, TableRow, Typography } from '@mui/material';
import { ClassNameMap, makeStyles } from '@mui/styles';
import {
  LevelType,
  MeasurementType,
  MeasurementWarningTypes,
  TableType,
  format,
} from '@workflow-nx/common';
import * as math from '@workflow-nx/math';
import { LumbarLevelMeasurement, MeasurementValues } from '@workflow-nx/core-algorithms';
import { MeasurementsConfig } from '@workflow-nx/ui';
import MeasurementValueError from './MeasurementTableError';

type LumbarMeasurementTableRowProps = {
  data?: LumbarLevelMeasurement;
  levelType: LevelType;
  tableType: TableType;
  disabled?: boolean;
  showErrors?: boolean;
  measurementsConfig: MeasurementsConfig;
};

const useStyles = makeStyles(() => ({
  disabled: {
    backgroundColor: 'rgba(224, 224, 224, 0.25)',
    '& td': {
      color: 'rgb(84, 110, 122)',
    },
  },
  positive: {
    color: 'green',
    marginLeft: '5px',
  },
  negative: {
    color: 'blue',
    marginLeft: '5px',
  },
}));

export function LumbarMeasurementTableRow(props: LumbarMeasurementTableRowProps) {
  const classes: ClassNameMap = useStyles();

  const label = format.formatLevelType(props.levelType);

  const renderMeasurementErrorWarning = (value: number | null, type: MeasurementType) => {
    let result = null;

    if (props.showErrors && value && !props.disabled) {
      if (props.tableType === TableType.Plan) {
        switch (type) {
          case MeasurementType.PosteriorHeight:
            if (
              math.inRange(
                value,
                props.measurementsConfig.posteriorHeightMin,
                props.measurementsConfig.posteriorHeightMax,
              )
            ) {
              result = (
                <MeasurementValueError
                  text={`The Posterior Height (PH) is out of range. PH must be within ${props.measurementsConfig.posteriorHeightMin} - ${props.measurementsConfig.posteriorHeightMax} mm range.`}
                  type={MeasurementWarningTypes.Warning}
                  testId={`${props.levelType}-ph-warning`}
                />
              );
            }
            break;
          case MeasurementType.AnteriorHeight:
            if (
              math.inRange(
                value,
                props.measurementsConfig.anteriorHeightMin,
                props.measurementsConfig.anteriorHeightMax,
              )
            ) {
              result = (
                <MeasurementValueError
                  text={`The Anterior Height (AH) is out of range. AH must be within ${props.measurementsConfig.anteriorHeightMin} - ${props.measurementsConfig.anteriorHeightMax} mm range.`}
                  type={MeasurementWarningTypes.Warning}
                  testId={`${props.levelType}-ah-warning`}
                />
              );
            }
            break;
          case MeasurementType.LordoticAngle:
            if (math.inRange(value, null, props.measurementsConfig.lordoticAngleMax)) {
              result = (
                <MeasurementValueError
                  text={`The Lordotic Angle (LA) is out of range. LA must be less than ${props.measurementsConfig.lordoticAngleMax}.`}
                  type={MeasurementWarningTypes.Danger}
                  testId={`${props.levelType}-la-danger`}
                />
              );
            }
            break;
          case MeasurementType.CoronalAngle:
            if (math.inRange(value, null, props.measurementsConfig.coronalAngleMax)) {
              result = (
                <MeasurementValueError
                  text={`The Coronal Height (CA) is out of range. CA must be less than ${props.measurementsConfig.coronalAngleMax}.`}
                  type={MeasurementWarningTypes.Danger}
                  testId={`${props.levelType}-ca-danger`}
                />
              );
            }
            break;
          default:
            result = value;
            break;
        }
      } else {
        if (type === MeasurementType.AnteriorHeight) {
          if (math.inRange(value, props.measurementsConfig.anteriorHeightPreOp)) {
            result = (
              <MeasurementValueError
                text={`The Anterior Height (AH) is out of range. AH must be greater than ${props.measurementsConfig.anteriorHeightPreOp}.`}
                type={MeasurementWarningTypes.Warning}
                testId={`${props.levelType}-ah-warning`}
              />
            );
          }
        }
      }
    }

    return result;
  };

  const getTableValue = (values: MeasurementValues | undefined, tableType?: TableType) => {
    if (!values) return null;

    switch (tableType ?? props.tableType) {
      case TableType.Preop:
      case TableType.Daisy:
        return values.preOp;
      case TableType.Plan:
        return values.plan;
      case TableType.PlanDiff:
        return values.delta;
      default:
        return null;
    }
  };

  const renderFormattedMeasurementValue = (value: number | null, type: MeasurementType) => {
    let result = null;
    let preopValue: number | null = null;
    let digits: number = 0;
    let difference: number = 0;

    if (value === null) {
      return difference;
    }

    if (value && props.measurementsConfig.shouldDisplayPreopDifferences) {
      switch (type) {
        case MeasurementType.LordoticAngle:
          digits = 0;
          preopValue = getTableValue(props.data?.lordoticAngle, TableType.Preop);
          break;
        case MeasurementType.CoronalAngle:
          digits = 0;
          preopValue = getTableValue(props.data?.coronalAngle, TableType.Preop);
          break;
        case MeasurementType.AnteriorHeight:
          digits = 1;
          preopValue = getTableValue(props.data?.anteriorHeight, TableType.Preop);

          break;
        case MeasurementType.PosteriorHeight:
          digits = 1;
          preopValue = getTableValue(props.data?.posteriorHeight, TableType.Preop);

          break;
        case MeasurementType.LumbarAngle:
          digits = 0;
          preopValue = getTableValue(props.data?.angleToS1, TableType.Preop);

          break;
        default:
          break;
      }

      if (preopValue !== null) {
        //need to get the difference of rounded values
        difference = Number(value.toFixed(digits)) - Number(preopValue.toFixed(digits));
        const formattedDifference: string = Math.abs(difference).toFixed(digits);
        const signClass: any = difference > 0 ? classes.positive : classes.negative;

        const sign: any =
          difference > 0 ? (
            <FontAwesomeIcon icon={faCaretUp} />
          ) : (
            <FontAwesomeIcon icon={faCaretDown} />
          );
        if (Number(formattedDifference) !== 0) {
          result = (
            <Box
              className={signClass}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              sx={{
                flexWrap: 'no-wrap',
                gap: '2px',
              }}
            >
              {sign} {`${formattedDifference}`}
            </Box>
          );
        }
      }
    }
    return result;
  };

  const lordoticAngle = getTableValue(props.data?.lordoticAngle);
  const coronalAngle = getTableValue(props.data?.coronalAngle);
  const anteriorHeight = getTableValue(props.data?.anteriorHeight);
  const posteriorHeight = getTableValue(props.data?.posteriorHeight);
  const lumbarAngle = getTableValue(props.data?.angleToS1);

  return (
    <TableRow className={props.disabled ? classes.disabled : ''}>
      <TableCell>
        <Typography variant={'body1'}>{label}</Typography>
      </TableCell>
      <TableCell align={'center'}>
        <Typography
          component={'span'}
          variant={'body1'}
          data-testid={`${props.tableType.toLowerCase()}-${props.levelType}-lordoticAngle`}
        >
          <Box display={'flex'} justifyContent={'center'}>
            {math.formatNumber(lordoticAngle, '—', 0, true)}
            {renderFormattedMeasurementValue(lordoticAngle, MeasurementType.LordoticAngle)}
            {renderMeasurementErrorWarning(lordoticAngle, MeasurementType.LordoticAngle)}
          </Box>
        </Typography>
      </TableCell>
      <TableCell align={'center'}>
        <Typography
          component={'span'}
          variant={'body1'}
          data-testid={`${props.tableType.toLowerCase()}-${props.levelType}-lumbarAngle`}
        >
          <Box display={'flex'} justifyContent={'center'}>
            {math.formatNumber(lumbarAngle, '—', 0, true)}
            {renderFormattedMeasurementValue(lumbarAngle, MeasurementType.LumbarAngle)}
          </Box>
        </Typography>
      </TableCell>
      <TableCell align={'center'}>
        <Typography
          component={'span'}
          variant={'body1'}
          data-testid={`${props.tableType.toLowerCase()}-${props.levelType}-coronalAngle`}
        >
          <Box display={'flex'} justifyContent={'center'}>
            {math.formatNumber(coronalAngle, '—', 0, true)}
            {renderFormattedMeasurementValue(coronalAngle, MeasurementType.CoronalAngle)}
            {renderMeasurementErrorWarning(coronalAngle, MeasurementType.CoronalAngle)}
          </Box>
        </Typography>
      </TableCell>
      <TableCell align={'center'}>
        <Typography
          component={'span'}
          variant={'body1'}
          data-testid={`${props.tableType.toLowerCase()}-${props.levelType}-anteriorHeight`}
        >
          <Box display={'flex'} justifyContent={'center'}>
            {math.formatNumber(anteriorHeight, '—', 1, false)}
            {renderFormattedMeasurementValue(anteriorHeight, MeasurementType.AnteriorHeight)}
            {renderMeasurementErrorWarning(anteriorHeight, MeasurementType.AnteriorHeight)}
          </Box>
        </Typography>
      </TableCell>
      <TableCell align={'center'}>
        <Typography
          component={'span'}
          variant={'body1'}
          data-testid={`${props.tableType.toLowerCase()}-${props.levelType}-posteriorHeight`}
        >
          <Box display={'flex'} justifyContent={'center'}>
            {math.formatNumber(posteriorHeight, '—', 1, false)}
            {renderFormattedMeasurementValue(posteriorHeight, MeasurementType.PosteriorHeight)}
            {renderMeasurementErrorWarning(posteriorHeight, MeasurementType.PosteriorHeight)}
          </Box>
        </Typography>
      </TableCell>
    </TableRow>
  );
}
