import React, { Dispatch } from 'react';
import { Alert, Box, Card, CardContent, Divider, Typography } from '@mui/material';
import { CaseStageType, ICase, PlanStatusType } from '@workflow-nx/common';
import { CaseViewActionType } from '../CaseView';
import { ReviewPlanView } from './ReviewPlanView';
import { PlanHeaderView } from '../CasePlanningTab/PlanHeaderView';
import { ImplantSizeExclusionAlert } from '../../../../components/ImplantSizeExclusionAlert';

export function CaseProposedTabView(props: {
  activeCase: ICase;
  dispatch: Dispatch<CaseViewActionType>;
}) {
  const proposedOrApprovedPlan = props.activeCase.plans.find((plan) =>
    [PlanStatusType.Proposed, PlanStatusType.Approved].includes(plan.status),
  );

  const stageComplete =
    props.activeCase.stage !== CaseStageType.Open &&
    props.activeCase.stage !== CaseStageType.Segmentation &&
    props.activeCase.stage !== CaseStageType.Planning &&
    props.activeCase.stage !== CaseStageType.Proposed;

  return (
    <Card>
      <CardContent>
        <Box mb={2}>
          <Typography variant={'h2'}>Case Review</Typography>
        </Box>

        {stageComplete ? (
          <Box mb={2}>
            <Alert severity={'success'}>This stage is complete</Alert>
          </Box>
        ) : null}

        <Box mx={10} mb={4}>
          <Box mb={2}>
            <ImplantSizeExclusionAlert
              activeCase={props.activeCase}
              currentStage={CaseStageType.Proposed}
              planId={proposedOrApprovedPlan?.planId}
              onUpdate={() => {
                props.dispatch({ type: 'refetch' });
              }}
            />
          </Box>

          {proposedOrApprovedPlan && props.activeCase ? (
            <>
              <PlanHeaderView selectedPlan={proposedOrApprovedPlan} activeCase={props.activeCase} />
              <Box sx={{ my: 2 }}>
                <Divider />
              </Box>
              <ReviewPlanView
                activeCase={props.activeCase}
                plan={proposedOrApprovedPlan}
                onUpdate={() => {
                  props.dispatch({ type: 'refetch' });
                }}
              />
            </>
          ) : (
            <Alert severity={'warning'}>There is no proposed plan for this case</Alert>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}
