import React, { useState, useEffect, ChangeEvent } from 'react';
import { PathologyType } from '@workflow-nx/common';
import { useFormContext, Control, FieldValues, SetFieldValue } from 'react-hook-form';
import { TableRow } from '@mui/material';
import { TableCell } from '../../../../../components/TableCell';
import { CheckboxRHF, TextFieldRHF } from '@workflow-nx/ui';
import { CaseReportDialogStateType } from './CaseReportDialog.reducer';
import { formatCasePathologyType } from '../../../../../utils/caseReport/caseReportFormat';

export function CasePathologyTableRow(props: {
  pathologyType: PathologyType;
  pathologySelected: boolean;
  pathologyTypeIndex: number;
  disabled: boolean;
}) {
  const { control, setValue, resetField } = useFormContext<CaseReportDialogStateType>();
  const [disabledPathologyNote, setDisabledPathologyNote] = useState(true);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDisabledPathologyNote(!event.currentTarget.checked);
    if (!event.currentTarget.checked) {
      setValue(
        `casePathologies[${props.pathologyTypeIndex}].pathologyNote` as SetFieldValue<FieldValues>,
        '' as any,
      );
    } else {
      resetField(
        `casePathologies[${props.pathologyTypeIndex}].pathologyNote` as SetFieldValue<FieldValues>,
      );
    }
  };

  useEffect(() => {
    if (props.pathologySelected) {
      setDisabledPathologyNote(false);
    }
  }, [props.pathologySelected]);

  return (
    <TableRow hover>
      <TableCell width={400}>
        <CheckboxRHF
          name={`casePathologies[${props.pathologyTypeIndex}].pathologySelected`}
          control={control as unknown as Control<FieldValues>}
          label={formatCasePathologyType(props.pathologyType)}
          disabled={props.disabled}
          onChange={handleChange}
        />
      </TableCell>
      <TableCell width={400}>
        <TextFieldRHF
          name={`casePathologies[${props.pathologyTypeIndex}].pathologyNote`}
          control={control as unknown as Control<FieldValues>}
          disabled={props.disabled || disabledPathologyNote}
          fullWidth={true}
        />
      </TableCell>
    </TableRow>
  );
}
