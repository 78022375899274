import { TableRow } from '@mui/material';
import { TableCell } from '../../../../components/TableCell';
import { TextField } from '@workflow-nx/ui';
import { NumberTextField } from '../../../../components/NumberTextField';
import React from 'react';

export function Form19RevisionTableRow(props: {
  value: any;
  levelIndex: number;
  valueIndex: number;
  disabled: boolean;
}) {
  return (
    <TableRow hover>
      <TableCell colSpan={1}>
        <NumberTextField
          name={`levels[${props.levelIndex}].values[${props.valueIndex}].revision`}
          label={'STL Revision#'}
          disabled={props.disabled}
          fullWidth={false}
          shrink={true}
        />
      </TableCell>
      <TableCell colSpan={3}>
        <TextField
          name={`levels[${props.levelIndex}].values[${props.valueIndex}].description`}
          label={'Revision Description'}
          multiline={false}
          disabled={props.disabled}
          shrink={true}
        />
      </TableCell>
    </TableRow>
  );
}
