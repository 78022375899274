import {
  AssetCategory,
  AssetPositions,
  AssetSuffix,
  AssetType,
  CaseSpineProfile,
  caseUtils,
  Coordinate,
  EndPlate,
  IAsset,
  IMeasure,
  IMeasurementPoint,
  IPlanImplant,
  LevelType,
  Position,
  VertebraePositionColor,
  VertebralBody,
} from '@workflow-nx/common';
import * as math from '@workflow-nx/math';
import {
  AbstractMesh,
  Angle,
  Animation,
  ArcRotateCamera,
  AssetsManager,
  Axis,
  Camera,
  Color3,
  Color4,
  CubicEase,
  EasingFunction,
  Mesh,
  MeshAssetTask,
  MeshBuilder,
  Node,
  Scene,
  SceneLoader,
  StandardMaterial,
  Tags,
  Vector3,
} from 'babylonjs';
import { STLExport } from 'babylonjs-serializers';
import { AbstractAssetTask } from 'babylonjs/Misc/assetsManager';
import { orderBy } from 'lodash';
import {
  ImplantViewType,
  loadSupportAsset,
} from '../../views/cases/CaseView/CasePlanningTab/ImplantEditorDialog/ImplantEditorDialog.helpers';
import { getImplantHasScrews } from '../../views/cases/CaseView/CasePlanningTab/ImplantEditorDialog/utils/implantEditor';

export interface IAdjacentMeshNames {
  parentMesh: AssetType | null;
  childMesh: AssetType | null;
}

export const getMeshBuffer = (name: string, scene: Scene): DataView | null => {
  let buffer = null;

  const targetMesh = scene.getMeshByName(name) as Mesh;

  if (targetMesh) {
    buffer = STLExport.CreateSTL([targetMesh], false, undefined, true, true, true, true);
  }

  return buffer;
};

export const configureImplantSupportAssets = async (
  planImplant: IPlanImplant,
  level: LevelType,
  findImplantPart: any,
  scene: Scene,
) => {
  // load mini
  const miniMesh = await loadSupportAsset(
    `${level}_${planImplant.partType}_MINI`,
    level,
    planImplant.partType,
    'MINI',
    planImplant,
    ['plan', 'mini'],
    findImplantPart,
    scene,
  );

  if (miniMesh) {
    miniMesh.setParent(null);
    miniMesh.visibility = 0;
  }

  const instrumentMesh = await loadSupportAsset(
    `${level}_IMPLANT_INSTRUMENT`,
    level,
    planImplant.partType,
    'INSTRUMENT',
    planImplant,
    ['plan', 'instrument'],
    findImplantPart,
    scene,
  );

  if (instrumentMesh) {
    instrumentMesh.visibility = 0;
    instrumentMesh.isPickable = false;
  }

  const hasScrews = getImplantHasScrews(planImplant.partType);

  if (hasScrews && planImplant.screwLength) {
    const implantView = `SCREWS_${planImplant.screwLength ?? 20}` as ImplantViewType;

    const screwMesh = await loadSupportAsset(
      `${level}_IMPLANT_SCREW`,
      level,
      planImplant.partType,
      implantView,
      planImplant,
      ['plan', 'screws'],
      findImplantPart,
      scene,
    );

    if (screwMesh) {
      screwMesh.isPickable = false;
    }

    const guideMesh = await loadSupportAsset(
      `${level}_IMPLANT_SCREW_GUIDE`,
      level,
      planImplant.partType,
      'CLEARANCE',
      planImplant,
      ['plan', 'guide'],
      findImplantPart,
      scene,
    );

    if (guideMesh) {
      guideMesh.visibility = 0;
      guideMesh.isPickable = false;
    }
  }
};
// NOTE: Only called when adding or editing an implant, not when one loads
export const configureImplant = (
  implantMesh: AbstractMesh | null,
  name: string,
  planImplant: IPlanImplant,
  setPosition: boolean,
  tags: string[],
  color: Color3 | undefined,
  scene: Scene,
) => {
  if (implantMesh) {
    implantMesh.name = name;
    implantMesh.id = name;

    const { rotation, position, screwLength, partType, ap, ml } = planImplant;

    //set color - create material since material does not exist on AbstractMesh when imported
    const implantMeshMaterial = new StandardMaterial(`${name}-material`, scene);

    implantMeshMaterial.diffuseColor = color ?? Color3.FromInts(255, 255, 143);
    // to remove light reflection, set the specular color to black
    implantMeshMaterial.specularColor = Color3.Black();
    implantMesh.material = implantMeshMaterial;

    const adjacentMeshes: IAdjacentMeshNames = getAdjacentMeshNames(name);

    // correct rotation since asset position stores absolute rotation
    let parentCorrectionRotation: Vector3 = Vector3.Zero();

    if (adjacentMeshes.parentMesh) {
      const parent = scene.getMeshByName(adjacentMeshes.parentMesh);
      if (parent) {
        parentCorrectionRotation = parent.absoluteRotationQuaternion.toEulerAngles();
      }
    }

    //set position / rotation
    if (setPosition) {
      implantMesh.position = new Vector3(position.x, position.y, position.z);

      implantMesh.rotation = new Vector3(rotation.x, rotation.y, rotation.z).add(
        parentCorrectionRotation,
      );
    }

    // NOTE: turn on edges to make the text labels easier to read
    implantMesh.enableEdgesRendering();
    implantMesh.edgesWidth = 1.0;
    implantMesh.edgesColor = new Color4(0, 0, 0, 1);

    //set mesh metadata - on update/create invoke this data will be accessible
    implantMesh.metadata = {
      ap: ap,
      ml: ml,
      level: name.replace('_CYBORG_IMPLANT', ''),
      screwLength,
      partType,
    };

    //set parent
    if (adjacentMeshes.parentMesh) {
      implantMesh.setParent(scene.getMeshByName(adjacentMeshes.parentMesh));
    }

    Tags.AddTagsTo(implantMesh, `${tags.join(' ')} implant`);
  }
};

export function handleAddPointByTags(
  point: Vector3,
  position: Position,
  plate: EndPlate,
  body: VertebralBody,
  scene: Scene,
  shouldSetParent: boolean,
) {
  if (!point) {
    return;
  }

  let color =
    plate === EndPlate.Superior
      ? Color3.FromHexString(VertebraePositionColor.AnteriorTop) // light red
      : Color3.FromHexString(VertebraePositionColor.AnteriorBottom); // dark red

  if (position === Position.Posterior) {
    color =
      plate === EndPlate.Superior
        ? Color3.FromHexString(VertebraePositionColor.PosteriorTop) // light yellow
        : Color3.FromHexString(VertebraePositionColor.PosteriorBottom); // dark yellow
  }
  if (position === Position.PatientLeft) {
    color =
      plate === EndPlate.Superior
        ? Color3.FromHexString(VertebraePositionColor.PatientLeftTop) // light green
        : Color3.FromHexString(VertebraePositionColor.PatientLeftBottom); // dark green
  }
  if (position === Position.PatientRight) {
    color =
      plate === EndPlate.Superior
        ? Color3.FromHexString(VertebraePositionColor.PatientRightTop) // light blue
        : Color3.FromHexString(VertebraePositionColor.PatientRightBottom); // dark blue
  }

  addPointByTags(position, plate, body, point, color, shouldSetParent, scene);
}

export function addPointByTags(
  position: Position,
  plate: EndPlate,
  body: VertebralBody,
  point: Vector3,
  color: Color3,
  shouldSetParent: boolean,
  scene: Scene,
) {
  let mesh = scene.getMeshesByTags(`${position} && ${plate} && ${body} && point`)?.[0];

  if (!mesh) {
    const material = new StandardMaterial('redMat', scene);
    const name = `${body}.${position}.${plate}${AssetSuffix.PointSuffix}`;

    mesh = MeshBuilder.CreateSphere(name, { diameter: 3 }, scene);
    material.diffuseColor = color;
    mesh.material = material;

    //for asset previewer
    if (shouldSetParent) {
      const parentMesh = scene.getMeshesByTags(body)?.[0];
      mesh.setParent(parentMesh);
    }
    Tags.AddTagsTo(mesh, `point ${body} ${position} ${plate}`);
  }
  mesh.isPickable = false;
  mesh.position = point;

  return mesh;
}

export function removePointOfRotation(scene: Scene) {
  const name = `POINT_OF_ROTATION`;
  const mesh = scene.getMeshByName(name);
  if (mesh) {
    scene.removeMesh(mesh);
  }
}

export function setPointOfRotation(point: Vector3, scene: Scene) {
  const name = `POINT_OF_ROTATION`;

  let mesh = scene.getMeshByName(name);
  if (!mesh) {
    const material = new StandardMaterial('pointMaterial', scene);

    mesh = MeshBuilder.CreatePolyhedron(name, { type: 1, size: 0.75 }, scene);
    material.diffuseColor = Color3.FromHexString('#FFD700');
    mesh.material = material;
  }

  mesh.isPickable = false;
  mesh.position = point;

  (scene.activeCamera as ArcRotateCamera).setTarget(point);
}

export async function addAssetToScene(url: string, scene: Scene): Promise<AbstractMesh | null> {
  let mesh = null;

  try {
    const result = await SceneLoader.ImportMeshAsync('', url, '', scene, null, '.stl');

    mesh = result.meshes?.[0] ?? null;
  } catch (e) {
    console.error(`Unable to import mesh for url ${url}`);
  }
  return mesh;
}

export async function addImplantToScene(url: string, scene: Scene): Promise<AbstractMesh | null> {
  let mesh = null;

  try {
    const result = await SceneLoader.ImportMeshAsync('', url, '', scene, null, '.stl');

    mesh = result.meshes?.[0] ?? null;
  } catch (e) {
    console.error(`Unable to import mesh for url ${url}`);
  }
  return mesh;
}

export function clearMeshes(nodes: Node[]) {
  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];
    clearMeshes(node.getChildren());

    if (node instanceof AbstractMesh) {
      node.dispose();
    }
  }
}

export async function loadAssetsFromAssetPositions(
  assetPositions: AssetPositions,
  assets: IAsset[],
  color: string | undefined,
  tags: string[],
  spineProfile: CaseSpineProfile,
  scene: Scene,
) {
  const spineProfileConfig = caseUtils.getCaseSpineProfile(spineProfile);

  const assetUrls: {
    url: string;
    fileName: string;
    assetType: AssetType;
  }[] = assets.map((asset: IAsset) => {
    return {
      fileName: '',
      url: asset.signedDownloadUrl,
      assetType: asset.assetType,
    };
  }) as any[];

  const assetsManager = new AssetsManager(scene);

  if (assetUrls?.length) {
    for (const assetUrl of assetUrls) {
      assetsManager.addMeshTask(assetUrl.assetType, '', assetUrl.url, assetUrl.fileName);
    }
  }

  //check for error on task set up
  assetsManager.onTaskError = function (error) {
    console.error(error);
  };

  //set up scene load
  assetsManager.onFinish = function (tasks: AbstractAssetTask[]) {
    // ordering the tasks to make sure we are applying the assetPositions rotation and position
    // in order of the lowest vertebrae to highest (ie. S1, L5, L4, L3, L2, L1). This is required
    // so that the vertebrae renders as expected
    const sortedTasks = orderBy(tasks, ['name'], ['desc']);

    for (const task of sortedTasks) {
      const targetMesh: Mesh | null = (task as MeshAssetTask).loadedMeshes
        ? ((task as MeshAssetTask).loadedMeshes[0] as Mesh)
        : null;

      if (!targetMesh) {
        return;
      }

      // when the mesh is transparent, these settings will make it so that the
      // back of the mesh doesn't make it through the front of the mesh
      const material = new StandardMaterial(`${targetMesh.name}-material`, scene);
      if (material) {
        material.backFaceCulling = true;
        material.transparencyMode = BABYLON.Material.MATERIAL_ALPHABLEND;
        material.needDepthPrePass = true;
        targetMesh.material = material;
      }

      //set mesh name and id
      targetMesh.name = task.name;
      targetMesh.id = task.name;

      // add tags to mesh to be able to find it later on
      Tags.AddTagsTo(targetMesh, [...(tags ?? []), task.name].join(' '));

      const parentVertebralBody = Object.values(spineProfileConfig.vertebralInfoMap).find(
        (vBody) => vBody?.asset === targetMesh.name,
      )?.parent;

      let parentAssetType: string | null = null;

      if (parentVertebralBody) {
        const parentVertebralBodyInfo = spineProfileConfig.vertebralInfoMap[parentVertebralBody];

        if (parentVertebralBodyInfo) {
          parentAssetType = parentVertebralBodyInfo.asset;
        }
      }

      const parentMesh = (tasks?.find((task) => task.name === parentAssetType) as MeshAssetTask)
        ?.loadedMeshes?.[0];

      setupMeshAssetPositions(assetPositions, color, targetMesh, parentMesh, scene);
    }
    assetsManager.reset();
  };

  await assetsManager.loadAsync();
}

export const addImplantSupportAsset = async (
  name: string,
  level: LevelType,
  planImplant: IPlanImplant,
  tags: string[],
  url: string,
  scene: Scene,
): Promise<boolean> => {
  const { position, rotation } = planImplant;

  const result = await SceneLoader.ImportMeshAsync('', url, '', scene, null, '.stl');
  const mesh = result.meshes?.[0] as Mesh;
  if (mesh) {
    mesh.name = name;
    configureImplantSupportAssetMesh(mesh, level, tags, position, rotation, scene);

    return true;
  }
  return false;
};

export function setAdjacentMeshTransparency(level: LevelType, visibility: number, scene: Scene) {
  const adjacentMeshNames: IAdjacentMeshNames = getAdjacentMeshNames(level);
  let parentMesh = adjacentMeshNames.parentMesh
    ? scene.getMeshesByTags(adjacentMeshNames.parentMesh)?.[0]
    : null;
  let childMesh = adjacentMeshNames.childMesh
    ? scene.getMeshesByTags(adjacentMeshNames.childMesh)?.[0]
    : null;

  if (parentMesh) {
    parentMesh.visibility = visibility;
  }
  if (childMesh) {
    childMesh.visibility = visibility;
  }
}

export const setAssetTarget = (tags: string, scene: Scene) => {
  const mesh = scene.getMeshesByTags(tags)?.[0];

  if (mesh) {
    (scene.activeCamera as ArcRotateCamera).setTarget(mesh.getBoundingInfo().boundingBox.center);
  }
};

const FRAMES_PER_SECOND = 60;
const SPEED_RATIO = 4;
const LOOP_MODE = false;
const FROM_FRAME = 0;
const TO_FRAME = 100;

function createAnimation({ property, from, to }: any) {
  const ease = new CubicEase();
  ease.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);

  const animation = Animation.CreateAnimation(
    property,
    Animation.ANIMATIONTYPE_FLOAT,
    FRAMES_PER_SECOND,
    ease,
  );
  animation.setKeys([
    {
      frame: 0,
      value: from,
    },
    {
      frame: 100,
      value: to,
    },
  ]);

  return animation;
}
export const moveActiveCamera = (scene: Scene, { radius, alpha, beta, target }: any) => {
  const camera = scene.activeCamera as ArcRotateCamera;

  if (!camera) return;

  const orthoProperties = getOrthoCameraProperties(radius, scene);

  const isOrtho = camera.mode === Camera.ORTHOGRAPHIC_CAMERA;

  if (isOrtho && !orthoProperties) return;

  camera.animations = [
    ...(isOrtho
      ? [
          createAnimation({
            property: 'orthoBottom',
            from: camera.orthoBottom,
            to: orthoProperties?.orthoBottom,
          }),
          createAnimation({
            property: 'orthoTop',
            from: camera.orthoTop,
            to: orthoProperties?.orthoTop,
          }),
          createAnimation({
            property: 'orthoRight',
            from: camera.orthoRight,
            to: orthoProperties?.orthoRight,
          }),
          createAnimation({
            property: 'orthoLeft',
            from: camera.orthoLeft,
            to: orthoProperties?.orthoLeft,
          }),
        ]
      : []),
    createAnimation({
      property: 'radius',
      from: camera.radius,
      to: radius,
    }),
    createAnimation({
      property: 'beta',
      from: camera.beta,
      to: beta,
    }),
    createAnimation({
      property: 'alpha',
      from: camera.alpha,
      to: alpha,
    }),
    createAnimation({
      property: 'target.x',
      from: camera.target.x,
      to: target.x,
    }),
    createAnimation({
      property: 'target.y',
      from: camera.target.y,
      to: target.y,
    }),
    createAnimation({
      property: 'target.z',
      from: camera.target.z,
      to: target.z,
    }),
  ];

  scene.beginAnimation(camera, FROM_FRAME, TO_FRAME, LOOP_MODE, SPEED_RATIO);
};

export function getOrthoCameraProperties(radius: number, scene: Scene) {
  const canvas = scene.getEngine().getRenderingCanvas();

  const distance = radius;

  if (!canvas) return null;

  const dimRatio = canvas.height / canvas.width;

  return {
    orthoLeft: -distance,
    orthoRight: distance,
    orthoBottom: -distance * dimRatio,
    orthoTop: distance * dimRatio,
  };
}

export function rotateCamera(
  alphaDegrees: number,
  betaDegrees: number,
  distance: number,
  scene: Scene,
  disableAnimation?: boolean,
) {
  // set up initial camera view based on a typical patient scan
  const alpha = Angle.FromDegrees(alphaDegrees).radians();
  const beta = Angle.FromDegrees(betaDegrees).radians();
  const cam: ArcRotateCamera = scene.activeCamera as ArcRotateCamera;

  if (!cam) return;

  const cameraMode: Camera['mode'] = cam.mode;

  if (disableAnimation) {
    cam.alpha = alpha;
    cam.beta = beta;

    if (cameraMode === Camera.ORTHOGRAPHIC_CAMERA) {
      const orthoProperties = getOrthoCameraProperties(distance, scene);

      if (orthoProperties) {
        cam.orthoBottom = orthoProperties.orthoBottom;
        cam.orthoTop = orthoProperties.orthoTop;
        cam.orthoRight = orthoProperties.orthoRight;
        cam.orthoLeft = orthoProperties.orthoLeft;
      }
    }

    cam.radius = distance;
  } else {
    moveActiveCamera(scene, {
      radius: distance,
      alpha,
      beta,
      target: cam.target,
    });
  }

  cam.upVector = Axis.Y.clone();

  cam.rebuildAnglesAndRadius();
}

function configureImplantSupportAssetMesh(
  supportAssetMesh: Mesh,
  levelType: LevelType,
  tags: string[],
  position: Coordinate,
  rotation: Coordinate,
  scene: Scene,
) {
  //set target implant mesh name & id since imported mesh defaults to "stlmesh"
  if (supportAssetMesh) {
    Tags.AddTagsTo(supportAssetMesh, `${tags.join(' ')} ${levelType}`);
  }

  if (supportAssetMesh) {
    //set color - create material since material does not exist on AbstractMesh when imported
    const implantMeshMaterial = new StandardMaterial(`${name}-support-material`, scene);

    if (tags.includes('mini')) {
      implantMeshMaterial.diffuseColor = Color3.FromHexString('#f4a198');
    } else if (tags.includes('guide')) {
      implantMeshMaterial.diffuseColor = Color3.FromHexString('#356ec4');
    } else if (tags.includes('instrument')) {
      implantMeshMaterial.diffuseColor = Color3.FromHexString('#b4b8bc');
    } else {
      implantMeshMaterial.diffuseColor = Color3.Blue();
    }

    // to remove light reflection, set the specular color to black
    implantMeshMaterial.specularColor = Color3.Black();
    supportAssetMesh.material = implantMeshMaterial;

    //set position / rotation
    supportAssetMesh.position = new Vector3(position.x, position.y, position.z);
    supportAssetMesh.rotation = new Vector3(rotation.x, rotation.y, rotation.z);
  }
}

const setupMeshAssetPositions = (
  assetPositions: AssetPositions,
  color: string | undefined,
  targetMesh: any,
  parentMesh: any,
  scene: Scene,
): void => {
  //determine mesh type
  let targetMeshType = AssetCategory.VertebralBodies;
  if (targetMesh.name.includes(AssetSuffix.ImplantSuffix)) {
    targetMeshType = AssetCategory.Implants;
  }

  //determine position, rotation, color by type
  switch (targetMeshType) {
    case AssetCategory.VertebralBodies:
      targetMesh.setParent(parentMesh);
      targetMesh.setPivotPoint(targetMesh.getBoundingInfo().boundingBox.center);

      //set color
      if (color) {
        targetMesh.material = new StandardMaterial('colorOverlay', scene);
        (targetMesh.material as StandardMaterial).diffuseColor = Color3.FromHexString(color);
      }

      break;
    default:
      break;
  }

  //set pivot point for each mesh
  if (targetMesh.name === VertebralBody.L3) {
    (scene.activeCamera as ArcRotateCamera).setTarget(
      targetMesh.getBoundingInfo().boundingBox.center,
    );
  }
};

export const getAdjacentMeshNames = (meshName: string): IAdjacentMeshNames => {
  let result: IAdjacentMeshNames = {
    parentMesh: null,
    childMesh: null,
  };

  switch (meshName) {
    case AssetType.L1L2:
      result.parentMesh = AssetType.L2;
      result.childMesh = AssetType.L1;
      break;
    case AssetType.L2L3:
      result.parentMesh = AssetType.L3;
      result.childMesh = AssetType.L2;
      break;
    case AssetType.L3L4:
      result.parentMesh = AssetType.L4;
      result.childMesh = AssetType.L3;
      break;
    case AssetType.L4L5:
      result.parentMesh = AssetType.L5;
      result.childMesh = AssetType.L4;
      break;
    case AssetType.L5S1:
      result.parentMesh = AssetType.S1;
      result.childMesh = AssetType.L5;
      break;
    case AssetType.L5L6:
      result.parentMesh = AssetType.L6;
      result.childMesh = AssetType.L5;
      break;
    case AssetType.L6S1:
      result.parentMesh = AssetType.S1;
      result.childMesh = AssetType.L6;
      break;
    case AssetType.L4S1:
      result.parentMesh = AssetType.S1;
      result.childMesh = AssetType.L4;
      break;
    case AssetType.C2C3:
      result.parentMesh = AssetType.C3;
      result.childMesh = AssetType.C2;
      break;
    case AssetType.C3C4:
      result.parentMesh = AssetType.C4;
      result.childMesh = AssetType.C3;
      break;
    case AssetType.C4C5:
      result.parentMesh = AssetType.C5;
      result.childMesh = AssetType.C4;
      break;
    case AssetType.C6C7:
      result.parentMesh = AssetType.C7;
      result.childMesh = AssetType.C6;
      break;
    case AssetType.C7C8:
      result.parentMesh = AssetType.C8;
      result.childMesh = AssetType.C7;
      break;
    case AssetType.C6T1:
      result.parentMesh = AssetType.T1;
      result.childMesh = AssetType.C6;
      break;
    case AssetType.C7T1:
      result.parentMesh = AssetType.T1;
      result.childMesh = AssetType.C7;
      break;
    case AssetType.C8T1:
      result.parentMesh = AssetType.T1;
      result.childMesh = AssetType.C8;
      break;
  }

  return result;
};

export function getMeasurementPointsByTags(scene: Scene | undefined): IMeasurementPoint {
  const pointMap: IMeasurementPoint = {};

  if (scene) {
    scene.getActiveMeshes().forEach((mesh: AbstractMesh) => {
      mesh.computeWorldMatrix(true);
    });

    const rootMesh: Mesh = scene.getMeshesByTags('S1')?.[0];
    const allChildMeshes: AbstractMesh[] = rootMesh?.getChildMeshes() ?? [];

    for (const childMesh of allChildMeshes) {
      const hasPointTag = Tags.MatchesQuery(childMesh, 'point');
      if (hasPointTag) {
        const absolutePosition: Vector3 = childMesh.getAbsolutePosition();
        pointMap[childMesh.name] = [absolutePosition.x, absolutePosition.y, absolutePosition.z];
      }
    }
  }
  return pointMap;
}

export const renderVertebraeLandmarks = async (
  measurements: IMeasure[],
  spineProfile: CaseSpineProfile,
  scene: Scene,
) => {
  const allVertebralBodies = caseUtils.getVertebralBodiesSortedByHierarchy(spineProfile, 'asc');

  for (const body of allVertebralBodies) {
    const foundParts = measurements.filter((f) => f.body === body);
    for (const foundPart of foundParts) {
      handleAddPointByTags(
        math.vectorFromArray(foundPart.point),
        foundPart.position,
        foundPart.endPlate,
        body as VertebralBody,
        scene,
        true,
      );
    }
  }
};
