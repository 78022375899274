import {
  caseUtils,
  format,
  ICase,
  ICaseImplantSpecification,
  ICaseResult,
  LevelResult,
  LevelSize,
  LevelType,
  PartType,
} from '@workflow-nx/common';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Button, IconFontButton } from '@workflow-nx/ui';
import React, { useState } from 'react';
import { faChevronCircleDown, faChevronCircleUp } from '@fortawesome/pro-solid-svg-icons';

const useStyles = makeStyles((theme: Theme) => ({
  disabledRow: {
    backgroundColor: theme.palette.grey['50'],
  },
}));

export function ImplantAnalysisTableView(props: {
  activeCase?: ICase;
  caseImplantSpecifications?: ICaseImplantSpecification[];
  caseResults?: ICaseResult[];
  onEdit: () => void;
  onImport: () => void;
}) {
  const theme = useTheme();
  const classes = useStyles();
  const [showDetails, setShowDetails] = useState(false);

  const profileLevelTypes = caseUtils
    .getLevelsSortedByHierarchy(props.activeCase?.spineProfile, 'desc')
    .filter((levelType: LevelType) => {
      return !['NONE', 'FUSED'].includes(props.activeCase?.levels[levelType] ?? 'NONE');
    });

  function getCaseImplantSpecification(
    levelType: LevelType,
    levelSize: LevelSize,
  ): ICaseImplantSpecification | null {
    let foundCaseImplantSpecification = props.caseImplantSpecifications?.find(function (
      caseImplantSpecification: ICaseImplantSpecification,
    ) {
      return (
        caseImplantSpecification.level === levelType && caseImplantSpecification.size === levelSize
      );
    });
    return foundCaseImplantSpecification ?? null;
  }

  return (
    <Box p={2} borderRadius="5px" border={`1px solid ${theme.palette.divider}`}>
      <Box display={'flex'} alignItems={'center'}>
        <Typography variant={'h4'} noWrap>
          IMPLANTS
        </Typography>
        <Box ml={2}>
          <Button variant={'outlined'} color={'primary'} onClick={props.onEdit} label={'Edit'} />
        </Box>
        {(props?.caseImplantSpecifications?.length ?? 0) === 0 ? (
          <Box ml={2}>
            <Button
              variant={'outlined'}
              color={'primary'}
              onClick={props.onImport}
              label={'Import'}
            />
          </Box>
        ) : null}
        <Box ml={2} flexGrow={1} />
        <Box>
          <IconFontButton
            icon={showDetails ? faChevronCircleUp : faChevronCircleDown}
            onClick={() => setShowDetails(!showDetails)}
          />
        </Box>
      </Box>
      {showDetails ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant={'body1'} noWrap>
                  <strong>Level</strong>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant={'body1'} noWrap>
                  <strong>Part</strong>
                </Typography>
              </TableCell>
              <TableCell align={'center'}>
                <Typography variant={'body1'} noWrap>
                  <strong>AP</strong>
                </Typography>
              </TableCell>
              <TableCell align={'center'}>
                <Typography variant={'body1'} noWrap>
                  <strong>ML</strong>
                </Typography>
              </TableCell>
              <TableCell align={'center'}>
                <Typography variant={'body1'} noWrap>
                  <strong>Max Height.</strong>
                </Typography>
              </TableCell>
              <TableCell align={'center'}>
                <Typography variant={'body1'} noWrap>
                  <strong>Bullet Height</strong>
                </Typography>
              </TableCell>
              <TableCell align={'center'}>
                <Typography variant={'body1'}>
                  <strong>Bullet Angle</strong>
                </Typography>
              </TableCell>
              <TableCell align={'center'}>
                <Typography variant={'body1'}>
                  <strong>Coronal Angle</strong>
                </Typography>
              </TableCell>
              <TableCell align={'center'}>
                <Typography variant={'body1'}>
                  <strong>Lordotic Angle</strong>
                </Typography>
              </TableCell>
              <TableCell align={'center'}>
                <Typography variant={'body1'}>
                  <strong>Anterior Height</strong>
                </Typography>
              </TableCell>
              <TableCell align={'center'}>
                <Typography variant={'body1'}>
                  <strong>Posterior Height</strong>
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {profileLevelTypes.map((level) => {
              const caseResult = props?.caseResults?.find((caseResult: ICaseResult) => {
                return caseResult.level === level;
              });
              const caseImplantSpecification = caseResult
                ? getCaseImplantSpecification(caseResult.level, caseResult.size)
                : null;

              return (
                <>
                  <TableRow
                    className={
                      !Object.values(PartType).find(
                        (part) => part === props?.activeCase?.levels[level],
                      )
                        ? classes.disabledRow
                        : undefined
                    }
                  >
                    <TableCell>{format.formatLevelType(level)}</TableCell>
                    <TableCell>
                      <Typography variant={'body1'}>
                        {format.formatPartType(caseResult?.part as PartType)}
                      </Typography>
                      <Typography variant={'body1'} color={'textSecondary'}>
                        <Box display={'flex'} alignItems={'center'}>
                          {caseResult?.size ? (
                            <>
                              {format.formatInterbodyLevelSize(caseResult.size)}
                              <Box mx={0.5}>&mdash;</Box>
                            </>
                          ) : null}
                          {caseResult?.result ? format.formatCaseResult(caseResult.result) : null}
                        </Box>
                      </Typography>
                    </TableCell>
                    <TableCell align={'center'}>
                      {caseImplantSpecification?.apDepth ?? <>&mdash;</>}
                    </TableCell>
                    <TableCell align={'center'}>
                      {caseImplantSpecification?.mlWidth ?? <>&mdash;</>}
                    </TableCell>
                    <TableCell align={'center'}>
                      {caseImplantSpecification?.maxHeight ?? <>&mdash;</>}
                    </TableCell>
                    <TableCell align={'center'}>
                      <Box>{caseImplantSpecification?.bulletHeight ?? <>&mdash;</>}</Box>
                      <Box>{caseImplantSpecification?.leftBulletHeight ?? <>&mdash;</>} (L)</Box>
                      <Box>{caseImplantSpecification?.rightBulletHeight ?? <>&mdash;</>} (R)</Box>
                    </TableCell>
                    <TableCell align={'center'}>
                      <Box>{caseImplantSpecification?.bulletAngle ?? <>&mdash;</>}</Box>
                      <Box>{caseImplantSpecification?.leftBulletAngle ?? <>&mdash;</>} (L)</Box>
                      <Box>{caseImplantSpecification?.rightBulletAngle ?? <>&mdash;</>} (R)</Box>
                    </TableCell>
                    <TableCell align={'center'}>
                      {caseImplantSpecification?.coronalAngle ?? <>&mdash;</>}
                    </TableCell>
                    <TableCell align={'center'}>
                      {caseImplantSpecification?.lordoticAngle ?? <>&mdash;</>}
                    </TableCell>
                    <TableCell align={'center'}>
                      {caseImplantSpecification?.anteriorHeight ?? <>&mdash;</>}
                    </TableCell>
                    <TableCell align={'center'}>
                      {caseImplantSpecification?.posteriorHeight ?? <>&mdash;</>}
                    </TableCell>
                  </TableRow>
                  {caseResult?.result === LevelResult.Exception ? (
                    <TableRow>
                      <TableCell />
                      <TableCell colSpan={10}>
                        <Typography variant={'body1'}>
                          {caseResult?.exceptionReason ?? <>&mdash;</>}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : null}
                </>
              );
            })}
          </TableBody>
        </Table>
      ) : null}
    </Box>
  );
}
