import React, { useState } from 'react';
import { Box, IconButton, Theme, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import NavBar from './NavBar';
import TopBar from './TopBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSidebar } from '@fortawesome/pro-light-svg-icons';
import useAuth from '../../hooks/useAuth';
import { Outlet } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.grey['100'],
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    transition: 'padding-left 0.5s',
    paddingLeft: (props: any) => (!props.drawerOpen ? 0 : theme.spacing(20)),
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
  },
}));

export const Globals = {
  TopBarHeight: 65,
  DrawerWidthClosed: 75,
  DrawerWidthOpen: 235,
  DefaultListPageSize: 25,
  DefaultListPageSizeOptions: [25, 50, 75],
};

const DashboardLayout = () => {
  const auth = useAuth();
  const theme = useTheme();
  const [drawerOpen, setDrawerOpen] = useState(auth.isDrawerOpen);
  const styles = useStyles({ drawerOpen });

  return (
    <div className={styles.root}>
      <Box style={{ backgroundColor: theme.palette.background.paper }} display={'flex'}>
        <Box
          display={'flex'}
          width={Globals.DrawerWidthClosed}
          height={Globals.TopBarHeight}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <IconButton
            onClick={() => {
              setDrawerOpen(!drawerOpen);
              auth?.setDrawerState?.(!drawerOpen);
            }}
            size="large"
          >
            <FontAwesomeIcon icon={faSidebar} />
          </IconButton>
        </Box>
        <Box>
          <TopBar />
        </Box>
      </Box>
      <NavBar open={drawerOpen ?? false} />
      <div className={styles.wrapper}>
        <div className={styles.contentContainer}>
          <div className={styles.content}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
