import { useCallback, useEffect, useState } from 'react';
import CustomDialog from '../CustomDialog';
import { useLazyQuery } from '@apollo/client';
import { FIND_PLAN_ASSETS } from '../../gql';
import { Vector3, Color3 } from 'babylonjs';
import { AssetType, format, PlanAssetColorType } from '@workflow-nx/common';
import { Box, Slider, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ZoomMeshViewer } from '../../views/cases/CaseView/CasePlanningTab/ZoomMeshViewer';
import { AssetMeshView } from '../BetterSpineViewer';

const useStyles = makeStyles(() => ({
  canvasRoot: {
    border: `1px solid rgba(0, 0, 0, 0.12)`,
    borderRadius: 5,
    '& canvas': {
      width: '100%',
      height: 600,
      touchAction: 'none',
      background: 'transparent',
    },
  },
}));

export function ImplantMeshViewDialog(props: {
  open: boolean;
  caseId?: number;
  planId?: number;
  assetType?: AssetType;
  showVertebrae?: boolean;
  onClose: () => void;
}) {
  const styles = useStyles();
  const [zoomAssets, setZoomAssets] = useState<AssetMeshView[]>([]);
  const [opacity, setOpacity] = useState(25);

  const handleChange = (event: any, newValue: number | number[]) => {
    setOpacity(newValue as number);
  };

  const [findAssets] = useLazyQuery(FIND_PLAN_ASSETS, {
    onCompleted: (data) => {
      const zoomAssets: AssetMeshView[] = [];
      for (let asset of data.assets) {
        const isImplant = asset.assetType === props.assetType;
        let color = PlanAssetColorType.PlanVertebrae;
        let showOpacity = props.showVertebrae;

        if (isImplant) {
          color = PlanAssetColorType.PlanImplantCut;
          showOpacity = false;
        }

        zoomAssets.push({
          name: asset.assetType,
          signedDownloadUrl: asset.signedDownloadUrl,
          tags: [asset.assetType],
          category: 'PLAN',
          type: 'IMPLANT',
          options: {
            color: Color3.FromHexString(color),
            showOpacity: showOpacity,
          },
        });
      }
      setZoomAssets(zoomAssets);
    },
  });

  const handleLoad = useCallback(async () => {
    if (props.assetType) {
      const [inferior, superior] = props.assetType.replace('_APP', '').split('_');
      const cyborgImplant = props.assetType.replace('_APP', '_CYBORG_IMPLANT');
      const assetTypeFilter = props.showVertebrae
        ? [props.assetType, cyborgImplant, inferior, superior]
        : [props.assetType];

      findAssets({
        variables: {
          caseId: props.caseId,
          planId: props.planId,
          assetTypeFilter: assetTypeFilter,
        },
      });
    }
  }, [findAssets]);

  useEffect(() => {
    if (props.open && props.assetType) {
      handleLoad();
    }

    return function cleanup() {
      setZoomAssets([]);
    };
  }, [props.assetType, props.open, handleLoad]);

  return (
    <CustomDialog
      maxWidth={'md'}
      open={props.open}
      title={`Viewing Asset ${format.formatAssetType(props?.assetType)}`}
      onClose={() => {
        props.onClose();
      }}
      positiveActionButtons={[]}
    >
      {props.open && zoomAssets.length > 0 ? (
        <Box
          className={styles.canvasRoot}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          flexDirection={'column'}
        >
          <ZoomMeshViewer
            assetMeshViews={zoomAssets}
            focus={props.assetType ? [props.assetType] : undefined}
            cameraPosition={new Vector3(0, 0, 100)}
          />
          {props.showVertebrae ? (
            <Box p={2} width={'100%'}>
              <Typography variant={'body1'}>Vertebrae Opacity</Typography>
              <Slider value={opacity} onChange={handleChange} />
            </Box>
          ) : null}
        </Box>
      ) : null}
    </CustomDialog>
  );
}
