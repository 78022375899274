import { Alert, Box, Button, Collapse, Stack, Typography } from '@mui/material';
import { AssetType, LevelType, format } from '@workflow-nx/common';
import React, { ReactNode, useState } from 'react';
import _ from 'lodash';

type StageError = {
  key: string;
  level: string;
  value: string;
};

interface ExpandableDivProps {
  maxHeight: number; // Max height of the div when collapsed
  children: ReactNode;
}

const ExpandableDiv: React.FC<ExpandableDivProps> = ({ maxHeight, children }) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <Box>
      <Collapse in={expanded} collapsedSize={maxHeight} timeout={300}>
        {children}
      </Collapse>
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Button variant="text" onClick={handleToggle}>
          {expanded ? 'View Less' : 'View More'}
        </Button>
      </Box>
    </Box>
  );
};

export function StageErrorsView(props: { errors: StageError[] }) {
  function formatErrorKey(value: string): string {
    switch (value) {
      case 'MISSING_PLAN_IMPLANT_PROPERTY':
        return 'Missing Plan Implant Properties';
      case 'MISSING_ASSET':
        return 'Missing Assets';
      default:
        return value;
    }
  }

  function formatMissingImplantProperty(value: string): string {
    switch (value) {
      case 'OBLIQUE_THREAD_ANGLE':
        return 'Oblique Thread Angle';
      case 'CRANIAL_CAUDAL_THREAD_ANGLE':
        return 'Cranial-Caudal Thread Angle';
      default:
        return value;
    }
  }

  const groupedKeys = _.groupBy(props.errors, 'key');

  return (
    <Alert severity={'error'} sx={{ '& .MuiAlert-message': { width: '100%' } }}>
      <ExpandableDiv maxHeight={100}>
        <Typography sx={{ mb: 1 }}>
          The following {props.errors.length} errors need to be resolved before the case can be
          proposed
        </Typography>
        <Stack direction={'row'} gap={2}>
          {Object.entries(groupedKeys).map(([key, errors]) => {
            const groupedLevels = _.groupBy(errors, 'level');

            return (
              <Box>
                <Typography key={key}>
                  <strong>{formatErrorKey(key)}</strong>
                </Typography>

                {Object.entries(groupedLevels).map(([level, errors]) => {
                  return (
                    <>
                      <Typography key={level} sx={{ mb: 1, mt: 2 }}>
                        <strong>{format.formatLevelType(level as LevelType)}</strong>
                      </Typography>
                      <Box>
                        {errors.map((error, index) => {
                          const message =
                            error.key === 'MISSING_PLAN_IMPLANT_PROPERTY'
                              ? formatMissingImplantProperty(error.value)
                              : format.formatAssetType(error.value as AssetType);
                          return <Typography key={index}>{message}</Typography>;
                        })}
                      </Box>
                    </>
                  );
                })}
              </Box>
            );
          })}
        </Stack>
      </ExpandableDiv>
    </Alert>
  );
}
