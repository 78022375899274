import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import { Box, Grid, Typography } from '@mui/material';
import CustomDialog from '../../../components/CustomDialog';
import * as Yup from 'yup';
import { ProgressButton, TextField } from '@workflow-nx/ui';
import { DatePickerField } from '../../../components/DatePickerField';
import { Checkbox } from '@workflow-nx/ui';

export function GenericDateDialog({
  title,
  labelText,
  description,
  dateLabelText,
  open,
  onComplete,
  onClose,
}: {
  open: boolean;
  title: string;
  labelText: string;
  dateLabelText: string;
  description?: string;
  onComplete: (values: {
    date: Date | string;
    isSurgeryDateTentative: boolean;
    comment?: string;
  }) => void;
  onClose: (shouldUpdate: boolean) => void;
}) {
  const handleSubmitForm = async (
    values: { date: string; isSurgeryDateTentative: boolean; comment: string },
    {
      setSubmitting,
    }: FormikHelpers<{ date: string; isSurgeryDateTentative: boolean; comment: string }>,
  ) => {
    if (onComplete) {
      onComplete(values);
    }
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{ date: '', isSurgeryDateTentative: false, comment: '' }}
      validationSchema={Yup.object().shape({
        date: Yup.date().required('Field is required'),
        isSurgeryDateTentative: Yup.boolean(),
      })}
      onSubmit={handleSubmitForm}
      enableReinitialize={true}
    >
      {({ handleSubmit, isSubmitting, submitForm }) => (
        <CustomDialog
          maxWidth={'sm'}
          open={open}
          title={title}
          onClose={() => {
            onClose(false);
          }}
          positiveActionButtons={[
            <ProgressButton
              onClick={() => submitForm()}
              loading={isSubmitting}
              disabled={isSubmitting}
              label={labelText}
            />,
          ]}
        >
          {open ? (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} direction={'row'}>
                {description ? (
                  <Grid item xs={12}>
                    <Typography variant={'body1'}>{description}</Typography>
                  </Grid>
                ) : null}
                <Grid item xs={9}>
                  <DatePickerField
                    disabled={isSubmitting}
                    name="date"
                    label={dateLabelText}
                    required={true}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Checkbox
                    name={'isSurgeryDateTentative'}
                    label={
                      <Typography variant={'body1'} noWrap={true}>
                        Tentative?
                      </Typography>
                    }
                    disabled={false}
                  />
                </Grid>
                <Grid item xs>
                  <TextField
                    disabled={isSubmitting}
                    name="comment"
                    label={'Optional Comment'}
                    multiline={true}
                  />
                </Grid>
              </Grid>
              <Box mt={1} />
            </form>
          ) : null}
        </CustomDialog>
      )}
    </Formik>
  );
}
