import React from 'react';
import { useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

type AuthGuardProps = {
  children?: JSX.Element;
};

export default function AuthGuard({ children }: AuthGuardProps): JSX.Element | null {
  const { isAuthenticated, hasTokenExpired } = useAuth();
  const location = useLocation();

  const isTokenExpired = hasTokenExpired ? hasTokenExpired() : false;

  if (!isAuthenticated || isTokenExpired) {
    // if the user is not authenticated, and they are navigating to a
    // page within the application, then store it in local storage until
    // they log in again
    if (location.pathname.startsWith('/app')) {
      localStorage.setItem('redirectTo', location.pathname);
    }
    window.location.href = '/login';
  } else {
    // if there is a redirectTo entry, redirect the user to that
    // page and then delete the entry to prevent this from
    // happening a second time
    const redirectTo = localStorage.getItem('redirectTo');
    if (redirectTo) {
      window.location.href = redirectTo;
      localStorage.removeItem('redirectTo');
    }
  }

  return children ?? null;
}
