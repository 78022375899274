import {
  faAtom,
  faBoxesPacking,
  faCalendarCirclePlus,
  faCircleInfo,
  faCirclePause,
  faCirclePlay,
  faClipboardCheck,
  faClipboardMedical,
  faClipboardUser,
  faFileCheck,
  faFileExclamation,
  faFileXmark,
  faHospital,
  faHouseMedicalCircleCheck,
  faHouseMedicalCircleExclamation,
  faHouseMedicalCircleXmark,
  faMemoCircleCheck,
  faMemoCircleInfo,
  faNotesMedical,
  faPrint,
  faSensorFire,
  faSoap,
  faTruckMedical,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';
import { Box, Typography, alpha, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ActivityOriginType, ActivityType, EventType, IComment, format } from '@workflow-nx/common';
import { UserAvatar } from '@workflow-nx/ui';
import { useEffect, useState } from 'react';
import { DiscussionListItem } from './DiscussionListItem';
import { TimelineActivity } from './DiscussionTabView';
import { TimelineInfoContent } from './TimelineInfoContent';

const useStyles = makeStyles(() => ({
  icon: {
    display: 'flex',
    alignItems: 'center',
  },
  timelineOpposite: {
    flex: '0',
  },
}));

export function DiscussionTimelineItem(props: {
  activity: TimelineActivity;
  index: number;
  disabled?: boolean;
  comment?: IComment;
  isPatientView?: boolean;
  onPinClicked?: () => void;
  onDeleteClicked?: () => void;
  onEditClicked?: () => void;
  onUpdate?: () => void;
}) {
  const classes = useStyles();
  const theme = useTheme();
  const timelineOppositeContentPadding = props.comment ? 0 : 1;
  const activityType = props.activity?.activityType;
  const originType = props.activity?.originType;
  const [icon, setIcon] = useState(faCircleInfo);
  const [timelineDate, setTimelineDate] = useState('');
  const [iconColor, setIconColor] = useState('');
  const [bgColor, setBGColor] = useState('');

  const setTimelineIcon = () => {
    if (originType === ActivityOriginType.Event) {
      switch (props?.activity?.entry?.eventType) {
        case EventType.BuildPlateConfirmationComplete:
        case EventType.AdditivePrintingComplete: {
          setIcon(faPrint);
          break;
        }
        case EventType.HipComplete:
        case EventType.HeatTreatComplete:
          setIcon(faSensorFire);
          break;

        case EventType.PostHipInspectionComplete:
        case EventType.PostHeatTreatInspectionComplete:
          setIcon(faClipboardCheck);
          break;

        case EventType.NitricPassivationComplete: {
          setIcon(faAtom);
          break;
        }
        case EventType.CleaningAndPackagingComplete: {
          setIcon(faBoxesPacking);
          break;
        }
        case EventType.SterilizationComplete: {
          setIcon(faSoap);
          break;
        }
        case EventType.FinalReleaseComplete: {
          setIcon(faTruckMedical);
          break;
        }
        case EventType.HospitalDeliveryComplete: {
          setIcon(faHospital);
          break;
        }
        case EventType.DHRReviewRequestApproved: {
          setIcon(faFileCheck);
          break;
        }
        case EventType.DHRReviewRequestRejected: {
          setIcon(faFileXmark);
          break;
        }
        case EventType.DHRReviewRequested: {
          setIcon(faFileExclamation);
          break;
        }
        default: {
          setIcon(faCalendarCirclePlus);
          break;
        }
      }
    } else {
      switch (activityType) {
        case ActivityType.CaseCreate: {
          setIcon(faHouseMedicalCircleCheck);
          break;
        }
        case ActivityType.CaseStudyDateUpdated:
        case ActivityType.CaseUpdateNumber: {
          setIcon(faClipboardMedical);
          break;
        }
        case ActivityType.CaseUpdateStudyDate: {
          setIcon(faClipboardUser);
          break;
        }
        case ActivityType.CaseCancelled: {
          setIcon(faHouseMedicalCircleExclamation);
          break;
        }
        case ActivityType.CaseDelete: {
          setIcon(faHouseMedicalCircleXmark);
          break;
        }
        case ActivityType.CaseUncancelled: {
          setIcon(faHouseMedicalCircleCheck);
          break;
        }
        case ActivityType.CaseResultsUpdated: {
          setIcon(faNotesMedical);
          break;
        }
        case ActivityType.CaseHold: {
          setIcon(faCirclePause);
          break;
        }
        case ActivityType.CaseRemoveHold: {
          setIcon(faCirclePlay);
          break;
        }
        default: {
          setIcon(faCircleInfo);
        }
      }

      const CaseStageCompleted = activityType.match(/^CASE_STAGE_.*_COMPLETE$/);
      const CaseStageReopened = activityType.match(/^CASE_STAGE_.*_REOPENED$/);
      if (CaseStageCompleted) {
        setIcon(faMemoCircleCheck);
      }
      if (CaseStageReopened || activityType === ActivityType.PlanProposalRejected) {
        setIcon(faMemoCircleInfo);
      }
    }
  };

  const setTimelineColor = () => {
    if (activityType === ActivityType.CaseCancelled) {
      setBGColor(alpha(theme.palette.error.light, 0.1));
      setIconColor(theme.palette.error.main);
    } else if (activityType === ActivityType.CaseStageProductionComplete && props.activity.entry) {
      setBGColor(alpha(theme.palette.success.light, 0.1));
      setIconColor(theme.palette.success.main);
    } else if (
      activityType.match(/^CASE_STAGE_.*_COMPLETE$/) ||
      activityType === ActivityType.CaseRemoveHold ||
      activityType === ActivityType.CaseUncancelled
    ) {
      setBGColor(alpha(theme.palette.info.light, 0.1));
      setIconColor(theme.palette.info.main);
    } else if (
      activityType.match(/^CASE_STAGE_.*_REOPENED$/) ||
      activityType === ActivityType.PlanProposalRejected ||
      activityType === ActivityType.CaseHold
    ) {
      setBGColor(alpha(theme.palette.warning.light, 0.1));
      setIconColor(theme.palette.warning.main);
    } else {
      setIconColor(theme.palette.text.secondary);
      setBGColor('#fff');
    }
  };

  useEffect(() => {
    setTimelineIcon();
    setTimelineColor();
    if (originType === ActivityOriginType.Event) {
      setTimelineDate(format.formatISODate(props.activity?.entry?.occurredAt, 'MMM dd, yyyy'));
    } else {
      {
        setTimelineDate(format.formatDate(props.activity.createdAt, 'MMM dd, yyyy'));
      }
    }
  }, []);

  return (
    <Box my={0.3} bgcolor={bgColor}>
      <TimelineItem>
        <TimelineOppositeContent className={classes.timelineOpposite}>
          <Box paddingTop={timelineOppositeContentPadding} width={150}>
            <Typography variant={'body1'} color={'textPrimary'} noWrap>
              {timelineDate}
            </Typography>
            {props.comment ? (
              <Typography variant={'body2'} color={'textSecondary'}>
                {format.formatDateTime(props.activity.createdAt, 'h:mma')}
              </Typography>
            ) : null}
          </Box>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            paddingTop={1}
            paddingBottom={1}
            width={50}
            height={50}
          >
            {props.comment ? (
              <UserAvatar
                user={props.comment?.createdByUser}
                hideDetails={false}
                hideMarginRight={true}
              />
            ) : (
              <FontAwesomeIcon
                icon={icon}
                className={classes.icon}
                fixedWidth={true}
                style={{ padding: '1px', margin: '2px' }}
                size={'2x'}
                color={iconColor}
              />
            )}
          </Box>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent data-testid={`timeline-test-${props.index}`}>
          <Box>
            {props.comment ? (
              <DiscussionListItem
                key={props.comment?.commentId}
                comment={props.comment}
                disabled={props.disabled}
                isPatientView={props.isPatientView}
                onPinClicked={props.onPinClicked}
                onDeleteClicked={props.onDeleteClicked}
                onEditClicked={props.onEditClicked}
              />
            ) : (
              <TimelineInfoContent
                activity={props.activity}
                disabled={props.disabled}
                onUpdate={props.onUpdate}
              />
            )}
          </Box>
        </TimelineContent>
      </TimelineItem>
    </Box>
  );
}
