import React, { useEffect } from 'react';
import Page from '../../../components/Page';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import SplashScreen from '../../../components/SplashScreen';
import useAuth from '../../../hooks/useAuth';
import { UserStatusType } from '@workflow-nx/common';

const CodeView = () => {
  const location = useLocation();
  const auth = useAuth();
  const navigate = useNavigate();

  const handleLoad = async () => {
    const hash = location?.hash?.replace('#', '');
    const searchParams: URLSearchParams = new URLSearchParams(hash);
    const id_token = searchParams.get('id_token');
    const access_token = searchParams.get('access_token');

    if (!access_token || !id_token) {
      throw new Error(`Unable to authenticate user`);
    }
    const userStatus = await auth?.validate?.(access_token, id_token);
    if (userStatus === UserStatusType.Active) {
      navigate('/');
    } else {
      localStorage.removeItem('jwt_token');
      localStorage.removeItem('user');

      window.location.href = `/login?reason=${userStatus ?? 'ERROR'}`;
    }
  };

  useEffect(() => {
    handleLoad();
  }, [handleLoad]);

  return (
    <Page>
      <SplashScreen />
    </Page>
  );
};

export default CodeView;
