import React from 'react';
import { Box, Typography } from '@mui/material';
import { Control, FieldValues } from 'react-hook-form';
import { ErrorMessageRHF, CheckboxListFieldRHF, TextFieldRHF } from '@workflow-nx/ui';
import {
  AssetType,
  LordosisDifferenceRationaleType,
  ValidFileExtensions,
  format,
  IAsset,
} from '@workflow-nx/common';
import { CaseReportFileRow } from './CaseReportFileRow';
import { lordosisDifferenceRationaleTypeListItems } from './caseReportMenuOptions';
import { NumberTextFieldRHF } from '../../../../../components/NumberTextFieldRHF';
import { CaseReportDialogStateType } from './CaseReportDialog.reducer';
import { toNumber } from 'lodash';

interface CaseReportPreOpXrayMeasurementsProps {
  control: Control<FieldValues>;
  FormData: CaseReportDialogStateType;
  disabled: boolean;
  isEditingAllowed: boolean;
  getAsset: (assetType: AssetType) => IAsset | undefined;
  clearAsset: (assetType: AssetType) => void;
}

export const CaseReportPreOpXrayMeasurements: React.FC<CaseReportPreOpXrayMeasurementsProps> = ({
  control,
  FormData,
  disabled,
  isEditingAllowed,
  getAsset,
  clearAsset,
}) => {
  return (
    <>
      <Box display={'flex'} alignItems={'center'} mt={4}>
        <Box display={'flex'} justifyContent={'left'}>
          <Typography variant={'h5'}>
            <strong>Pre-operative X-ray Measurements Section</strong>
          </Typography>
        </Box>
      </Box>
      <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
        <Box display={'flex'} width={240} justifyContent={'left'} alignItems={'center'}>
          <Typography variant={'body1'}>
            {format.formatAssetType(AssetType.CaseReportStandingXrayLateralMeasured)}
          </Typography>
        </Box>
        <Box display={'flex'} width={650} justifyContent={'left'}>
          <CaseReportFileRow
            id={'caseReportStandingXrayLateralMeasured'}
            label={'Standing X-ray'}
            editing={isEditingAllowed}
            asset={getAsset(AssetType.CaseReportStandingXrayLateralMeasured)}
            onClick={() => clearAsset(AssetType.CaseReportStandingXrayLateralMeasured)}
            control={control as unknown as Control<FieldValues>}
            validFileExtensions={[ValidFileExtensions.JPG]}
            inputAssetFile={FormData?.caseReportStandingXrayLateralMeasured}
          />
        </Box>
        <Box display={'flex'} width={240} justifyContent={'left'}>
          <ErrorMessageRHF
            name={'caseReportStandingXrayLateralMeasured'}
            control={control as unknown as Control<FieldValues>}
          />
        </Box>
      </Box>
      <Box display={'flex'} alignItems={'flex-start'} my={2} gap={1}>
        <Box display={'flex'} width={240} justifyContent={'left'}>
          <Typography variant={'body1'}>Pelvic Incidence (°)</Typography>
        </Box>
        <Box display={'flex'} width={650} justifyContent={'left'}>
          <NumberTextFieldRHF
            allowNegative={true}
            decimalPlaces={2}
            name={`patientRecord.pelvicIncidence`}
            control={control as unknown as Control<FieldValues>}
            label={''}
            disabled={disabled}
          />
        </Box>
        <Box display={'flex'} width={240} justifyContent={'left'}>
          <ErrorMessageRHF
            name={'patientRecord.pelvicIncidence'}
            control={control as unknown as Control<FieldValues>}
          />
        </Box>
      </Box>
      <Box display={'flex'} alignItems={'flex-start'} my={2} gap={1}>
        <Box display={'flex'} width={240} justifyContent={'left'}>
          <Typography variant={'body1'}>Lumbar Coronal Angle (°)</Typography>
        </Box>
        <Box display={'flex'} width={650} justifyContent={'left'}>
          <NumberTextFieldRHF
            allowNegative={true}
            decimalPlaces={2}
            name={`patientRecord.lumbarCoronalCobb`}
            control={control as unknown as Control<FieldValues>}
            label={''}
            disabled={disabled}
          />
        </Box>
        <Box display={'flex'} width={240} justifyContent={'left'}>
          <ErrorMessageRHF
            name={'patientRecord.lumbarCoronalCobb'}
            control={control as unknown as Control<FieldValues>}
          />
        </Box>
      </Box>
      <Box display={'flex'} alignItems={'flex-start'} my={2} gap={1}>
        <Box display={'flex'} width={240} justifyContent={'left'}>
          <Typography variant={'body1'}>L4-S1 Lordotic Distribution %</Typography>
        </Box>
        <Box display={'flex'} width={650} justifyContent={'left'}>
          <NumberTextFieldRHF
            allowNegative={true}
            decimalPlaces={2}
            name={`patientRecord.l4S1LordoticDistribution`}
            control={control as unknown as Control<FieldValues>}
            label={''}
            disabled={disabled}
          />
        </Box>
        <Box display={'flex'} width={240} justifyContent={'left'}>
          <ErrorMessageRHF
            name={'patientRecord.l4S1LordoticDistribution'}
            control={control as unknown as Control<FieldValues>}
          />
        </Box>
      </Box>
      <Box display={'flex'} alignItems={'flex-start'} my={2} gap={1}>
        <Box display={'flex'} width={240} justifyContent={'left'}>
          <Typography variant={'body1'}>Global Tilt</Typography>
        </Box>
        <Box display={'flex'} width={650} justifyContent={'left'}>
          <NumberTextFieldRHF
            allowNegative={true}
            decimalPlaces={2}
            name={`patientRecord.globalTilt`}
            control={control as unknown as Control<FieldValues>}
            label={''}
            disabled={disabled}
          />
        </Box>
        <Box display={'flex'} width={240} justifyContent={'left'}>
          <ErrorMessageRHF
            name={'patientRecord.globalTilt'}
            control={control as unknown as Control<FieldValues>}
          />
        </Box>
      </Box>
      <Box display={'flex'} alignItems={'flex-start'} my={2} gap={1}>
        <Box display={'flex'} width={240} justifyContent={'left'}>
          <Typography variant={'body1'}>Pelvic Tilt</Typography>
        </Box>
        <Box display={'flex'} width={650} justifyContent={'left'}>
          <NumberTextFieldRHF
            allowNegative={true}
            decimalPlaces={2}
            name={`patientRecord.pelvicTilt`}
            control={control as unknown as Control<FieldValues>}
            label={''}
            disabled={disabled}
          />
        </Box>
        <Box display={'flex'} width={240} justifyContent={'left'}>
          <ErrorMessageRHF
            name={'patientRecord.pelvicTilt'}
            control={control as unknown as Control<FieldValues>}
          />
        </Box>
      </Box>
      <Box display={'flex'} alignItems={'flex-start'} my={2} gap={1}>
        <Box display={'flex'} width={240} justifyContent={'left'}>
          <Typography variant={'body1'}>Sacral Slope</Typography>
        </Box>
        <Box display={'flex'} width={650} justifyContent={'left'}>
          <NumberTextFieldRHF
            allowNegative={true}
            decimalPlaces={2}
            name={`patientRecord.sacralSlope`}
            control={control as unknown as Control<FieldValues>}
            label={''}
            disabled={disabled}
          />
        </Box>
        <Box display={'flex'} width={240} justifyContent={'left'}>
          <ErrorMessageRHF
            name={'patientRecord.sacralSlope'}
            control={control as unknown as Control<FieldValues>}
          />
        </Box>
      </Box>
      <Box display={'flex'} alignItems={'flex-start'} my={2} gap={1}>
        <Box display={'flex'} width={240} justifyContent={'left'}>
          <Typography variant={'body1'}>c7-Sagittal Veritical Line</Typography>
        </Box>
        <Box display={'flex'} width={650} justifyContent={'left'}>
          <NumberTextFieldRHF
            allowNegative={true}
            decimalPlaces={2}
            name={`patientRecord.c7SagittalVerticalLine`}
            control={control as unknown as Control<FieldValues>}
            label={''}
            disabled={disabled}
          />
        </Box>
        <Box display={'flex'} width={240} justifyContent={'left'}>
          <ErrorMessageRHF
            name={'patientRecord.c7SagittalVerticalLine'}
            control={control as unknown as Control<FieldValues>}
          />
        </Box>
      </Box>
      <Box display={'flex'} alignItems={'flex-start'} my={2} gap={1}>
        <Box display={'flex'} width={240} justifyContent={'left'}>
          <Typography variant={'body1'}>Sagittal Vertical Axis</Typography>
        </Box>
        <Box display={'flex'} width={650} justifyContent={'left'}>
          <NumberTextFieldRHF
            allowNegative={true}
            decimalPlaces={2}
            name={`patientRecord.sagittalVerticalAxis`}
            control={control as unknown as Control<FieldValues>}
            label={''}
            disabled={disabled}
          />
        </Box>
        <Box display={'flex'} width={240} justifyContent={'left'}>
          <ErrorMessageRHF
            name={'patientRecord.sagittalVerticalAxis'}
            control={control as unknown as Control<FieldValues>}
          />
        </Box>
      </Box>
      <Box display={'flex'} alignItems={'flex-start'} my={2} gap={1}>
        <Box display={'flex'} width={240} justifyContent={'left'}>
          <Typography variant={'body1'}>Lumbar Lordosis (X-ray) (°)</Typography>
        </Box>
        <Box display={'flex'} width={650} justifyContent={'left'}>
          <NumberTextFieldRHF
            allowNegative={true}
            decimalPlaces={2}
            name={`patientRecord.lumbarLordosis`}
            control={control as unknown as Control<FieldValues>}
            label={''}
            disabled={disabled}
          />
        </Box>
        <Box display={'flex'} width={240} justifyContent={'left'}>
          <ErrorMessageRHF
            name={'patientRecord.lumbarLordosis'}
            control={control as unknown as Control<FieldValues>}
          />
        </Box>
      </Box>
      <Box display={'flex'} alignItems={'flex-start'} my={2} gap={1}>
        <Box display={'flex'} width={240} justifyContent={'left'}>
          <Typography variant={'body1'}>Lumbar Lordosis (CT) (°)</Typography>
        </Box>
        <Box display={'flex'} width={650} justifyContent={'left'}>
          <Typography variant={'body1'}>
            {'\u00A0\u00A0\u00A0'}
            {FormData?.preOp?.lumbarLordosis.toFixed(0)}
          </Typography>
        </Box>
        <Box display={'flex'} width={240} justifyContent={'left'}></Box>
      </Box>
      <Box display={'flex'} alignItems={'flex-start'} my={2} gap={1}>
        <Box display={'flex'} width={240} justifyContent={'left'}>
          <Typography variant={'body1'}> Difference (CT LL and X-Ray LL)</Typography>
        </Box>
        <Box display={'flex'} width={650} justifyContent={'left'}>
          <Typography variant={'body1'}>
            {FormData.measurementLordosisDifference
              ? `${FormData.measurementLordosisDifference}°`
              : 'Invalid'}
          </Typography>
        </Box>
        <Box display={'flex'} width={240} justifyContent={'left'}></Box>
      </Box>
      {toNumber(FormData.measurementLordosisDifference) > 10 ? (
        <>
          <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <Typography variant={'body1'}>Rationale for difference greater than 10° </Typography>
            </Box>
            <Box display={'flex'} width={650} justifyContent={'left'}>
              <CheckboxListFieldRHF
                label={''}
                name={'measurementLordosisDifferenceRationale'}
                control={control as unknown as Control<FieldValues>}
                disabled={disabled}
                disableInline={true}
                displayError={false}
                listItems={lordosisDifferenceRationaleTypeListItems}
              />
            </Box>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <ErrorMessageRHF
                name={'measurementLordosisDifferenceRationale'}
                control={control as unknown as Control<FieldValues>}
              />
            </Box>
          </Box>
          {FormData.measurementLordosisDifferenceRationale.includes(
            LordosisDifferenceRationaleType.Other,
          ) ? (
            <Box display={'flex'} alignItems={'flex-start'} my={2} gap={1}>
              <Box display={'flex'} width={240} justifyContent={'left'}>
                <Typography variant={'body1'}>Difference Rationale Other Note</Typography>
              </Box>
              <Box display={'flex'} width={650} justifyContent={'left'}>
                <TextFieldRHF
                  name={'measurementLordosisDifferenceRationaleNote'}
                  control={control as unknown as Control<FieldValues>}
                  label={''}
                  fullWidth={true}
                  multiline={true}
                  disabled={disabled}
                />
              </Box>
              <Box display={'flex'} width={240} justifyContent={'left'}>
                <ErrorMessageRHF
                  name={'measurementLordosisDifferenceRationaleNote'}
                  control={control as unknown as Control<FieldValues>}
                />
              </Box>
            </Box>
          ) : null}
        </>
      ) : null}
      <Box display={'flex'} alignItems={'flex-start'} my={2} gap={1}>
        <Box display={'flex'} width={240} justifyContent={'left'}>
          <Typography variant={'body1'}>Measurement Note</Typography>
        </Box>
        <Box display={'flex'} width={650} justifyContent={'left'}>
          <TextFieldRHF
            name={'measurementNote'}
            control={control as unknown as Control<FieldValues>}
            label={''}
            fullWidth={true}
            multiline={true}
            disabled={disabled}
          />
        </Box>
        <Box display={'flex'} width={240} justifyContent={'left'}></Box>
      </Box>
    </>
  );
};
