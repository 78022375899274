import { TableBody, TableRow } from '@mui/material';
import { Skeleton } from '@mui/material';
import React from 'react';
import { TableCell } from './TableCell';

export function LoadingTableBody(props: { rows: number; cols: number }) {
  return (
    <TableBody>
      {new Array(props.rows).fill(0).map((_, index) => (
        <TableRow key={index}>
          {new Array(props.cols).fill(0).map((_, index) => (
            <TableCell key={index}>
              <Skeleton />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
}
