import React from 'react';
import { useQuery } from '@apollo/client';
import { FIND_CASES } from '../../../gql';
import { CaseStageType, ICase } from '@workflow-nx/common';
import Page from '../../../components/Page';
import { Box, Card, Container, Grid } from '@mui/material';
import { CustomAppBar } from '../../../components/CustomAppBar';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CaseListTable from '../../cases/ListCasesView/CaseListTable';

const ListCasesReadyForReview = () => {
  const { loading, data } = useQuery(FIND_CASES, {
    variables: {
      stageFilter: [
        CaseStageType.Open,
        CaseStageType.Segmentation,
        CaseStageType.Planning,
        CaseStageType.Proposed,
        CaseStageType.Design,
        CaseStageType.QaReview,
        CaseStageType.Production,
      ],
      orderBy: { surgeryDate: 'asc' },
    },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Page title={'Cases'}>
      <Container>
        <CustomAppBar title={`Cases Ready For Review`} />
        <Box mt={3}>
          <Grid container>
            <Grid item xs={12}>
              <Card>
                <PerfectScrollbar>
                  <Box p={3}>
                    {!loading ? (
                      <CaseListTable
                        cases={data?.cases?.cases}
                        shouldHighlight={(activeCase: ICase) =>
                          activeCase.stage === CaseStageType.QaReview
                        }
                        onSort={() => {}}
                        onPagination={() => {}}
                      />
                    ) : null}
                  </Box>
                </PerfectScrollbar>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
};

export default ListCasesReadyForReview;
