import {
  SegmentationImagingAssessmentType,
  SagittalGoalType,
  CoronalGoalType,
  LordosisDifferenceRationaleType,
  SegmentationSliceThicknessType,
  CaseRiskAssessmentType,
  PlanFeedbackType,
  format,
} from '@workflow-nx/common';

import {
  formatSegmentationImagingAssessmentType,
  formatLordosisDifferenceRationaleType,
  formatSegmentationSliceThicknessType,
  formatPlanFeedbackType,
} from '../../../../../utils/caseReport/caseReportFormat';

export const segmentationImagingAssessmentTypeOptions = [
  {
    key: SegmentationImagingAssessmentType.GoldCtFullLengthXrayWithFemoralHeads,
    value: formatSegmentationImagingAssessmentType(
      SegmentationImagingAssessmentType.GoldCtFullLengthXrayWithFemoralHeads,
    ),
  },
  {
    key: SegmentationImagingAssessmentType.SilverCtStandardLumbarXrayWithFemoralHeads,
    value: formatSegmentationImagingAssessmentType(
      SegmentationImagingAssessmentType.SilverCtStandardLumbarXrayWithFemoralHeads,
    ),
  },
  {
    key: SegmentationImagingAssessmentType.BronzeCtStandardLumbarXrayWithoutFemoralHeads,
    value: formatSegmentationImagingAssessmentType(
      SegmentationImagingAssessmentType.BronzeCtStandardLumbarXrayWithoutFemoralHeads,
    ),
  },
  {
    key: SegmentationImagingAssessmentType.BronzeCtNoXrayOrUnusableXray,
    value: formatSegmentationImagingAssessmentType(
      SegmentationImagingAssessmentType.BronzeCtNoXrayOrUnusableXray,
    ),
  },
];

export const sagittalGoalTypeMenuItems = [
  {
    key: SagittalGoalType.AgeAdjusted,
    value: format.formatSagittalGoalType(SagittalGoalType.AgeAdjusted),
  },
  {
    key: SagittalGoalType.MinimalMismatch,
    value: format.formatSagittalGoalType(SagittalGoalType.MinimalMismatch),
  },
  {
    key: SagittalGoalType.GapScore,
    value: format.formatSagittalGoalType(SagittalGoalType.GapScore),
  },
  {
    key: SagittalGoalType.AlternativeSupportivePlanningReference,
    value: format.formatSagittalGoalType(SagittalGoalType.AlternativeSupportivePlanningReference),
  },
  {
    key: SagittalGoalType.Other,
    value: 'Surgeon Request',
  },
];

export const lordosisDifferenceRationaleTypeListItems = [
  {
    key: LordosisDifferenceRationaleType.HypermobilityInDiscSpaces,
    value: formatLordosisDifferenceRationaleType(
      LordosisDifferenceRationaleType.HypermobilityInDiscSpaces,
    ),
  },
  {
    key: LordosisDifferenceRationaleType.XrayQualityCausingInaccurateS1OrL1Placement,
    value: formatLordosisDifferenceRationaleType(
      LordosisDifferenceRationaleType.XrayQualityCausingInaccurateS1OrL1Placement,
    ),
  },
  {
    key: LordosisDifferenceRationaleType.XrayQualityCausingInaccurateFemoralHeadPlacement,
    value: formatLordosisDifferenceRationaleType(
      LordosisDifferenceRationaleType.XrayQualityCausingInaccurateFemoralHeadPlacement,
    ),
  },
  {
    key: LordosisDifferenceRationaleType.Other,
    value: formatLordosisDifferenceRationaleType(LordosisDifferenceRationaleType.Other),
  },
];

export const coronalGoalTypeMenuItems = [
  {
    key: CoronalGoalType.ParallelDiscSpaceAtTreatmentLevels,
    value: format.formatCoronalGoalType(CoronalGoalType.ParallelDiscSpaceAtTreatmentLevels),
  },
  {
    key: CoronalGoalType.SuperiorEndplateOfHighestTreatedLevelParallelToSacrum,
    value: format.formatCoronalGoalType(
      CoronalGoalType.SuperiorEndplateOfHighestTreatedLevelParallelToSacrum,
    ),
  },
  {
    key: CoronalGoalType.SuperiorL1EndplateParallelToSacrum,
    value: format.formatCoronalGoalType(CoronalGoalType.SuperiorL1EndplateParallelToSacrum),
  },
  {
    key: CoronalGoalType.SuperiorL1EndplateParallelToFloor,
    value: format.formatCoronalGoalType(CoronalGoalType.SuperiorL1EndplateParallelToFloor),
  },
  {
    key: CoronalGoalType.Other,
    value: 'Surgeon Request',
  },
];

export const segmentationSliceThicknessTypeMenuItems = [
  {
    key: SegmentationSliceThicknessType.LessThanOneMm,
    value: formatSegmentationSliceThicknessType(SegmentationSliceThicknessType.LessThanOneMm),
  },
  {
    key: SegmentationSliceThicknessType.BetweenOneAndTwoMm,
    value: formatSegmentationSliceThicknessType(SegmentationSliceThicknessType.BetweenOneAndTwoMm),
  },
  {
    key: SegmentationSliceThicknessType.GreaterThanTwoMm,
    value: formatSegmentationSliceThicknessType(SegmentationSliceThicknessType.GreaterThanTwoMm),
  },
];

export const caseRiskAssessmentTypeOptions = [
  {
    key: CaseRiskAssessmentType.CaseReviewQaReview,
    value: format.formatCaseRiskAssessmentType(CaseRiskAssessmentType.CaseReviewQaReview),
  },
  {
    key: CaseRiskAssessmentType.QaReviewOnly,
    value: format.formatCaseRiskAssessmentType(CaseRiskAssessmentType.QaReviewOnly),
  },
];

export const planFeedbackTypeMenuItems = [
  {
    key: PlanFeedbackType.NoFeedback,
    value: formatPlanFeedbackType(PlanFeedbackType.NoFeedback),
  },
  {
    key: PlanFeedbackType.AnatomicalConstraints,
    value: formatPlanFeedbackType(PlanFeedbackType.AnatomicalConstraints),
  },
  {
    key: PlanFeedbackType.ImplantFootprintSpecification,
    value: formatPlanFeedbackType(PlanFeedbackType.ImplantFootprintSpecification),
  },
  {
    key: PlanFeedbackType.ImplantHeightSpecification,
    value: formatPlanFeedbackType(PlanFeedbackType.ImplantHeightSpecification),
  },
  {
    key: PlanFeedbackType.KyphoticSpecification,
    value: formatPlanFeedbackType(PlanFeedbackType.KyphoticSpecification),
  },
  {
    key: PlanFeedbackType.LordoticSpecification,
    value: formatPlanFeedbackType(PlanFeedbackType.LordoticSpecification),
  },
  {
    key: PlanFeedbackType.Other,
    value: formatPlanFeedbackType(PlanFeedbackType.Other),
  },
];
