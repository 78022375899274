import {
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faRotateLeft,
  faRotateRight,
  faX,
} from '@fortawesome/pro-solid-svg-icons';
import { Box } from '@mui/material';
import { Axis } from '@workflow-nx/common';
import { useState } from 'react';
import { Select } from '../../../../../../components/Select';
import { useKeyPress } from '../../../../../../hooks/useKeyPress';
import { KeyboardButton } from './KeyboardButton';

export enum CoordinateValueTypes {
  XRotation = 'X_ROTATION',
  YRotation = 'Y_ROTATION',
  ZRotation = 'Z_ROTATION',
  XPosition = 'X_POSITION',
  YPosition = 'Y_POSITION',
  ZPosition = 'Z_POSITION',
}

export enum AxisColors {
  Z = '#309630',
  Y = '#4343e0',
  X = '#d43f3f',
}

export enum CoordinateTypes {
  Rotation = 'ROTATION',
  Position = 'POSITION',
}

type CoordinateInputContainerProps = {
  onChange: (value: number, type: CoordinateValueTypes) => void;
  active: boolean;
  coordinateType: CoordinateTypes;
  keyConfiguration: DirectionalKeyboardMappingTypes;
};

export enum DirectionalKeyboardMappingTypes {
  KeyboardLeft,
  KeyboardRight,
}

enum DirectionalKeyboardMappedKeys {
  Up,
  Down,
  Left,
  Right,
  Back,
  Forward,
}

const directionalKeyboardMapping = {
  [DirectionalKeyboardMappingTypes.KeyboardLeft]: {
    [DirectionalKeyboardMappedKeys.Up]: 'KeyW',
    [DirectionalKeyboardMappedKeys.Down]: 'KeyS',
    [DirectionalKeyboardMappedKeys.Left]: 'KeyA',
    [DirectionalKeyboardMappedKeys.Right]: 'KeyD',
    [DirectionalKeyboardMappedKeys.Forward]: 'KeyZ',
    [DirectionalKeyboardMappedKeys.Back]: 'KeyC',
  },
  [DirectionalKeyboardMappingTypes.KeyboardRight]: {
    [DirectionalKeyboardMappedKeys.Up]: 'KeyY',
    [DirectionalKeyboardMappedKeys.Down]: 'KeyH',
    [DirectionalKeyboardMappedKeys.Left]: 'KeyG',
    [DirectionalKeyboardMappedKeys.Right]: 'KeyJ',
    [DirectionalKeyboardMappedKeys.Forward]: 'KeyB',
    [DirectionalKeyboardMappedKeys.Back]: 'KeyM',
  },
};

export function CoordinateInputContainer({
  onChange,
  coordinateType,
  keyConfiguration,
  active,
}: CoordinateInputContainerProps) {
  const [selectedKey, setSelectedKey] = useState<string | null>(null);
  const [increment, setIncrement] = useState<number>(1);

  const handleChange = (value: number, type: CoordinateValueTypes) => {
    setSelectedKey(null);
    onChange(value, type);
  };

  useKeyPress(
    (event: KeyboardEvent) => {
      if (!active) return;

      let value = 0;
      let isPositionSelected = coordinateType === CoordinateTypes.Position;
      let coordinateValueType: CoordinateValueTypes = isPositionSelected
        ? CoordinateValueTypes.XPosition
        : CoordinateValueTypes.XRotation;

      setSelectedKey(event.code);

      const keyMap = directionalKeyboardMapping[keyConfiguration];

      switch (event.code) {
        case keyMap[DirectionalKeyboardMappedKeys.Forward]:
          value = -1;
          coordinateValueType = isPositionSelected
            ? CoordinateValueTypes.ZPosition
            : CoordinateValueTypes.ZRotation;
          break;
        case keyMap[DirectionalKeyboardMappedKeys.Back]:
          value = 1;
          coordinateValueType = isPositionSelected
            ? CoordinateValueTypes.ZPosition
            : CoordinateValueTypes.ZRotation;
          break;
        case keyMap[DirectionalKeyboardMappedKeys.Up]:
          value = 1;
          coordinateValueType = isPositionSelected
            ? CoordinateValueTypes.YPosition
            : CoordinateValueTypes.YRotation;
          break;
        case keyMap[DirectionalKeyboardMappedKeys.Down]:
          value = -1;
          coordinateValueType = isPositionSelected
            ? CoordinateValueTypes.YPosition
            : CoordinateValueTypes.YRotation;
          break;
        case keyMap[DirectionalKeyboardMappedKeys.Left]:
          value = -1;
          coordinateValueType = isPositionSelected
            ? CoordinateValueTypes.XPosition
            : CoordinateValueTypes.XRotation;
          break;
        case keyMap[DirectionalKeyboardMappedKeys.Right]:
          value = 1;
          coordinateValueType = isPositionSelected
            ? CoordinateValueTypes.XPosition
            : CoordinateValueTypes.XRotation;
          break;
      }

      if (value && coordinateValueType) {
        handleChange(value * increment, coordinateValueType);
      }
    },

    [
      DirectionalKeyboardMappingTypes.KeyboardLeft,
      DirectionalKeyboardMappingTypes.KeyboardRight,
    ].flatMap((m) => [
      directionalKeyboardMapping[m][DirectionalKeyboardMappedKeys.Up],
      directionalKeyboardMapping[m][DirectionalKeyboardMappedKeys.Down],
      directionalKeyboardMapping[m][DirectionalKeyboardMappedKeys.Left],
      directionalKeyboardMapping[m][DirectionalKeyboardMappedKeys.Right],
      directionalKeyboardMapping[m][DirectionalKeyboardMappedKeys.Forward],
      directionalKeyboardMapping[m][DirectionalKeyboardMappedKeys.Back],
    ]),
  );

  const determineCoordinateValueType = (axis: Axis): CoordinateValueTypes => {
    const isPositionType = coordinateType === CoordinateTypes.Position;
    let targetPosition: CoordinateValueTypes = CoordinateValueTypes.XPosition;

    switch (axis) {
      case Axis.X:
        targetPosition = isPositionType
          ? CoordinateValueTypes.XPosition
          : CoordinateValueTypes.XRotation;
        break;
      case Axis.Y:
        targetPosition = isPositionType
          ? CoordinateValueTypes.YPosition
          : CoordinateValueTypes.YRotation;
        break;
      case Axis.Z:
        targetPosition = isPositionType
          ? CoordinateValueTypes.ZPosition
          : CoordinateValueTypes.ZRotation;
        break;
      default:
        break;
    }

    return targetPosition;
  };

  const upKey = directionalKeyboardMapping[keyConfiguration][DirectionalKeyboardMappedKeys.Up];
  const downKey = directionalKeyboardMapping[keyConfiguration][DirectionalKeyboardMappedKeys.Down];
  const leftKey = directionalKeyboardMapping[keyConfiguration][DirectionalKeyboardMappedKeys.Left];
  const rightKey =
    directionalKeyboardMapping[keyConfiguration][DirectionalKeyboardMappedKeys.Right];
  const backKey = directionalKeyboardMapping[keyConfiguration][DirectionalKeyboardMappedKeys.Back];
  const forwardKey =
    directionalKeyboardMapping[keyConfiguration][DirectionalKeyboardMappedKeys.Forward];

  const getKeyName = (key: string) => key.replace('Key', '');

  const controlSize = 175;

  return (
    <Box
      flexGrow={1}
      display={'flex'}
      flexDirection="column"
      justifyContent="center"
      alignItems={'center'}
    >
      <Box
        my={2}
        display={'grid'}
        gridTemplateColumns={'repeat(3, 33%)'}
        gridTemplateRows={'repeat(2, 33%)'}
        gap={'5px'}
        width={controlSize}
        height={controlSize}
      >
        <Box />

        <KeyboardButton
          data-testid={`${coordinateType}-y-increment`}
          letter={getKeyName(upKey)}
          direction={'+Y'}
          color={AxisColors.Y}
          selected={
            selectedKey ===
            directionalKeyboardMapping[keyConfiguration][DirectionalKeyboardMappedKeys.Up]
          }
          icon={faChevronUp}
          onClick={() => {
            setSelectedKey(upKey);
            handleChange(1 * increment, determineCoordinateValueType(Axis.Y));
          }}
        />
        <KeyboardButton
          data-testid={`${coordinateType}-z-increment`}
          letter={getKeyName(backKey)}
          color={AxisColors.Z}
          selected={selectedKey === backKey}
          icon={coordinateType === CoordinateTypes.Rotation ? faRotateRight : faX}
          direction={'+Z'}
          onClick={() => {
            setSelectedKey(backKey);
            handleChange(1 * increment, determineCoordinateValueType(Axis.Z));
          }}
        />
        <KeyboardButton
          data-testid={`${coordinateType}-x-decrement`}
          letter={getKeyName(leftKey)}
          selected={selectedKey === leftKey}
          color={AxisColors.X}
          direction={'-X'}
          icon={faChevronLeft}
          onClick={() => {
            setSelectedKey(leftKey);
            handleChange(-1 * increment, determineCoordinateValueType(Axis.X));
          }}
        />
        <Box />
        <KeyboardButton
          data-testid={`${coordinateType}-x-increment`}
          letter={getKeyName(rightKey)}
          color={AxisColors.X}
          selected={selectedKey === rightKey}
          icon={faChevronRight}
          direction={'+X'}
          onClick={() => {
            setSelectedKey(rightKey);
            handleChange(1 * increment, determineCoordinateValueType(Axis.X));
          }}
        />
        <KeyboardButton
          data-testid={`${coordinateType}-z-decrement`}
          letter={getKeyName(forwardKey)}
          color={AxisColors.Z}
          direction={'-Z'}
          icon={coordinateType === CoordinateTypes.Rotation ? faRotateLeft : faCircle}
          selected={selectedKey === forwardKey}
          onClick={() => {
            setSelectedKey(forwardKey);
            handleChange(-1 * increment, determineCoordinateValueType(Axis.Z));
          }}
        />
        <KeyboardButton
          data-testid={`${coordinateType}-y-decrement`}
          letter={getKeyName(downKey)}
          color={AxisColors.Y}
          direction={'-Y'}
          selected={selectedKey === downKey}
          icon={faChevronDown}
          onClick={() => {
            setSelectedKey(downKey);
            handleChange(-1 * increment, determineCoordinateValueType(Axis.Y));
          }}
        />
        <Box />
      </Box>
      <Box display={'flex'} alignItems="center" flexGrow={1} mt={3} width={'100%'}>
        <Select
          data-testid={`${coordinateType}-select`}
          name={'increment'}
          label={'Increment'}
          value={increment.toString()}
          onChange={(e) => setIncrement(Number(e.target.value))}
          disabled={false}
          menuItems={[
            {
              key: '0.1',
              value: `0.10${coordinateType === CoordinateTypes.Rotation ? '°' : 'mm'}`,
            },
            {
              key: '0.25',
              value: `0.25${coordinateType === CoordinateTypes.Rotation ? '°' : 'mm'}`,
            },
            { key: '1', value: `1${coordinateType === CoordinateTypes.Rotation ? '°' : 'mm'}` },
            {
              key: '10',
              value: `10${coordinateType === CoordinateTypes.Rotation ? '°' : 'mm'}`,
            },
          ]}
          fullWidth={true}
          hideNone={true}
        />
      </Box>
    </Box>
  );
}
