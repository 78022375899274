import { format, ImplantType, SurgeonPreferenceMeasurementType } from '@workflow-nx/common';
import { TableRow } from '@mui/material';
import { TableCell } from '../../../../components/TableCell';
import { NumberTextField } from '../../../../components/NumberTextField';
import React from 'react';

export function SurgeonPreferencesMeasurementTableRow(props: {
  implantType: ImplantType;
  measurementType: SurgeonPreferenceMeasurementType;
  implantTypeIndex: number;
  measurementTypeIndex: number;
  disabled: boolean;
}) {
  return (
    (props.implantType !== ImplantType.LLIF ||
      props.measurementType !== SurgeonPreferenceMeasurementType.ImplantPositionDistanceRange) && (
      <TableRow hover>
        <TableCell>
          {format.formatSurgeonPreferenceMeasurementType(props.measurementType)}
        </TableCell>
        <TableCell>
          <NumberTextField
            allowNegative={true}
            decimalPlaces={2}
            name={`surgeonPreferenceImplantMeasurements[${props.measurementTypeIndex}].min`}
            label={''}
            disabled={props.disabled}
          />
        </TableCell>
        <TableCell>
          <NumberTextField
            allowNegative={true}
            decimalPlaces={2}
            name={`surgeonPreferenceImplantMeasurements[${props.measurementTypeIndex}].max`}
            label={''}
            disabled={props.disabled}
          />
        </TableCell>
      </TableRow>
    )
  );
}
