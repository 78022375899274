import {
  Axis,
  CaseSpineProfile,
  CaseSpineType,
  EndPlate,
  LEVEL_CONFIG_MAP,
  Position,
  VertebralBody,
  caseUtils,
} from '@workflow-nx/common';
import * as _ from 'lodash';
import { MeasurementType, MeasurementsVersionType } from './enums';
import {
  CervicalMeasurements,
  IMeasure,
  IMeasurementPoint,
  IMeasurementPointValues,
  IMeasurementVectorData,
  LumbarMeasurements,
  MeasurementDefinition,
} from './interfaces';
import {
  calculateAngleBetweenPointsAlongPlane,
  calculateDistanceBetweenPointsAlongAxis,
  evaluateAxialRotation,
  evaluateCervicalCoronalBalance,
  evaluateCoronalAngleData,
  evaluateHeight,
  evaluateLateralAngleFromGround,
  evaluateLordoticAngleData,
  evaluateSVA,
  evaluateTranslation,
} from './math';

function getMeasurementName(measurement: MeasurementDefinition) {
  return `${measurement.type}.${measurement.start.body}.${measurement.start.endPlate}.${measurement.end.body}.${measurement.end.endPlate}`;
}

const getDefaultMeasurements: (
  spineType: CaseSpineType,
  spineProfile?: CaseSpineProfile,
) => MeasurementDefinition[] = (spineType, spineProfile) => {
  const lumbarMeasurements: MeasurementDefinition[] = [
    {
      type: MeasurementType.LumbarLordosis,
      start: {
        body: VertebralBody.L1 as VertebralBody,
        endPlate: EndPlate.Superior,
      },
      end: {
        body: VertebralBody.S1 as VertebralBody,
        endPlate: EndPlate.Superior,
      },
    },
    {
      type: MeasurementType.LumbarCoronalCobb,
      start: {
        body: VertebralBody.L1 as VertebralBody,
        endPlate: EndPlate.Superior,
      },
      end: {
        body: VertebralBody.S1 as VertebralBody,
        endPlate: EndPlate.Superior,
      },
    },
    {
      type: MeasurementType.L1L4Lordosis,
      start: {
        body: VertebralBody.L1,
        endPlate: EndPlate.Superior,
      },
      end: {
        body: VertebralBody.L4,
        endPlate: EndPlate.Superior,
      },
    },
  ];

  const cervicalMeasurements: MeasurementDefinition[] = [
    {
      type: MeasurementType.CervicalLordosis,
      start: {
        body: VertebralBody.C2,
        endPlate: EndPlate.Inferior,
      },
      end: {
        body: VertebralBody.C7,
        endPlate: EndPlate.Inferior,
      },
    },
    {
      type: MeasurementType.CervicalSagittalVerticalAxis,
      start: {
        body: VertebralBody.C2,
        endPlate: EndPlate.Superior,
      },
      end: {
        body: VertebralBody.C7,
        endPlate: EndPlate.Superior,
      },
    },
    {
      type: MeasurementType.T1Slope,
      start: {
        body: VertebralBody.T1,
        endPlate: EndPlate.Superior,
      },
      end: {
        body: VertebralBody.T1,
        endPlate: EndPlate.Superior,
      },
    },
    {
      type: MeasurementType.SLS,
      start: {
        body: VertebralBody.C1,
        endPlate: EndPlate.Inferior,
      },
      end: {
        body: VertebralBody.C1,
        endPlate: EndPlate.Inferior,
      },
    },
    {
      type: MeasurementType.C2T1CobbAngle,
      start: {
        body: VertebralBody.C2,
        endPlate: EndPlate.Superior,
      },
      end: {
        body: VertebralBody.T1,
        endPlate: EndPlate.Inferior,
      },
    },

    {
      type: MeasurementType.C2C7CoronalCobbAngle,
      start: {
        body: VertebralBody.C2,
        endPlate: EndPlate.Superior,
      },
      end: {
        body: VertebralBody.C7,
        endPlate: EndPlate.Inferior,
      },
    },
    {
      type: MeasurementType.CervicalCoronalBalance,
      start: {
        body: VertebralBody.C2,
        endPlate: EndPlate.Superior,
      },
      end: {
        body: VertebralBody.T1,
        endPlate: EndPlate.Superior,
      },
    },
  ];

  const levelMeasurements: MeasurementDefinition[] = caseUtils
    .getCaseSpineProfile(spineProfile)
    .validLevels.flatMap((level) => {
      const superior = LEVEL_CONFIG_MAP[level].superiorVertebrae;

      const inferior = LEVEL_CONFIG_MAP[level].inferiorVertebrae;

      const allMeasurements = [
        {
          type: MeasurementType.SegmentalLordoticAngle,
          start: {
            body: inferior,
            endPlate: inferior === VertebralBody.S1 ? EndPlate.Superior : EndPlate.Inferior,
          },
          end: {
            body: superior,
            endPlate: EndPlate.Superior,
          },
        },
        {
          type: MeasurementType.LordoticAngle,
          start: {
            body: inferior,
            endPlate: EndPlate.Inferior,
          },
          end: {
            body: superior,
            endPlate: EndPlate.Superior,
          },
        },
        {
          type: MeasurementType.LumbarAngle,
          start: {
            body: inferior,
            endPlate: EndPlate.Superior,
          },
          end: {
            body: VertebralBody.S1,
            endPlate: EndPlate.Superior,
          },
        },
        {
          type: MeasurementType.SegmentalCoronalAngle,
          start: {
            body: inferior,
            endPlate: inferior === VertebralBody.S1 ? EndPlate.Superior : EndPlate.Inferior,
          },
          end: {
            body: superior,
            endPlate: EndPlate.Superior,
          },
        },
        {
          type: MeasurementType.CoronalAngle,
          start: {
            body: inferior,
            endPlate: EndPlate.Inferior,
          },
          end: {
            body: superior,
            endPlate: EndPlate.Superior,
          },
        },
        {
          type: MeasurementType.PosteriorHeight,
          start: {
            body: inferior,
            endPlate: EndPlate.Inferior,
          },
          end: {
            body: superior,
            endPlate: EndPlate.Superior,
          },
        },
        {
          type: MeasurementType.AnteriorHeight,
          start: {
            body: inferior,
            endPlate: EndPlate.Inferior,
          },
          end: {
            body: superior,
            endPlate: EndPlate.Superior,
          },
        },
        {
          type: MeasurementType.AxialRotation,
          start: {
            body: inferior,
            endPlate: EndPlate.Superior,
          },
          end: {
            body: superior,
            endPlate: EndPlate.Superior,
          },
        },
      ];

      if (spineType === CaseSpineType.Cervical) {
        allMeasurements.push(
          {
            type: MeasurementType.APTranslation,
            start: {
              body: inferior,
              endPlate: EndPlate.Inferior,
            },
            end: {
              body: superior,
              endPlate: EndPlate.Superior,
            },
          },
          {
            type: MeasurementType.MLTranslation,
            start: {
              body: inferior,
              endPlate: EndPlate.Inferior,
            },
            end: {
              body: superior,
              endPlate: EndPlate.Superior,
            },
          },
        );
      }

      return allMeasurements;
    });

  const spineMeasurements =
    spineType === CaseSpineType.Cervical ? cervicalMeasurements : lumbarMeasurements;

  return spineMeasurements.concat(levelMeasurements);
};

const getAPTranslation = (
  inferior: VertebralBody | string,
  superior: VertebralBody | string,
  values: IMeasurementPointValues,
) => {
  const name = `${MeasurementType.APTranslation}.${inferior}.${EndPlate.Inferior}.${superior}.${EndPlate.Superior}`;
  return getMeasurementValue(name, values);
};

const getMLTranslation = (
  inferior: VertebralBody | string,
  superior: VertebralBody | string,
  values: IMeasurementPointValues,
) => {
  const name = `${MeasurementType.MLTranslation}.${inferior}.${EndPlate.Inferior}.${superior}.${EndPlate.Superior}`;
  return getMeasurementValue(name, values);
};

const getAxialRotation = (
  inferior: VertebralBody | string,
  superior: VertebralBody | string,
  values: IMeasurementPointValues,
) => {
  const name = `${MeasurementType.AxialRotation}.${inferior}.${EndPlate.Superior}.${superior}.${EndPlate.Superior}`;
  return getMeasurementValue(name, values);
};

const getPosteriorHeight = (
  inferior: VertebralBody | string,
  superior: VertebralBody | string,
  values: IMeasurementPointValues,
) => {
  const name = `${MeasurementType.PosteriorHeight}.${inferior}.${EndPlate.Inferior}.${superior}.${EndPlate.Superior}`;
  return getMeasurementValue(name, values);
};

const getAnteriorHeight = (
  inferior: VertebralBody | string,
  superior: VertebralBody | string,
  values: IMeasurementPointValues,
) => {
  const name = `${MeasurementType.AnteriorHeight}.${inferior}.${EndPlate.Inferior}.${superior}.${EndPlate.Superior}`;
  return getMeasurementValue(name, values);
};

const getLumbarLordosis = (values: IMeasurementPointValues): number | null => {
  const name: string = `${MeasurementType.LumbarLordosis}.${VertebralBody.L1}.${EndPlate.Superior}.${VertebralBody.S1}.${EndPlate.Superior}`;
  return getMeasurementValue(name, values);
};

const getCervicalLordosis = (values: IMeasurementPointValues): number | null => {
  const name: string = `${MeasurementType.CervicalLordosis}.${VertebralBody.C2}.${EndPlate.Inferior}.${VertebralBody.C7}.${EndPlate.Inferior}`;
  return getMeasurementValue(name, values);
};

const getC2T1CobbAngle = (values: IMeasurementPointValues): number | null => {
  const name: string = `${MeasurementType.C2T1CobbAngle}.${VertebralBody.C2}.${EndPlate.Superior}.${VertebralBody.T1}.${EndPlate.Inferior}`;
  return getMeasurementValue(name, values);
};

const getC2C7CoronalCobbAngle = (values: IMeasurementPointValues): number | null => {
  const name: string = `${MeasurementType.C2C7CoronalCobbAngle}.${VertebralBody.C2}.${EndPlate.Superior}.${VertebralBody.C7}.${EndPlate.Inferior}`;
  return getMeasurementValue(name, values);
};

const getCervicalSVA = (values: IMeasurementPointValues): number | null => {
  const name: string = `${MeasurementType.CervicalSagittalVerticalAxis}.${VertebralBody.C2}.${EndPlate.Superior}.${VertebralBody.C7}.${EndPlate.Superior}`;
  return getMeasurementValue(name, values);
};

const getCervicalCoronalBalance = (values: IMeasurementPointValues): number | null => {
  const name: string = `${MeasurementType.CervicalCoronalBalance}.${VertebralBody.C2}.${EndPlate.Superior}.${VertebralBody.T1}.${EndPlate.Superior}`;
  return getMeasurementValue(name, values);
};

const getT1Slope = (values: IMeasurementPointValues): number | null => {
  const name: string = `${MeasurementType.T1Slope}.${VertebralBody.T1}.${EndPlate.Superior}.${VertebralBody.T1}.${EndPlate.Superior}`;
  return getMeasurementValue(name, values);
};

const getSLS = (values: IMeasurementPointValues): number | null => {
  const name: string = `${MeasurementType.SLS}.${VertebralBody.C1}.${EndPlate.Inferior}.${VertebralBody.C1}.${EndPlate.Inferior}`;
  return getMeasurementValue(name, values);
};

const getLumbarCoronalCobb = (values: IMeasurementPointValues): number | null => {
  const name: string = `${MeasurementType.LumbarCoronalCobb}.${VertebralBody.L1}.${EndPlate.Superior}.${VertebralBody.S1}.${EndPlate.Superior}`;
  return getMeasurementValue(name, values);
};

const getL1L4Lordosis = (values: IMeasurementPointValues): number | null => {
  const name: string = `${MeasurementType.L1L4Lordosis}.${VertebralBody.L1}.${EndPlate.Superior}.${VertebralBody.L4}.${EndPlate.Superior}`;
  return getMeasurementValue(name, values);
};

const getLumbarAngle = (
  inferior: VertebralBody | string,
  values: IMeasurementPointValues,
): number | null => {
  const name: string = `${MeasurementType.LumbarAngle}.${inferior}.${EndPlate.Superior}.${VertebralBody.S1}.${EndPlate.Superior}`;
  return getMeasurementValue(name, values);
};

const getLordoticAngle = (
  inferior: VertebralBody | string,
  superior: VertebralBody | string,
  values: IMeasurementPointValues,
): number | null => {
  const name: string = `${MeasurementType.LordoticAngle}.${inferior}.${EndPlate.Inferior}.${superior}.${EndPlate.Superior}`;
  return getMeasurementValue(name, values);
};

const getCoronalAngle = (
  inferior: VertebralBody | string,
  superior: VertebralBody | string,
  values: IMeasurementPointValues,
): number | null => {
  const name: string = `${MeasurementType.CoronalAngle}.${inferior}.${EndPlate.Inferior}.${superior}.${EndPlate.Superior}`;
  return getMeasurementValue(name, values);
};

const getMeasurementValue = (
  name: string,
  values: IMeasurementPointValues | null,
): number | null => {
  const value: number | null = values ? values[name] : null;
  if (!_.isNil(value)) {
    return value;
  }
  return null;
};

export const getPointName = (
  m: { body: VertebralBody; endPlate: EndPlate },
  position: Position,
) => {
  return `${m.body}.${position}.${m.endPlate}.POINT`;
};

function getLine(pointName1: string, pointName2: string, measurementPoints: IMeasurementPoint) {
  const point1 = measurementPoints[pointName1];
  const point2 = measurementPoints[pointName2];

  if (!point1 || !point2) {
    return null;
  }

  return { point1, point2 };
}

const setMeasurementTypeValues = (
  position1: Position,
  position2: Position,
  measurement: MeasurementDefinition,
  measurementPoints: IMeasurementPoint,
  measurementPointValues: IMeasurementPointValues,
) => {
  const line1 = getLine(
    getPointName(measurement.start, position1),
    getPointName(measurement.start, position2),
    measurementPoints,
  );

  const line2 = getLine(
    getPointName(measurement.end, position1),
    getPointName(measurement.end, position2),
    measurementPoints,
  );

  if (line1 && line2) {
    measurementPointValues[getMeasurementName(measurement)] = calculateAngleBetweenPointsAlongPlane(
      line1,
      line2,
      Axis.Y,
    );
  }
};

const getMeasurementPointsFromSource = (caseMeasurements: IMeasure[]) => {
  const values: any = {};
  caseMeasurements.forEach((measurement: IMeasure) => {
    values[`${measurement.body}.${measurement.position}.${measurement.endPlate}.POINT`] =
      measurement.point;
  });
  return values;
};

export const getMeasurementPointData = (
  measurementPoints: IMeasurementPoint,
  measurementsVersion: MeasurementsVersionType,
  spineType: CaseSpineType,
  spineProfile: CaseSpineProfile,
): { values: IMeasurementPointValues; vectorData?: IMeasurementVectorData } => {
  if (
    measurementsVersion === MeasurementsVersionType.Version1 &&
    spineType === CaseSpineType.Cervical
  ) {
    throw new Error('Cervical does not support measurements version 1');
  }

  return measurementsVersion === MeasurementsVersionType.Version1
    ? getMeasurementPointValuesV1(measurementPoints, spineProfile)
    : getMeasurementPointValuesV2(measurementPoints, spineType, spineProfile);
};

export const getMeasurementPointValues = (
  measurementPoints: IMeasurementPoint,
  measurementsVersion: MeasurementsVersionType,
  spineType: CaseSpineType,
  spineProfile: CaseSpineProfile,
): IMeasurementPointValues => {
  return getMeasurementPointData(measurementPoints, measurementsVersion, spineType, spineProfile)
    .values;
};

function getMeasurementPointValuesV1(
  measurementPoints: IMeasurementPoint,
  caseSpineProfile?: CaseSpineProfile,
): { values: IMeasurementPointValues; vectorData?: IMeasurementVectorData } {
  const measurementPointValues: IMeasurementPointValues = {};

  const defaultMeasurements = getDefaultMeasurements(CaseSpineType.Lumbar, caseSpineProfile);

  for (let measurement of defaultMeasurements) {
    switch (measurement.type) {
      case MeasurementType.LumbarAngle:
      case MeasurementType.SegmentalLordoticAngle:
      case MeasurementType.SegmentalCoronalAngle:
      case MeasurementType.LordoticAngle:
      case MeasurementType.CoronalAngle:
      case MeasurementType.LumbarCoronalCobb:
      case MeasurementType.LumbarLordosis: {
        let position1 = Position.Anterior;
        let position2 = Position.Posterior;

        if (
          measurement.type === MeasurementType.SegmentalCoronalAngle ||
          measurement.type === MeasurementType.CoronalAngle ||
          measurement.type === MeasurementType.LumbarCoronalCobb
        ) {
          position1 = Position.PatientRight;
          position2 = Position.PatientLeft;
        }

        setMeasurementTypeValues(
          position1,
          position2,
          measurement,
          measurementPoints,
          measurementPointValues,
        );
        break;
      }
      case MeasurementType.PosteriorHeight:
      case MeasurementType.AnteriorHeight: {
        const position =
          measurement.type === MeasurementType.PosteriorHeight
            ? Position.Posterior
            : Position.Anterior;

        const point1 = measurementPoints[getPointName(measurement.start, position)];
        const point2 = measurementPoints[getPointName(measurement.end, position)];

        if (point1 && point2) {
          let measurementName = getMeasurementName(measurement);
          measurementPointValues[measurementName] = calculateDistanceBetweenPointsAlongAxis(
            point1,
            point2,
            Axis.Y,
          );
        }
        break;
      }
    }
  }
  return { values: measurementPointValues };
}

function getMeasurementPointValuesV2(
  measurementPoints: IMeasurementPoint,
  spineType: CaseSpineType,
  spineProfile: CaseSpineProfile,
): { values: IMeasurementPointValues; vectorData?: IMeasurementVectorData } {
  const measurementPointValues: IMeasurementPointValues = {};
  const measurementVisuals: IMeasurementVectorData = {};

  const defaultMeasurements: MeasurementDefinition[] = getDefaultMeasurements(
    spineType,
    spineProfile,
  );

  for (const measurement of defaultMeasurements) {
    switch (measurement.type) {
      case MeasurementType.SegmentalCoronalAngle:
      case MeasurementType.CoronalAngle:
      case MeasurementType.C2C7CoronalCobbAngle:
      case MeasurementType.LumbarCoronalCobb: {
        const coronalAngleRes = evaluateCoronalAngleData(measurement, measurementPoints);

        const totalAngle = coronalAngleRes.value;

        switch (measurement.type) {
          case MeasurementType.C2C7CoronalCobbAngle:
            _.set(
              measurementVisuals,
              ['cervical', 'c2C7CoronalCobbAngle'],
              coronalAngleRes.vectorData,
            );
            break;
          case MeasurementType.LumbarCoronalCobb:
            _.set(measurementVisuals, ['lumbar', 'lumbarCoronalAngle'], coronalAngleRes.vectorData);
            break;
          default:
            break;
        }

        measurementPointValues[getMeasurementName(measurement)] = totalAngle;

        break;
      }

      case MeasurementType.L1L4Lordosis:
      case MeasurementType.LumbarAngle:
      case MeasurementType.SegmentalLordoticAngle:
      case MeasurementType.LordoticAngle:
      case MeasurementType.C2T1CobbAngle:
      case MeasurementType.CervicalLordosis:
      case MeasurementType.LumbarLordosis: {
        const lordoticAngleRes = evaluateLordoticAngleData(measurement, measurementPoints);

        const totalAngle = lordoticAngleRes.value;

        switch (measurement.type) {
          case MeasurementType.CervicalLordosis:
            _.set(
              measurementVisuals,
              ['cervical', 'cervicalLordosis'],
              lordoticAngleRes.vectorData,
            );
            break;
          case MeasurementType.LumbarLordosis:
            _.set(measurementVisuals, ['lumbar', 'lumbarLordosis'], lordoticAngleRes.vectorData);
            break;
          case MeasurementType.LumbarAngle:
            if (measurement.start.body === VertebralBody.L4)
              _.set(measurementVisuals, ['lumbar', 'l4AngleToS1'], lordoticAngleRes.vectorData);
            break;
          case MeasurementType.L1L4Lordosis:
            _.set(measurementVisuals, ['lumbar', 'l1L4Lordosis'], lordoticAngleRes.vectorData);
            break;
          default:
            break;
        }

        measurementPointValues[getMeasurementName(measurement)] = totalAngle;
        break;
      }
      case MeasurementType.PosteriorHeight:
      case MeasurementType.AnteriorHeight: {
        const totalDistance = evaluateHeight(measurement, measurementPoints);

        measurementPointValues[getMeasurementName(measurement)] = totalDistance;
        break;
      }

      case MeasurementType.CervicalSagittalVerticalAxis: {
        const svaRes = evaluateSVA(measurement, measurementPoints);

        const totalDistance = svaRes.value;

        measurementPointValues[getMeasurementName(measurement)] = totalDistance;

        _.set(measurementVisuals, ['cervical', 'cSVA'], svaRes.vectorData);

        break;
      }
      case MeasurementType.CervicalCoronalBalance: {
        const ccbRes = evaluateCervicalCoronalBalance(measurement, measurementPoints);

        const totalDistance = ccbRes.value;

        _.set(measurementVisuals, ['cervical', 'cervicalCoronalBalance'], ccbRes.vectorData);

        measurementPointValues[getMeasurementName(measurement)] = totalDistance;

        break;
      }
      case MeasurementType.T1Slope:
      case MeasurementType.SLS: {
        const angleRes = evaluateLateralAngleFromGround(measurement, measurementPoints);

        const angle = angleRes.value;

        measurementPointValues[getMeasurementName(measurement)] = angle;

        if (measurement.type === MeasurementType.T1Slope) {
          _.set(measurementVisuals, ['cervical', 't1Slope'], angleRes.vectorData);
        } else if (measurement.type === MeasurementType.SLS) {
          _.set(measurementVisuals, ['cervical', 'sls'], angleRes.vectorData);
        }

        break;
      }
      case MeasurementType.APTranslation:
      case MeasurementType.MLTranslation:
        const totalDistance = evaluateTranslation(measurement, measurementPoints);

        measurementPointValues[getMeasurementName(measurement)] = totalDistance;
        break;
      case MeasurementType.AxialRotation:
        const axialRotationRes = evaluateAxialRotation(measurement, measurementPoints);

        const degrees = axialRotationRes.value;

        const spineTypeKey = spineType === CaseSpineType.Cervical ? 'cervical' : 'lumbar';

        _.set(
          measurementVisuals,
          [spineTypeKey, 'axialRotation', measurement.start.body],
          axialRotationRes.inferiorVectorData,
        );

        _.set(
          measurementVisuals,
          [spineTypeKey, 'axialRotation', measurement.end.body],
          axialRotationRes.superiorVectorData,
        );

        measurementPointValues[getMeasurementName(measurement)] = degrees;
        break;
    }
  }

  return { values: measurementPointValues, vectorData: measurementVisuals };
}

export const getLumbarMeasurements = (config: {
  preOpMeasurements: IMeasure[];
  planMeasurements: IMeasure[] | null;
  spineProfile: CaseSpineProfile;
  measurementsVersion: MeasurementsVersionType;
}): LumbarMeasurements => {
  const preopMeasurementData = getMeasurementPointData(
    getMeasurementPointsFromSource(config.preOpMeasurements),
    config.measurementsVersion,
    CaseSpineType.Lumbar,
    config.spineProfile,
  );

  const planMeasurementData = config.planMeasurements
    ? getMeasurementPointData(
        getMeasurementPointsFromSource(config.planMeasurements),
        config.measurementsVersion,
        CaseSpineType.Lumbar,
        config.spineProfile,
      )
    : null;

  const preopMeasurementPointValues = preopMeasurementData.values;

  const planMeasurementPointValues = planMeasurementData ? planMeasurementData.values : null;

  const preOpLumbarLordosis = getLumbarLordosis(preopMeasurementPointValues);

  const preOpLumbarCoronalCobb = getLumbarCoronalCobb(preopMeasurementPointValues);

  const preOpL1L4Lordosis = getL1L4Lordosis(preopMeasurementPointValues);

  let planLumbarLordosis = null;
  let planLumbarCoronalCobb = null;
  let planL1L4Lordosis = null;

  if (planMeasurementPointValues) {
    planLumbarLordosis = getLumbarLordosis(planMeasurementPointValues);
    planLumbarCoronalCobb = getLumbarCoronalCobb(planMeasurementPointValues);
    planL1L4Lordosis = getL1L4Lordosis(planMeasurementPointValues);
  }

  const finalResult: LumbarMeasurements = {
    lumbarLordosis: {
      preOp: preOpLumbarLordosis,
      plan: planLumbarLordosis,
      delta:
        !_.isNil(planLumbarLordosis) && !_.isNil(preOpLumbarLordosis)
          ? planLumbarLordosis - preOpLumbarLordosis
          : null,
    },
    lumbarCoronalAngle: {
      preOp: preOpLumbarCoronalCobb,
      plan: planLumbarCoronalCobb,
      delta:
        !_.isNil(planLumbarCoronalCobb) && !_.isNil(preOpLumbarCoronalCobb)
          ? planLumbarCoronalCobb - preOpLumbarCoronalCobb
          : null,
    },
    l1L4Lordosis: {
      preOp: preOpL1L4Lordosis,
      plan: planL1L4Lordosis,
      delta:
        !_.isNil(planL1L4Lordosis) && !_.isNil(preOpL1L4Lordosis)
          ? planL1L4Lordosis - preOpL1L4Lordosis
          : null,
    },
    levels: {},
    vectorData: {
      preOp: preopMeasurementData?.vectorData?.lumbar,
      plan: planMeasurementData?.vectorData?.lumbar,
    },
  };

  const lumbarLevels = caseUtils.getCaseSpineProfile(config.spineProfile).validLevels;

  lumbarLevels.forEach((levelType) => {
    const inferior = LEVEL_CONFIG_MAP[levelType].inferiorVertebrae;
    const superior = LEVEL_CONFIG_MAP[levelType].superiorVertebrae;

    const preOpLordoticAngle = getLordoticAngle(inferior, superior, preopMeasurementPointValues);
    const preOpCoronalAngle = getCoronalAngle(inferior, superior, preopMeasurementPointValues);
    const preOpAnteriorHeight = getAnteriorHeight(inferior, superior, preopMeasurementPointValues);
    const preOpPosteriorHeight = getPosteriorHeight(
      inferior,
      superior,
      preopMeasurementPointValues,
    );
    const preOpLumbarAngle = getLumbarAngle(inferior, preopMeasurementPointValues);

    let planLordoticAngle = null;
    let planCoronalAngle = null;
    let planAnteriorHeight = null;
    let planPosteriorHeight = null;
    let planLumbarAngle = null;

    if (planMeasurementPointValues) {
      planLordoticAngle = getLordoticAngle(inferior, superior, planMeasurementPointValues);
      planCoronalAngle = getCoronalAngle(inferior, superior, planMeasurementPointValues);
      planAnteriorHeight = getAnteriorHeight(inferior, superior, planMeasurementPointValues);
      planPosteriorHeight = getPosteriorHeight(inferior, superior, planMeasurementPointValues);
      planLumbarAngle = getLumbarAngle(inferior, planMeasurementPointValues);
    }

    finalResult.levels[levelType] = {
      angleToS1: {
        preOp: preOpLumbarAngle,
        plan: planLumbarAngle,
        delta:
          !_.isNil(planLumbarAngle) && !_.isNil(preOpLumbarAngle)
            ? planLumbarAngle - preOpLumbarAngle
            : null,
      },
      anteriorHeight: {
        preOp: preOpAnteriorHeight,
        plan: planAnteriorHeight,
        delta:
          !_.isNil(planAnteriorHeight) && !_.isNil(preOpAnteriorHeight)
            ? planAnteriorHeight - preOpAnteriorHeight
            : null,
      },
      posteriorHeight: {
        preOp: preOpPosteriorHeight,
        plan: planPosteriorHeight,
        delta:
          !_.isNil(planPosteriorHeight) && !_.isNil(preOpPosteriorHeight)
            ? planPosteriorHeight - preOpPosteriorHeight
            : null,
      },
      coronalAngle: {
        preOp: preOpCoronalAngle,
        plan: planCoronalAngle,
        delta:
          !_.isNil(planCoronalAngle) && !_.isNil(preOpCoronalAngle)
            ? planCoronalAngle - preOpCoronalAngle
            : null,
      },
      lordoticAngle: {
        preOp: preOpLordoticAngle,
        plan: planLordoticAngle,
        delta:
          !_.isNil(planLordoticAngle) && !_.isNil(preOpLordoticAngle)
            ? planLordoticAngle - preOpLordoticAngle
            : null,
      },
    };
  });

  return finalResult;
};

export const getCervicalMeasurements = (config: {
  preOpMeasurements: IMeasure[];
  planMeasurements: IMeasure[] | null;
  spineProfile: CaseSpineProfile;
}): CervicalMeasurements => {
  const preopMeasurementData = getMeasurementPointData(
    getMeasurementPointsFromSource(config.preOpMeasurements),
    MeasurementsVersionType.Version2,
    CaseSpineType.Cervical,
    config.spineProfile,
  );

  const planMeasurementData = config.planMeasurements
    ? getMeasurementPointData(
        getMeasurementPointsFromSource(config.planMeasurements),
        MeasurementsVersionType.Version2,
        CaseSpineType.Cervical,
        config.spineProfile,
      )
    : null;

  const preopMeasurementPointValues = preopMeasurementData.values;

  const planMeasurementPointValues = planMeasurementData ? planMeasurementData.values : null;

  const preOpCervicalLordosis = getCervicalLordosis(preopMeasurementPointValues);
  const preOpC2C7CoronalCobb = getC2C7CoronalCobbAngle(preopMeasurementPointValues);
  const preOpC2T1Cobb = getC2T1CobbAngle(preopMeasurementPointValues);
  const preOpCervicalSVA = getCervicalSVA(preopMeasurementPointValues);
  const preOpT1Slope = getT1Slope(preopMeasurementPointValues);
  const preOpCervicalCoronalBalance = getCervicalCoronalBalance(preopMeasurementPointValues);
  const preOpSls = getSLS(preopMeasurementPointValues);

  let planCervicalLordosis = null;
  let planC2C7CoronalCobb = null;
  let planC2T1Cobb = null;
  let planCervicalSVA = null;
  let planT1Slope = null;
  let planCervicalCoronalBalance = null;
  let planSls = null;

  if (planMeasurementPointValues) {
    planCervicalLordosis = getCervicalLordosis(planMeasurementPointValues);
    planC2C7CoronalCobb = getC2C7CoronalCobbAngle(planMeasurementPointValues);
    planC2T1Cobb = getC2T1CobbAngle(planMeasurementPointValues);
    planCervicalSVA = getCervicalSVA(planMeasurementPointValues);
    planT1Slope = getT1Slope(planMeasurementPointValues);
    planCervicalCoronalBalance = getCervicalCoronalBalance(planMeasurementPointValues);
    planSls = getSLS(planMeasurementPointValues);
  }

  const finalResult: CervicalMeasurements = {
    cervicalLordosis: {
      preOp: preOpCervicalLordosis,
      plan: planCervicalLordosis,
      delta:
        !_.isNil(planCervicalLordosis) && !_.isNil(preOpCervicalLordosis)
          ? planCervicalLordosis - preOpCervicalLordosis
          : null,
    },
    c2C7CoronalCobbAngle: {
      preOp: preOpC2C7CoronalCobb,
      plan: planC2C7CoronalCobb,
      delta:
        !_.isNil(planC2C7CoronalCobb) && !_.isNil(preOpC2C7CoronalCobb)
          ? planC2C7CoronalCobb - preOpC2C7CoronalCobb
          : null,
    },
    c2T1CobbAngle: {
      preOp: preOpC2T1Cobb,
      plan: planC2T1Cobb,
      delta:
        !_.isNil(planC2T1Cobb) && !_.isNil(preOpC2T1Cobb) ? planC2T1Cobb - preOpC2T1Cobb : null,
    },
    cervicalSagittalVerticalAxis: {
      preOp: preOpCervicalSVA,
      plan: planCervicalSVA,
      delta:
        !_.isNil(planCervicalSVA) && !_.isNil(preOpCervicalSVA)
          ? planCervicalSVA - preOpCervicalSVA
          : null,
    },
    t1Slope: {
      preOp: preOpT1Slope,
      plan: planT1Slope,
      delta: !_.isNil(planT1Slope) && !_.isNil(preOpT1Slope) ? planT1Slope - preOpT1Slope : null,
    },
    cervicalCoronalBalance: {
      preOp: preOpCervicalCoronalBalance,
      plan: planCervicalCoronalBalance,
      delta:
        !_.isNil(planCervicalCoronalBalance) && !_.isNil(preOpCervicalCoronalBalance)
          ? planCervicalCoronalBalance - preOpCervicalCoronalBalance
          : null,
    },
    sls: {
      preOp: preOpSls,
      plan: planSls,
      delta: !_.isNil(planSls) && !_.isNil(preOpSls) ? planSls - preOpSls : null,
    },
    levels: {},
    vectorData: {
      preOp: preopMeasurementData?.vectorData?.cervical,
      plan: planMeasurementData?.vectorData?.cervical,
    },
  };

  const cervicalLevels = caseUtils.getCaseSpineProfile(config.spineProfile).validLevels;

  cervicalLevels.forEach((levelType) => {
    const inferior = LEVEL_CONFIG_MAP[levelType].inferiorVertebrae;
    const superior = LEVEL_CONFIG_MAP[levelType].superiorVertebrae;

    const preOpLordoticAngle = getLordoticAngle(inferior, superior, preopMeasurementPointValues);
    const preOpCoronalAngle = getCoronalAngle(inferior, superior, preopMeasurementPointValues);
    const preOpAnteriorHeight = getAnteriorHeight(inferior, superior, preopMeasurementPointValues);
    const preOpPosteriorHeight = getPosteriorHeight(
      inferior,
      superior,
      preopMeasurementPointValues,
    );
    const preOpAPTranslation = getAPTranslation(inferior, superior, preopMeasurementPointValues);
    const preOpMLTranslation = getMLTranslation(inferior, superior, preopMeasurementPointValues);
    const preOpAxialRotation = getAxialRotation(inferior, superior, preopMeasurementPointValues);

    let planLordoticAngle = null;
    let planCoronalAngle = null;
    let planAnteriorHeight = null;
    let planPosteriorHeight = null;
    let planAPTranslation = null;
    let planMLTranslation = null;
    let planAxialRotation = null;

    if (planMeasurementPointValues) {
      planLordoticAngle = getLordoticAngle(inferior, superior, planMeasurementPointValues);
      planCoronalAngle = getCoronalAngle(inferior, superior, planMeasurementPointValues);
      planAnteriorHeight = getAnteriorHeight(inferior, superior, planMeasurementPointValues);
      planPosteriorHeight = getPosteriorHeight(inferior, superior, planMeasurementPointValues);
      planAPTranslation = getAPTranslation(inferior, superior, planMeasurementPointValues);
      planMLTranslation = getMLTranslation(inferior, superior, planMeasurementPointValues);
      planAxialRotation = getAxialRotation(inferior, superior, planMeasurementPointValues);
    }

    finalResult.levels[levelType] = {
      anteriorHeight: {
        preOp: preOpAnteriorHeight,
        plan: planAnteriorHeight,
        delta:
          !_.isNil(planAnteriorHeight) && !_.isNil(preOpAnteriorHeight)
            ? planAnteriorHeight - preOpAnteriorHeight
            : null,
      },
      posteriorHeight: {
        preOp: preOpPosteriorHeight,
        plan: planPosteriorHeight,
        delta:
          !_.isNil(planPosteriorHeight) && !_.isNil(preOpPosteriorHeight)
            ? planPosteriorHeight - preOpPosteriorHeight
            : null,
      },
      coronalAngle: {
        preOp: preOpCoronalAngle,
        plan: planCoronalAngle,
        delta:
          !_.isNil(planCoronalAngle) && !_.isNil(preOpCoronalAngle)
            ? planCoronalAngle - preOpCoronalAngle
            : null,
      },
      lordoticAngle: {
        preOp: preOpLordoticAngle,
        plan: planLordoticAngle,
        delta:
          !_.isNil(planLordoticAngle) && !_.isNil(preOpLordoticAngle)
            ? planLordoticAngle - preOpLordoticAngle
            : null,
      },
      apTranslation: {
        preOp: preOpAPTranslation,
        plan: planAPTranslation,
        delta:
          !_.isNil(planAPTranslation) && !_.isNil(preOpAPTranslation)
            ? planAPTranslation - preOpAPTranslation
            : null,
      },
      mlTranslation: {
        preOp: preOpMLTranslation,
        plan: planMLTranslation,
        delta:
          !_.isNil(planMLTranslation) && !_.isNil(preOpMLTranslation)
            ? planMLTranslation - preOpMLTranslation
            : null,
      },
      axialRotation: {
        preOp: preOpAxialRotation,
        plan: planAxialRotation,
        delta:
          !_.isNil(planAxialRotation) && !_.isNil(preOpAxialRotation)
            ? planAxialRotation - preOpAxialRotation
            : null,
      },
    };
  });

  return finalResult;
};

export const parseInterMeasurementPoints = (
  measurement: MeasurementDefinition,
  measurementPoints: IMeasurementPoint,
): IMeasurementPoint => {
  const startVertebralBody: VertebralBody = measurement.start.body;
  const startEndPlate: EndPlate = measurement.start.endPlate;
  const endVertebralBody: VertebralBody = measurement.end.body;
  const endEndPlate: EndPlate = measurement.end.endPlate;

  const startMeasurementPoints: IMeasurementPoint = {};
  const endMeasurementPoints: IMeasurementPoint = {};

  for (let key in measurementPoints) {
    if (key.includes(startVertebralBody) && key.includes(startEndPlate)) {
      startMeasurementPoints[key] = measurementPoints[key];
    }
  }

  for (let key in measurementPoints) {
    if (key.includes(endVertebralBody) && key.includes(endEndPlate)) {
      endMeasurementPoints[key] = measurementPoints[key];
    }
  }

  return Object.assign({}, startMeasurementPoints, endMeasurementPoints);
};
