import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Link, Stack, Typography } from '@mui/material';
import { AssetType, IAsset, ICloudDesignSettings, PartType } from '@workflow-nx/common';
import { ProgressButton, TextFieldRHF } from '@workflow-nx/ui';
import React, { useEffect, useState } from 'react';
import { Control, FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import useCreateAndUploadAsset from '../../../hooks/useCreateAndUploadAsset';
import { CloudDesignSettingsFormData } from './CloudDesignForm';
import { FieldRow } from './FieldRow';
import { FileRow } from './FileRow';

export type CloudDesignPartType =
  | PartType.ALIF
  | PartType.ALIF_X_TWO_UP
  | PartType.LLIF_LEFT
  | PartType.LLIF_RIGHT
  | PartType.TLIFC_OFFSET_LEFT
  | PartType.TLIFC_OFFSET_RIGHT
  | PartType.TLIFO_LEFT
  | PartType.TLIFO_RIGHT;

export function CloudDesignPartView(props: {
  ntopFiles: IAsset[];
  partType: CloudDesignPartType;
  cloudDesignSettings?: ICloudDesignSettings;
  onClose: (shouldUpdate: boolean) => void;
}) {
  const { createAndUploadAsset } = useCreateAndUploadAsset();
  const [upsertSettings, { loading }] = useMutation(gql`
    mutation UpsertSettings($cloudDesign: JSON) {
      upsertSettings(input: { cloudDesign: $cloudDesign }) {
        settings {
          cloudDesign
        }
      }
    }
  `);
  const [editing, setEditing] = useState(false);
  const [ntopPartFiles, setNtopPartFiles] = useState<IAsset[]>([]);

  function checkFileExists(value: unknown, assetType: AssetType) {
    return !!value || !!getAsset(assetType);
  }

  const schema = Yup.object().shape({
    url: Yup.string().required(),
    release: Yup.string().required(),
    ntopAutoOp1File: Yup.mixed<File>()
      .test('hasNTopAutoOp1File', 'File is required', (value) =>
        checkFileExists(value, `NTOP_AUTO_OP1_${props.partType}_FILE` as AssetType),
      )
      .nullable(),
    ntopAutoOp2File: Yup.mixed()
      .test('hasNTopAutoOp2File', 'File is required', (value) =>
        checkFileExists(value, `NTOP_AUTO_OP2_${props.partType}_FILE` as AssetType),
      )
      .nullable(),
    ntopManualOp1File: Yup.mixed()
      .test('hasNTopManualOp1File', 'File is required', (value) =>
        checkFileExists(value, `NTOP_MANUAL_OP1_${props.partType}_FILE` as AssetType),
      )
      .nullable(),
    ntopManualOp2File: Yup.mixed()
      .test('hasNTopManualOp2File', 'File is required', (value) =>
        checkFileExists(value, `NTOP_MANUAL_OP2_${props.partType}_FILE` as AssetType),
      )
      .nullable(),
  });

  const handleSubmitForm: SubmitHandler<CloudDesignSettingsFormData> = async (data) => {
    try {
      const updatedCloudDesignSettings = {
        ...props.cloudDesignSettings,
      } as ICloudDesignSettings;
      updatedCloudDesignSettings[props.partType] = { url: data.url, release: data.release };

      await upsertSettings({
        variables: { cloudDesign: updatedCloudDesignSettings },
      });

      if (data.ntopAutoOp1File) {
        await createAndUploadAsset(
          data.ntopAutoOp1File,
          `NTOP_AUTO_OP1_${props.partType}_FILE` as AssetType,
        );
      }

      if (data.ntopAutoOp2File) {
        await createAndUploadAsset(
          data.ntopAutoOp2File,
          `NTOP_AUTO_OP2_${props.partType}_FILE` as AssetType,
        );
      }

      if (data.ntopManualOp1File) {
        await createAndUploadAsset(
          data.ntopManualOp1File,
          `NTOP_MANUAL_OP1_${props.partType}_FILE` as AssetType,
        );
      }

      if (data.ntopManualOp2File) {
        await createAndUploadAsset(
          data.ntopManualOp2File,
          `NTOP_MANUAL_OP2_${props.partType}_FILE` as AssetType,
        );
      }

      setEditing(false);
      props.onClose(true);
    } catch (e) {}
  };

  function getAsset(assetType: AssetType) {
    return ntopPartFiles.find((nTopFile) => nTopFile.assetType === assetType);
  }

  function clearAsset(assetType: AssetType) {
    const updatedNtopFiles = ntopPartFiles.filter((ntopFile) => ntopFile.assetType !== assetType);
    setNtopPartFiles(updatedNtopFiles);
  }

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<CloudDesignSettingsFormData>({
    defaultValues: {
      url: props.cloudDesignSettings?.[props.partType]?.url ?? '',
      release: props.cloudDesignSettings?.[props.partType]?.release ?? '',
    },
    resolver: yupResolver(schema) as any,
  });

  const formControl = control as unknown as Control<FieldValues>;

  useEffect(() => {
    const partFiles = props.ntopFiles.filter((ntopFile) =>
      ntopFile.assetType.includes(`_${props.partType}_`),
    );
    setNtopPartFiles(partFiles);
  }, []);

  function formatCloudDesignPartType(partType: CloudDesignPartType): React.ReactNode {
    switch (partType) {
      case PartType.ALIF:
        return 'ALIF';
      case PartType.ALIF_X_TWO_UP:
        return 'ALIF/X';
      case PartType.LLIF_LEFT:
        return 'LLIF (Left)';
      case PartType.LLIF_RIGHT:
        return 'LLIF (Right)';
      case PartType.TLIFC_OFFSET_LEFT:
        return 'TLIF/C (Left)';
      case PartType.TLIFC_OFFSET_RIGHT:
        return 'TLIF/C (Right)';
      case PartType.TLIFO_LEFT:
        return 'TLIF/O (Left)';
      case PartType.TLIFO_RIGHT:
        return 'TLIF/O (Right)';
    }
  }

  return (
    <Stack spacing={1} sx={{ p: 4 }}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography variant={'h4'}>
          <strong>{formatCloudDesignPartType(props.partType)}</strong>
        </Typography>

        <Button variant={editing ? 'text' : 'contained'} onClick={() => setEditing(!editing)}>
          {editing ? 'Cancel' : 'Edit'}
        </Button>
      </Stack>

      <Stack spacing={1}>
        <FieldRow id={'url'} label={'OnShape URL'} control={formControl}>
          {!editing ? (
            <>
              {props.cloudDesignSettings?.[props.partType]?.url ? (
                <Link
                  href={props.cloudDesignSettings?.[props.partType]?.url}
                  variant={'body1'}
                  sx={{ wordWrap: 'break-word' }}
                  target="_blank"
                  rel="noopener"
                >
                  {props.cloudDesignSettings?.[props.partType]?.url}
                </Link>
              ) : null}
              {!props.cloudDesignSettings?.[props.partType]?.url ? (
                <Typography variant={'body1'} color={'textSecondary'}>
                  Missing Value
                </Typography>
              ) : null}
            </>
          ) : null}
          {editing ? (
            <TextFieldRHF
              name={'url'}
              label={'URL'}
              control={formControl}
              disabled={isSubmitting || loading}
            />
          ) : null}
        </FieldRow>
        <FieldRow id={'release'} label={'Release'} control={formControl}>
          {!editing ? (
            <Typography>
              {props.cloudDesignSettings?.[props.partType]?.release ?? 'Missing value'}
            </Typography>
          ) : null}
          {editing ? (
            <TextFieldRHF
              name={'release'}
              label={'Release'}
              control={formControl}
              disabled={isSubmitting || loading}
            />
          ) : null}
        </FieldRow>
        <FileRow
          id={'ntopAutoOp1File'}
          label="Cut"
          specification={4000}
          editing={editing}
          asset={getAsset(`NTOP_AUTO_OP1_${props.partType}_FILE` as AssetType)}
          onClick={() => clearAsset(`NTOP_AUTO_OP1_${props.partType}_FILE` as AssetType)}
          control={control}
        />

        <FileRow
          id={'ntopAutoOp2File'}
          label="Mesh And Lattice"
          specification={4000}
          editing={editing}
          asset={getAsset(`NTOP_AUTO_OP2_${props.partType}_FILE` as AssetType)}
          onClick={() => clearAsset(`NTOP_AUTO_OP2_${props.partType}_FILE` as AssetType)}
          control={control}
        />

        <FileRow
          id={'ntopManualOp1File'}
          label="Cut"
          specification={4001}
          editing={editing}
          asset={getAsset(`NTOP_MANUAL_OP1_${props.partType}_FILE` as AssetType)}
          onClick={() => clearAsset(`NTOP_MANUAL_OP1_${props.partType}_FILE` as AssetType)}
          control={control}
        />
        <FileRow
          id={'ntopManualOp2File'}
          label="Mesh And Lattice"
          specification={4001}
          editing={editing}
          asset={getAsset(`NTOP_MANUAL_OP2_${props.partType}_FILE` as AssetType)}
          onClick={() => clearAsset(`NTOP_MANUAL_OP2_${props.partType}_FILE` as AssetType)}
          control={control}
        />

        {editing ? (
          <Stack direction={'row'} justifyContent={'flex-end'}>
            <ProgressButton
              variant={'contained'}
              disabled={isSubmitting || loading}
              onClick={(evt) => handleSubmit(handleSubmitForm)(evt)}
              label={'Save Settings'}
              loading={isSubmitting || loading}
            />
          </Stack>
        ) : null}
      </Stack>
    </Stack>
  );
}
