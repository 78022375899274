import { FormikValues, useFormikContext } from 'formik';
import {
  AssetType,
  CaseStageType,
  CaseType,
  IAsset,
  ICase,
  IDicomMetadata,
  UserRoleType,
} from '@workflow-nx/common';
import { Box, Divider, Grid } from '@mui/material';
import { DicomField } from '../../../../components/DicomField';
import { SelectField, TextField } from '@workflow-nx/ui';
import { DatePickerField } from '../../../../components/DatePickerField';
import React, { useState } from 'react';
import { MetadataReviewDialog } from './MetadataReviewDialog';
import useAuth from '../../../../hooks/useAuth';

export function PatientCaseDetailsForm(props: {
  activeCase?: ICase;
  assets?: IAsset[];
  loading: boolean;
  disabled: boolean;
}) {
  const auth = useAuth();
  const { values, setFieldValue } = useFormikContext<FormikValues>();
  const [pendingMetadata, setPendingMetadata] = useState<IDicomMetadata>();
  const [pendingMetadataAssetType, setPendingMetadataAssetType] = useState<
    AssetType.DicomXray | AssetType.DicomCt
  >();
  const [showPendingMetadataDialog, setShowPendingMetadataDialog] = useState(false);

  function handleDicomLoaded(
    dicomMetadata: IDicomMetadata,
    assetType: AssetType.DicomCt | AssetType.DicomXray,
  ) {
    setPendingMetadata(dicomMetadata);
    setPendingMetadataAssetType(assetType);
    setShowPendingMetadataDialog(true);
  }

  const hasDicomMetadata = values.dicomCtMetadata || values.dicomXrayMetadata;
  const dicomCtAsset = (props.assets || []).find((asset) => asset.assetType === AssetType.DicomCt);
  const dicomXrayAsset = (props.assets || []).find(
    (asset) => asset.assetType === AssetType.DicomXray,
  );
  const dicomMriAsset = (props.assets || []).find(
    (asset) => asset.assetType === AssetType.DicomMri,
  );

  const disabled = props.disabled || hasDicomMetadata || dicomXrayAsset || dicomCtAsset;

  const confirmedMetadata = () => {
    if (props.activeCase?.patient) return null;
    else {
      switch (pendingMetadataAssetType) {
        case AssetType.DicomCt: {
          return values.dicomXrayMetadata ?? null;
        }
        case AssetType.DicomXray: {
          return values.dicomCtMetadata ?? null;
        }
        default: {
          return null;
        }
      }
    }
  };

  const hasValidRolesForApprovedCases = () => {
    if (props.activeCase && props.activeCase.caseType === CaseType.Live) {
      const validEditingRole = auth.hasRole?.([
        UserRoleType.Operations,
        UserRoleType.SiteAdministrator,
        UserRoleType.SupplyChain,
      ]);
      const isCaseApproved = [
        CaseStageType.Complete,
        CaseStageType.Production,
        CaseStageType.Ready,
      ].includes(props?.activeCase?.stage);

      if (isCaseApproved) {
        return validEditingRole ? true : false;
      }
    }
    return true;
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Grid container spacing={2} direction={'column'}>
            <Grid item xs={12}>
              <DicomField
                asset={dicomCtAsset}
                onLoaded={(dicomMetadata) => {
                  handleDicomLoaded(dicomMetadata, AssetType.DicomCt);
                }}
                name={'dicomCt'}
                label={'DICOM CT'}
                loading={props.loading}
                disabled={!hasValidRolesForApprovedCases()}
              />
            </Grid>
            <Grid item xs={12}>
              <DicomField
                asset={dicomXrayAsset}
                onLoaded={(dicomMetadata) => {
                  handleDicomLoaded(dicomMetadata, AssetType.DicomXray);
                }}
                name={'dicomXray'}
                label={'DICOM X-Ray'}
                loading={props.loading}
                disabled={!hasValidRolesForApprovedCases()}
              />
            </Grid>
            <Grid item xs={12}>
              <DicomField
                asset={dicomMriAsset}
                onLoaded={() => undefined}
                name={'dicomMri'}
                label={'DICOM MRI'}
                loading={props.loading}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <Box display={'flex'}>
            <Box mr={2}>
              <Divider orientation={'vertical'} />
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  shrink={true}
                  name={'firstName'}
                  label={'First Name'}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  shrink={true}
                  name={'middleName'}
                  label={'Middle Name'}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  shrink={true}
                  name={'lastName'}
                  label={'Last Name'}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={6}>
                <DatePickerField
                  shrink={true}
                  disabled={disabled}
                  name="birthDate"
                  required={false}
                  label={'Birth Date'}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectField
                  name={'gender'}
                  label={'Gender'}
                  hideNone={true}
                  menuItems={[
                    { key: 'MALE', value: 'Male' },
                    { key: 'FEMALE', value: 'Female' },
                  ]}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField shrink={true} name={'mrn'} label={'MRN'} disabled={disabled} />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      {showPendingMetadataDialog ? (
        <MetadataReviewDialog
          activeCase={props.activeCase}
          assetType={pendingMetadataAssetType}
          metadata={pendingMetadata}
          open={showPendingMetadataDialog}
          onClose={() => {
            switch (pendingMetadataAssetType) {
              case AssetType.DicomCt:
                setFieldValue('dicomCt', null);
                setFieldValue('dicomCtMetadata', {});
                break;
              case AssetType.DicomXray:
                setFieldValue('dicomXray', null);
                setFieldValue('dicomXrayMetadata', {});
                break;
            }
            setPendingMetadata(undefined);
            setPendingMetadataAssetType(undefined);

            setShowPendingMetadataDialog(false);
          }}
          onConfirm={(dicomMetadata: IDicomMetadata) => {
            setShowPendingMetadataDialog(false);

            setFieldValue('firstName', dicomMetadata.firstName);
            setFieldValue('middleName', dicomMetadata.middleName);
            setFieldValue('lastName', dicomMetadata.lastName);
            setFieldValue('gender', dicomMetadata.gender);
            setFieldValue('birthDate', dicomMetadata.birthDate);
            setFieldValue('mrn', dicomMetadata.mrn);
            setFieldValue('studyDate', dicomMetadata.studyDate);
          }}
          confirmedMetadata={confirmedMetadata()}
        />
      ) : null}
    </>
  );
}
