import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client/core';
import { Stack, TextField, Typography } from '@mui/material';
import { UserStatusType } from '@workflow-nx/common';
import { Button } from '@workflow-nx/ui';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { VerificationCode } from '../../../components/VerificationCode';
import useAuth from '../../../hooks/useAuth';
import { getLoginProvider } from './loginHelpers';

export function TOTPLogin() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [hasError, setHasError] = useState(false);
  const [verificationCodeSent, setVerificationCodeSent] = useState(false);
  const [verificationCodeExpiresAt, setVerificationCodeExpiresAt] = useState<Date | null>(null);
  const [verificationCodeType, setVerificationCodeType] = useState<'EMAIL' | 'PHONE'>('EMAIL');
  const [value, setValue] = useState(localStorage.getItem('last_successful_login') ?? '');

  const [createTOTP, { loading }] = useMutation(gql`
    mutation CreateTOTP(
      $verificationType: TOTPVerificationType
      $value: String!
      $requestedAt: DateTime!
    ) {
      createTOTP(
        input: { verificationType: $verificationType, value: $value, requestedAt: $requestedAt }
      ) {
        expiresAt
      }
    }
  `);

  const handleVerificationCodeComplete = async (token: string) => {
    setHasError(false);

    try {
      const userStatus = await auth?.validateOneTime?.(token);

      if (userStatus === UserStatusType.Active) {
        localStorage.setItem('last_successful_login', value);
        navigate('/');
      } else {
        localStorage.removeItem('jwt_token');
        localStorage.removeItem('user');

        setHasError(true);
      }
    } catch (e) {
      console.error(e);
      setHasError(true);
    }
  };

  const handleLoginClick = async () => {
    try {
      setHasError(false);

      const lowercaseEmail = value.toLowerCase();

      if (
        lowercaseEmail.endsWith('@carlsmed.com') ||
        lowercaseEmail.endsWith('@contractor.carlsmed.com')
      ) {
        const jumpCloudLoginProvider = getLoginProvider();
        if (jumpCloudLoginProvider) {
          localStorage.setItem('last_successful_login', value);
          window.location.href = jumpCloudLoginProvider.authUrl;
        } else {
          setHasError(true);
        }
      } else {
        const response = await createTOTP({
          variables: {
            value: value,
            requestedAt: new Date(),
          },
        });

        setVerificationCodeExpiresAt(response.data.createTOTP.expiresAt);
        setVerificationCodeType(response.data.createTOTP.verificationCodeType);
        setVerificationCodeSent(true);
      }
    } catch (e) {
      console.error(e);
      setHasError(true);
      setVerificationCodeSent(false);
    }
  };

  return (
    <>
      {!verificationCodeSent ? (
        <Stack spacing={1} width={'100%'} justifyContent={'center'}>
          <TextField
            disabled={loading}
            placeholder={'Email address'}
            value={value}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleLoginClick();
              }
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setValue(event.target.value);
            }}
          />
          {hasError ? (
            <Typography color={'red'} sx={{ textAlign: 'center' }}>
              An error occurred sending the login email. Please contact support for help if this
              issue persists.
            </Typography>
          ) : null}
          <Button
            disabled={loading}
            label={'Login'}
            variant={'contained'}
            onClick={handleLoginClick}
          ></Button>
        </Stack>
      ) : (
        <Stack spacing={1}>
          <Typography sx={{ textAlign: 'center' }}>
            A validation code has been sent via{' '}
            {verificationCodeType === 'EMAIL' ? 'email' : 'text message'}. Please check your device
            and enter the token below to access aprevo® DPS.
          </Typography>
          <VerificationCode
            onComplete={handleVerificationCodeComplete}
            expiresAt={verificationCodeExpiresAt}
          />
          {hasError ? (
            <Typography color={'red'} sx={{ textAlign: 'center' }}>
              An error occurred validating the verification code. Please confirm the code and try
              again.
            </Typography>
          ) : null}
          <Button
            disabled={loading}
            label={'Resend Validation Code'}
            variant={'contained'}
            onClick={handleLoginClick}
          ></Button>
        </Stack>
      )}
    </>
  );
}
