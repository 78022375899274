import { AssetSuffix, EndPlate, Position, VertebralBody } from '@workflow-nx/common';
import {
  Axis,
  BoundingBox,
  Mesh,
  PickingInfo,
  Quaternion,
  Ray,
  Scene,
  Tags,
  Vector3,
} from 'babylonjs';
import { cloneDeep, isNil } from 'lodash';
import * as numeric from 'numeric';
import { AutoCorrectionMesh, CrossSection } from '../shared/enum';
import {
  AutoCorrectionConfigType,
  AutoCorrectionQuartileMaxType,
  AutoCorrectionVectorLimitsType,
  Coordinates2DType,
} from '../shared/types';
import {
  createRay,
  createSacrumPlane,
  createSphere,
  createVertebralBodyPlane,
  renderPosteriorPoint,
  setLandMarkParent,
} from './cad';
import {
  filterInterceptsDBScanDerivatives,
  filterInterceptsDBScanVectors,
  filterNullIntercepts,
  validateCrossSection,
} from './landmarkingPointValidation';

export const evaluatePointsAlongLine = (
  mesh: Mesh,
  lineStart: Vector3,
  lineEnd: Vector3,
  config: AutoCorrectionConfigType,
): Vector3[] => {
  lineStart.y += 20;
  lineEnd.y += 20;

  const pathDirection = lineEnd.subtract(lineStart);
  const totalDistance = pathDirection.length();
  pathDirection.normalize();

  let currentDistance = 0;

  const currentPoint = lineStart.clone();

  const intersections = [];

  while (currentDistance <= totalDistance) {
    let ray: Ray | null = new Ray(currentPoint, Vector3.Down(), 30);

    const { pickedPoint: meshIntersection }: PickingInfo = ray.intersectsMesh(mesh, false);
    if (meshIntersection) {
      intersections.push(meshIntersection);
    }

    currentPoint.addInPlace(pathDirection.scale(config.RAY_CAST_HIGH_FIDELITY));
    currentDistance += config.RAY_CAST_HIGH_FIDELITY;

    ray = null; // dereference ray and let the garbage collector handle it
  }

  return intersections;
};

export const evaluateMLLimits = (
  indexM: number,
  indexL: number,
  endplateCentroid: Vector3,
  highFidelityML: Vector3[],
  config: AutoCorrectionConfigType,
) => {
  const centerX: number = evaluateClosestVectorIndex(endplateCentroid, highFidelityML);
  const mRadius: number = centerX - indexM;
  const lRadius: number = indexL - centerX;

  const targetMLRadius: number = mRadius < lRadius ? mRadius : lRadius;

  const targetIndexM: number = centerX - targetMLRadius + 1 / config.RAY_CAST_HIGH_FIDELITY;
  const targetIndexL: number = centerX + targetMLRadius - 1 / config.RAY_CAST_HIGH_FIDELITY;

  let targetM: Vector3 = highFidelityML[targetIndexM];
  let targetL: Vector3 = highFidelityML[targetIndexL];

  if (targetMLRadius * 2 < config.VB_MIN_ML_CUT_OFF / config.RAY_CAST_HIGH_FIDELITY) {
    targetM = highFidelityML[indexM];
    targetL = highFidelityML[indexL];
  }

  return {
    targetM,
    targetL,
  };
};

export const evaluateLandMarkPoints = (
  closestCoordinates: Coordinates2DType,
  mesh: Mesh,
  intercepts: (Vector3 | null)[][],
  endplateCentroid: Vector3,
  endplate: EndPlate,
  config: AutoCorrectionConfigType,
  scene: Scene,
): Mesh[] => {
  let result: Mesh[] = [];

  const { x, y }: Coordinates2DType = closestCoordinates;

  const isSacrum: boolean = Tags.GetTags(mesh).includes(VertebralBody.S1);

  let highFidelityAP: Vector3[] = [];
  let highFidelityML: Vector3[] = [];

  if (isSacrum) {
    const apVectors: (Vector3 | null)[] = [];
    intercepts.forEach((row: (Vector3 | null)[]) => {
      row.forEach((col: Vector3 | null, j) => {
        if (j === x && col) {
          apVectors.push(col);
        }
      });
    });

    const mlVectors: (Vector3 | null)[] = intercepts[y];

    const apColVector: Vector3[] = filterNullIntercepts([apVectors]).flat();
    const mlRowVector: Vector3[] = filterNullIntercepts([mlVectors]).flat();

    const projectionStartAP: Vector3 = apColVector[0];
    const projectionEndAP: Vector3 = apColVector[apColVector.length - 1];

    const projectionStartML: Vector3 = mlRowVector[0];
    const projectionEndML: Vector3 = mlRowVector[mlRowVector.length - 1];

    highFidelityAP = evaluatePointsAlongLine(mesh, projectionStartAP, projectionEndAP, config);
    highFidelityML = evaluatePointsAlongLine(mesh, projectionStartML, projectionEndML, config);
  } else {
    highFidelityAP = evaluateAPCrossSection(endplateCentroid, mesh, config, scene);
    highFidelityML = evaluateMLCrossSection(endplateCentroid, mesh, config, scene);
  }

  const { q1Index: indexA, q4Index: indexP }: AutoCorrectionQuartileMaxType =
    evaluateLandMarkIndexes(highFidelityAP, config, true);

  const { q1Index: indexM, q4Index: indexL }: AutoCorrectionQuartileMaxType =
    evaluateLandMarkIndexes(highFidelityML, config, true);

  const { targetM, targetL } = evaluateMLLimits(
    indexM,
    indexL,
    endplateCentroid,
    highFidelityML,
    config,
  );

  const a: Vector3 | null = highFidelityAP[indexA];
  const p: Vector3 | null = highFidelityAP[indexP];
  const m: Vector3 | null = endplate === EndPlate.Inferior ? targetL : targetM;
  const l: Vector3 | null = endplate === EndPlate.Inferior ? targetM : targetL;

  const vertebralBody = Tags.GetTags(mesh).split(' ')[0] as VertebralBody;

  if (a && p && m && l) {
    const sphereA = createSphere(a, scene);
    const sphereP = createSphere(p, scene);
    const sphereM = createSphere(m, scene);
    const sphereL = createSphere(l, scene);

    Tags.RemoveTagsFrom(sphereA, AutoCorrectionMesh.Sphere);
    Tags.RemoveTagsFrom(sphereP, AutoCorrectionMesh.Sphere);
    Tags.RemoveTagsFrom(sphereM, AutoCorrectionMesh.Sphere);
    Tags.RemoveTagsFrom(sphereL, AutoCorrectionMesh.Sphere);

    Tags.AddTagsTo(
      sphereA,
      `${vertebralBody}.${Position.Anterior}.${endplate}${AssetSuffix.PointSuffix} ${AutoCorrectionMesh.Landmark}`,
    );
    Tags.AddTagsTo(
      sphereP,
      `${vertebralBody}.${Position.Posterior}.${endplate}${AssetSuffix.PointSuffix} ${AutoCorrectionMesh.Landmark}`,
    );
    Tags.AddTagsTo(
      sphereM,
      `${vertebralBody}.${Position.PatientRight}.${endplate}${AssetSuffix.PointSuffix} ${AutoCorrectionMesh.Landmark}`,
    );
    Tags.AddTagsTo(
      sphereL,
      `${vertebralBody}.${Position.PatientLeft}.${endplate}${AssetSuffix.PointSuffix} ${AutoCorrectionMesh.Landmark}`,
    );

    result = [sphereA, sphereP, sphereM, sphereL];
  } else {
    throw Error('Error Occurred while evaluating landmark points, one or more points missing');
  }

  const posteriorPoint = renderPosteriorPoint(
    scene,
    endplate,
    highFidelityAP[highFidelityAP.length - 1],
  );

  Tags.AddTagsTo(
    posteriorPoint,
    `${AutoCorrectionMesh.EdgePosterior} ${AutoCorrectionMesh.EdgePosterior}-${endplate}`,
  );

  setLandMarkParent(mesh, posteriorPoint);

  result.forEach((sphere) => {
    setLandMarkParent(mesh, sphere);
  });

  return result;
};

export const evaluateInverseHeightMap = (intercepts: (Vector3 | null)[][]): (number | null)[][] => {
  const filteredIntercepts: (number | null)[][] = [];

  intercepts.forEach((row: (Vector3 | null)[]) => {
    const refinedRow: number[] = [];

    row.forEach((intercept: Vector3 | null) => {
      if (!isNil(intercept)) {
        refinedRow.push(intercept.y);
      }
    });

    const meanIntercept: number =
      refinedRow.reduce((acc, curr) => acc + curr, 0) / refinedRow.length;

    const filteredInterceptsRow: (number | null)[] = [];

    row.forEach((intercept: Vector3 | null) => {
      if (!isNil(intercept)) {
        filteredInterceptsRow.push(-(intercept.y - meanIntercept));
      } else {
        filteredInterceptsRow.push(null);
      }
    });

    filteredIntercepts.push(filteredInterceptsRow);
  });

  return filteredIntercepts;
};

export const evaluateNormalizedDerivative = (derivatives: (number | null)[]): (number | null)[] => {
  const refinedRow: number[] = [];

  derivatives.forEach((intercept: number | null) => {
    if (intercept) {
      refinedRow.push(intercept);
    }
  });

  const quartileLength: number = Math.floor((refinedRow.length + 1) / 4);

  const centralSlice: number[] = refinedRow.slice(quartileLength, 3 * quartileLength + 1);

  const meanIntercept: number =
    centralSlice.reduce((acc, curr) => acc + curr, 0) / centralSlice.length;

  const normalizedDerivative: (number | null)[] = [];

  derivatives.forEach((intercept: number | null) => {
    if (intercept) {
      normalizedDerivative.push(-(intercept - meanIntercept));
    } else {
      normalizedDerivative.push(intercept);
    }
  });

  return normalizedDerivative;
};

export const evaluateDerivative = (
  interceptHeights: (number | null)[][],
  config: AutoCorrectionConfigType,
  isHighFidelity = false,
): (number | null)[][] => {
  const derivative: (number | null)[][] = [];

  interceptHeights.forEach((row: (number | null)[]) => {
    const derivativeRow: (number | null)[] = [];
    row.forEach((intercept: number | null, i) => {
      const next: number | null = row[i + 1];
      if (typeof intercept === 'number' && typeof next === 'number') {
        const slope: number =
          (next - intercept) /
          (isHighFidelity ? config.RAY_CAST_HIGH_FIDELITY : config.RAY_CAST_LOW_FIDELITY);
        derivativeRow.push(slope);
      } else {
        derivativeRow.push(null);
      }
    });
    derivative.push(derivativeRow);
  });
  return derivative;
};

export const evaluateStartAndEndIndices = (values: (number | null)[]) => {
  let startIndex = 0;
  let endIndex = 0;

  values.forEach((derivative: number | null, i: number) => {
    if (derivative) {
      endIndex = i;
      if (!startIndex) {
        startIndex = i;
      }
    }
  });

  return {
    startIndex,
    endIndex,
  };
};

export const evaluateLandMarkIndexes = (
  intercepts: (Vector3 | null)[],
  config: AutoCorrectionConfigType,
  isHighFidelity = false,
): AutoCorrectionQuartileMaxType => {
  const filteredIntercepts: (Vector3 | null)[] = cloneDeep(intercepts);

  const [inverseHeights]: (number | null)[][] = evaluateInverseHeightMap([filteredIntercepts]);
  const [firstDerivative]: (number | null)[][] = evaluateDerivative(
    [inverseHeights],
    config,
    isHighFidelity,
  );

  const normalizedDerivative: (number | null)[] = evaluateNormalizedDerivative(firstDerivative);

  normalizedDerivative.unshift(null);

  const targetPeaks: number[] = [];
  normalizedDerivative.forEach((derivative: number | null, i) => {
    const next: number | null = normalizedDerivative[i + 1];
    if (derivative && next && derivative > 0 && next < 0) {
      targetPeaks.push(i);
    }
  });

  const { startIndex, endIndex } = evaluateStartAndEndIndices(normalizedDerivative);

  const quartileLength: number = Math.floor((endIndex - startIndex) / 3.75);

  let q1Index = 0;
  let q4Index = 0;

  inverseHeights.forEach((height: number | null, i: number) => {
    if (height && i > startIndex && i < startIndex + quartileLength && targetPeaks.includes(i)) {
      q1Index = i;
    } else if (height && i < endIndex && i > endIndex - quartileLength && targetPeaks.includes(i)) {
      q4Index = i;
    }
  });

  q1Index = q1Index ? q1Index : startIndex + 2 / config.RAY_CAST_HIGH_FIDELITY;
  q4Index = q4Index ? q4Index : endIndex - 2 / config.RAY_CAST_HIGH_FIDELITY;

  return {
    q1Index,
    q4Index,
  };
};

export const evaluateAxialCorrectionAngle = (
  inferiorCentroid: Vector3,
  superiorCentroid: Vector3,
): number => {
  const diff: Vector3 = inferiorCentroid.subtract(superiorCentroid).normalize();
  const worldZ: Vector3 = new Vector3(0, 0, -1);

  // Get the dot product of the difference and the world z-axis
  const dot: number = Vector3.Dot(diff, worldZ);
  const angle: number = Math.acos(dot);
  const axis: Vector3 = Vector3.Cross(diff, worldZ);
  const quaternion: Quaternion = Quaternion.RotationAxis(axis, angle);
  const euler: Vector3 = quaternion.toEulerAngles();
  const correctionAngle: number = euler.y;

  if (isNaN(angle)) {
    throw new Error('Evaluated Axial Correction Angle is NaN');
  }

  return correctionAngle;
};

export const evaluateCentroidFromIntercepts = (points: Vector3[]): Vector3 => {
  const sum: Vector3 = points.reduce((acc, cur) => acc.add(cur), Vector3.Zero());
  const centroid: Vector3 = sum.scale(1 / points.length);

  return centroid;
};

export const evaluateCovarianceMatrix = (points: Vector3[], centroid: Vector3): number[][] => {
  const covarianceMatrix: number[][] = numeric.dot(
    numeric.transpose(
      points.map((point) => [point.x - centroid.x, point.y - centroid.y, point.z - centroid.z]),
    ),
    points.map((point) => [point.x - centroid.x, point.y - centroid.y, point.z - centroid.z]),
  ) as number[][];

  return covarianceMatrix;
};

export const evaluateBestFitPlaneLSM = (points: Vector3[]): Vector3 => {
  const centroid: Vector3 = evaluateCentroidFromIntercepts(points);
  const covarianceMatrix: number[][] = evaluateCovarianceMatrix(points, centroid);
  const tensor = numeric.eig(covarianceMatrix).E.x as number[][];

  let targetIndex = 2;
  let largest = 0;

  tensor[1].forEach((eig: number, i: number) => {
    if (Math.abs(eig) > largest) {
      largest = Math.abs(eig);
      targetIndex = i;
    }
  });

  const normal: Vector3 = new Vector3(
    tensor[0][targetIndex],
    tensor[1][targetIndex],
    tensor[2][targetIndex],
  ).normalize();

  return normal;
};

export const evaluateRawEndplatePoints = (
  mesh: Mesh,
  scene: Scene,
  config: AutoCorrectionConfigType,
): (Vector3 | null)[][] => {
  const endPlatePoints: (Vector3 | null)[][] = [];

  const [boundingBox]: Mesh[] = scene.getMeshesByTags(AutoCorrectionMesh.BoundingBox);
  const { vectorsWorld }: BoundingBox = boundingBox.getBoundingInfo().boundingBox;

  const { start, end } = evaluateEndplateLength(mesh, scene, config);

  const initialTarget: Vector3 = cloneDeep(vectorsWorld[3]);
  const currentTarget: Vector3 = cloneDeep(vectorsWorld[3]);

  const totalLength: number = config.BOUNDING_BOX_SIZE / config.RAY_CAST_LOW_FIDELITY;

  for (let i = 0; i < totalLength; i++) {
    const heightRow: (Vector3 | null)[] = [];

    if (currentTarget.z > start.z) {
      for (let j = 0; j < totalLength; j++) {
        const current: Vector3 = cloneDeep(currentTarget);

        let ray: Ray | null = createRay(Vector3.Down(), current, config);

        const { pickedPoint: meshIntersection }: PickingInfo = ray.intersectsMesh(mesh, false);

        if (meshIntersection) {
          heightRow.push(meshIntersection);
        } else {
          heightRow.push(null);
        }

        currentTarget.x += config.RAY_CAST_LOW_FIDELITY;

        ray = null; // dereference ray and let the garbage collector handle it
      }

      endPlatePoints.push(heightRow);
    }

    if (currentTarget.z > end.z) {
      break;
    }

    currentTarget.x = initialTarget.x;
    currentTarget.z += config.RAY_CAST_LOW_FIDELITY;
  }

  return endPlatePoints;
};

export const evaluateRawForamenPoints = (
  mesh: Mesh,
  scene: Scene,
  config: AutoCorrectionConfigType,
): Vector3[] => {
  const foramenPoints: Vector3[] = [];

  const [boundingBox]: Mesh[] = scene.getMeshesByTags(AutoCorrectionMesh.BoundingBox);
  const { vectorsWorld }: BoundingBox = boundingBox.getBoundingInfo().boundingBox;

  const { end }: AutoCorrectionVectorLimitsType = evaluateEndplateLength(mesh, scene, config);

  const initialTarget: Vector3 = cloneDeep(vectorsWorld[3]);
  const currentTarget: Vector3 = cloneDeep(vectorsWorld[3]);

  let plane: Mesh;
  if (Tags.GetTags(mesh).includes(VertebralBody.S1)) {
    plane = createSacrumPlane(scene, end);
  } else {
    plane = createVertebralBodyPlane(scene, mesh);
  }

  const totalLength: number = config.BOUNDING_BOX_SIZE / config.RAY_CAST_LOW_FIDELITY;

  for (let i = 0; i < totalLength; i++) {
    if (currentTarget.z > end.z) {
      for (let j = 0; j < totalLength; j++) {
        const current: Vector3 = cloneDeep(currentTarget);

        const ray: Ray = createRay(Vector3.Down(), current, config);

        const { pickedPoint: meshIntersection }: PickingInfo = ray.intersectsMesh(mesh, false);
        const { pickedPoint: planeIntersection }: PickingInfo = ray.intersectsMesh(plane, false);

        if (!meshIntersection) {
          if (planeIntersection) {
            foramenPoints.push(planeIntersection);
          }
        } else if (planeIntersection) {
          if (planeIntersection.y > meshIntersection.y) {
            foramenPoints.push(planeIntersection);
          }
        }

        currentTarget.x += config.RAY_CAST_LOW_FIDELITY;
      }
    }

    // TODO: Stop Loop after last row of plane intersection is detected
    currentTarget.x = initialTarget.x;
    currentTarget.z += config.RAY_CAST_LOW_FIDELITY;
  }

  plane.dispose();

  return foramenPoints;
};

export const evaluateRawSpinousProcessPoints = (
  mesh: Mesh,
  scene: Scene,
  config: AutoCorrectionConfigType,
): Vector3[] => {
  const spinousProcessPoints: Vector3[][] = [];

  const [boundingBox]: Mesh[] = scene.getMeshesByTags(AutoCorrectionMesh.BoundingBox);
  const { vectorsWorld }: BoundingBox = boundingBox.getBoundingInfo().boundingBox;

  const initialTarget: Vector3 = cloneDeep(vectorsWorld[6]);
  const currentTarget: Vector3 = cloneDeep(vectorsWorld[6]);

  const totalLength: number = config.BOUNDING_BOX_SIZE / config.RAY_CAST_LOW_FIDELITY;

  let positiveRowCount = 0;

  for (let i = totalLength; i > 0; i--) {
    const heightRow: Vector3[] = [];

    for (let j = 0; j < totalLength; j++) {
      const current: Vector3 = cloneDeep(currentTarget);

      const ray: Ray = createRay(Vector3.Down(), current, config);

      const { pickedPoint: meshIntersection }: PickingInfo = ray.intersectsMesh(mesh, false);
      if (meshIntersection) {
        heightRow.push(meshIntersection);
      }

      currentTarget.x += config.RAY_CAST_LOW_FIDELITY;
    }

    if (heightRow.some((height: Vector3 | null) => height)) {
      spinousProcessPoints.push(heightRow);
      positiveRowCount++;
    }

    if (positiveRowCount === Math.round(10 / config.RAY_CAST_LOW_FIDELITY)) {
      break;
    }

    currentTarget.x = initialTarget.x;
    currentTarget.z -= config.RAY_CAST_LOW_FIDELITY;
  }

  return spinousProcessPoints.flat();
};

export const evaluateCentroid = (
  foramenPoints: Vector3[],
  config: AutoCorrectionConfigType,
): Vector3 => {
  const filtered = filterInterceptsDBScanVectors([foramenPoints] as (Vector3 | null)[][], config);
  const refined: Vector3[] = filterNullIntercepts(filtered);
  const foramenCentroid: Vector3 = evaluateCentroidFromIntercepts(refined);

  return foramenCentroid;
};

export const evaluateEndplateCentroid = (endplatePoints: (Vector3 | null)[][]): Vector3 => {
  const filteredEndplatePoints: Vector3[] = filterNullIntercepts(endplatePoints);
  const endplateCentroid: Vector3 = evaluateCentroidFromIntercepts(filteredEndplatePoints);

  return endplateCentroid;
};

export const evaluateClosestEndplateSphere = (
  target: Vector3,
  intercepts: (Vector3 | null)[][],
): Coordinates2DType => {
  let closestDistance = 100;
  let closestCoordinates: Coordinates2DType = { x: 0, y: 0 };

  intercepts.forEach((row, i) => {
    row.forEach((intercept, j) => {
      if (intercept) {
        const distance = Vector3.Distance(target, intercept);
        if (distance < closestDistance) {
          closestDistance = distance;
          closestCoordinates = { x: j, y: i };
        }
      }
    });
  });

  return closestCoordinates;
};

export const evaluateCoronalCorrectionAngle = (normal: Vector3): number => {
  let targetNormal: Vector3;

  if (normal.y > 0) {
    targetNormal = normal;
  } else {
    targetNormal = normal.negate();
  }
  const rotationalAxis: Vector3 = Vector3.Cross(targetNormal, Axis.Y);
  const rotationalDot: number = Vector3.Dot(targetNormal, Axis.Y);
  const correctionAngle: number = Math.acos(rotationalDot);
  const quaternion: Quaternion = Quaternion.RotationAxis(rotationalAxis, correctionAngle);
  const euler: Vector3 = quaternion.toEulerAngles();
  const rotationalDirection = targetNormal.x > 0 ? 1 : -1;

  const targetCorrectionAngle: number = rotationalDirection * Math.abs(euler.z);

  if (isNaN(correctionAngle)) {
    throw new Error('Evaluated Coronal Correction Angle is NaN');
  }

  return targetCorrectionAngle;
};

export const evaluateSagittalCorrectionAngle = (normal: Vector3): number => {
  let targetNormal: Vector3;

  if (normal.y > 0) {
    targetNormal = normal;
  } else {
    targetNormal = normal.negate();
  }

  const rotationalAxis: Vector3 = Vector3.Cross(targetNormal, Axis.Y);
  const rotationalDot: number = Vector3.Dot(targetNormal, Axis.Y);
  const correctionAngle: number = Math.acos(rotationalDot);
  const quaternion: Quaternion = Quaternion.RotationAxis(rotationalAxis, correctionAngle);
  const euler: Vector3 = quaternion.toEulerAngles();
  const rotationalDirection = targetNormal.y > 0 ? 1 : -1;

  const targetCorrectionAngle: number = rotationalDirection * Math.abs(euler.x);

  if (isNaN(correctionAngle)) {
    throw new Error('Evaluated Sagittal Correction Angle is NaN');
  }

  return targetCorrectionAngle;
};

export const evaluateEndplateLength = (
  mesh: Mesh,
  scene: Scene,
  config: AutoCorrectionConfigType,
): AutoCorrectionVectorLimitsType => {
  const intercepts: (Vector3 | null)[] = [];

  const isSacrum: boolean = Tags.GetTags(mesh).includes(VertebralBody.S1);

  const [boundingBox]: Mesh[] = scene.getMeshesByTags(AutoCorrectionMesh.BoundingBox);
  const { vectorsWorld }: BoundingBox = boundingBox.getBoundingInfo().boundingBox;

  const targetTop: Vector3 = cloneDeep(vectorsWorld[3]);
  targetTop.x += Math.floor(config.BOUNDING_BOX_SIZE / 2);

  for (let i = 0; i < config.BOUNDING_BOX_SIZE / config.RAY_CAST_LOW_FIDELITY; i++) {
    const ray: Ray = createRay(Vector3.Down(), targetTop, config);

    const { pickedPoint: meshIntersection }: PickingInfo = ray.intersectsMesh(mesh, false);

    if (meshIntersection) {
      intercepts.push(meshIntersection);
    } else {
      intercepts.push(null);
    }

    targetTop.z += config.RAY_CAST_LOW_FIDELITY;
  }

  const [inverseHeights]: (number | null)[][] = evaluateInverseHeightMap([intercepts]);
  const firstDerivative: (number | null)[] = evaluateDerivative([inverseHeights], config)[0];
  const secondDerivative: (number | null)[] = evaluateDerivative([firstDerivative], config)[0];

  const normalizedDerivative: (number | null)[] = isSacrum
    ? evaluateNormalizedDerivative(firstDerivative)
    : evaluateNormalizedDerivative(secondDerivative);

  const firstSegment: number[] = isSacrum
    ? evaluateFirstSacrumSegment(normalizedDerivative, config)
    : evaluateFirstVBSegment(normalizedDerivative);

  const filteredSegment: number[][] = filterInterceptsDBScanDerivatives(
    firstSegment,
    isSacrum ? config.DBSCAN_SCAN_SACRUM_RADIUS : config.DBSCAN_SCAN_VB_RADIUS,
    config,
  );

  const startIndex = normalizedDerivative.findIndex(
    (derivative) => derivative === filteredSegment[0][1],
  ) as number;
  const endIndex = normalizedDerivative.findIndex(
    (derivative) => derivative === filteredSegment[filteredSegment.length - 1][1],
  ) as number;

  const end = intercepts[endIndex] as Vector3;
  const start = intercepts[startIndex] as Vector3;

  return {
    start,
    end,
  };
};

export const evaluateAPCrossSection = (
  endplateCentroid: Vector3,
  mesh: Mesh,
  config: AutoCorrectionConfigType,
  scene: Scene,
): Vector3[] => {
  const intercepts: (Vector3 | null)[] = [];

  const [boundingBox]: Mesh[] = scene.getMeshesByTags(AutoCorrectionMesh.BoundingBox);
  const { vectorsWorld }: BoundingBox = boundingBox.getBoundingInfo().boundingBox;

  const targetTop: Vector3 = cloneDeep(vectorsWorld[3]);
  targetTop.x = endplateCentroid.x;

  for (let i = 0; i < config.BOUNDING_BOX_SIZE / config.RAY_CAST_HIGH_FIDELITY; i++) {
    const ray: Ray = createRay(Vector3.Down(), targetTop, config);

    const { pickedPoint: meshIntersection }: PickingInfo = ray.intersectsMesh(mesh, false);

    if (meshIntersection) {
      intercepts.push(meshIntersection);
    } else {
      intercepts.push(null);
    }

    targetTop.z += config.RAY_CAST_HIGH_FIDELITY;
  }

  const targetIntercept = validateCrossSection(intercepts, CrossSection.AP, config);

  return filterNullIntercepts([targetIntercept]).flat();
};

export const evaluateMLCrossSection = (
  endplateCentroid: Vector3,
  mesh: Mesh,
  config: AutoCorrectionConfigType,
  scene: Scene,
): Vector3[] => {
  const intercepts: (Vector3 | null)[] = [];

  const [boundingBox]: Mesh[] = scene.getMeshesByTags(AutoCorrectionMesh.BoundingBox);
  const { vectorsWorld }: BoundingBox = boundingBox.getBoundingInfo().boundingBox;

  const targetTop: Vector3 = cloneDeep(vectorsWorld[3]);
  targetTop.z = endplateCentroid.z;

  for (let i = 0; i < config.BOUNDING_BOX_SIZE / config.RAY_CAST_HIGH_FIDELITY; i++) {
    const ray: Ray = createRay(Vector3.Down(), targetTop, config);

    const { pickedPoint: meshIntersection }: PickingInfo = ray.intersectsMesh(mesh, false);

    if (meshIntersection) {
      intercepts.push(meshIntersection);
    } else {
      intercepts.push(null);
    }

    targetTop.x += config.RAY_CAST_HIGH_FIDELITY;
  }

  const targetIntercept: (Vector3 | null)[] = validateCrossSection(
    intercepts,
    CrossSection.ML,
    config,
  );

  return filterNullIntercepts([targetIntercept]).flat();
};

export const evaluateFirstSacrumSegment = (
  derivatives: (number | null)[],
  config: AutoCorrectionConfigType,
): number[] => {
  const firstSegment: number[] = [];

  const validDerivatives: number[] = derivatives.filter((value): value is number => value !== null);

  const mean: number =
    validDerivatives.reduce((sum, value) => sum + value, 0) / validDerivatives.length;
  const stdDev: number = Math.sqrt(
    validDerivatives.reduce((sum, value) => sum + Math.pow(value - mean, 2), 0) /
      validDerivatives.length,
  );
  const threshold: number = mean + 2 * stdDev;

  let segmentStart: number | null = null;
  for (let i = 0; i < derivatives.length; i++) {
    const derivative: number | null = derivatives[i];
    const nextDerivative: number | null = derivatives[i + 1];

    if (isNil(derivative) && segmentStart) {
      break;
    }

    if (!isNil(derivative) && Math.abs(derivative) > threshold) {
      break;
    }

    if (
      !isNil(derivative) &&
      !isNil(nextDerivative) &&
      derivative > 0 &&
      nextDerivative < 0 &&
      segmentStart &&
      i - segmentStart > config.SACRUM_MIN_AP_CUT_OFF_AVERAGE / config.RAY_CAST_LOW_FIDELITY
    ) {
      break;
    }

    if (!isNil(derivative) && !segmentStart) {
      segmentStart = i;
    }

    if (derivative) {
      firstSegment.push(derivative);
    }
  }

  return firstSegment;
};

export const evaluateFirstVBSegment = (normalizedSecondDerivative: (number | null)[]): number[] => {
  const firstSegment: number[] = [];

  const firstSegmentIndices: number[] = [];
  const firstSegmentDerivatives: number[] = [];
  let maxPeakIndex = -1;
  let maxTarget = -1;
  let segmentStart: number | null = null;
  for (let i = 0; i < normalizedSecondDerivative.length; i++) {
    const derivative: number | null = normalizedSecondDerivative[i];
    const nextDerivative: number | null = normalizedSecondDerivative[i + 1];

    if (isNil(derivative) && segmentStart) {
      break;
    }

    if (!isNil(derivative) && !segmentStart) {
      segmentStart = derivative;
    }

    if (!isNil(derivative)) {
      firstSegmentDerivatives.push(derivative);
      firstSegmentIndices.push(i);
    }

    if (isNil(derivative) || isNil(nextDerivative)) {
      continue;
    }

    let change: number | null = null;
    if (nextDerivative && derivative) {
      change = nextDerivative - derivative;
    }

    if (change !== null && maxTarget < change) {
      maxTarget = change;
      maxPeakIndex = i;
    }
  }

  const segmentLength1: number = maxPeakIndex - firstSegmentIndices[0];
  const segmentLength2: number = firstSegmentIndices[firstSegmentIndices.length - 1] - maxPeakIndex;

  let targetSegmentStartIndex: number = firstSegmentIndices[0];
  let targetSegmentEndIndex: number = maxPeakIndex;
  if (segmentLength1 < segmentLength2) {
    targetSegmentStartIndex = maxPeakIndex;
    targetSegmentEndIndex = firstSegmentIndices[firstSegmentIndices.length - 1];
  }

  for (let i = 0; i < firstSegmentDerivatives.length; i++) {
    const targetIndex = firstSegmentIndices[i];
    const targetDerivative = firstSegmentDerivatives[i];
    if (targetIndex > targetSegmentStartIndex && targetIndex < targetSegmentEndIndex) {
      firstSegment.push(targetDerivative);
    }
  }

  return firstSegment;
};

export const evaluateLargestNumberSegment = (arr: (number | null)[]): number[] => {
  let largestSegment: number[] = [];
  let currentSegment: number[] = [];

  for (const element of arr) {
    if (element !== null) {
      currentSegment.push(element);
    } else {
      if (!largestSegment || currentSegment.length > largestSegment.length) {
        largestSegment = currentSegment;
      }
      currentSegment = [];
    }
  }

  if (!largestSegment || currentSegment.length > largestSegment.length) {
    largestSegment = currentSegment;
  }

  return largestSegment;
};
export const evaluateSagittalSlope = (
  mesh: Mesh,
  scene: Scene,
  config: AutoCorrectionConfigType,
) => {
  let isNegativeSlope = false;
  let start: Vector3 = Vector3.Zero();
  let end: Vector3 = Vector3.Zero();

  if (Tags.GetTags(mesh).includes(VertebralBody.S1)) {
    const { start: startSegment, end: endSegment } = evaluateEndplateLength(mesh, scene, config);

    start = startSegment;
    end = endSegment;

    if (start.y > end.y) {
      isNegativeSlope = true;
    }
  } else {
    const [boundingBox]: Mesh[] = scene.getMeshesByTags(AutoCorrectionMesh.BoundingBox);
    const { vectorsWorld }: BoundingBox = boundingBox.getBoundingInfo().boundingBox;

    const targetTopIntercept: Vector3 = cloneDeep(vectorsWorld[3]);
    targetTopIntercept.x += config.BOUNDING_BOX_SIZE / 2;

    const topIntercepts: Vector3[] = [];

    for (let i = 0; i < config.BOUNDING_BOX_SIZE / config.RAY_CAST_LOW_FIDELITY; i++) {
      const ray: Ray = createRay(Vector3.Down(), targetTopIntercept, config);

      const { pickedPoint: meshIntersection }: PickingInfo = ray.intersectsMesh(mesh, false);

      if (meshIntersection) {
        topIntercepts.push(meshIntersection);
      }

      targetTopIntercept.z += config.RAY_CAST_LOW_FIDELITY;
    }

    end = topIntercepts[topIntercepts.length - 1];

    const halfTopIntercepts = topIntercepts.slice(0, Math.floor(topIntercepts.length / 4));

    let q1Lowest: Vector3 = new Vector3(
      Number.POSITIVE_INFINITY,
      Number.POSITIVE_INFINITY,
      Number.POSITIVE_INFINITY,
    );

    for (const vector of halfTopIntercepts) {
      if (!q1Lowest || vector.y < q1Lowest.y) {
        q1Lowest = vector;
      }
    }

    let q1Highest: Vector3 = new Vector3(
      Number.NEGATIVE_INFINITY,
      Number.NEGATIVE_INFINITY,
      Number.NEGATIVE_INFINITY,
    );

    for (const vector of halfTopIntercepts) {
      if (!q1Highest || vector.y > q1Highest.y) {
        q1Highest = vector;
      }
    }

    if (q1Highest.y > end.y) {
      isNegativeSlope = true;
    }

    start = isNegativeSlope ? q1Highest : q1Lowest;
  }

  return {
    start,
    end,
    isNegativeSlope,
  };
};

export const evaluateSubarrayIndices = (
  array: (number | null)[],
  subArray: (number | null)[],
): number => {
  if (!subArray.length) {
    return -1;
  }

  const subArrayLength = subArray.length;

  for (let i = 0; i <= array.length - subArrayLength; i++) {
    let match = true;
    for (let j = 0; j < subArrayLength; j++) {
      if (array[i + j] !== subArray[j]) {
        match = false;
        break;
      }
    }

    if (match) {
      return i;
    }
  }

  return -1;
};

export const evaluateClosestVectorIndex = (centroid: Vector3, vectors: Vector3[]): number => {
  let closestIndex = -1;
  let currentDistance: number = Vector3.Distance(centroid, vectors[0]);

  vectors.forEach((vector, i) => {
    const distance = Vector3.Distance(centroid, vector);
    if (distance < currentDistance) {
      currentDistance = distance;
      closestIndex = i;
    }
  });

  return closestIndex;
};
