import { Box, Card, CardContent, Grid } from '@mui/material';
import React, { useEffect, useReducer, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { IAsset, ICase } from '@workflow-nx/common';
import { CREATE_ASSET_DOWNLOAD_URL, FIND_ASSETS } from '../../../../gql';
import { AssetsListTable } from './AssetsListTable';
import { Heading } from '../../../../components/Heading';
import { Alert } from '@mui/material';
import { AssetsTableFilter } from './AssetsTableFilter';
import {
  AssetsTabReducer,
  AssetsTabStateType,
  defaultAssetTypeFilter,
  getAssetPageUrl,
} from './AssetsTab.reducer';
import { file } from '@workflow-nx/utils';
import FileSaver from 'file-saver';
import { useSnackbar } from 'notistack';

type AssetsTabViewProps = {
  activeCase: ICase;
};

const initialState: AssetsTabStateType = {
  assetTypeFilter: defaultAssetTypeFilter,
  showDeleted: false,
};

export function AssetsTabView({ activeCase }: AssetsTabViewProps) {
  const [findAssets, { loading: loadingAssets, data, refetch }] = useLazyQuery(FIND_ASSETS, {
    variables: {
      caseId: activeCase.caseId,
      assetTypeFilter: initialState.assetTypeFilter,
      showDeleted: initialState.showDeleted,
      ignorePlanFilter: true,
    },
  });

  const [state, dispatch] = useReducer(AssetsTabReducer(refetch), initialState);
  const [createAssetDownloadUrl] = useMutation(CREATE_ASSET_DOWNLOAD_URL);
  const [downloading, setDownloading] = useState(false);
  const [downloadPercent, setDownloadPercent] = useState(0);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (activeCase?.caseId) {
      findAssets();
    }
  }, [activeCase, findAssets]);

  const handleDownloadAsset = async (asset: IAsset) => {
    try {
      setDownloading(true);
      const { data } = await createAssetDownloadUrl({
        variables: {
          assetId: asset.assetId,
          allowDeleted: true,
        },
      });

      const response = await file.downloadFile(data.createAssetDownloadUrl.signedUrl, {
        onDownloadProgress: (percentComplete) => {
          setDownloadPercent(percentComplete);
        },
      });

      FileSaver.saveAs(response.data, asset.fileName);
    } catch (e) {
      enqueueSnackbar('Error Downloading File', {
        variant: 'error',
      });
    } finally {
      setDownloadPercent(0);
      setDownloading(false);
    }
  };

  const handleViewAsset = async (asset: IAsset) => {
    const url = getAssetPageUrl(activeCase, asset);

    if (url) {
      window.open(url);
    }
  };

  return (
    <Box>
      <Grid container>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Box display={'flex'} alignItems={'center'} height={40} mb={1}>
                <Heading label={'Filters'} />
              </Box>
              <Card>
                <CardContent>
                  <AssetsTableFilter state={state} dispatch={dispatch} loading={loadingAssets} />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={10}>
              <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mb={1}>
                <Heading label={`Results (${data?.assets?.length ?? 0} assets)`} />
              </Box>
              <Box>
                <Card>
                  <CardContent style={{ height: 'calc(100vh - 228px)' }}>
                    {data?.assets?.length > 0 ? (
                      <AssetsListTable
                        activeCase={activeCase}
                        dispatch={dispatch}
                        loading={loadingAssets}
                        assets={data?.assets ?? []}
                        onView={(asset) => {
                          handleViewAsset(asset);
                        }}
                        onDownload={(asset) => {
                          handleDownloadAsset(asset);
                        }}
                      />
                    ) : (
                      <Alert severity={'warning'}>No assets match the filter criteria. </Alert>
                    )}
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
