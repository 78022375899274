import React, { Dispatch, useState } from 'react';
import { useQuery } from '@apollo/client';
import { format, UserRoleType, IUser, TaskActionType } from '@workflow-nx/common';
import { FIND_USERS_BY_ROLE } from '../../../gql';
import { Box, Button } from '@mui/material';
import { Select } from '../../../components/Select';
import { TaskTableActionType } from './ListTasks.reducer';

type OptionsField = {
  key: string;
  value: string;
};

const taskTypes: OptionsField[] = [];
for (const value of Object.values(TaskActionType)) {
  taskTypes.push({ key: value, value: format.formatTaskDescription(value) });
}
taskTypes.push({ key: 'Custom_Type', value: '[Custom Task Type]' });

export function TaskTableFilter(props: {
  state: {
    assignedId?: string;
    actionType?: string;
  };
  loading: boolean;
  dispatch: Dispatch<TaskTableActionType>;
}) {
  const [fieldReps, setFieldReps] = useState<OptionsField[]>([]);

  useQuery(FIND_USERS_BY_ROLE, {
    fetchPolicy: 'network-only',
    variables: {
      roleFilter: [UserRoleType.FieldRep, UserRoleType.CustomerRep],
    },
    onCompleted: (data) => {
      const fieldRepUsers = data?.users?.users || [];
      setFieldReps(
        fieldRepUsers.map((user: IUser) => {
          return { key: user.userId, value: format.formatName(user) };
        }),
      );
    },
  });

  return (
    <Box display={'flex'} flexDirection={'column'} gap={2}>
      <Box>
        <Select
          name={'assignedTo'}
          label={'Assigned To'}
          value={props.state.assignedId ?? ''}
          onChange={(event) => {
            props.dispatch({
              type: 'USER_CHANGED',
              data: event.target.value,
            });
          }}
          menuItems={fieldReps}
          disabled={props.loading}
          fullWidth={true}
        />
      </Box>
      <Box>
        <Select
          name={'task type'}
          label={'Task Type'}
          value={props.state.actionType?.toString()}
          onChange={(event) => {
            props.dispatch({
              type: 'TASK_TYPE_CHANGED',
              data: event.target.value,
            });
          }}
          menuItems={taskTypes}
          disabled={props.loading}
          fullWidth={true}
        />
      </Box>
      <Box>
        <Box textAlign={'center'}>
          <Button
            onClick={() => {
              props.dispatch({ type: 'RESET_FILTERS' });
            }}
          >
            Reset Filters
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
