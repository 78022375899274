import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TableType } from '@workflow-nx/common';
import * as math from '@workflow-nx/math';

import { Box, IconButton, Typography } from '@mui/material';
import {
  CervicalMeasurements,
  MeasurementRangeStatusLevel,
  MeasurementValues,
  MeasurementsWithRange,
  determineMeasurementRangeLevel,
} from '@workflow-nx/core-algorithms';

type CervicalMeasurementSummaryViewProps = {
  disableToggle: boolean;
  isExpanded: boolean;
  setExpansion: (expanded: boolean) => void;
  size: 'small' | 'medium';
  tableType: TableType;
  data?: CervicalMeasurements;
  slopeOfLineOfSight?: number;
};

function formatTableType(tableType: TableType) {
  switch (tableType) {
    case TableType.PlanDiff:
      return 'PLAN DIFF';
    default:
      return tableType;
  }
}

export function CervicalMeasurementSummaryView({
  disableToggle,
  isExpanded,
  setExpansion,
  size,
  tableType,
  data,
  slopeOfLineOfSight,
}: CervicalMeasurementSummaryViewProps) {
  const handleExpansion = () => {
    isExpanded ? setExpansion(false) : setExpansion(true);
  };
  const textStyle = {
    textOverflow: 'ellipsis',
    fontSize: size === 'medium' ? 1 : '0.65rem',
    marginBottom: size === 'medium' ? 1 : 2,
    borderRadius: 1,
  };

  const commonTextProps = {
    component: 'div',
    style: textStyle,
    p: '5px',
  };

  const getTableValue = (values: MeasurementValues | undefined) => {
    if (!values) return null;

    switch (tableType) {
      case TableType.Preop:
      case TableType.Daisy:
        return values.preOp;
      case TableType.Plan:
        return values.plan;
      case TableType.PlanDiff:
        return values.delta;
      default:
        return null;
    }
  };

  const renderExpansionToggleButton = () => {
    let result = null;

    if (!disableToggle) {
      result = (
        <IconButton color={'inherit'} onClick={handleExpansion}>
          {isExpanded ? (
            <FontAwesomeIcon icon={faCaretUp} />
          ) : (
            <FontAwesomeIcon icon={faCaretDown} />
          )}
        </IconButton>
      );
    }

    return result;
  };

  const getBackgroundColor = (measurement: MeasurementsWithRange, value: number | null) => {
    const green = '#96e69b';
    const yellow = '#ffe066';
    const red = '#ffa8a8';
    const gray = '#dee2e6';

    const rangeLevel = determineMeasurementRangeLevel(measurement, value);

    switch (rangeLevel) {
      case MeasurementRangeStatusLevel.Pass:
        return green;
      case MeasurementRangeStatusLevel.Warning:
        return yellow;
      case MeasurementRangeStatusLevel.Fail:
        return red;
      case MeasurementRangeStatusLevel.None:
        return gray;
    }
  };

  const t1Slope = getTableValue(data?.t1Slope);
  const cervicalLordosis = getTableValue(data?.cervicalLordosis);
  const coronal = getTableValue(data?.c2C7CoronalCobbAngle);
  const sva = getTableValue(data?.cervicalSagittalVerticalAxis);
  const cervicalCoronalBalance = getTableValue(data?.cervicalCoronalBalance);

  let t1SlopeMinusCL = null;
  if (t1Slope && cervicalLordosis) {
    t1SlopeMinusCL = t1Slope - cervicalLordosis;
  }

  return (
    <Box display={'flex'} minWidth={'475px'}>
      <Box mr={3} display={'flex'} style={{ alignItems: 'center' }}>
        <Typography variant={'h3'}>
          <Typography variant="body1">
            <strong>{formatTableType(tableType)}</strong>
          </Typography>
        </Typography>
      </Box>
      <Box style={{ flexGrow: 1 }} />
      {data ? (
        <Box display={'flex'} style={{ justifyContent: 'flex-end', columnGap: '3px' }}>
          <Typography {...commonTextProps} data-testid={`${tableType.toLowerCase()}-evaluated-T1S`}>
            <Typography variant="body1">
              <strong>{math.formatNumber(t1Slope, '—', 0, true)}</strong>
            </Typography>
            <Box>T1S</Box>
          </Typography>
          <Typography {...commonTextProps} data-testid={`${tableType.toLowerCase()}-evaluated-CL`}>
            <Typography variant="body1">
              <strong>{math.formatNumber(cervicalLordosis, '—', 0, true)}</strong>
            </Typography>
            <Box>CL</Box>
          </Typography>
          <Typography
            {...commonTextProps}
            data-testid={`${tableType.toLowerCase()}-evaluated-Coronal`}
          >
            <Typography variant="body1">
              <strong>{math.formatNumber(coronal, '—', 0, true)}</strong>
            </Typography>
            <Box>COR</Box>
          </Typography>
          <Typography
            {...commonTextProps}
            data-testid={`${tableType.toLowerCase()}-evaluated-T1S-CL`}
            sx={{ backgroundColor: getBackgroundColor('T1S_CL', t1SlopeMinusCL), width: 60 }}
          >
            <Typography variant="body1">
              <strong>{math.formatNumber(t1SlopeMinusCL, '—', 0, true)}</strong>
            </Typography>
            <Box>T1S-CL</Box>
          </Typography>
          <Typography
            {...commonTextProps}
            data-testid={`${tableType.toLowerCase()}-evaluated-SVA`}
            sx={{ backgroundColor: getBackgroundColor('C2_C7_SVA', sva), width: 60 }}
          >
            <Box display={'flex'} alignItems="end">
              <Typography variant="body1">
                <strong>{math.formatNumber(sva, '—', 0, false)}</strong>
              </Typography>
              <Typography variant="caption">
                <strong>mm</strong>
              </Typography>
            </Box>
            <Box>cSVA</Box>
          </Typography>
          <Typography
            {...commonTextProps}
            data-testid={`${tableType.toLowerCase()}-evaluated-CCB`}
            sx={{ backgroundColor: getBackgroundColor('CCB', sva), width: 60 }}
          >
            <Box display={'flex'} alignItems="end">
              <Typography variant="body1">
                <strong>{math.formatNumber(cervicalCoronalBalance, '—', 0, false)}</strong>
              </Typography>
              <Typography variant="caption">
                <strong>mm</strong>
              </Typography>
            </Box>
            <Box>CCB</Box>
          </Typography>
          <Typography
            {...commonTextProps}
            data-testid={`${tableType.toLowerCase()}-evaluated-SLS`}
            sx={{
              backgroundColor: getBackgroundColor('SLS', slopeOfLineOfSight ?? null),
              width: 60,
            }}
          >
            <Typography variant="body1">
              <strong>{math.formatNumber(slopeOfLineOfSight ?? null, '—', 0, true)}</strong>
            </Typography>
            <Box>SLS</Box>
          </Typography>
        </Box>
      ) : null}
      {renderExpansionToggleButton()}
    </Box>
  );
}
