import { CaseStageType, ICase, IUser, Permission } from '@workflow-nx/common';

export const canDaisySegmentCase = (
  caseTags: {
    key: number;
    value: string;
  }[],
): boolean => {
  const caseTagValues = caseTags.map((caseTag) => caseTag.value.toUpperCase());

  let canExportDaisySegmentation = true;
  caseTagValues.forEach((caseTagValue) => {
    const hasForbiddenTag = [
      'CAT 2',
      'CAT 3',
      'PRE HARDWARE',
      'AUTO-FUSED',
      'REVISION SURGERY',
    ].includes(caseTagValue);
    if (hasForbiddenTag) {
      canExportDaisySegmentation = false;
    }
  });
  return canExportDaisySegmentation;
};

export const canEditCase = (activeCase: ICase, currentUser?: IUser) => {
  if (!currentUser) return false;

  let isEditable =
    currentUser.permissions.includes(Permission.EditCase) ||
    currentUser.permissions.includes(Permission.ManageCase);

  if (activeCase.deletedAt) {
    isEditable = false;
  }

  if (
    ![CaseStageType.Open, CaseStageType.Segmentation, CaseStageType.Planning].includes(
      activeCase.stage,
    )
  ) {
    isEditable = false;
  }

  return isEditable;
};
