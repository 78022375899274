import {
  ILevels,
  LevelType,
  PartType,
  format,
  CaseSpineProfile,
  caseUtils,
} from '@workflow-nx/common';
import { Box, Typography } from '@mui/material';
import React from 'react';

export function InterbodyLevelsView(props: { levels: ILevels; spineProfile: CaseSpineProfile }) {
  const caseProfileLevels = caseUtils.getLevelsSortedByHierarchy(props.spineProfile, 'desc');

  return (
    <Box display={'flex'}>
      {caseProfileLevels.map((key: string) => {
        return (
          <Box paddingRight={2} key={key}>
            <Typography variant={'body2'}>
              <strong>{format.formatInterbodyLevel(key as LevelType)}</strong>
            </Typography>
            <Typography variant={'body1'} noWrap={true}>
              {format.formatPartType(props.levels[key] as PartType)}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
}
