import { IAsset, ValidFileExtensions } from '@workflow-nx/common';
import { Control, FieldValues } from 'react-hook-form';
import { Button, Grid, Stack, Typography } from '@mui/material';
import { DropzoneRHF, ErrorMessageRHF } from '@workflow-nx/ui';
import React from 'react';
import { CloudDesignSettingsFormData } from './CloudDesignForm';
import { FileInfoView } from './FileInfoView';

export function FileRow(props: {
  id: string;
  label: string;
  specification: 4000 | 4001;
  editing: boolean;
  asset?: IAsset;
  onClick: () => void;
  control: Control<CloudDesignSettingsFormData, any>;
}) {
  return (
    <Grid item container xs={12} spacing={1} alignItems={'center'}>
      <Grid item xs={3} md={3} alignItems={'center'}>
        <Typography variant={'h5'}>{props.label}</Typography>
        <Typography variant={'body2'} color={'textSecondary'}>
          Specification {props.specification}
        </Typography>
      </Grid>
      <Grid item xs={6} md={6}>
        {!props.editing && props.asset ? <FileInfoView asset={props.asset} /> : null}
        {!props.editing && !props.asset ? (
          <Typography color={'textSecondary'}>No file</Typography>
        ) : null}
        {props.editing && props.asset ? (
          <Stack direction={'row'} alignItems={'center'}>
            <Typography>{props.asset?.fileName}</Typography>
            <Button variant={'text'} onClick={props.onClick}>
              Clear
            </Button>
          </Stack>
        ) : null}
        {props.editing && !props.asset ? (
          <DropzoneRHF
            name={props.id}
            control={props.control as unknown as Control<FieldValues>}
            validFileExtensions={[ValidFileExtensions.NTOP]}
            variant={'small'}
          />
        ) : null}
      </Grid>
      <Grid item xs={3} md={3} display={'flex'} justifyContent={'center'}>
        <ErrorMessageRHF
          name={props.id}
          control={props.control as unknown as Control<FieldValues>}
        />
      </Grid>
    </Grid>
  );
}
