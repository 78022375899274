import { Alert } from '@mui/material';
import { caseUtils } from '@workflow-nx/common';

export function CaseExpiryDateAlert(props: { expiryDate: string }) {
  const isExpiryDateApproachingOrAlreadyExpired =
    caseUtils.isCaseExpiryDateApproachingOrAlreadyExpired(props.expiryDate);

  return (
    <>
      {isExpiryDateApproachingOrAlreadyExpired ? (
        <Alert severity={'error'}>
          Case study date has expired or is within 30 days of the current expiry date of
          <strong>{` ${props.expiryDate}`}</strong>
        </Alert>
      ) : null}
    </>
  );
}
