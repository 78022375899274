export enum MeasurementRangeStatusLevel {
  Pass,
  Warning,
  Fail,
  None,
}

export type MeasurementsWithRange = 'T1S_CL' | 'C2_C7_SVA' | 'CCB' | 'SLS';

export const determineMeasurementRangeLevel = (
  measurement: MeasurementsWithRange,
  value: number | null,
): MeasurementRangeStatusLevel => {
  if (!value) {
    return MeasurementRangeStatusLevel.None;
  }
  const num = Math.round(value);

  switch (measurement) {
    case 'T1S_CL':
      if (num < 15) {
        return MeasurementRangeStatusLevel.Pass;
      }
      if (num >= 15 && num <= 20) {
        return MeasurementRangeStatusLevel.Warning;
      }
      if (num > 20) {
        return MeasurementRangeStatusLevel.Fail;
      }
      break;
    case 'C2_C7_SVA':
      if (num < 40) {
        return MeasurementRangeStatusLevel.Pass;
      }
      if (num >= 40 && num <= 80) {
        return MeasurementRangeStatusLevel.Warning;
      }
      if (num > 80) {
        return MeasurementRangeStatusLevel.Fail;
      }
      break;
    case 'CCB':
      if (num >= 0 && num <= 20) {
        return MeasurementRangeStatusLevel.Pass;
      } else if (num > 20 && num <= 40) {
        return MeasurementRangeStatusLevel.Warning;
      } else if (num > 40) {
        return MeasurementRangeStatusLevel.Fail;
      }

      break;
    case 'SLS':
      if (num >= 1 && num <= 10) {
        return MeasurementRangeStatusLevel.Pass;
      }
      if ((num >= -10 && num <= 0) || (num >= 11 && num <= 25)) {
        return MeasurementRangeStatusLevel.Warning;
      }
      if (num < -10 || num > 25) {
        return MeasurementRangeStatusLevel.Fail;
      }
      break;
  }

  return MeasurementRangeStatusLevel.None;
};
