import { Alert, Box } from '@mui/material';
import {
  ILevels,
  ISurgeonPreferenceImplant,
  ISurgeonPreferences,
  IUser,
  caseUtils,
  format,
} from '@workflow-nx/common';
import { sortBy } from 'lodash';
import CustomDialog from '../../../../../components/CustomDialog';
import {
  IMPLANT_TYPE_CUSTOM_SORT,
  MEASUREMENT_TYPE_CUSTOM_SORT,
} from '../../../../../utils/surgeonPreferences';
import { ViewSurgeonPreferencesImplant } from './ViewSurgeonPreferencesImplant';
import { ViewSurgeonPreferencesMain } from './ViewSurgeonPreferencesMain';
import { ViewSurgeonPreferencesMeasurement } from './ViewSurgeonPreferencesMeasurement';

export function SurgeonPreferencesViewDialog({
  caseLevels,
  caseNumber,
  surgeonUser,
  preferences,
  onClose,
  open,
}: {
  caseLevels: ILevels;
  caseNumber: string;
  surgeonUser: IUser;
  preferences?: ISurgeonPreferences;
  onClose: () => void;
  open: boolean;
}) {
  const sortedSurgeonPreferenceImplants = sortBy(preferences?.surgeonPreferenceImplants, (item) =>
    IMPLANT_TYPE_CUSTOM_SORT.indexOf(item.implantType),
  );

  const sortedSurgeonPreferenceImplantMeasurements = sortBy(
    preferences?.surgeonPreferenceImplantMeasurements,
    (item) => [
      IMPLANT_TYPE_CUSTOM_SORT.indexOf(item.implantType),
      MEASUREMENT_TYPE_CUSTOM_SORT.indexOf(item.measurementType),
    ],
  );

  const foundImplantTypes = caseUtils.getValidImplantTypes(caseLevels);
  return (
    <CustomDialog
      maxWidth={'md'}
      fullHeight={false}
      open={open}
      title={`${format.formatName(surgeonUser)} Preferences - ${caseNumber}`}
      onClose={() => {
        onClose();
      }}
      positiveActionButtons={[]}
    >
      {open ? (
        <>
          <Box mb={2} />
          {!preferences && (
            <Alert severity={'warning'}>The surgeon's preferences have not been set up yet.</Alert>
          )}
          {preferences && (
            <Alert severity={'info'}>
              Last updated by: {format.formatName(preferences?.updatedByUser)} at{' '}
              {format.formatDateTime(preferences?.updatedAt)}
            </Alert>
          )}
          {preferences && (
            <Box display={'flex'}>
              <Box width={'100%'}>
                <Box>
                  <ViewSurgeonPreferencesMain preferences={preferences} />
                  {preferences?.preferredProcedures?.length ? (
                    <>
                      <Box my={3} />
                      <Box>
                        {sortedSurgeonPreferenceImplants?.map(
                          (selectedImplantType: ISurgeonPreferenceImplant) => {
                            return foundImplantTypes?.includes(selectedImplantType?.implantType) ? (
                              <Box mb={4}>
                                <ViewSurgeonPreferencesImplant
                                  selectedImplantType={selectedImplantType}
                                />
                                <ViewSurgeonPreferencesMeasurement
                                  surgeonPreferenceImplantMeasurements={
                                    sortedSurgeonPreferenceImplantMeasurements
                                  }
                                  implantType={selectedImplantType?.implantType}
                                />
                              </Box>
                            ) : null;
                          },
                        )}
                      </Box>
                    </>
                  ) : null}
                </Box>
              </Box>
            </Box>
          )}
        </>
      ) : null}
    </CustomDialog>
  );
}
