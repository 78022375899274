import { ICase, IPlan, PlanStatusType } from '@workflow-nx/common';
import React, { useEffect, useState } from 'react';
import { Badge, Box, IconButton, Stack, Switch, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList } from '@fortawesome/pro-light-svg-icons';
import { useLazyQuery } from '@apollo/client';
import { FIND_PLANS } from '../../../../gql';
import CustomDialog from 'apps/workflow-client/src/app/components/CustomDialog';
import { PlanGridTable } from '../PlanGridTable';

export function PlansList(props: {
  activeCase: ICase;
  selectedPlan: IPlan | null;
  onSelectPlan: (plan: IPlan) => void;
  caseNumber: string;
}) {
  const [showDeletedPlans, setShowDeletedPlans] = useState(false);
  const [plans, setPlans] = useState<IPlan[]>([]);
  const [showPlanListDialog, setShowPlanListDialog] = useState(false);

  const [findPlans, { loading: loadingFindPlans }] = useLazyQuery(FIND_PLANS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setPlans(data.plans);
    },
  });

  useEffect(() => {
    findPlans({
      variables: {
        caseId: props.activeCase.caseId,
        status: [PlanStatusType.Draft, PlanStatusType.Proposed, PlanStatusType.Approved],
        showDeleted: false,
      },
    });
  }, []);

  return (
    <>
      <IconButton
        size={'large'}
        onClick={() => setShowPlanListDialog(true)}
        disabled={plans.length <= 1}
      >
        <Badge badgeContent={plans.length} color="secondary">
          <FontAwesomeIcon icon={faList} />
        </Badge>
      </IconButton>
      {showPlanListDialog ? (
        <CustomDialog
          maxWidth={'md'}
          fullHeight={false}
          open={showPlanListDialog}
          title={`Plan List - ${props.caseNumber}`}
          onClose={() => setShowPlanListDialog(false)}
          positiveActionButtons={[]}
        >
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <Box flexGrow={1} />
            <Typography>Show Deleted Plans</Typography>
            <Switch
              disabled={loadingFindPlans}
              checked={showDeletedPlans}
              onChange={async () => {
                setShowDeletedPlans(!showDeletedPlans);
                await findPlans({
                  variables: {
                    caseId: props.activeCase.caseId,
                    status: [
                      PlanStatusType.Draft,
                      PlanStatusType.Proposed,
                      PlanStatusType.Approved,
                    ],
                    showDeleted: !showDeletedPlans,
                  },
                });
              }}
            />
          </Stack>
          <PlanGridTable
            activeCase={props.activeCase}
            onSelect={(plan) => {
              props.onSelectPlan(plan);
              setShowPlanListDialog(false);
            }}
            plans={plans}
          />
        </CustomDialog>
      ) : null}
    </>
  );
}
