import { useLazyQuery } from '@apollo/client';
import { dataExport, format, IExportCaseDwh, LevelType, UserRoleType } from '@workflow-nx/common';
import { DateTime } from 'luxon';
import ActionButton from '../../../components/ActionButton';
import config from '../../../extras/config';
import { FIND_REPORT_CASES_EXPORT_DWH } from '../../../gql';

const allLevels = [
  LevelType.L1L2,
  LevelType.L2L3,
  LevelType.L3L4,
  LevelType.L4L5,
  LevelType.L5S1,
  LevelType.L5L6,
  LevelType.L6S1,
  LevelType.L4S1,
];

const CASES_HEADER_LABEL = {
  CaseNumber: 'Case Number',
  CaseType: 'Case Type',
  CaseLink: 'Link',
  CaseReceivedAt: 'Received At',
  CaseDeliveredOn: 'Case Delivered On',
  SurgeryDate: 'Surgery Date',
  TentativeSurgeryDate: 'Tentative Surgery Date',
  SurgeonId: 'Surgeon Id',
  Surgeon: 'Surgeon',
  Institution: 'Institution',
  LocationId: 'Location Id',
  Location: 'Location',
  Region: 'Region',
  Territory: 'Territory',
  MDM: `${format.formatUserRole(UserRoleType.FieldRep)}`,
  Assigned: 'Assigned',
  Stage: 'Stage',
  PatientGender: 'Patient Gender',
  PatientBirthdate: 'Patient Birthdate',
  NumLevelsPlanned: '# of Levels Planned',
  NumLevelsCompleted: '# of Levels Completed',
  NumLevelsBilled: '# of Levels Billed',
  ProjectedRevenue: 'Projected Revenue',
  ActualRevenue: 'Actual Revenue',
  PriceLevelType: 'Price Level Type',
  [LevelType.L1L2]: format.formatLevelType(LevelType.L1L2),
  [LevelType.L2L3]: format.formatLevelType(LevelType.L2L3),
  [LevelType.L3L4]: format.formatLevelType(LevelType.L3L4),
  [LevelType.L4L5]: format.formatLevelType(LevelType.L4L5),
  [LevelType.L5S1]: format.formatLevelType(LevelType.L5S1),
  [LevelType.L4S1]: format.formatLevelType(LevelType.L4S1),
  [LevelType.L5L6]: format.formatLevelType(LevelType.L5L6),
  [LevelType.L6S1]: format.formatLevelType(LevelType.L6S1),
  PlanRevisions: 'Plan Revisions',
  PlanFirstCompletedAt: 'Plan First Completed At',
  PlanCompletedAt: 'Plan Completed At',
  PlanApprovedAt: 'Plan Approved At',
  DicomCTReceivedAt: 'DICOM CT Received At',
  DicomXRayReceivedAt: 'DICOM XR Received At',
  DicomCTFirstReceivedAt: 'DICOM CT First Received At',
  DicomXRayFirstReceivedAt: 'DICOM XR First Received At',
  NearestExpiringAsset: 'Nearest Expiring Asset',
  NearestExpiringAssetStudyDate: 'Nearest Expiring Asset Study Date',
  NearestExpiringAssetDate: 'Nearest Expiring Asset Date',
  EventType: 'Event Type',
  EventOccurredAt: 'Event Occurred At',
  EventNote: 'Event Note',
  LostOpportunityDate: 'Lost Opportunity Date',
  LostOpportunityType: 'Lost Opportunity Type',
  LostOpportunityReason: 'Lost Opportunity Reason',
  LostOpportunityNote: 'Lost Opportunity Note',
  CaseAgeSinceReceived: 'Case Age Since Received',
  AllImagesReceived: 'All Images Received',
  ImagingToFirstPlan: 'Imaging To First Plan',
  FirstPlanToApproval: 'First Plan To Approval',
  ApprovalToDelivery: 'Approval To Delivery',
  ImagingToFirstPlanWorkingDays: 'Imaging To First Plan Working Days',
  SixWeeksPostOpImaging: 'Six Weeks Post-op Imaging',
  SixMonthsPostOpImaging: 'Six Months Post-op Imaging',
};

const CASES_HEADERS = [
  { value: CASES_HEADER_LABEL.CaseNumber, width: 15 },
  { value: CASES_HEADER_LABEL.CaseType, width: 15 },
  { value: CASES_HEADER_LABEL.CaseLink, width: 10 },
  { value: CASES_HEADER_LABEL.CaseReceivedAt, width: 10 },
  { value: CASES_HEADER_LABEL.CaseDeliveredOn, width: 10 },
  { value: CASES_HEADER_LABEL.SurgeryDate, width: 10 },
  { value: CASES_HEADER_LABEL.TentativeSurgeryDate, width: 10 },
  { value: CASES_HEADER_LABEL.SurgeonId, width: 10 },
  { value: CASES_HEADER_LABEL.Surgeon, width: 15 },
  { value: CASES_HEADER_LABEL.Institution, width: 15 },
  { value: CASES_HEADER_LABEL.LocationId, width: 10 },
  { value: CASES_HEADER_LABEL.Location, width: 15 },
  { value: CASES_HEADER_LABEL.Region, width: 15 },
  { value: CASES_HEADER_LABEL.Territory, width: 15 },
  { value: CASES_HEADER_LABEL.MDM, width: 15 },
  { value: CASES_HEADER_LABEL.Assigned, width: 15 },
  { value: CASES_HEADER_LABEL.Stage, width: 15 },
  { value: CASES_HEADER_LABEL.PatientGender, width: 15 },
  { value: CASES_HEADER_LABEL.PatientBirthdate, width: 15 },
  { value: CASES_HEADER_LABEL.NumLevelsPlanned, width: 12 },
  { value: CASES_HEADER_LABEL.NumLevelsCompleted, width: 12 },
  { value: CASES_HEADER_LABEL.NumLevelsBilled, width: 12 },
  { value: CASES_HEADER_LABEL.ProjectedRevenue, width: 12 },
  { value: CASES_HEADER_LABEL.ActualRevenue, width: 12 },
  { value: CASES_HEADER_LABEL.PriceLevelType, width: 12 },
  ...allLevels.map((level) => ({
    value: format.formatLevelType(level),
    width: 12,
  })),
  { value: CASES_HEADER_LABEL.PlanRevisions, width: 15 },
  { value: CASES_HEADER_LABEL.PlanFirstCompletedAt, width: 15 },
  { value: CASES_HEADER_LABEL.PlanCompletedAt, width: 15 },
  { value: CASES_HEADER_LABEL.PlanApprovedAt, width: 15 },
  { value: CASES_HEADER_LABEL.DicomCTReceivedAt, width: 15 },
  { value: CASES_HEADER_LABEL.DicomXRayReceivedAt, width: 15 },
  { value: CASES_HEADER_LABEL.DicomCTFirstReceivedAt, width: 25 },
  { value: CASES_HEADER_LABEL.DicomXRayFirstReceivedAt, width: 25 },
  { value: CASES_HEADER_LABEL.NearestExpiringAsset, width: 15 },
  { value: CASES_HEADER_LABEL.NearestExpiringAssetStudyDate, width: 8 },
  { value: CASES_HEADER_LABEL.NearestExpiringAssetDate, width: 8 },
  { value: CASES_HEADER_LABEL.EventType, width: 15 },
  { value: CASES_HEADER_LABEL.EventOccurredAt, width: 8 },
  { value: CASES_HEADER_LABEL.EventNote, width: 50 },
  { value: CASES_HEADER_LABEL.LostOpportunityDate, width: 8 },
  { value: CASES_HEADER_LABEL.LostOpportunityType, width: 15 },
  { value: CASES_HEADER_LABEL.LostOpportunityReason, width: 15 },
  { value: CASES_HEADER_LABEL.LostOpportunityNote, width: 50 },
  { value: CASES_HEADER_LABEL.CaseAgeSinceReceived, width: 15 },
  { value: CASES_HEADER_LABEL.AllImagesReceived, width: 15 },
  { value: CASES_HEADER_LABEL.ImagingToFirstPlan, width: 15 },
  { value: CASES_HEADER_LABEL.FirstPlanToApproval, width: 15 },
  { value: CASES_HEADER_LABEL.ApprovalToDelivery, width: 15 },
  { value: CASES_HEADER_LABEL.ImagingToFirstPlanWorkingDays, width: 15 },
  { value: CASES_HEADER_LABEL.SixWeeksPostOpImaging, width: 15 },
  { value: CASES_HEADER_LABEL.SixMonthsPostOpImaging, width: 15 },
];

export function ExportCasesButtonDwh(props: { dailySnapshotDate: string }) {
  const snapshotDate = DateTime.fromJSDate(
    DateTime.fromISO(props.dailySnapshotDate).toJSDate(),
  ).toISODate();

  const [lazyFindReportCasesExport, { loading: lazyLoadingFindReportCasesExport }] = useLazyQuery(
    FIND_REPORT_CASES_EXPORT_DWH,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    },
  );

  const handleExportButtonClick = async () => {
    const { data } = await lazyFindReportCasesExport({
      variables: {
        dailySnapshotDate: snapshotDate,
      },
    });
    const xlsData: any[] = [];

    data.reportCasesExportDwh.forEach((exportCase: IExportCaseDwh) => {
      const hospitalDeliveryDate = exportCase.hospitalDeliveryDate;
      const ctReceivedDate = exportCase?.latestDicomCtAsset;
      const xrReceivedDate = exportCase?.latestDicomXrayAsset;
      const ctFirstReceivedDate = exportCase?.oldestDicomCtAsset;
      const xrFirstReceivedDate = exportCase?.oldestDicomXrayAsset;
      const planningFirstCompleteDate = exportCase.planFirstCompletedAt;
      const planningCompleteDate = exportCase.planCompletedAt;
      const planningApprovedDate = exportCase.planApprovedAt;
      const plannedLevelCount = exportCase.levelsPlanned;
      const completedCaseLevelCount = exportCase.levelsCompleted;
      const billableCaseLevelCount = exportCase.levelsBilled;
      const projectedRevenue = exportCase.projectedRevenue;
      const actualRevenue = exportCase.actualRevenue;

      const levels = {
        [LevelType.C2C3]: null,
        [LevelType.C3C4]: null,
        [LevelType.C4C5]: null,
        [LevelType.C5C6]: null,
        [LevelType.C6C7]: null,
        [LevelType.C8T1]: null,
        [LevelType.C7T1]: null,
        [LevelType.C6T1]: null,
        [LevelType.C7C8]: null,

        [LevelType.L1L2]: exportCase?.L1_L2,
        [LevelType.L2L3]: exportCase?.L2_L3,
        [LevelType.L3L4]: exportCase?.L3_L4,
        [LevelType.L4L5]: exportCase?.L4_L5,
        [LevelType.L5S1]: exportCase?.L5_S1,
        [LevelType.L5L6]: exportCase?.L5_L6,
        [LevelType.L6S1]: exportCase?.L6_S1,
        [LevelType.L4S1]: exportCase?.L4_S1,
      };

      xlsData.push({
        [CASES_HEADER_LABEL.CaseNumber]: {
          v: exportCase.number,
        },
        [CASES_HEADER_LABEL.CaseType]: {
          v: format.formatCaseType(exportCase.caseType),
        },
        [CASES_HEADER_LABEL.CaseLink]: {
          l: {
            Target: `${config.client.url}/app/cases/${exportCase.caseId}`,
          },
          v: 'View Case',
        },
        [CASES_HEADER_LABEL.CaseReceivedAt]: {
          t: 'd',
          v: exportCase.receivedAt
            ? format.formatISODate(exportCase.receivedAt as string, 'MM/dd/yyyy')
            : undefined,
        },
        [CASES_HEADER_LABEL.CaseDeliveredOn]: {
          t: 'd',
          v: hospitalDeliveryDate
            ? format.formatISODate(new Date(hospitalDeliveryDate).toISOString(), 'MM/dd/yyyy')
            : undefined,
        },
        [CASES_HEADER_LABEL.SurgeryDate]: {
          t: 'd',
          v:
            exportCase.surgeryDate && !exportCase.isSurgeryDateTentative
              ? format.formatISODate(exportCase.surgeryDate as string, 'MM/dd/yyyy')
              : undefined,
        },
        [CASES_HEADER_LABEL.TentativeSurgeryDate]: {
          t: 'd',
          v:
            exportCase.surgeryDate && exportCase.isSurgeryDateTentative
              ? format.formatISODate(exportCase.surgeryDate as string, 'MM/dd/yyyy')
              : undefined,
        },
        [CASES_HEADER_LABEL.SurgeonId]: {
          t: 'n',
          v: exportCase?.surgeonId ?? undefined,
        },
        [CASES_HEADER_LABEL.Surgeon]: {
          t: 's',
          v: exportCase?.surgeonUser ? exportCase.surgeonUser : '',
        },
        [CASES_HEADER_LABEL.Institution]: {
          t: 's',
          v: exportCase?.organization ?? '',
        },
        [CASES_HEADER_LABEL.LocationId]: {
          t: 'n',
          v: exportCase?.locationId ?? undefined,
        },
        [CASES_HEADER_LABEL.Location]: {
          t: 's',
          v: exportCase?.location ?? '',
        },
        [CASES_HEADER_LABEL.Region]: {
          t: 's',
          v: exportCase?.region ?? '',
        },
        [CASES_HEADER_LABEL.Territory]: {
          t: 's',
          v: exportCase?.territory ?? '',
        },
        [CASES_HEADER_LABEL.MDM]: {
          t: 's',
          v: exportCase?.fieldRepUser ? exportCase.fieldRepUser : '',
        },
        [CASES_HEADER_LABEL.Assigned]: {
          t: 's',
          v: exportCase?.assignedUser ? exportCase.assignedUser : '',
        },
        [CASES_HEADER_LABEL.Stage]: {
          t: 's',
          v: exportCase.stage,
        },
        [CASES_HEADER_LABEL.PatientGender]: {
          t: 's',
          v: exportCase?.patientGender ?? undefined,
        },
        [CASES_HEADER_LABEL.PatientBirthdate]: {
          t: 'd',
          v: exportCase?.patientBirthDate
            ? format.formatISODate(exportCase?.patientBirthDate as string, 'MM/dd/yyyy')
            : undefined,
        },
        [CASES_HEADER_LABEL.NumLevelsPlanned]: {
          t: 'n',
          v: plannedLevelCount,
        },
        [CASES_HEADER_LABEL.NumLevelsCompleted]: {
          t: 'n',
          v: completedCaseLevelCount,
        },
        [CASES_HEADER_LABEL.NumLevelsBilled]: {
          t: 'n',
          v: billableCaseLevelCount,
        },
        [CASES_HEADER_LABEL.ProjectedRevenue]: {
          t: 'n',
          v: projectedRevenue ?? undefined,
        },
        [CASES_HEADER_LABEL.ActualRevenue]: {
          t: 'n',
          v: actualRevenue ?? undefined,
        },

        [CASES_HEADER_LABEL.PriceLevelType]: {
          t: 's',
          v: exportCase?.priceLevelType ?? undefined,
        },
        ...allLevels.reduce(
          (prev, currLevel) => ({
            ...prev,
            [format.formatLevelType(currLevel)]: {
              t: 's',
              v: levels[currLevel] ?? 'NONE',
            },
          }),
          {},
        ),
        [CASES_HEADER_LABEL.PlanRevisions]: {
          t: 'n',
          v: exportCase.planRevisions,
        },
        [CASES_HEADER_LABEL.PlanFirstCompletedAt]: {
          t: 'd',
          v: planningFirstCompleteDate
            ? format.formatISODate(new Date(planningFirstCompleteDate).toISOString(), 'MM/dd/yyyy')
            : undefined,
        },
        [CASES_HEADER_LABEL.PlanCompletedAt]: {
          t: 'd',
          v: planningCompleteDate
            ? format.formatISODate(new Date(planningCompleteDate).toISOString(), 'MM/dd/yyyy')
            : undefined,
        },
        [CASES_HEADER_LABEL.PlanApprovedAt]: {
          t: 'd',
          v: planningApprovedDate
            ? format.formatISODate(new Date(planningApprovedDate).toISOString(), 'MM/dd/yyyy')
            : undefined,
        },
        [CASES_HEADER_LABEL.DicomCTReceivedAt]: {
          t: 'd',
          v: ctReceivedDate
            ? format.formatISODate(new Date(ctReceivedDate).toISOString(), 'MM/dd/yyyy')
            : undefined,
        },
        [CASES_HEADER_LABEL.DicomXRayReceivedAt]: {
          t: 'd',
          v: xrReceivedDate
            ? format.formatISODate(new Date(xrReceivedDate).toISOString(), 'MM/dd/yyyy')
            : undefined,
        },
        [CASES_HEADER_LABEL.DicomCTFirstReceivedAt]: {
          t: 'd',
          v: ctFirstReceivedDate
            ? format.formatISODate(new Date(ctFirstReceivedDate).toISOString(), 'MM/dd/yyyy')
            : undefined,
        },
        [CASES_HEADER_LABEL.DicomXRayFirstReceivedAt]: {
          t: 'd',
          v: xrFirstReceivedDate
            ? format.formatISODate(new Date(xrFirstReceivedDate).toISOString(), 'MM/dd/yyyy')
            : undefined,
        },
        [CASES_HEADER_LABEL.NearestExpiringAsset]: {
          t: 's',
          v: exportCase?.nearestExpAssetType ?? undefined,
        },
        [CASES_HEADER_LABEL.NearestExpiringAssetStudyDate]: {
          t: 'd',
          v: exportCase?.nearestExpStudyDate
            ? format.formatISODate(exportCase?.nearestExpStudyDate as string, 'MM/dd/yyyy')
            : undefined,
        },
        [CASES_HEADER_LABEL.NearestExpiringAssetDate]: {
          t: 'd',
          v: exportCase?.nearestExpExpiryDate
            ? format.formatISODate(exportCase?.nearestExpExpiryDate as string, 'MM/dd/yyyy')
            : undefined,
        },
        [CASES_HEADER_LABEL.EventType]: {
          t: 's',
          v: exportCase?.lastEventType ?? undefined,
        },
        [CASES_HEADER_LABEL.EventOccurredAt]: {
          t: 'd',
          v: exportCase?.eventOccurredAt
            ? format.formatISODate(exportCase?.eventOccurredAt as string, 'MM/dd/yyyy')
            : undefined,
        },
        [CASES_HEADER_LABEL.EventNote]: {
          t: 's',
          v: exportCase?.eventNote ?? '',
        },
        [CASES_HEADER_LABEL.LostOpportunityDate]: {
          t: 'd',
          v: exportCase?.cancelledAt
            ? format.formatISODate(exportCase?.cancelledAt as string, 'MM/dd/yyyy')
            : undefined,
        },
        [CASES_HEADER_LABEL.LostOpportunityType]: {
          t: 's',
          v: exportCase?.cancellationType ?? undefined,
        },
        [CASES_HEADER_LABEL.LostOpportunityReason]: {
          t: 's',
          v: exportCase?.cancelReason ?? undefined,
        },
        [CASES_HEADER_LABEL.LostOpportunityNote]: {
          t: 's',
          v: exportCase?.cancelNote ?? undefined,
        },
        [CASES_HEADER_LABEL.CaseAgeSinceReceived]: {
          t: 'n',
          v: exportCase?.ageSinceReceived,
        },
        [CASES_HEADER_LABEL.AllImagesReceived]: {
          t: 'd',
          v: exportCase?.allImagesReceived
            ? format.formatISODate(exportCase?.allImagesReceived as string, 'MM/dd/yyyy')
            : undefined,
        },
        [CASES_HEADER_LABEL.ImagingToFirstPlan]: {
          t: 'n',
          v: exportCase?.imagingToFirstPlan ?? undefined,
        },
        [CASES_HEADER_LABEL.FirstPlanToApproval]: {
          t: 'n',
          v: exportCase?.firstPlanToApproval ?? undefined,
        },
        [CASES_HEADER_LABEL.ApprovalToDelivery]: {
          t: 'n',
          v: exportCase?.approvalToDelivery ?? undefined,
        },
        [CASES_HEADER_LABEL.ImagingToFirstPlanWorkingDays]: {
          t: 'n',
          v: exportCase?.imagingToFirstPlanWorkingDays ?? undefined,
        },
        [CASES_HEADER_LABEL.SixWeeksPostOpImaging]: {
          t: 's',
          v: exportCase?.sixWeeksPostOpImaging ?? undefined,
        },
        [CASES_HEADER_LABEL.SixMonthsPostOpImaging]: {
          t: 's',
          v: exportCase?.sixMonthsPostOpImaging ?? undefined,
        },
      });
    });
    dataExport.saveXlsxFile(
      `DPS_case_export_${snapshotDate}.xlsx`,
      'DPS Cases',
      CASES_HEADERS,
      xlsData,
    );
  };

  return (
    <ActionButton
      variant={'outlined'}
      color={'secondary'}
      loading={lazyLoadingFindReportCasesExport}
      onClick={handleExportButtonClick}
    >
      Export Cases (XLSX)
    </ActionButton>
  );
}
