import React from 'react';
import { Box, useTheme } from '@mui/material';

export function CalloutView(props: { children: React.ReactNode }) {
  const theme = useTheme();

  return (
    <Box mt={1} mb={2} bgcolor={theme.palette.grey[100]} p={0.5} borderRadius={5}>
      {props.children}
    </Box>
  );
}
