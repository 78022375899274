import { Box, colors, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ICase, IEvent } from '@workflow-nx/common';
import { Dispatch } from 'react';
import { CaseProductionTabActionType } from './CaseProductionTab/CaseProductionTab.view';
import { EventGridTableRow } from './EventGridTableRow';

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    display: 'grid',
    rowGap: 1,
    gridTemplateColumns: 'auto repeat(6, max-content)',
    gridTemplateRows: '25 50',
    alignItems: 'center',
    backgroundColor: theme.palette.divider,
  },
  gridHeaderCell: {
    backgroundColor: 'white',
    height: 25,
    color: colors.grey[600],
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

export function EventGridTable(props: {
  activeCase: ICase;
  events: IEvent[];
  readOnly?: boolean;
  dispatch: Dispatch<CaseProductionTabActionType>;
}) {
  const styles = useStyles();

  return (
    <Box className={styles.gridContainer}>
      <Box className={styles.gridHeaderCell}>
        <Typography variant={'body1'}>Event Type</Typography>
      </Box>
      <Box className={styles.gridHeaderCell}>
        <Typography variant={'body1'}>Occurred At</Typography>
      </Box>
      <Box className={styles.gridHeaderCell}>
        <Typography variant={'body1'}>Description</Typography>
      </Box>
      <Box className={styles.gridHeaderCell}>
        <Typography variant={'body1'}>Carrier</Typography>
      </Box>
      <Box className={styles.gridHeaderCell}>
        <Typography variant={'body1'}>PO #</Typography>
      </Box>
      <Box className={styles.gridHeaderCell}>
        <Typography variant={'body1'}>PO Due Date</Typography>
      </Box>
      <Box className={styles.gridHeaderCell}>
        <Typography variant={'body1'}>Actions</Typography>
      </Box>
      {props?.events?.map((event: IEvent) => {
        return (
          <EventGridTableRow
            readOnly={props.readOnly}
            event={event}
            key={event.eventId}
            onDelete={() => props?.dispatch({ type: 'REFETCH_EVENTS' })}
          />
        );
      })}
    </Box>
  );
}
