import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Alert, Box, Card, CardContent, Stack, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from '../../../components/Page';
import Logo from '../../../components/Logo';
import LoginButton from './LoginButton';
import { UserStatusType } from '@workflow-nx/common';
import { TOTPLogin } from './TOTPLogin';
import config from '../../../extras/config';

const useStyles = makeStyles(() => ({
  title: {
    fontFamily: `'Questrial', sans-serif`,
    fontSize: '1em',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
}));

const LoginView = () => {
  const styles = useStyles();
  const theme = useTheme();
  const params = window.location.search.replace('?', '');
  const searchParams = new URLSearchParams(params);
  const failedLoginReason = searchParams.get('reason');

  return (
    <Page title="Login">
      <Box
        pt={10}
        display={'flex'}
        justifyContent={'center'}
        height={'100vh'}
        style={{ backgroundColor: theme.palette.grey['50'] }}
      >
        <Box width={450}>
          <Card>
            <CardContent>
              <Stack
                sx={{
                  mb: 2,
                  p: 3,
                }}
                alignItems={'center'}
                spacing={2}
              >
                <Stack alignItems={'center'}>
                  <RouterLink to="/">
                    <Logo />
                  </RouterLink>
                  <Typography variant={'body1'} color={'textSecondary'} className={styles.title}>
                    <strong>Digital Production System</strong>
                  </Typography>
                </Stack>
                <>
                  {failedLoginReason === 'EXPIRED' ? (
                    <Alert severity={'warning'}>
                      <Typography variant={'body1'}>
                        Your login has expired. Please login again.
                      </Typography>
                    </Alert>
                  ) : null}
                  {failedLoginReason === UserStatusType.Pending ? (
                    <Alert severity={'warning'}>
                      <Typography variant={'body1'}>
                        Your account in currently pending verification. Please contact the site
                        administrator for further details.
                      </Typography>
                    </Alert>
                  ) : null}
                  {failedLoginReason === UserStatusType.Inactive ? (
                    <Alert severity={'error'}>
                      <Typography variant={'body1'}>
                        Your account in currently inactive. Please contact the site administrator
                        for further details.
                      </Typography>
                    </Alert>
                  ) : null}
                  {failedLoginReason === 'ERROR' ? (
                    <Alert severity={'error'}>
                      <Typography variant={'body1'}>
                        An error occurred logging in. Please contact the site administrator for
                        further details.
                      </Typography>
                    </Alert>
                  ) : null}
                  {failedLoginReason === 'CLIENT_ERROR' ? (
                    <Alert severity={'error'}>
                      <Typography variant={'body1'}>
                        An client error has occurred. Please reload the application in your browser.
                        If this issue persists, please contact the site administrator for further
                        details.
                      </Typography>
                    </Alert>
                  ) : null}
                </>
                {!config.featureFlags.magicLinks ? <LoginButton /> : null}
                {config.featureFlags.magicLinks ? (
                  <Box sx={{ paddingTop: 4, width: '100%' }}>
                    <TOTPLogin />
                  </Box>
                ) : null}
              </Stack>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </Page>
  );
};

export default LoginView;
