import React, { useEffect } from 'react';
import { useField } from 'formik';
import { OutlinedFileTextField } from './OutlinedFileTextField';
import { FileSelectedView } from './FileSelectedView';
import { Grid } from '@mui/material';
import { IAsset, LevelType } from '@workflow-nx/common';
import { useSnackbar } from 'notistack';
import _ from 'lodash';

const fileTypes = ['image/jpeg', 'image/png'];
let acceptedType = '';
fileTypes.forEach((type) => (acceptedType = acceptedType + ', ' + type));

export function ImageField(props: {
  asset?: IAsset;
  levelType: LevelType;
  name: string;
  label: string;
  loading: boolean;
  disabled: boolean;
  onRemove: (levelType: LevelType, assetId: number | undefined) => void;
}) {
  const [asset, setAsset] = React.useState<IAsset | undefined>();
  const [imageInput, , imageHelpers] = useField(props.name);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setAsset(props?.asset);
  }, [props.asset]);

  return (
    <Grid container spacing={1} direction={'column'}>
      <Grid item xs={12}>
        {asset ? (
          <FileSelectedView
            hideRemove={false}
            label={props.label}
            name={asset?.fileName || ''}
            size={asset?.size || 0}
            hideDetails={true}
            disabled={props.disabled}
            onRemove={() => {
              props.onRemove(props.levelType, asset?.assetId);
              setAsset(undefined);
              imageHelpers.setValue(null);
            }}
          />
        ) : null}
        {!asset && !imageInput.value ? (
          <OutlinedFileTextField
            label={props.label}
            name={props.name}
            onBlur={imageInput.onBlur}
            accept={acceptedType}
            onChange={(event) => {
              const file = (event.currentTarget as unknown as any).files[0] as Record<
                string,
                unknown
              >;

              if (!_.includes(fileTypes, file?.type)) {
                enqueueSnackbar('Must be a PNG or JPEG image file', {
                  variant: 'error',
                });
              } else {
                imageHelpers.setValue(file);
              }
            }}
            disabled={props.loading || props.disabled}
          />
        ) : null}
        {!asset && !!imageInput.value ? (
          <FileSelectedView
            hideRemove={false}
            label={props.label}
            name={imageInput?.value?.name}
            size={imageInput?.value?.size}
            hideDetails={true}
            disabled={props.disabled}
            onRemove={() => {
              props.onRemove(props.levelType, undefined);
              imageHelpers.setValue(null);
            }}
          />
        ) : null}
      </Grid>
    </Grid>
  );
}
