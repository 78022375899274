import { JSONSchema } from 'vanilla-jsoneditor';

export const form19Schema: JSONSchema = {
  title: 'Form19',
  type: 'object',
  patternProperties: {
    '^.*$': {
      title: 'Level',
      type: 'object',
      required: ['attributes'],
      properties: {
        header: {
          title: 'Header',
          type: 'object',
          required: ['version', 'drawingNumber'],
          properties: {
            version: { type: ['number', 'string'] },
            drawingNumber: { type: 'string' },
          },
          additionalProperties: false,
        },
        attributes: {
          title: 'Attributes',
          type: 'object',
          patternProperties: {
            '^.*$': {
              title: 'Attribute Name',
              type: 'object',
              required: ['min', 'max'],
              properties: {
                min: { type: ['number', 'null'] },
                max: { type: ['number', 'null'] },
                fixedValue: { type: ['number', 'null'] },
              },
              additionalProperties: false,
            },
          },
          additionalProperties: false,
        },
      },
    },
  },
  additionalProperties: false,
};

export const form20Schema: JSONSchema = {
  title: 'Form20',
  type: 'array',
  items: {
    title: 'Product',
    type: 'object',
    properties: {
      sku: { type: 'string' },
      type: {
        type: 'string',
        enum: ['IMPLANT', 'INSTRUMENT', 'ALIF_X_SCREW'],
      },
      status: {
        type: 'string',
        enum: ['ARCHIVED', 'IN_USE', 'PRE_MARKET'],
      },
      description: { type: 'string' },
      gs1DeviceId: { type: 'string' },
    },
    required: ['sku', 'type', 'status', 'description', 'gs1DeviceId'],
  },
  additionalProperties: false,
};

const implantDrawingDocumentSchema = {
  title: 'ImplantDrawingDocument',
  type: 'object',
  properties: {
    documentId: { type: 'string' },
    documentName: { type: 'string' },
    versions: {
      type: 'array',
      items: {
        title: 'ImplantDrawingDocumentVersion',
        type: 'object',
        properties: {
          versionId: { type: 'string' },
          versionNumber: { type: 'number' },
        },
        required: ['versionId', 'versionNumber'],
      },
    },
  },
  required: ['documentId', 'documentName', 'versions'],
};

const otherDocumentsSchema = {
  title: 'OtherDocuments',
  type: 'array',
  items: {
    title: 'OtherDocument',
    type: 'object',
    properties: {
      documentName: { type: 'string' },
      versionId: { type: 'string' },
      versionNumber: { type: 'number' },
    },
    required: ['versionId', 'documentName', 'versionNumber'],
  },
};

export const implantDrawingsSchema: JSONSchema = {
  title: 'ImplantDrawings',
  type: 'object',
  properties: {
    alif: implantDrawingDocumentSchema,
    llif: implantDrawingDocumentSchema,
    alifx: implantDrawingDocumentSchema,
    tlifc: implantDrawingDocumentSchema,
    tlifca: implantDrawingDocumentSchema,
    tlifo: implantDrawingDocumentSchema,
    otherDocuments: otherDocumentsSchema,
  },
  required: ['alif', 'llif', 'alifx', 'tlifc', 'tlifca', 'tlifo'],
  additionalProperties: false,
};

export const testerEmailsSchema: JSONSchema = {
  title: 'TesterEmails',
  type: 'object',
  properties: {
    whitelist: {
      type: 'array',
      items: {
        title: 'TesterEmailItemType',
        type: 'object',
        properties: {
          key: { type: 'string' },
          type: {
            type: 'string',
            enum: ['USER'],
          },
          value: { type: 'string' },
        },
        required: ['key', 'type', 'value'],
      },
    },
  },
  required: ['whitelist'],
  additionalProperties: false,
};

export const organizationMetadataSchema: JSONSchema = {
  title: 'OrganizationMetadata',
  type: 'object',
  properties: {
    heatTreatAvailable: {
      type: 'boolean',
    },
  },
  required: [],
  additionalProperties: false,
};
