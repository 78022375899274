export enum AutoCorrectionMesh {
  Landmark = 'LANDMARK',
  Sphere = 'SPHERE',
  Plane = 'PLANE',
  BoundingBox = 'BOUNDING_BOX',
  VertebralBody = 'VERTEBRAL_BODY',
  Centroid = 'CENTROID',
  EdgePosterior = 'EDGE-POSTERIOR',
}

export enum GapScoreConfig {
  LDI65 = 0.65,
  LDI35 = 0.35,
  GAP_SCORE_CONSTANT = 0.62,
  GAP_SCORE_INTERCEPT = 29,
}

export enum defaultIdealSpineMapValues {
  idealDefault = 0,
  idealL5S1 = 0.35,
  idealL4L5 = 0.27,
  idealL3L4 = 0.2,
  idealL2L3 = 0.14,
  idealL1L2 = 0.04,
}

export enum IncrementUnits {
  AngleIncrement = 0.000174533,
  HeightIncrement = 0.01,
}

export enum MeasurementSetCount {
  Single = 4,
  Double = 8,
}

export enum CrossSection {
  ML = 'ML',
  AP = 'AP',
}
