import { ImplantType, LevelSize, LevelType, PartType } from '../enums';
import {
  ILevels,
  IPlan,
  IPlanExcludedImplantSizes,
  ISurgeonPreferenceImplant,
  AllowedImplantExclusionType,
} from '../interfaces';

import { caseUtils } from '@workflow-nx/common';

export const getLevelImplant = (level: LevelType, plan: IPlan) => {
  const planImplant = plan?.implants?.find((implant) => implant.level === level);

  if (!planImplant) {
    return null;
  }

  return {
    part: planImplant?.partType,
    ap: planImplant?.ap,
    ml: planImplant?.ml,
    screwLength: [PartType.ALIF_X_TWO_UP, PartType.ALIF_X_TWO_DOWN].includes(
      planImplant?.partType as PartType,
    )
      ? planImplant?.screwLength
      : undefined,
    excludedImplantSizes: planImplant?.excludedImplantSizes,
  };
};

export const getExcludedLevelsImplants = (
  caseLevels: ILevels,
  plan: IPlan,
): IPlanExcludedImplantSizes[] => {
  const excludedImplantSizes: IPlanExcludedImplantSizes[] = [];
  const validCaseLevels = caseUtils.getValidCaseLevels(caseLevels) as LevelType[];

  for (const level of validCaseLevels) {
    const partType = caseLevels[level] as PartType;
    const implantType = caseUtils.getImplantType(partType);
    const levelImplant = getLevelImplant(level, plan);

    const plusSizeExcluded = (levelImplant?.excludedImplantSizes ?? []).includes(LevelSize.Plus);
    const minusSizeExcluded = (levelImplant?.excludedImplantSizes ?? []).includes(LevelSize.Minus);

    if (plusSizeExcluded || minusSizeExcluded) {
      excludedImplantSizes.push({
        implantType: implantType,
        level: level,
        partType: partType,
        plusSizeExcluded: plusSizeExcluded,
        minusSizeExcluded: minusSizeExcluded,
      });
    }
  }

  return excludedImplantSizes;
};

export const extractAllowedExcludedImplants = (
  surgeonPreferenceImplants: ISurgeonPreferenceImplant[],
  foundCaseImplantTypes: ImplantType[],
): AllowedImplantExclusionType[] => {
  return surgeonPreferenceImplants
    .filter(
      (surgeonPreferenceImplant) =>
        foundCaseImplantTypes.includes(surgeonPreferenceImplant.implantType) &&
        surgeonPreferenceImplant.excludedImplantSizes &&
        surgeonPreferenceImplant.excludedImplantSizes.length === 1 &&
        [LevelSize.Plus, LevelSize.Minus].includes(
          surgeonPreferenceImplant.excludedImplantSizes[0] as LevelSize,
        ),
    )
    .map((surgeonPreferenceImplant) => ({
      implantType: surgeonPreferenceImplant.implantType,
      excludedSize: surgeonPreferenceImplant?.excludedImplantSizes?.[0] as LevelSize,
    }));
};

export function getExcludedImplantSize(
  levelType: LevelType,
  excludedLevelsImplants: IPlanExcludedImplantSizes[],
): LevelSize | undefined {
  let excludedImplantSize = undefined;
  const excludedImplant = excludedLevelsImplants.find(
    (implant) =>
      implant.level === levelType && (implant.plusSizeExcluded || implant.minusSizeExcluded),
  );

  if (excludedImplant) {
    excludedImplantSize = excludedImplant?.plusSizeExcluded ? LevelSize.Plus : LevelSize.Minus;
  }
  return excludedImplantSize;
}

export function getValidCaseExcludedImplants(
  allowedImplantExclusions: AllowedImplantExclusionType[],
  excludedLevelsImplants: IPlanExcludedImplantSizes[],
): IPlanExcludedImplantSizes[] {
  return excludedLevelsImplants.filter((implant) => {
    const matchingExclusion = allowedImplantExclusions.find(
      (exclusion) => exclusion.implantType === implant.implantType,
    );

    if (matchingExclusion) {
      if (matchingExclusion.excludedSize === LevelSize.Plus && implant.plusSizeExcluded) {
        return true;
      }

      if (matchingExclusion.excludedSize === LevelSize.Minus && implant.minusSizeExcluded) {
        return true;
      }
    }

    return false;
  });
}

export function determineImplantExcludeLoopBounds(
  caseExcludedImplant?: IPlanExcludedImplantSizes,
): [number, number] {
  let start = 1;
  let end = 3;

  if (caseExcludedImplant) {
    if (caseExcludedImplant.plusSizeExcluded && caseExcludedImplant.minusSizeExcluded) {
      start = 2;
      end = 2;
    } else if (caseExcludedImplant.plusSizeExcluded) {
      end = 2;
    } else if (caseExcludedImplant.minusSizeExcluded) {
      start = 2;
    }
  }

  return [start, end];
}

export function getExcludedImplantSizeForLevel(
  caseLevel: LevelType,
  validCaseExcludedImplants?: IPlanExcludedImplantSizes[],
): LevelSize | undefined {
  if (!validCaseExcludedImplants) {
    return undefined;
  }

  const excludedImplant = validCaseExcludedImplants.find((implant) => implant.level === caseLevel);

  return excludedImplant?.plusSizeExcluded
    ? LevelSize.Plus
    : excludedImplant?.minusSizeExcluded
    ? LevelSize.Minus
    : undefined;
}
