import {
  IUser,
  ICase,
  IAsset,
  IPlan,
  Permission,
  CaseReportStatusType,
  CaseReportError,
  AssetType,
  caseUtils,
  MeasurementsVersionType,
  format,
  CaseRiskAssessmentType,
  MedicalReviewStatusType,
  ReviewRequestType,
  ReviewResultType,
} from '@workflow-nx/common';
import { formatMedicalReviewStatusType } from '../../../../../utils/caseReport/caseReportFormat';
import { useSnackbar } from 'notistack';
import React, { useEffect, useReducer, useState, useCallback } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  FIND_CASE_REPORT,
  FIND_USERS,
  UPSERT_CASE_REPORT,
  UPDATE_PATIENT_RECORD,
  DELETE_ASSET,
  CREATE_ASSET_DOWNLOAD_URL,
  CREATE_CASE_REPORT_REVIEW_REQUEST,
  REJECT_CASE_REPORT_REVIEW,
  APPROVE_CASE_REPORT_REVIEW,
} from '../../../../../gql';

import { Resolver, SubmitHandler, useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import CustomDialog from '../../../../../components/CustomDialog';
import { ProgressButton } from '@workflow-nx/ui';
import { useConfirm } from 'material-ui-confirm';
import useAuth from '../../../../../hooks/useAuth';
import * as Yup from 'yup';
import * as caseReportUtils from '../../../../../utils/caseReport/caseReport';
import { checkGuardrailsFlagged } from '../../../../../utils/caseReport/caseReportMedicalReviewCorrectionPlan';
import * as FileSaver from 'file-saver';
import { CaseReportForm } from './CaseReportForm';
import { createCaseReportDialogSchema } from './caseReportDialogSchema';
import { file, caseReportPdf } from '@workflow-nx/utils';
import useCreateAndUploadAsset from '../../../../../hooks/useCreateAndUploadAsset';
import { Alert } from '@mui/material';
import { DateTime } from 'luxon';
import _ from 'lodash';

import {
  CaseReportDialogReducer,
  CaseReportDialogStateType,
  CasePathologyType,
  CaseReportCorrectionPlanRuleType,
  EncodedAssetLevelType,
  CaseEncodedToBase64ImageType,
  SubmitHandlerWithAction,
  FormActionType,
} from './CaseReportDialog.reducer';
import { MedicalReviewForm } from './MedicalReviewForm';
import { CaseReportReviewForm } from './CaseReportReviewForm';
import { useInterval } from '../../../../../hooks/useInterval';

export function CaseReportDialog({
  surgeonUser,
  activeCase,
  plan,
  onClose,
  open,
}: {
  surgeonUser: IUser;
  activeCase: ICase;
  plan?: IPlan;
  onClose: (shouldUpdate: boolean) => void;
  open: boolean;
}) {
  const { hasPermission, user: currentUser, caseReports } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const [medicalReviewLock, setMedicalReviewLock] = useState(false);
  const [medicalReviewLockExpired, setMedicalReviewLockExpired] = useState(false);
  const [upsertCaseReport] = useMutation(UPSERT_CASE_REPORT);
  const [updatePatientRecord] = useMutation(UPDATE_PATIENT_RECORD);
  const [createCaseReportReviewRequest] = useMutation(CREATE_CASE_REPORT_REVIEW_REQUEST);
  const [rejectCaseReportReview] = useMutation(REJECT_CASE_REPORT_REVIEW);
  const [approveCaseReportReview] = useMutation(APPROVE_CASE_REPORT_REVIEW);
  const [createAssetDownloadUrl] = useMutation(CREATE_ASSET_DOWNLOAD_URL);
  const { createAndUploadAsset } = useCreateAndUploadAsset();
  const [deleteAsset] = useMutation(DELETE_ASSET);
  const [Users, setCaseReportUsers] = useState<IUser[]>([]);
  const [medicalReviewUser, setMedicalReviewUser] = useState<IUser | null>(null);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [activeButton, setActiveButton] = useState<FormActionType | null>(null);

  const validCaseLevels = caseUtils.getValidCaseLevelsWithPartTypes(
    activeCase.levels,
  ) as caseReportUtils.ILevelsHeaderData[];

  const [findCaseReport, { loading, error }] = useLazyQuery(FIND_CASE_REPORT, {
    variables: {
      caseId: activeCase?.caseId,
      patientId: activeCase?.patient?.patientId,
      planId: plan?.planId,
      userId: surgeonUser?.userId,
      assetTypeFilter: caseReportUtils.getAllCaseReportAssetTypes(validCaseLevels),
      deletedAt: null,
      measurementsVersion: MeasurementsVersionType.Version2,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      dispatch({ type: 'INIT', data });
    },
  });

  useQuery(FIND_USERS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const users = data?.users?.users ?? [];

      const peerReviewUsers: IUser[] = [];

      (caseReports?.peerReviewUserIds ?? [])?.forEach((peerReviewUserId) => {
        const peerReviewUser = users.find((user: IUser) => user.userId === peerReviewUserId);
        if (peerReviewUser) {
          peerReviewUsers.push(peerReviewUser);
        }
      });

      // NOTE: should be a list of medical review users but in this case, we will only pick one
      const medicalReviewUser = users.find(
        (user: IUser) => user.userId === caseReports?.medicalReviewUserId,
      );

      setCaseReportUsers(peerReviewUsers);
      setMedicalReviewUser(medicalReviewUser);
    },
  });

  const [state, dispatch] = useReducer(CaseReportDialogReducer(activeCase, plan), {
    CaseData: undefined,
    isStateReady: false,
    caseReportVertebraePreEditImage: undefined,
    caseReportVertebraePostEditImage: undefined,
    caseReportImplantPreEditImage: undefined,
    caseReportImplantPostEditImage: undefined,
    caseReportStandingXrayLateralMeasured: undefined,
    assets: [],
    caseReportId: 0,
    caseId: activeCase?.caseId,
    status: CaseReportStatusType.InProgress,
    isComplete: false,
    riskAssessment: undefined,
    correctionGoalSagittal: undefined,
    correctionGoalSagittalOther: undefined,
    correctionGoalCoronal: undefined,
    correctionGoalCoronalOther: undefined,
    correctionGoalDiscHeightOnly: false,
    correctionGoalNote: undefined,
    segmentationAssetId: undefined,
    segmentationSliceThickness: undefined,
    segmentationPassed: true,
    segmentationImagingAssessment: undefined,
    segmentationImageDate: undefined,
    segmentedByQualifiedOperator: false,
    segmentationPerformedByUser: undefined,
    segmentationPerformedBy: undefined,
    segmentationReviewedByUser: undefined,
    segmentationReviewedBy: undefined,
    segmentationType: undefined,
    reportReviewRequestedAt: undefined,
    reportReviewedByUser: undefined,
    reportReviewedBy: '',
    reportReviewedAt: undefined,
    reportReviewNote: undefined,
    measurementLordosisDifference: undefined,
    measurementLordosisDifferenceRationale: [],
    measurementLordosisDifferenceRationaleNote: undefined,
    measurementNote: undefined,
    medicalReviewRequestedAt: undefined,
    medicalReviewStatus: undefined,
    medicalReviewedAt: undefined,
    medicalReviewedByUser: undefined,
    medicalReviewedBy: undefined,
    medicalReviewNote: undefined,
    planFeedback: [],
    planFeedbackOther: undefined,
    caseReportStatements: [],
    casePathologies: [],
    caseReportCorrectionPlanRules: [],
    patientRecord: {
      patientId: activeCase?.patient?.patientId ?? undefined,
      pelvicIncidence: null,
      lumbarLordosis: undefined,
      lumbarCoronalCobb: undefined,
      sagittalVerticalAxis: undefined,
      l4S1LordoticDistribution: undefined,
      globalTilt: undefined,
      pelvicTilt: undefined,
      sacralSlope: undefined,
      c7SagittalVerticalLine: undefined,
    },
    levelAssets: [],
    deletedAssetIds: [],
    preOp: undefined,
    plan: undefined,
    preferences: undefined,
    caseReportAlignmentGoalTypes: [],
    proposedLumbarMeasurements: undefined,
    caseEncodedToBase64Images: undefined,
  });

  const caseReportDialogSchema = createCaseReportDialogSchema(checkFileExists);
  const Errors: CaseReportError[] = [];

  const methods = useForm<CaseReportDialogStateType>({
    defaultValues: state,
    values: state, // don't delete
    resolver: yupResolver(caseReportDialogSchema) as unknown as Resolver<CaseReportDialogStateType>,
  });

  const dirtyFields = methods.formState.dirtyFields;
  const fieldsToCheck = [
    'riskAssessment',
    'reportReviewedBy',
    'correctionGoalSagittal',
    'correctionGoalSagittalOther',
    'correctionGoalCoronal',
    'correctionGoalCoronalOther',
    'correctionGoalDiscHeightOnly',
    'correctionGoalNote',
    'segmentationSliceThickness',
    'segmentationPassed',
    'segmentationImagingAssessment',
    'segmentedByQualifiedOperator',
    'segmentationPerformedBy',
    'segmentationReviewedBy',
    'measurementLordosisDifferenceRationale',
    'measurementLordosisDifferenceRationaleNote',
    'measurementNote',
    'planFeedback',
    'planFeedbackOther',
    'caseReportStatements',
    'casePathologies',
    'caseReportCorrectionPlanRules',
    'patientRecord',
  ];

  const riskAssessment = methods.getValues('riskAssessment');
  const reportStatus = methods.getValues('status');
  // @ts-ignore
  const caseReportAssets = (methods.getValues('assets') ?? []) as unknown as IAsset[];

  function getAsset(assetType: AssetType) {
    return caseReportAssets.find((asset) => asset.assetType === assetType);
  }

  function checkFileExists(value: unknown, assetType: AssetType) {
    return !!value || !!getAsset(assetType);
  }

  const canEditCaseReport = !!hasPermission?.([
    Permission.ManageCase,
    Permission.EditCase,
    Permission.ManagePostOpAnalysis,
  ]);
  const canEditAndApproveCaseReport =
    caseReportUtils.isValidDesignEngineerUser(activeCase?.assignedUser?.userId, currentUser) &&
    canEditCaseReport;

  const hasFlaggedGuardrails = checkGuardrailsFlagged(state?.caseReportCorrectionPlanRules);

  const isMedicalReviewFormReadyToReview = !!(
    hasFlaggedGuardrails &&
    state.medicalReviewRequestedAt &&
    [MedicalReviewStatusType.Pending, MedicalReviewStatusType.Rejected].includes(
      state.medicalReviewStatus as MedicalReviewStatusType,
    ) &&
    currentUser?.userId === state.medicalReviewedByUser?.userId
  );

  const isCaseReportReadyToReview = !!(
    state.riskAssessment === CaseRiskAssessmentType.CaseReviewQaReview &&
    state.reportReviewRequestedAt &&
    [CaseReportStatusType.InReview, CaseReportStatusType.Rejected].includes(state.status) &&
    currentUser?.userId === state.reportReviewedByUser?.userId
  );

  const selfApproveCaseReportEnabled =
    state.riskAssessment === CaseRiskAssessmentType.QaReviewOnly &&
    canEditAndApproveCaseReport &&
    !state.reportReviewRequestedAt &&
    ![CaseReportStatusType.Approved].includes(state.status);

  const sendMedicalReviewEnabled =
    hasFlaggedGuardrails &&
    canEditAndApproveCaseReport &&
    (!state.medicalReviewRequestedAt ||
      [MedicalReviewStatusType.Overridden, MedicalReviewStatusType.Rejected].includes(
        state.medicalReviewStatus as MedicalReviewStatusType,
      ));
  const overrideMedicalReviewEnabled = !!(
    hasFlaggedGuardrails &&
    canEditAndApproveCaseReport &&
    state.medicalReviewRequestedAt &&
    [MedicalReviewStatusType.Pending, MedicalReviewStatusType.Rejected].includes(
      state.medicalReviewStatus as MedicalReviewStatusType,
    )
  );
  const sendCaseReportReviewEnabled =
    state.riskAssessment === CaseRiskAssessmentType.CaseReviewQaReview &&
    canEditAndApproveCaseReport &&
    !state.reportReviewRequestedAt;

  function getMedicalReviewExpiryTime(medicalReviewRequestedAt: Date): Date | string {
    return new Date(
      DateTime.fromJSDate(new Date(medicalReviewRequestedAt)).plus({ hours: 3 }).toJSDate(),
    );
  }

  // Wrapper function for handleSubmit
  const handleFormSubmit = (formActionType: FormActionType) => {
    return (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      methods.handleSubmit((data) => handleSubmitWithAction(data, formActionType))();
    };
  };

  const handleSubmitWithAction: SubmitHandlerWithAction<CaseReportDialogStateType> = async (
    data,
    formActionType,
  ) => {
    setActiveButton(formActionType);
    try {
      let title = 'Save TEM-013 Case Report?';
      let description = 'Are you sure you want to save TEM-013 case report?';

      if (formActionType === FormActionType.createCaseReportMedicalReviewRequest) {
        title = 'Send Case Report Medical Review Request?';
        description =
          'This will send case report medical review request to Medical Affairs Team. Continue?';
      } else if (formActionType === FormActionType.CreateCaseReportPeerReviewRequest) {
        title = 'Send Case Report Review Request?';
        description = 'This will send case report review request. Continue?';
      } else if (formActionType === FormActionType.ApproveCaseReportPeerReview) {
        title = 'Approve Case Report?';
        description = 'Are you sure you want to approve case report?';
      }

      await confirm({
        title: title,
        description: description,
      });

      const caseId = data.caseId;
      const planId = data?.CaseData?.planId ?? 0;

      const casePathologies = data?.casePathologies
        .filter((element: CasePathologyType) => element.pathologySelected)
        .map((pathology: CasePathologyType) => ({
          pathologyType: pathology.pathologyType,
          pathologyNote: pathology.pathologyNote,
        }));

      const caseReportCorrectionPlanRules = data?.caseReportCorrectionPlanRules.map(
        (caseReportCorrectionPlanRule: CaseReportCorrectionPlanRuleType) => {
          return {
            ruleType: caseReportCorrectionPlanRule.ruleType,
            level: caseReportCorrectionPlanRule?.level
              ? caseReportCorrectionPlanRule.level
              : undefined,
            planValue: Number(caseReportCorrectionPlanRule?.planValue),
            flagged: caseReportCorrectionPlanRule?.flagged,
            note: caseReportCorrectionPlanRule?.note,
          };
        },
      );

      const caseReviewQaReview = data?.riskAssessment === CaseRiskAssessmentType.CaseReviewQaReview;

      let status = CaseReportStatusType.InProgress;
      let reportReviewedBy = data?.reportReviewedBy ? Number(data?.reportReviewedBy) : null;
      let reportReviewedAt = null;

      if (formActionType === FormActionType.ApproveCaseReportPeerReview) {
        status = CaseReportStatusType.Approved;
        reportReviewedBy = activeCase.assignedUser.userId;
        reportReviewedAt = new Date().toISOString();
      } else if (formActionType === FormActionType.CreateCaseReportPeerReviewRequest) {
        status = CaseReportStatusType.InReview;
      } else if (formActionType === FormActionType.createCaseReportMedicalReviewRequest) {
        status = CaseReportStatusType.InMedicalReview;
      }
      await upsertCaseReport({
        variables: {
          caseId: caseId,
          status: status,
          isComplete: data?.isComplete,
          riskAssessment: data?.riskAssessment,
          correctionGoalSagittal: data?.correctionGoalSagittal,
          correctionGoalSagittalOther: data?.correctionGoalSagittalOther,
          correctionGoalCoronal: data?.correctionGoalCoronal,
          correctionGoalCoronalOther: data?.correctionGoalCoronalOther,
          correctionGoalDiscHeightOnly: data?.correctionGoalDiscHeightOnly,
          correctionGoalNote: data?.correctionGoalNote,
          segmentationAssetId: caseUtils.convertNullOrNumberString(data?.segmentationAssetId),
          segmentationSliceThickness: data?.segmentationSliceThickness,
          segmentationPassed: data?.segmentationPassed,
          segmentationImagingAssessment: data?.segmentationImagingAssessment,
          segmentationImageDate: data?.segmentationImageDate,
          segmentedByQualifiedOperator: data?.segmentedByQualifiedOperator,
          segmentationPerformedBy: caseUtils.convertNullOrNumberString(
            data?.segmentationPerformedBy,
          ),
          segmentationReviewedBy: caseUtils.convertNullOrNumberString(data?.segmentationReviewedBy),
          segmentationType: data?.segmentationType,
          reportReviewedBy: caseUtils.convertNullOrNumberString(reportReviewedBy),
          reportReviewedAt: reportReviewedAt,
          measurementLordosisDifference: data?.measurementLordosisDifference,
          measurementLordosisDifferenceRationale: data?.measurementLordosisDifferenceRationale,
          measurementLordosisDifferenceRationaleNote:
            data?.measurementLordosisDifferenceRationaleNote,
          measurementNote: data?.measurementNote,
          planFeedback: data?.planFeedback,
          planFeedbackOther: data?.planFeedbackOther,
          casePathologies: casePathologies,
          caseReportStatements: data?.caseReportStatements,
          caseReportCorrectionPlanRules: caseReportCorrectionPlanRules,
        },
      });

      await updatePatientRecord({
        variables: {
          patientId: data?.patientRecord?.patientId,
          pelvicIncidence:
            data?.patientRecord?.pelvicIncidence != null &&
            data?.patientRecord?.pelvicIncidence?.toString() !== ''
              ? Number(data?.patientRecord?.pelvicIncidence)
              : null,
          lumbarLordosis:
            data?.patientRecord?.lumbarLordosis != null &&
            data?.patientRecord?.lumbarLordosis?.toString() !== ''
              ? Number(data?.patientRecord?.lumbarLordosis)
              : null,
          lumbarCoronalCobb:
            data?.patientRecord?.lumbarCoronalCobb != null &&
            data?.patientRecord?.lumbarCoronalCobb?.toString() !== ''
              ? Number(data?.patientRecord?.lumbarCoronalCobb)
              : null,
          sagittalVerticalAxis:
            data?.patientRecord?.sagittalVerticalAxis != null &&
            data?.patientRecord?.sagittalVerticalAxis?.toString() !== ''
              ? Number(data?.patientRecord?.sagittalVerticalAxis)
              : null,
          l4S1LordoticDistribution:
            data?.patientRecord?.l4S1LordoticDistribution != null &&
            data?.patientRecord?.l4S1LordoticDistribution?.toString() !== ''
              ? Number(data?.patientRecord?.l4S1LordoticDistribution)
              : null,
          globalTilt:
            data?.patientRecord?.globalTilt != null &&
            data?.patientRecord?.globalTilt?.toString() !== ''
              ? Number(data?.patientRecord?.globalTilt)
              : null,
          pelvicTilt:
            data?.patientRecord?.pelvicTilt != null &&
            data?.patientRecord?.pelvicTilt?.toString() !== ''
              ? Number(data?.patientRecord?.pelvicTilt)
              : null,
          sacralSlope:
            data?.patientRecord?.sacralSlope != null &&
            data?.patientRecord?.sacralSlope?.toString() !== ''
              ? Number(data?.patientRecord?.sacralSlope)
              : null,
          c7SagittalVerticalLine:
            data?.patientRecord?.c7SagittalVerticalLine != null &&
            data?.patientRecord?.c7SagittalVerticalLine?.toString() !== ''
              ? Number(data?.patientRecord?.c7SagittalVerticalLine)
              : null,
        },
      });

      for (const deleteAssetId of data?.deletedAssetIds ?? []) {
        await deleteAsset({
          variables: {
            assetId: deleteAssetId,
          },
        });
      }

      setUploadingImage(true);
      if (data?.caseReportStandingXrayLateralMeasured) {
        await createAndUploadAsset(
          data.caseReportStandingXrayLateralMeasured,
          AssetType.CaseReportStandingXrayLateralMeasured,
          caseId,
          planId,
        );
      }

      if (data?.caseReportVertebraePreEditImage) {
        await createAndUploadAsset(
          data.caseReportVertebraePreEditImage,
          AssetType.CaseReportVertebraePreEditImage,
          caseId,
          planId,
        );
      }
      if (data?.caseReportVertebraePostEditImage) {
        await createAndUploadAsset(
          data.caseReportVertebraePostEditImage,
          AssetType.CaseReportVertebraePostEditImage,
          caseId,
          planId,
        );
      }
      if (data?.caseReportImplantPreEditImage) {
        await createAndUploadAsset(
          data.caseReportImplantPreEditImage,
          AssetType.CaseReportImplantPreEditImage,
          caseId,
          planId,
        );
      }
      if (data?.caseReportImplantPostEditImage) {
        await createAndUploadAsset(
          data.caseReportImplantPostEditImage,
          AssetType.CaseReportImplantPostEditImage,
          caseId,
          planId,
        );
      }

      if (data?.levelAssets) {
        for (const levelAsset of data.levelAssets) {
          for (const inputAsset of levelAsset.inputAssets) {
            if (inputAsset?.inputAsset) {
              await createAndUploadAsset(
                inputAsset.inputAsset,
                inputAsset.assetType,
                caseId,
                planId,
              );
            }
          }
        }
      }

      if (formActionType === FormActionType.createCaseReportMedicalReviewRequest) {
        await createCaseReportReviewRequest({
          variables: {
            caseId: activeCase?.caseId,
            userId: medicalReviewUser?.userId,
            reviewRequestType: ReviewRequestType.MedicalReview,
          },
        });
      }

      if (
        formActionType === FormActionType.CreateCaseReportPeerReviewRequest &&
        caseReviewQaReview
      ) {
        await createCaseReportReviewRequest({
          variables: {
            caseId: activeCase?.caseId,
            userId: data?.reportReviewedBy ? Number(data?.reportReviewedBy) : undefined,
            reviewRequestType: ReviewRequestType.PeerReview,
          },
        });
      }

      enqueueSnackbar('TEM-013 case report saved', {
        variant: 'success',
      });
      findCaseReport();
      //onClose(true);
    } catch (err) {
      if (!err) {
        return;
      }
      console.error(err);
      enqueueSnackbar('An error occurred updating the TEM-013 case report', {
        variant: 'error',
      });
    } finally {
      //methods.reset();
      setUploadingImage(false);
      setActiveButton(null);
    }
  };

  const handleMedicalReviewSubmit = async (actionType: FormActionType, formData: any) => {
    try {
      await confirm({
        title: `${
          actionType === FormActionType.ApproveCaseReportMedicalReview ? 'Approve' : 'Reject'
        } Case Report Medical Review?`,
        description: (
          <>
            Are you sure you want to{' '}
            {actionType === FormActionType.ApproveCaseReportMedicalReview ? 'approve' : 'reject'}{' '}
            Case Report Medical Review? Please confirm
          </>
        ),
      });

      if (actionType === FormActionType.ApproveCaseReportMedicalReview) {
        await approveCaseReportReview({
          variables: {
            caseId: activeCase?.caseId,
            reviewResultType: ReviewResultType.MedicalReviewApprove,
            medicalReviewNote: formData?.medicalReviewNote,
          },
        });
      } else if (actionType === FormActionType.RejectCaseReportMedicalReview) {
        await rejectCaseReportReview({
          variables: {
            caseId: activeCase?.caseId,
            reviewResultType: ReviewResultType.MedicalReviewReject,
            medicalReviewNote: formData?.medicalReviewNote,
          },
        });
      }

      enqueueSnackbar(
        `Case Report Medical Review has been ${
          actionType === FormActionType.ApproveCaseReportMedicalReview ? 'approved' : 'rejected'
        }`,
        {
          variant: 'success',
        },
      );
      findCaseReport();
    } catch (e) {
      if (!e) {
        return;
      }
      console.error(e);
      enqueueSnackbar(
        `An error occurred ${
          actionType === FormActionType.ApproveCaseReportMedicalReview ? 'approving' : 'rejecting'
        } the case report medical review`,
        { variant: 'error' },
      );
    }
  };

  const handleCaseReportReviewSubmit = async (actionType: FormActionType, formData: any) => {
    try {
      await confirm({
        title: `${
          actionType === FormActionType.ApproveCaseReportPeerReview ? 'Approve' : 'Reject'
        } Case Report Review?`,
        description: (
          <>
            Are you sure you want to{' '}
            {actionType === FormActionType.ApproveCaseReportPeerReview ? 'approve' : 'reject'} Case
            Report Review? Please confirm
          </>
        ),
      });

      if (actionType === FormActionType.ApproveCaseReportPeerReview) {
        await approveCaseReportReview({
          variables: {
            caseId: activeCase?.caseId,
            reviewResultType: ReviewResultType.PeerReviewApprove,
            reportReviewNote: formData?.reportReviewNote,
          },
        });
      } else if (actionType === FormActionType.RejectCaseReportPeerReview) {
        await rejectCaseReportReview({
          variables: {
            caseId: activeCase?.caseId,
            reviewResultType: ReviewResultType.PeerReviewReject,
            reportReviewNote: formData?.reportReviewNote,
          },
        });
      }

      enqueueSnackbar(
        `Case Report has been ${
          actionType === FormActionType.ApproveCaseReportPeerReview ? 'approved' : 'rejected'
        }`,
        {
          variant: 'success',
        },
      );
      findCaseReport();
    } catch (e) {
      if (!e) {
        return;
      }
      console.error(e);
      enqueueSnackbar(
        `An error occurred ${
          actionType === FormActionType.ApproveCaseReportPeerReview ? 'approving' : 'rejecting'
        } the case report review`,
        { variant: 'error' },
      );
    }
  };

  const handleOverrideMedicalReview = async () => {
    setActiveButton(FormActionType.OverrideMedicalReview);
    try {
      await confirm({
        title: `Override Case Report Medical Review?`,
        description: <>This will override case report medical review. Continue?</>,
      });

      await rejectCaseReportReview({
        variables: {
          caseId: activeCase?.caseId,
          reviewResultType: ReviewResultType.MedicalReviewOverride,
        },
      });
      enqueueSnackbar(`The case report medical review has been overridden`, { variant: 'success' });
      findCaseReport();
    } catch (e) {
      if (!e) {
        return;
      }
      console.error(e);
      enqueueSnackbar('An error occurred overriding case report medical review', {
        variant: 'error',
      });
    } finally {
      setActiveButton(null);
    }
  };

  const handleClose = async () => {
    const hasDirtyFields = fieldsToCheck.some(
      (field) => dirtyFields[field as keyof typeof dirtyFields],
    );

    if (hasDirtyFields) {
      try {
        await confirm({
          title: 'Close TEM-013 dialog?',
          description: 'You have unsaved changes. Are you sure you want to close without saving?',
        });
        methods.reset();
        onClose(false);
      } catch {
        // Do nothing if the user cancels the confirmation dialog
      }
    } else {
      onClose(false);
    }
  };

  const getBase64EncodedAsset = useCallback(
    async (assetId: number) => {
      return await file.encodeAssetToBase64(assetId, createAssetDownloadUrl);
    },
    [createAssetDownloadUrl],
  );

  const encodeFileToBase64 = async (inputAssetFile: File): Promise<string> => {
    return await file.encodeBlobToBase64(inputAssetFile);
  };

  const createEncodedImages = async (caseReportData: CaseReportDialogStateType) => {
    const assets = caseReportData.assets ?? [];
    const levelAssets = caseReportData.levelAssets ?? [];

    const caseReportVertebraePreEditImage = caseReportData.caseReportVertebraePreEditImage;
    const caseReportVertebraePostEditImage = caseReportData.caseReportVertebraePostEditImage;
    const caseReportImplantPreEditImage = caseReportData.caseReportImplantPreEditImage;
    const caseReportImplantPostEditImage = caseReportData.caseReportImplantPostEditImage;
    const caseReportStandingXrayLateralMeasured =
      caseReportData.caseReportStandingXrayLateralMeasured;

    const findAsset = (assetType: AssetType) =>
      assets.find((asset) => asset.assetType === assetType);

    const encodedVertebraePreEditImage = await (async () => {
      const asset = findAsset(AssetType.CaseReportVertebraePreEditImage);
      if (asset) return getBase64EncodedAsset(asset.assetId);
      if (caseReportVertebraePreEditImage)
        return encodeFileToBase64(caseReportVertebraePreEditImage);
      return '';
    })();

    const encodedVertebraePostEditImage = await (async () => {
      const asset = findAsset(AssetType.CaseReportVertebraePostEditImage);
      if (asset) return getBase64EncodedAsset(asset.assetId);
      if (caseReportVertebraePostEditImage)
        return encodeFileToBase64(caseReportVertebraePostEditImage);
      return '';
    })();

    const encodedImplantPreEditImage = await (async () => {
      const asset = findAsset(AssetType.CaseReportImplantPreEditImage);
      if (asset) return getBase64EncodedAsset(asset.assetId);
      if (caseReportImplantPreEditImage) return encodeFileToBase64(caseReportImplantPreEditImage);
      return '';
    })();

    const encodedImplantPostEditImage = await (async () => {
      const asset = findAsset(AssetType.CaseReportImplantPostEditImage);
      if (asset) return getBase64EncodedAsset(asset.assetId);
      if (caseReportImplantPostEditImage) return encodeFileToBase64(caseReportImplantPostEditImage);
      return '';
    })();

    const encodedStandingXrayLateralMeasured = await (async () => {
      const asset = findAsset(AssetType.CaseReportStandingXrayLateralMeasured);
      if (asset) return getBase64EncodedAsset(asset.assetId);
      if (caseReportStandingXrayLateralMeasured)
        return encodeFileToBase64(caseReportStandingXrayLateralMeasured);
      return '';
    })();

    const encodedPreopLateralImage = await (async () => {
      const asset = findAsset(AssetType.PreopLateralImage);
      if (asset) return getBase64EncodedAsset(asset.assetId);
      return '';
    })();

    const encodedPreopCoronalImage = await (async () => {
      const asset = findAsset(AssetType.PreopCoronalImage);
      if (asset) return getBase64EncodedAsset(asset.assetId);
      return '';
    })();

    const encodedPlanLateralImage = await (async () => {
      const asset = findAsset(AssetType.PlanLateralImage);
      if (asset) return getBase64EncodedAsset(asset.assetId);
      return '';
    })();

    const encodedPlanCoronalImage = await (async () => {
      const asset = findAsset(AssetType.PlanCoronalImage);
      if (asset) return getBase64EncodedAsset(asset.assetId);
      return '';
    })();

    const encodedAssetLevels: EncodedAssetLevelType[] = await Promise.all(
      levelAssets.map(async (levelAsset) => {
        return Promise.all(
          levelAsset.inputAssets.map(async (inputAsset) => {
            const asset = findAsset(inputAsset.assetType as AssetType);
            let encodedImage = '';

            if (asset) {
              encodedImage = await getBase64EncodedAsset(asset.assetId);
            } else if (inputAsset.inputAsset) {
              encodedImage = await encodeFileToBase64(inputAsset.inputAsset);
            }

            return {
              level: inputAsset.level,
              implantType: levelAsset.implantType,
              assetType: inputAsset.assetType,
              encodedImage: encodedImage || '',
            };
          }),
        );
      }),
    ).then((results) => results.flat());

    const encodedImages: CaseEncodedToBase64ImageType = {
      encodedVertebraePreEditImage,
      encodedVertebraePostEditImage,
      encodedImplantPreEditImage,
      encodedImplantPostEditImage,
      encodedStandingXrayLateralMeasured,
      encodedPreopLateralImage,
      encodedPreopCoronalImage,
      encodedPlanLateralImage,
      encodedPlanCoronalImage,
      encodedAssetLevels,
    };

    return encodedImages;
  };

  const handleCreateCaseReportPDF: SubmitHandler<CaseReportDialogStateType> = async (
    caseReportData,
  ) => {
    setActiveButton(FormActionType.PreviewCaseReportPdf);
    try {
      await confirm({
        title: 'Save TEM-013 Case Report?',
        description: 'Are you sure you want to save TEM-013 case report?',
      });

      const caseEncodedToBase64Images = await createEncodedImages(caseReportData);
      const updatedCaseReportData: CaseReportDialogStateType = JSON.parse(
        JSON.stringify(caseReportData),
      );
      updatedCaseReportData.caseEncodedToBase64Images = caseEncodedToBase64Images;

      const caseReportPdfData = await caseReportPdf.caseReportPdf(updatedCaseReportData);
      FileSaver.saveAs(caseReportPdfData, `${activeCase.number} TEM-013.pdf`);

      enqueueSnackbar('TEM-013 case report saved', {
        variant: 'success',
      });
      //onClose(true);
    } catch (err) {
      if (!err) {
        return;
      }
      console.error(err);
      enqueueSnackbar('An error occurred creating the TEM-013 case report pdf', {
        variant: 'error',
      });
    } finally {
      setActiveButton(null);
    }
  };

  useEffect(() => {
    if (open) {
      findCaseReport();
    }
  }, [open, findCaseReport]);

  useEffect(() => {
    if (error) {
      console.error('Error fetching case report:', error);
      enqueueSnackbar('An error occurred while loading the case report', {
        variant: 'error',
      });
    }
  }, [error, enqueueSnackbar]);

  useInterval(
    () => {
      if (
        state.medicalReviewRequestedAt &&
        (state.medicalReviewStatus === MedicalReviewStatusType.Pending ||
          state.medicalReviewStatus === MedicalReviewStatusType.Rejected)
      ) {
        const medicalReviewExpiryTime = getMedicalReviewExpiryTime(state.medicalReviewRequestedAt);
        const currentTime = new Date();

        setMedicalReviewLock(currentTime < medicalReviewExpiryTime);
        setMedicalReviewLockExpired(currentTime >= medicalReviewExpiryTime);
      }
    },
    1000,
    [state.medicalReviewRequestedAt, state.medicalReviewStatus],
  );

  useEffect(() => {
    if (
      state.medicalReviewRequestedAt &&
      state.medicalReviewStatus !== MedicalReviewStatusType.Pending &&
      state.medicalReviewStatus !== MedicalReviewStatusType.Rejected
    ) {
      setMedicalReviewLock(false);
      setMedicalReviewLockExpired(false);
    }
  }, [state.medicalReviewRequestedAt, state.medicalReviewStatus]);

  useEffect(() => {
    if (open && state.isStateReady) {
      methods.reset(state);
    }
  }, [open, state, methods]);

  return open && state.isStateReady ? (
    <Box m={1}>
      <FormProvider {...methods}>
        <form>
          <CustomDialog
            maxWidth={'lg'}
            open={open}
            title={`TEM-013`}
            onClose={handleClose}
            positiveActionButtons={
              canEditCaseReport
                ? [
                    <ProgressButton
                      variant={'outlined'}
                      disabled={methods.formState.isSubmitting}
                      onClick={(evt) => methods.handleSubmit(handleCreateCaseReportPDF)(evt)}
                      label={
                        state?.status !== CaseReportStatusType.Approved
                          ? 'Preview TEM-013 PDF'
                          : 'Download TEM-013 PDF'
                      }
                      loading={
                        methods.formState.isSubmitting &&
                        activeButton === FormActionType.PreviewCaseReportPdf
                      }
                    />,
                    (!isCaseReportReadyToReview || !isMedicalReviewFormReadyToReview) &&
                      canEditAndApproveCaseReport && (
                        <ProgressButton
                          variant={'outlined'}
                          disabled={
                            methods.formState.isSubmitting ||
                            medicalReviewLock ||
                            [CaseReportStatusType.Approved, CaseReportStatusType.InReview].includes(
                              state?.status,
                            )
                          }
                          onClick={handleFormSubmit(FormActionType.SaveCaseReport)}
                          label={'Save'}
                          loading={
                            methods.formState.isSubmitting &&
                            activeButton === FormActionType.SaveCaseReport
                          }
                        />
                      ),
                  ]
                : []
            }
          >
            {open ? (
              <>
                {state?.medicalReviewStatus === MedicalReviewStatusType.Approved ? (
                  <Box mb={2}>
                    <Alert severity="success">
                      {format.formatName(state?.medicalReviewedByUser)} has given clinical approval
                      for the 'Medical Review' of TEM-013 on{' '}
                      {format.formatDate(state?.medicalReviewedAt as any)} at{' '}
                      {format.formatTime(state?.medicalReviewedAt as any)}.
                    </Alert>
                  </Box>
                ) : state?.medicalReviewStatus === MedicalReviewStatusType.Rejected ? (
                  <Box mb={2}>
                    <Alert severity="error">
                      {format.formatName(state?.medicalReviewedByUser)} has rejected the 'Medical
                      Review' of TEM-013 on {format.formatDate(state?.medicalReviewedAt as any)} at{' '}
                      {format.formatTime(state?.medicalReviewedAt as any)}.
                    </Alert>
                  </Box>
                ) : null}

                {state?.status === CaseReportStatusType.Approved ? (
                  <Box mb={2}>
                    <Alert severity="success">
                      {format.formatName(state?.reportReviewedByUser)} has given engineering
                      approval for the TEM-013 on{' '}
                      {format.formatDate(state?.reportReviewedAt as any)} at{' '}
                      {format.formatTime(state?.reportReviewedAt as any)}.
                    </Alert>
                  </Box>
                ) : state?.status === CaseReportStatusType.Rejected ? (
                  <Box mb={2}>
                    <Alert severity="error">
                      {format.formatName(state?.reportReviewedByUser)} has rejected the TEM-013 on{' '}
                      {format.formatDate(state?.reportReviewedAt as any)} at{' '}
                      {format.formatTime(state?.reportReviewedAt as any)}.
                    </Alert>
                  </Box>
                ) : null}

                {canEditCaseReport &&
                  state?.medicalReviewRequestedAt &&
                  ![
                    CaseReportStatusType.InReview,
                    CaseReportStatusType.Rejected,
                    CaseReportStatusType.Approved,
                  ].includes(state?.status) &&
                  (state?.medicalReviewStatus === MedicalReviewStatusType.Overridden ||
                    medicalReviewLock ||
                    medicalReviewLockExpired) && (
                    <Box mb={2}>
                      <Alert severity="warning">
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          width="100%"
                        >
                          <Box flex="1" pr={2}>
                            <Box>
                              The Medical Review status:{' '}
                              <strong>
                                {formatMedicalReviewStatusType(
                                  state?.medicalReviewStatus as MedicalReviewStatusType,
                                )}
                              </strong>
                            </Box>
                            {medicalReviewLock && overrideMedicalReviewEnabled && (
                              <Box mt={2}>
                                The Medical Review process started at{' '}
                                {format.formatDateTime(state.medicalReviewRequestedAt)} and will
                                expire at{' '}
                                {format.formatDateTime(
                                  getMedicalReviewExpiryTime(state.medicalReviewRequestedAt),
                                )}
                                . If urgent access is needed, you can override it by clicking the
                                'Override Medical Review' button.
                              </Box>
                            )}
                            {medicalReviewLockExpired && overrideMedicalReviewEnabled && (
                              <Box mt={2}>
                                The Medical Review was requested at{' '}
                                {format.formatDateTime(state.medicalReviewRequestedAt)} and expired
                                at{' '}
                                {format.formatDateTime(
                                  getMedicalReviewExpiryTime(state.medicalReviewRequestedAt),
                                )}
                                . Click the 'Override Medical Review' button to continue..
                              </Box>
                            )}
                          </Box>
                          {canEditCaseReport && overrideMedicalReviewEnabled ? (
                            <Box
                              display="flex"
                              justifyContent="flex-end"
                              alignItems="center"
                              width="auto"
                            >
                              <ProgressButton
                                variant="contained"
                                disabled={methods.formState.isSubmitting}
                                onClick={handleOverrideMedicalReview}
                                label="Override Medical Review"
                                loading={activeButton === FormActionType.OverrideMedicalReview}
                              />
                            </Box>
                          ) : null}
                        </Box>
                      </Alert>
                    </Box>
                  )}
                <CaseReportForm
                  activeCase={activeCase}
                  state={state}
                  surgeonUser={surgeonUser}
                  disabled={
                    methods.formState.isSubmitting ||
                    uploadingImage ||
                    medicalReviewLock ||
                    isMedicalReviewFormReadyToReview ||
                    isCaseReportReadyToReview ||
                    !canEditAndApproveCaseReport ||
                    [CaseReportStatusType.Approved, CaseReportStatusType.InReview].includes(
                      state?.status,
                    )
                  }
                  errors={Errors}
                  loading={methods.formState.isSubmitting}
                  Users={Users}
                  handleFormSubmit={handleFormSubmit}
                  activeButton={activeButton}
                  selfApproveCaseReportEnabled={selfApproveCaseReportEnabled}
                  sendMedicalReviewEnabled={sendMedicalReviewEnabled}
                  sendCaseReportReviewEnabled={sendCaseReportReviewEnabled}
                />
              </>
            ) : null}
            {isMedicalReviewFormReadyToReview && (
              <MedicalReviewForm
                medicalReviewNote={state.medicalReviewNote}
                medicalReviewStatus={state.medicalReviewStatus}
                disabled={state?.status === CaseReportStatusType.Approved}
                onActionSubmit={handleMedicalReviewSubmit}
              />
            )}
            {isCaseReportReadyToReview && (
              <CaseReportReviewForm
                riskAssessment={riskAssessment}
                reportStatus={reportStatus}
                reportReviewNote={state.reportReviewNote}
                disabled={state?.status === CaseReportStatusType.Approved}
                onActionSubmit={handleCaseReportReviewSubmit}
              />
            )}
          </CustomDialog>
        </form>
      </FormProvider>
    </Box>
  ) : null;
}
