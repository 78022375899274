import { useLazyQuery } from '@apollo/client';
import { Alert, Box, Card, CardContent, Grid } from '@mui/material';
import { ICase } from '@workflow-nx/common';
import { useEffect, useReducer } from 'react';
import { Heading } from '../../../../components/Heading';
import { FIND_ACTIVITIES } from '../../../../gql';
import { ActivityListTable } from './ActivityListTable';
import { ActivityTabReducer, ActivityTabStateType } from './ActivityTab.reducer';
import { ActivityTableFilter } from './ActivityTableFilter';

type ActivityTabViewProps = {
  activeCase: ICase;
};

export function ActivityTabView({ activeCase }: ActivityTabViewProps) {
  const [findActivities, { loading: loadingActivities, data, refetch }] = useLazyQuery(
    FIND_ACTIVITIES,
    {
      variables: {
        caseId: activeCase.caseId,
        activityTypeFilter: undefined,
        originTypeFilter: undefined,
        orderBy: { createdAt: 'desc' },
      },
    },
  );

  const initialState: ActivityTabStateType = {
    activityTypeFilter: undefined,
    activityOriginTypeFilter: undefined,
    orderBy: { createdAt: 'desc' },
  };

  const [state, dispatch] = useReducer(ActivityTabReducer(refetch), initialState);

  useEffect(() => {
    if (activeCase?.caseId) {
      findActivities();
    }
  }, [activeCase, findActivities]);

  const currentActivitiesList = data?.activities?.activities || [];

  const currentActivityCount = data?.activities?.count || 0;

  return (
    <Box>
      <Grid container>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Box display={'flex'} alignItems={'center'} mb={1}>
                <Heading label={'Filters'} />
              </Box>
              <Card>
                <CardContent>
                  <ActivityTableFilter
                    state={state}
                    dispatch={dispatch}
                    loading={loadingActivities}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={10}>
              <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mb={1}>
                <Heading label={`Results (${currentActivityCount} activities)`} />
              </Box>
              <Box>
                <Card>
                  <CardContent style={{ height: 'calc(100vh - 228px)' }}>
                    {currentActivitiesList?.length > 0 ? (
                      <ActivityListTable
                        dispatch={dispatch}
                        orderBy={state.orderBy}
                        loading={loadingActivities}
                        activities={currentActivitiesList}
                      />
                    ) : (
                      <Alert severity={'warning'}>No activities match the filter criteria. </Alert>
                    )}
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
