import React, { useEffect, useState } from 'react';
import {
  caseUtils,
  format,
  ICase,
  ICaseImplantSpecification,
  ICaseResult,
  LevelResult,
  LevelSize,
  LevelType,
  PartType,
} from '@workflow-nx/common';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import { ProgressButton, TextField } from '@workflow-nx/ui';
import { UPSERT_CASE_IMPLANT_SPECIFICATION } from '../../../gql';
import * as Yup from 'yup';
import CustomDialog from '../../../components/CustomDialog';
import { NumberTextField } from '../../../components/NumberTextField';
import _ from 'lodash';

function parseNullableNumberString(value: any) {
  return _.isNumber(value) || (!_.isEmpty(value) && !_.isNaN(value)) ? Number(value) : null;
}

const useStyles = makeStyles((theme: Theme) => ({
  disabledRow: {
    backgroundColor: theme.palette.grey['50'],
  },
}));

export function EditImplantAnalysisDialog(props: {
  caseId: number;
  activeCase?: ICase;
  caseImplantSpecifications?: ICaseImplantSpecification[];
  caseResults?: ICaseResult[];
  onClose: (shouldUpdate: boolean) => void;
  open: boolean;
}) {
  const styles = useStyles();
  const [upsertCaseImplantSpecification, { loading }] = useMutation(
    UPSERT_CASE_IMPLANT_SPECIFICATION,
  );

  const { enqueueSnackbar } = useSnackbar();

  const [initialValues, setInitialValues] = useState<{
    caseImplantSpecifications: ICaseImplantSpecification[];
  }>({
    caseImplantSpecifications: [],
  });

  const handleSubmitForm = async (
    values: FormikValues,
    { setErrors, setStatus, setSubmitting, resetForm }: FormikHelpers<any>,
  ) => {
    try {
      await upsertCaseImplantSpecification({
        variables: {
          caseId: props.caseId,
          caseImplantSpecifications: values.caseImplantSpecifications.map(
            (cis: ICaseImplantSpecification) => {
              return {
                level: cis.level,
                partType: cis.partType,
                size: cis.size,
                apDepth: parseNullableNumberString(cis.apDepth),
                mlWidth: parseNullableNumberString(cis.mlWidth),
                maxHeight: parseNullableNumberString(cis.maxHeight),
                bulletHeight: parseNullableNumberString(cis.bulletHeight),
                leftBulletHeight: parseNullableNumberString(cis.leftBulletHeight),
                rightBulletHeight: parseNullableNumberString(cis.rightBulletHeight),
                bulletAngle: parseNullableNumberString(cis.bulletAngle),
                leftBulletAngle: parseNullableNumberString(cis.leftBulletAngle),
                rightBulletAngle: parseNullableNumberString(cis.rightBulletAngle),
                coronalAngle: parseNullableNumberString(cis.coronalAngle),
                lordoticAngle: parseNullableNumberString(cis.lordoticAngle),
                anteriorHeight: parseNullableNumberString(cis.anteriorHeight),
                posteriorHeight: parseNullableNumberString(cis.posteriorHeight),
              };
            },
          ),
        },
      });

      setStatus({ success: true });

      enqueueSnackbar('Implant Analysis Saved', {
        variant: 'success',
      });

      props.onClose(true);
    } catch (err: any) {
      console.error(err);
      setStatus({ success: false });
      setErrors({ submit: err.message });
      enqueueSnackbar('An error occurred saving the implant analysis', {
        variant: 'error',
      });
    } finally {
      setSubmitting(false);
      resetForm();
    }
  };

  function getCaseImplantSpecification(
    levelType: LevelType,
    levelSize: LevelSize,
  ): ICaseImplantSpecification | null {
    let foundCaseImplantSpecification = props.caseImplantSpecifications?.find(function (
      caseImplantSpecification: ICaseImplantSpecification,
    ) {
      return (
        caseImplantSpecification.level === levelType && caseImplantSpecification.size === levelSize
      );
    });
    return foundCaseImplantSpecification ?? null;
  }

  const profileLevelTypes = caseUtils
    .getLevelsSortedByHierarchy(props.activeCase?.spineProfile, 'desc')
    .filter((levelType: LevelType) => {
      const caseResult = props?.caseResults?.find(
        (cr) => cr.level === levelType && cr.result === LevelResult.Used,
      );
      return (
        caseResult && !['NONE', 'FUSED'].includes(props.activeCase?.levels[levelType] ?? 'NONE')
      );
    });

  const getValidationSchema = () => {
    return Yup.array().of(
      Yup.object().shape({
        apDepth: Yup.number().nullable(),
        mlDepth: Yup.number().nullable(),
        maxHeight: Yup.number().nullable(),
        bulletHeight: Yup.number().nullable(),
        bulletAngle: Yup.number().nullable(),
        leftBulletHeight: Yup.number().nullable(),
        leftBulletAngle: Yup.number().nullable(),
        rightBulletHeight: Yup.number().nullable(),
        rightBulletAngle: Yup.number().nullable(),
        coronalAngle: Yup.number().nullable(),
        lordoticAngle: Yup.number().nullable(),
        anteriorHeight: Yup.number().nullable(),
        posteriorHeight: Yup.number().nullable(),
      }),
    );
  };

  useEffect(() => {
    const cis = caseUtils
      .getLevelsSortedByHierarchy(props.activeCase?.spineProfile, 'desc')
      .filter((levelType: LevelType) => {
        const caseResult = props?.caseResults?.find(
          (cr) => cr.level === levelType && cr.result === LevelResult.Used,
        );
        return (
          caseResult && !['NONE', 'FUSED'].includes(props.activeCase?.levels[levelType] ?? 'NONE')
        );
      })
      .map((levelType: LevelType) => {
        const cr = props?.caseResults?.find(
          (cr) => cr.level === levelType && cr.result === LevelResult.Used,
        );

        const cis = props?.caseImplantSpecifications?.find((cis) => {
          return cr?.level === cis.level && cr?.size === cis.size;
        });

        return { ...cis, level: cr?.level, size: cr?.size ?? LevelSize.Normal, partType: cr?.part };
      }) as ICaseImplantSpecification[];

    setInitialValues({
      caseImplantSpecifications: cis,
    });
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={getValidationSchema()}
      enableReinitialize={true}
      onSubmit={handleSubmitForm}
    >
      {({ resetForm, submitForm, isSubmitting }) => {
        return (
          <CustomDialog
            maxWidth={'lg'}
            open={props.open}
            title={`Edit Implant Analysis`}
            onClose={() => {
              resetForm();
              props.onClose(false);
            }}
            positiveActionButtons={[
              <ProgressButton
                variant={'contained'}
                disabled={isSubmitting || loading}
                onClick={() => submitForm()}
                label={'Save'}
                loading={isSubmitting || loading}
              />,
            ]}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant={'body1'} noWrap>
                      <strong>Level</strong>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant={'body1'} noWrap>
                      <strong>Part</strong>
                    </Typography>
                  </TableCell>
                  <TableCell align={'center'}>
                    <Typography variant={'body1'} noWrap>
                      <strong>Size</strong>
                    </Typography>
                  </TableCell>
                  <TableCell align={'center'}>
                    <Typography variant={'body1'} noWrap>
                      <strong>AP</strong>
                    </Typography>
                  </TableCell>
                  <TableCell align={'center'}>
                    <Typography variant={'body1'} noWrap>
                      <strong>ML</strong>
                    </Typography>
                  </TableCell>
                  <TableCell align={'center'}>
                    <Typography variant={'body1'} noWrap>
                      <strong>Max Height.</strong>
                    </Typography>
                  </TableCell>
                  <TableCell align={'center'}>
                    <Typography variant={'body1'}>
                      <strong>Bullet Height</strong>
                    </Typography>
                  </TableCell>
                  <TableCell align={'center'}>
                    <Typography variant={'body1'}>
                      <strong>Bullet Angle</strong>
                    </Typography>
                  </TableCell>
                  <TableCell align={'center'}>
                    <Typography variant={'body1'}>
                      <strong>Coronal Angle</strong>
                    </Typography>
                  </TableCell>
                  <TableCell align={'center'}>
                    <Typography variant={'body1'}>
                      <strong>Lordotic Angle</strong>
                    </Typography>
                  </TableCell>
                  <TableCell align={'center'}>
                    <Typography variant={'body1'}>
                      <strong>Anterior Height</strong>
                    </Typography>
                  </TableCell>
                  <TableCell align={'center'}>
                    <Typography variant={'body1'}>
                      <strong>Posterior Height</strong>
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {profileLevelTypes.map((level, index) => {
                  const caseResult = props?.caseResults?.find((caseResult: ICaseResult) => {
                    return caseResult.level === level;
                  });
                  const caseImplantSpecification = caseResult
                    ? getCaseImplantSpecification(caseResult.level, caseResult.size)
                    : null;
                  const canEdit = caseResult && !caseImplantSpecification?.cloudDesignQueueId;

                  return (
                    <TableRow
                      className={
                        !Object.values(PartType).find(
                          (part) => part === props?.activeCase?.levels[level],
                        )
                          ? styles.disabledRow
                          : undefined
                      }
                    >
                      <TableCell>
                        {format.formatLevelType(caseResult?.level as LevelType)}
                      </TableCell>
                      <TableCell>
                        {format.formatPartType(caseResult?.part as PartType)}
                        <TextField
                          name={`caseImplantSpecifications.[${index}].level`}
                          hidden={true}
                        />
                        <TextField
                          name={`caseImplantSpecifications.[${index}].size`}
                          hidden={true}
                        />
                        <TextField
                          name={`caseImplantSpecifications.[${index}].partType`}
                          hidden={true}
                        />
                      </TableCell>
                      <TableCell align={'center'}>{caseResult?.size ?? <>&mdash;</>}</TableCell>
                      <TableCell align={'center'}>
                        {canEdit ? (
                          <NumberTextField
                            decimalPlaces={2}
                            name={`caseImplantSpecifications.[${index}].apDepth`}
                            disabled={!!caseImplantSpecification?.cloudDesignQueueId}
                            shrink={true}
                            suffix={'mm'}
                          />
                        ) : (
                          <>&mdash;</>
                        )}
                      </TableCell>
                      <TableCell align={'center'}>
                        {canEdit ? (
                          <NumberTextField
                            decimalPlaces={2}
                            name={`caseImplantSpecifications.[${index}].mlWidth`}
                            disabled={!!caseImplantSpecification?.cloudDesignQueueId}
                            shrink={true}
                            suffix={'mm'}
                          />
                        ) : (
                          <>&mdash;</>
                        )}
                      </TableCell>
                      <TableCell align={'center'}>
                        {canEdit ? (
                          <NumberTextField
                            decimalPlaces={2}
                            name={`caseImplantSpecifications.[${index}].maxHeight`}
                            disabled={!!caseImplantSpecification?.cloudDesignQueueId}
                            shrink={true}
                            suffix={'mm'}
                          />
                        ) : (
                          <>&mdash;</>
                        )}
                      </TableCell>
                      <TableCell align={'center'}>
                        {caseResult?.part === PartType.ALIF ? (
                          <Box>
                            {canEdit ? (
                              <NumberTextField
                                decimalPlaces={2}
                                name={`caseImplantSpecifications.[${index}].bulletHeight`}
                                disabled={!!caseImplantSpecification?.cloudDesignQueueId}
                                shrink={true}
                                suffix={'mm'}
                              />
                            ) : (
                              <>&mdash;</>
                            )}
                          </Box>
                        ) : null}
                        {caseResult?.part !== PartType.ALIF ? (
                          <Box display={'flex'} alignItems={'center'} mb={1}>
                            {canEdit ? (
                              <NumberTextField
                                decimalPlaces={2}
                                name={`caseImplantSpecifications.[${index}].leftBulletHeight`}
                                disabled={!!caseImplantSpecification?.cloudDesignQueueId}
                                shrink={true}
                                suffix={'mm'}
                              />
                            ) : (
                              <>&mdash;</>
                            )}
                            <Box ml={1}>(L)</Box>
                          </Box>
                        ) : null}
                        {![
                          PartType.ALIF,
                          PartType.ALIF_X_TWO_UP,
                          PartType.ALIF_X_TWO_DOWN,
                        ].includes(caseResult?.part as PartType) ? (
                          <Box display={'flex'} alignItems={'center'}>
                            {canEdit ? (
                              <NumberTextField
                                decimalPlaces={2}
                                name={`caseImplantSpecifications.[${index}].rightBulletHeight`}
                                disabled={!!caseImplantSpecification?.cloudDesignQueueId}
                                shrink={true}
                                suffix={'mm'}
                              />
                            ) : (
                              <>&mdash;</>
                            )}
                            <Box ml={1}>(R)</Box>
                          </Box>
                        ) : null}
                      </TableCell>
                      <TableCell align={'center'}>
                        {![
                          PartType.ALIF,
                          PartType.ALIF_X_TWO_UP,
                          PartType.ALIF_X_TWO_DOWN,
                        ].includes(caseResult?.part as PartType) ? (
                          <Box>
                            {canEdit ? (
                              <NumberTextField
                                decimalPlaces={2}
                                name={`caseImplantSpecifications.[${index}].bulletAngle`}
                                disabled={!!caseImplantSpecification?.cloudDesignQueueId}
                                shrink={true}
                                suffix={'°'}
                              />
                            ) : (
                              <>&mdash;</>
                            )}
                          </Box>
                        ) : null}
                        {caseResult?.part !== PartType.ALIF ? (
                          <Box display={'flex'} alignItems={'center'} mb={1}>
                            {canEdit ? (
                              <NumberTextField
                                decimalPlaces={2}
                                name={`caseImplantSpecifications.[${index}].leftBulletAngle`}
                                disabled={!!caseImplantSpecification?.cloudDesignQueueId}
                                shrink={true}
                                suffix={'°'}
                              />
                            ) : (
                              <>&mdash;</>
                            )}
                            <Box ml={1}>(L)</Box>
                          </Box>
                        ) : null}
                        {caseResult?.part !== PartType.ALIF ? (
                          <Box display={'flex'} alignItems={'center'}>
                            {canEdit ? (
                              <NumberTextField
                                decimalPlaces={2}
                                name={`caseImplantSpecifications.[${index}].rightBulletAngle`}
                                disabled={!!caseImplantSpecification?.cloudDesignQueueId}
                                shrink={true}
                                suffix={'°'}
                              />
                            ) : (
                              <>&mdash;</>
                            )}
                            <Box ml={1}>(R)</Box>
                          </Box>
                        ) : null}
                      </TableCell>
                      <TableCell align={'center'}>
                        {canEdit ? (
                          <NumberTextField
                            decimalPlaces={2}
                            name={`caseImplantSpecifications.[${index}].coronalAngle`}
                            disabled={!!caseImplantSpecification?.cloudDesignQueueId}
                            shrink={true}
                            suffix={'°'}
                          />
                        ) : (
                          <>&mdash;</>
                        )}
                      </TableCell>
                      <TableCell align={'center'}>
                        {canEdit ? (
                          <NumberTextField
                            decimalPlaces={2}
                            name={`caseImplantSpecifications.[${index}].lordoticAngle`}
                            disabled={!!caseImplantSpecification?.cloudDesignQueueId}
                            shrink={true}
                            suffix={'°'}
                          />
                        ) : (
                          <>&mdash;</>
                        )}
                      </TableCell>
                      <TableCell align={'center'}>
                        {canEdit ? (
                          <NumberTextField
                            decimalPlaces={2}
                            name={`caseImplantSpecifications.[${index}].anteriorHeight`}
                            disabled={false}
                            shrink={true}
                            suffix={'mm'}
                          />
                        ) : (
                          <>&mdash;</>
                        )}
                      </TableCell>
                      <TableCell align={'center'}>
                        {canEdit ? (
                          <NumberTextField
                            decimalPlaces={2}
                            name={`caseImplantSpecifications.[${index}].posteriorHeight`}
                            disabled={false}
                            shrink={true}
                            suffix={'mm'}
                          />
                        ) : (
                          <>&mdash;</>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </CustomDialog>
        );
      }}
    </Formik>
  );
}
