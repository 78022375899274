import { IComment } from '@workflow-nx/common';
import useAuth from '../../../../hooks/useAuth';
import { Box } from '@mui/material';
import { CommentView } from './CommentView';

export function DiscussionListItem(props: {
  comment: IComment;
  disabled?: boolean;
  isPatientView?: boolean;
  onPinClicked?: () => void;
  onDeleteClicked?: () => void;
  onEditClicked?: () => void;
}) {
  const auth = useAuth();
  const isCommentByAuthUser = auth?.user?.userId === props.comment?.createdByUser?.userId;

  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      mb={1}
      style={{ opacity: props.disabled ? 0.25 : 1 }}
    >
      <CommentView
        onPinClicked={props.onPinClicked}
        onDeleteClicked={props.onDeleteClicked}
        onEditClicked={props.onEditClicked}
        selected={isCommentByAuthUser}
        comment={props.comment}
        disabled={props.disabled}
        isPatientView={props.isPatientView}
      />
    </Box>
  );
}
