import { useLazyQuery } from '@apollo/client';
import {
  ActivityType,
  CaseStageType,
  CaseType,
  dataExport,
  format,
  IExportCase,
  IPriceLevel,
  LevelType,
  PriceLevelType,
  UserRoleType,
} from '@workflow-nx/common';
import ActionButton from '../../../components/ActionButton';
import config from '../../../extras/config';
import { FIND_REPORT_CASES_EXPORT } from '../../../gql';

const allLevels = [
  LevelType.L1L2,
  LevelType.L2L3,
  LevelType.L3L4,
  LevelType.L4L5,
  LevelType.L5S1,
  LevelType.L5L6,
  LevelType.L6S1,
  LevelType.L4S1,
];

const CASES_HEADER_LABEL = {
  CaseNumber: 'Case Number',
  CaseType: 'Case Type',
  CaseLink: 'Link',
  CaseReceivedAt: 'Received At',
  CaseDeliveredOn: 'Case Delivered On',
  SurgeryDate: 'Surgery Date',
  TentativeSurgeryDate: 'Tentative Surgery Date',
  Surgeon: 'Surgeon',
  Institution: 'Institution',
  Region: 'Region',
  Territory: 'Territory',
  MDM: `${format.formatUserRole(UserRoleType.FieldRep)}`,
  Assigned: 'Assigned',
  Stage: 'Stage',
  PatientGender: 'Patient Gender',
  PatientBirthdate: 'Patient Birthdate',
  NumLevelsPlanned: '# of Levels Planned',
  NumLevelsCompleted: '# of Levels Completed',
  NumLevelsBilled: '# of Levels Billed',
  ProjectedRevenue: 'Projected Revenue',
  ActualRevenue: 'Actual Revenue',
  PriceLevelType: 'Price Level Type',
  [LevelType.L1L2]: format.formatLevelType(LevelType.L1L2),
  [LevelType.L2L3]: format.formatLevelType(LevelType.L2L3),
  [LevelType.L3L4]: format.formatLevelType(LevelType.L3L4),
  [LevelType.L4L5]: format.formatLevelType(LevelType.L4L5),
  [LevelType.L5S1]: format.formatLevelType(LevelType.L5S1),
  [LevelType.L4S1]: format.formatLevelType(LevelType.L4S1),
  [LevelType.L5L6]: format.formatLevelType(LevelType.L5L6),
  [LevelType.L6S1]: format.formatLevelType(LevelType.L6S1),
  PlanRevisions: 'Plan Revisions',
  PlanFirstCompletedAt: 'Plan First Completed At',
  PlanCompletedAt: 'Plan Completed At',
  PlanApprovedAt: 'Plan Approved At',
  DicomCTReceivedAt: 'DICOM CT Received At',
  DicomXRayReceivedAt: 'DICOM XR Received At',
  DicomCTFirstReceivedAt: 'DICOM CT First Received At',
  DicomXRayFirstReceivedAt: 'DICOM XR First Received At',
  NearestExpiringAsset: 'Nearest Expiring Asset',
  NearestExpiringAssetStudyDate: 'Nearest Expiring Asset Study Date',
  NearestExpiringAssetDate: 'Nearest Expiring Asset Date',
  EventType: 'Event Type',
  EventOccurredAt: 'Event Occurred At',
  EventNote: 'Event Note',
  LostOpportunityDate: 'Lost Opportunity Date',
  LostOpportunityType: 'Lost Opportunity Type',
  LostOpportunityReason: 'Lost Opportunity Reason',
  LostOpportunityNote: 'Lost Opportunity Note',
  PinnedCommentDate: 'Pinned Comment Date',
  PinnedComment: 'Pinned Comment',
};

const CASES_HEADERS = [
  { value: CASES_HEADER_LABEL.CaseNumber, width: 15 },
  { value: CASES_HEADER_LABEL.CaseType, width: 15 },
  { value: CASES_HEADER_LABEL.CaseLink, width: 10 },
  { value: CASES_HEADER_LABEL.CaseReceivedAt, width: 10 },
  { value: CASES_HEADER_LABEL.CaseDeliveredOn, width: 10 },
  { value: CASES_HEADER_LABEL.SurgeryDate, width: 10 },
  { value: CASES_HEADER_LABEL.TentativeSurgeryDate, width: 10 },
  { value: CASES_HEADER_LABEL.Surgeon, width: 15 },
  { value: CASES_HEADER_LABEL.Institution, width: 15 },
  { value: CASES_HEADER_LABEL.Region, width: 15 },
  { value: CASES_HEADER_LABEL.Territory, width: 15 },
  { value: CASES_HEADER_LABEL.MDM, width: 15 },
  { value: CASES_HEADER_LABEL.Assigned, width: 15 },
  { value: CASES_HEADER_LABEL.Stage, width: 15 },
  { value: CASES_HEADER_LABEL.PatientGender, width: 15 },
  { value: CASES_HEADER_LABEL.PatientBirthdate, width: 15 },
  { value: CASES_HEADER_LABEL.NumLevelsPlanned, width: 12 },
  { value: CASES_HEADER_LABEL.NumLevelsCompleted, width: 12 },
  { value: CASES_HEADER_LABEL.NumLevelsBilled, width: 12 },
  { value: CASES_HEADER_LABEL.ProjectedRevenue, width: 12 },
  { value: CASES_HEADER_LABEL.ActualRevenue, width: 12 },
  { value: CASES_HEADER_LABEL.PriceLevelType, width: 12 },
  ...allLevels.map((level) => ({
    value: format.formatLevelType(level),
    width: 12,
  })),
  { value: CASES_HEADER_LABEL.PlanRevisions, width: 15 },
  { value: CASES_HEADER_LABEL.PlanFirstCompletedAt, width: 15 },
  { value: CASES_HEADER_LABEL.PlanCompletedAt, width: 15 },
  { value: CASES_HEADER_LABEL.PlanApprovedAt, width: 15 },
  { value: CASES_HEADER_LABEL.DicomCTReceivedAt, width: 15 },
  { value: CASES_HEADER_LABEL.DicomXRayReceivedAt, width: 15 },
  { value: CASES_HEADER_LABEL.DicomCTFirstReceivedAt, width: 25 },
  { value: CASES_HEADER_LABEL.DicomXRayFirstReceivedAt, width: 25 },
  { value: CASES_HEADER_LABEL.NearestExpiringAsset, width: 15 },
  { value: CASES_HEADER_LABEL.NearestExpiringAssetStudyDate, width: 8 },
  { value: CASES_HEADER_LABEL.NearestExpiringAssetDate, width: 8 },
  { value: CASES_HEADER_LABEL.EventType, width: 15 },
  { value: CASES_HEADER_LABEL.EventOccurredAt, width: 8 },
  { value: CASES_HEADER_LABEL.EventNote, width: 50 },
  { value: CASES_HEADER_LABEL.LostOpportunityDate, width: 8 },
  { value: CASES_HEADER_LABEL.LostOpportunityType, width: 15 },
  { value: CASES_HEADER_LABEL.LostOpportunityReason, width: 15 },
  { value: CASES_HEADER_LABEL.LostOpportunityNote, width: 50 },
  { value: CASES_HEADER_LABEL.PinnedCommentDate, width: 8 },
  { value: CASES_HEADER_LABEL.PinnedComment, width: 50 },
];

function getActualAndProjectedCaseRevenue(
  matchingPriceLevel: IPriceLevel,
  billableCaseLevelCount: number,
  plannedLevelCount: number,
) {
  let actualRevenue: number | undefined = undefined;
  let projectedRevenue: number | undefined = undefined;

  switch (matchingPriceLevel.priceLevelType) {
    case PriceLevelType.Fixed:
      actualRevenue = billableCaseLevelCount > 0 ? matchingPriceLevel.oneLevel : undefined;
      projectedRevenue = matchingPriceLevel.oneLevel;
      break;
    case PriceLevelType.PerImplant:
    case PriceLevelType.BasePlusLevel:
      if (billableCaseLevelCount > 0) {
        actualRevenue = matchingPriceLevel.oneLevel;

        if (billableCaseLevelCount > 1) {
          actualRevenue = actualRevenue + matchingPriceLevel.twoLevel;
        }
        if (billableCaseLevelCount > 2) {
          actualRevenue = actualRevenue + matchingPriceLevel.threeLevel;
        }
        if (billableCaseLevelCount > 3) {
          actualRevenue = actualRevenue + matchingPriceLevel.fourLevel;
        }
        if (billableCaseLevelCount > 4) {
          actualRevenue = actualRevenue + matchingPriceLevel.fiveLevel;
        }
      }

      projectedRevenue = matchingPriceLevel.oneLevel;
      if (plannedLevelCount > 1) {
        projectedRevenue = projectedRevenue + matchingPriceLevel.twoLevel;
      }
      if (plannedLevelCount > 2) {
        projectedRevenue = projectedRevenue + matchingPriceLevel.threeLevel;
      }
      if (plannedLevelCount > 3) {
        projectedRevenue = projectedRevenue + matchingPriceLevel.fourLevel;
      }
      if (plannedLevelCount > 4) {
        projectedRevenue = projectedRevenue + matchingPriceLevel.fiveLevel;
      }
      break;
  }
  return { actualRevenue, projectedRevenue };
}

export function ExportCasesButton() {
  const [lazyFindReportCasesExport, { loading: lazyLoadingFindReportCasesExport }] = useLazyQuery(
    FIND_REPORT_CASES_EXPORT,
    {
      variables: {
        assignedToFilter: [] as number[],
        surgeonFilter: [] as number[],
        fieldRepFilter: [],
        caseTypeFilter: [CaseType.Live, CaseType.Draft],
        stageFilter: [
          CaseStageType.Open,
          CaseStageType.Segmentation,
          CaseStageType.Planning,
          CaseStageType.Proposed,
          CaseStageType.Design,
          CaseStageType.QaReview,
          CaseStageType.Production,
          CaseStageType.Ready,
          CaseStageType.Complete,
        ],
        activityTypeFilter: [
          ActivityType.CaseStageProposedComplete,
          ActivityType.CaseStagePlanningComplete,
        ],
        orderBy: { surgeryDate: 'asc' },
        search: '',
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
        const xlsData: any[] = [];

        data.reportCasesExport.forEach((exportCase: IExportCase) => {
          const pinnedComment = exportCase?.pinnedComment;
          const lastEvent = exportCase?.lastEvent;
          const hospitalDeliveryDate = exportCase.hospitalDeliveryDate;
          const ctReceivedDate = exportCase?.dicomCtAsset?.createdAt;
          const xrReceivedDate = exportCase?.dicomXrayAsset?.createdAt;
          const ctFirstReceivedDate = exportCase?.oldestDicomCtAsset?.createdAt;
          const xrFirstReceivedDate = exportCase?.oldestDicomXrayAsset?.createdAt;
          const planningFirstCompleteDate = exportCase.planFirstCompletedAt;
          const planningCompleteDate = exportCase.planCompletedAt;
          const planningApprovedDate = exportCase.planApprovedAt;
          const plannedLevelCount = exportCase.levelsPlanned;
          const completedCaseLevelCount = exportCase.levelsCompleted;
          const billableCaseLevelCount = exportCase.levelsBilled;

          let actualRevenue = undefined;
          let projectedRevenue = undefined;
          let matchingPriceLevel = exportCase?.priceLevel;

          if (matchingPriceLevel) {
            const result = getActualAndProjectedCaseRevenue(
              matchingPriceLevel,
              billableCaseLevelCount,
              plannedLevelCount,
            );
            actualRevenue = result.actualRevenue;
            projectedRevenue = result.projectedRevenue;
          }

          const caseCancellation = exportCase?.caseCancellation;

          xlsData.push({
            [CASES_HEADER_LABEL.CaseNumber]: {
              v: exportCase.number,
            },
            [CASES_HEADER_LABEL.CaseType]: {
              v: format.formatCaseType(exportCase.caseType),
            },
            [CASES_HEADER_LABEL.CaseLink]: {
              l: {
                Target: `${config.client.url}/app/cases/${exportCase.caseId}`,
              },
              v: 'View Case',
            },
            [CASES_HEADER_LABEL.CaseReceivedAt]: {
              t: 'd',
              v: exportCase.receivedAt
                ? format.formatISODate(exportCase.receivedAt as string, 'MM/dd/yyyy')
                : undefined,
            },
            [CASES_HEADER_LABEL.CaseDeliveredOn]: {
              t: 'd',
              v: hospitalDeliveryDate
                ? format.formatISODate(new Date(hospitalDeliveryDate).toISOString(), 'MM/dd/yyyy')
                : undefined,
            },
            [CASES_HEADER_LABEL.SurgeryDate]: {
              t: 'd',
              v:
                exportCase.surgeryDate && !exportCase.isSurgeryDateTentative
                  ? format.formatISODate(exportCase.surgeryDate as string, 'MM/dd/yyyy')
                  : undefined,
            },
            [CASES_HEADER_LABEL.TentativeSurgeryDate]: {
              t: 'd',
              v:
                exportCase.surgeryDate && exportCase.isSurgeryDateTentative
                  ? format.formatISODate(exportCase.surgeryDate as string, 'MM/dd/yyyy')
                  : undefined,
            },
            [CASES_HEADER_LABEL.Surgeon]: {
              t: 's',
              v: exportCase.surgeonUser ? format.formatName(exportCase.surgeonUser) : '',
            },
            [CASES_HEADER_LABEL.Institution]: {
              t: 's',
              v: exportCase?.organization?.name ?? '',
            },
            [CASES_HEADER_LABEL.Region]: {
              t: 's',
              v: exportCase?.regionTerritory?.region ?? '',
            },
            [CASES_HEADER_LABEL.Territory]: {
              t: 's',
              v: exportCase?.regionTerritory?.territory ?? '',
            },
            [CASES_HEADER_LABEL.MDM]: {
              t: 's',
              v: exportCase.fieldRepUser ? format.formatName(exportCase.fieldRepUser) : '',
            },
            [CASES_HEADER_LABEL.Assigned]: {
              t: 's',
              v: exportCase.assignedUser ? format.formatName(exportCase.assignedUser) : '',
            },
            [CASES_HEADER_LABEL.Stage]: {
              t: 's',
              v: exportCase.stage,
            },
            [CASES_HEADER_LABEL.PatientGender]: {
              t: 's',
              v: exportCase?.patient?.gender ?? undefined,
            },
            [CASES_HEADER_LABEL.PatientBirthdate]: {
              t: 'd',
              v: exportCase?.patient?.birthDate
                ? format.formatISODate(exportCase?.patient?.birthDate as string, 'MM/dd/yyyy')
                : undefined,
            },
            [CASES_HEADER_LABEL.NumLevelsPlanned]: {
              t: 'n',
              v: plannedLevelCount,
            },
            [CASES_HEADER_LABEL.NumLevelsCompleted]: {
              t: 'n',
              v: completedCaseLevelCount,
            },
            [CASES_HEADER_LABEL.NumLevelsBilled]: {
              t: 'n',
              v: billableCaseLevelCount,
            },
            [CASES_HEADER_LABEL.ProjectedRevenue]: {
              t: 'n',
              v: projectedRevenue,
            },
            [CASES_HEADER_LABEL.ActualRevenue]: {
              t: 'n',
              v: actualRevenue,
            },

            [CASES_HEADER_LABEL.PriceLevelType]: {
              t: 's',
              v: matchingPriceLevel?.priceLevelType ?? undefined,
            },
            ...allLevels.reduce(
              (prev, currLevel) => ({
                ...prev,
                [format.formatLevelType(currLevel)]: {
                  t: 's',
                  v: exportCase.levels[currLevel] ?? 'NONE',
                },
              }),
              {},
            ),
            [CASES_HEADER_LABEL.PlanRevisions]: {
              t: 'n',
              v: exportCase.planRevisions,
            },
            [CASES_HEADER_LABEL.PlanFirstCompletedAt]: {
              t: 'd',
              v: planningFirstCompleteDate
                ? format.formatISODate(
                    new Date(planningFirstCompleteDate).toISOString(),
                    'MM/dd/yyyy',
                  )
                : undefined,
            },
            [CASES_HEADER_LABEL.PlanCompletedAt]: {
              t: 'd',
              v: planningCompleteDate
                ? format.formatISODate(new Date(planningCompleteDate).toISOString(), 'MM/dd/yyyy')
                : undefined,
            },
            [CASES_HEADER_LABEL.PlanApprovedAt]: {
              t: 'd',
              v: planningApprovedDate
                ? format.formatISODate(new Date(planningApprovedDate).toISOString(), 'MM/dd/yyyy')
                : undefined,
            },
            [CASES_HEADER_LABEL.DicomCTReceivedAt]: {
              t: 'd',
              v: ctReceivedDate
                ? format.formatISODate(new Date(ctReceivedDate).toISOString(), 'MM/dd/yyyy')
                : undefined,
            },
            [CASES_HEADER_LABEL.DicomXRayReceivedAt]: {
              t: 'd',
              v: xrReceivedDate
                ? format.formatISODate(new Date(xrReceivedDate).toISOString(), 'MM/dd/yyyy')
                : undefined,
            },
            [CASES_HEADER_LABEL.DicomCTFirstReceivedAt]: {
              t: 'd',
              v: ctFirstReceivedDate
                ? format.formatISODate(new Date(ctFirstReceivedDate).toISOString(), 'MM/dd/yyyy')
                : undefined,
            },
            [CASES_HEADER_LABEL.DicomXRayFirstReceivedAt]: {
              t: 'd',
              v: xrFirstReceivedDate
                ? format.formatISODate(new Date(xrFirstReceivedDate).toISOString(), 'MM/dd/yyyy')
                : undefined,
            },
            [CASES_HEADER_LABEL.NearestExpiringAsset]: {
              t: 's',
              v: exportCase?.nearestExpiryAsset?.assetType ?? undefined,
            },
            [CASES_HEADER_LABEL.NearestExpiringAssetStudyDate]: {
              t: 'd',
              v: exportCase?.nearestExpiryAsset?.studyDate
                ? format.formatISODate(
                    exportCase?.nearestExpiryAsset?.studyDate as string,
                    'MM/dd/yyyy',
                  )
                : undefined,
            },
            [CASES_HEADER_LABEL.NearestExpiringAssetDate]: {
              t: 'd',
              v: exportCase?.nearestExpiryAsset?.expiryDate
                ? format.formatISODate(
                    exportCase?.nearestExpiryAsset?.expiryDate as string,
                    'MM/dd/yyyy',
                  )
                : undefined,
            },
            [CASES_HEADER_LABEL.EventType]: {
              t: 's',
              v: lastEvent?.eventType,
            },
            [CASES_HEADER_LABEL.EventOccurredAt]: {
              t: 'd',
              v: lastEvent?.occurredAt
                ? format.formatISODate(lastEvent?.occurredAt as string, 'MM/dd/yyyy')
                : undefined,
            },
            [CASES_HEADER_LABEL.EventNote]: {
              t: 's',
              v: lastEvent?.note ?? '',
            },
            [CASES_HEADER_LABEL.LostOpportunityDate]: {
              t: 'd',
              v: caseCancellation?.cancelledAt
                ? format.formatISODate(caseCancellation?.cancelledAt as string, 'MM/dd/yyyy')
                : undefined,
            },
            [CASES_HEADER_LABEL.LostOpportunityType]: {
              t: 's',
              v: caseCancellation?.cancellationType ?? undefined,
            },
            [CASES_HEADER_LABEL.LostOpportunityReason]: {
              t: 's',
              v: caseCancellation?.reason ?? undefined,
            },
            [CASES_HEADER_LABEL.LostOpportunityNote]: {
              t: 's',
              v: caseCancellation?.note ?? undefined,
            },
            [CASES_HEADER_LABEL.PinnedCommentDate]: {
              t: 's',
              v: pinnedComment?.createdAt ?? undefined,
            },
            [CASES_HEADER_LABEL.PinnedComment]: {
              t: 's',
              v: pinnedComment?.comment ?? undefined,
            },
          });
        });
        dataExport.saveXlsxFile(
          `workflow-cases-${format.formatDate(new Date())}.xlsx`,
          'Workflow Cases',
          CASES_HEADERS,
          xlsData,
        );
      },
    },
  );

  return (
    <ActionButton
      variant={'outlined'}
      color={'secondary'}
      loading={lazyLoadingFindReportCasesExport}
      onClick={() => lazyFindReportCasesExport()}
    >
      Export Cases (XLSX)
    </ActionButton>
  );
}
