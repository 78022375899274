import React from 'react';
import { Badge, Box } from '@mui/material';
import { IComment } from '@workflow-nx/common';
import { faComments } from '@fortawesome/pro-light-svg-icons';
import PopupState, { bindHover, bindPopover } from 'material-ui-popup-state';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DiscussionListItem } from '../CaseView/DiscussionTab/DiscussionListItem';

export function CommentsPopoverView(props: { caseId: number; comments: IComment[] }) {
  return props.comments.length > 0 ? (
    <PopupState variant="popover" popupId={`${props.caseId}Popover`}>
      {(popupState) => (
        <div>
          <div {...bindHover(popupState)}>
            <Badge
              badgeContent={props?.comments?.length}
              color={'primary'}
              {...bindHover(popupState)}
            >
              <FontAwesomeIcon size={'lg'} icon={faComments} />
            </Badge>
          </div>
          <HoverPopover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Box p={1}>
              {props.comments.map((comment) => (
                <DiscussionListItem key={comment.commentId} comment={comment} />
              ))}
            </Box>
          </HoverPopover>
        </div>
      )}
    </PopupState>
  ) : (
    <FontAwesomeIcon size={'lg'} icon={faComments} />
  );
}
