import { TextField, Box, Popover } from '@mui/material';
import React from 'react';
import { SketchPicker } from 'react-color';

export const ColorSelectField: React.FC<{
  handleChange: React.ComponentProps<typeof SketchPicker>['onChangeComplete'];
  color?: string;
}> = ({ handleChange, color }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const popoverOpen = Boolean(anchorEl);
  const id = popoverOpen ? 'color-select-field-popover' : undefined;

  return (
    <>
      <TextField
        contentEditable={false}
        InputProps={{
          contentEditable: false,
          startAdornment: (
            <Box
              sx={{
                width: 20,
                height: 20,
                bgcolor: color,
                mr: 1,
                borderRadius: 5,
              }}
            />
          ),
        }}
        aria-describedby={id}
        onClick={handleClick}
        label="Color"
        InputLabelProps={{ shrink: true }}
        fullWidth
        value={color || ''}
      />
      <Popover
        id={id}
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <SketchPicker color={color} onChangeComplete={handleChange} />
      </Popover>
    </>
  );
};
