import React from 'react';
import { Box } from '@mui/material';
import { FieldValues, Control, useForm, SubmitHandler } from 'react-hook-form';
import {
  ConfigurationKeyType,
  ConfigurationValueType,
  IAwsSimpleNotificationServiceConfiguration,
  IConfiguration,
  IConfigurationValueStructure,
  schemas,
} from '@workflow-nx/common';
import { yupResolver } from '@hookform/resolvers/yup';
import { ServicesSectionForm } from './ServicesSectionForm';

export function AwsSimpleNotificationServiceForm(props: {
  configurationData?: IConfigurationValueStructure[];
  handleSubmit: (formData: IConfiguration) => void;
}) {
  const valueFields = [
    { id: 'region', label: 'Region', type: ConfigurationValueType.Text },
    { id: 'username', label: 'Username', type: ConfigurationValueType.Text },
    { id: 'password', label: 'Password', type: ConfigurationValueType.Password },
  ];

  const initialConfigFormData = Object.fromEntries(
    valueFields.map((field) => [
      field.id,
      (props.configurationData?.find((config) => config.key === field.id)?.value?.value as
        | string
        | number) ?? (field.type !== ConfigurationValueType.Number ? '' : 0),
    ]),
  ) as unknown as IAwsSimpleNotificationServiceConfiguration;

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty, dirtyFields },
  } = useForm<IAwsSimpleNotificationServiceConfiguration>({
    defaultValues: initialConfigFormData ?? undefined,
    resolver: yupResolver(schemas.configurationAwsSimpleNotificationServiceSchema),
  });

  const handleSubmitForm: SubmitHandler<IAwsSimpleNotificationServiceConfiguration> = async (
    data,
  ) => {
    const changedValues: IConfigurationValueStructure[] = [];
    const changedFields = props.configurationData ? dirtyFields : data;

    for (const [key] of Object.entries(changedFields)) {
      changedValues.push({
        key,
        value: {
          type: valueFields?.find((field) => field.id === key)?.type ?? ConfigurationValueType.Text,
          value: (data as unknown as Record<string, unknown>)[key],
        },
      });
    }

    props.handleSubmit({
      key: ConfigurationKeyType.AwsSimpleNotificationService,
      value: changedValues,
    });
  };

  return (
    <Box>
      <form>
        <ServicesSectionForm
          title={'AWS Simple Notification Service'}
          fields={valueFields}
          control={control as unknown as Control<FieldValues>}
          submitHandler={(evt) => handleSubmit(handleSubmitForm)(evt)}
          disabled={isSubmitting || !isDirty}
        />
      </form>
    </Box>
  );
}
