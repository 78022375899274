import { faCircleExclamation, faTriangleExclamation } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Stack, Typography } from '@mui/material';
import {
  CaseStageType,
  CaseType,
  caseUtils,
  ICaseCalendarFilteredCases,
} from '@workflow-nx/common';

function getCalendarLabel(isTentative: boolean, caseType: CaseType) {
  let label = '';

  if (isTentative && caseType === CaseType.Draft) {
    label = 'D/T';
  } else if (isTentative && !(caseType === CaseType.Draft)) {
    label = 'T';
  } else if (!isTentative && caseType === CaseType.Draft) {
    label = 'D';
  }

  return label;
}

export function CalendarEventView(props: { event: ICaseCalendarFilteredCases }) {
  const calendarLabel = getCalendarLabel(
    props.event.extendedProps.isSurgeryDateTentative,
    props.event.extendedProps.caseType,
  );

  const isComplete = props.event.extendedProps.stage === CaseStageType.Complete;
  const studyDateExpiryStatus = caseUtils.getStudyDateExpiryStatus(
    props.event.extendedProps.surgeryDate,
    props.event.extendedProps.studyDate?.expiryDate,
  );

  return (
    <Stack
      direction={'row'}
      spacing={0.5}
      alignItems={'center'}
      justifyContent={'center'}
      className={props.event.className as string}
      sx={{
        borderColor: props.event.borderColor,
        borderRadius: '5px',
        backgroundColor: props.event.backgroundColor,
        height: '25px',
        mb: 0.5,
      }}
      title={props.event.title}
    >
      {studyDateExpiryStatus === 'EXPIRED' && !isComplete ? (
        <FontAwesomeIcon icon={faCircleExclamation} fontSize={20} color={'white'} />
      ) : undefined}
      {studyDateExpiryStatus === 'NEAR_EXPIRY' && !isComplete ? (
        <FontAwesomeIcon icon={faTriangleExclamation} fontSize={20} color={'white'} />
      ) : undefined}
      <Typography textAlign={'center'} sx={{ fontSize: '1rem' }} noWrap>
        {props.event.title}
      </Typography>
      <Typography textAlign={'center'} sx={{ fontSize: '1rem' }} noWrap>
        {calendarLabel ? <>&mdash;{calendarLabel}</> : null}
      </Typography>
    </Stack>
  );
}
