import React, { ChangeEvent } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { FormControlLabel, Radio, FormControl } from '@mui/material';
import MuiRadioGroup from '@mui/material/RadioGroup';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  error: {
    color: 'red',
  },
}));

interface RadioGroupRHFProps {
  disabled?: boolean;
  label?: string;
  name: string;
  hideLabel?: boolean;
  row?: boolean;
  options: { key: string | boolean; value: string }[];
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  control: UseControllerProps['control'];
}

const RadioGroupRHF: React.FC<RadioGroupRHFProps> = ({
  disabled,
  label,
  name,
  hideLabel,
  row,
  options,
  onChange,
  control,
}) => {
  const styles = useStyles();
  const {
    field: { value, onChange: hookFormOnChange },
    fieldState: { invalid },
  } = useController({
    name,
    control,
  } as UseControllerProps);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.persist();
    hookFormOnChange(event);
    if (onChange) {
      onChange(event);
    }
  };

  const currentValue = typeof value === 'boolean' ? value.toString() : value;

  return (
    <FormControl fullWidth>
      <MuiRadioGroup name={name} value={currentValue || ''} row={row} onChange={handleChange}>
        {(options || []).map((option, index) => (
          <FormControlLabel
            control={<Radio className={invalid ? styles.error : ''} disabled={disabled} />}
            disabled={disabled}
            key={index}
            label={!hideLabel ? option.value : null}
            value={option.key.toString()}
          />
        ))}
      </MuiRadioGroup>
    </FormControl>
  );
};

export default RadioGroupRHF;
