import { useMutation } from '@apollo/client';
import { faCloudDownload, faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, LinearProgress, Link, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CarrierType, IEvent, format } from '@workflow-nx/common';
import { file } from '@workflow-nx/utils';
import * as FileSaver from 'file-saver';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { FormattedFieldView } from '../../../components/FormattedFieldView';
import { CREATE_ASSET_DOWNLOAD_URL, DELETE_EVENT } from '../../../gql';

const useStyles = makeStyles((theme: Theme) => ({
  gridCell: {
    backgroundColor: 'white',
    height: 50,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    whiteSpace: 'nowrap',
    '&.note': {
      gridColumn: 'span 7',
    },
  },
  gridCellNotes: {
    gridColumn: '1 / span 7',
    backgroundColor: 'white',
    minHeight: 50,
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginTop: '-1px',
  },
}));

export function EventGridTableRow(props: {
  event: IEvent;
  readOnly?: boolean;
  onDelete: () => void;
}) {
  const [deleteEvent, { loading }] = useMutation(DELETE_EVENT);
  const [createAssetDownloadUrl] = useMutation(CREATE_ASSET_DOWNLOAD_URL);
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const [downloading, setDownloading] = useState(false);
  const [downloadPercent, setDownloadPercent] = useState(0);

  const handleDownloadEventAsset = async () => {
    const eventAsset = props.event?.asset;

    if (!eventAsset) return;

    try {
      setDownloading(true);
      const { data } = await createAssetDownloadUrl({
        variables: {
          assetId: eventAsset.assetId,
        },
      });

      const response = await file.downloadFile(data.createAssetDownloadUrl.signedUrl, {
        onDownloadProgress: (percentComplete) => {
          setDownloadPercent(percentComplete);
        },
      });

      FileSaver.saveAs(response.data, eventAsset.fileName);
    } catch (e) {
      enqueueSnackbar('Error Downloading File', {
        variant: 'error',
      });
    } finally {
      setDownloadPercent(0);
      setDownloading(false);
    }
  };

  const handleDeleteEvent = async () => {
    try {
      const formattedEventType = format.formatEventType(props.event.eventType);

      await confirm({
        description: `This will permanently delete the event ${formattedEventType}.`,
      });

      await deleteEvent({
        variables: {
          eventId: props.event.eventId,
        },
      });

      props.onDelete();

      enqueueSnackbar(`Event ${formattedEventType} deleted successfully`, {
        variant: 'success',
      });
    } catch (e) {
      enqueueSnackbar('An error occurred deleting the event', {
        variant: 'error',
      });
      console.error(e);
    }
  };

  return (
    <>
      {downloading && props.event.asset ? (
        <Box gridColumn={'span 6'} className={styles.gridCell}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            height={50}
          >
            <Typography color={'textSecondary'} align={'center'} variant={'h6'}>
              {`Downloading ${props.event.asset?.assetType}`.toUpperCase()}
            </Typography>
            <Box width={'100%'}>
              <LinearProgress color={'secondary'} variant={'determinate'} value={downloadPercent} />
            </Box>
          </Box>
        </Box>
      ) : (
        <>
          <Box className={styles.gridCell}>
            <Box display={'flex'} alignItems={'center'} height={50}>
              <Typography variant={'body1'}>
                {format.formatEventType(props.event.eventType)}
              </Typography>
            </Box>
          </Box>
          <Box className={styles.gridCell}>
            <Box display={'flex'} alignItems={'center'} height={50}>
              <Typography variant={'body1'}>
                {format.formatISODate(props.event.occurredAt as string)}
              </Typography>
            </Box>
          </Box>
          <Box className={styles.gridCell}>
            <Box display={'flex'} alignItems={'center'} height={50}>
              <Typography variant={'body1'}>
                {props.event.description ? props.event.description : <span>&mdash;</span>}
              </Typography>
            </Box>
          </Box>
          <Box className={styles.gridCell}>
            <Box display={'flex'} alignItems={'center'} height={50}>
              <Typography variant={'body1'}>
                {props.event.carrierType ? (
                  <Box display={'flex'} alignItems={'center'}>
                    {format.formatCarrierType(props.event.carrierType)}
                    <Box ml={1} />
                    {props.event.carrierType !== CarrierType.Other ? (
                      <Link
                        target="_blank"
                        rel="noopener"
                        href={format.formatCarrierTrackingUrl(
                          props.event.carrierType,
                          props.event.trackingNumber ?? '',
                        )}
                      >
                        {props.event.trackingNumber}
                      </Link>
                    ) : null}
                  </Box>
                ) : (
                  <span>&mdash;</span>
                )}
              </Typography>
            </Box>
          </Box>
          <Box className={styles.gridCell}>
            <Box display={'flex'} alignItems={'center'} height={50}>
              <Typography variant={'body1'}>
                {props.event.purchaseOrderNumber ? (
                  props.event.purchaseOrderNumber
                ) : (
                  <span>&mdash;</span>
                )}
              </Typography>
            </Box>
          </Box>
          <Box className={styles.gridCell}>
            <Box display={'flex'} alignItems={'center'} height={50}>
              <Typography variant={'body1'}>
                {props.event.dueDate ? (
                  format.formatISODate(props.event.dueDate as string)
                ) : (
                  <span>&mdash;</span>
                )}
              </Typography>
            </Box>
          </Box>

          <Box className={styles.gridCell}>
            <Box display={'flex'} alignItems={'center'} height={50}>
              {props.event.assetId ? (
                <IconButton disabled={loading} onClick={handleDownloadEventAsset} size="large">
                  <FontAwesomeIcon icon={faCloudDownload} />
                </IconButton>
              ) : null}

              <IconButton
                disabled={loading || props.readOnly}
                onClick={handleDeleteEvent}
                size="large"
              >
                <FontAwesomeIcon icon={faTrash} />
              </IconButton>
            </Box>
          </Box>
          {props.event.note ? (
            <Box className={styles.gridCellNotes}>
              <FormattedFieldView
                label={'Event Notes'}
                value={
                  <Typography variant={'caption'} whiteSpace={'pre-wrap'}>
                    {format.formatSanitizeAndParseHtml(props.event.note as string)}
                  </Typography>
                }
              />
            </Box>
          ) : null}
        </>
      )}
    </>
  );
}
