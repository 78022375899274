import { TextField } from '@mui/material';
import { forwardRef, useState } from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

type InlineNummericTextFieldProps = {
  onChange(value: number): void;
  value: number;
  label: string;
};

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumericFormatCustom = forwardRef<NumberFormatProps, CustomProps>(function NumericFormatCustom(
  props,
  ref,
) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
});

const InlineNummericTextField = ({ onChange, value, label }: InlineNummericTextFieldProps) => {
  const [focused, setFocused] = useState(false);

  return (
    <TextField
      onChange={(v) => {
        if (!focused) return;

        onChange(parseFloat(v.target.value));
      }}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      value={value}
      name="numberformat"
      label={label}
      size="small"
      InputProps={{
        inputComponent: NumericFormatCustom as any,
      }}
      variant="outlined"
    />
  );
};

export default InlineNummericTextField;
