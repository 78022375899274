import React, { useState } from 'react';
import { ICase, PlanStatusType, AssetType, IAsset } from '@workflow-nx/common';
import ActionButton from '../../../components/ActionButton';
import { useQuery } from '@apollo/client';
import { FIND_ASSETS_WITH_URL } from '../../../gql';
import { useSnackbar } from 'notistack';
import { pdf } from '@workflow-nx/utils';
import * as FileSaver from 'file-saver';

export function ExportForm19KitBomButton(props: {
  activeCase: ICase;
  inserterSku?: string;
  loading?: boolean;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const plans = props.activeCase?.plans || [];
  const plan = plans.find((element) => element.status === PlanStatusType.Approved);
  const [form19Data, setForm19Data] = useState<IAsset | null>(null);
  const [assetDownloading, setAssetDownloading] = useState(false);

  const { loading: loadingAsset } = useQuery(FIND_ASSETS_WITH_URL, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      caseId: props.activeCase.caseId,
      planId: plan?.planId,
      assetTypeFilter: [AssetType.Form19],
      showDeleted: false,
      ignorePlanFilter: plan?.planId ? true : false,
    },
    onCompleted: (data) => {
      const assets = data?.assets;
      if (assets) {
        setForm19Data(assets.find((asset: IAsset) => asset.assetType === AssetType.Form19));
      }
    },
  });

  const handleClick = async () => {
    try {
      setAssetDownloading(true);
      if (!form19Data?.signedDownloadUrl) {
        throw new Error('Unable to locate signed download URL for FORM-019');
      }
      const downloadString = await pdf.extractLastPageData(form19Data?.signedDownloadUrl);

      FileSaver.saveAs(
        downloadString,
        `${props?.activeCase?.number ?? 'Unknown'}-Form019-KitBOM.pdf`,
      );
    } catch (e) {
      enqueueSnackbar('Error downloading Form-019 Kit BOM', {
        variant: 'error',
      });
      console.error(`Error downloading Form-019 Kit BOM - ${e}`);
    } finally {
      setAssetDownloading(false);
    }
  };

  return (
    <ActionButton
      variant={'outlined'}
      color={'secondary'}
      loading={assetDownloading || loadingAsset}
      onClick={handleClick}
      disabled={!form19Data}
    >
      Export Form 19 Kit BOM (PDF)
    </ActionButton>
  );
}
