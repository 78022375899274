import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import {
  CarrierType,
  EventType,
  ICase,
  format,
  getEventAssetType,
  isPurchaseOrderEvent,
  isTimeSensitiveEvent,
} from '@workflow-nx/common';
import { InputConfigRHF, InputRHF, InputTypeRHF, ProgressButton } from '@workflow-nx/ui';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import { ComponentProps } from 'react';
import { Control, FieldValues, Resolver, SubmitHandler, useForm } from 'react-hook-form';
import CustomDialog from '../../../components/CustomDialog';
import {
  ICreateEventAssetDialogFormValues,
  createEventAssetsDialogFormValues,
} from '../../../extras/formValues';
import { CreateEventAssetDialogSchema } from '../../../extras/schemas';
import { CREATE_EVENT } from '../../../gql';

export interface CreateEventFormType extends FieldValues {
  occurredAt: Date;
  eventType: EventType;
  description: string;
  note: string;
  trackingNumber: string | null;
  purchaseOrder: string | null;
  carrierType: CarrierType | null;
}

export function CreateEventAssetDialog(props: {
  activeCase: ICase;
  assetId?: number;
  eventType?: EventType;
  onClose: () => void;
}) {
  const [createEvent, { loading: loadingCreateEvent }] = useMutation(CREATE_EVENT);

  const { enqueueSnackbar } = useSnackbar();

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: createEventAssetsDialogFormValues(),
    resolver: yupResolver(
      CreateEventAssetDialogSchema,
    ) as unknown as Resolver<ICreateEventAssetDialogFormValues>,
  });

  const handleSubmitForm: SubmitHandler<ICreateEventAssetDialogFormValues> = async (data) => {
    try {
      const occurredAt = data.occurredAt
        ? DateTime.fromJSDate(data.occurredAt).toISODate()
        : undefined;

      const dueDate = data.dueDate ? DateTime.fromJSDate(data.dueDate).toISODate() : undefined;

      await createEvent({
        variables: {
          caseId: props.activeCase.caseId,
          eventType: props.eventType,
          purchaseOrderNumber: data.purchaseOrderNumber,
          description: data.description,
          occurredAt,
          dueDate,
          note: data.note,
          assetId: props.assetId,
        },
      });

      enqueueSnackbar('Event created', {
        variant: 'success',
      });

      props.onClose();
    } catch (err: any) {
      console.error(err);

      enqueueSnackbar('An error occurred creating the event', {
        variant: 'error',
      });
    } finally {
      reset();
    }
  };

  const loading = loadingCreateEvent;

  const formItems: InputConfigRHF[] = [
    {
      id: 'occurredAt',
      label: 'Occurred At',
      input: InputTypeRHF.Date,
    },
    {
      id: 'description',
      label: 'Description',
      input: InputTypeRHF.Text,
    },
    ...(props.eventType && isPurchaseOrderEvent(props.eventType)
      ? [
          {
            id: 'purchaseOrderNumber',
            label: 'Purchase Order #',
            input: InputTypeRHF.Text,
          },
        ]
      : []),
    ...(props.eventType && isTimeSensitiveEvent(props.eventType)
      ? [
          {
            id: 'dueDate',
            label: 'Due Date',
            input: InputTypeRHF.Date,
          },
        ]
      : []),

    {
      id: 'note',
      label: 'Note',
      input: InputTypeRHF.Text,
    },
  ];

  const getInputProps = (config: InputConfigRHF): ComponentProps<typeof InputRHF> => ({
    config: config,
    control: control as Control<FieldValues>,
    disabled: isSubmitting || loading,
    textFieldProps: {
      multiline: config.id === 'note' ? true : false,
    },
    selectFieldProps: {
      hideNone: true,
    },
    datePickerProps: {
      disableFuture: config.id === 'occurredAt',
      disablePast: config.id === 'dueDate',
    },
    dropzoneProps: {
      validFileExtensions: config.validFileExtensions,
    },
  });

  const assetType = props.eventType ? getEventAssetType(props.eventType)?.assetType : null;

  return (
    <CustomDialog
      maxWidth={'md'}
      open={!!props.assetId && !!props.eventType}
      title={`Upload ${assetType ? format.formatAssetType(assetType) : ''}`}
      onClose={null}
      positiveActionButtons={[
        <ProgressButton
          variant={'contained'}
          disabled={isSubmitting || loading}
          onClick={(evt) => handleSubmit(handleSubmitForm)(evt)}
          label={'Create'}
          loading={isSubmitting || loading}
        />,
      ]}
    >
      <Grid container spacing={3}>
        {formItems.map((config) => {
          return <InputRHF key={config.id} {...getInputProps(config)} />;
        })}
      </Grid>
    </CustomDialog>
  );
}
