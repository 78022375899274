import React, { memo } from 'react';
import { Control, FieldValues, useController } from 'react-hook-form';
import { MuiTelInput } from 'mui-tel-input';

const PhoneNumberField = memo(function ({
  fullWidth = true,
  required,
  disabled,
  label,
  name,
  variant,
  control,
  onBlur,
  onChange,
}: {
  name: string;
  onBlur?: any;
  onChange?: any;
  control: Control<FieldValues>;
  label?: string;
  fullWidth?: boolean;
  required?: boolean;
  disabled?: boolean;
  variant?: 'standard' | 'filled' | 'outlined' | undefined;
}) {
  const {
    field: { onChange: hookFormOnChange, onBlur: hookFormOnBlur, value },
    fieldState: { invalid },
  } = useController({ name, control });

  return (
    <MuiTelInput
      value={value}
      required={required}
      error={invalid}
      onBlur={(e) => {
        if (onBlur) {
          onBlur(e);
        }
        hookFormOnBlur();
      }}
      onChange={(e) => {
        if (onChange) {
          onChange(e);
        }
        hookFormOnChange(e);
      }}
      fullWidth={fullWidth}
      label={label}
      variant={variant ?? 'outlined'}
      size={'small'}
      disabled={Boolean(disabled)}
      onlyCountries={['US']}
      defaultCountry={'US'}
      forceCallingCode
      focusOnSelectCountry
      disableDropdown
    />
  );
});

export { PhoneNumberField };
