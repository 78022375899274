import React, { useState } from 'react';
import { Box, Typography, Tabs, Tab } from '@mui/material';
import { CalloutView, IconFontButton } from '@workflow-nx/ui';
import { faChevronCircleDown, faChevronCircleUp } from '@fortawesome/pro-solid-svg-icons';
import { IForm19JsonLevel, IForm20Product } from '@workflow-nx/common';
import { Form20ProductsForm } from './Form20ProductsForm';
import { JsonEditorForm } from './JsonEditorForm';

export enum FormTypes {
  form19 = 'FORM-19',
  form20 = 'FORM-20',
}

export function ConfigureFormsView(props: {
  form20: IForm20Product[];
  form19: IForm19JsonLevel[];
  onClose: (shouldUpdate: boolean) => void;
}) {
  const [open, setOpen] = useState(false);
  const [tabValue, setTabValue] = useState('form20Product');

  const handleClose = () => {
    if (open) {
      props.onClose(false);
    }
    setOpen(!open);
  };

  return (
    <>
      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        <Typography variant={'h4'}>Configure Forms</Typography>
        <Box>
          <IconFontButton
            icon={open ? faChevronCircleUp : faChevronCircleDown}
            onClick={handleClose}
          />
        </Box>
      </Box>
      {open ? (
        <Box m={1}>
          <CalloutView>
            <Box mt={1}>
              <Tabs
                value={tabValue}
                indicatorColor="primary"
                textColor="primary"
                variant="standard"
                scrollButtons="auto"
                onChange={(_, newValue) => {
                  setTabValue(newValue);
                }}
                style={{ marginBottom: 8 }}
              >
                <Tab label={'Manage FORM-20 Products'} value={'form20Product'} />
                <Tab label={'FORM-20 Data'} value={'form20Data'} />
                <Tab label={'FORM-19 Data'} value={'form19Data'} />
              </Tabs>
            </Box>
          </CalloutView>
          <div role={'tabpanel'} hidden={tabValue !== 'form20Product'}>
            {tabValue === 'form20Product' ? (
              <Form20ProductsForm form20={props.form20} handleClose={handleClose} />
            ) : null}
          </div>
          <div role={'tabpanel'} hidden={tabValue !== 'form20Data'}>
            {tabValue === 'form20Data' ? (
              <JsonEditorForm
                form={props.form20}
                formType={FormTypes.form20}
                handleClose={handleClose}
              />
            ) : null}
          </div>
          <div role={'tabpanel'} hidden={tabValue !== 'form19Data'}>
            {tabValue === 'form19Data' ? (
              <JsonEditorForm
                form={props.form19}
                formType={FormTypes.form19}
                handleClose={handleClose}
              />
            ) : null}
          </div>
        </Box>
      ) : null}
    </>
  );
}
