import { Tooltip, Typography } from '@mui/material';
import { ClassNameMap, makeStyles } from '@mui/styles';
import { MeasurementWarningTypes } from '@workflow-nx/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons';

const useStyles = makeStyles(() => ({
  warning: {
    fill: '#ff980099',
    marginLeft: '5px',
  },
  danger: {
    fill: '#ff000099',
    marginLeft: '5px',
  },
  toolTip: {
    fontSize: '13px',
  },
}));

type MeasurementValueErrorProps = {
  text: string;
  type: MeasurementWarningTypes;
  testId: string;
};

const MeasurementValueError = (props: MeasurementValueErrorProps) => {
  const classes: ClassNameMap = useStyles();

  return (
    <Tooltip
      title={
        <>
          <Typography variant="body1" color="inherit" className={classes.toolTip}>
            {props.text}
          </Typography>
        </>
      }
    >
      <FontAwesomeIcon
        icon={faTriangleExclamation}
        className={
          props.type === MeasurementWarningTypes.Warning ? classes.warning : classes.danger
        }
        data-testid={props.testId}
      />
    </Tooltip>
  );
};

export default MeasurementValueError;
