import { ApolloQueryResult } from '@apollo/client/core/types';
import { CaseStageType, CaseType, EventType } from '@workflow-nx/common';

export type CaseCalendarActionType = {
  type: string;
  data?: any;
};

export type CaseCalendarStateType = {
  fieldRep?: string;
  organization?: string;
  surgeon?: string;
  caseStatus?: string;
  orderBy?: { [key: string]: 'asc' | 'desc' };
  caseTags?: string[];
  search?: string;
  startDate?: string;
  endDate?: string;
  showTentativeSurgeryDates?: boolean;
  showDraftCases?: boolean;
};

export const CaseCalendarWeekReducer =
  (refetch: (variables?: Partial<any>) => Promise<ApolloQueryResult<any>>) =>
  (state: CaseCalendarStateType, action: CaseCalendarActionType): CaseCalendarStateType => {
    const updatedState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
      case 'refetch':
        refetch();
        break;
      case 'CASE_DATE_RANGE_CHANGED':
        updatedState.startDate = action.data.startDate;
        updatedState.endDate = action.data.endDate;

        break;
    }

    const variables = {
      organizationFilter: updatedState.organization ? [updatedState.organization] : undefined,
      surgeonFilter: updatedState.surgeon ? [updatedState.surgeon] : undefined,
      fieldRepFilter: updatedState.fieldRep ? [updatedState.fieldRep] : undefined,
      tagsFilter: (updatedState.caseTags || []).map((tagId: string) => Number(tagId)),
      orderBy: undefined,
      search: updatedState.search,
      stageFilter: [] as CaseStageType[],
      eventTypeFilter: [] as EventType[],
      startDate: updatedState.startDate,
      endDate: updatedState.endDate,
      showTentativeSurgeryDates: updatedState.showTentativeSurgeryDates,
      caseTypeFilter: updatedState.showDraftCases
        ? [CaseType.Live, CaseType.Draft]
        : [CaseType.Live],
    };

    refetch(variables);

    return updatedState;
  };
