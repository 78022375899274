import { CaseStageType, CaseStageTypeColor, CaseType, ICase } from '@workflow-nx/common';
import { makeStyles } from '@mui/styles';
import { Theme, lighten } from '@mui/material';

const LIGHTEN_FACTOR = 0.4;

export const useCalendarStyles = makeStyles(() => ({
  listRoot: {
    listStyle: 'none',
  },
  stripedDraft: {
    background: `repeating-linear-gradient(45deg, ${lighten(
      CaseStageTypeColor.Draft,
      LIGHTEN_FACTOR,
    )}, ${lighten(CaseStageTypeColor.Draft, LIGHTEN_FACTOR)} 10px, ${
      CaseStageTypeColor.Draft
    } 10px, ${CaseStageTypeColor.Draft} 20px)`,
  },
  stripedPlanning: {
    background: `repeating-linear-gradient(45deg, ${lighten(
      CaseStageTypeColor.Planning,
      LIGHTEN_FACTOR,
    )}, ${lighten(CaseStageTypeColor.Planning, LIGHTEN_FACTOR)} 10px, ${
      CaseStageTypeColor.Planning
    } 10px, ${CaseStageTypeColor.Planning} 20px)`,
  },
  stripedApproved: {
    background: `repeating-linear-gradient(45deg, ${lighten(
      CaseStageTypeColor.Design,
      LIGHTEN_FACTOR,
    )}, ${lighten(CaseStageTypeColor.Design, LIGHTEN_FACTOR)} 10px, ${
      CaseStageTypeColor.Design
    } 10px, ${CaseStageTypeColor.Design} 20px)`,
  },
  stripedManufacturing: {
    background: `repeating-linear-gradient(45deg, ${lighten(
      CaseStageTypeColor.Production,
      LIGHTEN_FACTOR,
    )}, ${lighten(CaseStageTypeColor.Production, LIGHTEN_FACTOR)} 10px, ${
      CaseStageTypeColor.Production
    } 10px, ${CaseStageTypeColor.Production} 20px)`,
  },
  stripedReady: {
    background: `repeating-linear-gradient(45deg, ${lighten(
      CaseStageTypeColor.Ready,
      LIGHTEN_FACTOR,
    )}, ${lighten(CaseStageTypeColor.Ready, LIGHTEN_FACTOR)} 10px, ${
      CaseStageTypeColor.Ready
    } 10px, ${CaseStageTypeColor.Ready} 20px)`,
  },
  stripedComplete: {
    background: `repeating-linear-gradient(45deg, ${lighten(
      CaseStageTypeColor.Complete,
      LIGHTEN_FACTOR,
    )}, ${lighten(CaseStageTypeColor.Complete, LIGHTEN_FACTOR)} 10px, ${
      CaseStageTypeColor.Complete
    } 10px, ${CaseStageTypeColor.Complete} 20px)`,
  },
  stripeTextBackground: {
    '& p': {
      color: 'white',
      fontWeight: '500',
      textShadow: '2px 2px 4px black',
    },
  },
  legendBox: {
    width: 15,
    height: 15,
    borderRadius: 3,
    marginRight: 12,
    border: '1px solid grey',
  },
  stripedLegendBox: {
    background:
      'repeating-linear-gradient(45deg, rgb(233 233 233), rgb(233 233 233) 3px, rgb(190 190 190) 3px, rgb(190 190 190) 6px)',
  },
}));

export enum CalendarCaseCategory {
  Draft = 'Draft',
  Planning = 'Planning',
  Approved = 'Approved',
  Manufacturing = 'Manufacturing',
  Ready = 'Ready',
  Complete = 'Complete',
}

export const CASE_CATEGORY_CLASSIFICATION: Record<
  CalendarCaseCategory,
  { stages?: CaseStageType[]; hasHospitalDelivery?: boolean; caseType?: CaseType }
> = {
  [CalendarCaseCategory.Draft]: {
    stages: [CaseStageType.Open],
    caseType: CaseType.Draft,
  },
  [CalendarCaseCategory.Planning]: {
    stages: [
      CaseStageType.Open,
      CaseStageType.Segmentation,
      CaseStageType.Planning,
      CaseStageType.Proposed,
    ],
  },
  [CalendarCaseCategory.Approved]: {
    stages: [CaseStageType.Design, CaseStageType.QaReview],
  },
  [CalendarCaseCategory.Manufacturing]: {
    stages: [CaseStageType.Production],
  },
  [CalendarCaseCategory.Ready]: {
    stages: [CaseStageType.Ready],
    hasHospitalDelivery: true,
  },
  [CalendarCaseCategory.Complete]: {
    stages: [CaseStageType.Complete],
  },
};

export const CASE_CATEGORY_COLOR_LEGEND: (theme: Theme) => Record<
  CalendarCaseCategory,
  {
    palette: {
      contrastText: string;
      main: string;
    };
    stripedClass: string;
  }
> = (theme) => ({
  [CalendarCaseCategory.Draft]: {
    palette: {
      contrastText: theme.palette.getContrastText(CaseStageTypeColor.Draft),
      main: CaseStageTypeColor.Draft,
    },
    stripedClass: 'stripedDraft',
  },
  [CalendarCaseCategory.Planning]: {
    palette: {
      contrastText: theme.palette.getContrastText(CaseStageTypeColor.Planning),
      main: CaseStageTypeColor.Open,
    },
    stripedClass: 'stripedPlanning',
  },
  [CalendarCaseCategory.Approved]: {
    palette: {
      contrastText: theme.palette.getContrastText(CaseStageTypeColor.Design),
      main: CaseStageTypeColor.Design,
    },
    stripedClass: 'stripedApproved',
  },
  [CalendarCaseCategory.Manufacturing]: {
    palette: {
      contrastText: theme.palette.getContrastText(CaseStageTypeColor.Production),
      main: CaseStageTypeColor.Production,
    },
    stripedClass: 'stripedManufacturing',
  },
  [CalendarCaseCategory.Ready]: {
    palette: {
      contrastText: theme.palette.getContrastText(CaseStageTypeColor.Ready),
      main: CaseStageTypeColor.Ready,
    },
    stripedClass: 'stripedReady',
  },
  [CalendarCaseCategory.Complete]: {
    palette: {
      contrastText: theme.palette.getContrastText(CaseStageTypeColor.Complete),
      main: CaseStageTypeColor.Complete,
    },
    stripedClass: 'stripedComplete',
  },
});

export function getCalendarCaseCategory(activeCase: ICase): CalendarCaseCategory | null {
  const matchedCategory = Object.entries(CASE_CATEGORY_CLASSIFICATION).find(([_, classReq]) => {
    const caseTypeReqMet = classReq.caseType ? classReq.caseType === activeCase.caseType : true;
    const stagesReqMet = classReq.stages ? classReq.stages.includes(activeCase.stage) : true;

    const deliveryReqMet = classReq.hasHospitalDelivery ? Boolean(activeCase.deliveredAt) : true;

    return stagesReqMet && deliveryReqMet && caseTypeReqMet;
  });

  return matchedCategory?.[0] as CalendarCaseCategory;
}
