import {
  caseUtils,
  format,
  ICase,
  ILevels,
  IPostOpAnalysis,
  LevelType,
  PartType,
} from '@workflow-nx/common';
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Button, IconFontButton } from '@workflow-nx/ui';
import React, { useState } from 'react';
import { faChevronCircleDown, faChevronCircleUp } from '@fortawesome/pro-solid-svg-icons';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownLong, faArrowUpLong, faDash } from '@fortawesome/pro-light-svg-icons';

const useStyles = makeStyles((theme: Theme) => ({
  disabledRow: {
    backgroundColor: theme.palette.grey['50'],
  },
}));

function getProperty<T extends Record<string, any>>(obj: T, key: string): any {
  if (key in obj) {
    return obj[key as keyof T];
  } else {
    throw new Error(`Property ${key} does not exist on type ${JSON.stringify(obj)}`);
  }
}

function LabelValueView(props: {
  values: [number | undefined, number | undefined];
  display: 'degrees' | 'mm';
  label: string;
}) {
  return (
    <Stack>
      <DeltaValueView values={props.values} display={props.display} size={'large'} />
      <Typography variant={'body2'} noWrap color={'textSecondary'}>
        {props.label}
      </Typography>
    </Stack>
  );
}

function DeltaValueView(props: {
  values: [number | undefined, number | undefined];
  display: 'degrees' | 'mm';
  size?: 'small' | 'medium' | 'large';
}) {
  const value = props.values[0];
  const deltaValue = props.values[1];

  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      justifyContent={!!value && !!deltaValue ? 'justify-content' : 'center'}
      gap={1}
    >
      {value ? (
        <Typography variant={props.size === 'large' ? 'h5' : 'body1'} sx={{ ml: 'auto' }}>
          {value.toFixed(2)}
          {props.display === 'degrees' ? <>&deg;</> : 'mm'}
        </Typography>
      ) : (
        <>&mdash;</>
      )}
      {!!value && !!deltaValue ? (
        <Stack
          direction={'row'}
          gap={0.5}
          alignItems={'center'}
          justifyContent={'center'}
          sx={{
            p: 0.25,
            color: '#fff',
            backgroundColor: '#71acff',
            borderRadius: '5px',
            mr: 'auto',
          }}
        >
          {!!deltaValue && deltaValue > 0 ? (
            <FontAwesomeIcon size={'sm'} icon={faArrowUpLong} />
          ) : null}
          {!deltaValue || deltaValue === 0 ? <FontAwesomeIcon size={'sm'} icon={faDash} /> : null}
          {!!deltaValue && deltaValue < 0 ? (
            <FontAwesomeIcon size={'sm'} icon={faArrowDownLong} />
          ) : null}
          <Typography variant={'body2'} noWrap>
            {deltaValue.toFixed(2)}
          </Typography>
        </Stack>
      ) : null}
    </Stack>
  );
}

export function AnalysisTableView(props: {
  activeCase?: ICase;
  label: string;
  deltaValues?: IPostOpAnalysis;
  analysisValues?: IPostOpAnalysis;
  valueType: 'PRE_OP' | 'PLAN' | 'POST_OP' | 'DELTA';
  onEdit?: () => void;
  onMeasure?: () => void;
  expanded?: boolean;
}) {
  const theme = useTheme();
  const classes = useStyles();
  const [showDetails, setShowDetails] = useState(props.expanded);

  function roundToTwoDecimals(value: number) {
    return Math.round((value + Number.EPSILON) * 100) / 100;
  }

  function getLevelValues(
    level: LevelType,
    name: string,
  ): [number | undefined, number | undefined] {
    if (!props.analysisValues) {
      return [undefined, undefined];
    }

    let value: number | undefined = getProperty(props?.analysisValues, name)?.[level];

    let deltaValue = undefined;
    if (props.deltaValues) {
      deltaValue = getProperty(props?.deltaValues, name)?.[level];

      if (_.isNumber(value) && _.isNumber(deltaValue)) {
        deltaValue = value - deltaValue;
      }
    }

    return [
      _.isNumber(value) ? roundToTwoDecimals(value) : undefined,
      _.isNumber(deltaValue) ? roundToTwoDecimals(deltaValue) : undefined,
    ];
  }

  function getValues(name: string): [number | undefined, number | undefined] {
    if (!props.analysisValues) {
      return [undefined, undefined];
    }

    let value: number | undefined = getProperty(props?.analysisValues, name);

    let deltaValue = undefined;
    if (props.deltaValues) {
      deltaValue = getProperty(props?.deltaValues, name);

      if (_.isNumber(value) && _.isNumber(deltaValue)) {
        deltaValue = value - deltaValue;
      }
    }

    return [
      _.isNumber(value) ? roundToTwoDecimals(value) : undefined,
      _.isNumber(deltaValue) ? roundToTwoDecimals(deltaValue) : undefined,
    ];
  }

  const validLevels = props?.activeCase?.levels
    ? caseUtils.getValidCaseLevels(props?.activeCase?.levels as ILevels)
    : [];
  const profileLevelTypes = caseUtils.getLevelsSortedByHierarchy(
    props.activeCase?.spineProfile,
    'desc',
  );

  return (
    <Box p={2} borderRadius="5px" border={`1px solid ${theme.palette.divider}`}>
      <Stack direction={'row'} alignItems={'center'} gap={2}>
        <Typography variant={'h4'} noWrap>
          {props.label}
        </Typography>
        {props.valueType === 'POST_OP' ? (
          <>
            <Box ml={2}>
              <Button
                variant={'outlined'}
                color={'primary'}
                onClick={props.onEdit}
                label={'Edit'}
              />
            </Box>
          </>
        ) : null}
        <Box ml={2} flexGrow={1} />
        <LabelValueView
          values={[props.activeCase?.patient?.patientRecord?.pelvicIncidence, undefined]}
          label={'PI'}
          display={'degrees'}
        />
        <LabelValueView values={getValues('lumbarLordosis')} label={'LL'} display={'degrees'} />
        <LabelValueView
          values={getValues('lumbarCoronalAngulation')}
          label={'LCA'}
          display={'degrees'}
        />

        {props.valueType === 'POST_OP' || props.valueType === 'DELTA' ? (
          <>
            <LabelValueView values={getValues('coronalBalance')} label={'CB'} display={'degrees'} />
            <LabelValueView values={getValues('pelvicTilt')} label={'PT'} display={'degrees'} />
            <LabelValueView
              values={getValues('thoracicKyphosis')}
              label={'TK'}
              display={'degrees'}
            />
            <LabelValueView
              values={getValues('sagittalVerticalAxis')}
              label={'SVA'}
              display={'degrees'}
            />
          </>
        ) : null}
        <Box ml={2} />
        <Box>
          <IconFontButton
            icon={showDetails ? faChevronCircleUp : faChevronCircleDown}
            onClick={() => setShowDetails(!showDetails)}
          />
        </Box>
      </Stack>
      {showDetails ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant={'body1'} noWrap>
                  <strong>Level</strong>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant={'body1'} noWrap>
                  <strong>Part</strong>
                </Typography>
              </TableCell>
              <TableCell align={'center'}>
                <Typography variant={'body1'} noWrap>
                  <strong>Lordosis</strong>
                </Typography>
              </TableCell>
              <TableCell align={'center'}>
                <Typography variant={'body1'} noWrap>
                  <strong>Angle to S1</strong>
                </Typography>
              </TableCell>
              <TableCell align={'center'}>
                <Typography variant={'body1'} noWrap>
                  <strong>Coronal</strong>
                </Typography>
              </TableCell>
              <TableCell align={'center'}>
                <Typography variant={'body1'} noWrap>
                  <strong>H-ANT</strong>
                </Typography>
              </TableCell>
              <TableCell align={'center'}>
                <Typography variant={'body1'} noWrap>
                  <strong>H-POST</strong>
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {profileLevelTypes.map((level) => {
              const isInvalidLevel = !validLevels?.includes(level);

              return (
                <TableRow className={isInvalidLevel ? classes.disabledRow : undefined} key={level}>
                  <TableCell>{format.formatLevelType(level)}</TableCell>
                  <TableCell>
                    {format.formatPartType(props?.activeCase?.levels[level] as PartType)}
                  </TableCell>
                  <TableCell align={'center'}>
                    <DeltaValueView
                      values={getLevelValues(level, 'segmentalLumbarLordosis')}
                      display={'degrees'}
                    />
                  </TableCell>
                  <TableCell align={'center'}>
                    <DeltaValueView
                      values={getLevelValues(level, 'angleToS1')}
                      display={'degrees'}
                    />
                  </TableCell>
                  <TableCell align={'center'}>
                    <DeltaValueView
                      values={getLevelValues(level, 'segmentalCoronalAngle')}
                      display={'degrees'}
                    />
                  </TableCell>
                  <TableCell align={'center'}>
                    <DeltaValueView
                      values={getLevelValues(level, 'segmentalAnteriorHeight')}
                      display={'mm'}
                    />
                  </TableCell>
                  <TableCell align={'center'}>
                    <DeltaValueView
                      values={getLevelValues(level, 'segmentalPosteriorHeight')}
                      display={'mm'}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : null}
    </Box>
  );
}
