import React, { useCallback, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import * as jsondiffpatch from 'jsondiffpatch';
import 'jsondiffpatch/dist/formatters-styles/html.css';
import { Alert } from '@mui/material';
import CustomDialog from '../../../../components/CustomDialog';
import { FIND_ACTIVITY } from '../../../../gql';

export function ActivityViewDialog(props: {
  open: boolean;
  activityId: number | undefined;
  onClose: () => void;
}) {
  const [findActivity, { data }] = useLazyQuery(FIND_ACTIVITY);

  const handleLoad = useCallback(
    async (activityId: number) => {
      await findActivity({
        variables: {
          activityId,
        },
      });
    },
    [findActivity],
  );

  useEffect(() => {
    if (props.open && !!props.activityId) {
      handleLoad(props.activityId);
    }
  }, [props.activityId, props.open, handleLoad]);

  return (
    <CustomDialog
      maxWidth={'sm'}
      open={props.open}
      title={`Viewing Activity`}
      onClose={() => {
        props.onClose();
      }}
      positiveActionButtons={[]}
    >
      {props.open ? (
        data?.activity?.delta ? (
          <div
            dangerouslySetInnerHTML={{
              __html: jsondiffpatch.formatters.html.format(data.activity.delta, {}),
            }}
          />
        ) : (
          <Alert severity={'info'}>There is no change information for this activity.</Alert>
        )
      ) : null}
    </CustomDialog>
  );
}
