import React, { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Box, ButtonBase, Menu, MenuItem, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import useAuth from '../../../hooks/useAuth';
import { format } from '@workflow-nx/common';
import { UserAvatar } from '@workflow-nx/ui';

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    height: 40,
    width: 40,
    marginRight: theme.spacing(1),
  },
  popover: {
    width: 200,
  },
  menuHeader: {
    height: 60,
    display: 'block',
    backgroundColor: theme.palette.action.hover,
  },
}));

const Account = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const ref = useRef(null);
  const { user, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      if (logout) await logout();
      navigate('/');
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to logout', {
        variant: 'error',
      });
    }
  };

  return user ? (
    <>
      {/* @ts-ignore */}
      <Box display="flex" alignItems="center" component={ButtonBase} onClick={handleOpen} ref={ref}>
        <UserAvatar user={user} hideDetails={true} />
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        PaperProps={{ className: styles.popover }}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem component={'div'} className={styles.menuHeader}>
          <Typography variant={'body1'}>{format.formatName(user)}</Typography>
          <Typography variant={'body2'}>{format.formatUserRole(user?.role)}</Typography>
        </MenuItem>
        <MenuItem component={RouterLink} to={`/app/user/${user?.userId}`}>
          Profile
        </MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  ) : null;
};

export default Account;
