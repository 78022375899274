import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, CircularProgress, IconButton as MuiIconButton, PropTypes } from '@mui/material';
import { testing } from '@workflow-nx/utils';
import React, { MouseEventHandler } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { IconLookup } from '@fortawesome/pro-light-svg-icons';

type IconFontButtonProps = {
  badgeContent?: React.ReactNode;
  color?: PropTypes.Color;
  fontColor?: string;
  loading?: boolean;
  disabled?: boolean;
  icon: IconLookup;
  onClick?: MouseEventHandler;
  to?: any;
  size?: 'small' | 'medium' | 'large';
};

export function IconFontButton({
  color,
  fontColor,
  disabled,
  loading,
  icon,
  onClick,
  size,
  to,
  badgeContent,
}: IconFontButtonProps) {
  return (
    <MuiIconButton
      color={color}
      onClick={onClick}
      data-test-id={testing.toKebabCase(icon.iconName) + '-icon-font-button'}
      component={to ? RouterLink : 'button'}
      to={to ? to : undefined}
      size={size}
      disabled={disabled}
      style={{ color: fontColor }}
    >
      {!loading ? (
        <Badge badgeContent={badgeContent} color={'primary'}>
          <FontAwesomeIcon icon={icon} fixedWidth={true} />
        </Badge>
      ) : null}

      {loading && (
        <span className="fa-layers fa-fw">
          <CircularProgress size={24} />
        </span>
      )}
    </MuiIconButton>
  );
}
