import { defaultIdealSpineMapValues } from '../shared/enum';
import {
  AutoCorrectionLordosisStore,
  AutoCorrectionAngleToS1SpineMapType,
  AutoCorrectionConfigType,
  AutoCorrectionIdealSpineMapType,
  PosteriorTargetType,
  TEM013RecommendationsType,
  AutoCorrectionStrictRecommendations,
} from '../shared/types';

const noLevelRecommendation = {
  anteriorHeight: {
    min: 0,
    max: 0,
  },
  posteriorHeight: 0,
};
// TODO: L4S1 or L5S1 Sacralization Consideration Needed
export const defaultIdealSpineMap: AutoCorrectionIdealSpineMapType = {
  C2_C3: defaultIdealSpineMapValues.idealDefault,
  C3_C4: defaultIdealSpineMapValues.idealDefault,
  C4_C5: defaultIdealSpineMapValues.idealDefault,
  C5_C6: defaultIdealSpineMapValues.idealDefault,
  C6_C7: defaultIdealSpineMapValues.idealDefault,
  C7_C8: defaultIdealSpineMapValues.idealDefault,
  C7_T1: defaultIdealSpineMapValues.idealDefault,
  C6_T1: defaultIdealSpineMapValues.idealDefault,
  C8_T1: defaultIdealSpineMapValues.idealDefault,

  L4_S1: defaultIdealSpineMapValues.idealDefault,
  L6_S1: defaultIdealSpineMapValues.idealDefault,
  L5_S1: defaultIdealSpineMapValues.idealL5S1,
  L5_L6: defaultIdealSpineMapValues.idealDefault,
  L4_L5: defaultIdealSpineMapValues.idealL4L5,
  L3_L4: defaultIdealSpineMapValues.idealL3L4,
  L2_L3: defaultIdealSpineMapValues.idealL2L3,
  L1_L2: defaultIdealSpineMapValues.idealL1L2,
};

export const defaultAngleToS1TargetSpineMap: AutoCorrectionAngleToS1SpineMapType = {
  CERVICAL_STANDARD: {
    C2_C3: null,
    C3_C4: null,
    C4_C5: null,
    C5_C6: null,
    C6_C7: null,
    C7_C8: null,
    C7_T1: null,
    C6_T1: null,
    C8_T1: null,

    L4_L5: null,
    L5_S1: null,
    L4_S1: null,
    L6_S1: null,
    L5_L6: null,
    L3_L4: null,
    L2_L3: null,
    L1_L2: null,
  },
  CERVICAL_STANDARD_MINUS_C7: {
    C2_C3: null,
    C3_C4: null,
    C4_C5: null,
    C5_C6: null,
    C6_C7: null,
    C7_C8: null,
    C7_T1: null,
    C6_T1: null,
    C8_T1: null,

    L4_L5: null,
    L5_S1: null,
    L4_S1: null,
    L6_S1: null,
    L5_L6: null,
    L3_L4: null,
    L2_L3: null,
    L1_L2: null,
  },
  CERVICAL_STANDARD_PLUS_C8: {
    C2_C3: null,
    C3_C4: null,
    C4_C5: null,
    C5_C6: null,
    C6_C7: null,
    C7_C8: null,
    C7_T1: null,
    C6_T1: null,
    C8_T1: null,

    L4_L5: null,
    L5_S1: null,
    L4_S1: null,
    L6_S1: null,
    L5_L6: null,
    L3_L4: null,
    L2_L3: null,
    L1_L2: null,
  },
  LUMBAR_STANDARD: {
    C2_C3: null,
    C3_C4: null,
    C4_C5: null,
    C5_C6: null,
    C6_C7: null,
    C7_C8: null,
    C7_T1: null,
    C6_T1: null,
    C8_T1: null,

    L4_L5: 0.698132,
    L5_S1: 0.436332,
    L4_S1: null,
    L6_S1: null,
    L5_L6: null,
    L3_L4: null,
    L2_L3: null,
    L1_L2: null,
  },
  LUMBAR_STANDARD_MINUS_L5: {
    C2_C3: null,
    C3_C4: null,
    C4_C5: null,
    C5_C6: null,
    C6_C7: null,
    C7_C8: null,
    C7_T1: null,
    C6_T1: null,
    C8_T1: null,

    L3_L4: 0.698132,
    L4_S1: 0.436332,
    L4_L5: null,
    L5_S1: null,
    L6_S1: null,
    L5_L6: null,
    L2_L3: null,
    L1_L2: null,
  },
  LUMBAR_STANDARD_PLUS_L6: {
    C2_C3: null,
    C3_C4: null,
    C4_C5: null,
    C5_C6: null,
    C6_C7: null,
    C7_C8: null,
    C7_T1: null,
    C6_T1: null,
    C8_T1: null,

    L5_L6: 0.698132,
    L6_S1: 0.436332,
    L3_L4: null,
    L4_S1: null,
    L4_L5: null,
    L5_S1: null,
    L2_L3: null,
    L1_L2: null,
  },
};

export const defaultStrictRecommendations: AutoCorrectionStrictRecommendations = {
  lordosis: 22,
};

export const defaultRecommendationMap: TEM013RecommendationsType = {
  implantRecommendations: {
    ALIF: {
      lordosis: 20,
      coronal: 10,
    },
    ALIF_X: {
      lordosis: 20,
      coronal: 10,
    },
    LLIF: {
      lordosis: 15,
      coronal: 10,
    },
    TLIF_O: {
      lordosis: 8,
      coronal: 8,
    },
    TLIF_C: {
      lordosis: 10,
      coronal: 8,
    },
    TLIF_CA: {
      lordosis: 10,
      coronal: 8,
    },
    //New implant types are added, please add the correct values when need to use
    ACDF: {
      lordosis: 0,
      coronal: 0,
    },
    ACDF_X: {
      lordosis: 0,
      coronal: 0,
    },
    ACDF_X_NO_CAM: {
      lordosis: 0,
      coronal: 0,
    },
    NONE: {
      lordosis: 0,
      coronal: 0,
    },
  },
  levelRecommendations: {
    CERVICAL_STANDARD: {
      C2_C3: noLevelRecommendation,
      C3_C4: noLevelRecommendation,
      C4_C5: noLevelRecommendation,
      C5_C6: noLevelRecommendation,
      C6_C7: noLevelRecommendation,
      C7_C8: noLevelRecommendation,
      C7_T1: noLevelRecommendation,
      C6_T1: noLevelRecommendation,
      C8_T1: noLevelRecommendation,

      L1_L2: noLevelRecommendation,
      L2_L3: noLevelRecommendation,
      L3_L4: noLevelRecommendation,
      L4_L5: noLevelRecommendation,
      L5_L6: noLevelRecommendation,
      L4_S1: noLevelRecommendation,
      L5_S1: noLevelRecommendation,
      L6_S1: noLevelRecommendation,
    },
    CERVICAL_STANDARD_MINUS_C7: {
      C2_C3: noLevelRecommendation,
      C3_C4: noLevelRecommendation,
      C4_C5: noLevelRecommendation,
      C5_C6: noLevelRecommendation,
      C6_C7: noLevelRecommendation,
      C7_C8: noLevelRecommendation,
      C7_T1: noLevelRecommendation,
      C6_T1: noLevelRecommendation,
      C8_T1: noLevelRecommendation,

      L1_L2: noLevelRecommendation,
      L2_L3: noLevelRecommendation,
      L3_L4: noLevelRecommendation,
      L4_L5: noLevelRecommendation,
      L5_L6: noLevelRecommendation,
      L4_S1: noLevelRecommendation,
      L5_S1: noLevelRecommendation,
      L6_S1: noLevelRecommendation,
    },
    CERVICAL_STANDARD_PLUS_C8: {
      C2_C3: noLevelRecommendation,
      C3_C4: noLevelRecommendation,
      C4_C5: noLevelRecommendation,
      C5_C6: noLevelRecommendation,
      C6_C7: noLevelRecommendation,
      C7_C8: noLevelRecommendation,
      C7_T1: noLevelRecommendation,
      C6_T1: noLevelRecommendation,
      C8_T1: noLevelRecommendation,

      L1_L2: noLevelRecommendation,
      L2_L3: noLevelRecommendation,
      L3_L4: noLevelRecommendation,
      L4_L5: noLevelRecommendation,
      L5_L6: noLevelRecommendation,
      L4_S1: noLevelRecommendation,
      L5_S1: noLevelRecommendation,
      L6_S1: noLevelRecommendation,
    },
    LUMBAR_STANDARD: {
      C2_C3: noLevelRecommendation,
      C3_C4: noLevelRecommendation,
      C4_C5: noLevelRecommendation,
      C5_C6: noLevelRecommendation,
      C6_C7: noLevelRecommendation,
      C7_C8: noLevelRecommendation,
      C7_T1: noLevelRecommendation,
      C6_T1: noLevelRecommendation,
      C8_T1: noLevelRecommendation,

      L1_L2: {
        anteriorHeight: {
          min: 8,
          max: 12,
        },
        posteriorHeight: 7,
      },
      L2_L3: {
        anteriorHeight: {
          min: 8,
          max: 12,
        },
        posteriorHeight: 7,
      },
      L3_L4: {
        anteriorHeight: {
          min: 9,
          max: 12,
        },
        posteriorHeight: 7,
      },
      L4_L5: {
        anteriorHeight: {
          min: 10,
          max: 14,
        },
        posteriorHeight: 7,
      },
      L5_L6: {
        anteriorHeight: {
          min: 0,
          max: 0,
        },
        posteriorHeight: 0,
      },
      L4_S1: {
        anteriorHeight: {
          min: 0,
          max: 0,
        },
        posteriorHeight: 0,
      },
      L5_S1: {
        anteriorHeight: {
          min: 12,
          max: 16,
        },
        posteriorHeight: 7,
      },
      L6_S1: {
        anteriorHeight: {
          min: 0,
          max: 0,
        },
        posteriorHeight: 0,
      },
    },
    LUMBAR_STANDARD_MINUS_L5: {
      C2_C3: noLevelRecommendation,
      C3_C4: noLevelRecommendation,
      C4_C5: noLevelRecommendation,
      C5_C6: noLevelRecommendation,
      C6_C7: noLevelRecommendation,
      C7_C8: noLevelRecommendation,
      C7_T1: noLevelRecommendation,
      C6_T1: noLevelRecommendation,
      C8_T1: noLevelRecommendation,

      L1_L2: {
        anteriorHeight: {
          min: 8,
          max: 12,
        },
        posteriorHeight: 7,
      },
      L2_L3: {
        anteriorHeight: {
          min: 9,
          max: 12,
        },
        posteriorHeight: 7,
      },
      L3_L4: {
        anteriorHeight: {
          min: 10,
          max: 14,
        },
        posteriorHeight: 7,
      },
      L4_L5: {
        anteriorHeight: {
          min: 0,
          max: 0,
        },
        posteriorHeight: 0,
      },
      L5_L6: {
        anteriorHeight: {
          min: 0,
          max: 0,
        },
        posteriorHeight: 0,
      },
      L4_S1: {
        anteriorHeight: {
          min: 12,
          max: 16,
        },
        posteriorHeight: 7,
      },
      L5_S1: {
        anteriorHeight: {
          min: 0,
          max: 0,
        },
        posteriorHeight: 0,
      },
      L6_S1: {
        anteriorHeight: {
          min: 0,
          max: 0,
        },
        posteriorHeight: 0,
      },
    },
    LUMBAR_STANDARD_PLUS_L6: {
      C2_C3: noLevelRecommendation,
      C3_C4: noLevelRecommendation,
      C4_C5: noLevelRecommendation,
      C5_C6: noLevelRecommendation,
      C6_C7: noLevelRecommendation,
      C7_C8: noLevelRecommendation,
      C7_T1: noLevelRecommendation,
      C6_T1: noLevelRecommendation,
      C8_T1: noLevelRecommendation,

      L1_L2: {
        anteriorHeight: {
          min: 8,
          max: 12,
        },
        posteriorHeight: 7,
      },
      L2_L3: {
        anteriorHeight: {
          min: 8,
          max: 12,
        },
        posteriorHeight: 7,
      },
      L3_L4: {
        anteriorHeight: {
          min: 9,
          max: 12,
        },
        posteriorHeight: 7,
      },
      L4_L5: {
        anteriorHeight: {
          min: 10,
          max: 14,
        },
        posteriorHeight: 7,
      },
      L5_L6: {
        anteriorHeight: {
          min: 12,
          max: 16,
        },
        posteriorHeight: 7,
      },
      L4_S1: {
        anteriorHeight: {
          min: 0,
          max: 0,
        },
        posteriorHeight: 0,
      },
      L5_S1: {
        anteriorHeight: {
          min: 0,
          max: 0,
        },
        posteriorHeight: 0,
      },
      L6_S1: {
        anteriorHeight: {
          min: 12,
          max: 16,
        },
        posteriorHeight: 7,
      },
    },
  },
};

export const defaultPosteriorTargets: PosteriorTargetType = {
  ALIF: {
    posterior: 5,
  },
  ALIF_X: {
    posterior: 6,
  },
  LLIF: {
    posterior: 5,
  },
  TLIF_O: {
    posterior: 6,
  },
  TLIF_C: {
    posterior: 6,
  },
  TLIF_CA: {
    posterior: 6,
  },
  //New implant types are added, please add the correct values when need to use
  ACDF: {
    posterior: 0,
  },
  ACDF_X: {
    posterior: 0,
  },
  ACDF_X_NO_CAM: {
    posterior: 0,
  },
  NONE: {
    posterior: 0,
  },
};

export const defaultConfig: AutoCorrectionConfigType = {
  SACRUM_MAX_ML_CUT_OFF_MALE: 58,
  SACRUM_MAX_ML_CUT_OFF_FEMALE: 52,
  SACRUM_MIN_AP_CUT_OFF_AVERAGE: 35, // Place holder need a real average
  VB_MAX_ML_CUT_OFF_MALE: 52,
  VB_MAX_ML_CUT_OFF_FEMALE: 47,
  VB_MIN_ML_CUT_OFF: 35,
  DBSCAN_CLUSTER_COUNT: 2,
  DBSCAN_SCAN_XY_RADIUS: 4,
  DBSCAN_SCAN_VB_RADIUS: 3,
  DBSCAN_SCAN_CROSS_SECTION_RADIUS: 8.1,
  DBSCAN_SCAN_SACRUM_RADIUS: 1.1,
  DBSCAN_SCAN_DERIVATIVE_RADIUS: 3.1,
  DBSCAN_SCAN_DERIVATIVE_TRANSPOSED_RADIUS: 4.1,
  DBSCAN_SCAN_LOW_FIDELITY_ARTIFACT_RADIUS: 1.1,
  DBSCAN_SCAN_HIGH_FIDELITY_ARTIFACT_RADIUS: 0.2,
  DBSCAN_AXIAL_VALIDATION: 6.1,
  RAY_CAST_LOW_FIDELITY: 0.5,
  RAY_CAST_HIGH_FIDELITY: 0.1,
  NORMAL_FILTER_AP_CUT_OFF: Math.PI / 4,
  BOUNDING_BOX_SIZE: 125,
};

export const defaultAddedLordosis: AutoCorrectionLordosisStore = {
  C2_C3: 0,
  C3_C4: 0,
  C4_C5: 0,
  C5_C6: 0,
  C6_C7: 0,
  C8_T1: 0,
  C7_T1: 0,
  C6_T1: 0,
  C7_C8: 0,
  L1_L2: 0,
  L2_L3: 0,
  L3_L4: 0,
  L4_L5: 0,
  L5_S1: 0,
  L4_S1: 0,
  L5_L6: 0,
  L6_S1: 0,
};

export const defaultPreopLordosis: AutoCorrectionLordosisStore = {
  C2_C3: 0,
  C3_C4: 0,
  C4_C5: 0,
  C5_C6: 0,
  C6_C7: 0,
  C8_T1: 0,
  C7_T1: 0,
  C6_T1: 0,
  C7_C8: 0,
  L1_L2: 0,
  L2_L3: 0,
  L3_L4: 0,
  L4_L5: 0,
  L5_S1: 0,
  L4_S1: 0,
  L5_L6: 0,
  L6_S1: 0,
};
