import { faTimes } from '@fortawesome/pro-light-svg-icons';
import {
  Dialog as MatDialog,
  DialogActions as MatDialogActions,
  DialogContent as MatDialogContent,
  DialogTitle as MatDialogTitle,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { IconFontButton } from '@workflow-nx/ui';
import React, { ComponentProps, MouseEventHandler } from 'react';

const useStyles = makeStyles(() => ({
  fullHeightDialog: {
    '& .MuiDialog-paper': {
      minHeight: '90vh',
    },
  },
}));

type CustomDialogProps = {
  title: React.ReactNode;
  positiveActionButtons?: any[];
  negativeActionButtons?: any[];
  open: boolean;
  dialogContentClassName?: ComponentProps<typeof MatDialogContent>['className'];
  containerClassName?: ComponentProps<typeof Box>['className'];
  fullScreen?: boolean;
  fullHeight?: boolean;
  fullWidth?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  onClose: MouseEventHandler | null;
  children?: React.ReactNode;
};

function CustomDialog({
  positiveActionButtons,
  negativeActionButtons,
  title,
  open,
  maxWidth,
  dialogContentClassName,
  containerClassName,
  fullScreen,
  fullHeight,
  onClose,
  children,
}: CustomDialogProps) {
  const styles = useStyles();

  return (
    <MatDialog
      fullScreen={fullScreen}
      open={open}
      scroll="paper"
      maxWidth={maxWidth}
      fullWidth={true}
      className={fullHeight ? styles.fullHeightDialog : ''}
    >
      <MatDialogTitle style={{ paddingBottom: 0 }}>
        <Box display={'flex'} alignItems={'center'}>
          <Typography style={{ fontSize: '1em' }} variant={'body1'} noWrap={true}>
            {title}
          </Typography>
          <Box flexGrow={1} />
          {onClose ? <IconFontButton onClick={onClose} icon={faTimes} /> : null}
        </Box>
      </MatDialogTitle>
      <MatDialogContent className={dialogContentClassName}>
        <Box className={containerClassName} my={2}>
          {children}
        </Box>
      </MatDialogContent>
      {((positiveActionButtons || []).length > 0 || (negativeActionButtons || []).length > 0) && (
        <MatDialogActions disableSpacing={true}>
          {negativeActionButtons?.map((negativeActionButton, i) => (
            <React.Fragment key={i}>
              {negativeActionButton}
              {i > 0 && <Box mx={1} />}
            </React.Fragment>
          ))}
          <Box flex={1} />
          {positiveActionButtons?.map((positiveActionButton, i) => (
            <React.Fragment key={i}>
              {i > 0 && <Box mx={1} />}
              {positiveActionButton}
            </React.Fragment>
          ))}
        </MatDialogActions>
      )}
    </MatDialog>
  );
}

export default CustomDialog;
