import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button as MatButton, SxProps, Theme, useTheme } from '@mui/material';
import { testing } from '@workflow-nx/utils';
import { ReactNode } from 'react';

function ActionButton(props: {
  onClick: () => void;
  fullWidth?: boolean;
  disabled?: boolean;
  loading?: boolean;
  children: ReactNode;
  label?: string;
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  size?: 'small' | 'medium' | 'large';
  variant?: 'text' | 'outlined' | 'contained';
  sx?: SxProps<Theme>;
}) {
  const theme = useTheme();
  const backgroundColor = props.variant === 'outlined' ? theme.palette.grey['100'] : '';

  return (
    <MatButton
      variant={props.variant ?? 'contained'}
      color={props.color ?? 'primary'}
      onClick={props.onClick}
      fullWidth={props.fullWidth}
      disabled={props.disabled || props.loading}
      data-test-id={testing.toKebabCase(props.label || 'action') + '-button'}
      style={{ backgroundColor: backgroundColor }}
      size={props.size}
      sx={props.sx}
    >
      {props.children}
      {props.loading ? (
        <>
          <Box ml={1} />
          <FontAwesomeIcon icon={faSpinner} spin />
        </>
      ) : null}
    </MatButton>
  );
}

export default ActionButton;
