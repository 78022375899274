import { MeasurementsVersionType } from '@workflow-nx/common';
import { Box, Chip, FormControlLabel, FormGroup, Stack, Switch } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRuler } from '@fortawesome/pro-light-svg-icons';
import React from 'react';

export function MeasurementsVersionToggle(props: {
  originalCaseMeasurementsVersion: MeasurementsVersionType | undefined;
  selectedCaseMeasurementsVersion: MeasurementsVersionType;
  onChange: () => void;
}) {
  return (
    <Stack direction={'row'} alignItems={'center'} spacing={1}>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={props.selectedCaseMeasurementsVersion === MeasurementsVersionType.Version2}
              onChange={props.onChange}
            />
          }
          labelPlacement={'start'}
          label={
            <Stack direction="row" alignItems={'center'} spacing={1}>
              <FontAwesomeIcon icon={faRuler} />
              <Box mx={0.25} />
              {props.selectedCaseMeasurementsVersion === MeasurementsVersionType.Version1
                ? 'v1'
                : 'v2'}
            </Stack>
          }
        />
      </FormGroup>
      {props.originalCaseMeasurementsVersion === MeasurementsVersionType.Version1 ? (
        <Chip color={'warning'} size={'small'} label={'Measurements V1'} variant={'outlined'} />
      ) : null}
    </Stack>
  );
}
