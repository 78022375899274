import { Box, colors, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { Dispatch } from 'react';
import { ICase, ICaseShipping } from '@workflow-nx/common';
import { CaseProductionTabActionType } from './CaseProductionTab/CaseProductionTab.view';
import { ShipmentTrackingGridTableRow } from './ShipmentTrackingGridTableRow';
import { DateTime } from 'luxon';

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    display: 'grid',
    rowGap: 1,
    gridTemplateColumns: 'auto repeat(2, min-content)',
    gridTemplateRows: '25 50',
    alignItems: 'center',
    backgroundColor: theme.palette.divider,
  },
  gridHeaderCell: {
    backgroundColor: 'white',
    height: 25,
    color: colors.grey[600],
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

export function ShipmentTrackingGridTable(props: {
  activeCase: ICase;
  trackingUpdate: ICaseShipping;
  readOnly?: boolean;
  dispatch: Dispatch<CaseProductionTabActionType>;
}) {
  const styles = useStyles();
  const reverseTrackingDetails = props?.trackingUpdate?.result?.tracking_details.slice(0).reverse();
  const localTimeZone = DateTime.local().toFormat('ZZZZ');

  return (
    <Box className={styles.gridContainer}>
      <Box className={styles.gridHeaderCell}>
        <Typography variant={'body1'}>Status</Typography>
      </Box>
      <Box className={styles.gridHeaderCell}>
        <Typography variant={'body1'}>Event Date ({localTimeZone})</Typography>
      </Box>
      <Box className={styles.gridHeaderCell}>
        <Typography variant={'body1'}>Location</Typography>
      </Box>
      {reverseTrackingDetails.map((event: ICaseShipping, index: number) => {
        return (
          <ShipmentTrackingGridTableRow
            readOnly={props.readOnly}
            trackingEvent={event}
            estDeliveryDate={props.trackingUpdate?.result?.est_delivery_date ?? undefined}
            key={index}
          />
        );
      })}
    </Box>
  );
}
