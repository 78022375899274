import {
  CaseSpineProfile,
  caseUtils,
  format,
  ILevels,
  ImplantType,
  IPlan,
  LevelType,
  PartType,
} from '@workflow-nx/common';
import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client/core';
import { Stack, Typography } from '@mui/material';

export function InterbodyLevelsDetailView(props: {
  levels: ILevels;
  plan: IPlan | undefined;
  spineProfile: CaseSpineProfile;
}) {
  const [planImplants, setPlanImplants] = useState<
    {
      level: LevelType;
      ap: number;
      ml: number;
      screwLength: number;
    }[]
  >([]);
  useQuery(
    gql`
      query FindPlanImplants($planId: Int!) {
        planImplants(planId: $planId) {
          level
          ap
          ml
          screwLength
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      variables: { planId: props?.plan?.planId },
      onCompleted: (data) => {
        setPlanImplants(data.planImplants ?? []);
      },
    },
  );

  function getPlanImplant(level: LevelType) {
    let planImplant = {
      level: level,
      ap: '??',
      ml: '??',
      screwLength: '??',
    };

    if (props.plan) {
      const interbody = planImplants.find((planImplant) => planImplant.level === level);

      if (interbody) {
        planImplant = {
          ...planImplant,
          ap: interbody.ap + 'mm',
          ml: interbody.ml + 'mm',
          screwLength: interbody?.screwLength + 'mm',
        };
      }
    }

    return planImplant;
  }

  const caseProfileLevels = caseUtils.getValidCaseLevels(props.levels);

  return (
    <Stack gap={1}>
      {caseProfileLevels.map((key: string) => {
        const level = key as LevelType;
        const partType = props.levels[key] as PartType;
        const implantType = caseUtils.getImplantType(partType);
        const planImplant = !['NONE', 'FUSED'].includes(partType) ? getPlanImplant(level) : null;

        return (
          <Stack direction={'row'} gap={5} justifyContent={'space-between'}>
            <Typography variant={'body2'}>
              <strong>{format.formatInterbodyLevel(level)}</strong>
            </Typography>
            <Stack alignItems={'flex-end'}>
              <Typography variant={'body1'} noWrap={true}>
                {format.formatPartType(partType)}
              </Typography>
              {planImplant ? (
                <Typography color={'textSecondary'} variant={'body2'} noWrap={true}>
                  AP: {planImplant.ap} ML: {planImplant.ml}
                </Typography>
              ) : null}
              {planImplant && [ImplantType.ALIFX].includes(implantType) ? (
                <Typography color={'textSecondary'} variant={'body2'} noWrap={true}>
                  Screw Size: {planImplant.screwLength ? planImplant.screwLength : 'unknown'}
                </Typography>
              ) : null}
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
}
