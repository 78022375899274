import { format, IPriceLevel } from '@workflow-nx/common';
import React, { useState } from 'react';
import { useConfirm } from 'material-ui-confirm';
import { useMutation, useQuery } from '@apollo/client';
import { gql } from '@apollo/client/core';
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { TableHeadCell } from '../../../components/TableHeadCell';
import { TableCell } from '../../../components/TableCell';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTrash } from '@fortawesome/pro-light-svg-icons';
import { CreatePriceLevelDialog } from './CreatePriceLevelDialog';
import { FIND_PRICE_LEVELS_BY_ORGANIZATION } from '../../../gql';

export function PriceLevelsListTable(props: { organizationId: number }) {
  const { data: priceLevelsData, refetch: refetchPriceLevels } = useQuery(
    FIND_PRICE_LEVELS_BY_ORGANIZATION,
    { variables: { organizationId: props.organizationId }, fetchPolicy: 'network-only' },
  );
  const [priceLevelId, setPriceLevelId] = useState<number | undefined>(undefined);
  const [openPriceLevelDialog, setOpenPriceLevelDialog] = useState(false);
  const confirm = useConfirm();
  const [deletePriceLevel, { loading: loadingDeletePriceLevel }] = useMutation(gql`
    mutation DeletePriceLevel($priceLevelId: Int!) {
      deletePriceLevel(priceLevelId: $priceLevelId) {
        status
      }
    }
  `);

  const handleEditPriceLevelClick = async (priceLevel: IPriceLevel) => {
    setPriceLevelId(priceLevel.priceLevelId);
    setOpenPriceLevelDialog(true);
  };

  const handleDeletePriceLevelClick = async (priceLevel: IPriceLevel) => {
    try {
      await confirm({
        title: 'Delete Price Level?',
        description: `This will permanently delete the price level.`,
      });

      await deletePriceLevel({
        variables: {
          priceLevelId: priceLevel.priceLevelId,
        },
      });
      refetchPriceLevels({
        organizationId: props.organizationId,
      });
    } catch (e) {
      console.info('Cancelled delete');
    }
  };

  return (
    <>
      <Box mt={3} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        <Typography variant={'h4'}>Price Levels</Typography>
        <Button
          variant={'outlined'}
          disabled={loadingDeletePriceLevel}
          onClick={() => setOpenPriceLevelDialog(true)}
        >
          Create Price Level
        </Button>
      </Box>
      <Box mb={2}>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadCell>Type</TableHeadCell>
              <TableHeadCell>1</TableHeadCell>
              <TableHeadCell>2</TableHeadCell>
              <TableHeadCell>3</TableHeadCell>
              <TableHeadCell>4</TableHeadCell>
              <TableHeadCell>5</TableHeadCell>
              <TableHeadCell>Start Date</TableHeadCell>
              <TableHeadCell>End Date</TableHeadCell>
              <TableHeadCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {priceLevelsData?.priceLevels?.map((priceLevel: IPriceLevel) => {
              return (
                <TableRow key={priceLevel.priceLevelId}>
                  <TableCell>{format.formatPriceLevelType(priceLevel.priceLevelType)}</TableCell>
                  <TableCell>{priceLevel.oneLevel}</TableCell>
                  <TableCell>{priceLevel.twoLevel}</TableCell>
                  <TableCell>{priceLevel.threeLevel}</TableCell>
                  <TableCell>{priceLevel.fourLevel}</TableCell>
                  <TableCell>{priceLevel.fiveLevel}</TableCell>
                  <TableCell>{format.formatISODate(priceLevel.startDate)}</TableCell>
                  <TableCell>{format.formatISODate(priceLevel.endDate)}</TableCell>
                  <TableCell>
                    <Box display={'flex'} alignItems={'center'}>
                      <IconButton
                        disabled={loadingDeletePriceLevel}
                        onClick={() => handleEditPriceLevelClick(priceLevel)}
                        size={'small'}
                      >
                        <FontAwesomeIcon icon={faPencil} />
                      </IconButton>
                      <Box mx={1} />
                      <IconButton
                        disabled={loadingDeletePriceLevel}
                        onClick={() => handleDeletePriceLevelClick(priceLevel)}
                        size={'small'}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
      {openPriceLevelDialog ? (
        <CreatePriceLevelDialog
          priceLevelId={Number(priceLevelId)}
          organizationId={props.organizationId}
          open={openPriceLevelDialog}
          onClose={(shouldUpdate?: boolean) => {
            setOpenPriceLevelDialog(false);
            setPriceLevelId(0);
            if (shouldUpdate) {
              refetchPriceLevels({
                organizationId: props.organizationId,
              });
            }
          }}
        />
      ) : null}
    </>
  );
}
