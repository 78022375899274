import { DatePicker as MatDatePicker } from '@mui/x-date-pickers';
import React from 'react';
import { testing } from '@workflow-nx/utils';
import { DateTime } from 'luxon';

type DatePickerProps = {
  field: any;
  form: any;
  label: string;
  required: boolean;
  disabled?: boolean;
  disablePast: boolean;
  shrink?: boolean;
};

const DatePicker = ({
  field,
  form,
  label,
  required,
  disablePast,
  disabled,
  shrink,
}: DatePickerProps) => {
  const currentError = form.errors[field.name];

  return (
    <MatDatePicker
      closeOnSelect={true}
      label={label}
      disabled={disabled}
      disablePast={disablePast}
      slotProps={{
        textField: {
          name: field.name,
          size: 'small',
          required: required,
          fullWidth: true,
          variant: 'outlined',
          helperText: currentError,
          error: Boolean(currentError),
          InputLabelProps: { shrink },
        },
      }}
      value={field.value ? DateTime.fromJSDate(field.value) : null}
      format="MM/dd/yyyy"
      onError={(error) => {
        if (error !== currentError) {
          form.setFieldError(field.name, error);
        }
      }}
      onChange={(date) => {
        return form.setFieldValue(field.name, date ? date.toJSDate() : null, false);
      }}
      data-test-id={testing.toKebabCase(label) + '-datepicker-field'}
    />
  );
};

export default DatePicker;
