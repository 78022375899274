import { faChevronCircleDown, faChevronCircleUp } from '@fortawesome/pro-solid-svg-icons';
import { yupResolver } from '@hookform/resolvers/yup';
import PlusIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  ImplantDrawingDocumentType,
  ImplantDrawingSettings,
  ImplantType,
  format,
} from '@workflow-nx/common';
import { IconFontButton, TextFieldRHF } from '@workflow-nx/ui';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Control, FieldValues, useFieldArray, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { UPSERT_SETTINGS } from '../../gql';
import { FieldRow } from './CloudDesign/FieldRow';

const schema = Yup.object().shape({
  documentId: Yup.string().required('Enter a document ID'),
  documentName: Yup.string().required('Enter a document name'),
  versions: Yup.array()
    .of(
      Yup.object().shape({
        versionId: Yup.string().required('Enter a version ID'),
        versionNumber: Yup.number()
          .typeError((v) => {
            if (!v.originalValue) return 'Enter a version number';

            return 'Version number must be an integer';
          })
          .required('Enter a version number'),
        deleted: Yup.boolean().required(),
        saved: Yup.boolean().required(),
      }),
    )
    .test('unique-version-number', 'Version numbers must be unique', function (value) {
      if (!value) return true;

      const versionNumbers = value.map((version) => version.versionNumber);

      const isUnique = new Set(versionNumbers).size === versionNumbers.length;

      return isUnique;
    }),
});

const otherDocumentsSchema = Yup.object().shape({
  otherDocuments: Yup.array()
    .of(
      Yup.object().shape({
        documentName: Yup.string().required('Enter a document name'),
        versionId: Yup.string().required('Enter a version ID'),
        versionNumber: Yup.number()
          .typeError((v) => {
            if (!v.originalValue) return 'Enter a version number';

            return 'Version number must be an integer';
          })
          .required('Enter a version number'),
        deleted: Yup.boolean().required(),
        saved: Yup.boolean().required(),
      }),
    )
    .test('unique-document-name', 'Document names must be unique', function (value) {
      if (!value) return true;

      const documentNames = value.map((document) => document.documentName);

      const isUnique = new Set(documentNames).size === documentNames.length;

      return isUnique;
    }),
});
type ImplantDrawingDocumentFormType = {
  documentId: string;
  documentName: string;
  versions: Array<{
    versionId: string;
    versionNumber: number;
    deleted: boolean;
    saved: boolean;
  }>;
};

type OtherDocumentsFormType = {
  otherDocuments: Array<{
    documentName: string;
    versionId: string;
    versionNumber: number;
    deleted: boolean;
    saved: boolean;
  }>;
};

const ImplantDrawingDocumentForm = (props: {
  implantType: ImplantType;
  documentName: ImplantDrawingDocumentType['documentName'];
  documentId: ImplantDrawingDocumentType['documentId'];
  versions: ImplantDrawingDocumentType['versions'];
  handleSave: (form: ImplantDrawingDocumentType) => Promise<void>;
}) => {
  const ascendingVersions: ImplantDrawingDocumentFormType['versions'] = props.versions
    .slice()
    .sort((a, b) => a.versionNumber - b.versionNumber)
    .map((v) => ({ ...v, saved: true, deleted: false }));

  const descendingVersions: ImplantDrawingDocumentFormType['versions'] = props.versions
    .slice()
    .sort((a, b) => b.versionNumber - a.versionNumber)
    .map((v) => ({ ...v, saved: true, deleted: false }));

  const [editing, setEditing] = useState(false);

  const [savingSettings, setSavingSettings] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, dirtyFields, isDirty, errors },
  } = useForm<ImplantDrawingDocumentFormType>({
    defaultValues: {
      documentId: props.documentId,
      documentName: props.documentName,
      versions: ascendingVersions,
    },
    resolver: yupResolver(schema) as any,
  });

  const {
    fields: formVersions,
    update,
    append,
  } = useFieldArray({
    control,
    name: 'versions',
  });

  const formControl = control as unknown as Control<FieldValues>;

  const handleDeleteVersion = (versionIndex: number) => {
    update(versionIndex, {
      ...formVersions[versionIndex],
      deleted: true,
    });
  };

  const handleRestoreVersion = (versionIndex: number) => {
    update(versionIndex, {
      ...formVersions[versionIndex],
      deleted: false,
    });
  };

  const resetForm = () => {
    reset({
      documentId: props.documentId,
      documentName: props.documentName,
      versions: ascendingVersions.map((v) => ({
        ...v,
        deleted: false,
      })),
    });
  };

  const toggleEditing = (shouldEdit: boolean) => {
    resetForm();

    setEditing(shouldEdit);
  };

  const handleSave = async (e: React.BaseSyntheticEvent) => {
    handleSubmit(async (form) => {
      try {
        setSavingSettings(true);

        await props.handleSave({
          documentId: form.documentId,
          documentName: form.documentName,
          versions: form.versions
            .filter((v) => !v.deleted)
            .map((v) => _.pick(v, ['versionId', 'versionNumber'])),
        });

        enqueueSnackbar(
          `${format.formatImplantTypes(props.implantType)} drawing settings saved successfully`,
          { variant: 'success' },
        );

        toggleEditing(false);
      } catch (e) {
        enqueueSnackbar(
          `${format.formatImplantTypes(props.implantType)} drawing settings failed to save`,
          { variant: 'error' },
        );

        console.error(props.implantType, 'Drawing settings failed to save', e);
      } finally {
        setSavingSettings(false);
      }
    })(e);
  };

  const handleAddVersion = () => {
    append({
      versionId: '',
      versionNumber: formVersions.length + 1,
      deleted: false,
      saved: false,
    });
  };

  const documentIdChanged = !!dirtyFields.documentId;

  const documentNameChanged = !!dirtyFields.documentName;

  const visibleVersions = editing ? formVersions : descendingVersions;

  return (
    <Stack spacing={5} my={3}>
      <Box
        display={'flex'}
        flexGrow={1}
        sx={{ justifyContent: 'space-between', alignContent: 'center' }}
      >
        <Typography fontWeight={'bold'} variant="h4">
          {format.formatImplantTypes(props.implantType)}
        </Typography>

        {editing ? (
          <>
            <Stack direction="row" spacing={3}>
              <Button
                variant={'text'}
                onClick={() => toggleEditing(false)}
                disabled={savingSettings}
              >
                Cancel
              </Button>
              <LoadingButton
                variant={'contained'}
                onClick={handleSave}
                disabled={!isDirty}
                loading={savingSettings}
              >
                Save
              </LoadingButton>
            </Stack>
          </>
        ) : (
          <Button variant={'contained'} onClick={() => toggleEditing(true)}>
            Edit
          </Button>
        )}
      </Box>

      {savingSettings ? (
        <Skeleton variant="rectangular" height={250} />
      ) : (
        <>
          <FieldRow id={'documentId'} label={'Document ID'} control={formControl}>
            {editing ? (
              <TextFieldRHF
                name={'documentId'}
                label={'ID'}
                control={formControl}
                fontColor={documentIdChanged ? 'orange' : undefined}
                disabled={!editing || isSubmitting}
              />
            ) : (
              <Typography>
                {props.documentId.length ? props.documentId : 'Missing Value'}
              </Typography>
            )}
          </FieldRow>

          <FieldRow id={'documentName'} label={'Document Name'} control={formControl}>
            {editing ? (
              <TextFieldRHF
                name={'documentName'}
                label={'Document Name'}
                control={formControl}
                fontColor={documentNameChanged ? 'orange' : undefined}
                disabled={!editing || isSubmitting}
              />
            ) : (
              <Typography>
                {props.documentName?.length ? props.documentName : 'Missing Value'}
              </Typography>
            )}
          </FieldRow>

          {editing ? (
            <>
              <Box display={'flex'} justifyContent={'flex-end'}>
                <Button variant="outlined" startIcon={<PlusIcon />} onClick={handleAddVersion}>
                  New Version
                </Button>
              </Box>
              {errors.versions?.root?.message ? (
                <Typography color="red">{errors.versions.root.message}</Typography>
              ) : null}
            </>
          ) : null}
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Version Number</TableCell>
                  <TableCell>Version ID</TableCell>
                  {editing ? (
                    <>
                      <TableCell align="center">Actions</TableCell>
                    </>
                  ) : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {visibleVersions.map((version, index) => {
                  const versionIdChanged = !!dirtyFields.versions?.[index]?.versionId;
                  const versionDeleted = !!version?.deleted;
                  const textDecoration = {
                    textDecoration: versionDeleted ? 'line-through' : undefined,
                  };

                  return (
                    <TableRow
                      key={`version-${index}`}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {editing && !versionDeleted && !version.saved ? (
                          <TextFieldRHF
                            type="number"
                            showErrorText
                            fullWidth={false}
                            variant="standard"
                            name={`versions.${index}.versionNumber`}
                            control={formControl}
                            fontColor={'orange'}
                            disabled={!editing || isSubmitting}
                          />
                        ) : (
                          <Typography style={textDecoration}>{version.versionNumber}</Typography>
                        )}
                      </TableCell>
                      <TableCell>
                        {editing && !versionDeleted ? (
                          <TextFieldRHF
                            showErrorText
                            fullWidth={false}
                            variant="standard"
                            name={`versions.${index}.versionId`}
                            control={formControl}
                            fontColor={versionIdChanged ? 'orange' : undefined}
                            disabled={!editing || isSubmitting}
                          />
                        ) : (
                          <Typography style={textDecoration}>{version.versionId}</Typography>
                        )}
                      </TableCell>
                      {editing ? (
                        <>
                          <TableCell align="center">
                            {versionDeleted ? (
                              <Button
                                color="info"
                                variant="text"
                                onClick={() => handleRestoreVersion(index)}
                              >
                                Restore
                              </Button>
                            ) : (
                              <IconButton
                                aria-label="delete"
                                color="error"
                                onClick={() => handleDeleteVersion(index)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            )}
                          </TableCell>
                        </>
                      ) : null}
                    </TableRow>
                  );
                })}
                {!visibleVersions.length ? (
                  <TableRow>
                    <TableCell colSpan={12} height={40} />
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Stack>
  );
};

const OtherDocumentsForm = (props: {
  handleChange: (event: React.SyntheticEvent, isExpanded: boolean) => void;
  handleSave: (form: ImplantDrawingSettings['otherDocuments']) => Promise<void>;
  otherDocuments: ImplantDrawingSettings['otherDocuments'];
}) => {
  const ascendingDocuments: OtherDocumentsFormType['otherDocuments'] = (props.otherDocuments ?? [])
    .slice()
    .sort()
    .map((v) => ({ ...v, saved: true, deleted: false }));

  const [editing, setEditing] = useState(false);

  const [savingSettings, setSavingSettings] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, dirtyFields, isDirty, errors },
  } = useForm<OtherDocumentsFormType>({
    defaultValues: {
      otherDocuments: ascendingDocuments,
    },
    resolver: yupResolver(otherDocumentsSchema) as any,
  });

  const {
    fields: formOtherDocuments,
    update,
    append,
  } = useFieldArray({
    control,
    name: 'otherDocuments',
  });

  const formControl = control as unknown as Control<FieldValues>;

  const handleDeleteDocument = (documentIndex: number) => {
    update(documentIndex, {
      ...formOtherDocuments[documentIndex],
      deleted: true,
    });
  };

  const handleRestoreDocument = (documentIndex: number) => {
    update(documentIndex, {
      ...formOtherDocuments[documentIndex],
      deleted: false,
    });
  };

  const resetForm = () => {
    reset({
      otherDocuments: ascendingDocuments.map((v) => ({
        ...v,
        deleted: false,
      })),
    });
  };

  const toggleEditing = (shouldEdit: boolean) => {
    resetForm();

    setEditing(shouldEdit);
  };

  const handleSave = async (e: React.BaseSyntheticEvent) => {
    handleSubmit(async (form) => {
      try {
        setSavingSettings(true);

        await props.handleSave(
          form.otherDocuments
            .filter((v) => !v.deleted)
            .map((v) => _.pick(v, ['documentName', 'versionId', 'versionNumber'])),
        );

        enqueueSnackbar(`Other document settings saved successfully`, { variant: 'success' });

        toggleEditing(false);
      } catch (e) {
        enqueueSnackbar(`Other documents settings failed to save`, { variant: 'error' });

        console.error('Other documents settings failed to save', e);
      } finally {
        setSavingSettings(false);
      }
    })(e);
  };

  const handleAddDocument = () => {
    append({
      documentName: '',
      versionId: '',
      versionNumber: 1,
      deleted: false,
      saved: false,
    });
  };

  const visibleDocuments = editing ? formOtherDocuments : ascendingDocuments;

  return (
    <Stack spacing={5} my={3}>
      <Box
        display={'flex'}
        flexGrow={1}
        sx={{ justifyContent: 'space-between', alignContent: 'center' }}
      >
        <Typography fontWeight={'bold'} variant="h4">
          Other Documents
        </Typography>

        {editing ? (
          <>
            <Stack direction="row" spacing={3}>
              <Button
                variant={'text'}
                onClick={() => toggleEditing(false)}
                disabled={savingSettings}
              >
                Cancel
              </Button>
              <LoadingButton
                variant={'contained'}
                onClick={handleSave}
                disabled={!isDirty}
                loading={savingSettings}
              >
                Save
              </LoadingButton>
            </Stack>
          </>
        ) : (
          <Button variant={'contained'} onClick={() => toggleEditing(true)}>
            Edit
          </Button>
        )}
      </Box>

      {savingSettings ? (
        <Skeleton variant="rectangular" height={250} />
      ) : (
        <>
          {editing ? (
            <>
              <Box display={'flex'} justifyContent={'flex-end'}>
                <Button variant="outlined" startIcon={<PlusIcon />} onClick={handleAddDocument}>
                  New Document
                </Button>
              </Box>
              {errors.otherDocuments?.root?.message ? (
                <Typography color="red">{errors.otherDocuments.root.message}</Typography>
              ) : null}
            </>
          ) : null}
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Document Name</TableCell>
                  <TableCell>Version Number</TableCell>
                  <TableCell>Version ID</TableCell>
                  {editing ? (
                    <>
                      <TableCell align="center">Actions</TableCell>
                    </>
                  ) : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {visibleDocuments.map((document, index) => {
                  const documentNameChanged = !!dirtyFields.otherDocuments?.[index]?.documentName;
                  const versionNumberChanged = !!dirtyFields.otherDocuments?.[index]?.versionNumber;
                  const versionIdChanged = !!dirtyFields.otherDocuments?.[index]?.versionId;
                  const documentDeleted = !!document?.deleted;
                  const textDecoration = {
                    textDecoration: documentDeleted ? 'line-through' : undefined,
                  };

                  return (
                    <TableRow
                      key={`document-${index}`}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {editing && !documentDeleted ? (
                          <TextFieldRHF
                            showErrorText
                            fullWidth={false}
                            variant="standard"
                            name={`otherDocuments.${index}.documentName`}
                            control={formControl}
                            fontColor={documentNameChanged ? 'orange' : undefined}
                            disabled={!editing || isSubmitting}
                          />
                        ) : (
                          <Typography style={textDecoration}>{document.documentName}</Typography>
                        )}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {editing && !documentDeleted ? (
                          <TextFieldRHF
                            type="number"
                            showErrorText
                            fullWidth={false}
                            variant="standard"
                            name={`otherDocuments.${index}.versionNumber`}
                            control={formControl}
                            fontColor={versionNumberChanged ? 'orange' : undefined}
                            disabled={!editing || isSubmitting}
                          />
                        ) : (
                          <Typography style={textDecoration}>{document.versionNumber}</Typography>
                        )}
                      </TableCell>
                      <TableCell>
                        {editing && !documentDeleted ? (
                          <TextFieldRHF
                            showErrorText
                            fullWidth={false}
                            variant="standard"
                            name={`otherDocuments.${index}.versionId`}
                            control={formControl}
                            fontColor={versionIdChanged ? 'orange' : undefined}
                            disabled={!editing || isSubmitting}
                          />
                        ) : (
                          <Typography style={textDecoration}>{document.versionId}</Typography>
                        )}
                      </TableCell>
                      {editing ? (
                        <>
                          <TableCell align="center">
                            {documentDeleted ? (
                              <Button
                                color="info"
                                variant="text"
                                onClick={() => handleRestoreDocument(index)}
                              >
                                Restore
                              </Button>
                            ) : (
                              <IconButton
                                aria-label="delete"
                                color="error"
                                onClick={() => handleDeleteDocument(index)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            )}
                          </TableCell>
                        </>
                      ) : null}
                    </TableRow>
                  );
                })}
                {!visibleDocuments.length ? (
                  <TableRow>
                    <TableCell colSpan={12} height={40} />
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Stack>
  );
};

export function ImplantDrawingDocumentsForm(props: {
  documents: ImplantDrawingSettings;
  onClose: (shouldUpdate: boolean) => void;
}) {
  const [open, setOpen] = useState(false);

  const [upsertSettings] = useMutation(UPSERT_SETTINGS);

  const handleSave = async (implantType: ImplantType, form: ImplantDrawingDocumentType) => {
    const newPartSettings: ImplantDrawingDocumentType = _.clone(form);

    const newSettings: ImplantDrawingSettings = {
      alif: getImplantDrawing(ImplantType.ALIF)!,
      alifx: getImplantDrawing(ImplantType.ALIFX)!,
      llif: getImplantDrawing(ImplantType.LLIF)!,
      tlifc: getImplantDrawing(ImplantType.TLIFC)!,
      tlifca: getImplantDrawing(ImplantType.TLIFCA)!,
      tlifo: getImplantDrawing(ImplantType.TLIFO)!,
      otherDocuments: props.documents.otherDocuments ?? [],
    };

    switch (implantType) {
      case ImplantType.ALIF:
        newSettings.alif = newPartSettings;
        break;
      case ImplantType.ALIFX:
        newSettings.alifx = newPartSettings;
        break;
      case ImplantType.LLIF:
        newSettings.llif = newPartSettings;
        break;
      case ImplantType.TLIFC:
        newSettings.tlifc = newPartSettings;
        break;
      case ImplantType.TLIFCA:
        newSettings.tlifca = newPartSettings;
        break;
      case ImplantType.TLIFO:
        newSettings.tlifo = newPartSettings;
        break;
      default:
        return;
    }

    await upsertSettings({
      variables: {
        implantDrawings: newSettings,
      },
    });

    props.onClose(true);
  };

  const handleSaveOtherDocuments = async (form: ImplantDrawingSettings['otherDocuments']) => {
    const newSettings: ImplantDrawingSettings = {
      alif: getImplantDrawing(ImplantType.ALIF)!,
      alifx: getImplantDrawing(ImplantType.ALIFX)!,
      llif: getImplantDrawing(ImplantType.LLIF)!,
      tlifc: getImplantDrawing(ImplantType.TLIFC)!,
      tlifca: getImplantDrawing(ImplantType.TLIFCA)!,
      tlifo: getImplantDrawing(ImplantType.TLIFO)!,
      otherDocuments: form ?? [],
    };

    await upsertSettings({
      variables: {
        implantDrawings: newSettings,
      },
    });

    props.onClose(true);
  };

  const getImplantDrawing = (implantType: ImplantType): ImplantDrawingDocumentType | null => {
    const defaultValue: ImplantDrawingDocumentType = {
      documentId: '',
      documentName: '',
      versions: [],
    };

    const neededKeys = ['documentId', 'documentName', 'versions'];

    switch (implantType) {
      case ImplantType.ALIF:
        return {
          ...defaultValue,
          ..._.pick(props.documents.alif, neededKeys),
        };
      case ImplantType.ALIFX:
        return {
          ...defaultValue,
          ..._.pick(props.documents.alifx, neededKeys),
        };
      case ImplantType.LLIF:
        return {
          ...defaultValue,
          ..._.pick(props.documents.llif, neededKeys),
        };
      case ImplantType.TLIFC:
        return {
          ...defaultValue,
          ..._.pick(props.documents.tlifc, neededKeys),
        };
      case ImplantType.TLIFCA:
        return {
          ...defaultValue,
          ..._.pick(props.documents.tlifca, neededKeys),
        };
      case ImplantType.TLIFO:
        return {
          ...defaultValue,
          ..._.pick(props.documents.tlifo, neededKeys),
        };

      default:
        return null;
    }
  };

  return (
    <>
      <Box>
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
          <Typography variant={'h4'}>Configure Implant Drawings</Typography>
          <Box>
            <IconFontButton
              icon={open ? faChevronCircleUp : faChevronCircleDown}
              onClick={() => setOpen(!open)}
            />
          </Box>
        </Box>
        {open ? (
          <Box py={2}>
            {[
              ImplantType.ALIF,
              ImplantType.ALIFX,
              ImplantType.TLIFC,
              ImplantType.TLIFCA,
              ImplantType.TLIFO,
              ImplantType.LLIF,
            ].map((implantType) => {
              const implantDrawing = getImplantDrawing(implantType)!;

              return (
                <ImplantDrawingDocumentForm
                  handleSave={(form) => handleSave(implantType, form)}
                  implantType={implantType}
                  documentName={implantDrawing.documentName}
                  documentId={implantDrawing.documentId}
                  versions={implantDrawing.versions}
                />
              );
            })}

            <OtherDocumentsForm
              handleChange={() => {}}
              handleSave={(form) => handleSaveOtherDocuments(form)}
              otherDocuments={props.documents.otherDocuments}
            />
          </Box>
        ) : null}
      </Box>
    </>
  );
}
