import { useMutation, useQuery } from '@apollo/client';
import { gql } from '@apollo/client/core';
import { faChevronCircleDown, faChevronCircleUp } from '@fortawesome/pro-solid-svg-icons';
import { Box, Typography } from '@mui/material';
import { IUser, TesterEmailSettings, UserRoleItemType, format } from '@workflow-nx/common';
import { Button, CalloutView, IconFontButton, UserRoleAutocomplete } from '@workflow-nx/ui';
import { Formik, FormikErrors, FormikHelpers, FormikValues } from 'formik';
import { sortBy } from 'lodash';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import * as Yup from 'yup';
import { UPSERT_SETTINGS } from '../../gql';

export function TesterEmailSettingsForm(props: {
  emails: TesterEmailSettings;
  onClose: (shouldUpdate: boolean) => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [upsertSettings] = useMutation(UPSERT_SETTINGS);
  const [open, setOpen] = useState(false);
  const [usersAndRoles, setUsersAndRoles] = useState<UserRoleItemType[]>([]);

  useQuery(
    gql`
      query {
        users(
          roleFilter: [
            CASE_ENGINEER
            CUSTOMER_REP
            CUSTOMER_REP_MANAGER
            FIELD_REP
            FIELD_REP_MANAGER
            OPERATIONS
            QUALITY_ENGINEER
            SUPPLY_CHAIN
            VENDOR
          ]
        ) {
          users {
            userId
            firstName
            lastName
            email
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const users: UserRoleItemType[] = [];

        data?.users?.users.forEach((user: IUser) => {
          if (!user.email.match(/@(?:[a-zA-Z0-9-]+\.)?carlsmed\.com/)) {
            users.push({
              key: user.userId.toString(),
              type: 'USER',
              value: `${format.formatName(user)} <${user.email}>`,
            });
          }
        });

        setUsersAndRoles(sortBy(users, ['value']));
      },
    },
  );

  const handleClose = () => {
    if (open) {
      props.onClose(false);
    }
    setOpen(!open);
  };

  const handleSubmitForm = async (
    values: FormikValues,
    { setStatus, setSubmitting }: FormikHelpers<any>,
  ) => {
    try {
      await upsertSettings({
        variables: {
          testerEmails: values.testerEmails,
        },
      });

      setOpen(false);
      setStatus({ success: true });
      enqueueSnackbar('Testers updated', {
        variant: 'success',
      });
      props.onClose(true);
    } catch (err) {
      console.error(err);
      setStatus({ success: false });
      enqueueSnackbar('An error occurred updating testers', {
        variant: 'error',
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleFormikErrors = (values: FormikErrors<{ testerEmails: TesterEmailSettings }>) => {
    if (values.testerEmails && Object.keys(values).length) {
      for (const [, value] of Object.entries(values.testerEmails)) {
        enqueueSnackbar(`${value}`, {
          variant: 'error',
        });
      }
    }
  };

  const yupKeyValueSchema = Yup.array().of(
    Yup.object().shape({
      key: Yup.string(),
      type: Yup.string(),
      value: Yup.string(),
    }),
  );

  return (
    <>
      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        <Typography variant={'h4'}>Configure Testers</Typography>
        <Box>
          <IconFontButton
            icon={open ? faChevronCircleUp : faChevronCircleDown}
            onClick={handleClose}
          />
        </Box>
      </Box>
      {open ? (
        <Box m={1}>
          <Formik
            initialValues={{ testerEmails: props.emails }}
            validationSchema={Yup.object().shape({
              testerEmails: Yup.object().shape({
                whitelist: yupKeyValueSchema,
              }),
            })}
            onSubmit={handleSubmitForm}
            enableReinitialize={true}
          >
            {({ submitForm, errors, touched }) => (
              <Box>
                <Box mb={4}>
                  <Typography variant={'h5'}>Testers</Typography>
                  <CalloutView>
                    <Typography variant={'body1'}>
                      Emails entered will be sent a fixed TOTP code during login.
                    </Typography>
                  </CalloutView>
                  <UserRoleAutocomplete
                    label={'Tester Emails'}
                    name={'testerEmails.whitelist'}
                    options={usersAndRoles}
                  />
                </Box>

                <Box mt={4} display={'flex'} justifyContent={'flex-end'}>
                  <Button
                    variant={'outlined'}
                    color={'primary'}
                    label={'Save'}
                    onClick={() => {
                      submitForm();

                      handleFormikErrors(errors);
                    }}
                    disabled={!Object.keys(touched).length}
                  />
                </Box>
              </Box>
            )}
          </Formik>
        </Box>
      ) : null}
    </>
  );
}
