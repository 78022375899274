import { useQuery } from '@apollo/client';
import { Box, Divider, Typography } from '@mui/material';
import { caseUtils, ProductionPipelineEstimate, ProductionStageType } from '@workflow-nx/common';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { ESTIMATE_CASE_PRODUCTION_TIME } from '../gql';
import { DashboardCardView } from '../views/DashboardView/DashboardCardView';
import DatePickerValue from './DatePickerValue';

const NON_PRODUCTION_STEPS = [
  { stage: ProductionStageType.PreProduction, subStage: null },
  { stage: ProductionStageType.SurgeonApproval, subStage: null },
];

export default function EstimateCaseDeliveryForm() {
  const currentDate = useMemo(() => new Date().toISOString(), []);
  const { refetch: estimateCaseProductionTime, data: caseProductionTimeData } = useQuery<{
    caseProductionTime: ProductionPipelineEstimate;
  }>(ESTIMATE_CASE_PRODUCTION_TIME, {
    variables: {
      startDate: currentDate,
    },
  });
  const [estimateCaseStartTime, setEstimateCaseStartTime] = useState<string | null>(currentDate);

  const estimatePipeline = caseProductionTimeData?.caseProductionTime.pipeline || [];

  const detailedPipelineSteps = estimatePipeline.filter((step) =>
    NON_PRODUCTION_STEPS.find(
      (nonProdStep) => nonProdStep.stage === step.stage && nonProdStep.subStage === step.subStage,
    ),
  );

  const productionPipelineSteps = estimatePipeline.filter(
    (step) =>
      !NON_PRODUCTION_STEPS.find(
        (nonProdStep) => nonProdStep.stage === step.stage && nonProdStep.subStage === step.subStage,
      ),
  );

  const totalPipelineEstimateDays = _.sumBy(estimatePipeline, 'lengthInBusinessDays');

  const totalPipelineTargetDays = _.sumBy(estimatePipeline, 'vendorEstimateInBusinessDays');

  const deviationFromTargetDays = totalPipelineEstimateDays - Math.round(totalPipelineTargetDays);

  const productionPipelineEstimateDays = _.sumBy(productionPipelineSteps, 'lengthInBusinessDays');

  return (
    <DashboardCardView fitContentHeight label="Estimate Case Delivery Date">
      <Box p={2}>
        <Box display="flex" alignItems={'center'} flexWrap="wrap">
          <Typography>If a case is ready to start by</Typography>
          <Box width={100} mx={1}>
            <DatePickerValue
              inputProps={{ style: { textAlign: 'center' } }}
              shrink
              variant="standard"
              onChange={(value) => {
                setEstimateCaseStartTime(value);
                estimateCaseProductionTime({ startDate: value });
              }}
              value={
                estimateCaseStartTime ? DateTime.fromISO(estimateCaseStartTime).toJSDate() : null
              }
            />
          </Box>
          <Typography>, the earliest date the case will be ready by is:</Typography>
          <Box ml={1}>
            <Typography variant="h5">
              {caseProductionTimeData?.caseProductionTime?.endDate
                ? DateTime.fromISO(caseProductionTimeData?.caseProductionTime?.endDate).toFormat(
                    'LL/dd/yyyy',
                  )
                : ''}
            </Typography>
          </Box>
        </Box>
        <Box my={3}>
          <Divider />
        </Box>

        <Box display="flex" alignItems={'center'} flexWrap="wrap">
          {detailedPipelineSteps?.map((stepEstimate, index) => {
            return (
              <div key={index}>
                <Typography variant="h5">
                  {caseUtils.getCaseProductionStageName(stepEstimate.stage, stepEstimate.subStage)}:
                </Typography>
                <Typography color="textSecondary">
                  &nbsp;{stepEstimate.lengthInBusinessDays} business day
                  {stepEstimate.lengthInBusinessDays > 1 ? 's' : ''},&nbsp;
                </Typography>
              </div>
            );
          })}
          <Typography variant="h5">Production:</Typography>
          <Typography color="textSecondary">
            &nbsp;{productionPipelineEstimateDays} business day
            {productionPipelineEstimateDays > 1 ? 's' : ''},&nbsp;
          </Typography>

          <Typography variant="h5">Total:</Typography>
          <Typography color="textSecondary">
            &nbsp;{totalPipelineEstimateDays} business day
            {totalPipelineEstimateDays > 1 ? 's' : ''}&nbsp;
            {deviationFromTargetDays > 0
              ? `(extended by ${deviationFromTargetDays} day${
                  deviationFromTargetDays > 1 ? 's' : ''
                }
              because of holidays/outages)`
              : null}
          </Typography>
        </Box>
      </Box>
    </DashboardCardView>
  );
}
