import React from 'react';
import { Box, TextField } from '@mui/material';
import { FormikValues, useFormikContext } from 'formik';
import { testing } from '@workflow-nx/utils';

type OutlinedFileTextFieldProps = {
  name: string;
  onBlur?: React.FocusEventHandler;
  onChange: React.ChangeEventHandler;
  disabled: boolean;
  required?: boolean;
  accept?: string;
  label: string;
};

export function OutlinedFileTextField(props: OutlinedFileTextFieldProps) {
  const fileInput = React.useRef(null);
  const { values, touched, errors } = useFormikContext<FormikValues>();

  return (
    <label htmlFor={'outlined-' + props.name + '-button'}>
      <input
        id={'outlined-' + props.name + '-button'}
        name={props.name}
        type="file"
        accept={props.accept ?? ''}
        ref={fileInput}
        onBlur={props.onBlur}
        onChange={(event) => {
          props.onChange(event);
        }}
        style={{ display: 'none' }}
        data-test-id={testing.toKebabCase(props.label) + '-fileupload'}
      />

      <Box display={'flex'} justifyContent={'space-between'}>
        <TextField
          error={Boolean((touched as any)[props.name] && (errors as any)[props.name])}
          helperText={(touched as any)[props.name] && (errors as any)[props.name]}
          label={props.label}
          variant="outlined"
          fullWidth={true}
          color="secondary"
          disabled={props.disabled}
          onClick={() => (fileInput?.current as any)?.click()}
          required={props.required}
          aria-readonly={true}
          value={values[props.name]?.name ?? 'No File Selected'}
          size={'small'}
        />
      </Box>
    </label>
  );
}
