import React from 'react';
import CustomDialog from './CustomDialog';
import { IAsset, format } from '@workflow-nx/common';
import { Box, Typography } from '@mui/material';
import { Alert } from '@mui/material';

export function AssetMetadataViewDialog(props: {
  open: boolean;
  asset?: IAsset;
  onClose: () => void;
}) {
  const metadata = props?.asset?.metadata ?? {};

  return (
    <CustomDialog
      maxWidth={'sm'}
      open={props.open}
      title={`Viewing Asset ${format.formatAssetType(props?.asset?.assetType)} Metadata`}
      onClose={() => {
        props.onClose();
      }}
      positiveActionButtons={[]}
    >
      {props.open ? (
        <Box height={400}>
          {Object.keys(metadata).length > 0 ? (
            Object.keys(metadata).map((metadataKey) => {
              const metadataItem = metadata[metadataKey];
              return (
                <Box display={'flex'} mb={1}>
                  <Box width={200}>
                    <Typography variant={'body1'} noWrap={true}>
                      <strong>{metadataKey}</strong>
                    </Typography>
                  </Box>
                  <Box mx={2} />
                  <Typography variant={'body1'}>{metadataItem || <>&mdash;</>}</Typography>
                </Box>
              );
            })
          ) : (
            <Alert severity={'warning'}>There is no metadata for this DICOM image.</Alert>
          )}
        </Box>
      ) : null}
    </CustomDialog>
  );
}
