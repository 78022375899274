import React from 'react';
import App from './app/app';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'nprogress/nprogress.css';
import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import * as Sentry from '@sentry/react';
import config from './app/extras/config';
import { isValidToken } from './app/utils/user';
import { SentryProvider } from './app/contexts/SentryContext';
import { createRoot } from 'react-dom/client';
import { onError } from '@apollo/client/link/error';

const httpLink = new HttpLink({ uri: config.auth.gql, fetchOptions: {} });
const authLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem('accessToken');
  const operationName = operation.operationName;

  /* if the token has expired and the user isn't trying to validate a token, then redirect back to log in */
  if (token && operationName !== 'ValidateToken') {
    if (!isValidToken(token)) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('jwt_token');
      localStorage.removeItem('user');

      window.location.href = '/login?reason=EXPIRED';
      return null;
    }
  }

  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
      'cm-client-id': config.client.id ?? '',
    },
  });

  return forward(operation);
});

const errorLink = onError((errorHandler) => {
  if (errorHandler.graphQLErrors) {
    for (const err of errorHandler.graphQLErrors) {
      switch (err?.extensions?.code) {
        case 'INVALID_CLIENT_ID':
        case 'INVALID_CLIENT_ROLE':
        case 'INVALID_JWT_TOKEN':
          localStorage.removeItem('accessToken');
          localStorage.removeItem('jwt_token');
          localStorage.removeItem('user');

          window.location.href = '/login?reason=CLIENT_ERROR';
          break;
      }
    }
  }
});

const responseErrorLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const errors = response.errors || [];

    if (errors.length > 0) {
      const errorMessages = errors.map((e) => e.message);

      Sentry.captureEvent({
        message: errorMessages.join('/'),
        extra: {
          errors: JSON.stringify(errors),
          operationName: operation.operationName,
          payload: operation.variables,
          appVersion: config.version,
          environment: config.environmentName,
        },
      });
    }

    return response;
  });
});

const client = new ApolloClient({
  link: responseErrorLink.concat(errorLink.concat(authLink.concat(httpLink))),
  cache: new InMemoryCache(),
});

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <SentryProvider>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </SentryProvider>,
);
