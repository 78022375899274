import { Autocomplete, FormControl, Box, TextField, Chip, Typography } from '@mui/material';
import { format, IUser } from '@workflow-nx/common';
import { useField, Field, FieldProps } from 'formik';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';

export const FormikUserAutocomplete = ({
  name,
  label,
  disabled,
  required,
  onChange,
  users,
  startWithLastName = false,
}: {
  disabled?: boolean;
  required?: boolean;
  label?: string;
  name: string;
  onChange?: (newValue: string | null) => void;
  users: IUser[];
  startWithLastName?: boolean;
}) => {
  const [formVal, meta] = useField<number>(name);

  const userOptions: number[] = useMemo(
    () => _.sortBy(users, (u) => format.formatName(u, startWithLastName)).map((u) => u.userId),
    [users, startWithLastName],
  );

  const userToNameMap: Record<number, IUser> = useMemo(
    () => _.mapKeys(users, (u) => u.userId),
    [users],
  );

  const [inputValue, setInputValue] = useState('');

  const getUserOptionLabel = useCallback(
    (userId: number) => {
      const thisUser = userToNameMap[userId];

      return thisUser ? format.formatName(thisUser, startWithLastName) : '';
    },
    [userToNameMap, startWithLastName],
  );

  useEffect(() => {
    if (!inputValue) {
      const userName = formVal.value ? getUserOptionLabel(formVal.value) : '';

      setInputValue(userName);
    }
  }, [getUserOptionLabel, formVal.value]);

  return (
    <FormControl component="fieldset" fullWidth={true}>
      <Box>
        <Field name={name}>
          {(fieldProps: FieldProps) => {
            const currentUserId = fieldProps?.field?.value ? fieldProps?.field?.value : null;

            return (
              <Autocomplete
                clearOnBlur={true}
                onInputChange={(e, val) => setInputValue(val)}
                clearOnEscape={true}
                disabled={disabled}
                options={userOptions}
                value={currentUserId}
                inputValue={inputValue}
                onChange={(_, option) => {
                  fieldProps.form.setFieldValue(name, option || null);
                  onChange?.(option ? option.toString() : null);
                }}
                onBlur={() => {
                  fieldProps.form.setFieldTouched(name, true);
                }}
                getOptionLabel={getUserOptionLabel}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      label={label}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      size={'small'}
                      error={Boolean(meta.touched && meta.error)}
                      helperText={meta.touched && meta.error ? meta.error : ''}
                      tabIndex={1}
                      required={required}
                      inputProps={{
                        ...params.inputProps,
                      }}
                      variant="outlined"
                      onKeyDown={(e) => {
                        // stop enter from submitting a form
                        if (e.key === 'Enter') {
                          e.preventDefault();
                        }
                      }}
                    />
                  );
                }}
              />
            );
          }}
        </Field>
      </Box>
    </FormControl>
  );
};

export const UserAutocomplete = ({
  label,
  disabled,
  required,
  users,
  value,
  onChange,
  fullWidth,
  startWithLastName = false,
}: {
  fullWidth?: boolean;
  disabled?: boolean;
  required?: boolean;
  label?: string;
  name: string;
  value?: string;
  onChange?: (newValue: string | null) => void;
  users: IUser[];
  startWithLastName?: boolean;
}) => {
  const userOptions: string[] = useMemo(
    () =>
      _.sortBy(users, (u) => format.formatName(u, startWithLastName)).map((u) =>
        u.userId.toString(),
      ),
    [users, startWithLastName],
  );

  const userToNameMap: Record<string, IUser> = useMemo(
    () => _.mapKeys(users, (u) => u.userId.toString()),
    [users],
  );

  const [inputValue, setInputValue] = useState('');

  const getUserOptionLabel = useCallback(
    (userId: string) => {
      const thisUser = userToNameMap[userId];

      return thisUser ? format.formatName(thisUser, startWithLastName) : '';
    },
    [userToNameMap, startWithLastName],
  );

  useEffect(() => {
    if (!inputValue) {
      const userName = value ? getUserOptionLabel(value) : '';

      setInputValue(userName);
    }
  }, [getUserOptionLabel, value]);

  return (
    <FormControl component="fieldset" fullWidth={true}>
      <Box>
        <Autocomplete
          clearOnBlur={true}
          onInputChange={(_, val) => setInputValue(val)}
          clearOnEscape={true}
          disabled={disabled}
          options={userOptions}
          value={value ?? null}
          fullWidth={fullWidth}
          inputValue={inputValue}
          onChange={(e, option) => {
            onChange?.(option);
          }}
          getOptionLabel={getUserOptionLabel}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                placeholder="None"
                label={label}
                fullWidth={fullWidth}
                InputLabelProps={{
                  shrink: true,
                }}
                size={'small'}
                tabIndex={1}
                required={required}
                inputProps={{
                  ...params.inputProps,
                }}
                variant="outlined"
                onKeyDown={(e) => {
                  // stop enter from submitting a form
                  if (e.key === 'Enter') {
                    e.preventDefault();
                  }
                }}
              />
            );
          }}
        />
      </Box>
    </FormControl>
  );
};

export const MultiUserAutocomplete = ({
  label,
  disabled,
  required,
  users,
  value,
  onChange,
  fullWidth,
  startWithLastName = false,
}: {
  fullWidth?: boolean;
  disabled?: boolean;
  required?: boolean;
  label?: string;
  name: string;
  value?: IUser[];
  onChange?: (newValue: string[]) => void;
  users: IUser[];
  startWithLastName?: boolean;
}) => {
  const userOptions: IUser[] = useMemo(
    () => _.sortBy(users, (u) => format.formatName(u, startWithLastName)),
    [users, startWithLastName],
  );

  const userToNameMap: Record<string, IUser> = useMemo(
    () => _.mapKeys(users, (u) => u.userId.toString()),
    [users],
  );

  const getUserOptionLabel = useCallback(
    (user: IUser) => {
      return format.formatName(user, startWithLastName);
    },
    [userToNameMap, startWithLastName],
  );

  return (
    <FormControl component="fieldset" fullWidth={true}>
      <Box>
        <Autocomplete
          clearOnBlur={true}
          clearOnEscape={true}
          disabled={disabled}
          multiple
          options={userOptions}
          value={value ?? []}
          fullWidth={fullWidth}
          groupBy={(user) => user?.organization?.name}
          onChange={(e, users) => {
            onChange?.(users.map((o) => o.userId.toString()));
          }}
          getOptionLabel={getUserOptionLabel}
          renderTags={(userIds) => {
            return userIds.map((userId, index) => {
              return (
                <Box mr={1} mb={0.5}>
                  <Chip
                    key={index}
                    variant={'outlined'}
                    deleteIcon={<FontAwesomeIcon size={'lg'} icon={faTimesCircle} />}
                    label={
                      <Box display={'flex'} alignItems={'center'}>
                        <Typography>{getUserOptionLabel(userId)}</Typography>
                      </Box>
                    }
                  />
                </Box>
              );
            });
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                placeholder="None"
                label={label}
                fullWidth={fullWidth}
                InputLabelProps={{
                  shrink: true,
                }}
                size={'small'}
                tabIndex={1}
                required={required}
                inputProps={{
                  ...params.inputProps,
                }}
                variant="outlined"
                onKeyDown={(e) => {
                  // stop enter from submitting a form
                  if (e.key === 'Enter') {
                    e.preventDefault();
                  }
                }}
              />
            );
          }}
        />
      </Box>
    </FormControl>
  );
};
