import { Field, useField } from 'formik';
import { FormControlLabel, Checkbox as MuiCheckbox } from '@mui/material';
import React, { ChangeEvent, useCallback } from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  error: {
    color: 'red',
  },
}));

export function Checkbox(props: { name: string; label: React.ReactNode; disabled: boolean }) {
  const styles = useStyles();
  const [, meta] = useField(props.name);
  const change = useCallback(
    (
        setFieldTouched: (field: string, value: any, shouldValidate?: boolean) => void,
        onChange: (event: ChangeEvent<HTMLInputElement>) => void,
      ) =>
      (event: ChangeEvent<HTMLInputElement>) => {
        event.persist();
        setFieldTouched(props.name, true, false);
        onChange(event);
      },
    [props.name],
  );

  return (
    <FormControlLabel
      disabled={props.disabled}
      label={props.label}
      control={
        <Field name={props.name} id={props.name}>
          {({ field, form }: { field: any; form: any }) => (
            <MuiCheckbox
              className={meta.touched && meta.error ? styles.error : ''}
              disabled={form.isSubmitting || props.disabled}
              id={props.name}
              name={props.name}
              onChange={change(form.setFieldTouched, field.onChange)}
              checked={field.value || false}
              value={field.value || false}
              size={'small'}
            />
          )}
        </Field>
      }
    />
  );
}
