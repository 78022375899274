export enum PlanAssetColorType {
  DaisyVertebrae = '#FFD60A',
  PreopVertebrae = '#30B0C7',
  PreopHardware = '#C0C0C0',
  PlanVertebrae = '#EAEAEA',
  PlanNotUsedVertebrae = '#A9A9A9',
  PlanDiffVertebrae = '#e0d7c6',
  PlanImplantTemplate = '#FFFF8F',
  PlanImplantCut = '#8E8E93',
  PlanImplantScrew = '#9ae14d',
}

export enum VertebraePositionColor {
  AnteriorTop = '#fa8072',
  AnteriorBottom = '#8b0000',
  PosteriorTop = '#ffff99',
  PosteriorBottom = '#cccc00',
  PatientRightTop = '#87cefa',
  PatientRightBottom = '#4682b4',
  PatientLeftTop = '#90ee90',
  PatientLeftBottom = '#228b22',
}
