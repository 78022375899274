import { ICase } from '@workflow-nx/common';
import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { green, grey, red, yellow } from '@mui/material/colors';
import { date } from '@workflow-nx/utils';

const useStyles = makeStyles(() => ({
  root: { fontSize: '1.5em', fontWeight: 'bold' },
  progressTextUnknown: {
    color: `${grey[500]} !important`,
  },
  progressTextWarning: {
    color: `${yellow[500]} !important`,
  },
  progressTextDanger: {
    color: `${red[500]} !important`,
  },
  progressTextOk: {
    color: `${green[500]} !important`,
  },
  progressBarUnknown: {
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: `${grey[500]} !important`,
    },
  },
  progressBarWarning: {
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: `${yellow[500]} !important`,
    },
  },
  progressBarDanger: {
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: `${red[500]} !important`,
    },
  },
  progressBarOk: {
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: `${green[500]} !important`,
    },
  },
}));

export function DaysToManufacturingView(props: {
  activeCase: ICase;
  manufacturingCloseWindowInDays: number;
}) {
  const styles = useStyles();
  const [daysUntilManufacturingCloseWindow, setDaysUntilManufacturingCloseWindow] = useState<
    number | undefined
  >(undefined);

  const getProgressTextColor = () => {
    if (daysUntilManufacturingCloseWindow === undefined) {
      return styles.progressTextUnknown;
    }
    if (daysUntilManufacturingCloseWindow < 14 && daysUntilManufacturingCloseWindow > 7) {
      return styles.progressTextWarning;
    }
    if (daysUntilManufacturingCloseWindow < 7) {
      return styles.progressTextDanger;
    }
    return styles.progressTextOk;
  };

  useEffect(() => {
    if (props?.activeCase?.surgeryDate) {
      const dueDate = date.subtract(new Date(props?.activeCase.surgeryDate), {
        days: props.manufacturingCloseWindowInDays,
      });
      const since = date.daysSince(dueDate, new Date());
      setDaysUntilManufacturingCloseWindow(since < 0 ? 0 : since);
    }
  }, [props]);

  return (
    <Box textAlign={'center'}>
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
        {daysUntilManufacturingCloseWindow === undefined ? (
          <Typography className={`${styles.root} ${getProgressTextColor()}`} variant={'body2'}>
            TBD
          </Typography>
        ) : (
          <Typography className={`${styles.root} ${getProgressTextColor()}`} variant={'body2'}>
            {daysUntilManufacturingCloseWindow}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
