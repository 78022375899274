import { IPlanImplant, LevelType } from '@workflow-nx/common';
import { Scene } from 'babylonjs';
import VisibilityView from './VisibilityView';

export function MiniVisibilityView(props: {
  planImplant: IPlanImplant;
  levelType: LevelType;
  scene: Scene;
}) {
  const meshName = `${props.levelType}_${props.planImplant?.partType}_MINI`;

  // TODO - // Reduce reflection
  // (mesh.material as StandardMaterial).specularColor = new Color3(0.5, 0.5, 0.5);

  return <VisibilityView {...props} label="Mini Implant" meshName={meshName} />;
}
