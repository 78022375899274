import { Box, Card, CardContent, Container, Grid } from '@mui/material';
import React, { useReducer, useState } from 'react';
import { useQuery } from '@apollo/client';
import Page from '../../../components/Page';
import { Permission, TaskStatusType, UserRoleType } from '@workflow-nx/common';
import { FIND_TASKS } from '../../../gql';
import { EditTaskStatusDialog } from '../../shared/EditTaskStatusDialog';
import { TaskListTable } from './TaskListTable';
import { Heading } from '../../../components/Heading';
import { Alert } from '@mui/material';
import { ListTasksReducer, ListTasksStateType } from './ListTasks.reducer';
import useAuth from '../../../hooks/useAuth';
import { CustomAppBar } from '../../../components/CustomAppBar';
import { TaskTableFilter } from './TaskTableFilter';
import { Globals } from '../../../layouts/DashboardLayout';

const ListTasksView = () => {
  const auth = useAuth();
  const [openTaskStatusEditDialog, setOpenTaskStatusEditDialog] = useState(false);
  const [taskId, setTaskId] = useState<number | undefined>();
  const currentFieldRepUser = auth?.hasRole?.([UserRoleType.FieldRep]) ? auth?.user : undefined;
  const {
    loading: loadingTasks,
    data,
    refetch,
  } = useQuery(FIND_TASKS, {
    variables: {
      assignedId: currentFieldRepUser?.userId ?? undefined,
      status: TaskStatusType.Open,
      take: Globals.DefaultListPageSize,
      skip: 0,
    },
    fetchPolicy: 'cache-and-network',
  });

  const initialState: ListTasksStateType = {
    orderBy: { dueDate: 'asc' },
    actionType: '',
    assignedId: currentFieldRepUser?.userId.toString() ?? undefined,
    pageNumber: 0,
    pageSize: Globals.DefaultListPageSize,
  };

  const [state, dispatch] = useReducer(ListTasksReducer(refetch), initialState);
  const canAccessTasks = auth?.hasPermission?.([Permission.ListTasks]);
  const tasksFound = data?.tasks?.count === 1 ? `1 task` : `${data?.tasks?.count ?? 0} tasks`;

  return (
    <Page title={'Tasks'}>
      <Container maxWidth={false}>
        <CustomAppBar title={`Tasks`} />
        <Box>
          <Grid container>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {canAccessTasks ? (
                  <>
                    <Grid item xs={2}>
                      <Box display={'flex'} alignItems={'center'} height={40} mb={1}>
                        <Heading label={'Filters'} />
                      </Box>
                      <Card>
                        <CardContent>
                          <TaskTableFilter
                            state={state}
                            dispatch={dispatch}
                            loading={loadingTasks}
                          />
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={10}>
                      <Box
                        display={'flex'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        mb={2}
                      >
                        <Heading label={`Results (${tasksFound})`} />
                      </Box>
                      <Box>
                        <Card>
                          <CardContent style={{ height: 'calc(100vh - 228px)' }}>
                            {data?.tasks?.tasks?.length > 0 ? (
                              <TaskListTable
                                dispatch={dispatch}
                                orderBy={state.orderBy}
                                page={state.pageNumber}
                                rowsPerPage={state.pageSize}
                                totalCaseCount={data?.tasks?.count ?? 0}
                                loading={loadingTasks}
                                tasks={data?.tasks?.tasks ?? []}
                                onView={(taskId: number) => {
                                  setTaskId(taskId);
                                  setOpenTaskStatusEditDialog(true);
                                }}
                              />
                            ) : (
                              <Alert severity={'info'}>There is no task assigned to you. </Alert>
                            )}
                          </CardContent>
                        </Card>
                      </Box>
                    </Grid>
                  </>
                ) : (
                  <Alert severity={'warning'}>Access denied.</Alert>
                )}
              </Grid>
            </Grid>
          </Grid>
          <EditTaskStatusDialog
            taskId={taskId}
            open={openTaskStatusEditDialog}
            onClose={() => {
              setOpenTaskStatusEditDialog(false);
              setTaskId(undefined);
              refetch();
            }}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default ListTasksView;
