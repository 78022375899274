import Refresh from '@mui/icons-material/RefreshOutlined';
import ThreeSixty from '@mui/icons-material/ThreeSixtyOutlined';
import OrthoIcon from '@mui/icons-material/ViewInAr';
import PerspectiveIcon from '@mui/icons-material/Visibility';
import { Box, Fab } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { BabylonSceneManager, CameraMode } from '@workflow-nx/scene';
import { useState } from 'react';

const useStyles = makeStyles(() => ({
  spineCanvasRoot: {
    '& canvas': {
      width: '100%',
      height: '100%',
      touchAction: 'none',
    },
  },
  canvasActions: {
    position: 'absolute',
    bottom: 10,
    right: 10,
  },
  fab: {
    '&:hover': {
      backgroundColor: '#d3d3d3',
    },
    backgroundColor: '#d3d3d3',
  },
}));

const BabylonSceneMenu = (props: { scene?: BabylonSceneManager; loading: boolean }) => {
  const [cameraMode, setCameraMode] = useState<CameraMode>(CameraMode.Orthographic);
  const [rotateCameraEnabled, setRotateCameraEnabled] = useState(false);

  const styles = useStyles();

  const handleChangeCameraMode = (newCameraMode: CameraMode) => {
    if (!props.scene) return;

    setCameraMode(newCameraMode);

    props.scene.changeCameraMode(newCameraMode);
  };

  const toggleCameraRotation = (enabled: boolean) => {
    if (!props.scene) return;

    setRotateCameraEnabled(enabled);

    props.scene.toggleCameraRotation(enabled);
  };

  return (
    <>
      {props.scene && !props.loading ? (
        <Box
          display={'flex'}
          className={styles.canvasActions}
          justifyContent={'center'}
          alignItems={'center'}
          width={'100%'}
        >
          <Fab
            className={styles.fab}
            sx={{ marginRight: 1 }}
            size="small"
            color="primary"
            aria-label="rotation-mode"
            onClick={() => toggleCameraRotation(!rotateCameraEnabled)}
          >
            {rotateCameraEnabled ? (
              <Refresh sx={{ color: 'gray' }} />
            ) : (
              <ThreeSixty sx={{ color: 'gray' }} />
            )}
          </Fab>
          <Fab
            className={styles.fab}
            size="small"
            color="primary"
            aria-label="camera-mode"
            onClick={() => {
              let newCameraMode =
                cameraMode === CameraMode.Orthographic
                  ? CameraMode.Perspective
                  : CameraMode.Orthographic;

              handleChangeCameraMode(newCameraMode);
            }}
          >
            {cameraMode === CameraMode.Orthographic ? (
              <OrthoIcon sx={{ color: 'gray' }} />
            ) : (
              <PerspectiveIcon sx={{ color: 'gray' }} />
            )}
          </Fab>
        </Box>
      ) : null}
    </>
  );
};

export default BabylonSceneMenu;
