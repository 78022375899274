import { CaseStageType, CaseType, ICase, UserRoleType } from '@workflow-nx/common';
import React, { useState } from 'react';
import { Button, Stack } from '@mui/material';
import { FormattedFieldView } from '../../../../components/FormattedFieldView';
import {
  getStudyDateDescription,
  UpdateCaseStudyDateExpiryDialog,
} from './UpdateCaseStudyDateExpiryDialog';
import useAuth from '../../../../hooks/useAuth';

export function CaseStudyDateExpiryView(props: {
  activeCase: ICase;
  caseEditable: boolean;
  onChange?: () => void;
}) {
  const auth = useAuth();
  const [showDialog, setShowDialog] = useState(false);

  const validEditingRole = auth.hasRole?.([
    UserRoleType.Operations,
    UserRoleType.SiteAdministrator,
    UserRoleType.SupplyChain,
    UserRoleType.QualityEngineer,
  ]);
  const isCaseApproved = [
    CaseStageType.Complete,
    CaseStageType.Production,
    CaseStageType.Ready,
  ].includes(props.activeCase.stage);

  return (
    <>
      <Stack gap={1}>
        <FormattedFieldView
          label={'Nearest Expiring Asset Study Date'}
          value={getStudyDateDescription(props.activeCase.studyDate)}
        />
        {props.caseEditable ? (
          <Button
            variant={'text'}
            onClick={() => setShowDialog(true)}
            disabled={
              isCaseApproved && props.activeCase?.caseType === CaseType.Live
                ? !validEditingRole
                : false
            }
          >
            Change
          </Button>
        ) : null}
        <UpdateCaseStudyDateExpiryDialog
          open={showDialog}
          activeCase={props.activeCase}
          onClose={(shouldUpdate) => {
            if (shouldUpdate) {
              props.onChange?.();
            }
            setShowDialog(false);
          }}
        />
      </Stack>
    </>
  );
}
