import {
  Checkbox as MuiCheckbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from '@mui/material';
import React, { ChangeEvent, useEffect } from 'react';
import { testing } from '@workflow-nx/utils';

export function CheckboxList(props: {
  values: { [key: string]: boolean };
  label: string;
  disabled?: boolean;
  required?: boolean;
  listItems: { key: string; value: string }[];
  onChange: (values: { [key: string]: boolean }) => void;
  onLabelRender?: (key: string, value: string) => string;
  display?: 'inline';
}) {
  const [selected, setSelected] = React.useState<{ [key: string]: boolean }>({});

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const updatedSelected = { ...selected, [event.target.name]: event.target.checked };
    setSelected(updatedSelected);
    props?.onChange(updatedSelected);
  };

  useEffect(() => {
    setSelected(props.values ?? {});
  }, [props.values]);

  return (
    <FormControl required={props.required} component="fieldset">
      <FormLabel component="legend">{props.label}</FormLabel>
      <FormGroup style={{ display: props.display }}>
        {props.listItems.map((listItem) => {
          return (
            <FormControlLabel
              key={listItem.key}
              control={
                <MuiCheckbox
                  name={listItem.key}
                  data-test-id={`${testing.toKebabCase(listItem.key)}-${testing.toKebabCase(
                    listItem.key,
                  )}-checkbox`}
                  checked={!!selected?.[listItem.key]}
                  onChange={handleChange}
                  value={listItem.key}
                  disabled={props.disabled}
                />
              }
              label={props?.onLabelRender?.(listItem.key, listItem.value) ?? listItem.value}
            />
          );
        })}
      </FormGroup>
    </FormControl>
  );
}
