import { useQuery } from '@apollo/client';
import {
  AssetType,
  CaseStageType,
  IAsset,
  ICase,
  Permission,
  ValidFileExtensions,
} from '@workflow-nx/common';
import { useReducer } from 'react';
import { FIND_ASSETS } from '../../../../gql';
import useAuth from '../../../../hooks/useAuth';
import { AssetGridTable } from '../../../../components/AssetGridTable/AssetGridTable';

type ActionType = {
  type: string;
  data: any;
};

type StateType = {
  bookingAsset?: IAsset;
};

export function BookingSheetAssetGrid(props: { activeCase: ICase }) {
  const auth = useAuth();
  const hasPermission = !!auth.hasPermission?.([Permission.ManageCase]);
  const isStageComplete = props.activeCase.stage === CaseStageType.Complete;

  const { refetch, loading: loadingAsset } = useQuery(FIND_ASSETS, {
    variables: {
      caseId: props.activeCase.caseId,
      assetTypeFilter: [AssetType.BookingSheet],
    },
    onCompleted: (data) => {
      readyDispatch({ type: 'INIT', data: data?.assets?.[0] ?? undefined });
    },
  });

  const initialState: StateType = {
    bookingAsset: undefined,
  };

  const readyReducer = (state: StateType, action: ActionType): StateType => {
    let updatedState = state;

    switch (action.type) {
      case 'ASSET_UPLOADED':
      case 'ASSET_REPLACED':
      case 'ASSET_DELETED': {
        const replacedAsset = action.data;

        updatedState = {
          bookingAsset: replacedAsset ?? undefined,
        };
        refetch();
        break;
      }
      case 'INIT': {
        const caseAsset = action?.data ?? undefined;

        updatedState = {
          bookingAsset: caseAsset,
        };
        break;
      }
      default:
        throw new Error();
    }
    return updatedState;
  };

  const [state, readyDispatch] = useReducer(readyReducer, initialState);

  return (
    <AssetGridTable
      dispatch={readyDispatch}
      caseId={props?.activeCase?.caseId}
      assets={state?.bookingAsset ? [state.bookingAsset] : []}
      showAdd={hasPermission && !loadingAsset}
      allowReplace={hasPermission && !loadingAsset}
      validFileExtensions={[
        ValidFileExtensions.JPG,
        ValidFileExtensions.JPEG,
        ValidFileExtensions.PNG,
        ValidFileExtensions.PDF,
      ]}
      validAssets={[AssetType.BookingSheet]}
      disableDelete={isStageComplete}
      readOnly={loadingAsset || !!props?.activeCase?.caseCancellation || !hasPermission}
    />
  );
}
