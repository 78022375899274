import React, { memo } from 'react';
import { Switch } from '@mui/material';
import { Control, FieldValues, useController } from 'react-hook-form';

type SwitchRHFProps = {
  name: string;
  control: Control<FieldValues>;
  onChange?: any;
  disabled?: boolean;
};

const SwitchRHF = memo(function ({ name, control, onChange, disabled = false }: SwitchRHFProps) {
  const {
    field: { onChange: hookFormOnChange, value },
  } = useController({ name, control });

  return (
    <Switch
      checked={Boolean(value)}
      onChange={(e) => {
        if (onChange) {
          onChange(e);
        }
        hookFormOnChange(e);
      }}
      disabled={disabled}
    />
  );
});

export default SwitchRHF;
