import { Box, Typography, Button } from '@mui/material';
import React, { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import config from '../extras/config';

export const SentryProvider = (props: { children: React.ReactNode }) => {
  function ErrorFallback({ error, resetErrorBoundary }: { error: Error; resetErrorBoundary: any }) {
    return (
      <Box role="alert">
        <Typography>Something went wrong:</Typography>
        <Box my={3}>
          <pre>{error.message}</pre>
          <pre>{error.stack}</pre>
        </Box>
        <Button onClick={resetErrorBoundary} style={{ marginRight: '15px' }} variant="contained">
          Try Again
        </Button>

        <Button onClick={() => window.location.replace('/')} variant="contained" color="primary">
          Home
        </Button>
      </Box>
    );
  }

  const handleError = (error: Error, info: { componentStack: string }) => {
    console.error(error, info);

    Sentry.captureException(error);
  };

  useEffect(() => {
    Sentry.init({
      dsn: config.sentry.dsn,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 1.0,
      environment: config.environmentName,
      enabled: config.environmentName == 'production',
    });
  }, []);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleError}>
      {props.children}
    </ErrorBoundary>
  );
};
