import React, { useReducer } from 'react';
import { Box, Card, CardContent, Container, Grid, useTheme } from '@mui/material';
import Page from '../../../components/Page';
import PostOpCaseListTable from './PostOpCaseListTable';
import { useQuery } from '@apollo/client';
import { CustomAppBar } from '../../../components/CustomAppBar';
import { Alert } from '@mui/material';
import { Heading } from '../../../components/Heading';
import SearchInput from '../../../components/SearchInput';
import {
  ListPostOpCasesReducer,
  ListPostOpCasesStateType,
  PATIENT_AGE_RANGE_MAX,
  PATIENT_AGE_RANGE_MIN,
} from './ListPostOpCases.reducer';
import { gql } from '@apollo/client/core';
import { PostOpCaseTableFilter } from './PostOpCaseTableFilter';
import {
  CaseCancellationListType,
  CaseStageType,
  CaseType,
  PartType,
  PatientGender,
} from '@workflow-nx/common';
import { ExportPostOpCasesButton } from './ExportPostOpCasesButton';
import { Globals } from '../../../layouts/DashboardLayout';

export const FIND_COMPLETED_CASES = gql`
  query FindCompletedCases(
    $stageFilter: [CaseStage]
    $surgeonFilter: [Int]
    $showSixWeekAnalysisCompleted: Boolean
    $showSixWeekImagingCompleted: Boolean
    $showHasPostOpData: Boolean
    $patientAgeFilter: CasesPatientAgeFilter
    $levelPartFilter: [LevelPart]
    $patientGenderFilter: [Gender]
    $showHasCompletedLevels: Boolean
    $tagsFilter: [Int]
    $orderBy: CaseOrderByInput
    $search: String
    $take: Int
    $skip: Int
  ) {
    cases(
      stageFilter: $stageFilter
      surgeonFilter: $surgeonFilter
      showSixWeekAnalysisCompleted: $showSixWeekAnalysisCompleted
      showSixWeekImagingCompleted: $showSixWeekImagingCompleted
      showHasPostOpData: $showHasPostOpData
      patientAgeFilter: $patientAgeFilter
      levelPartFilter: $levelPartFilter
      patientGenderFilter: $patientGenderFilter
      showHasCompletedLevels: $showHasCompletedLevels
      tagsFilter: $tagsFilter
      orderBy: $orderBy
      search: $search
      take: $take
      skip: $skip
    ) {
      cases {
        caseId
        number
        plans {
          assetPositions
        }
        surgeonUser {
          userId
          firstName
          lastName
          location {
            description
          }
          organization {
            organizationId
            name
          }
        }
        fieldRepUser {
          userId
          role
          firstName
          lastName
        }
        surgeryDate
        hasSixWeekImaging
        hasSixWeekAnalysis
      }

      count
    }
  }
`;

interface IListPostOpCasesVariables {
  orderBy: { surgeryDate: string };
  search: string;
  showSixWeekImagingCompleted: boolean;
  showSixWeekAnalysisCompleted: boolean;
  showHasPostOpData: boolean;
  levelPartFilter: PartType[];
  patientGenderFilter: PatientGender[];
  patientAgeFilter: { min?: number; max?: number };
  showHasCompletedLevels: boolean;
  tagsFilter: number[];
}

function getDefaultState() {
  let defaultVariables: IListPostOpCasesVariables;
  let initialState: ListPostOpCasesStateType;

  defaultVariables = {
    showSixWeekAnalysisCompleted: false,
    showSixWeekImagingCompleted: false,
    showHasPostOpData: false,
    orderBy: { surgeryDate: 'asc' },
    search: '',
    levelPartFilter: [],
    patientGenderFilter: [],
    patientAgeFilter: {},
    showHasCompletedLevels: true,
    tagsFilter: [],
  };

  initialState = {
    showSixWeekAnalysisCompleted: false,
    showSixWeekImagingCompleted: false,
    showHasPostOpData: false,
    orderBy: { surgeryDate: 'asc' },
    search: '',
    levelPartFilter: [],
    patientGenderFilter: [],
    patientAgeFilter: [PATIENT_AGE_RANGE_MIN, PATIENT_AGE_RANGE_MAX],
    caseTags: [],
    pageNumber: 0,
    pageSize: Globals.DefaultListPageSize,
  };

  return [defaultVariables, initialState];
}

const ListPostOpCasesView = () => {
  const theme = useTheme();
  const [defaultVariables, initialState] = getDefaultState();
  const { loading, data, refetch } = useQuery(FIND_COMPLETED_CASES, {
    variables: {
      ...defaultVariables,
      caseCancellationTypeFilter: [CaseCancellationListType.None],
      caseTypeFilter: [CaseType.Live],
      stageFilter: [CaseStageType.Complete],
      skip: 0,
      take: Globals.DefaultListPageSize,
    },
    fetchPolicy: 'cache-and-network',
  });

  const [state, dispatch] = useReducer(
    ListPostOpCasesReducer(refetch),
    initialState as ListPostOpCasesStateType,
  );

  return (
    <>
      <Page title={'Post-Op Analysis'}>
        <Container maxWidth={false}>
          <CustomAppBar title={`Post-Op  Analysis`} />
          <Box>
            <Grid container>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <Box display={'flex'} alignItems={'center'} height={40} mb={1}>
                      <Heading label={'Filters'} />
                    </Box>
                    <Card>
                      <CardContent>
                        <PostOpCaseTableFilter
                          state={state}
                          dispatch={dispatch}
                          loading={loading}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={10}>
                    <Box
                      display={'flex'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      mb={1}
                    >
                      <Heading label={`Results (${data?.cases?.count ?? 0} cases)`} />
                      <Box display="flex" alignItems={'center'}>
                        <Box width={200} mr={3}>
                          <ExportPostOpCasesButton />
                        </Box>
                        <Box width={250} bgcolor={theme.palette.background.paper}>
                          <SearchInput
                            value={state.search ?? ''}
                            onChange={(value: string) =>
                              dispatch({ type: 'SEARCH_CHANGED', data: value })
                            }
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box>
                      <Card>
                        <CardContent style={{ height: 'calc(100vh - 228px)' }}>
                          {data?.cases?.count ? (
                            <PostOpCaseListTable
                              loading={loading}
                              orderBy={state.orderBy}
                              dispatch={dispatch}
                              cases={data?.cases?.cases}
                              page={state.pageNumber}
                              rowsPerPage={state.pageSize}
                              totalCaseCount={data?.cases?.count ?? 0}
                            />
                          ) : (
                            <Alert severity={'warning'}>No cases match the filter criteria. </Alert>
                          )}
                        </CardContent>
                      </Card>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Page>
    </>
  );
};

export default ListPostOpCasesView;
