import {
  ILevels,
  format,
  caseUtils,
  SagittalGoalType,
  CoronalGoalType,
  IPostOpAnalysis,
  CaseSpineProfile,
} from '@workflow-nx/common';
import {
  LumbarMeasurementType,
  ProposedLumbarMeasurementType,
} from '../../views/cases/CaseView/CasePlanningTab/CaseReportDialog/CaseReportDialog.reducer';

export type CaseReportAlignmentGoalType = {
  parameterType: 'LORDOTIC' | 'CORONAL';
  correctionParameter: string;
  alignmentGoal: string;
  targetFormula: string;
  calculation: string;
  correctionNeeded: string;
};

function getTargetLumbarLordosis(
  patientAge: number,
  pelvicIncidence: number,
  sagittalGoalType: SagittalGoalType,
) {
  if (!sagittalGoalType || (sagittalGoalType === SagittalGoalType.AgeAdjusted && !patientAge)) {
    return 0;
  }

  let targetLumbarLordosis;

  switch (sagittalGoalType) {
    case SagittalGoalType.AgeAdjusted:
      targetLumbarLordosis = pelvicIncidence - 3 - (patientAge - 55) / 2;
      break;
    case SagittalGoalType.MinimalMismatch:
      targetLumbarLordosis = pelvicIncidence;
      break;
    case SagittalGoalType.GapScore:
      targetLumbarLordosis = pelvicIncidence * 0.62 + 29;
      break;
    default:
      targetLumbarLordosis = 0;
  }

  return targetLumbarLordosis;
}

export function generateCaseAlignmentGoals(
  levels: ILevels,
  sagittalGoalType: SagittalGoalType,
  patientAge: number,
  pelvicIncidence: number,
  coronalGoalType?: CoronalGoalType,
  preOp?: IPostOpAnalysis,
): CaseReportAlignmentGoalType[] {
  const caseAlignmentGoals: CaseReportAlignmentGoalType[] = [];
  let targetFormula = '';
  let calculation = '';
  let correctionNeeded = '';
  let preOpCoronalAngle;
  let neededCoronalAngle = '';
  const visualCheck = 'Visual Check';

  const caseLevels = caseUtils.getValidCaseLevels(levels);

  if (sagittalGoalType) {
    const preOpLordosis = preOp?.lumbarLordosis ? preOp?.lumbarLordosis : 0;
    const targetLumbarLordosis = getTargetLumbarLordosis(
      patientAge,
      pelvicIncidence,
      sagittalGoalType,
    );
    const lordosisCorrection = targetLumbarLordosis - preOpLordosis;
    const sign = lordosisCorrection === 0 ? '' : lordosisCorrection > 0 ? '+' : '-';
    const lordosisCorrectionNeeded = `${sign}${Math.abs(lordosisCorrection).toFixed(2)}`;
    correctionNeeded = `Target LL ${targetLumbarLordosis.toFixed(
      2,
    )}° \nPre-op LL ${preOpLordosis.toFixed(2)}° \nNeeded ${lordosisCorrectionNeeded}°`;

    switch (sagittalGoalType) {
      case SagittalGoalType.AgeAdjusted:
        targetFormula = `LL = (PI - 3) - (age - 55)/2`;
        calculation = `LL = (${pelvicIncidence} - 3) - (${patientAge} - 55)/2`;
        break;
      case SagittalGoalType.MinimalMismatch:
        targetFormula = `LL = PI`;
        calculation = `LL = ${pelvicIncidence}`;
        break;
      case SagittalGoalType.GapScore:
        targetFormula = `LL = PI * 0.62 + 29`;
        calculation = `LL = ${pelvicIncidence} * 0.62 + 29`;
        break;
      case SagittalGoalType.AlternativeSupportivePlanningReference:
      case SagittalGoalType.Other:
        targetFormula = visualCheck;
        calculation = visualCheck;
        correctionNeeded = '-';
        break;
      default:
    }
    caseAlignmentGoals.push({
      parameterType: 'LORDOTIC',
      correctionParameter: 'Lordotic Correction',
      alignmentGoal: format.formatSagittalGoalType(sagittalGoalType),
      targetFormula: targetFormula,
      calculation: calculation,
      correctionNeeded: correctionNeeded,
    });
  }

  if (coronalGoalType) {
    switch (coronalGoalType) {
      case CoronalGoalType.SuperiorL1EndplateParallelToSacrum:
        preOpCoronalAngle = preOp?.lumbarCoronalAngulation ? preOp?.lumbarCoronalAngulation : 0;
        neededCoronalAngle =
          preOpCoronalAngle === 0
            ? `0`
            : preOpCoronalAngle > 0
            ? `-${preOpCoronalAngle.toFixed(2)}`
            : `+${Math.abs(preOpCoronalAngle).toFixed(2)}`;
        targetFormula = `Pre-Op Coronal Angle Total`;
        calculation = `Coronal Angle ${preOpCoronalAngle.toFixed(2)}`;
        correctionNeeded = `Needed ${neededCoronalAngle}°`;
        break;
      case CoronalGoalType.SuperiorL1EndplateParallelToFloor:
      case CoronalGoalType.SuperiorEndplateOfHighestTreatedLevelParallelToSacrum:
      case CoronalGoalType.Other:
        targetFormula = visualCheck;
        calculation = visualCheck;
        correctionNeeded = '-';
        break;
      case CoronalGoalType.ParallelDiscSpaceAtTreatmentLevels:
        caseLevels.forEach((level) => {
          const preOpSegmentalCoronalAngle = preOp?.segmentalCoronalAngle?.[level] ?? 0;
          const neededSegmentalCoronalAngle =
            preOpSegmentalCoronalAngle === 0
              ? `0`
              : preOpSegmentalCoronalAngle > 0
              ? `-${preOpSegmentalCoronalAngle.toFixed(2)}`
              : `+${Math.abs(preOpSegmentalCoronalAngle).toFixed(2)}`;
          targetFormula = `${format.formatLevelType(level)}`;
          calculation = `Pre-Op Segmental Coronal Angle ${preOpSegmentalCoronalAngle.toFixed(2)}`;
          correctionNeeded = `Needed ${neededSegmentalCoronalAngle}°`;

          caseAlignmentGoals.push({
            parameterType: 'CORONAL',
            correctionParameter: 'Coronal Correction',
            alignmentGoal: format.formatCoronalGoalType(coronalGoalType),
            targetFormula: targetFormula,
            calculation: calculation,
            correctionNeeded: correctionNeeded,
          });
        });
        break;
    }
    if (coronalGoalType !== CoronalGoalType.ParallelDiscSpaceAtTreatmentLevels) {
      caseAlignmentGoals.push({
        parameterType: 'CORONAL',
        correctionParameter: 'Coronal Correction',
        alignmentGoal: format.formatCoronalGoalType(coronalGoalType),
        targetFormula: targetFormula,
        calculation: calculation,
        correctionNeeded: correctionNeeded,
      });
    }
  }

  return caseAlignmentGoals;
}

export function generateLumbarMeasurementDataset(
  data: IPostOpAnalysis,
  caseSpineProfile: CaseSpineProfile,
): LumbarMeasurementType[] {
  const validLevels = caseUtils.getLevelsSortedByHierarchy(caseSpineProfile, 'desc');

  return validLevels.map((level) => ({
    level,
    segmentalLumbarLordosis: data?.segmentalLumbarLordosis?.[level] ?? 0,
    angleToS1: data?.angleToS1?.[level] ?? 0,
    segmentalCoronalAngle: data?.segmentalCoronalAngle?.[level] ?? 0,
    segmentalAnteriorHeight: data?.segmentalAnteriorHeight?.[level] ?? 0,
    segmentalPosteriorHeight: data?.segmentalPosteriorHeight?.[level] ?? 0,
  }));
}

export function getProposedLumbarMeasurements(
  preOp: IPostOpAnalysis,
  plan: IPostOpAnalysis,
  caseSpineProfile: CaseSpineProfile,
): ProposedLumbarMeasurementType {
  return {
    preOpLumbarMeasurements: generateLumbarMeasurementDataset(preOp, caseSpineProfile),
    planLumbarMeasurements: generateLumbarMeasurementDataset(plan, caseSpineProfile),
  };
}
