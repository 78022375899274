import { useLazyQuery } from '@apollo/client';
import {
  ICase,
  ICaseImplantSpecification,
  IForm20Product,
  LevelSize,
  PartType,
  PlanStatusType,
  dataExport,
  forms,
} from '@workflow-nx/common';
import { useEffect } from 'react';
import ActionButton from '../../../components/ActionButton';
import { FIND_CASE_IMPLANT_SPECIFICATIONS_BY_CASE_ID, FIND_SETTINGS } from '../../../gql';

import { date } from '@workflow-nx/utils';
import useAuth from '../../../hooks/useAuth';
import { FeatureFlag } from '../../../utils/featureFlags';
import { Form17DataType, HeaderType, generateForm17CaseData } from '../../../utils/form17';
import { ProductDates } from '../../../utils/form19';

let form20MasterList: IForm20Product[] = [];
let caseImplantSpecifications: ICaseImplantSpecification[] = [];

export function ExportShippingLabelButton(props: {
  activeCase: ICase;
  inserterSku?: string;
  loading?: boolean;
}) {
  const { hasFeatureFlag } = useAuth();
  const isImplantSizeExclusionEnabled = hasFeatureFlag?.(FeatureFlag.implantSizeExclusionEnabled);

  const plans = props.activeCase?.plans || [];
  const plan = plans.find((element) => element.status === PlanStatusType.Approved);

  const [findCaseImplantSpecifications] = useLazyQuery(
    FIND_CASE_IMPLANT_SPECIFICATIONS_BY_CASE_ID,
    {
      variables: {
        caseId: props.activeCase.caseId,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      onCompleted: (implantSpecificationsData) => {
        caseImplantSpecifications =
          implantSpecificationsData?.caseImplantSpecifications?.caseImplantSpecifications.filter(
            (caseImplantSpecification: ICaseImplantSpecification) =>
              (caseImplantSpecification.partType === PartType.ALIF_X_TWO_UP ||
                caseImplantSpecification.partType === PartType.ALIF_X_TWO_DOWN) &&
              caseImplantSpecification.size === LevelSize.Normal,
          ) ?? [];
      },
    },
  );

  const [lazyFindForm20Data, { loading: lazyLoadingForm20 }] = useLazyQuery(FIND_SETTINGS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (lazyForm20Data) => {
      form20MasterList = lazyForm20Data?.settings?.form20 ? lazyForm20Data?.settings?.form20 : [];
      const applicationSettings = lazyForm20Data?.settings?.application
        ? lazyForm20Data?.settings?.application
        : {};
      const productDates: ProductDates = {
        kitCartonStartDate:
          date.parseCalendarDateFromString(
            applicationSettings?.product?.kitCartonStartDate as string,
          ) ?? undefined,
        m4lStartDate:
          date.parseCalendarDateFromString(applicationSettings?.product?.m4lStartDate as string) ??
          undefined,
        tlifCM4lStartDate:
          date.parseCalendarDateFromString(
            applicationSettings?.product?.tlifCM4lStartDate as string,
          ) ?? undefined,
        tlifCOrientationStartDate:
          date.parseCalendarDateFromString(
            applicationSettings?.product?.tlifCOrientationStartDate as string,
          ) ?? undefined,
        alifXRotationLockStartDate:
          date.parseCalendarDateFromString(
            applicationSettings?.product?.alifXRotationLockStartDate as string,
          ) ?? undefined,
      };

      if (plan) {
        const hospitalName = props.activeCase.location ? props.activeCase.location.description : '';

        let header: HeaderType[], data: Form17DataType[];
        if (isImplantSizeExclusionEnabled) {
          const planShippingLabels = plan?.planShippingLabels?.length
            ? plan?.planShippingLabels
            : [];
          ({ data, header } = forms.generateCaseForm17(planShippingLabels));
        } else {
          ({ data, header } = generateForm17CaseData(
            props.activeCase,
            plan,
            hospitalName,
            form20MasterList,
            caseImplantSpecifications,
            productDates,
          ));
        }

        dataExport.saveXlsxFile(
          `${props.activeCase.number}-Form017-Label.xlsx`,
          'Workflow Cases',
          header,
          data,
        );
      }
    },
  });

  useEffect(() => {
    findCaseImplantSpecifications();
  }, [findCaseImplantSpecifications]);

  return (
    <ActionButton
      variant={'outlined'}
      color={'secondary'}
      loading={lazyLoadingForm20}
      onClick={() => lazyFindForm20Data()}
    >
      Export Form 17 Shipping Label (XLSX)
    </ActionButton>
  );
}
