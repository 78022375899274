import JSZip from '@progress/jszip-esm';
import { DimensionFileDataType, ExportBlob, ImplantCharacteristicType } from '@workflow-nx/common';
import axios, { AxiosProgressEvent, AxiosResponse, GenericAbortSignal, ResponseType } from 'axios';
import { toNumber } from 'lodash';

export const parseDimensionFileData = (textData: string) => {
  const textLines = (textData as string).split(/\r?\n/);
  const textToJson: DimensionFileDataType = {
    apDepth: undefined,
    mlWidth: undefined,
    maxHeight: undefined,
  };
  if (textLines.length) {
    for (const textLine of textLines) {
      if (textLine.includes('=')) {
        const [key, val] = textLine.split('=');
        switch (key.trim()) {
          case ImplantCharacteristicType.ApDepthB:
            textToJson.apDepth = toNumber(val);
            break;
          case ImplantCharacteristicType.MlWidthA:
            textToJson.mlWidth = toNumber(val);
            break;
          case ImplantCharacteristicType.MaxHeightC:
            textToJson.maxHeight = toNumber(val);
            break;
          case ImplantCharacteristicType.BulletAngle:
            textToJson.bulletAngle = toNumber(val);
            break;
          case ImplantCharacteristicType.BulletHeight:
            textToJson.bulletHeight = toNumber(val);
            break;
          case ImplantCharacteristicType.RightBulletAngle:
            textToJson.rightBulletAngle = toNumber(val);
            break;
          case ImplantCharacteristicType.RightBulletHeight:
            textToJson.rightBulletHeight = toNumber(val);
            break;
          case ImplantCharacteristicType.LeftBulletAngle:
            textToJson.leftBulletAngle = toNumber(val);
            break;
          case ImplantCharacteristicType.LeftBulletHeight:
            textToJson.leftBulletHeight = toNumber(val);
            break;
        }
      }
    }
  }
  return textToJson;
};

export const createZipFile = async (blobObjArray: ExportBlob[]): Promise<Blob> => {
  const zip = new JSZip();
  blobObjArray.forEach((blobObj: ExportBlob) => {
    zip.file(blobObj.name, blobObj.blob);
  });
  return await zip.generateAsync({ type: 'blob' });
};

export const convertRowsToCsv = (rows: string[][]) => {
  let csv = '';
  rows.forEach(function (rowArray) {
    let row = rowArray.join(',');
    csv += row + '\r\n';
  });
  return csv;
};

export function saveCsvFile(name: string, rows: string[][]) {
  let csvContent = '';
  rows.forEach(function (rowArray) {
    let row = rowArray.join(',');
    csvContent += row + '\r\n';
  });
  return new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
}

export const uploadFile = async (
  url: string,
  data: any,
  onUploadProgress?: (percentComplete: number) => void,
  signal?: GenericAbortSignal,
): Promise<any> => {
  return await axios.put(url, data, {
    signal,
    headers: {
      'Content-Type': 'application/octet-stream',
    },
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
      if (progressEvent.total) {
        const percent: number = (progressEvent.loaded * 100) / progressEvent.total;
        onUploadProgress?.(Math.round(percent));
      }
    },
  });
};

export const downloadFile = async (
  url: string,
  options?: {
    onDownloadProgress?: (percentComplete: number) => void;
    responseType?: ResponseType;
  },
): Promise<AxiosResponse> => {
  return await axios.get(url, {
    responseType: options?.responseType ?? 'blob',
    headers: {
      Accept: 'application/octet-stream',
    },
    onDownloadProgress: (progressEvent: AxiosProgressEvent) => {
      if (progressEvent.total) {
        const percent: number = (progressEvent.loaded * 100) / progressEvent.total;
        options?.onDownloadProgress?.(Math.round(percent));
      }
    },
  });
};

export const zipFiles = async (
  files: { name: string; data: any }[],
  rootFolder: string = '',
): Promise<ArrayBuffer> => {
  const zip = new JSZip();
  files.forEach((file: { name: string; data: any }) => {
    if (!file.data) {
      if (rootFolder) {
        zip.folder(rootFolder).folder(file.name);
      } else {
        zip.folder(file.name);
      }
    } else {
      if (rootFolder) {
        zip.folder(rootFolder).file(file.name, file.data);
      } else {
        zip.file(file.name, file.data);
      }
    }
  });
  return zip.generateAsync({ type: 'arraybuffer' });
};

export const unzipFiles = async (zipFile: Buffer): Promise<{ name: string; data: Buffer }[]> => {
  const outputFiles: { name: string; data: Buffer }[] = [];
  const jsZip = new JSZip();

  const zipFiles = await jsZip.loadAsync(zipFile);

  for (const filesKey in zipFiles.files) {
    const zipFile = zipFiles.files[filesKey];
    const data = await zipFile.async('arraybuffer');
    outputFiles.push({
      name: zipFile.name.slice(zipFile.name.lastIndexOf('\\') + 1),
      data: Buffer.from(data),
    });
  }

  return outputFiles;
};

export const createZipBlob = async (
  files: { name: string; data: any }[],
  rootFolder: string = '',
): Promise<Blob> => {
  const zip = new JSZip();
  files.forEach((blobObj: { name: string; data: any }) => {
    if (rootFolder) {
      zip.folder(rootFolder).file(blobObj.name, blobObj.data);
    } else {
      zip.file(blobObj.name, blobObj.data);
    }
  });
  return await zip.generateAsync({ type: 'blob' });
};

export const encodeBlobToBase64 = async (blob: Blob): Promise<string> => {
  return await new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.readAsDataURL(blob);
  });
};

export const readTextFileFromBlob = async (blob: Blob) => {
  return await new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.readAsText(blob);
  });
};

export const encodeAssetToBase64 = async (
  assetId: number,
  createAssetDownloadUrl: (params: { variables: { assetId: number } }) => Promise<any>,
): Promise<string> => {
  let encodedImage = '';
  try {
    const { data } = await createAssetDownloadUrl({
      variables: { assetId },
    });

    const signedUrl = data.createAssetDownloadUrl.signedUrl;
    const response = await downloadFile(signedUrl);
    if (response.status === 200) {
      encodedImage = await encodeBlobToBase64(response.data);
    }
  } catch (error) {
    console.error('Error loading asset:', error);
  }
  return encodedImage;
};
