import { ICase } from '@workflow-nx/common';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useMutation } from '@apollo/client';
import { REJECT_FORM_19, UPSERT_FORM19 } from '../../../gql';
import { Formik, FormikHelpers } from 'formik';
import { Box, Grid } from '@mui/material';
import CustomDialog from '../../../components/CustomDialog';
import * as Yup from 'yup';
import { ProgressButton, TextField } from '@workflow-nx/ui';

export function Form19RejectionDialog({
  activeCase,
  open,
  onClose,
}: {
  open: boolean;
  activeCase: ICase;
  onClose: (shouldUpdate: boolean) => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [upsertForm19] = useMutation(UPSERT_FORM19);
  const [rejectForm19] = useMutation(REJECT_FORM_19);

  const unApproveForm19 = async () => {
    try {
      await upsertForm19({
        variables: {
          caseId: activeCase.caseId,
          engineeringApprovedBy: null,
          engineeringApprovedAt: null,
          qualityApprovedBy: null,
          qualityApprovedAt: null,
          data: {},
        },
      });
    } catch (err) {
      console.error(err);
      enqueueSnackbar('An error occurred un-approving the FORM-019', {
        variant: 'error',
      });
    }
  };

  const handleSubmitForm = async (
    values: { reason: string },
    { setStatus, setSubmitting }: FormikHelpers<{ reason: string }>,
  ) => {
    try {
      await unApproveForm19();
      await rejectForm19({
        variables: { caseId: activeCase.caseId, reason: values.reason },
      });

      setStatus({ success: true });
      enqueueSnackbar('Form 19 Rejected', {
        variant: 'success',
      });

      onClose(true);
    } catch (err) {
      console.error(err);
      setStatus({ success: false });
      enqueueSnackbar('An error occurred rejecting the form 19', {
        variant: 'error',
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{ reason: '' }}
      validationSchema={Yup.object().shape({
        reason: Yup.string().required(),
      })}
      onSubmit={handleSubmitForm}
      enableReinitialize={true}
    >
      {({ handleSubmit, isSubmitting, submitForm }) => (
        <CustomDialog
          maxWidth={'md'}
          open={open}
          title={`Rejecting Form 19 for Case ${activeCase.number}`}
          onClose={() => {
            onClose(false);
          }}
          positiveActionButtons={[
            <ProgressButton
              onClick={() => submitForm()}
              loading={isSubmitting}
              disabled={isSubmitting}
              label={'Reject'}
            />,
          ]}
        >
          {open ? (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs>
                  <TextField disabled={isSubmitting} name="reason" label={'Reason'} />
                </Grid>
              </Grid>
              <Box mt={1} />
            </form>
          ) : null}
        </CustomDialog>
      )}
    </Formik>
  );
}
