export type TaskTableActionType = {
  type:
    | 'ORDER_BY_CHANGED'
    | 'TASK_TYPE_CHANGED'
    | 'USER_CHANGED'
    | 'RESET_FILTERS'
    | 'UPDATE_PAGINATION'
    | 'refetch';
  data?: any;
};

export type ListTasksStateType = {
  orderBy?: { [key: string]: 'asc' | 'desc' };
  assignedId?: string;
  actionType?: string;
  pageNumber: number;
  pageSize: number;
};

export const ListTasksReducer =
  (refetch: any) =>
  (state: ListTasksStateType, action: TaskTableActionType): ListTasksStateType => {
    let updatedState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
      case 'refetch':
        refetch();
        break;
      case 'RESET_FILTERS':
        updatedState = {
          orderBy: updatedState.orderBy,
          actionType: '',
          assignedId: undefined,
          pageNumber: updatedState.pageNumber,
          pageSize: updatedState.pageSize,
        };
        break;
      case 'ORDER_BY_CHANGED':
        updatedState.orderBy = action.data;
        break;
      case 'TASK_TYPE_CHANGED':
        updatedState.actionType = action.data;
        break;
      case 'USER_CHANGED':
        updatedState.assignedId = action.data;
        break;
      case 'UPDATE_PAGINATION':
        updatedState.pageSize = action.data.pageSize;
        updatedState.pageNumber = action.data.pageNumber;
        break;
    }

    let orderBy = updatedState.orderBy;

    // Reset page number if a query parameter has changed
    if (action.type !== 'UPDATE_PAGINATION') {
      updatedState.pageNumber = 0;
    }

    if (Object.keys(orderBy)[0] === 'assignedId') {
      orderBy = { assignedUser: { lastName: orderBy['assignedId'] } };
    }

    if (Object.keys(orderBy)[0] === 'surgeon') {
      orderBy = { case: { surgeonUser: { lastName: orderBy['surgeon'] } } };
    }

    const variables = {
      orderBy,
      actionType: updatedState.actionType ? updatedState.actionType : undefined,
      assignedId: updatedState.assignedId ? parseInt(updatedState.assignedId) : undefined,
      take: updatedState.pageSize,
      skip:
        updatedState.pageSize && updatedState.pageNumber
          ? updatedState.pageSize * updatedState.pageNumber
          : undefined,
    };

    refetch(variables);

    return updatedState;
  };
