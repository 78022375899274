import * as XLSX from 'xlsx';

export function convertCsvToBuffer(rows: string[][]): Buffer {
  let csvContent = '';
  rows.forEach(function (rowArray) {
    const row = rowArray.join(',');
    csvContent += row + '\r\n';
  });

  return new Buffer(csvContent);
}

export function generateXlsxFile(
  sheetName: string,
  headerInfo: Array<{
    value: string;
    width: number;
  }>,
  data: Array<
    Record<
      string,
      {
        l?: string;
        t?: string;
        z?: string;
        v: string | number | boolean | undefined;
      }
    >
  >,
): XLSX.WorkBook {
  const header = headerInfo.map((h) => {
    return h.value;
  });
  const colWidths = headerInfo.map((h) => {
    return { wch: h.width };
  });

  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(data, {
    header,
    cellDates: true,
    cellStyles: true,
    dateNF: 'yyyy-mm-dd',
  });
  ws['!cols'] = colWidths;

  XLSX.utils.book_append_sheet(wb, ws, sheetName);

  return wb;
}

export function saveXlsxFile(
  name: string,
  sheetName: string,
  headerInfo: Array<{
    value: string;
    width: number;
  }>,
  data: Array<
    Record<
      string,
      {
        l?: string;
        t?: string;
        z?: string;
        v: string | number | boolean | undefined;
      }
    >
  >,
) {
  const wb = generateXlsxFile(sheetName, headerInfo, data);

  XLSX.writeFile(wb, name, { cellStyles: true, cellDates: true, compression: true });
}

export function getXlsxFileBuffer(
  sheetName: string,
  headerInfo: Array<{
    value: string;
    width: number;
  }>,
  data: Array<
    Record<
      string,
      {
        l?: string;
        t?: string;
        z?: string;
        v: string | number | boolean | undefined;
      }
    >
  >,
) {
  const wb = generateXlsxFile(sheetName, headerInfo, data);

  const b64 = XLSX.write(wb, { type: 'base64', bookType: 'xlsx' }) as string;

  return b64;
}
