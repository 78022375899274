import {
  AssetType,
  CloudDesignQueueType,
  CloudDesignStatus,
  format,
  IAsset,
  ICloudDesignQueue,
  LevelSize,
  LevelType,
  PartType,
} from '@workflow-nx/common';
import { useConfirm } from 'material-ui-confirm';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  CREATE_ASSET_DOWNLOAD_URL,
  DELETE_EXPORT_CLOUD_DESIGN,
  EXPORT_CLOUD_DESIGN_CUT_AND_MESH,
  FIND_ASSETS,
  FIND_DESIGN_ASSETS,
} from '../../gql';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import * as FileSaver from 'file-saver';
import { Box, LinearProgress, Skeleton, Typography } from '@mui/material';
import {
  faBug,
  faCloudUpload,
  faDownload,
  faEye,
  faRotateRight,
  faRulerTriangle,
  faTimes,
} from '@fortawesome/pro-light-svg-icons';
import { ImplantMeshViewDialog } from '../AssetMeshView/ImplantMeshViewDialog';
import { date, file } from '@workflow-nx/utils';
import { OptionsMenuButton } from '../OptionsMenuButton';
import { IconFontButton } from '@workflow-nx/ui';
import { useDownloadCloudDesignDebugFiles } from '../../hooks/useDownloadCloudDesignDebugFiles';

function getEstimatedTime(estimatedDuration: number, startedAt: string): string {
  const remaining = estimatedDuration - date.distanceInMinutes(startedAt);

  if (remaining > 1) {
    return `Approximately ${remaining} minutes remaining`;
  }
  if (remaining === 0 || remaining === 1) {
    return `Approximately less than one minute remaining`;
  }
  return `Process overdue by ${Math.abs(remaining)} minute(s)`;
}

export function CloudDesignTableRow(props: {
  label: React.ReactNode;
  caseId: number;
  planId: number;
  levelType: LevelType;
  levelSize: LevelSize;
  partType: PartType;
  onStatusChange: (
    level: LevelType,
    levelSize: LevelSize,
    partType: PartType,
    cloudDesignStatus: CloudDesignStatus,
  ) => void;
}) {
  const estimatedDuration = 15;

  const assetType = `${props.levelType}${
    props.levelSize === LevelSize.Normal ? '' : `_${props.levelSize}`
  }` as AssetType;

  const confirm = useConfirm();
  const { download: downloadCloudDesignDebugFiles, loading: loadingDownloadCloudDesignDebugFiles } =
    useDownloadCloudDesignDebugFiles();

  const [exportCloudDesignCutAndMesh] = useMutation(EXPORT_CLOUD_DESIGN_CUT_AND_MESH);
  const [createAssetDownloadUrl] = useMutation(CREATE_ASSET_DOWNLOAD_URL);
  const [deleteExportCloudDesign] = useMutation(DELETE_EXPORT_CLOUD_DESIGN);
  const [findAssetDimensions] = useLazyQuery(FIND_ASSETS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      caseId: props.caseId,
      planId: props.planId,
      assetTypeFilter: [`${assetType}_DIMENSIONS`],
    },
    onCompleted: (data) => {
      handleDownloadAsset(data.assets[0]);
    },
  });
  const { startPolling, stopPolling, refetch } = useQuery(FIND_DESIGN_ASSETS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      caseId: props.caseId,
      planId: props.planId,
      level: props.levelType,
      levelSize: props.levelSize,
      type: CloudDesignQueueType.CutAndMesh,
      assetTypeFilter: [assetType, `${assetType}_LOG`, `${assetType}_DIMENSIONS`],
    },
    onCompleted: (data: any) => {
      const asset = data.assets.find((asset: IAsset) => asset.assetType === assetType);
      const cloudDesignQueueByLevel = data.cloudDesignQueueByLevel as ICloudDesignQueue;

      if (
        cloudDesignQueue !== null &&
        cloudDesignQueue?.status !== cloudDesignQueueByLevel?.status
      ) {
        props.onStatusChange(
          cloudDesignQueueByLevel.level,
          cloudDesignQueueByLevel.levelSize ?? LevelSize.Normal,
          cloudDesignQueueByLevel.part,
          cloudDesignQueueByLevel.status,
        );
      }

      setAsset(asset);
      setCloudDesignQueue(cloudDesignQueueByLevel);
      setCloudDesignInitialized(true);
    },
  });
  const { enqueueSnackbar } = useSnackbar();
  const [downloading, setDownloading] = useState(false);
  const [downloadingPercent, setDownloadingPercent] = useState(0);
  const [loading, setLoading] = useState(false);
  const [openAssetViewer, setOpenAssetViewer] = useState(false);
  const [assetViewerAssetType, setAssetViewerAssetType] = useState<AssetType>();
  const [asset, setAsset] = useState<IAsset | null>();
  const [cloudDesignQueue, setCloudDesignQueue] = useState<ICloudDesignQueue | null>(null);
  const [cloudDesignInitialized, setCloudDesignInitialized] = useState(false);

  const handleDownloadAsset = async (asset: IAsset) => {
    try {
      setDownloading(true);
      const { data } = await createAssetDownloadUrl({
        variables: {
          assetId: asset?.assetId,
        },
      });

      const response = await file.downloadFile(data.createAssetDownloadUrl.signedUrl, {
        onDownloadProgress: (percentComplete) => {
          setDownloadingPercent(percentComplete);
        },
      });

      FileSaver.saveAs(response.data, asset?.fileName);
    } catch (e) {
      enqueueSnackbar('Error Downloading File', {
        variant: 'error',
      });
    } finally {
      setDownloading(false);
    }
  };

  const handleCloudDesignCancelClick = async () => {
    try {
      if (!cloudDesignQueue) {
        return;
      }

      if (cloudDesignQueue?.status === CloudDesignStatus.Processing) {
        await confirm({
          title: `Cancel Cloud Design ${format.formatLevelType(
            props.levelType,
          )} (${format.formatLevelSize(props.levelSize)}) ${format.formatPartType(
            props.partType,
          )} Implant?`,
          description: (
            <>
              This will remove the case from the cloud design queue. Are you sure you wish to
              continue?
            </>
          ),
        });
      }

      await deleteExportCloudDesign({
        variables: {
          cloudDesignQueueId: cloudDesignQueue?.cloudDesignQueueId,
        },
      });

      setCloudDesignQueue(null);

      await refetch();
      stopPolling();
      enqueueSnackbar(`The cloud design operation has been cancelled`, { variant: 'success' });
    } catch (e) {
      console.error(e);
      enqueueSnackbar('An error occurred cancelling the cloud design operation', {
        variant: 'error',
      });
    }
  };

  const handleExportCloudDesign = async (): Promise<void> => {
    try {
      setLoading(true);

      try {
        await confirm({
          title: `Re-send implant at ${format.formatLevelType(props.levelType)} to cloud design?`,
          allowClose: false,
          description: (
            <>
              This will remove the existing cut and meshed implant assets and restart the cutting
              process at level {format.formatLevelType(props.levelType)}. Are you sure you wish to
              continue?
            </>
          ),
        });
      } catch (e) {
        return;
      }

      await exportCloudDesignCutAndMesh({
        variables: {
          caseId: props.caseId,
          input: {
            level: props.levelType,
            levelSize: props.levelSize,
            planId: props.planId,
          },
        },
      });

      enqueueSnackbar(
        `Cutting and meshing of ${format.formatLevelType(props.levelType)} ${format.formatPartType(
          props.partType,
        )} initiated`,
        {
          variant: 'success',
        },
      );

      refetch();
      startPolling(10000);
    } catch (err) {
      console.error(err);
      enqueueSnackbar('An error occurred while initiating cloud design', {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (cloudDesignQueue?.status === CloudDesignStatus.Processing) {
      startPolling(10000);
    }

    return () => {
      stopPolling();
    };
  }, [cloudDesignQueue]);

  const cutLevelOptions = [
    {
      key: 'VIEW',
      icon: faEye,
      label: 'View Implant',
      disabled: false,
    },
    {
      key: 'CLOUD_DESIGN_CUT',
      icon: faRotateRight,
      label: 'Re-send To Cloud Design',
    },
    {
      key: 'DOWNLOAD_ASSET',
      icon: faDownload,
      label: 'Download Level Assets',
    },
    {
      key: 'DOWNLOAD_DIMENSIONS',
      icon: faRulerTriangle,
      label: 'Download Implant Dimensions',
    },
    {
      key: 'DOWNLOAD_DEBUG_ASSETS',
      icon: faBug,
      disabled: !cloudDesignQueue,
      label: 'Download nTop Debug Assets',
    },
  ];
  const unCutLevelOptions = [
    {
      key: 'CLOUD_DESIGN_CUT',
      icon: faCloudUpload,
      label: 'Send To Cloud Design',
    },
  ];

  if (loading || !cloudDesignInitialized) {
    return (
      <Box gridColumn={'span 2'}>
        <Skeleton height={50} style={{ width: '100%' }} />
      </Box>
    );
  }

  if (cloudDesignQueue?.status === CloudDesignStatus.Processing) {
    return (
      <>
        <Box gridColumn={'span 1'} height={50} px={1}>
          <Typography color={'textSecondary'} align={'center'} variant={'h6'}>
            {`${format.formatLevelSize(props.levelSize)} Implant - ${getEstimatedTime(
              estimatedDuration,
              cloudDesignQueue.startedAt as unknown as string,
            )}`}
          </Typography>
          <Box width={'100%'}>
            <LinearProgress color={'secondary'} />
          </Box>
        </Box>
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <IconFontButton icon={faTimes} onClick={() => handleCloudDesignCancelClick()} />
        </Box>
      </>
    );
  }

  if (downloading) {
    return (
      <Box gridColumn={'span 2'} height={50} px={1}>
        <Typography color={'textSecondary'} align={'center'} variant={'h6'}>
          {`Downloading ${format.formatAssetType(asset?.assetType)}`}
        </Typography>
        <Box width={'100%'}>
          <LinearProgress color={'secondary'} variant={'determinate'} value={downloadingPercent} />
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Box px={1}>
        <Typography variant={'body1'}>{props.label}</Typography>
        {cloudDesignQueue &&
        ![CloudDesignStatus.Processing].includes(
          cloudDesignQueue?.status ?? CloudDesignStatus.None,
        ) ? (
          <Typography color={'textSecondary'} variant={'caption'}>
            Last status: {cloudDesignQueue?.status} took {cloudDesignQueue?.minutesProcessing}{' '}
            minutes on {cloudDesignQueue?.log.nTopVersion} (
            {format.formatDateTime(cloudDesignQueue?.finishedAt as Date)}{' '}
            {cloudDesignQueue?.log.environment})
          </Typography>
        ) : null}
        {!cloudDesignQueue ? (
          <Typography color={'textSecondary'} variant={'caption'}>
            Cloud Design process ready to be initiated
          </Typography>
        ) : null}
      </Box>
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'} px={1}>
        <OptionsMenuButton
          options={!cloudDesignQueue ? unCutLevelOptions : cutLevelOptions}
          loading={loadingDownloadCloudDesignDebugFiles || downloading}
          onSelect={async (option) => {
            switch (option) {
              case 'VIEW':
                setAssetViewerAssetType(asset?.assetType);
                setOpenAssetViewer(true);
                break;
              case 'CLOUD_DESIGN_CUT':
                await handleExportCloudDesign();
                break;
              case 'DOWNLOAD_ASSET':
                if (asset) {
                  await handleDownloadAsset(asset);
                }
                break;
              case 'DOWNLOAD_DIMENSIONS':
                await findAssetDimensions();
                break;
              case 'DOWNLOAD_DEBUG_ASSETS':
                await downloadCloudDesignDebugFiles(cloudDesignQueue?.cloudDesignQueueId);
                break;
              default:
                break;
            }
          }}
        />
      </Box>
      {openAssetViewer ? (
        <ImplantMeshViewDialog
          open={openAssetViewer}
          caseId={props.caseId}
          planId={props.planId}
          assetType={assetViewerAssetType}
          onClose={() => setOpenAssetViewer(false)}
        />
      ) : null}
    </>
  );
}
