import { ApolloQueryResult } from '@apollo/client/core/types';

export type ListCaseTriageActionType = {
  type: string;
  data?: any;
};

export type ListCaseTriageStateType = {
  orderBy?: { [key: string]: 'asc' | 'desc' };
  search?: string;
};

export const ListCaseTriageReducer =
  (refetch: (variables?: Partial<any>) => Promise<ApolloQueryResult<any>>) =>
  (state: ListCaseTriageStateType, action: ListCaseTriageActionType): ListCaseTriageStateType => {
    let updatedState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
      case 'refetch':
        refetch();
        break;
      case 'SEARCH_CHANGED':
        updatedState.search = action.data;
        break;
      case 'ORDER_BY_CHANGED':
        updatedState.orderBy = action.data;
        break;
    }

    let orderBy = updatedState.orderBy;

    if (Object.keys(orderBy)[0] === 'surgeonId') {
      orderBy = { surgeonUser: { lastName: orderBy['surgeonId'] } };
    }

    if (Object.keys(orderBy)[0] === 'assignedId') {
      orderBy = { assignedUser: { lastName: orderBy['assignedId'] } };
    }

    if (Object.keys(orderBy)[0] === 'fieldRepId') {
      orderBy = { fieldRepUser: { lastName: orderBy['fieldRepId'] } };
    }

    if (Object.keys(orderBy)[0] === 'organizationId') {
      orderBy = { organization: { name: orderBy['organizationId'] } };
    }

    const variables = {
      orderBy,
      search: updatedState.search,
    };
    refetch(variables);

    return updatedState;
  };
