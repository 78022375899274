import { Axis, EndPlate, Position } from '@workflow-nx/common';
import { Angle, Plane, Tools, Vector3 } from 'babylonjs';
import { MeasurementType } from './enums';
import {
  CommonVector3,
  IAngleOriginVectorData,
  IAngleVectorData,
  ILineVectorData,
  IMeasurementPoint,
  IPlumbLineDistanceVectorData,
  MeasurementDefinition,
} from './interfaces';
import { getPointName, parseInterMeasurementPoints } from './measurements';

export function projectPointOntoPlane(point: Vector3, plane: Plane) {
  // NOTE - The projection ray should be the plane normal; hence we add the normal to the target point to get the ray origin
  return point.projectOnPlane(plane, point.add(plane.normal));
}

export function projectVectorOntoPlane(vectorToProject: Vector3, plane: Plane): Vector3 {
  // Calculate the projection
  const dotProduct = Vector3.Dot(vectorToProject, plane.normal);
  const projection = vectorToProject.subtract(plane.normal.scale(dotProduct));

  return projection;
}

export function calculateAngleBetweenPointsAlongPlane(
  line1: { point1: number[]; point2: number[] },
  line2: { point1: number[]; point2: number[] },
  axis: Axis,
) {
  const ap1 = vectorFromArray(line1.point1).subtract(vectorFromArray(line1.point2));

  const ap2 = vectorFromArray(line2.point1).subtract(vectorFromArray(line2.point2));

  // STEP 1 - figure out the normal from the plane
  let normal = Vector3.Zero();
  switch (axis) {
    case Axis.X:
      normal = Vector3.Cross(ap1, new Vector3(1, 0, 0)).normalize();
      break;
    case Axis.Y:
      normal = Vector3.Cross(ap1, new Vector3(0, 1, 0)).normalize();
      break;
    case Axis.Z:
      normal = Vector3.Cross(ap1, new Vector3(0, 0, 1)).normalize();
      break;
  }

  // STEP 2 - project ap2 against the normal
  const ap2DotProduct = Vector3.Dot(ap2, normal);
  const projAp2 = ap2.subtract(
    normal.multiplyByFloats(ap2DotProduct, ap2DotProduct, ap2DotProduct),
  );

  // STEP 3 - calculate the angle
  const radians = Vector3.GetAngleBetweenVectors(ap1, projAp2, normal);
  const angle = new Angle(radians).degrees();

  // TODO: figure out how to not do this angle hack. The issue is that all
  // vectors need to be the same direction regardless of the line1 / line2
  // order. Is there an abs function for vectors?
  return angle <= 90 ? angle : 360 - angle;
}

export function calculateDistanceBetweenPointsAlongAxis(
  point1: number[],
  point2: number[],
  axis: Axis,
) {
  let distance = 0;

  if (axis === Axis.X) {
    distance = point1[0] - point2[0];
  }
  if (axis === Axis.Y) {
    distance = point1[1] - point2[1];
  }
  if (axis === Axis.Z) {
    distance = point1[2] - point2[2];
  }
  return Math.abs(distance);
}

export const evaluateCoronalAngleData = (
  measurement: MeasurementDefinition,
  measurementPoints: IMeasurementPoint,
): { value: number; vectorData?: IAngleVectorData } => {
  let totalAngle = 0;

  const infAMeasurement: number[] | undefined =
    measurementPoints[getPointName(measurement.start, Position.Anterior)];
  const infPMeasurement: number[] | undefined =
    measurementPoints[getPointName(measurement.start, Position.Posterior)];
  const supAMeasurement: number[] | undefined =
    measurementPoints[getPointName(measurement.end, Position.Anterior)];
  const supPMeasurement: number[] | undefined =
    measurementPoints[getPointName(measurement.end, Position.Posterior)];
  const infPRMeasurement: number[] | undefined =
    measurementPoints[getPointName(measurement.start, Position.PatientRight)];
  const infPLMeasurement: number[] | undefined =
    measurementPoints[getPointName(measurement.start, Position.PatientLeft)];
  const supPRMeasurement: number[] | undefined =
    measurementPoints[getPointName(measurement.end, Position.PatientRight)];
  const supPLMeasurement: number[] | undefined =
    measurementPoints[getPointName(measurement.end, Position.PatientLeft)];

  if (
    supAMeasurement &&
    infAMeasurement &&
    supPMeasurement &&
    infPMeasurement &&
    supPRMeasurement &&
    supPLMeasurement &&
    infPRMeasurement &&
    infPLMeasurement
  ) {
    const supA: Vector3 = new Vector3(...supAMeasurement);
    const infA: Vector3 = new Vector3(...infAMeasurement);
    const supP: Vector3 = new Vector3(...supPMeasurement);
    const infP: Vector3 = new Vector3(...infPMeasurement);

    const supPR: Vector3 = new Vector3(...supPRMeasurement);
    const infPR: Vector3 = new Vector3(...infPRMeasurement);
    const supPL: Vector3 = new Vector3(...supPLMeasurement);
    const infPL: Vector3 = new Vector3(...infPLMeasurement);

    const supAP: Vector3 = supA.subtract(supP);
    const infAP: Vector3 = infA.subtract(infP);

    const supML: Vector3 = supPR.subtract(supPL);
    const infML: Vector3 = infPR.subtract(infPL);

    const apMean: Vector3 = supAP
      .add(infAP)
      .scale(1 / 2)
      .normalize();

    const infMLDotProduct: number = Vector3.Dot(infML, apMean);
    const infMLProj = infML
      .subtract(apMean.multiplyByFloats(infMLDotProduct, infMLDotProduct, infMLDotProduct))
      .normalize();

    const supMLDotProduct: number = Vector3.Dot(supML, apMean);
    const supMLProj = supML
      .subtract(apMean.multiplyByFloats(supMLDotProduct, supMLDotProduct, supMLDotProduct))
      .normalize();

    const dotProduct = Vector3.Dot(infMLProj, supMLProj);
    const radians = Math.acos(Math.round(dotProduct * 1000000) / 1000000);
    const angle = new Angle(radians).degrees();
    const cross = supMLProj.cross(infMLProj);

    let sign = 1;

    if (cross.z < 0 && angle !== 0) {
      sign = -1;
    }

    totalAngle = sign * angle;

    const vectorData: IAngleVectorData = {
      pointA: convertToCommonVector3(supPR.add(supPL).divide(new Vector3(2, 2, 2))), // Top face centroid
      pointB: convertToCommonVector3(infPR.add(infPL).divide(new Vector3(2, 2, 2))), // Bottom face centroid
      vectorA: convertToCommonVector3(supML.normalizeToNew()), // Top face AP
      vectorB: convertToCommonVector3(infML.normalizeToNew()), // Bottom face AP
    };

    return { value: totalAngle, vectorData };
  }

  return { value: totalAngle };
};

export const evaluateCoronalAngle = (
  measurement: MeasurementDefinition,
  measurementPoints: IMeasurementPoint,
): number => {
  return evaluateCoronalAngleData(measurement, measurementPoints).value;
};

export const evaluateLordoticAngleData = (
  measurement: MeasurementDefinition,
  measurementPoints: IMeasurementPoint,
): { value: number; vectorData?: IAngleVectorData } => {
  let totalAngle = 0;

  const infAMeasurement: number[] | undefined =
    measurementPoints[getPointName(measurement.start, Position.Anterior)];
  const infPMeasurement: number[] | undefined =
    measurementPoints[getPointName(measurement.start, Position.Posterior)];
  const supAMeasurement: number[] | undefined =
    measurementPoints[getPointName(measurement.end, Position.Anterior)];
  const supPMeasurement: number[] | undefined =
    measurementPoints[getPointName(measurement.end, Position.Posterior)];
  const infPRMeasurement: number[] | undefined =
    measurementPoints[getPointName(measurement.start, Position.PatientRight)];
  const infPLMeasurement: number[] | undefined =
    measurementPoints[getPointName(measurement.start, Position.PatientLeft)];
  const supPRMeasurement: number[] | undefined =
    measurementPoints[getPointName(measurement.end, Position.PatientRight)];
  const supPLMeasurement: number[] | undefined =
    measurementPoints[getPointName(measurement.end, Position.PatientLeft)];

  if (
    supAMeasurement &&
    infAMeasurement &&
    supPMeasurement &&
    infPMeasurement &&
    supPRMeasurement &&
    supPLMeasurement &&
    infPRMeasurement &&
    infPLMeasurement
  ) {
    const supA: Vector3 = new Vector3(...supAMeasurement);
    const infA: Vector3 = new Vector3(...infAMeasurement);
    const supP: Vector3 = new Vector3(...supPMeasurement);
    const infP: Vector3 = new Vector3(...infPMeasurement);

    const supPR: Vector3 = new Vector3(...supPRMeasurement);
    const infPR: Vector3 = new Vector3(...infPRMeasurement);
    const supPL: Vector3 = new Vector3(...supPLMeasurement);
    const infPL: Vector3 = new Vector3(...infPLMeasurement);

    const supAP: Vector3 = supA.subtract(supP);
    const infAP: Vector3 = infA.subtract(infP);

    const supML: Vector3 = supPR.subtract(supPL);
    const infML: Vector3 = infPR.subtract(infPL);

    const mlMean: Vector3 = supML
      .add(infML)
      .scale(1 / 2)
      .normalize();

    const infAPDotProduct: number = Vector3.Dot(infAP, mlMean);
    const infAPProj = infAP
      .subtract(mlMean.multiplyByFloats(infAPDotProduct, infAPDotProduct, infAPDotProduct))
      .normalize();

    const supAPDotProduct: number = Vector3.Dot(supAP, mlMean);
    const supAPProj = supAP
      .subtract(mlMean.multiplyByFloats(supAPDotProduct, supAPDotProduct, supAPDotProduct))
      .normalize();

    const dotProduct = Vector3.Dot(infAPProj, supAPProj);
    const radians = Math.acos(Math.round(dotProduct * 1000000) / 1000000);
    const angle = new Angle(radians).degrees();
    const cross = supAPProj.cross(infAPProj);

    let sign = 1;

    if (cross.x < 0 && angle !== 0) {
      sign = -1;
    }

    totalAngle = sign * angle;

    const vectorData: IAngleVectorData = {
      pointA: convertToCommonVector3(supA.add(supP).divide(new Vector3(2, 2, 2))), // Top face centroid
      pointB: convertToCommonVector3(infA.add(infP).divide(new Vector3(2, 2, 2))), // Bottom face centroid
      vectorA: convertToCommonVector3(supAP.normalizeToNew()), // Top face AP
      vectorB: convertToCommonVector3(infAP.normalizeToNew()), // Bottom face AP
    };

    return { value: totalAngle, vectorData };
  }

  return { value: totalAngle };
};

export const evaluateLordoticAngle = (
  measurement: MeasurementDefinition,
  measurementPoints: IMeasurementPoint,
): number => {
  return evaluateLordoticAngleData(measurement, measurementPoints).value;
};

export const evaluateHeight = (
  measurement: MeasurementDefinition,
  measurementPoints: IMeasurementPoint,
): number => {
  let totalDistance = 0;

  const position =
    measurement.type === MeasurementType.PosteriorHeight ? Position.Posterior : Position.Anterior;

  const interMeasurementPoints = parseInterMeasurementPoints(measurement, measurementPoints);

  const { centroid, yPrime } = evaluateNormalFromMeasurementPoints(interMeasurementPoints);

  const targetPoint1: number[] | undefined =
    measurementPoints[getPointName(measurement.start, position)];
  const targetPoint2: number[] | undefined =
    measurementPoints[getPointName(measurement.end, position)];

  const A: number = yPrime.x;
  const B: number = yPrime.y;
  const C: number = yPrime.z;

  const X: number = centroid.x;
  const Y: number = centroid.y;
  const Z: number = centroid.z;

  const D: number = -(A * X + B * Y + C * Z);

  if (targetPoint1 && targetPoint2) {
    const point1: Vector3 = new Vector3(...targetPoint1);
    const point2: Vector3 = new Vector3(...targetPoint2);

    const distance1: number =
      (A * point1.x + B * point1.y + C * point1.z + D) / Math.sqrt(A ** 2 + B ** 2 + C ** 2);

    const distance2: number =
      (A * point2.x + B * point2.y + C * point2.z + D) / Math.sqrt(A ** 2 + B ** 2 + C ** 2);

    totalDistance = distance1 - distance2;
  }

  return totalDistance;
};

export const evaluateTranslation = (
  measurement: MeasurementDefinition,
  measurementPoints: IMeasurementPoint,
): number => {
  const position =
    measurement.type === MeasurementType.APTranslation ? Position.Posterior : Position.PatientLeft;

  const interMeasurementPoints = parseInterMeasurementPoints(measurement, measurementPoints);

  const { centroid, yPrime: centroidPlaneNormal } =
    evaluateNormalFromMeasurementPoints(interMeasurementPoints);

  const targetPoint1: number[] | undefined =
    measurementPoints[getPointName(measurement.start, position)];
  const targetPoint2: number[] | undefined =
    measurementPoints[getPointName(measurement.end, position)];

  if (!targetPoint1 || !targetPoint2) return 0;

  const centroidPlane = Plane.FromPositionAndNormal(centroid, centroidPlaneNormal);

  const point1CentroidPlaneProj = projectPointOntoPlane(
    new Vector3(...targetPoint1),
    centroidPlane,
  );
  const point2CentroidPlaneProj = projectPointOntoPlane(
    new Vector3(...targetPoint2),
    centroidPlane,
  );

  // NOTE - If calculating AP translation, points should be
  /**
   * If calculating AP translation, points should be projected onto the sagittal plane
   * If calculating ML translation, points should be projected onto the coronal plane
   */
  const viewPlane = Plane.FromPositionAndNormal(
    centroid,
    measurement.type === MeasurementType.APTranslation
      ? new Vector3(1, 0, 0)
      : new Vector3(0, 0, 1),
  );

  const point1ViewPlaneProj = projectPointOntoPlane(point1CentroidPlaneProj, viewPlane);
  const point2ViewPlaneProj = projectPointOntoPlane(point2CentroidPlaneProj, viewPlane);

  const diff =
    measurement.type === MeasurementType.APTranslation
      ? point2ViewPlaneProj.x - point1CentroidPlaneProj.x
      : point2ViewPlaneProj.z - point1CentroidPlaneProj.z;

  const sign = diff < 0 ? -1 : 1;

  return sign * Vector3.Distance(point1ViewPlaneProj, point2ViewPlaneProj);
};

export const evaluateAxialRotation = (
  measurement: MeasurementDefinition,
  measurementPoints: IMeasurementPoint,
): {
  value: number;
  inferiorVectorData?: ILineVectorData;
  superiorVectorData?: ILineVectorData;
} => {
  const interMeasurementPoints = parseInterMeasurementPoints(measurement, measurementPoints);

  const inferiorAnteriorPoint: number[] | undefined =
    measurementPoints[getPointName(measurement.start, Position.Anterior)];
  const inferiorPosteriorPoint: number[] | undefined =
    measurementPoints[getPointName(measurement.start, Position.Posterior)];

  const superiorAnteriorPoint: number[] | undefined =
    measurementPoints[getPointName(measurement.end, Position.Anterior)];
  const superiorPosteriorPoint: number[] | undefined =
    measurementPoints[getPointName(measurement.end, Position.Posterior)];

  if (
    !inferiorAnteriorPoint ||
    !inferiorPosteriorPoint ||
    !superiorAnteriorPoint ||
    !superiorPosteriorPoint
  )
    return { value: 0 };

  const { centroid, yPrime: centroidPlaneNormal } =
    evaluateNormalFromMeasurementPoints(interMeasurementPoints);

  const centroidPlane = Plane.FromPositionAndNormal(centroid, centroidPlaneNormal);

  const inferiorPosteriorPointVector = new Vector3(...inferiorPosteriorPoint);
  const inferiorAnteriorPointVector = new Vector3(...inferiorAnteriorPoint);
  const superiorPosteriorPointVector = new Vector3(...superiorPosteriorPoint);
  const superiorAnteriorPointVector = new Vector3(...superiorAnteriorPoint);

  const inferiorAP = inferiorPosteriorPointVector
    .subtract(inferiorAnteriorPointVector)
    .normalizeToNew();

  const superiorAP = superiorPosteriorPointVector
    .subtract(superiorAnteriorPointVector)
    .normalizeToNew();

  const inferiorAPProj = projectVectorOntoPlane(inferiorAP, centroidPlane);
  const superiorAPProj = projectVectorOntoPlane(superiorAP, centroidPlane);

  const radians = Vector3.GetAngleBetweenVectors(
    inferiorAPProj,
    superiorAPProj,
    centroidPlaneNormal,
  );

  let angle = new Angle(radians).degrees();

  // NOTE - If top vertebrae AP is more CW (in bottom-up axial view) then positive angle
  const sign = angle <= 90 ? 1 : -1;

  if (sign < 0) {
    angle = sign * (360 - angle);
  }

  const inferiorVectorData: ILineVectorData = {
    pointA: convertToCommonVector3(inferiorPosteriorPointVector),
    pointB: convertToCommonVector3(inferiorAnteriorPointVector),
  };

  const superiorVectorData: ILineVectorData = {
    pointA: convertToCommonVector3(superiorPosteriorPointVector),
    pointB: convertToCommonVector3(superiorAnteriorPointVector),
  };

  return { value: angle, inferiorVectorData, superiorVectorData };
};

export const evaluateSVA = (
  measurement: MeasurementDefinition,
  measurementPoints: IMeasurementPoint,
): { value: number; vectorData?: IPlumbLineDistanceVectorData } => {
  // Calculate top vertebrae centroid
  const topVertebraePlanePoints = parseInterMeasurementPoints(
    {
      type: measurement.type,
      start: {
        body: measurement.start.body,
        endPlate: EndPlate.Superior,
      },
      end: {
        body: measurement.start.body,
        endPlate: EndPlate.Inferior,
      },
    },
    measurementPoints,
  );

  const { centroid: topVertebraeCentroid } =
    evaluateNormalFromMeasurementPoints(topVertebraePlanePoints);

  // Calculate bottom vertebrae superior face centroid
  const bottomVertebraeSuperiorPlanePoints = parseInterMeasurementPoints(
    {
      type: measurement.type,
      start: {
        body: measurement.end.body,
        endPlate: EndPlate.Superior,
      },
      end: {
        body: measurement.end.body,
        endPlate: EndPlate.Superior,
      },
    },
    measurementPoints,
  );

  const bottomVertebraeSuperiorCentroid = calculateSuperiorCentroid(
    bottomVertebraeSuperiorPlanePoints,
  );

  const bottomVertebraePosition = Position.Posterior;

  const bottomVertebraePoint: number[] | undefined =
    measurementPoints[getPointName(measurement.end, bottomVertebraePosition)];

  if (!bottomVertebraePoint) return { value: 0 };

  // Up direction in CT
  const axialPlaneNormal = new Vector3(0, 1, 0);

  const axialPlane = Plane.FromPositionAndNormal(bottomVertebraeSuperiorCentroid, axialPlaneNormal);

  const topVertebraeCentroidAxialProj = projectPointOntoPlane(topVertebraeCentroid, axialPlane);

  const bottomVertebraePointAxialProj = projectPointOntoPlane(
    new Vector3(...bottomVertebraePoint),
    axialPlane,
  );

  const sagittalPlane = Plane.FromPositionAndNormal(
    bottomVertebraeSuperiorCentroid,
    new Vector3(1, 0, 0),
  );

  const topVertebraeCentroidSagittalProj = projectPointOntoPlane(
    topVertebraeCentroidAxialProj,
    sagittalPlane,
  );

  const bottomVertebraePointSagittalProj = projectPointOntoPlane(
    bottomVertebraePointAxialProj,
    sagittalPlane,
  );

  const vectorData: IPlumbLineDistanceVectorData = {
    pointA: convertToCommonVector3(topVertebraeCentroidSagittalProj),
    pointB: convertToCommonVector3(bottomVertebraePointSagittalProj),
    upVector: convertToCommonVector3(new Vector3(0, 1, 0)),
  };

  return {
    value: Vector3.Distance(topVertebraeCentroidSagittalProj, bottomVertebraePointSagittalProj),
    vectorData,
  };
};

export const evaluateCervicalCoronalBalance = (
  measurement: MeasurementDefinition,
  measurementPoints: IMeasurementPoint,
): { value: number; vectorData?: IPlumbLineDistanceVectorData } => {
  // Calculate top vertebrae centroid
  const topVertebraePlanePoints = parseInterMeasurementPoints(
    {
      type: measurement.type,
      start: {
        body: measurement.start.body,
        endPlate: EndPlate.Superior,
      },
      end: {
        body: measurement.start.body,
        endPlate: EndPlate.Inferior,
      },
    },
    measurementPoints,
  );

  const bottomVertebraePlanePoints = parseInterMeasurementPoints(
    {
      type: measurement.type,
      start: {
        body: measurement.end.body,
        endPlate: EndPlate.Superior,
      },
      end: {
        body: measurement.end.body,
        endPlate: EndPlate.Inferior,
      },
    },
    measurementPoints,
  );

  const { centroid: topVertebraeCentroid } =
    evaluateNormalFromMeasurementPoints(topVertebraePlanePoints);

  const { centroid: bottomVertebraeCentroid } = evaluateNormalFromMeasurementPoints(
    bottomVertebraePlanePoints,
  );

  const axialPlane = Plane.FromPositionAndNormal(bottomVertebraeCentroid, new Vector3(0, 1, 0));

  const topVertebraeCentroidAxialProj = projectPointOntoPlane(topVertebraeCentroid, axialPlane);

  const bottomVertebraeCentroidAxialProj = projectPointOntoPlane(
    bottomVertebraeCentroid,
    axialPlane,
  );

  const coronalPlane = Plane.FromPositionAndNormal(bottomVertebraeCentroid, new Vector3(0, 0, 1));

  const topVertebraeCentroidCoronalProj = projectPointOntoPlane(
    topVertebraeCentroidAxialProj,
    coronalPlane,
  );

  const bottomVertebraeCentroidCoronalProj = projectPointOntoPlane(
    bottomVertebraeCentroidAxialProj,
    coronalPlane,
  );

  const distance = Vector3.Distance(
    topVertebraeCentroidCoronalProj,
    bottomVertebraeCentroidCoronalProj,
  );

  const vectorData: IPlumbLineDistanceVectorData = {
    pointA: convertToCommonVector3(topVertebraeCentroidCoronalProj),
    pointB: convertToCommonVector3(bottomVertebraeCentroidCoronalProj),
    upVector: convertToCommonVector3(new Vector3(0, 1, 0)),
  };

  return {
    value: distance,
    vectorData,
  };
};

const convertToCommonVector3 = (vector: Vector3): CommonVector3 => {
  return {
    x: vector.x,
    y: vector.y,
    z: vector.z,
  };
};

export const evaluateLateralAngleFromGround = (
  measurement: MeasurementDefinition,
  measurementPoints: IMeasurementPoint,
): { value: number; vectorData?: IAngleOriginVectorData } => {
  const supA: number[] | undefined =
    measurementPoints[getPointName(measurement.start, Position.Anterior)];
  const supP: number[] | undefined =
    measurementPoints[getPointName(measurement.start, Position.Posterior)];

  if (!supA || !supP) return { value: 0 };
  const supAVector = new Vector3(...supA);
  const supPVector = new Vector3(...supP);

  let normal = supPVector.subtract(supAVector).normalizeToNew();

  if (normal.z < 0) {
    normal = normal.scale(-1);
  }

  const sagittalPlaneNormal = new Vector3(1, 0, 0);

  const projectedNormal = normal
    .subtract(sagittalPlaneNormal.scale(Vector3.Dot(normal, sagittalPlaneNormal)))
    .normalize();

  const flatNormal = new Vector3(0, 0, 1);

  // Calculate the dot product
  let dotProduct = Vector3.Dot(projectedNormal, flatNormal);

  // Calculate the angle in radians
  let radians = Math.acos(dotProduct);

  const angle = Tools.ToDegrees(radians);

  const vectorData: IAngleOriginVectorData = {
    origin: convertToCommonVector3(getMidpoint(supAVector, supPVector)),
    topVector: convertToCommonVector3(normal),
    bottomVector: convertToCommonVector3(flatNormal),
  };

  return { value: angle, vectorData };
};

const evaluateNormalFromMeasurementPoints = (
  measurements: IMeasurementPoint,
): {
  centroid: Vector3;
  ap: Vector3;
  ml: Vector3;
  yPrime: Vector3;
} => {
  const evaluatedMeasurementData = calculateDoubleMeasurementSet(measurements);

  if (evaluatedMeasurementData) {
    const { centroid, ap, ml } = evaluatedMeasurementData;

    //define disc coordinate system
    const yPrime: Vector3 = ml.cross(ap).normalize();

    return {
      centroid,
      ap,
      ml,
      yPrime,
    };
  } else {
    throw new Error('Measurement data not found, cannot evaluate correction data');
  }
};

const calculateSuperiorCentroid = (measurements: IMeasurementPoint) => {
  let supAMeasurement: number[] = [];
  let supPMeasurement: number[] = [];
  let supPRMeasurement: number[] = [];
  let supPLMeasurement: number[] = [];

  for (let key in measurements) {
    if (key.includes(Position.Anterior) && key.includes(EndPlate.Superior)) {
      supAMeasurement = measurements[key];
    }
    if (key.includes(Position.Posterior) && key.includes(EndPlate.Superior)) {
      supPMeasurement = measurements[key];
    }
    if (key.includes(Position.PatientRight) && key.includes(EndPlate.Superior)) {
      supPRMeasurement = measurements[key];
    }
    if (key.includes(Position.PatientLeft) && key.includes(EndPlate.Superior)) {
      supPLMeasurement = measurements[key];
    }
  }

  if (supAMeasurement && supPMeasurement && supPRMeasurement && supPLMeasurement) {
    const supA = new Vector3(...supAMeasurement);
    const supP = new Vector3(...supPMeasurement);
    const supPR = new Vector3(...supPRMeasurement);
    const supPL = new Vector3(...supPLMeasurement);

    const midpointAP = getMidpoint(supA, supP);
    const midpointML = getMidpoint(supPR, supPL);
    const centroid = getMidpoint(midpointAP, midpointML);

    return centroid;
  } else {
    throw new Error('Measurement point not found, cannot evaluate centroid');
  }
};

const calculateDoubleMeasurementSet = (measurements: IMeasurementPoint) => {
  let supAMeasurement: number[] = [];
  let supPMeasurement: number[] = [];
  let supPRMeasurement: number[] = [];
  let supPLMeasurement: number[] = [];
  let infAMeasurement: number[] = [];
  let infPMeasurement: number[] = [];
  let infPRMeasurement: number[] = [];
  let infPLMeasurement: number[] = [];

  for (let key in measurements) {
    if (key.includes(Position.Anterior) && key.includes(EndPlate.Superior)) {
      supAMeasurement = measurements[key];
    }
    if (key.includes(Position.Posterior) && key.includes(EndPlate.Superior)) {
      supPMeasurement = measurements[key];
    }
    if (key.includes(Position.PatientRight) && key.includes(EndPlate.Superior)) {
      supPRMeasurement = measurements[key];
    }
    if (key.includes(Position.PatientLeft) && key.includes(EndPlate.Superior)) {
      supPLMeasurement = measurements[key];
    }
    if (key.includes(Position.Anterior) && key.includes(EndPlate.Inferior)) {
      infAMeasurement = measurements[key];
    }
    if (key.includes(Position.Posterior) && key.includes(EndPlate.Inferior)) {
      infPMeasurement = measurements[key];
    }
    if (key.includes(Position.PatientRight) && key.includes(EndPlate.Inferior)) {
      infPRMeasurement = measurements[key];
    }
    if (key.includes(Position.PatientLeft) && key.includes(EndPlate.Inferior)) {
      infPLMeasurement = measurements[key];
    }
  }

  if (
    supAMeasurement &&
    supPMeasurement &&
    supPRMeasurement &&
    supPLMeasurement &&
    infAMeasurement &&
    infPMeasurement &&
    infPRMeasurement &&
    infPLMeasurement
  ) {
    const supA = new Vector3(...supAMeasurement);
    const supP = new Vector3(...supPMeasurement);
    const supPR = new Vector3(...supPRMeasurement);
    const supPL = new Vector3(...supPLMeasurement);

    const infA = new Vector3(...infAMeasurement);
    const infP = new Vector3(...infPMeasurement);
    const infPR = new Vector3(...infPRMeasurement);
    const infPL = new Vector3(...infPLMeasurement);

    const divisor = new Vector3(2, 2, 2);

    //calculate the AP and ML mean between the superior and inferior bodies
    const superiorAP: Vector3 = supA.subtract(supP);
    const inferiorAP: Vector3 = infA.subtract(infP);
    const ap: Vector3 = superiorAP.add(inferiorAP).divide(divisor);
    const superiorML: Vector3 = supPL.subtract(supPR);
    const inferiorML: Vector3 = infPL.subtract(infPR);
    const ml: Vector3 = superiorML.add(inferiorML).divide(divisor);

    const midpointA = getMidpoint(supA, infA);
    const midpointP = getMidpoint(supP, infP);
    const midpointPR = getMidpoint(supPR, infPR);
    const midpointPL = getMidpoint(supPL, infPL);

    const midpointAP = getMidpoint(midpointA, midpointP);
    const midpointML = getMidpoint(midpointPR, midpointPL);
    const centroid = getMidpoint(midpointAP, midpointML);

    return {
      centroid,
      ap,
      ml,
    };
  } else {
    throw new Error('Measurement point not found, cannot evaluate centroid');
  }
};

function getMidpoint(vectorA: Vector3, vectorB: Vector3): Vector3 {
  const difference = vectorB.subtract(vectorA);
  const midpoint = difference.divide(new Vector3(2, 2, 2));

  return vectorA.add(midpoint);
}

export function vectorFromArray(point: number[]): Vector3 {
  if (!point) {
    return Vector3.Zero();
  }
  return new Vector3(point[0], point[1], point[2]);
}

export function calculateDistanceBetweenPoints(point1: number[], point2: number[]) {
  return vectorFromArray(point1).subtract(vectorFromArray(point2)).length();
}

export function calculateHeightBetweenPointsAlongPlane(
  p1: Vector3,
  a1: Vector3,
  a2: Vector3,
  axis: Axis,
) {
  // STEP 1 - figure out the normal from the plane
  const ap1 = p1.subtract(a1);
  let normal = Vector3.Zero();

  switch (axis) {
    case Axis.X:
      normal = Vector3.Cross(ap1, new Vector3(1, 0, 0)).normalize();
      break;
    case Axis.Y:
      normal = Vector3.Cross(ap1, new Vector3(0, 1, 0)).normalize();
      break;
    case Axis.Z:
      normal = Vector3.Cross(ap1, new Vector3(0, 0, 1)).normalize();
      break;
  }

  // STEP 2 - project ap2 against the normal
  const a2DotProduct = Vector3.Dot(a2.subtract(a1), normal);
  const a2Prime = a2.subtract(normal.multiplyByFloats(a2DotProduct, a2DotProduct, a2DotProduct));

  // STEP 3 - define the vector of the anterior and the posterior
  const result = a1.subtract(a2Prime);
  return result.length();
}
