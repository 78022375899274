import {
  AssetType,
  CaseSpineProfile,
  caseUtils,
  DEFAULT_SPINE_PROFILE,
  IAsset,
  ICase,
  IMeasure,
  IMeasurementPointValues,
  IPlan,
  IPlanImplant,
  LevelType,
  measurements,
  MeasurementsVersionType,
} from '@workflow-nx/common';
import { Nullable, Quaternion, Scene, Vector3 } from 'babylonjs';
import {
  getMeasurementPointsByTags,
  setPointOfRotation,
} from '../../../../../components/SceneComponent/renderer';
import { ISettingsForm19 } from '../../../../../utils/form19';
import { getMeasurementsFromAssetPositions } from './utils/implantEditor';

export type ImplantEditorActionType = {
  type:
    | 'ADD_IMPLANT_POSITION'
    | 'DISPOSE'
    | 'CUT_IMPLANT'
    | 'EDIT_IMPLANT_POSITION'
    | 'SET_IMPLANT_ORIGINAL_POSITION'
    | 'SET_IMPLANT_MESH_DIRTY'
    | 'SET_IMPLANT_SHOULD_UPDATE'
    | 'IMPLANTS_LOADED'
    | 'INIT'
    | 'REMOVE_IMPLANT'
    | 'SCENE_READY'
    | 'SCENE_LOADED'
    | 'POINT_OF_ROTATION_CHANGED'
    | 'TOGGLE_ADD_IMPLANT_DIALOG'
    | 'TOGGLE_EDIT_IMPLANT_DIALOG'
    | 'UPDATE_IMPLANTS';
  data?: any;
};

export type ImplantEditorStateType = {
  addImplantLevel?: LevelType;
  editImplantLevel?: LevelType;
  vertebralBodyAssets: any[];
  caseLevels: LevelType[];
  cuttingImplantLevel?: LevelType;
  moveImplantLevel?: LevelType;
  moveImplant?: IPlanImplant;
  moveImplantOriginalPositionAndRotation?: { position: Vector3; rotation: Nullable<Quaternion> };
  moveImplantIsDirty?: boolean;
  moveImplantShouldUpdate?: boolean;
  isAssetsLoaded?: boolean;
  isImplantsLoaded?: boolean;
  isSceneLoaded?: boolean;
  isSceneReady?: boolean;
  lumbarLordosis: number;
  lumbarCoronalCobb: number;
  measurementPointValues?: IMeasurementPointValues;
  measurements: IMeasure[];
  pelvicIncidence: number;
  settings?: { form19: ISettingsForm19 };
  showPreopAssetView?: boolean;
  showAddImplantDialog?: boolean;
  showEditImplantDialog?: boolean;
};

export const implantEditorReducer =
  (
    activeCase: ICase,
    caseMeasurementsVersion: MeasurementsVersionType,
    plan: IPlan,
    scene?: Scene,
  ) =>
  (state: ImplantEditorStateType, action: ImplantEditorActionType): ImplantEditorStateType => {
    const updatedState = { ...state };

    const spineProfile: CaseSpineProfile = activeCase.spineProfile || DEFAULT_SPINE_PROFILE;
    const measurementsVersion: MeasurementsVersionType = caseMeasurementsVersion;

    switch (action.type) {
      case 'ADD_IMPLANT_POSITION': {
        updatedState.moveImplantLevel = action.data.level;
        break;
      }
      case 'CUT_IMPLANT': {
        updatedState.cuttingImplantLevel = action.data;
        break;
      }
      case 'EDIT_IMPLANT_POSITION': {
        updatedState.moveImplantLevel = action.data.level;
        updatedState.moveImplant = action.data.implant;

        if (!action.data.level) {
          updatedState.moveImplantOriginalPositionAndRotation = undefined;
          updatedState.moveImplantIsDirty = undefined;
        }

        break;
      }
      case 'SET_IMPLANT_ORIGINAL_POSITION': {
        updatedState.moveImplantOriginalPositionAndRotation = action.data;
        break;
      }
      case 'SET_IMPLANT_MESH_DIRTY': {
        updatedState.moveImplantIsDirty = action.data;
        break;
      }
      case 'SET_IMPLANT_SHOULD_UPDATE': {
        updatedState.moveImplantShouldUpdate = action.data;
        break;
      }
      case 'POINT_OF_ROTATION_CHANGED': {
        if (!scene) break;

        const point: Vector3 = action.data;

        setPointOfRotation(point, scene);
        break;
      }
      case 'REMOVE_IMPLANT': {
        /*
        updatedState.editingImplantLevel = undefined;

        updatedState.planImplants = JSON.parse(
          JSON.stringify(
            updatedState.planImplants.filter(
              (updatedPlanImplant: IPlanImplant) => updatedPlanImplant.level !== action.data.level,
            ),
          ),
        );

*/
        break;
      }
      case 'TOGGLE_ADD_IMPLANT_DIALOG': {
        updatedState.showAddImplantDialog = action.data.open;
        updatedState.addImplantLevel = action.data.addImplantLevel;
        break;
      }
      case 'TOGGLE_EDIT_IMPLANT_DIALOG': {
        updatedState.showEditImplantDialog = action.data.open;
        updatedState.editImplantLevel = action.data.editImplantLevel;
        break;
      }
      case 'UPDATE_IMPLANTS': {
        /*
        if (!!scene && action.data) {
          updatedState.planImplants = action.data.planImplants;
          break;
        }
*/
        break;
      }
      case 'SCENE_READY': {
        updatedState.isSceneReady = true;
        break;
      }
      case 'SCENE_LOADED': {
        updatedState.isSceneLoaded = true;
        updatedState.lumbarLordosis =
          measurements.getLumbarLordosis(
            measurements.getMeasurementPointValues(
              getMeasurementPointsByTags(scene),
              measurementsVersion,
              spineProfile,
            ),
          ) ?? 0;
        updatedState.lumbarCoronalCobb =
          measurements.getLumbarCoronalCobb(
            measurements.getMeasurementPointValues(
              getMeasurementPointsByTags(scene),
              measurementsVersion,
              spineProfile,
            ),
          ) ?? 0;
        break;
      }
      case 'IMPLANTS_LOADED': {
        updatedState.isImplantsLoaded = true;
        break;
      }
      case 'INIT': {
        const assets: IAsset[] = action.data.assets;
        const measurementPoints = getMeasurementPointsByTags(scene);

        updatedState.measurementPointValues = measurements.getMeasurementPointValues(
          measurementPoints,
          measurementsVersion,
          activeCase.spineProfile,
        );
        updatedState.pelvicIncidence = activeCase?.patient?.patientRecord?.pelvicIncidence ?? 0;
        updatedState.caseLevels = caseUtils.getValidCaseLevels(activeCase.levels);
        updatedState.measurements = getMeasurementsFromAssetPositions(
          plan.assetPositions.plan.points,
        );

        const vertebraeAssetTypes = caseUtils.getCaseVertebralBodyAssets(
          activeCase.spineProfile,
        ).standard;

        vertebraeAssetTypes.push(AssetType.Pelvis);

        updatedState.vertebralBodyAssets = assets.filter((asset) =>
          vertebraeAssetTypes.includes(asset.assetType),
        );

        updatedState.isAssetsLoaded = true;
        updatedState.isSceneLoaded = false;
        updatedState.isImplantsLoaded = false;
        updatedState.settings = action.data?.settings ?? {};
        break;
      }
      case 'DISPOSE': {
        scene?.dispose();
        updatedState.vertebralBodyAssets = [];
        // updatedState.planImplants = [];
        updatedState.isSceneReady = false;
        updatedState.isAssetsLoaded = true;
        updatedState.isSceneLoaded = false;
        updatedState.isImplantsLoaded = false;
        break;
      }
    }

    return updatedState;
  };
