import { Button, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { MouseEventHandler } from 'react';
import { testing } from '@workflow-nx/utils';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'relative',
    top: '50%',
    left: '50%',
    marginLeft: -12,
  },
}));

type ProgressButtonProps = {
  variant?: 'text' | 'outlined' | 'contained';
  onClick?: MouseEventHandler;
  disabled?: boolean;
  loading?: boolean;
  label: string;
};

export function ProgressButton({
  disabled,
  loading,
  label,
  onClick,
  variant,
}: ProgressButtonProps) {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Button
        color="secondary"
        variant={variant || 'contained'}
        type={onClick ? 'button' : 'submit'}
        onClick={onClick}
        disabled={disabled || loading}
        data-test-id={`${testing.toKebabCase(label)}-button`}
      >
        {loading && (
          <>
            <CircularProgress size={24} className={classes.buttonProgress} />
            <span>{label}</span>
          </>
        )}
        {!loading && <span>{label}</span>}
      </Button>
    </div>
  );
}
