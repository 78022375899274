import { AssetType, format, ICase, IDicomMetadata } from '@workflow-nx/common';
import CustomDialog from '../../../../components/CustomDialog';
import {
  DatePickerRHF,
  ErrorMessageRHF,
  InputConfigRHF,
  InputTypeRHF,
  ProgressButton,
  SelectFieldRHF,
  TextFieldRHF,
} from '@workflow-nx/ui';
import { date } from '@workflow-nx/utils';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import * as Yup from 'yup';
import { Control, FieldValues, Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

const useStyles = makeStyles(() => ({
  different: {
    backgroundColor: 'lightyellow',
    border: 5,
    padding: 2,
  },
}));

const formRowTextData: InputConfigRHF[] = [
  {
    id: 'firstName',
    label: 'First Name',
    input: InputTypeRHF.Text,
  },
  {
    id: 'middleName',
    label: 'Middle Name',
    input: InputTypeRHF.Text,
  },
  {
    id: 'lastName',
    label: 'Last Name',
    input: InputTypeRHF.Text,
  },
  {
    id: 'mrn',
    label: 'MRN',
    input: InputTypeRHF.Text,
  },
  {
    id: 'gender',
    label: 'Gender',
    input: InputTypeRHF.Select,
    menuItems: [
      { key: 'MALE', value: 'Male' },
      { key: 'FEMALE', value: 'Female' },
    ],
  },
  {
    id: 'birthDate',
    label: 'Birthdate',
    input: InputTypeRHF.Date,
  },
];

export function MetadataReviewDialog(props: {
  activeCase?: ICase;
  assetType?: AssetType.DicomCt | AssetType.DicomXray;
  metadata?: IDicomMetadata;
  confirmedMetadata?: IDicomMetadata;
  open: boolean;
  onClose: () => void;
  onConfirm: (metadata: IDicomMetadata) => void;
}) {
  const styles = useStyles();
  const handleSubmitForm: SubmitHandler<IDicomMetadata> = async (data) => {
    if (data.birthDate) {
      props.onConfirm({ ...data, birthDate: new Date(data.birthDate) as unknown as string });
    } else {
      props.onConfirm(data);
    }
  };
  const hasCurrentColumn = Boolean(props?.confirmedMetadata || props?.activeCase?.patient);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      ...props.metadata,
      birthDate: date.parseCalendarDateFromString(props.metadata?.birthDate as string),
    } as any,
    resolver: yupResolver(
      Yup.object().shape({
        firstName: Yup.string().required('First Name is required'),
        middleName: Yup.string().nullable(),
        lastName: Yup.string().required('Last Name is required'),
        birthDate: hasCurrentColumn
          ? Yup.string().test('match', 'Birthdates do not match', function (value) {
              const formattedValue = format.formatDate(new Date(value ?? '2000-01-01'));

              if (props?.activeCase?.patient?.birthDate) {
                return formattedValue === props.activeCase.patient.birthDate;
              } else if (props?.confirmedMetadata?.birthDate) {
                return formattedValue === props.confirmedMetadata.birthDate;
              } else {
                //fail-safe if it cannot find the previous birthday to still allow the user enter a new birthdate in
                return true;
              }
            })
          : Yup.string().required('Birthday is required'),
        gender: Yup.string().required(),
        mrn: Yup.string().nullable(),
      }),
    ) as unknown as Resolver<IDicomMetadata>,
  });

  return (
    <CustomDialog
      maxWidth={'md'}
      open={props.open}
      onClose={props.onClose}
      title={`Verify ${format.formatAssetType(props.assetType)} metadata`}
      positiveActionButtons={[
        <ProgressButton
          label={'Confirm'}
          onClick={(evt) => handleSubmit(handleSubmitForm)(evt)}
          disabled={isSubmitting}
        />,
      ]}
    >
      <form>
        <Grid container spacing={2}>
          <Grid item container xs={12} spacing={1} alignItems={'center'}>
            <Grid item xs={hasCurrentColumn ? 2 : 3} display={'flex'} alignItems={'center'}></Grid>
            <Grid item xs={hasCurrentColumn ? 2 : 3} display={'flex'} alignItems={'center'}>
              <Typography variant={'body1'}>Metadata</Typography>
            </Grid>
            {hasCurrentColumn ? (
              <Grid item xs={2} display={'flex'} alignItems={'center'}>
                <Typography variant={'body1'}>Current</Typography>
              </Grid>
            ) : null}
            <Grid item xs={3}>
              <Typography variant={'body1'}>Value</Typography>
            </Grid>
            <Grid item xs={3} display={'flex'} justifyContent={'center'}></Grid>
          </Grid>
          {formRowTextData.map((config, index) => {
            const pendingMetadataField = (props.metadata as any)?.[config.id];
            const currentValue = (props?.activeCase?.patient as any)?.[config.id] ?? '';
            const confirmedData = (props?.confirmedMetadata as any)?.[config.id] ?? '';
            let fieldValue;
            switch (config.id) {
              case 'gender':
                fieldValue = currentValue ? (
                  format.formatGender(currentValue)
                ) : confirmedData ? (
                  format.formatGender(confirmedData)
                ) : (
                  <>&mdash;</>
                );
                break;
              case 'birthDate':
                fieldValue = currentValue ? (
                  currentValue
                ) : confirmedData ? (
                  confirmedData
                ) : (
                  <>&mdash;</>
                );
                break;
              default:
                fieldValue = currentValue ? (
                  currentValue
                ) : confirmedData ? (
                  confirmedData
                ) : (
                  <>&mdash;</>
                );
            }

            return (
              <Grid item container xs={12} spacing={1} alignItems={'center'} key={index}>
                <Grid item xs={hasCurrentColumn ? 2 : 3} display={'flex'} alignItems={'center'}>
                  <Typography variant={'body1'}>{config.label}</Typography>
                </Grid>
                <Grid item xs={hasCurrentColumn ? 2 : 3} display={'flex'} alignItems={'center'}>
                  <Typography variant={'body1'}>{pendingMetadataField || <>&mdash;</>}</Typography>
                </Grid>
                {hasCurrentColumn ? (
                  <Grid
                    item
                    xs={2}
                    md={2}
                    display={'flex'}
                    alignItems={'center'}
                    className={pendingMetadataField !== currentValue ? styles.different : ''}
                  >
                    <Typography variant={'body1'}>{fieldValue}</Typography>
                  </Grid>
                ) : null}
                <Grid item xs={3}>
                  {config.input === InputTypeRHF.Select ? (
                    <SelectFieldRHF
                      control={control}
                      name={config.id}
                      menuItems={config.menuItems ? config.menuItems : []}
                      disabled={isSubmitting}
                      hideNone
                    />
                  ) : config.input === InputTypeRHF.Date ? (
                    <DatePickerRHF
                      name={config.id}
                      control={control as unknown as Control<FieldValues>}
                      disabled={isSubmitting}
                    />
                  ) : (
                    <TextFieldRHF
                      name={config.id}
                      control={control as unknown as Control<FieldValues>}
                      disabled={isSubmitting}
                    />
                  )}
                </Grid>
                <Grid item xs={3} display={'flex'} justifyContent={'center'}>
                  <ErrorMessageRHF
                    name={config.id}
                    control={control as unknown as Control<FieldValues>}
                  />
                </Grid>
              </Grid>
            );
          })}
          <Grid item container xs={12} spacing={1} alignItems={'center'}>
            <Grid item xs={hasCurrentColumn ? 2 : 3} display={'flex'} alignItems={'center'}>
              <Typography variant={'body1'}>Study Date</Typography>
            </Grid>
            <Grid item xs={hasCurrentColumn ? 2 : 3} display={'flex'} alignItems={'center'}>
              <Typography variant={'body1'}>
                {(props.metadata as any)?.['studyDate'] || <>&mdash;</>}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  );
}
