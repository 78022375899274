import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import { getDicomMetadata } from '../utils/dicom';
import { LabelledProgressBar } from './LabelledProgressBar';
import { OutlinedFileTextField } from './OutlinedFileTextField';
import { FileSelectedView } from './FileSelectedView';
import { Box, Grid, Popover } from '@mui/material';
import { DicomMetadataView } from './DicomMetadataView';
import { IAsset, IDicomMetadata } from '@workflow-nx/common';

export function DicomField(props: {
  asset?: IAsset;
  disabled?: boolean;
  name: string;
  label: string;
  loading: boolean;
  onLoaded: (dicomMetadata: IDicomMetadata) => void;
}) {
  const [processingDicom, setProcessingDicom] = useState(false);
  const [showDetailsPopover, setShowDetailsPopover] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [asset, setAsset] = React.useState<IAsset | undefined>();
  const divRef = React.useRef();

  const [dicomInput, , dicomHelpers] = useField(props.name);
  const [dicomMetadataInput, , dicomMetadataHelpers] = useField(`${props.name}Metadata`);

  const handleProcessZipFile = async (zipFile: File) => {
    try {
      dicomMetadataHelpers.setValue({});
      setProcessingDicom(true);

      const dicomMetadata = await getDicomMetadata(zipFile, props.asset?.assetType);
      dicomMetadataHelpers.setValue(dicomMetadata);
      props.onLoaded(dicomMetadata);
    } finally {
      setProcessingDicom(false);
    }
  };

  useEffect(() => {
    setAsset(props?.asset);
  }, [props.asset]);

  return (
    <>
      <Grid container spacing={1} direction={'column'}>
        <Grid item xs={12}>
          {processingDicom ? <LabelledProgressBar /> : null}

          {asset && !processingDicom ? (
            <Box ref={divRef}>
              <FileSelectedView
                hideRemove={false}
                label={props.label}
                name={asset?.fileName || ''}
                size={asset?.size || 0}
                onRemove={() => {
                  setAsset(undefined);
                  dicomHelpers.setValue(null);
                  dicomMetadataHelpers.setValue({});
                }}
                onDetails={() => {
                  // @ts-ignore
                  setAnchorEl(divRef?.current);
                  setShowDetailsPopover(true);
                }}
                disabled={props?.disabled}
              />
            </Box>
          ) : null}

          {!asset && !processingDicom && !dicomInput.value ? (
            <OutlinedFileTextField
              label={props.label}
              name={props.name}
              onBlur={dicomInput.onBlur}
              onChange={(event) => {
                // @ts-ignore
                const file = event.currentTarget.files[0];
                dicomHelpers.setValue(file);
                handleProcessZipFile(file);
              }}
              disabled={Boolean(props.loading) || Boolean(props?.disabled)}
            />
          ) : null}
          {!asset && !processingDicom && !!dicomInput.value ? (
            <FileSelectedView
              hideRemove={false}
              label={props.label}
              name={dicomInput?.value?.name}
              size={dicomInput?.value?.size}
              disabled={props?.disabled}
              onRemove={() => {
                dicomHelpers.setValue(null);
                dicomMetadataHelpers.setValue({});
              }}
              onDetails={() => {
                // @ts-ignore
                setAnchorEl(divRef?.current);
                setShowDetailsPopover(true);
              }}
            />
          ) : null}
        </Grid>
      </Grid>
      <Popover
        open={showDetailsPopover}
        onClose={() => setShowDetailsPopover(false)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box width={350} p={2}>
          <DicomMetadataView dicomMetadata={asset ? asset.metadata : dicomMetadataInput.value} />
        </Box>
      </Popover>
    </>
  );
}
