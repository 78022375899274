import {
  CaseCancellationListType,
  CaseSpineType,
  CaseStageType,
  CaseType,
  PartType,
} from '@workflow-nx/common';
import { Globals } from '../../../layouts/DashboardLayout';

export type ListCasesActionType = {
  type: string;
  data?: any;
};

export type ListCasesStateType = {
  assignedTo?: string;
  fieldRep?: string;
  organization?: string;
  surgeon?: string;
  caseType?: CaseType[];
  caseSpineType?: CaseSpineType[];
  caseCancellationType?: CaseCancellationListType;
  stage?: CaseStageType[];
  partTypes?: PartType[];
  caseTags?: string[];
  planVersion?: number;
  showOnlyOnHoldCases?: boolean;
  showOnlyDemoCases?: boolean;
  orderBy?:
    | { [key: string]: { [key: string]: 'asc' | 'desc' } }
    | { [key: string]: 'asc' | 'desc' };
  search?: string;
  pageSize?: number;
  pageNumber?: number;
};

export const ListCasesReducer = (
  state: ListCasesStateType,
  action: ListCasesActionType,
): ListCasesStateType => {
  let updatedState = JSON.parse(JSON.stringify(state));

  switch (action.type) {
    case 'RESET_FILTERS':
      updatedState = {
        assignedTo: '',
        organization: '',
        surgeon: '',
        fieldRep: '',
        search: '',
        caseType: [CaseType.Live],
        caseSpineType: '',
        caseCancellationType: CaseCancellationListType.None,
        stage: [
          CaseStageType.Open,
          CaseStageType.Segmentation,
          CaseStageType.Planning,
          CaseStageType.Proposed,
          CaseStageType.Design,
          CaseStageType.QaReview,
          CaseStageType.Production,
          CaseStageType.Ready,
        ],
        orderBy: { surgeryDate: 'asc' },
        pageSize: Globals.DefaultListPageSize,
        caseTags: [],
        partTypes: [],
        planVersion: undefined,
        showOnlyOnHoldCases: false,
      };
      localStorage.removeItem('ListCases.cache');
      break;
    case 'SEARCH_CHANGED':
      updatedState.search = action.data;
      break;
    case 'ORDER_BY_CHANGED':
      updatedState.orderBy = action.data;
      break;
    case 'ASSIGNED_TO_CHANGED':
      updatedState.assignedTo = action.data;
      break;
    case 'CASE_TYPE_CHANGED':
      updatedState.caseType = action.data;
      break;
    case 'CASE_CANCELLATION_TYPE_CHANGED':
      updatedState.caseCancellationType = action.data;
      break;
    case 'CASE_STAGE_CHANGED':
      updatedState.stage = action.data;
      break;
    case 'CASE_PART_TYPES_CHANGED':
      updatedState.partTypes = action.data;
      break;
    case 'CASE_SPINE_TYPE_CHANGED':
      updatedState.caseSpineType = !action.data ? [] : [action.data];
      break;
    case 'PLAN_VERSION_CHANGED':
      updatedState.planVersion = action.data ?? undefined;
      break;
    case 'CASE_TAGS_CHANGED':
      updatedState.caseTags = action.data;
      break;
    case 'FIELD_REP_CHANGED':
      updatedState.fieldRep = action.data;
      break;
    case 'SURGEON_CHANGED':
      updatedState.surgeon = action.data;
      break;
    case 'ORGANIZATION_CHANGED':
      updatedState.organization = action.data;
      break;
    case 'UPDATE_PAGINATION':
      updatedState.pageSize = action.data.pageSize;
      updatedState.pageNumber = action.data.pageNumber;
      break;
    case 'CASE_HOLD_CHANGED':
      updatedState.showOnlyOnHoldCases = action.data;
      break;
    case 'DEMO_CASES_ONLY_CHANGED':
      updatedState.showOnlyDemoCases = action.data;
      break;
  }

  // Reset page number if a query parameter has changed
  if (action.type !== 'UPDATE_PAGINATION') updatedState.pageNumber = 0;

  return updatedState;
};
