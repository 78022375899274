import { faTimesCircle, faUser, faUsers } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  FormControl,
  FormLabel,
  Autocomplete as MuiAutocomplete,
  Chip as MuiChip,
  TextField as MuiTextField,
  Typography,
} from '@mui/material';
import { Field, FieldProps, useField } from 'formik';
import { ChangeEvent } from 'react';

export const UserRoleAutocomplete = ({
  name,
  label,
  disabled,
  required,
  options,
}: {
  disabled?: boolean;
  required?: boolean;
  label?: string;
  name: string;
  onChange?: (e: ChangeEvent) => void;
  options: { key: string; value: string }[];
}) => {
  const [, meta] = useField(name);

  return (
    <FormControl component="fieldset" fullWidth={true}>
      <FormLabel component="legend">{label}</FormLabel>
      <Box mt={1}>
        <Field name={name}>
          {(fieldProps: FieldProps) => {
            return (
              <MuiAutocomplete
                clearOnBlur={true}
                clearOnEscape={true}
                disabled={disabled}
                multiple
                options={options}
                value={(fieldProps.field.value ?? []).map(
                  (fieldValue: { key: string; type: 'USER' | 'ROLE' }) => ({
                    ...fieldValue,
                    value:
                      options.find((option) => option.key === fieldValue.key)?.value ?? 'Unknown',
                  }),
                )}
                onChange={(e, value) => {
                  fieldProps.form.setFieldValue(name, value);
                }}
                onBlur={() => {
                  fieldProps.form.setFieldTouched(name, true);
                }}
                groupBy={(option) => {
                  return option.type;
                }}
                isOptionEqualToValue={(option, value) => option.key === value.key}
                getOptionLabel={(option) => option.value}
                renderTags={(value) =>
                  value.map((option) => {
                    return (
                      <Box mr={0.5} mb={0.5} key={option.key}>
                        <MuiChip
                          variant={'outlined'}
                          deleteIcon={<FontAwesomeIcon size={'lg'} icon={faTimesCircle} />}
                          label={
                            <Box display={'flex'} alignItems={'center'}>
                              <FontAwesomeIcon
                                size={'lg'}
                                icon={option.type === 'USER' ? faUser : faUsers}
                              />
                              <Box ml={0.5} />
                              <Typography>{option.value}</Typography>
                            </Box>
                          }
                        />
                      </Box>
                    );
                  })
                }
                renderInput={(params) => (
                  <MuiTextField
                    {...params}
                    error={Boolean(meta.touched && meta.error)}
                    helperText={meta.touched && meta.error ? meta.error : ''}
                    tabIndex={1}
                    required={required}
                    inputProps={{
                      ...params.inputProps,
                    }}
                    onKeyDown={(e) => {
                      // stop enter from submitting a form
                      if (e.key === 'Enter') {
                        e.preventDefault();
                      }
                    }}
                  />
                )}
              />
            );
          }}
        </Field>
      </Box>
    </FormControl>
  );
};
