import { CaseApproachType } from '@workflow-nx/common';
import { date } from '@workflow-nx/utils';
import { GapScoreConfig } from '../shared/enum';

export const calculateTargetLordosis = (
  goalType: CaseApproachType,
  pelvicIncidence: number,
  birthDate?: string,
) => {
  let totalLordosis = 0;

  switch (goalType) {
    case CaseApproachType.AgeAdjusted:
      if (birthDate) {
        const age = date.distanceInYears(birthDate as string);
        totalLordosis = pelvicIncidence - 3 - (age - 55) / 2;
      }
      break;
    case CaseApproachType.ZeroMismatch:
      totalLordosis = pelvicIncidence;
      break;
    case CaseApproachType.GapScore:
      totalLordosis =
        pelvicIncidence * GapScoreConfig.GAP_SCORE_CONSTANT + GapScoreConfig.GAP_SCORE_INTERCEPT;
      break;
    case CaseApproachType.None:
    default:
      break;
  }

  return (totalLordosis * Math.PI) / 180;
};
