import { useMutation, useQuery } from '@apollo/client';
import { Alert, Box, Button, Card, CardContent, Divider, Typography } from '@mui/material';
import { orange } from '@mui/material/colors';
import {
  AssetType,
  CarrierType,
  CaseShippingStatusType,
  CaseStageType,
  EventType,
  ICase,
  ICaseShipping,
  IEvent,
  ORDERED_MANUAL_VENDOR_CATEGORIES,
  Permission,
  PlanStatusType,
  UserRoleType,
  ValidFileExtensions,
  VendorTaskCategory,
  caseUtils,
  format,
  getAssetEventType,
  isTrackableEvent,
} from '@workflow-nx/common';
import { AssetGridTable } from 'apps/workflow-client/src/app/components/AssetGridTable/AssetGridTable';
import { FormattedFieldView } from 'apps/workflow-client/src/app/components/FormattedFieldView';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import { Dispatch, useCallback, useReducer, useState } from 'react';
import ActionButton from '../../../../components/ActionButton';
import { ImplantSizeExclusionAlert } from '../../../../components/ImplantSizeExclusionAlert';
import {
  COMPLETE_CASE_STAGE,
  DELETE_EVENT,
  FIND_CASE_PRODUCTION_TAB_DATA,
  REVIEW_DHR,
} from '../../../../gql';
import useAuth from '../../../../hooks/useAuth';
import { ProductionRejectionDialog } from '../CaseCompleteTab/ProductionRejectionDialog';
import { CaseExpiryDateAlert } from '../CaseExpiryDateAlert';
import { CaseViewActionType } from '../CaseView';
import { CreateEventAssetDialog } from '../CreateEventAssetDialog';
import { CreateEventDialog } from '../CreateEventDialog';
import { EventGridTable } from '../EventGridTable';
import { ExportForm19KitBomButton } from '../ExportForm19KitBomButton';
import { ExportShippingLabelButton } from '../ExportShippingLabelButton';
import { ShipmentTrackingGridTable } from '../ShipmentTrackingGridTable';
import { EditVendorAssignmentsDialog } from './EditVendorAssignmentsDialog';
import ReviewDHRDialog from './ReviewDHRDialog';

export type CaseProductionTabActionType = {
  type:
    | 'ASSET_REPLACED'
    | 'ASSET_UPLOADED'
    | 'ASSET_DELETED'
    | 'REFETCH_EVENTS'
    | 'EVENT_ADDED'
    | 'INIT';
  data?: any;
};

type CaseProductionTabStateType = {
  events?: IEvent[];
  caseShipping?: ICaseShipping;
  isEditingAllowed: boolean;
  isStageComplete: boolean;
  canMoveToReady: boolean;
};

export function CaseProductionTabView(props: {
  activeCase: ICase;
  dispatch: Dispatch<CaseViewActionType>;
}) {
  const auth = useAuth();
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();

  const [completeCaseStage, { loading: loadingCompleteCase }] = useMutation(COMPLETE_CASE_STAGE);
  const [reviewDHR, { loading: loadingSubmitReview }] = useMutation(REVIEW_DHR);
  const [openEventDialog, setOpenEventDialog] = useState(false);
  const [openProdRejectionDialog, setOpenProdRejectionDialog] = useState(false);
  const [showDHRReviewDialog, setShowDHRReviewDialog] = useState(false);
  const [showEditVendorDialog, setShowEditVendorDialog] = useState(false);

  const [createEventAsset, setCreateEventAssetData] = useState<{
    assetId: number;
    eventType: EventType;
  } | null>(null);

  const findFedExTrackingEvent = (event: IEvent) => {
    return !!(
      isTrackableEvent(event.eventType) &&
      (event.carrierType === CarrierType.FedEx || event.carrierType === CarrierType.FedExSameDay) &&
      event.trackingNumber
    );
  };

  const { loading: loadingEvents, refetch: refetchEvents } = useQuery(
    FIND_CASE_PRODUCTION_TAB_DATA,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      variables: {
        caseId: props.activeCase.caseId,
      },
      onCompleted: (data) => {
        productionTabDispatch({ type: 'INIT', data: data });

        const events: IEvent[] = data?.events.events;
        const trackingEvent = events.some(findFedExTrackingEvent);
        setHasFedExTrackingNumber(trackingEvent);
      },
    },
  );

  const [deleteEvent, { loading: deletingEvent }] = useMutation(DELETE_EVENT);

  const initialState: CaseProductionTabStateType = {
    canMoveToReady: false,
    isEditingAllowed: false,
    isStageComplete: false,
    events: undefined,
    caseShipping: undefined,
  };

  const handleCompleteCaseStage = useCallback(async () => {
    try {
      await completeCaseStage({
        variables: {
          caseId: props.activeCase.caseId,
        },
      });

      props.dispatch({ type: 'refetch' });

      enqueueSnackbar('Case updated successfully', {
        variant: 'success',
      });
    } catch (e) {
      console.error(e);
      enqueueSnackbar('Error updating case', {
        variant: 'error',
      });
    }
  }, [completeCaseStage, props, enqueueSnackbar]);

  const handleDeliveryCompleteEventCreation = async () => {
    try {
      await confirm({
        title: 'Confirm Case Stage Change',
        description: `Would you like move this case to the ${format.formatCaseStage(
          CaseStageType.Ready,
        )} stage?`,
      });

      if (state.isEditingAllowed && !props?.activeCase?.caseCancellation) {
        handleCompleteCaseStage();
      } else {
        enqueueSnackbar(
          `An error occurred moving this case to the ${format.formatCaseStage(
            CaseStageType.Ready,
          )} stage`,
          {
            variant: 'error',
          },
        );
      }
    } catch (e) {
      enqueueSnackbar(
        `Case was not moved to ${format.formatCaseStage(CaseStageType.Ready)} stage`,
        {
          variant: 'info',
        },
      );
    }
  };

  const handleSubmitDHRReview = async (isApproved: boolean, note: string) => {
    try {
      setShowDHRReviewDialog(false);

      await reviewDHR({
        variables: {
          caseId: props.activeCase.caseId,
          isApproved,
          reason: note,
        },
      });

      enqueueSnackbar(`DHR review for Case ${props.activeCase.number} submitted successfully`, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(`Error submitting DHR review for Case ${props.activeCase.number}`, {
        variant: 'error',
      });

      console.error('Error submitting DHR review', error);
    } finally {
      refetchEvents();
    }
  };

  const productionReducer = (
    state: CaseProductionTabStateType,
    action: CaseProductionTabActionType,
  ): CaseProductionTabStateType => {
    let updatedState = state;

    switch (action.type) {
      case 'ASSET_REPLACED': {
        const eventType = getAssetEventType(action.data.assetType);

        const matchingEvent = state.events?.find((e) => e.eventType === eventType);

        if (!matchingEvent) break;

        deleteEvent({
          variables: {
            eventId: matchingEvent?.eventId,
          },
        }).then(() => {
          if (!eventType) return;

          setCreateEventAssetData({
            assetId: action.data.assetId,
            eventType,
          });
        });

        break;
      }
      case 'ASSET_DELETED': {
        const eventType = getAssetEventType(action.data.assetType);

        const matchingEvent = state.events?.find((e) => e.eventType === eventType);

        if (!matchingEvent) break;

        deleteEvent({
          variables: {
            eventId: matchingEvent?.eventId,
          },
        }).then(() => {
          refetchEvents();
        });

        break;
      }
      case 'ASSET_UPLOADED': {
        const eventType = getAssetEventType(action.data.assetType);

        if (!eventType) break;

        setCreateEventAssetData({
          assetId: action.data.assetId,
          eventType,
        });

        break;
      }

      case 'REFETCH_EVENTS': {
        refetchEvents();
        break;
      }
      case 'EVENT_ADDED': {
        const updatedEvents = JSON.parse(JSON.stringify(state.events));
        const createdEvent = action.data;

        updatedEvents.push(createdEvent);
        updatedState = {
          canMoveToReady: caseUtils.isCaseStageProductionComplete(updatedEvents),
          isEditingAllowed: state.isEditingAllowed,
          isStageComplete: state.isStageComplete,
          events: updatedEvents,
        };

        break;
      }
      case 'INIT': {
        const events = action?.data?.events?.events ?? [];
        const trackingNumber = events.filter((e: IEvent) => e.trackingNumber)[0]?.trackingNumber;
        const caseShipping =
          action?.data?.caseShipping?.update?.trackingNumber === trackingNumber
            ? action.data.caseShipping.update
            : undefined;

        const stageComplete =
          props.activeCase.stage !== CaseStageType.Open &&
          props.activeCase.stage !== CaseStageType.Segmentation &&
          props.activeCase.stage !== CaseStageType.Planning &&
          props.activeCase.stage !== CaseStageType.Proposed &&
          props.activeCase.stage !== CaseStageType.Design &&
          props.activeCase.stage !== CaseStageType.Production;
        const editingAllowed = !!auth.hasPermission?.([Permission.ManageCase]);

        updatedState = {
          canMoveToReady: caseUtils.isCaseStageProductionComplete(events),
          isEditingAllowed: editingAllowed,
          isStageComplete: stageComplete || !editingAllowed,
          events,
          caseShipping,
        };
        break;
      }
    }
    return updatedState;
  };
  const [state, productionTabDispatch] = useReducer(productionReducer, initialState);
  const [hasFedExTrackingNumber, setHasFedExTrackingNumber] = useState(false);

  const canMoveToReady = !!auth?.hasPermission?.([Permission.ManageCase]);
  const canRejectProduction =
    auth?.hasPermission?.([Permission.ManageCase]) ||
    auth?.hasRole?.([UserRoleType.QualityEngineer]);
  const canExportForm17 = !!auth?.hasPermission?.([Permission.QaReviewCase]);
  const canReviewDHR =
    !!auth?.hasPermission?.([Permission.QaReviewCase]) ||
    !!auth?.hasRole?.([UserRoleType.SiteAdministrator]);
  const canUpdateVendorAssignments =
    !!auth?.hasRole?.([UserRoleType.SiteAdministrator]) ||
    !!auth?.hasRole?.([UserRoleType.Operations]) ||
    !!auth?.hasRole?.([UserRoleType.SupplyChain]);

  const isStageProduction = props.activeCase.stage === CaseStageType.Production;
  const mostRecentEvent = state.events
    ?.slice()
    .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())?.[0];
  const shouldShowDHRReviewAction =
    canReviewDHR &&
    (mostRecentEvent?.eventType === EventType.DHRReviewRequested ||
      mostRecentEvent?.eventType === EventType.DHRReviewRequestRejected);

  const localTimeZone = DateTime.local().toFormat('ZZZZ');

  const plan = (props.activeCase?.plans ?? []).find(
    (plan) => plan.status === PlanStatusType.Approved,
  );

  const getVendor = (taskCategory: VendorTaskCategory) =>
    props.activeCase?.vendorAssignments?.find((v) => v.taskCategory === taskCategory);

  return (
    <>
      <Card>
        <CardContent>
          {!state.isStageComplete ? (
            <CaseExpiryDateAlert expiryDate={props.activeCase?.studyDate?.expiryDate} />
          ) : null}
          <ImplantSizeExclusionAlert
            activeCase={props.activeCase}
            currentStage={CaseStageType.Production}
            planId={plan?.planId}
          />
          {shouldShowDHRReviewAction ? (
            <Box
              display={'flex'}
              bgcolor={orange['50']}
              borderRadius="5px"
              alignItems={'center'}
              mb={2}
              p={2}
            >
              <Typography variant={'body1'}>{'The DHR is ready for review.'}</Typography>
              <Box flexGrow={1} />
              <ActionButton
                color={'secondary'}
                onClick={() => setShowDHRReviewDialog(true)}
                variant={'outlined'}
                disabled={loadingSubmitReview || showDHRReviewDialog}
              >
                <Typography color={'secondary'} variant={'button'} noWrap={true}>
                  Review DHR
                </Typography>
              </ActionButton>
            </Box>
          ) : null}

          <Box my={2}>
            <Box display="flex" justifyContent={'space-between'} mt={2}>
              <Typography variant={'h4'}>
                <strong>Vendor Assignments</strong>
              </Typography>

              {canUpdateVendorAssignments ? (
                <Button
                  onClick={() => {
                    setShowEditVendorDialog(true);
                  }}
                  variant={'contained'}
                >
                  Edit
                </Button>
              ) : null}
            </Box>

            <Box mb={4}>
              <Box display="flex">
                {ORDERED_MANUAL_VENDOR_CATEGORIES.map((category) => {
                  const vendor = getVendor(category);
                  return (
                    <>
                      <FormattedFieldView
                        label={`${format.formatVendorTaskCategory(category)} Vendor`}
                        value={
                          vendor?.organization?.name ? (
                            <Typography variant={'body1'} noWrap={true}>
                              {vendor?.organization?.name}
                            </Typography>
                          ) : (
                            <span>&mdash;</span>
                          )
                        }
                      />
                    </>
                  );
                })}
              </Box>
            </Box>
          </Box>
          {canExportForm17 ? (
            <Box my={2}>
              <Box my={2}>
                <Typography variant={'h4'}>
                  <strong>Vendor Documents</strong>
                </Typography>
              </Box>

              <Box display={'flex'} alignItems={'center'} ml={1} gap={2}>
                <ExportShippingLabelButton
                  activeCase={props.activeCase}
                  loading={loadingCompleteCase}
                />
                <ExportForm19KitBomButton
                  activeCase={props.activeCase}
                  loading={loadingCompleteCase}
                />
              </Box>
            </Box>
          ) : null}
          <Box mt={4} mb={2}>
            <Typography variant={'h4'}>
              <strong>Assets</strong>
            </Typography>
          </Box>
          <Box my={2}>
            <AssetGridTable
              dispatch={productionTabDispatch}
              allowReplace
              assets={_.compact(state.events?.map((e) => e.asset)) || []}
              caseId={props.activeCase.caseId}
              validFileExtensions={[ValidFileExtensions.PDF]}
              validAssets={[
                AssetType.PurchaseOrder,
                AssetType.PurchaseOrderPackaging,
                AssetType.MasterBuildRecord,
              ]}
              readOnly={state.isStageComplete || !state.isEditingAllowed || deletingEvent}
            />
            <CreateEventAssetDialog
              activeCase={props.activeCase}
              onClose={() => {
                setCreateEventAssetData(null);

                productionTabDispatch({
                  type: 'REFETCH_EVENTS',
                });
              }}
              assetId={createEventAsset?.assetId}
              eventType={createEventAsset?.eventType}
            />
          </Box>

          <Box mt={4} mb={2}>
            <Typography variant={'h4'}>
              <strong>Production Events</strong>
            </Typography>
          </Box>
          {state && state.events && state?.events?.length > 0 ? (
            <EventGridTable
              readOnly={state.isStageComplete || deletingEvent}
              events={state.events}
              activeCase={props.activeCase}
              dispatch={productionTabDispatch}
            />
          ) : (
            <Alert severity={'info'}>There are no events in production</Alert>
          )}

          <Box display={'flex'} my={1} justifyContent={'center'}>
            <ActionButton
              disabled={
                state.isStageComplete ||
                state.canMoveToReady ||
                !!props?.activeCase?.caseCancellation ||
                deletingEvent
              }
              loading={loadingEvents}
              onClick={() => {
                setOpenEventDialog(true);
              }}
              variant={'outlined'}
            >
              Create New Event
            </ActionButton>
          </Box>
          <Box my={2}>
            <Divider />
          </Box>
          <Box mt={4} mb={2}>
            <Typography variant={'h4'}>
              <strong>Shipment Tracking Events</strong>
            </Typography>
          </Box>
          {hasFedExTrackingNumber &&
          state.caseShipping &&
          state?.caseShipping?.result.tracking_details?.length > 0 ? (
            <>
              <Box mb={2}>
                <Alert severity={'info'}>
                  <Box>
                    <Typography variant={'button'} color={'textSecondary'}>
                      <strong>Current Status:</strong>
                      {` ${
                        state.caseShipping.result.tracking_details[
                          state.caseShipping.result.tracking_details.length - 1
                        ].message
                      } `}
                      &mdash; {` `}
                      <strong>Est Delivery Date:</strong>
                      {` `}
                      {state.caseShipping.status === CaseShippingStatusType.Delivered &&
                      state.caseShipping?.result?.carrier_detail?.initial_delivery_attempt
                        ? `Delivered at: 
                        ${format.formatDateTime(
                          state.caseShipping.result.carrier_detail.initial_delivery_attempt,
                        )}
                        ${' '}
                        (${localTimeZone})  
                        `
                        : state.caseShipping?.result?.est_delivery_date
                        ? format.formatDateTime(state.caseShipping.result.est_delivery_date)
                        : 'TBD'}
                    </Typography>
                  </Box>
                </Alert>
              </Box>
              <ShipmentTrackingGridTable
                readOnly={state.isStageComplete}
                trackingUpdate={state.caseShipping}
                activeCase={props.activeCase}
                dispatch={productionTabDispatch}
              />
            </>
          ) : (
            <Alert severity={'info'}>No shipment information available</Alert>
          )}

          <Box my={2}>
            <Divider />
          </Box>
          {props.activeCase.stage !== CaseStageType.Ready &&
          (canMoveToReady || canRejectProduction) ? (
            <Box my={2}>
              <Box display={'flex'} my={2} justifyContent={'center'}>
                <Button
                  variant={'outlined'}
                  disabled={
                    !isStageProduction ||
                    !!props?.activeCase?.caseCancellation ||
                    !canRejectProduction
                  }
                  onClick={() => setOpenProdRejectionDialog(true)}
                >
                  Reject Production
                </Button>
                <Box mx={1} />
                <ActionButton
                  variant={'outlined'}
                  disabled={
                    !state.canMoveToReady ||
                    !state.isEditingAllowed ||
                    !!props?.activeCase?.caseCancellation ||
                    !canMoveToReady ||
                    caseUtils.isCaseExpiryDateApproachingOrAlreadyExpired(
                      props?.activeCase?.studyDate?.expiryDate,
                    )
                  }
                  onClick={handleCompleteCaseStage}
                  loading={loadingCompleteCase}
                >
                  Move To Ready
                </ActionButton>
              </Box>
            </Box>
          ) : null}
        </CardContent>
      </Card>
      <CreateEventDialog
        open={openEventDialog}
        events={state.events}
        activeCase={props.activeCase}
        onClose={(shouldUpdate, eventType) => {
          if (shouldUpdate) {
            refetchEvents();
          }
          setOpenEventDialog(false);
          if (eventType === EventType.HospitalDeliveryComplete) {
            handleDeliveryCompleteEventCreation();
          }
        }}
      />
      <ProductionRejectionDialog
        open={openProdRejectionDialog}
        activeCase={props.activeCase}
        onClose={(shouldUpdate) => {
          if (shouldUpdate) {
            props.dispatch({ type: 'refetch' });
          }
          setOpenProdRejectionDialog(false);
        }}
      />
      <ReviewDHRDialog
        caseData={props.activeCase}
        visible={showDHRReviewDialog}
        loading={loadingSubmitReview}
        onClose={() => {
          setShowDHRReviewDialog(false);
        }}
        onSave={handleSubmitDHRReview}
      />
      <EditVendorAssignmentsDialog
        activeCase={props.activeCase}
        onClose={(shouldUpdate) => {
          if (shouldUpdate) {
            props.dispatch({ type: 'refetch' });
          }

          setShowEditVendorDialog(false);
        }}
        open={showEditVendorDialog}
      />
    </>
  );
}
