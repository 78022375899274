import {
  format,
  IUser,
  Permission,
  LevelType,
  ImplantType,
  ImplantOrientation,
  ILevels,
} from '@workflow-nx/common';
import { useSnackbar } from 'notistack';
import React, { useEffect, useReducer } from 'react';
import { useMutation } from '@apollo/client';
import { UPSERT_SURGEON_PREFERENCES } from '../../../../gql';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import { Box } from '@mui/material';
import CustomDialog from '../../../../components/CustomDialog';
import { useConfirm } from 'material-ui-confirm';
import useAuth from '../../../../hooks/useAuth';
import { date } from '@workflow-nx/utils';
import ActionButton from '../../../../components/ActionButton';
import * as Yup from 'yup';
import * as surgeonPreferencesUtils from '../../../../utils/surgeonPreferences';
import { SurgeonPreferencesForm } from './SurgeonPreferencesForm';
import {
  SurgeonPreferencesReducer,
  SurgeonPreferencesStateType,
  SurgeonPreferenceImplantType,
  SurgeonPreferenceImplantMeasurementType,
} from './EditSurgeonPreferences.reducer';

export function EditSurgeonPreferencesDialog({
  surgeonUser,
  onClose,
  open,
}: {
  surgeonUser: IUser;
  onClose: (shouldUpdate: boolean) => void;
  open: boolean;
}) {
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const [upsertSurgeonPreferences] = useMutation(UPSERT_SURGEON_PREFERENCES);
  const product = auth?.product;

  // NOTE: Removing ACDF, ACDF/X and TLIF/CA until they have preferences
  const excludeImplantTypes = [
    ImplantType.None,
    ImplantType.ACDF,
    ImplantType.ACDFX,
    ImplantType.ACDFX_NO_CAM,
  ];

  const isTlifCArticulatingStarted =
    !!product?.tlifCArticulatingStartDate &&
    date.isBeforeToday(product.tlifCArticulatingStartDate as string);

  if (!isTlifCArticulatingStarted) {
    excludeImplantTypes.push(ImplantType.TLIFCA);
  }

  const implantTypes = Object.values(ImplantType).filter(
    (element) => !excludeImplantTypes.includes(element),
  );

  const permissions = auth?.user?.permissions;
  const canEditSurgeonPreferences = !!permissions?.includes(Permission.ManageSurgeonPreferences);

  const [state, dispatch] = useReducer(SurgeonPreferencesReducer(surgeonUser), {
    surgeonPreferenceId: 0,
    userId: surgeonUser.userId,
    preferredProcedures: implantTypes,
    sagittalGoalPrimary: undefined,
    sagittalGoalSecondary: undefined,
    sagittalGoalOther: undefined,
    coronalGoalPrimary: undefined,
    coronalGoalSecondary: undefined,
    navigationSystems: [],
    navigationSystemOther: undefined,
    targetHeightMetric: undefined,
    excludedInstruments: [],
    reusableInstruments: false,
    note: undefined,
    surgeonPreferenceImplants: [],
    surgeonPreferenceImplantMeasurements: [],
  });

  const surgeonPreferenceImplantSchema = Yup.object().shape({
    positioning: Yup.string(),
    direction: Yup.string().nullable(),
    orientation: Yup.string().nullable(),
    screwLength: Yup.string().nullable(),
    maximizeFootprint: Yup.boolean().nullable(),
    plusSizeImplant: Yup.number(),
    minimalHeightSpecification: Yup.boolean(),
    excludedImplantSizes: Yup.mixed(),
    note: Yup.string().nullable(),
  });
  const surgeonPreferenceImplantMeasurementSchema = Yup.object().shape({
    min: Yup.number().required('Minimum is required'),
    max: Yup.number().required('Maximum is required'),
  });

  const surgeonPreferencesDialogSchema = Yup.object().shape({
    preferredProcedures: Yup.array().of(Yup.string()),
    sagittalGoalPrimary: Yup.string().required('Sagittal Goal Primary is required'),
    sagittalGoalSecondary: Yup.string().nullable(),
    sagittalGoalOther: Yup.string().nullable(),
    coronalGoalPrimary: Yup.string().nullable(),
    coronalGoalSecondary: Yup.string().nullable(),
    navigationSystems: Yup.array().of(Yup.string()),
    navigationSystemOther: Yup.string().nullable(),
    targetHeightMetric: Yup.string().nullable(),
    excludedInstruments: Yup.array().of(Yup.string()),
    reusableInstruments: Yup.boolean().nullable(),
    note: Yup.string().nullable(),
    surgeonPreferenceImplants: Yup.array().of(surgeonPreferenceImplantSchema),
    surgeonPreferenceImplantMeasurements: Yup.array().of(surgeonPreferenceImplantMeasurementSchema),
  });

  const handleSubmitForm = async (
    values: FormikValues,
    { setStatus, setSubmitting }: FormikHelpers<SurgeonPreferencesStateType>,
  ) => {
    try {
      await confirm({
        title: 'Save Surgeon Preferences?',
        description: 'Are you sure you want to save surgeon Preferences?',
      });

      const surgeonPreferenceImplants = values.surgeonPreferenceImplants
        .filter((element: SurgeonPreferenceImplantType) =>
          values.preferredProcedures.includes(element.implantType),
        )
        .map((element: SurgeonPreferenceImplantType) => {
          let orientationByLevels: ILevels | undefined = undefined;
          const excludedImplantSizes = Array.isArray(element.excludedImplantSizes)
            ? element.excludedImplantSizes.filter((size) => size !== '')
            : typeof element.excludedImplantSizes === 'string' &&
              element.excludedImplantSizes !== ''
            ? [element.excludedImplantSizes]
            : [];

          if (
            element.implantType === ImplantType.ALIFX &&
            element.orientation === ImplantOrientation.LevelSpecific
          ) {
            orientationByLevels = {
              [LevelType.L3L4]: element.orientationLevelL3L4,
              [LevelType.L4L5]: element.orientationLevelL4L5,
              [LevelType.L5S1]: element.orientationLevelL5S1,
            } as ILevels;
          }
          return {
            implantType: element.implantType,
            positioning: element.positioning,
            direction: element.direction,
            orientation: element.orientation,
            orientationByLevels: orientationByLevels,
            screwLength: element.screwLength,
            maximizeFootprint: element.maximizeFootprint,
            plusSizeImplant: element.plusSizeImplant,
            minimalHeightSpecification: element.minimalHeightSpecification,
            excludedImplantSizes: excludedImplantSizes,
            note: element.note,
          };
        });

      const surgeonPreferenceImplantMeasurements = values.surgeonPreferenceImplantMeasurements
        .filter((element: SurgeonPreferenceImplantMeasurementType) =>
          values.preferredProcedures.includes(element.implantType),
        )
        .map((element: SurgeonPreferenceImplantMeasurementType) => {
          element.min = Number(element.min);
          element.max = Number(element.max);
          return element;
        });

      await upsertSurgeonPreferences({
        variables: {
          userId: values.userId,
          preferredProcedures: values.preferredProcedures,
          sagittalGoalPrimary: values.sagittalGoalPrimary,
          sagittalGoalSecondary: values.sagittalGoalSecondary,
          sagittalGoalOther: values.sagittalGoalOther,
          coronalGoalPrimary: values.coronalGoalPrimary || undefined,
          coronalGoalSecondary: values.coronalGoalSecondary || undefined,
          navigationSystems: values.navigationSystems,
          navigationSystemOther: values.navigationSystemOther,
          targetHeightMetric: values.targetHeightMetric,
          excludedInstruments: values.excludedInstruments,
          reusableInstruments: values.reusableInstruments,
          note: values.note,
          surgeonPreferenceImplants: surgeonPreferenceImplants,
          surgeonPreferenceImplantMeasurements: surgeonPreferenceImplantMeasurements,
        },
      });
      setStatus({ success: true });
      enqueueSnackbar('Surgeon Preferences updated', {
        variant: 'success',
      });
      onClose(true);
    } catch (err) {
      console.error(err);
      setStatus({ success: false });
      enqueueSnackbar('An error occurred updating the Surgeon Preferences', {
        variant: 'error',
      });
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (open) {
      dispatch({ type: 'INIT', data: surgeonUser.preferences });
    }
  }, [open, dispatch, surgeonUser]);

  return open ? (
    <Box m={1}>
      <Formik
        initialValues={state}
        validationSchema={surgeonPreferencesDialogSchema}
        onSubmit={handleSubmitForm}
        enableReinitialize={true}
      >
        {({ values, isSubmitting, submitForm }) => {
          const surgeonPreferencesErrors = surgeonPreferencesUtils.getSurgeonPreferencesErrors(
            values,
            surgeonPreferencesUtils.SURGEON_PREFERENCES_MIN_MAX_SETTINGS,
            isTlifCArticulatingStarted,
          );

          return (
            <CustomDialog
              maxWidth={'lg'}
              open={open}
              title={`Edit Surgeon Preferences - ${format.formatName(surgeonUser)} - ${
                surgeonUser?.organization?.name
              }`}
              onClose={() => {
                onClose(true);
              }}
              positiveActionButtons={
                canEditSurgeonPreferences
                  ? [
                      <ActionButton
                        disabled={surgeonPreferencesErrors.length > 0 || !canEditSurgeonPreferences}
                        loading={isSubmitting}
                        onClick={() => submitForm()}
                        variant={'outlined'}
                      >
                        Save
                      </ActionButton>,
                    ]
                  : []
              }
            >
              {open ? (
                <SurgeonPreferencesForm
                  surgeonUser={surgeonUser}
                  disabled={isSubmitting}
                  errors={surgeonPreferencesErrors}
                  loading={isSubmitting}
                  isTlifCArticulatingStarted={isTlifCArticulatingStarted}
                />
              ) : null}
            </CustomDialog>
          );
        }}
      </Formik>
    </Box>
  ) : null;
}
