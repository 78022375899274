import { Box, LinearProgress, Theme, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import React from 'react';

const BorderLinearProgress = withStyles((theme: Theme) => ({
  root: {
    height: 5,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[200],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.info.main,
  },
}))(LinearProgress);

export function LabelledProgressBar({
  label,
  value,
  variant = 'indeterminate',
  fullWidth,
}: {
  label?: string;
  variant?: 'determinate' | 'indeterminate';
  value?: number;
  fullWidth?: boolean;
}) {
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      width={fullWidth ? '100%' : undefined}
    >
      {!!label ? (
        <Box mb={0.5}>
          <Typography variant={'body2'}>{label}</Typography>
        </Box>
      ) : null}
      <BorderLinearProgress
        variant={variant}
        value={value}
        style={fullWidth ? { width: '100%' } : undefined}
      />
    </Box>
  );
}
