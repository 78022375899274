import React, { ChangeEvent } from 'react';
import { FormControlLabel, Checkbox as MuiCheckbox } from '@mui/material';
import { useController, UseControllerProps } from 'react-hook-form';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  error: {
    color: 'red',
  },
}));

interface CheckboxProps {
  name: string;
  label: React.ReactNode;
  disabled: boolean;
  control: UseControllerProps['control'];
  onChange?: any;
}

export function CheckboxRHF({ name, label, disabled, control, onChange }: CheckboxProps) {
  const styles = useStyles();
  const {
    field: { onChange: hookFormOnChange, onBlur, value, ref },
    fieldState: { invalid },
  } = useController({
    name,
    control,
  } as UseControllerProps);

  const change =
    (onChange: (event: ChangeEvent<HTMLInputElement>) => void) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      event.persist();
      onChange(event);
    };

  return (
    <FormControlLabel
      disabled={disabled}
      label={label}
      control={
        <MuiCheckbox
          className={invalid ? styles.error : ''}
          disabled={disabled}
          id={name}
          name={name}
          onBlur={onBlur}
          onChange={(e) => {
            if (onChange) {
              onChange(e);
            }
            hookFormOnChange(e);
          }}
          checked={value || false}
          inputRef={ref}
          value={value || false}
          size={'small'}
        />
      }
    />
  );
}
