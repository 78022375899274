import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import React from 'react';

export function ProgressDialog(props: { open: boolean; progressState: string; title?: string }) {
  return (
    <Dialog open={props.open}>
      {props.title ? <DialogTitle>{props.title}</DialogTitle> : null}
      <DialogContent>
        <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
          <CircularProgress variant={'indeterminate'} />
          <Box mt={1} />
          <Typography variant={'body1'}>{props.progressState}</Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
