import * as pdfMake from 'pdfmake/build/pdfmake.js';
import {
  CaseRiskAssessmentType,
  format,
  IKitCarton,
  IKitItem,
  ImplantDirection,
  ImplantType,
  PartType,
  Result,
  IPlanExcludedImplantSizes,
  planUtils,
  LevelSize,
} from '@workflow-nx/common';

import * as vfsFonts from 'pdfmake/build/vfs_fonts.js';
import { sortBy } from 'lodash';
import { PDFDocument } from 'pdf-lib';
import { Buffer } from 'buffer';

function getInsertionSideText(implant: ImplantType, direction: ImplantDirection) {
  if (
    [ImplantType.LLIF, ImplantType.TLIFC, ImplantType.TLIFCA, ImplantType.TLIFO].includes(implant)
  ) {
    return direction === ImplantDirection.Left ? 'Patient Left' : 'Patient Right';
  }

  return 'N/A';
}

function getLevelNotesRowSpan(implantType: ImplantType, showTlifCOrientation: boolean) {
  let rowSpanValue = 5;
  if ([ImplantType.ALIF].includes(implantType)) {
    rowSpanValue = 3;
  } else if (
    [ImplantType.LLIF, ImplantType.TLIFO].includes(implantType) ||
    ([ImplantType.TLIFC, ImplantType.TLIFCA].includes(implantType) && !showTlifCOrientation)
  ) {
    rowSpanValue = 4;
  }

  return rowSpanValue;
}

export const form19 = async (form19Data: any): Promise<Blob> => {
  const colors = {
    black: 'black',
    blue: 'blue',
    green: 'green',
    orange: 'orange',
    red: 'red',
    white: 'white',
    yellow: 'yellow',
    lightGray: '#d9d9d9',
    lightGreen: '#e2efda',
    lightOrange: '#f8cbad',
    lightYellow: '#fff2cc',
    poloBlue: '#8ea9db',
  };

  const formName = form19Data?.header?.name ? form19Data?.header?.name : 'FORM-019';
  const documentDescription = `Document Description: ${
    form19Data?.header?.documentDescription
      ? form19Data?.header?.documentDescription
      : 'Patient-Specific Workflow Approvals2'
  }`;
  const referToSops = `Refer to ${form19Data?.header?.sop ?? 'SOP-033'}, ${
    form19Data?.header?.wi ?? 'WI-013'
  }`;
  const lotNumber = form19Data?.header?.lotNumber ? form19Data?.header?.lotNumber : '';
  const version = form19Data?.header?.version ? `Ver. ${form19Data?.header?.version}` : '';
  const engineeringApprovedBy = form19Data?.approvals?.engineeringApprovedBy
    ? form19Data?.approvals?.engineeringApprovedBy
    : '-';
  const engineeringApprovedAt = form19Data?.approvals?.engineeringApprovedAt
    ? form19Data?.approvals?.engineeringApprovedAt
    : '-';
  const peerReviewer = form19Data?.approvals?.peerReviewer
    ? form19Data?.approvals?.peerReviewer
    : '-';
  const qualityApprovedBy = form19Data?.approvals?.qualityApprovedBy
    ? form19Data?.approvals?.qualityApprovedBy
    : '-';
  const qualityApprovedAt = form19Data?.approvals?.qualityApprovedAt
    ? form19Data?.approvals?.qualityApprovedAt
    : '-';
  const peerReviewerPerRiskAssessment =
    form19Data?.step1?.caseRiskAssessment === CaseRiskAssessmentType.CaseReviewQaReview
      ? peerReviewer
      : 'N/A - per case risk assessment';
  const peerReviewerPerRiskAssessmentAt =
    form19Data?.step1?.caseRiskAssessment === CaseRiskAssessmentType.CaseReviewQaReview
      ? engineeringApprovedAt
      : 'N/A - per case risk assessment';
  const physicianApproval = form19Data?.step4?.dataUrl
    ? {
        image: form19Data?.step4?.dataUrl,
        fit: [400, 400],
      }
    : { text: `Physician approval not found.` };

  const form19RevisionsHistory = form19Data?.form19RevisionsHistory ?? [];
  const levelsFilesRevisionsHistory = form19Data?.levelsFilesRevisionsHistory ?? [];
  const caseKitCartons: IKitCarton[] = form19Data?.caseKitCartons ?? [];
  const excludedItems: IKitItem[] = form19Data?.excludedItems ?? [];
  const isImplantSizeExclusionEnabled = form19Data?.isImplantSizeExclusionEnabled;
  const validCaseExcludedImplants: IPlanExcludedImplantSizes[] =
    form19Data?.validCaseExcludedImplants ?? [];

  const excludedInstruments: string[] =
    form19Data?.excludedInstruments?.length > 0 ? sortBy(form19Data?.excludedInstruments) : [];
  let casePartsTotalQty = 0;
  const pageBreak = { text: '', pageBreak: 'before', style: 'subheader' };

  const levelsData = form19Data?.levelsData ?? [];
  const allLevelsData: any[] = [];
  for (const levelData of levelsData) {
    const { levelHeader, levelDataRows } = levelData;
    const excludedImplantSizeForLevel = planUtils.getExcludedImplantSizeForLevel(
      levelHeader.levelType,
      validCaseExcludedImplants,
    );
    const body = [
      [
        { text: 'Characteristic', style: 'tableHeader2' },
        { text: 'Minus 01', style: 'tableHeader2' },
        { text: 'Plan 02', style: 'tableHeader2' },
        { text: 'Plus 03', style: 'tableHeader2' },
        { text: 'Min Req.', style: 'tableHeader2' },
        { text: 'Max Req.', style: 'tableHeader2' },
        { text: 'Pass/Fail', style: 'tableHeader2' },
        { text: 'QA Review', style: 'tableHeader2' },
      ],
    ];
    for (const rowData of levelDataRows) {
      const row = [
        {
          text: format.formatImplantCharacteristicType(
            rowData?.characteristic,
            levelHeader.partType as PartType,
          ),
          style: 'tableRow',
          fillColor: colors.poloBlue,
        },
        {
          text: excludedImplantSizeForLevel !== LevelSize.Minus ? rowData?.minus01 : 'N/A',
          style: 'tableRow',
        },
        { text: rowData?.plan02, style: 'tableRow' },
        {
          text: excludedImplantSizeForLevel !== LevelSize.Plus ? rowData?.plus03 : 'N/A',
          style: 'tableRow',
        },
        { text: rowData?.min, style: 'tableRow', fillColor: colors.lightOrange },
        { text: rowData?.max, style: 'tableRow', fillColor: colors.lightOrange },
        {
          text: rowData?.result,
          style: 'tableRow',
          color: rowData?.result === Result.Pass ? colors.green : colors.red,
        },
        {
          text: `${qualityApprovedBy} ${qualityApprovedAt}`,
          style: 'tableRow',
          fontSize: 8,
        },
      ];
      body.push(row);
    }
    const additionalImagesTable = levelHeader?.levelAdditionalImageDataUrl
      ? {
          style: 'table',
          table: {
            headerRows: 1,
            widths: ['*'],
            body: [
              [{ text: 'Additional Images', style: 'tableRow2', alignment: 'center' }],
              [
                {
                  image: levelHeader?.levelAdditionalImageDataUrl,
                  fit: [565, 300],
                },
              ],
            ],
          },
        }
      : {};

    const levelHeadingsBody = [
      [
        { text: 'Print/Level', fillColor: colors.poloBlue, alignment: 'center' },
        { text: levelHeader?.levelCode, alignment: 'center', color: colors.black },
        {
          text: [
            { text: 'Notes: ' },
            {
              text: levelHeader?.levelNotes ? levelHeader?.levelNotes : 'N/A',
              color: colors.black,
            },
          ],
          rowSpan: getLevelNotesRowSpan(
            levelHeader.implantLevelType,
            form19Data.showTlifCOrientation,
          ),
        },
      ],
      [
        { text: 'Drawing #:', fillColor: colors.poloBlue, alignment: 'center' },
        { text: levelHeader?.drawingNumber, alignment: 'center', color: colors.black },
      ],
      [
        { text: 'Version:', fillColor: colors.poloBlue, alignment: 'center' },
        { text: levelHeader?.version, alignment: 'center', color: colors.black },
      ],
    ];

    if (
      [ImplantType.LLIF, ImplantType.TLIFO, ImplantType.TLIFC, ImplantType.TLIFCA].includes(
        levelHeader.implantLevelType,
      )
    ) {
      levelHeadingsBody.push([
        {
          text: 'Insertion Side:',
          fillColor: colors.poloBlue,
          alignment: 'center',
        },
        {
          text: getInsertionSideText(levelHeader.implantLevelType, levelHeader.direction),
          alignment: 'center',
          color: colors.black,
        },
      ]);
    }
    if ([ImplantType.ALIFX].includes(levelHeader.implantLevelType)) {
      if (form19Data.showCageOrientation) {
        levelHeadingsBody.push([
          {
            text: 'Cage Orientation:',
            fillColor: colors.poloBlue,
            alignment: 'center',
          },
          {
            text: format.formatImplantOrientation(levelHeader.orientation),
            alignment: 'center',
            color: colors.black,
          },
        ]);
      }
      levelHeadingsBody.push([
        { text: 'Screw Length:', fillColor: colors.poloBlue, alignment: 'center' },
        {
          text: [PartType.ALIF_X_TWO_DOWN, PartType.ALIF_X_TWO_UP].includes(levelHeader?.partType)
            ? levelHeader?.alifXLevelScrewLengthType
              ? format.formatInterbodyScrewLength(levelHeader?.alifXLevelScrewLengthType)
              : ''
            : 'N/A',
          alignment: 'center',
          color: colors.black,
        },
      ]);
    }
    if (
      form19Data.showTlifCOrientation &&
      [ImplantType.TLIFC, ImplantType.TLIFCA].includes(levelHeader.implantLevelType)
    ) {
      levelHeadingsBody.push([
        {
          text: 'Thread Angle:',
          fillColor: colors.poloBlue,
          alignment: 'center',
        },
        {
          text: format.formatImplantOrientation(levelHeader.orientation),
          alignment: 'center',
          color: colors.black,
        },
      ]);
    }

    const printLevelData = [
      {
        text: levelHeader?.implantLevelType?.replace('_', '-'),
        pageBreak: 'before',
        style: 'subheader',
      },
      {
        style: 'table',
        table: {
          widths: [100, 100, '*'],
          body: levelHeadingsBody,
        },
      },
      {
        style: 'table',
        table: {
          headerRows: 1,
          widths: [120, '*', '*', '*', '*', '*', '*', 60],
          body: body,
        },
      },
      {
        style: 'table',
        table: {
          headerRows: 1,
          widths: ['*', '*', '*'],
          body: [
            [
              { text: 'Minus 01', style: 'tableHeader2' },
              { text: 'Plan 02', style: 'tableHeader2' },
              { text: 'Plus 03', style: 'tableHeader2' },
            ],
            [
              { text: 'View 1', style: 'tableRow2' },
              { text: 'View 1', style: 'tableRow2' },
              { text: 'View 1', style: 'tableRow2' },
            ],
            [
              {
                text:
                  excludedImplantSizeForLevel !== LevelSize.Minus
                    ? levelHeader?.minus01File
                    : 'N/A',
                style: 'tableRow3',
              },
              { text: levelHeader?.plan02File, style: 'tableRow3' },
              {
                text:
                  excludedImplantSizeForLevel !== LevelSize.Plus ? levelHeader?.plus03File : 'N/A',
                style: 'tableRow3',
              },
            ],
          ],
        },
      },
      additionalImagesTable,
    ];
    allLevelsData.push(printLevelData);
  }

  function getStep3Body() {
    const step3Body: any[] = [];
    let step3Row: any[];
    step3Row = [
      {
        text: `Step 3: Quality Review of Implant`,
        style: 'tableHeader',
        colSpan: 2,
        alignment: 'center',
        fillColor: colors.poloBlue,
      },
      {},
    ];
    step3Body.push(step3Row);

    step3Row = [
      {
        text: `Confirm data input for implant dimensions is documented and within listed specifications`,
      },
      {
        text: form19Data?.step3?.verifyImplantCharacteristics ? Result.Pass : Result.Fail,
        color: form19Data?.step3?.verifyImplantCharacteristics ? colors.green : colors.red,
      },
    ];
    step3Body.push(step3Row);

    if (!isImplantSizeExclusionEnabled) {
      step3Body.push([
        {
          text: `Confirm 01 plan is 1mm shorter than 02 plan & 03 plan is 1mm/2mm taller than 02 plan for each level`,
        },
        {
          text: form19Data?.step3?.isMaxHeightCageValid ? Result.Pass : Result.Fail,
          color: form19Data?.step3?.isMaxHeightCageValid ? colors.green : colors.red,
        },
      ]);
    } else {
      step3Body.push(
        [
          {
            text: `Confirm 01 plan is 1mm shorter than 02 plan for each level`,
          },
          {
            text: form19Data?.step3?.isMinusSizeImplantExcludedForAllLevels
              ? 'N/A'
              : form19Data?.step3?.isMaxHeightCageValid
              ? Result.Pass
              : Result.Fail,
            color: form19Data?.step3?.isMinusSizeImplantExcludedForAllLevels
              ? colors.green
              : form19Data?.step3?.isMaxHeightCageValid
              ? colors.green
              : colors.red,
          },
        ],
        [
          {
            text: `Confirm 03 plan is 1mm/2mm taller than 02 plan for each level`,
          },
          {
            text: form19Data?.step3?.isPlusSizeImplantExcludedForAllLevels
              ? 'N/A'
              : form19Data?.step3?.isMaxHeightCageValid
              ? Result.Pass
              : Result.Fail,
            color: form19Data?.step3?.isPlusSizeImplantExcludedForAllLevels
              ? colors.green
              : form19Data?.step3?.isMaxHeightCageValid
              ? colors.green
              : colors.red,
          },
        ],
      );
    }
    //step3Body.push(step3Row);

    if (form19Data?.step3?.isNonConformance) {
      step3Row = [
        {
          text: `FORM-019 Non Conformance: ${form19Data?.step3?.nonConformanceReason}`,
          colSpan: 2,
          alignment: 'left',
        },
        {},
      ];
      step3Body.push(step3Row);
    }

    step3Row = [
      {
        text: `*If either of the above steps are FAIL, return to Step 2`,
        colSpan: 2,
        alignment: 'center',
        fillColor: colors.lightGray,
      },
      {},
    ];
    step3Body.push(step3Row);

    step3Row = [
      {
        text: `Quality Initial & Date of Review of Steps 1, 2 & 3`,
        colSpan: 2,
        alignment: 'center',
        fillColor: colors.lightYellow,
      },
      {},
    ];
    step3Body.push(step3Row);

    step3Row = [
      {
        text: `Name: ${qualityApprovedBy}`,
      },
      {
        text: `Date: ${qualityApprovedAt}`,
      },
    ];
    step3Body.push(step3Row);

    return step3Body;
  }

  function getForm19RevisionHistoryTable() {
    const body = [
      [
        {
          text: `FORM-019 Revision History`,
          colSpan: 3,
          alignment: 'center',
          fillColor: colors.poloBlue,
        },
        {},
        {},
      ],
      [
        { text: 'FORM-019 Rev', style: 'tableHeader2' },
        { text: 'LOT', style: 'tableHeader2' },
        { text: 'Description of Change', style: 'tableHeader2' },
      ],
    ];
    for (const form19RevisionHistory of form19RevisionsHistory) {
      const row = [
        { text: form19RevisionHistory?.revision ?? '-', style: 'tableRow' },
        { text: form19RevisionHistory?.lot ?? '-', style: 'tableRow' },
        { text: form19RevisionHistory?.description ?? '-', style: 'tableRow4' },
      ];
      body.push(row);
    }
    return {
      style: 'table',
      table: {
        headerRows: 1,
        widths: [100, 165, '*'],
        body: body,
      },
    };
  }

  function getLevelsFilesRevisionsHistoryTable() {
    const body = [
      [
        {
          text: `Design File Revision History`,
          colSpan: 4,
          alignment: 'center',
          fillColor: colors.lightGray,
        },
        {},
        {},
        {},
      ],
      [
        { text: 'PN', style: 'tableHeader2' },
        { text: 'Implant Design File Name', style: 'tableHeader2' },
        { text: 'Rev', style: 'tableHeader2' },
        { text: 'Description of Change', style: 'tableHeader2' },
      ],
    ];

    for (const fileRevisionHistory of levelsFilesRevisionsHistory) {
      const row = [
        { text: fileRevisionHistory?.partNumber ?? '-', style: 'tableRow4' },
        { text: fileRevisionHistory?.fileName ?? '-', style: 'tableRow' },
        { text: fileRevisionHistory?.revision ?? '-', style: 'tableRow' },
        { text: fileRevisionHistory?.description ?? '-', style: 'tableRow4' },
      ];
      body.push(row);
    }

    return {
      style: 'table',
      table: {
        headerRows: 1,
        widths: [100, 165, 35, '*'],
        body: body,
      },
    };
  }
  function createKitCartonContents() {
    const body = [
      [
        {
          text: `Kit Carton Contents for Lot Number: ${lotNumber}`,
          colSpan: 3,
          alignment: 'center',
          fillColor: colors.poloBlue,
        },
        {},
        {},
      ],
    ];

    for (const caseKitCarton of caseKitCartons) {
      if (!caseKitCarton?.itemsCount) break;

      const kitNameRow = [
        {
          text: caseKitCarton?.kitName ?? '-',
          colSpan: 3,
          alignment: 'center',
          style: 'tableHeader2',
        },
        {},
        {},
      ];
      // @ts-ignore
      body.push(kitNameRow);

      const kitHeaderRow = [
        { text: 'Item', style: 'tableHeader2' },
        { text: 'Part Number', style: 'tableHeader2' },
        { text: 'Description', style: 'tableHeader2' },
      ];
      // @ts-ignore
      body.push(kitHeaderRow);
      casePartsTotalQty += caseKitCarton?.itemsCount ?? 0;
      const kitItems = caseKitCarton.kitItems;

      for (const kitItem of kitItems) {
        const itemRow = [
          { text: kitItem?.itemNumber.toString() ?? '-', style: 'tableRow5' },
          { text: kitItem?.partNumber ?? '-', style: 'tableRow5' },
          { text: kitItem?.description ?? '-', style: 'tableRow5' },
        ];
        // @ts-ignore
        body.push(itemRow);
      }

      const blankRow = [
        { text: '', colSpan: 3, style: 'tableRow', fillColor: colors.lightGray },
        {},
        {},
      ];
      // @ts-ignore
      body.push(blankRow);
    }
    return {
      style: 'table',
      table: {
        headerRows: 0,
        widths: [50, 130, '*'],
        heights: function (row: number) {
          return row === 0 ? 20 : 8;
        },
        body: body,
      },
    };
  }

  function showKitCartonsTotalQty() {
    const body = [
      [
        {
          text: `Case Part QTY`,
          colSpan: 2,
          alignment: 'center',
          style: 'tableHeader2',
        },
        {},
      ],
      [
        { text: casePartsTotalQty.toString(), style: 'tableRow' },
        { text: 'Total QTY of Parts', style: 'tableRow' },
      ],
    ];

    return {
      style: 'table',
      table: {
        headerRows: 1,
        widths: [50, 130],
        body: body,
      },
    };
  }

  function showExcludedInstruments() {
    const body = [
      [
        {
          text: `This Kit Carton Excludes:`,
          colSpan: 2,
          alignment: 'center',
          style: 'tableHeader3',
        },
        {},
      ],
      [
        { text: 'Part Number', alignment: 'center', style: 'tableHeader2' },
        { text: 'Description', alignment: 'center', style: 'tableHeader2' },
      ],
    ];
    for (const excludedInstrument of excludedInstruments) {
      body.push([
        { text: excludedInstrument, colSpan: 1, alignment: 'center', style: 'tableRow' },
        {
          text: format.formatExcludedInstrumentType(excludedInstrument),
          colSpan: 1,
          alignment: 'center',
          style: 'tableRow',
        },
      ]);
    }

    return {
      style: 'table',
      table: {
        headerRows: 1,
        widths: [60, 120],
        body: body,
      },
    };
  }

  function showExcludedItems() {
    const body = [
      [
        {
          text: `This Kit Carton Excludes:`,
          colSpan: 2,
          alignment: 'center',
          style: 'tableHeader3',
        },
        {},
      ],
      [
        { text: 'Part Number', alignment: 'center', style: 'tableHeader2' },
        { text: 'Description', alignment: 'center', style: 'tableHeader2' },
      ],
    ];
    for (const excludedItem of excludedItems) {
      body.push([
        { text: excludedItem.partNumber, colSpan: 1, alignment: 'center', style: 'tableRow' },
        {
          text: excludedItem.description,
          colSpan: 1,
          alignment: 'center',
          style: 'tableRow',
        },
      ]);
    }

    return {
      style: 'table',
      table: {
        headerRows: 1,
        widths: [60, 120],
        body: body,
      },
    };
  }

  function createStep1Table() {
    const step1TableBody = [
      [
        {
          text: referToSops,
          style: 'tableHeader',
          colSpan: 2,
          alignment: 'center',
          fillColor: colors.lightGray,
        },
        {},
      ],
      [
        { text: 'Lot Number: ', fillColor: colors.lightGray },
        { text: lotNumber, color: colors.black, bold: true },
      ],
      [
        {
          text: 'Step 1: Operations Review of Images',
          style: 'tableHeader',
          colSpan: 2,
          alignment: 'center',
          fillColor: colors.poloBlue,
        },
        {},
      ],
      [
        {
          text: `Assure image slice thickness spacing of 3.0mm or less`,
          colSpan: 2,
          alignment: 'center',
          fillColor: colors.lightOrange,
        },
        {},
      ],
      [
        `Images have slice spacing of 3.0mm or less`,
        {
          text: form19Data?.step1?.imagesSliceSpacing ? Result.Pass : Result.Fail,
          color: form19Data?.step1?.imagesSliceSpacing ? colors.green : colors.red,
        },
      ],
      [
        `Assure images contain the entire lumbar spine`,
        {
          text: form19Data?.step1?.imageContainEntireLumbar ? Result.Pass : Result.Fail,
          color: form19Data?.step1?.imageContainEntireLumbar ? colors.green : colors.red,
        },
      ],
      [
        `Confirm imaging study occurs 18 months or less before surgery date or Surgeon has approved use of imaging provided`,
        {
          text: form19Data?.step1?.validStudyDate ? Result.Pass : Result.Fail,
          color: form19Data?.step1?.validStudyDate ? colors.green : colors.red,
        },
      ],
      [
        `Did Segmentation Pass?`,
        {
          text: Result.Pass,
          color: colors.green,
        },
      ],
      [
        `Case Risk Assessment`,
        {
          text: form19Data?.step1?.caseRiskAssessment
            ? format.formatCaseRiskAssessmentType(form19Data?.step1?.caseRiskAssessment)
            : 'N/A',
          color: colors.green,
        },
      ],
      [
        `Implant Plus Size Selected`,
        {
          text: form19Data?.step1?.plusLevelSize ? `+${form19Data?.step1?.plusLevelSize}mm` : '',
          color: colors.green,
        },
      ],
      [
        {
          text: `*If either of the above steps are FAIL, notify physician`,
          colSpan: 2,
          alignment: 'center',
          fillColor: colors.lightGray,
        },
        {},
      ],
    ];
    if (isImplantSizeExclusionEnabled) {
      step1TableBody.push(
        [
          `Implant Size Exclusion?`,
          {
            text: form19Data?.step1?.hasImplantSizeExclusion ?? 'N/A',
            color: colors.green,
          },
        ],
        [
          `Level and Implant Type Excluded`,
          {
            text: form19Data?.step1?.implantSizeExclusionList ?? 'N/A',
            color: colors.green,
          },
        ],
      );
    }
    step1TableBody.push(
      [
        {
          text: `Operations Initial & Date of Review of Step 1`,
          colSpan: 2,
          alignment: 'center',
          fillColor: colors.lightYellow,
        },
        {},
      ],
      [`Name: ${engineeringApprovedBy}`, `Date: ${engineeringApprovedAt}`],
    );

    return {
      style: 'table',
      table: {
        widths: ['*', '*'],
        body: step1TableBody,
      },
    };
  }

  const docDefinition: any = {
    header: { text: version, alignment: 'right', margin: [0, 15, 20, 0] },
    footer: function (currentPage: number, pageCount: number) {
      return {
        text: currentPage.toString() + ' of ' + pageCount,
        alignment: 'right',
        margin: [0, 0, 20, 20],
      };
    },
    pageSize: 'LETTER',
    pageOrientation: 'portrait',
    pageMargins: [20, 30, 20, 30],
    content: [
      { text: formName, style: 'header' },
      { text: documentDescription, style: 'header2' },
      createStep1Table(),
      {
        style: 'table',
        table: {
          widths: ['*', '*'],
          body: [
            [
              {
                text: `Step 2: Operations Verification of Planned Correction and Implants`,
                style: 'tableHeader',
                colSpan: 2,
                alignment: 'center',
                fillColor: colors.poloBlue,
              },
              {},
            ],
            [
              `Design Selections - number of levels`,
              { text: `${form19Data?.step2?.numberOfLevels} Level`, bold: true },
            ],
            [
              {
                text: `Verify all design characteristics are completed for plan 01, 02, 03 for all case levels selected on various tabs.`,
                style: 'tableHeader',
                colSpan: 2,
                alignment: 'center',
              },
              {},
            ],
            [
              {
                text: `If any dimension/feature fails, return to create surgical plan step in SOP-033`,
                colSpan: 2,
                alignment: 'center',
                fillColor: colors.lightGray,
              },
              {},
            ],
            [
              {
                text: `If Case Review is required, a case reviewer will verify the correction for the specific case is appropriate for the presentation to the surgeon.`,
                colSpan: 2,
                alignment: 'center',
                fillColor: colors.lightGray,
              },
              {},
            ],
            [
              {
                text: `Operations Case Review Name & Date of Review of Step 2`,
                colSpan: 2,
                alignment: 'center',
                fillColor: colors.lightYellow,
              },
              {},
            ],
            [`Name: ${peerReviewerPerRiskAssessment}`, `Date: ${peerReviewerPerRiskAssessmentAt}`],
          ],
        },
      },
      {
        style: 'table',
        table: {
          widths: ['*', '*'],
          body: getStep3Body(),
        },
      },
      { text: '', pageBreak: 'before', style: 'subheader' },
      {
        style: 'table',
        table: {
          widths: ['*', '*'],
          body: [
            [
              {
                text: `Step 4: Physician Reviews Surgical Plan`,
                style: 'tableHeader',
                colSpan: 2,
                alignment: 'center',
                fillColor: colors.poloBlue,
              },
              {},
            ],
            [
              {
                text: `if rejected, return to create surgical plan step per SOP-033`,
                colSpan: 2,
                alignment: 'center',
                fillColor: colors.lightGray,
              },
              {},
            ],
            [
              {
                text: `Physician approval acquired and is:`,
                colSpan: 2,
                alignment: 'center',
                fillColor: colors.lightYellow,
              },
              {},
            ],
            [`Stored in the production database?`, { text: 'YES', color: colors.green }],
            [`Acquired, with confirmation attached below`, { text: 'YES', color: colors.green }],
            [
              {
                text: `Physician approval verification by Engineering`,
                colSpan: 2,
                alignment: 'center',
                fillColor: colors.lightYellow,
              },
              {},
            ],
            [
              `Name: ${engineeringApprovedBy}`,
              `Date: ${form19Data?.approvals?.engineeringApprovedAt}`,
            ],
          ],
        },
      },
      physicianApproval,
      allLevelsData,
      [pageBreak, getForm19RevisionHistoryTable(), getLevelsFilesRevisionsHistoryTable()],
      caseKitCartons.length ? [pageBreak, createKitCartonContents()] : '',
      caseKitCartons.length ? [showKitCartonsTotalQty()] : '',
      caseKitCartons.length
        ? isImplantSizeExclusionEnabled && excludedItems.length
          ? [showExcludedItems()]
          : excludedInstruments.length
          ? [showExcludedInstruments()]
          : ''
        : '',
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10],
        alignment: 'center',
      },
      header2: {
        fontSize: 14,
        bold: true,
        margin: [0, 0, 0, 10],
        alignment: 'center',
      },
      subheader: {
        fontSize: 16,
        bold: true,
        margin: [0, 10, 0, 5],
        alignment: 'center',
      },
      table: {
        margin: [0, 5, 0, 15],
      },
      tableOpacity: {
        margin: [0, 5, 0, 15],
        fillColor: colors.blue,
        fillOpacity: 0.3,
      },
      tableHeader: {
        bold: true,
        fontSize: 13,
        color: colors.black,
      },
      tableHeader2: {
        bold: true,
        fontSize: 10,
        color: colors.black,
        fillColor: colors.lightYellow,
        alignment: 'center',
      },
      tableHeader3: {
        bold: true,
        fontSize: 10,
        color: colors.black,
        fillColor: colors.poloBlue,
        alignment: 'center',
      },
      tableRow: {
        bold: false,
        fontSize: 9,
        color: colors.black,
        alignment: 'center',
      },
      tableRow2: {
        bold: false,
        fontSize: 9,
        color: colors.blue,
        fillColor: colors.lightGreen,
        alignment: 'right',
      },
      tableRow3: {
        bold: false,
        fontSize: 8,
        color: colors.black,
        alignment: 'left',
      },
      tableRow4: {
        bold: false,
        fontSize: 9,
        color: colors.black,
        alignment: 'left',
      },
      tableRow5: {
        bold: false,
        fontSize: 8,
        color: colors.black,
        alignment: 'center',
      },
    },
    defaultStyle: {
      alignment: 'justify',
    },
  };

  return new Promise((resolve) => {
    const pdf = pdfMake.createPdf(
      docDefinition,
      undefined,
      undefined,
      vfsFonts.pdfMake ? vfsFonts.pdfMake.vfs : pdfMake.vfs,
    );
    pdf.getBlob((result: Blob) => {
      resolve(result);
    });
  });
};

export const extractLastPageData = async (url: string) => {
  try {
    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());

    // Load and prepare the new PDF document
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const pageCount = pdfDoc.getPageCount();
    const newPdfDoc = await PDFDocument.create();
    const [lastPage] = await newPdfDoc.copyPages(pdfDoc, [pageCount - 1]);
    newPdfDoc.addPage(lastPage);

    // Serialize the new PDF document to binary
    const newPdfBytes = await newPdfDoc.save();
    const newPdfBase64 = Buffer.from(newPdfBytes).toString('base64');

    return `data:application/pdf;base64,${newPdfBase64}`;
  } catch (error) {
    console.error('Error extracting last page:', error);
    throw error;
  }
};
