import {
  ArcRotateCamera,
  Color4,
  Matrix,
  PointerEventTypes,
  Scene,
  SceneLoader,
  Vector3,
} from 'babylonjs';
import { AdvancedDynamicTexture, Rectangle, TextBlock } from 'babylonjs-gui';
import { STLFileLoader } from 'babylonjs-loaders';
import { useState } from 'react';
import { SceneComponent } from '../../../../../../components/SceneComponent';

export interface ILoadingScreen {
  displayLoadingUI: () => void;
  hideLoadingUI: () => void;
  loadingUIBackgroundColor: string;
  loadingUIText: string;
}

export class CustomLoadingScreen implements ILoadingScreen {
  //optional, but needed due to interface definitions
  public displayLoadingUI: () => void;
  public hideLoadingUI: () => void;
  // @ts-ignore
  public loadingUIBackgroundColor: string;
  // @ts-ignore
  public loadingUIText: string;

  private textBlock: TextBlock;
  private background: Rectangle;

  constructor(private gui: AdvancedDynamicTexture) {
    this.displayLoadingUI = () => this.displayUIInternal();
    this.hideLoadingUI = () => this.hideLoadingUIInternal();

    this.background = new Rectangle('StateChangeLoadingScreen Rectangle');
    this.background.top = 0;
    this.background.left = 0;
    this.background.width = '100%';
    this.background.height = '100%';
    this.background.background = '#0d47a1';
    this.background.isVisible = false;
    gui.addControl(this.background);

    this.textBlock = new TextBlock('StateChangeLoadingScreen TextBlock', 'Loading...');
    this.textBlock.color = 'white';
    this.textBlock.isVisible = false;
    gui.addControl(this.textBlock);
  }

  private displayUIInternal(): void {
    this.textBlock.isVisible = true;
    this.background.isVisible = true;
  }

  private hideLoadingUIInternal(): void {
    this.textBlock.isVisible = false;
    this.background.isVisible = false;
  }
}

type MeshViewerProps = {
  canvasName: string;
  onReady: (scene: Scene) => void;
  onMeshClick?: (position: Vector3 | null, coordinates: { x: number; y: number }) => void;
  onMeshControlClick?: (position: Vector3 | null, coordinates?: { x: number; y: number }) => void;
  disableInputs?: boolean;
  distance?: number;
  transparentBackground?: boolean;
  debug?: boolean;
  loading?: boolean;
};

function MeshViewer({
  canvasName,
  onMeshClick,
  onMeshControlClick,
  onReady,
  disableInputs = false,
  distance = 100,
  transparentBackground = false,
  debug = false,
  loading = false,
}: MeshViewerProps) {
  const [scene, setScene] = useState<Scene>();

  if (loading) {
    scene?.getEngine().displayLoadingUI();
  } else {
    scene?.getEngine().hideLoadingUI();
  }

  const onSceneReady = (scene: Scene) => {
    setScene(scene);

    SceneLoader.RegisterPlugin(new STLFileLoader());

    scene.getEngine().loadingScreen = new CustomLoadingScreen(
      AdvancedDynamicTexture.CreateFullscreenUI('myUI'),
    );

    if (transparentBackground) {
      scene.clearColor = new Color4(0, 0, 0, 0);
    }

    // const canvas = scene.getEngine().getRenderingCanvas();
    // let light = scene.lights?.[0] as PointLight;
    //
    // camera.attachControl(canvas, false);
    //
    // light.position = camera.position;

    const camera = scene.activeCamera as ArcRotateCamera;
    scene.onPointerObservable.add(function (evt) {
      if (camera.inertialAlphaOffset || camera.inertialBetaOffset) {
        return;
      }
      const ray = scene.createPickingRay(scene.pointerX, scene.pointerY, Matrix.Identity(), camera);

      if (evt.type === PointerEventTypes.POINTERDOUBLETAP) {
        const hit = scene.pickWithRay(ray);

        if (hit) {
          const { pickedMesh, pickedPoint } = hit;

          if (pickedMesh && !!onMeshClick) {
            onMeshClick?.(pickedPoint, {
              x: scene.pointerX,
              y: scene.pointerY,
            });
          }
        }

        return;
      }
      if (evt.type === PointerEventTypes.POINTERTAP && evt.event.ctrlKey) {
        const hit = scene.pickWithRay(ray);
        if (hit && !!hit.pickedMesh && !!onMeshControlClick) {
          onMeshControlClick?.(hit.pickedPoint, {
            x: scene.pointerX,
            y: scene.pointerY,
          });
        }
        return;
      }
    });

    if (debug) {
      scene.debugLayer.show({ overlay: true });
    }

    onReady(scene);
  };

  const onRender = (scene: Scene) => {
    scene.render();
  };

  return (
    <SceneComponent
      antialias
      adaptToDeviceRatio={true}
      onSceneReady={onSceneReady}
      onRender={onRender}
      canvasId={canvasName}
      loading={loading}
    />
  );
}

export default MeshViewer;
