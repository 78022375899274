import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@mui/material';
import { ClassNameMap, makeStyles } from '@mui/styles';
import {
  CaseSpineProfile,
  CaseSpineType,
  DEFAULT_SPINE_PROFILE,
  IMeasure,
  LevelType,
  TableType,
} from '@workflow-nx/common';
import {
  MeasurementsVersionType,
  getLumbarMeasurements,
  getCervicalMeasurements,
} from '@workflow-nx/core-algorithms';
import { ReactNode, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { LumbarMeasurementSummaryView } from './LumbarMeasurementSummaryView';
import { LumbarMeasurementTable } from './LumbarMeasurementTable';
import { CervicalMeasurementTable } from './CervicalMeasurementTable';
import { CervicalMeasurementSummaryView } from './CervicalMeasurementSummaryView';

const useStyles = makeStyles(() => ({
  warning: {
    fill: '#ff980099',
    margin: '0 5px',
    fontSize: '18px',
  },
  danger: {
    fill: '#ff000099',
    margin: '0 5px',
    fontSize: '18px',
  },
  legendText: {
    fontSize: '13px',
  },
}));

export type MeasurementsConfig = {
  posteriorHeightMin: number;
  posteriorHeightMax: number;
  anteriorHeightMin: number;
  anteriorHeightMax: number;
  lordoticAngleMax: number;
  coronalAngleMax: number;
  anteriorHeightPreOp: number;
  shouldDisplayPreopDifferences: boolean;
};

type MeasurementsViewProps = {
  caseLevels: LevelType[];
  spineType: CaseSpineType;
  plan?: IMeasure[];
  preop?: IMeasure[];
  measurementsVersion: MeasurementsVersionType;
  caseSpineProfile: CaseSpineProfile;
  disableToggle: boolean;
  pelvicIncidence?: number;
  slopeOfLineOfSight?: number;
  color?: 'invert' | 'default';
  tableType: TableType;
  showErrors?: boolean;
  size?: 'small' | 'medium';
  measurementConfig: MeasurementsConfig;
  defaultExpanded?: boolean;
};

export function MeasurementsView({
  caseLevels = [],
  spineType = CaseSpineType.Lumbar,
  caseSpineProfile = DEFAULT_SPINE_PROFILE,
  disableToggle,
  preop = [],
  plan = [],
  pelvicIncidence,
  slopeOfLineOfSight,
  measurementsVersion,
  tableType = TableType.Preop,
  showErrors,
  size = 'medium',
  color = 'default',
  measurementConfig,
  defaultExpanded = true,
}: MeasurementsViewProps) {
  const classes: ClassNameMap = useStyles();

  const [isExpanded, setIsExpanded] = useState<boolean>(defaultExpanded);

  const setExpansion = (expanded: boolean) => {
    setIsExpanded(expanded);
  };

  const renderMeasurementLegend = () => {
    let result: ReactNode | null = null;

    if (showErrors) {
      result = (
        <div>
          <Box display={'flex'} mt={1}>
            <FontAwesomeIcon icon={faTriangleExclamation} className={classes.warning} />
            <Typography component={'span'} className={classes.legendText}>
              Height is out of range
            </Typography>
            <FontAwesomeIcon icon={faTriangleExclamation} className={classes.danger} />
            <Typography component={'span'} className={classes.legendText}>
              Angle is out of range
            </Typography>
          </Box>
        </div>
      );
    }

    return result;
  };

  const lumbarMeasurements =
    spineType === CaseSpineType.Lumbar
      ? getLumbarMeasurements({
          preOpMeasurements: preop ?? [],
          planMeasurements: plan ?? [],
          spineProfile: caseSpineProfile,
          measurementsVersion: measurementsVersion,
        })
      : undefined;

  const cervicalMeasurements =
    spineType === CaseSpineType.Cervical
      ? getCervicalMeasurements({
          preOpMeasurements: preop ?? [],
          planMeasurements: plan ?? [],
          spineProfile: caseSpineProfile,
        })
      : undefined;

  return (
    <div>
      {spineType === CaseSpineType.Lumbar ? (
        <LumbarMeasurementSummaryView
          data={lumbarMeasurements}
          pelvicIncidence={pelvicIncidence}
          tableType={tableType}
          size={'small'}
          color={'invert'}
          isExpanded={isExpanded}
          setExpansion={setExpansion}
          disableToggle={disableToggle}
        />
      ) : null}
      {spineType === CaseSpineType.Cervical ? (
        <CervicalMeasurementSummaryView
          data={cervicalMeasurements}
          slopeOfLineOfSight={slopeOfLineOfSight}
          tableType={tableType}
          size={'small'}
          isExpanded={isExpanded}
          setExpansion={setExpansion}
          disableToggle={disableToggle}
        />
      ) : null}
      {isExpanded ? (
        <>
          <PerfectScrollbar>
            {spineType === CaseSpineType.Lumbar && lumbarMeasurements ? (
              <LumbarMeasurementTable
                caseSpineProfile={caseSpineProfile}
                size={size}
                color={color}
                caseLevels={caseLevels}
                tableType={tableType}
                data-testid={`${tableType}-table`}
                showErrors={showErrors}
                measurementsConfig={measurementConfig}
                lumbarMeasurements={lumbarMeasurements}
              />
            ) : null}
            {spineType === CaseSpineType.Cervical && cervicalMeasurements ? (
              <CervicalMeasurementTable
                caseSpineProfile={caseSpineProfile}
                size={size}
                color={color}
                caseLevels={caseLevels}
                tableType={tableType}
                data-testid={`${tableType}-table`}
                showErrors={showErrors}
                measurementsConfig={measurementConfig}
                cervicalMeasurements={cervicalMeasurements}
              />
            ) : null}
          </PerfectScrollbar>
          {renderMeasurementLegend()}
        </>
      ) : null}
    </div>
  );
}
