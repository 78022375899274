import { CaseShippingStatusType, EventType } from '@workflow-nx/common';
import { ApolloQueryResult } from '@apollo/client/core/types';
import { Globals } from '../../../../layouts/DashboardLayout';

export type ListCaseEventsActionType = {
  type: string;
  data?: any;
};

export type ListCaseEventsStateType = {
  assignedTo?: string;
  fieldRep?: string;
  organization?: string;
  surgeon?: string;
  events?: EventType[];
  shippingStatuses: CaseShippingStatusType[];
  orderBy?: { [key: string]: 'asc' | 'desc' };
  search?: string;
  pageSize?: number;
  pageNumber?: number;
};

export const ListCasesEventsReducer =
  (refetch: (variables?: Partial<any>) => Promise<ApolloQueryResult<any>>) =>
  (state: ListCaseEventsStateType, action: ListCaseEventsActionType): ListCaseEventsStateType => {
    let updatedState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
      case 'refetch':
        refetch();
        break;
      case 'RESET_FILTERS':
        updatedState = {
          assignedTo: '',
          organization: '',
          surgeon: '',
          fieldRep: '',
          search: '',
          events: [],
          shippingStatuses: [],
          orderBy: { surgeryDate: 'asc' },
          pageSize: Globals.DefaultListPageSize,
          caseTags: [],
          partTypes: [],
        };
        localStorage.removeItem('ListProductionFocusCases.cache');
        break;
      case 'SEARCH_CHANGED':
        updatedState.search = action.data;
        break;
      case 'ORDER_BY_CHANGED':
        updatedState.orderBy = action.data;
        break;
      case 'ASSIGNED_TO_CHANGED':
        updatedState.assignedTo = action.data;
        break;
      case 'EVENTS_CHANGED':
        updatedState.events = action.data;
        break;
      case 'SHIPPING_STATUS_CHANGED':
        updatedState.shippingStatuses = action.data;
        break;
      case 'FIELD_REP_CHANGED':
        updatedState.fieldRep = action.data;
        break;
      case 'SURGEON_CHANGED':
        updatedState.surgeon = action.data;
        break;
      case 'ORGANIZATION_CHANGED':
        updatedState.organization = action.data;
        break;
      case 'UPDATE_PAGINATION':
        updatedState.pageSize = action.data.pageSize;
        updatedState.pageNumber = action.data.pageNumber;
        break;
    }

    // Reset page number if a query parameter has changed
    if (action.type !== 'UPDATE_PAGINATION') updatedState.pageNumber = 0;

    let orderBy = updatedState.orderBy;

    if (Object.keys(orderBy)[0] === 'surgeonId') {
      orderBy = { surgeonUser: { lastName: orderBy['surgeonId'] } };
    }

    if (Object.keys(orderBy)[0] === 'assignedId') {
      orderBy = { assignedUser: { lastName: orderBy['assignedId'] } };
    }

    if (Object.keys(orderBy)[0] === 'fieldRepId') {
      orderBy = { fieldRepUser: { lastName: orderBy['fieldRepId'] } };
    }

    if (Object.keys(orderBy)[0] === 'organizationId') {
      orderBy = { organization: { name: orderBy['organizationId'] } };
    }

    const variables = {
      assignedToFilter:
        updatedState.assignedTo !== '' ? [Number(updatedState.assignedTo)] : undefined,
      organizationFilter: updatedState.organization ? [updatedState.organization] : undefined,
      surgeonFilter: updatedState.surgeon ? [Number(updatedState.surgeon)] : undefined,
      fieldRepFilter: updatedState.fieldRep ? [Number(updatedState.fieldRep)] : undefined,
      eventsFilter: updatedState.events,
      shippingStatusFilter: updatedState.shippingStatuses,
      orderBy,
      search: updatedState.search,
      take: updatedState.pageSize,
      skip:
        updatedState.pageSize && updatedState.pageNumber
          ? updatedState.pageSize * updatedState.pageNumber
          : undefined,
    };

    refetch(variables);

    localStorage.setItem(
      'ListProductionFocusCases.cache',
      JSON.stringify({
        variables: variables,
        state: updatedState,
      }),
    );

    return updatedState;
  };
