import { ICase, format } from '@workflow-nx/common';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { UPDATE_PATIENT } from '../../../gql';
import { Formik, FormikHelpers } from 'formik';
import {
  IUpdatePatientDetailsFormValues,
  updatePatientDetailsFormValues,
} from '../../../extras/formValues';
import { updatePatientDetailsSchema } from '../../../extras/schemas';
import { Box, FormControlLabel, FormGroup, Grid, Switch, Typography } from '@mui/material';
import CustomDialog from '../../../components/CustomDialog';
import { DateTime } from 'luxon';
import { DatePickerField } from '../../../components/DatePickerField';
import { NumberTextField } from '../../../components/NumberTextField';
import { ProgressButton, SelectField, TextField } from '@workflow-nx/ui';

export function EditPatientDetailsDialog({
  activeCase,
  open,
  onClose,
}: {
  open: boolean;
  activeCase: ICase;
  onClose: (shouldUpdate: boolean) => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [updatePatient, { loading: loadingUpdatePatient }] = useMutation(UPDATE_PATIENT);
  const [isParticipant, setIsParticipant] = useState(!!activeCase?.patient?.participantId);
  const handleSubmitForm = async (
    values: IUpdatePatientDetailsFormValues,
    { setStatus, setSubmitting }: FormikHelpers<IUpdatePatientDetailsFormValues>,
  ) => {
    try {
      const birthDate = DateTime.fromJSDate(values.birthDate as Date).toISODate();
      const patient = activeCase.patient;

      let participantId = isParticipant ? values.participantId : null;
      if (isParticipant && !participantId) {
        participantId = 'TBD';
      }

      await updatePatient({
        variables: {
          patientId: patient.patientId,
          firstName: values.firstName,
          middleName: values.middleName,
          lastName: values.lastName,
          gender: values.gender || null,
          mrn: values.mrn,
          birthDate: birthDate || null,
          phoneNumber: values.phoneNumber || null,
          email: values.email || null,
          contactPreference: values.contactPreference || null,
          contactCadence: values.contactCadence || null,
          hasPermissionToContact: values.hasPermissionToContact
            ? values.hasPermissionToContact === 'true'
            : null,
          hasPermissionToSchedule: values.hasPermissionToSchedule
            ? values.hasPermissionToSchedule === 'true'
            : null,
          participantId,
        },
      });

      setStatus({ success: true });
      enqueueSnackbar(`Patient ${format.formatName(patient)} details updated`, {
        variant: 'success',
      });

      onClose(true);
    } catch (err) {
      console.error(err);
      setStatus({ success: false });
      enqueueSnackbar('An error occurred updating the patient details', {
        variant: 'error',
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={updatePatientDetailsFormValues(activeCase)}
      validationSchema={updatePatientDetailsSchema}
      onSubmit={handleSubmitForm}
      enableReinitialize={true}
    >
      {({ handleSubmit, isSubmitting, submitForm }) => {
        const loading = isSubmitting || loadingUpdatePatient;

        return (
          <CustomDialog
            maxWidth={'lg'}
            open={open}
            title={`Editing Patient ${format.formatName(activeCase.patient)}`}
            onClose={() => {
              onClose(false);
            }}
            positiveActionButtons={[
              <ProgressButton
                onClick={() => submitForm()}
                loading={loading}
                disabled={loading}
                label={'Save'}
              />,
            ]}
          >
            {open ? (
              <form onSubmit={handleSubmit}>
                <Typography sx={{ mb: 2 }} variant={'h4'}>
                  Patient Details
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <TextField name={'firstName'} label={'First Name'} disabled={loading} />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField name={'middleName'} label={'Middle Name'} disabled={loading} />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField name={'lastName'} label={'Last Name'} disabled={loading} />
                  </Grid>
                  <Grid item xs={4}>
                    <DatePickerField
                      disabled={loading}
                      name="birthDate"
                      required={false}
                      label={'Birth Date'}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <SelectField
                      name={'gender'}
                      label={'Gender'}
                      hideNone={true}
                      menuItems={[
                        { key: 'MALE', value: 'Male' },
                        { key: 'FEMALE', value: 'Female' },
                      ]}
                      disabled={loading}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField name={'mrn'} label={'MRN'} disabled={loading} />
                  </Grid>
                </Grid>

                <Typography sx={{ my: 2 }} variant={'h4'}>
                  Patient Contact Information
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <NumberTextField
                      name={'phoneNumber'}
                      label={'Phone'}
                      disabled={loading}
                      allowNegative={false}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField name={'email'} label={'Email'} disabled={loading} />
                  </Grid>
                  <Grid item xs={4}>
                    <SelectField
                      name={'contactPreference'}
                      label={'Contact Preference'}
                      hideNone={true}
                      menuItems={[
                        { key: 'PHONE', value: 'Phone' },
                        { key: 'TEXT', value: 'Text' },
                        { key: 'EMAIL', value: 'Email' },
                      ]}
                      disabled={loading}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <SelectField
                      name={'contactCadence'}
                      label={'Contact Cadence'}
                      hideNone={true}
                      menuItems={[
                        { key: 'OPT_OUT', value: 'Opt-Out' },
                        { key: 'WEEKLY', value: 'Weekly' },
                        { key: 'BI_WEEKLY', value: 'Bi-Weekly' },
                      ]}
                      disabled={loading}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <SelectField
                      name={'hasPermissionToContact'}
                      label={'Permission to Contact'}
                      hideNone={true}
                      menuItems={[
                        { key: 'true', value: 'Yes' },
                        { key: 'false', value: 'No' },
                      ]}
                      disabled={loading}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <SelectField
                      name={'hasPermissionToSchedule'}
                      label={'Permission to Schedule'}
                      hideNone={true}
                      menuItems={[
                        { key: 'true', value: 'Yes' },
                        { key: 'false', value: 'No' },
                      ]}
                      disabled={loading}
                    />
                  </Grid>
                </Grid>

                <Typography sx={{ my: 2 }} variant={'h4'}>
                  ComPASS Information
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={isParticipant}
                            onChange={(event) => {
                              setIsParticipant(event.target.checked);
                            }}
                          />
                        }
                        label={
                          <Typography variant={'body1'} noWrap={true}>
                            ComPASS Patient?
                          </Typography>
                        }
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      name={'participantId'}
                      label={'ComPASS Participant ID'}
                      disabled={loading || !isParticipant}
                    />
                  </Grid>
                </Grid>
                <Box mt={1} />
              </form>
            ) : null}
          </CustomDialog>
        );
      }}
    </Formik>
  );
}
