import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import { Box, Grid, Typography } from '@mui/material';
import * as Yup from 'yup';
import { ProgressButton, TextField } from '@workflow-nx/ui';
import CustomDialog from '../../../../components/CustomDialog';

export function ApprovedWithChangesDaisySegmentationReasonDialog({
  title,
  labelText,
  description,
  loading,
  open,
  onComplete,
  onClose,
}: {
  open: boolean;
  title: string;
  labelText: string;
  description?: string;
  loading?: boolean;
  onComplete: (reason: string) => void;
  onClose: (shouldUpdate: boolean) => void;
}) {
  const handleSubmitForm = async (
    values: { reason: string },
    { setSubmitting }: FormikHelpers<{ reason: string }>,
  ) => {
    if (onComplete) {
      onComplete(values.reason);
    }
    setSubmitting(false);
  };

  const initialValues: { reason: string } = {
    reason: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        reason: Yup.string().required(),
      })}
      onSubmit={handleSubmitForm}
      enableReinitialize={true}
    >
      {({ handleSubmit, isSubmitting, submitForm }) => (
        <CustomDialog
          maxWidth={'md'}
          open={open}
          title={title}
          onClose={() => {
            onClose(false);
          }}
          positiveActionButtons={[
            <ProgressButton
              onClick={() => submitForm()}
              loading={isSubmitting || loading}
              disabled={isSubmitting || loading}
              label={labelText}
            />,
          ]}
        >
          {open ? (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} direction={'column'}>
                {description ? (
                  <Grid item xs>
                    <Typography variant={'body1'}>{description}</Typography>
                  </Grid>
                ) : null}
                <Grid item xs>
                  <TextField
                    disabled={isSubmitting}
                    name="reason"
                    label={'Reason'}
                    multiline={true}
                  />
                </Grid>
              </Grid>
              <Box mt={1} />
            </form>
          ) : null}
        </CustomDialog>
      )}
    </Formik>
  );
}
