import { useQuery } from '@apollo/client';
import { FIND_ASSETS } from '../gql';
import { AssetType, IAsset, ValidFileExtensions } from '@workflow-nx/common';
import React, { useReducer } from 'react';
import { AssetGridTable } from './AssetGridTable/AssetGridTable';

export function PreOpStandingXrayAssetGridTable(props: { caseId: number }) {
  const { loading: loadingXrayAssets } = useQuery(FIND_ASSETS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      caseId: props.caseId,
      assetTypeFilter: [AssetType.StandingXrayAP, AssetType.StandingXrayLateral],
    },
    onCompleted: (data) => {
      standingXrayDispatch({
        type: 'INIT',
        data: data.assets,
      });
    },
  });

  const standingXrayReducer = (
    state: {
      standingXrayAssets: IAsset[];
    },
    action: {
      type: string;
      data: any;
    },
  ): {
    standingXrayAssets: IAsset[];
  } => {
    let updatedState = state;

    switch (action.type) {
      case 'ASSET_REPLACED': {
        const replacedAsset = action.data;
        let replacedXrayAssets = JSON.parse(JSON.stringify(state.standingXrayAssets));

        replacedXrayAssets = replacedXrayAssets.filter(
          (ca: IAsset) => ca.assetType !== replacedAsset.assetType,
        );
        replacedXrayAssets.push(replacedAsset);

        updatedState = {
          standingXrayAssets: replacedXrayAssets,
        };
        break;
      }
      case 'ASSET_DELETED': {
        const deletedAsset = action.data;
        let updatedXrayAssets = JSON.parse(JSON.stringify(state.standingXrayAssets));

        updatedXrayAssets = updatedXrayAssets.filter(
          (ca: IAsset) => ca.assetId !== deletedAsset.assetId,
        );

        updatedState = {
          standingXrayAssets: updatedXrayAssets,
        };

        break;
      }
      case 'ASSET_UPLOADED': {
        const updatedXrayAssets = JSON.parse(JSON.stringify(state.standingXrayAssets));
        const createdAsset = action.data;

        updatedXrayAssets.push(createdAsset);

        updatedState = {
          standingXrayAssets: updatedXrayAssets,
        };
        break;
      }
      case 'INIT': {
        const standingXrayAssets = action?.data ?? [];

        updatedState = {
          standingXrayAssets,
        };
        break;
      }
      default:
        throw new Error();
    }
    return updatedState;
  };

  const [standingXrayState, standingXrayDispatch] = useReducer(standingXrayReducer, {
    standingXrayAssets: [],
  });

  return (
    <AssetGridTable
      dispatch={standingXrayDispatch}
      caseId={props.caseId}
      assets={standingXrayState.standingXrayAssets ?? []}
      allowReplace={true}
      validFileExtensions={[
        ValidFileExtensions.JPG,
        ValidFileExtensions.JPEG,
        ValidFileExtensions.PNG,
      ]}
      readOnly={loadingXrayAssets}
      validAssets={[AssetType.StandingXrayLateral, AssetType.StandingXrayAP]}
    />
  );
}
