import {
  CloudDesignStatus,
  format,
  IPlan,
  IPlanImplant,
  LevelSize,
  LevelType,
  PartType,
} from '@workflow-nx/common';
import React, { useState } from 'react';
import CustomDialog from '../../../../components/CustomDialog';
import { Box, Typography } from '@mui/material';
import { CloudDesignTable } from 'apps/workflow-client/src/app/components/CloudDesignTable/CloudDesignTable';
import { useSnackbar } from 'notistack';
import { useQuery } from '@apollo/client';
import { FIND_PLAN_IMPLANT_BY_LEVEL } from '../../../../gql';

export function ManageDesignAssetsDialog(props: {
  plan: IPlan;
  partType: PartType;
  level: LevelType;
  open: boolean;
  caseNumber: string;
  onClose: () => void;
  onStatusChange: (
    level: LevelType,
    levelSize: LevelSize,
    partType: PartType,
    cloudDesignStatus: CloudDesignStatus,
  ) => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [planImplant, setPlanImplant] = useState<IPlanImplant | null>(null);

  useQuery(FIND_PLAN_IMPLANT_BY_LEVEL, {
    variables: { planId: props.plan.planId, level: props.level },
    onCompleted: (data) => {
      setPlanImplant(data.planImplant);
    },
  });

  const cloudDesignTableItems = [
    {
      level: props.level,
      levelSize: LevelSize.Minus,
      partType: props.partType,
      label: <>{format.formatInterbodyLevelSize(LevelSize.Minus)}</>,
    },
    {
      level: props.level,
      levelSize: LevelSize.Normal,
      partType: props.partType,
      label: <>{format.formatInterbodyLevelSize(LevelSize.Normal)}</>,
    },
    {
      level: props.level,
      levelSize: LevelSize.Plus,
      partType: props.partType,
      label: <>{format.formatInterbodyLevelSize(LevelSize.Plus)}</>,
    },
  ];

  return (
    <CustomDialog
      maxWidth={'md'}
      fullHeight={false}
      open={props.open}
      title={`${format.formatLevelType(props.level)} Design Assets - ${props.caseNumber}`}
      onClose={() => {
        props.onClose();
      }}
      positiveActionButtons={[]}
    >
      <>
        <Box display={'flex'} mb={2} flexDirection={'column'}>
          <Box display={'flex'} alignItems={'center'}>
            <Typography variant={'h4'} noWrap={true}>
              {format.formatPartType(props.partType)}
            </Typography>
            <Box mx={1} />
            <Typography variant={'body1'}>
              {planImplant?.ap}mm x {planImplant?.ml}mm
            </Typography>
          </Box>
          {[
            PartType.LLIF_LEFT,
            PartType.LLIF_RIGHT,
            PartType.ALIF,
            PartType.ALIF_X_TWO_UP,
            PartType.ALIF_X_TWO_DOWN,
          ].includes(planImplant?.partType as PartType) ? (
            <Box>
              <Box display={'flex'} alignItems={'center'}>
                <Typography variant={'body1'}>Insertion Side</Typography>
                <Box mx={1} />
                <Typography variant={'body2'}>
                  Bullet Angle: {planImplant?.bullet?.insertionSide?.angle}&deg;
                </Typography>
                <Box mx={1} />
                <Typography variant={'body2'}>
                  Taper Height: {planImplant?.bullet?.insertionSide?.height}&deg;
                </Typography>
              </Box>
              {[PartType.LLIF_LEFT, PartType.LLIF_RIGHT].includes(
                planImplant?.partType as PartType,
              ) ? (
                <Box display={'flex'} alignItems={'center'}>
                  <Typography variant={'body1'}>Threaded Side</Typography>
                  <Box mx={1} />
                  <Typography variant={'body2'}>
                    Bullet Angle: {planImplant?.bullet?.threadedSide?.angle}&deg;
                  </Typography>
                  <Box mx={1} />
                  <Typography variant={'body2'}>
                    Taper Height: {planImplant?.bullet?.threadedSide?.height}&deg;
                  </Typography>
                </Box>
              ) : null}
            </Box>
          ) : null}
        </Box>
        <CloudDesignTable
          items={cloudDesignTableItems}
          caseId={props.plan.caseId}
          planId={props.plan.planId}
          onStatusChange={(level, levelSize, partType, cloudDesignStatus: CloudDesignStatus) => {
            if (cloudDesignStatus === CloudDesignStatus.Success) {
              enqueueSnackbar(
                `Cloud Design complete for ${format.formatLevelType(
                  level,
                )} ${format.formatLevelSize(levelSize)} and ${format.formatPartType(partType)}`,
                {
                  variant: 'success',
                },
              );
            }
            props.onStatusChange(level, levelSize, partType, cloudDesignStatus);
          }}
        />
      </>
    </CustomDialog>
  );
}
