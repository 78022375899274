import { Box, Chip } from '@mui/material';
import { CaseType } from '@workflow-nx/common';
import React from 'react';

export const CaseTypeChip: React.FC<{
  caseType: CaseType;
}> = ({ caseType }) => {
  const renderChip = () => {
    switch (caseType) {
      case CaseType.Lab:
        return <Chip size={'small'} color={'secondary'} label={'LAB'} />;
      case CaseType.Test:
        return <Chip size={'small'} color={'secondary'} label={'TEST'} />;
      case CaseType.Demo:
        return <Chip size={'small'} color={'secondary'} label={'DEMO'} />;
      case CaseType.Draft:
        return <Chip size={'small'} color={'secondary'} label={'DRAFT'} />;
      default:
        return null;
    }
  };

  return (
    <Box pl={1} display={'inline'}>
      {renderChip()}
    </Box>
  );
};
