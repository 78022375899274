import React, { useState } from 'react';

import { useQuery } from '@apollo/client';
import { FIND_REPORT_CASE_STAGE_STATUS } from '../../gql';
import { Badge, Box, TableBody, TableRow, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TableCell } from '../../components/TableCell';
import {
  ActivityType,
  CaseStageType,
  CaseType,
  format,
  IActivity,
  ICase,
  ITableHeader,
} from '@workflow-nx/common';
import { date } from '@workflow-nx/utils';
import { useNavigate } from 'react-router-dom';
import { green, grey, red, yellow } from '@mui/material/colors';
import { DashboardCardView } from './DashboardCardView';
import { CommonTable } from '../../components/CommonTable';
import _ from 'lodash';

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: '0.75em',
  },
  root: {
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.divider} !important`,
  },
  progressUnknown: {
    borderBottom: `2px solid ${grey[500]} !important`,
  },
  progressWarning: {
    borderBottom: `2px solid ${yellow[500]} !important`,
  },
  progressDanger: {
    borderBottom: `2px solid ${red[500]} !important`,
  },
  progressOk: {
    borderBottom: `2px solid ${green[500]} !important`,
  },
}));

const CaseDaysInStage = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const [tableCasesData, setTableCasesDate] = useState<ICase[]>([]);
  const [headerOrderBy, setHeaderOrderBy] = useState<{ [key: string]: 'asc' | 'desc' }>({
    surgerydate: 'asc',
  });
  const { loading } = useQuery(FIND_REPORT_CASE_STAGE_STATUS, {
    variables: { orderBy: { surgeryDate: 'asc' } },
    onCompleted: (data) => {
      if (data?.reportCaseStageStatus && data?.reportCaseStageStatus.length > 0) {
        setTableCasesDate(data.reportCaseStageStatus);
      }
    },
  });

  const getDaysInStage = (
    stage: CaseStageType,
    receivedAt: string,
    activities: IActivity[],
  ): number => {
    let daysInStage = 0;

    switch (stage) {
      case CaseStageType.Open:
        daysInStage = date.daysSince(new Date(), date.parseISO(receivedAt));
        break;
      case CaseStageType.Segmentation: {
        const activity = activities.find(
          (activity: IActivity) =>
            activity.activityType === ActivityType.CaseStageOpenComplete ||
            activity.activityType === ActivityType.CaseStageSegmentationReopened,
        );

        const sinceDate = activity?.createdAt ?? receivedAt;
        if (sinceDate) {
          daysInStage = date.daysSince(
            new Date(),
            date.parseCalendarDateFromString(sinceDate as unknown as string) as Date,
          );
        }
        break;
      }
      case CaseStageType.Planning: {
        const activity = activities.find(
          (activity: IActivity) =>
            activity.activityType === ActivityType.CaseStageSegmentationComplete ||
            activity.activityType === ActivityType.CaseStagePlanningReopened ||
            activity.activityType === ActivityType.PlanProposalRejected,
        );

        if (activity?.createdAt) {
          daysInStage = date.daysSince(
            new Date(),
            date.parseCalendarDateFromString(activity?.createdAt as unknown as string) as Date,
          );
        }
        break;
      }
      case CaseStageType.Proposed: {
        const activity = activities.find(
          (activity: IActivity) =>
            activity.activityType === ActivityType.CaseStagePlanningComplete ||
            activity.activityType === ActivityType.CaseStageProposedReopened,
        );

        if (activity?.createdAt) {
          daysInStage = date.daysSince(
            new Date(),
            date.parseCalendarDateFromString(activity?.createdAt as unknown as string) as Date,
          );
        }
        break;
      }
      case CaseStageType.Design: {
        const activity = activities.find(
          (activity: IActivity) =>
            activity.activityType === ActivityType.CaseStageProposedComplete ||
            activity.activityType === ActivityType.CaseStageDesignReopened,
        );

        if (activity?.createdAt) {
          daysInStage = date.daysSince(
            new Date(),
            date.parseCalendarDateFromString(activity?.createdAt as unknown as string) as Date,
          );
        }
        break;
      }
      case CaseStageType.QaReview: {
        const activity = activities.find(
          (activity: IActivity) =>
            activity.activityType === ActivityType.CaseStageDesignComplete ||
            activity.activityType === ActivityType.CaseStageQaReviewReopened,
        );

        if (activity?.createdAt) {
          daysInStage = date.daysSince(
            new Date(),
            date.parseCalendarDateFromString(activity?.createdAt as unknown as string) as Date,
          );
        }
        break;
      }
    }

    return daysInStage;
  };

  const headers: ITableHeader[] = [
    {
      id: 'draft',
      sortable: true,
      label: 'Draft',
      align: 'center',
    },
    {
      id: 'segmentation',
      sortable: true,
      label: 'Segmentation',
      align: 'center',
    },
    {
      id: 'planning',
      sortable: true,
      label: 'Planning',
      align: 'center',
    },
    {
      id: 'proposed',
      sortable: true,
      label: 'Proposed',
      align: 'center',
    },
    {
      id: 'design',
      sortable: true,
      label: 'Design',
      align: 'center',
    },
    {
      id: 'qaReview',
      sortable: true,
      label: 'QA Review',
      align: 'center',
    },
    {
      id: 'surgeryDate',
      sortable: true,
      label: 'Surgery Date',
      align: 'center',
    },
  ];

  const getCaseStageStatusStyle = (daysUntilDueDate: number | undefined) => {
    if (daysUntilDueDate === undefined) {
      return styles.progressUnknown;
    }
    if (daysUntilDueDate < 14 && daysUntilDueDate > 7) {
      return styles.progressWarning;
    }
    if (daysUntilDueDate < 7) {
      return styles.progressDanger;
    }
    return styles.progressOk;
  };

  const handleSort = (orderBy: { [key: string]: 'asc' | 'desc' }) => {
    const key = Object.keys(orderBy)?.[0];
    const orderDirection = Object.values(orderBy)?.[0];

    let newTableCasesSort;
    switch (key) {
      case 'segmentation':
        newTableCasesSort = _.orderBy(
          tableCasesData,
          [
            (tableCasesData) => tableCasesData.stage !== CaseStageType.Segmentation,
            (tableCasesData) => tableCasesData.caseType !== CaseType.Live,
            (tableCasesData) =>
              getDaysInStage(
                tableCasesData.stage,
                tableCasesData.receivedAt as unknown as string,
                tableCasesData.activities,
              ),
          ],
          ['asc', 'asc', orderDirection],
        );
        break;
      case 'planning':
        newTableCasesSort = _.orderBy(
          tableCasesData,
          [
            (tableCasesData) => tableCasesData.stage !== CaseStageType.Planning,
            (tableCasesData) => tableCasesData.caseType !== CaseType.Live,
            (tableCasesData) =>
              getDaysInStage(
                tableCasesData.stage,
                tableCasesData.receivedAt as unknown as string,
                tableCasesData.activities,
              ),
          ],
          ['asc', 'asc', orderDirection],
        );
        break;
      case 'proposed':
        newTableCasesSort = _.orderBy(
          tableCasesData,
          [
            (tableCasesData) => tableCasesData.stage !== CaseStageType.Proposed,
            (tableCasesData) => tableCasesData.caseType !== CaseType.Live,
            (tableCasesData) =>
              getDaysInStage(
                tableCasesData.stage,
                tableCasesData.receivedAt as unknown as string,
                tableCasesData.activities,
              ),
          ],
          ['asc', 'asc', orderDirection],
        );
        break;
      case 'design':
        newTableCasesSort = _.orderBy(
          tableCasesData,
          [
            (tableCasesData) => tableCasesData.stage !== CaseStageType.Design,
            (tableCasesData) => tableCasesData.caseType !== CaseType.Live,
            (tableCasesData) =>
              getDaysInStage(
                tableCasesData.stage,
                tableCasesData.receivedAt as unknown as string,
                tableCasesData.activities,
              ),
          ],
          ['asc', 'asc', orderDirection],
        );
        break;
      case 'qaReview':
        newTableCasesSort = _.orderBy(
          tableCasesData,
          [
            (tableCasesData) => tableCasesData.stage !== CaseStageType.QaReview,
            (tableCasesData) => tableCasesData.caseType !== CaseType.Live,
            (tableCasesData) =>
              getDaysInStage(
                tableCasesData.stage,
                tableCasesData.receivedAt as unknown as string,
                tableCasesData.activities,
              ),
          ],
          ['asc', 'asc', orderDirection],
        );
        break;
      case 'draft':
        newTableCasesSort = _.orderBy(
          tableCasesData,
          [(tableCasesData) => tableCasesData.caseType !== CaseType.Draft, 'surgeryDate'],
          ['asc', orderDirection],
        );
        break;
      case 'surgeryDate':
        newTableCasesSort = _.orderBy(tableCasesData, ['surgeryDate'], [orderDirection]);
        break;
      default:
        newTableCasesSort = tableCasesData;
    }

    setTableCasesDate(newTableCasesSort);
    setHeaderOrderBy(orderBy);
  };

  return (
    <DashboardCardView label={'Case Days In Stage'}>
      <CommonTable
        orderBy={headerOrderBy}
        headers={headers}
        onHeaderClick={(orderBy) => {
          handleSort(orderBy);
        }}
        rows={
          <TableBody>
            {tableCasesData?.map((reportCase: ICase) => {
              return reportCase.stage !== CaseStageType.Open ||
                reportCase.caseType === CaseType.Draft ? (
                <TableRow
                  key={reportCase.caseId}
                  hover={true}
                  onClick={() => navigate(`/app/cases/${reportCase.caseId}`)}
                >
                  <TableCell align={'center'}>
                    {reportCase.caseType === CaseType.Draft ? (
                      <Badge color="primary">
                        <Box className={`${styles.root} `} style={{ cursor: 'pointer' }}>
                          <Typography variant={'button'}>{reportCase.number}</Typography>
                        </Box>
                      </Badge>
                    ) : null}
                  </TableCell>
                  {[
                    CaseStageType.Segmentation,
                    CaseStageType.Planning,
                    CaseStageType.Proposed,
                    CaseStageType.Design,
                    CaseStageType.QaReview,
                  ].map((stage: CaseStageType) => {
                    let daysUntilDueDate = undefined;

                    const daysInStage = getDaysInStage(
                      stage,
                      reportCase.receivedAt as unknown as string,
                      reportCase.activities,
                    );

                    if (reportCase.surgeryDate) {
                      const dueDate = date.subtract(new Date(reportCase.surgeryDate), {
                        weeks: 2,
                      });
                      const since = date.daysSince(dueDate, new Date());
                      daysUntilDueDate = since < 0 ? 0 : since;
                    }

                    return reportCase.stage === stage && reportCase.caseType === CaseType.Live ? (
                      <TableCell key={stage} align={'center'}>
                        <Badge badgeContent={daysInStage} color="primary">
                          <Box
                            className={`${styles.root} ${getCaseStageStatusStyle(
                              daysUntilDueDate,
                            )}`}
                            style={{ cursor: 'pointer' }}
                          >
                            <Typography variant={'button'}>{reportCase.number}</Typography>
                          </Box>
                        </Badge>
                      </TableCell>
                    ) : (
                      <TableCell key={stage} align={'center'} />
                    );
                  })}
                  <TableCell align={'center'}>
                    {reportCase.surgeryDate
                      ? format.formatISODate(reportCase.surgeryDate as unknown as string)
                      : 'TBD'}
                  </TableCell>
                </TableRow>
              ) : null;
            })}
          </TableBody>
        }
        loading={loading}
      />
    </DashboardCardView>
  );
};

export default CaseDaysInStage;
