import React, { Dispatch } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Chip, TableBody, TableRow, Typography } from '@mui/material';
import { format, ICase, ITableHeader, UserRoleType } from '@workflow-nx/common';
import { TableCell } from '../../../components/TableCell';
import { CommonTable } from '../../../components/CommonTable';
import { ListCaseTriageActionType } from './ListCaseTriage.reducer';
import { DaysToManufacturingView } from './DaysToManufacturingView';
import { TasksPopoverView } from './TasksPopoverView';
import { CommentsPopoverView } from './CommentsPopoverView';

type CaseListTableProps = {
  cases: ICase[];
  orderBy?: { [key: string]: 'asc' | 'desc' };
  dispatch?: Dispatch<ListCaseTriageActionType>;
  shouldHighlight?: (activeCase: ICase) => boolean;
  loading?: boolean;
};

const CaseTriageListTable = ({ cases, orderBy, dispatch, loading }: CaseListTableProps) => {
  const navigate = useNavigate();
  const headers: ITableHeader[] = [
    {
      id: 'number',
      sortable: true,
      label: 'Case #',
    },
    {
      id: 'daysToMfgCloseWindow',
      label: 'Days to Mfg. CW',
    },
    {
      id: 'surgeryDate',
      sortable: true,
      label: 'Surgery Date',
    },
    {
      id: 'comments',
      sortable: false,
      label: 'Discussion',
    },
    {
      id: 'tasks',
      sortable: false,
      label: 'Open Tasks',
    },
    {
      id: 'stageSortOrder',
      sortable: true,
      label: 'Stage',
    },
    {
      id: 'surgeonId',
      sortable: true,
      label: 'Surgeon',
    },
    {
      id: 'assignedId',
      sortable: true,
      label: 'Assigned',
    },
    {
      id: 'fieldRepId',
      sortable: true,
      label: `${format.formatUserRole(UserRoleType.FieldRep)}`,
    },
  ];

  return (
    <CommonTable
      orderBy={orderBy}
      headers={headers}
      onHeaderClick={(orderBy) => {
        if (dispatch) {
          dispatch({ type: 'ORDER_BY_CHANGED', data: orderBy });
        }
      }}
      rows={
        <TableBody>
          {cases?.map((activeCase: ICase) => {
            return (
              <TableRow
                hover
                key={activeCase.caseId}
                onClick={() => navigate(`/app/cases/${activeCase.caseId}`)}
              >
                <TableCell>
                  <Box display={'flex'} alignItems={'center'}>
                    {activeCase.number}
                  </Box>
                </TableCell>
                <TableCell align={'center'}>
                  <DaysToManufacturingView
                    activeCase={activeCase}
                    manufacturingCloseWindowInDays={28}
                  />
                </TableCell>
                <TableCell align={'center'}>
                  <Box display={'flex'} alignItems={'center'}>
                    <Typography variant={'body1'}>
                      {activeCase.surgeryDate ? (
                        format.formatDate(activeCase.surgeryDate)
                      ) : (
                        <>&mdash;</>
                      )}
                    </Typography>
                    {activeCase?.isSurgeryDateTentative ? (
                      <Box ml={1}>
                        <Chip size={'small'} color={'secondary'} label={'T'} />
                      </Box>
                    ) : null}
                  </Box>
                </TableCell>
                <TableCell align={'center'}>
                  <CommentsPopoverView caseId={activeCase.caseId} comments={activeCase.comments} />
                </TableCell>
                <TableCell align={'center'}>
                  <TasksPopoverView
                    caseId={activeCase.caseId}
                    tasks={activeCase.tasks}
                    loading={loading}
                  />
                </TableCell>
                <TableCell>{format.formatCaseStage(activeCase.stage)}</TableCell>
                <TableCell>
                  {activeCase.surgeonUser ? (
                    <Typography noWrap={true}>
                      {format.formatName(activeCase.surgeonUser)}
                    </Typography>
                  ) : (
                    <span>&mdash;</span>
                  )}
                  {activeCase?.surgeonUser?.organization ? (
                    <Typography noWrap={true} color={'textSecondary'} variant={'button'}>
                      {activeCase?.surgeonUser?.organization.name}
                    </Typography>
                  ) : null}
                </TableCell>
                <TableCell>
                  {activeCase.assignedUser ? (
                    <Typography noWrap={true}>
                      {format.formatName(activeCase.assignedUser)}
                    </Typography>
                  ) : (
                    <span>&mdash;</span>
                  )}
                </TableCell>
                <TableCell>
                  {activeCase.fieldRepUser ? (
                    <Typography noWrap={true}>
                      {format.formatName(activeCase.fieldRepUser)}
                    </Typography>
                  ) : (
                    <span>&mdash;</span>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      }
      loading={loading}
    />
  );
};

export default CaseTriageListTable;
