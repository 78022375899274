import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material';
import { jssPreset, StylesProvider } from '@mui/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import GlobalStyles from './components/GlobalStyles';
import ScrollReset from './components/ScrollReset';
import AuthContext from './contexts/AuthContext';
import { createTheme } from './theme';
import routes, { renderRoutes } from './routes';
import { ConfirmProvider } from 'material-ui-confirm';
import { NotificationsProvider } from './contexts/NotificationsContext';
import { AnalyticsProvider } from './contexts/AnalyticsContext';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { Info, Settings } from 'luxon';

Settings.defaultWeekSettings = {
  firstDay: 7, // Sunday
  minimalDays: Info.getMinimumDaysInFirstWeek(),
  weekend: Info.getWeekendWeekdays(),
};

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const App = () => {
  const theme = createTheme({});

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <StylesProvider jss={jss}>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <SnackbarProvider dense maxSnack={3}>
              {/* @ts-ignore */}
              <ConfirmProvider>
                <BrowserRouter>
                  <AuthContext.AuthProvider>
                    <AnalyticsProvider>
                      <NotificationsProvider>
                        <GlobalStyles />
                        <ScrollReset />
                        {renderRoutes(routes)}
                      </NotificationsProvider>
                    </AnalyticsProvider>
                  </AuthContext.AuthProvider>
                </BrowserRouter>
              </ConfirmProvider>
            </SnackbarProvider>
          </LocalizationProvider>
        </StylesProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
