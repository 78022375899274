import React, { useState } from 'react';
import { faCircleNotch, IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons';

export function OptionsMenuButton(props: {
  disabled?: boolean;
  loading?: boolean;
  options: { key: string; icon: IconDefinition; label: string; disabled?: boolean }[];
  onSelect: (option: string) => Promise<void>;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [loading, setLoading] = useState(props.loading);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (key: string) => {
    setLoading(true);
    setAnchorEl(null);
    props.onSelect(key).finally(() => setLoading(false));
  };

  return (
    <>
      <IconButton disabled={props.disabled || loading} onClick={handleClick}>
        {loading ? (
          <FontAwesomeIcon icon={faCircleNotch} fixedWidth={true} spin={true} size={'sm'} />
        ) : (
          <FontAwesomeIcon icon={faEllipsisV} fixedWidth={true} size={'sm'} />
        )}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={(key: 'CLOUD_DESIGN_ALL' | 'VIEW' | 'DOWNLOAD') => {
          setAnchorEl(null);
          setLoading(false);
          handleClose(key);
        }}
      >
        {props.options.map((option) => (
          <MenuItem
            key={option.key}
            onClick={() => handleClose(option.key)}
            disabled={option.disabled}
          >
            <Box display={'flex'} alignItems={'center'}>
              <FontAwesomeIcon icon={option.icon} />
              <Box mx={0.5} />
              <Typography>{option.label}</Typography>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
