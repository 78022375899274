import { IDicomMetadata } from '@workflow-nx/common';
import { Grid, Typography } from '@mui/material';
import React from 'react';
import { format } from '@workflow-nx/common';
import { date } from '@workflow-nx/utils';
import Box from '@mui/material/Box';
import { faCircleExclamation } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from '@mui/styles';

export function DicomMetadataView(props: { dicomMetadata: IDicomMetadata }) {
  const theme = useTheme();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant={'body1'} noWrap={true}>
          <strong>Name</strong>
          <Box pl={1} component={'span'}>
            {props.dicomMetadata.firstName} {props.dicomMetadata.lastName}
          </Box>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={'body1'} noWrap={true}>
          <strong>Gender</strong>
          <Box pl={1} component={'span'}>
            {props.dicomMetadata.gender || <span>&mdash;</span>}
          </Box>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={'body1'} noWrap={true}>
          <strong>MRN</strong>
          <Box pl={1} component={'span'}>
            {props.dicomMetadata.mrn || <span>&mdash;</span>}
          </Box>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={'body1'} noWrap={true}>
          <strong>Birth Date</strong>
          <Box pl={1} component={'span'}>
            {props.dicomMetadata.birthDate ? (
              <>
                {date.distanceInYears(props.dicomMetadata.birthDate)} years old (
                {format.formatISODate(props.dicomMetadata.birthDate as string)})
              </>
            ) : (
              <span>&mdash;</span>
            )}
          </Box>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant={'body1'} noWrap={true}>
          <strong>Study Date</strong>
          <Box pl={1} component={'span'}>
            {props?.dicomMetadata?.hasStudyDateError ? (
              <FontAwesomeIcon icon={faCircleExclamation} color={theme.palette.error.main} />
            ) : null}
            <Box component={'span'} pr={1} />
            {props.dicomMetadata.studyDate ? (
              <>
                {date.distanceInMonths(props.dicomMetadata.studyDate)} months old (
                {format.formatISODate(props.dicomMetadata.studyDate)})
              </>
            ) : (
              <span>&mdash;</span>
            )}
          </Box>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box component={'span'} display={'flex'} alignItems={'center'}>
          <Typography variant={'body1'} noWrap={true}>
            <strong>Slick Thickness (Axial)</strong>
            <Box pl={1} component={'span'}>
              {props?.dicomMetadata?.hasSliceThicknessError ? (
                <FontAwesomeIcon icon={faCircleExclamation} color={theme.palette.error.main} />
              ) : null}
              <Box component={'span'} pr={1} />
              {props.dicomMetadata.sliceThickness ? (
                `${Number(props.dicomMetadata.sliceThickness).toFixed(2)}mm`
              ) : (
                <span>&mdash;</span>
              )}
            </Box>
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={'body1'} noWrap={true}>
          <strong>Institution Name</strong>
          <Box pl={1} component={'span'}>
            {props.dicomMetadata.institutionName}
          </Box>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={'body1'} noWrap={true}>
          <strong>Referring Physician</strong>
          <Box pl={1} component={'span'}>
            {props.dicomMetadata.referringPhysician}
          </Box>
        </Typography>
      </Grid>
    </Grid>
  );
}
