import { AssetType, IAsset } from '@workflow-nx/common';
import { useState } from 'react';
import { file as fileUtils } from '@workflow-nx/utils';
import { useMutation } from '@apollo/client';
import { CREATE_ASSET, DELETE_ASSET } from '../gql';
import { AxiosError } from 'axios';

const useCreateAndUploadAsset = (): {
  createAndUploadAsset: (
    file: File,
    assetType: AssetType | undefined,
    caseId?: number,
    planId?: number,
    metaData?: any,
    sendNotification?: boolean,
  ) => Promise<{ createdAsset: { asset: IAsset; signedUrl: string } | null; error: string | null }>;
  uploadProgress: number;
} => {
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [createAsset] = useMutation(CREATE_ASSET);
  const [deleteAsset] = useMutation(DELETE_ASSET);

  const createAndUploadAsset = async (
    file: File,
    assetType: AssetType | undefined,
    caseId?: number,
    planId?: number,
    metaData?: any,
    sendNotification?: boolean,
  ): Promise<{
    createdAsset: { asset: IAsset; signedUrl: string } | null;
    error: string | null;
  }> => {
    let createdAsset: { asset: IAsset; signedUrl: string } | null = null;
    let errorMessage: string | null = null;

    if (file) {
      try {
        const { data: assetData } = await createAsset({
          variables: {
            caseId: caseId ?? undefined,
            planId: planId ?? undefined,
            fileName: file.name,
            size: file.size,
            assetType: assetType,
            metadata: metaData ?? undefined,
            sendNotification: sendNotification ?? undefined,
          },
        });

        createdAsset = assetData?.createAsset;

        if (createdAsset?.signedUrl) {
          await fileUtils.uploadFile(createdAsset.signedUrl, file, (percentComplete) => {
            setUploadProgress(percentComplete);
          });
        }
      } catch (e) {
        if (createdAsset) {
          await deleteAsset({
            variables: {
              assetId: createdAsset?.asset?.assetId,
            },
          });
        }
        console.error(e);
        errorMessage = (e as AxiosError)?.message;
      }
    }
    return { createdAsset, error: errorMessage };
  };

  return { createAndUploadAsset, uploadProgress };
};

export default useCreateAndUploadAsset;
