import { JSONEditor, JSONEditorPropsOptional } from 'vanilla-jsoneditor';
import { useEffect, useRef } from 'react';
import { useTheme, colors } from '@mui/material';
import { makeStyles } from '@mui/styles';

export function VanillaJSONEditor(props: JSONEditorPropsOptional) {
  const refContainer = useRef(null as any as HTMLDivElement);
  const refEditor = useRef(null as null | JSONEditor);
  const theme = useTheme();

  const useStyles = makeStyles(() => ({
    override: {
      '--jse-theme-color': `${theme.palette.secondary.main}`,
      '--jse-message-warning-background': `${colors.yellow[500]}`,
      '--jse-message-error-background': `${colors.red[500]}`,
      '--jse-menu-button-size': '48px',
      '--jse-padding': '10px',
      '--jse-theme-color-highlight': `${theme.palette.secondary.light}`,
      '--jse-context-menu-background': `var(--jse-theme-color)`,
      '--jse-context-menu-color-disabled': `${theme.palette.secondary.light}`,
      '--jse-context-menu-background-highlight': `var(--jse-theme-color-highlight)`,
      '--jse-context-menu-separator-color': `${theme.palette.secondary.contrastText}`,
    },
  }));
  const styles = useStyles();

  useEffect(() => {
    // create editor
    refEditor.current = new JSONEditor({
      target: refContainer.current,
      props: {},
    });

    return () => {
      // destroy editor
      if (refEditor.current) {
        refEditor.current.destroy();
        refEditor.current = null;
      }
    };
  }, []);

  // update props
  useEffect(() => {
    if (refEditor.current) {
      refEditor.current.updateProps(props as any);
    }
  }, [props]);

  return (
    <div
      className={styles.override}
      ref={refContainer}
      style={{
        display: 'flex',
        flex: 1,
        height: 600,
      }}
    ></div>
  );
}
