import * as BABYLON from 'babylonjs';

interface IOrientationControlOptions {
  meshScaling?: number;
  lightPosition?: BABYLON.Vector3;
  showAxis?: boolean;
}

export class OrientationControl {
  private engine: BABYLON.Engine;
  private sceneGizmo: BABYLON.Scene;
  private cameraGizmo: BABYLON.ArcRotateCamera;
  private lightGizmo: BABYLON.HemisphericLight;
  private boxGizmo: BABYLON.Mesh;
  private groundGizmo: BABYLON.Mesh;

  constructor(
    camera: BABYLON.ArcRotateCamera,
    options: IOrientationControlOptions,
    scene: BABYLON.Scene,
  ) {
    this.engine = scene.getEngine();

    this.sceneGizmo = new BABYLON.Scene(this.engine);
    this.sceneGizmo.autoClear = false;

    this.cameraGizmo = new BABYLON.ArcRotateCamera(
      'cameraGizmo',
      camera.alpha,
      camera.beta,
      options.meshScaling ? (3 * options.meshScaling) / 4 : 6,
      BABYLON.Vector3.Zero(),
      this.sceneGizmo,
    );
    this.cameraGizmo.viewport = new BABYLON.Viewport(0.77, -0.025, 0.3, 0.3);

    this.lightGizmo = new BABYLON.HemisphericLight(
      'lightGizmo',
      options.lightPosition ?? new BABYLON.Vector3(0, 1, 0),
      this.sceneGizmo,
    );
    this.lightGizmo.groundColor = BABYLON.Color3.White();

    const faceColumns = 6;
    const faceRows = 1;
    const faceColors = new Array(6);
    const faceUV = new Array(6);
    for (var i = 0; i < faceUV.length; i++) {
      faceUV[i] = new BABYLON.Vector4(i / faceColumns, 0, (i + 1) / faceColumns, 1 / faceRows);
    }

    const boxOption = {
      size: options.meshScaling ? (5 * options.meshScaling) / 8 : 1.5,
      faceColors: faceColors,
      faceUV: faceUV,
      wrap: true,
    };

    this.boxGizmo = BABYLON.MeshBuilder.CreateBox('', boxOption, this.sceneGizmo);
    const boxMaterial = new BABYLON.StandardMaterial('material', this.sceneGizmo);

    const faceTextureWidth = 1024;
    const faceTextureHeight = 128;
    const faceTexture = new BABYLON.DynamicTexture(
      'faceTexture',
      {
        width: faceTextureWidth,
        height: faceTextureHeight,
      },
      this.sceneGizmo,
    );
    faceTexture.hasAlpha = true;

    const faceContext = faceTexture.getContext();
    boxMaterial.backFaceCulling = false;
    boxMaterial.diffuseTexture = faceTexture;
    boxMaterial.specularColor = new BABYLON.Color3(0, 0, 0);

    this.boxGizmo.material = boxMaterial;

    const faceTexts = ['Y+', 'Y-', 'X+', 'X-', 'Z+', 'Z-'];
    const font = 'bold 44px Arial';
    const colors = ['blue', 'blue', 'red', 'red', 'green', 'green'];

    const columnSize = faceTextureWidth / faceColumns;
    faceContext.font = font;
    for (let i = 0; i < faceUV.length; i++) {
      const measure = faceContext.measureText(faceTexts[i]);
      faceTexture.drawText(
        faceTexts[i],
        columnSize * (i + 0.5) - measure.width / 2,
        null,
        font,
        colors[i],
        null,
      );
    }
    faceTexture.update();

    this.groundGizmo = BABYLON.MeshBuilder.CreateGround(
      'ground',
      {
        width: options.meshScaling ?? 4,
        height: options.meshScaling ?? 4,
      },
      this.sceneGizmo,
    );
    this.groundGizmo.parent = this.boxGizmo;
    this.groundGizmo.position.y = (-1.1 * boxOption.size) / 2;
    const groundGizmoMaterial = new BABYLON.StandardMaterial('groundMaterial', this.sceneGizmo);
    groundGizmoMaterial.backFaceCulling = true;
    const groundTextureSize = 256;

    groundGizmoMaterial.diffuseTexture = new BABYLON.DynamicTexture(
      'groundTexture',
      {
        width: groundTextureSize,
        height: groundTextureSize,
      },
      this.sceneGizmo,
    );
    groundGizmoMaterial.diffuseTexture.hasAlpha = true;
    this.groundGizmo.material = groundGizmoMaterial;
    this.groundGizmo.isPickable = false;

    this.createAxesGizmo();

    camera.onViewMatrixChangedObservable.add(() => {
      this.cameraGizmo.alpha = camera.alpha;
      this.cameraGizmo.beta = camera.beta;
    });

    scene.onReadyObservable.add(() => {
      this.engine.runRenderLoop(() => {
        this.sceneGizmo.render();
      });
    });
  }

  private createAxesGizmo() {
    const createAxisLine = (
      start: BABYLON.Vector3,
      end: BABYLON.Vector3,
      color: BABYLON.Color3,
    ) => {
      const path = [start, end];
      const tube = BABYLON.MeshBuilder.CreateTube(
        'tube',
        {
          path: path,
          radius: 0.05 / 2,
          tessellation: 8,
          updatable: false,
        },
        this.sceneGizmo,
      );

      // Set the color of the tube
      const material = new BABYLON.StandardMaterial('lineMaterial', this.sceneGizmo);
      material.diffuseColor = color;
      tube.material = material;

      return tube;
    };

    const createArrow = (
      position: BABYLON.Vector3,
      direction: BABYLON.Vector3,
      color: BABYLON.Color3,
    ) => {
      const arrowLength = 0.25;
      const arrowBaseRadius = 0.2;

      const arrow = BABYLON.MeshBuilder.CreateCylinder(
        'arrow',
        {
          height: arrowLength,
          diameterTop: 0,
          diameterBottom: arrowBaseRadius,
          tessellation: 10,
        },
        this.sceneGizmo,
      );

      // Position the arrow at the end of the line
      arrow.position = position;

      // Align the arrow with the direction of the axis
      const axisRotation = BABYLON.Vector3.Cross(BABYLON.Vector3.Up(), direction);
      const angle = BABYLON.Vector3.Dot(BABYLON.Vector3.Up(), direction);
      arrow.rotationQuaternion = BABYLON.Quaternion.RotationAxis(axisRotation, Math.acos(angle));

      // Set the color of the arrow
      const material = new BABYLON.StandardMaterial('arrowMaterial', this.sceneGizmo);
      material.diffuseColor = color;
      arrow.material = material;

      return arrow;
    };

    const axisLength = 1.25; // Length of each axis

    createAxisLine(
      new BABYLON.Vector3(-axisLength, 0, 0),
      new BABYLON.Vector3(axisLength, 0, 0),
      BABYLON.Color3.Red(),
    );
    createArrow(
      new BABYLON.Vector3(axisLength, 0, 0),
      new BABYLON.Vector3(1, 0, 0),
      BABYLON.Color3.Red(),
    );
    createArrow(
      new BABYLON.Vector3(-axisLength, 0, 0),
      new BABYLON.Vector3(-1, 0, 0),
      BABYLON.Color3.Red(),
    );

    // Y-axis (green)
    createAxisLine(
      new BABYLON.Vector3(0, -axisLength, 0),
      new BABYLON.Vector3(0, axisLength, 0),
      BABYLON.Color3.Green(),
    );
    createArrow(
      new BABYLON.Vector3(0, axisLength, 0),
      new BABYLON.Vector3(0, -1, 0),
      BABYLON.Color3.Green(),
    );
    createArrow(
      new BABYLON.Vector3(0, -axisLength, 0),
      new BABYLON.Vector3(-1, -1, -1),
      BABYLON.Color3.Green(),
    );

    // Z-axis (blue)
    createAxisLine(
      new BABYLON.Vector3(0, 0, -axisLength),
      new BABYLON.Vector3(0, 0, axisLength),
      BABYLON.Color3.Blue(),
    );
    createArrow(
      new BABYLON.Vector3(0, 0, axisLength),
      new BABYLON.Vector3(0, 0, 1),
      BABYLON.Color3.Blue(),
    );
    createArrow(
      new BABYLON.Vector3(0, 0, -axisLength),
      new BABYLON.Vector3(0, 0, -1),
      BABYLON.Color3.Blue(),
    );
  }
}
