import { useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery } from '@apollo/client';
import { FIND_ORGANIZATIONS, UPSERT_SETTINGS } from '../../gql';
import { FieldArray, Formik, FormikHelpers, FormikValues } from 'formik';
import {
  Box,
  Divider,
  Grid,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Button, Calendar, IconFontButton } from '@workflow-nx/ui';
import { faChevronCircleDown, faChevronCircleUp } from '@fortawesome/pro-solid-svg-icons';
import * as Yup from 'yup';
import { faPlus, faTimes } from '@fortawesome/pro-light-svg-icons';
import { TextField as MuiTextField } from '@mui/material';
import DatePickerValue from '../../components/DatePickerValue';
import { DateTime } from 'luxon';
import {
  DowntimeDateRange,
  IOrganization,
  OrganizationType,
  ProductionStageEstimate,
  PRODUCTION_STAGE_ORDER,
  ProductionStageType,
  caseUtils,
} from '@workflow-nx/common';
import { Select } from '../../components/Select';
import _ from 'lodash';
import DowntimeEventView from './DowntimeEventView';

const DEFAULT_BOX_COLOR = '#89CFF0';

function getRandomHexColor(excludeColors: string[]): string {
  const generatedColor = '#000000'.replace(/0/g, function () {
    return (~~(Math.random() * 16)).toString(16);
  });

  if (excludeColors?.includes(generatedColor)) {
    return getRandomHexColor(excludeColors);
  } else {
    return generatedColor;
  }
}

const useStyles = makeStyles(() => ({
  tableCell: {
    border: '1px solid rgba(224, 224, 224, 1)',
  },
  legendBox: {
    width: 15,
    height: 15,
    borderRadius: 3,
    marginRight: 12,
    border: '1px solid grey',
  },
}));

export function NetworkDaysForm(props: {
  networkDays: {
    holidays: DowntimeDateRange[];
    outages: DowntimeDateRange[];
    stageVendorMapping: Partial<Record<ProductionStageType, number[]>>;
    stages: ProductionStageEstimate[];
  };
  onSave: () => void;
}) {
  const classes = useStyles();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [upsertSettings] = useMutation(UPSERT_SETTINGS);
  const {
    data: vendorData,
    loading: vendorDataLoading,
    error: vendorDataError,
  } = useQuery<{ organizations: { organizations: IOrganization[]; count: number } }>(
    FIND_ORGANIZATIONS,
    {
      variables: {
        organizationTypeFilter: [OrganizationType.Manufacturer],
      },
      onError: () => {
        enqueueSnackbar('An error occurred while fetching vendors.', { variant: 'error' });
      },
      onCompleted: (data) => {
        setVendorColorMapping(() =>
          (data?.organizations?.organizations as IOrganization[]).reduce(
            (prev, currVendor) => ({
              ...prev,
              [currVendor.organizationId]: getRandomHexColor([DEFAULT_BOX_COLOR, '#ffffff']),
            }),
            {},
          ),
        );
      },
    },
  );
  const [open, setOpen] = useState(false);
  const [holidayFormValue, setHolidayFormValue] = useState<Partial<DowntimeDateRange>>({});
  const [outageFormValue, setOutageFormValue] = useState<Partial<DowntimeDateRange>>({});
  const [vendorColorMapping, setVendorColorMapping] = useState<Record<string, string>>({});

  const [stageEstimateFormValue, setStageEstimateFormValue] = useState<
    Record<
      string,
      {
        days: number;
        stage: string;
        subStage: string;
        vendorId: number | null;
      }
    >
  >({});

  const handleSubmitForm = async (
    values: FormikValues,
    { setStatus, setSubmitting }: FormikHelpers<any>,
  ) => {
    try {
      await upsertSettings({
        variables: {
          networkDays: values,
        },
      });

      props.onSave();

      setOpen(false);
      setStatus({ success: true });
      enqueueSnackbar('Network days updated', {
        variant: 'success',
      });
    } catch (err) {
      setStatus({ success: false });
      enqueueSnackbar('An error occurred updating the network days', {
        variant: 'error',
      });
    } finally {
      setSubmitting(false);
    }
  };

  const vendorNameMap = useMemo(
    () => _.keyBy(vendorData?.organizations?.organizations, 'organizationId'),
    [vendorData],
  );

  return (
    <>
      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        <Typography variant={'h4'}>Configure Production Calendar</Typography>
        <Box>
          <IconFontButton
            icon={open ? faChevronCircleUp : faChevronCircleDown}
            onClick={() => setOpen(!open)}
          />
        </Box>
      </Box>
      {open ? (
        <Box m={1}>
          <Formik
            initialValues={props.networkDays}
            validationSchema={Yup.object().shape({
              holidays: Yup.array().of(
                Yup.object().shape({
                  startDate: Yup.date().required(),
                  endDate: Yup.date().required(),
                  description: Yup.string().required(),
                  vendorId: Yup.number().nullable(),
                }),
              ),
              outages: Yup.array().of(
                Yup.object().shape({
                  startDate: Yup.date().required(),
                  endDate: Yup.date().required(),
                  description: Yup.string().required(),
                  vendorId: Yup.number().required(),
                }),
              ),
              stages: Yup.array().of(
                Yup.object().shape({
                  order: Yup.number().required(),
                  days: Yup.number().required(),
                  stage: Yup.string().required(),
                  subStage: Yup.string().nullable(),
                  vendorId: Yup.number().nullable(),
                }),
              ),
            })}
            validateOnChange={false}
            validate={(values) => {
              if (
                !PRODUCTION_STAGE_ORDER.every((stageOrderInfo) =>
                  (values.stages || []).find(
                    (stageInfo) => stageOrderInfo.order === stageInfo.order,
                  ),
                )
              ) {
                return {
                  stages: PRODUCTION_STAGE_ORDER.filter(
                    (stageOrderInfo) =>
                      !(values.stages || []).find(
                        (stageInfo) => stageOrderInfo.order === stageInfo.order,
                      ),
                  ).map((stageOrderInfo) => stageOrderInfo.order),
                };
              }
              return;
            }}
            onSubmit={handleSubmitForm}
            enableReinitialize={true}
          >
            {({ values, submitForm, errors }) => {
              const allEvents = (values.holidays || [])
                .concat(values.outages || [])
                .filter((e) => {
                  const vendorName = e.vendorId
                    ? vendorNameMap[e.vendorId]?.name || 'Unknown'
                    : undefined;

                  return vendorName === null;
                })
                .map((e) => {
                  const vendorName = e.vendorId
                    ? vendorNameMap[e.vendorId]?.name || 'Unknown'
                    : undefined;

                  const bgColor = e.vendorId ? vendorColorMapping[e.vendorId] : DEFAULT_BOX_COLOR;

                  return {
                    title: `${e.vendorId ? `${vendorName} - ` : ''}${e.description}`,
                    start: DateTime.fromISO(e.startDate).startOf('day').toJSDate(),
                    end: DateTime.fromISO(e.endDate).endOf('day').toJSDate(),
                    backgroundColor: bgColor,
                    textColor: theme.palette.getContrastText(bgColor),
                  };
                });

              return (
                <Box>
                  <Box my={5}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} lg={8}>
                        <Calendar
                          events={allEvents}
                          eventContent={(eventInfo) => {
                            const { title } = eventInfo.event;
                            return <DowntimeEventView title={title} />;
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <Box mt={1} mb={2}>
                          <Typography variant={'h4'}>Legend</Typography>
                        </Box>
                        <Box display="flex" alignItems={'center'} mb={1}>
                          <Box className={classes.legendBox} bgcolor={DEFAULT_BOX_COLOR} />

                          <Typography>Holidays</Typography>
                        </Box>
                        {Object.entries(vendorColorMapping).map(([vendorId, vendorColor]) => {
                          const vendorName = vendorNameMap[vendorId]?.name || 'Unknown';
                          return (
                            <Box display="flex" alignItems={'center'} mb={1}>
                              <Box className={classes.legendBox} bgcolor={vendorColor} />

                              <Typography>{vendorName}</Typography>
                            </Box>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </Box>
                  <Divider />

                  {/* Holidays */}
                  <Box my={5}>
                    <Box mb={3}>
                      <Typography variant={'h4'}>Holidays</Typography>
                    </Box>
                    <FieldArray name="holidays">
                      {(arrayHelpers) => {
                        return (
                          <TableContainer component={Paper}>
                            <Table size="small" style={{ overflow: 'hidden' }}>
                              <TableHead>
                                <TableRow>
                                  <TableCell>Name</TableCell>
                                  <TableCell align="left">Start Date</TableCell>
                                  <TableCell align="left">End Date</TableCell>
                                  <TableCell align="left"></TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    className={classes.tableCell}
                                  >
                                    <MuiTextField
                                      variant="standard"
                                      size="small"
                                      fullWidth
                                      InputLabelProps={{ shrink: true }}
                                      onChange={(e) =>
                                        setHolidayFormValue((prev) => ({
                                          ...prev,
                                          description: e.target.value,
                                        }))
                                      }
                                      value={holidayFormValue.description || ''}
                                    />
                                  </TableCell>
                                  <TableCell align="left" className={classes.tableCell}>
                                    <DatePickerValue
                                      shrink
                                      variant="standard"
                                      onChange={(value) =>
                                        setHolidayFormValue((prev) => ({
                                          ...prev,
                                          startDate: value,
                                        }))
                                      }
                                      value={
                                        holidayFormValue.startDate
                                          ? DateTime.fromISO(holidayFormValue.startDate).toJSDate()
                                          : null
                                      }
                                    />
                                  </TableCell>
                                  <TableCell align="left" className={classes.tableCell}>
                                    <DatePickerValue
                                      shrink
                                      variant="standard"
                                      onChange={(value) =>
                                        setHolidayFormValue((prev) => ({
                                          ...prev,
                                          endDate: value,
                                        }))
                                      }
                                      value={
                                        holidayFormValue.endDate
                                          ? DateTime.fromISO(holidayFormValue.endDate).toJSDate()
                                          : null
                                      }
                                    />
                                  </TableCell>

                                  <TableCell align="center" className={classes.tableCell}>
                                    <IconFontButton
                                      icon={faPlus}
                                      disabled={
                                        !holidayFormValue.description ||
                                        !holidayFormValue.startDate ||
                                        !holidayFormValue.endDate
                                      }
                                      onClick={() => {
                                        if (
                                          holidayFormValue.startDate &&
                                          holidayFormValue.endDate
                                        ) {
                                          arrayHelpers.push({
                                            startDate: DateTime.fromISO(holidayFormValue.startDate)
                                              .startOf('day')
                                              .toISO(),
                                            endDate: DateTime.fromISO(holidayFormValue.endDate)
                                              .endOf('day')
                                              .toISO(),
                                            description: holidayFormValue.description,
                                            vendorId: null,
                                          });
                                          setHolidayFormValue({});
                                        }
                                      }}
                                    />
                                  </TableCell>
                                </TableRow>
                                {(values.holidays || []).map((outage, index) => {
                                  const holidayStart = DateTime.fromJSDate(
                                    new Date(outage.startDate),
                                  );

                                  const holidayEnd = DateTime.fromJSDate(new Date(outage.endDate));

                                  return (
                                    <TableRow key={`outage-${index}`}>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        className={classes.tableCell}
                                      >
                                        {outage.description}
                                      </TableCell>
                                      <TableCell align="left" className={classes.tableCell}>
                                        {holidayStart.toFormat('yyyy/L/d')}
                                      </TableCell>
                                      <TableCell align="left" className={classes.tableCell}>
                                        {holidayEnd.toFormat('yyyy/L/d')}
                                      </TableCell>

                                      <TableCell align="center" className={classes.tableCell}>
                                        <IconFontButton
                                          icon={faTimes}
                                          onClick={() => arrayHelpers.remove(index)}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        );
                      }}
                    </FieldArray>
                  </Box>
                  <Divider />
                  {/* Outages */}
                  <Box my={5}>
                    <Box mb={3}>
                      <Typography variant={'h4'}>Outages</Typography>
                    </Box>
                    <FieldArray name="outages">
                      {(arrayHelpers) => {
                        return (
                          <TableContainer component={Paper}>
                            <Table size="small" style={{ overflow: 'hidden' }}>
                              <TableHead>
                                <TableRow>
                                  <TableCell>Vendor</TableCell>
                                  <TableCell align="left">Start Date</TableCell>
                                  <TableCell align="left">End Date</TableCell>
                                  <TableCell align="left">Description</TableCell>
                                  <TableCell align="left"></TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    className={classes.tableCell}
                                  >
                                    <Box width={150}>
                                      <Select
                                        name={''}
                                        variant="standard"
                                        onChange={(event) => {
                                          const selectedVendorId = event.target.value;
                                          setOutageFormValue((prev) => ({
                                            ...prev,
                                            vendorId:
                                              selectedVendorId !== ''
                                                ? parseInt(selectedVendorId)
                                                : null,
                                          }));
                                        }}
                                        value={outageFormValue.vendorId?.toString() || ''}
                                        menuItems={(
                                          vendorData?.organizations?.organizations || []
                                        ).map((v) => ({
                                          key: v.organizationId.toString(),
                                          value: v.name,
                                        }))}
                                        disabled={Boolean(vendorDataError || vendorDataLoading)}
                                        fullWidth={true}
                                      />
                                    </Box>
                                  </TableCell>

                                  <TableCell align="left" className={classes.tableCell}>
                                    <DatePickerValue
                                      shrink
                                      variant="standard"
                                      onChange={(value) =>
                                        setOutageFormValue((prev) => ({
                                          ...prev,
                                          startDate: value,
                                        }))
                                      }
                                      value={
                                        outageFormValue.startDate
                                          ? DateTime.fromISO(outageFormValue.startDate).toJSDate()
                                          : null
                                      }
                                    />
                                  </TableCell>
                                  <TableCell align="left" className={classes.tableCell}>
                                    <DatePickerValue
                                      shrink
                                      variant="standard"
                                      onChange={(value) =>
                                        setOutageFormValue((prev) => ({
                                          ...prev,
                                          endDate: value,
                                        }))
                                      }
                                      value={
                                        outageFormValue.endDate
                                          ? DateTime.fromISO(outageFormValue.endDate).toJSDate()
                                          : null
                                      }
                                    />
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    className={classes.tableCell}
                                  >
                                    <MuiTextField
                                      variant="standard"
                                      size="small"
                                      fullWidth
                                      InputLabelProps={{ shrink: true }}
                                      onChange={(e) =>
                                        setOutageFormValue((prev) => ({
                                          ...prev,
                                          description: e.target.value,
                                        }))
                                      }
                                      value={outageFormValue.description || ''}
                                    />
                                  </TableCell>
                                  <TableCell align="center" className={classes.tableCell}>
                                    <IconFontButton
                                      icon={faPlus}
                                      disabled={
                                        !outageFormValue.description ||
                                        !outageFormValue.startDate ||
                                        !outageFormValue.endDate ||
                                        !outageFormValue.vendorId ||
                                        Boolean(vendorDataError || vendorDataLoading)
                                      }
                                      onClick={() => {
                                        if (
                                          outageFormValue.startDate &&
                                          outageFormValue.endDate &&
                                          outageFormValue.vendorId &&
                                          outageFormValue.description
                                        ) {
                                          arrayHelpers.push({
                                            startDate: DateTime.fromISO(outageFormValue.startDate)
                                              .startOf('day')
                                              .toISO(),
                                            endDate: DateTime.fromISO(outageFormValue.endDate)
                                              .endOf('day')
                                              .toISO(),
                                            description: outageFormValue.description,
                                            vendorId: outageFormValue.vendorId,
                                          });
                                          setOutageFormValue({});
                                        }
                                      }}
                                    />
                                  </TableCell>
                                </TableRow>
                                {(values.outages || []).map((outage, index) => {
                                  const outageStart = DateTime.fromJSDate(
                                    new Date(outage.startDate),
                                  );

                                  const outageEnd = DateTime.fromJSDate(new Date(outage.endDate));

                                  const outageVendorName =
                                    (vendorData?.organizations?.organizations || []).find(
                                      (v) => v.organizationId === outage.vendorId,
                                    )?.name || 'Unknown';

                                  return (
                                    <TableRow key={`outage-${index}`}>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        className={classes.tableCell}
                                      >
                                        {outageVendorName}
                                      </TableCell>
                                      <TableCell align="left" className={classes.tableCell}>
                                        {outageStart.toFormat('yyyy/L/d')}
                                      </TableCell>
                                      <TableCell align="left" className={classes.tableCell}>
                                        {outageEnd.toFormat('yyyy/L/d')}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        className={classes.tableCell}
                                      >
                                        {outage.description}
                                      </TableCell>

                                      <TableCell align="center" className={classes.tableCell}>
                                        <IconFontButton
                                          icon={faTimes}
                                          onClick={() => arrayHelpers.remove(index)}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        );
                      }}
                    </FieldArray>
                  </Box>
                  <Divider />
                  {/* Stage Estimates */}
                  <Box my={5}>
                    <Box mb={3}>
                      <Typography variant={'h4'}>Production Stage Estimates</Typography>
                    </Box>
                    <FieldArray name="stages">
                      {(arrayHelpers) => {
                        return (
                          <TableContainer component={Paper}>
                            <Table size="small" style={{ overflow: 'hidden' }}>
                              <TableHead>
                                <TableRow>
                                  <TableCell width={'40%'}>Stage</TableCell>
                                  <TableCell>Estimate</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {PRODUCTION_STAGE_ORDER.map((stageOrderInfo) => {
                                  const stageEstimates = (values.stages || [])
                                    .map((stageEstimate, currIdx) => ({
                                      ...stageEstimate,
                                      originalIndex: currIdx,
                                    }))
                                    .filter(
                                      (stageEstimate) =>
                                        stageEstimate.stage === stageOrderInfo.stage &&
                                        stageEstimate.subStage === stageOrderInfo.subStage,
                                    );

                                  const vendorsWithEstimates = stageEstimates.map(
                                    (e) => e.vendorId,
                                  );

                                  const showEstimateForm = !(
                                    vendorData?.organizations?.organizations.every((v) =>
                                      vendorsWithEstimates.includes(v.organizationId),
                                    ) && vendorsWithEstimates.includes(null)
                                  );

                                  return (
                                    <TableRow>
                                      <TableCell variant="head">
                                        <Box>
                                          {caseUtils.getCaseProductionStageName(
                                            stageOrderInfo.stage,
                                            stageOrderInfo.subStage,
                                          )}

                                          {(errors?.stages as number[])?.includes(
                                            stageOrderInfo.order,
                                          ) ? (
                                            <Typography color="error" variant="body2">
                                              Please provide at least one estimate for this stage.
                                            </Typography>
                                          ) : null}
                                        </Box>
                                      </TableCell>
                                      <TableCell>
                                        {stageEstimates.map((stageEstimate) => (
                                          <Box display="flex" alignItems="center" mb={2}>
                                            <Box width={150} mr={2}>
                                              <Typography variant="h6">
                                                {(stageEstimate.vendorId &&
                                                  vendorNameMap[stageEstimate.vendorId]?.name) ||
                                                  'None'}
                                              </Typography>
                                            </Box>
                                            &mdash;
                                            <Box mr={2} />
                                            <Box mr={2} width={100} textAlign="right">
                                              <Typography>
                                                {stageEstimate.days.toFixed(1)} days
                                              </Typography>
                                            </Box>
                                            <Box>
                                              <IconFontButton
                                                icon={faTimes}
                                                size="medium"
                                                onClick={() => {
                                                  arrayHelpers.remove(stageEstimate.originalIndex);
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        ))}

                                        {showEstimateForm ? (
                                          <Box display="flex" alignItems={'center'} mb={2}>
                                            <Box width={150} mr={2}>
                                              <Select
                                                name={''}
                                                hideNone={vendorsWithEstimates.includes(null)}
                                                variant="standard"
                                                onChange={(event) => {
                                                  const selectedVendorId = event.target.value;

                                                  setStageEstimateFormValue((prev) => ({
                                                    ...prev,
                                                    [stageOrderInfo.order]: {
                                                      ...prev[stageOrderInfo.order],
                                                      vendorId:
                                                        selectedVendorId !== ''
                                                          ? parseInt(selectedVendorId)
                                                          : null,
                                                    },
                                                  }));
                                                }}
                                                value={
                                                  stageEstimateFormValue[
                                                    stageOrderInfo.order
                                                  ]?.vendorId?.toString() || ''
                                                }
                                                menuItems={(
                                                  vendorData?.organizations?.organizations || []
                                                )
                                                  .filter(
                                                    (v) =>
                                                      !vendorsWithEstimates.includes(
                                                        v.organizationId,
                                                      ),
                                                  )
                                                  .map((v) => ({
                                                    key: v.organizationId.toString(),
                                                    value: v.name,
                                                  }))}
                                                disabled={Boolean(
                                                  vendorDataError || vendorDataLoading,
                                                )}
                                                fullWidth={true}
                                              />
                                            </Box>
                                            &mdash;
                                            <Box mr={2} />
                                            <Box mr={2} width={100}>
                                              <MuiTextField
                                                InputProps={{
                                                  endAdornment: (
                                                    <InputAdornment position="end">
                                                      days
                                                    </InputAdornment>
                                                  ),
                                                }}
                                                variant="standard"
                                                size="small"
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                onChange={(e) => {
                                                  const newVal = e.target.value;

                                                  setStageEstimateFormValue((prev) => ({
                                                    ...prev,
                                                    [stageOrderInfo.order]: {
                                                      ...prev[stageOrderInfo.order],
                                                      days: parseInt(newVal),
                                                    },
                                                  }));
                                                }}
                                                value={
                                                  stageEstimateFormValue[stageOrderInfo.order]
                                                    ?.days || ''
                                                }
                                              />
                                            </Box>
                                            <Box>
                                              <IconFontButton
                                                icon={faPlus}
                                                size="medium"
                                                disabled={
                                                  Boolean(vendorDataError || vendorDataLoading) ||
                                                  !stageEstimateFormValue[stageOrderInfo.order]
                                                    ?.days ||
                                                  vendorsWithEstimates.includes(
                                                    stageEstimateFormValue[stageOrderInfo.order]
                                                      ?.vendorId || null,
                                                  )
                                                }
                                                onClick={() => {
                                                  const currentForm =
                                                    stageEstimateFormValue[stageOrderInfo.order];

                                                  if (!currentForm) return;

                                                  const newStageEstimate: ProductionStageEstimate =
                                                    {
                                                      vendorId: currentForm?.vendorId || null,
                                                      order: stageOrderInfo.order,
                                                      stage: stageOrderInfo.stage,
                                                      subStage: stageOrderInfo.subStage,
                                                      days: currentForm.days,
                                                    };

                                                  arrayHelpers.push(newStageEstimate);

                                                  setStageEstimateFormValue((prev) => {
                                                    const newState = { ...prev };

                                                    delete newState[stageOrderInfo.order];

                                                    return newState;
                                                  });
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        ) : null}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        );
                      }}
                    </FieldArray>
                  </Box>

                  <Box my={4} display={'flex'} justifyContent={'flex-end'}>
                    <Button
                      variant={'outlined'}
                      color={'primary'}
                      label={'Save Production Calendar'}
                      onClick={submitForm}
                    />
                  </Box>
                </Box>
              );
            }}
          </Formik>
        </Box>
      ) : null}
    </>
  );
}
