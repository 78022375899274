import {
  CloudDesignQueueType,
  CloudDesignStatus,
  format,
  ICloudDesignQueue,
  LevelType,
} from '@workflow-nx/common';
import { date } from '@workflow-nx/utils';
import { Box, IconButton, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBug,
  faCheck,
  faExclamationCircle,
  faEye,
  faFileCheck,
  faPauseCircle,
  faQuestionCircle,
  faRunning,
  faTimes,
} from '@fortawesome/pro-light-svg-icons';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { DELETE_EXPORT_CLOUD_DESIGN, FIND_CLOUD_DESIGN_QUEUE_BY_LEVEL } from '../../../../gql';
import CustomDialog from '../../../../components/CustomDialog';
import { useConfirm } from 'material-ui-confirm';
import { gql } from '@apollo/client/core';
import { IconFontButton } from '@workflow-nx/ui';
import { useDownloadCloudDesignDebugFiles } from '../../../../hooks/useDownloadCloudDesignDebugFiles';

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    border: `1px solid ${theme.palette.error.main}`,
    '& .status-icon': {
      color: theme.palette.error.main,
    },
  },
  info: {
    border: `1px solid ${theme.palette.info.main}`,
    '& .status-icon': {
      color: theme.palette.info.main,
    },
  },
  success: {
    border: `1px solid ${theme.palette.success.main}`,
    '& .status-icon': {
      color: theme.palette.success.main,
    },
  },
}));

function getEstimatedProcessingTime(estimatedTime: number, startedAt: Date): number {
  const elapsedTimeInMinutes = startedAt
    ? date.distanceInMinutes(startedAt as unknown as string)
    : 0;
  const estimate = estimatedTime - elapsedTimeInMinutes;

  return estimate > 0 ? estimate : 0;
}

export function CloudDesignStatusView(props: {
  caseId: number;
  planId: number;
  disabled: boolean;
  levelType: LevelType;
  onStatusChange: (status: CloudDesignStatus) => void;
}) {
  const styles = useStyles();
  const [showCloudDesignMessage, setShowCloudDesignMessage] = useState(false);
  const [estimatedProcessingTimeRemaining, setEstimatedProcessingTimeRemaining] = useState(60);
  const confirm = useConfirm();
  const { download: downloadCloudDesignDebugFiles, loading: loadingDownloadCloudDesignDebugFiles } =
    useDownloadCloudDesignDebugFiles();

  const [cloudDesignQueue, setCloudDesignQueue] = useState<ICloudDesignQueue>();

  const [deleteExportCloudDesign, { loading: loadingDeleteExportCloudDesign }] = useMutation(
    DELETE_EXPORT_CLOUD_DESIGN,
  );
  const [validateExportCloudDesign, { loading: loadingValidateExportCloudDesign }] = useMutation(
    gql`
      mutation ValidateExportCloudDesign($cloudDesignQueueId: Int!) {
        validateExportCloudDesign(cloudDesignQueueId: $cloudDesignQueueId) {
          status
        }
      }
    `,
  );

  const { loading: loadingCloudDesignQueue, refetch: refetchFindCloudDesignQueueByLevel } =
    useQuery(FIND_CLOUD_DESIGN_QUEUE_BY_LEVEL, {
      variables: {
        caseId: props.caseId,
        planId: props.planId,
        level: props.levelType,
        type: CloudDesignQueueType.CutAndMesh,
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        const updatedCloudDesignQueueByLevel = data.cloudDesignQueueByLevel;

        if (updatedCloudDesignQueueByLevel?.status !== cloudDesignQueue?.status) {
          props.onStatusChange(updatedCloudDesignQueueByLevel?.status);
        }

        setEstimatedProcessingTimeRemaining(
          getEstimatedProcessingTime(60, updatedCloudDesignQueueByLevel?.startedAt),
        );
        setCloudDesignQueue(updatedCloudDesignQueueByLevel);
      },
    });

  const handleCancelClick = async () => {
    try {
      await confirm({
        title: 'Cancel Cloud Design Processing?',
        description: 'Are you sure you want to cancel this processing job?',
      });

      await deleteExportCloudDesign({
        variables: { cloudDesignQueueId: cloudDesignQueue?.cloudDesignQueueId },
      });
      refetchFindCloudDesignQueueByLevel?.();
    } catch {}
  };

  const handleValidateExportCloudDesign = async () => {
    try {
      await validateExportCloudDesign({
        variables: { cloudDesignQueueId: cloudDesignQueue?.cloudDesignQueueId },
      });
      refetchFindCloudDesignQueueByLevel?.();
    } catch (e) {
      console.error(e);
    }
  };

  function getCloudDesignQueueStatusSeverity(cloudDesignQueue?: ICloudDesignQueue) {
    if (cloudDesignQueue?.status === CloudDesignStatus.Error) return styles.error;
    if (cloudDesignQueue?.status === CloudDesignStatus.Success) return styles.success;

    return styles.info;
  }

  function getCloudDesignQueueStatusIcon(cloudDesignQueue?: ICloudDesignQueue) {
    if (cloudDesignQueue?.status === CloudDesignStatus.Error) return faExclamationCircle;
    if (cloudDesignQueue?.status === CloudDesignStatus.Success) return faCheck;
    if (cloudDesignQueue?.status === CloudDesignStatus.Processing) return faRunning;
    if (cloudDesignQueue?.status === CloudDesignStatus.Pending) return faPauseCircle;

    return faQuestionCircle;
  }

  useEffect(() => {
    if (cloudDesignQueue?.status === CloudDesignStatus.Processing) {
      setInterval(() => {
        refetchFindCloudDesignQueueByLevel();
      }, 10000);
    }
  }, [cloudDesignQueue]);

  return cloudDesignQueue ? (
    <>
      <Box className={getCloudDesignQueueStatusSeverity(cloudDesignQueue)} p={2} borderRadius="5px">
        <Box display={'flex'} alignItems={'center'} width={'100%'}>
          <Box pr={2} className={'status-icon'}>
            <FontAwesomeIcon icon={getCloudDesignQueueStatusIcon(cloudDesignQueue)} size={'2x'} />
          </Box>
          <Box>
            {cloudDesignQueue.status === CloudDesignStatus.Error ||
            cloudDesignQueue.status === CloudDesignStatus.Success ? (
              <Typography variant={'body1'}>
                <strong>Cloud Design</strong> ran from{' '}
                {format.formatDateTime(cloudDesignQueue?.startedAt ?? new Date())} to{' '}
                {cloudDesignQueue?.finishedAt ? (
                  format.formatDateTime(cloudDesignQueue?.finishedAt)
                ) : (
                  <>&mdash;</>
                )}
              </Typography>
            ) : null}
            {cloudDesignQueue.status === CloudDesignStatus.Processing ? (
              <Typography variant={'body1'}>
                <strong>Cloud Design</strong> started processing at{' '}
                {format.formatDateTime(cloudDesignQueue?.startedAt ?? new Date())}. Est. time to
                completion {estimatedProcessingTimeRemaining} minutes.
              </Typography>
            ) : null}
            <Typography>Result: {cloudDesignQueue?.status}</Typography>
          </Box>
          <Box flexGrow={1} />
          <IconFontButton
            icon={faTimes}
            onClick={handleCancelClick}
            loading={loadingDeleteExportCloudDesign}
            disabled={
              (cloudDesignQueue.status !== CloudDesignStatus.Processing &&
                cloudDesignQueue.status !== CloudDesignStatus.Pending &&
                !props.disabled) ||
              loadingCloudDesignQueue
            }
          />
          <IconFontButton
            disabled={
              cloudDesignQueue.status === CloudDesignStatus.Pending ||
              cloudDesignQueue.status === CloudDesignStatus.None ||
              loadingCloudDesignQueue ||
              loadingDownloadCloudDesignDebugFiles
            }
            loading={loadingDownloadCloudDesignDebugFiles}
            icon={faBug}
            onClick={() => {
              downloadCloudDesignDebugFiles(cloudDesignQueue.cloudDesignQueueId);
            }}
          />
          <IconButton
            onClick={() => setShowCloudDesignMessage(true)}
            disabled={
              cloudDesignQueue.status === CloudDesignStatus.Processing || loadingCloudDesignQueue
            }
            size="large"
          >
            <FontAwesomeIcon icon={faEye} />
          </IconButton>
          <IconFontButton
            icon={faFileCheck}
            onClick={handleValidateExportCloudDesign}
            loading={loadingValidateExportCloudDesign}
            disabled={cloudDesignQueue.status === CloudDesignStatus.None || loadingCloudDesignQueue}
          />
        </Box>
      </Box>
      {showCloudDesignMessage ? (
        <CustomDialog
          title={'Output Log ' + format.formatLevelType(props.levelType)}
          open={showCloudDesignMessage}
          onClose={() => setShowCloudDesignMessage(false)}
        >
          <pre>
            <ul style={{ listStyle: 'none' }}>
              {cloudDesignQueue?.log?.output?.split(/\s+(?=\d+:\d+:\d+\s)/).map((line: string) => (
                <li>{line}</li>
              ))}
            </ul>
          </pre>
        </CustomDialog>
      ) : null}
    </>
  ) : null;
}
