import { useMutation } from '@apollo/client';
import { ICase } from '@workflow-nx/common';
import { ProgressButton, TextField } from '@workflow-nx/ui';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import CustomDialog from '../../../components/CustomDialog';
import { DELETE_CASE } from '../../../gql';

export function CaseDeletionDialog(props: {
  activeCase: ICase;
  open: boolean;
  onClose: (shouldUpdate: boolean) => void;
}) {
  const [deleteCase] = useMutation(DELETE_CASE);
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();

  async function handleDeleteCase(reason: string) {
    try {
      await confirm({
        title: `Delete Case ${props.activeCase.number}?`,
        description: (
          <>
            This action will remove the case from the aprevo® Digital Production System. It can only
            be retrieved by a system administrator. Are you sure you wish to continue?
          </>
        ),
      });

      await deleteCase({
        variables: {
          caseId: props.activeCase.caseId,
          reason,
        },
      });

      enqueueSnackbar(`Case ${props.activeCase.number} was successfully deleted.`, {
        variant: 'success',
      });

      props.onClose(true);
    } catch (errors) {
      console.error(errors);
      enqueueSnackbar('Error deleting case', {
        variant: 'error',
      });
    }
  }

  const handleSubmitForm = async (values: FormikValues, { setSubmitting }: FormikHelpers<any>) => {
    const { reason } = values;
    try {
      await handleDeleteCase(reason);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        reason: '',
      }}
      validationSchema={Yup.object().shape({
        reason: Yup.string().required(),
      })}
      onSubmit={handleSubmitForm}
      enableReinitialize={true}
    >
      {({ validateForm, values, handleSubmit, isSubmitting, submitForm }) => (
        <CustomDialog
          maxWidth={'sm'}
          open={props.open}
          title={`Delete Case ${props.activeCase.number}`}
          onClose={() => {
            props.onClose(false);
          }}
          positiveActionButtons={[
            <ProgressButton
              onClick={async () => {
                await validateForm(values);
                await submitForm();
              }}
              loading={isSubmitting}
              disabled={isSubmitting}
              label={'Confirm'}
            />,
          ]}
        >
          {props.open ? (
            <form onSubmit={handleSubmit}>
              <TextField disabled={isSubmitting} name="reason" label={'Reason'} multiline={true} />
            </form>
          ) : null}
        </CustomDialog>
      )}
    </Formik>
  );
}
