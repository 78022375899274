import { alpha, Theme } from '@mui/material';
import { styled } from '@mui/styles';

export const FullCalendarWrapper = styled('div')(({ theme }) => {
  const palette = (theme as Theme).palette;
  const typography = (theme as Theme).typography;
  const shape = (theme as Theme).shape;
  const spacing = (theme as Theme).spacing;

  return {
    marginTop: spacing(1),
    '& .fc-license-message': {
      display: 'none',
    },
    '& .fc': {
      '--fc-bg-event-opacity': 1,
      '--fc-border-color': palette.divider,
      '--fc-daygrid-event-dot-width': '10px',
      '--fc-event-text-color': palette.primary.contrastText,
      '--fc-list-event-hover-bg-color': palette.background.default,
      '--fc-neutral-bg-color': palette.background.default,
      '--fc-page-bg-color': palette.background.default,
      '--fc-today-bg-color': alpha(palette.primary.main, 0.25),
      color: palette.text.primary,
      fontFamily: typography.fontFamily,
    },
    '& .fc .fc-col-header-cell-cushion': {
      paddingBottom: '10px',
      paddingTop: '10px',
      fontSize: typography.overline.fontSize,
      fontWeight: typography.overline.fontWeight,
      letterSpacing: typography.overline.letterSpacing,
      lineHeight: typography.overline.lineHeight,
      textTransform: typography.overline.textTransform,
    },
    '& .fc .fc-day-other .fc-daygrid-day-top': {
      color: palette.text.secondary,
    },
    '& .fc-daygrid-event': {
      borderRadius: shape.borderRadius,
      padding: '0px 4px',
      fontSize: typography.subtitle2.fontSize,
      fontWeight: typography.subtitle2.fontWeight,
      lineHeight: typography.subtitle2.lineHeight,
    },
    '& .fc-daygrid-block-event .fc-event-time': {
      fontSize: typography.body2.fontSize,
      fontWeight: typography.body2.fontWeight,
      lineHeight: typography.body2.lineHeight,
    },
    '& .fc-daygrid-day-frame': {
      padding: '12px',
    },
  };
});
