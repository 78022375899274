import { cloneDeep } from 'lodash';
import { EndPlate, PatientGender, VertebralBody } from '@workflow-nx/common';
import { Axis, DeepImmutable, Mesh, Quaternion, Scene, Space, Tags, Vector3 } from 'babylonjs';
import {
  evaluateAxialCorrectionAngle,
  evaluateBestFitPlaneLSM,
  evaluateClosestEndplateSphere,
  evaluateCoronalCorrectionAngle,
  evaluateEndplateCentroid,
  evaluateEndplateLength,
  evaluateLandMarkPoints,
  evaluateRawEndplatePoints,
  evaluateRawForamenPoints,
  evaluateRawSpinousProcessPoints,
  evaluateSagittalCorrectionAngle,
  evaluateSagittalSlope,
  evaluateMLCrossSection,
  evaluateCentroid,
} from './landmarkingPointEvaluation';
import { addBoundingBox, flipVertebralBodyCoronal, removeBoundingBox } from './cad';
import {
  filterEndplateIntercepts,
  filterNullIntercepts,
  validateClusterCount,
} from './landmarkingPointValidation';
import {
  AutoCorrectionConfigType,
  AutoCorrectionVectorLimitsType,
  Coordinates2DType,
} from '../shared/types';

export const testLandMarkingPoint = (
  scene: Scene,
  target: VertebralBody,
  config: AutoCorrectionConfigType,
  gender: PatientGender,
) => {
  const [vertebralBody]: Mesh[] = scene.getMeshesByTags(target);
  generateVertebralBodyLandMarkingPoint(vertebralBody, scene, config, gender);
};

export const generateVertebralBodyLandMarkingPoint = (
  mesh: Mesh,
  scene: Scene,
  config: AutoCorrectionConfigType,
  gender: PatientGender,
) => {
  console.time('vertebral body landmark generation');

  // speed up ray picking by subdividing the mesh into smaller chunks so that the
  // picking algorithm doesn't have to search as far to determine where the ray hit
  mesh.subdivide(Math.round(mesh.getTotalVertices() / 1000));

  addBoundingBox(mesh, scene, config);
  console.log(`Added bounding box ...`);
  console.timeLog('vertebral body landmark generation');
  applyVertebralReOrientation(mesh, scene, config);
  console.log(`Vertebral ReOrientation ...`);
  console.timeLog('vertebral body landmark generation');
  applyCoronalCorrection(mesh, scene, config, gender);
  console.log(`Coronal Correction ...`);
  console.timeLog('vertebral body landmark generation');
  applySagittalCorrection(mesh, scene, config, gender);
  console.log(`Sagittal Correction ...`);
  console.timeLog('vertebral body landmark generation');
  applyAxialCorrection(mesh, scene, config, gender);
  console.log(`Axial Correction ...`);
  console.timeLog('vertebral body landmark generation');

  generateEndplateLandmarks(EndPlate.Superior, mesh, scene, config, gender);
  console.log(`Superior Landmarks placed ...`);
  console.timeLog('vertebral body landmark generation');
  restoreOriginalOrientation(mesh);
  console.log(`Original Vertebral Body Orientation Restored ...`);
  console.timeLog('vertebral body landmark generation');
  removeBoundingBox(scene);
  console.log(`Bounding Box removed ...`);
  console.timeLog('vertebral body landmark generation');

  flipVertebralBodyCoronal(mesh);
  console.log(`Vertebral Body Flipped ...`);
  console.timeLog('vertebral body landmark generation');

  addBoundingBox(mesh, scene, config);
  console.log(`Added bounding box ...`);
  console.timeLog('vertebral body landmark generation');
  applyVertebralReOrientation(mesh, scene, config);
  console.log(`Vertebral ReOrientation ...`);
  console.timeLog('vertebral body landmark generation');
  applyCoronalCorrection(mesh, scene, config, gender);
  console.log(`Coronal Correction ...`);
  console.timeLog('vertebral body landmark generation');
  applySagittalCorrection(mesh, scene, config, gender);
  console.log(`Sagittal Correction ...`);
  console.timeLog('vertebral body landmark generation');
  applyAxialCorrection(mesh, scene, config, gender);
  console.log(`Axial Correction ...`);
  console.timeLog('vertebral body landmark generation');

  generateEndplateLandmarks(EndPlate.Inferior, mesh, scene, config, gender);
  console.log(`Inferior Landmarks placed ...`);
  console.timeLog('vertebral body landmark generation');
  restoreOriginalOrientation(mesh);
  console.log(`Original Vertebral Body Orientation Restored ...`);
  console.timeLog('vertebral body landmark generation');
  removeBoundingBox(scene);
  console.log(`Bounding Box removed ...`);
  console.timeLog('vertebral body landmark generation');

  console.timeEnd('vertebral body landmark generation');
};

export const generateSacrumLandMarkingPoint = (
  mesh: Mesh,
  scene: Scene,
  config: AutoCorrectionConfigType,
  gender: PatientGender,
) => {
  console.time('sacrum landmark generation');

  // speed up ray picking by subdividing the mesh into smaller chunks so that the
  // picking algorithm doesn't have to search as far to determine where the ray hit
  mesh.subdivide(Math.round(mesh.getTotalVertices() / 1000));

  addBoundingBox(mesh, scene, config);
  console.log(`Added bounding box ...`);
  console.timeLog('sacrum landmark generation');
  applySacrumReOrientation(mesh, scene, config);
  console.log(`Sacrum ReOrientation ...`);
  console.timeLog('sacrum landmark generation');
  applyCoronalCorrection(mesh, scene, config, gender);
  console.log(`Coronal Correction ...`);
  console.timeLog('sacrum landmark generation');
  applySagittalCorrection(mesh, scene, config, gender);
  console.log(`Sagittal Correction ...`);
  console.timeLog('sacrum landmark generation');
  applyAxialCorrection(mesh, scene, config, gender);
  console.log(`Axial Correction ...`);
  console.timeLog('sacrum landmark generation');

  generateEndplateLandmarks(EndPlate.Superior, mesh, scene, config, gender);
  console.log(`Sacrum Landmarks placed ...`);
  console.timeLog('sacrum landmark generation');
  restoreOriginalOrientation(mesh);
  console.log(`Original Vertebral Body Orientation Restored ...`);
  console.timeLog('sacrum landmark generation');
  removeBoundingBox(scene);
  console.log(`Bounding Box removed ...`);
  console.timeLog('sacrum landmark generation');

  console.timeEnd('sacrum landmark generation');
};

export const generateEndplateLandmarks = (
  anatomicalDirection: EndPlate,
  mesh: Mesh,
  scene: Scene,
  config: AutoCorrectionConfigType,
  gender: PatientGender,
) => {
  const endplate: (Vector3 | null)[][] = evaluateRawEndplatePoints(mesh, scene, config);

  const filteredEndplateIntercepts: (Vector3 | null)[][] = filterEndplateIntercepts(
    endplate,
    mesh,
    config,
    gender,
  );

  const endplateCentroid: Vector3 = evaluateEndplateCentroid(filteredEndplateIntercepts);

  const closestCentroidCoordinates: Coordinates2DType = evaluateClosestEndplateSphere(
    endplateCentroid,
    filteredEndplateIntercepts,
  );

  evaluateLandMarkPoints(
    closestCentroidCoordinates,
    mesh,
    filteredEndplateIntercepts,
    endplateCentroid,
    anatomicalDirection,
    config,
    scene,
  );
};

export const restoreOriginalOrientation = (mesh: Mesh): void => {
  mesh.rotation = Vector3.Zero();
  mesh.position = Vector3.Zero();

  mesh.computeWorldMatrix(true);
};

export const applyAxialCorrection = (
  mesh: Mesh,
  scene: Scene,
  config: AutoCorrectionConfigType,
  gender: PatientGender,
) => {
  const isSacrum: boolean = Tags.GetTags(mesh).includes(VertebralBody.S1);

  const endplate: (Vector3 | null)[][] = evaluateRawEndplatePoints(mesh, scene, config);
  const foramen: Vector3[] = evaluateRawForamenPoints(mesh, scene, config);
  const spinousProcess: Vector3[] = evaluateRawSpinousProcessPoints(mesh, scene, config);

  const clusterCount: number = validateClusterCount(spinousProcess, config);

  const filteredEndplateIntercepts: (Vector3 | null)[][] = filterEndplateIntercepts(
    endplate,
    mesh,
    config,
    gender,
  );

  const endplateCentroid: Vector3 = evaluateEndplateCentroid(filteredEndplateIntercepts);
  const foramenCentroid: Vector3 = evaluateCentroid(foramen, config);
  const spinousProcessCentroid: Vector3 = evaluateCentroid(spinousProcess, config);

  const correctionAngleEndplate: number = evaluateAxialCorrectionAngle(
    endplateCentroid,
    foramenCentroid,
  );
  const correctionAngleSpinousProcess: number = evaluateAxialCorrectionAngle(
    endplateCentroid,
    spinousProcessCentroid,
  );

  let correctionAngle: number;
  if (isSacrum) {
    correctionAngle =
      Math.abs(correctionAngleSpinousProcess) < Math.abs(correctionAngleEndplate)
        ? correctionAngleSpinousProcess
        : correctionAngleEndplate;
  } else {
    mesh.rotate(Axis.Y, correctionAngleSpinousProcess, Space.WORLD);
    mesh.computeWorldMatrix(true);

    const spinousAlignedML: (Vector3 | null)[] = cloneDeep(
      evaluateMLCrossSection(endplateCentroid, mesh, config, scene),
    );

    mesh.rotate(Axis.Y, -correctionAngleSpinousProcess, Space.WORLD);
    mesh.computeWorldMatrix(true);

    mesh.rotate(Axis.Y, correctionAngleEndplate, Space.WORLD);
    mesh.computeWorldMatrix(true);

    const endplateAlignedML: (Vector3 | null)[] = cloneDeep(
      evaluateMLCrossSection(endplateCentroid, mesh, config, scene),
    );

    mesh.rotate(Axis.Y, -correctionAngleEndplate, Space.WORLD);
    mesh.computeWorldMatrix(true);

    correctionAngle =
      spinousAlignedML.length > endplateAlignedML.length
        ? correctionAngleSpinousProcess
        : correctionAngleEndplate;

    // TODO: Refine lack of spinous detection
    if (clusterCount > 1) {
      correctionAngle = correctionAngleEndplate;
    } else {
      correctionAngle = correctionAngleSpinousProcess;
    }
  }

  mesh.rotate(Axis.Y, correctionAngle, Space.WORLD);
  mesh.computeWorldMatrix(true);
};

export const applyCoronalCorrection = (
  mesh: Mesh,
  scene: Scene,
  config: AutoCorrectionConfigType,
  gender: PatientGender,
): void => {
  const endplate: (Vector3 | null)[][] = evaluateRawEndplatePoints(mesh, scene, config);

  const filteredEndplateIntercepts: (Vector3 | null)[][] = filterEndplateIntercepts(
    endplate,
    mesh,
    config,
    gender,
  );

  const finalEndplatePoints: Vector3[] = filterNullIntercepts(filteredEndplateIntercepts);

  const normal: Vector3 = evaluateBestFitPlaneLSM(finalEndplatePoints);

  const correctionAngle: number = evaluateCoronalCorrectionAngle(normal);

  mesh.rotate(Axis.Z, correctionAngle, Space.WORLD);
  mesh.computeWorldMatrix(true);
};

export const applySagittalCorrection = (
  mesh: Mesh,
  scene: Scene,
  config: AutoCorrectionConfigType,
  gender: PatientGender,
) => {
  const endplate: (Vector3 | null)[][] = evaluateRawEndplatePoints(mesh, scene, config);

  const { isNegativeSlope } = evaluateSagittalSlope(mesh, scene, config);

  const filteredEndplateIntercepts: (Vector3 | null)[][] = filterEndplateIntercepts(
    endplate,
    mesh,
    config,
    gender,
  );

  const finalEndplatePoints: Vector3[] = filterNullIntercepts(filteredEndplateIntercepts);

  const normal: Vector3 = evaluateBestFitPlaneLSM(finalEndplatePoints);

  const correctionAngle: number = Math.abs(evaluateSagittalCorrectionAngle(normal));

  mesh.rotate(Axis.X, isNegativeSlope ? -correctionAngle : correctionAngle, Space.WORLD);
  mesh.computeWorldMatrix(true);
};

export const applySacrumReOrientation = (
  mesh: Mesh,
  scene: Scene,
  config: AutoCorrectionConfigType,
): void => {
  const { start, end }: AutoCorrectionVectorLimitsType = evaluateEndplateLength(
    mesh,
    scene,
    config,
  );

  const diff: Vector3 = end.subtract(start).normalize();
  const dot: number = Vector3.Dot(diff, Vector3.Backward());
  const sign: number = dot > 0 ? 1 : -1;
  const angle: number = Math.acos(dot);
  const axis: Vector3 = Vector3.Cross(diff, Vector3.Backward());
  const quaternion: Quaternion = Quaternion.RotationAxis(axis, angle);
  const euler: Vector3 = quaternion.toEulerAngles();
  const correctionAngle: number = sign * euler.x;

  mesh.rotate(Axis.X, correctionAngle);
  mesh.computeWorldMatrix(true);
};

export const applyVertebralReOrientation = (
  mesh: Mesh,
  scene: Scene,
  config: AutoCorrectionConfigType,
) => {
  const { start, end } = evaluateEndplateLength(mesh, scene, config);

  const diff: Vector3 = start.subtract(end).normalize();

  const meshMatrix: DeepImmutable<number[] | Float32Array> = mesh.getWorldMatrix().asArray();
  const meshAxisX: Vector3 = new Vector3(meshMatrix[0], meshMatrix[1], meshMatrix[2]).normalize();
  const flippedDirection = meshAxisX.x > 0 ? 1 : -1;
  const dot: number = Vector3.Dot(diff, Vector3.Backward());
  const angle: number = Math.acos(dot);
  const rotationalAxis: Vector3 = Vector3.Cross(diff, Vector3.Backward());
  const quaternion: Quaternion = Quaternion.RotationAxis(rotationalAxis, angle);
  const euler: Vector3 = quaternion.toEulerAngles();
  const normalDirection = diff.y > 0 ? -1 : 1;
  const correctionAngle: number = flippedDirection * normalDirection * Math.abs(euler.x);

  mesh.rotate(Axis.X, correctionAngle);
  mesh.computeWorldMatrix(true);
};
