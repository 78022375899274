import { format, IAsset, ICase, ITableHeader } from '@workflow-nx/common';
import { Box, IconButton, TableBody, TableCell, TableRow, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownload, faExternalLink } from '@fortawesome/pro-light-svg-icons';
import React from 'react';
import { CommonTable } from '../../../../components/CommonTable';
import { getAssetPageUrl } from './AssetsTab.reducer';

const useStylesheet = makeStyles((theme: Theme) => ({
  deletedCell: {
    verticalAlign: 'top',
    color: theme.palette.error.main,
  },
  tableCell: {
    verticalAlign: 'top',
  },
}));

export function AssetsListTable(props: {
  loading: boolean;
  activeCase: ICase;
  assets: IAsset[];
  dispatch: any;
  onView: (assetType: IAsset) => void;
  onDownload: (asset: IAsset) => void;
}) {
  const classes = useStylesheet();

  const headers: ITableHeader[] = [
    {
      id: 'assetType',
      label: 'Asset',
    },
    {
      id: 'createdBy',
      label: 'Created By',
    },
    {
      id: 'createdAt',
      label: 'Created At',
    },
    {
      id: 'deletedAt',
      label: 'Deleted At',
    },
    {
      id: 'view',
      label: 'View',
      align: 'center',
    },
    {
      id: 'download',
      label: 'Download',
      align: 'center',
    },
  ];

  return (
    <CommonTable
      headers={headers}
      rows={
        <TableBody>
          {props?.assets?.map((asset: IAsset) => {
            const cellClass = asset.deletedAt ? classes.deletedCell : classes.tableCell;

            return (
              <TableRow key={asset.assetId}>
                <TableCell className={cellClass}>
                  <Typography variant={'body1'} noWrap>
                    {`${format.formatAssetType(asset.assetType)}${asset.planId ? ' (Plan)' : ''}`}
                  </Typography>
                </TableCell>
                <TableCell className={cellClass}>
                  <Typography variant={'body1'} noWrap>
                    {format.formatName(asset.createdByUser)}
                  </Typography>
                </TableCell>
                <TableCell className={cellClass}>
                  <Typography variant={'body1'} noWrap>
                    {format.formatDateTime(asset.createdAt)}
                  </Typography>
                </TableCell>
                <TableCell className={cellClass}>
                  <Typography variant={'body1'} noWrap>
                    {asset.deletedAt ? format.formatDateTime(asset.deletedAt) : null}
                  </Typography>
                </TableCell>
                <TableCell className={cellClass}>
                  <Box display={'flex'} justifyContent={'center'} height={50}>
                    <IconButton
                      disabled={
                        Boolean(asset.deletedAt) ||
                        !Boolean(getAssetPageUrl(props.activeCase, asset))
                      }
                      onClick={() => {
                        props.onView(asset);
                      }}
                      size="large"
                    >
                      <FontAwesomeIcon icon={faExternalLink} />
                    </IconButton>
                  </Box>
                </TableCell>
                <TableCell className={cellClass}>
                  <Box display={'flex'} justifyContent={'center'} height={50}>
                    <IconButton
                      onClick={() => {
                        props.onDownload(asset);
                      }}
                      size="large"
                    >
                      <FontAwesomeIcon icon={faCloudDownload} />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      }
      loading={props.loading}
    />
  );
}
