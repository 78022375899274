import { AbstractMesh, Mesh, NullEngine, Scene, SceneLoader, Tags, Vector3 } from 'babylonjs';
import {
  generateSacrumLandMarkingPoint,
  generateVertebralBodyLandMarkingPoint,
} from '../utils/landmarkingPoint';
import { loadVertebralBody } from '../utils/cad';
import { EndPlate, IMeasure, Position, VertebralBody } from '@workflow-nx/common';
import { STLFileLoader } from 'babylonjs-loaders';
import { AutoCorrectionMesh } from '../shared/enum';
import { defaultConfig } from '../data/defaultConfigs';
import { PatientGender } from '@workflow-nx/common';
import { AutoCorrectionRawLandmarkType } from '../shared/types';

SceneLoader.RegisterPlugin(new STLFileLoader());

export class Landmark {
  private _engine: NullEngine = new NullEngine();
  private _scene: Scene = new Scene(this._engine);
  private _signedUrl = '';
  private _vertebralBody: VertebralBody = VertebralBody.L1;
  private _gender: PatientGender = PatientGender.Male;

  constructor(vertebralBody: VertebralBody, signedUrl: string, gender: PatientGender) {
    this._signedUrl = signedUrl;
    this._vertebralBody = vertebralBody;
    this._gender = gender;
  }

  public async main(): Promise<AutoCorrectionRawLandmarkType> {
    const measurementPoints: IMeasure[] = [];

    const mesh: Mesh = await loadVertebralBody(this._vertebralBody, this._scene, this._signedUrl);

    console.log(`Starting landmarking placement for ${this._vertebralBody}`);

    performance.mark('landmarks-start');

    if (this._vertebralBody !== VertebralBody.S1) {
      generateVertebralBodyLandMarkingPoint(mesh, this._scene, defaultConfig, this._gender);
    } else {
      generateSacrumLandMarkingPoint(mesh, this._scene, defaultConfig, this._gender);
    }

    console.log(`Completed landmarking placement for ${this._vertebralBody}`);

    this._scene.meshes.forEach((mesh: AbstractMesh) => {
      mesh.computeWorldMatrix(true);
    });

    const landMarkingPoints: Mesh[] = this._scene.getMeshesByTags(AutoCorrectionMesh.Landmark);

    landMarkingPoints.forEach((landmark: Mesh) => {
      const tags: string[] = Tags.GetTags(landmark).split(' ')[0].split('.');
      const [vertebralBody, position, endPlate]: string[] = tags;
      const vector: Vector3 = landmark.getAbsolutePosition();
      const point: number[] = [vector.x, vector.y, vector.z];

      const measurement: IMeasure = {
        body: vertebralBody as VertebralBody,
        endPlate: endPlate as EndPlate,
        position: position as Position,
        point,
      };

      measurementPoints.push(measurement);
    });

    const [posteriorInferiorPoints]: (Mesh | undefined)[] = this._scene.getMeshesByTags(
      `${AutoCorrectionMesh.EdgePosterior}-${EndPlate.Inferior}`,
    );

    const [posteriorSuperiorPoints]: (Mesh | undefined)[] = this._scene.getMeshesByTags(
      `${AutoCorrectionMesh.EdgePosterior}-${EndPlate.Superior}`,
    );

    let edgeInferiorPosterior: IMeasure | null = null;
    let edgeSuperiorPosterior: IMeasure | null = null;

    if (posteriorInferiorPoints) {
      edgeInferiorPosterior = {
        body: this._vertebralBody,
        endPlate: EndPlate.Inferior,
        position: Position.PosteriorEdge,
        point: [
          posteriorInferiorPoints.position.x,
          posteriorInferiorPoints.position.y,
          posteriorInferiorPoints.position.z,
        ],
      };

      measurementPoints.push(edgeInferiorPosterior);
    }

    if (posteriorSuperiorPoints) {
      edgeSuperiorPosterior = {
        body: this._vertebralBody,
        endPlate: EndPlate.Superior,
        position: Position.PosteriorEdge,
        point: [
          posteriorSuperiorPoints.position.x,
          posteriorSuperiorPoints.position.y,
          posteriorSuperiorPoints.position.z,
        ],
      };

      measurementPoints.push(edgeSuperiorPosterior);
    }

    return {
      measurementPoints,
    };
  }
}
