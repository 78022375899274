import { Grid, Typography } from '@mui/material';
import { ComponentProps } from 'react';
import { Control, FieldValues } from 'react-hook-form';
import { DatePickerRHF, ErrorMessageRHF, InputConfigRHF, InputTypeRHF } from '@workflow-nx/ui';

export const InputApplicationSettingsRHF = (props: {
  config: InputConfigRHF;
  disabled?: boolean;
  control: Control<FieldValues>;
  datePickerProps?: Partial<ComponentProps<typeof DatePickerRHF>>;
}) => {
  return (
    props.config.input === InputTypeRHF.Date && (
      <Grid item container xs={12} spacing={1} alignItems={'center'}>
        <Grid item xs={3} md={3} display={'flex'} alignItems={'center'}>
          <Typography>{props.config.label}</Typography>
        </Grid>
        <Grid item xs={6} md={6}>
          <DatePickerRHF
            name={props.config.id}
            control={props.control}
            {...props.datePickerProps}
          />
        </Grid>
        <Grid item xs={3} md={3} display={'flex'} justifyContent={'center'}>
          <ErrorMessageRHF name={props.config.id} control={props.control} />
        </Grid>
      </Grid>
    )
  );
};
