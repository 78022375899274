import { Grid } from '@mui/material';
import { NumberTextField } from '../../../../components/NumberTextField';
import React from 'react';
import { CaseSpineType } from '@workflow-nx/common';

export function StandingXrayCaseDetailsForm(props: {
  disabled: boolean;
  caseSpineType: CaseSpineType;
}) {
  return (
    <Grid container spacing={2}>
      <Grid item xs>
        {props.caseSpineType === CaseSpineType.Lumbar ? (
          <NumberTextField
            decimalPlaces={2}
            name={'pelvicIncidence'}
            label={'Pelvic Incidence(1\u00b0 to 180\u00b0)'}
            disabled={props.disabled}
            shrink={true}
          />
        ) : props.caseSpineType === CaseSpineType.Cervical ? (
          <NumberTextField
            name={'slopeOfLineOfSight'}
            label={'Slope of Line of Sight'}
            disabled={props.disabled}
            shrink={true}
          />
        ) : null}
      </Grid>
      <Grid item xs>
        <NumberTextField
          decimalPlaces={2}
          name={'lumbarLordosis'}
          label={'Lumbar Lordosis'}
          disabled={props.disabled}
          shrink={true}
        />
      </Grid>
      <Grid item xs>
        <NumberTextField
          decimalPlaces={2}
          name={'lumbarCoronalCobb'}
          label={'Lumbar Coronal Angle'}
          disabled={props.disabled}
          shrink={true}
        />
      </Grid>
      <Grid item xs>
        <NumberTextField
          decimalPlaces={2}
          name={'sagittalVerticalAxis'}
          label={'SVA'}
          disabled={props.disabled}
          shrink={true}
        />
      </Grid>
      <Grid item xs>
        <NumberTextField
          decimalPlaces={2}
          name={'coronalBalance'}
          label={'Coronal Balance'}
          disabled={props.disabled}
          shrink={true}
        />
      </Grid>
    </Grid>
  );
}
