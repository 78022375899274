import React, { Dispatch, useCallback, useContext, useReducer, useState } from 'react';
import {
  Alert,
  Box,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@mui/material';
import {
  AssetType,
  CaseSpineType,
  CaseStageType,
  caseUtils,
  IAsset,
  ICase,
  Permission,
  UserRoleType,
  ValidFileExtensions,
} from '@workflow-nx/common';
import { AssetGridTable } from '../../../../components/AssetGridTable/AssetGridTable';
import { useMutation, useQuery } from '@apollo/client';
import {
  COMPLETE_CASE_STAGE,
  EXPORT_DAISY_SEGMENTATION,
  FIND_ASSETS,
  UPDATE_CASE_DICOM_XR_REQUIRED,
} from '../../../../gql';
import { useSnackbar } from 'notistack';
import { TabLoadingView } from '../TabLoadingView';
import ActionButton from '../../../../components/ActionButton';
import useAuth from '../../../../hooks/useAuth';
import { CaseViewActionType } from '../CaseView';
import { ExportDaisySegmentationView } from './ExportDaisySegmentationView';
import { PlanViewDialog } from '../CasePlanningTab/PlanView/PlanViewDialog';
import { CaseViewContext } from '../CaseView.context';
import { PlanViewDialogProvider } from '../CasePlanningTab/PlanView/PlanViewDialog.context';
import EditedVertebralAssetsBanner from '../EditedVertebralAssetsBanner';
import { FeatureFlag } from '../../../../utils/featureFlags';

type ActionType = {
  type: string;
  data: any;
};

type StateType = {
  caseAssets?: IAsset[];
  isEditingAllowed: boolean;
  isStageComplete: boolean;
  isXrayOptional: boolean;
  canMoveToPlanning: boolean;
};

export function CaseSegmentationTabView(props: {
  activeCase: ICase;
  dispatch: Dispatch<CaseViewActionType>;
}) {
  const { hasRole, hasPermission, hasFeatureFlag } = useAuth();
  const caseViewContext = useContext(CaseViewContext);
  const vertebralBodyAssets = caseUtils.getCaseVertebralBodyAssets(
    props.activeCase.spineProfile,
  ).standard;

  const requiredAssets = [AssetType.DicomCt, ...vertebralBodyAssets];
  const [completeCaseStage, { loading: loadingCompleteCase }] = useMutation(COMPLETE_CASE_STAGE);
  const [updateCaseDicomXrOption] = useMutation(UPDATE_CASE_DICOM_XR_REQUIRED);

  const [exportDaisySegmentation] = useMutation(EXPORT_DAISY_SEGMENTATION);
  const { refetch: refetchAssets, loading: loadingCaseAssets } = useQuery(FIND_ASSETS, {
    variables: {
      caseId: props.activeCase.caseId,
      assetTypeFilter: [
        ...requiredAssets,
        AssetType.DicomMri,
        AssetType.DicomXray,
        AssetType.StandingXrayAP,
        AssetType.StandingXrayLateral,
        ...caseUtils.getCaseVertebralBodyAssets(props.activeCase.spineProfile).daisy,
        ...caseUtils
          .getCaseVertebralBodyAssets(props.activeCase.spineProfile)
          .standard.map((s) => s + '_EDITED'),
        AssetType.HardwarePreop,
      ],
    },
    onCompleted: (data) => {
      segmentationDispatch({ type: 'INIT', data: data.assets });
    },
  });
  const [showSegmentedAssetsView, setShowSegmentedAssetsView] = useState(false);
  const [loadingToggleXray, setLoadingToggleXray] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const initialState: StateType = {
    caseAssets: undefined,
    canMoveToPlanning: false,
    isEditingAllowed: false,
    isStageComplete: false,
    isXrayOptional: false,
  };

  const segmentationReducer = (state: StateType, action: ActionType): StateType => {
    let updatedState = state;

    switch (action.type) {
      case 'ASSET_REPLACED': {
        const replacedAsset = action.data;
        let replacedCaseAssets = JSON.parse(JSON.stringify(state.caseAssets));

        replacedCaseAssets = replacedCaseAssets.filter(
          (ca: IAsset) => ca.assetType !== replacedAsset.assetType,
        );
        replacedCaseAssets.push(replacedAsset);

        updatedState = {
          canMoveToPlanning: caseUtils.isCaseStageSegmentationComplete(
            replacedCaseAssets,
            props.activeCase.spineProfile,
            props.activeCase.isXrayNotRequired,
          ),
          isEditingAllowed: state.isEditingAllowed,
          isStageComplete: state.isStageComplete,
          isXrayOptional: state.isXrayOptional,
          caseAssets: replacedCaseAssets,
        };
        break;
      }
      case 'ASSET_DELETED': {
        const deletedAsset = action.data;
        let updatedCaseAssets = JSON.parse(JSON.stringify(state.caseAssets));

        updatedCaseAssets = updatedCaseAssets.filter(
          (ca: IAsset) => ca.assetId !== deletedAsset.assetId,
        );

        updatedState = {
          canMoveToPlanning: caseUtils.isCaseStageSegmentationComplete(
            updatedCaseAssets,
            props.activeCase.spineProfile,
            props.activeCase.isXrayNotRequired,
          ),
          isEditingAllowed: state.isEditingAllowed,
          isStageComplete: state.isStageComplete,
          isXrayOptional: state.isXrayOptional,
          caseAssets: updatedCaseAssets,
        };

        break;
      }
      case 'TOGGLE_XRAY_OPTIONAL_REQ': {
        const isOptional = action.data;

        const updatedCaseAssets = JSON.parse(JSON.stringify(state.caseAssets));

        updatedState = {
          canMoveToPlanning: caseUtils.isCaseStageSegmentationComplete(
            updatedCaseAssets,
            props.activeCase.spineProfile,
            isOptional,
          ),
          isEditingAllowed: state.isEditingAllowed,
          isStageComplete: state.isStageComplete,
          isXrayOptional: isOptional,
          caseAssets: updatedCaseAssets,
        };

        break;
      }
      case 'ASSET_UPLOADED': {
        const updatedCaseAssets = JSON.parse(JSON.stringify(state.caseAssets));
        const createdAsset = action.data;

        updatedCaseAssets.push(createdAsset);
        if (createdAsset.assetType === AssetType.DicomCt) {
          exportDaisySegmentation({
            variables: {
              caseId: props.activeCase.caseId,
            },
          })
            .then(() => {
              console.error(`Case #${props.activeCase.number} successfully sent for segmentation`);
            })
            .catch((error) => {
              console.error('An error occurred exporting the case to Daisy', error);
            });
        }

        updatedState = {
          canMoveToPlanning: caseUtils.isCaseStageSegmentationComplete(
            updatedCaseAssets,
            props.activeCase.spineProfile,
            props.activeCase.isXrayNotRequired,
          ),
          isEditingAllowed: state.isEditingAllowed,
          isStageComplete: state.isStageComplete,
          isXrayOptional: state.isXrayOptional,
          caseAssets: updatedCaseAssets,
        };
        break;
      }
      case 'INIT': {
        const caseAssets = action?.data ?? [];

        const isXrayOptional = props.activeCase.isXrayNotRequired;

        const canMoveToPlanning = caseUtils.isCaseStageSegmentationComplete(
          caseAssets,
          props.activeCase.spineProfile,
          isXrayOptional,
        );

        const stageComplete =
          props.activeCase.stage !== CaseStageType.Open &&
          props.activeCase.stage !== CaseStageType.Segmentation;
        const editingAllowed = !!hasPermission?.([Permission.ManageCase, Permission.EditCase]);

        updatedState = {
          canMoveToPlanning,
          isEditingAllowed: editingAllowed,
          isStageComplete: stageComplete,
          isXrayOptional,
          caseAssets,
        };
        break;
      }
      default:
        throw new Error();
    }
    return updatedState;
  };

  const [state, segmentationDispatch] = useReducer(segmentationReducer, initialState);

  // move to top level and dispatch?
  const handleCompleteCaseStage = useCallback(async () => {
    try {
      await completeCaseStage({
        variables: {
          caseId: props.activeCase.caseId,
        },
      });

      props.dispatch({ type: 'refetch' });

      enqueueSnackbar('Case updated successfully', {
        variant: 'success',
      });
    } catch (e) {
      console.error(e);
      enqueueSnackbar('Error updating case', {
        variant: 'error',
      });
    }
  }, [completeCaseStage, props, enqueueSnackbar]);

  const handleToggleXrayRequiredOption = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const isOptional = event.target.checked;

    try {
      setLoadingToggleXray(true);
      await updateCaseDicomXrOption({
        variables: {
          caseId: props.activeCase.caseId,
          isXrayNotRequired: isOptional,
        },
      });

      segmentationDispatch({ type: 'TOGGLE_XRAY_OPTIONAL_REQ', data: isOptional });
    } catch (e) {
      console.error("An error occurred updating the case's DICOM XR requirement", e);
      enqueueSnackbar('Error setting x-ray not required confirmation', {
        variant: 'error',
      });
    } finally {
      setLoadingToggleXray(false);
    }
  };

  const dicomAssetsReadOnly =
    state.isStageComplete ||
    !state.isEditingAllowed ||
    hasRole?.([UserRoleType.SegmentationEngineer]);

  const segmentationAssetsReadOnly = state.isStageComplete || !state.isEditingAllowed;

  const dicomXRayAssetUploaded =
    state.caseAssets?.find((asset) => asset.assetType == AssetType.DicomXray) != null;

  return loadingCaseAssets ? (
    <TabLoadingView label={'Loading Segmentation Tab'} />
  ) : (
    <>
      <Card>
        <CardContent>
          {state.isStageComplete ? (
            <Box mb={2}>
              <Alert severity={'success'}>This stage is complete</Alert>
            </Box>
          ) : null}

          <Box mb={2}>
            <Typography variant={'h4'}>
              <strong>Step 1: Upload DICOM and X-Ray Assets</strong>
            </Typography>
          </Box>

          <AssetGridTable
            dispatch={segmentationDispatch}
            caseId={props?.activeCase?.caseId}
            assets={state.caseAssets ?? []}
            showAdd={false}
            validFileExtensions={[ValidFileExtensions.ZIP]}
            validAssets={[AssetType.DicomCt, AssetType.DicomXray, AssetType.DicomMri]}
            readOnly={dicomAssetsReadOnly}
          />

          <Box display={'flex'} justifyContent={'center'} mb={4}>
            <FormControlLabel
              control={
                <Checkbox
                  name="isXrayOptional"
                  checked={state.isXrayOptional}
                  onChange={handleToggleXrayRequiredOption}
                  disabled={(dicomXRayAssetUploaded && state.isStageComplete) || loadingToggleXray}
                />
              }
              label={'Surgeon has confirmed that no DICOM X-Ray is required for this case'}
            />
          </Box>

          <AssetGridTable
            dispatch={segmentationDispatch}
            caseId={props?.activeCase?.caseId}
            assets={state.caseAssets ?? []}
            allowReplace={true}
            validFileExtensions={[
              ValidFileExtensions.JPG,
              ValidFileExtensions.JPEG,
              ValidFileExtensions.PNG,
            ]}
            validAssets={[AssetType.StandingXrayLateral, AssetType.StandingXrayAP]}
          />

          <Box mb={4} />

          <Box mb={2} mt={2} display={'flex'} alignItems={'center'}>
            <Typography variant={'h4'}>
              <strong>Step 2: Upload Segmented Assets</strong>
            </Typography>
            <Box ml={2} />
            <ActionButton onClick={() => setShowSegmentedAssetsView(true)} variant={'outlined'}>
              Show Preview
            </ActionButton>
          </Box>

          <Box display={'flex'}>
            <Box width={'100%'}>
              <AssetGridTable
                dispatch={segmentationDispatch}
                caseId={props?.activeCase?.caseId}
                assets={state.caseAssets ?? []}
                validFileExtensions={[ValidFileExtensions.STL]}
                validAssets={[...vertebralBodyAssets, AssetType.HardwarePreop]}
                readOnly={segmentationAssetsReadOnly}
              />
              {hasFeatureFlag?.(FeatureFlag.cervicalSupportEnabled) ? (
                <Box my={2}>
                  <EditedVertebralAssetsBanner
                    dispatch={segmentationDispatch}
                    disabled={state.isStageComplete}
                    assets={state.caseAssets ?? []}
                    activeCase={props.activeCase}
                    readOnly={![CaseStageType.Segmentation].includes(props.activeCase.stage)}
                  />
                </Box>
              ) : null}
            </Box>
            {props.activeCase.spineType !== CaseSpineType.Cervical &&
            hasRole?.([UserRoleType.SiteAdministrator, UserRoleType.CaseAdmin]) ? (
              <Box ml={2} width={500}>
                <ExportDaisySegmentationView
                  caseId={props?.activeCase?.caseId}
                  caseStage={props?.activeCase?.stage}
                  caseNumber={props?.activeCase?.number}
                  caseTags={props?.activeCase?.caseTags ?? []}
                  spineProfile={props?.activeCase.spineProfile}
                  disabled={false}
                  onApproved={() => {
                    refetchAssets?.();
                  }}
                  onRejected={() => {
                    refetchAssets?.();
                  }}
                />
              </Box>
            ) : null}
          </Box>

          {!state.isStageComplete ? (
            <Box my={2}>
              {!state.canMoveToPlanning ? (
                <Box mb={2}>
                  <Alert severity={'info'}>
                    The CT DICOM and all {vertebralBodyAssets.length} segmented assets must be
                    uploaded before moving to the <strong>PLANNING</strong> stage
                  </Alert>
                </Box>
              ) : null}
              <Box display={'flex'} my={2} justifyContent={'center'}>
                <ActionButton
                  variant={'outlined'}
                  disabled={
                    !state.canMoveToPlanning ||
                    loadingCaseAssets ||
                    !state.isEditingAllowed ||
                    !!props?.activeCase?.caseCancellation ||
                    hasRole?.([UserRoleType.SegmentationEngineer])
                  }
                  onClick={handleCompleteCaseStage}
                  loading={loadingCompleteCase}
                  label="Move To Planning"
                >
                  Move To Planning
                </ActionButton>
              </Box>
            </Box>
          ) : null}
        </CardContent>
      </Card>
      {showSegmentedAssetsView ? (
        <PlanViewDialogProvider activeCase={props.activeCase} showDaisy={true} plan={null}>
          <PlanViewDialog
            open={showSegmentedAssetsView}
            activeCase={props.activeCase}
            showDaisy={true}
            caseMeasurementsVersion={caseViewContext.caseMeasurementsVersion}
            onClose={() => setShowSegmentedAssetsView(false)}
          />
        </PlanViewDialogProvider>
      ) : null}
    </>
  );
}
