import { useQuery } from '@apollo/client';
import { Alert, Box, Skeleton, Typography, useTheme } from '@mui/material';
import {
  CoronalGoalType,
  IForm20Product,
  IUser,
  ImplantDirection,
  ImplantOrientation,
  ImplantType,
  LevelSize,
  NavigationSystemType,
  PositionDistanceType,
  SagittalGoalType,
  SurgeonPreferencesError,
  TargetHeightMetricType,
  format,
  caseUtils,
} from '@workflow-nx/common';
import { Checkbox, CheckboxListField, SelectField, TextField } from '@workflow-nx/ui';
import { FieldArray, FormikValues, useFormikContext } from 'formik';
import { sortBy } from 'lodash';
import { useState } from 'react';
import RadioGroup from '../../../../components/RadioGroup';
import { FIND_SETTINGS } from '../../../../gql';
import useAuth from '../../../../hooks/useAuth';
import { FeatureFlag, isTlifCOrientationAvailable } from '../../../../utils/featureFlags';
import * as surgeonPreferencesUtils from '../../../../utils/surgeonPreferences';
import { AlifxScrewOrientationEditView } from './AlifxScrewOrientationEditView';
import { SurgeonPreferenceImplantType } from './EditSurgeonPreferences.reducer';
import { SurgeonPreferencesMeasurementEditTable } from './SurgeonPreferencesMeasurementEditTable';

export function SurgeonPreferencesForm(props: {
  surgeonUser: IUser;
  disabled: boolean;
  errors: SurgeonPreferencesError[];
  loading: boolean;
  isTlifCArticulatingStarted: boolean;
}) {
  const theme = useTheme();
  const { hasFeatureFlag } = useAuth();
  const [excludableInstruments, setExcludableInstruments] = useState<
    { key: string; value: string }[]
  >([]);
  const { values: formikValues, setFieldValue } = useFormikContext<FormikValues>();

  useQuery(FIND_SETTINGS, {
    fetchPolicy: 'network-only',
    onCompleted: (settingsData) => {
      const form20Products = settingsData?.settings?.form20 || [];
      const foundExcludableInstruments = sortBy(
        caseUtils
          .getValidExcludableInstruments(form20Products)
          .map((form20Product: IForm20Product) => {
            return {
              key: form20Product.sku,
              value: form20Product.sku + ' - ' + form20Product.description,
            };
          }),
        ['value'],
      );
      setExcludableInstruments(foundExcludableInstruments);
    },
  });

  const disabled = props.loading || props.disabled;

  const plusSizeImplantOptions = [
    {
      key: '1',
      value: '+1mm',
    },
    {
      key: '2',
      value: '+2mm',
    },
  ];

  const preferredProceduresTypeListItems = [
    {
      key: ImplantType.ALIF,
      value: format.formatImplantTypes(ImplantType.ALIF),
    },
    {
      key: ImplantType.ALIFX,
      value: format.formatImplantTypes(ImplantType.ALIFX),
    },
    {
      key: ImplantType.LLIF,
      value: format.formatImplantTypes(ImplantType.LLIF),
    },
    {
      key: ImplantType.TLIFC,
      value: format.formatImplantTypes(ImplantType.TLIFC),
    },
    {
      key: ImplantType.TLIFO,
      value: format.formatImplantTypes(ImplantType.TLIFO),
    },
  ];

  if (props.isTlifCArticulatingStarted) {
    const tlifcIndex = preferredProceduresTypeListItems.findIndex(
      (item) => item.key === ImplantType.TLIFC,
    );
    preferredProceduresTypeListItems.splice(tlifcIndex + 1, 0, {
      key: ImplantType.TLIFCA,
      value: format.formatImplantTypes(ImplantType.TLIFCA),
    });
  }

  const navigationSystemTypeMenuItems = [
    {
      key: NavigationSystemType.DiscPrep,
      value: format.formatNavigationSystem(NavigationSystemType.DiscPrep),
    },
    {
      key: NavigationSystemType.Interbody,
      value: format.formatNavigationSystem(NavigationSystemType.Interbody),
    },
    {
      key: NavigationSystemType.PosteriorFixation,
      value: format.formatNavigationSystem(NavigationSystemType.PosteriorFixation),
    },
  ];

  const sagittalGoalPrimaryMenuItems = [
    {
      key: SagittalGoalType.AgeAdjusted,
      value: format.formatSagittalGoalType(SagittalGoalType.AgeAdjusted),
    },
    {
      key: SagittalGoalType.MinimalMismatch,
      value: format.formatSagittalGoalType(SagittalGoalType.MinimalMismatch),
    },
    {
      key: SagittalGoalType.GapScore,
      value: format.formatSagittalGoalType(SagittalGoalType.GapScore),
    },
    {
      key: SagittalGoalType.AlternativeSupportivePlanningReference,
      value: format.formatSagittalGoalType(SagittalGoalType.AlternativeSupportivePlanningReference),
    },
    {
      key: SagittalGoalType.Other,
      value: format.formatSagittalGoalType(SagittalGoalType.Other),
    },
  ];

  const sagittalGoalSecondaryMenuItems = [
    {
      key: SagittalGoalType.AgeAdjusted,
      value: format.formatSagittalGoalType(SagittalGoalType.AgeAdjusted),
    },
    {
      key: SagittalGoalType.MinimalMismatch,
      value: format.formatSagittalGoalType(SagittalGoalType.MinimalMismatch),
    },
    {
      key: SagittalGoalType.GapScore,
      value: format.formatSagittalGoalType(SagittalGoalType.GapScore),
    },
  ];

  const coronalGoalTypeMenuItems = [
    {
      key: CoronalGoalType.ParallelDiscSpaceAtTreatmentLevels,
      value: format.formatCoronalGoalType(CoronalGoalType.ParallelDiscSpaceAtTreatmentLevels),
    },
    {
      key: CoronalGoalType.SuperiorEndplateOfHighestTreatedLevelParallelToSacrum,
      value: format.formatCoronalGoalType(
        CoronalGoalType.SuperiorEndplateOfHighestTreatedLevelParallelToSacrum,
      ),
    },
    {
      key: CoronalGoalType.SuperiorL1EndplateParallelToSacrum,
      value: format.formatCoronalGoalType(CoronalGoalType.SuperiorL1EndplateParallelToSacrum),
    },
    {
      key: CoronalGoalType.SuperiorL1EndplateParallelToFloor,
      value: format.formatCoronalGoalType(CoronalGoalType.SuperiorL1EndplateParallelToFloor),
    },
  ];

  const targetHeightMetricTypeOptions = [
    {
      key: TargetHeightMetricType.ImplantHeight,
      value: format.formatTargetHeightMetricType(TargetHeightMetricType.ImplantHeight),
    },
    {
      key: TargetHeightMetricType.DiscSpaceHeight,
      value: format.formatTargetHeightMetricType(TargetHeightMetricType.DiscSpaceHeight),
    },
    {
      key: TargetHeightMetricType.CaseSpecific,
      value: format.formatTargetHeightMetricType(TargetHeightMetricType.CaseSpecific),
    },
  ];

  const implantDirectionMenuItems = [
    {
      key: ImplantDirection.None,
      value: format.formatImplantDirectionOrientation(ImplantDirection.None),
    },
    {
      key: ImplantDirection.Left,
      value: format.formatImplantDirectionOrientation(ImplantDirection.Left),
    },
    {
      key: ImplantDirection.Right,
      value: format.formatImplantDirectionOrientation(ImplantDirection.Right),
    },
    {
      key: ImplantDirection.ApproachFromSideWithShorterDiscHeight,
      value: format.formatImplantDirectionOrientation(
        ImplantDirection.ApproachFromSideWithShorterDiscHeight,
      ),
    },
    {
      key: ImplantDirection.CaseSpecific,
      value: format.formatImplantDirectionOrientation(ImplantDirection.CaseSpecific),
    },
  ];

  const implantPositioningMenuItems = [
    {
      key: PositionDistanceType.AnteriorThird,
      value: format.formatPositionDistanceType(PositionDistanceType.AnteriorThird),
    },
    {
      key: PositionDistanceType.Midline,
      value: format.formatPositionDistanceType(PositionDistanceType.Midline),
    },
    {
      key: PositionDistanceType.PosteriorThird,
      value: format.formatPositionDistanceType(PositionDistanceType.PosteriorThird),
    },
  ];

  const threadAngleOptions = [
    {
      key: ImplantOrientation.CaseSpecific,
      value: format.formatImplantOrientation(ImplantOrientation.CaseSpecific),
    },
    {
      key: ImplantOrientation.Inline,
      value: format.formatImplantOrientation(ImplantOrientation.Inline),
    },
    {
      key: ImplantOrientation.Offset,
      value: format.formatImplantOrientation(ImplantOrientation.Offset),
    },
  ];

  const excludedImplantSizesTypeMenuItems = [
    {
      key: LevelSize.Plus,
      value: format.formatLevelSize(LevelSize.Plus),
    },
    {
      key: LevelSize.Minus,
      value: format.formatLevelSize(LevelSize.Minus),
    },
  ];

  return (
    <Box display={'flex'}>
      <Box width={'calc(100% - 400px)'}>
        <Box>
          <Box display={'flex'} alignItems={'center'} my={2}>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <Typography variant={'body1'}>Sagittal Goal Primary</Typography>
            </Box>
            <Box display={'flex'} width={500} justifyContent={'left'}>
              <SelectField
                name={'sagittalGoalPrimary'}
                label={''}
                hideNone={true}
                menuItems={sagittalGoalPrimaryMenuItems}
                disabled={disabled}
              />
            </Box>
          </Box>
          {formikValues?.sagittalGoalPrimary ===
          SagittalGoalType.AlternativeSupportivePlanningReference ? (
            <Box display={'flex'} alignItems={'center'} my={2}>
              <Box display={'flex'} width={240} justifyContent={'left'}>
                <Typography variant={'body1'}>Sagittal Goal Secondary</Typography>
              </Box>
              <Box display={'flex'} width={500} justifyContent={'left'}>
                <SelectField
                  name={'sagittalGoalSecondary'}
                  label={''}
                  hideNone={true}
                  menuItems={sagittalGoalSecondaryMenuItems}
                  disabled={disabled}
                />
              </Box>
            </Box>
          ) : null}
          {formikValues?.sagittalGoalPrimary === SagittalGoalType.Other ? (
            <Box display={'flex'} alignItems={'center'} my={2}>
              <Box display={'flex'} width={240} justifyContent={'left'}>
                <Typography variant={'body1'}>Sagittal Goal Other</Typography>
              </Box>
              <Box display={'flex'} width={500} justifyContent={'left'}>
                <TextField
                  name={'sagittalGoalOther'}
                  label={''}
                  fullWidth={true}
                  multiline={false}
                  disabled={disabled}
                  shrink={false}
                />
              </Box>
            </Box>
          ) : null}
          <Box display={'flex'} alignItems={'center'} my={2}>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <Typography variant={'body1'}>Coronal Goal Primary</Typography>
            </Box>
            <Box display={'flex'} width={500} justifyContent={'left'}>
              <SelectField
                name={'coronalGoalPrimary'}
                label={''}
                hideNone={false}
                menuItems={coronalGoalTypeMenuItems}
                disabled={disabled}
              />
            </Box>
          </Box>
          <Box display={'flex'} alignItems={'center'} my={2}>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <Typography variant={'body1'}>Coronal Goal Secondary</Typography>
            </Box>
            <Box display={'flex'} width={500} justifyContent={'left'}>
              <SelectField
                name={'coronalGoalSecondary'}
                label={''}
                hideNone={false}
                menuItems={coronalGoalTypeMenuItems}
                disabled={disabled}
              />
            </Box>
          </Box>
          <Box display={'flex'} alignItems={'center'} my={2}>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <Typography variant={'body1'}>Navigation Systems</Typography>
            </Box>
            <Box display={'flex'} width={500} justifyContent={'left'}>
              <SelectField
                name={'navigationSystems'}
                label={''}
                hideNone={true}
                multiple={true}
                menuItems={navigationSystemTypeMenuItems}
                disabled={disabled}
              />
            </Box>
          </Box>
          <Box display={'flex'} alignItems={'center'} my={2}>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <Typography variant={'body1'}>Navigation System Other</Typography>
            </Box>
            <Box display={'flex'} width={500} justifyContent={'left'}>
              <TextField
                name={'navigationSystemOther'}
                label={''}
                fullWidth={true}
                multiline={false}
                disabled={disabled}
                shrink={false}
              />
            </Box>
          </Box>
          <Box display={'flex'} alignItems={'center'} my={2}>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <Typography variant={'body1'}>Target Height Metric</Typography>
            </Box>
            <Box display={'flex'} width={500} justifyContent={'left'}>
              <RadioGroup
                name={`targetHeightMetric`}
                label={''}
                hideLabel={false}
                disabled={disabled}
                row={true}
                options={targetHeightMetricTypeOptions}
                onChange={() => {}}
              />
            </Box>
          </Box>
          <Box display={'flex'} alignItems={'center'} my={2}>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <Typography variant={'body1'}>Excluded Instruments</Typography>
            </Box>
            <Box display={'flex'} width={500} justifyContent={'left'}>
              <SelectField
                name={'excludedInstruments'}
                label={''}
                hideNone={true}
                multiple={true}
                menuItems={excludableInstruments}
                disabled={disabled}
              />
            </Box>
          </Box>
          {hasFeatureFlag?.(FeatureFlag.reusableInstrumentsEnabled) ? (
            <Box display={'flex'} alignItems={'center'} my={2}>
              <Box display={'flex'} width={240} justifyContent={'left'}>
                <Typography variant={'body1'}>Reusable Instruments</Typography>
              </Box>
              <Box display={'flex'} width={500} justifyContent={'left'}>
                <Checkbox name={`reusableInstruments`} label={''} disabled={disabled} />
              </Box>
            </Box>
          ) : null}
          <Box display={'flex'} alignItems={'flex-start'} my={2}>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <Typography variant={'body1'}>General Note</Typography>
            </Box>
            <Box display={'flex'} width={500} justifyContent={'left'}>
              <TextField
                name={'note'}
                label={''}
                fullWidth={true}
                multiline={true}
                disabled={disabled}
              />
            </Box>
          </Box>
          <Box display={'flex'} alignItems={'center'} my={2}>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <Typography variant={'body1'}>Preferred Procedures</Typography>
            </Box>
            <Box display={'flex'} width={500} justifyContent={'left'}>
              <CheckboxListField
                label={''}
                name={'preferredProcedures'}
                disabled={disabled}
                listItems={preferredProceduresTypeListItems}
              />
            </Box>
          </Box>
          {formikValues?.preferredProcedures?.length ? (
            <>
              <Box my={3} />
              <FieldArray name="surgeonPreferenceImplants">
                {() => {
                  return (
                    <Box>
                      {formikValues?.surgeonPreferenceImplants?.map(
                        (
                          selectedImplantType: SurgeonPreferenceImplantType,
                          implantTypeIndex: number,
                        ) => {
                          return formikValues?.preferredProcedures?.includes(
                            selectedImplantType?.implantType,
                          ) ? (
                            <Box mb={4}>
                              <Box display={'flex'} my={4}>
                                <Typography variant={'h4'}>
                                  {format.formatImplantTypes(selectedImplantType?.implantType)}
                                </Typography>
                              </Box>
                              <Box display={'flex'} alignItems={'center'} my={2}>
                                <Box display={'flex'} width={240} justifyContent={'left'}>
                                  <Typography variant={'body1'}>Position Distance</Typography>
                                </Box>
                                {selectedImplantType?.implantType === ImplantType.LLIF ? (
                                  <Box display={'flex'} width={500} justifyContent={'left'}>
                                    <SelectField
                                      name={`surgeonPreferenceImplants[${implantTypeIndex}].positioning`}
                                      label={''}
                                      hideNone={true}
                                      menuItems={implantPositioningMenuItems}
                                      disabled={disabled}
                                    />
                                  </Box>
                                ) : (
                                  <Box display={'flex'} width={500} justifyContent={'left'}>
                                    <Typography variant={'body1'}>
                                      {format.formatPositionDistanceType(
                                        selectedImplantType?.positioning,
                                      )}
                                    </Typography>
                                  </Box>
                                )}
                              </Box>
                              {![ImplantType.ALIF, ImplantType.ALIFX].includes(
                                selectedImplantType?.implantType,
                              ) ? (
                                <Box display={'flex'} alignItems={'center'} my={2}>
                                  <Box display={'flex'} width={240} justifyContent={'left'}>
                                    <Typography variant={'body1'}>Direction</Typography>
                                  </Box>
                                  <Box display={'flex'} width={500} justifyContent={'left'}>
                                    <SelectField
                                      name={`surgeonPreferenceImplants[${implantTypeIndex}].direction`}
                                      label={''}
                                      hideNone={true}
                                      menuItems={implantDirectionMenuItems}
                                      disabled={disabled}
                                    />
                                  </Box>
                                </Box>
                              ) : null}
                              {isTlifCOrientationAvailable() &&
                              [ImplantType.TLIFC].includes(selectedImplantType?.implantType) ? (
                                <Box display={'flex'} alignItems={'center'} my={2}>
                                  <Box display={'flex'} width={240} justifyContent={'left'}>
                                    <Typography variant={'body1'}>Thread Angle</Typography>
                                  </Box>
                                  <Box display={'flex'} width={500} justifyContent={'left'}>
                                    <RadioGroup
                                      name={`surgeonPreferenceImplants[${implantTypeIndex}].orientation`}
                                      label={''}
                                      hideLabel={false}
                                      disabled={disabled}
                                      row={true}
                                      options={threadAngleOptions}
                                      onChange={() => {}}
                                    />
                                  </Box>
                                </Box>
                              ) : null}
                              {props.isTlifCArticulatingStarted &&
                              [ImplantType.TLIFCA].includes(selectedImplantType?.implantType) ? (
                                <Box display={'flex'} alignItems={'center'} my={2}>
                                  <Box display={'flex'} width={240} justifyContent={'left'}>
                                    <Typography variant={'body1'}>Thread Angle</Typography>
                                  </Box>
                                  <Box display={'flex'} width={500} justifyContent={'left'}>
                                    <RadioGroup
                                      name={`surgeonPreferenceImplants[${implantTypeIndex}].orientation`}
                                      label={''}
                                      hideLabel={false}
                                      disabled={disabled}
                                      row={true}
                                      options={threadAngleOptions}
                                      onChange={() => {}}
                                    />
                                  </Box>
                                </Box>
                              ) : null}
                              {selectedImplantType?.implantType === ImplantType.ALIFX ? (
                                <AlifxScrewOrientationEditView
                                  implantTypeIndex={implantTypeIndex}
                                  orientation={
                                    selectedImplantType?.orientation as ImplantOrientation
                                  }
                                  disabled={disabled}
                                />
                              ) : null}
                              {[ImplantType.ALIF, ImplantType.ALIFX, ImplantType.LLIF].includes(
                                selectedImplantType?.implantType,
                              ) ? (
                                <Box display={'flex'} alignItems={'center'} my={2}>
                                  <Box display={'flex'} width={240} justifyContent={'left'}>
                                    <Typography variant={'body1'}>Maximize Footprint</Typography>
                                  </Box>
                                  <Box display={'flex'} width={500} justifyContent={'left'}>
                                    <Checkbox
                                      name={`surgeonPreferenceImplants[${implantTypeIndex}].maximizeFootprint`}
                                      label={''}
                                      disabled={disabled}
                                    />
                                  </Box>
                                </Box>
                              ) : null}
                              <Box display={'flex'} alignItems={'center'} my={2}>
                                <Box display={'flex'} width={240} justifyContent={'left'}>
                                  <Typography variant={'body1'}>Plus Size Implant</Typography>
                                </Box>
                                <Box display={'flex'} width={500} justifyContent={'left'}>
                                  <RadioGroup
                                    name={`surgeonPreferenceImplants[${implantTypeIndex}].plusSizeImplant`}
                                    label={''}
                                    hideLabel={false}
                                    disabled={disabled}
                                    row={true}
                                    options={plusSizeImplantOptions}
                                    onChange={(e) => {
                                      const plusSizeImplant = e.target.value;
                                      setFieldValue(
                                        `surgeonPreferenceImplants[${implantTypeIndex}].plusSizeImplant`,
                                        Number(plusSizeImplant),
                                      );
                                    }}
                                  />
                                </Box>
                              </Box>
                              {hasFeatureFlag?.(FeatureFlag.implantSizeExclusionEnabled) ? (
                                <Box display={'flex'} alignItems={'center'} my={2}>
                                  <Box display={'flex'} width={240} justifyContent={'left'}>
                                    <Typography variant={'body1'}>
                                      Excluded Implant Sizes
                                    </Typography>
                                  </Box>
                                  <Box display={'flex'} width={500} justifyContent={'left'}>
                                    <SelectField
                                      name={`surgeonPreferenceImplants[${implantTypeIndex}].excludedImplantSizes`}
                                      label={''}
                                      hideNone={false}
                                      multiple={false}
                                      menuItems={excludedImplantSizesTypeMenuItems}
                                      disabled={disabled}
                                    />
                                  </Box>
                                </Box>
                              ) : null}
                              <Box display={'flex'} alignItems={'center'} my={2}>
                                <Box display={'flex'} width={240} justifyContent={'left'}>
                                  <Typography variant={'body1'}>
                                    Minimal Height Specification
                                  </Typography>
                                </Box>
                                <Box display={'flex'} width={500} justifyContent={'left'}>
                                  <Checkbox
                                    name={`surgeonPreferenceImplants[${implantTypeIndex}].minimalHeightSpecification`}
                                    label={''}
                                    disabled={disabled}
                                  />
                                </Box>
                              </Box>
                              <Box display={'flex'} alignItems={'flex-start'} my={2}>
                                <Box display={'flex'} width={240} justifyContent={'left'}>
                                  <Typography variant={'body1'}>Procedure Note</Typography>
                                </Box>
                                <Box display={'flex'} width={500} justifyContent={'left'}>
                                  <TextField
                                    name={`surgeonPreferenceImplants[${implantTypeIndex}].note`}
                                    label={''}
                                    fullWidth={true}
                                    multiline={true}
                                    multiLineRows={2}
                                    disabled={disabled}
                                  />
                                </Box>
                              </Box>
                              <FieldArray name={`surgeonPreferenceImplantMeasurements`}>
                                {() => {
                                  return (
                                    <SurgeonPreferencesMeasurementEditTable
                                      surgeonPreferenceImplantMeasurements={
                                        formikValues?.surgeonPreferenceImplantMeasurements
                                      }
                                      selectedImplantType={selectedImplantType.implantType}
                                      implantTypeIndex={implantTypeIndex}
                                      disabled={disabled}
                                    />
                                  );
                                }}
                              </FieldArray>
                            </Box>
                          ) : null;
                        },
                      )}
                    </Box>
                  );
                }}
              </FieldArray>
            </>
          ) : null}
        </Box>
      </Box>
      <Box width={400} mx={2}>
        <Typography>
          {props.errors.length ? (
            <strong>Issues</strong>
          ) : (
            <Alert severity={'success'}>No issues found</Alert>
          )}
        </Typography>
        {!props.loading ? (
          <ul style={{ listStyle: 'none' }}>
            {props.errors.map((surgeonPreferencesError, index) => (
              <li key={index}>
                <Typography
                  gutterBottom={true}
                  style={{
                    color: theme.palette.error.main,
                  }}
                  data-testid={surgeonPreferencesError.type}
                >
                  {surgeonPreferencesUtils.getSurgeonPreferencesErrorDescription(
                    surgeonPreferencesError,
                  )}
                </Typography>
              </li>
            ))}
          </ul>
        ) : null}
        {props.loading ? (
          <ul style={{ listStyle: 'none' }}>
            <li>
              <Skeleton height={25} />
            </li>
            <li>
              <Skeleton height={25} />
            </li>
            <li>
              <Skeleton height={25} />
            </li>
          </ul>
        ) : null}
      </Box>
    </Box>
  );
}
