import config from '../../../extras/config';
import { faCloud } from '@fortawesome/pro-solid-svg-icons';

export const getLoginProvider = () => {
  const getAuthUrlForProvider = (identityProvider: string) =>
    `${config.auth.oauth.url}/oauth2/authorize?identity_provider=${identityProvider}&client_id=${config.auth.clientId}&response_type=${config.auth.responseType}&scope=${config.auth.scope}&redirect_uri=${config.auth.redirectUri}`;

  return {
    name: 'JUMPCLOUD',
    label: 'Log in with JumpCloud',
    icon: faCloud,
    authUrl: getAuthUrlForProvider(config.auth.oauth.idp.jumpCloud),
  };
};
