import { IPlanImplant, LevelType } from '@workflow-nx/common';
import { Scene } from 'babylonjs';
import VisibilityView from './VisibilityView';

export function ImplantVisibilityView(props: {
  planImplant: IPlanImplant;
  levelType: LevelType;
  scene: Scene;
}) {
  const meshName = `${props.levelType}_CYBORG_IMPLANT`;

  return <VisibilityView {...props} label="Implant" meshName={meshName} />;
}
