import {
  ActivityType,
  AssetType,
  CaseStageType,
  format,
  IActivity,
  IAsset,
  ICaseTag,
} from '@workflow-nx/common';
import React, { useState } from 'react';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  APPROVE_DAISY_SEGMENTATION,
  APPROVE_WITH_CHANGES_DAISY_SEGMENTATION,
  FIND_ACTIVITIES,
  FIND_ASSETS_WITH_URL,
  REJECT_DAISY_SEGMENTATION,
} from '../../../../gql';
import { Alert, Box, Skeleton, Stack } from '@mui/material';
import ActionButton from '../../../../components/ActionButton';
import { ApprovedWithChangesDaisySegmentationReasonDialog } from './ApprovedWithChangesDaisySegmentationReasonDialog';
import { RejectDaisySegmentationReasonDialog } from './RejectDaisySegmentationReasonDialog';
import * as FileSaver from 'file-saver';
import { file } from '@workflow-nx/utils';
import { ProgressDialog } from '@workflow-nx/ui';

export function DaisySegmentationCompleteView(props: {
  caseId: number;
  caseNumber: string;
  caseTags: ICaseTag[];
  caseStage: CaseStageType;
}) {
  const [showApprovedWithChangesSegmentationDialog, setShowApprovedWithChangesSegmentationDialog] =
    useState(false);
  const [showRejectSegmentationDialog, setShowRejectSegmentationDialog] = useState(false);
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const [progressState, setProgressState] = useState('');
  const [showDownloadAllProgressDialog, setShowDownloadAllProgressDialog] = useState(false);
  const [lastActivity, setLastActivity] = useState<IActivity | undefined>(undefined);

  const [approveDaisySegmentation, { loading: loadingApproveDaisySegmentation }] = useMutation(
    APPROVE_DAISY_SEGMENTATION,
  );

  const [
    approveWithChangesDaisySegmentation,
    { loading: loadingApproveWithChangesDaisySegmentation },
  ] = useMutation(APPROVE_WITH_CHANGES_DAISY_SEGMENTATION);

  const [rejectDaisySegmentation, { loading: loadingRejectDaisySegmentation }] =
    useMutation(REJECT_DAISY_SEGMENTATION);

  const [findSegmentedAssets] = useLazyQuery(FIND_ASSETS_WITH_URL, {
    fetchPolicy: 'network-only',
    variables: {
      caseId: props.caseId,
      assetTypeFilter: [
        AssetType.L1Daisy,
        AssetType.L2Daisy,
        AssetType.L3Daisy,
        AssetType.L4Daisy,
        AssetType.L5Daisy,
        AssetType.L6Daisy,
        AssetType.S1Daisy,
        AssetType.HardwareDaisy,
        AssetType.DicomCtDaisy,
      ],
    },
  });

  const { loading: loadingFindActivities, refetch } = useQuery(FIND_ACTIVITIES, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      caseId: props.caseId,
      activityTypeFilter: [
        ActivityType.CaseAutoSegmentationApproved,
        ActivityType.CaseAutoSegmentationApprovedWithChanges,
        ActivityType.CaseAutoSegmentationRejected,
      ],
    },
    onCompleted: (data) => {
      const activity = (data?.activities?.activities ?? [])?.[0];

      setLastActivity(activity);
    },
  });

  const handleDownloadAll = async () => {
    try {
      setShowDownloadAllProgressDialog(true);
      const assetsToZip = [];

      const { data } = await findSegmentedAssets();

      for (let i = 0; i < data.assets.length; i++) {
        setProgressState(`Downloading ${i + 1} of ${data.assets.length} files`);
        const asset = data.assets[i];

        if (asset) {
          const response = await file.downloadFile(asset.signedDownloadUrl);

          assetsToZip.push({
            blob: new Blob([response.data], { type: 'octet/stream' }),
            name: `${asset.fileName}`,
          });
        }
      }

      setProgressState(`Compressing files`);

      const zipFile = await file.createZipFile(assetsToZip);

      FileSaver.saveAs(zipFile, `${props.caseNumber}-daisy-segmented-assets.zip`);
    } catch (e) {
      enqueueSnackbar('Error Downloading File', {
        variant: 'error',
      });
    } finally {
      setShowDownloadAllProgressDialog(false);
      setProgressState('');
    }
  };

  const handleApproveDaisySegmentationClick = async () => {
    try {
      await confirm({
        title: `Approve Daisy segmentation ${props.caseNumber}?`,
        description: (
          <>
            This will copy the Daisy auto-segmentation assets to the case and overwrite the existing
            assets (if any). Continue?
          </>
        ),
      });

      await approveDaisySegmentation({
        variables: {
          caseId: props.caseId,
        },
      });

      refetch();

      enqueueSnackbar(`The Daisy segmentation has been approved`, { variant: 'success' });
    } catch (e) {
      console.error(e);
      enqueueSnackbar('An error occurred approving the Daisy segmentation', { variant: 'error' });
    }
  };

  const handleApproveWithChangesDaisySegmentationClick = async (reason: string) => {
    try {
      await approveWithChangesDaisySegmentation({
        variables: {
          caseId: props.caseId,
          reason,
        },
      });

      enqueueSnackbar(`The Daisy segmentation has been approved but needs changes`, {
        variant: 'success',
      });

      refetch();
    } catch (e) {
      console.error(e);
      enqueueSnackbar('An error occurred approving the Daisy segmentation with changes', {
        variant: 'error',
      });
    } finally {
      setShowApprovedWithChangesSegmentationDialog(false);
    }
  };

  const handleRejectDaisySegmentationClick = async (reason: string, tags: number[]) => {
    try {
      await rejectDaisySegmentation({
        variables: {
          caseId: props.caseId,
          reason,
          tags,
        },
      });

      enqueueSnackbar(`The Daisy segmentation has been rejected`, { variant: 'success' });

      refetch();
    } catch (e) {
      console.error(e);
      enqueueSnackbar('An error occurred rejecting the Daisy segmentation', { variant: 'error' });
    } finally {
      setShowRejectSegmentationDialog(false);
    }
  };

  const loading = loadingFindActivities;

  const disabledButtons =
    showApprovedWithChangesSegmentationDialog ||
    showRejectSegmentationDialog ||
    showDownloadAllProgressDialog ||
    loadingApproveDaisySegmentation ||
    loadingApproveWithChangesDaisySegmentation ||
    loadingRejectDaisySegmentation;

  return !loading ? (
    <>
      {!!lastActivity ? (
        <>
          {lastActivity.activityType === ActivityType.CaseAutoSegmentationApproved ? (
            <Alert severity={'success'} sx={{ mb: 1 }}>
              Auto-segmentation was approved on {format.formatDateTime(lastActivity.createdAt)} by{' '}
              {format.formatName(lastActivity.createdByUser)}
            </Alert>
          ) : null}
          {lastActivity.activityType === ActivityType.CaseAutoSegmentationApprovedWithChanges ? (
            <Alert severity={'success'} sx={{ mb: 1 }}>
              Auto-segmentation was approved with changes on{' '}
              {format.formatDateTime(lastActivity.createdAt)} by{' '}
              {format.formatName(lastActivity.createdByUser)} for the reason:
              <Box sx={{ mt: 1 }}>&ldquo;{lastActivity.delta.reason}&rdquo;</Box>
            </Alert>
          ) : null}
          {lastActivity.activityType === ActivityType.CaseAutoSegmentationRejected ? (
            <Alert severity={'error'} sx={{ mb: 1 }}>
              Auto-segmentation was rejected on {format.formatDateTime(lastActivity.createdAt)} by{' '}
              {format.formatName(lastActivity.createdByUser)} for the reason:
              <Box sx={{ mt: 1 }}>&ldquo;{lastActivity.delta.reason}&rdquo;</Box>
            </Alert>
          ) : null}
        </>
      ) : null}
      <Box sx={{ my: 1 }}>
        <ActionButton
          fullWidth={true}
          color={'primary'}
          loading={showDownloadAllProgressDialog}
          variant={'text'}
          disabled={
            loadingApproveDaisySegmentation ||
            loadingApproveWithChangesDaisySegmentation ||
            loadingRejectDaisySegmentation
          }
          label={'Download Assets'}
          onClick={handleDownloadAll}
        >
          Download Assets
        </ActionButton>
      </Box>
      {props.caseStage === CaseStageType.Segmentation ? (
        <>
          {!lastActivity && !showDownloadAllProgressDialog ? (
            <Stack direction={'column'} spacing={1} sx={{ m: 1 }}>
              <ActionButton
                fullWidth={true}
                color={'primary'}
                loading={loadingApproveDaisySegmentation}
                disabled={disabledButtons}
                variant={'outlined'}
                label={'Approve'}
                onClick={handleApproveDaisySegmentationClick}
              >
                Approve
              </ActionButton>
              <ActionButton
                fullWidth={true}
                color={'primary'}
                loading={loadingApproveWithChangesDaisySegmentation}
                disabled={disabledButtons}
                variant={'outlined'}
                label={'Approve With Changes'}
                onClick={() => setShowApprovedWithChangesSegmentationDialog(true)}
              >
                Approve With Changes
              </ActionButton>
              <ActionButton
                fullWidth={true}
                color={'primary'}
                loading={loadingRejectDaisySegmentation}
                disabled={disabledButtons}
                variant={'outlined'}
                label={'Reject'}
                onClick={() => setShowRejectSegmentationDialog(true)}
              >
                Reject
              </ActionButton>
            </Stack>
          ) : null}
          <ApprovedWithChangesDaisySegmentationReasonDialog
            open={showApprovedWithChangesSegmentationDialog}
            title={`Approve Daisy Segmentation With Changes?`}
            labelText={'Approve With Changes'}
            description={`Please include the reason for the approval with changes of the Daisy case segmentation.`}
            onComplete={handleApproveWithChangesDaisySegmentationClick}
            onClose={() => {
              setShowApprovedWithChangesSegmentationDialog(false);
            }}
          />
          <RejectDaisySegmentationReasonDialog
            caseTags={props.caseTags}
            open={showRejectSegmentationDialog}
            title={`Reject Daisy Segmentation?`}
            labelText={'Reject'}
            description={`Please include the reason for the rejection of the Daisy case segmentation.`}
            onComplete={handleRejectDaisySegmentationClick}
            onClose={() => {
              setShowRejectSegmentationDialog(false);
            }}
          />
        </>
      ) : null}
      <ProgressDialog open={showDownloadAllProgressDialog} progressState={progressState} />
    </>
  ) : (
    <Skeleton component={'div'} />
  );
}
