import { TableRow } from '@mui/material';
import { TableCell } from '../../../../components/TableCell';
import { TextField } from '@workflow-nx/ui';
import React from 'react';

export function Form19NoteTableRow(props: {
  value: any;
  levelIndex: number;
  valueIndex: number;
  disabled: boolean;
}) {
  return (
    <TableRow hover>
      <TableCell>Note</TableCell>
      <TableCell colSpan={3}>
        <TextField
          name={`levels[${props.levelIndex}].values[${props.valueIndex}].notes`}
          label={''}
          multiline={true}
          disabled={props.disabled}
        />
      </TableCell>
    </TableRow>
  );
}
