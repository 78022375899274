import React from 'react';
import { Card, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Heading } from '../../components/Heading';

const useStyles = makeStyles<Theme, { fitContentHeight: boolean }>((theme: Theme) => ({
  root: {
    height: (props) => (props.fitContentHeight ? 'fit-content' : 400),
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: theme.spacing(2),
  },
}));

export function DashboardCardView(props: {
  label: string;
  children: React.ReactNode;
  fitContentHeight?: boolean;
}) {
  const styles = useStyles({ fitContentHeight: Boolean(props.fitContentHeight) });

  return (
    <>
      <Heading label={props.label} />
      <Card>
        <div className={styles.root}>{props.children}</div>
      </Card>
    </>
  );
}
