import { CaseRiskAssessmentType, CaseReportStatusType } from '@workflow-nx/common';
import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Resolver, useForm, Control, FieldValues } from 'react-hook-form';
import { TextFieldRHF } from '@workflow-nx/ui';
import { FormActionType } from './CaseReportDialog.reducer';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

interface CaseReportReviewFormProps {
  riskAssessment?: CaseRiskAssessmentType;
  reportStatus?: CaseReportStatusType;
  reportReviewNote?: string;
  disabled: boolean;
  onActionSubmit: (actionType: FormActionType, data: CaseReportReviewFormValues) => void;
}

interface CaseReportReviewFormValues {
  reportReviewNote: string;
}

export const CaseReportReviewForm: React.FC<CaseReportReviewFormProps> = ({
  riskAssessment,
  reportStatus,
  reportReviewNote,
  disabled,
  onActionSubmit,
}) => {
  const caseReportReviewFormSchema = Yup.object().shape({
    reportReviewNote: Yup.string().trim(),
  });

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm<CaseReportReviewFormValues>({
    defaultValues: {
      reportReviewNote: reportReviewNote || '',
    },
    resolver: yupResolver(
      caseReportReviewFormSchema,
    ) as unknown as Resolver<CaseReportReviewFormValues>,
  });

  const handleRejectReview = (data: CaseReportReviewFormValues) => {
    if (!data.reportReviewNote.trim()) {
      setError('reportReviewNote', {
        type: 'required',
        message: 'Note is required when rejecting the report review.',
      });
      return;
    }
    onActionSubmit(FormActionType.RejectCaseReportPeerReview, data);
  };

  return (
    <form
      onSubmit={handleSubmit((data) =>
        onActionSubmit(FormActionType.ApproveCaseReportPeerReview, data),
      )}
    >
      <Box display={'flex'} alignItems={'center'} mt={4}>
        <Box display={'flex'} justifyContent={'left'}>
          <Typography variant={'h5'}>
            <strong>Case Report Approval Section</strong>
          </Typography>
        </Box>
      </Box>
      <Box display={'flex'} alignItems={'flex-start'} my={2} gap={1}>
        <Box display={'flex'} width={240} justifyContent={'left'}>
          <Typography variant={'body1'}>Case Report Reviewer Note</Typography>
        </Box>
        <Box display={'flex'} width={650} justifyContent={'left'}>
          <TextFieldRHF
            name={'reportReviewNote'}
            control={control as unknown as Control<FieldValues>}
            label={''}
            fullWidth={true}
            multiline={true}
            disabled={disabled || reportStatus === CaseReportStatusType.Approved}
          />
        </Box>
        <Box display={'flex'} width={240} justifyContent={'left'}>
          <Typography variant={'caption'} color={'error'}>
            {errors.reportReviewNote ? errors.reportReviewNote.message : null}
          </Typography>
        </Box>
      </Box>
      <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
        <Box display={'flex'} width={240} justifyContent={'left'}></Box>
        <Box display={'flex'} width={650} justifyContent={'right'}>
          <Button
            variant={'outlined'}
            onClick={handleSubmit((data) =>
              onActionSubmit(FormActionType.ApproveCaseReportPeerReview, data),
            )}
            disabled={disabled || reportStatus === CaseReportStatusType.Approved}
          >
            Approve
          </Button>
          <Box ml={2}>
            <Button
              variant={'outlined'}
              onClick={handleSubmit(handleRejectReview)}
              disabled={
                disabled ||
                reportStatus === CaseReportStatusType.Approved ||
                reportStatus === CaseReportStatusType.Rejected
              }
            >
              Reject
            </Button>
          </Box>
        </Box>
        <Box display={'flex'} width={240} justifyContent={'left'}></Box>
      </Box>
    </form>
  );
};
