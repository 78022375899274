import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import { useMutation, useQuery } from '@apollo/client';
import { FIND_USERS, CREATE_TASK } from '../../../../gql';
import {
  createTaskDialogFormValues,
  ICreateTaskDialogFormValues,
} from '../../../../extras/formValues';
import { createTaskDialogSchema } from '../../../../extras/schemas';
import CustomDialog from '../../../../components/CustomDialog';
import { ProgressButton, SelectField, TextField } from '@workflow-nx/ui';
import { UserRoleType, format, TaskPriorityType, ICase, TaskStatusType } from '@workflow-nx/common';
import { Grid } from '@mui/material';
import { DatePickerField } from '../../../../components/DatePickerField';
import { DateTime } from 'luxon';

export function CreateTaskDialog(props: {
  open: boolean;
  activeCase: ICase;
  onCreate: (taskId: string) => void;
  onClose: () => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [fieldReps, setFieldReps] = useState<{ key: string; value: string }[]>([]);
  const roleFilter = [UserRoleType.FieldRep];
  useQuery(FIND_USERS, {
    fetchPolicy: 'network-only',
    variables: { roleFilter },
    onCompleted: (data) => {
      const users = data.users;

      if (users?.count > 0) {
        const fieldReps: { key: string; value: string }[] = [];
        for (const user of users.users) {
          fieldReps.push({ key: user.userId, value: format.formatName(user, true) });
        }
        setFieldReps(fieldReps);
      }
    },
  });
  const [createTask, { loading: loadingCreateTask }] = useMutation(CREATE_TASK);

  const taskPriorityType = [
    { key: TaskPriorityType.High, value: format.formatTaskPriorityType(TaskPriorityType.High) },
    { key: TaskPriorityType.Medium, value: format.formatTaskPriorityType(TaskPriorityType.Medium) },
    { key: TaskPriorityType.Low, value: format.formatTaskPriorityType(TaskPriorityType.Low) },
  ];

  const handleSubmitForm = async (
    values: FormikValues,
    { setStatus, setSubmitting, resetForm }: FormikHelpers<ICreateTaskDialogFormValues>,
  ) => {
    try {
      const dueDate = values.dueDate ? DateTime.fromJSDate(values.dueDate).toISODate() : undefined;
      const { data: createTaskData } = await createTask({
        variables: {
          caseId: props.activeCase.caseId,
          assignedId: Number(values.assignedId),
          description: values.description,
          status: TaskStatusType.Open,
          statusDescription: undefined,
          dueDate: dueDate || null,
          priority: values.priority,
        },
      });

      const taskId = createTaskData.createTask.task.taskId;

      setStatus({ success: true });
      enqueueSnackbar('Task Created', {
        variant: 'success',
      });

      props.onCreate(taskId);
    } catch (err) {
      console.error(err);
      setStatus({ success: false });
      enqueueSnackbar('An error occurred creating the task', {
        variant: 'error',
      });
    } finally {
      setSubmitting(false);
      resetForm();
    }
  };

  return (
    <Formik
      initialValues={createTaskDialogFormValues()}
      validationSchema={createTaskDialogSchema}
      onSubmit={handleSubmitForm}
    >
      {({ resetForm, submitForm, isSubmitting }) => {
        const loading = loadingCreateTask;

        return (
          <CustomDialog
            maxWidth={'md'}
            open={props.open}
            title={'Create Task'}
            onClose={() => {
              resetForm();
              props.onClose();
            }}
            positiveActionButtons={[
              <ProgressButton
                variant={'contained'}
                disabled={isSubmitting || loading}
                onClick={() => submitForm()}
                label={'Create'}
                loading={isSubmitting || loading}
              />,
            ]}
          >
            <form>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    name={'description'}
                    label={'Task Description'}
                    multiline={true}
                    disabled={isSubmitting}
                  />
                </Grid>
                <Grid item xs={4}>
                  <SelectField
                    name={'assignedId'}
                    label={'Assigned To'}
                    hideNone={true}
                    menuItems={fieldReps}
                    disabled={isSubmitting}
                  />
                </Grid>
                <Grid item xs={4}>
                  <SelectField
                    name={'priority'}
                    label={'Priority'}
                    hideNone={true}
                    menuItems={taskPriorityType}
                    disabled={isSubmitting}
                  />
                </Grid>
                <Grid item xs={4}>
                  <DatePickerField
                    disabled={isSubmitting}
                    name="dueDate"
                    required={false}
                    label={'Due Date'}
                  />
                </Grid>
                <Grid item xs={12} md={6} />
              </Grid>
            </form>
          </CustomDialog>
        );
      }}
    </Formik>
  );
}
