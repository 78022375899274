import { useLazyQuery } from '@apollo/client';
import { gql } from '@apollo/client/core';
import {
  AssetType,
  CaseSpineType,
  caseUtils,
  IAsset,
  ICase,
  IMeasure,
  IPlan,
  LevelType,
  PartType,
  PlanAssetColorType,
  planUtils,
  VertebralBody,
} from '@workflow-nx/common';
import {
  BabylonSceneManager,
  LandmarkConfig,
  LoadedAssetConfig,
  LoadedSpineConfig,
  SpineAssetTags,
} from '@workflow-nx/scene';
import { sortBy } from 'lodash';
import { createContext, ReactNode, useEffect, useState } from 'react';
import { FIND_PLAN } from '../../../../../gql';

export const SPINE_CAMERA_DISTANCE = 400;
export const IMPLANT_CAMERA_DISTANCE = 200;

const FIND_ASSETS_QUERY = gql`
  query FindAssets(
    $caseId: Int!
    $planId: Int
    $showDeleted: Boolean
    $assetTypeFilter: [AssetType]
  ) {
    staticAssets: assets(caseId: $caseId, showDeleted: $showDeleted, assetTypeFilter: PELVIS) {
      assetId
      assetType
      fileName
      signedDownloadUrl
    }
    planAssets: assets(
      caseId: $caseId
      planId: $planId
      showDeleted: $showDeleted
      assetTypeFilter: $assetTypeFilter
    ) {
      assetId
      assetType
      fileName
      signedDownloadUrl
    }
    planMeasurements: measurements(caseId: $caseId, planId: $planId) {
      body
      endPlate
      position
      point
      source
    }
    preopAssets: assets(
      caseId: $caseId
      showDeleted: $showDeleted
      assetTypeFilter: $assetTypeFilter
    ) {
      assetId
      assetType
      fileName
      signedDownloadUrl
    }
    preopMeasurements: measurements(caseId: $caseId) {
      body
      endPlate
      position
      point
      source
    }
  }
`;

function getLevelState(tags: string[], scene: BabylonSceneManager) {
  let levelState: {
    level: LevelType;
    isChecked: boolean;
  }[] = [];

  if (scene) {
    scene.getMeshesByTags(tags.join(' && ')).forEach((meshData) => {
      const levelType = meshData.mesh.meshName
        .replace('_APP', '')
        .replace('_IMPLANT_SCREW', '') as LevelType;

      const existingLevelState = levelState.find((level) => level.level === levelType);

      if (!existingLevelState) {
        levelState.push({
          level: levelType,
          isChecked: meshData.opacity > 0,
        });
      }
    });

    levelState = sortBy(levelState, ['level']);
  }
  return levelState;
}

function toggleMesh(tags: string[], visibility: number, scene: BabylonSceneManager) {
  scene.updateMeshesByTags(tags, { opacity: visibility }, true);
}

function setTarget(targetNames: string[], scene: BabylonSceneManager) {
  const targetMesh = scene?.getMeshesByTags((targetNames || []).join(' && '))?.[0];

  if (targetMesh) {
    scene.setCameraTarget(targetMesh.mesh);
  }
}

export type PlanViewDialogLevelState = {
  planVisible: boolean;
  hardwareVisible: boolean;
  implants: {
    level: LevelType;
    isChecked: boolean;
  }[];
  plan: {
    level: LevelType;
    isChecked: boolean;
  }[];
  preopVisible: boolean;
  preop: {
    level: LevelType;
    isChecked: boolean;
  }[];
  daisyVisible: boolean;
  daisy: {
    level: LevelType;
    isChecked: boolean;
  }[];
  planDiff: {
    level: LevelType;
    isChecked: boolean;
  }[];
};

export type PlanViewDialogImplantState = {
  screwLength: number | undefined;
  part: PartType | undefined;
  ap: number | undefined;
  ml: number | undefined;
  level: LevelType | null;
  screwsVisible?: boolean;
};

const convertToLandmarkConfig = (m: IMeasure, tags: string[]): LandmarkConfig => {
  return {
    endPlate: m.endPlate,
    landmarkPosition: m.position,
    point: {
      x: m.point[0],
      y: m.point[1],
      z: m.point[2],
    },
    vertebralBody: m.body,
    meshId: {
      meshName: m.body,
      tags,
    },
  };
};

export const PlanViewDialogContext = createContext({
  opacity: 1,
  loading: false,
  loadPlanDiff: async (diffPlanId: number): Promise<void> => {},
  loadAssets: async (): Promise<void> => {},
  handleSceneReady: (scene: BabylonSceneManager): void => {},
  plan: null as IPlan | null,
  planDiff: null as IPlan | null,
  scene: undefined as BabylonSceneManager | undefined,
  toggleImplant: (implantLevel: LevelType | null): void => {},
  toggleMeasurements: (): void => {},
  toggleOpacity: (opacity: number): void => {},
  toggleScrews: (): void => {},
  toggleVisibility: (tags: string[]): void => {},
  showMeasurements: true,
  switchCamera: (cameraView: 'AXIAL' | 'CORONAL' | 'SAGITTAL_LEFT' | 'SAGITTAL_RIGHT'): void => {},
  implantState: { level: null } as PlanViewDialogImplantState,
  levelState: {
    opacity: 1,
    measurementsVisible: false,
    planVisible: false,
    preopVisible: false,
    daisyVisible: false,
    hardwareVisible: false,
    implants: [],
    plan: [],
    preop: [],
    daisy: [],
    planDiff: [],
  } as PlanViewDialogLevelState,
});

export const PlanViewDialogProvider = ({
  activeCase,
  plan,
  showDaisy,
  children,
}: {
  activeCase: ICase;
  plan: IPlan | null;
  showDaisy: boolean;
  children: ReactNode;
}) => {
  const defaultTarget =
    activeCase.spineType === CaseSpineType.Lumbar ? VertebralBody.L3 : VertebralBody.C4;
  const [implantState, setImplantState] = useState<PlanViewDialogImplantState>({
    screwLength: undefined,
    part: undefined,
    ap: undefined,
    ml: undefined,
    level: null,
  });
  const [levelState, setLevelState] = useState<PlanViewDialogLevelState>({
    implants: [],
    planVisible: !!plan,
    plan: [],
    planDiff: [],
    preopVisible: !plan,
    preop: [],
    daisyVisible: !plan,
    daisy: [],
    hardwareVisible: !plan,
  });
  const [showMeasurements, setShowMeasurements] = useState(true);
  const [opacity, setOpacity] = useState(1);
  const [loading, setLoading] = useState(false);
  const [scene, setScene] = useState<BabylonSceneManager>();
  const [planDiff, setPlanDiff] = useState<IPlan | null>(null);
  const validCutImplants = caseUtils
    .getValidCaseLevels(activeCase.levels)
    .map((validCaseLevelsType) => {
      return `${validCaseLevelsType}_APP`;
    });
  const validImplantScrews = caseUtils
    .getValidCaseLevels(activeCase.levels)
    .map((validCaseLevelsType) => {
      return `${validCaseLevelsType}_IMPLANT_SCREW`;
    });
  const validLevels = caseUtils.getCaseVertebralBodyAssets(
    activeCase.spineProfile,
    'desc',
  ).standard;

  const [findPlan] = useLazyQuery(FIND_PLAN, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const [findAssets] = useLazyQuery(FIND_ASSETS_QUERY, {
    fetchPolicy: 'network-only',
  });

  const handleSceneReady = (scene: BabylonSceneManager) => {
    setScene(scene);
  };

  const switchCamera = (cameraView: 'AXIAL' | 'CORONAL' | 'SAGITTAL_LEFT' | 'SAGITTAL_RIGHT') => {
    if (!scene) return;

    let distance = implantState.level ? IMPLANT_CAMERA_DISTANCE : SPINE_CAMERA_DISTANCE;

    if (cameraView === 'AXIAL') {
      scene.zoom({
        cameraView: 'AXIAL_TOP',
        distance,
      });
    } else {
      scene.zoom({
        cameraView,
        distance,
      });
    }
  };

  const toggleOpacity = (opacity: number) => {
    if (!scene) return;

    scene.updateMeshesByTagQuery(
      `(${SpineAssetTags.Plan} || ${SpineAssetTags.PreOp} || ${SpineAssetTags.PlanDiff}) && !${SpineAssetTags.Implant}`,
      (mesh) => {
        if (mesh.opacity > 0) {
          return { opacity };
        }

        return {};
      },
    );

    setOpacity(opacity);
  };

  const toggleImplant = (implantLevel: LevelType | null) => {
    if (!scene) return;

    let levelImplant: {
      screwLength: number | undefined;
      part: PartType | undefined;
      ap: number | undefined;
      ml: number | undefined;
    } | null = {
      screwLength: undefined,
      part: undefined,
      ap: undefined,
      ml: undefined,
    };

    if (implantLevel !== null && plan !== null) {
      levelImplant = planUtils.getLevelImplant(implantLevel as LevelType, plan);
    }

    setImplantState({
      screwLength: levelImplant?.screwLength,
      part: levelImplant?.part,
      ap: levelImplant?.ap,
      ml: levelImplant?.ml,
      level: implantState.level === implantLevel ? null : implantLevel,
      screwsVisible: true,
    });

    setShowMeasurements(implantLevel === null);

    const newLevelState: PlanViewDialogLevelState = {
      ...levelState,
      preopVisible: false,
      planVisible: true,
      preop: levelState.preop.map((levelState) => {
        return { level: levelState.level, isChecked: false };
      }),
      plan: levelState.plan.map((levelState) => {
        return { level: levelState.level, isChecked: implantLevel === null };
      }),
      planDiff: levelState.planDiff.map((levelState) => {
        return { level: levelState.level, isChecked: false };
      }),
    };

    setLevelState(newLevelState);

    if (implantLevel !== null) {
      toggleMesh([SpineAssetTags.PreOp], 0, scene);
      toggleMesh([SpineAssetTags.Plan], 0, scene);
      toggleMesh([SpineAssetTags.Implant], 0, scene);

      const [, inferior] = implantLevel.split('_');
      toggleMesh([SpineAssetTags.Plan, inferior], opacity, scene);
      toggleMesh([SpineAssetTags.Implant, implantLevel], 1, scene);
      toggleMesh([SpineAssetTags.Screws, implantLevel], 1, scene);
      setTarget([SpineAssetTags.Plan, SpineAssetTags.Implant, implantLevel], scene);
    } else {
      toggleMesh([SpineAssetTags.Implant], 1, scene);
      toggleMesh([SpineAssetTags.Screws], 1, scene);

      setTarget([SpineAssetTags.Plan, defaultTarget], scene);

      setLevelStateVisibility(newLevelState);
    }
  };

  const setLevelStateVisibility = (levelState: PlanViewDialogLevelState) => {
    if (!scene) return;

    if (levelState.planVisible) {
      levelState.plan.forEach((value) => {
        scene.updateMeshesByTags(
          [SpineAssetTags.Plan, value.level],
          { opacity: value.isChecked ? opacity : 0 },
          true,
        );
        toggleMesh([SpineAssetTags.Plan, value.level], value.isChecked ? opacity : 0, scene);
      });
    } else {
      toggleMesh([SpineAssetTags.Plan], 0, scene);
    }

    if (levelState.preopVisible) {
      levelState.preop.forEach((value) => {
        toggleMesh([SpineAssetTags.PreOp, value.level], value.isChecked ? opacity : 0, scene);
      });
    } else {
      toggleMesh([SpineAssetTags.PreOp], 0, scene);
    }
  };

  const toggleScrews = () => {
    if (!scene) return;

    const tags: string[] = [SpineAssetTags.Screws];

    if (implantState.level) tags.push(implantState.level);

    scene.updateMeshesByTagQuery(`${SpineAssetTags.Screws} && ${implantState.level}`, (mesh) => {
      if (mesh.opacity > 0) {
        return { opacity: implantState.screwsVisible ? 0 : 1 };
      }

      return {};
    });

    setImplantState({
      ...implantState,
      screwsVisible: !implantState.screwsVisible,
    });
  };

  const toggleMeasurements = () => {
    setShowMeasurements(!showMeasurements);
  };

  const toggleVisibility = (tags: string[]) => {
    if (!scene) return;

    let planVisible = levelState.planVisible;

    let hardwareVisible = levelState.hardwareVisible;

    let preopVisible = levelState.preopVisible;

    let daisyVisible = levelState.daisyVisible;

    if (tags.length === 1) {
      const tag = tags[0];

      switch (tag) {
        case SpineAssetTags.Plan:
          planVisible = !levelState.planVisible;
          break;
        case SpineAssetTags.PreOp:
          preopVisible = !levelState.preopVisible;

          hardwareVisible = preopVisible;

          break;
        case SpineAssetTags.Daisy:
          daisyVisible = !levelState.daisyVisible;
          break;
        case SpineAssetTags.Hardware:
          hardwareVisible = !levelState.hardwareVisible;
          break;
      }
    }

    scene.updateMeshesByTagQuery(tags.join(' && '), (mesh) => {
      let visibility = mesh.opacity > 0 ? 0 : opacity;

      if (tags.length === 1) {
        const tag = tags[0];

        switch (tag) {
          case SpineAssetTags.Plan:
            visibility = !planVisible ? 0 : opacity;
            break;
          case SpineAssetTags.PreOp:
            visibility = !preopVisible ? 0 : opacity;
            break;
          case SpineAssetTags.Daisy:
            visibility = !daisyVisible ? 0 : opacity;
            break;
          case SpineAssetTags.Hardware:
            visibility = !hardwareVisible ? 0 : opacity;
            break;
        }
      }

      return {
        opacity: visibility,
      };
    });

    setLevelState({
      planVisible,
      implants: getLevelState([SpineAssetTags.Plan, SpineAssetTags.Implant], scene),
      plan: getLevelState([SpineAssetTags.Plan, SpineAssetTags.Vertebrae], scene),
      planDiff: getLevelState([SpineAssetTags.PlanDiff, SpineAssetTags.Vertebrae], scene),
      preopVisible,
      preop: getLevelState([SpineAssetTags.PreOp, SpineAssetTags.Vertebrae], scene),
      daisyVisible,
      daisy: getLevelState([SpineAssetTags.Daisy, SpineAssetTags.Vertebrae], scene),
      hardwareVisible,
    });
  };

  const loadAssets = async () => {
    if (!scene) return;

    setLoading(true);

    const response = await findAssets({
      variables: {
        caseId: activeCase.caseId,
        planId: plan?.planId,
        assetTypeFilter: [
          AssetType.Pelvis,
          AssetType.HardwarePreop,
          ...validLevels,
          ...validCutImplants,
          ...validImplantScrews,
          ...validLevels.map((validLevel) => `${validLevel}_DAISY`),
        ],
      },
    });

    const spineAssets: LoadedSpineConfig[] = [];

    if (response.data) {
      const preopAssets = response.data.preopAssets.filter(
        (asset: IAsset) => !asset.assetType.endsWith('_DAISY'),
      );

      for (const asset of preopAssets) {
        if (asset.signedDownloadUrl) {
          if (asset.assetType === AssetType.HardwarePreop) {
            spineAssets.push({
              assetType: asset.assetType,
              assetUrl: asset.signedDownloadUrl,
              tag: SpineAssetTags.Hardware,
              otherTags: [SpineAssetTags.PreOp],
              opacity: levelState.preopVisible ? 1 : 0,
            });
          } else {
            spineAssets.push({
              assetType: asset.assetType,
              assetUrl: asset.signedDownloadUrl,
              tag: SpineAssetTags.PreOp,
              otherTags: [SpineAssetTags.Vertebrae],
              opacity: levelState.preopVisible ? 1 : 0,
            });
          }
        }
      }

      const staticAssets = response.data.staticAssets;

      for (const asset of staticAssets) {
        if (asset.signedDownloadUrl) {
          spineAssets.push({
            assetType: asset.assetType,
            assetUrl: asset.signedDownloadUrl,
            tag: SpineAssetTags.PreOp,
            otherTags: [SpineAssetTags.Vertebrae],
            opacity: levelState.preopVisible ? 1 : 0,
          });

          spineAssets.push({
            assetType: asset.assetType,
            assetUrl: asset.signedDownloadUrl,
            tag: SpineAssetTags.Plan,
            otherTags: [SpineAssetTags.Vertebrae],
            opacity: levelState.planVisible ? 1 : 0,
          });
        }
      }

      const daisyAssets = response.data.preopAssets.filter((asset: IAsset) =>
        asset.assetType.endsWith('_DAISY'),
      );

      for (const asset of daisyAssets) {
        if (asset.signedDownloadUrl) {
          spineAssets.push({
            assetType: asset.assetType.replace('_DAISY', ''),
            assetUrl: asset.signedDownloadUrl,
            tag: SpineAssetTags.Daisy,
            otherTags: [SpineAssetTags.Vertebrae],
            opacity: showDaisy && levelState.daisyVisible ? 1 : 0,
          });
        }
      }

      for (const asset of response.data.planAssets) {
        if (asset.signedDownloadUrl) {
          const name = asset.assetType.replace('_APP', '').replace('_IMPLANT_SCREW', '');

          let type: SpineAssetTags.Vertebrae | SpineAssetTags.Implant | SpineAssetTags.Screws =
            asset.assetType.endsWith('_APP') ? SpineAssetTags.Implant : SpineAssetTags.Vertebrae;

          if (asset.assetType.endsWith('_IMPLANT_SCREW')) {
            type = SpineAssetTags.Screws;
          }

          spineAssets.push({
            assetType: name,
            assetUrl: asset.signedDownloadUrl,
            tag: type,
            otherTags: [SpineAssetTags.Plan, type],
            opacity: levelState.planVisible ? 1 : 0,
          });
        }
      }

      await scene.loadSpine(activeCase.spineProfile, spineAssets, {
        [SpineAssetTags.PreOp]: PlanAssetColorType.PreopVertebrae,
        [SpineAssetTags.Plan]: PlanAssetColorType.PlanVertebrae,
        [SpineAssetTags.Daisy]: PlanAssetColorType.DaisyVertebrae,
        [SpineAssetTags.Screws]: PlanAssetColorType.PlanImplantScrew,
        [SpineAssetTags.Implant]: PlanAssetColorType.PlanImplantCut,
        [SpineAssetTags.Hardware]: PlanAssetColorType.PlanImplantCut,
        [SpineAssetTags.PlanDiff]: PlanAssetColorType.PlanDiffVertebrae,
      });

      const preOpMeasurements: IMeasure[] = response.data.preopMeasurements || [];

      const planMeasurements: IMeasure[] = response.data.planMeasurements || [];

      const preOpMeasurementLandmarks: LandmarkConfig[] = preOpMeasurements.map((m) =>
        convertToLandmarkConfig(m, [SpineAssetTags.PreOp, SpineAssetTags.Vertebrae]),
      );

      const planMeasurementLandmarks: LandmarkConfig[] = planMeasurements.map((m) =>
        convertToLandmarkConfig(m, [SpineAssetTags.Plan, SpineAssetTags.Vertebrae]),
      );

      scene.createLandmarks([...preOpMeasurementLandmarks, ...planMeasurementLandmarks]);

      setLevelState({
        ...levelState,
        implants: getLevelState([SpineAssetTags.Plan, SpineAssetTags.Implant], scene),
        plan: getLevelState([SpineAssetTags.Plan, SpineAssetTags.Vertebrae], scene),
        planDiff: getLevelState([SpineAssetTags.PlanDiff, SpineAssetTags.Vertebrae], scene),
        preop: getLevelState([SpineAssetTags.PreOp, SpineAssetTags.Vertebrae], scene),
        daisy: getLevelState([SpineAssetTags.Daisy, SpineAssetTags.Vertebrae], scene),
      });

      scene.zoom({
        cameraView: 'CORONAL',
      });

      setLoading(false);
    }
  };

  const loadPlanDiff = async (planId: number) => {
    if (!scene) return;

    if (!planId || !!planDiff) {
      scene.removeMeshesByTags([SpineAssetTags.PlanDiff]);
    }

    let planDiffLevelState: {
      level: LevelType;
      isChecked: boolean;
    }[] = [];
    let updatedPlanDiff = null;

    if (planId) {
      const { data } = await findPlan({ variables: { planId } });
      updatedPlanDiff = data?.plan;

      if (updatedPlanDiff) {
        setPlanDiff(updatedPlanDiff);

        if (!updatedPlanDiff) {
        } else {
          const response = await findAssets({
            variables: {
              caseId: activeCase.caseId,
              planId: updatedPlanDiff.planId,
              showDeleted: true,
              assetTypeFilter: [...validLevels],
            },
          });

          if (response.data.planAssets) {
            const assetConfigs: LoadedAssetConfig[] = [];

            const measurements: IMeasure[] = response.data.planMeasurements || [];

            for (const asset of response.data.planAssets) {
              if (asset.signedDownloadUrl) {
                assetConfigs.push({
                  assetUrl: asset.signedDownloadUrl,
                  meshConfig: { opacity: 1, color: PlanAssetColorType.PlanDiffVertebrae },
                  mesh: {
                    meshName: asset.assetType,
                    tags: [SpineAssetTags.PlanDiff, SpineAssetTags.Vertebrae],
                  },
                });
              }
            }

            const landmarks: LandmarkConfig[] = measurements.map((m) =>
              convertToLandmarkConfig(m, [SpineAssetTags.PlanDiff, SpineAssetTags.Vertebrae]),
            );

            scene.createLandmarks(landmarks);

            await scene.loadAssets(assetConfigs, false);

            planDiffLevelState = getLevelState(
              [SpineAssetTags.PlanDiff, SpineAssetTags.Vertebrae],
              scene,
            );
          }
        }
      }
    }

    setPlanDiff(updatedPlanDiff);
    setLevelState({
      ...levelState,
      planDiff: planDiffLevelState,
    });
  };

  useEffect(() => {
    if (!scene) return;

    if (!plan) {
      if (levelState.preop.length > 0) {
        setTarget([SpineAssetTags.PreOp, defaultTarget], scene);
      }

      if (levelState.preop.length === 0 && levelState.daisy.length > 0) {
        setTarget([SpineAssetTags.Daisy, defaultTarget], scene);
      }
    } else if (implantState.level === null) {
      setTarget([SpineAssetTags.Plan, defaultTarget], scene);
    }

    switchCamera('CORONAL');
  }, [levelState, scene]);

  useEffect(() => {
    if (!scene) return;

    const tags: string[] = [SpineAssetTags.Screws];

    if (implantState.level) tags.push(implantState.level);

    toggleMesh(tags, implantState?.screwsVisible ? 1 : 0, scene);
  }, [implantState?.screwsVisible]);

  return (
    <PlanViewDialogContext.Provider
      value={{
        opacity,
        implantState,
        levelState,
        handleSceneReady,
        loading,
        loadPlanDiff,
        loadAssets,
        plan,
        planDiff,
        scene,
        showMeasurements,
        switchCamera,
        toggleImplant,
        toggleMeasurements,
        toggleOpacity,
        toggleScrews,
        toggleVisibility,
      }}
    >
      {children}
    </PlanViewDialogContext.Provider>
  );
};
