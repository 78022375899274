import { Box } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  CalendarApi,
  CssDimValue,
  EventClickArg,
  EventContentArg,
  EventMountArg,
  EventSourceInput,
} from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import { CalendarToolbar, CalendarViewMode } from './CalendarToolbar';
import { FullCalendarWrapper } from './FullCalendarWrapper';
import { DateTime } from 'luxon';

type CalendarProps = {
  date?: Date;
  showExtended?: boolean;
  calendarHeight?: CssDimValue;
  viewMode?: CalendarViewMode;
  events: EventSourceInput;
  eventContent?: (event: EventContentArg) => void;
  onEventClick?: (event: EventClickArg) => void;
  onEventDidMount?: (args: EventMountArg) => void;
  onDateChange?: (date: Date) => void;
  loadingEvents?: boolean;
  hideControls?: boolean;
  dayGridMonthSpanWeeks?: number;
  dayMaxEventRows?: boolean | number;
};

const DEFAULT_DAY_GRID_MONTH_SPAN_WEEKS = 6;

export function Calendar(props: CalendarProps) {
  const weeksShown = useMemo(() => {
    return props.dayGridMonthSpanWeeks || DEFAULT_DAY_GRID_MONTH_SPAN_WEEKS;
  }, [props.dayGridMonthSpanWeeks]);

  const customCalendarViews = {
    dayGridMonthSpan: {
      type: 'dayGridMonth',
      duration: { weeks: weeksShown },
      showNonCurrentDates: true,
    },
  };

  const calendarRef = useRef(null);
  const [date, setDate] = useState(props.date ?? new Date());
  const [view, setView] = useState<CalendarViewMode>('dayGridMonth');

  const handleDateToday = () => {
    const calendarEl: any = calendarRef.current;

    if (calendarEl) {
      const calendarApi: CalendarApi = calendarEl.getApi();

      calendarApi.today();

      const calendarDate = calendarApi.getDate();

      setDate(calendarDate);

      if (props.onDateChange) props.onDateChange(calendarDate);
    }
  };

  const handleViewChange = (newView: CalendarViewMode) => {
    const calendarEl: any = calendarRef.current;

    if (calendarEl) {
      const calendarApi: CalendarApi = calendarEl.getApi();

      calendarApi.changeView(newView);
      setView(newView);
    }
  };

  const handleDatePrev = () => {
    const calendarEl: any = calendarRef.current;

    if (calendarEl) {
      const calendarApi: CalendarApi = calendarEl.getApi();

      calendarApi.prev();

      const calendarDate = calendarApi.getDate();

      setDate(calendarDate);

      if (props.onDateChange) props.onDateChange(calendarDate);
    }
  };

  const handleDateNext = () => {
    const calendarEl: any = calendarRef.current;

    if (calendarEl) {
      const calendarApi: CalendarApi = calendarEl.getApi();

      calendarApi.next();

      const calendarDate = calendarApi.getDate();

      setDate(calendarDate);

      if (props.onDateChange) props.onDateChange(calendarDate);
    }
  };

  useEffect(() => {
    if (view !== props.viewMode && props.viewMode) {
      handleViewChange(props.viewMode);
    }
  }, [props.viewMode]);

  useEffect(() => {
    setDate(props.date ?? new Date());
  }, [props.date]);

  useEffect(() => {
    const calendarEl: any = calendarRef.current;

    if (calendarEl) {
      const calendarApi: CalendarApi = calendarEl.getApi();

      calendarApi.gotoDate(date);
    }
  }, [date]);

  return (
    <Box height={props.calendarHeight ? undefined : '100%'} flexDirection={'column'} display="flex">
      <CalendarToolbar
        hideControls={props.hideControls}
        date={date}
        loading={props.loadingEvents}
        onDateNext={handleDateNext}
        onDatePrev={handleDatePrev}
        onDateToday={handleDateToday}
        onViewChange={handleViewChange}
        view={view}
        totalWeeksShown={view === 'dayGridMonthSpan' ? weeksShown : undefined}
      />
      <FullCalendarWrapper style={{ flexGrow: 1 }}>
        <FullCalendar
          dayHeaderFormat={
            view === 'dayGridMonthSpan'
              ? (args) => {
                  const datetime = DateTime.fromJSDate(args.date.marker);

                  const weekday = datetime.weekday;

                  /**
                   * Because the 'date.marker' param provided only has the weekday number and
                   * no year/month/day information, we must compute the header's actual date
                   * based on the current date provided
                   *
                   * NOTE: Date markers that fall on a Saturday or Sunday seem to actually proceed
                   * the Friday date markers (hence subtracting days from the current date)
                   */

                  if (weekday === 6 || weekday === 7) {
                    return DateTime.fromJSDate(date)
                      .startOf('week')
                      .minus({ days: 7 - weekday })
                      .toFormat('EEE L/dd');
                  } else {
                    return DateTime.fromJSDate(date)
                      .startOf('week')
                      .plus({ days: weekday })
                      .toFormat('EEE L/dd');
                  }
                }
              : undefined
          }
          views={customCalendarViews}
          showNonCurrentDates
          eventDidMount={props?.onEventDidMount}
          allDayMaintainDuration
          dayMaxEventRows={props?.dayMaxEventRows ?? 3}
          droppable={false}
          editable={false}
          eventClick={props?.onEventClick}
          eventContent={props?.eventContent}
          eventDisplay="block"
          eventResizableFromStart
          events={props.events}
          handleWindowResize={true}
          headerToolbar={false}
          initialDate={date}
          initialView={view}
          plugins={[dayGridPlugin, interactionPlugin, listPlugin]}
          ref={calendarRef}
          rerenderDelay={10}
          height={props.calendarHeight}
          selectable
          weekends
        />
      </FullCalendarWrapper>
    </Box>
  );
}

export * from './CalendarToolbar';
