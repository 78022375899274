import React, { Dispatch } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, TableBody, TableRow, Typography } from '@mui/material';
import { format, ICase, ITableHeader, UserRoleType } from '@workflow-nx/common';
import { date } from '@workflow-nx/utils';
import { TableCell } from '../../../components/TableCell';
import { CommonTable } from '../../../components/CommonTable';
import { ListCompletedCasesActionType } from './ListPostOpCases.reducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { Globals } from '../../../layouts/DashboardLayout';

type CaseListTableProps = {
  cases: ICase[];
  page?: number;
  rowsPerPage?: number;
  totalCaseCount?: number;
  orderBy?: { [key: string]: 'asc' | 'desc' };
  dispatch?: Dispatch<ListCompletedCasesActionType>;
  shouldHighlight?: (activeCase: ICase) => boolean;
  loading?: boolean;
};

const PostOpCaseListTable = ({
  cases,
  totalCaseCount,
  page,
  rowsPerPage,
  orderBy,
  dispatch,
  loading,
}: CaseListTableProps) => {
  const navigate = useNavigate();
  const headers: ITableHeader[] = [
    {
      id: 'number',
      sortable: true,
      label: 'Case #',
    },
    {
      id: 'surgeryDate',
      sortable: true,
      label: 'Surgery Date',
      align: 'center',
    },
    {
      id: 'weeksSinceSurgeryDate',
      sortable: false,
      label: 'Weeks Since Surgery',
      align: 'center',
    },
    {
      id: 'surgeonId',
      sortable: true,
      label: 'Surgeon',
    },
    {
      id: 'fieldRepId',
      sortable: true,
      label: `${format.formatUserRole(UserRoleType.FieldRep)}`,
    },
    {
      id: 'hasAssetPositions',
      sortable: false,
      label: 'Cyborg Plan Data',
      align: 'center',
    },
    {
      id: 'hasSixWeekImaging',
      sortable: false,
      label: '6w Imaging',
      align: 'center',
    },
    {
      id: 'hasSixWeekAnalysis',
      sortable: false,
      label: '6w Analysis',
      align: 'center',
    },
  ];

  const showPagination = Boolean(dispatch && rowsPerPage && page !== undefined);

  return (
    <CommonTable
      orderBy={orderBy}
      headers={headers}
      onHeaderClick={(orderBy) => {
        if (dispatch) {
          dispatch({ type: 'ORDER_BY_CHANGED', data: orderBy });
        }
      }}
      rows={
        <TableBody>
          {cases?.map((activeCase: ICase) => {
            return (
              <TableRow
                hover
                key={activeCase.caseId}
                onClick={() => navigate(`/app/post-op-analysis/${activeCase.caseId}`)}
              >
                <TableCell>
                  <Box display={'flex'} alignItems={'center'}>
                    {activeCase.number}
                  </Box>
                </TableCell>
                <TableCell align={'center'}>
                  <Typography variant={'body1'}>
                    {activeCase.surgeryDate
                      ? format.formatISODate(activeCase.surgeryDate as unknown as string)
                      : 'TBD'}
                  </Typography>
                </TableCell>
                <TableCell align={'center'}>
                  <Typography variant={'body1'}>
                    {activeCase.surgeryDate ? (
                      `${date.distanceInWeeks(activeCase.surgeryDate as string)} week(s)`
                    ) : (
                      <>&mdash;</>
                    )}
                  </Typography>
                </TableCell>
                <TableCell>
                  {activeCase.surgeonUser ? (
                    <Typography noWrap={true}>
                      {format.formatName(activeCase.surgeonUser)}
                    </Typography>
                  ) : (
                    <span>&mdash;</span>
                  )}
                  {activeCase?.surgeonUser?.organization ? (
                    <Typography noWrap={true} color={'textSecondary'} variant={'button'}>
                      {activeCase?.surgeonUser?.organization.name}
                    </Typography>
                  ) : null}
                </TableCell>
                <TableCell>
                  {activeCase.fieldRepUser ? (
                    <Typography noWrap={true}>
                      {format.formatName(activeCase.fieldRepUser)}
                    </Typography>
                  ) : (
                    <span>&mdash;</span>
                  )}
                </TableCell>
                <TableCell align={'center'}>
                  {activeCase?.plans?.[0]?.assetPositions ? (
                    <FontAwesomeIcon icon={faCheck} />
                  ) : (
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                  )}
                </TableCell>
                <TableCell align={'center'}>
                  {activeCase?.hasSixWeekImaging ? (
                    <FontAwesomeIcon icon={faCheck} />
                  ) : (
                    <FontAwesomeIcon icon={faTimes} />
                  )}
                </TableCell>
                <TableCell align={'center'}>
                  {activeCase?.hasSixWeekAnalysis ? (
                    <FontAwesomeIcon icon={faCheck} />
                  ) : (
                    <FontAwesomeIcon icon={faTimes} />
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      }
      loading={loading}
      paginationConfig={
        showPagination
          ? {
              totalCount: totalCaseCount || 0,
              onPaginationChange: (pagination) => {
                dispatch?.({
                  type: 'UPDATE_PAGINATION',
                  data: {
                    pageSize: pagination.rowsPerPage,
                    pageNumber: pagination.page,
                  },
                });
              },
              rowsPerPage: rowsPerPage || Globals.DefaultListPageSize,
              page: page || 0,
              rowsPerPageOptions: Globals.DefaultListPageSizeOptions,
            }
          : undefined
      }
    />
  );
};

export default PostOpCaseListTable;
