import { Chip, useTheme } from '@mui/material';
import { DEFAULT_TAG_CATEGORY_COLOR, ITag } from '@workflow-nx/common';
import { ComponentProps } from 'react';

export const TagChip: React.FC<
  ComponentProps<typeof Chip> & {
    tag: ITag;
  }
> = ({ tag, ...props }) => {
  const theme = useTheme();

  const chipColor = tag.tagCategory?.color || DEFAULT_TAG_CATEGORY_COLOR;

  return (
    <Chip
      {...props}
      label={tag.label}
      style={{
        ...props.style,
        backgroundColor: chipColor,
        color: theme.palette.getContrastText(chipColor),
      }}
    />
  );
};
