import { AppBar, Box, Grid, Toolbar, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconFontButton } from '@workflow-nx/ui';
import { faChevronCircleLeft } from '@fortawesome/pro-solid-svg-icons';
import { Globals } from '../layouts/DashboardLayout';
import useAuth from '../hooks/useAuth';

type CustomAppBarProps = {
  navigateBackUrl?: string;
  title?: string;
  actions?: any[];
};

export function CustomAppBar({ navigateBackUrl, title, actions }: CustomAppBarProps) {
  const navigate = useNavigate();
  const theme = useTheme();
  const actionButtons = actions ?? [];
  const auth = useAuth();

  return (
    <>
      <AppBar
        color={'default'}
        position={'fixed'}
        style={{
          background: theme.palette.grey[200],
          borderTopWidth: 1,
          borderTopStyle: 'solid',
          borderTopColor: theme.palette.divider,
          transition: 'padding-left 0.5s',
          paddingLeft: auth.isDrawerOpen ? Globals.DrawerWidthOpen : Globals.DrawerWidthClosed,
          marginTop: 64,
        }}
      >
        <Toolbar>
          <Grid container spacing={2} alignItems={'center'}>
            {navigateBackUrl ? (
              <Grid item>
                <IconFontButton
                  onClick={() => {
                    navigate(navigateBackUrl);
                  }}
                  icon={faChevronCircleLeft}
                />
              </Grid>
            ) : null}
            {title ? (
              <Grid item>
                <Typography variant={'h3'}>{title?.toUpperCase()}</Typography>
              </Grid>
            ) : null}
            {actionButtons.length > 0 ? <Box flexGrow={1} /> : null}
            {actionButtons.map((action: React.ReactNode, index: number) => {
              return (
                <Grid item key={index}>
                  {action}
                </Grid>
              );
            })}
          </Grid>
        </Toolbar>
      </AppBar>
      <Box mt={10} />
    </>
  );
}
