import React, { useState, useEffect } from 'react';
import { MedicalReviewStatusType } from '@workflow-nx/common';
import { Box, Button, Typography } from '@mui/material';
import { Resolver, useForm, Control, FieldValues } from 'react-hook-form';
import { TextFieldRHF } from '@workflow-nx/ui';
import { FormActionType } from './CaseReportDialog.reducer';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

interface MedicalReviewFormProps {
  medicalReviewNote?: string;
  medicalReviewStatus?: MedicalReviewStatusType;
  disabled: boolean;
  onActionSubmit: (actionType: FormActionType, data: MedicalReviewFormValues) => void;
}

interface MedicalReviewFormValues {
  medicalReviewNote: string;
}

export const MedicalReviewForm: React.FC<MedicalReviewFormProps> = ({
  medicalReviewNote,
  medicalReviewStatus,
  disabled,
  onActionSubmit,
}) => {
  const medicalReviewFormSchema = Yup.object().shape({
    medicalReviewNote: Yup.string().trim(),
  });

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm<MedicalReviewFormValues>({
    defaultValues: {
      medicalReviewNote: medicalReviewNote || '',
    },
    resolver: yupResolver(medicalReviewFormSchema) as unknown as Resolver<MedicalReviewFormValues>,
  });

  const handleRejectReview = (data: MedicalReviewFormValues) => {
    if (!data.medicalReviewNote.trim()) {
      setError('medicalReviewNote', {
        type: 'required',
        message: 'Note is required when rejecting the medical review.',
      });
      return;
    }
    onActionSubmit(FormActionType.RejectCaseReportMedicalReview, data);
  };

  return (
    <form
      onSubmit={handleSubmit((data) =>
        onActionSubmit(FormActionType.ApproveCaseReportMedicalReview, data),
      )}
    >
      <Box display={'flex'} alignItems={'center'} mt={4}>
        <Box display={'flex'} justifyContent={'left'}>
          <Typography variant={'h5'}>
            <strong>Medical Review Section</strong>
          </Typography>
        </Box>
      </Box>
      <Box display={'flex'} alignItems={'flex-start'} my={2} gap={1}>
        <Box display={'flex'} width={240} justifyContent={'left'}>
          <Typography variant={'body1'}>Medical Reviewer Note</Typography>
        </Box>
        <Box display={'flex'} width={650} justifyContent={'left'}>
          <TextFieldRHF
            name={'medicalReviewNote'}
            control={control as unknown as Control<FieldValues>}
            label={''}
            fullWidth={true}
            multiline={true}
            disabled={disabled || medicalReviewStatus === MedicalReviewStatusType.Approved}
          />
        </Box>
        <Box display={'flex'} width={240} justifyContent={'left'}>
          <Typography variant={'caption'} color={'error'}>
            {errors.medicalReviewNote ? errors.medicalReviewNote.message : null}
          </Typography>
        </Box>
      </Box>
      <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
        <Box display={'flex'} width={240} justifyContent={'left'}></Box>
        <Box display={'flex'} width={650} justifyContent={'right'}>
          <Button
            variant={'outlined'}
            onClick={handleSubmit((data) =>
              onActionSubmit(FormActionType.ApproveCaseReportMedicalReview, data),
            )}
            disabled={disabled || medicalReviewStatus === MedicalReviewStatusType.Approved}
          >
            Approve
          </Button>
          <Box ml={2}>
            <Button
              variant={'outlined'}
              onClick={handleSubmit(handleRejectReview)}
              disabled={
                disabled ||
                medicalReviewStatus === MedicalReviewStatusType.Approved ||
                medicalReviewStatus === MedicalReviewStatusType.Rejected
              }
            >
              Reject
            </Button>
          </Box>
        </Box>
        <Box display={'flex'} width={240} justifyContent={'left'}></Box>
      </Box>
    </form>
  );
};
