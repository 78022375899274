import { Autocomplete, FormControl, Box, TextField } from '@mui/material';
import React, { useCallback, useMemo } from 'react';

export const PartTypeAutocomplete = ({
  label,
  disabled,
  required,
  options,
  value,
  onChange,
  fullWidth,
}: {
  fullWidth?: boolean;
  disabled?: boolean;
  required?: boolean;
  label?: string;
  name: string;
  value?: string;
  onChange?: (newValue: string | null) => void;
  options: Record<string, string>[];
}) => {
  const selectedValue = useMemo(() => {
    return options.find((option) => option.key === value);
  }, [value, options]);

  const getOptionLabel = useCallback((partType: any) => {
    return partType.label || '';
  }, []);

  return (
    <FormControl component="fieldset" fullWidth={true}>
      <Box>
        <Autocomplete
          clearOnBlur={true}
          clearOnEscape={true}
          disabled={disabled}
          options={options}
          value={selectedValue ?? null}
          fullWidth={fullWidth}
          onChange={(e, option) => {
            onChange?.(option?.key);
          }}
          getOptionLabel={getOptionLabel}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                placeholder="None"
                label={label}
                fullWidth={fullWidth}
                InputLabelProps={{
                  shrink: true,
                }}
                size={'small'}
                tabIndex={1}
                required={required}
                inputProps={{
                  ...params.inputProps,
                }}
                variant="outlined"
                onKeyDown={(e) => {
                  // stop enter from submitting a form
                  if (e.key === 'Enter') {
                    e.preventDefault();
                  }
                }}
              />
            );
          }}
        />
      </Box>
    </FormControl>
  );
};
