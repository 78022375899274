import { colors, responsiveFontSizes } from '@mui/material';
import { createTheme as createMuiTheme } from '@mui/material/styles';
import { softShadows } from './shadows';
import typography from './typography';
import { merge } from 'lodash';

const baseOptions = {
  direction: 'ltr',
  typography,
  components: {
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: 8,
          borderRadius: 3,
          overflow: 'hidden',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 32,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1em',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          '& .MuiCardContent-root:last-child': {
            paddingBottom: 16,
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          '&.MuiTypography-root': {
            fontSize: '1.5em',
            fontWeight: 500,
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          marginLeft: 16,
          marginRight: 16,
          marginBottom: 16,
        },
      },
    },
  },
};

const themesOptions = [
  {
    name: 'LIGHT',
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600],
          },
        },
      },
    },
    palette: {
      mode: 'light',
      action: {
        active: colors.blueGrey[600],
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white,
      },
      primary: {
        main: colors.indigo[600],
      },
      secondary: {
        main: '#5850EC',
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600],
      },
    },
    shadows: softShadows,
  },
];

export const createTheme = (config = {}) => {
  let themeOptions = themesOptions.find((theme) => theme.name === 'LIGHT');

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createMuiTheme(merge({}, baseOptions, themeOptions, { direction: config.direction }));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
