import { Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { ReactNode } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    fontSize: theme.spacing(1.75),
    color: '#546e7a',
  },
}));

export function FormattedFieldView(props: { label: string; value?: string | number | ReactNode }) {
  const classes = useStyles();

  return (
    <Grid container direction={'column'}>
      <Grid item>
        <Typography className={classes.label} component={'div'} gutterBottom={false} noWrap={true}>
          {props.label}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant={'body1'} component={'div'}>
          {props.value ? props.value : <span>&mdash;</span>}
        </Typography>
      </Grid>
    </Grid>
  );
}
