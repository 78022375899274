import { Box, Tab, Tabs, Typography } from '@mui/material';
import { LevelSize, LevelType, Nullable } from '@workflow-nx/common';
import { Mesh, Scene } from 'babylonjs';
import { useEffect, useState } from 'react';

const CutImplantMetadataOverlay = (props: { levelType: LevelType; scene: Scene }) => {
  const [currentMesh, setCurrentMesh] = useState<Nullable<Mesh>>(null);

  const [size, setSize] = useState<LevelSize.Minus | LevelSize.Normal>(LevelSize.Normal);

  const changeSize = (levelType: LevelType, newSize: LevelSize.Minus | LevelSize.Normal) => {
    const newMesh: Nullable<Mesh> =
      newSize === LevelSize.Normal
        ? (props.scene.getMeshByName(`${levelType}_APP`) as Nullable<Mesh>)
        : (props.scene.getMeshByName(`${levelType}_APP_MINUS`) as Nullable<Mesh>);

    setCurrentMesh(newMesh);

    setSize(newSize);
  };

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);

    let newSize = LevelSize.Normal;

    if (newValue === 0) {
      newSize = LevelSize.Normal;
    } else if (newValue === 1) {
      newSize = LevelSize.Minus;
    }

    if (newSize === size) return;

    changeSize(props.levelType, newSize);
  };

  useEffect(() => {
    changeSize(props.levelType, LevelSize.Normal);
  }, [props.levelType]);

  return (
    <>
      <Box mb={1}>
        <Tabs value={tabValue} onChange={handleTabChange} variant="fullWidth">
          <Tab label="Plan" />
          <Tab label="Minus" />
        </Tabs>
      </Box>

      <Box mt={2}>
        <Box>
          <Typography>
            <strong>Cut Dimensions</strong>
            <Box sx={{ overflow: 'auto', maxHeight: 150 }}>
              <Typography variant={'caption'}>
                <pre>{currentMesh?.metadata?.dimensions}</pre>
              </Typography>
            </Box>
          </Typography>
        </Box>

        {size === LevelSize.Normal ? (
          <Box mt={1}>
            <Typography>
              <strong>Cut Metadata</strong>
              <Box sx={{ overflow: 'auto', maxHeight: 150 }}>
                <Typography variant={'caption'}>
                  <pre>{currentMesh?.metadata?.metadata}</pre>
                </Typography>
              </Box>
            </Typography>
          </Box>
        ) : null}
      </Box>
    </>
  );
};

export default CutImplantMetadataOverlay;
