import { format, ITableHeader, ITask } from '@workflow-nx/common';
import { TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { CommonTable } from '../../../../components/CommonTable';

const useStyles = makeStyles(() => ({
  row: {
    '& :hover': {
      cursor: 'pointer',
    },
  },
}));

export function TaskListTable(props: {
  orderBy: any;
  loading: boolean;
  tasks: ITask[];
  dispatch: any;
  onView: (taskId: number) => void;
}) {
  const styles = useStyles();
  const headers: ITableHeader[] = [
    {
      id: 'priority',
      sortable: true,
      label: 'Priority',
    },
    {
      id: 'description',
      sortable: true,
      label: 'Task',
    },
    {
      id: 'dueDate',
      sortable: true,
      label: 'Due Date',
    },
    {
      id: 'assignedId',
      sortable: true,
      label: 'Assigned To',
    },
    {
      id: 'status',
      sortable: true,
      label: 'Status',
    },
  ];

  return (
    <CommonTable
      orderBy={props.orderBy}
      headers={headers}
      onHeaderClick={(orderBy) => {
        if (props.dispatch) {
          props.dispatch({ type: 'ORDER_BY_CHANGED', data: orderBy });
        }
      }}
      rows={
        <TableBody>
          {props?.tasks?.map((task: ITask) => (
            <TableRow
              hover
              key={task.taskId}
              onClick={() => {
                props.onView(task.taskId);
              }}
              className={`${styles.row}`}
            >
              <TableCell style={{ verticalAlign: 'top' }}>
                <Typography variant={'body1'} noWrap>
                  {format.formatTaskPriorityType(task?.priority)}
                </Typography>
              </TableCell>
              <TableCell style={{ verticalAlign: 'top', maxWidth: '600px' }}>
                <Typography variant={'body1'} noWrap>
                  {task?.description}
                </Typography>
              </TableCell>
              <TableCell style={{ verticalAlign: 'top' }}>
                <Typography variant={'body1'} noWrap>
                  {task?.dueDate ? (
                    format.formatISODate(task?.dueDate as unknown as string)
                  ) : (
                    <span>&mdash;</span>
                  )}
                </Typography>
              </TableCell>
              <TableCell style={{ verticalAlign: 'top' }}>
                <Typography variant={'body1'} noWrap>
                  {format.formatName(task.assignedUser)}
                </Typography>
              </TableCell>
              <TableCell style={{ verticalAlign: 'top' }}>
                <Typography variant={'body1'} noWrap>
                  {format.formatTaskStatusType(task?.status)}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      }
      loading={props.loading}
    />
  );
}
