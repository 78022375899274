import { Box, Popover } from '@mui/material';
import { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';
import React from 'react';
import { CalendarEventView } from './CalendarEventView';
import CalendarGridEventView from './CalendarGridEventView';

const CalendarGridAllEventsView = (props: { children: React.ReactNode; events: any[] }) => {
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'caseDetailsPopover',
  });

  return (
    <>
      <Box {...bindTrigger(popupState)} sx={{ cursor: 'pointer' }}>
        {props.children}
      </Box>
      <Popover
        style={{ zIndex: 9999 }}
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 15,
        }}
      >
        <Box sx={{ m: 2 }}>
          {props.events.map((event) => {
            return (
              <Box key={event.title}>
                <CalendarGridEventView extendedProps={event.extendedProps}>
                  <Box>
                    <CalendarEventView event={event} />
                  </Box>
                </CalendarGridEventView>
              </Box>
            );
          })}
        </Box>
      </Popover>
    </>
  );
};

export default CalendarGridAllEventsView;
