import {
  AssetType,
  CaseStageType,
  caseUtils,
  IAsset,
  ICase,
  IPatientRecord,
  IPlan,
  LevelType,
  PartType,
  PlanVersionType,
} from '@workflow-nx/common';

export type CasePlanningTabActionType = {
  type: string;
  data: any;
};

export type CasePlanningTabStateType = {
  caseLevels: LevelType[];
  patientRecord: IPatientRecord;
  plan?: IPlan;
  planAssets?: IAsset[];
  errors: { key: string; level: string; value: string }[];
  isEditingAllowed: boolean;
  isStageComplete: boolean;
  canMoveToProposed?: boolean;
  requiredAppAssets?: AssetType[];
  optionalAppAssets?: AssetType[];
  interbodyDataAssets?: AssetType[];
};

export const CasePlanningTabReducer =
  (activeCase: ICase, editingAllowed: boolean) =>
  (
    state: CasePlanningTabStateType,
    action: CasePlanningTabActionType,
  ): CasePlanningTabStateType => {
    let updatedState = state;

    switch (action.type) {
      case 'ASSET_DELETED': {
        const deletedAsset = action.data;
        let updatedPlanAssets = JSON.parse(JSON.stringify(state.planAssets));

        updatedPlanAssets = updatedPlanAssets.filter(
          (ca: IAsset) => ca.assetId !== deletedAsset.assetId,
        );

        updatedState = {
          caseLevels: state.caseLevels,
          canMoveToProposed: caseUtils.isCaseStagePlanningComplete(
            activeCase.levels,
            activeCase.spineProfile,
            updatedPlanAssets,
            state?.plan?.version ?? PlanVersionType.Version2,
            state.patientRecord,
            state?.plan?.implants ?? [],
          ),
          errors: caseUtils.getCaseStagePlanningErrors(
            activeCase.levels,
            updatedPlanAssets,
            state?.plan?.version ?? PlanVersionType.Version2,
            activeCase.patient.patientRecord,
            state?.plan?.implants ?? [],
          ),
          isEditingAllowed: state.isEditingAllowed,
          isStageComplete: state.isStageComplete,
          plan: state.plan,
          patientRecord: state.patientRecord,
          requiredAppAssets: state.requiredAppAssets,
          interbodyDataAssets: state.interbodyDataAssets,
          planAssets: updatedPlanAssets,
        };
        break;
      }
      case 'ASSET_UPLOADED': {
        const updatedPlanAssets = JSON.parse(JSON.stringify(state.planAssets));
        const createdAsset = action.data;

        updatedPlanAssets.push(createdAsset);
        updatedState = {
          caseLevels: state.caseLevels,
          canMoveToProposed: caseUtils.isCaseStagePlanningComplete(
            activeCase.levels,
            activeCase.spineProfile,
            updatedPlanAssets,
            state.plan?.version ?? PlanVersionType.Version2,
            state.patientRecord,
            state?.plan?.implants ?? [],
          ),
          errors: caseUtils.getCaseStagePlanningErrors(
            activeCase.levels,
            updatedPlanAssets,
            state.plan?.version ?? PlanVersionType.Version2,
            activeCase.patient.patientRecord,
            state?.plan?.implants ?? [],
          ),
          isEditingAllowed: state.isEditingAllowed,
          isStageComplete: state.isStageComplete,
          plan: state.plan,
          patientRecord: state.patientRecord,
          requiredAppAssets: state.requiredAppAssets,
          interbodyDataAssets: state.interbodyDataAssets,
          planAssets: updatedPlanAssets,
        };

        break;
      }
      case 'APP_READY_STATUS_CHANGED': {
        updatedState = {
          caseLevels: state.caseLevels,
          canMoveToProposed: caseUtils.isCaseStagePlanningComplete(
            activeCase.levels,
            activeCase.spineProfile,
            state?.planAssets ?? [],
            state.plan?.version ?? PlanVersionType.Version2,
            state.patientRecord,
            state?.plan?.implants ?? [],
          ),
          errors: caseUtils.getCaseStagePlanningErrors(
            activeCase.levels,
            state?.planAssets ?? [],
            state.plan?.version ?? PlanVersionType.Version2,
            activeCase.patient.patientRecord,
            state?.plan?.implants ?? [],
          ),
          isEditingAllowed: state.isEditingAllowed,
          isStageComplete: state.isStageComplete,
          patientRecord: state.patientRecord,
          plan: state.plan,
          planAssets: state.planAssets,
          requiredAppAssets: state.requiredAppAssets,
          interbodyDataAssets: state.interbodyDataAssets,
        };

        break;
      }
      case 'SELECT_PLAN': {
        const plan = action?.data;
        const planAssets = action?.data?.assets ?? [];
        const requiredAppAssets: AssetType[] = [];
        const optionalAppAssets: AssetType[] = [];
        const interbodyDataAssets: AssetType[] = [];

        const caseLevels = caseUtils.getValidCaseLevels(activeCase.levels) as LevelType[];
        if (plan) {
          const patientRecord = activeCase?.patient?.patientRecord;

          // get the required app assets for this plan
          caseLevels.forEach((caseLevel) => {
            requiredAppAssets.push(`${caseLevel}_APP` as AssetType);
            if (plan?.version === 2) {
              requiredAppAssets.push(`${caseLevel}_CYBORG_IMPLANT` as AssetType);
            }

            const partType = activeCase.levels[caseLevel] as PartType;
            if ([PartType.ALIF_X_TWO_UP, PartType.ALIF_X_TWO_DOWN].includes(partType)) {
              requiredAppAssets.push(`${caseLevel}_IMPLANT_SCREW` as AssetType);
              if (plan?.version === 2) {
                requiredAppAssets.push(`${caseLevel}_IMPLANT_SCREW_GUIDE` as AssetType);
              } else {
                optionalAppAssets.push(`${caseLevel}_IMPLANT_SCREW_GUIDE` as AssetType);
              }
            }

            if ([PartType.ALIF, PartType.ALIF_X_TWO_DOWN].includes(partType)) {
              if (patientRecord.hasPelvisHighPelvicIncidence) {
                requiredAppAssets.push(`${caseLevel}_IMPLANT_INSTRUMENT` as AssetType);
              }
            }

            if ([PartType.LLIF_LEFT, PartType.LLIF_RIGHT].includes(partType)) {
              if (patientRecord.hasPelvisHighCrest || patientRecord.hasPelvisOblique) {
                requiredAppAssets.push(`${caseLevel}_IMPLANT_INSTRUMENT` as AssetType);
              }
            }

            interbodyDataAssets.push(`${caseLevel}_DATA` as AssetType);
          });
        }

        const stageComplete =
          activeCase.stage !== CaseStageType.Open &&
          activeCase.stage !== CaseStageType.Segmentation &&
          activeCase.stage !== CaseStageType.Planning;

        updatedState = {
          caseLevels,
          canMoveToProposed: caseUtils.isCaseStagePlanningComplete(
            activeCase.levels,
            activeCase.spineProfile,
            planAssets,
            plan?.version ?? PlanVersionType.Version2,
            state.patientRecord,
            plan?.implants ?? [],
          ),
          errors: caseUtils.getCaseStagePlanningErrors(
            activeCase.levels,
            planAssets,
            plan?.version ?? PlanVersionType.Version2,
            activeCase.patient.patientRecord,
            plan?.implants ?? [],
          ),
          isEditingAllowed: editingAllowed,
          isStageComplete: stageComplete || !editingAllowed,
          patientRecord: state.patientRecord,
          plan,
          planAssets,
          requiredAppAssets,
          optionalAppAssets,
          interbodyDataAssets,
        };

        break;
      }
      case 'UPDATE_PLUS_SIZE': {
        const plusLevelSize = action?.data;
        if (state.plan) {
          updatedState = {
            ...state,
            plan: {
              ...state.plan,
              plusLevelSize,
            },
          };
        }
        break;
      }
      case 'UPDATE_PATIENT_RECORD': {
        const patientRecord = action?.data;

        updatedState = {
          ...state,
          patientRecord,
        };
        break;
      }
    }
    return updatedState;
  };
