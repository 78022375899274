import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { AssetType, IAsset, ICloudDesignSettings, PartType } from '@workflow-nx/common';
import { Box, Chip, Stack, Typography } from '@mui/material';
import { IconFontButton } from '@workflow-nx/ui';
import { faChevronCircleDown, faChevronCircleUp } from '@fortawesome/pro-solid-svg-icons';
import { gql } from '@apollo/client/core';
import { CloudDesignPartView } from './CloudDesignPartView';

export type CloudDesignSettingsFormData = {
  url: string;
  release: string;
  ntopAutoOp1File?: File;
  ntopAutoOp2File?: File;
  ntopManualOp1File?: File;
  ntopManualOp2File?: File;
};

export function CloudDesignForm() {
  const [open, setOpen] = useState(false);
  const [nTopFiles, setNtopFiles] = useState<IAsset[]>([]);
  const [cloudDesignSettings, setCloudDesignSettings] = useState<ICloudDesignSettings>();

  const [findCloudDesignSettings, { loading: loadingSettings }] = useLazyQuery(
    gql`
      query LoadCloudDesignSettings($assetTypeFilter: [AssetType]) {
        assets(assetTypeFilter: $assetTypeFilter) {
          assetId
          assetType
          fileName
          size
          updatedByUser {
            userId
            firstName
            lastName
          }
          updatedAt
        }
        settings {
          cloudDesign
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      variables: {
        assetTypeFilter: [
          AssetType.NtopAutoOp1AlifFile,
          AssetType.NtopAutoOp2AlifFile,
          AssetType.NtopManualOp1AlifFile,
          AssetType.NtopManualOp2AlifFile,
          AssetType.NtopAutoOp1AlifXFile,
          AssetType.NtopAutoOp2AlifXFile,
          AssetType.NtopManualOp1AlifXFile,
          AssetType.NtopManualOp2AlifXFile,
          AssetType.NtopAutoOp1LlifLeftFile,
          AssetType.NtopAutoOp2LlifLeftFile,
          AssetType.NtopManualOp1LlifLeftFile,
          AssetType.NtopManualOp2LlifLeftFile,
          AssetType.NtopAutoOp1LlifRightFile,
          AssetType.NtopAutoOp2LlifRightFile,
          AssetType.NtopManualOp1LlifRightFile,
          AssetType.NtopManualOp2LlifRightFile,
          AssetType.NtopAutoOp1TlifCLeftFile,
          AssetType.NtopAutoOp2TlifCLeftFile,
          AssetType.NtopManualOp1TlifCLeftFile,
          AssetType.NtopManualOp2TlifCLeftFile,
          AssetType.NtopAutoOp1TlifCRightFile,
          AssetType.NtopAutoOp2TlifCRightFile,
          AssetType.NtopManualOp1TlifCRightFile,
          AssetType.NtopManualOp2TlifCRightFile,
          AssetType.NtopAutoOp1TlifOLeftFile,
          AssetType.NtopAutoOp2TlifOLeftFile,
          AssetType.NtopManualOp1TlifOLeftFile,
          AssetType.NtopManualOp2TlifOLeftFile,
          AssetType.NtopAutoOp1TlifORightFile,
          AssetType.NtopAutoOp2TlifORightFile,
          AssetType.NtopManualOp1TlifORightFile,
          AssetType.NtopManualOp2TlifORightFile,
        ],
      },
      onCompleted: (data) => {
        const cloudDesign = data.settings.cloudDesign;
        setCloudDesignSettings(cloudDesign);
        setNtopFiles(data.assets);
      },
    },
  );

  const handleClose = () => {
    if (open) {
      // props.onClose(false);
    }
    setOpen(!open);
  };

  useEffect(() => {
    if (open) {
      findCloudDesignSettings();
    }
  }, [open]);

  return (
    <Box>
      <form>
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
          <Typography variant={'h4'}>
            Configure Cloud Design <Chip size={'small'} color={'warning'} label={'PREVIEW'}></Chip>
          </Typography>
          <Box>
            <IconFontButton
              icon={open ? faChevronCircleUp : faChevronCircleDown}
              onClick={handleClose}
            />
          </Box>
        </Box>
        {open ? (
          <Stack spacing={1}>
            {!loadingSettings ? (
              <CloudDesignPartView
                partType={PartType.ALIF}
                cloudDesignSettings={cloudDesignSettings}
                ntopFiles={nTopFiles}
                onClose={async (shouldUpdate) => {
                  if (shouldUpdate) {
                    await findCloudDesignSettings();
                  }
                }}
              />
            ) : null}
            {!loadingSettings ? (
              <CloudDesignPartView
                partType={PartType.ALIF_X_TWO_UP}
                cloudDesignSettings={cloudDesignSettings}
                ntopFiles={nTopFiles}
                onClose={async (shouldUpdate) => {
                  if (shouldUpdate) {
                    await findCloudDesignSettings();
                  }
                }}
              />
            ) : null}
            {!loadingSettings ? (
              <CloudDesignPartView
                partType={PartType.LLIF_LEFT}
                cloudDesignSettings={cloudDesignSettings}
                ntopFiles={nTopFiles}
                onClose={async (shouldUpdate) => {
                  if (shouldUpdate) {
                    await findCloudDesignSettings();
                  }
                }}
              />
            ) : null}
            {!loadingSettings ? (
              <CloudDesignPartView
                partType={PartType.LLIF_RIGHT}
                cloudDesignSettings={cloudDesignSettings}
                ntopFiles={nTopFiles}
                onClose={async (shouldUpdate) => {
                  if (shouldUpdate) {
                    await findCloudDesignSettings();
                  }
                }}
              />
            ) : null}
            {!loadingSettings ? (
              <CloudDesignPartView
                partType={PartType.TLIFC_OFFSET_LEFT}
                cloudDesignSettings={cloudDesignSettings}
                ntopFiles={nTopFiles}
                onClose={async (shouldUpdate) => {
                  if (shouldUpdate) {
                    await findCloudDesignSettings();
                  }
                }}
              />
            ) : null}
            {!loadingSettings ? (
              <CloudDesignPartView
                partType={PartType.TLIFC_OFFSET_RIGHT}
                cloudDesignSettings={cloudDesignSettings}
                ntopFiles={nTopFiles}
                onClose={async (shouldUpdate) => {
                  if (shouldUpdate) {
                    await findCloudDesignSettings();
                  }
                }}
              />
            ) : null}
            {!loadingSettings ? (
              <CloudDesignPartView
                partType={PartType.TLIFO_LEFT}
                cloudDesignSettings={cloudDesignSettings}
                ntopFiles={nTopFiles}
                onClose={async (shouldUpdate) => {
                  if (shouldUpdate) {
                    await findCloudDesignSettings();
                  }
                }}
              />
            ) : null}
            {!loadingSettings ? (
              <CloudDesignPartView
                partType={PartType.TLIFO_RIGHT}
                cloudDesignSettings={cloudDesignSettings}
                ntopFiles={nTopFiles}
                onClose={async (shouldUpdate) => {
                  if (shouldUpdate) {
                    await findCloudDesignSettings();
                  }
                }}
              />
            ) : null}
          </Stack>
        ) : null}
      </form>
    </Box>
  );
}
