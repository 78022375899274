export enum MeasurementType {
  L1L4Lordosis = 'L1_L4_LORDOSIS',
  LumbarLordosis = 'LUMBAR_LORDOSIS',
  LumbarCoronalCobb = 'LUMBAR_CORONAL_COBB',
  SegmentalLordoticAngle = 'SEGMENTAL_LORDOTIC_ANGLE',
  SegmentalCoronalAngle = 'SEGMENTAL_CORONAL_ANGLE',
  LordoticAngle = 'LORDOTIC_ANGLE',
  CoronalAngle = 'CORONAL_ANGLE',
  PosteriorHeight = 'POSTERIOR_HEIGHT',
  AnteriorHeight = 'ANTERIOR_HEIGHT',
  ApTranslation = 'AP_TRANSLATION',
  MlTranslation = 'ML_TRANSLATION',
  AxialRotation = 'AXIAL_ROTATION',
  LumbarAngle = 'LUMBAR_ANGLE',
  ThoracicKyphosis = 'THORACIC_KYPHOSIS',
  SagittalVerticalAxis = 'SAGITTAL_VERTICAL_AXIS',
  RealSegmentalLordoticAngle = 'REAL_SEGMENTAL_LORDOTIC_ANGLE',
  RealSegmentalCoronalAngle = 'REAL_SEGMENTAL_CORONAL_ANGLE',

  CervicalLordosis = 'CERVICAL_LORDOSIS',
  CervicalSagittalVerticalAxis = 'CERVICAL_SAGITTAL_VERTICAL_AXIS',
  CervicalCoronalBalance = 'CERVICAL_CORONAL_BALANCE',
  T1Slope = 'T1_SLOPE',
  SLS = 'SLS',
  C2T1CobbAngle = 'C2_T1_COBB_ANGLE',
  C2C7CoronalCobbAngle = 'C2_C7_CORONAL_COBB_ANGLE',
  APTranslation = 'AP_TRANSLATION',
  MLTranslation = 'ML_TRANSLATION',
}

export enum MeasurementsVersionType {
  Version1 = 1,
  Version2 = 2,
}
