import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  heading: {
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
  },
}));

export function Heading(props: { label: string }) {
  const styles = useStyles();

  return (
    <Typography variant="h4" className={styles.heading}>
      {props.label}
    </Typography>
  );
}
