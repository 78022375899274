import { Button as MuiButton } from '@mui/material';
import React, { MouseEventHandler } from 'react';
import { testing } from '@workflow-nx/utils';

type ButtonProps = {
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  disabled?: boolean;
  label: string;
  onClick?: MouseEventHandler;
  variant?: 'text' | 'outlined' | 'contained';
  style?: React.CSSProperties;
  fullWidth?: boolean;
};

export function Button({
  color,
  disabled,
  label,
  onClick,
  variant,
  style,
  fullWidth,
}: ButtonProps) {
  return (
    <MuiButton
      variant={variant}
      fullWidth={fullWidth}
      color={color}
      onClick={onClick}
      disabled={disabled}
      data-test-id={testing.toKebabCase(label) + '-button'}
      style={style}
    >
      {label}
    </MuiButton>
  );
}
