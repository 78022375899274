import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography, useTheme } from '@mui/material';

export function KeyboardButton(props: {
  letter: string;
  direction: string;
  color: string;
  icon: IconProp;
  selected: boolean;
  disabled?: boolean;
  onClick: () => void;
}) {
  const theme = useTheme();

  const bgcolor = props.selected || props.disabled ? '#e0e0e0' : 'white';

  return (
    <Box
      {...props}
      display="flex"
      alignItems={'center'}
      border={`1px solid ${theme.palette.grey[300]}`}
      borderRadius="5px"
      bgcolor={bgcolor}
      onClick={() => {
        if (!props.disabled) {
          props.onClick();
        }
      }}
    >
      <Box
        display={'flex'}
        flexGrow={1}
        flexDirection={'column'}
        justifyContent={'space-evenly'}
        alignItems={'center'}
      >
        <Typography
          variant={'caption'}
          textAlign={'center'}
          color={props.color}
          fontWeight={'bold'}
        >
          {props.direction}
        </Typography>
        <FontAwesomeIcon icon={props.icon} size="xs" />
        <Typography variant={'caption'} textAlign={'center'} color={'text.secondary'}>
          {props.letter}
        </Typography>
      </Box>
    </Box>
  );
}
