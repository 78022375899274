import { MeasurementsVersionType } from '@workflow-nx/common';
import { createContext, ReactNode, useState } from 'react';

export const CaseViewContext = createContext({
  caseMeasurementsVersion: MeasurementsVersionType.Version1,
  toggleCaseMeasurementsVersion: (): MeasurementsVersionType => {
    return MeasurementsVersionType.Version1;
  },
});

export const CaseViewProvider = ({
  measurementsVersion,
  children,
}: {
  measurementsVersion: MeasurementsVersionType;
  children: ReactNode;
}) => {
  const [caseMeasurementsVersion, setCaseMeasurementsVersion] = useState<MeasurementsVersionType>(
    measurementsVersion ?? MeasurementsVersionType.Version1,
  );

  const handleToggleCaseMeasurementsVersion = (): MeasurementsVersionType => {
    const updatedCaseMeasurementVersion =
      caseMeasurementsVersion === MeasurementsVersionType.Version1
        ? MeasurementsVersionType.Version2
        : MeasurementsVersionType.Version1;
    setCaseMeasurementsVersion(updatedCaseMeasurementVersion);

    return updatedCaseMeasurementVersion;
  };

  return (
    <CaseViewContext.Provider
      value={{
        caseMeasurementsVersion: caseMeasurementsVersion,
        toggleCaseMeasurementsVersion: handleToggleCaseMeasurementsVersion,
      }}
    >
      {children}
    </CaseViewContext.Provider>
  );
};
