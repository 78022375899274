import { ImplantType, ISurgeonPreferenceImplantMeasurement } from '@workflow-nx/common';
import { Box, Table, TableBody, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { TableHeadCell } from '../../../../components/TableHeadCell';
import { SurgeonPreferencesMeasurementViewTableRow } from './SurgeonPreferencesMeasurementViewTableRow';

interface ViewSurgeonPreferencesMeasurementProps {
  surgeonPreferenceImplantMeasurements: ISurgeonPreferenceImplantMeasurement[];
  implantType: ImplantType;
}

export function ViewSurgeonPreferencesMeasurement({
  surgeonPreferenceImplantMeasurements,
  implantType,
}: ViewSurgeonPreferencesMeasurementProps) {
  return (
    <Box width={600}>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeadCell>
              <strong>Measurement Type</strong>
            </TableHeadCell>
            <TableHeadCell>
              <strong>Min</strong>
            </TableHeadCell>
            <TableHeadCell>
              <strong>Max</strong>
            </TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {surgeonPreferenceImplantMeasurements.map(
            (implantMeasurement: ISurgeonPreferenceImplantMeasurement) => {
              return implantMeasurement?.implantType === implantType ? (
                <SurgeonPreferencesMeasurementViewTableRow
                  key={`${implantType}.${implantMeasurement?.measurementType}`}
                  implantType={implantType}
                  measurementType={implantMeasurement?.measurementType}
                  min={implantMeasurement?.min}
                  max={implantMeasurement?.max}
                />
              ) : null;
            },
          )}
        </TableBody>
      </Table>
    </Box>
  );
}
