import { format, TaskStatusType, Permission } from '@workflow-nx/common';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { FIND_TASK, UPDATE_TASK, DELETE_TASK } from '../../gql';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import { Box, Grid, Button, Divider } from '@mui/material';
import CustomDialog from '../../components/CustomDialog';
import { ProgressButton, TextField } from '@workflow-nx/ui';
import { useConfirm } from 'material-ui-confirm';
import { IUpdateTaskDialogFormValues, updateTaskDialogFormValues } from '../../extras/formValues';
import { updateTaskDialogSchema } from '../../extras/schemas';
import { FormattedFieldView } from '../../components/FormattedFieldView';
import useAuth from '../../hooks/useAuth';

export function EditTaskStatusDialog({
  open,
  taskId,
  onClose,
}: {
  open: boolean;
  taskId: number | undefined;
  onClose: (shouldUpdate: boolean) => void;
}) {
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [updateTask] = useMutation(UPDATE_TASK);
  const [deleteTask] = useMutation(DELETE_TASK);
  const confirm = useConfirm();

  const [findTask, { data, loading }] = useLazyQuery(FIND_TASK, { fetchPolicy: 'network-only' });

  useEffect(() => {
    const handleLoad = async (taskId: number) => {
      await findTask({
        variables: {
          taskId,
        },
      });
    };

    if (open && !!taskId) {
      handleLoad(taskId);
    }
  }, [taskId, open, findTask]);

  const handleSubmitForm = async (
    values: FormikValues,
    { setStatus, setSubmitting }: FormikHelpers<IUpdateTaskDialogFormValues>,
  ) => {
    try {
      const taskStatusType = values.shouldCancel
        ? TaskStatusType.Cancelled
        : TaskStatusType.Complete;
      await updateTask({
        variables: {
          taskId: data?.task?.taskId,
          status: taskStatusType,
          statusDescription: values.statusDescription,
        },
      });

      setStatus({ success: true });
      enqueueSnackbar('Task Status Updated', {
        variant: 'success',
      });

      onClose(true);
    } catch (err) {
      console.error(err);
      setStatus({ success: false });
      enqueueSnackbar('An error occurred updating the task status', {
        variant: 'error',
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleDeleteTaskClick = async () => {
    try {
      await confirm({
        title: `Delete Task`,
        description: 'Are you sure you want to delete this task?',
      });
      try {
        await deleteTask({
          variables: {
            taskId: Number(data?.task?.taskId),
          },
        });

        enqueueSnackbar(`Task was successfully deleted.`, {
          variant: 'success',
        });

        onClose(true);
      } catch (errors) {
        console.error(errors);
        enqueueSnackbar('Error deleting Task', {
          variant: 'error',
        });
      }
    } catch (errors) {
      console.error(errors);
    }
  };

  const canEditTask =
    (data?.task?.assignedUser?.userId === auth?.user?.userId ||
      auth?.hasPermission?.([Permission.ManageTask])) &&
    data?.task?.status === TaskStatusType.Open;

  const canDeleteTask =
    auth?.hasPermission?.([Permission.ManageTask]) && data?.task?.status === TaskStatusType.Open;

  return !loading ? (
    <Formik
      initialValues={updateTaskDialogFormValues()}
      validationSchema={updateTaskDialogSchema}
      onSubmit={handleSubmitForm}
      enableReinitialize={true}
    >
      {({ setFieldValue, handleSubmit, isSubmitting, submitForm }) => (
        <CustomDialog
          maxWidth={'lg'}
          open={open}
          title={'Edit Task Status'}
          onClose={() => {
            onClose(false);
          }}
          positiveActionButtons={
            canEditTask
              ? [
                  <ProgressButton
                    onClick={async () => {
                      setFieldValue('shouldCancel', true);
                      await submitForm();
                    }}
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    label={'Remove Task'}
                    variant="outlined"
                  />,
                  <ProgressButton
                    onClick={async () => {
                      setFieldValue('shouldCancel', false);
                      submitForm();
                    }}
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    label={'Mark as complete'}
                  />,
                ]
              : []
          }
          negativeActionButtons={
            canDeleteTask
              ? [
                  <Button variant={'outlined'} onClick={handleDeleteTaskClick}>
                    Delete
                  </Button>,
                ]
              : []
          }
        >
          {open ? (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} alignItems={'center'}>
                <Grid item xs={12}>
                  <FormattedFieldView label={'Task Description'} value={data?.task?.description} />
                </Grid>
                <Grid item xs>
                  <FormattedFieldView
                    label={'Assigned To'}
                    value={
                      data?.task?.assignedUser ? (
                        format.formatName(data?.task?.assignedUser)
                      ) : (
                        <span>&mdash;</span>
                      )
                    }
                  />
                </Grid>
                <Grid item xs>
                  <FormattedFieldView
                    label={'Surgeon'}
                    value={
                      data?.task?.taskCase?.surgeonUser ? (
                        format.formatName(data?.task?.taskCase?.surgeonUser)
                      ) : (
                        <span>&mdash;</span>
                      )
                    }
                  />
                </Grid>
                <Grid item xs>
                  <FormattedFieldView
                    label={'Priority'}
                    value={format.formatTaskPriorityType(data?.task?.priority)}
                  />
                </Grid>
                <Grid item xs>
                  <FormattedFieldView
                    label={'Due Date'}
                    value={format.formatISODate(data?.task?.dueDate as unknown as string)}
                  />
                </Grid>
                <Grid item xs>
                  <FormattedFieldView
                    label={'Created By'}
                    value={
                      data?.task?.assignedUser ? (
                        format.formatName(data?.task?.createdByUser)
                      ) : (
                        <span>&mdash;</span>
                      )
                    }
                  />
                </Grid>
                <Grid item xs>
                  <FormattedFieldView
                    label={'Created At'}
                    value={format.formatISODate(data?.task?.createdAt as unknown as string)}
                  />
                </Grid>
                <Grid item xs>
                  <FormattedFieldView
                    label={'Status'}
                    value={format.formatTaskStatusType(data?.task?.status)}
                  />
                </Grid>
                <Box my={4} />
                <Box my={3}>
                  <Divider />
                </Box>
                <Grid item xs={12}>
                  {canEditTask ? (
                    <TextField
                      name={'statusDescription'}
                      label={'Status Reason'}
                      multiline={true}
                      disabled={isSubmitting}
                    />
                  ) : (
                    <FormattedFieldView
                      label={'Status Reason'}
                      value={data?.task?.statusDescription}
                    />
                  )}
                </Grid>
                <Grid item md={6} xs={12} />
              </Grid>
              <Box mt={1} />
            </form>
          ) : null}
        </CustomDialog>
      )}
    </Formik>
  ) : null;
}
