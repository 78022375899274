import { Control, FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Box, Button, Stack, Typography } from '@mui/material';
import { SwitchRHF, TextFieldRHF } from '@workflow-nx/ui';
import { CommentType, IComment } from '@workflow-nx/common';
import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { CREATE_COMMENT, DELETE_ASSET, UPDATE_COMMENT } from '../../../../gql';
import { useSnackbar } from 'notistack';
import { file } from '@workflow-nx/utils';
import { LabelledProgressBar } from '../../../../components/LabelledProgressBar';

export function CreateCommentView(props: {
  caseId: number;
  commentType: CommentType;
  editComment: IComment | undefined;
  onCreate: () => void;
}) {
  const { enqueueSnackbar } = useSnackbar();

  const [createComment, { loading: loadingCreateComment }] = useMutation(CREATE_COMMENT);
  const [updateComment, { loading: loadingUpdateComment }] = useMutation(UPDATE_COMMENT);
  const [deleteAsset] = useMutation(DELETE_ASSET);

  const [uploadProgress, setUploadProgress] = useState(0);

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm<{
    attachment?: File;
    comment: string;
    disableNotifications?: boolean;
  }>({
    defaultValues: {
      attachment: undefined,
      comment: props.editComment?.comment ?? '',
    },
    resolver: yupResolver(
      Yup.object().shape({
        attachment: Yup.mixed<File>(),
        comment: Yup.string().required(),
        disableNotifications: Yup.boolean(),
      }),
    ),
  });

  const handleSubmitForm: SubmitHandler<{
    attachment?: File;
    comment: string;
    disableNotifications?: boolean;
  }> = async (data) => {
    try {
      if (props.editComment) {
        await updateComment({
          variables: {
            commentId: props.editComment.commentId,
            comment: data.comment,
          },
        });
        enqueueSnackbar('Comment was edited successfully', {
          variant: 'success',
        });
      } else {
        const response = await createComment({
          variables: {
            caseId: props.caseId,
            comment: data.comment,
            disableNotifications: data.disableNotifications,
            commentType: props.commentType,
            fileName: data?.attachment?.name,
            fileSize: data?.attachment?.size,
          },
        });

        const signedUrl = response?.data?.createComment?.signedUrl;
        if (signedUrl) {
          try {
            await file.uploadFile(signedUrl, data, (percentComplete: number) => {
              setUploadProgress(percentComplete);
            });
          } catch (e) {
            await deleteAsset({
              variables: {
                assetId: signedUrl?.assetId,
              },
            });
            enqueueSnackbar(`Upload failed for asset: ${signedUrl.assetType}`, {
              variant: 'error',
            });
            console.error(e);
          }
        }

        enqueueSnackbar('Comment was added successfully', {
          variant: 'success',
        });
      }
      props.onCreate();
    } catch (err) {
      console.error(err);
      enqueueSnackbar('An error occurred creating/updating a comment for the case', {
        variant: 'error',
      });
    } finally {
      reset();
      setUploadProgress(0);
    }
  };

  useEffect(() => {
    reset({ comment: props?.editComment?.comment ?? '' });
  }, [props.editComment]);

  return (
    <Box display={'flex'} alignItems={'flex-start'} justifyContent={'flex-start'} px={3}>
      <Stack gap={1} flex={1}>
        <TextFieldRHF
          control={control as unknown as Control<FieldValues>}
          multiline={true}
          label={''}
          name={'comment'}
          disabled={loadingCreateComment || loadingUpdateComment || isSubmitting}
        />
        {!props.editComment
          ? [
              /*
              <DropzoneRHF
                name={'attachment'}
                control={control as unknown as Control}
                validFileExtensions={[]}
                variant={'small'}
                disabled={loadingCreateComment || loadingUpdateComment || isSubmitting}
              />,
*/
              <Box>
                {uploadProgress > 0 ? (
                  <LabelledProgressBar
                    variant={'determinate'}
                    value={uploadProgress}
                    fullWidth={true}
                  />
                ) : null}
              </Box>,
              <Stack direction={'row'} alignItems={'center'}>
                <SwitchRHF
                  name={'disableNotifications'}
                  control={control as unknown as Control<FieldValues>}
                  disabled={loadingCreateComment || loadingUpdateComment || isSubmitting}
                />
                <Typography>Do not send an email for this comment</Typography>
              </Stack>,
            ]
          : null}
      </Stack>
      <Box px={1} />
      <Stack alignItems={'center'} justifyContent={'center'} gap={2}>
        {props.editComment ? (
          [
            <Button
              variant={'outlined'}
              disabled={isSubmitting}
              onClick={(evt) => handleSubmit(handleSubmitForm)(evt)}
              data-testid="comment-update-button"
            >
              Update
            </Button>,
            <Button
              variant={'text'}
              onClick={props.onCreate}
              disabled={loadingCreateComment || loadingUpdateComment || isSubmitting}
            >
              Cancel
            </Button>,
          ]
        ) : (
          <Button
            disabled={isSubmitting}
            variant={'outlined'}
            onClick={(evt) => handleSubmit(handleSubmitForm)(evt)}
          >
            Send
          </Button>
        )}
      </Stack>
    </Box>
  );
}
