import React, { Dispatch } from 'react';
import { AssetType, format } from '@workflow-nx/common';
import { Box, Button, FormControlLabel, FormGroup, Switch } from '@mui/material';
import { Select } from '../../../../components/Select';
import { AssetsTableActionType, AssetsTabStateType } from './AssetsTab.reducer';

export function AssetsTableFilter(props: {
  state: AssetsTabStateType;
  loading: boolean;
  dispatch: Dispatch<AssetsTableActionType>;
}) {
  const assetTypeMenuItems = Object.values(AssetType).map((assetType) => {
    return {
      key: assetType,
      value: format.formatAssetType(assetType as AssetType),
    };
  });

  return (
    <Box display={'flex'} flexDirection={'column'} gap={2}>
      <Box>
        <Select
          name={'assetType'}
          label={'Asset Type'}
          multiple
          value={(props.state?.assetTypeFilter ?? []) as unknown as string}
          onChange={(event) => {
            props.dispatch({
              type: 'ASSETS_TYPE_CHANGED',
              data: event.target.value,
            });
          }}
          menuItems={assetTypeMenuItems}
          disabled={props.loading}
          fullWidth={true}
        />
      </Box>
      <Box>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={props.state.showDeleted}
                onChange={(event) => {
                  props.dispatch({
                    type: 'SHOW_DELETED_TOGGLE_CHANGED',
                    data: event.target.checked,
                  });
                }}
              />
            }
            label="Show Deleted"
          />
        </FormGroup>
      </Box>
      <Box>
        <Box textAlign={'center'}>
          <Button onClick={() => props.dispatch({ type: 'RESET_FILTERS' })}>Reset Filters</Button>
        </Box>
      </Box>
    </Box>
  );
}
