import { useSnackbar } from 'notistack';
import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import { useMutation } from '@apollo/client';
import { CREATE_ORGANIZATION, CREATE_PRICE_LEVEL } from '../../../gql';
import { createOrganizationDialogSchema } from '../../../extras/schemas';
import CustomDialog from '../../../components/CustomDialog';
import { Grid, Box } from '@mui/material';
import {
  createOrganizationDialogFormValues,
  ICreateOrganizationDialogFormValues,
} from '../../../extras/formValues';
import { OrganizationType } from '@workflow-nx/common';
import { ProgressButton, SelectField, TextField } from '@workflow-nx/ui';
import { CreatePriceLevelForm } from './CreatePriceLevelForm';
import { DateTime } from 'luxon';
import { sortBy } from 'lodash';

export function CreateOrganizationDialog(props: {
  open: boolean;
  organizationType?: OrganizationType;
  onCreate: (caseId: string) => void;
  onClose: () => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [createOrganization, { loading: loadingCreateOrganization }] =
    useMutation(CREATE_ORGANIZATION);
  const [createPriceLevel, { loading: loadingCreatePriceLevel }] = useMutation(CREATE_PRICE_LEVEL);

  const organizationTypes = sortBy(
    [
      { key: OrganizationType.Distributor, value: 'Distributor' },
      { key: OrganizationType.Manufacturer, value: 'Manufacturer' },
      { key: OrganizationType.Vendor, value: 'Vendor' },
    ],
    ['value'],
  );

  const handleSubmitForm = async (
    values: ICreateOrganizationDialogFormValues,
    { setStatus, setSubmitting, resetForm }: FormikHelpers<ICreateOrganizationDialogFormValues>,
  ) => {
    try {
      const { data } = await createOrganization({
        variables: {
          name: values.name,
          organizationType: values.organizationType,
        },
      });

      const organizationId = data.createOrganization.organization.organizationId;

      const startDate = values.startDate
        ? DateTime.fromJSDate(values.startDate).toISODate()
        : undefined;

      const endDate = values.endDate ? DateTime.fromJSDate(values.endDate).toISODate() : null;

      if (values.organizationType === OrganizationType.Hospital) {
        await createPriceLevel({
          variables: {
            organizationId,
            priceLevelType: values.priceLevelType,
            oneLevel: Number(values.oneLevel),
            twoLevel: Number(values.twoLevel),
            threeLevel: Number(values.threeLevel),
            fourLevel: Number(values.fourLevel),
            fiveLevel: Number(values.fiveLevel),
            startDate,
            endDate,
          },
        });
      }

      setStatus({ success: true });
      enqueueSnackbar('Organization created', {
        variant: 'success',
      });

      props.onCreate(organizationId);
    } catch (err) {
      console.error(err);
      setStatus({ success: false });
      enqueueSnackbar('An error occurred creating the organization', {
        variant: 'error',
      });
    } finally {
      setSubmitting(false);
      resetForm();
    }
  };

  return (
    <Formik
      initialValues={createOrganizationDialogFormValues(props.organizationType)}
      validationSchema={createOrganizationDialogSchema}
      onSubmit={handleSubmitForm}
    >
      {({ resetForm, submitForm, isSubmitting, values }) => {
        const loading = loadingCreateOrganization || loadingCreatePriceLevel;
        return (
          <CustomDialog
            maxWidth={'md'}
            open={props.open}
            title={'Create Organization'}
            onClose={() => {
              resetForm();
              props.onClose();
            }}
            positiveActionButtons={[
              <ProgressButton
                variant={'contained'}
                disabled={isSubmitting || loading}
                onClick={() => submitForm()}
                label={'Create'}
                loading={isSubmitting || loading}
              />,
            ]}
          >
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12} md={!props.organizationType ? 8 : 12}>
                  <TextField name={'name'} label={'Name'} disabled={isSubmitting} />
                </Grid>
                {!props.organizationType ? (
                  <Grid item xs={12} md={4}>
                    <SelectField
                      name={'organizationType'}
                      label={'Organization Type'}
                      menuItems={organizationTypes}
                      disabled={isSubmitting}
                    />
                  </Grid>
                ) : null}
                <Grid item xs={12} md={!props.organizationType ? 8 : 12}>
                  {values.organizationType === OrganizationType.Hospital ? (
                    <CreatePriceLevelForm isSubmitting={isSubmitting} />
                  ) : null}
                </Grid>
              </Grid>
            </form>
          </CustomDialog>
        );
      }}
    </Formik>
  );
}
