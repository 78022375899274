import React, { useState, useEffect, ChangeEvent } from 'react';
import { format, StatementType, Statement } from '@workflow-nx/common';
import { useFormContext, Control, FieldValues, SetFieldValue } from 'react-hook-form';
import { TableRow } from '@mui/material';
import { TableCell } from '../../../../../components/TableCell';
import { CheckboxRHF, TextFieldRHF } from '@workflow-nx/ui';
import { CaseReportDialogStateType } from './CaseReportDialog.reducer';

export function CaseReportGoalAlignmentTableRow(props: {
  correctionParameter: string;
  alignmentGoal: string;
  targetFormula: string;
  calculation: string;
  correctionNeeded: string;
}) {
  useEffect(() => {
    //
  }, []);

  return (
    <TableRow hover>
      <TableCell>{props.correctionParameter}</TableCell>
      <TableCell>{props.alignmentGoal}</TableCell>
      <TableCell>{props.targetFormula}</TableCell>
      <TableCell>{props.calculation}</TableCell>
      <TableCell>{props.correctionNeeded}</TableCell>
    </TableRow>
  );
}
