import { useSnackbar } from 'notistack';
import React from 'react';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import { useMutation } from '@apollo/client';
import { UPDATE_CASE_NUMBER } from '../../../gql';
import {
  IUpdateCaseNumberDialogFormValues,
  updateCaseNumberDialogFormValues,
} from '../../../extras/formValues';
import { updateCaseNumberDialogSchema } from '../../../extras/schemas';
import CustomDialog from '../../../components/CustomDialog';
import { ICase } from '@workflow-nx/common';
import { Box, Grid } from '@mui/material';
import { DatePickerField } from '../../../components/DatePickerField';
import { Alert } from '@mui/material';
import { format } from '@workflow-nx/common';
import { DateTime } from 'luxon';
import { ProgressButton, TextField } from '@workflow-nx/ui';
import { date } from '@workflow-nx/utils';

function getUpdatedCaseNumber(receivedAt: Date, firstName: string, lastName: string) {
  const formattedDate = format.formatDate(receivedAt, 'yyMMdd');
  const firstInitial = firstName?.length > 0 ? firstName[0].toUpperCase() : 'X';
  const lastInitial = lastName?.length > 0 ? lastName[0].toUpperCase() : 'X';

  return `${formattedDate}.${firstInitial}${lastInitial}`;
}

export function UpdateCaseNumberDialog(props: {
  open: boolean;
  activeCase: ICase;
  onUpdate: (caseId: string) => void;
  onClose: () => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [updateCaseNumber, { loading: loadingUpdateCaseNumber }] = useMutation(UPDATE_CASE_NUMBER);

  const handleSubmitForm = async (
    values: FormikValues,
    { setStatus, setSubmitting }: FormikHelpers<IUpdateCaseNumberDialogFormValues>,
  ) => {
    try {
      const receivedAt = date.parseCalendarDateFromString(
        values.receivedAt instanceof Date ? values.receivedAt.toISOString() : values.receivedAt,
      );

      if (!receivedAt) {
        return;
      }

      const { data } = await updateCaseNumber({
        variables: {
          caseId: props.activeCase.caseId,
          receivedAt: DateTime.fromJSDate(receivedAt).toISODate(),
          firstName: values.firstName,
          lastName: values.lastName,
        },
      });

      const caseId = data.updateCaseNumber.case.caseId;

      setStatus({ success: true });
      enqueueSnackbar('Case Number Updated', {
        variant: 'success',
      });

      props.onUpdate(caseId);
    } catch (err) {
      console.error(err);
      setStatus({ success: false });
      enqueueSnackbar('An error occurred updating the case number', {
        variant: 'error',
      });
    } finally {
      setSubmitting(false);
    }
  };

  return props.open ? (
    <Formik
      initialValues={updateCaseNumberDialogFormValues(props.activeCase)}
      enableReinitialize={true}
      validationSchema={updateCaseNumberDialogSchema}
      onSubmit={handleSubmitForm}
    >
      {({ values, resetForm, submitForm, isSubmitting }) => {
        const updatedCaseNumber = getUpdatedCaseNumber(
          values.receivedAt,
          values.firstName,
          values.lastName,
        );

        return (
          <CustomDialog
            maxWidth={'sm'}
            open={props.open}
            title={'Update Case Number'}
            onClose={() => {
              resetForm();
              props.onClose();
            }}
            positiveActionButtons={[
              <ProgressButton
                variant={'contained'}
                disabled={isSubmitting || loadingUpdateCaseNumber}
                onClick={() => submitForm()}
                label={'Update'}
                loading={isSubmitting || loadingUpdateCaseNumber}
              />,
            ]}
          >
            <form>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <DatePickerField
                    disabled={isSubmitting || loadingUpdateCaseNumber}
                    name="receivedAt"
                    label={'Received Date'}
                    required={true}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    name={'firstName'}
                    label={'First Name'}
                    disabled={isSubmitting || loadingUpdateCaseNumber}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    name={'lastName'}
                    label={'Last Name'}
                    disabled={isSubmitting || loadingUpdateCaseNumber}
                  />
                </Grid>
              </Grid>

              {!props?.activeCase?.number.startsWith(updatedCaseNumber) ? (
                <Box mt={2}>
                  <Alert severity={'info'}>
                    The case number will be changed to <strong>{updatedCaseNumber}.XX</strong>
                  </Alert>
                </Box>
              ) : null}
            </form>
          </CustomDialog>
        );
      }}
    </Formik>
  ) : null;
}
