import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import {
  ConfigurationKeyType,
  ConfigurationValueType,
  IConfiguration,
  IConfigurationValueStructure,
  IGreenlightGuruConfiguration,
  schemas,
} from '@workflow-nx/common';
import { Control, FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { ServicesSectionForm } from './ServicesSectionForm';

export function GreenlightGuruForm(props: {
  configurationData?: IConfigurationValueStructure[];
  handleSubmit: (formData: IConfiguration) => void;
}) {
  const valueFields = [
    { id: 'exportApiUrl', label: 'Export API URL', type: ConfigurationValueType.Url },
    { id: 'apiKey', label: 'API Key', type: ConfigurationValueType.Password },
    { id: 'cacheDisabled', label: 'Cache Disabled', type: ConfigurationValueType.Boolean },
  ];

  const initialConfigFormData = Object.fromEntries(
    valueFields.map((field) => [
      field.id,
      props.configurationData?.find((config) => config.key === field.id)?.value?.value ??
        (field.type !== ConfigurationValueType.Boolean ? '' : true),
    ]),
  ) as unknown as IGreenlightGuruConfiguration;

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty, dirtyFields },
  } = useForm<IGreenlightGuruConfiguration>({
    defaultValues: initialConfigFormData ?? undefined,
    resolver: yupResolver(schemas.configurationGreenlightGuruSchema),
  });

  const handleSubmitForm: SubmitHandler<IGreenlightGuruConfiguration> = async (data) => {
    const changedValues: IConfigurationValueStructure[] = [];
    const changedFields = props.configurationData ? dirtyFields : data;

    for (const [key] of Object.entries(changedFields)) {
      changedValues.push({
        key,
        value: {
          type: valueFields?.find((field) => field.id === key)?.type ?? ConfigurationValueType.Text,
          value: (data as unknown as Record<string, unknown>)[key],
        },
      });
    }

    props.handleSubmit({ key: ConfigurationKeyType.GreenlightGuru, value: changedValues });
  };

  return (
    <Box>
      <form>
        <ServicesSectionForm
          title={'Greenlight Guru'}
          fields={valueFields}
          control={control as unknown as Control<FieldValues>}
          submitHandler={(evt) => handleSubmit(handleSubmitForm)(evt)}
          disabled={isSubmitting || !isDirty}
        />
      </form>
    </Box>
  );
}
