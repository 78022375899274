import React from 'react';
import { Table, TableBody, TableHead, TableRow } from '@mui/material';
import { TableHeadCell } from '../../../../../components/TableHeadCell';
import { CaseReportGoalAlignmentTableRow } from './CaseReportGoalAlignmentTableRow';
import { CaseReportAlignmentGoalType } from '../../../../../utils/caseReport/caseReportAlignmentGoals';

interface CaseReportAlignmentGoalTableProps {
  caseReportAlignmentGoals: CaseReportAlignmentGoalType[];
}

export function CaseReportAlignmentGoalViewTable({
  caseReportAlignmentGoals,
}: CaseReportAlignmentGoalTableProps) {
  const lordoticAlignmentGoals = caseReportAlignmentGoals.filter(
    (item) => item.parameterType === 'LORDOTIC',
  );
  const coronalAlignmentGoals = caseReportAlignmentGoals.filter(
    (item) => item.parameterType === 'CORONAL',
  );

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeadCell>Parameter</TableHeadCell>
          <TableHeadCell>Alignment Goal</TableHeadCell>
          <TableHeadCell>Target Formula</TableHeadCell>
          <TableHeadCell>Calculation</TableHeadCell>
          <TableHeadCell>Correction Needed</TableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {lordoticAlignmentGoals?.length > 0 ? (
          <CaseReportGoalAlignmentTableRow
            correctionParameter={lordoticAlignmentGoals[0].correctionParameter}
            alignmentGoal={lordoticAlignmentGoals[0].alignmentGoal}
            targetFormula={lordoticAlignmentGoals[0].targetFormula}
            calculation={lordoticAlignmentGoals[0].calculation}
            correctionNeeded={lordoticAlignmentGoals[0].correctionNeeded}
          />
        ) : null}
        {coronalAlignmentGoals.length > 0
          ? coronalAlignmentGoals.map(
              (coronalAlignmentGoal: CaseReportAlignmentGoalType, alignmentGoalIndex: number) => (
                <CaseReportGoalAlignmentTableRow
                  key={`${alignmentGoalIndex}`}
                  correctionParameter={coronalAlignmentGoal.correctionParameter}
                  alignmentGoal={coronalAlignmentGoal.alignmentGoal}
                  targetFormula={coronalAlignmentGoal.targetFormula}
                  calculation={coronalAlignmentGoal.calculation}
                  correctionNeeded={coronalAlignmentGoal.correctionNeeded}
                />
              ),
            )
          : null}
      </TableBody>
    </Table>
  );
}
