import { format, ICaseHold } from '@workflow-nx/common';
import { Box } from '@mui/material';
import { Alert, AlertTitle } from '@mui/material';
import React from 'react';

export function CaseHoldView(props: { caseHold: ICaseHold }) {
  return (
    <Box mt={1}>
      <Alert severity={'warning'}>
        <AlertTitle>Case Is On-Hold</AlertTitle>
        Case was put on <strong>
          {format.formatCaseHoldReasonType(props?.caseHold.holdType)}
        </strong>{' '}
        hold for the reason <strong>{props.caseHold.reason}</strong>.
      </Alert>
    </Box>
  );
}
