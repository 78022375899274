import React, { Dispatch } from 'react';
import { Box, Chip, Link, TableBody, TableRow, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  CaseSpineType,
  CaseStageType,
  caseUtils,
  format,
  ICase,
  ITableHeader,
  UserRoleType,
} from '@workflow-nx/common';
import { TableCell } from '../../../components/TableCell';
import { blue, red } from '@mui/material/colors';
import { CommonTable } from '../../../components/CommonTable';
import { ListCasesActionType } from './ListCases.reducer';
import { CaseTypeChip } from '../../../components/CaseTypeChip';
import { UserAvatar } from '@workflow-nx/ui';
import { useNavigate } from 'react-router-dom';
import { Globals } from '../../../layouts/DashboardLayout';

type CaseListTableProps = {
  cases: ICase[];
  page?: number;
  rowsPerPage?: number;
  totalCaseCount?: number;
  orderBy?:
    | { [key: string]: { [key: string]: 'asc' | 'desc' } }
    | { [key: string]: 'asc' | 'desc' };
  dispatch?: Dispatch<ListCasesActionType>;
  shouldHighlight?: (activeCase: ICase) => boolean;
  loading?: boolean;
  onSort: (
    orderBy:
      | { [key: string]: { [key: string]: 'asc' | 'desc' } }
      | { [key: string]: 'asc' | 'desc' },
  ) => void;
  onPagination: (pageSize: number, pageNumber: number) => void;
};

const useStyles = makeStyles(() => ({
  highlightedRow: {
    backgroundColor: blue[50],
  },
  cancelledRow: {
    backgroundColor: red[50],
  },
  row: {
    height: 50,
    '& :hover': {
      cursor: 'pointer',
    },
  },
}));

const CaseListTable = ({
  cases,
  totalCaseCount,
  page,
  rowsPerPage,
  orderBy,
  shouldHighlight,
  loading,
  onSort,
  onPagination,
}: CaseListTableProps) => {
  const navigate = useNavigate();
  const styles = useStyles();
  const headers: ITableHeader[] = [
    {
      id: 'number',
      sortable: true,
      label: 'Case #',
    },
    {
      id: 'stageSortOrder',
      sortable: true,
      label: 'Stage',
    },
    {
      id: 'surgeryDate',
      sortable: true,
      label: 'Surgery Date',
      align: 'center',
    },
    {
      id: 'isSurgeryDateTentative',
      sortable: true,
      label: 'Tent.?',
    },
    {
      id: 'levels',
      sortable: false,
      label: 'Levels (Plan / Comp.)',
      align: 'center',
    },
    {
      id: 'surgeonId',
      sortable: true,
      label: 'Surgeon',
      align: 'center',
    },
    {
      id: 'locationId',
      sortable: true,
      label: 'Hospital',
      align: 'center',
    },
    {
      id: 'assignedId',
      sortable: true,
      label: 'Assigned',
      align: 'center',
    },
    {
      id: 'fieldRepId',
      sortable: true,
      label: `${format.formatUserRole(UserRoleType.FieldRep)}`,
      align: 'center',
    },
    {
      id: 'updatedAt',
      sortable: true,
      label: 'Updated',
      align: 'center',
    },
  ];

  const showPagination = Boolean(rowsPerPage && page !== undefined);

  return (
    <CommonTable
      orderBy={orderBy}
      headers={headers}
      onHeaderClick={(orderBy) => {
        onSort(orderBy);
      }}
      rows={
        <TableBody>
          {cases?.map((activeCase: ICase) => {
            return (
              <TableRow
                hover
                key={activeCase.caseId}
                onClick={() => navigate(`/app/cases/${activeCase.caseId}`)}
                className={`${styles.row} ${
                  shouldHighlight && shouldHighlight(activeCase) ? styles.highlightedRow : undefined
                } ${activeCase.caseCancellation ? styles.cancelledRow : undefined}`}
              >
                <TableCell>
                  <Box display={'flex'} alignItems={'center'}>
                    <Link
                      underline={'none'}
                      variant={'body1'}
                      href={`/app/cases/${activeCase.caseId}`}
                      onClick={(e) => e.preventDefault()}
                    >
                      {activeCase.number}
                      {activeCase.spineType === CaseSpineType.Cervical ? (
                        <Chip size={'small'} color={'secondary'} label={'CERVICAL'} />
                      ) : null}
                      <CaseTypeChip caseType={activeCase.caseType} />
                    </Link>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box width={100} overflow={'hidden'} textOverflow={'ellipsis'}>
                    {format.formatCaseStage(activeCase.stage)}
                    {activeCase.caseCancellation ? (
                      <>
                        <Typography variant={'h5'}>
                          {format.formatCaseCancellationType(
                            activeCase.caseCancellation.cancellationType,
                          )}
                        </Typography>
                        <Typography variant={'body1'} noWrap={true}>
                          {format.formatCaseCancellationReasonType(activeCase.caseCancellation)}
                        </Typography>
                      </>
                    ) : null}
                    {!activeCase.caseCancellation &&
                    activeCase.stage === CaseStageType.Production ? (
                      <Tooltip title={format.formatEventType(activeCase.events?.[0]?.eventType)}>
                        <Box>
                          {activeCase.events?.[0]?.eventType ? (
                            <Typography noWrap={true} variant={'button'} color={'textSecondary'}>
                              {format.formatEventType(activeCase.events?.[0]?.eventType)}
                            </Typography>
                          ) : null}
                        </Box>
                      </Tooltip>
                    ) : null}
                  </Box>
                </TableCell>
                <TableCell align={'center'}>
                  <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                    {activeCase?.caseHold ? (
                      <Box mr={1}>
                        <Chip size={'small'} color={'secondary'} label={'On-Hold'} />
                      </Box>
                    ) : null}
                    {activeCase.surgeryDate
                      ? format.formatISODate(activeCase.surgeryDate as unknown as string)
                      : 'TBD'}
                  </Box>
                </TableCell>
                <TableCell>
                  {activeCase?.isSurgeryDateTentative ? (
                    <Tooltip title={'Tentative'}>
                      <Chip size={'small'} color={'secondary'} label={'T'} />
                    </Tooltip>
                  ) : null}
                </TableCell>
                <TableCell align={'center'}>
                  <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                    <Box>{caseUtils.getValidCaseLevels(activeCase.levels).length}</Box>{' '}
                    <Box mx={0.5}>/</Box>
                    <Box>
                      {activeCase.stage === CaseStageType.Complete ? (
                        caseUtils.getCompletedCaseLevelCount(activeCase.caseResults)
                      ) : (
                        <span>&mdash;</span>
                      )}
                    </Box>
                  </Box>
                </TableCell>
                <TableCell align={'center'}>
                  {activeCase.surgeonUser ? (
                    <Typography noWrap={true}>
                      {format.formatName(activeCase.surgeonUser)}
                    </Typography>
                  ) : (
                    <span>&mdash;</span>
                  )}
                </TableCell>
                <TableCell align={'center'}>
                  {activeCase?.location ? (
                    <Tooltip title={activeCase?.location?.organization?.name}>
                      <Typography noWrap={true} color={'textSecondary'}>
                        {activeCase?.location?.description}
                      </Typography>
                    </Tooltip>
                  ) : (
                    <span>&mdash;</span>
                  )}
                </TableCell>
                <TableCell align={'center'}>
                  {activeCase.assignedUser ? <UserAvatar user={activeCase.assignedUser} /> : null}
                </TableCell>
                <TableCell align={'center'}>
                  {activeCase.fieldRepUser ? <UserAvatar user={activeCase.fieldRepUser} /> : null}
                </TableCell>
                <TableCell align={'center'}>
                  {format.formatISODate(activeCase.updatedAt as unknown as string)}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      }
      loading={loading}
      paginationConfig={
        showPagination
          ? {
              totalCount: totalCaseCount || 0,
              onPaginationChange: (pagination) => {
                onPagination(pagination.rowsPerPage, pagination.page);
              },
              rowsPerPage: rowsPerPage || Globals.DefaultListPageSize,
              page: page || 0,
              rowsPerPageOptions: Globals.DefaultListPageSizeOptions,
            }
          : undefined
      }
    />
  );
};

export default CaseListTable;
